/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SideBarChildMenu from './sideBarChildMenu';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';
const { NAVIGATION } = katanaSubPageEnums;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SectionDefinitionsMenu = function SectionDefinitionsMenu() {
    /***** HOOKS *****/
    const { id, childpage, subpage } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_katana_section_definitions_ordered_data, isLoading: isGetSectionDefinitionsLoading } =
        katanaQuery.serviceID.meta.getSectionDefinitions.useOrderedQuery(id);
    const { data: get_katana_site_sections_data, isLoading: isGetSectionsLoading } = katanaQuery.serviceID.getSections.useQuery(id);
    const siteSectionIds = get_katana_site_sections_data?.map?.(({ section_id }) => section_id) ?? [];

    /***** RENDER HELPERS *****/
    /**
     * @type {import('containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/types').NSideBarChildMenu.Option[]}
     */
    const childMenuOptions = [
        !isGetSectionDefinitionsLoading && !isGetSectionsLoading && { title: 'Menu and Socials', routeKey: NAVIGATION },
        ...(get_katana_section_definitions_ordered_data
            ?.filter?.(({ id: _id }) => siteSectionIds.includes(_id))
            ?.map?.(({ name }) => ({ title: name, routeKey: getSectionNameRouteKey(name) })) ?? [])
    ].filter(Boolean);

    /***** RENDER *****/

    if (!childpage && subpage !== NAVIGATION) {
        return '';
    }
    return <SideBarChildMenu options={childMenuOptions} isLoading={isGetSectionDefinitionsLoading || isGetSectionsLoading} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { SectionDefinitionsMenu };
