/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OutlineTag.scss';
export const TAG_COLOURS = /** @type {const} */ ({
    ERROR: 'error',
    WARN: 'warn',
    SUCCESS: 'success',
    CONFIRM: 'confirm',
    WARNING: 'warning',
    NOTICE: 'notice',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    INFO: 'info',
    CONTENTION: 'contention',
    BRONZE: 'bronze',
    SILVER: 'silver',
    GOLD: 'gold',
    PLATINUM: 'platinum',
    LEGACY: 'legacy'
});
export const tagColors = Object.values(TAG_COLOURS);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      children: React.ReactNode;
 *      color?: NXUtils.ValueOf<typeof TAG_COLOURS>;
 *      className?: string;
 * }} props
 */
const OutlineTag = ({ children, color, className }) => (
    <div className={`OutlineTag${color ? ' ' + color : ''}${className ? ' ' + className : ''}`}>{children}</div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   PROPTYPES
 **********************************************************************************************************/

OutlineTag.propTypes = {
    // Text content of the tag
    children: PropTypes.node,

    // Color of the tag
    color: PropTypes.oneOf(tagColors),

    // Additional class to give to the tag
    className: PropTypes.string
};

export default OutlineTag;
