/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { synergyPasswordReset } from 'config/config';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/*   ACTIONS
 *****************************************************/
import { RenderField, RenderRevealPasswordField, requiredFieldValidation, renderButton } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SynergyLoginForm extends Component {
    componentDidMount() {
        const { vsm_synergy_login_error, change } = this.props;

        if (vsm_synergy_login_error?.credentials) {
            const { username, password } = vsm_synergy_login_error.credentials;
            change('username', username);
            change('password', password);
        }
    }

    render() {
        const { handleSubmit, migrateAfterLink, pristine, submitting, valid } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="vsLoginForm" onSubmit={handleSubmit}>
                <h3 className="vsLoginForm__heading">Already a Synergy Wholesale partner?</h3>
                <p className="vsLoginForm__intro">Enter your details below to get started</p>
                <Field
                    name="username"
                    label="Username"
                    component={RenderField}
                    validate={[requiredFieldValidation]}
                    type="text"
                    className="form__textfield"
                />
                <Field
                    name="password"
                    label="Password"
                    component={RenderRevealPasswordField}
                    validate={[requiredFieldValidation]}
                    type="password"
                    className="form__textfield"
                />
                {renderButton(pristine, submitting, valid, `Link account and ${migrateAfterLink ? 'confirm' : 'continue'}`, 'primary')}
                <Anchor href={synergyPasswordReset} target="_blank">
                    Forgot your password?
                </Anchor>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
SynergyLoginForm = reduxForm({
    form: 'vsSynergyLogin'
})(SynergyLoginForm);

export default connect(
    (state) => ({
        vsm_synergy_login_error: state.vsm.vsm_synergy_login_error
    }),
    {
        change
    }
)(SynergyLoginForm);
