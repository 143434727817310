/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{ children: React.ReactNode, name: string }} props
 * @deprecated Please just use props to pass named children to a component.
 */
export const Slot = ({ children }) => <>{children}</>;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

Slot.propTypes = {
    /**
     * Name of the slot this will fit in.
     */
    name: PropTypes.string.isRequired
};
