/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    return useMutation({
        mutationFn: (attributes: KatanaAPI.Katana.ServiceID.User.Socials.Type.Callback.POST.Attributes) =>
            KATANA_API.katana.service_id.user.socials.$type.callback.POST(attributes),
        onSuccess: () => {}
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const handleAuthorisationCallback = Object.freeze({
    useMutation: _useMutation
});
