/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let TargetNotEmptyMessage = ({ support_submit_migration_details_check_service_data }) => {
    /***** RENDER *****/
    if (support_submit_migration_details_check_service_data?.failed?.target_not_empty) {
        return (
            <DialogNotification type="warning" tail={{ pos: 'top' }}>
                {support_submit_migration_details_check_service_data?.failed?.target_not_empty}
            </DialogNotification>
        );
    }

    return '';
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    support_submit_migration_details_check_service_data: state.support.support_submit_migration_details_check_service_data
});

TargetNotEmptyMessage = connect(mapStateToProps)(TargetNotEmptyMessage);

export default TargetNotEmptyMessage;
