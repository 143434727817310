/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useMemo, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _AccountConnected as AccountConnected } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/_AccountConnected';
import { _ConnectAccount as ConnectAccount } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/_ConnectAccount';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { filterInstagramType } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/filterInstagramType';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SECOND } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface InstagramConnectCaveatProps {
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const InstagramConnectCaveat: React.FC<InstagramConnectCaveatProps> = ({ property }) => {
    /***** STATE *****/
    const [isAuthorising, setIsAuthorising] = useState(false);
    const [refetchInterval, setRefetchInterval] = useState(SECOND * 10);
    const [isRequestTimedOut, setIsRequestTimedOut] = useState(false);
    const [totalInstagramAccounts, setTotalInstagramAccounts] = useState(0);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const userSocialID = useGetDynamicFormFieldValue(property.key);
    const [dynamicSocialID, setDynamicSocialID] = useState<number | null>(null);
    const [currentlyConnectedSocialID, setCurrentlyConnectedSocialID] = useState<number | null>(null);

    useEventListener('focus', () => {
        if (isAuthorising) {
            setRefetchInterval(SECOND * 3);
        }
    });

    useEventListener('blur', () => {
        if (isAuthorising) {
            setRefetchInterval(SECOND * 10);
        }
    });

    /***** QUERIES *****/
    const {
        data: get_social_account_data,
        isLoading: isGetSocialAccountLoading,
        isSuccess: isGetSocialAccountSuccess
    } = katanaQuery.serviceID.getSocialAccount.useQuery(id, userSocialID);
    const { data: instagramAccounts, isLoading: isLoadingSocialAccounts } = katanaQuery.serviceID.listSocialAccounts.useQuery(id, {
        refetchInterval: isAuthorising ? refetchInterval : false,
        refetchOnWindowFocus: isAuthorising,
        select: filterInstagramType
    });

    /***** RENDER HELPERS *****/
    const hasConnectedSocialAccount = useMemo(() => {
        return instagramAccounts?.length || Boolean(userSocialID);
    }, [userSocialID, get_social_account_data, isGetSocialAccountSuccess]);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!dynamicSocialID) {
            setCurrentlyConnectedSocialID(userSocialID);
        }
    }, [userSocialID, dynamicSocialID]);

    useEffect(() => {
        if (!isAuthorising) return;

        //cancel the refetchInterval after 'x' amount of time
        const cancelRefetch = setTimeout(() => {
            setIsRequestTimedOut(true);
            setIsAuthorising(false);
        }, SECOND * 40);

        return () => {
            clearTimeout(cancelRefetch);
        };
    }, [instagramAccounts, isAuthorising]);

    useEffect(() => {
        if (instagramAccounts?.length !== totalInstagramAccounts) {
            setTotalInstagramAccounts(instagramAccounts?.length ?? 0);
            setIsRequestTimedOut(false);
            setIsAuthorising(false);
        }
    }, [instagramAccounts]);

    /***** RENDER HELPERS *****/
    const connectionStatus: 'not_connected' | 'connected' | 'loading' | 'authorising' | undefined = useMemo(() => {
        if (isAuthorising) {
            return 'authorising';
        }

        if (isLoadingSocialAccounts || isGetSocialAccountLoading) {
            return 'loading';
        }

        if (hasConnectedSocialAccount) {
            return 'connected';
        }

        if (!hasConnectedSocialAccount) {
            return 'not_connected';
        }
    }, [hasConnectedSocialAccount, isLoadingSocialAccounts, isAuthorising, isGetSocialAccountLoading]);

    /***** RENDER *****/
    const content = useMemo(() => {
        switch (connectionStatus) {
            case 'not_connected':
            case 'connected':
                return (
                    <>
                        <AccountConnected
                            propertyKey={property.key}
                            currentlyConnectedSocialID={currentlyConnectedSocialID}
                            setDynamicSocialID={setDynamicSocialID}
                        />
                        <ConnectAccount
                            propertyKey={property.key}
                            setIsAuthorising={setIsAuthorising}
                            setDynamicSocialID={setDynamicSocialID}
                            isAuthorising={isAuthorising}
                            isRequestTimedOut={isRequestTimedOut}
                        />
                    </>
                );

            case 'loading':
                return <RequestLoader message="Checking connected instagram accounts..." />;

            case 'authorising':
                return (
                    <Flex direction="column" gap={2}>
                        <RequestLoader message="Waiting for instagram to authorise the connection..." />
                        <SolidButton color="warn" onClick={() => setIsAuthorising(false)} width="full">
                            Cancel
                        </SolidButton>
                    </Flex>
                );

            default:
                break;
        }
    }, [connectionStatus]);

    /***** RENDER *****/
    return (
        <Padding paddingOnly top={4}>
            <NXBox.Title title="Instagram Details" />
            <NXBox.Description description="Add your instagram account to showcase your posts on your website." />
            <Flex direction="column" gap={2}>
                <Text>Instagram Account</Text>
                {content}
            </Flex>
        </Padding>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
