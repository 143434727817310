/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback } from 'react';
import { useStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Automatically updates the value of the state when the original writable input value changes.
 * @param {import('svelte/store').Writable<boolean>} store
 */
export function useBooleanStore(store) {
    /***** STATE *****/
    const [storeState] = useStore(store);

    /***** FUNCTIONS *****/
    const setTrue = useCallback(() => {
        store.set(true);
    }, []);

    const setFalse = useCallback(() => {
        store.set(false);
    }, []);

    const toggle = useCallback(() => {
        store.update((v) => !v);
    }, []);

    /***** HOOK RESULTS *****/
    return {
        value: storeState,
        setTrue,
        setFalse,
        toggle,
        setValue: store.set
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
