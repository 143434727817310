/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isAttachmentDataObject } from 'containers/katana/queries/methods/attachmentData';

export function mergeWithAttributeObjectPicker(objValue, srcValue) {
    if (isAttachmentDataObject(srcValue)) {
        return srcValue;
    }
}
