/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

import { getGSuiteVerificationStatus } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteSetupComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationStatus: false,
            record: undefined
        };
    }

    componentDidMount() {
        const { gsuite_verification_data } = this.props;

        if (gsuite_verification_data && gsuite_verification_data.attributes) {
            this.setState({
                record: gsuite_verification_data.attributes.token
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { gsuite_verification_status_status, gsuite_verification_status_data, gsuite_verification_status, gsuite_verification_data } =
            this.props;

        if (
            gsuite_verification_data &&
            gsuite_verification_data.attributes &&
            gsuite_verification_status === 'success' &&
            prevProps.gsuite_verification_status === 'loading'
        ) {
            this.setState({
                record: gsuite_verification_data.attributes.token
            });
        }

        if (gsuite_verification_status_status === 'success' && prevProps.gsuite_verification_status_status === 'loading') {
            const { attributes } = gsuite_verification_status_data;
            const { verified } = attributes;

            this.setState({
                verificationStatus: verified
            });
        }
    }

    render() {
        const { gsuiteid, gsuite_verification_status_status, getGSuiteVerificationStatus } = this.props;
        const { verificationStatus } = this.state;

        const renderVerificationStatus = (status) => {
            switch (status) {
                case true:
                    return (
                        <div className="sharedBox__infoColumn hasIcon">
                            <i className="infoColumn__icon icon icon-check confirm"></i>
                            <div className="infoColumn__wrapper">
                                <div className="title">Domain Verification Status</div>
                                <div className="desc">Verified</div>
                            </div>
                        </div>
                    );

                case false:
                default:
                    return (
                        <div className="sharedBox__infoColumn hasIcon">
                            <i className="infoColumn__icon icon icon-save-time-money notice"></i>
                            <div className="infoColumn__wrapper">
                                <div className="title">Domain Verification Status</div>
                                <div className="desc">In Progress</div>
                            </div>
                        </div>
                    );
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <Fragment>
                <Box
                    request={{
                        action: getGSuiteVerificationStatus,
                        args: gsuiteid,
                        status: gsuite_verification_status_status
                    }}
                    className="gsuiteSetupComplete"
                    status={gsuite_verification_status_status}
                    bottom={true}
                    columns={[
                        {
                            render: renderVerificationStatus(verificationStatus)
                        }
                    ]}
                />
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_verification_status_status: state.gsuite.gsuite_verification_status_status,
        gsuite_verification_status_data: state.gsuite.gsuite_verification_status_data,
        gsuite_verification_status: state.gsuite.gsuite_verification_status,
        gsuite_verification_data: state.gsuite.gsuite_verification_data,
        gsuite_service_data: state.gsuite.gsuite_service_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGSuiteVerificationStatus
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteSetupComplete));
