/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useFreeDomainClaim() {
    /***** STATE *****/
    const [isNoFreeDomainSelected, setIsNoFreeDomainSelected] = useState(true);
    const [freeDomainForms, setFreeDomainForms] = useState([]);

    /***** FUNCTIONS *****/
    const setTlds = (tlds) => {
        setFreeDomainForms(
            tlds.map(({ tld, domain, price }) => ({
                tld,
                domain,
                price,
                autoRenew: true,
                idProtection: true,
                register: false
            }))
        );
    };

    const selectNoFreeDomain = () => {
        setIsNoFreeDomainSelected(true);
        setFreeDomainForms((prevFreeDomainForms) =>
            prevFreeDomainForms.map((option) => ({
                ...option,
                register: false
            }))
        );
    };

    const toggleFreeDomainValue = (tld, field = 'autoRenew') => {
        if (!['autoRenew', 'idProtection', 'register'].includes(field)) return;

        setIsNoFreeDomainSelected((prevValue) => (field === 'register' ? false : prevValue));
        setFreeDomainForms((prevFreeDomainForms) =>
            prevFreeDomainForms.map((option) => {
                const getNewFieldValue = () => {
                    if (field === 'register' && freeDomainForms.length > 1) {
                        if (option.register === false && option.tld === tld) {
                            return true;
                        }
                        if (option.register === true && option.tld === tld) {
                            return false;
                        }
                    }

                    return tld === option.tld ? !option[field] : option[field];
                };

                return {
                    ...option,
                    [field]: getNewFieldValue()
                };
            })
        );
    };

    /***** RETURN *****/
    return { isNoFreeDomainSelected, freeDomainForms, setTlds, selectNoFreeDomain, toggleFreeDomainValue };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useFreeDomainClaim;
