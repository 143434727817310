/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderLargeListRadioButtons } from 'components/Form/RenderLargeListRadioButtons';
import { RenderSelectField, renderCheckboxField, requiredAcceptedValidation, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { booleanValidation } from 'utilities/methods/commonActions';

const formName = 'billingCycleForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class BillingCycleForm extends Component {
    render() {
        const { handleSubmit, options, invoice, service, newBillingCycle, services } = this.props;

        if (invoice && invoice.attributes.invoice_items.length > 1 && newBillingCycle) {
            const {
                attributes: { invoice_items }
            } = invoice;
            const currentService = invoice_items.find(({ service_id }) => service_id === service.id);
            const listItems = invoice_items
                .filter((item) =>
                    services
                        .filter(({ available_billing_cycle_ids }) => available_billing_cycle_ids.includes(newBillingCycle.id))
                        .map(({ id }) => id)
                        .includes(item.service_id)
                )
                .map(({ description }) => description);

            const disabledItems = invoice_items
                .filter((item) =>
                    services
                        .filter(({ available_billing_cycle_ids }) => !available_billing_cycle_ids.includes(newBillingCycle.id))
                        .map(({ id }) => id)
                        .includes(item.service_id)
                )
                .map(({ description }) => description);

            const list = [
                {
                    label: 'Apply this billing cycle change to this service only',
                    listItems: currentService.description,
                    value: false
                },
                {
                    label: 'Apply this billing cycle change to all other services on the same invoice too',
                    listItems: listItems,
                    value: true
                }
            ];

            const unpaidNotification = () => {
                if (currentService.status === 'unpaid')
                    return (
                        <div className="confirmation__notification">
                            PLEASE NOTE: Our systems indicate that this service currently has an outstanding invoice that also contains additional
                            services. By changing your billing cycle on this service, your original invoice will be cancelled and a new invoice(s)
                            will be created in its place. Your new invoice number(s) will be available once you have confirmed below.
                        </div>
                    );
                return '';
            };

            return (
                <form className="popup__form billingCycle--form" onSubmit={handleSubmit}>
                    <Field
                        label="Select New Billing Cycle"
                        name="newBillingCycle"
                        component={RenderSelectField}
                        validate={[requiredFieldValidation]}
                        type="select"
                        className="form__dropdown"
                        options={options}
                    />
                    <Field
                        component={RenderLargeListRadioButtons}
                        itemlist={list}
                        disabledItemList={disabledItems}
                        validate={[booleanValidation, requiredFieldValidation]}
                        name="other"
                        className="Invoices__radio-button"
                    />
                    <div className="confirmation__container">
                        {unpaidNotification()}
                        <h5 className="confirmation__heading">Acknowledgements</h5>
                        <div className="confirmation__input">
                            <Field
                                name="acknowledgements"
                                label="I understand that my original invoice will be cancelled and split into one or more invoices containing my newly requested billing cycle for this service and any remaining outstanding services from the original invoice."
                                component={renderCheckboxField}
                                validate={[requiredAcceptedValidation]}
                                type="checkbox"
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <SolidButton type="submit">Confirm Billing Cycle</SolidButton>
                </form>
            );
        }

        /***** RENDER *****/
        return (
            <form className="popup__form billingCycle--form" onSubmit={handleSubmit}>
                {invoice && (
                    <DialogNotification type="warning">
                        {`PLEASE NOTE: Our system has detected an outstanding invoice for this particular service, by changing your billing cycle the
                        outstanding invoice will be cancelled. A replacement invoice with your desired billing cycle will take it's place once you
                        confirm below.`}
                    </DialogNotification>
                )}
                <Field
                    label="Select New Billing Cycle"
                    name="newBillingCycle"
                    component={RenderSelectField}
                    validate={[requiredFieldValidation]}
                    type="select"
                    className="form__dropdown"
                    options={options}
                />
                {newBillingCycle?.name === 'Annually' ? (
                    <DialogNotification type="warning">
                        PLEASE NOTE: After changing to an annual billing cycle,
                        <br />
                        you are NOT able to revert back to a monthly billing cycle.
                    </DialogNotification>
                ) : (
                    ''
                )}
                <ReduxFormButton form={formName}>Confirm Billing Cycle</ReduxFormButton>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
BillingCycleForm = reduxForm({
    form: formName
})(BillingCycleForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const newBillingCycle = selector(state, 'newBillingCycle');

    return {
        newBillingCycle
    };
};

export default withRouter(connect(mapStateToProps, null)(BillingCycleForm));
