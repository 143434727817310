/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Subaccounts from 'containers/support/modules/subaccounts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OutlineButton from 'components/Buttons/OutlineButton';
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import MessagePlaceHolder from 'components/Placeholders/Message';
import NoServicesPlaceholder from 'components/Placeholders/NoServices';
import Search from 'components/Search';
import StatusTag from 'components/StatusTag';
import SolidTag from 'components/Tags/SolidTag';

/*   ACTIONS
 *****************************************************/
import { getSubaccountList } from 'containers/hosting/state/accountActions';
import {
    domainSearch,
    emailSearch,
    filterEmail,
    gsuiteSearch,
    hostingSearch,
    ms365Search,
    resetDomainSearch,
    resetEmailSearch,
    resetGSuiteSearch,
    resetHostingSearch,
    resetMs365Search,
    resetSSLSearch,
    resetVPSSearch,
    sslSearch,
    vpsSearch
} from 'containers/services/action';
import {
    cancelSearchDomain,
    cancelSearchEmail,
    cancelSearchGSuite,
    cancelSearchHosting,
    cancelSearchMs365,
    cancelSearchSSL,
    cancelSearchVPS
} from 'utilities/api/services';
import { getIncludedObjBasedOnType, textLowerCase } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketServiceInfo } from 'containers/support/methods';
import useSearchParams from 'utilities/hooks/useSearchParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getPurchaseLink, getPurchaseLinkIcon } from 'containers/services/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function TicketServices({
    // Redux props
    app_viewport,
    support_all_services_status,
    support_all_services_data,
    hosting_search_status,
    hosting_search_data,
    domain_search_status,
    domain_search_data,
    email_search_status,
    email_search_data,
    vps_search_status,
    vps_search_data,
    ssl_search_status,
    ssl_search_data,
    gsuite_search_status,
    gsuite_search_data,
    ms365_search_status,
    ms365_search_data,
    hosting_subaccount_list_data,
    hostingSearch,
    resetHostingSearch,
    domainSearch,
    resetDomainSearch,
    filterEmail,
    resetEmailSearch,
    vpsSearch,
    resetVPSSearch,
    sslSearch,
    resetSSLSearch,
    gsuiteSearch,
    resetGSuiteSearch,
    ms365Search,
    resetMs365Search,
    getSubaccountList
}) {
    /***** STATE *****/
    const [keyword, setKeyword] = useState('');
    const [subaccountKeyword, setSubaccountKeyword] = useState('');
    const [openResellerAccount, setOpenResellerAccount] = useState(null);
    const [templateView, setTemplateView] = useState('');
    const [templateTitle, setTemplateTitle] = useState('');

    /***** HOOKS *****/
    const ticketServicesRef = useRef(null);
    const subaccountSearchRef = useRef(null);

    const { searchParamsAdd } = useSearchParams();

    /***** FUNCTIONS *****/
    function monitorKeyword(keyword = '') {
        setKeyword(keyword);
    }

    function monitorSubaccountKeyword(subaccountKeyword = '') {
        setSubaccountKeyword(subaccountKeyword);
    }

    function setOpenResellerAccountFunc(id = null) {
        setOpenResellerAccount(id);
        monitorSubaccountKeyword();
    }

    function changeView(view) {
        const template = {
            view,
            title: ''
        };

        switch (view) {
            case 'webHosting':
                template.title = 'Web Hosting';
                break;
            case 'emailHosting':
                template.title = 'Email Hosting';
                break;
            case 'google':
                template.title = 'Google Workspace';
                break;
            case 'ms365':
                template.title = 'Microsoft 365';
                break;
            case 'vps':
                template.title = 'VPS';
                break;
            case 'ssl':
                template.title = 'SSL Certificates';
                break;
            case 'domains':
            default:
                template.title = 'Domain Names';
                break;
        }

        setTemplateTitle(template.title);
        setTemplateView(template.view);
        setKeyword('');
    }

    /***** RENDER HELPERS *****/
    const renderTable = (data) => {
        const renderSearch = () => {
            return (
                <div className="search__container">
                    <div className="search__form">
                        <input
                            ref={subaccountSearchRef}
                            type="text"
                            onChange={(e) => monitorSubaccountKeyword(e.target.value)}
                            value={subaccountKeyword}
                            placeholder="Search for a sub account"
                        />
                        {subaccountKeyword ? (
                            <button type="button" onClick={() => monitorSubaccountKeyword()}>
                                <SolidTag color="info">Clear</SolidTag>
                            </button>
                        ) : (
                            <button type="submit">
                                <span className="icon icon-search"></span>
                            </button>
                        )}
                    </div>
                </div>
            );
        };

        if (data.length <= 0 && keyword) return <p className="ticketServices__noSearchResults">No services matched your search.</p>;

        return data.map((service, index) => {
            const serviceInfo = getTicketServiceInfo(service);

            if (!serviceInfo) return '';

            const product = getIncludedObjBasedOnType(service.included, 'product');

            const isReseller = product?.attributes?.service_type === 'reseller_hosting';

            function getButtonClick() {
                if (isReseller)
                    return () => {
                        getSubaccountList(serviceInfo.id);
                        setOpenResellerAccountFunc(serviceInfo.id);
                    };
                return () => {
                    searchParamsAdd('service', serviceInfo.id);
                };
            }
            const buttonOnClick = getButtonClick();

            const subaccountsList = hosting_subaccount_list_data?.attributes?.subaccounts || [];
            const listToMap = subaccountKeyword ? subaccountsList.filter((item) => item.domain.includes(subaccountKeyword)) : subaccountsList;

            return (
                <div key={`${serviceInfo.id}-${index}`} className="ticketsServicesTable__row">
                    <div className="ticketsServicesTable__rowLeft">
                        <div className="ticketsServicesTable__service">
                            <div className="ticketsServicesTable__serviceTitle">{serviceInfo.domain}</div>
                            <div className="ticketsServicesTable__tag">
                                <StatusTag status={textLowerCase(serviceInfo.status)} className="ticketsServicesTable__serviceStatus"></StatusTag>
                            </div>
                        </div>

                        {serviceInfo.product ? <div className="ticketsServicesTable__serviceProduct">{serviceInfo.product}</div> : ''}
                    </div>

                    {isReseller && openResellerAccount === serviceInfo.id ? (
                        ''
                    ) : (
                        <OutlineButton type="onClick" size="small" onClick={buttonOnClick}>
                            {isReseller ? 'List Accounts' : 'Select'}
                        </OutlineButton>
                    )}

                    {isReseller && openResellerAccount === serviceInfo.id && (
                        <div
                            className={`ticketsServicesTable__subaccounts${
                                isReseller && openResellerAccount === serviceInfo.id ? ' ticketsServicesTable__subaccounts--open' : ''
                            }`}
                        >
                            {renderSearch()}
                            <Subaccounts
                                actionOnClick={({ user, domain }) => {
                                    searchParamsAdd('service', serviceInfo.id);
                                    searchParamsAdd('subaccount-user', user);
                                    searchParamsAdd('subaccount-domain', domain);
                                }}
                                data={listToMap}
                            />
                        </div>
                    )}
                </div>
            );
        });
    };

    const renderContent = () => {
        const services = {
            webHosting: {
                key: 'webHosting',
                title: 'Web Hosting',
                purchaseLink: '',
                data: support_all_services_data[`webHosting`],
                search: {
                    status: hosting_search_status,
                    data: hosting_search_data,
                    reset: resetHostingSearch,
                    cancel: cancelSearchHosting,
                    func: hostingSearch
                }
            },
            emailHosting: {
                key: 'emailHosting',
                title: 'Email Hosting',
                purchaseLink: '',
                data: support_all_services_data[`emailHosting`],
                search: {
                    status: email_search_status,
                    data: email_search_data,
                    reset: resetEmailSearch,
                    cancel: cancelSearchEmail,
                    func: (keyword) => filterEmail(support_all_services_data, keyword)
                }
            },
            google: {
                key: 'google',
                title: 'Google Workspace',
                purchaseLink: '',
                data: support_all_services_data[`gsuite`],
                search: {
                    status: gsuite_search_status,
                    data: gsuite_search_data,
                    reset: resetGSuiteSearch,
                    cancel: cancelSearchGSuite,
                    func: gsuiteSearch
                }
            },
            ms365: {
                key: 'ms365',
                title: 'Microsoft 365',
                purchaseLink: '',
                data: support_all_services_data[`ms365`],
                search: {
                    status: ms365_search_status,
                    data: ms365_search_data,
                    reset: resetMs365Search,
                    cancel: cancelSearchMs365,
                    func: ms365Search
                }
            },
            vps: {
                key: 'vps',
                title: 'VPS',
                purchaseLink: '',
                data: support_all_services_data[`vps`],
                search: {
                    status: vps_search_status,
                    data: vps_search_data,
                    reset: resetVPSSearch,
                    cancel: cancelSearchVPS,
                    func: vpsSearch
                }
            },
            ssl: {
                key: 'ssl',
                title: 'SSL Certificates',
                purchaseLink: '',
                data: support_all_services_data[`ssl`],
                search: {
                    status: ssl_search_status,
                    data: ssl_search_data,
                    reset: resetSSLSearch,
                    cancel: cancelSearchSSL,
                    func: sslSearch
                }
            },
            domains: {
                key: 'domains',
                title: 'Domain Names',
                purchaseLink: '',
                data: support_all_services_data[`domains`],
                search: {
                    status: domain_search_status,
                    data: domain_search_data,
                    reset: resetDomainSearch,
                    cancel: cancelSearchDomain,
                    func: domainSearch
                }
            }
        };

        const serviceType = services[templateView];

        let matrixData = [];
        if (keyword) {
            if (serviceType.search.data?.length > 0) matrixData = [...serviceType.search.data];
        } else {
            if (serviceType.data?.length > 0) matrixData = [...serviceType.data];
        }

        return serviceType.data?.length > 0 ? (
            <>
                <Search
                    slim={true}
                    render={{
                        status: serviceType.search.status,
                        placeholder: `Search for ${serviceType.title} service`
                    }}
                    functions={{
                        cancel: serviceType.search.cancel,
                        search: serviceType.search.func,
                        reset: serviceType.search.reset
                    }}
                    helpers={{
                        keyword: monitorKeyword
                    }}
                />
                {serviceType.search.status === 'loading' ? <RequestLoader /> : renderTable(matrixData)}
            </>
        ) : (
            <NoServicesPlaceholder
                service={{
                    name: templateTitle,
                    purchaseLink: getPurchaseLink(templateTitle)
                }}
            />
        );
    };

    const renderServicesSection = () => {
        return (
            <section ref={ticketServicesRef} className="ticketServices">
                <Box
                    premounted={true}
                    className="ticketServices__box"
                    title={templateTitle}
                    tabs={{
                        condition: templateView,
                        data: [
                            {
                                key: '',
                                title: <div className="initialHeading">Select a Service Type</div>
                            },
                            {
                                key: 'domains',
                                title: 'Domain Names',
                                className: support_all_services_data[`domains`]?.length > 0 ? 'hasServices' : '',
                                icon: getPurchaseLinkIcon('Domain Names'),
                                action: (e) => {
                                    e.preventDefault();
                                    changeView('domains');
                                }
                            },
                            {
                                key: 'webHosting',
                                title: 'Web Hosting',
                                className: support_all_services_data[`webHosting`]?.length > 0 ? 'hasServices' : '',
                                icon: getPurchaseLinkIcon('Web Hosting'),
                                action: (e) => {
                                    e.preventDefault();
                                    changeView('webHosting');
                                }
                            },
                            {
                                key: 'emailHosting',
                                title: 'Email Hosting',
                                className: support_all_services_data[`emailHosting`]?.length > 0 ? 'hasServices' : '',
                                icon: getPurchaseLinkIcon('Email Hosting'),
                                action: (e) => {
                                    e.preventDefault();
                                    changeView('emailHosting');
                                }
                            },
                            {
                                key: 'google',
                                title: 'Google Workspace',
                                className: support_all_services_data[`gsuite`]?.length > 0 ? 'hasServices' : '',
                                icon: getPurchaseLinkIcon('Google Workspace'),
                                action: (e) => {
                                    e.preventDefault();
                                    changeView('google');
                                }
                            },
                            {
                                key: 'ms365',
                                title: 'Microsoft 365',
                                className: support_all_services_data[`ms365`]?.length > 0 ? 'hasServices' : '',
                                icon: getPurchaseLinkIcon('Microsoft 365'),
                                action: (e) => {
                                    e.preventDefault();
                                    changeView('ms365');
                                }
                            },
                            {
                                key: 'vps',
                                title: 'VPS',
                                className: support_all_services_data[`vps`]?.length > 0 ? 'hasServices' : '',
                                icon: getPurchaseLinkIcon('VPS'),
                                action: (e) => {
                                    e.preventDefault();
                                    changeView('vps');
                                }
                            },
                            {
                                key: 'ssl',
                                title: 'SSL Certificates',
                                className: support_all_services_data[`ssl`]?.length > 0 ? 'hasServices' : '',
                                icon: getPurchaseLinkIcon('SSL Certificates'),
                                action: (e) => {
                                    e.preventDefault();
                                    changeView('ssl');
                                }
                            }
                        ]
                    }}
                    custom={{
                        render: templateView ? (
                            renderContent()
                        ) : (
                            <MessagePlaceHolder
                                message={`Please use the ${
                                    app_viewport === 'sm' || app_viewport === 'xs' ? 'dropdown menu' : 'tabs above'
                                } to select the type of service you wish to submit an eTicket about.`}
                                icon={app_viewport === 'sm' || app_viewport === 'xs' ? 'list' : 'grid'}
                            />
                        ),
                        pos: `bottom`
                    }}
                />
            </section>
        );
    };

    /***** RENDER *****/
    switch (support_all_services_status) {
        case 'success':
            return renderServicesSection();
        case 'error':
            return <FetchPageError />;
        case 'loading':
        default:
            return <RequestLoader />;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            app_viewport: state.app.app_viewport,
            support_all_services_status: state.support.support_all_services_status,
            support_all_services_data: state.support.support_all_services_data,
            hosting_search_status: state.services.hosting_search_status,
            hosting_search_data: state.services.hosting_search_data,
            domain_search_status: state.services.domain_search_status,
            domain_search_data: state.services.domain_search_data,
            email_search_status: state.services.email_search_status,
            email_search_data: state.services.email_search_data,
            vps_search_status: state.services.vps_search_status,
            vps_search_data: state.services.vps_search_data,
            ssl_search_status: state.services.ssl_search_status,
            ssl_search_data: state.services.ssl_search_data,
            gsuite_search_status: state.services.gsuite_search_status,
            gsuite_search_data: state.services.gsuite_search_data,
            ms365_search_status: state.services.ms365_search_status,
            ms365_search_data: state.services.ms365_search_data,
            hosting_subaccount_list_data: state.hosting.hosting_subaccount_list_data
        }),
        {
            hostingSearch,
            resetHostingSearch,
            domainSearch,
            resetDomainSearch,
            emailSearch,
            resetEmailSearch,
            vpsSearch,
            resetVPSSearch,
            sslSearch,
            resetSSLSearch,
            gsuiteSearch,
            ms365Search,
            resetMs365Search,
            resetGSuiteSearch,
            getSubaccountList,
            filterEmail
        }
    )(TicketServices)
);
