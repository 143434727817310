/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ValidateTXT extends Component {
    componentDidMount() {
        const { beacon, dispatch, form } = this.props;

        dispatch(change(form, 'beacon', beacon));
    }

    render() {
        const { beacon, copyToClipboard } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="validateTXT">
                <div className="validateTXT__container">
                    <div className="validateTXT__input">
                        <div className="validateTXT__value">{beacon}</div>
                        <Anchor
                            className="validateTXT__action"
                            onClick={(e) => {
                                e.preventDefault();
                                copyToClipboard(beacon);
                            }}
                        >
                            Copy
                        </Anchor>
                    </div>
                </div>
                <div className="validateTXT__text">
                    Once created, click the <strong>Validate Domain Name</strong> button below. Allow up to 15 minutes for DNS propogation.
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ValidateTXT = reduxForm({
    form: 'validateTXT'
})(ValidateTXT);

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            copyToClipboard
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(ValidateTXT);
