/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ScrollableComponent } from 'components/ScrollableComponent';
import { getExpiryInfo } from 'containers/services/modules/domains/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show the expiry date of the domain
 * @param {{
 *      expiryDate: string
 *      isRenewable: boolean
 *      domainId: string
 * }} props
 */
export function DomainExpiry({ expiryDate, isRenewable, domainId }) {
    /***** RENDER HELPERS *****/
    const expiryInfo = getExpiryInfo(expiryDate);

    function renderRenewAnchor() {
        if (!isRenewable) {
            return '';
        }

        return <ScrollableComponent.Anchor pathname={`/my-services/domains/general/renew/${domainId}`}>Renew</ScrollableComponent.Anchor>;
    }

    /***** RENDER *****/
    return (
        <NXBox.OverviewRow>
            <NXBox.InfoPair
                title="Expires"
                description={
                    <Text>
                        <Text warn={expiryInfo.isWithin90} bold={expiryInfo.isWithin90}>
                            {expiryInfo.partOne} {expiryInfo.partTwo}
                        </Text>{' '}
                        <Text secondary size--s italic>
                            {expiryInfo.formattedExpiryDate || ''}
                        </Text>
                    </Text>
                }
            />
            {renderRenewAnchor()}
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainExpiry;
