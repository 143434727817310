/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showNavigation } from 'utilities/consts';

/*   ACTIONS
 *****************************************************/
import { Route } from 'react-router-dom';
import { resetScrollEvents } from 'utilities/methods/commonActions';
import { renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Move from 'components/Move';
import ChangeRegistrant from '../modules/changeRegistrant';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Admin extends Component {
    /************** LIFECYCLE METHODS **************/
    componentWillUnmount() {
        const { resetScrollEvents } = this.props;

        resetScrollEvents();
    }

    render() {
        const { modules } = this.props;

        if (showNavigation) {
            return <div className="domainAdmin">{renderSectionModules(modules)}</div>;
        }

        return (
            <div className="domainAdmin">
                <Route exact path="/my-services/domains/admin/move/:id" component={Move} />
                <Route exact path="/my-services/domains/admin/cor/:id" component={ChangeRegistrant} />
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapDispatchToProps = {
    resetScrollEvents
};

export default connect(null, mapDispatchToProps)(Admin);
