/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Border from 'components/Utils/Border';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useMatchMedia } from 'utilities/hooks/useMatchMedia';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import AppPresetGraphic from 'assets/images/domains/app-presets.svg';
import './_DNSHosting.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{ toggleAddPresetLightbox: () => void }>}
 */
export const PresetFooter = ({ toggleAddPresetLightbox }) => {
    /***** HOOKS *****/
    const isLarge = useMatchMedia({ min: 1100 });
    const isMobile = useAppViewport(['sm', 'xs']);

    /***** RENDER HELPERS *****/
    const classes = /** @type {const} */ ({
        content: classNames('DNSHostingPresetFooter__content', {
            'DNSHostingPresetFooter__content--large': isLarge,
            'DNSHostingPresetFooter__content--mobile': isMobile
        }),
        graphic: classNames('DNSHostingPresetFooter__graphic', {
            'DNSHostingPresetFooter__graphic--mobile': isMobile
        }),
        outer: classNames('DNSHostingPresetFooter', {
            'DNSHostingPresetFooter--mobile': isMobile
        }),
        button: 'DNSHostingPresetFooter__button'
    });

    /***** RENDER *****/
    return (
        <Border top={1}>
            <Padding xy={isMobile ? 3 : 5}>
                <div className={classes.outer}>
                    <div className={classes.content}>
                        <Text primary size--xl semiBold>
                            Add an App preset
                        </Text>
                        <Text secondary={!isMobile} size--s>
                            Connect your domain name to a third party service like Google Workspace, your Shopify website and more. You can also
                            easily point your domain to a VentraIP service.
                        </Text>
                        <SolidButton onClick={toggleAddPresetLightbox} className={classes.button}>
                            Add App Preset
                        </SolidButton>
                    </div>
                    <img src={AppPresetGraphic} alt="app icons" className={classes.graphic} />
                </div>
            </Padding>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
