import DEV_IconOverview from 'components/TestRoutes/components/DEV_IconOverview';
import DEV_IconsOverview from 'components/TestRoutes/components/DEV_IconsOverview';

/**
 * @type {TestRoute[]}
 */
export const AppTestRoutes = [
    {
        title: 'Render All CCP Font Icons',
        path: '/DEV/all-font-icons',
        DEV_Component: DEV_IconOverview
    },
    {
        title: 'Render All CCP SVG Icons',
        path: '/DEV/all-svg-icons',
        DEV_Component: DEV_IconsOverview
    }
];
