/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _RichTextEditorTextArea as RichTextEditorTextArea } from 'components/Form/RichTextEditor/_RichTextEditorTextArea';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import type { RichTextData } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import { isRichTextData } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RichTextEditorTextAreaReduxFormNamespace } from 'components/Form/RichTextEditor/_RichTextEditorTextArea/wrappers/reduxform/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RichTextEditorTextAreaReduxForm: React.FC<RichTextEditorTextAreaReduxFormNamespace.Props> = (props) => {
    const { input, meta, label, rows, features, initialValue } = props;
    const { name, value, onChange, onBlur } = input;

    /***** HOOKS *****/
    const isMarkdown = useMemo(() => features?.includes('markdown'), [features]);
    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    const onChangeProxy = useCallback(
        (value: string) => {
            dispatch(touch(meta.form, name));
            if (isMarkdown) {
                const newData: RichTextData = {
                    content: value,
                    type: 'markdown'
                };

                onChange(newData);
                return;
            }

            onChange(value);
        },
        [onChange, isMarkdown]
    );

    /***** RENDER HELPERS *****/
    const valueProxy = useMemo(() => {
        if (isRichTextData(value)) {
            return value.content ?? '';
        }
        if (_.isString(value)) {
            return value;
        }

        Sentry.captureException(new Error('Invalid value passed to `_RichTextEditorTextAreaReduxForm`'), { data: { props, input } });
        return '';
    }, [value]);

    /***** RENDER *****/
    return (
        <FormItem
            // Want to keep these here to help remind that these are normally required but are not implemented in _RichTextEditorTextAreaReduxForm
            // disabled={disabled}
            name={name}
        >
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta}>
                <RichTextEditorTextArea
                    // Want to keep these here to help remind that these are normally required but are not implemented in _RichTextEditorTextAreaReduxForm
                    // disabled={disabled}
                    initialValue={initialValue}
                    value={valueProxy}
                    rows={rows}
                    onChange={onChangeProxy}
                    onBlur={onBlur}
                    features={features}
                />
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
