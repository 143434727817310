/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RequestLoader from 'components/Loaders/Request';
import SolidTag from 'components/Tags/SolidTag';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import RenderPhoneField from 'components/Form/PhoneInput';
import { pushNotification } from 'components/Toast/functions';
import { application } from 'config/config';
import {
    emailFieldValidation,
    RenderAdvanceListSlim,
    RenderField,
    requiredFieldValidation,
    validateIsAustralianNumber,
    validateReactPhoneNumberInput
} from 'utilities/methods/form';
import { enableTwoFactor, getQRCode, getTwoFactor, verifyTwoFactor } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const formName = 'twoFactorSetupForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TwoFactorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mountedView: null,
            currentView: null,
            verify: {
                destination: undefined,
                step: false
            },
            google: {
                image: undefined,
                token: undefined
            },
            hasPushTwoFactor: false
        };
        this.mountView = this.mountView.bind(this);
        this.changeView = this.changeView.bind(this);
        this.getCode = this.getCode.bind(this);
        this.verifyTwoFactorCode = this.verifyTwoFactorCode.bind(this);
        this.setupWebOTPApi = this.setupWebOTPApi.bind(this);
        this.stopPolling = this.stopPolling.bind(this);

        this.webOTPAbortController = null;
    }

    mountView(value) {
        // Preps view to be displayed without rendering it immediately
        this.setState({
            mountedView: value
        });
    }

    changeView(value) {
        // Toggles the actual view and displays it after mounting
        const verify = {
            destination: undefined,
            step: false
        };
        this.setState({
            currentView: value ? value : null,
            verify: value ? this.state.verify : verify
        });
    }

    getCode() {
        // Toggles the actual view and displays it after mounting
        const { enableTwoFactor, mobileNumber, emailAddress, getQRCode } = this.props;
        const { mountedView } = this.state;
        const { changeView } = this;

        changeView('loading');

        let attributes = {
            method: mountedView.toUpperCase()
        };

        if (mountedView === 'push') {
            getQRCode();
        } else {
            switch (mountedView) {
                case 'email':
                    attributes = {
                        ...attributes,
                        email: emailAddress
                    };
                    break;

                case 'sms':
                    attributes = {
                        ...attributes,
                        phone_number: mobileNumber
                    };
                    break;

                case 'app':
                    attributes = {
                        ...attributes,
                        method: 'GAUTH'
                    };
                    break;

                case 'push':
                    attributes = {
                        ...attributes,
                        method: 'PUSH'
                    };
                    break;

                default:
                    break;
            }

            enableTwoFactor(attributes);
        }
    }

    verifyTwoFactorCode(twoFACode = null) {
        const { verifyTwoFactor, code } = this.props;
        verifyTwoFactor(twoFACode || code);
    }

    setupWebOTPApi() {
        const { change } = this.props;
        const { verifyTwoFactorCode } = this;

        // Web OTP
        if ('OTPCredential' in window) {
            this.webOTPAbortController = new AbortController();

            navigator.credentials
                .get({
                    otp: { transport: ['sms'] },
                    signal: this.webOTPAbortController.signal
                })
                .then((otp) => {
                    const twoFACode = otp.code;
                    change('code', twoFACode);
                    verifyTwoFactorCode(twoFACode);
                    if (this.webOTPAbortController?.abort) this.webOTPAbortController.abort();
                })
                .catch((err) => {});
        }
    }

    componentDidMount() {
        const { preset, account_twofactor_data } = this.props;
        const { getCode } = this;

        if (account_twofactor_data) {
            const pushTwoFactor = account_twofactor_data.find((twoFactor) => twoFactor.attributes.method === 'PUSH');

            if (pushTwoFactor) {
                this.setState({
                    hasPushTwoFactor: true
                });
            }
        }

        if (preset) {
            this.setState(
                {
                    currentView: preset,
                    mountedView: preset
                },
                () => {
                    if (['app', 'push'].includes(preset)) {
                        getCode();
                    }
                }
            );
        }
    }

    /**
     * Attempts to clear any polling that may be occuring within the state
     */
    stopPolling() {
        const { intervalId, timeoutId } = this.state;

        clearTimeout(timeoutId);
        clearInterval(intervalId);

        this.setState((prevState) => ({
            ...prevState,
            timeoutId: null,
            intervalId: null
        }));
    }

    componentWillUnmount() {
        if (this.webOTPAbortController?.abort) this.webOTPAbortController.abort();
        clearTimeout(this.state.timeoutId);
        clearInterval(this.state.intervalId);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            account_twofactor_config_status,
            account_twofactor_enable_status,
            account_twofactor_enable_data,
            account_twofactor_verify_status,
            account_twofactor_data,
            account_twofactor_status,
            account_qr_code_status,
            account_qr_code_data,
            emailAddress,
            mobileNumber,
            getTwoFactor,
            closeSetupLightbox
        } = this.props;
        const { mountedView, verify, intervalId, hasPushTwoFactor } = this.state;
        const { setupWebOTPApi } = this;

        if (account_twofactor_enable_status === 'error' && prevProps.account_twofactor_enable_status === 'loading') {
            this.setState({
                currentView: mountedView
            });
        }

        if (account_twofactor_config_status === 'success' && prevProps.account_twofactor_config_status === 'loading') {
            this.setState({
                currentView: mountedView
            });
        }

        if (account_twofactor_enable_status === 'success' && prevProps.account_twofactor_enable_status === 'loading') {
            if (mountedView === 'app') {
                this.setState({
                    currentView: mountedView,
                    google: {
                        image: account_twofactor_enable_data.img,
                        secret: account_twofactor_enable_data.secret
                    }
                });
            } else if (mountedView === 'sms') {
                this.setState({
                    currentView: mountedView,
                    verify: {
                        destination: mobileNumber,
                        step: true
                    }
                });
            } else {
                this.setState({
                    currentView: mountedView,
                    verify: {
                        destination: emailAddress,
                        step: true
                    }
                });
            }
        }

        if (verify.step && !prevState.verify.step) {
            setupWebOTPApi();
        }

        if (account_twofactor_verify_status === 'success' && prevProps.account_twofactor_verify_status === 'loading') {
            this.setState({
                currentView: null,
                verify: {
                    destination: undefined,
                    step: false
                }
            });
        }

        // Check the user's list of configured 2fa methods for the vipc app method
        if (account_twofactor_status === 'success' && prevProps.account_twofactor_status === 'loading') {
            const pushTwoFactor = account_twofactor_data?.find((twoFactor) => twoFactor.attributes.method === 'PUSH');

            // Adding the app has been successful so close the lightbox
            if (!hasPushTwoFactor && pushTwoFactor) {
                closeSetupLightbox();
                pushNotification({ status: 200, details: 'Two factor successfully configured.' });
            }
        }

        if (account_qr_code_status === 'success' && prevProps.account_qr_code_status === 'loading' && mountedView === 'push') {
            this.setState({
                currentView: mountedView,
                google: {
                    image: account_qr_code_data.img,
                    secret: account_qr_code_data.secret
                }
            });

            const twoFactorPolling = () => {
                // Fetch the user's list of configured 2fa methods. We will then check the list for the vipc app method to determine if adding the app method has been successful
                getTwoFactor();
                clearInterval(intervalId);
            };

            const newIntervalId = setInterval(() => {
                twoFactorPolling();
            }, 10000);

            const timeoutId = setTimeout(() => clearInterval(this.state.intervalId), 300000);

            this.setState((prevState) => {
                return {
                    ...prevState,
                    timeoutId,
                    intervalId: newIntervalId
                };
            });
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { code, account_qr_code_data, emailAddress, mobileNumber, account_twofactor_data, account_twofactor_status, closeSetupLightbox } =
            this.props;
        const { mountedView, currentView, google, verify } = this.state;
        const { changeView, mountView, getCode, verifyTwoFactorCode, stopPolling } = this;

        const render2FACodeField = () => (
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Verify Code"
                        name="code"
                        type="text"
                        component={RenderField}
                        autoComplete="one-time-code"
                        inputMode="numeric"
                        className="form__textfield"
                        validate={requiredFieldValidation}
                    />
                </div>
            </div>
        );

        const renderGoBackButton = () => (
            <div className="twofactor__link-container">
                <Anchor
                    className="twofactor__link"
                    onClick={(e) => {
                        e.preventDefault();
                        stopPolling();

                        if (account_twofactor_data?.length === 4) {
                            closeSetupLightbox();
                        }

                        changeView();
                    }}
                >
                    Go Back
                </Anchor>
            </div>
        );

        const pages = /** @satisfies {Record<string, { onSubmit: () => void }>} */ ({
            email: {
                onSubmit: () => {
                    if (verify.step && code) verifyTwoFactorCode();
                    else if (!requiredFieldValidation(emailAddress) && !emailFieldValidation(emailAddress)) getCode();
                }
            },
            app: {
                onSubmit: () => {
                    if (code) verifyTwoFactorCode();
                }
            },
            sms: {
                onSubmit: () => {
                    if (verify.step && code) verifyTwoFactorCode();
                    else if (!requiredFieldValidation(mobileNumber) && !validateReactPhoneNumberInput(mobileNumber)) getCode();
                }
            }
        });

        const handleSMSRender = () => {
            if (verify.step) {
                return (
                    <Fragment>
                        <div className="twofactor__description">
                            We&apos;ve sent a confirmation code to {verify.destination}. Please enter this code below to enable two-factor
                            authentication via SMS.
                        </div>
                        {render2FACodeField()}
                        <ReduxFormButton form={formName}>Continue</ReduxFormButton>
                        {renderGoBackButton()}
                    </Fragment>
                );
            }

            return (
                <Fragment>
                    <div className="twofactor__description">
                        Your two-factor authentication code will be sent to the phone number below. Please note that we currently only support
                        Australian phone numbers for two-factor authentication.
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Phone"
                                name="phone"
                                component={RenderPhoneField}
                                required
                                type="tel"
                                country="AU"
                                forceStaticCountry={true}
                                countrySelectEnabled={false}
                                validate={[requiredFieldValidation, validateReactPhoneNumberInput, validateIsAustralianNumber]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <ReduxFormButton form={formName}>Continue</ReduxFormButton>
                    {renderGoBackButton()}
                </Fragment>
            );
        };

        const handleEmailRender = () => {
            if (verify.step) {
                return (
                    <Fragment>
                        <div className="twofactor__description">
                            We&apos;ve sent a confirmation code to {verify.destination}. Please enter this code below to enable two-factor
                            authentication via email.
                        </div>
                        {render2FACodeField()}
                        <ReduxFormButton form={formName}>Continue</ReduxFormButton>
                        {renderGoBackButton()}
                    </Fragment>
                );
            }

            return (
                <Fragment>
                    <div className="twofactor__description">Your two-factor code will be sent to the email address attached to your account.</div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Email Address"
                                name="email"
                                component={RenderField}
                                type="email"
                                placeholder="Email"
                                readOnly="readonly"
                                validate={[requiredFieldValidation, emailFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <ReduxFormButton form={formName}>Continue</ReduxFormButton>
                    {renderGoBackButton()}
                </Fragment>
            );
        };

        const handleAppRender = () => {
            return (
                <Fragment>
                    <div className="twofactor__description">
                        Scan the QR Code below with your preferred Authenticator App to set up two-factor authentication.
                    </div>
                    <img alt="QR Code" className="twofactor__image" src={`data:image/png;base64,${google.image}`} />
                    {render2FACodeField()}
                    <ReduxFormButton form={formName}>Verify</ReduxFormButton>
                    <div className="twofactor__token">
                        <div className="token__details">
                            <div className="token__label">Token</div>
                            <div className="token__value">{google.secret}</div>
                        </div>
                        <div className="token__link">
                            <Anchor
                                onClick={(e) => {
                                    e.preventDefault();
                                    getCode();
                                }}
                                className="link"
                            >
                                Generate new token
                            </Anchor>
                        </div>
                    </div>
                    {renderGoBackButton()}
                </Fragment>
            );
        };

        const handlePushRender = () => {
            if (!account_qr_code_data) {
                return <Fragment>{renderGoBackButton()}</Fragment>;
            }

            return (
                <Fragment>
                    <div className="twofactor__description">Scan the QR Code below with {application} app to set up two-factor authentication.</div>
                    <img alt="QR Code" className="twofactor__image" src={`data:image/png;base64,${google.image}`} />
                    <div className="twofactor__setup">
                        <h3 className="twofactor__heading">How to setup {application} app authentication on your account</h3>
                        <ol className="twofactor__steps">
                            <li className="twoFactor__steps--step">
                                1. Download the {application} app from
                                <Anchor
                                    className="twofactor__external"
                                    href="https://apps.apple.com/us/app/vipcontrol/id6448714430"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {' App Store '}
                                </Anchor>
                                or
                                <Anchor
                                    className="twofactor__external"
                                    href="https://play.google.com/store/apps/details?id=com.vipcontrol"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {' Google Play Store'}
                                </Anchor>
                            </li>
                            <li className="twoFactor__steps--step">
                                2. On the app login page, select the
                                <span className="highlight"> &apos;Or use a QR code to login&apos;</span> option
                            </li>
                            <li className="twoFactor__steps--step">3. Scan the QR code above to enable {application} authentication</li>
                        </ol>
                    </div>
                    {renderGoBackButton()}
                </Fragment>
            );
        };

        const getListAttributes = () => {
            let fieldOptions = [
                {
                    attributes: {
                        name: 'app',
                        displayName: `Authenticator App`,
                        desc: `Receive a code from your authentication app`,
                        onClick: () => mountView('app')
                    }
                },
                {
                    attributes: {
                        name: 'push',
                        displayName: `${application} App`,
                        tag: <SolidTag color="success">New!</SolidTag>,
                        desc: `Receive a verification push notification from ${application} app`,
                        onClick: () => mountView('push')
                    }
                },
                {
                    attributes: {
                        name: 'sms',
                        displayName: 'Mobile Verification',
                        desc: `Receive a code via SMS every time you log in.`,
                        onClick: () => mountView('sms')
                    }
                },
                {
                    attributes: {
                        name: 'email',
                        displayName: 'Email Verification',
                        desc: `Receive a code via email every time you log in`,
                        onClick: () => mountView('email')
                    }
                }
            ];

            account_twofactor_data?.forEach(({ attributes }) => {
                switch (attributes.method) {
                    case 'EMAIL':
                        fieldOptions = fieldOptions.filter(({ attributes }) => attributes.name !== 'email');
                        break;
                    case 'GAUTH':
                        fieldOptions = fieldOptions.filter(({ attributes }) => attributes.name !== 'app');
                        break;
                    case 'SMS':
                        fieldOptions = fieldOptions.filter(({ attributes }) => attributes.name !== 'sms');
                        break;
                    case 'PUSH':
                        fieldOptions = fieldOptions.filter(({ attributes }) => attributes.name !== 'push');
                        break;
                    default:
                        break;
                }
            });

            return fieldOptions;
        };

        const handleViewToggle = () => {
            switch (currentView) {
                case null:
                    return (
                        <Fragment>
                            <div className="twofactor__description">
                                Please select your preferred method of authentication. <br />
                                Selecting an existing method of authentication will overwrite any existing settings already configured for that
                                method.
                            </div>
                            {account_twofactor_status === 'loading' ? (
                                <RequestLoader />
                            ) : (
                                <Field
                                    component={RenderAdvanceListSlim}
                                    name="method"
                                    type="hidden"
                                    className="form__textfield"
                                    list={getListAttributes()}
                                />
                            )}
                            <ReduxFormButton
                                form={formName}
                                onClick={() => (['app', 'push'].includes(mountedView) ? getCode() : changeView(mountedView))}
                            >
                                Continue
                            </ReduxFormButton>
                        </Fragment>
                    );
                case 'loading':
                    return <RequestLoader />;

                case 'app':
                    return handleAppRender();

                case 'push':
                    return handlePushRender();

                case 'email':
                    return handleEmailRender();

                case 'sms':
                default:
                    return handleSMSRender();
            }
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            pages[currentView]?.onSubmit();
        };

        return (
            <Transition when={currentView}>
                <form autoComplete="off" className={`twofactor twofactor__form ${currentView ? currentView : `default`}`} onSubmit={handleSubmit}>
                    {handleViewToggle()}
                </form>
            </Transition>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
TwoFactorForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(TwoFactorForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const emailAddress = selector(state, 'email');
    const mobileNumber = selector(state, 'phone');
    const code = selector(state, 'code');

    const initialValues = state.app.app_user_data;
    if (!initialValues?.phone?.startsWith('+61')) initialValues.phone = '+61';

    return {
        initialValues,
        app_user_data: state.app.app_user_data,
        account_twofactor_config_status: state.account.account_twofactor_config_status,
        account_twofactor_verify_status: state.account.account_twofactor_verify_status,
        account_twofactor_enable_status: state.account.account_twofactor_enable_status,
        account_twofactor_enable_data: state.account.account_twofactor_enable_data,
        account_qr_code_status: state.account.account_qr_code_status,
        account_qr_code_data: state.account.account_qr_code_data,
        account_twofactor_data: state.account.account_twofactor_data,
        account_twofactor_status: state.account.account_twofactor_status,
        emailAddress,
        mobileNumber,
        code
    };
};
const mapDispatchToProps = {
    enableTwoFactor,
    getQRCode,
    verifyTwoFactor,
    change,
    getTwoFactor
};

TwoFactorForm = connect(mapStateToProps, mapDispatchToProps)(TwoFactorForm);

export default TwoFactorForm;
