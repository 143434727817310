/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RewardCardBody } from 'containers/vipRewards/modules/rewardCard/new/body';
import { RewardCardButton } from 'containers/vipRewards/modules/rewardCard/new/button';
import { RewardCardImage } from 'containers/vipRewards/modules/rewardCard/new/image';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RewardCardComponent = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _RewardCardComponent: RewardCardComponent = ({ children }) => {
    /***** RENDER *****/
    return <div className="rewardCard">{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const RewardCard = Object.assign(_RewardCardComponent, {
    Image: RewardCardImage,
    Body: RewardCardBody,
    Button: RewardCardButton
});
