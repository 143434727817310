/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { renderButton, RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/

class InvoiceDateForm extends Component {
    render() {
        const { handleSubmit, pristine, submitting, valid } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="invoiceDate__form" onSubmit={handleSubmit}>
                <DialogNotification type="warning">
                    PLEASE NOTE: This feature does not apply to Domain Names and SSL Certificates but rather applies to all recurring products
                </DialogNotification>
                <Field
                    label="Select New Invoice Generation Preference"
                    name="days"
                    component={RenderSelectField}
                    validate={[requiredFieldValidation]}
                    type="select"
                    className="form__dropdown"
                    options={[
                        {
                            label: '7 Days',
                            value: 7
                        },
                        {
                            label: '10 Days',
                            value: 10
                        },
                        {
                            label: '14 Days',
                            value: 14
                        },
                        {
                            label: '28 Days',
                            value: 28
                        }
                    ]}
                />
                {renderButton(pristine, submitting, valid, submitting ? <RequestLoader /> : 'Confirm', 'primary')}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
InvoiceDateForm = reduxForm({
    form: 'InvoiceDateForm'
})(InvoiceDateForm);

export default InvoiceDateForm;
