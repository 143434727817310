/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getRenewData } from './methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_bulk.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let BulkActions = (props) => {
    const {
        closeBulk,
        tools,
        title,
        preselectedTool,
        preselected,
        preappliedPromoCode,
        ServiceSelectionComponent,
        onSelectTool,
        /** Redux Props */
        domain_bulk_list_status,
        domain_bulk_action_status,
        domain_bulk_action_data
    } = props;

    /***** STATE *****/
    const [showInvoiceLightbox, setShowInvoiceLightbox] = useState(false);
    const [invoiceID, setInvoiceID] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [currentTool, setCurrentTool] = useState(preselectedTool);

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** FUNCTIONS *****/
    function openPayInvoice(id) {
        setShowInvoiceLightbox(true);
        setInvoiceID(id);
    }

    function closePayInvoice() {
        setShowInvoiceLightbox(false);
        setInvoiceID(null);
        getRenewData({ selectedServices, promoCode: preappliedPromoCode });
    }

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, domain_bulk_list_status === 'success');
    }, [domain_bulk_list_status]);

    useEffect(() => {
        onSelectTool?.(currentTool);
    }, [currentTool]);

    useEffectAfterMount(() => {
        if (domain_bulk_action_status !== 'success') return;
        if (domain_bulk_action_data?.id) openPayInvoice(domain_bulk_action_data.id);
        else closeBulk();
    }, [domain_bulk_action_status]);

    /***** RENDER HELPERS *****/
    /**
     * Based on the current tool it calls the render method on that tool
     */
    const handleBulkToolRender = () => {
        if (has(tools, currentTool)) {
            const renderData = { selectedServices, preappliedPromoCode };
            return tools[currentTool].render(renderData);
        }
        return '';
    };

    /**
     * Create tool select dropdown list.
     */
    const toolSelectOptions = Object.keys(tools).map((key) => {
        const { title } = tools[key];
        return {
            value: key,
            label: title,
            onClick: () => {
                setCurrentTool(key);
            }
        };
    });

    const preselectedOptionIndex = toolSelectOptions.indexOf(toolSelectOptions.find(({ value }) => value === preselectedTool));

    /***** RENDER *****/
    return (
        <div ref={scrollRef} className="bulkActions">
            <div className="bulkActions__serviceList">
                {/* This component will pass a list of selected service back to the bulk component so that it can be passed to the active tool */}
                <ServiceSelectionComponent
                    preselected={preselected}
                    onUpdateSelectedServices={setSelectedServices}
                    servicesFilter={tools?.[currentTool]?.servicesFilter}
                    disabledServices={tools?.[currentTool]?.disabledServices}
                />
            </div>
            <div className="bulkActions__actions">
                <NXBox className="bulkActions__box">
                    <NXBox.Top title={title} />
                    <Anchor
                        className="bulkActions__goBackButton"
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            closeBulk();
                        }}
                    >
                        GO BACK
                    </Anchor>

                    <NXBox.DefaultPadding>
                        <SelectDropdown
                            preselectedOptionIndex={preselectedOptionIndex >= 0 ? preselectedOptionIndex : null}
                            label="Select Bulk Domain Tool"
                            noSelectionLabel="Please Select"
                            options={toolSelectOptions}
                        />
                    </NXBox.DefaultPadding>
                    <NXBox.DefaultPadding>{handleBulkToolRender()}</NXBox.DefaultPadding>
                </NXBox>
            </div>
            {showInvoiceLightbox && (
                <OverlayLightbox
                    title={'Pay Invoice #' + invoiceID}
                    invoiceid={invoiceID}
                    onOpen={showInvoiceLightbox}
                    onClose={closePayInvoice}
                    onSuccessClose={closePayInvoice}
                />
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

BulkActions.propTypes = {
    /**
     * Title of the Tools selection / operation Box
     */
    title: PropTypes.string.isRequired,

    /**
     * The preselected tool
     */
    preselectedTool: PropTypes.string,
    /**
     * List of preselected service ID's
     */
    preselected: PropTypes.arrayOf(PropTypes.number),

    /**
     * This is used when we want to navigate to the bulk tool from some other page, and have a promo code already be applied. Eg. we used it for mega may promo
     */
    preappliedPromoCode: PropTypes.string,

    /**
     * The component that handles selection of the services to be used in the bulk editor.
     */
    ServiceSelectionComponent: PropTypes.elementType,

    /**
     * Object with tools title and render method.
     */
    tools: PropTypes.objectOf(
        PropTypes.shape({
            /**
             * The title of the dropdown menu item.
             */
            title: PropTypes.string.isRequired,

            /**
             * The render function which takes a object of @param { selectedServices: object[], promoCode: string }
             */
            render: PropTypes.func.isRequired,

            /**
             * Will be "disabled" when trying to select for this tool
             */
            disabledServices: PropTypes.arrayOf(
                PropTypes.shape({
                    /**
                     * The condition for the service.
                     * Takes @param {object} service
                     */
                    condition: PropTypes.func.isRequired,
                    /**
                     * The content to render for the disabled services
                     */
                    content: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string])
                })
            )
        })
    ),

    /**
     * The function to call when wanting to close the bulk section.
     */
    closeBulk: PropTypes.func.isRequired,

    /**
     * Function to call when the selected tool is updated
     */
    onSelectTool: PropTypes.func
};

const mapStateToProps = (state) => ({
    domain_bulk_action_data: state.servicesBulk.domain_bulk_action_data,
    domain_bulk_action_status: state.servicesBulk.domain_bulk_action_status,
    domain_bulk_list_status: state.servicesBulk.domain_bulk_list_status
});

const mapDispatchToProps = {};

BulkActions = connect(mapStateToProps, mapDispatchToProps)(BulkActions);

BulkActions = withRouter(BulkActions);

export default BulkActions;
