import Grid from 'components/Grid';
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SimpleTable from 'components/SimpleTable';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useReviewPlan } from '.';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * ReviewTable - Private Component exported as part of the ComposableReviewPlan Component
 */
const _ReviewTable = () => {
    /***** HOOKS *****/
    const { currentPlan, name, onGoBack, cycle, price, refund } = useReviewPlan();

    /***** FUNCTIONS *****/
    const columns = '40% 40% auto';
    const insertIf = (condition, element) => (condition ? [element] : []);

    /***** RENDER *****/
    return (
        <SimpleTable
            rows={React.Children.toArray([
                <Grid columns={columns}>
                    <Text secondary>Current Plan</Text>
                    <Text>{currentPlan}</Text>
                </Grid>,
                <Grid columns={columns}>
                    <span>
                        <Text primary bold>
                            <span>NEW</span>
                        </Text>
                        <Text secondary>
                            <span> Plan</span>
                        </Text>
                    </span>
                    <Text>{name}</Text>
                    <Anchor onClick={onGoBack}>Revise</Anchor>
                </Grid>,
                <Grid columns={columns}>
                    <Text secondary>Billing Cycle</Text>
                    <Text>{cycle}</Text>
                </Grid>,
                <Grid columns={columns}>
                    <Text secondary>Plan Cost</Text>
                    <Text>${price}</Text>
                </Grid>,
                ...insertIf(
                    refund,
                    <Grid columns={columns}>
                        <Text secondary>Refunded Amount</Text>
                        <Text>
                            <p>${refund}</p>
                        </Text>
                    </Grid>
                )
            ])}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _ReviewTable;
