/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MegaMaySpinModal, getRemainingSpins } from '../spinModal';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import SolidButton from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useMegaMayAvaliableSpinsQuery } from '../../queries';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useStore } from 'utilities/hooks/useStore';
import { pluralize } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { megaMaySpinBannerLightboxState } from 'config/containers/promotions/megamay2024/components/spinBanner/consts';
import Background from '../../assets/skinny-banner-background.svg';
import Logo from '../../assets/skinny-banner-logo.svg';
import MiniWheel from '../../assets/wheel-mini.svg';

import './_megamay24SpinBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function MegaMaySpinBanner() {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['sm', 'xs']);

    /***** STATE *****/
    const [isSpinModalOpen, setIsSpinModalOpen] = useStore(megaMaySpinBannerLightboxState);

    /***** QUERIES *****/
    const { data: megaMayAvailableSpinsData } = useMegaMayAvaliableSpinsQuery();

    /***** RENDER HELPERS *****/
    const numOfSpinsRemaining = getRemainingSpins(megaMayAvailableSpinsData);

    /***** RENDER *****/
    if (typeof megaMayAvailableSpinsData?.total_spins !== 'number' || megaMayAvailableSpinsData.total_spins <= 0) {
        return null;
    }

    return (
        <BackgroundImage className="mmSpinBanner" imageURL={Background} cover>
            <div className="mmSpinBanner__content">
                <img className="mmSpinBanner__logo" src={Logo} alt="MegaMay Spin to Win" />
                <div className="mmSpinBanner__middle">
                    <Text className="mmSpinBanner__text" white align--center lead--1>
                        You have <span>{numOfSpinsRemaining}</span> wheel spin{pluralize(numOfSpinsRemaining)}{' '}
                        {numOfSpinsRemaining > 0 ? 'waiting for you!' : 'remaining'}
                    </Text>
                    <img className="mmSpinBanner__miniWheel" src={MiniWheel} alt="Mini Wheel" />
                </div>
                <SolidButton
                    className={classNames('mmSpinBanner__button', { 'mmSpinBanner__button--hidden': numOfSpinsRemaining <= 0 })}
                    color="white"
                    onClick={() => setIsSpinModalOpen(true)}
                >
                    {isMobile ? 'Spin' : 'Spin The Wheel'}
                </SolidButton>
            </div>

            <MegaMaySpinModal isSpinModalOpen={isSpinModalOpen} closeSpinModal={() => setIsSpinModalOpen(false)} />
        </BackgroundImage>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
