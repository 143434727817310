/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import Sidebar from 'components/Sidebar';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SideBarPageWrapper from 'components/SideBarPage/_SideBarPageWrapper';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { resetScrollEvents } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SideBarPage.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      title?: string;
 *      icon?: string;
 *      children: React.ReactNode;
 *      sidebarList: MobileSideBar.LinkItem[];
 *      className?: string;
 * }>}
 */
const _SideBarPage = ({ title, icon, children, sidebarList, className }) => {
    /***** HOOKS *****/

    /***** RENDER HELPERS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const isTablet = useAppViewport('md');

    function renderSideBar() {
        return <Sidebar title={title} icon={icon} links={sidebarList} />;
    }

    function getColumns() {
        if (isMobile) {
            return '100%';
        }
        if (isTablet) {
            return '190px minmax(100px, 1fr)';
        }
        return '300px minmax(100px, 1fr)';
    }

    /***** EFFECTS *****/
    useEffect(() => {
        return () => {
            resetScrollEvents()(store.dispatch);
        };
    }, []);

    /***** RENDER *****/
    return (
        <SideBarPageWrapper className={className}>
            {/* Render Mobile Size */}
            {isMobile ? renderSideBar() : ''}

            <div className="SideBarPage__content">
                <Grid columns={getColumns()} gap={6} alignItems--start>
                    {isMobile ? '' : renderSideBar()}

                    {children}
                </Grid>
            </div>
        </SideBarPageWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const SideBarPage = Object.assign(_SideBarPage, { Wrapper: SideBarPageWrapper });

export default SideBarPage;
