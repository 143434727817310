/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { performValidations } from 'utilities/methods/commonActions/performValidation/performValidation';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NXInputDecoupledNamespace } from 'components/Form/Input/wrappers/decoupled/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This input component handles all the logic for validation and other input validations.
 * It's decoupled so that it works independently of any form framework
 */
const _InputDecoupledDefault: React.FC<NXInputDecoupledNamespace.Props> = (props) => {
    const { label, name, value = null, children, placeholder, validate = [], inputRef, validationBorderOnly, intrinsicProps } = props;

    /***** STATE *****/
    const [touched, setTouched] = useState(false);

    /***** FUNCTIONS *****/
    /**
     * @type {React.ChangeEventHandler<HTMLInputElement>}
     */
    function _onChange(v) {
        setTouched(true);
        intrinsicProps.onChange(v);
    }

    /***** RENDER HELPERS *****/
    // Check if the input is valid if one of them is invalid, the input is invalid return the value from the first invalidation
    const validationResult = validate.length ? performValidations(validate, value) : undefined;

    /***** RENDER *****/
    return (
        <FormItem name={name}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner
                borderOnly={validationBorderOnly}
                meta={{
                    error: validationResult,
                    touched,
                    warning: undefined,
                    pristine: false,
                    initial: undefined
                }}
            >
                <Input
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    inputRef={inputRef}
                    intrinsicProps={{ ...intrinsicProps, onChange: _onChange }}
                />

                {children}
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _InputDecoupledDefault };
