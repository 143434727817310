/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createBrowserHistory } from 'history';

/**
 * History object used to store history state throughout the app.
 *
 * Shared with the outermost Router & Sentry.
 */
export const history = createBrowserHistory();
