/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BaseDropdown from '../_BaseDropdown';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SolidDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// IMPROVE: Base styling for the list does not match OutlineDropdown
/**
 * Solid Dropdown Variant, use this for most dropdowns
 */
function SolidDropdown(props) {
    /***** RENDER *****/
    return <BaseDropdown variant="SolidDropdown" {...props} />;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

// SolidDropdown.propTypes = {
//     ...baseDropdownProptypes
// };

export default SolidDropdown;
