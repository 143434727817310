/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';
import { MigrationReduxControlPanelFormPart2 } from './controlPanelValidate';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const checkboxField = getFieldComponentAndClassName(FieldTypesEnum.CHECKBOX);

const checkBoxData = [
    {
        label: 'You accept that ALL data on the destination cPanel account with VentraIP Australia will be deleted to allow the migration process to proceed. This process will delete ALL data including files, images, databases, email accounts and DNS records.',
        name: 'delete_data'
    },
    {
        label: 'You accept that any files or databases incompatible with a Linux/MySQL/cPanel web hosting environment will not function (for example, .ASP files and MSSQL databases that are dependent on Windows will not function on our Linux cPanel servers',
        name: 'incompatible_check'
    },
    {
        label: "You're aware that any email software configured on your local devices may need to be updated to connect to the new hosting server once the migration is complete. Our technical support team will be able to provide you with this new information as part of the migration process.",
        name: 'email_reconfig'
    }
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ControlPanelAcknowledgementsForm = () => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <>
            {checkBoxData.map((field) => {
                const { label, name } = field;
                return (
                    <Field
                        key={name}
                        label={label}
                        name={name}
                        component={checkboxField.component}
                        type={FieldTypesEnum.CHECKBOX}
                        className={checkboxField.className}
                    />
                );
            })}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ControlPanelAcknowledgementsForm = reduxForm(MigrationReduxControlPanelFormPart2)(ControlPanelAcknowledgementsForm);

export default ControlPanelAcknowledgementsForm;
