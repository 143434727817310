/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BillingCycleForm from '../../forms/billingCycle';
import BillingCycleSuccess from '../../forms/billingCycle/success';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';

/*   ACTIONS
 *****************************************************/
import { getEmailBillingCycles, getEmailMailboxInformation, updateEmailBillingCycle } from 'containers/email/action';
import { formatLuxonOrdinal, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ScrollableComponent } from 'components/ScrollableComponent';
import './_emailBillingCycle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function BillingCycle({
    // Redux props
    email_information_mailbox_data,
    email_billingcycle_status,
    email_billing_status,
    updateEmailBillingCycle,
    getEmailBillingCycles,
    getEmailMailboxInformation
}) {
    /***** STATE *****/
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSuccessLightboxOpen, setIsSuccessLightboxOpen] = useState(false);

    /***** FUNCTIONS *****/
    function toggleDropdown() {
        setIsDropdownOpen((prev) => !prev);
    }

    function openSuccessLightbox() {
        setIsSuccessLightboxOpen(true);
    }

    function closeSuccessLightbox() {
        setIsSuccessLightboxOpen(false);
        getEmailMailboxInformation(email_information_mailbox_data?.id);
    }

    function submitBillingRequest(values) {
        const attributes = {
            billing_cycle_id: values.newBillingCycle.id,
            include_other: values.other
        };

        updateEmailBillingCycle(email_information_mailbox_data?.id, attributes);
    }

    /***** EFFECTS *****/
    // Fetch possible billing cycles
    useEffect(() => {
        getEmailBillingCycles(email_information_mailbox_data?.id);
    }, [email_information_mailbox_data?.id]);

    // Open success lightbox after billing cycle change success
    useEffectAfterMount(() => {
        if (email_billing_status === 'success') {
            openSuccessLightbox();
        }
    }, [email_billing_status]);

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={email_billingcycle_status === 'success'} className="emailBillingCycle">
            <NXBox initialStatus={email_billingcycle_status} isLoading={email_billing_status === 'loading'}>
                <NXBox.Top
                    title="Billing Cycle"
                    description="You can change your billing cycle to make when you're invoiced more convenient. You will be invoiced on the new billing cycle at the end of your current prepaid period."
                />
                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={[
                            <NXBox.InfoPair
                                key={1}
                                title="Current Billing Cycle"
                                description={email_information_mailbox_data?.attributes.billing_cycle}
                            />,
                            <NXBox.InfoPair
                                key={2}
                                title="Next Invoice Date"
                                description={formatLuxonOrdinal(
                                    toLuxonDate(email_information_mailbox_data?.attributes.next_invoice_date).toFormat('ccc, LLL {d} yyyy')
                                )}
                            />,
                            <OutlineButton key={3} type="onClick" onClick={toggleDropdown}>
                                Change
                            </OutlineButton>
                        ]}
                    />
                </NXBox.Bottom>
                <NXBox.Dropdown isOpen={isDropdownOpen}>
                    <NXBox.DefaultPadding>
                        <div>
                            <NXBox.Dropdown.CloseButton onClose={toggleDropdown} />
                            <BillingCycleForm onSubmit={submitBillingRequest} />
                        </div>
                    </NXBox.DefaultPadding>
                </NXBox.Dropdown>
            </NXBox>

            {isSuccessLightboxOpen ? (
                <OverlayLightbox onOpen={isSuccessLightboxOpen} onClose={closeSuccessLightbox}>
                    <BillingCycleSuccess closeLightbox={closeSuccessLightbox} />
                </OverlayLightbox>
            ) : (
                ''
            )}
        </ScrollableComponent>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    email_information_mailbox_data: state.email.email_information_mailbox_data,
    email_billingcycle_status: state.email.email_billingcycle_status,
    email_billing_status: state.email.email_billing_status
});

const mapDispatchToProps = {
    updateEmailBillingCycle,
    getEmailBillingCycles,
    getEmailMailboxInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingCycle);
