/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { getAccountEmails, resendAccountEmail, resetSearchAccountEmails, searchAccountEmails, viewAccountEmail } from 'containers/account/action';
import { cancelSearchAccountEmails } from 'utilities/api/account';
import { isLastPage, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AccountEmail extends Component {
    constructor(props) {
        super(props);
        this.openEmailLightbox = this.openEmailLightbox.bind(this);
        this.closeEmailLightbox = this.closeEmailLightbox.bind(this);
        this.resendEmail = this.resendEmail.bind(this);
        this.monitorSearchKeyword = this.monitorSearchKeyword.bind(this);

        this.state = {
            showEmailLightbox: false,
            showEmailID: false,
            showEmailMessage: false,
            currentSearchKeyword: null
        };
    }

    monitorSearchKeyword(keyword = null) {
        this.setState({
            currentSearchKeyword: keyword
        });
    }

    openEmailLightbox(id) {
        const { viewAccountEmail } = this.props;

        this.setState(
            {
                showEmailLightbox: true,
                showEmailID: id
            },
            () => {
                viewAccountEmail(id);
            }
        );
    }

    closeEmailLightbox() {
        this.setState({
            showEmailLightbox: false,
            showEmailID: false,
            showEmailMessage: false
        });
    }

    resendEmail(id) {
        const { resendAccountEmail } = this.props;
        resendAccountEmail(id);
    }

    componentDidUpdate(prevProps) {
        const { account_emails_status, account_emails_view_status, account_emails_view_data } = this.props;
        registerScrollEvents(this, account_emails_status === 'success' && prevProps.account_emails_status === 'loading');

        if (account_emails_view_status === 'success' && prevProps.account_emails_view_status === 'loading') {
            const { attributes } = account_emails_view_data;

            this.setState({
                showEmailMessage: attributes.message
            });
        }
    }

    render() {
        const {
            account_emails_search_status,
            account_emails_search_data,
            account_emails_status,
            account_emails_data,
            account_emails_meta,
            searchAccountEmails,
            resetSearchAccountEmails,
            getAccountEmails
        } = this.props;
        const { showEmailLightbox, showEmailMessage, currentSearchKeyword } = this.state;
        const { monitorSearchKeyword, closeEmailLightbox, openEmailLightbox, resendEmail } = this;

        const renderDropDown = (id) => {
            return {
                label: 'View',
                type: 'onClick',
                className: 'emailDropdown',
                onClick: (e) => {
                    e.preventDefault();
                    openEmailLightbox(id);
                },
                list: [
                    {
                        label: 'Resend Email',
                        type: 'onClick',
                        onClick: (e) => {
                            e.preventDefault();
                            resendEmail(id);
                        }
                    }
                ]
            };
        };

        const getEmailMatrix = (data) => {
            if (!data || data.length <= 0) return [];

            return data.map((item) => {
                const dateAttribute = toLuxonDate(item.attributes.date);
                const dateFormat = dateAttribute.toFormat('dd/MM/yyyy h:mm a');
                return {
                    subject: (
                        <Anchor
                            onClick={() => {
                                openEmailLightbox(item.id);
                            }}
                            className="details__subject"
                        >
                            {item.attributes.subject}
                        </Anchor>
                    ),
                    date: dateFormat,
                    actions: renderDropDown(item.id)
                };
            });
        };

        const handleLoadingStatus = () => {
            return (currentSearchKeyword ? account_emails_search_status : account_emails_status) || 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountEmails"
            >
                <Box
                    request={{
                        action: getAccountEmails,
                        args: false,
                        status: account_emails_status
                    }}
                    className="accountEmails__box"
                    title="Account Emails"
                    desc="Below is a list of emails that have been sent to this account."
                    search={{
                        status: account_emails_search_status,
                        placeholder: `Search for an email`,
                        slim: true,
                        functions: {
                            cancel: cancelSearchAccountEmails,
                            search: searchAccountEmails,
                            reset: resetSearchAccountEmails
                        },
                        helpers: {
                            keyword: monitorSearchKeyword
                        }
                    }}
                    custom={{
                        render: (
                            <Fragment>
                                <Table
                                    embedded={true}
                                    layered={true}
                                    loading={handleLoadingStatus()}
                                    header={[
                                        {
                                            title: `Email Subject / Date Sent`,
                                            className: `subject`
                                        },
                                        {
                                            action: true
                                        }
                                    ]}
                                    matrix={getEmailMatrix(currentSearchKeyword ? account_emails_search_data : account_emails_data)}
                                    error="You have no emails at the moment."
                                    showAll={{
                                        label: 'SHOW MORE',
                                        status: account_emails_status,
                                        conditions: !isLastPage(account_emails_meta) && !currentSearchKeyword,
                                        onClick: () => getAccountEmails(account_emails_meta.current_page + 1, account_emails_data)
                                    }}
                                />
                            </Fragment>
                        ),
                        pos: 'bottom'
                    }}
                />
                {showEmailLightbox ? (
                    <OverlayLightbox title="View Email" onClose={closeEmailLightbox} onOpen={showEmailLightbox}>
                        <div className="accountEmails__view">{showEmailMessage ? htmr(showEmailMessage) : <RequestLoader />}</div>
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            account_emails_status: state.account.account_emails_status,
            account_emails_data: state.account.account_emails_data,
            account_emails_meta: state.account.account_emails_meta,
            account_emails_search_status: state.account.account_emails_search_status,
            account_emails_search_data: state.account.account_emails_search_data,
            account_emails_view_status: state.account.account_emails_view_status,
            account_emails_view_data: state.account.account_emails_view_data
        }),
        {
            resetSearchAccountEmails,
            searchAccountEmails,
            viewAccountEmail,
            getAccountEmails,
            resendAccountEmail
        }
    )(AccountEmail)
);
