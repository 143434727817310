/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { particleParams } from 'assets/particles/particles';
import { company, service_status, support_number, ticket_name } from 'config/config';
import htmr from 'htmr';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Particles from 'react-tsparticles';
import store from 'store/store';
import { loadFull } from 'tsparticles';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Category from '../modules/category';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import OutlineButton from 'components/Buttons/OutlineButton';
import { CardGroup } from 'components/Card';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import Search from 'components/Search';

/*   ACTIONS
 *****************************************************/
import { changeLivechatVisibility, livechatVisibilityOptions } from 'App/action';
import { scrollToRef } from 'utilities/methods/commonActions';
import { getKnowledgeBaseData, setKnowledgeBaseAll } from '../action';
import { supportFilterDelayedFuseSearch } from '../methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let KnowledgeBase = (props) => {
    const { dispatch } = store;
    const {
        support_knowledge_base_all_posts_data,
        match: {
            url,
            params: { section, param4, param5 }
        },
        support_knowledge_base_data,
        support_knowledge_base_category,
        support_knowledge_base_sub_category,
        support_knowledge_base_article,
        support_knowledge_base_category_refs,
        app_viewport,
        support_knowledge_base_status,
        history
    } = props;

    /***** STATE *****/
    const [searchList, setSearchList] = useState([]);
    const [showSearchResults, setShowSearchResults] = useState(false);

    /***** HOOKS *****/
    const knowledgeBaseRef = useRef();
    const otherMethodsRef = useRef();
    const particlesInit = useCallback(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    /***** FUNCTIONS *****/
    function setSearchArticles(articleList) {
        const filteredList = articleList.map((result) => result.item);

        setSearchList(filteredList);
    }

    function searchArticles(keyword) {
        if (support_knowledge_base_all_posts_data?.length > 0) {
            supportFilterDelayedFuseSearch(support_knowledge_base_all_posts_data, keyword, setSearchArticles);

            setShowSearchResults(!!keyword);
        }
    }

    function resetSearch() {
        setSearchList([]);
        setShowSearchResults(false);
    }

    function searchItemClick() {
        setShowSearchResults(false);
        scrollToRef(knowledgeBaseRef?.current);
    }

    /***** RENDER HELPERS *****/
    function renderSearchList() {
        if (searchList?.length > 0)
            return (
                <ul className="knowledgeBase__searchList">
                    {searchList.map((item, index) => (
                        <li key={index} className="knowledgeBase__searchResult">
                            <Link onClick={searchItemClick} to={item.link || '/support/support-centre'}>
                                {item.post_title}
                            </Link>
                        </li>
                    ))}
                </ul>
            );
        return (
            <div className="knowledgeBase__searchEmpty">
                <div className="knowledgeBase__searchEmptyText">No articles could be found using those search terms</div>
                <Anchor to="/support/tickets/submit" className="knowledgeBase__searchTicketLink">
                    Submit an eTicket
                </Anchor>
            </div>
        );
    }

    function setKnowledgeBaseStateFromUrl() {
        const category = support_knowledge_base_data?.find((cat) => cat.slug === section);
        const subcat = category?.subcategories?.find((subcat) => subcat.slug === param4);
        const article = subcat ? subcat.posts?.find((post) => post.post_name === param5) : category?.posts?.find((post) => post.post_name === param4);

        if (
            category !== support_knowledge_base_category ||
            subcat !== support_knowledge_base_sub_category ||
            article !== support_knowledge_base_article
        ) {
            dispatch(setKnowledgeBaseAll(category, subcat, article));
        }
    }

    /************** LIFECYCLE METHODS **************/
    useEffect(() => {
        dispatch(getKnowledgeBaseData());
    }, []);

    // Update category, subcategory and article when url changes
    useEffect(() => {
        setKnowledgeBaseStateFromUrl();
    }, [url]);

    useEffect(() => {
        // When page loads wait until all category refs are registered then set category, subcategory and article based on url
        if (support_knowledge_base_data && Object.keys(support_knowledge_base_category_refs).length === support_knowledge_base_data.length) {
            setKnowledgeBaseStateFromUrl();
        }
    }, [support_knowledge_base_data, support_knowledge_base_category_refs]);

    useEffect(() => {
        // If a new category is set, scroll to it
        if (support_knowledge_base_category && !support_knowledge_base_article) {
            scrollToRef(support_knowledge_base_category_refs[support_knowledge_base_category.title]?.current);
        }
    }, [support_knowledge_base_category, support_knowledge_base_article]);

    useEffect(() => {
        // If a new article is set, scroll to it
        if (support_knowledge_base_article) {
            scrollToRef(knowledgeBaseRef?.current);
        }
    }, [support_knowledge_base_article]);

    /***** RENDER HELPERS *****/
    const renderCategoryCards = () => {
        function mapKnowledgeBaseCategory(category) {
            return {
                active: support_knowledge_base_category?.title === category.title,
                title: category.title,
                icon: category.icon,
                action: () => {
                    if (url !== category.link) {
                        history.push(category.link);
                    }
                }
            };
        }

        return (
            <div className="supportCentre__cardsWrapper">
                <CardGroup className="supportCentre__cards" cardObject={support_knowledge_base_data.map(mapKnowledgeBaseCategory)} />
            </div>
        );
    };

    const renderTopSection = () => {
        return (
            <div className="supportCentre__container">
                {!['sm', 'xs'].includes(app_viewport) ? (
                    <Particles className="supportCentre__background" id="tsparticles" init={particlesInit} options={particleParams} />
                ) : (
                    ''
                )}
                <div className="supportCentre__intro">
                    <h1>How can we help?</h1>
                    <h3>Let us know what you are looking for below.</h3>
                    <Search
                        className="supportCentre__search"
                        onClickAway={() => setShowSearchResults(false)}
                        onClickSearchInput={() => setShowSearchResults(true)}
                        render={{
                            status: showSearchResults ? 'success' : null,
                            placeholder: 'eg How do I renew my domain name',
                            list: renderSearchList()
                        }}
                        functions={{
                            search: searchArticles,
                            reset: resetSearch
                        }}
                    />
                </div>
                {support_knowledge_base_status === 'success' ? renderCategoryCards() : <div className="supportCentre__cards" />}
            </div>
        );
    };

    const renderActiveArticle = () => {
        if (support_knowledge_base_article) {
            const { post_title, post_date, post_content } = support_knowledge_base_article;

            const transform = {
                table: (props) => (
                    <div className="transformedTable">
                        <table {...props}></table>
                    </div>
                )
            };

            return (
                <div className="activeArticle">
                    <div className="activeArticle__nav">
                        <Anchor to="/support/support-centre">Knowledge Base Home</Anchor>
                        <div>/</div>
                        <Anchor to={support_knowledge_base_category.link}>{support_knowledge_base_category.title}</Anchor>
                        {support_knowledge_base_sub_category ? (
                            <>
                                <div>/</div>
                                <Anchor to={support_knowledge_base_sub_category.link}>{support_knowledge_base_sub_category.title}</Anchor>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="activeArticle__post">
                        <div className="activeArticle__title">{post_title}</div>
                        <div className="activeArticle__date">{post_date}</div>
                        <div className="activeArticle__content">{htmr(post_content, { transform })}</div>
                    </div>
                </div>
            );
        }
        return '';
    };

    const getCategoryList = () =>
        support_knowledge_base_data.map((category, index) => <Category key={index} className="knowledgeBase__category" category={category} />);

    const renderKnowledgeBaseList = () => {
        switch (support_knowledge_base_status) {
            case 'success':
                return (
                    <>
                        {renderActiveArticle()}
                        <div className="knowledgeBase__list">{getCategoryList()}</div>
                    </>
                );
            case 'error':
            default:
                return <FetchComponentError />;
        }
    };

    const renderSupportCentreFooter = () => {
        const otherMethodsIntro = "Can't find the answers you're looking for? Check out these other methods of support!";

        return (
            <div ref={otherMethodsRef} className="otherMethods">
                {otherMethodsIntro}
                <div className="otherMethods__intro"></div>
                <div className="otherMethods__container">
                    <Anchor to="/support/tickets/submit" className="otherMethods__method">
                        <i className="otherMethods__icon icon icon-eticket" />
                        <div className="otherMethods__methodName">Submit a new {ticket_name}</div>
                    </Anchor>
                    <Anchor href={`tel:${support_number}`} className="otherMethods__method">
                        <i className="otherMethods__icon icon icon-phone1" />
                        <div className="otherMethods__methodName">Give us a call on {support_number}</div>
                    </Anchor>
                    <Anchor href={service_status} target="_blank" className="otherMethods__method">
                        <i className="otherMethods__icon icon icon-service-status" />
                        <div className="otherMethods__methodName">See our Service Status</div>
                    </Anchor>
                    {company === 'VentraIP Australia' && (
                        <Anchor
                            onClick={() => dispatch(changeLivechatVisibility(livechatVisibilityOptions.MAXIMIZED))}
                            className="otherMethods__method"
                        >
                            <i className="otherMethods__icon icon icon-online-sms" />
                            <div className="otherMethods__methodName">Live Chat</div>
                        </Anchor>
                    )}
                </div>
            </div>
        );
    };

    const renderBottomSection = () => {
        return (
            <div className="supportCentre__bottom" ref={knowledgeBaseRef}>
                <div className="knowledgeBase">
                    <div className="knowledgeBase__column">
                        <h2>Knowledge Base</h2>
                        <p>Find solutions to the most common technical, account and product related enquiries.</p>
                        <OutlineButton color="primary" to="/support/tickets/submit">
                            Submit a new {ticket_name}
                        </OutlineButton>
                        <Anchor className="action__link" onClick={() => scrollToRef(otherMethodsRef?.current)}>
                            Other methods of support
                        </Anchor>
                    </div>
                    <div className="knowledgeBase__column">{renderKnowledgeBaseList()}</div>
                </div>
                {renderSupportCentreFooter()}
            </div>
        );
    };

    const renderPage = () => {
        return (
            <div className="supportCentre">
                {support_knowledge_base_status === 'success' ? renderTopSection() : ''}
                {renderBottomSection()}
            </div>
        );
    };

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    switch (support_knowledge_base_status) {
        case 'success':
        case 'error':
            return renderPage();
        case 'loading':
        default:
            return <RequestLoader />;
    }
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    app_viewport: state.app.app_viewport,
    support_knowledge_base_status: state.support.support_knowledge_base_status,
    support_knowledge_base_data: state.support.support_knowledge_base_data,
    support_knowledge_base_all_posts_data: state.support.support_knowledge_base_all_posts_data,
    support_knowledge_base_category: state.support.support_knowledge_base_category,
    support_knowledge_base_sub_category: state.support.support_knowledge_base_sub_category,
    support_knowledge_base_article: state.support.support_knowledge_base_article,
    support_knowledge_base_category_refs: state.support.support_knowledge_base_category_refs
});

KnowledgeBase = withRouter(KnowledgeBase);

KnowledgeBase = connect(mapStateToProps)(KnowledgeBase);

export default KnowledgeBase;
