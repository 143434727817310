/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/types';

export const typedGet = <T extends object, K extends NXUtils.Path<T>>(obj: T, key: K): NXUtils.Choose<T, K> => _.get(obj, key) as unknown as any;
export const typedSet = <T extends object, K extends NXUtils.Path<T>>(obj: T, key: K, value: NXUtils.Choose<T, K>) =>
    _.set(obj, key, value) as unknown as any;

export const typedSetClone = <T extends object, K extends NXUtils.Path<T>>(obj: T, key: K, value: NXUtils.Choose<T, K>) =>
    _.set(_.cloneDeep(obj), key, value);
