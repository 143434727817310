/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContactForm from '../forms/contact';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { addContact, getContactsList, removeContact, updateContact } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AccountContacts extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = createRef();
        this.state = {
            contactMatrix: [],
            showContactLightbox: false,
            showContactMode: 'Add',
            showContactData: undefined,
            showRemoveLightbox: false
        };
        this.openContactLightbox = this.openContactLightbox.bind(this);
        this.closeContactLightbox = this.closeContactLightbox.bind(this);
        this.openRemoveLightbox = this.openRemoveLightbox.bind(this);
        this.closeRemoveLightbox = this.closeRemoveLightbox.bind(this);
        this.handleContactForm = this.handleContactForm.bind(this);
        this.handleRemoveContact = this.handleRemoveContact.bind(this);
    }

    openContactLightbox(mode, data) {
        this.setState({
            showContactLightbox: true,
            showContactMode: mode,
            showContactData: data
        });
    }

    closeContactLightbox() {
        this.setState({
            showContactLightbox: false,
            showContactMode: 'Add',
            showContactData: undefined
        });
    }

    openRemoveLightbox(data) {
        this.setState({
            showRemoveLightbox: true,
            showContactData: data
        });
    }

    closeRemoveLightbox() {
        this.setState({
            showRemoveLightbox: false,
            showContactData: undefined
        });
    }

    handleContactForm(values) {
        const { addContact, updateContact } = this.props;
        const { showContactMode, showContactData } = this.state;
        const attributes = {
            ...values,
            general_emails: values.general_emails ? values.general_emails : false,
            invoice_emails: values.invoice_emails ? values.invoice_emails : false,
            product_emails: values.product_emails ? values.product_emails : false
        };

        if (showContactMode === 'Add') {
            addContact(attributes);
        } else {
            updateContact(showContactData.id, attributes);
        }
    }

    handleRemoveContact() {
        const { removeContact } = this.props;
        const { showContactData } = this.state;
        removeContact(showContactData.id);
    }

    componentDidUpdate(prevProps) {
        const { closeContactLightbox, closeRemoveLightbox } = this;
        const {
            account_add_contact_status,
            account_contact_list_status,
            account_update_contact_status,
            account_remove_contact_status,
            getContactsList
        } = this.props;

        registerScrollEvents(this, account_contact_list_status === 'success' && prevProps.account_contact_list_status === 'loading');

        if (
            (prevProps.account_add_contact_status === 'loading' && account_add_contact_status === 'success') ||
            (prevProps.account_update_contact_status === 'loading' && account_update_contact_status === 'success')
        ) {
            getContactsList();
            closeContactLightbox();
        }

        if (prevProps.account_remove_contact_status === 'loading' && account_remove_contact_status === 'success') {
            getContactsList();
            closeRemoveLightbox();
        }
    }

    render() {
        const { account_contact_list_status, account_add_contact_status, account_remove_contact_status, account_contact_list_data, getContactsList } =
            this.props;
        const { showRemoveLightbox, showContactLightbox, showContactMode, showContactData } = this.state;
        const { openContactLightbox, closeContactLightbox, openRemoveLightbox, closeRemoveLightbox, handleContactForm, handleRemoveContact } = this;

        const getCommunications = (general_emails, invoice_emails, product_emails) => {
            let string = '';

            if (general_emails) {
                string += 'General &&';
            }
            if (invoice_emails) {
                string += 'Invoices &&';
            }
            if (product_emails) {
                string += 'Products &&';
            }

            if (string) {
                string = string.split(' &&').join(', ');
                string = string.slice(0, -2);
            }

            return string;
        };

        let contactMatrix = [];

        if (account_contact_list_data && account_contact_list_data.length > 0) {
            contactMatrix = Array(account_contact_list_data.length).fill();

            Object.keys(contactMatrix).forEach((value) => {
                const { attributes } = account_contact_list_data[value];
                const { email, firstname, lastname, general_emails, invoice_emails, product_emails } = attributes;

                contactMatrix[value] = {
                    Name: `${firstname} ${lastname}`,
                    Email: email,
                    Communications: getCommunications(general_emails, invoice_emails, product_emails),
                    actions: {
                        label: 'Edit',
                        type: 'onClick',
                        size: 'small',
                        color: 'primary',
                        button: 'outline',
                        onClick: (e) => {
                            e.preventDefault();
                            openContactLightbox('Edit', account_contact_list_data[value]);
                        },
                        list: [
                            <OutlineButton
                                key={0}
                                type="onClick"
                                color="warn"
                                onClick={() => {
                                    openRemoveLightbox(account_contact_list_data[value]);
                                }}
                            >
                                Delete
                            </OutlineButton>
                        ]
                    }
                };
            });
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountContacts"
            >
                <Box
                    request={{
                        action: getContactsList,
                        args: false,
                        status: account_contact_list_status
                    }}
                    className="accountContacts__box"
                    status={account_contact_list_status}
                    title="Contacts"
                    action={{
                        label: 'Add Contact',
                        type: 'onClick',
                        size: 'large',
                        color: 'primary',
                        onClick: () => {
                            openContactLightbox('Add');
                        }
                    }}
                    desc="Contacts can receive communications relating to your account such as invoices, announcements and notifications requiring your attention. Please note that they can not log into your account."
                    custom={{
                        render: (
                            <Table
                                header={[
                                    {
                                        title: `Name`,
                                        className: `name`
                                    },
                                    {
                                        title: `Email`,
                                        className: `email`
                                    },
                                    {
                                        title: `Communications`,
                                        className: `communications`
                                    },
                                    {
                                        action: true
                                    }
                                ]}
                                matrix={contactMatrix}
                                stacked={true}
                                embedded={true}
                                error="You have no contacts on this account."
                                className="accountContacts__table"
                            />
                        ),
                        pos: 'bottom'
                    }}
                />
                {showContactLightbox ? (
                    <OverlayLightbox
                        title={`${showContactMode} Contact`}
                        loading={account_add_contact_status}
                        onOpen={showContactLightbox}
                        onClose={closeContactLightbox}
                        onSuccessClose={closeContactLightbox}
                    >
                        <ContactForm mode={showContactMode} selectedContact={showContactData} onSubmit={handleContactForm} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
                {showRemoveLightbox ? (
                    <OverlayLightbox
                        title="Remove Account Contact"
                        onOpen={showRemoveLightbox}
                        loading={account_remove_contact_status}
                        confirm={{
                            desc: htmr(
                                `Are you sure you want to remove ${showContactData.firstname} ${showContactData.lastname} as an account contact?`
                            ),
                            buttonText: 'Remove Contact',
                            buttonAction: handleRemoveContact,
                            closeText: 'No, I want to keep this contact',
                            closeAction: closeRemoveLightbox
                        }}
                        onClose={closeRemoveLightbox}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        account_contact_list_status: state.account.account_contact_list_status,
        account_contact_list_data: state.account.account_contact_list_data,
        account_add_contact_status: state.account.account_add_contact_status,
        account_update_contact_status: state.account.account_update_contact_status,
        account_remove_contact_status: state.account.account_remove_contact_status
    };
};

const mapDispatchToProps = {
    getContactsList,
    updateContact,
    removeContact,
    addContact
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountContacts));
