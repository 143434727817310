/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RadioButtonCircle from 'components/Form/RadioButtonCircle';
import { ValidationMessage } from 'components/Form/ValidationMessage';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RadioGroup.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated - Don't use class components, rebuild if using
 */
class RenderRadioGroup extends Component {
    render() {
        const {
            label,
            input,
            options,
            className,
            outlined,
            meta: { touched, error, warning, initial }
        } = this.props;
        const { value, name, onChange } = input;

        const renderRadio = (options) => {
            return options.map((item, index) => {
                return (
                    <button
                        type="button"
                        key={index}
                        className={`RadioGroup__option${item.value === value ? ' RadioGroup__option--selected' : ''}`}
                        onClick={() => {
                            onChange(item.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === ' ') {
                                e.preventDefault();
                                onChange(item.value);
                            }
                        }}
                    >
                        <div className="option__input">
                            <RadioButtonCircle isActive={item.value === value} color={outlined ? 'primary' : 'info'} />
                            <input
                                style={{ display: 'none' }}
                                type="radio"
                                value={item.value}
                                name={name}
                                checked={item.value === value}
                                readOnly={true}
                            />
                        </div>
                        <div className="option__label">
                            <div className="option__title">{item.label}</div>
                            {item.desc ? <div className="option__desc">{item.desc}</div> : ''}
                        </div>
                    </button>
                );
            });
        };

        return (
            <div className="form__item">
                {label ? <label>{label}</label> : ''}
                <div className={`RadioGroup${className ? ' ' + className : ''}${outlined ? ' RadioGroup--outlined' : ''}`}>
                    <div className="RadioGroup__container"> {renderRadio(options)} </div>
                </div>
                <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} initial={initial} />
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default RenderRadioGroup;
