/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { deleteGSuiteService } from '../action';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Cancel from 'components/Cancel';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteDelete extends Component {
    constructor(props) {
        super(props);
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
    }

    handleCancelSubmit(values) {
        const { gsuiteid, deleteGSuiteService, history } = this.props;
        const attributes = {
            ...values
        };
        deleteGSuiteService(gsuiteid, attributes, history);
    }

    componentDidMount() {
        registerScrollEvents(this, true);
    }

    render() {
        const { gsuiteid, gsuite_delete_mailbox_status } = this.props;
        const { handleCancelSubmit } = this;

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="gsuiteDeleteMailbox"
            >
                <Cancel
                    title="Delete Mailbox"
                    desc="If you no longer need your Google Workspace service, you can close your account. Please note that this will remove all Google Workspace accounts using this domain."
                    label="Cancel Service"
                    cancel={{
                        id: gsuiteid,
                        service: 'gsuite',
                        action: handleCancelSubmit,
                        loading: gsuite_delete_mailbox_status
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_delete_mailbox_status: state.gsuite.gsuite_delete_mailbox_status,
        gsuite_delete_mailbox_data: state.gsuite.gsuite_delete_mailbox_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteGSuiteService
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteDelete));
