/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { generateId } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { isValidElement, useEffect, useState } from 'react';
import { DEFAULT_TIMEOUT, TOAST_NOTIFICATION, TRANSITION_DURATION, notificationScopes, toastStates, toastStatuses } from './consts';
import { atomicState, pubSub } from './state';

/**
 * Generates a Proxy object that will publish a notification event when the object is updated.
 *
 * @param {import('./types').TAtomicToastState} obj
 */
const _generateToastItem = (obj) =>
    new Proxy(obj, {
        set(target, property, value) {
            target[property] = value;
            pubSub.publish(TOAST_NOTIFICATION, atomicState);
            return true;
        },
        deleteProperty(target, property) {
            delete target[property];
            pubSub.publish(TOAST_NOTIFICATION, atomicState);
            return true;
        }
    });

/**
 * helper function to safely interact with the toast state
 *
 * @type {import('./types').TSafelyGetNotification}
 */
export const safelyGetNotification = (identifier, callback) => {
    const index = atomicState.findIndex(({ identifier: id }) => id === identifier);
    if (index === -1) return;

    callback(atomicState[index]);
};

/**
 * Function to push a notification to the toast queue. This can be called from anywhere in the app and will be displayed
 * in the toast component.
 *
 * @type {import('./types').TPushNotification}
 */
export const pushNotification = (notification, timeout = DEFAULT_TIMEOUT, scope = notificationScopes.USER, devMode = false) => {
    const isAllowedNotification = () => {
        const userIsLoggedIn = !!store.getState().app.app_user_data;

        switch (true) {
            case scope === notificationScopes.GLOBAL:
            case scope === notificationScopes.USER && userIsLoggedIn:
            case scope === notificationScopes.GUEST && !userIsLoggedIn:
            case devMode && import.meta.env.MODE === 'development':
                return true;
            default:
                return false;
        }
    };

    // Do not push the notification if the user is not allowed to see it
    if (!isAllowedNotification()) {
        return;
    }

    const getStatus = (/** @type {number | null} */ status) => {
        if (status === null) {
            return toastStatuses.INFO;
        }

        switch (true) {
            case status >= 200 && status < 300:
                return toastStatuses.SUCCESS;
            case status >= 300 && status < 400:
                return toastStatuses.NOTICE;
            case status >= 400 && status < 600:
                return toastStatuses.WARN;
            default:
                return toastStatuses.INFO;
        }
    };

    /**
     * Type Predicate to ensure that the notification object is valid
     *
     * @type {import('./types').TIsNotificationReactNode}
     */
    const isNotificationReactNode = (obj) => isValidElement(obj);

    /**
     * Type Predicate to ensure that the notification object is valid
     *
     * Returns whether the notification matches TObjectNotification
     *
     * @type {import('./types').TIsNotificationDetailsAndStatus}
     */
    const isNotificationDetailsAndStatus = (obj) => {
        // verify it is an object
        if (typeof obj !== 'object') {
            return false;
        }
        if (!obj) {
            return false;
        }

        // verify that it contains the required properties
        if (!('details' in obj)) {
            return false;
        }
        if (!('status' in obj)) {
            return false;
        }

        const { details, status } = obj;

        // verify that the properties are of the correct type
        if (!details || !status) {
            return false;
        }
        if (typeof details !== 'string' && !isNotificationReactNode(details)) {
            return false;
        }
        if (typeof status !== 'number') {
            return false;
        }

        return true;
    };

    /**
     * Type Predicate to ensure that the notification object is valid
     *
     * Returns whether the notification matches TObjectNotification
     *
     * @type {import('./types').TIsNotificationMessageAndStatus}
     */
    const isNotificationMessageAndStatus = (obj) => {
        // verify it is an object
        if (typeof obj !== 'object') {
            return false;
        }
        if (!obj) {
            return false;
        }

        // verify that it contains the required properties
        if (!('message' in obj)) {
            return false;
        }
        if (!('status' in obj)) {
            return false;
        }

        const { message, status } = obj;

        // verify that the properties are of the correct type
        if (!message || !status) {
            return false;
        }
        if (typeof message !== 'string' && !isNotificationReactNode(message)) {
            return false;
        }
        if (typeof status !== 'number') {
            return false;
        }

        return true;
    };

    const generalProperties = /** @type {const} */ ({
        time: timeout ?? DEFAULT_TIMEOUT,
        identifier: generateId(),
        active: 'inactive'
    });

    if (isNotificationDetailsAndStatus(notification) || isNotificationMessageAndStatus(notification)) {
        // if the notification is a valid object, push it to the queue
        return void atomicState.push(
            _generateToastItem({
                ...notification,
                ...generalProperties,
                status: getStatus(notification.status)
            })
        );
    }

    if (typeof notification === 'string' || isNotificationReactNode(notification)) {
        // if the notification is a string, push it to the queue
        return void atomicState.push(
            _generateToastItem({
                status: getStatus(null),
                details: notification,
                ...generalProperties
            })
        );
    }

    // if the notification is not a valid object or a string, push a default notification to the queue
    return void atomicState.push(
        _generateToastItem({
            status: getStatus(null),
            details: 'It looks like something went wrong. Please try again later or contact our support team.',
            ...generalProperties
        })
    );
};

/** @type {import('./types').TPullNotification} */
export const pullNotification = (identifier) => {
    safelyGetNotification(identifier, (notification) => {
        notification.active = toastStates.EXITING;

        setTimeout(() => {
            safelyGetNotification(identifier, (notification) => {
                atomicState.splice(atomicState.indexOf(notification), 1);
            });
        }, TRANSITION_DURATION);
    });
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useNotifications = () => {
    const [notifications, setNotifications] = useState(atomicState);

    useEffect(() => {
        pubSub.subscribe(TOAST_NOTIFICATION, setNotifications);

        return () => pubSub.unsubscribe(TOAST_NOTIFICATION, setNotifications);
    }, []);

    return notifications;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
