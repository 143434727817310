/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import { FormItem } from 'components/Form/FormItem';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @typedef {{
 *      options: Array<Omit<import('components/Dropdowns/SelectDropdown/types').NSelectDropDown.Option, 'onClick'>>;
 *      className?: string;
 *      label?: string;
 *      disabled?: boolean;
 *      noSelectionLabel?: string;
 * }} SelectDropdownFieldTypeDef
 */

/**
 * @param {SelectDropdownFieldTypeDef & import('redux-form').WrappedFieldProps} props
 */
function SelectDropdownField({ options, className, label, disabled, noSelectionLabel = 'Please Select', input }) {
    const { value, onChange } = input;

    /***** FUNCTIONS *****/
    /** @param {Omit<import('components/Dropdowns/SelectDropdown/types').NSelectDropDown.Option, 'onClick'>} option */
    function handleClick(option) {
        onChange(option.value);
    }

    /***** RENDER HELPERS *****/
    const foundOption = options.find((option) => option.value === value);
    const preselectedOptionIndex = foundOption ? options.indexOf(foundOption) : null;
    const newOptions = useMemo(() => options.map((option) => ({ ...option, onClick: () => handleClick(option) })), [options]);

    /***** RENDER *****/
    return (
        <FormItem className={className} disabled={disabled}>
            <FormLabel htmlFor={input.name}>{label}</FormLabel>
            <SelectDropdown
                preselectedOptionIndex={preselectedOptionIndex}
                className={className}
                disabled={disabled}
                noSelectionLabel={noSelectionLabel}
                options={newOptions}
            />
        </FormItem>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SelectDropdownField;
