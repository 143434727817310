/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PropertiesRenderer } from 'containers/katana/components/dynamicFormFieldRenderer/propertiesRenderer';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RecursiveContextProvider } from 'utilities/hooks/useRecursiveContext';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { DynamicFormFieldRendererContextValues } from 'containers/katana/components/dynamicFormFieldRenderer/consts';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DynamicFormFieldRenderer: React.FC<{
    properties: KatanaNamespace.SectionDefinitions.Values['properties'];
}> = (props) => {
    const { properties } = props;
    /***** RENDER *****/
    return (
        <RecursiveContextProvider value={DynamicFormFieldRendererContextValues}>
            <PropertiesRenderer properties={properties} />
        </RecursiveContextProvider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DynamicFormFieldRenderer;
