import { recovery_email } from 'config/config';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import RenderPhoneField from 'components/Form/PhoneInput';
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import { TextArea } from 'components/Form/TextArea';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { getSecurityQuestions } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import {
    RenderField,
    RenderSelectField,
    dateValidation,
    domainFieldValidation,
    emailFieldValidation,
    generatePostcodeValidation,
    peopleNameFieldValidation,
    renderButton,
    requiredFieldValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   VALIDATION
 **********************************************************************************************************/
const validate = (values) => {
    const errors = {};
    if (!values.twofactorcode) {
        errors.twofactorcode = 'Required';
    }

    return errors;
};

const postcodeValidation = generatePostcodeValidation();

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class LoginRecovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            security: false
        };
    }

    componentDidMount() {
        const { getSecurityQuestions, login_security_questions_data } = this.props;

        if (login_security_questions_data) {
            this.setState({
                security: login_security_questions_data
            });
        } else {
            getSecurityQuestions();
        }
    }

    componentDidUpdate(prevProps) {
        const { login_security_questions_status, login_security_questions_data } = this.props;

        if (prevProps.login_security_questions_status === 'loading' && login_security_questions_status === 'success') {
            this.setState({
                security: login_security_questions_data
            });
        }
    }
    render() {
        const { handleSubmit, navigation, pristine, submitting, valid, login_recovery_manual_status } = this.props;
        const { security } = this.state;

        const renderSecurityOptions = () => {
            if (security) {
                const options = () => {
                    return security.map((item) => {
                        const { attributes, id } = item;
                        const { question } = attributes;
                        return {
                            label: question,
                            value: id
                        };
                    });
                };
                let securityOptions = [
                    {
                        label: 'Please select',
                        value: ''
                    }
                ];
                securityOptions = securityOptions.concat(options());
                return (
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <Field
                                label="Security Question"
                                name="security_question_id"
                                component={RenderSelectField}
                                validate={[requiredFieldValidation]}
                                type="select"
                                className="form__dropdown"
                                options={securityOptions}
                            />
                        </div>
                    </div>
                );
            }

            return '';
        };

        return (
            <Fragment>
                <div className="login__title">
                    <div className="heading">Two-Factor Options</div>
                    <div className="description">
                        If you&apos;re having issues or simply cant access your two-factor code, select another way to sign in below
                    </div>
                </div>
                <form className="login__form backupForm" onSubmit={handleSubmit}>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Service Name (your domain name)"
                                name="service"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, domainFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Full Name"
                                name="name"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, peopleNameFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    {renderSecurityOptions()}
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Security Answer"
                                name="security_answer"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <Field
                                label="Date of Birth"
                                name="dob"
                                component={RenderDateTimePicker}
                                validate={[requiredFieldValidation, dateValidation]}
                                dateFormat="d/M/yyyy"
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Mobile Number"
                                name="phone"
                                component={RenderPhoneField}
                                required
                                type="tel"
                                validate={[requiredFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Postcode"
                                name="postcode"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, postcodeValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="An email address we can currently contact you on"
                                name="contact_email"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, emailFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field label="Message (optional)" name="message" component={TextArea.ReduxForm.Default} className="form__textfield" />
                        </div>
                    </div>
                    {renderButton(
                        pristine,
                        submitting,
                        valid && login_recovery_manual_status !== 'loading',
                        login_recovery_manual_status === 'loading' ? <RequestLoader /> : 'Submit'
                    )}

                    <div className="login__link-container">
                        <Anchor
                            className="login__link"
                            onClick={(e) => {
                                e.preventDefault();
                                navigation('login');
                            }}
                        >
                            Back to Login
                        </Anchor>
                    </div>

                    <Box
                        premounted={true}
                        className="contactRecovery"
                        title={`Can't fill out this form?`}
                        desc="If you cannot answer the security questions, but still need access to the account, please contact our Recovery team via email outlining your situation, and they will be in touch with you soon."
                        bottom={true}
                        columns={[
                            {
                                render: (
                                    <div className="sharedBox__infoColumn hasIcon">
                                        <a className="contactRecovery__link" href={`mailto:${recovery_email}`}>
                                            <span className="icon icon-email"></span>
                                            <span className="text">{recovery_email}</span>
                                        </a>
                                    </div>
                                )
                            }
                        ]}
                    />
                </form>
                <Anchor
                    className="login__link"
                    onClick={(e) => {
                        e.preventDefault();
                        navigation('2fa');
                    }}
                >
                    Go back a step
                </Anchor>
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
LoginRecovery = reduxForm({
    form: 'recoveryForm',
    validate
})(LoginRecovery);

const mapStateToProps = (state) => {
    return {
        login_security_questions_status: state.login.login_security_questions_status,
        login_security_questions_data: state.login.login_security_questions_data,
        login_recovery_manual_status: state.login.login_recovery_manual_status
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getSecurityQuestions
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LoginRecovery);
