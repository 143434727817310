/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TPPDomainNameserversForm from 'containers/domain/tppDomains/forms/nameservers';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import OutlineButton from 'components/Buttons/OutlineButton';
import Text from 'components/Utils/Text';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import InactiveButton from 'components/Buttons/InactiveButton';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainDnsQuery } from 'containers/domain/tppDomains/queries/useTppdomainDnsQuery';
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { useTppDomainUpdateDnsMutation } from 'containers/domain/tppDomains/queries/mutations';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import useSearchParams from 'utilities/hooks/useSearchParams';
import { getIsLegacyDomainTransferLocked } from 'containers/domain/tppDomains/modules/transferLock/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_tppDomainNameservers.scss';
import Icons from 'components/Icons';
const nameserversFormLightboxParam = 'update-legacy-domain-nameservers';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain nameservers module
 */
function TPPDomainNameservers({ path }) {
    /***** HOOKS *****/
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const { searchParamsGet, searchParamsRemove } = useSearchParams();
    const scrollRef = useRef(null);

    /***** QUERIES *****/
    const { data: tpp_domain_dns_data, status: tpp_domain_dns_status, isFetching: isTppDomainDnsFetching } = useTppDomainDnsQuery(params.id);
    const { data: tpp_domain_transfer_lock_data } = useTppDomainTransferLockQuery(params.id);
    const { mutate: mutateTppDomainUpdateDns, isPending: isTppDomainUpdateDnsLoading } = useTppDomainUpdateDnsMutation();

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props: { path, location }, scrollRef: scrollRef.current }, tpp_domain_dns_status === 'success');
    }, [tpp_domain_dns_status]);

    /***** FUNCTIONS *****/
    function handleUpdateNameservers(values) {
        mutateTppDomainUpdateDns(
            {
                domainId: params.id,
                newDns: { nameservers: values.nameservers.map((nameserverFormField) => nameserverFormField.record) }
            },
            {
                onSuccess: () => searchParamsRemove(nameserversFormLightboxParam)
            }
        );
    }

    /***** RENDER *****/
    return (
        <section ref={scrollRef} className="tppDomainNameservers">
            <NXBox initialStatus={tpp_domain_dns_status === 'pending' ? 'loading' : tpp_domain_dns_status} isLoading={isTppDomainDnsFetching}>
                {(() => {
                    if (!tpp_domain_dns_data) return '';

                    const nameservers = Array.isArray(tpp_domain_dns_data.attributes?.nameserver) ? tpp_domain_dns_data.attributes.nameserver : [];
                    const initialValues = { nameservers: nameservers.map((nameserver) => ({ record: nameserver })) };
                    const isTransferLocked = getIsLegacyDomainTransferLocked(tpp_domain_transfer_lock_data);

                    function getButton() {
                        if (isTransferLocked) {
                            return (
                                <HoverTooltip key={2} content="Domain nameservers cannot currently be edited as the domain is transfer locked.">
                                    <InactiveButton>
                                        <Icons.Lock />
                                        Edit
                                    </InactiveButton>
                                </HoverTooltip>
                            );
                        }

                        return (
                            <OutlineButton
                                key={2}
                                color="secondary"
                                to={`/my-services/domains/legacy/general/nameservers/${params.id}?${nameserversFormLightboxParam}=true`}
                            >
                                Edit
                            </OutlineButton>
                        );
                    }

                    return (
                        <>
                            <NXBox.Top
                                title="Domain Nameservers"
                                description="Domain Nameservers will direct all traffic for your domain name to the services designated on those Nameservers."
                            />
                            <NXBox.Bottom>
                                <NXBox.BottomColumns
                                    columns={[
                                        <div key={1}>
                                            <Text secondary size--xs bold uppercase>
                                                Nameservers
                                            </Text>
                                            <div className="tppDomainNameservers__records">
                                                {nameservers.map((record, index) => (
                                                    <Text key={`${record}-${index}`} black size--xss medium>
                                                        {record}
                                                    </Text>
                                                ))}
                                            </div>
                                        </div>,
                                        getButton()
                                    ]}
                                />
                            </NXBox.Bottom>

                            {searchParamsGet(nameserversFormLightboxParam) === 'true' ? (
                                <OverlayLightbox
                                    title="Update Domain Nameservers"
                                    onOpen={searchParamsGet(nameserversFormLightboxParam) === 'true'}
                                    onClose={() => history.push(`/my-services/domains/legacy/general/nameservers/${params.id}`)}
                                >
                                    <TPPDomainNameserversForm
                                        initialValues={initialValues}
                                        onSubmit={handleUpdateNameservers}
                                        isTppDomainUpdateDnsLoading={isTppDomainUpdateDnsLoading}
                                    />
                                </OverlayLightbox>
                            ) : (
                                ''
                            )}
                        </>
                    );
                })()}
            </NXBox>
        </section>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainNameservers;
