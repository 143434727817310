/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Carousel from 'components/Carousel';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_vipRewardsMoreInfoTiersCarousel.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsMoreInfoTiersCarousel({ slides }) {
    /***** RENDER *****/
    return (
        <div className="vipRewardsMoreInfoTiersCarousel">
            <Carousel slides={slides} />
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
VipRewardsMoreInfoTiersCarousel.propTypes = {
    /**
     * Slides to pass to the carousel
     */
    slides: PropTypes.arrayOf(PropTypes.node)
};

export default VipRewardsMoreInfoTiersCarousel;
