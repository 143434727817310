/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { validatorDomainNoSubdomain } from '../..';

/**********************************************************************************************************
 *   START ZOD REFINERS
 **********************************************************************************************************/
export const zodRefinerDomainNoSubdomain = {
    function: (value) => validatorDomainNoSubdomain(value),
    options: {
        message: (
            <>
                Please enter a valid primary domain. An example of a valid domain would be: &apos;yourbusiness.com&apos;. If you are trying to set up
                a subdomain please&nbsp;<Anchor href="https://ventraip.com.au/faq/article/creating-a-sub-domain-in-cpanel/">refer to this FAQ</Anchor>
            </>
        )
    }
};
