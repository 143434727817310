/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Switch from 'components/Switch';
import SolidTag from 'components/Tags/SolidTag';

/*   ACTIONS
 *****************************************************/
import { ControlledCheckbox } from 'utilities/methods/form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { getCurrentDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPES DEFINITIONS
 **********************************************************************************************************/
type TFreeDomainClaimForm = {
    autoRenew: boolean;
    domain: string;
    faded: boolean;
    idProtection: boolean;
    register: boolean;
    toggleAutoRenew: () => void;
    toggleIdProtection: () => void;
    toggleRegister: () => void;
    value: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function FreeDomainClaimForm({
    domain,
    value,
    autoRenew,
    toggleAutoRenew,
    idProtection,
    toggleIdProtection,
    register,
    toggleRegister,
    faded
}: TFreeDomainClaimForm) {
    /***** RENDER HELPERS *****/
    const renderRegisterControl = () => {
        return (
            <ControlledCheckbox
                noBackground
                label={<div className="domainRenewClaim__toggleText">Register</div>}
                checked={register}
                onClick={toggleRegister}
            />
        );
    };

    const freeUntilDate = getCurrentDate().plus({ years: 1 }).toFormat('LLL yyyy');

    /***** RENDER *****/
    return (
        <div className={classNames('domainRenewClaim')}>
            <div className="domainRenewClaim__container">
                <div>
                    <p className="domainRenewClaim__domain">{domain}</p>
                    <div className="domainRenewClaim__info">
                        <SolidTag color="primary">FREE until {freeUntilDate}</SolidTag>
                        <Text size--xss secondary>
                            renews at ${value}
                        </Text>
                    </div>
                </div>
                <div className="domainRenewClaim__fields">
                    <Switch label="Auto Renew" switchvalue={autoRenew ? '1' : '0'} onClick={toggleAutoRenew} disabled={faded} />
                    <Switch label="ID Protection" switchvalue={idProtection ? '1' : '0'} onClick={toggleIdProtection} disabled={faded} />
                    {renderRegisterControl()}
                </div>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default FreeDomainClaimForm;
