/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RenderVPSPasswordField } from 'containers/vps';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import OutlineButton from 'components/Buttons/OutlineButton';
import Clipboard from 'components/Clipboard';
import DialogNotification from 'components/Notifications/DialogNotification';
import NXBox from 'components/NXBox';

/*   ACTIONS
 *****************************************************/
import { changeVNCPassword, getVNCInfo } from 'containers/vps/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let VNCAccess = (props) => {
    const {
        /** Redux State */
        changeVNCPassword,
        getVNCInfo,
        vps_change_vnc_password_status,
        vps_information_data,
        vps_power_state_data,
        vps_power_state_status,
        vps_vnc_information_data,
        vps_vnc_information_status
    } = props;

    /***** STATE *****/
    const [showPassword, setShowPassword] = useState(false);

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** FUNCTIONS *****/
    function togglePassword() {
        setShowPassword(!showPassword);
    }

    function handleChangePassword() {
        changeVNCPassword(vps_information_data.id);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, vps_vnc_information_status === 'success');
    }, [vps_vnc_information_status]);

    useEffect(() => {
        if (vps_change_vnc_password_status === 'success') getVNCInfo(vps_information_data.id);
    }, [vps_change_vnc_password_status]);

    /***** RENDER HELPERS *****/
    function renderInformation() {
        if (!vps_vnc_information_data) return '';

        if (vps_power_state_data?.state === 'shutdown')
            return <p className="VPS-VNCAccess__shutdown">Your VPS must be running to enable VNC access.</p>;

        const {
            attributes: { ip, password, port }
        } = vps_vnc_information_data;

        return (
            <Fragment>
                <div className="VPS-VNCAccess__table">
                    <div className="VPS-VNCAccess__table-row VPS-VNCAccess__table-row--odd">
                        <div className="VPS-VNCAccess__table-column VPS-VNCAccess__table-column--label">VNC IP</div>
                        <div className="VPS-VNCAccess__table-column VPS-VNCAccess__table-column--value">{ip}</div>
                    </div>
                    <div className="VPS-VNCAccess__table-row">
                        <div className="VPS-VNCAccess__table-column VPS-VNCAccess__table-column--label">VNC Port</div>
                        <div className="VPS-VNCAccess__table-column VPS-VNCAccess__table-column--value">{port}</div>
                    </div>
                    <div className="VPS-VNCAccess__table-row VPS-VNCAccess__table-row--odd">
                        <div className="VPS-VNCAccess__table-column VPS-VNCAccess__table-column--label">VNC Password</div>
                        <div className="VPS-VNCAccess__table-column VPS-VNCAccess__table-column--value">
                            <RenderVPSPasswordField
                                showPassword={showPassword}
                                password={password}
                                toggle={togglePassword}
                                className="VPS-VNCAccess__passwordfield"
                            />
                            <Clipboard value={password} className="VPS-VNCAccess__clipboard" />
                        </div>
                    </div>
                </div>
                <div className="VPS-VNCAccess__button-wrapper">
                    <DialogNotification type="warning">
                        PLEASE NOTE: VNC password&apos;s are automatically generated. Also your VPS will require a manual reboot for the new VNC
                        Password to come into effect.
                    </DialogNotification>
                    <OutlineButton
                        className="VPS-VNCAccess__button"
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            handleChangePassword();
                        }}
                    >
                        Change Password
                    </OutlineButton>
                </div>
            </Fragment>
        );
    }

    const getLoadingStatus = () => {
        if ([vps_vnc_information_status, vps_change_vnc_password_status, vps_power_state_status].includes('loading')) return 'loading';
        if ([vps_vnc_information_status, vps_change_vnc_password_status].includes('error')) return 'error';
        return 'success';
    };

    /***** RENDER *****/
    return (
        <div ref={scrollRef}>
            <NXBox className="VPS-VNCAccess" initialStatus={getLoadingStatus()}>
                <NXBox.Top title="VNC Access" description="VNC access allows you to determine and troubleshoot problems with your VPS."></NXBox.Top>
                <NXBox.DefaultPadding>
                    <div>{renderInformation()}</div>
                </NXBox.DefaultPadding>

                <NXBox.Footer>
                    <Flex fullWidth justify="end">
                        <Text secondary size--s>
                            Need help? View our guide on
                        </Text>
                        <Anchor className="VPS-VNCAccess__footer-link" to="/support/support-centre/getting-started/setting-up-your-vnc-client">
                            Setting up your VNC client
                        </Anchor>
                    </Flex>
                </NXBox.Footer>
            </NXBox>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    vps_information_data: state.vps.vps_information_data,
    vps_vnc_information_status: state.vps.vps_vnc_information_status,
    vps_vnc_information_data: state.vps.vps_vnc_information_data,
    vps_change_vnc_password_status: state.vps.vps_change_vnc_password_status,
    vps_power_state_status: state.vps.vps_power_state_status,
    vps_power_state_data: state.vps.vps_power_state_data
});

const mapDispatchToProps = {
    getVNCInfo,
    changeVNCPassword
};

VNCAccess = connect(mapStateToProps, mapDispatchToProps)(VNCAccess);

VNCAccess = withRouter(VNCAccess);

export default VNCAccess;
