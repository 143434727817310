/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ReactChildrenProp } from 'utilities/consts';
import './__NXCard.scss';

const NXCardAppliedStylingPropTypes = /** @type {const} */ ({
    /**
     * Applies border to the NXCard
     */
    border: PropTypes.bool,

    /**
     * Shows a shadow for the card as if it's elevated.
     */
    elevated: PropTypes.bool,

    /**
     * Shows a thick border as if it's "highlighted" or "selected"
     */
    highlighted: PropTypes.bool,

    /**
     * Disables the card
     */
    disabled: PropTypes.bool,

    /**
     * On click function for the card, turns it into a button element
     */
    onClick: PropTypes.func
});

const NXCardAppliedStylingPropTypeKeys = Object.keys(NXCardAppliedStylingPropTypes);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      className?: string;
 *      children: React.ReactNode;
 *      onClick?: () => void;
 *      border?: boolean;
 * 		disabled?: boolean;
 *      elevated?: boolean;
 *      highlighted?: boolean;
 * }} props
 */
function _NXCard(props) {
    const { className, children, onClick, disabled } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: NXCardAppliedStylingPropTypeKeys,
        componentName: 'NXCard',
        delimiter: '--'
    });

    const nxCardClassNames = classNames('NXCard', appliedStylingClasses, className);

    const renderContent = () => <div className="NXCard__wrapper">{children}</div>;

    /***** RENDER *****/
    if (onClick)
        return (
            <button type="button" onClick={disabled ? null : onClick} className={nxCardClassNames}>
                {renderContent()}
            </button>
        );

    return <div className={nxCardClassNames}>{renderContent()}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

_NXCard.propTypes = {
    /**
     * Class Names
     */
    className: PropTypes.string,

    /**
     * React Children
     */
    children: ReactChildrenProp,

    ...NXCardAppliedStylingPropTypes
};

export { _NXCard };
