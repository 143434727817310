/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ServiceSelectItemNotAllowed from '../serviceSelectItemNotAllowed';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DomainSearchList = ({
    selectedServices,
    resultItemOnClick,
    servicesFilter,
    disabledServices,
    /**
     * Redux Props
     */
    domain_search_data
}) => {
    /***** FUNCTIONS *****/
    function getFilteredDomainSearchMap() {
        const filteredDomainSearchMap = domain_search_data.filter((search_data_entry) => {
            const foundSelectedService = selectedServices.find((selectedService) => selectedService.id === search_data_entry.id);
            return !foundSelectedService;
        });

        if (servicesFilter) {
            return filteredDomainSearchMap.filter(servicesFilter);
        }

        return filteredDomainSearchMap;
    }

    /***** RENDER *****/
    if (domain_search_data && domain_search_data.length > 0) {
        const filteredDomainSearchMap = getFilteredDomainSearchMap();

        return filteredDomainSearchMap.map((result, index) => {
            const { id, attributes } = result;
            const { domain } = attributes;

            function onResultItemClick() {
                resultItemOnClick(id);
            }

            const notAllowedMessage = disabledServices?.find(({ condition }) => condition(result));

            return (
                <ServiceSelectItemNotAllowed key={index} disabled={!!notAllowedMessage} content={notAllowedMessage?.content ?? null}>
                    <button className="result__item" onClick={notAllowedMessage ? null : onResultItemClick}>
                        <div className="result__title">{domain}</div>
                    </button>
                </ServiceSelectItemNotAllowed>
            );
        });
    }

    if (domain_search_data && domain_search_data.length <= 0) {
        return <div className="result__item--noresult">{`Couldn't find any matching domain names.`}</div>;
    }

    return <RequestLoader message="Fetching domains" />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DomainSearchList.propTypes = {
    /**
     * Array of the selected services.
     */
    selectedServices: PropTypes.arrayOf(PropTypes.object).isRequired,

    /**
     * Click method for the search list item to add the id to the list
     */
    resultItemOnClick: PropTypes.func,

    /**
     * filter function to remove services from the final selection list.
     */
    servicesFilter: PropTypes.func,

    /**
     * Will be "disabled" when trying to select for this tool
     */
    disabledServices: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The condition for the service.
             * Takes @param {object} service
             */
            condition: PropTypes.func.isRequired,
            /**
             * The content to render for the disabled services
             */
            content: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string])
        })
    )
};

const mapStateToProps = (state) => {
    return {
        domain_search_data: state.services.domain_search_data
    };
};

DomainSearchList = connect(mapStateToProps)(DomainSearchList);

export default DomainSearchList;
