/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SubAccountsList from '../modules/subaccounts';

/*   ACTIONS
 *****************************************************/
import { resetScrollEvents } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import '../styles/_resellerStyles.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Reseller extends Component {
    /************** LIFECYCLE METHODS **************/
    componentWillUnmount() {
        const { resetScrollEvents } = this.props;

        resetScrollEvents();
    }

    render() {
        const { hosting_information_data } = this.props;
        const { id } = hosting_information_data;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="hosting__reseller">
                <SubAccountsList path={`/my-services/hosting/reseller/subaccounts/${id}`} hostingid={id} />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            hosting_information_data: state.hosting.hosting_information_data
        }),
        {
            resetScrollEvents
        }
    )(Reseller)
);
