/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainRenewAutoDiscountDisplay from './autoDiscountDisplay';
import FreeDomainClaimForm from './freeDomainClaimForm';
import AutoRenewForm from './autoRenewForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DialogNotification from 'components/Notifications/DialogNotification';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import PricingTable from 'components/PricingTable';
import PromoCode from 'components/PromoCode';

/*   ACTIONS
 *****************************************************/
import { pluralize } from 'utilities/methods/commonActions';
import { RenderSelectField, renderButton, requiredFieldValidation } from 'utilities/methods/form';
import useFreeDomainClaim from './hooks/useFreeDomainClaim';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_domainRenewForm.scss';
import { application } from 'config/base.config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let RenewForm = ({
    submitRenew,
    isPremium,
    options,
    getRenewPricing,
    manualPromoCode,
    handleSubmit,
    submitting,
    // Redux props
    selectedYears,
    domain_renew_apply_promo_status,
    domain_renew_apply_promo_data,
    promotion_radix_renew_offers_data,
    domain_information_data,
    change
}) => {
    /***** STATE *****/
    const [prices, setPrices] = useState([]);
    const [autoRenew, setAutoRenew] = useState(null);
    const [isAutoRenewBoxOpen, setIsAutoRenewBoxOpen] = useState(false);

    /***** HOOKS *****/
    const { isNoFreeDomainSelected, freeDomainForms, setTlds, toggleFreeDomainValue } = useFreeDomainClaim();

    const selectedFreeDomains = freeDomainForms.filter((option) => option.register);

    /***** EFFECTS *****/
    // Create prices on mount
    useEffect(() => {
        if (!options) return;
        const prices = options.map(({ years, price }) => ({
            value: years,
            label: `${years} Year${pluralize(years)} - $${price} AUD`
        }));

        setPrices(prices);
        change('renewPeriod', 1);
    }, []);

    // Set list of tlds once radix request succeeds
    useEffect(() => {
        if (promotion_radix_renew_offers_data) {
            const { available } = promotion_radix_renew_offers_data;
            setTlds(Array.isArray(available) ? available : []);
        }
    }, [promotion_radix_renew_offers_data]);

    /***** FUNCTIONS ******/
    function handleAutoRenewCheck() {
        //if Domain does not have auto renew selected AND it is not a premium domain, open lightbox to suggest turning auto renew on
        if (!domain_information_data?.attributes.auto_renew && !isAutoRenewBoxOpen && !isPremium) {
            setIsAutoRenewBoxOpen(true);
            return;
        }

        //if domain already has auto renew selected OR the user selected to enable it via the lightbox, set "enable_auto_renew" to true, otherwise set it to false
        const enableAutoRenew = !!(domain_information_data?.attributes.autoRenew || autoRenew === 'enable');
        const newValues = { years: Number(selectedYears), enable_auto_renew: enableAutoRenew };

        if (selectedFreeDomains) {
            const domains = selectedFreeDomains.map((freeDomain) => {
                const { domain, autoRenew, idProtection } = freeDomain;
                return {
                    domain: domain,
                    auto_renew: autoRenew,
                    id_protect: idProtection,
                    promo_code: promotion_radix_renew_offers_data?.promo_code || ''
                };
            });

            newValues.meta = {
                post_renewal_registration: {
                    promo_code: 'RENVIPONLINE',
                    domains: domains
                }
            };
        }

        submitRenew(newValues);
        setIsAutoRenewBoxOpen(false);
    }

    /***** RENDER HELPERS *****/
    const isDiscount = domain_renew_apply_promo_data?.discount_amount && domain_renew_apply_promo_data.discount_amount !== '0.00';

    const renderPromoCode = () => {
        if (domain_information_data?.attributes?.is_premium) {
            return '';
        }

        const renderAutoAppliedMessage = () => {
            if (domain_renew_apply_promo_status === 'loading') {
                return <RequestLoader />;
            }

            if (manualPromoCode || !isDiscount) {
                return '';
            }

            return (
                <DomainRenewAutoDiscountDisplay
                    discountType={domain_renew_apply_promo_data?.discount_type}
                    discountValue={domain_renew_apply_promo_data?.discount_value}
                    isAppliedTagShowing
                />
            );
        };

        return (
            <>
                <PromoCode
                    isDisabled={isPremium}
                    customDisabledMessage="Coupon codes cannot be applied to premium domains."
                    onApply={(promoCodeValue) => getRenewPricing(promoCodeValue)}
                    isLoading={false}
                    removeAppliedCode={() => getRenewPricing()}
                    appliedPromoCode={manualPromoCode ? domain_renew_apply_promo_data?.discount_code : null}
                    isRemoveable={true}
                />
                {renderAutoAppliedMessage()}
            </>
        );
    };

    const isMoreThanOneFreeDomainSelected = freeDomainForms.reduce((acca, currentValue) => acca + (currentValue?.register ? 1 : 0), 0) > 1;

    const renderDialogNotification = (index) => {
        //if one free domain is selected, render under that option.
        //if more than one free domain is seleceted, render at the bottom.
        if (
            (isMoreThanOneFreeDomainSelected && index === freeDomainForms.length - 1) ||
            (!isMoreThanOneFreeDomainSelected && freeDomainForms[index]?.register)
        ) {
            return (
                <DialogNotification type="warning" tail={{ pos: 'top' }}>
                    PLEASE NOTE: The contact details for the domain {isMoreThanOneFreeDomainSelected ? 'names' : 'name'} listed above will be taken
                    from your current account details. Once the domain has been registered you can change the contact details through your{' '}
                    {application} account.
                </DialogNotification>
            );
        }

        return '';
    };

    const renderFreeDomainForms = () => {
        if (freeDomainForms.length <= 0) return '';

        const isMultipleOptions = freeDomainForms.length !== 1;

        return (
            <div className="domainRenewClaimList">
                <div className="domainRenewClaimList__top">
                    <p className="domainRenewClaimList__desc">
                        Secure {isMultipleOptions ? 'different versions' : `the ${freeDomainForms[0].tld} version`} of your domain name for{' '}
                        <span>FREE!*</span>
                    </p>
                </div>

                {freeDomainForms.map((option, index) => {
                    const { tld, domain, price, autoRenew, idProtection, register } = option;

                    return (
                        <div key={domain}>
                            <FreeDomainClaimForm
                                domain={domain}
                                value={price}
                                autoRenew={autoRenew}
                                toggleAutoRenew={() => toggleFreeDomainValue(tld, 'autoRenew')}
                                idProtection={idProtection}
                                toggleIdProtection={() => toggleFreeDomainValue(tld, 'idProtection')}
                                register={register}
                                toggleRegister={() => toggleFreeDomainValue(tld, 'register')}
                                faded={isMultipleOptions && !register && !isNoFreeDomainSelected}
                            />
                            {renderDialogNotification(index)}
                        </div>
                    );
                })}

                <small className="domainRenewClaimList__tandc">
                    *Renewals are optional and the standard renewal cost will apply at the time of renewal. Applies to the first year on new standard
                    registrations only.
                    <br />
                    Please note the renewal {isMultipleOptions ? 'prices are' : 'price is'} subject to change.
                </small>
            </div>
        );
    };

    const renderPricingTable = () => {
        if (!selectedYears || !domain_renew_apply_promo_data) {
            return '';
        }

        const rows = [];

        selectedFreeDomains.forEach((option) => {
            if (option.tld) {
                rows.push({
                    label: `Free ${option.tld} domain name`,
                    amount: '$0.00'
                });
            }
        });

        if (isDiscount) {
            rows.push({
                label: 'Discount',
                amount: `-$${domain_renew_apply_promo_data.discount_amount} AUD`
            });
        }

        return (
            <PricingTable
                slim={true}
                total={{
                    label: 'Total Amount Due',
                    amount: `$${domain_renew_apply_promo_data.total} AUD`
                }}
                rows={rows}
            />
        );
    };

    /***** RENDER *****/
    if (domain_renew_apply_promo_status === 'error') {
        return <FetchComponentError />;
    }

    return (
        <>
            <form className="domainRenewForm" onSubmit={handleSubmit(handleAutoRenewCheck)}>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Renew Domain Name For"
                            name="renewPeriod"
                            component={RenderSelectField}
                            validate={[requiredFieldValidation]}
                            type="select"
                            className="form__dropdown"
                            options={prices}
                        />
                    </div>
                </div>
                {renderPromoCode()}
                {renderFreeDomainForms()}
                {renderPricingTable()}
                {renderButton(false, submitting, domain_renew_apply_promo_status !== 'loading', 'Generate Renewal Invoice', 'primary')}
            </form>
            <AutoRenewForm
                isOpen={isAutoRenewBoxOpen}
                autoRenew={autoRenew}
                setAutoRenew={setAutoRenew}
                handleAutoRenewCheck={handleAutoRenewCheck}
            />
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
RenewForm = reduxForm({
    form: 'domainRenewForm'
})(RenewForm);

RenewForm = connect(
    (state) => {
        const selector = formValueSelector('domainRenewForm');
        const selectedYears = selector(state, 'renewPeriod');

        return {
            form: 'domainRenewForm',
            selectedYears,
            domain_renew_apply_promo_status: state.domain.domain_renew_apply_promo_status,
            domain_renew_apply_promo_data: state.domain.domain_renew_apply_promo_data,
            promotion_radix_renew_offers_data: state.promotion.promotion_radix_renew_offers_data,
            domain_information_data: state.domain.domain_information_data
        };
    },
    {
        // applyRenewPromo,
        change
    }
)(RenewForm);

RenewForm.propTypes = {
    // submit handler for the domain renew request
    submitRenew: PropTypes.func,

    // is the domain a premium domain
    isPremium: PropTypes.bool,

    // list of renew length option
    options: PropTypes.array,

    // function to fetch the pricing including automatic or manual promos, if called with a value it will try to override auto promo code with the manual one
    getRenewPricing: PropTypes.func,

    // Info about the currently applied discount
    manualPromoCode: PropTypes.string
};

export default withRouter(RenewForm);
