/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { formatLuxonOrdinal, getCurrentDate, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

export const vpsServerTypes = /** @type {const} */ ({
    ASTRO: 'astro',
    SOLUS: 'souls'
});

export const vpsTypes = /** @type {const} */ ({
    FULLY_MANAGED: 'fully_managed_vps',
    SELF_MANAGED: 'self_managed_vps',
    VPS: 'vps'
});

/**********************************************************************************************************
 *   VPS
 **********************************************************************************************************/
export const vpsMethods = {
    /**
     * @param {Services.VPS.TListData['data'][number]} vpsInfoData
     * @returns {NXUtils.ValueOf<typeof vpsServerTypes>}
     */
    getVpsServerType(vpsInfoData) {
        const product = getIncludedObjBasedOnType(vpsInfoData?.included, 'product');
        const serverType = product?.attributes.server_type;

        if (typeof serverType === 'string' && serverType.toLowerCase().includes(vpsServerTypes.ASTRO)) return vpsServerTypes.ASTRO;
        return vpsServerTypes.SOLUS;
    }
};

export function getVpsType(productName) {
    if (typeof productName !== 'string') return vpsTypes.VPS;

    const strippedProductName = productName.replaceAll('-', '').toLowerCase();

    if (strippedProductName.includes('selfmanaged')) return vpsTypes.SELF_MANAGED;
    if (strippedProductName.includes('fullymanaged')) return vpsTypes.FULLY_MANAGED;
    return vpsTypes.VPS;
}

export const migrationDateOptions = () => {
    let currentDate = getCurrentDate();
    const range = 14;
    const rangeArray = [];

    for (let i = 0; i < range; i++) {
        currentDate = currentDate.plus({ days: 1 });
        const updatedLuxonDate = formatLuxonOrdinal(currentDate.toFormat('{d} MMMM yyyy'));
        const currentOption = {
            label: updatedLuxonDate,
            value: updatedLuxonDate
        };
        rangeArray.push(currentOption);
    }

    return rangeArray;
};
