/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import NXBox from 'components/NXBox';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SPLIT_INVOICE_PROP_TYPES } from '../consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SplittingInvoiceBox({ invoiceID }) {
    /***** RENDER *****/
    return (
        <NXBox>
            <NXBox.Top
                title={`Splitting invoice #${invoiceID}`}
                description="You can use this tool to split off products onto new invoices.  Move services onto a new invoice by clicking the Split button. The products left over will form a new invoice."
            />
            <div className="splitInvoiceView__tableHeader">
                <DialogNotification tail={{ pos: 'top' }} type="warning">
                    Please note PAID invoices cannot be split and it is not possible for new services to be split - this can only be done for services
                    that are renewable.
                </DialogNotification>
            </div>

            <div className="splitInvoiceView__tableLinkWrapper">
                <Anchor to="/billing/invoices">GO BACK</Anchor>
            </div>
        </NXBox>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SplittingInvoiceBox.propTypes = {
    invoiceID: SPLIT_INVOICE_PROP_TYPES.invoiceID
};

export default SplittingInvoiceBox;
