const primitive = {
    'P_color_grey_20': '#fbfcfc',
    'P_color_grey_50': '#f5f7f8',
    'P_color_grey_60': '#f2f2f2',
    'P_color_grey_80': '#eaeaea',
    'P_color_grey_100': '#f5f6f7',
    'P_color_grey_125': '#f0f1f3',
    'P_color_grey_150': '#eff2f4',
    'P_color_grey_200': '#d7d7d7',
    'P_color_grey_220': '#cfd8dc',
    'P_color_grey_230': '#bcc4c7',
    'P_color_grey_250': '#c8d3d9',
    'P_color_grey_300': '#bebebe',
    'P_color_grey_400': '#abb2ba',
    'P_color_grey_450': '#919ba7',
    'P_color_grey_500': '#6c7a89',
    'P_color_grey_550': '#6e7076',
    'P_color_grey_580': '#55575c',
    'P_color_grey_600': '#677482',
    'P_color_grey_700': '#5c6875',
    'P_color_orange_500': '#fcae57',
    'P_color_orange_600': '#f69122',
    'P_color_purple_50': '#fdf7fa',
    'P_color_purple_100': '#fff4f9',
    'P_color_purple_200': '#f3d4e7',
    'P_color_purple_300': '#eab4d5',
    'P_color_purple_500': '#c32888',
    'P_color_purple_600': '#b52578',
    'P_color_white': '#ffffff',
    'P_color_transparent': 'transparent',
    'P_color_black': '#343636',
    'P_color_blue-black': '#2e3a4a',
    'P_color_skyBlue_400': '#54a9ef',
    'P_color_skyBlue_450': '#5dadf0',
    'P_color_skyBlue_500': '#2c98f0',
    'P_color_green_500': '#2bce78',
    'P_color_green_600': '#31b971',
    'P_color_red_500': '#fe7274',
    'P_color_red_600': '#eb5557',
    'P_color_lightBlue_200': '#f0f7ff',
    'P_border-radius_2': '2px',
    'P_border-radius_3': '3px',
    'P_border-radius_5': '5px',
    'P_border-radius_6': '6px',
    'P_border-radius_15': '15px',
    'P_border-radius_50': '50px',
    'P_padding_0': '0px',
    'P_margin_0': '0px',
    'P_box-shadow_white': '-6px 6px 12px #e7e4e3, 6px -6px 12px #fffcfb'
};

const semantic = {
    'S_color_background_primary_base': primitive['P_color_purple_500'],
    'S_color_background_primary_hover': primitive['P_color_purple_600'],
    'S_color_background_secondary_base': primitive['P_color_grey_500'],
    'S_color_background_secondary_hover': primitive['P_color_grey_600'],
    'S_color_background_secondary_light': primitive.P_color_grey_50,
    'S_color_background_secondary-alt_base': primitive.P_color_grey_220,
    'S_color_background_secondary-alt_hover': primitive.P_color_grey_230,
    'S_color_background_tertiary_base': primitive['P_color_white'],
    'S_color_background_tertiary_hover': primitive.P_color_white,
    'S_color_background_confirm_base': primitive['P_color_green_500'],
    'S_color_background_confirm_hover': primitive['P_color_green_600'],
    'S_color_background_notice_base': primitive['P_color_orange_500'],
    'S_color_background_notice_hover': primitive['P_color_orange_600'],
    'S_color_background_warn_base': primitive['P_color_red_500'],
    'S_color_background_warn_hover': primitive['P_color_red_600'],
    'S_color_background_info_base': primitive.P_color_skyBlue_400,
    'S_color_background_info_hover': primitive.P_color_skyBlue_450,
    'S_color_background_grey_base': primitive.P_color_grey_450,
    'S_color_background_default_base': primitive['P_color_white'],
    'S_color_background_black_base': primitive.P_color_black,
    'S_color_text_default_base': primitive['P_color_black'],
    'S_color_text_inverse_base': primitive['P_color_white'],
    'S_color_text_inverse_light': primitive['P_color_grey_200'],
    'S_color_text_primary_base': primitive['P_color_purple_500'],
    'S_color_text_primary_hover': primitive['P_color_purple_600'],
    'S_color_text_secondary_base': primitive.P_color_grey_500,
    'S_color_text_secondary_hover': primitive.P_color_grey_600,
    'S_color_text_confirm_base': primitive['P_color_green_500'],
    'S_color_text_confirm_hover': primitive.P_color_green_600,
    'S_color_text_notice_base': primitive['P_color_orange_500'],
    'S_color_text_notice_hover': primitive.P_color_orange_600,
    'S_color_text_warn_base': primitive['P_color_red_500'],
    'S_color_text_warn_hover': primitive.P_color_red_600,
    'S_color_text_info_base': primitive.P_color_skyBlue_400,
    'S_color_text_grey_base': primitive.P_color_grey_450,
    'S_color_icon_primary_base': primitive['P_color_purple_500'],
    'S_color_icon_secondary_base': primitive['P_color_grey_500'],
    'S_color_icon_confirm_base': primitive['P_color_green_500'],
    'S_color_icon_notice_base': primitive.P_color_orange_500,
    'S_color_border_default_base': primitive['P_color_grey_200'],
    'S_color_border_primary_base': primitive['P_color_purple_500'],
    'S_color_border_secondary_base': primitive['P_color_grey_500'],
    'S_color_border_secondary-alt_base': primitive.P_color_grey_230,
    'S_color_border_confirm_base': primitive['P_color_green_500'],
    'S_color_border_notice_base': primitive['P_color_orange_500'],
    'S_color_border_warn_base': primitive['P_color_red_500'],
    'S_color_border_info_base': primitive.P_color_skyBlue_400,
    'S_color_border_grey_base': primitive.P_color_grey_450,
    'S_color_border_black_base': primitive.P_color_black,
    'S_color_border_white_base': primitive.P_color_white,
    'S_color_border_transparent': primitive.P_color_transparent,
    'S_border-radius_default': primitive['P_border-radius_2'],
    'S_border-radius_rounded': primitive['P_border-radius_50']
};

const component = {
    'C_Subnav_color_background_base': semantic.S_color_background_secondary_base,
    'C_Subnav_color_background_active': semantic.S_color_background_secondary_hover,
    'C_Subnav__option--activeBefore_color_background': semantic.S_color_background_secondary_hover,
    'C_Subnav_color_background_activeMobile': primitive['P_color_grey_700'],
    'C_Subnav_color_text_base': semantic['S_color_text_inverse_light'],
    'C_Subnav_color_text_active': semantic['S_color_text_inverse_base'],
    'C_Subnav_color_border': primitive.P_color_transparent,
    'C_Title_color_background': primitive['P_color_grey_50'],
    'C_Title_color_border': primitive['P_color_transparent'],
    'C_Title_borderRadius': semantic['S_border-radius_default'],
    'C_Title_backButtonContainer_color_background': primitive['P_color_grey_150'],
    'C_Title_backButton_color_background': primitive['P_color_transparent'],
    'C_Title_backButton_color_text': semantic['S_color_text_secondary_base'],
    'C_Title_backButton_color_border': semantic['S_color_text_secondary_base'],
    'C_BaseButton_border-radius_default': semantic['S_border-radius_default'],
    'C_OutlineButton_color_border_primary': semantic['S_color_border_primary_base'],
    'C_OutlineButton_color_background_primary_hover': semantic.S_color_background_primary_hover,
    'C_OutlineButton_color_text_primary_base': semantic['S_color_text_primary_base'],
    'C_OutlineButton_color_text_primary_hover': semantic['S_color_text_inverse_base'],
    'C_OutlineButton_color_border_secondary': primitive.P_color_grey_550,
    'C_OutlineButton_color_background_secondary_hover': primitive.P_color_grey_550,
    'C_OutlineButton_color_text_secondary_base': primitive.P_color_grey_550,
    'C_OutlineButton_color_text_secondary_hover': semantic['S_color_text_inverse_base'],
    'C_SolidButton_color_background_secondary': primitive.P_color_grey_550,
    'C_SolidButton_color_background_secondary_hover': primitive.P_color_grey_580,
    'C_SolidButton_color_text_secondary_base': semantic['S_color_text_inverse_base'],
    'C_SolidButton_color_text_secondary_hover': semantic['S_color_text_inverse_base'],
    'C_InactiveButton_color_text': primitive.P_color_grey_450,
    'C_InactiveButton_color_background': primitive.P_color_grey_100,
    'C_InactiveButton_color_border': semantic.S_color_border_default_base,
    'C_2FARadioButton_color_background': semantic.S_color_background_default_base,
    'C_2FARadioButton_color_background_selected': primitive.P_color_skyBlue_450,
    'C_2FARadioButton_color_border_selected': primitive.P_color_skyBlue_450,
    'C_2FARadioButtonContainer_color_background_hover': primitive['P_color_grey_100'],
    'C_Sidebar_color_border': semantic['S_color_border_default_base'],
    'C_Sidebar_color_background': primitive['P_color_transparent'],
    'C_Sidebar__heading_color_text': semantic['S_color_text_default_base'],
    'C_Sidebar__listItem_color_background_active': primitive['P_color_transparent'],
    'C_Sidebar__listItem_color_text_base': semantic.S_color_text_secondary_base,
    'C_Sidebar__listItem_color_text_active': semantic['S_color_text_primary_base'],
    'C_Sidebar__listItem--selected_color': semantic['S_color_text_primary_base'],
    'C_MobileSidebar_margin-top': primitive['P_margin_0'],
    'C_Anchor_color_text_base': primitive['P_color_skyBlue_500'],
    'C_Anchor_color_text_hover': primitive['P_color_skyBlue_500'],
    'C_NXBox_border-radius_default': primitive['P_border-radius_3'],
    'C_NXBox_border-radius_defaultMobile': primitive['P_border-radius_15'],
    'C_NXBox_color_border': semantic['S_color_border_default_base'],
    'C_NXBox__subheading_color_text': semantic['S_color_text_secondary_base'],
    'C_NXBoxSplit__section_color_border': semantic['S_color_border_default_base'],
    'C_NXBoxBottom__container_color_background': primitive['P_color_grey_50'],
    'C_NXBoxBottom_padding': primitive['P_padding_0'],
    'C_NXBoxBottom_border-radius_default': semantic['S_border-radius_default'],
    'C_NXTable__subHeading_color_background': primitive.P_color_grey_125,
    'C_NXTable__subHeading_color_text': primitive.P_color_grey_125,
    'C_NXTable_color_background': semantic.S_color_background_default_base,
    'C_NXTable__row_color_text': semantic.S_color_text_secondary_base,
    'C_Input_border-radius': semantic['S_border-radius_default'],
    'C_Input_color_background': primitive['P_color_grey_80'],
    'C_Input_color_text': semantic['S_color_text_default_base'],
    'C_Input__disabled_color_text': primitive.P_color_grey_400,
    'C_Checkbox_border-radius_default': primitive['P_border-radius_2'],
    'C_SelectDropdown_color_background': primitive.P_color_grey_50,
    'C_SelectDropdown_color_text': semantic.S_color_text_secondary_base,
    'C_SelectDropdown_color_border': primitive.P_color_grey_60,
    'C_Select__listItem--selected_color_background': primitive['P_color_grey_50'],
    'C_StyledLoader_color_background': semantic.S_color_background_secondary_light,
    'C_NXTableRow_color_background': semantic.S_color_background_default_base,
    'C_NXTableRow__alternator_color_background': primitive.P_color_grey_50,
    'C_NXTableRow__alternator_color_background_mobile': primitive.P_color_grey_100,
    'C_Checkbox_color_border': primitive.P_color_grey_80,
    'C_accounts__indicator_color_background': primitive['P_color_purple_100'],
    'C_descriptionText_color_text': semantic.S_color_text_secondary_base,
    'C_PricingTable_color_background': primitive['P_color_grey_50'],
    'C_Tabs_color_background': primitive.P_color_grey_20,
    'C_rangeslider_color_background': primitive['P_color_purple_200'],
    'C_changeResourcesFormAllocations__names_color_background': semantic['S_color_background_secondary_base'],
    'C_changeResourcesFormAllocations__currentValues_color_background': primitive['P_color_grey_100'],
    'C_changeResourcesFormAllocations__currentValuesHeading_color_background': primitive['P_color_grey_250'],
    'C_changeResourcesFormAllocations__newValues_color_background': primitive['P_color_purple_200'],
    'C_changeResourcesFormAllocations__newValuesHeading_color_background': primitive['P_color_purple_300'],
    'C_hostingDetails__tabSelectorOption_color_background_hover': primitive['P_color_grey_50'],
    'C_accountLoginHistory__tableHeader_color_text': semantic.S_color_text_secondary_base,
    'C_SolidTag_color_text': primitive.P_color_white,
    'C_accounts__dropdown--name_color_text': primitive['P_color_blue-black'],
    'C_Tooltip__content_color_background': semantic.S_color_background_secondary_base,
    'C_Tooltip_border-radius': primitive['P_border-radius_6'],
    'C_Search_color_background': primitive.P_color_white,
    'C_Search_border-radius': primitive['P_border-radius_5'],
    'C_invoiceView__tag_color_background_secondary': primitive.P_color_grey_50,
    'C_invoiceView__info_color_background_mobile': primitive.P_color_grey_50,
    'C_invoiceView__tag_border-radius': primitive['P_border-radius_5'],
    'C_invoicePay__items_box-shadow': primitive['P_box-shadow_white'],
    'C_paymentMethods__methodSelected_color_background': semantic.S_color_background_secondary_light,
    'C_ticketList__openTicket_color_background': primitive.P_color_purple_50,
    'C_ticketViewMessage__body--customer_color_background': primitive.P_color_lightBlue_200,
    'C_ticketViewMessage__body--worker_color_background': primitive.P_color_purple_50,
    'C_ticketForm__waitTime_color_background': primitive.P_color_grey_100,
    'C_login__supportLink_color_text': primitive.P_color_skyBlue_450
};

const ventra = {
    ...primitive,
    ...semantic,
    ...component
};

export const __DO_NOT_USE_IN_PRODUCTION_BUILD_OR_YOU_WILL_BE_FIRED__VENTRA__CONFIG__ = ventra;
