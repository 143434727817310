/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**
 * @param {KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params} params
 */
function createQueryKey({ serviceID, presetID, site }) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'preview', 'preset', presetID, JSON.stringify(site)]));
}

/**
 * @param { KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params & { enabled?: boolean } } params
 */
function createQueryOptions({ serviceID, presetID, site, enabled }) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, presetID, site }),
        queryFn: () => KATANA_API.katana.service_id.preview.preset.POST({ serviceID, presetID, site }),
        enabled: Boolean(serviceID && presetID && enabled),
        staleTime: Infinity,
        select: (data) => {
            if (typeof data === 'string') {
                return data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the preview for the preset
 * @param {KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params & { enabled?: boolean }} params
 */
function _useQuery({ serviceID, presetID, site, enabled }) {
    return useQuery(createQueryOptions({ serviceID, presetID, site, enabled }));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getPresetPreview = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions
});
