/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_emailBillingCycleSuccessForm.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let BillingCycleSuccess = ({
    closeLightbox,
    // Redux props
    email_information_mailbox_data,
    email_billing_data
}) => {
    /***** RENDER HELPERS *****/
    const currentInvoice = getIncludedObjBasedOnType(email_information_mailbox_data?.included, 'invoice');
    const dueDate = email_information_mailbox_data?.attributes.next_invoice_date;

    const renderInvoices = () => {
        const { new_invoice_id, remaining_invoice_id } = email_billing_data;

        return (
            <div className="emailBillingCycleSuccess__invoices">
                <div className="emailBillingCycleSuccess__old">
                    <div className="emailBillingCycleSuccess__old--heading">Disregard old Invoice</div>
                    <div className="emailBillingCycleSuccess__old--invoice">#{currentInvoice.id}</div>
                    <div className="emailBillingCycleSuccess__old--description">Your services that were on the same invoice</div>
                </div>
                <i className="icon icon-arrow" />
                <div className="emailBillingCycleSuccess__new">
                    <div className="emailBillingCycleSuccess__new--heading">NEW Invoice created</div>
                    <div className="emailBillingCycleSuccess__new--invoice">#{new_invoice_id}</div>
                    <div className="emailBillingCycleSuccess__new--description">Your services with the new billing cycle</div>
                </div>
                {remaining_invoice_id && (
                    <div className="emailBillingCycleSuccess__remaining">
                        <div className="emailBillingCycleSuccess__remaining--heading">NEW Invoice create</div>
                        <div className="emailBillingCycleSuccess__remaining--invoice">#{remaining_invoice_id}</div>
                        <div className="emailBillingCycleSuccess__remaining--description">Your other services</div>
                    </div>
                )}
            </div>
        );
    };

    const renderSuccessDescription = () => {
        if (email_billing_data) {
            return <div className="emailBillingCycleSuccess__description">This will take effect at the end of the current cycle.</div>;
        }

        return (
            <div className="emailBillingCycleSuccess__description">
                This will take effect at the end of the current cycle.
                <br />
                Please take note the service&apos;s due date is
                <span className="emailBillingCycleSuccess__description--dueDate">&nbsp;{toLuxonDate(dueDate).toFormat('d LLL yyyy')}</span>.
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <div className="emailBillingCycleSuccess">
            <i className="icon icon-valid" />
            <div className="emailBillingCycleSuccess__heading">Billing cycle successfully changed</div>
            {renderSuccessDescription()}
            {currentInvoice && email_billing_data && renderInvoices()}
            <SolidButton className="emailBillingCycleSuccess__button" type="onClick" onClick={closeLightbox}>
                Back to Manage Mailbox
            </SolidButton>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
BillingCycleSuccess = reduxForm({
    form: 'emailBillingCycleSuccessForm'
})(BillingCycleSuccess);

const mapStateToProps = (state) => ({
    email_information_mailbox_data: state.email.email_information_mailbox_data,
    email_billing_data: state.email.email_billing_data
});

export default connect(mapStateToProps)(BillingCycleSuccess);
