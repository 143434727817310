/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, emailFieldValidation, renderButton, renderCheckboxField, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_emailForwarder.scss';
import { forwarderLightboxModes } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let EmailForwarder = ({
    mode,
    closeLightbox,
    /** Redux Props */
    catchAll,
    domain_information_data,
    handleSubmit,
    pristine,
    submitting,
    valid
}) => {
    const domain = domain_information_data?.attributes?.domain;

    /***** RENDER *****/
    return (
        <form className="addRecord__form addEmailForwarder__form" onSubmit={handleSubmit}>
            <div className="wrapper">
                <div className="form__row">
                    <div className="form__column full confirmation__input">
                        <Field name="catch_all" label="Catch All" component={renderCheckboxField} type="checkbox" className="form__textfield" />
                    </div>
                </div>
                {catchAll ? (
                    ''
                ) : (
                    <>
                        {mode === 'add' ? (
                            <div className="form__row">
                                <div className="form__column full ext__label">
                                    <Field
                                        label="Address"
                                        name="source"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        validate={[requiredFieldValidation]}
                                        className="form__textfield"
                                        append={{
                                            type: `domain`,
                                            text: `@${domain}`
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form__row">
                                <div className="form__column full ext__label">
                                    <Field
                                        label="Address"
                                        name="source"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        validate={[requiredFieldValidation, emailFieldValidation]}
                                        className="form__textfield"
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Forwarding To"
                            name="destination"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation, emailFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        {renderButton(pristine, submitting, valid, `${mode === 'add' ? 'Add' : 'Save'} Email Forwarder`)}
                    </div>
                </div>
                <div className="addRecord__viewToggle--tooltip">
                    <button className="sharedBox__viewToggle" onClick={closeLightbox}>
                        <i className="icon icon-x" />
                    </button>
                </div>
            </div>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

EmailForwarder.propTypes = {
    /** The mode of the forwarder. Add or edit. */
    mode: PropTypes.oneOf(forwarderLightboxModes),

    /** ID of the record you're modifying */
    recordID: PropTypes.oneOf([PropTypes.number, null]),

    /** The function that closes the lightbox. */
    closeLightbox: PropTypes.func
};

EmailForwarder = reduxForm({
    form: 'emailForwarderForm',
    enableReinitialize: true
})(EmailForwarder);

const mapStateToProps = (state, { mode, recordID }) => {
    const domain_email_record_store = state.domain.domain_email_record_store;
    const selector = formValueSelector('emailForwarderForm');
    const catchAll = selector(state, 'catch_all');

    const initialValues = {};

    if (mode === 'edit') {
        const selectedRecord = domain_email_record_store.find(({ id }) => id === recordID)?.attributes;

        Object.entries(selectedRecord).forEach(([key, value]) => {
            if (value.startsWith(`@`)) {
                initialValues['catch_all'] = true;
            } else {
                initialValues[key] = value;
            }
        });
    }

    return {
        initialValues,
        domain_information_data: state.domain.domain_information_data,
        catchAll
    };
};

EmailForwarder = connect(mapStateToProps)(EmailForwarder);

export default EmailForwarder;
