/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _RadioButtonsWithSelectedRenderItem as RadioButtonsWithSelectedRenderItem } from 'components/Form/RadioButtonsWithSelectedRender/_RadioButtonSelectedRenderItem';
import { _RadioButtonsWithSelectedRender } from 'components/Form/RadioButtonsWithSelectedRender/_RadioButtonsWithSelectedRender';
import { _RadioButtonsWithSelectedRenderReduxFormDefault as RadioButtonsWithSelectedRenderReduxFormDefault } from 'components/Form/RadioButtonsWithSelectedRender/wrappers/reduxform/Default';

const RadioButtonsWithSelectedRender = Object.assign(_RadioButtonsWithSelectedRender, {
    ReduxForm: {
        Default: RadioButtonsWithSelectedRenderReduxFormDefault
    },
    Item: RadioButtonsWithSelectedRenderItem
});

export { RadioButtonsWithSelectedRender };
