/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _ScrollableComponentAnchor } from './Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSectionModuleContext } from 'utilities/methods/sectionRendering';
import { useScrollableComponent } from './hooks';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides functionality to any component inside a section module to be scrollable, and to be able to scroll to it using the sidebarRef.
 *
 * @type {ScrollableComponent}
 */
const _ScrollableComponent = ({ children, className, ready = true, allowImmediateReady = false }) => {
    /***** HOOKS *****/
    const { path } = useSectionModuleContext();
    const scrollRef = useScrollableComponent(path, ready, {
        allowImmediateReady
    });

    /***** RENDER *****/
    return (
        <div ref={scrollRef} className={className}>
            {children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const ScrollableComponent = Object.assign(_ScrollableComponent, {
    Anchor: _ScrollableComponentAnchor
});

export { ScrollableComponent };
