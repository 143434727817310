/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import {
    setGoogleDNSRecords,
    setOfficeMXRecords,
    setShopifyDNSRecords,
    setSquarespaceDNSRecords,
    setVentraMXRecords
} from 'containers/domain/action';
import { getEmailHostingDomainGroupDetails } from 'containers/email/action';
import { emailSearch } from 'containers/services/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { BUTTON_COLORS } from 'components/Buttons/_BaseButton';
import PreviewPreset from 'containers/domain/forms/presets/_conflicting';
import { renderButton } from 'utilities/methods/form';
import { handleRenderIcon, isRecordConflicting } from './helpers';
import { presets, views } from './types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * Imports
 *
 * @typedef {import('./types').TPresetName} TPresetName
 * @typedef {import('./types').TPresets} TPresets
 * @typedef {import('./types').TViewName} TViewName
 * @typedef {import('redux-form').InjectedFormProps} InjectedFormProps
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 */

/**
 * @template T
 * @typedef {import('utilities/types').useState<T>} useState
 */

/**
 * HOC Props
 *
 * @typedef {ReturnType<typeof mapStateToProps>} TMapStateToProps
 * @typedef {typeof mapDispatchToProps} TMapDispatchToProps
 */

/**
 * @typedef {{
 *   forcedPreset: TPresetName,
 *   closeLightbox: () => void,
 * } & TMapStateToProps & TMapDispatchToProps & InjectedFormProps & RouteComponentProps} TProps
 */

export const ThirdPartyPresetName = 'thirdPartyPreset';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {(props: TProps) => JSX.Element}
 */
let ThirdPartyPreset = ({
    forcedPreset,
    closeLightbox,

    /**
     * Redux Props
     */
    domain_information_data,
    setOfficeMXRecords,
    setGoogleDNSRecords,
    setVentraMXRecords,
    setShopifyDNSRecords,
    setSquarespaceDNSRecords,
    remove_conflicting,
    verification_code,
    domain_third_party_status,
    email_search_status,
    email_search_data,
    email_domain_group_details_status,
    email_domain_group_details_data,
    getEmailHostingDomainGroupDetails,
    domain_preview_preset_changes_status,
    emailSearch,

    /**
     * Redux Form Props
     */
    submitting,
    valid
}) => {
    /***** STATE *****/
    const [view, setView] = /** @type {useState<TViewName | null>} */ (useState(views.SELECT));
    const [selected, setSelected] = /** @type {useState<TPresetName | null>} */ (useState(null));
    const [email, setEmail] = /** @type {useState<{ service: boolean, details: unknown }>} */ (
        useState({
            service: false,
            details: null
        })
    );

    const domain_record_store = useSelector((/** @type {*} */ state) => state.domain.domain_record_store);

    /***** FUNCTIONS *****/
    const submitPreset = (e) => {
        e.preventDefault();

        const { id } = domain_information_data;

        const attributes = {
            remove_conflicting: remove_conflicting || false,
            replacements: {}
        };

        switch (selected) {
            default:
            case presets.GOOGLE_WORKSPACE:
                return setGoogleDNSRecords(id, attributes);

            case presets.OFFICE_365:
                return setOfficeMXRecords(id, attributes);

            case presets.VENTRAIP_EMAIL: {
                if (email?.details === null || !domain_record_store) {
                    return setVentraMXRecords(id, null, attributes);
                }

                const { hostname, record: content } = /** @type {*} */ (email.details)?.dkim ?? {};

                const record = {
                    hostname,
                    content,
                    dns_type: 'TXT'
                };

                const dkimIsConflicting = isRecordConflicting(record, domain_record_store);

                if (dkimIsConflicting) {
                    return setVentraMXRecords(id, null, attributes);
                }

                return setVentraMXRecords(
                    id,
                    {
                        domain: hostname,
                        record: content
                    },
                    attributes
                );
            }

            case presets.SHOPIFY:
                return setShopifyDNSRecords(id, attributes);

            case presets.SQUARESPACE:
                if (verification_code) {
                    attributes.replacements.verification_code = verification_code;
                }

                return setSquarespaceDNSRecords(id, attributes);
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        if (domain_third_party_status === 'error') {
            setView(views.SELECT);
            setSelected(null);
        }
    }, [domain_third_party_status]);

    useEffect(() => {
        const { domain } = domain_information_data.attributes;

        if (email_search_status !== 'success') {
            return;
        }

        const filteredEmail = email_search_data.filter((email) => domain === email.attributes.domain)[0];

        if (!filteredEmail || !filteredEmail.id) {
            return;
        }

        setEmail({
            service: filteredEmail,
            details: undefined
        });
        getEmailHostingDomainGroupDetails(filteredEmail.id);
    }, [email_search_status]);

    useEffect(() => {
        if (email_domain_group_details_status !== 'success') return;

        setEmail((email) => ({
            ...email,
            details: email_domain_group_details_data.attributes
        }));
    }, [email_domain_group_details_status]);

    useEffect(() => {
        emailSearch(domain_information_data.attributes.domain);

        if (forcedPreset) {
            setSelected(forcedPreset);
            setView(views.PRESET);
        }
    }, []);

    /***** RENDER HELPERS *****/
    const handleRenderOptions = () => {
        return (
            <div className="thirdPartyForm__optionsContainer">
                {Object.values(presets).map((value) => {
                    return (
                        <button
                            key={value}
                            className="dns__preset"
                            onClick={() => {
                                setSelected(value);
                                setView(views.PRESET);
                            }}
                        >
                            {handleRenderIcon(value)}
                            <div className="dns__preset--title">{value}</div>
                        </button>
                    );
                })}
            </div>
        );
    };

    const handleRenderDetails = () => {
        const handleClick = () => {
            if (forcedPreset) {
                closeLightbox();
            } else {
                setView('select');
            }
        };

        // selected should never be null while view is preset
        if (!selected) {
            setView('select');
            return null;
        }

        return (
            <form className="thirdPartyForm__confirm" onSubmit={submitPreset}>
                <h2 className="summary__title">Connect to {selected}</h2>
                <PreviewPreset preset={selected} />
                {renderButton(
                    false,
                    submitting || domain_preview_preset_changes_status !== 'success',
                    valid,
                    'Apply DNS Preset',
                    BUTTON_COLORS.PRIMARY
                )}
                <Anchor className="OverlayConfirm__commonlink" onClick={handleClick}>
                    Go Back
                </Anchor>
            </form>
        );
    };

    /***** RENDER *****/
    switch (view) {
        case views.SELECT:
            return <div className="thirdPartyForm">{handleRenderOptions()}</div>;

        case views.PRESET:
            return <div className="thirdPartyForm">{handleRenderDetails()}</div>;

        default:
            return <RequestLoader />;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    const selector = formValueSelector(ThirdPartyPresetName);
    const remove_conflicting = selector(state, 'remove_conflicting');
    const verification_code = selector(state, 'verification_code');

    return /** @type const */ ({
        domain_information_data: state.domain.domain_information_data,
        domain_third_party_status: state.domain.domain_third_party_status,
        email_search_status: state.services.email_search_status,
        email_search_data: state.services.email_search_data,
        email_domain_group_details_status: state.email.email_domain_group_details_status,
        email_domain_group_details_data: state.email.email_domain_group_details_data,
        domain_preview_preset_changes_status: state.domain.domain_preview_preset_changes_status,
        remove_conflicting,
        verification_code
    });
};

const mapDispatchToProps = /** @type const */ ({
    setOfficeMXRecords,
    setGoogleDNSRecords,
    setVentraMXRecords,
    setShopifyDNSRecords,
    setSquarespaceDNSRecords,
    getEmailHostingDomainGroupDetails,
    emailSearch
});

ThirdPartyPreset = reduxForm({
    form: ThirdPartyPresetName,
    enableReinitialize: false,
    destroyOnUnmount: false
})(ThirdPartyPreset);

ThirdPartyPreset = connect(mapStateToProps, mapDispatchToProps)(ThirdPartyPreset);

export default withRouter(ThirdPartyPreset);
