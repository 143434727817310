/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import htmr from 'htmr';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import Tooltip from 'components/Tooltip';
import Slider from 'components/Slider';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { billingCycles } from 'config/config';

import './_resourceSelect.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PurchaseCustomHostingResourceSelect({ labels, billingCycle, availableConfigs, selectedConfigs, setSelectedConfigs }) {
    /***** HOOKS *****/
    const app_viewport = useSelector((state) => state.app.app_viewport);

    /***** STATE *****/
    // This is kept so that when a slider is dragged but not let go of, the slider can visually show the new values, but without calling the external setter (setSelectedConfigs) to change the external copy of the configs. This way, we know that the reference to "selectedConfigs" will only change when the drag is finished, not every pixel that the mouse moves. So in the component that renders this component, we can safely call the pricing calc endpoint every time "selectedConfigs" changes
    const [localCopyOfConfigs, setLocalCopyOfConfigs] = useState(selectedConfigs);

    /***** FUNCTIONS *****/
    function handleChange(value, resourceName, commitChangesExternally) {
        const newConfigs = {
            ...localCopyOfConfigs,
            [resourceName]: {
                config_id: availableConfigs[resourceName][value].config_id,
                option_id: availableConfigs[resourceName][value].option_id,
                value: availableConfigs[resourceName][value].value,
                orderValue: availableConfigs[resourceName][value].orderValue,
                name: availableConfigs[resourceName][value].name
            }
        };

        setLocalCopyOfConfigs(newConfigs);

        if (commitChangesExternally) {
            setSelectedConfigs(newConfigs);
        }
    }

    /***** RENDER HELPERS *****/
    const renderSlider = (data) => {
        const { title, subtitle, tooltip, dataRef, labels, onChange } = data;

        const handleDropdownOptions = (data) =>
            Object.keys(data).map((key) => ({
                label: htmr(`<strong>${labels[key]}</strong> ${title}`),
                onClick: () => {
                    onChange(key, true);
                }
            }));

        const handlePricingRender = () => {
            const price = availableConfigs[dataRef]?.find((option) => option?.option_id === localCopyOfConfigs?.[dataRef]?.option_id)?.pricing[
                billingCycle
            ];

            if (typeof price !== 'number') {
                return '';
            }

            return `$${price.toFixed(2)}${billingCycles[billingCycle]}`;
        };

        const renderMobile = () => (
            <>
                {labels ? (
                    <SelectDropdown
                        label={htmr(`<strong>${localCopyOfConfigs[dataRef].name}</strong> ${title}`)}
                        options={handleDropdownOptions(labels)}
                    />
                ) : (
                    ''
                )}
                <div className="vipRewardsHomePurchaseHostingResourceSelect__config-wrapper">
                    <div className="vipRewardsHomePurchaseHostingResourceSelect__config-price">{handlePricingRender()}</div>
                </div>
            </>
        );

        const renderDesktop = () => (
            <>
                <div className="vipRewardsHomePurchaseHostingResourceSelect__config-wrapper">
                    <div className="vipRewardsHomePurchaseHostingResourceSelect__config-option">
                        {title} <Tooltip className="vipRewardsHomePurchaseHostingResourceSelect__config-tooltip" info={tooltip} />
                    </div>
                    <div className="vipRewardsHomePurchaseHostingResourceSelect__config-name">
                        <strong>{localCopyOfConfigs[dataRef].name}</strong> {subtitle}
                    </div>
                    <div className="vipRewardsHomePurchaseHostingResourceSelect__config-price">{handlePricingRender()}</div>
                </div>
                {labels ? (
                    <Slider
                        className="vipRewardsHomePurchaseHostingResourceSelect__slider"
                        min={0}
                        max={Object.keys(labels).length - 1}
                        labels={labels}
                        onChange={(value) => onChange(value, false)}
                        onChangeComplete={(value) => onChange(value, true)}
                        value={localCopyOfConfigs[dataRef].orderValue}
                        tooltip={false}
                    />
                ) : (
                    ''
                )}
            </>
        );

        return (
            <div className="vipRewardsHomePurchaseHostingResourceSelect__option">
                {['sm', 'xs'].includes(app_viewport) ? renderMobile() : renderDesktop()}
            </div>
        );
    };

    /***** RENDER *****/
    if (!localCopyOfConfigs) {
        return '';
    }

    return (
        <div className="vipRewardsHomePurchaseHostingResourceSelect__form">
            {renderSlider({
                title: `Disk Space`,
                subtitle: `Disk Space`,
                tooltip: `Your files and databases will be stored on our new enterprise SAS SSD storage which is lightning fast and highly reliable.`,
                dataRef: `disk`,
                labels: labels?.disk,
                onChange: (value, commitChangesExternally) => handleChange(value, 'disk', commitChangesExternally)
            })}
            {renderSlider({
                title: `CPU Cores`,
                subtitle: `CPU`,
                tooltip: `This is the limit of CPU available to your website, where 100% equals 1 CPU core. You can view your CPU usage inside cPanel at anytime.`,
                dataRef: `cpu`,
                labels: labels?.cpu,
                onChange: (value, commitChangesExternally) => handleChange(value, 'cpu', commitChangesExternally)
            })}
            {renderSlider({
                title: `Memory`,
                subtitle: `Memory`,
                tooltip: `The is the amount of memory your website processes can use before throttling is applied. You can view your memory usage inside cPanel at any time.`,
                dataRef: `mem`,
                labels: labels?.mem,
                onChange: (value, commitChangesExternally) => handleChange(value, 'mem', commitChangesExternally)
            })}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PurchaseCustomHostingResourceSelect.propTypes = {
    /**
     * Eg. "100%"
     */
    labels: PropTypes.shape({
        /**
         * Eg. "100%"
         */
        cpu: PropTypes.string
    }),

    /**
     * The currently selected billing cycle, eg. "Monthly"
     */
    billingCycle: PropTypes.string,

    /**
     * All the possible configs, from /api/web-hosting/custom-hosting/config-list. Has "cpu", "disk" and "mem" as keys
     */
    availableConfigs: PropTypes.object,

    /**
     * Similar to above but with some properties modified. Has "cpu", "disk" and "mem" as keys
     */
    selectedConfigs: PropTypes.object,

    /**
     * Setter function for "selectedConfigs"
     */
    setSelectedConfigs: PropTypes.func
};

export default PurchaseCustomHostingResourceSelect;
