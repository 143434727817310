/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RewardCards from '../../modules/rewardCards';
import VipRewardsTierIcon from '../moreInfo/tierIcon';
import VipRewardsHomeBenefits from './benefits';
import VipRewardsHomePurchaseHostingLightbox from './purchaseHostingLightbox';
import VipRewardsHomeRegisterDomainLightbox from './registerDomainLightbox';
import VipRewardsHomeTierBenefitsLightbox from './tierBenefitsLightbox';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Progress from 'components/Progress';
import ProgressStepsNode, { nodeStates } from 'components/ProgressSteps/Node';
import SolidTag from 'components/Tags/SolidTag';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';
import { capitalize, getCurrentDate, pluralize } from 'utilities/methods/commonActions';
import { getTiersWithRequiredAccountAgeDays } from './methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { orderedTiers, tiers, vipRewardsReadableName } from 'containers/vipRewards/consts';
import VipRewardsLogo from 'assets/images/vipRewards/vipRewardsLogo.svg';
import './_vipRewardsHome.scss';

const defaultRewardCardsList = /** @type {const} */ (['nrlGiveaway', 'dotOnline', 'dotStore', 'dotXyz', 'dotSite', 'referral']);

export const lightboxes = {
    TIER_BENEFITS: 'tierBenefits',
    PURCHASE_HOSTING: 'purchaseHosting',
    REGISTER_DOMAIN: 'registerDomain'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsHome() {
    /***** HOOKS *****/
    const { currentTier, customerFrom, firstName, rewardsTiers } = useVipRewards();

    /***** STATE *****/
    const [currentlyOpenLightbox, setCurrentlyOpenLightbox] = useState(null);

    /***** RENDER HELPERS *****/
    const timelineTiers = getTiersWithRequiredAccountAgeDays(rewardsTiers);
    const accountAgeInDays = customerFrom.luxonDate ? getCurrentDate().diff(customerFrom.luxonDate, ['days', 'hours']).days : 0;

    const renderNextTierProgress = () => {
        // These are set to 100 just as an arbitrary number, because if its platinum it doesn't matter, the progress bar is always full
        let max = 100;
        let value = 100;
        let nextTierName = '';
        let daysAwayMessage = "You've done it!";
        let awayFromStatusMessage = 'Platinum status reached';

        if (currentTier?.lowercase !== tiers.PLATINUM) {
            const nextTierIndex = timelineTiers.findIndex((timelineTiers) => timelineTiers?.name === currentTier?.lowercase) + 1;
            const prevMax = timelineTiers[nextTierIndex - 1]?.days || 0;
            max = (timelineTiers[nextTierIndex]?.days ?? 0) - prevMax;
            value = Math.max(0, accountAgeInDays - prevMax);
            nextTierName = timelineTiers[nextTierIndex]?.name || '';
            daysAwayMessage = `${max - value} day${pluralize(max - value)}`;
            awayFromStatusMessage = `away from ${capitalize(nextTierName)} status`;
        }

        return (
            <div className="vipRewardsHomeSummary__section">
                <div className="vipRewardsHomeSummary__panel">
                    {(function () {
                        if (!customerFrom.luxonDate) {
                            return (
                                <>
                                    <Text semiBold black size--l>
                                        <p>You currently have {currentTier?.readable} status</p>
                                    </Text>
                                    <div className="vipRewardsHomeSummary__panelTop">
                                        {currentTier?.lowercase !== tiers.PLATINUM ? (
                                            <Anchor onClick={() => setCurrentlyOpenLightbox(lightboxes.TIER_BENEFITS)}>
                                                See {capitalize(nextTierName)} benefits
                                            </Anchor>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </>
                            );
                        }

                        return (
                            <>
                                <div className="vipRewardsHomeSummary__panelTop">
                                    <Text semiBold black lead--xs>
                                        <p className="vipRewardsHomeSummary__panelDays">{daysAwayMessage}</p>
                                    </Text>
                                    {currentTier?.lowercase !== tiers.PLATINUM ? (
                                        <Anchor onClick={() => setCurrentlyOpenLightbox(lightboxes.TIER_BENEFITS)}>
                                            See {capitalize(nextTierName)} benefits
                                        </Anchor>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <Text semiBold black size--l>
                                    <p>{awayFromStatusMessage}</p>
                                </Text>
                                <Progress
                                    color={currentTier?.lowercase === tiers.VIP ? 'primary' : currentTier?.lowercase}
                                    max={max}
                                    maxPlus={timelineTiers[1]?.days ?? 0}
                                    value={value}
                                />
                            </>
                        );
                    })()}
                </div>
            </div>
        );
    };

    const renderTiersTimeline = () => (
        <div className="vipRewardsHomeSummary__section">
            <div className="vipRewardsHomeSummary__tiers">
                <div className="vipRewardsHomeSummary__tiersTop">
                    {orderedTiers.map((tier, orderedTiersIndex) => (
                        <div key={`tierImage-${tier}`} className="vipRewardsHomeSummary__tierGraphic">
                            <div className="vipRewardsHomeSummary__tierGraphicImgContainer">
                                <VipRewardsTierIcon tier={tier} />
                            </div>
                            <Text semiBold black size--s>
                                {tier === 'vip' ? 'VIP' : capitalize(tier)}
                            </Text>
                            <Text secondary size--xs lead--none medium>
                                <p>
                                    {orderedTiersIndex === 0
                                        ? `0-${rewardsTiers?.[orderedTiers[orderedTiersIndex + 1]]?.account_age}`
                                        : rewardsTiers?.[tier]?.account_age}{' '}
                                    years
                                </p>
                            </Text>
                        </div>
                    ))}
                </div>

                {customerFrom.luxonDate ? (
                    <div className="vipRewardsHomeSummary__tiersTimeline">
                        {timelineTiers.map((timelineTier, index) => {
                            if (index === 0) {
                                return '';
                            }

                            const prevMax = timelineTiers[index - 1]?.days || 0;
                            const max = (timelineTier?.days ?? 0) - prevMax;
                            const value = Math.max(1, accountAgeInDays - prevMax);
                            const isComplete = value >= max;

                            return (
                                <div
                                    key={index}
                                    className={classNames('vipRewardsHomeSummary__tiersTimelineBox', {
                                        'vipRewardsHomeSummary__tiersTimelineBox--complete': isComplete
                                    })}
                                >
                                    {index === 1 ? (
                                        <div className="vipRewardsHomeSummary__tiersTimelineNodeContainer vipRewardsHomeSummary__tiersTimelineNodeContainer--vipTier">
                                            <ProgressStepsNode state={nodeStates.COMPLETE} />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <Progress color="confirm" max={max} maxPlus={max} value={value} />
                                    <div className="vipRewardsHomeSummary__tiersTimelineNodeContainer">
                                        <ProgressStepsNode state={nodeStates.COMPLETE} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );

    /***** RENDER *****/
    return (
        <div className="vipRewardsHome">
            <div className="vipRewardsHome__header">
                <img src={VipRewardsLogo} alt={vipRewardsReadableName} />
            </div>

            <div className="vipRewardsHome__section vipRewardsHome__section--summary">
                <div className={`vipRewardsHomeSummary vipRewardsHomeSummary--${currentTier?.lowercase}`}>
                    <div className="vipRewardsHomeSummary__background" />
                    <div className="vipRewardsHomeSummary__content">
                        <div className="vipRewardsHomeSummary__section">
                            <div className="vipRewardsHomeSummary__hello">
                                <VipRewardsTierIcon tier={currentTier?.lowercase} />
                                <div className="vipRewardsHomeSummary__helloText">
                                    <Text semiBold primary lead--none size--xxl>
                                        <p>Hello, {firstName}</p>
                                    </Text>
                                    <SolidTag color={currentTier?.lowercase === tiers.VIP ? 'primary' : currentTier?.lowercase}>
                                        {currentTier?.lowercase.toUpperCase()}
                                    </SolidTag>
                                    {customerFrom.luxonDate ? (
                                        <Text secondary size--s medium>
                                            <p>Customer since {customerFrom.readable}</p>
                                        </Text>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>

                        {renderNextTierProgress()}
                        {renderTiersTimeline()}
                    </div>
                </div>
            </div>

            <VipRewardsHomeBenefits setCurrentlyOpenLightbox={setCurrentlyOpenLightbox} />

            <div className="vipRewardsHome__section">
                <RewardCards title="Additional bonus offers" cards={defaultRewardCardsList} />
            </div>

            {currentlyOpenLightbox === lightboxes.TIER_BENEFITS ? (
                <OverlayLightbox
                    onOpen={currentlyOpenLightbox === lightboxes.TIER_BENEFITS}
                    onClose={() => setCurrentlyOpenLightbox(null)}
                    title={`Level up your ${vipRewardsReadableName} benefits`}
                    desc={
                        <Text>
                            Boost Your Rewards Over Time! <br />
                            <br /> As time keeps ticking, we&apos;ll magically ramp up your {vipRewardsReadableName} for every new service and service
                            renewals. Get ready to uncover the exciting surprises that await you!
                        </Text>
                    }
                >
                    <VipRewardsHomeTierBenefitsLightbox />
                </OverlayLightbox>
            ) : (
                ''
            )}

            {currentlyOpenLightbox === lightboxes.PURCHASE_HOSTING ? (
                <OverlayLightbox
                    onOpen={currentlyOpenLightbox === lightboxes.PURCHASE_HOSTING}
                    onClose={() => setCurrentlyOpenLightbox(null)}
                    title="Purchase Web Hosting"
                >
                    <VipRewardsHomePurchaseHostingLightbox />
                </OverlayLightbox>
            ) : (
                ''
            )}

            {currentlyOpenLightbox === lightboxes.REGISTER_DOMAIN ? (
                <OverlayLightbox
                    onOpen={currentlyOpenLightbox === lightboxes.REGISTER_DOMAIN}
                    onClose={() => setCurrentlyOpenLightbox(null)}
                    title="Register Domain Names "
                >
                    <VipRewardsHomeRegisterDomainLightbox />
                </OverlayLightbox>
            ) : (
                ''
            )}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default VipRewardsHome;
