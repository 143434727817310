/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_DialogNotificationButtonsContainer.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DialogNotificationButtonsContainer = ({ text, buttons }) => {
    /***** RENDER *****/
    return (
        <div className="DialogNotificationButtonsContainer">
            <p className="DialogNotificationButtonsContainer__text">{text}</p>
            <div className="DialogNotificationButtonsContainer__buttons">
                {buttons.map((button) => cloneElement(button, { className: 'DialogNotificationButtonsContainer__button' }))}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

DialogNotificationButtonsContainer.propTypes = {
    // Text to show before the buttons
    text: PropTypes.node.isRequired,

    // List of SolidButton's to show after the text in
    buttons: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default DialogNotificationButtonsContainer;
