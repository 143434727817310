/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { ticket_name } from 'config/config';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { checkipv6, disableipv6, enableipv6 } from '../state/configActions';
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class IPv6Management extends Component {
    constructor(props) {
        super(props);
        this.confirmLightbox = this.confirmLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.confirmipv6 = this.confirmipv6.bind(this);
        this.state = {
            status: 'disabled',
            data: null,
            showConfirmLightbox: false
        };
    }

    confirmLightbox() {
        this.setState({
            showConfirmLightbox: true
        });
    }

    closeLightbox() {
        this.setState({
            showConfirmLightbox: false
        });
    }

    confirmipv6() {
        const { hostingid, subaccount, enableipv6 } = this.props;
        enableipv6(hostingid, subaccount);
    }

    componentDidUpdate(prevProps) {
        const { hosting_ipv6_check_status, hosting_ipv6_check_data, hosting_ipv6_status } = this.props;
        registerScrollEvents(this, hosting_ipv6_check_status === 'success' && prevProps.hosting_ipv6_check_status === 'loading');

        if (hosting_ipv6_check_status === 'success' && prevProps.hosting_ipv6_check_status === 'loading') {
            const { attributes } = hosting_ipv6_check_data;
            const { ipv6 } = attributes;

            if (ipv6 === 'Disabled') {
                this.setState({
                    status: ipv6
                });
            } else {
                this.setState({
                    status: 'Enabled',
                    data: ipv6
                });
            }
        }

        if (hosting_ipv6_status === 'success' && prevProps.hosting_ipv6_status === 'loading') {
            this.setState({
                showConfirmLightbox: false
            });
        }
    }

    render() {
        const { hostingid, subaccount, hosting_ipv6_check_status, hosting_ipv6_status, disableipv6, checkipv6 } = this.props;
        const { status, data, showConfirmLightbox } = this.state;
        const { confirmLightbox, closeLightbox, confirmipv6 } = this;

        const loadingStatus = () => {
            if (hosting_ipv6_check_status === 'loading' || hosting_ipv6_status === 'loading') {
                return 'loading';
            } else if (hosting_ipv6_check_status === 'success' || hosting_ipv6_status === 'success') {
                return 'success';
            }
            return 'error';
        };

        const confirmIpv6Data = {
            desc: htmr(
                `If you're unsure about IPv6's functionality please submit a new ${ticket_name} to our Technical Support prior to enabling it.`
            ),
            buttonText: 'Enable IPv6',
            buttonAction: confirmipv6,
            closeText: 'No, Go Back',
            closeAction: closeLightbox,
            loading: hosting_ipv6_status
        };

        let conditionalProps = {
            desc: `This tool allows you to enable IPv6 connectivity to your hosting service. This will allow IPv6 enabled users to reach your website in addition to the existing IPv4 user base.`,
            action: {
                label: 'Enable',
                type: 'onClick',
                className: '',
                color: '',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    confirmLightbox();
                }
            }
        };

        if (status.toLowerCase() !== 'disabled') {
            conditionalProps = {
                bottom: true,
                columns: [
                    {
                        render: (
                            <div className="sharedBox__infoColumn">
                                <div className="title">IPv6 Address</div>
                                <div className="desc">{data}</div>
                            </div>
                        )
                    }
                ],
                action: {
                    label: 'Disable',
                    type: 'onClick',
                    className: '',
                    color: 'warn',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        disableipv6(hostingid, subaccount);
                    }
                }
            };
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="ipv6"
            >
                <Box
                    request={{
                        action: checkipv6,
                        args: [hostingid, subaccount],
                        status: hosting_ipv6_check_status
                    }}
                    className="ipv6__box"
                    title="IPv6 Management"
                    status={loadingStatus()}
                    info={`
                        Enabling IPv6 on your cPanel service will create an equivalent AAAA record for all pre-existing IPv4 records in your DNS zone file; these records will resolve to your dedicated IPv6 address. It will also adjust any pre-existing IPv6 DNS records to your new dedicated IPv6. If you are pointing any domains or subdomains to another hosting provider from within the zone editor, it is important you manually adjust these values after enabling your IPv6 connectivity.
                        <br><br>
                        If you are using free DNS Hosting or hosting your DNS records with a third party, remember to update your DNS with the AAAA record that’s generated for you.
                    `}
                    {...conditionalProps}
                />

                {showConfirmLightbox ? (
                    <OverlayLightbox
                        onOpen={showConfirmLightbox}
                        title="Enable IPv6 Management?"
                        warningMsg={`If you are unsure of it's functionality, please contact our Technical Support team via <Anchor href="/support/tickets/submit/technical-support">${ticket_name}</Anchor> prior to enabling it.`}
                        loading={hosting_ipv6_status}
                        confirm={confirmIpv6Data}
                        onClose={closeLightbox}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_ipv6_check_status: state.hosting.hosting_ipv6_check_status,
        hosting_ipv6_check_data: state.hosting.hosting_ipv6_check_data,
        hosting_ipv6_check_error: state.hosting.hosting_ipv6_check_error,
        hosting_ipv6_status: state.hosting.hosting_ipv6_status,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            checkipv6,
            disableipv6,
            enableipv6
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IPv6Management));
