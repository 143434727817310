/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaFileFormFieldDisplayOnly } from 'containers/katana/formFields/file/inputTypes/display_only';
import { KatanaFileFormFieldNXDropZone } from 'containers/katana/formFields/file/inputTypes/dropzone';
import { KatanaFileFormFieldNXDropZoneCompact } from 'containers/katana/formFields/file/inputTypes/dropzone_compact';
import { KatanaFileFormFieldNXDropZoneOrStockPhoto } from 'containers/katana/formFields/file/inputTypes/dropzone_or_stock_photo';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FilePropertyInputType } from 'containers/katana/formFields/file/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaFileFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    switch (input_type) {
        case FilePropertyInputType.DISPLAY_ONLY:
            return <KatanaFileFormFieldDisplayOnly property={property} />;

        case FilePropertyInputType.DROPZONE:
            return <KatanaFileFormFieldNXDropZone property={property} />;

        case FilePropertyInputType.DROPZONE_OR_STOCK_PHOTO:
            return <KatanaFileFormFieldNXDropZoneOrStockPhoto property={property} />;

        case FilePropertyInputType.DROPZONE_COMPACT:
            return <KatanaFileFormFieldNXDropZoneCompact property={property} />;

        default:
            return <InvalidInputType name={name} inputType={input_type} fieldType="File" />;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormField };
