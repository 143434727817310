/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { billingCycles } from 'config/config';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import { CheckBox } from 'components/Form/CheckBox';
import SimpleTable from 'components/SimpleTable';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getCurrentDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_changeResourcesFormConfirmLightbox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Lightbox to confirm breakdown of hosting change resources
 */
let ChangeResourcesFormConfirmLightbox = ({
    selectedBillingCycle,
    submitChangeRequest,
    /* Redux props */
    custom_hosting_calculate_data
}) => {
    /***** STATE *****/
    const [isAcknowledgementChecked, setIsAcknowledgementChecked] = useState(false);

    /***** RENDER HELPERS *****/
    if (!custom_hosting_calculate_data) return '';

    const {
        credit_unused,
        overpay_amount,
        days_unused,
        discount,
        total_due,
        total_credit_applied,
        new_plan_cost,
        additional_credit_used,
        is_credit_back
    } = custom_hosting_calculate_data;

    const rows = [
        [
            <Text key={1}>
                <Text primary bold>
                    NEW
                </Text>
                &nbsp;
                <Text secondary>Cost</Text>
            </Text>,
            <Text key={2} medium>
                ${new_plan_cost}
                {billingCycles[selectedBillingCycle]}
            </Text>
        ]
    ];

    if (custom_hosting_calculate_data?.is_billing_cycle_reset) {
        rows.push([
            <Text key={3}>
                <Text primary bold>
                    NEW
                </Text>
                &nbsp;
                <Text secondary>Billing Cycle Period</Text>
            </Text>,
            <Text key={4} medium>
                {custom_hosting_calculate_data?.new_period?.start_date || ''} - {custom_hosting_calculate_data?.new_period?.end_date || ''}
            </Text>
        ]);
    } else {
        rows.push([
            <Text secondary key={3} span>
                Existing Billing Cycle Period
            </Text>,
            <Text key={4} medium>
                {custom_hosting_calculate_data?.old_period?.start_date || ''} - {custom_hosting_calculate_data?.old_period?.end_date || ''}
            </Text>
        ]);
    }

    // Conditional table rows
    if (total_credit_applied !== '0.00') {
        rows.push([
            <Flex key={1} items="center" fullWidth>
                <Text secondary className="changeResourcesFormConfirmLightbox__tooltipContainer">
                    Credit for unused time
                </Text>
                <Tooltip
                    info={`You still have ${days_unused} days of your current billing period remaining. You will be credited $${credit_unused} for these unused days, ${
                        credit_unused === total_credit_applied ? 'all of which' : `of which $${total_credit_applied}`
                    } will go towards the new service cost.`}
                />
            </Flex>,
            <Text key={2} notice semiBold>
                -${total_credit_applied}
            </Text>
        ]);
    }

    if (discount !== '0.00') {
        rows.push([
            <Text key={1} secondary>
                Promotional discount
            </Text>,
            <Text key={2} notice semiBold>
                -${discount}
            </Text>
        ]);
    }

    if (additional_credit_used !== '0.00') {
        rows.push([
            <Text key={1} secondary>
                Additional account credit used
            </Text>,
            <Text key={2} notice semiBold>
                -${additional_credit_used}
            </Text>
        ]);
    }

    /***** RENDER *****/
    return (
        <div className="changeResourcesFormConfirmLightbox">
            <Text semiBold>Price Breakdown</Text>
            <SimpleTable rows={rows} />

            <div className="changeResourcesFormConfirmLightbox__due">
                <div className="changeResourcesFormConfirmLightbox__dueTop">
                    <Text white bold>
                        Amount Due
                    </Text>
                    <Text white bold>
                        <p>${total_due}</p>
                    </Text>
                </div>
                <div className="changeResourcesFormConfirmLightbox__dueBottom">
                    <Text bold>Due Date</Text>
                    <Text bold>{total_due === '0.00' ? '-' : getCurrentDate().plus({ days: 10 }).toFormat('dd/LL/yyyy')}</Text>
                </div>
            </div>

            {is_credit_back && overpay_amount !== '0.00' ? (
                <SimpleTable
                    rows={[
                        [
                            <Flex key={1} items="center">
                                <Text secondary className="changeResourcesFormConfirmLightbox__tooltipContainer">
                                    Unused refunded credit
                                </Text>
                                <Tooltip
                                    info={`You still have ${days_unused} days of your current billing period remaining. You will be credited $${credit_unused} for these unused days, ${
                                        credit_unused === overpay_amount ? 'all of which' : `of which $${overpay_amount}`
                                    } will be refunded as account credit.`}
                                />
                            </Flex>,
                            <Text key={2} medium lead--none>
                                ${overpay_amount}
                            </Text>
                        ]
                    ]}
                />
            ) : (
                ''
            )}

            <AcknowledgementBox>
                <CheckBox isChecked={isAcknowledgementChecked} onChange={() => setIsAcknowledgementChecked((prev) => !prev)} name="acknowledgement">
                    <Text secondary size--s>
                        <p>
                            This change plan request cannot be cancelled after it has been initiated, please ensure you are happy with these changes
                            and price before confirming. Once commenced you will immediately receive your new configuration and billing cycle. If
                            payment has not been received in 7 days, your service will be suspended and terminated.
                        </p>
                    </Text>
                </CheckBox>
            </AcknowledgementBox>

            {isAcknowledgementChecked ? (
                <SolidButton type="onClick" onClick={submitChangeRequest}>
                    Generate Invoice
                </SolidButton>
            ) : (
                <InactiveButton>Generate Invoice</InactiveButton>
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ChangeResourcesFormConfirmLightbox.propTypes = {
    // The new billing cycle that's been chosen for the upgrade
    selectedBillingCycle: PropTypes.string,

    // The upgrade submit request
    submitChangeRequest: PropTypes.func
};

const mapStateToProps = (state) => ({
    custom_hosting_calculate_data: state.hosting.custom_hosting_calculate_data
});

ChangeResourcesFormConfirmLightbox = connect(mapStateToProps)(ChangeResourcesFormConfirmLightbox);

export default ChangeResourcesFormConfirmLightbox;
