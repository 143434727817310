/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';
import { KatanaTextFormFieldText } from 'containers/katana/formFields/text/inputTypes/text';
import { KatanaTextFormFieldTextArea } from 'containers/katana/formFields/text/inputTypes/textarea';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/text/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { Katana } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaTextFormField: React.FC<Katana.SectionDefinitions.PropertiesModified> = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    switch (input_type) {
        case SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE.TEXT:
            return <KatanaTextFormFieldText property={property} />;

        case SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE.TEXTAREA:
            return <KatanaTextFormFieldTextArea property={property} />;

        case SITE_SECTION_TEXT_PROPERTY_INPUT_TYPE.RICH_TEXT:
            return <KatanaTextFormFieldTextArea property={property} />;

        default:
            return <InvalidInputType name={name} inputType={input_type} fieldType="Text" />;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
