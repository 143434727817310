/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';
import SimpleTable from 'components/SimpleTable';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ConditionalRender } from 'components/Utils/ConditionalRender';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *  ReviewPlan - Private Component used within ChangePlan to display a review of the current plan
 *
 *  This can be overridden in the ChangePlan Component by passing the prop `composableReviewPlan` with a ReactNode
 *  which will be displayed instead. A Composable version of this component is also available for more complex use cases
 */
const ReviewPlan = ({
    name,
    rawData,
    currentPlan,
    cycle,
    refund,
    customConfirm,
    customReviewElement,
    onConfirm,
    onCancel,
    onGoBack,
    price = '0.00',
    total = '0.00',
    isDowngrade = false
}) => {
    /***** STATE *****/
    const [isLoading, setIsLoading] = useState(true);

    /***** FUNCTIONS *****/
    const columns = '40% 40% auto';

    const onClick = () => {
        setIsLoading(true);
        onConfirm({ name, cycle, rawData });
    };

    const insertIf = (condition, element) => (condition ? [element] : []);

    /***** EFFECTS *****/
    useEffect(() => {
        if (name && price && total) setIsLoading(false);
    }, [name, price, total]);

    /***** RENDER *****/
    if (isLoading) return <RequestLoader />;

    return (
        <div className="reviewPlan">
            <SimpleTable
                rows={React.Children.toArray([
                    <Grid columns={columns}>
                        <Text secondary>Current Plan</Text>
                        <Text>{currentPlan}</Text>
                    </Grid>,
                    <Grid columns={columns}>
                        <span>
                            <Text primary bold>
                                <span>NEW</span>
                            </Text>
                            <Text secondary>
                                <span> Plan</span>
                            </Text>
                        </span>
                        <Text>{name}</Text>
                        <Anchor onClick={onGoBack}>Revise</Anchor>
                    </Grid>,
                    <Grid columns={columns}>
                        <Text secondary>Billing Cycle</Text>
                        <Text>{cycle}</Text>
                    </Grid>,
                    <Grid columns={columns}>
                        <Text secondary>Plan Cost</Text>
                        <Text>${price}</Text>
                    </Grid>,
                    ...insertIf(
                        refund,
                        <Grid columns={columns}>
                            <Text secondary>Refunded Amount</Text>
                            <Text>${refund}</Text>
                        </Grid>
                    )
                ])}
            />
            <div className="reviewPlan__total">
                <Padding x={0} y={3}>
                    <Text bold secondary size--s>
                        {`TOTAL ${isDowngrade ? 'DOWNGRADE' : 'UPGRADE'} COST`}
                    </Text>
                </Padding>
                <Text bold primary>
                    <span>
                        ${total}
                        <Text bold primary size--xss>
                            <span> AUD</span>
                        </Text>
                    </span>
                </Text>
            </div>
            <ConditionalRender condition={!!customReviewElement} onTrue={<div>{customReviewElement}</div>} />
            <ConditionalRender
                condition={!!customConfirm}
                onTrue={customConfirm?.({ onClick })}
                onFalse={
                    <SolidButton type="onClick" onClick={onClick}>
                        Confirm {isDowngrade ? 'Downgrade' : 'Upgrade'}
                    </SolidButton>
                }
            />
            <Text uppercase>
                <Anchor className="reviewPlan__cancel" color="primary" onClick={onCancel}>
                    Cancel {isDowngrade ? 'Downgrade' : 'Upgrade'}
                </Anchor>
            </Text>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ReviewPlan.propTypes = {
    // required - name of the plan
    name: PropTypes.string,

    // required - billing cycle
    cycle: PropTypes.string,

    // required - current plan name
    currentPlan: PropTypes.string,

    // required - raw data of the plan
    rawData: PropTypes.object,

    // required - function to call when confirming the plan
    onConfirm: PropTypes.func,

    // optional - custom function that returns a jsx element to act as the confirm button
    customConfirm: PropTypes.func,

    // optional - custom jsx element to display in the review plan, this goes directly above the confirm button
    customReviewElement: PropTypes.element,

    // required - function to call when cancelling the upgrade/downgrade
    onCancel: PropTypes.func,

    // required - function to call when going back to the previous step
    onGoBack: PropTypes.func,

    // optional - price of the plan
    price: PropTypes.string,

    // optional - refund amount
    refund: PropTypes.string,

    // optional - total cost of the plan
    total: PropTypes.string,

    // optional - is this a downgrade
    isDowngrade: PropTypes.bool
};

export default ReviewPlan;
