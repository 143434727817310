/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Accordion from 'components/Accordion';
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { sslDownloadZip } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Certificate extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = createRef();
        this.handleDownload = this.handleDownload.bind(this);
        this.state = {
            private_key: '',
            ca_bundle: '',
            cert: ''
        };
    }

    handleDownload(id) {
        const { sslDownloadZip } = this.props;
        sslDownloadZip(id);
    }

    componentDidMount() {
        const { ssl_information_data } = this.props;

        if (ssl_information_data) {
            const { attributes } = ssl_information_data;
            const { ca_bundle, cert, private_key } = attributes;

            this.setState({
                ca_bundle,
                cert,
                private_key
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { ssl_information_status, ssl_information_data } = this.props;
        registerScrollEvents(this, ssl_information_status === 'success' && prevProps.ssl_information_status === 'loading');

        if (ssl_information_status === 'success' && prevProps.ssl_information_status === 'loading') {
            const { attributes } = ssl_information_data;
            const { ca_bundle, cert, private_key } = attributes;

            this.setState({
                ca_bundle,
                cert,
                private_key
            });
        }
    }

    render() {
        const { sslid } = this.props;
        const { ca_bundle, cert, private_key } = this.state;
        const { handleDownload } = this;

        const renderPrivateKey = () => {
            return (
                <div className="form__item">
                    <div className="form__item__inner">
                        <div className="wrapperbox readonly">
                            <textarea className="ssl__certificate__details" readOnly="readOnly" value={private_key} />
                        </div>
                    </div>
                </div>
            );
        };

        const renderCaBundle = () => {
            return (
                <div className="form__item">
                    <div className="form__item__inner">
                        <div className="wrapperbox readonly">
                            <textarea className="ssl__certificate__details" readOnly="readOnly" value={ca_bundle} />
                        </div>
                    </div>
                </div>
            );
        };

        const renderSsl = () => {
            return (
                <div className="form__item">
                    <div className="form__item__inner">
                        <div className="wrapperbox readonly">
                            <textarea className="ssl__certificate__details" readOnly="readOnly" value={cert} />
                        </div>
                    </div>
                </div>
            );
        };

        /**********************************************************************************************************
         *   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="sslDetails"
            >
                <Box
                    premounted={true}
                    className="sslDetails__box"
                    title="SSL Certificate Details"
                    desc="If you wish to manually install your SSL certificate, use the details below."
                    custom={{
                        render: (
                            <Fragment>
                                <div className="sslDetails__accordion">
                                    {private_key ? (
                                        <Accordion
                                            title="Private Key"
                                            content={renderPrivateKey()}
                                            info="The private key is used to generate a Certificate Signing Request (CSR), and secure and verify connections using the certificate created per that request."
                                            copyContent={private_key}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <Accordion
                                        title="CA Bundle"
                                        content={renderCaBundle()}
                                        info="CA bundle is a file that contains root and intermediate certificates."
                                        copyContent={ca_bundle}
                                    />
                                    <Accordion
                                        title="SSL Certificate"
                                        content={renderSsl()}
                                        info="SSL Certificates are data files that digitally bind a cryptographic key to an organisation’s details."
                                        copyContent={cert}
                                    />
                                </div>
                                <div className="sslDetails__footer">
                                    <div className="sslDetails__footer--desc">
                                        You can download a ZIP archive of this SSL certificate, making it easy to pass on to others.
                                    </div>
                                    <SolidButton
                                        type="onClick"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDownload(sslid);
                                        }}
                                        color="secondary"
                                    >
                                        Download SSL Certificate
                                    </SolidButton>
                                </div>
                            </Fragment>
                        ),
                        pos: 'bottom'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Certificate = reduxForm({
    form: 'Certificate'
})(Certificate);

const mapStateToProps = (state) => {
    return {
        ssl_information_status: state.ssl.ssl_information_status,
        ssl_information_data: state.ssl.ssl_information_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            sslDownloadZip
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Certificate));
