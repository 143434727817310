/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'billingCycleForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class BillingCycleForm extends Component {
    render() {
        const { handleSubmit, options } = this.props;

        return (
            <form className="popup__form billingCycle--form" onSubmit={handleSubmit}>
                <Field
                    label="Select New Billing Cycle"
                    name="newBillingCycle"
                    component={RenderSelectField}
                    validate={[requiredFieldValidation]}
                    type="select"
                    className="form__dropdown"
                    options={options}
                />
                <ReduxFormButton form={formName}>Confirm Billing Cycle</ReduxFormButton>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
BillingCycleForm = reduxForm({
    form: formName
})(BillingCycleForm);

export default withRouter(
    connect((state) => {
        const initialValues = {
            newBillingCycle: state.hosting.hosting_information_data?.attributes?.billing_cycle_name
        };

        return {
            initialValues
        };
    })(BillingCycleForm)
);
