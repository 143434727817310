/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FileRenderer from 'components/FileRenderer';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const baseClass = 'NXDropZone__fileRendererWrapper';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      file: import('react-dropzone').FileWithPath;
 *      index?: number;
 *      removeFile: (index: number) => void;
 * }>}
 */
const NXDropZoneFileRenderer = ({ file, index = -1, removeFile }) => {
    /***** RENDER *****/
    if (Array.isArray(file)) {
        return;
    }

    if (!file?.type) {
        return 'file type not supported';
    }

    const flatType = file.type.replaceAll('/', '');
    return (
        <div className={classNames(baseClass, `${baseClass}--${flatType}`)}>
            <div className={`${baseClass}__wrapperChild`}>
                <FileRenderer file={file} />
                <button
                    type="button"
                    className="NXDropZone__removeFileButton"
                    onClick={() => {
                        removeFile(index);
                    }}
                >
                    <PhosphorIcons.Trash.Bold white />
                </button>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { NXDropZoneFileRenderer };
