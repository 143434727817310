/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CompanyThemeConfigObject } from 'config/tokens';

const primitive = {
    'P_color_grey_50': '#f5f7f8',
    'P_color_grey_80': '#eaeaea',
    'P_color_grey_100': '#f5f6f7',
    'P_color_grey_125': '#f0f1f3',
    'P_color_grey_200': '#d7d7d7',
    'P_color_grey_300': '#bebebe',
    'P_color_grey_400': '#abb2ba',
    'P_color_grey_450': '#919ba7',
    'P_color_grey_700': '#5c6875',
    'P_color_grey_800': '#686868',
    'P_color_orange_100': '#fdf1da',
    'P_color_orange_550': '#feb41c',
    'P_color_orange_650': '#d69717',
    'P_color_white': '#ffffff',
    'P_color_transparent': 'transparent',
    'P_color_skyBlue_400': '#54a9ef',
    'P_color_skyBlue_450': '#5dadf0',
    'P_color_skyBlue_500': '#2c98f0',
    'P_color_navy_500': '#12143e',
    'P_color_green_500': '#2bce78',
    'P_color_green_600': '#31b971',
    'P_color_blue_100': '#e3ecff',
    'P_color_blue_200': '#adc1f0',
    'P_color_blue_300': '#779df5',
    'P_color_blue_500': '#1256fa',
    'P_color_blue_600': '#104bd5',
    'P_color_lilac_500': '#f1f1fb',
    'P_color_lilac_550': '#e0e0f0',
    'P_color_red_600': '#ff5053',
    'P_color_red_700': '#eb5557',
    'P_color_black': '#000000',
    'P_border-radius_2': '2px',
    'P_border-radius_5': '5px',
    'P_border-radius_6': '6px',
    'P_border-radius_10': '10px',
    'P_border-radius_15': '15px',
    'P_border-radius_50': '50px',
    'P_padding_15': '15px',
    'P_margin_20': '20px',
    'P_box-shadow_none': 'none'
} satisfies Record<string, string>;

const semantic = {
    'S_color_background_primary_base': primitive['P_color_blue_500'],
    'S_color_background_primary_hover': primitive['P_color_blue_600'],
    'S_color_background_secondary_base': primitive['P_color_grey_200'],
    'S_color_background_secondary_hover': primitive['P_color_grey_200'],
    'S_color_background_secondary_light': primitive.P_color_white,
    'S_color_background_secondary-alt_base': primitive.P_color_grey_200,
    'S_color_background_secondary-alt_hover': primitive.P_color_grey_200,
    'S_color_background_tertiary_base': primitive['P_color_lilac_500'],
    'S_color_background_tertiary_hover': primitive.P_color_lilac_550,
    'S_color_background_confirm_base': primitive.P_color_green_500,
    'S_color_background_confirm_hover': primitive.P_color_green_600,
    'S_color_background_notice_base': primitive['P_color_orange_550'],
    'S_color_background_notice_hover': primitive['P_color_orange_650'],
    'S_color_background_warn_base': primitive['P_color_red_600'],
    'S_color_background_warn_hover': primitive['P_color_red_700'],
    'S_color_background_info_base': primitive.P_color_skyBlue_400,
    'S_color_background_info_hover': primitive.P_color_skyBlue_450,
    'S_color_background_default_base': primitive['P_color_white'],
    'S_color_background_grey_base': primitive.P_color_grey_450,
    'S_color_background_black_base': primitive.P_color_black,
    'S_color_text_default_base': primitive['P_color_navy_500'],
    'S_color_text_inverse_base': primitive['P_color_white'],
    'S_color_text_inverse_light': primitive['P_color_grey_200'],
    'S_color_text_primary_base': primitive['P_color_navy_500'],
    'S_color_text_primary_hover': primitive.P_color_navy_500,
    'S_color_text_secondary_base': primitive.P_color_grey_800,
    'S_color_text_secondary_hover': primitive.P_color_black,
    'S_color_text_confirm_base': primitive['P_color_green_500'],
    'S_color_text_confirm_hover': primitive.P_color_green_600,
    'S_color_text_notice_base': primitive['P_color_orange_550'],
    'S_color_text_notice_hover': primitive.P_color_orange_650,
    'S_color_text_warn_base': primitive['P_color_red_600'],
    'S_color_text_warn_hover': primitive.P_color_red_700,
    'S_color_text_info_base': primitive.P_color_skyBlue_400,
    'S_color_text_grey_base': primitive.P_color_grey_450,
    'S_color_icon_primary_base': primitive.P_color_blue_500,
    'S_color_icon_secondary_base': primitive['P_color_grey_800'],
    'S_color_icon_confirm_base': primitive['P_color_green_500'],
    'S_color_icon_notice_base': primitive.P_color_orange_550,
    'S_color_border_default_base': primitive['P_color_grey_300'],
    'S_color_border_primary_base': primitive['P_color_blue_500'],
    'S_color_border_secondary_base': primitive['P_color_grey_200'],
    'S_color_border_secondary-alt_base': primitive.P_color_grey_200,
    'S_color_border_confirm_base': primitive.P_color_green_500,
    'S_color_border_notice_base': primitive.P_color_orange_550,
    'S_color_border_warn_base': primitive.P_color_red_600,
    'S_color_border_info_base': primitive.P_color_skyBlue_400,
    'S_color_border_grey_base': primitive.P_color_grey_450,
    'S_color_border_black_base': primitive.P_color_black,
    'S_color_border_white_base': primitive.P_color_white,
    'S_color_border_transparent': primitive.P_color_transparent,
    'S_border-radius_default': primitive['P_border-radius_10'],
    'S_border-radius_rounded': primitive['P_border-radius_50']
} satisfies Record<CompanyThemeConfigObject.SemanticTokens, string>;

const component: Record<CompanyThemeConfigObject.ComponentTokens, string> = {
    'C_Subnav_color_background_base': primitive['P_color_white'],
    'C_Subnav_color_background_active': semantic['S_color_background_tertiary_base'],
    'C_Subnav__option--activeBefore_color_background': primitive['P_color_transparent'],
    'C_Subnav_color_background_activeMobile': semantic.S_color_background_tertiary_hover,
    'C_Subnav_color_text_base': semantic['S_color_text_primary_base'],
    'C_Subnav_color_text_active': semantic['S_color_text_primary_base'],
    'C_Subnav_color_border': semantic['S_color_border_default_base'],
    'C_Title_color_background': primitive['P_color_white'],
    'C_Title_color_border': semantic['S_color_border_default_base'],
    'C_Title_borderRadius': semantic['S_border-radius_default'],
    'C_Title_backButtonContainer_color_background': primitive['P_color_transparent'],
    'C_Title_backButton_color_background': semantic.S_color_background_secondary_base,
    'C_Title_backButton_color_text': semantic['S_color_text_secondary_base'],
    'C_Title_backButton_color_border': primitive['P_color_transparent'],
    'C_BaseButton_border-radius_default': semantic['S_border-radius_default'],
    'C_OutlineButton_color_border_primary': semantic['S_color_border_default_base'],
    'C_OutlineButton_color_background_primary_hover': primitive.P_color_transparent,
    'C_OutlineButton_color_text_primary_base': semantic['S_color_text_primary_base'],
    'C_OutlineButton_color_text_primary_hover': semantic.S_color_text_primary_base,
    'C_OutlineButton_color_border_secondary': semantic.S_color_border_secondary_base,
    'C_OutlineButton_color_background_secondary_hover': semantic.S_color_background_secondary_base,
    'C_OutlineButton_color_text_secondary_base': primitive['P_color_grey_200'],
    'C_OutlineButton_color_text_secondary_hover': semantic['S_color_text_inverse_base'],
    'C_SolidButton_color_background_secondary': semantic['S_color_background_secondary_base'],
    'C_SolidButton_color_background_secondary_hover': semantic['S_color_background_secondary_hover'],
    'C_SolidButton_color_text_secondary_base': semantic['S_color_text_secondary_base'],
    'C_SolidButton_color_text_secondary_hover': semantic['S_color_text_secondary_base'],
    'C_InactiveButton_color_text': primitive.P_color_grey_450,
    'C_InactiveButton_color_background': primitive.P_color_grey_100,
    'C_InactiveButton_color_border': semantic.S_color_border_default_base,
    'C_2FARadioButton_color_background': semantic.S_color_background_default_base,
    'C_2FARadioButton_color_background_selected': semantic.S_color_background_primary_base,
    'C_2FARadioButton_color_border_selected': semantic.S_color_background_primary_base,
    'C_2FARadioButtonContainer_color_background_hover': semantic.S_color_background_default_base,
    'C_Sidebar_color_border': semantic['S_color_border_default_base'],
    'C_Sidebar_color_background': primitive['P_color_transparent'],
    'C_Sidebar__heading_color_text': semantic['S_color_text_default_base'],
    'C_Sidebar__listItem_color_background_active': semantic['S_color_background_tertiary_base'],
    'C_Sidebar__listItem_color_text_base': semantic['S_color_text_secondary_base'],
    'C_Sidebar__listItem_color_text_active': semantic['S_color_text_primary_base'],
    'C_Sidebar__listItem--selected_color': primitive['P_color_transparent'],
    'C_MobileSidebar_margin-top': primitive['P_margin_20'],
    'C_Anchor_color_text_base': primitive['P_color_skyBlue_500'],
    'C_Anchor_color_text_hover': primitive['P_color_skyBlue_500'],
    'C_NXBox_border-radius_default': semantic['S_border-radius_default'],
    'C_NXBox_border-radius_defaultMobile': primitive['P_border-radius_15'],
    'C_NXBox_color_border': primitive['P_color_transparent'],
    'C_NXBox__subheading_color_text': semantic['S_color_text_primary_base'],
    'C_NXBoxSplit__section_color_border': primitive['P_color_transparent'],
    'C_NXBoxBottom__container_color_background': primitive['P_color_white'],
    'C_NXBoxBottom_padding': primitive['P_padding_15'],
    'C_NXBoxBottom_border-radius_default': semantic['S_border-radius_default'],
    'C_NXTable__subHeading_color_background': semantic.S_color_background_tertiary_base,
    'C_NXTable__subHeading_color_text': primitive.P_color_grey_125,
    'C_NXTable_color_background': semantic.S_color_background_default_base,
    'C_NXTable__row_color_text': semantic.S_color_text_primary_base,
    'C_Input_border-radius': primitive['P_border-radius_5'],
    'C_Input_color_background': primitive['P_color_white'],
    'C_Input_color_text': semantic['S_color_text_primary_base'],
    'C_Input__disabled_color_text': primitive.P_color_grey_400,
    'C_Checkbox_border-radius_default': primitive['P_border-radius_2'],
    'C_Select__listItem--selected_color_background': semantic['S_color_background_tertiary_base'],
    'C_SelectDropdown_color_background': semantic.S_color_background_secondary_base,
    'C_SelectDropdown_color_text': semantic.S_color_text_default_base,
    'C_SelectDropdown_color_border': semantic.S_color_border_default_base,
    'C_StyledLoader_color_background': semantic.S_color_background_tertiary_hover,
    'C_Checkbox_color_border': primitive.P_color_grey_80,
    'C_accounts__indicator_color_background': primitive.P_color_blue_100,
    'C_descriptionText_color_text': semantic['S_color_text_primary_base'],
    'C_NXTableRow_color_background': primitive.P_color_lilac_550,
    'C_NXTableRow__alternator_color_background': semantic.S_color_background_tertiary_base,
    'C_NXTableRow__alternator_color_background_mobile': semantic.S_color_background_tertiary_base,
    'C_PricingTable_color_background': primitive['P_color_grey_50'],
    'C_Tabs_color_background': semantic.S_color_background_default_base,
    'C_rangeslider_color_background': primitive['P_color_blue_100'],
    'C_changeResourcesFormAllocations__names_color_background': primitive['P_color_grey_800'],
    'C_changeResourcesFormAllocations__currentValues_color_background': primitive['P_color_grey_100'],
    'C_changeResourcesFormAllocations__currentValuesHeading_color_background': primitive['P_color_grey_300'],
    'C_changeResourcesFormAllocations__newValues_color_background': primitive['P_color_blue_200'],
    'C_changeResourcesFormAllocations__newValuesHeading_color_background': primitive['P_color_blue_300'],
    'C_hostingDetails__tabSelectorOption_color_background_hover': primitive['P_color_white'],
    'C_accountLoginHistory__tableHeader_color_text': semantic.S_color_text_default_base,
    'C_SolidTag_color_text': primitive.P_color_white,
    'C_accounts__dropdown--name_color_text': semantic.S_color_text_default_base,
    'C_Tooltip__content_color_background': primitive.P_color_grey_800,
    'C_Tooltip_border-radius': primitive['P_border-radius_6'],
    'C_Search_color_background': primitive.P_color_white,
    'C_Search_border-radius': primitive['P_border-radius_5'],
    'C_invoiceView__tag_color_background_secondary': primitive.P_color_grey_50,
    'C_invoiceView__info_color_background_mobile': primitive.P_color_grey_50,
    'C_invoiceView__tag_border-radius': semantic['S_border-radius_default'],
    'C_invoicePay__items_box-shadow': primitive['P_box-shadow_none'],
    'C_paymentMethods__methodSelected_color_background': semantic.S_color_background_tertiary_hover,
    'C_ticketList__openTicket_color_background': semantic.S_color_background_tertiary_base,
    'C_ticketViewMessage__body--customer_color_background': primitive.P_color_orange_100,
    'C_ticketViewMessage__body--worker_color_background': semantic.S_color_background_tertiary_base,
    'C_ticketForm__waitTime_color_background': primitive.P_color_white,
    'C_login__supportLink_color_text': primitive.P_color_skyBlue_450
} satisfies Record<CompanyThemeConfigObject.ComponentTokens, string>;

const intaserve = {
    ...primitive,
    ...semantic,
    ...component
} satisfies CompanyThemeConfigObject.WithLocalPrimitives<typeof primitive>;

export const __DO_NOT_USE_IN_PRODUCTION_OR_YOU_WILL_BE_FIRED__INTASERVE__CONFIG__ = intaserve;
