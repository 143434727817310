/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CollectMissingDetailsForm from './form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { getCountries, getLocalStates } from 'App/action';
import { getSecurityQuestions, getUserSecurityInformation, provideMissingDetails } from 'containers/account/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import PersonAvatarIcon from 'assets/images/dashboard/personAvatar.svg';
import './_collectMissingDetailsContent.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Users that have personal or security details missing from their mercury account must complete this form after logging in, before they can access VIPC
 */
function CollectMissingDetailsContent({ markSelfAsComplete }) {
    /***** HOOKS *****/
    const { account_security_information_status, account_security_questions_status, app_local_states_status, app_countries_status } = useSelector(
        (state) => ({
            account_security_information_status: state.account.account_security_information_status,
            account_security_questions_status: state.account.account_security_questions_status,
            app_local_states_status: state.app.app_local_states_status,
            app_countries_status: state.app.app_countries_status
        })
    );

    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    function handleSubmitMissingDetails(values) {
        const updateUserDetailsAttributes = {
            firstname: values.firstname,
            lastname: values.lastname,
            company: values.company || '',
            address1: values.address1,
            address2: values.address2 || '',
            city: values.city,
            state: values.state,
            postcode: values.postcode,
            country: values.country,
            phone: values.phone
        };

        const updateSecurityDetailsAttributes = {
            question_id: values.question_id,
            answer: values.answer,
            dob: DateTime.fromJSDate(values.dob).toFormat('dd/MM/yyyy')
        };

        provideMissingDetails(updateUserDetailsAttributes, updateSecurityDetailsAttributes, { onSuccess: markSelfAsComplete });
    }

    /***** EFFECTS *****/
    useEffect(() => {
        dispatch(getUserSecurityInformation());
        dispatch(getSecurityQuestions());
        getLocalStates();
        getCountries();
    }, []);

    /***** RENDER HELPERS *****/
    const renderForm = () => {
        const statuses = [account_security_information_status, account_security_questions_status, app_local_states_status, app_countries_status];

        if (statuses.includes('loading') || statuses.some((status) => !status)) return <RequestLoader message="Checking account details..." />;
        // If any of "questions list", "states" or "countries" requests fail, cant show the form. If only "security info" errors, we can still show it
        if (statuses.slice(1).includes('error')) {
            return (
                <>
                    <FetchComponentError />
                    <Anchor className="collectMissingDetailsContent__errorSkipLink" onClick={markSelfAsComplete}>
                        Skip for now
                    </Anchor>
                </>
            );
        }

        return <CollectMissingDetailsForm onSubmit={handleSubmitMissingDetails} />;
    };

    /***** RENDER *****/
    return (
        <div className="collectMissingDetailsContent">
            <div className="collectMissingDetailsContent__container">
                <img src={PersonAvatarIcon} alt="Personal Details" />
                <Text className="collectMissingDetailsContent__title" size--xl semiBold lead--xs align--center>
                    <h3>We need a few more details from you</h3>
                </Text>
                <Text secondary size--s lead--s align--center>
                    It looks like we&apos;re missing some information for your account. Please fill in the missing details below. While you&apos;re
                    there, please feel free to amend any existing details that seem incorrect.
                </Text>
            </div>

            {renderForm()}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CollectMissingDetailsContent.propTypes = {
    /**
     * Function that marks "userDetails" item as complete in the "postLoginChecklist"
     */
    markSelfAsComplete: PropTypes.func
};

export default CollectMissingDetailsContent;
