/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ventraSynMigrationBanners } from 'config/config';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Invoices from 'containers/billing/modules/invoices';
import VentraSynBanner from 'containers/ventraSynMigration/modules/banner';
import { Account } from './Components/account';
import Tickets from './Components/etickets';
import { ExpressServiceManager } from './Components/expressServiceManager';
import OverdueInvoices from './Components/overdueInvoices';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import Page from 'components/Page';

/*   ACTIONS
 *****************************************************/
import { checkRequiresMigration } from 'containers/ventraSynMigration/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/***** MEGAMAY24 START *****/
import { MegaMay24Deals } from 'config/containers/promotions/megamay2024/components/deals';
import { activePromotion } from 'utilities/methods/commonActions';
/***** MEGAMAY24 END *****/

import './_dashboard.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.promoDealsRef = createRef();
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { checkRequiresMigration } = this.props;

        if (ventraSynMigrationBanners) checkRequiresMigration();
    }

    render() {
        const { promoDealsRef } = this;
        const { app_user_status, vsm_requires_migration_data } = this.props;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        switch (app_user_status) {
            case 'error':
                return <FetchPageError />;

            case 'success':
                return (
                    <Page className="dashboard">
                        {ventraSynMigrationBanners && vsm_requires_migration_data?.requires_migration && <VentraSynBanner />}
                        <Account promoDealsRef={promoDealsRef} />
                        <OverdueInvoices />
                        <ExpressServiceManager />
                        <Tickets />
                        <Invoices path="/dashboard/invoices" />
                        {/* MEGAMAY24 START */}
                        {activePromotion('megamay2024') ? <MegaMay24Deals promoDealsRef={promoDealsRef} /> : ''}
                        {/* MEGAMAY24 END */}
                    </Page>
                );

            case 'loading':
            default:
                return <RequestLoader />;
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

Dashboard = connect(
    (state) => ({
        app_user_status: state.app.app_user_status,
        vsm_requires_migration_data: state.vsm.vsm_requires_migration_data
    }),
    {
        checkRequiresMigration
    }
)(Dashboard);

export default withRouter(Dashboard);
