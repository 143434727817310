/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChangeResourcesForm from 'containers/hosting/forms/changeResourcesForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';
import DialogNotification from 'components/Notifications/DialogNotification';
import { ScrollableComponent } from 'components/ScrollableComponent';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/*   ACTIONS
 *****************************************************/
import { getHostingBillingCycles, getHostingInformation, resetCalculationStatus, updateCustomHosting } from 'containers/hosting/state/accountActions';
import { getHostingResourceConfig, getHostingResources } from 'containers/hosting/state/baseActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_changeResources.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ChangeResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showResourceDropdown: false,
            invoiceId: null,
            settings: {
                cpu: undefined,
                memory: undefined,
                disk_space: undefined
            }
        };

        this.toggleInvoiceLightbox = this.toggleInvoiceLightbox.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleResourceDropdown = this.toggleResourceDropdown.bind(this);
        this.submitChangeRequest = this.submitChangeRequest.bind(this);
    }

    /************** INVOICE TOGGLES **************/
    toggleInvoiceLightbox(id = null) {
        this.setState({
            invoiceId: id
        });
    }

    closePayInvoiceSuccess() {
        const { getHostingInformation, hostingid } = this.props;

        this.setState(
            {
                showResourceDropdown: false,
                invoiceId: null
            },
            () => {
                getHostingInformation(hostingid);
            }
        );
    }

    /************** RESOURCE TOGGLES **************/
    /**
     * @param {boolean} [state]
     */
    toggleResourceDropdown(state) {
        const { resetCalculationStatus } = this.props;
        this.setState(
            {
                showResourceDropdown: state ? state : !this.state.showResourceDropdown
            },
            () => {
                resetCalculationStatus();
            }
        );
    }

    submitChangeRequest(attributes) {
        const { hostingid, updateCustomHosting } = this.props;
        const { toggleInvoiceLightbox } = this;

        updateCustomHosting(hostingid, attributes, { successCallback: (invoiceId) => toggleInvoiceLightbox(invoiceId) });
    }

    componentDidMount() {
        const { hostingid, getHostingResourceConfig, getHostingResources, hosting_billingcycle_status, getHostingBillingCycles, location } = this.props;

        getHostingResources(hostingid);
        getHostingResourceConfig(hostingid);

        if (!['loading', 'success'].includes(hosting_billingcycle_status)) getHostingBillingCycles(hostingid);

        if (location.pathname.includes('custom-hosting')) {
            const isOpen = new URLSearchParams(location.search).get('open');

            if (isOpen) {
                this.toggleResourceDropdown(true);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { hosting_resource_status, hosting_resource_data, location } = this.props;

        if (location !== prevProps.location && location.pathname.includes('custom-hosting')) {
            const isOpen = new URLSearchParams(location.search).get('open');

            if (isOpen) {
                this.toggleResourceDropdown(true);
            }
        }

        if (hosting_resource_status === 'success' && prevProps.hosting_resource_status === 'loading' && hosting_resource_data) {
            const { config_values } = hosting_resource_data.attributes;

            this.setState({
                settings: {
                    cpu: config_values.cpu?.name,
                    disk_space: config_values.disk_space?.name,
                    memory: config_values.memory?.name
                }
            });
        }
    }

    render() {
        const { hosting_resource_status, hosting_resource_data, custom_hosting_change_status, getHostingInformation, hostingid } = this.props;
        const { showResourceDropdown, invoiceId, settings } = this.state;
        const { toggleInvoiceLightbox, toggleResourceDropdown, closePayInvoiceSuccess, submitChangeRequest } = this;
        const isReady = hosting_resource_status === 'success';

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <ScrollableComponent ready={isReady}>
                <NXBox className="changeResources" initialStatus={hosting_resource_status} isLoading={hosting_resource_status === 'loading'}>
                    {hosting_resource_data?.attributes?.invoice ? (
                        <DialogNotification type="warning" className="changeResources__payInvoiceNotification">
                            <DialogNotification.ButtonsContainer
                                text="You have an outstanding invoice for an upgrade on this service."
                                buttons={[
                                    <SolidButton
                                        key={1}
                                        size="medium"
                                        color="white"
                                        type="onClick"
                                        onClick={() => toggleInvoiceLightbox(hosting_resource_data.attributes.invoice)}
                                    >
                                        Pay Invoice
                                    </SolidButton>
                                ]}
                            />
                        </DialogNotification>
                    ) : (
                        ''
                    )}
                    <NXBox.Top
                        title="Upgrade/Downgrade"
                        description="You can use this tool to upgrade or downgrade your custom hosting service if your current resource allocations no longer meet your needs."
                    />
                    <NXBox.Bottom>
                        <NXBox.BottomColumns
                            columns={[
                                <NXBox.InfoPair key={1} title="CPU" description={settings.cpu || ''} />,
                                <NXBox.InfoPair key={2} title="Memory" description={settings.memory || ''} />,
                                <NXBox.InfoPair key={3} title="Disk Space" description={settings.disk_space || ''} />,
                                hosting_resource_data?.attributes?.invoice ? (
                                    <InactiveButton>Upgrade/Downgrade</InactiveButton>
                                ) : (
                                    <OutlineButton key={4} type="onClick" onClick={() => toggleResourceDropdown()}>
                                        Upgrade/Downgrade
                                    </OutlineButton>
                                )
                            ]}
                        />
                    </NXBox.Bottom>

                    <NXBox.Dropdown isOpen={showResourceDropdown}>
                        <NXBox.DefaultPadding>
                            <Flex direction="column">
                                <NXBox.Dropdown.CloseButton onClose={() => toggleResourceDropdown()} />
                                <ChangeResourcesForm submitChangeRequest={submitChangeRequest} />
                            </Flex>
                        </NXBox.DefaultPadding>
                    </NXBox.Dropdown>
                </NXBox>

                {invoiceId ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceId}
                        invoiceid={invoiceId}
                        onOpen={invoiceId}
                        onClose={() => {
                            toggleInvoiceLightbox();
                            // Refetch hosting information if an upgrade request has just been completed, but the invoice is not paid
                            if (custom_hosting_change_status === 'success') {
                                getHostingInformation(hostingid);
                            }
                        }}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
            </ScrollableComponent>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    hosting_resource_status: state.hosting.hosting_resource_status,
    hosting_resource_data: state.hosting.hosting_resource_data,
    custom_hosting_change_status: state.hosting.custom_hosting_change_status,
    hosting_billingcycle_status: state.hosting.hosting_billingcycle_status
});

const mapDispatchToProps = {
    getHostingResources,
    getHostingResourceConfig,
    resetCalculationStatus,
    getHostingBillingCycles,
    updateCustomHosting,
    getHostingInformation
};

ChangeResources = connect(mapStateToProps, mapDispatchToProps)(ChangeResources);

ChangeResources = withRouter(ChangeResources);

export default ChangeResources;
