/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderCheckboxField, requiredAcceptedValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteSeatDelete extends Component {
    constructor(props) {
        super(props);
        this.propegateFormChange = this.propegateFormChange.bind(this);
    }

    propegateFormChange = (value, name) => {
        const { form } = this.props;
        this.props.dispatch(change(form, name, value));
    };

    render() {
        const { formValues, pristine, submitting, valid, handleSubmit, close, gsuite_mailbox_data } = this.props;
        const { propegateFormChange } = this;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="gsuiteSeatDelete__form">
                <div className="gsuiteSeatDelete__desc">
                    Are you sure you want to delete the Google Workspace user {gsuite_mailbox_data.attributes.primary_email}?
                </div>
                <div className="confirmation__container">
                    <div className="confirmation__input">
                        <Field
                            name="confirm_delete"
                            label="I understand that deleting this user will remove all emails, contacts, calendar entries and tasks that are assosicated with it."
                            component={renderCheckboxField}
                            validate={[requiredAcceptedValidation]}
                            type="checkbox"
                            className="form__textfield"
                            onClick={(e) => {
                                e.preventDefault();
                                propegateFormChange(!formValues.confirm_delete, 'confirm_delete'); // Fix for checkbox not toggling.
                            }}
                        />
                    </div>
                </div>

                {pristine || submitting || !valid ? (
                    <InactiveButton className="gsuiteSeatDelete__submit">Delete User</InactiveButton>
                ) : (
                    <SolidButton
                        color="warn"
                        className="gsuiteSeatDelete__submit"
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        Delete User
                    </SolidButton>
                )}
                <OverlayLightbox.BackLink onClick={close} text="NO, GO BACK" />
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
GSuiteSeatDelete = reduxForm({
    form: 'gsuiteSeatDelete',
    enableReinitialize: true
})(GSuiteSeatDelete);

const mapStateToProps = (state) => {
    const formValues = getFormValues('gsuiteSeatDelete')(state);

    return {
        form: 'gsuiteSeatDelete',
        formValues,
        initialValues: {
            confirm_delete: false
        },
        gsuite_mailbox_data: state.gsuite.gsuite_mailbox_data
    };
};

export default connect(mapStateToProps, null)(GSuiteSeatDelete);
