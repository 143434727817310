/**
 * @param {Array<ValidatorFunction>} validators
 * @param {string} _value
 */
export function performValidations(validators, _value) {
    for (const validator of validators) {
        const validationResult = validator(_value);
        if (validationResult) {
            return validationResult;
        }
    }
}
