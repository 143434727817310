/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { has } from 'lodash';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/*   ACTIONS
 *****************************************************/
import { getPleskDetails } from '../../state/accountActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Details extends Component {
    constructor(props) {
        super(props);
        this.renderDetails = this.renderDetails.bind(this);
        this.processProducts = this.processProducts.bind(this);
        this.state = {
            product: null
        };
    }

    renderDetails(data) {
        const renderRows = [];
        Object.entries(data).forEach((row, index) => {
            if (row[0] && !row[1]) {
                return;
            }

            renderRows.push(
                <div key={index} className="simplifiedTable__row">
                    <div className="simplifiedTable__column title">{row[0]}</div>
                    <div className="simplifiedTable__column">{row[0] === 'Plesk Hostname' ? `https://${row[1]}` : row[1]}</div>
                </div>
            );
        });
        return renderRows;
    }

    processProducts() {
        const { hosting_information_data } = this.props;
        const { product } = this.state;

        if (hosting_information_data) {
            hosting_information_data.included.forEach((item) => {
                if (item.type === 'product' && product !== item.attributes.name.toLowerCase()) {
                    this.setState({
                        product: item.attributes.name.toLowerCase()
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { hosting_plesk_status, hosting_plesk_data } = this.props;
        registerScrollEvents(this, hosting_plesk_status === 'success' && prevProps.hosting_plesk_status === 'loading');

        if (hosting_plesk_status === 'success' && prevProps.hosting_plesk_status === 'loading' && has(hosting_plesk_data, 'included')) {
            const { included } = hosting_plesk_data;
            included.forEach((data) => {
                this.setState({
                    [data.type]: data.attributes
                });
            });
        }
    }

    render() {
        const { hosting_plesk_status, getPleskDetails, hostingid } = this.props;
        const { ftp, plesk, server } = this.state;
        const { renderDetails, processProducts } = this;
        processProducts();

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        if (hosting_plesk_status === 'error') {
            return '';
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="hostingDetails"
            >
                <Box
                    request={{
                        action: getPleskDetails,
                        args: hostingid,
                        status: hosting_plesk_status
                    }}
                    status={hosting_plesk_status}
                    custom={{
                        render: (
                            <div className="hostingDetails__wrapper">
                                <div className="sharedBox__wrapper--top">
                                    <div className="sharedBox__title">Service Details </div>
                                </div>
                                {plesk ? (
                                    <div className="simplifiedTable">
                                        <div className="simplifiedTable__header">
                                            <div className="simplifiedTable__row">
                                                <div className="simplifiedTable__column">Plesk Details</div>
                                            </div>
                                        </div>
                                        <div className="simplifiedTable__body">{renderDetails(plesk)}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {server ? (
                                    <div className="simplifiedTable">
                                        <div className="simplifiedTable__header">
                                            <div className="simplifiedTable__row">
                                                <div className="simplifiedTable__column">Server Details</div>
                                            </div>
                                        </div>
                                        <div className="simplifiedTable__body">{renderDetails(server)}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {ftp ? (
                                    <div className="simplifiedTable">
                                        <div className="simplifiedTable__header">
                                            <div className="simplifiedTable__row">
                                                <div className="simplifiedTable__column">FTP Details</div>
                                            </div>
                                        </div>
                                        <div className="simplifiedTable__body">{renderDetails(ftp)}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ),
                        pos: 'top'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_plesk_status: state.hosting.hosting_plesk_status,
        hosting_plesk_data: state.hosting.hosting_plesk_data,
        hosting_information_data: state.hosting.hosting_information_data,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getPleskDetails
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
