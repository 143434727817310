/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';

function createQueryKey(id, params: KatanaAPI.Katana.ServiceID.Unsplash.GET.Params) {
    // I'm not using a base query key here because the results are not user specific
    return ['katana', 'unsplash', 'search', params.search, params.per_page, params.page];
}

function createQueryOptions(id: number, params: KatanaAPI.Katana.ServiceID.Unsplash.GET.Params) {
    return queryOptions({
        queryKey: createQueryKey(id, params),
        queryFn: () => KATANA_API.katana.unsplash.search.GET(id, params),
        retryOnMount: false,
        staleTime: Infinity,
        enabled: TruthKeys(params),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(id: number, params: KatanaAPI.Katana.ServiceID.Unsplash.GET.Params) {
    return queryClient.prefetchQuery(createQueryOptions(id, params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

function _useQuery(id: number, params: KatanaAPI.Katana.ServiceID.Unsplash.GET.Params) {
    return useQuery({
        ...createQueryOptions(id, params)
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const searchUnsplash = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    createQueryKey,
    createQueryOptions
});
