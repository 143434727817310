import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { formatBytes } from 'utilities/methods/commonActions/formatBytes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      rejectedItem: import('react-dropzone').FileRejection,
 *      maxFileSize: number
 * }>}
 */
const _NXDropZoneRejectedFileListItem = (props) => {
    const { rejectedItem } = props;
    const { name } = rejectedItem.file;

    /***** RENDER *****/
    return (
        <Text size--s warn lead--xs>
            {rejectedItem.errors.map(({ message }) => {
                if (message.includes('File is larger than')) {
                    const num = message.match(/\d+/g)?.[0];
                    if (num) {
                        return (
                            <>
                                {name} is too large to upload.
                                <br />
                                Please reduce the file size below {formatBytes(num)} and try again.
                            </>
                        );
                    }
                }

                if (message.includes('File type must be')) {
                    return `${name} - ${message.split(',').join(', ')}`;
                }

                return `${name} - ${message}`;
            })}
        </Text>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXDropZoneRejectedFileListItem };
