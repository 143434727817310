/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { showNavigation } from 'utilities/consts';

/*   ACTIONS
 *****************************************************/
import { getHostingByStatus } from 'containers/services/action';
import { resetScrollEvents } from 'utilities/methods/commonActions';
import { renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChildNS from '../modules/childns';
import ManageDNS from '../modules/dns';
import DNSSEC from '../modules/dnssec';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DomainDNS extends Component {
    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getHostingByStatus } = this.props;

        getHostingByStatus('active');
    }

    componentWillUnmount() {
        const { resetScrollEvents } = this.props;

        resetScrollEvents();
    }

    render() {
        const { modules } = this.props;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        if (showNavigation) {
            return <div className="domainManage">{renderSectionModules(modules)}</div>;
        }

        return (
            <div className="domainManage">
                <Route exact path="/my-services/domains/manage/dns/:id" component={ManageDNS} />
                <Route exact path="/my-services/domains/manage/child-nameservers/:id" component={ChildNS} />
                <Route exact path="/my-services/domains/manage/dnssec/:id" component={DNSSEC} />
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapDispatchToProps = {
    resetScrollEvents,
    getHostingByStatus
};

export default connect(null, mapDispatchToProps)(DomainDNS);
