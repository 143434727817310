/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { touch } from 'redux-form';
import store from 'store/store';

/**
 * @param {{
 *      e: React.KeyboardEvent | React.MouseEvent;
 *      form: string;
 *      fields: Array<string>;
 *      submitButtonClickEvent?: TContentEditorRenderModules.SubmitButtonClickEvent;
 * }} params
 */
export function handleOnDisabledClick({ e, form, fields, submitButtonClickEvent }) {
    const triggerFieldValidation = touch(form, ...fields);

    if (e instanceof KeyboardEvent && e.key !== 'Enter') {
        return; // Do not trigger touch if key is not "Enter"
    }

    submitButtonClickEvent?.set?.(e);

    store.dispatch(triggerFieldValidation);
}
