/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show the domain status
 * @param {{
 *      status: 'Active' | 'Expired' | 'Expiring'
 * }} props
 */
export function DomainStatus({ status }) {
    /***** RENDER *****/
    return (
        <NXBox.OverviewRow>
            <NXBox.InfoPair
                title="Status"
                description={
                    <Text bold confirm={status === 'Active'} warn={['Expired', 'Expiring'].includes(status)}>
                        {status}
                    </Text>
                }
            />
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainStatus;
