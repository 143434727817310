/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { vpsMethods, vpsServerTypes } from 'containers/vps/methods';
import Account from 'containers/vps/sections/account';
import Addons from 'containers/vps/sections/addons';
import Admin from 'containers/vps/sections/admin';
import Manage from 'containers/vps/sections/manage';

import WHMLink from './modules/WHMLink';
import AstroResourceUsage from './modules/astro/resourceUsage';
import Billing from './modules/billing';
import Overview from './modules/overview';
import ServerJobs from './modules/serverJobs';
import SolusResourceUsage from './modules/solus/resourceUsage';
import Upgrade from './modules/upgrade';

import ResourceAddons from './modules/resourceAddons';
import SoftwareAddons from './modules/softwareAddons';

import PowerManagement from './modules/powerManagement';
import ReverseDNS from './modules/reverseDNS';
import VNCAccess from './modules/vncAccess';
import VNCLogin from './modules/vncLogin';

import Cancel from 'components/Cancel';

import FormatVPS from './modules/formatVPS';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar';
import Subnav from 'components/Subnav';
import OutlineTag from 'components/Tags/OutlineTag';
import Title from 'components/Title';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { handleSideMenuOptions, isURLValid } from 'utilities/methods/sectionRendering';
import { WHMVPSLogin, createAstroVNCSession, getLegacyAddon, getSelectedVPS, getVPSAddons, getVPSUserInfo } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import { handleRedirect } from 'utilities/methods/redirects/methods';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import './_vps.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RenderVPSPasswordField = (props) => {
    const { showPassword, password, toggle, className } = props;
    const getWidth = () => {
        if (password && password.length > 0) {
            return (password.length + 1) * 8 + 'px';
        }

        return 0;
    };

    return (
        <div className={`VPS-Password-Field${className ? ' ' + className : ''}`}>
            <input
                type={showPassword ? 'text' : 'password'}
                style={{ width: getWidth() }}
                readOnly
                className="VPS-Password-Field__input"
                value={password}
            />
            <button
                className={`VPS-Password-Field__icon icon icon-eye-${showPassword ? 'closed' : 'open'}`}
                onClick={(e) => {
                    e.preventDefault();
                    toggle();
                }}
            />
        </div>
    );
};

export class ConfirmationLightbox extends Component {
    render() {
        const { heading, description, action, actionlabel, closeLightbox } = this.props;

        return (
            <div className="VPS-Confirmation-Lightbox">
                <div className="heading">{heading}</div>
                <div className="description">{description}</div>
                <SolidButton
                    className="button"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        action();
                        closeLightbox();
                    }}
                >
                    {actionlabel}
                </SolidButton>
                <Anchor
                    onClick={(e) => {
                        closeLightbox();
                    }}
                    className="commonlink"
                >
                    Cancel
                </Anchor>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class VPS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noAddon: true
        };
    }

    componentDidMount() {
        const { match, getSelectedVPS, getVPSUserInfo, getVPSAddons, getLegacyAddon } = this.props;
        const { params } = match;
        const { id } = params;

        getSelectedVPS(id);
        getVPSUserInfo(id);
        getVPSAddons(id);
        getLegacyAddon(id);
    }

    componentDidUpdate(prevProps) {
        const { vps_information_status, vps_information_error, vps_information_data, vps_legacy_addon_status, vps_legacy_addon_data, history } =
            this.props;

        if (vps_information_error?.status === 403) {
            history.push(redirectUrls.vps);
            pushNotification(vps_information_error);
        } else if (vps_information_data) {
            handleRedirect({
                serviceType: 'vps',
                status: vps_information_data?.attributes?.status,
                domainName: vps_information_data?.attributes?.domain,
                history
            });
        }

        if (vps_information_status !== prevProps.vps_information_status && vps_information_data) {
            const { attributes } = vps_information_data;
            const { domain_status, domain } = attributes;

            handleRedirect({
                serviceType: 'vps',
                status: domain_status,
                domainName: domain,
                history
            });
        }

        if (vps_legacy_addon_status === 'success' && prevProps.vps_legacy_addon_status === 'loading') {
            const { attributes } = vps_legacy_addon_data;
            const { ftp_details } = attributes;
            if (ftp_details) {
                this.setState({
                    ...this.state,
                    noAddon: false
                });
            }
        }
    }

    render() {
        const { location, vps_information_status, vps_information_data, app_viewport, match, vps_cancel_status, history } = this.props;
        const { pathname } = location;

        const { params, url } = match;
        const { id } = params;

        const renderTitle = () => {
            const { vps_information_status, vps_information_data, history, WHMVPSLogin, createAstroVNCSession, vps_power_state_data } = this.props;

            if (vps_information_status === 'success' && vps_information_data) {
                const { attributes, included, type, id } = vps_information_data;
                const { domain, has_whm, vps_type } = attributes;
                const product = getIncludedObjBasedOnType(included, 'product');
                const productAtt = product.attributes;
                const { name } = productAtt;

                const getName = () => {
                    if (vps_type && vps_type === 'legacy') {
                        return (
                            <div className="vps-page__legacy-tag-wrapper">
                                {name}{' '}
                                <OutlineTag size="small" className="vps-page__legacy-tag">
                                    Legacy
                                </OutlineTag>
                            </div>
                        );
                    } else {
                        return name;
                    }
                };

                const getActions = () => {
                    const isAstro = vpsMethods.getVpsServerType(vps_information_data) === vpsServerTypes.ASTRO;

                    const vncOnClick = (e) => {
                        e.preventDefault();
                        if (vps_power_state_data?.state === 'shutdown') pushNotification('Your VPS must be running to enable VNC access.');
                        else createAstroVNCSession(id);
                    };

                    const buttons = {
                        whm: {
                            label: 'WHM Login',
                            type: 'onClick',
                            color: 'notice',
                            onClick: (e) => {
                                e.preventDefault();
                                WHMVPSLogin(id);
                            }
                        },
                        vncLogin: {
                            label: 'VNC Login',
                            type: 'onClick',
                            color: 'notice',
                            size: 'medium',
                            onClick: vncOnClick
                        },
                        vpsHelp: (
                            <CustomDropdown
                                className="TitleShared__singleDropdown"
                                label={({ labelRef, dropdownTitleOnClick }) => (
                                    <button
                                        className="TitleShared__singleDropdownButton"
                                        ref={labelRef}
                                        type="button"
                                        onClick={() => dropdownTitleOnClick()}
                                    >
                                        VPS Help
                                        <i className="item__icon icon icon-chevron-down" />
                                    </button>
                                )}
                                key="dropdown"
                                render={() => (
                                    <SelectOptions
                                        options={[
                                            {
                                                label: 'VPS Help Articles',
                                                onSelect: () => history.push('/support/support-centre/vps')
                                            },
                                            {
                                                label: 'Submit a Technical Support eTicket for this Service',
                                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                            },
                                            {
                                                label: 'Submit a Billing eTicket for this Service',
                                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                            }
                                        ]}
                                    />
                                )}
                            />
                        )
                    };

                    // If its astro self managed, add the VNC login button
                    if (isAstro && vps_type !== 'fully_managed') {
                        if (has_whm) return [buttons.whm, buttons.vncLogin, buttons.vpsHelp];

                        return [buttons.vncLogin, buttons.vpsHelp];
                    }

                    // If its not astro self managed, dont add VNC login button
                    if (has_whm) {
                        return [buttons.whm, buttons.vpsHelp];
                    }

                    return buttons.vpsHelp;
                };

                return <Title serviceTitle={domain} serviceSubTitle={getName()} serviceType={type} action={getActions()} />;
            }
            return '';
        };

        const modules = {
            account: [
                {
                    sidebarLink: {
                        label: 'Overview',
                        link: `/my-services/vps/account/overview/${id}`
                    },
                    component: <Overview />,
                    condition: true,
                    identifier: 'vps/overview'
                },
                {
                    sidebarLink: {
                        label: null,
                        link: `/my-services/vps/account/whm-link/${id}`
                    },
                    component: <WHMLink />,
                    condition: true,
                    identifier: 'vps/whm-link'
                },
                {
                    sidebarLink: {
                        label: null,
                        link: `/my-services/vps/account/resources/${id}`
                    },
                    component: <AstroResourceUsage vpsid={id} />,
                    condition: vpsMethods.getVpsServerType(vps_information_data) === vpsServerTypes.ASTRO,
                    identifier: 'vps/astro-resources'
                },
                {
                    sidebarLink: {
                        label: null,
                        link: `/my-services/vps/account/resources/${id}`
                    },
                    component: <SolusResourceUsage vpsid={id} />,
                    condition: vpsMethods.getVpsServerType(vps_information_data) !== vpsServerTypes.ASTRO,
                    identifier: 'vps/solus-resources'
                },
                {
                    sidebarLink: {
                        label: 'Server Jobs',
                        link: `/my-services/vps/account/server-jobs/${id}`
                    },
                    component: <ServerJobs />,
                    condition: vps_information_data?.attributes.vps_type !== 'legacy',
                    identifier: 'vps/server-jobs'
                },
                {
                    sidebarLink: {
                        label: 'Upgrade Plan',
                        link: `/my-services/vps/account/upgrade-plan/${id}`
                    },
                    component: <Upgrade vpsid={id} />,
                    condition: true,
                    identifier: 'vps/upgrade-plan'
                },
                {
                    sidebarLink: {
                        label: 'Renew',
                        link: [`/my-services/vps/account/renew/${id}`, `/my-services/vps/account/billing/${id}`]
                    },
                    component: <Billing serviceid={id} />,
                    condition: true,
                    identifier: 'vps/renew'
                }
            ],
            addons: [
                {
                    sidebarLink: {
                        label: 'Resource Addons',
                        link: `/my-services/vps/addons/resource-addons/${id}`
                    },
                    component: <ResourceAddons vpsid={id} />,
                    condition: true,
                    identifier: 'vps/resource-addons'
                },
                {
                    sidebarLink: {
                        label: 'Software Addons',
                        link: `/my-services/vps/addons/software-licenses/${id}`
                    },
                    component: <SoftwareAddons />,
                    condition: true,
                    identifier: 'vps/software-addons'
                }
            ],
            manage: [
                {
                    sidebarLink: {
                        label: 'Power Management',
                        link: `/my-services/vps/manage/power-management/${id}`
                    },
                    component: <PowerManagement />,
                    condition: vps_information_data?.attributes.vps_type !== 'fully_managed',
                    identifier: 'vps/power-management'
                },
                {
                    sidebarLink: {
                        label: 'VNC Access',
                        link: `/my-services/vps/manage/vnc-access/${id}`
                    },
                    component: <VNCAccess />,
                    condition:
                        vps_information_data?.attributes.vps_type !== 'fully_managed' &&
                        vpsMethods.getVpsServerType(vps_information_data) !== vpsServerTypes.ASTRO,
                    identifier: 'vps/vnc-access'
                },
                {
                    sidebarLink: {
                        label: 'VNC Login',
                        link: `/my-services/vps/manage/vnc-login/${id}`
                    },
                    component: <VNCLogin />,
                    condition:
                        vps_information_data?.attributes.vps_type !== 'fully_managed' &&
                        vpsMethods.getVpsServerType(vps_information_data) === vpsServerTypes.ASTRO,
                    identifier: 'vps/vnc-login'
                },
                {
                    sidebarLink: {
                        label: 'Reverse DNS',
                        link: `/my-services/vps/manage/reverse-dns/${id}`
                    },
                    component: <ReverseDNS />,
                    condition: true,
                    identifier: 'vps/reverse-dns'
                }
            ],
            admin: [
                {
                    sidebarLink: {
                        label: 'Format VPS',
                        link: `/my-services/vps/admin/format-vps/${id}`
                    },
                    component: <FormatVPS />,
                    condition: vps_information_data?.attributes.vps_type === 'self_managed',
                    identifier: 'vps/format-vps'
                },
                {
                    sidebarLink: {
                        label: 'Cancel Service',
                        link: `/my-services/vps/admin/cancel-service/${id}`
                    },
                    component: (
                        <Cancel
                            cancel={{
                                id: id,
                                service: `vps`,
                                status: vps_cancel_status,
                                onSuccess: () => {
                                    history.push(`/my-services/vps`);
                                }
                            }}
                            title="Cancel Service"
                            label="Cancel VPS Service"
                            desc="Once you cancel your service, you will lose all files, emails, databases, and configurations. To keep your data, make a full backup before cancelling your service."
                        />
                    ),
                    condition: true,
                    identifier: 'vps/cancel-service'
                }
            ]
        };

        const renderSidemenu = () => {
            const accountList = {
                list_title: 'Account',
                list_icon: 'accounts',
                list_items: handleSideMenuOptions(modules, 'account')
            };

            const addonsList = {
                list_title: 'Addons',
                list_icon: 'other-control-panel',
                list_items: handleSideMenuOptions(modules, 'addons')
            };

            const manageList = {
                list_title: 'Manage',
                list_icon: 'vps-self-managed',
                list_items: handleSideMenuOptions(modules, 'manage')
            };

            const adminList = {
                list_title: 'Admin',
                list_icon: 'settings',
                list_items: handleSideMenuOptions(modules, 'admin')
            };

            return <Sidebar links={[accountList, addonsList, manageList, adminList]} />;
        };

        const renderSection = () => {
            const { match } = this.props;
            const { params } = match;
            const { subpage } = params;

            switch (subpage) {
                case 'account':
                    return <Account modules={modules.account.filter(({ condition }) => condition)} />;

                case 'manage':
                    return <Manage modules={modules.manage.filter(({ condition }) => condition)} />;

                case 'admin':
                    return <Admin modules={modules.admin.filter(({ condition }) => condition)} />;

                case 'addons':
                    return <Addons modules={modules.addons.filter(({ condition }) => condition)} />;

                default:
                    return '';
            }
        };

        const renderVPSPage = () => {
            switch (vps_information_status) {
                case 'success':
                    return (
                        <div className="vps-page-wrapper">
                            <Subnav pathname={pathname} />
                            {renderTitle()}
                            {['sm', 'xs'].includes(app_viewport) ? renderSidemenu() : ''}
                            <Page sidebar={app_viewport === 'md' || app_viewport === 'lg' || app_viewport === 'xl' ? renderSidemenu() : ''}>
                                {renderSection()}
                            </Page>
                        </div>
                    );

                case 'error':
                    return (
                        <>
                            <Subnav pathname={pathname} />
                            <PageNotFound />
                        </>
                    );

                case 'loading':
                default:
                    return (
                        <>
                            <Subnav pathname={pathname} />
                            <RequestLoader />
                        </>
                    );
            }
        };

        return isURLValid(modules, url) ? renderVPSPage() : <PageNotFound />;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            vps_information_status: state.vps.vps_information_status,
            vps_information_data: state.vps.vps_information_data,
            vps_information_error: state.vps.vps_information_error,
            vps_legacy_addon_data: state.vps.vps_legacy_addon_data,
            vps_legacy_addon_status: state.vps.vps_legacy_addon_status,
            vps_power_state_data: state.vps.vps_power_state_data,
            vps_cancel_status: state.cancel.vps_cancel_status,
            app_viewport: state.app.app_viewport
        }),
        {
            getSelectedVPS,
            getVPSUserInfo,
            getVPSAddons,
            getLegacyAddon,
            WHMVPSLogin,
            createAstroVNCSession
        }
    )(VPS)
);
