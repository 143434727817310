/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useParams } from 'react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TicketServices from '../modules/ticketServices';
import SelectInvoice from './services/selectInvoice';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useSearchParams from 'utilities/hooks/useSearchParams';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AboutExistingService = () => {
    /***** HOOKS *****/
    const { searchParamsGet, searchParamsAdd } = useSearchParams();
    const urlParams = useParams();

    /***** RENDER HELPERS *****/
    const invoiceQueryParam = searchParamsGet('invoice');
    const serviceQueryParam = searchParamsGet('service');

    const isAboutInvoice = invoiceQueryParam && !['NA', 'none'].includes(invoiceQueryParam);
    const isAboutService = serviceQueryParam && !['NA', 'none'].includes(serviceQueryParam);

    const renderChoiceButton = (condition, text, first, onClick) => {
        return condition ? (
            <div className={`SolidButton ticketAbout__${first ? 'first' : 'second'}Button`}>{text}</div>
        ) : (
            <OutlineButton className={`ticketAbout__${first ? 'first' : 'second'}Button`} type="onClick" onClick={onClick}>
                {text}
            </OutlineButton>
        );
    };

    const renderAboutInvoiceQuestion = () => {
        return (
            <div className="ticketAbout__invoice">
                <div className="ticketAbout__questionWrapper">
                    <div className="ticketAbout__question">Was this eTicket regarding an existing invoice?</div>
                    <div className="ticketAbout__buttonContainer">
                        {renderChoiceButton(isAboutInvoice, 'Yes', true, () => searchParamsAdd('invoice', 'select'))}
                        {renderChoiceButton(invoiceQueryParam === 'none', 'No', false, () => searchParamsAdd('invoice', 'none'))}
                    </div>
                </div>
            </div>
        );
    };

    const renderSelectedInvoice = () => {
        return (
            <div className="ticketAbout__selectedInvoice">
                Selected Invoice:
                <span className="ticketAbout__selectedInvoiceNum">#{invoiceQueryParam}</span>
                <Anchor className="ticketAbout__selectDifferentInvoice" onClick={() => searchParamsAdd('invoice', 'select')}>
                    Choose a different invoice
                </Anchor>
            </div>
        );
    };

    const renderAboutServiceQuestion = () => {
        return (
            <div className="ticketAbout__service">
                <div className="ticketAbout__questionWrapper">
                    <div className="ticketAbout__question">Was this eTicket regarding an existing VentraIP Australia service?</div>
                    <div className="ticketAbout__buttonContainer">
                        {renderChoiceButton(isAboutService, 'Yes', true, () => searchParamsAdd('service', 'select'))}
                        {renderChoiceButton(serviceQueryParam === 'none', 'No', false, () => searchParamsAdd('service', 'none'))}
                    </div>
                </div>
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <div className="ticketAbout">
            {invoiceQueryParam !== 'na' ? renderAboutInvoiceQuestion() : ''}
            {invoiceQueryParam === 'select' ? <SelectInvoice /> : ''}
            {Number(invoiceQueryParam) ? renderSelectedInvoice() : ''}
            {serviceQueryParam !== 'na' && invoiceQueryParam && invoiceQueryParam !== 'select' && urlParams.param4 !== 'technical-support'
                ? renderAboutServiceQuestion()
                : ''}
            {serviceQueryParam !== 'na' && invoiceQueryParam && invoiceQueryParam !== 'select' && serviceQueryParam === 'select' ? (
                <TicketServices />
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default AboutExistingService;
