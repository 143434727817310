/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import SupportLink from 'components/SupportLink';
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RenewForm from '../forms/renew';

/*   ACTIONS
 ************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getRadixRenewOffers } from 'config/containers/promotions/action';
import { getDaysBetween, toLuxonDate } from 'utilities/methods/commonActions';
import { applyRenewPromo, getDomainInformation, getDomainRenew, renewDomain } from '../action';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import useUrlDropdown from 'components/NXBox/Dropdown/hooks/useUrlDropdown';
import { useCancelInvoiceMutation } from 'containers/billing/queries/invoice';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { UNABLE_TO_RENEW_MESSAGES } from 'containers/domain/tppDomains/modules/renew/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Renew = (props) => {
    const {
        domainid,
        expiry,
        /**
         * Redux Props
         */
        domain_information_data,
        domain_renew_options_error,
        domain_renew_options_status,
        domain_renew_options_data,
        domain_renew_status,
        domain_renew_data,
        domain_cor_data,
        selectedYears
    } = props;

    /***** STATE *****/
    const { value: isShowingInvoiceLightbox, setTrue: showInvoiceLightbox, setFalse: _hideInvoiceLightbox } = useBoolean(false);
    const { value: isShowingCancelLightbox, setTrue: showCancelLightbox, setFalse: hideCancelLightbox } = useBoolean(false);
    const { value: isRenewedTodayNotificationDismissed, setTrue: dismissRenewedTodayNotification } = useBoolean(false);

    const [manualPromoCode, setManualPromoCode] = useState('');
    const [renewPreviewRetryCounter, setRenewPreviewRetryCounter] = useState(0);

    /***** QUERIES *****/
    const { mutate: mutateCancelInvoice, status: cancel_invoice_status } = useCancelInvoiceMutation();

    /***** HOOKS *****/
    const { isDropdownOpen, openDropdown, closeDropdown } = useUrlDropdown(`/my-services/domains/general/renew/${domainid}`);

    /***** FUNCTIONS *****/
    function hideInvoiceLightbox() {
        getDomainInformation(domainid);
        _hideInvoiceLightbox();
    }

    function closePayInvoiceSuccess() {
        closeDropdown();
        hideInvoiceLightbox();
        // IMPROVE: Once get domain renew as a react query equivelent, invalidate the domain renew data if the invoice has that domain id in it.
        getDomainRenew(domainid);
    }

    function submitCancelRenew() {
        mutateCancelInvoice(domain_renew_options_data?.attributes?.invoice_id, {
            onSuccess: hideCancelLightbox,
            onSettled: () => getDomainRenew(domainid)
        });
    }

    function submitRenew(values) {
        const attributes = { ...values };

        if (manualPromoCode) attributes.promo_code = manualPromoCode;

        renewDomain(domainid, attributes, {
            onSuccess: () => {
                getDomainRenew(domainid);
                closeDropdown();
                showInvoiceLightbox();
            }
        });
    }

    function getRenewPricing(promoCode = '') {
        if (domain_information_data?.attributes?.is_premium) {
            return;
        }

        applyRenewPromo(
            domainid,
            { years: selectedYears || 1, promo_code: promoCode },
            {
                onSuccess: (successData) => {
                    // If a manual promo code was entered, but the automatic best promo is better, remove the manual code
                    if (promoCode && successData?.discount_code !== promoCode) {
                        setManualPromoCode('');

                        pushNotification({
                            status: 300,
                            details:
                                "There is a better discount available than the promo code you provided. We've applied the best available discount for you."
                        });
                    }

                    setManualPromoCode(successData?.discount_code === promoCode ? promoCode : '');
                },
                onError: (errorData) => {
                    // If an invalid promo code was attempted, refetch the pricing with no manual promo afterwards
                    if (errorData.status === 400 && renewPreviewRetryCounter < 3) {
                        setRenewPreviewRetryCounter((prev) => prev + 1);
                        getRenewPricing();
                    }
                }
            }
        );
    }

    /***** EFFECTS *****/
    useEffect(() => {
        getRadixRenewOffers(domainid);
    }, []);

    useEffect(() => {
        if (selectedYears) {
            getRenewPricing(manualPromoCode);
        }
    }, [selectedYears]);

    /***** RENDER HELPERS *****/
    const expiryDate = getDaysBetween(toLuxonDate(expiry, 'yyyy-MM-dd'));

    const renderExpiry = () => {
        if (isNaN(expiryDate)) {
            return 'Not Available';
        }

        if (expiryDate === 1) {
            return `Tomorrow`;
        }

        if (expiryDate === 0) {
            return `Today`;
        }

        if (expiryDate === -1) {
            return 'Yesterday';
        }

        if (expiryDate < 0) {
            return `${expiryDate.toString().substring(1)} days ago`;
        }

        return `${expiryDate} days`;
    };

    /*   RENDER TITLE
     **********************************************************************************************************/
    const renderTitle = () => {
        if (expiryDate < 0) {
            return 'Your domain name has expired';
        }

        if (isNaN(expiryDate) || domain_renew_options_data?.attributes?.is_in_rgp) {
            return 'Your domain name has expired';
        }

        return 'Renew Domain Name';
    };

    /*   RENDER DESCRIPTION
     **********************************************************************************************************/
    const renderDesc = () => {
        if (expiryDate < 0) {
            return "There's still time to renew! Click the button below to renew your domain name.";
        }

        if (isNaN(expiryDate) || domain_renew_options_data?.attributes?.is_in_rgp) {
            return (
                <div>
                    Your domain name has past the point of being regularly renewed. <SupportLink />
                </div>
            );
        }

        if (domain_renew_options_data?.attributes?.is_pending_renewal) {
            return 'Your domain name is currently pending renewal and will update shortly.';
        }

        return 'Ensure your domain name remains registered by renewing it before it expires.';
    };

    function renewLockedTooltipMessage() {
        if (domain_renew_options_error?.status === 422) {
            return `This domain name cannot be renewed while a Change of Registrant is being performed. Current COR status: ${domain_renew_options_error.details}`;
        }

        if (domain_cor_data?.attributes?.pending) {
            return 'This domain name cannot be renewed while a Change of Registrant is being performed';
        }

        if (domain_renew_options_data?.attributes?.is_pending_renewal) {
            return UNABLE_TO_RENEW_MESSAGES.PENDING_RENEWAL;
        }

        if (domain_renew_options_data?.attributes?.is_in_rgp) {
            return 'This domain name cannot be renewed as it is currently in the Redemption Grace Period';
        }

        if (domain_renew_options_data?.attributes?.invoice_id) {
            return UNABLE_TO_RENEW_MESSAGES.OUTSTANDING_RENEWAL_INVOICE;
        }

        if (domain_information_data?.attributes?.domain.toLowerCase().endsWith('.au') && expiryDate > 90) {
            return UNABLE_TO_RENEW_MESSAGES.AU_NOT_WITHIN_90;
        }

        return 'Please contact support to renew your domain name';
    }

    /*   RENDER BUTTON
     **********************************************************************************************************/
    const renderButton = () => {
        if (
            domain_renew_options_error?.status === 422 ||
            domain_renew_options_data?.attributes?.is_renewable === false ||
            domain_renew_options_data?.attributes?.is_pending_renewal ||
            domain_renew_options_data?.attributes?.is_in_rgp ||
            domain_renew_options_data?.attributes?.invoice_id
        ) {
            return (
                <HoverTooltip className="service__renew--tooltip" content={renewLockedTooltipMessage()}>
                    <SolidButton disabled size="large">
                        <PhosphorIcons.LockKey.Fill />
                        &nbsp;&nbsp;Renew
                    </SolidButton>
                </HoverTooltip>
            );
        }

        return {
            buttonType: 'Outline',
            label: 'Renew',
            type: 'onClick',
            className: '',
            color: expiryDate <= 90 ? 'warn' : '',
            size: 'large',
            onClick() {
                if (isDropdownOpen) {
                    closeDropdown();
                } else {
                    openDropdown();
                }
            }
        };
    };

    const handleInvoiceStatus = () => {
        if (domain_renew_options_data?.attributes?.invoice_id) {
            if (domain_renew_options_data?.attributes?.invoice_status === 'unpaid') {
                return {
                    type: 'warning',
                    text: htmr(UNABLE_TO_RENEW_MESSAGES.PLEASE_PAY_INVOICE),
                    action: [
                        {
                            label: 'Pay Invoice',
                            buttonType: 'Solid',
                            type: 'onClick',
                            className: '',
                            color: 'white',
                            size: 'medium',
                            onClick: showInvoiceLightbox
                        },
                        {
                            label: 'Cancel Invoice',
                            buttonType: 'Solid',
                            type: 'onClick',
                            className: '',
                            color: 'white',
                            size: 'medium',
                            onClick: showCancelLightbox
                        }
                    ]
                };
            }

            return {
                type: 'warning',
                text: `Your renewal is currently pending processing.`
            };
        }

        // If there is no invoice_id on the renew info request data, this means the domain has successfully been renewed. If the paid renewal invoice was created today, show success banner
        if (domain_renew_options_data?.attributes?.is_renewed_today && !isRenewedTodayNotificationDismissed) {
            return {
                type: 'success',
                text: `You successfully renewed this domain name today.`,
                action: {
                    icon: <PhosphorIcons.X />,
                    type: 'onClick',
                    className: '',
                    color: 'white',
                    onClick: dismissRenewedTodayNotification
                }
            };
        }

        return '';
    };

    const handleBoxStatus = () => {
        if ([domain_renew_options_status].includes('loading')) {
            return 'loading';
        }

        if (domain_renew_options_error?.status !== 422 && [domain_renew_options_status].includes('error')) {
            return 'error';
        }

        return 'success';
    };

    const boxStatus = domain_renew_options_status === 'error' && domain_renew_options_error.status === 422 ? 'success' : domain_renew_options_status;

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <ScrollableComponent ready={domain_renew_options_status === 'success'} className="service__renew">
            <Box
                request={{
                    action: getDomainRenew,
                    args: domainid,
                    status: boxStatus
                }}
                className="service__renew--box"
                status={handleBoxStatus()}
                title={renderTitle()}
                desc={renderDesc()}
                notification={{
                    info: handleInvoiceStatus()
                }}
                bottom={true}
                columns={[
                    {
                        render: (
                            <div className="sharedBox__infoColumn hasIcon">
                                <div className="infoColumn__wrapper">
                                    <div className="title">
                                        {domain_renew_options_data?.attributes?.is_expired || expiryDate === 0 ? `Expired` : `Expires`}
                                        {expiryDate > 1 && ' in'}
                                    </div>
                                    <div className="desc">
                                        {renderExpiry()}
                                        <div className="subInfo">({toLuxonDate(expiry, 'yyyy-MM-dd').toFormat('ccc LLL dd y')})</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                ]}
                action={renderButton()}
                reset={{
                    onClick: () => {
                        getDomainRenew(domainid);
                    }
                }}
                dropdown={{
                    title: 'Select a Renew Period',
                    render: (
                        <RenewForm
                            submitRenew={submitRenew}
                            isPremium={domain_renew_options_data?.attributes?.is_premium}
                            options={domain_renew_options_data?.attributes?.prices}
                            getRenewPricing={getRenewPricing}
                            manualPromoCode={manualPromoCode}
                        />
                    ),
                    condition: isDropdownOpen,
                    close: closeDropdown,
                    status: domain_renew_status
                }}
            />
            {isShowingInvoiceLightbox && (
                <OverlayLightbox
                    title={`Pay Invoice #${domain_renew_options_data?.attributes?.invoice_id || domain_renew_data?.id}`}
                    invoiceid={domain_renew_options_data?.attributes?.invoice_id || domain_renew_data?.id}
                    onOpen
                    onClose={() => {
                        hideInvoiceLightbox();
                    }}
                    onSuccessClose={closePayInvoiceSuccess}
                />
            )}
            {isShowingCancelLightbox && (
                <OverlayLightbox
                    onOpen
                    onClose={hideCancelLightbox}
                    title="Cancel Renewal Invoice?"
                    confirm={{
                        desc: htmr(`Cancelling this invoice will also cancel the renew request. Are you sure you'd like to do this?`),
                        buttonText: 'Cancel',
                        buttonAction: submitCancelRenew,
                        closeText: 'No, Go Back',
                        closeAction: hideCancelLightbox,
                        loading: cancel_invoice_status
                    }}
                />
            )}
        </ScrollableComponent>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    const selector = formValueSelector('domainRenewForm');
    const selectedYears = selector(state, 'renewPeriod');

    return {
        domain_information_data: state.domain.domain_information_data,
        domain_renew_options_status: state.domain.domain_renew_options_status,
        domain_renew_options_data: state.domain.domain_renew_options_data,
        domain_renew_options_error: state.domain.domain_renew_options_error,
        domain_renew_status: state.domain.domain_renew_status,
        domain_renew_data: state.domain.domain_renew_data,
        domain_cor_data: state.domain.domain_cor_data,
        selectedYears
    };
};

Renew = connect(mapStateToProps)(Renew);

Renew = withRouter(Renew);

export default Renew;
