/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

export const phosphorIconSpecificProps = {
    /** The name of the icon */
    name: PropTypes.string.isRequired
};

export const phosphorIconStandardPropTypes = {
    /** The size of the icon */
    size: PropTypes.number,

    /** Additional classnames */
    className: PropTypes.string
};

export const phosphorIconPropKeys = /** @type {const} */ ([
    /**
     * Icon Colours
     */
    /** Colour Primary Purple */
    'primary',
    /** Colour Secondary Gray */
    'secondary',
    /** Colour Secondary Gray alternative */
    'secondary-alt',
    /** Colour Confirm Green */
    'confirm',
    /** Colour Warning Red */
    'warn',
    /** Colour Info Blue */
    'info',
    /** Colour Notice Orange */
    'notice',
    /** Colour White */
    'white',
    /** Colour Black === Dark Gray */
    'black',
    /** Colour #000 */
    'blackest',
    /** Colour Inactive, feint gray */
    'inactive',
    /** Colour Gray */
    'gray',
    /** Colour Light Gray */
    'light-gray',
    /** Colour Faded Light Gray */
    'faded-light-gray'
]);
