/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const PAYMENT_METHODS = {
    BPAY: 'BPAY',
    EFT: 'EFT',
    EFT_TRANSFER: 'EFT Transfer',
    CREDIT_CARD: 'Credit Card',
    SAVED_CREDIT_CARD: 'Saved Credit Card',
    PAYPAL: 'PayPal',
    APPLY_CREDIT: 'Apply Credit'
};
export const PAYMENT_METHODS_VALUES = Object.values(PAYMENT_METHODS);

export const mutationOptionsPropType = PropTypes.shape({
    /**
     * Success and error function for the mutation
     */
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func
}).isRequired;

export const selectedPaymentMethodPropTypes = {
    /**
     * The currently selected payment method
     */
    selectedPaymentMethod: PropTypes.oneOf([...PAYMENT_METHODS_VALUES, null]),

    /**
     * the function to call when you need to update the selected payment method
     */
    setSelectedPaymentMethod: PropTypes.func.isRequired,

    /**
     * ID of the currently selected invoice
     */
    invoiceID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The Success And Error handling of the payment method success action
     */
    mutationOptions: mutationOptionsPropType
};
