/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DialogNotificationButtonsContainer from './DialogNotificationButtonsContainer';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_DialogNotification.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      children: React.ReactNode;
 *      type: 'warning' | 'info' | 'error' | 'success';
 *      outline?: boolean;
 *      className?: string;
 *      noMargin?: boolean;
 *      tail?: {
 *          pos: 'top' | 'bottom';
 *      } | null;
 * }} props
 */
const DialogNotification = ({ children, type, outline, className, tail = null, noMargin }) => {
    /***** RENDER HELPERS *****/
    const dialogNotificationClassName = classNames('DialogNotificationPage', className, type, {
        'DialogNotificationPage--topTail': tail && !outline && tail.pos === 'top',
        'DialogNotificationPage--bottomTail': tail && !outline && tail.pos !== 'top',
        'outline': outline,
        'DialogNotificationPage--noMargin': noMargin
    });

    /***** RENDER *****/
    return (
        <div className={dialogNotificationClassName}>
            <div className="text">{children}</div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** Compound Components START *****/
DialogNotification.ButtonsContainer = DialogNotificationButtonsContainer;
/***** Compound Components END *****/

DialogNotification.propTypes = {
    // Text that goes inside the notification
    children: PropTypes.node,

    // Determines the color of the notification
    type: PropTypes.oneOf(['warning', 'info', 'error', 'success']).isRequired,

    // Determines whether the notification should be transparent with only a border color
    outline: PropTypes.bool,

    // Additional className to give to the notification
    className: PropTypes.string,

    // Whether to include a little pointer on the notification
    tail: PropTypes.shape({
        // Whether the tail should appear on the top or the bottom
        pos: 'top'
    })
};

export default DialogNotification;
