/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaGroupFieldGroup } from 'components/Form/KatanaGroupField/group';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaGroupFormFieldGroup: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name = '', type, properties = [] } = property;

    /***** RENDER *****/
    return <Field name={key} label={name} component={KatanaGroupFieldGroup} properties={properties} type={type} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaGroupFormFieldGroup;
