/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import _ from 'lodash';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Helper hook for generating URLs for navigating within Katana.
 *
 * @param {number | null} [serviceID] - The ID of the Katana service.
 */
function useKatanaURL(serviceID) {
    /** */
    const { id = String(serviceID) } = useSetupEditorRouteParams();

    const ID = serviceID ?? Number(id) ?? null;

    /**
     * Generates a destination URL for navigating within the Katana service.
     *
     * @param {...string | { to: string } | number} destination - The destination path within the Katana service.
     * @returns {string} The complete URL for the specified destination.
     */
    function getKatanaDestination(...destination) {
        /**
         * Helper function to process the destination array or object.
         *
         * @returns {string} The processed destination path.
         * @throws {Error} Throws an error if the destination is not valid.
         */
        function getDestinations() {
            if (!destination) return '';
            const flattenedDestination = destination
                .flat()
                .filter(Boolean)
                .map((destinationPath) => (_.isObject(destinationPath) ? destinationPath.to : destinationPath))
                .filter(Boolean);

            const joinedDestination = flattenedDestination.join('/').replace('/?', '?');
            return `${joinedDestination}`;
        }

        function constructFinalURL() {
            if (ID) {
                return `${serviceNav.VIPsites.link}/${ID}/${getDestinations()}`;
            }

            return `${serviceNav.VIPsites.link}/${getDestinations()}`;
        }

        return constructFinalURL();
    }

    // Return the getKatanaDestination function as part of the hook's return value
    return {
        getKatanaDestination
    };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useKatanaURL;
