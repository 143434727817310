/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RadioButtonCircle from 'components/Form/RadioButtonCircle';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetAvailablePaymentMethodsQuery } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';
import { useGetDefaultPaymentMethodQuery } from 'containers/billing/queries/useGetDefaultPaymentMethodQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PAYMENT_METHODS_VALUES } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const PaymentMethodsRadioButton = ({ activeTab, selectedPaymentMethodData, onClickMakeDefaultMethod }) => {
    /***** QUERIES *****/
    const { data: get_default_payment_method_data } = useGetDefaultPaymentMethodQuery();
    const { data: get_available_payment_methods_data } = useGetAvailablePaymentMethodsQuery();

    /***** FUNCTIONS *****/
    function setMethodActiveTab() {
        const paymentMethodData = get_available_payment_methods_data.find((method_data) => activeTab === method_data.name);
        onClickMakeDefaultMethod(paymentMethodData);
    }

    /***** RENDER HELPERS *****/
    const defaultMethod = get_default_payment_method_data?.attributes?.default_payment_method ?? '';

    /***** RENDER *****/
    if (defaultMethod === activeTab) {
        return (
            <div className="availablePaymentMethod__radiowrapper">
                <PhosphorIcons.CheckCircle.Fill confirm size={25} />
                <Text confirm size--m>
                    <span>This is your default payment method.</span>
                </Text>
            </div>
        );
    }

    return (
        <button onClick={setMethodActiveTab} className="availablePaymentMethod__radiowrapper">
            <RadioButtonCircle isActive={selectedPaymentMethodData} />
            <Text black size--m align--left>
                <span>Make {activeTab} my default payment method.</span>
            </Text>
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PaymentMethodsRadioButton.propTypes = {
    /**
     * The currently selected payment method
     */
    activeTab: PropTypes.oneOf(PAYMENT_METHODS_VALUES),

    /**
     * The currently selected Payment Method Data
     */
    selectedPaymentMethodData: PropTypes.oneOfType([PropTypes.object], null),

    /**
     * handle Click Functionality for making default, just requires the paymentMethodData
     */
    onClickMakeDefaultMethod: PropTypes.func.isRequired
};

export default PaymentMethodsRadioButton;
