/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';
import type { WrappedFieldProps } from 'redux-form';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXImage } from 'components/NXImage';
import SolidTag from 'components/Tags/SolidTag';
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ComplexLabel } from 'containers/katana/components/complexLabel/ComplexLabel';
import { CopyValueOnClickToTarget } from 'containers/katana/components/copyValueOnClickToTarget';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetKatanaFileUploadValue } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/methods';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { getNestedKeyListIndex } from 'containers/katana/formFields/repeated/methods/getNestedKeyListIndex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import './_DisplayOnlyWrapper.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

const DisplayOnlyWrapper: React.FC<
    {
        input: TNXDropZone.ReduxForm.WithKatanaFileUpload.InputProp;
        property: KatanaNamespace.SectionDefinitions.PropertiesModified;
    } & WrappedFieldProps
> = ({ input, property }) => {
    const { value, name } = input;
    const { originalKey } = property;

    /***** HOOKS *****/
    const imageURL = useGetKatanaFileUploadValue(value);

    /***** HOOKS *****/
    const firstItemInListLabel = useGetCaveat(CaveatTypes.FIRST_ITEM_IN_LIST_LABEL_CAVEAT, property.caveats);

    const listIndex = firstItemInListLabel ? getNestedKeyListIndex(name, originalKey) : null;

    /***** RENDER HELPERS *****/
    return (
        <CopyValueOnClickToTarget property={property}>
            <ComplexLabel property={property}>
                <NXSquare className={classNames('FileFormFieldDisplayOnlyWrapper')}>
                    <NXImage.WithLoader cover src={imageURL} />
                    {listIndex === 0 && <SolidTag color="black">{firstItemInListLabel}</SolidTag>}
                </NXSquare>
            </ComplexLabel>
        </CopyValueOnClickToTarget>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaFileFormFieldDisplayOnly: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, type, validationFunctions } = property;

    /***** RENDER *****/
    return (
        <Field
            name={key}
            label={name}
            component={DisplayOnlyWrapper}
            property={property}
            type={type}
            singleFile={true}
            validate={validationFunctions}
        />
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormFieldDisplayOnly };
