/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AdditionalInfoForm from './additionalInfoForm';
import ControlPanelAcknowledgementsForm from './control_panel/controlPanelAcknowledgementsForm';
import DateTimeForm from './dateTimeForm';
import DNSUpdateForm from './dnsUpdateForm';
import EmailDetailsForm from './emailDetailsForm';
import FormStep from './formStep';
import SSHAccessDetailsForm from './sshAccessDetailsForm';
import StepTitle from './stepTitle';
import TechnicalPersonForm from './technicalPersonForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const FormGenericStep2 = () => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <>
            <FormStep>
                <StepTitle>Step 2. Email Migrations</StepTitle>

                <EmailDetailsForm />
            </FormStep>

            <FormStep>
                <StepTitle>Step 3. Do you have SSH access details?</StepTitle>

                <SSHAccessDetailsForm />
            </FormStep>

            <FormStep>
                <StepTitle>Step 4. Select a migration date and time</StepTitle>

                <DateTimeForm />
            </FormStep>

            <FormStep>
                <StepTitle>Step 5. Additional Information</StepTitle>

                <AdditionalInfoForm />
            </FormStep>

            <TechnicalPersonForm />

            <AcknowledgementBox.FlushBox>
                <AcknowledgementBox title="Acknowledgements" flush>
                    <ControlPanelAcknowledgementsForm />
                </AcknowledgementBox>

                <AcknowledgementBox title="DNS Update" flush>
                    <DNSUpdateForm />
                </AcknowledgementBox>
            </AcknowledgementBox.FlushBox>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FormGenericStep2;
