/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SynergyLoginForm from 'containers/ventraSynMigration/forms/synergyLoginForm';
import MarketingSidebar from 'containers/ventraSynMigration/modules/marketingSidebar';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import FetchComponentError from 'components/Errors/FetchComponentError';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import PasswordChecker, { validateConfirmValidation } from 'components/PasswordChecker';
import { validateDefaultRequirements } from 'components/PasswordChecker/default';

/*   ACTIONS
 *****************************************************/
import { synergyLogin } from 'containers/ventraSynMigration/action';
import { scrollToTop } from 'utilities/methods/commonActions';
import { RenderField, RenderRevealPasswordField, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class CreateAccountForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lightboxOpen: false
        };

        this.toggleLightbox = this.toggleLightbox.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    }

    toggleLightbox() {
        this.setState({
            lightboxOpen: !this.state.lightboxOpen
        });
    }

    handleLoginSubmit(values) {
        const { synergyLogin, formatServices, formValues } = this.props;

        synergyLogin(values, true, { ...formValues, services: formatServices() });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        scrollToTop();
    }

    render() {
        const { handleSubmit, pristine, submitting, valid, formValues, vsm_synergy_login_status, vsm_synergy_register_status, vsm_migrate_status } =
            this.props;
        const { lightboxOpen } = this.state;
        const { toggleLightbox, handleLoginSubmit } = this;

        const renderFormFields = () => {
            return (
                <>
                    <Field name="username" label="Request username" component={RenderField} validate={[requiredFieldValidation]} type="text" />
                    <Field
                        name="password"
                        label="Choose a password"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateDefaultRequirements]}
                        type="password"
                    />
                    <Field
                        name="password_confirmation"
                        label="Confirm Password"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateConfirmValidation]}
                        type="password"
                    />
                </>
            );
        };

        const renderMain = () => {
            return (
                <div className="vsAccountForm__mainContainer">
                    <form className="vsAccountForm__content" onSubmit={handleSubmit}>
                        <h3 className="vsAccountForm__heading">Become a new Synergy Wholesale partner</h3>
                        <p className="vsAccountForm__intro">
                            Sign up and confirm to finalise your migration request, receive your free hosting and Synergy Wholesale account.
                        </p>
                        <p className="vsAccountForm__alreadyHaveAccount">Already a Synergy Wholesale partner?</p>
                        <Anchor onClick={toggleLightbox}>Link account and confirm</Anchor>
                        <div className="vsAccountForm__noteContainer">
                            <div className="vsAccountForm__noteBg"></div>
                            <p className="vsAccountForm__noteContent">
                                Please note that the email address, billing address, and security question and answer of your {application} account
                                will be used to create your new Synergy Wholesale account. These details can be changed later if required.
                            </p>
                        </div>
                        {renderFormFields()}
                        <PasswordChecker input={formValues?.password} />
                        {renderButton(pristine, submitting, valid, 'SIGNUP AND CONFIRM', 'primary')}
                        <p className="vsAccountForm__disclaimer">
                            By clicking the Sign Up and Confirm above and creating your Synergy Wholesale account you acknowledge you have read and
                            agree to Synergy Wholesale&apos;s
                            <Anchor href="https://synergywholesale.com/documentation/" target="_blank">
                                Terms, Policies and Agreements
                            </Anchor>
                        </p>
                    </form>
                </div>
            );
        };

        const renderLoginLightbox = () => {
            const handleLightboxLoading = () => {
                if (vsm_synergy_login_status === 'error' || vsm_migrate_status === 'error') return 'error';
                if (vsm_synergy_login_status === 'loading' || vsm_migrate_status === 'loading') return 'loading';
                return 'success';
            };

            return (
                <OverlayLightbox onOpen={lightboxOpen} onClose={toggleLightbox} loading={handleLightboxLoading()}>
                    <SynergyLoginForm migrateAfterLink={true} onSubmit={handleLoginSubmit} />
                </OverlayLightbox>
            );
        };

        const renderPage = () => {
            return (
                <div className="vsAccountForm">
                    {renderMain()}
                    <MarketingSidebar numOfQuotes={1} />
                    {renderLoginLightbox()}
                </div>
            );
        };

        const handleLoading = () => {
            if (vsm_migrate_status === 'error') return 'error';
            if (vsm_synergy_register_status === 'loading' || vsm_migrate_status === 'loading') return 'loading';
            return 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        switch (handleLoading()) {
            case 'success':
                return renderPage();
            case 'error':
                return <FetchComponentError />;
            case 'loading':
            default:
                return <RequestLoader />;
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CreateAccountForm = reduxForm({
    form: 'ventraSynMigrationForm',
    destroyOnUnmount: false
})(CreateAccountForm);

export default withRouter(
    connect(
        (state) => ({
            formValues: getFormValues('ventraSynMigrationForm')(state),
            vsm_synergy_login_status: state.vsm.vsm_synergy_login_status,
            vsm_synergy_register_status: state.vsm.vsm_synergy_register_status,
            vsm_migrate_status: state.vsm.vsm_migrate_status
        }),
        {
            synergyLogin
        }
    )(CreateAccountForm)
);
