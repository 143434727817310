/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import axios from 'axios';
import { batchActions } from 'redux-batched-actions';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/*   ACTIONS
 *****************************************************/
import { API, cancelHostingUpgradeCalculate } from 'utilities/api/hosting';
import { API as SERVICES, cancelCalculateChangePlanCost } from 'utilities/api/services';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import { resetHostingAdmin } from './adminActions';
import { resetHostingConfiguration } from './configActions';
import { resetResellerState, storeUsername } from './resellerActions';
import { resetHostingSecurity } from './securityActions';

/***** MEGAMAY24 START *****/
import { invalidateMegaMayAvailableSpinsQuery } from 'config/containers/promotions/megamay2024/queries';
/***** MEGAMAY24 END *****/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const HOSTING_ACCOUNT_INITIAL_STATE = 'hosting/HOSTING_ACCOUNT_INITIAL_STATE';
export const HOSTING_SUBACCOUNT_INITIAL_STATE = 'hosting/HOSTING_SUBACCOUNT_INITIAL_STATE';

export const HOSTING_INFORMATION_RESET = 'hosting/HOSTING_INFORMATION_RESET';
export const HOSTING_INFORMATION_REQUEST = 'hosting/HOSTING_INFORMATION_REQUEST';
export const HOSTING_INFORMATION_SUCCESS = 'hosting/HOSTING_INFORMATION_SUCCESS';
export const HOSTING_INFORMATION_FAIL = 'hosting/HOSTING_INFORMATION_FAIL';

export const HOSTING_PLESK_REQUEST = 'hosting/HOSTING_PLESK_REQUEST';
export const HOSTING_PLESK_SUCCESS = 'hosting/HOSTING_PLESK_SUCCESS';
export const HOSTING_PLESK_FAIL = 'hosting/HOSTING_PLESK_FAIL';

export const HOSTING_SET_RESELLER = 'hosting/HOSTING_SET_RESELLER';
export const HOSTING_INFORMATION_SUBACCOUNT_REQUEST = 'hosting/HOSTING_INFORMATION_SUBACCOUNT_REQUEST';
export const HOSTING_INFORMATION_SUBACCOUNT_SUCCESS = 'hosting/HOSTING_INFORMATION_SUBACCOUNT_SUCCESS';
export const HOSTING_INFORMATION_SUBACCOUNT_FAIL = 'hosting/HOSTING_INFORMATION_SUBACCOUNT_FAIL';

export const HOSTING_SEND_DETAILS_REQUEST = 'hosting/HOSTING_SEND_DETAILS_REQUEST';
export const HOSTING_SEND_DETAILS_SUCCESS = 'hosting/HOSTING_SEND_DETAILS_SUCCESS';
export const HOSTING_SEND_DETAILS_FAIL = 'hosting/HOSTING_SEND_DETAILS_FAIL';

export const HOSTING_SUBACCOUNT_LIST_REQUEST = 'hosting/HOSTING_SUBACCOUNT_LIST_REQUEST';
export const HOSTING_SUBACCOUNT_LIST_SUCCESS = 'hosting/HOSTING_SUBACCOUNT_LIST_SUCCESS';
export const HOSTING_SUBACCOUNT_LIST_FAIL = 'hosting/HOSTING_SUBACCOUNT_LIST_FAIL';

export const HOSTING_SUBACCOUNT_LIST_RESET = 'hosting/HOSTING_SUBACCOUNT_LIST_RESET';

export const HOSTING_CPANEL_LOGIN_REQUEST = 'hosting/HOSTING_CPANEL_LOGIN_REQUEST';
export const HOSTING_CPANEL_LOGIN_SUCCESS = 'hosting/HOSTING_CPANEL_LOGIN_SUCCESS';
export const HOSTING_CPANEL_LOGIN_FAIL = 'hosting/HOSTING_CPANEL_LOGIN_FAIL';

export const HOSTING_CPANEL_DETAILS_REQUEST = 'hosting/HOSTING_CPANEL_DETAILS_REQUEST';
export const HOSTING_CPANEL_DETAILS_SUCCESS = 'hosting/HOSTING_CPANEL_DETAILS_SUCCESS';
export const HOSTING_CPANEL_DETAILS_FAIL = 'hosting/HOSTING_CPANEL_DETAILS_FAIL';

export const HOSTING_CPANEL_REQUEST = 'hosting/HOSTING_CPANEL_REQUEST';
export const HOSTING_CPANEL_SUCCESS = 'hosting/HOSTING_CPANEL_SUCCESS';
export const HOSTING_CPANEL_FAIL = 'hosting/HOSTING_CPANEL_FAIL';

export const HOSTING_DISKBLOCK_RESET = 'hosting/HOSTING_DISKBLOCK_RESET';

export const HOSTING_DISKBLOCK_LIST_REQUEST = 'hosting/HOSTING_DISKBLOCK_LIST_REQUEST';
export const HOSTING_DISKBLOCK_LIST_SUCCESS = 'hosting/HOSTING_DISKBLOCK_LIST_SUCCESS';
export const HOSTING_DISKBLOCK_LIST_FAIL = 'hosting/HOSTING_DISKBLOCK_LIST_FAIL';

export const HOSTING_DISKBLOCK_PURCHASE_REQUEST = 'hosting/HOSTING_DISKBLOCK_PURCHASE_REQUEST';
export const HOSTING_DISKBLOCK_PURCHASE_SUCCESS = 'hosting/HOSTING_DISKBLOCK_PURCHASE_SUCCESS';
export const HOSTING_DISKBLOCK_PURCHASE_FAIL = 'hosting/HOSTING_DISKBLOCK_PURCHASE_FAIL';

export const HOSTING_DISKBLOCK_REQUEST = 'hosting/HOSTING_DISKBLOCK_REQUEST';
export const HOSTING_DISKBLOCK_SUCCESS = 'hosting/HOSTING_DISKBLOCK_SUCCESS';
export const HOSTING_DISKBLOCK_FAIL = 'hosting/HOSTING_DISKBLOCK_FAIL';

export const HOSTING_UPGRADE_LIST_REQUEST = 'hosting/HOSTING_UPGRADE_LIST_REQUEST';
export const HOSTING_UPGRADE_LIST_SUCCESS = 'hosting/HOSTING_UPGRADE_LIST_SUCCESS';
export const HOSTING_UPGRADE_LIST_FAIL = 'hosting/HOSTING_UPGRADE_LIST_FAIL';

export const HOSTING_UPGRADE_CALCULATE_REQUEST = 'hosting/HOSTING_UPGRADE_CALCULATE_REQUEST';
export const HOSTING_UPGRADE_CALCULATE_SUCCESS = 'hosting/HOSTING_UPGRADE_CALCULATE_SUCCESS';
export const HOSTING_UPGRADE_CALCULATE_ERROR = 'hosting/HOSTING_UPGRADE_CALCULATE_ERROR';

export const HOSTING_UPGRADE_REQUEST = 'hosting/HOSTING_UPGRADE_REQUEST';
export const HOSTING_UPGRADE_SUCCESS = 'hosting/HOSTING_UPGRADE_SUCCESS';
export const HOSTING_UPGRADE_FAIL = 'hosting/HOSTING_UPGRADE_FAIL';

export const HOSTING_UPGRADE_PRORATA_REQUEST = 'hosting/HOSTING_UPGRADE_PRORATA_REQUEST';
export const HOSTING_UPGRADE_PRORATA_SUCCESS = 'hosting/HOSTING_UPGRADE_PRORATA_SUCCESS';
export const HOSTING_UPGRADE_PRORATA_FAIL = 'hosting/HOSTING_UPGRADE_PRORATA_FAIL';

export const HOSTING_BILLINGCYCLE_REQUEST = 'hosting/HOSTING_BILLINGCYCLE_REQUEST';
export const HOSTING_BILLINGCYCLE_SUCCESS = 'hosting/HOSTING_BILLINGCYCLE_SUCCESS';
export const HOSTING_BILLINGCYCLE_FAIL = 'hosting/HOSTING_BILLINGCYCLE_FAIL';

export const HOSTING_BILLING_REQUEST = 'hosting/HOSTING_BILLING_REQUEST';
export const HOSTING_BILLING_SUCCESS = 'hosting/HOSTING_BILLING_SUCCESS';
export const HOSTING_BILLING_FAIL = 'hosting/HOSTING_BILLING_FAIL';

export const HOSTING_PRIMARY_REQUEST = 'hosting/HOSTING_PRIMARY_REQUEST';
export const HOSTING_PRIMARY_SUCCESS = 'hosting/HOSTING_PRIMARY_SUCCESS';
export const HOSTING_PRIMARY_FAIL = 'hosting/HOSTING_PRIMARY_FAIL';

export const HOSTING_RENEW_REQUEST = 'hosting/HOSTING_RENEW_REQUEST';
export const HOSTING_RENEW_SUCCESS = 'hosting/HOSTING_RENEW_SUCCESS';
export const HOSTING_RENEW_FAIL = 'hosting/HOSTING_RENEW_FAIL';

export const HOSTING_RENEW_INFO_REQUEST = 'hosting/HOSTING_RENEW_INFO_REQUEST';
export const HOSTING_RENEW_INFO_SUCCESS = 'hosting/HOSTING_RENEW_INFO_SUCCESS';
export const HOSTING_RENEW_INFO_FAIL = 'hosting/HOSTING_RENEW_INFO_FAIL';

export const HOSTING_PREPAY_DETAILS_REQUEST = 'hosting/HOSTING_PREPAY_DETAILS_REQUEST';
export const HOSTING_PREPAY_DETAILS_SUCCESS = 'hosting/HOSTING_PREPAY_DETAILS_SUCCESS';
export const HOSTING_PREPAY_DETAILS_ERROR = 'hosting/HOSTING_PREPAY_DETAILS_ERROR';

export const CUSTOM_HOSTING_DETAILS_REQUEST = 'hosting/CUSTOM_HOSTING_DETAILS_REQUEST';
export const CUSTOM_HOSTING_DETAILS_SUCCESS = 'hosting/CUSTOM_HOSTING_DETAILS_SUCCESS';
export const CUSTOM_HOSTING_DETAILS_FAIL = 'hosting/CUSTOM_HOSTING_DETAILS_FAIL';

export const CUSTOM_HOSTING_CONFIG_REQUEST = 'hosting/CUSTOM_HOSTING_CONFIG_REQUEST';
export const CUSTOM_HOSTING_CONFIG_SUCCESS = 'hosting/CUSTOM_HOSTING_CONFIG_SUCCESS';
export const CUSTOM_HOSTING_CONFIG_FAIL = 'hosting/CUSTOM_HOSTING_CONFIG_FAIL';

export const CUSTOM_HOSTING_CHANGE_REQUEST = 'hosting/CUSTOM_HOSTING_CHANGE_REQUEST';
export const CUSTOM_HOSTING_CHANGE_SUCCESS = 'hosting/CUSTOM_HOSTING_CHANGE_SUCCESS';
export const CUSTOM_HOSTING_CHANGE_FAIL = 'hosting/CUSTOM_HOSTING_CHANGE_FAIL';

export const CUSTOM_HOSTING_REMOVE_REQUEST = 'hosting/CUSTOM_HOSTING_REMOVE_REQUEST';
export const CUSTOM_HOSTING_REMOVE_SUCCESS = 'hosting/CUSTOM_HOSTING_REMOVE_SUCCESS';
export const CUSTOM_HOSTING_REMOVE_FAIL = 'hosting/CUSTOM_HOSTING_REMOVE_FAIL';

export const CUSTOM_HOSTING_CALCULATE_RESET = 'hosting/CUSTOM_HOSTING_CALCULATE_RESET';
export const CUSTOM_HOSTING_CALCULATE_REQUEST = 'hosting/CUSTOM_HOSTING_CALCULATE_REQUEST';
export const CUSTOM_HOSTING_CALCULATE_SUCCESS = 'hosting/CUSTOM_HOSTING_CALCULATE_SUCCESS';
export const CUSTOM_HOSTING_CALCULATE_FAIL = 'hosting/CUSTOM_HOSTING_CALCULATE_FAIL';

export const CUSTOM_HOSTING_ADDON_LIST_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_LIST_REQUEST';
export const CUSTOM_HOSTING_ADDON_LIST_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_LIST_SUCCESS';
export const CUSTOM_HOSTING_ADDON_LIST_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_LIST_FAIL';

export const CUSTOM_HOSTING_ADDON_PURCHASE_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_PURCHASE_REQUEST';
export const CUSTOM_HOSTING_ADDON_PURCHASE_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_PURCHASE_SUCCESS';
export const CUSTOM_HOSTING_ADDON_PURCHASE_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_PURCHASE_FAIL';

export const CUSTOM_HOSTING_ADDON_REMOVE_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_REMOVE_REQUEST';
export const CUSTOM_HOSTING_ADDON_REMOVE_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_REMOVE_SUCCESS';
export const CUSTOM_HOSTING_ADDON_REMOVE_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_REMOVE_FAIL';

export const CUSTOM_HOSTING_ADDON_KEEP_REQUEST = 'hosting/CUSTOM_HOSTING_ADDON_KEEP_REQUEST';
export const CUSTOM_HOSTING_ADDON_KEEP_SUCCESS = 'hosting/CUSTOM_HOSTING_ADDON_KEEP_SUCCESS';
export const CUSTOM_HOSTING_ADDON_KEEP_FAIL = 'hosting/CUSTOM_HOSTING_ADDON_KEEP_FAIL';

export const HOSTING_SUBACCOUNT_INFO_RESET = 'hosting/HOSTING_SUBACCOUNT_INFO_RESET';

/**********************************************************************************************************
 *   RESET
 **********************************************************************************************************/

export const resetHostingState = (history, url) => {
    return (dispatch) => {
        const actionArray = [
            resetHostingAccount(),
            {
                type: HOSTING_SUBACCOUNT_INITIAL_STATE
            },
            resetHostingAdmin(),
            resetHostingConfiguration(),
            resetHostingSecurity(),
            forceResetHostingInfo()
        ];
        if (store.getState().hosting.hosting_subaccount_username) actionArray.push(resetResellerState());

        dispatch(batchActions(actionArray));
        if (history && url) history.push(url);
    };
};

export const prepStateForSubaccount = () => {
    return (dispatch) => {
        clearSubAccountData();
        dispatch(
            batchActions([
                {
                    type: HOSTING_SUBACCOUNT_INITIAL_STATE
                },
                resetHostingAdmin(),
                resetHostingConfiguration(),
                resetHostingSecurity()
            ])
        );
    };
};

export const resetHostingAccount = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ACCOUNT_INITIAL_STATE
        });
    };
};

export const resetHostingSubaccount = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SUBACCOUNT_INITIAL_STATE
        });
    };
};

export const resetResellerStateList = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SUBACCOUNT_LIST_RESET
        });
    };
};

export const forceResetHostingInfo = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_INFORMATION_RESET
        });
    };
};

/**********************************************************************************************************
 *   HOSTING DETAILS
 **********************************************************************************************************/
export const sendServiceDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SEND_DETAILS_REQUEST
        });
        API.hosting.POST.send(id)
            .then((response) => {
                const hosting_send_details_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_SEND_DETAILS_SUCCESS,
                            hosting_send_details_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_SEND_DETAILS_FAIL,
                            hosting_send_details_error: response
                        }
                    ])
                );
            });
    };
};

/**********************************************************************************************************
 *   RESELLER
 **********************************************************************************************************/
export const getSubaccountList = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SUBACCOUNT_LIST_REQUEST
        });
        API.hosting.subaccount.GET.list(id)
            .then((response) => {
                const hosting_subaccount_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_SUBACCOUNT_LIST_SUCCESS,
                    hosting_subaccount_list_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_SUBACCOUNT_LIST_FAIL,
                    hosting_subaccount_list_error: response
                });
            });
    };
};

export const whmLogin = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CPANEL_LOGIN_REQUEST
        });

        const { closePopup, goToTargetUrl } = createPopup();
        API.hosting.GET.login
            .whm(id)
            .then((response) => {
                const hosting_cpanel_login_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_CPANEL_LOGIN_SUCCESS,
                    hosting_cpanel_login_data
                });
                goToTargetUrl(hosting_cpanel_login_data.attributes.url);
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_CPANEL_LOGIN_FAIL,
                    hosting_cpanel_login_error: response
                });
                closePopup();
            });
    };
};

export const getHostingSubAccountInformation = (id, username) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_INFORMATION_SUBACCOUNT_REQUEST
        });
        API.hosting.subaccount.GET.details(id, username)
            .then((response) => {
                const hosting_information_subaccount_data = getDataFromSuccessResponse(response);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_INFORMATION_SUBACCOUNT_SUCCESS,
                            hosting_information_subaccount_data
                        },
                        storeUsername(username)
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_INFORMATION_SUBACCOUNT_FAIL,
                    hosting_information_subaccount_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   UPGRADE
 **********************************************************************************************************/
export const getHostingProducts = (type, options) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_UPGRADE_LIST_REQUEST
        });
        API.hosting.GET.products(type)
            .then((response) => {
                const hosting_upgrade_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_UPGRADE_LIST_SUCCESS,
                    hosting_upgrade_list_data
                });

                options?.onSuccess?.(hosting_upgrade_list_data);
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_UPGRADE_LIST_FAIL,
                    hosting_upgrade_list_error: response
                });
            });
    };
};

export const upgradeProrata = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_UPGRADE_PRORATA_REQUEST
        });
        API.hosting.POST.prorata(id, attributes)
            .then((response) => {
                const hosting_upgrade_prorata_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_UPGRADE_PRORATA_SUCCESS,
                    hosting_upgrade_prorata_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_UPGRADE_PRORATA_FAIL,
                    hosting_upgrade_prorata_error: response
                });
            });
    };
};

export const calculateHostingUpgradeCost = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_UPGRADE_CALCULATE_REQUEST
        });

        if (cancelHostingUpgradeCalculate) cancelHostingUpgradeCalculate();

        API.hosting.POST.custom
            .calculateCost(id, attributes)
            .then((response) => {
                const hosting_upgrade_calculate_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_UPGRADE_CALCULATE_SUCCESS,
                    hosting_upgrade_calculate_data
                });
            })
            .catch((error) => {
                if (error instanceof axios.Cancel) return;

                dispatch({ type: HOSTING_UPGRADE_CALCULATE_ERROR });
            });
    };
};

export const upgradeHostingService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_UPGRADE_REQUEST
        });
        API.hosting.POST.upgradeHosting(id, attributes)
            .then((response) => {
                const hosting_upgrade_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_upgrade_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_UPGRADE_SUCCESS,
                            hosting_upgrade_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const hosting_upgrade_error = getErrorFromFailResponse(error);
                pushNotification(hosting_upgrade_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_UPGRADE_FAIL,
                            hosting_upgrade_error
                        }
                    ])
                );
            });
    };
};

export const upgradeLegacyService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_UPGRADE_REQUEST
        });
        API.hosting.POST.upgradeLegacy(id, attributes)
            .then((response) => {
                const hosting_upgrade_data = getDataFromSuccessResponse(response);

                pushNotification(hosting_upgrade_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_UPGRADE_SUCCESS,
                            hosting_upgrade_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const hosting_upgrade_error = getErrorFromFailResponse(error);
                pushNotification(hosting_upgrade_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_UPGRADE_FAIL,
                            hosting_upgrade_error
                        }
                    ])
                );
            });
    };
};

/**********************************************************************************************************
 *   CPANEL
 **********************************************************************************************************/

export const cpanelLogin = (id, iOS) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CPANEL_LOGIN_REQUEST
        });
        const { closePopup, goToTargetUrl } = createPopup();

        API.hosting.GET.login
            .cpanel(id)
            .then((response) => {
                const hosting_cpanel_login_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_CPANEL_LOGIN_SUCCESS,
                    hosting_cpanel_login_data
                });

                goToTargetUrl(hosting_cpanel_login_data.attributes.url);
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_CPANEL_LOGIN_FAIL,
                    hosting_cpanel_login_error: response
                });

                closePopup();
            });
    };
};

export const getCpanelDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CPANEL_DETAILS_REQUEST
        });
        API.hosting.GET.details
            .cpanel(id)
            .then((response) => {
                const hosting_cpanel_details_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_CPANEL_DETAILS_SUCCESS,
                    hosting_cpanel_details_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_CPANEL_DETAILS_FAIL,
                    hosting_cpanel_details_error: response
                });
            });
    };
};

export const resetCpanelPassword = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CPANEL_REQUEST
        });
        API.hosting.POST.resetPassword(id)
            .then((response) => {
                const hosting_cpanel_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_cpanel_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_CPANEL_SUCCESS,
                            hosting_cpanel_data
                        },
                        getCpanelDetails(id)
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_CPANEL_FAIL,
                            hosting_cpanel_error: response
                        }
                    ])
                );
            });
    };
};

export const updateCpanelUsername = (id, attributes, username, history) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_CPANEL_REQUEST
            });
            API.hosting.subaccount.PUT.username(id, attributes, username)
                .then((response) => {
                    const hosting_cpanel_data = getDataFromSuccessResponse(response);
                    dispatch(
                        batchActions([
                            {
                                type: HOSTING_CPANEL_SUCCESS,
                                hosting_cpanel_data
                            },
                            resetResellerStateList()
                        ])
                    );
                    history.push(`/my-services/hosting/reseller/subaccounts/${id}`);
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_CPANEL_FAIL,
                        hosting_cpanel_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_CPANEL_REQUEST
        });
        API.hosting.PUT.cpanel(id, attributes)
            .then((response) => {
                const hosting_cpanel_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_cpanel_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_CPANEL_SUCCESS,
                            hosting_cpanel_data
                        },
                        getCpanelDetails(id)
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: HOSTING_CPANEL_FAIL,
                    hosting_cpanel_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   HOSTING DETAILS
 **********************************************************************************************************/

export const getHostingInformation = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_INFORMATION_REQUEST
        });
        API.hosting.GET.information(id)
            .then((response) => {
                const hosting_information_data = getDataFromSuccessResponse(response);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_SET_RESELLER,
                            hosting_isReseller: hosting_information_data['included'][0].attributes['is_reseller']
                        },
                        {
                            type: HOSTING_INFORMATION_SUCCESS,
                            hosting_information_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_INFORMATION_FAIL,
                    hosting_information_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   PLESK DETAILS
 **********************************************************************************************************/

export const getPleskDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_PLESK_REQUEST
        });
        API.hosting.GET.details
            .plesk(id)
            .then((response) => {
                const hosting_plesk_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_PLESK_SUCCESS,
                    hosting_plesk_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_PLESK_FAIL,
                    hosting_plesk_error: response
                });
            });
    };
};

export const pleskLogin = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CPANEL_LOGIN_REQUEST
        });

        const { closePopup, goToTargetUrl } = createPopup();

        API.hosting.GET.login
            .plesk(id)
            .then((response) => {
                const hosting_cpanel_login_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_CPANEL_LOGIN_SUCCESS,
                    hosting_cpanel_login_data
                });

                goToTargetUrl(hosting_cpanel_login_data.attributes.url);
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_CPANEL_LOGIN_FAIL,
                    hosting_cpanel_login_error: response
                });
                closePopup();
            });
    };
};

/**********************************************************************************************************
 *   DISK BLOCKS
 **********************************************************************************************************/
export const resetDiskBlockInfo = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_DISKBLOCK_RESET
        });
    };
};

export const getDiskBlockInfo = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_DISKBLOCK_LIST_REQUEST
        });
        API.hosting.GET.diskblocks(id)
            .then((response) => {
                const hosting_diskblock_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_DISKBLOCK_LIST_SUCCESS,
                    hosting_diskblock_list_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_DISKBLOCK_LIST_FAIL,
                    hosting_diskblock_list_error: response
                });
            });
    };
};

export const purchaseDiskBlock = (id, diskblock, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_DISKBLOCK_PURCHASE_REQUEST
        });
        API.hosting.POST.purchaseDiskBlock(id, diskblock, attributes)
            .then((response) => {
                const hosting_diskblock_purchase_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_diskblock_purchase_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_DISKBLOCK_PURCHASE_SUCCESS,
                            hosting_diskblock_purchase_data
                        },
                        getDiskBlockInfo(id)
                    ])
                );
            })
            .catch((error) => {
                const hosting_diskblock_purchase_error = getErrorFromFailResponse(error);
                pushNotification(hosting_diskblock_purchase_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_DISKBLOCK_PURCHASE_FAIL,
                            hosting_diskblock_purchase_error
                        }
                    ])
                );
            });
    };
};

export const removeDiskBlock = (id, diskblock) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_DISKBLOCK_REQUEST
        });
        API.hosting.POST.removeDiskBlock(id, diskblock)
            .then((response) => {
                const hosting_diskblock_data = getDataFromSuccessResponse(response);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_DISKBLOCK_SUCCESS,
                            hosting_diskblock_data
                        },
                        getDiskBlockInfo(id)
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_DISKBLOCK_FAIL,
                    hosting_diskblock_error: response
                });
            });
    };
};

export const cancelRemoveDiskBlock = (id, diskblock) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_DISKBLOCK_REQUEST
        });
        API.hosting.POST.cancelRemoveDiskBlock(id, diskblock)
            .then((response) => {
                const hosting_diskblock_data = getDataFromSuccessResponse(response);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_DISKBLOCK_SUCCESS,
                            hosting_diskblock_data
                        },
                        getDiskBlockInfo(id)
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_DISKBLOCK_FAIL,
                    hosting_diskblock_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   BILLING CYCLE
 **********************************************************************************************************/

export const getHostingBillingCycles = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_BILLINGCYCLE_REQUEST
        });
        API.hosting.GET.billingCycle(id)
            .then((response) => {
                const hosting_billingcycle_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_BILLINGCYCLE_SUCCESS,
                    hosting_billingcycle_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_BILLINGCYCLE_FAIL,
                    hosting_billingcycle_error: response
                });
            });
    };
};

export const updateBillingCycle = (id, attributes, oldInvoiceId) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_BILLING_REQUEST
        });
        API.hosting.POST.billingCycle(id, attributes)
            .then((response) => {
                const hosting_billing_data = getDataFromSuccessResponse(response);

                function getNotificationData() {
                    if (hosting_billing_data['new_invoice_id']) {
                        return {
                            ...hosting_billing_data,
                            details: `Billing cycle successfully changed. Your existing invoice (#${oldInvoiceId}) has been cancelled and a new invoice (#${hosting_billing_data['new_invoice_id']}) has been generated. View your invoices <a href="/billing/invoices">here</a>.`
                        };
                    }

                    return hosting_billing_data;
                }

                const notificationData = getNotificationData();

                pushNotification(notificationData);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_BILLING_SUCCESS,
                            hosting_billing_data
                        },
                        getHostingInformation(id)
                    ])
                );
            })
            .catch((error) => {
                const hosting_billing_error = getErrorFromFailResponse(error);
                pushNotification(hosting_billing_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_BILLING_FAIL,
                            hosting_billing_error
                        }
                    ])
                );
            });
    };
};

/**********************************************************************************************************
 *   PRIMARY DOMAIN
 **********************************************************************************************************/
export const updatePrimaryDomain = (id, attributes, username, history) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_PRIMARY_REQUEST
            });
            API.hosting.subaccount.PUT.primary(id, attributes, username)
                .then((response) => {
                    const hosting_primary_data = getDataFromSuccessResponse(response);
                    pushNotification(hosting_primary_data);
                    dispatch(
                        batchActions([
                            {
                                type: HOSTING_PRIMARY_SUCCESS,
                                hosting_primary_data
                            },
                            resetResellerStateList()
                        ])
                    );
                    history.push(`/my-services/hosting/reseller/subaccounts/${id}`);
                })
                .catch((error) => {
                    const hosting_primary_error = getErrorFromFailResponse(error);
                    pushNotification(hosting_primary_error);
                    dispatch(
                        batchActions([
                            {
                                type: HOSTING_PRIMARY_FAIL,
                                hosting_primary_error
                            }
                        ])
                    );
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_PRIMARY_REQUEST
        });
        API.hosting.PUT.primary(id, attributes)
            .then((response) => {
                const hosting_primary_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_primary_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_PRIMARY_SUCCESS,
                            hosting_primary_data
                        },
                        getHostingInformation(id)
                    ])
                );
            })
            .catch((error) => {
                const hosting_primary_error = getErrorFromFailResponse(error);
                pushNotification(hosting_primary_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_PRIMARY_FAIL,
                            hosting_primary_error
                        }
                    ])
                );
            });
    };
};

/**********************************************************************************************************
 *   RENEW HOSTING
 **********************************************************************************************************/
export const getPrepayInformation = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_RENEW_INFO_REQUEST
        });
        SERVICES.service.get
            .renewInfo(id)
            .then((response) => {
                const hosting_prepay_info_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_RENEW_INFO_SUCCESS,
                    hosting_prepay_info_data
                });
            })
            .catch((error) => {
                const hosting_prepay_info_error = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_RENEW_INFO_FAIL,
                    hosting_prepay_info_error
                });
            });
    };
};

export const getPrepayDetail = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_PREPAY_DETAILS_REQUEST
        });
        SERVICES.service.post
            .renewDetails(id, attributes)
            .then((response) => {
                const hosting_prepay_details_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_PREPAY_DETAILS_SUCCESS,
                    hosting_prepay_details_data
                });
            })
            .catch((error) => {
                const hosting_prepay_details_error = getErrorFromFailResponse(error);
                if (hosting_prepay_details_error.status !== 'Failed') {
                    pushNotification(hosting_prepay_details_error);
                    dispatch(
                        batchActions([
                            {
                                type: HOSTING_PREPAY_DETAILS_ERROR,
                                hosting_prepay_details_error
                            }
                        ])
                    );
                }
            });
    };
};

export const prepayHostingService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_RENEW_REQUEST
        });
        SERVICES.service.post
            .renew(id, attributes)
            .then((response) => {
                const hosting_prepay_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_RENEW_SUCCESS,
                    hosting_prepay_data
                });
            })
            .catch((error) => {
                const hosting_prepay_error = getErrorFromFailResponse(error);
                pushNotification(hosting_prepay_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_RENEW_FAIL,
                            hosting_prepay_error
                        }
                    ])
                );
            });
    };
};

/**********************************************************************************************************
 *   CUSTOM HOSTING
 **********************************************************************************************************/
export const getCustomHosting = (id) => {
    return (dispatch) => {
        dispatch({
            type: CUSTOM_HOSTING_DETAILS_REQUEST
        });

        API.hosting.GET.custom
            .details(id)
            .then((response) => {
                const custom_hosting_details_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: CUSTOM_HOSTING_DETAILS_SUCCESS,
                    custom_hosting_details_data
                });
            })
            .catch((error) => {
                const custom_hosting_details_error = getErrorFromFailResponse(error);
                dispatch({
                    type: CUSTOM_HOSTING_DETAILS_FAIL,
                    custom_hosting_details_error
                });
            });
    };
};

export const resetCalculationStatus = () => {
    return (dispatch) => {
        dispatch({
            type: CUSTOM_HOSTING_CALCULATE_RESET
        });
    };
};

export const calculateCustomHostingCost = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: CUSTOM_HOSTING_CALCULATE_REQUEST
        });

        if (cancelCalculateChangePlanCost) cancelCalculateChangePlanCost();

        SERVICES.service.post
            .changePlanCalculate(id, attributes)
            .then((response) => {
                const custom_hosting_calculate_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: CUSTOM_HOSTING_CALCULATE_SUCCESS,
                    custom_hosting_calculate_data
                });
            })
            .catch((error) => {
                if (error instanceof axios.Cancel) return;

                const custom_hosting_calculate_error = getErrorFromFailResponse(error);

                dispatch({
                    type: CUSTOM_HOSTING_CALCULATE_FAIL,
                    custom_hosting_calculate_error
                });
            });
    };
};

export const updateCustomHosting = (id, attributes, options) => {
    return (dispatch) => {
        dispatch({
            type: CUSTOM_HOSTING_CHANGE_REQUEST
        });

        SERVICES.service.post
            .changePlan(id, attributes)
            .then((response) => {
                /***** MEGAMAY24 START *****/
                invalidateMegaMayAvailableSpinsQuery();
                /***** MEGAMAY24 END *****/

                const custom_hosting_change_data = getDataFromSuccessResponse(response);

                pushNotification({ details: custom_hosting_change_data.details, status: 200 });
                dispatch({
                    type: CUSTOM_HOSTING_CHANGE_SUCCESS,
                    custom_hosting_change_data
                });

                options?.successCallback?.(custom_hosting_change_data?.new_invoice_id);
            })
            .catch((error) => {
                const custom_hosting_change_error = getErrorFromFailResponse(error);
                pushNotification(custom_hosting_change_error);
                dispatch(
                    batchActions([
                        {
                            type: CUSTOM_HOSTING_CHANGE_FAIL,
                            custom_hosting_change_error
                        }
                    ])
                );
            });
    };
};

export const removeCustomHostingChangeRequest = (id) => {
    return (dispatch) => {
        dispatch({
            type: CUSTOM_HOSTING_REMOVE_REQUEST
        });

        API.hosting.POST.custom
            .removeChange(id)
            .then((response) => {
                const custom_hosting_remove_data = getDataFromSuccessResponse(response);
                pushNotification(custom_hosting_remove_data);
                dispatch(
                    batchActions([
                        {
                            type: CUSTOM_HOSTING_REMOVE_SUCCESS,
                            custom_hosting_remove_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const custom_hosting_remove_error = getErrorFromFailResponse(error);
                pushNotification(custom_hosting_remove_error);
                dispatch(
                    batchActions([
                        {
                            type: CUSTOM_HOSTING_REMOVE_FAIL,
                            custom_hosting_remove_error
                        }
                    ])
                );
            });
    };
};

export function clearSubAccountData() {
    const { dispatch } = store;
    dispatch({
        type: HOSTING_SUBACCOUNT_INFO_RESET
    });
}
