/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { isArray } from 'lodash';
import { useState } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaMutation } from 'containers/katana/queries/mutationTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { iterativelyUploadFiles } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/methods';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 * @param {UseKatanaFileUpload.OnChange} onChange
 * @param {{
 *  context?: import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Uploads.POST.Parameters['formData']['context']
 * }} options
 */
function useKatanaFileUpload(serviceID, onChange, options = {}) {
    /***** STATE *****/
    const [isUploading, setIsUploading] = useState(false);

    /***** QUERIES *****/
    const { mutateAsync: uploadFile } = katanaMutation.uploadFile.useMutation(serviceID);

    /***** FUNCTIONS *****/
    function onUploadStart() {
        setIsUploading(true);
    }

    function onUploadEnd() {
        setIsUploading(false);
    }

    /**
     *
     * @param {TNXDropZone.Values} files
     */
    const onChangeProxy = async (files) => {
        const isFilesArray = isArray(files);
        const filteredFiles = (isFilesArray ? files.flat() : [files]).filter((file) => file instanceof File);
        onUploadStart?.();
        for (const filteredFile of filteredFiles) {
            try {
                const uploadResults = await iterativelyUploadFiles([filteredFile], uploadFile, options);
                if (isFilesArray) {
                    onChange(uploadResults);
                } else {
                    onChange(uploadResults[0]);
                }
            } catch (error) {
                console.error('Error uploading files', error);
            }
        }
        onUploadEnd?.();
    };

    /***** HOOK RESULTS *****/
    return {
        isUploading,
        onChangeProxy
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useKatanaFileUpload };
