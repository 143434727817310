/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as DOMAIN_API } from 'utilities/api/domain';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const tppDomainTransferLockBoilerPlate = createQueryBoilerPlate({
    service: 'legacy-domain',
    serviceKey: 'TPP_DOMAIN_TRANSFER_LOCK',
    APIRoute: (domainId) => () => DOMAIN_API.tppDomains.GET.transferLock(domainId),
    defaultOptions: {
        staleTime: 60_000 * 10,
        select: () => (data) => ({
            ...data.data,
            attributes: data.data.attributes[0]
        })
    }
});

const { getDefaults } = tppDomainTransferLockBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets transfer lock state for a TPP domain
 */
export function useTppDomainTransferLockQuery(domainId, options = {}) {
    return useQuery({
        ...getDefaults(domainId),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
