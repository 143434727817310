/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import OutlineDropdown from 'components/Dropdowns/OutlineDropdown';
import SolidDropdown from 'components/Dropdowns/SolidDropdown';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import StyledLoader from 'components/Loaders/Styled';
import DialogNotification from 'components/Notifications/DialogNotification';
import ComponentDisabled from 'components/Overlays/ComponentDisabled';
import Search from 'components/Search';
import SolidTag from 'components/Tags/SolidTag';
import Tip from 'components/Tooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Box.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated For the sake of sanity, this component is now in __*Component Prison*__, The new `NXBox` is much more flexible and this is just a monster of a component. just don't use it. use `components/NXBox` instead!
 */
class Box extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            mounted: false,
            view: 'loading',
            showTabsDropdown: false
        };

        this.setView = this.setView.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.openTabsDropdown = this.openTabsDropdown.bind(this);
        this.closeTabsDropdown = this.closeTabsDropdown.bind(this);
        this.clickAway = this.clickAway.bind(this);

        this.tabDropdownRef = createRef();
    }

    setView(view) {
        this.setState({
            view
        });
    }

    makeRequest() {
        const { request } = this.props;

        if (request.args && Array.isArray(request.args) && request.args.length > 0) {
            request.action(...request.args);
        } else {
            request.action(request.args);
        }
    }

    openTabsDropdown() {
        this.setState({
            showTabsDropdown: true
        });
    }

    closeTabsDropdown() {
        this.setState({
            showTabsDropdown: false
        });
    }

    clickAway(e) {
        if (this.tabDropdownRef.current && !this.tabDropdownRef.current.contains(e.target)) {
            this.closeTabsDropdown();
        }
    }

    componentDidMount() {
        const { premounted, request } = this.props;
        const { makeRequest } = this;

        if (premounted) {
            this.setState({
                ready: true,
                mounted: true,
                view: 'success'
            });
        } else if (request) {
            makeRequest();
        }

        document.addEventListener('click', this.clickAway);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickAway);
    }

    componentDidUpdate(prevProps) {
        const { request, status } = this.props;
        const { ready, mounted } = this.state;

        if (request && !ready && request.status === 'success') {
            this.setState({
                ready: true,
                mounted: true,
                view: 'success'
            });
        } else if (request && !ready && request.status === 'error' && prevProps.request.status === 'loading') {
            this.setState({
                ready: true,
                mounted: true,
                view: 'error'
            });
        }

        if (ready && mounted && prevProps.status !== status) {
            this.setView(status, status === 'error');
        }
    }

    render() {
        const {
            app_viewport,
            request,
            disabled,
            className,
            status,
            title,
            desc,
            bottom,
            columns,
            columnsAtTop,
            action,
            notification,
            custom,
            color,
            footer,
            info,
            tooltip,
            split,
            recommended,
            chained,
            purchase,
            search,
            dropdown,
            toggle,
            override,
            tabs
        } = this.props;
        const { ready, mounted, view, showTabsDropdown } = this.state;
        const { makeRequest, openTabsDropdown, closeTabsDropdown } = this;

        /*   RENDER TITLE
         **********************************************************************************************************/
        const renderTitle = () => {
            if (!title) {
                return '';
            }

            function renderTitleAction() {
                if (!title || !title.action) {
                    return '';
                }

                if (title.action.icon) {
                    return (
                        <button
                            className="sharedBox__title--icon"
                            onClick={(e) => {
                                title.action.onClick(e);
                            }}
                        >
                            {title.action.icon ? <i className={`icon icon-${title.action.icon}`}></i> : ''}
                        </button>
                    );
                }

                return renderBottomAction(title.action);
            }

            return (
                <>
                    <div className={`sharedBox__title ${title.links && title.links.sub ? 'sublink' : ''}`}>
                        {toggle && toggle.condition ? (
                            <Tooltip
                                className="sharedBox__viewToggle--tooltip"
                                direction="down"
                                distance={10}
                                arrowSize={5}
                                content={<div className="sharedBox__tooltip">Go Back</div>}
                            >
                                <button className="sharedBox__viewToggle" onClick={(e) => toggle.action(e)}>
                                    <i className="icon icon-arrow-left"></i>
                                </button>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                        {title && typeof title === 'string' ? htmr(title) : title.title}
                        {title && title.subtitle ? title.subtitle : ''}
                        {renderTitleAction()}
                        {purchase ? renderPurchaseAnchor(purchase) : ''}
                        {title && title.link ? (
                            <Anchor className="sharedBox__title--purchaseLink" onClick={title.link.onClick}>
                                {title.link.title}
                            </Anchor>
                        ) : (
                            ''
                        )}
                        {title.links && (
                            <div className="sharedBox__links">
                                {title && title.links.main ? (
                                    <Anchor className="sharedBox__link" onClick={title.links.main.onClick}>
                                        {title.links.main.title}
                                    </Anchor>
                                ) : (
                                    ''
                                )}
                                {title && title.links.sub && <div className="sharedBox__separator">&nbsp;|&nbsp;</div>}
                                {title && title.links.sub ? (
                                    <Anchor className="sharedBox__link" onClick={title.links.sub.onClick}>
                                        {title.links.sub.title}
                                    </Anchor>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                        {recommended ? <SolidTag color="success">Recommended</SolidTag> : ''}
                    </div>
                </>
            );
        };

        /*   RENDER Tabs
         **********************************************************************************************************/
        const renderTabs = (data) => {
            return (
                <ul className="sharedBox__tabs">
                    {data.data.map((item) => {
                        return (
                            // Disabled lint warning as component is deprecated
                            // eslint-disable-next-line
                            <li
                                // Disabled lint warning as component is deprecated
                                // eslint-disable-next-line
                                tabIndex={0}
                                key={item.key}
                                className={`${item.className ? item.className : ''}${data.condition === item.key ? ' active' : ''}`}
                                onClick={item.action}
                                onKeyDown={(e) => {
                                    if (e.key === ' ') item.action(e);
                                }}
                            >
                                {data.active && data.active === item.key ? (
                                    <Tooltip
                                        className="checkIcon"
                                        direction="up"
                                        distance={10}
                                        arrowSize={5}
                                        content={<div className="checkIcon__tooltip">Current Configuration</div>}
                                    >
                                        <i className="icon icon-tick-solid"></i>
                                    </Tooltip>
                                ) : (
                                    ''
                                )}
                                {item.icon ? <i className={`icon icon-${item.icon}`}></i> : ''}
                                {item.title}
                            </li>
                        );
                    })}
                </ul>
            );
        };

        const renderMobileTabs = (data) => {
            return (
                <div className="sharedBox__tabs">
                    {/* Disabled lint warning as component is deprecated */}
                    {/* eslint-disable-next-line */}
                    <div
                        ref={this.tabDropdownRef}
                        className="sharedBox__tabs-label"
                        onClick={(e) => {
                            e.preventDefault();
                            if (showTabsDropdown) closeTabsDropdown();
                            else openTabsDropdown();
                        }}
                    >
                        {data.data.map((item) => {
                            if (data.condition === item.key) {
                                return (
                                    <Fragment key={item.key}>
                                        {item.icon ? <i className={`icon icon-${item.icon}`}></i> : ''}
                                        {item.title}
                                        <i className="icon icon-chevron-down"></i>
                                    </Fragment>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <ul className={`sharedBox__tabs-dropdown${showTabsDropdown ? ' show' : ''}`}>
                        {data.data.map((item) => {
                            return (
                                // Disabled lint warning as component is deprecated
                                // eslint-disable-next-line
                                <li
                                    // Disabled lint warning as component is deprecated
                                    // eslint-disable-next-line
                                    tabIndex={0}
                                    key={item.key}
                                    className={data.condition === item.key ? 'active' : ''}
                                    onClick={item.action}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ') item.action(e);
                                    }}
                                >
                                    {data.active && data.active === item.key ? (
                                        <Tooltip
                                            className="checkIcon"
                                            direction="up"
                                            distance={10}
                                            arrowSize={5}
                                            content={<div className="checkIcon__tooltip">Current Configuration</div>}
                                        >
                                            <i className="icon icon-tick-solid"></i>
                                        </Tooltip>
                                    ) : (
                                        ''
                                    )}
                                    {item.icon ? <i className={`icon icon-${item.icon}`}></i> : ''}
                                    {item.title}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        };

        /*   RENDER Anchor
         **********************************************************************************************************/
        const renderPurchaseAnchor = (data) => {
            switch (data.type) {
                case 'internal':
                    return (
                        <Anchor className="sharedBox__title--purchaseLink" onClick={data.link}>
                            {data.text}
                        </Anchor>
                    );

                default:
                case 'external':
                    return (
                        <Anchor className="sharedBox__title--purchaseLink" href={data.link} target="_blank">
                            {data.text}
                        </Anchor>
                    );
            }
        };

        /*   RENDER COLUMNS
         **********************************************************************************************************/
        const renderColumns = () => {
            if (!columns) {
                return false;
            }
            return columns.map((element, index) => {
                const { render, className } = element;
                return (
                    <div key={index} className={`sharedBox__column ${className ? className : ''}`}>
                        {render}
                    </div>
                );
            });
        };

        /*   RENDER ACTION
         **********************************************************************************************************/
        const renderBottomAction = (data) => {
            if (React.isValidElement(data)) {
                return data;
            }

            const { icon, label, buttonType, type, onClick, className, color, list, size } = data;
            if (disabled) {
                return (
                    <InactiveButton key={label + '-button'} className={className ? className : ''}>
                        {label}
                    </InactiveButton>
                );
            }

            if (icon && !label) {
                return (
                    // Disabled lint warning as component is deprecated
                    // eslint-disable-next-line
                    <div
                        key={label + '-button'}
                        className={`icon__button ${className ? className : ''}`}
                        onClick={(e) => {
                            onClick(e);
                        }}
                    >
                        <i className={`icon icon-${icon}`}></i>
                    </div>
                );
            }

            switch (buttonType) {
                case 'Inactive':
                    return (
                        <InactiveButton key={label + '-button'} className={className ? className : ''}>
                            {label}
                        </InactiveButton>
                    );

                case 'Solid':
                    // UNUSED
                    if (list) {
                        return (
                            <SolidDropdown
                                key={label + '-button'}
                                title={label}
                                color={color ? color : 'primary'}
                                type={type}
                                size={size ? size : 'small'}
                                titleOnClick={(e) => {
                                    onClick(e);
                                }}
                                className={className ? className : ''}
                                list={list}
                            />
                        );
                    } else {
                        return (
                            <SolidButton
                                key={label + '-button'}
                                color={color ? color : 'primary'}
                                type={type}
                                size={size ? size : 'small'}
                                onClick={(e) => {
                                    onClick(e);
                                }}
                                className={className ? className : ''}
                            >
                                {label}
                            </SolidButton>
                        );
                    }

                default:
                    if (list) {
                        return (
                            <OutlineDropdown
                                key={label + '-button'}
                                title={label}
                                color={color ? color : 'primary'}
                                type={type}
                                size={size ? size : 'small'}
                                titleOnClick={(e) => {
                                    onClick(e);
                                }}
                                className={className ? className : ''}
                                list={list}
                            />
                        );
                    } else {
                        return (
                            <OutlineButton
                                key={label + '-button'}
                                color={color ? color : 'primary'}
                                type={type}
                                size={size ? size : 'small'}
                                onClick={(e) => {
                                    onClick(e);
                                }}
                                className={className ? className : ''}
                            >
                                {label}
                            </OutlineButton>
                        );
                    }
            }
        };

        /*   RENDER SEARCH
         **********************************************************************************************************/
        const handleSearchRender = () => {
            return (
                <Search
                    className="sharedBox__search"
                    validate={search.validate}
                    slim={search.slim ? search.slim : false}
                    render={{
                        status: search.status,
                        placeholder: search.placeholder,
                        list: search.list ? search.list : false
                    }}
                    functions={{
                        cancel: search.functions.cancel,
                        search: search.functions.search,
                        reset: search.functions.reset
                    }}
                    helpers={{
                        keyword: search.helpers.keyword
                    }}
                />
            );
        };

        const handleConditionalRenders = () => {
            function renderFooterAction() {
                if (footer && !footer.action) {
                    return <div className="sharedBox__footer">{footer}</div>;
                }

                if (footer && footer.action) {
                    return handleFooterActionRender();
                }

                return '';
            }

            return (
                <>
                    {renderFooterAction()}
                    {info ? (
                        <div className="sharedBox__information">
                            <Tip className="top__right__balloon" info={info} />
                        </div>
                    ) : (
                        ''
                    )}
                    {tooltip ? (
                        <div className="sharedBox__information">
                            <Tip info={tooltip} />
                        </div>
                    ) : (
                        ''
                    )}
                    {dropdown && dropdown.condition ? renderBoxDropdown() : ''}
                </>
            );
        };

        /*   RENDER FOOTER ACTION
         **********************************************************************************************************/
        const handleFooterActionRender = () => {
            return (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        footer.action();
                    }}
                    className="sharedBox__footerAction"
                >
                    <span className="link">{footer.title}</span>
                </button>
            );
        };

        /*   RENDER DROPDOWN
         **********************************************************************************************************/
        const renderBoxDropdown = () => {
            return (
                <div className="sharedBox__dropdown">
                    <div className="sharedBox__dropdown--outerArrow">
                        <div className="sharedBox__dropdown--innerArrow"></div>
                    </div>
                    <div className="sharedBox__dropdown--header">
                        {dropdown.title ? <div className="sharedBox__dropdown--title">{dropdown.title}</div> : ''}
                        <button type="button" className="sharedBox__dropdown--close" onClick={dropdown.close}>
                            <i className="icon icon-x"></i>
                        </button>
                    </div>
                    <div className="sharedBox__dropdown--content">{dropdown.status === 'loading' ? <RequestLoader /> : dropdown.render}</div>
                </div>
            );
        };

        const handleSuccessRender = () => {
            function renderNotificationInfoAction() {
                if (typeof notification.info.action === 'object' && notification.info.action.constructor === Array) {
                    return notification.info.action.map((action) => {
                        return renderBottomAction(action);
                    });
                }

                return renderBottomAction(notification.info.action);
            }

            function renderBottomActionOrTop() {
                if (!bottom && action) {
                    return (
                        <div className="sharedBox__wrapper--top action">
                            <div className="sharedBox__details">
                                {renderTitle()}
                                {desc ? <div className="sharedBox__description">{desc}</div> : ''}
                            </div>
                            <div className="sharedBox__action">{renderBottomAction(action)}</div>
                        </div>
                    );
                }

                if (!title && !desc) {
                    return '';
                }

                return (
                    <div className="sharedBox__wrapper--top">
                        {title ? renderTitle() : ''}
                        {desc ? <div className="sharedBox__description">{desc}</div> : ''}
                    </div>
                );
            }

            return (
                <>
                    {disabled ? (
                        <ComponentDisabled icon={disabled.icon ? disabled.icon : false} message={disabled.message ? disabled.message : false} />
                    ) : (
                        ''
                    )}
                    {split ? (
                        <div className="sharedBox__container--split">
                            <div className={`sharedBox__half sharedBox__left ${split.left.className ? split.left.className : ''}`}>
                                {split.left.render}
                            </div>
                            <div className={`sharedBox__half sharedBox__right ${split.right.className ? split.right.className : ''}`}>
                                {split.right.render}
                            </div>
                        </div>
                    ) : (
                        <div className={`sharedBox__container ${custom && custom.pos === 'top' ? 'custom__top' : ''}`}>
                            {notification && notification.info ? (
                                <DialogNotification type={notification.info.type}>
                                    <div className="text">{notification.info.text}</div>
                                    {notification.info.action ? <div className="action">{renderNotificationInfoAction()}</div> : ''}
                                </DialogNotification>
                            ) : (
                                ''
                            )}
                            {custom && custom.pos === 'top' ? (
                                <div className="sharedBox__custom top">{custom.render}</div>
                            ) : (
                                <>
                                    {renderBottomActionOrTop()}
                                    {search ? handleSearchRender() : ''}
                                </>
                            )}
                            {columns && columnsAtTop ? (
                                <div className="sharedBox__wrapper--bottom">
                                    <div className="sharedBox__wrapper--bottom__container">
                                        <div className="bottom__wrapper--left">{renderColumns()}</div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            {custom && custom.pos === 'bottom' ? <div className="sharedBox__custom bottom">{custom.render}</div> : ''}
                            {bottom && !columnsAtTop ? (
                                <div className="sharedBox__wrapper--bottom">
                                    <div className="sharedBox__wrapper--bottom__container">
                                        <div className="bottom__wrapper--left">{renderColumns()}</div>
                                        {action ? <div className="bottom__wrapper--right">{renderBottomAction(action)}</div> : ''}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                    {handleConditionalRenders()}
                </>
            );
        };

        const handleErrorRender = () => {
            return (
                <>
                    <div className="sharedBox__container">
                        <div className="sharedBox__wrapper--top">
                            {title ? renderTitle() : ''}
                            <FetchComponentError />
                            <div className="error__refresh">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (request) {
                                            makeRequest();
                                        } else {
                                            this.forceUpdate();
                                        }
                                    }}
                                >
                                    <i className="icon icon-reload"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            );
        };

        const handleOverrideRender = () => {
            return (
                <>
                    {override}
                    {handleConditionalRenders()}
                </>
            );
        };

        const handleRenderer = () => {
            switch (view) {
                case 'error':
                    return handleErrorRender();

                case 'success':
                    if (override) {
                        return handleOverrideRender();
                    }

                    return handleSuccessRender();

                case 'loading':
                default:
                    if (mounted && ready) {
                        return (
                            <>
                                <RequestLoader />
                                {status && status.message ? <div className="sharedBox__loader--text"></div> : ''}
                            </>
                        );
                    }

                    return <StyledLoader />;
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <>
                {tabs && app_viewport !== 'sm' && app_viewport !== 'xs' ? renderTabs(tabs) : ''}
                <div
                    className={`sharedBox ${status === 'mounting' ? ' mounting' : ''}${color ? ` ${color}` : ''}${className ? ` ${className}` : ''}${
                        chained ? ' chained' : ''
                    }${tabs ? ' tabs' : ''}`}
                >
                    {tabs && (app_viewport === 'sm' || app_viewport === 'xs') ? renderMobileTabs(tabs) : ''}
                    {handleRenderer()}
                </div>
            </>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default connect((state) => ({
    app_viewport: state.app.app_viewport
}))(Box);
