/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _RadioButtonsWithSelectedRender as RadioButtonsWithSelectedRender } from 'components/Form/RadioButtonsWithSelectedRender/_RadioButtonsWithSelectedRender';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<TRadioButtonsWithSelectedRender.Redux.Default.Props>}
 */
const _RadioButtonsWithSelectedRenderReduxFormDefault = (props) => {
    const { input, label, meta, disabled, children, onSelect, className, fullWidth } = props;
    const { value, onChange, name } = input;

    /***** FUNCTIONS *****/
    /**
     * @param {unknown} v
     */
    function handleChange(v) {
        onSelect?.(v);
        onChange(v);
    }

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name}>{label}</FormLabel>

            <FormItemInner meta={meta} no-bg fullWidth={fullWidth}>
                <RadioButtonsWithSelectedRender value={value} onChange={handleChange}>
                    {children}
                </RadioButtonsWithSelectedRender>
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _RadioButtonsWithSelectedRenderReduxFormDefault };
