/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Progress from 'components/Progress';
import { ScrollableComponent } from 'components/ScrollableComponent';

/*   ACTIONS
 *****************************************************/
import { formatLuxonOrdinal, toLuxonDate, truncateText } from 'utilities/methods/commonActions';
import { getSubaccountList } from '../state/accountActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsVictoriaEducationHosting, getHostingProduct, getHostingProductName } from 'containers/hosting/methods';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import cPanelImg from 'assets/images/support/cpanel.svg';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Overview extends Component {
    constructor(props) {
        super(props);
        this.processUsage = this.processUsage.bind(this);
        this.processLimit = this.processLimit.bind(this);
        this.state = {
            usage: null,
            subaccount: null
        };
    }

    processLimit(limit) {
        if (limit && limit === 'unlimited') {
            return undefined;
        }

        const limitInt = limit / 1000 + 'GB';
        return limitInt;
    }

    processUsage(usage) {
        if (usage && usage === 'unlimited') {
            return undefined;
        }

        const usageInt = usage + 'MB';
        return usageInt;
    }

    componentDidUpdate(prevProps) {
        const { hosting_information_status, hosting_subaccount_list_status, hosting_subaccount_list_data } = this.props;

        registerScrollEvents(this, hosting_information_status === 'success' && prevProps.hosting_information_status === 'loading');

        if (
            hosting_subaccount_list_data?.attributes &&
            hosting_subaccount_list_status === 'success' &&
            prevProps.hosting_subaccount_list_status === 'loading'
        ) {
            const { attributes } = hosting_subaccount_list_data;
            const { disk_used, disk_quote, allowed_subaccount_amount, subaccounts } = attributes;

            if (subaccounts) {
                this.setState({
                    usage: {
                        disk_used,
                        disk_quote
                    },
                    subaccount: {
                        total: allowed_subaccount_amount,
                        length: subaccounts.length
                    }
                });
            }
        }
    }

    componentDidMount() {
        const { hostingid, getSubaccountList, hosting_subaccount_list_data } = this.props;

        if (!hosting_subaccount_list_data) {
            getSubaccountList(hostingid);
        } else if (hosting_subaccount_list_data?.attributes) {
            const { attributes } = hosting_subaccount_list_data;
            const { disk_used, disk_quote, allowed_subaccount_amount, subaccounts } = attributes;

            this.setState({
                usage: {
                    disk_used,
                    disk_quote
                },
                subaccount: {
                    total: allowed_subaccount_amount,
                    length: subaccounts.length
                }
            });
        }
    }

    render() {
        const { hostingid, hosting_isReseller, hosting_information_status, hosting_information_data } = this.props;

        const { attributes } = hosting_information_data;
        const { custom_hosting } = attributes;
        const { usage, subaccount } = this.state;
        const { processUsage, processLimit } = this;
        const { domain, domain_status, next_invoice_date, next_due_date, billing_cycle, registration_date, amount } =
            hosting_information_data.attributes;

        const product = getHostingProduct(hosting_information_data);
        const productName = getHostingProductName(product);
        const isVictoriaEducationHosting = checkIsVictoriaEducationHosting(productName);

        const isNonResellerCustomHosting = !hosting_isReseller && custom_hosting;
        const upgradePath = /** @type {const}  */ (
            `/my-services/hosting/account/${isNonResellerCustomHosting ? 'custom-hosting' : 'overview'}/${hostingid}`
        );

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        const conditionalProps = {
            className: `service__overview`,
            split: {
                left: {
                    render: (
                        <Fragment>
                            <h2 className="box__heading">Service Overview</h2>
                            <div className="info__container">
                                <div className="box__description">Primary Domain Name</div>
                                <div className="box__subheading">
                                    {truncateText(domain, 35, '...')}
                                    {isVictoriaEducationHosting ? null : (
                                        <ScrollableComponent.Anchor pathname={`/my-services/hosting/account/primary-domain/${hostingid}`}>
                                            Change
                                        </ScrollableComponent.Anchor>
                                    )}
                                </div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Status</div>
                                <div
                                    className={classNames('box__subheading', {
                                        warning: domain_status === 'Suspended',
                                        confirm: domain_status === 'Active'
                                    })}
                                >
                                    {domain_status}
                                </div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Hosting Plan</div>
                                <div className="box__subheading plan">
                                    {hosting_isReseller ? productName + ' (Reseller)' : productName + ' (Shared)'}
                                    {isVictoriaEducationHosting ? null : (
                                        <ScrollableComponent.Anchor pathname={upgradePath}>Upgrade</ScrollableComponent.Anchor>
                                    )}
                                </div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Activated</div>
                                <div className="box__subheading">
                                    {formatLuxonOrdinal(toLuxonDate(registration_date).toFormat('EEEE, MMM {d} yyyy'))}
                                </div>
                            </div>
                        </Fragment>
                    )
                },
                right: {
                    render: (
                        <Fragment>
                            <h2 className="box__heading">Billing Overview</h2>
                            {billing_cycle !== 'One Time' ? (
                                <>
                                    <div className="info__container">
                                        <div className="box__description">Next Renewal Date</div>
                                        <div className="box__subheading">
                                            {formatLuxonOrdinal(toLuxonDate(next_due_date).toFormat('EEEE, MMM {d} yyyy'))}
                                        </div>
                                    </div>
                                    <div className="info__container">
                                        <div className="box__description">Next Invoice Generation Date</div>
                                        <div className="box__subheading">
                                            {formatLuxonOrdinal(toLuxonDate(next_invoice_date).toFormat('EEEE, MMM {d} yyyy'))}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                            <div className="info__container">
                                <div className="box__description">Billing Cycle</div>
                                <div className="box__subheading">
                                    {billing_cycle}
                                    {!hosting_isReseller && !isVictoriaEducationHosting && (
                                        <ScrollableComponent.Anchor
                                            openDropdown={[
                                                { searchParamKey: 'open', searchParamValue: 'true' },
                                                { searchParamKey: 'type', searchParamValue: 'billing' }
                                            ]}
                                            pathname={`/my-services/hosting/account/renew/${hostingid}`}
                                        >
                                            Change
                                        </ScrollableComponent.Anchor>
                                    )}
                                </div>
                            </div>
                            {billing_cycle !== 'One Time' ? (
                                <div className="info__container">
                                    <div className="box__description">Renewal Amount</div>
                                    <div className="box__subheading">${amount} AUD</div>
                                </div>
                            ) : (
                                ''
                            )}
                        </Fragment>
                    )
                }
            }
        };

        /*  Render Component
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="hostingOverview"
            >
                <Box premounted={true} status={hosting_information_status} {...conditionalProps} />
                {!!hosting_isReseller && !!subaccount && (
                    <div className="hostingOverview__extended">
                        <div className="hostingOverview__extended--progress">
                            {subaccount ? (
                                <div>
                                    <div className="cpanelProgress">
                                        <img className="cpanelProgress__image" src={cPanelImg} alt="cpanel progress" />
                                        <Progress
                                            title="Active cPanel Accounts"
                                            max={subaccount.total}
                                            value={subaccount.length}
                                            valueTitle={subaccount.length}
                                            maxTitle={subaccount.total}
                                        />
                                    </div>
                                    <Anchor to={`/my-services/hosting/reseller/subaccounts/${hostingid}`}>View all cPanel services</Anchor>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="hostingOverview__extended--progress">
                            {!!usage && (
                                <Progress
                                    title="Disk Space"
                                    max={usage.disk_quote}
                                    value={usage.disk_used}
                                    valueTitle={processUsage(usage.disk_used)}
                                    maxTitle={processLimit(usage.disk_quote)}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_isReseller: state.hosting.hosting_isReseller,
        hosting_information_status: state.hosting.hosting_information_status,
        hosting_information_data: state.hosting.hosting_information_data,
        hosting_subaccount_list_status: state.hosting.hosting_subaccount_list_status,
        hosting_subaccount_list_data: state.hosting.hosting_subaccount_list_data
    };
};

const mapDispatchToProps = {
    getSubaccountList
};

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);
Overview = withRouter(Overview);

export default Overview;
