/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import CreditCardImage from 'components/CreditCardImage';
import RadioButtonCircle from 'components/Form/RadioButtonCircle';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CreditCardInvoiceForm from '../../forms/creditCardInvoiceForm';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePayInvoiceMutation } from 'containers/billing/queries/invoice';
import { useGetAvailablePaymentMethodsQuery } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CREDIT_CARD_TYPES } from 'components/CreditCardImage/consts';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import { PAYMENT_METHODS, selectedPaymentMethodPropTypes } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      selectedPaymentMethod: typeof import('./consts').PAYMENT_METHODS_VALUES[number];
 *      setSelectedPaymentMethod: (method: typeof import('./consts').PAYMENT_METHODS_VALUES[number]) => void;
 *      invoiceID: number;
 *      mutationOptions: {
 *          onSuccess:function;
 *          onError:function
 *      };
 * }>}
 */
const CreditCardPaymentMethod = ({ selectedPaymentMethod, setSelectedPaymentMethod, invoiceID, mutationOptions }) => {
    /***** QUERIES *****/
    const { data: paymentMethodID, isLoading: isGetAvailablePaymentMethodsLoading } = useGetAvailablePaymentMethodsQuery({
        select: useGetAvailablePaymentMethodsQuery.selectFilters.getPaymentMethodID(PAYMENT_METHODS.CREDIT_CARD),
        retryOnMount: false
    });

    const { mutateAsync: mutatePayInvoice } = usePayInvoiceMutation(mutationOptions);

    /***** FUNCTIONS *****/
    function handleCreditCardInvoiceForm(values) {
        const { save_details = false } = values;

        const creditCardName = values[CREDIT_CARD_FIELD_DATA.CARD_HOLDER_NAME.name];
        const creditCardNumber = values[CREDIT_CARD_FIELD_DATA.CARD_NUMBER.name];
        const creditCardMonth = values[CREDIT_CARD_FIELD_DATA.CARD_MONTH.name];
        const creditCardYear = values[CREDIT_CARD_FIELD_DATA.CARD_YEAR.name];
        const cvv = values[CREDIT_CARD_FIELD_DATA.CARD_CVV.name];

        return mutatePayInvoice({
            invoiceID,
            payment_method_id: paymentMethodID,
            payment_data: {
                card_holder: creditCardName,
                card_number: creditCardNumber.replaceAll(' ', ''),
                expiry: `${creditCardMonth}/${creditCardYear}`,
                cvv,
                save_details
            }
        });
    }
    /***** RENDER HELPERS *****/
    const isCreditCardSelected = selectedPaymentMethod === PAYMENT_METHODS.CREDIT_CARD;

    /***** RENDER *****/
    return (
        <div
            key="creditCard"
            className={classNames('method', {
                'method--selected': isCreditCardSelected
            })}
        >
            <RadioButtonCircle.Button
                className="method__top"
                isActive={isCreditCardSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.CREDIT_CARD)}
            >
                <div className="method__top--title">
                    <div className="label">Credit Card</div>
                </div>
                <div className="method__top--img">
                    <CreditCardImage className="cc_icon creditcard" cardType={CREDIT_CARD_TYPES.VISA} />
                    <CreditCardImage className="cc_icon creditcard" cardType={CREDIT_CARD_TYPES.MASTER_CARD} />
                    <CreditCardImage className="cc_icon creditcard" cardType={CREDIT_CARD_TYPES.AMERICAN_EXPRESS} />
                </div>
            </RadioButtonCircle.Button>
            {isCreditCardSelected ? (
                <div className="method__bottom">
                    <div className="creditCard__wrapper">
                        {!paymentMethodID || isGetAvailablePaymentMethodsLoading ? (
                            <RequestLoader />
                        ) : (
                            <CreditCardInvoiceForm onSubmit={handleCreditCardInvoiceForm} />
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CreditCardPaymentMethod.propTypes = {
    ...selectedPaymentMethodPropTypes
};

export default CreditCardPaymentMethod;
