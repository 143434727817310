/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   CONSTS - DATA
 **********************************************************************************************************/
const radioGroupField = getFieldComponentAndClassName(FieldTypesEnum.RADIO_GROUP);
const technicalPersonOptions = [
    {
        value: false,
        label: 'I am NOT a technical person, please be gentle!'
    },
    {
        value: true,
        label: 'I am a technical person, I can handle the jargon!'
    }
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let TechnicalPersonForm = (props) => {
    const { app_user_data } = props;

    if (!app_user_data || app_user_data.is_technical !== null) return '';

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="migrationRequestForm__step migrationRequestForm__technicalClient migrationRequestForm__stepWrapper">
            <div className="migrationRequestForm__technicalClientTop">
                <SolidTag color="warning">NEW</SolidTag>
                <h4 className="migrationRequestForm__technicalClientTitle">Are you a technical person?</h4>
            </div>
            <p className="migrationRequestForm__technicalClientDesc">
                Our team wants to do their best to speak your language and provide you with an outstanding experience every time. To help them do
                that, you can now tell us whether or not you consider yourself to be a technical person and we will tailor our communication
                accordingly.
                <br />
                <br />
                Please note that when enabling the technical person option that it will be set to the entire account, meaning all future communication
                by eTicket, live chat or phone will follow this setting. You can change your technical person preference at any time in Account
                Settings.
            </p>

            <Field
                component={radioGroupField.component}
                name="is_technical"
                type={FieldTypesEnum.RADIO_GROUP}
                className={radioGroupField.className}
                options={technicalPersonOptions}
            />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    app_user_data: state.app.app_user_data
});

TechnicalPersonForm = connect(mapStateToProps, null)(TechnicalPersonForm);

export default TechnicalPersonForm;
