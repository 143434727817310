/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import StepTitle from '../stepTitle';
import FormStep from '../formStep';
import ControlPanelDetails from './controlPanelDetails';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ControlPanelSection1 = (props) => {
    const {
        onSubmit,
        validationAttempts,
        handleSkipNow,
        stepOneComplete,
        currentEnv,
        serviceValidationData,
        handleFormDetailsFieldChange,
        serviceValidationModifiedData,
        allowEditDetails,
        canEditDetails,
        selectedService
    } = props;

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <FormStep>
            {/* Render step 1 here */}
            <StepTitle>
                Step 1. Provide your&nbsp;
                {currentEnv ? <strong>{currentEnv}&nbsp;</strong> : ''}
                details
            </StepTitle>

            <ControlPanelDetails
                onSubmit={onSubmit}
                validationAttempts={validationAttempts}
                handleSkipNow={handleSkipNow}
                stepOneComplete={stepOneComplete}
                serviceValidationData={serviceValidationData}
                handleFormDetailsFieldChange={handleFormDetailsFieldChange}
                serviceValidationModifiedData={serviceValidationModifiedData}
                allowEditDetails={allowEditDetails}
                canEditDetails={canEditDetails}
                selectedService={selectedService}
            />
        </FormStep>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ControlPanelSection1;
