/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FormWrapper from '../formWrapper';
import CpanelSection1 from './cpanelSection1';
import CpanelSection2 from './cpanelSection2';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const CpanelForm = (props) => {
    const { currentEnv, selectedService } = props;

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <FormWrapper service_id={selectedService}>
            {({
                validateDetails,
                handleSubmitMigration,
                attemptsLeft,
                handleSkipNow,
                stepOneComplete,
                serviceValidationData,
                handleFormDetailsFieldChange,
                serviceValidationModifiedData,
                canEditDetails,
                allowEditDetails
            }) => (
                <>
                    <CpanelSection1
                        onSubmit={(values) => validateDetails('cpanel', values)}
                        validationAttempts={attemptsLeft}
                        handleSkipNow={handleSkipNow}
                        stepOneComplete={stepOneComplete}
                        serviceValidationData={serviceValidationData}
                        handleFormDetailsFieldChange={handleFormDetailsFieldChange}
                        serviceValidationModifiedData={serviceValidationModifiedData}
                        allowEditDetails={allowEditDetails}
                        canEditDetails={canEditDetails}
                        selectedService={selectedService}
                    />

                    {/* Render all others steps here */}
                    {stepOneComplete ? (
                        <CpanelSection2
                            onSubmit={(values) => {
                                return handleSubmitMigration('cpanel', currentEnv, values);
                            }}
                        />
                    ) : (
                        ''
                    )}
                </>
            )}
        </FormWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default CpanelForm;
