/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SkipDetailValidation = (props) => {
    const { onSkip } = props;

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="migrationRequestForm__stepWrapper">
            <div className="migrationRequestForm__wrapperbox migrationRequestForm__skipValidation">
                <h3 className="migrationRequestForm__title">We can get this fixed up later</h3>
                <p className="migrationRequestForm__body">
                    Our technical support team will work with you after this migration request has been submitted to clear up any issues which may
                    prevent the migration from being completed.
                </p>
                <SolidButton type="onClick" onClick={onSkip}>
                    Skip For Now
                </SolidButton>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SkipDetailValidation.propTypes = {
    /**
     * Function that will handle the skipping to the next section.
     */
    onSkip: PropTypes.func.isRequired
};

export default SkipDetailValidation;
