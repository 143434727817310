/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { billingCycles } from 'config/config';

/*   ACTIONS
 *****************************************************/
import { requiredFieldValidation, RenderRadioGroup } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PlanField({ options, billingCycle }) {
    /***** RENDER HELPERS *****/
    const getOptions = () =>
        options.map(({ planName, id, pricing, desc }) => {
            function getDesc() {
                if (pricing[billingCycle]?.discounted_price) {
                    return (
                        <>
                            <s>${pricing[billingCycle]?.base_price}</s> ${pricing[billingCycle]?.discounted_price} {billingCycles[billingCycle]} AUD
                        </>
                    );
                }

                return `$${pricing[billingCycle]?.base_price} ${billingCycles[billingCycle]} AUD`;
            }

            return {
                label: (
                    <>
                        <p>{planName}</p>
                        <small>{desc}</small>
                    </>
                ),
                value: id,
                desc: getDesc()
            };
        });

    /***** RENDER *****/
    return (
        <div className="form__row mailboxPlanFields">
            <div className="form__column full">
                <Field
                    label="Email Hosting Plan"
                    name="product_id"
                    outlined={true}
                    component={RenderRadioGroup}
                    validate={requiredFieldValidation}
                    options={getOptions()}
                />
            </div>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PlanField.propTypes = {
    // Whether or not to disable to "domain" field (should be disabled if it is already prefilled)
    options: PropTypes.arrayOf(
        PropTypes.shape({
            planName: PropTypes.string,
            id: PropTypes.string,
            pricing: PropTypes.object,
            desc: PropTypes.string
        })
    ),

    // The currently selected biling cycle, used to show the pricing of the plans
    billingCycle: PropTypes.string
};

export default PlanField;
