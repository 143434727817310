/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation, useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useSearchParams from 'utilities/hooks/useSearchParams';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Util for a dropdown which relies on url params to know whether it's open or not
 * @param {string} path
 * @param {{
 *      key: string
 *      value: string
 * }} searchParam
 * @returns {{
 *      openDropdown: () => void
 *      closeDropdown: () => void
 *      isDropdownOpen: boolean
 * }}
 */
function useUrlDropdown(path, searchParam = { key: 'open', value: 'true' }) {
    /***** HOOKS *****/
    const location = useLocation();
    const history = useHistory();
    const { searchParamsGet } = useSearchParams();

    /***** FUNCTIONS *****/
    function open() {
        history.push(`${path}?${searchParam.key}=${searchParam.value}`);
    }

    function close() {
        history.push(path);
    }

    /***** RETURN *****/
    return {
        openDropdown: open,
        closeDropdown: close,
        isDropdownOpen: !!(location.pathname.includes(path) && searchParamsGet(searchParam.key) === searchParam.value)
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useUrlDropdown;
