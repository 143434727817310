/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import axios from 'axios';
import { nxAxios } from 'store/nxAxios';
const CancelToken = axios.CancelToken;

export const checkCodeCancelTokens = {};

/**********************************************************************************************************
 *   LOGIN API
 **********************************************************************************************************/
export const PROMOTIONS = {
    GET: {
        freeOffer: () => {
            return axios.get('/api/promotion/free-offer');
        },

        radixRenewOffers: (id) => axios.get(`/api/promotion/radix-2023/${id}`),

        nrlOffer: {
            drawEligibilty: () => nxAxios.get('/api/promotion/viprewards/nrl-draw-tickets-2024/check-entry')
        },

        /***** MEGAMAY24 START *****/
        megamay: {
            availableSpins: () => nxAxios.get('/api/promotion/megamay2024/spintowin/redeemable-list')
        }
        /***** MEGAMAY24 END *****/
    },

    POST: {
        checkCode: (data) => {
            return axios.post(`/api/promotion/check-code`, data, {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    if (Object.prototype.hasOwnProperty.call(checkCodeCancelTokens, data.promo_code)) {
                        checkCodeCancelTokens[data.promo_code]();
                        delete checkCodeCancelTokens[data.promo_code];
                    }
                    checkCodeCancelTokens[data.promo_code] = c;
                })
            });
        },

        nrlOffer: {
            enterDraw: () => nxAxios.post('api/promotion/viprewards/nrl-draw-tickets-2024/enter-draw')
        },

        /***** MEGAMAY24 START *****/
        megamay: {
            drawPrize: () => nxAxios.post('/api/promotion/megamay2024/spintowin/draw-prize')
        }
        /***** MEGAMAY24 END *****/
    }
};
