/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import htmr from 'htmr';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/*   ACTIONS
 *****************************************************/
import { generateId, scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class HostingUpgradeSelection extends Component {
    constructor(props) {
        super(props);

        const fallbackMinPrice = '14.00';

        const getBusinessHostingPrice = () => {
            let success = true;

            const sortedCheapestFirst = [...(props.hosting_upgrade_list_data || [])].sort((a, b) => {
                if (!a.attributes?.price?.Monthly || !b.attributes?.price?.Monthly) {
                    success = false;
                    return 0;
                }
                return Number(a.attributes.price.Monthly) > Number(b.attributes.price.Monthly) ? 1 : -1;
            });
            const lowest = sortedCheapestFirst[0]?.attributes?.price?.Monthly;

            return success && lowest ? lowest : fallbackMinPrice;
        };

        const getCustomHostingPrice = () => {
            let success = true;

            const getCheapest = (arr) => {
                const sortedCheapestFirst = arr
                    .filter((option) => option.show_order)
                    .sort((a, b) => {
                        if (!a?.pricing?.Monthly || !b?.pricing?.Monthly) {
                            success = false;
                            return 0;
                        }
                        return a.pricing.Monthly > b.pricing.Monthly ? 1 : -1;
                    });

                return sortedCheapestFirst[0]?.pricing?.Monthly || 0;
            };

            const lowestCPU = getCheapest(props.hosting_resource_config_data?.attributes?.CPU || []);
            const lowestDisk = getCheapest(props.hosting_resource_config_data?.attributes?.['Disk Space'] || []);
            const lowestMem = getCheapest(props.hosting_resource_config_data?.attributes?.Memory || []);

            if (!success || !lowestCPU || !lowestDisk || !lowestMem) return fallbackMinPrice;

            return (lowestCPU + lowestDisk + lowestMem).toFixed(2);
        };

        this.componentRef = createRef();
        this.productTypes = [
            {
                icon: 'icon-business',
                title: 'Business Hosting',
                desc: 'Bringing your business online is now more important than ever, with our cloud-based web hosting plans your website will have the ultimate speed and security.',
                subDesc: `<p><strong>Perfect for</strong> business websites and e-commerce.</p>`,
                price: `$${getBusinessHostingPrice()}/mo`,
                template: 'business'
            },
            {
                icon: 'icon-custom',
                title: 'Custom Hosting',
                desc: 'The power is yours with custom cPanel hosting. Custom hosting gives you the ability to select a resource limit that best suits all of your hosting needs.',
                subDesc: `<p><strong>Ideal for</strong> everyone, fully customisable hosting.</p>`,
                price: `$${getCustomHostingPrice()}/mo`,
                template: 'custom'
            }
        ];
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { componentRef } = this;

        scrollToRef(componentRef?.current);
    }

    render() {
        const { componentRef, productTypes } = this;
        const { action } = this.props;

        const handleProductSelectRender = () => {
            return productTypes.map((product) => {
                return (
                    <div key={generateId()} className="hostingProductSelect__product">
                        <i
                            className={`icon icon-${product.icon} product__icon ${
                                product.icon === 'icon-custom' ? 'purpleGradient' : 'blueGradient'
                            }`}
                        ></i>
                        <div className="product__title">{product.title}</div>
                        <div className="product__price">from {product.price}</div>
                        <div className="product__desc">{product.desc}</div>
                        <div className="product__divider"></div>
                        <div className="product__subDesc">{htmr(product.subDesc)}</div>
                        <SolidButton
                            type="onClick"
                            onClick={(e) => {
                                action(product.template);
                            }}
                        >
                            View Plans
                        </SolidButton>
                    </div>
                );
            });
        };

        return (
            <div ref={componentRef} className="hostingProductSelect">
                {handleProductSelectRender()}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect((state) => ({
        hosting_resource_config_data: state.hosting.hosting_resource_config_data,
        hosting_upgrade_list_data: state.hosting.hosting_upgrade_list_data
    }))(HostingUpgradeSelection)
);
