import { createContext } from 'react';

export const ContentEditorRenderModulesContext = createContext(
    /** @type {TContentEditorRenderModules.Context} */ ({
        pristine: true,
        submitting: false,
        valid: false,
        anyTouched: false,
        form: null,
        submitButtonClickEvent: null
    })
);
