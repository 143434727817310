/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { eft_details } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let EFTTransferMethodInfo = ({ app_user_data }) => {
    /***** RENDER *****/
    return (
        <div key="efttransfer" className="tab__item availablePaymentMethod--efttransfer efttransfer">
            <div className="efttransfer__table table">
                <div className="table__row">
                    <div className="table__column table__column--label">Account Name</div>
                    <div className="table__column table__column--value">{eft_details.name}</div>
                </div>
                <div className="table__row">
                    <div className="table__column table__column--label">BSB</div>
                    <div className="table__column table__column--value">{eft_details.bsb}</div>
                </div>
                <div className="table__row">
                    <div className="table__column table__column--label">Account Number</div>
                    <div className="table__column table__column--value">{eft_details.account}</div>
                </div>
                <div className="table__row">
                    <div className="table__column table__column--label">Reference Number</div>
                    <div className="table__column table__column--value">{app_user_data.account_number}</div>
                </div>
            </div>
            <div className="availablePaymentMethod__description efttransfer__description">
                Please ensure you use your account number as a payment reference. Also allow 1-2 business days for payments to process. This may be
                further delayed by weekends and public holidays.
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        app_user_data: state.app.app_user_data
    };
};
EFTTransferMethodInfo = connect(mapStateToProps)(EFTTransferMethodInfo);

export default EFTTransferMethodInfo;
