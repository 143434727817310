/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import stars from 'assets/images/ventraSynMigration/temp/stars.svg';
import React, { Component } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class MarketingSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quotes: [
                {
                    customer: 'Damien Saunders',
                    body: 'Just moved 15 websites to Synergy Wholesale. I had put off the task for longer than necessary. The process could not have been easier and everything went without issue...very impressed, much appreciated.'
                },
                {
                    customer: 'Creative Ground',
                    body: 'Synergy Wholesale have looked after us for years. There pricing is great and their support is exceptional. We highly recommend.'
                }
            ]
        };
    }

    render() {
        const { numOfQuotes } = this.props;
        const { quotes } = this.state;

        const benefits = [
            {
                icon: 'icon-sw-create-custom-plans',
                text: 'Create custom hosting plans',
                tooltip: 'Enjoy the freedom of scaling up or down your space, RAM and CPU with no downtime involved.'
            },
            {
                icon: 'icon-sw-aus-servers',
                text: 'Australian Based servers',
                tooltip:
                    'NextDCs S1 data-centre in Sydney is where our partners call home for their hosting services. A state of the order Tier III facility.'
            },
            {
                icon: 'icon-sw-aus-support',
                text: '24/7 Australia Support',
                tooltip: 'Like VentraIP, we offer our partners 100% local support around the clock via phone, live chat, and our ticketing system!'
            },
            {
                icon: 'icon-sw-uptime-sla',
                text: '99.9% Uptime Guarantee',
                tooltip: 'The internet never sleeps, neither should your websites. With our 99.9% uptime guarantee, you know you can rely on us.'
            },
            {
                icon: 'icon-sw-offsite-backups',
                text: 'Hourly Off-Site Acronis Backups',
                tooltip:
                    'Rest easy knowing your data is backed up hourly with our Acronis Cloud Backups, available for you to access and restore at any time for free with a few simple clicks!'
            },
            {
                icon: 'icon-sw-enterprise-hardware',
                text: 'Enterprise-Grade Hardware',
                tooltip:
                    'Powered by Dell & HPE, Enterprise-Grade Intel Xeon Processors and Samsung SAS SSDs for the fastest page load speeds and ultimate reliability.'
            },
            {
                icon: 'icon-sw-whmcs-api',
                text: 'API/WHMCS Intergration',
                tooltip:
                    'You can save time with billing your customers!  We offer a fully-supported white label web hosting module for partners who use WHMCS. With our integration, you can also provide your clients with an automated store-front and client area.'
            },
            {
                icon: 'icon-sw-load-balancing',
                text: 'Load balanced provisioning',
                tooltip:
                    "We distribute our hosting services across our fleet of servers so all of your customers receive peak performance and aren't affected by maintenance and unexpected events all at once."
            },
            {
                icon: 'icon-sw-low-contention',
                text: 'Lower contention ratios',
                tooltip:
                    'Designed as a future proof solution, we ensure our servers have low contention ratios to cater for your clients as they grow and require more resources.'
            }
        ];

        const displayedQuotes = quotes.slice(0, numOfQuotes);

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="vsMarketing__sidebar">
                <div className="vsMarketing__sidebarContent">
                    <div className="vsMarketing__sidebarTop">
                        <p className="vsMarketing__subHeading">Some of the many benefits of becoming a Synergy Wholesale partner are</p>
                        <div className="vsMarketing__benefits">
                            {benefits.map((benefit, index) => (
                                <HoverTooltip key={`${benefit.text}-${index}`} color="black" content={benefit.tooltip}>
                                    <div key={index} className="vsMarketing__benefit">
                                        <i className={`icon ${benefit.icon}`} />
                                        <p className="vsMarketing__benefitText">{benefit.text}</p>
                                    </div>
                                </HoverTooltip>
                            ))}
                        </div>
                    </div>
                    <div className="vsMarketing__sidebarBottom">
                        <p className="vsMarketing__subHeading">See what some other VentraIP resellers are saying about Synergy Wholesale</p>
                        <div className="vsMarketing__quotes">
                            {displayedQuotes.map((quote, index) => (
                                <div key={index} className="vsMarketing__quote">
                                    <div className="vsMarketing__stars">
                                        <img src={stars} alt="Stars" />
                                    </div>
                                    <p className="vsMarketing__quoteText">&quot;{quote.body}&quot;</p>
                                    <p className="vsMarketing__quoteAuthor">{quote.customer}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default MarketingSidebar;
