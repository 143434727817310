/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides a simple abstraction on useState for managing the state of a lightbox. This includes
 * the state ({ show, id }) and a function to set the id and show/hide the lightbox.
 *
 * Providing id as null will set the show property to false, effectively hiding the lightbox while
 * setting id to a number set the show value to true and update the ID.
 */
export const useServiceLightboxState = () => {
    /***** STATE *****/
    /**
     * @typedef {{ show: boolean, id: null | number }} TServiceLightbox
     */
    const [serviceLightbox, _setServiceLightbox] = useState(
        /** @type {TServiceLightbox} */ ({
            show: false,
            id: null
        })
    );

    /***** FUNCTIONS *****/
    /**
     * @param {number | null} [id] - invoice ID to open lightbox or null to close
     */
    const setServiceLightbox = (id = null) => {
        _setServiceLightbox({
            show: !!id,
            id: id
        });
    };

    /***** RENDER *****/
    return /** @type {const} */ ([serviceLightbox, setServiceLightbox]);
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
