/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import axios from 'axios';
import { nxAxios } from 'store/nxAxios';
const CancelToken = axios.CancelToken;

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('containers/domain/forms/presets/types').TPresetName} TPresetName
 */

export let cancelBulkInfoRequest;
export let cancelCalculateHostingCost;
export let cancelGetDomainRenew;
export let cancelPreviewPresetChanges;

export const API = {
    /**********************************************************************************************************
     *   ACCOUNT API
     **********************************************************************************************************/

    account: {
        create: (id, attributes) => {
            return axios.post(`/api/user/`, {
                type: 'user',
                attributes: {
                    domain_id: id,
                    email: attributes.email,
                    firstname: attributes.firstname,
                    lastname: attributes.lastname,
                    phone: attributes.phone
                }
            });
        }
    },

    /**********************************************************************************************************
     *   DOMAIN API
     **********************************************************************************************************/

    domain: {
        GET: {
            /**
             * @returns {Artah.Domain.ID.GET.All.Promise}
             */
            information: (id) => {
                return axios.get(`/api/domain/${id}`);
            },

            active: () => {
                return axios.get(`/api/domain/active`);
            },

            contact: (id) => {
                return axios.get(`/api/domain/${id}/contact`);
            },

            eligibility: (id) => {
                return axios.get(`/api/domain/${id}/eligibility`);
            },

            suggestion: (id) => {
                return axios.get(`/api/domain/${id}/suggestion`);
            },

            whois: (id) => {
                return axios.get(`/api/domain/${id}/contact/whois`);
            },

            transfer: (id) => {
                return axios.get(`/api/domain/${id}/transfer/details`);
            },

            lock: (id) => {
                return axios.get(`/api/domain/${id}/transfer-lock`);
            },

            protection: (id) => {
                return axios.get(`/api/domain/${id}/id-protection`);
            },

            epp: (id) => {
                return axios.get(`/api/domain/${id}/epp`);
            },

            renew: (id) => {
                return axios.get(`/api/domain/${id}/renew`, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetDomainRenew = c;
                    })
                });
            },

            dns: (id) => {
                return axios.get(`/api/domain/${id}/dns`);
            },

            records: (id) => {
                return axios.get(`/api/domain/${id}/dns/record`);
            },

            default: (id) => {
                return axios.get(`/api/domain/${id}/dns/record/default`);
            },

            child: (id) => {
                return axios.get(`/api/domain/${id}/dns/child-ns`);
            },

            webForwarder: (id) => {
                return axios.get(`/api/domain/${id}/dns/record/web-forwarder`);
            },

            emailForwarder: (id) => {
                return axios.get(`/api/domain/${id}/dns/record/email-forwarder`);
            },

            /**
             * Gets the current price for cor
             * @param {service_id} id - domain service id
             */
            cor: (id) => {
                return axios.get(`/api/domain/${id}/cor`);
            },

            cor_pending: (id) => {
                return axios.get(`/api/domain/${id}/cor-pending`);
            },

            /**
             * @param {number} id - obfuscated domain ID
             */
            dnssec: (id) => {
                return axios.get(`/api/domain/${id}/dns-sec`);
            },

            dnssecOptions: () => {
                return axios.get(`/api/domain/dns-sec/get-options`);
            },

            /**
             * @param {number} id - obfuscated domain ID
             * @returns {Promise<import('axios').AxiosResponse<{
             *     data: {
             *         dnssec_status: enabled | disabled
             *     }
             * }>>}
             */
            dnssecEnabledStatus: (id) => {
                return axios.get(`/api/domain/${id}/dns-sec/status`);
            },

            /**
             * Endpoint specifically for DNSSEC information when the domain is using FreeDNS
             * @param {number} id - obfuscated domain ID
             * @returns {Promise<import('axios').AxiosResponse<any, any>>}
             */
            dnssecRecords: (id) => {
                return axios.get(`/api/domain/${id}/dns-sec/records`);
            },

            bulk: {
                list: () => {
                    return axios.get(`/api/domain?record_per_page=999&sort_by=expirydate&sort=asc`);
                }
            },

            subaccountSearch: (id) => {
                return axios.get(`/api/domain/dns/search-subaccount/${id}`);
            }
        },

        POST: {
            availability: (body) => {
                return axios.post(`/api/domain/availability`, body);
            },

            renew: (id, attributes) => {
                return axios.post(`/api/domain/${id}/renew`, {
                    type: 'domain',
                    id: id,
                    attributes
                });
            },

            promo: (id, attributes) => {
                return axios.post(`/api/domain/${id}/renew/preview`, {
                    type: 'domain',
                    id: id,
                    attributes
                });
            },

            enableRenew: (id) => {
                return axios.post(`/api/domain/${id}/auto-renew/enable`);
            },

            disableRenew: (id) => {
                return axios.post(`/api/domain/${id}/auto-renew/disable`);
            },

            move: (id, attributes) => {
                return axios.post(`/api/domain/${id}/move`, {
                    type: 'domain-move',
                    id: id,
                    attributes
                });
            },

            approve: (id, attributes) => {
                return axios.post(`/api/domain/${id}/transfer/approve`, {
                    attributes
                });
            },

            info: (id, attributes) => {
                return axios.post(`/api/domain/${id}/registration-details/update`, {
                    attributes
                });
            },

            epp: (id, attributes) => {
                return axios.post(`/api/domain/${id}/epp/update`, {
                    attributes
                });
            },

            cor: (id, attributes) => {
                return axios.post(`/api/domain/${id}/cor`, {
                    type: 'domain-cor',
                    id: id,
                    attributes
                });
            },

            manualCor: (id, attributes) => {
                return axios.post(`/api/domain/${id}/manual-cor`, {
                    type: 'ticket',
                    attributes
                });
            },

            record: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/record`, {
                    type: 'dns-record',
                    attributes
                });
            },

            child: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/child-ns`, {
                    type: 'child-host',
                    id: id,
                    attributes
                });
            },

            hostip: (id, hostname, attributes) => {
                return axios.post(`/api/domain/${id}/dns/child-ns/${hostname}/host-ip`, {
                    type: 'child-host',
                    id: id,
                    attributes
                });
            },

            reset: (id) => {
                return axios.post(`/api/domain/${id}/dns/reset`);
            },

            /**
             * @param {{
             *      id: string;
             *      preset: 'katana';
             *      attributes: {
             *          replacements: {
             *              hostname:string;
             *              server_hostname: string;
             *          };
             *      };
             * } | {
             *      id: number;
             *      preset: import('containers/domain/forms/presets/types').TApiPresetName;
             *      attributes: {
             *          replacements: {} | {
             *              verification_code: number
             *          }
             *      };
             * }} params
             */
            presetPreview: ({ id, preset, attributes }) => {
                return nxAxios.post(`/api/domain/${id}/dns/preset/${preset}/preview-changes`, {
                    type: 'dns-presets',
                    attributes
                });
            },

            /**
             * @param {{
             *      id: number;
             *      preset: 'katana';
             *      attributes: {
             *          remove_conflicting: boolean;
             *          replacements: {
             *              hostname:string;
             *              server_hostname: string;
             *          };
             *      };
             * }} params
             */
            presetApply: ({ id, preset, attributes }) => {
                return nxAxios.post(`/api/domain/${id}/dns/preset/${preset}`, {
                    type: 'dns-presets',
                    attributes
                });
            },
            ventraMX: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/preset/ventraip-mx`, {
                    type: 'dns-presets',
                    attributes
                });
            },

            googleDNS: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/preset/google-mx`, {
                    type: 'dns-presets',
                    attributes
                });
            },

            officeMX: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/preset/office-mx`, {
                    type: 'dns-presets',
                    attributes
                });
            },

            shopifyDNS: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/preset/shopify`, {
                    type: 'dns-presets',
                    attributes
                });
            },

            squarespaceDNS: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/preset/squarespace`, {
                    type: 'dns-presets',
                    attributes
                });
            },

            export: (id) => {
                return axios.post(`/api/domain/${id}/dns/record/export`);
            },

            webForwarder: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/record/web-forwarder`, {
                    type: 'dns-record',
                    id: id,
                    attributes
                });
            },

            emailForwarder: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns/record/email-forwarder`, {
                    type: 'dns-record',
                    id: id,
                    attributes
                });
            },

            dnssec: (id, attributes) => {
                return axios.post(`/api/domain/${id}/dns-sec/add`, {
                    type: 'dnssec',
                    id: id,
                    attributes
                });
            },

            dnssecEnable: (id) => {
                return axios.post(`/api/domain/${id}/dns-sec/enable`);
            },

            dnssecDisable: (id) => {
                return axios.post(`/api/domain/${id}/dns-sec/disable`);
            },

            bulk: {
                info: (data, promoCode) => {
                    const payload = { data };
                    if (promoCode) payload.promo_code = promoCode;

                    return axios({
                        method: 'post',
                        url: `/api/domain/bulk/renew/get-info`,
                        data: payload,
                        cancelToken: new CancelToken(function executor(c) {
                            cancelBulkInfoRequest = c;
                        })
                    });
                },

                renew: (data, promoCode) => {
                    const payload = { data };
                    if (promoCode) payload.promo_code = promoCode;

                    return axios.post(`/api/domain/bulk/renew/`, payload);
                },

                enableAuto: (data) => {
                    return axios.post(`/api/domain/bulk/auto-renew/enable`, {
                        data
                    });
                },

                disableAuto: (data) => {
                    return axios.post(`/api/domain/bulk/auto-renew/disable`, {
                        data
                    });
                }
            },

            calculateHostingCost: (productId, attributes) => {
                return axios({
                    method: 'post',
                    url: `/api/domain/purchase-hosting/calculate-cost/${productId}`,
                    data: {
                        attributes
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        cancelCalculateHostingCost = c;
                    })
                });
            }
        },

        PUT: {
            contact: (id, attributes) => {
                return axios.put(`/api/domain/${id}/contact`, {
                    type: 'domain-contact',
                    id: id,
                    attributes
                });
            },

            unlock: (id) => {
                return axios.put(`/api/domain/${id}/transfer-lock/unlock`, {
                    type: 'transfer-lock',
                    id: id
                });
            },

            lock: (id) => {
                return axios.put(`/api/domain/${id}/transfer-lock/lock`, {
                    type: 'transfer-lock',
                    id: id
                });
            },

            enableProtection: (id) => {
                return axios.put(`/api/domain/${id}/id-protection/enable`, {
                    type: 'id-protection',
                    id: id
                });
            },

            disableProtection: (id) => {
                return axios.put(`/api/domain/${id}/id-protection/disable`, {
                    type: 'id-protection',
                    id: id
                });
            },

            configuration: (id, attributes) => {
                return nxAxios.put(`/api/domain/${id}/dns`, {
                    type: 'domain-dns',
                    id: id,
                    attributes
                });
            },

            record: (id, record, attributes) => {
                return axios.put(`/api/domain/${id}/dns/record/${record}`, {
                    type: 'dns-record',
                    id: record,
                    attributes
                });
            },

            webForwarder: (id, record, attributes) => {
                return axios.put(`/api/domain/${id}/dns/record/web-forwarder/${record}`, {
                    type: 'dns-record',
                    id: record,
                    ...attributes
                });
            },

            emailForwarder: (id, record, attributes) => {
                return axios.put(`/api/domain/${id}/dns/record/email-forwarder/${record}`, {
                    type: 'dns-record',
                    id: record,
                    ...attributes
                });
            },

            bulkNameservers: (id, attributes) => {
                return axios.put(`/api/domain/bulk/dns/`, {
                    data: [
                        {
                            type: 'dns',
                            id: id,
                            attributes
                        }
                    ]
                });
            },

            bulk: {
                dns: (data) => {
                    return axios.put(`api/domain/bulk/dns/`, {
                        data
                    });
                },

                contact: (data) => {
                    return axios.put(`api/domain/bulk/contact/`, {
                        ...data
                    });
                }
            }
        },

        DELETE: {
            domain: (id) => {
                return nxAxios.delete(`/api/domain/${id}`);
            },

            record: (id, record) => {
                return axios.delete(`/api/domain/${id}/dns/record/${record}`);
            },

            child: (id, record) => {
                return axios.delete(`/api/domain/${id}/dns/child-ns/${record}`);
            },

            hostip: (id, hostname, hostip) => {
                return axios.delete(`/api/domain/${id}/dns/child-ns/${hostname}/host-ip/${hostip}`);
            },

            webForwarder: (id, record) => {
                return axios.delete(`/api/domain/${id}/dns/record/web-forwarder/${record}`);
            },

            emailForwarder: (id, record) => {
                return axios.delete(`/api/domain/${id}/dns/record/email-forwarder/${record}`);
            },

            dnssec: (id, uuid) => {
                return axios.delete(`/api/domain/${id}/dns-sec/remove/${uuid}`);
            }
        }
    },

    tppDomains: {
        GET: {
            list: () => {
                return nxAxios.get('/api/legacy-domain?record_per_page=999');
            },
            details: (domainId) => {
                return nxAxios.get(`/api/legacy-domain/${domainId}/details`);
            },
            epp: (domainId) => {
                return nxAxios.get(`/api/legacy-domain/${domainId}/epp`);
            },
            transferLock: (domainId) => {
                return nxAxios.get(`/api/legacy-domain/${domainId}/transfer-lock`);
            },
            contacts: (domainId) => {
                return nxAxios.get(`/api/legacy-domain/${domainId}/contact`);
            },
            renewCheck: (domainId) => {
                return nxAxios.get(`/api/legacy-domain/${domainId}/renew`);
            },
            dns: (domainId) => {
                return nxAxios.get(`/api/legacy-domain/${domainId}/dns`);
            }
        },
        POST: {
            renew: (domainId) => {
                return nxAxios.post(`/api/legacy-domain/${domainId}/renew`, {
                    type: 'domain',
                    id: domainId,
                    attributes: {
                        years: 1
                    }
                });
            },
            disableTransferLock: (domainId) => {
                return nxAxios.post(`/api/legacy-domain/${domainId}/transfer-lock/disable`);
            }
        },
        PUT: {
            dns: ({ domainId, newDns }) => {
                return nxAxios.put(`/api/legacy-domain/${domainId}/dns`, newDns);
            },
            contacts: ({ domainId, newContacts }) => {
                return nxAxios.put(`/api/legacy-domain/${domainId}/contact`, {
                    'domain-contact': newContacts
                });
            }
        }
    }
};
