/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';

export const SPLIT_INVOICE_PROP_TYPES = {
    /**
     * The Data the split preview endpoint needs
     */
    splitData: PropTypes.shape({
        /**
         * The invoice id
         */
        invoiceID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        /**
         * The itemId's to split off
         */
        itemIds: PropTypes.arrayOf(PropTypes.number)
    }),
    /**
     * Invoice ID
     */
    invoiceID: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export const SPLIT_INVOICE_WHICH = {
    INVOICE_A: 'invoice_a',
    INVOICE_B: 'invoice_b'
};
export const SPLIT_INVOICE_WHICH_VALUES = Object.values(SPLIT_INVOICE_WHICH);

const defaultSplitValues = { total: '0.00', subtotal: '0.00' };

export const defaultSplitPreviewData = {
    [SPLIT_INVOICE_WHICH.INVOICE_A]: defaultSplitValues,
    [SPLIT_INVOICE_WHICH.INVOICE_B]: defaultSplitValues
};
