/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Transition.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Transition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateChildren: {
                new: false,
                current: false,
                prev: false
            },
            transition: {
                start: false,
                end: false
            }
        };
        this.renderChildren = this.renderChildren.bind(this);
        this.transitionBegin = this.transitionBegin.bind(this);
        this.transitionEnd = this.transitionEnd.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
    }

    transitionBegin() {
        const { transitionEnd } = this;
        const { stateChildren } = this.state;

        this.setState({
            transition: {
                start: true,
                end: false
            },
            stateChildren: {
                new: false,
                current: false,
                prev: stateChildren.current ? stateChildren.current : false
            }
        });

        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            transitionEnd();
        }, 300);
    }

    transitionEnd() {
        const { onTransitionEnd } = this;
        const { children } = this.props;

        this.setState({
            transition: {
                start: false,
                end: true
            },
            stateChildren: {
                new: children,
                current: false,
                prev: false
            }
        });

        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            onTransitionEnd();
        }, 300);
    }

    onTransitionEnd() {
        const { children } = this.props;

        this.setState({
            transition: {
                start: false,
                end: false
            },
            stateChildren: {
                new: false,
                current: children,
                prev: false
            }
        });
    }

    renderChildren() {
        const { children } = this.props;
        this.setState({
            stateChildren: {
                new: false,
                current: children,
                prev: false
            }
        });
    }

    componentDidMount() {
        const { stateChildren } = this.state;
        const { transitionBegin } = this;

        if (!stateChildren.current) {
            transitionBegin();
        }
    }

    componentDidUpdate(prevProps) {
        const { transitionBegin, renderChildren } = this;
        const { when, children } = this.props;
        const { transition, stateChildren } = this.state;

        if (when === prevProps.when && children !== stateChildren.current && !transition.start && !transition.end) {
            renderChildren();
        }

        if (when !== prevProps.when && !transition.start && !transition.end && children !== stateChildren.current) {
            transitionBegin();
        }
    }

    render() {
        const { className, type } = this.props;
        const { stateChildren, transition } = this.state;

        const handleTransitionRender = () => {
            switch (type) {
                case 'switch':
                case 'footer':
                    return stateChildren.prev || stateChildren.new || stateChildren.current || '';

                default:
                    return (
                        <div
                            className={`${type === `table` ? `sharedTable__body ` : ``}transitionFrame${className ? ` ${className}` : ``}${
                                transition.start ? ' ccp-fadeout' : ''
                            }${transition.end ? ' ccp-fadein' : ''}`}
                        >
                            {stateChildren.prev || stateChildren.new || stateChildren.current || ''}
                        </div>
                    );
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return handleTransitionRender();
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Transition.propTypes = {
    /**
     * The content to be rendered in the transition component.
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),

    /**
     * The class name to be passed to the transition component.
     */
    className: PropTypes.string,

    /**
     * The type of transition to be rendered.
     */
    type: PropTypes.oneOf(['switch', 'footer', 'table']),

    /**
     * The boolean value to determine if the transition should be rendered.
     */
    when: PropTypes.bool
};

export default Transition;
