/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXTableAccordion.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Standard flex wrapper and minor styling for a Child of the NXTableAccordion
 * 
 * @param {{ children: React.ReactNode }} props
 */
function Child({ children }) {
    /***** RENDER *****/
    return <div className="NXTableAccordion__body--child">{children}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Child.propTypes = {
    /**
     * Child Components
     */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Child;
