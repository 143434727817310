/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/*   ACTIONS
 *****************************************************/
import { resetScrollEvents } from 'utilities/methods/commonActions';
import { renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Emails extends Component {
    /************** LIFECYCLE METHODS **************/
    componentWillUnmount() {
        const { resetScrollEvents } = this.props;

        resetScrollEvents();
    }

    render() {
        const { modules } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return <div className="accountEmails section">{renderSectionModules(modules)}</div>;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(null, {
        resetScrollEvents
    })(Emails)
);
