/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import { NXIframe } from 'components/NXIframe';

/*   ACTIONS
 *****************************************************/
import { getIndividualTicket, updateTicketFeedbackStatus } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TicketFeedbackSurvey extends Component {
    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const {
            toggleLightboxTemplate,
            getIndividualTicket,
            updateTicketFeedbackStatus,
            lightboxMeta: { id }
        } = this.props;

        getIndividualTicket(id);

        window.onmessage = (e) => {
            if (e.data === 'TicketID ' + id) {
                updateTicketFeedbackStatus(id, 'completed');
                toggleLightboxTemplate();
            }
        };
    }

    render() {
        const { support_individual_ticket_status, support_individual_ticket_meta } = this.props;

        const renderIframe = () => {
            const { mask, department, subject } = support_individual_ticket_meta;

            const iframeUrl =
                department === 'Accounts and Billing'
                    ? 'https://www.surveymonkey.com/r/JZ5L85H?TicketID='
                    : 'https://www.surveymonkey.com/r/8TXXYBD?TicketID=';

            return (
                <div className="ticketSurvey__wrapper">
                    <div className="ticketSurvey__title">
                        {subject} - {mask}
                    </div>
                    <NXIframe className="ticketSurvey__iframe" src={`${iframeUrl}${mask}`} />
                </div>
            );
        };

        const renderLightboxContents = () => {
            switch (support_individual_ticket_status) {
                case 'success':
                    return renderIframe();
                case 'error':
                    return <FetchComponentError />;
                case 'loading':
                default:
                    return <RequestLoader />;
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return renderLightboxContents();
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            support_individual_ticket_status: state.support.support_individual_ticket_status,
            support_individual_ticket_meta: state.support.support_individual_ticket_meta
        }),
        {
            getIndividualTicket,
            updateTicketFeedbackStatus
        }
    )(TicketFeedbackSurvey)
);
