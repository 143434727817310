/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';

/*   ACTIONS
 *****************************************************/
import { resetScrollEvents } from 'utilities/methods/commonActions';
import { renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import '../styles/_configStyles.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Configuration extends Component {
    /************** LIFECYCLE METHODS **************/
    componentWillUnmount() {
        const { resetScrollEvents } = this.props;

        resetScrollEvents();
    }

    render() {
        const { modules } = this.props;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return <div className="hosting__configuration">{renderSectionModules(modules)}</div>;
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapDispatchToProps = {
    resetScrollEvents
};

export default connect(null, mapDispatchToProps)(Configuration);
