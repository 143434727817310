/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import QueueJumpFormLabel from './queueJumpFormLabel';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const checkboxField = getFieldComponentAndClassName(FieldTypesEnum.CHECKBOX);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let QueueJumpForm = (props) => {
    const { support_migration_migration_dates_data } = props;

    const canQueueJump = support_migration_migration_dates_data?.attributes
        ? !support_migration_migration_dates_data?.attributes?.able_to_submit_urgent?.status_overdue &&
          !support_migration_migration_dates_data?.attributes?.able_to_submit_urgent?.status_unpaid
        : false;

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    if (!canQueueJump) return '';

    return (
        <div className="migrationRequestForm__stepWrapper">
            <Field
                component={checkboxField.component}
                label={<QueueJumpFormLabel />}
                name="is_urgent"
                type={FieldTypesEnum.CHECKBOX}
                className={classNames(checkboxField.className, 'checkbox__queueJump')}
            />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    support_migration_migration_dates_data: state.support.support_migration_migration_dates_data
});

QueueJumpForm = connect(mapStateToProps, null)(QueueJumpForm);

export default QueueJumpForm;
