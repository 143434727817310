/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ContentEditorModulesForm } from './contentEditorModulesForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_contentEditorRenderModules.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
type ContentEditorRenderModulesProps = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
    subpage: string;
    onSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'];
    initialValues: Object;
};
const ContentEditorRenderModules: React.FC<ContentEditorRenderModulesProps> = ({ subpageRouteData, subpage, onSubmit, initialValues }) => {
    /***** HOOKS *****/
    const { modules } = subpageRouteData;
    /***** RENDER *****/
    return (
        <ContentEditorModulesForm onSubmit={onSubmit} initialValues={initialValues} subpage={subpage}>
            {modules}
        </ContentEditorModulesForm>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ContentEditorRenderModules;
