/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { REDUX_FORM_BUTTON_STATE, ReduxFormButton } from 'components/Form/Button/reduxForm';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, emailFieldValidation, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'registrantEmailForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let RegistrantForm = ({ domain_protection_check_data, handleSubmit, domain_email_update_status }) => {
    const isIdProtectionEnabled = domain_protection_check_data?.attributes.id_protection === 'Enabled';

    /**
     * Provides manual overrides for the button state.
     */
    const handleFormButtonState = () => {
        if (domain_email_update_status === 'loading') {
            return REDUX_FORM_BUTTON_STATE.LOADING;
        }

        if (isIdProtectionEnabled) {
            return REDUX_FORM_BUTTON_STATE.INACTIVE;
        }

        return undefined;
    };

    /**
     * handleSubmit wrapper to handle extra validation not provided by fields.
     */
    const _handleSubmit = (e) => {
        e.preventDefault();

        if (handleFormButtonState()) {
            return;
        }

        handleSubmit(e);
    };

    return (
        <form className="registrantEmail__form" onSubmit={_handleSubmit}>
            <div className="registrantEmail__wrapper">
                <div className="registrantEmail__field">
                    <Field
                        name="email"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation, emailFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="registrantEmail__button">
                    <ReduxFormButton form={formName} force={handleFormButtonState()}>
                        Save Changes
                    </ReduxFormButton>
                </div>
            </div>
            {isIdProtectionEnabled ? (
                <DialogNotification className="registrantEmail__dialog" type="warning">
                    Please note that to change your registrant email address, you will first be required to turn off ID protection. Your registrant
                    email address is sent expiry notices and correspondence regarding this domain name.
                </DialogNotification>
            ) : (
                <DialogNotification className="registrantEmail__dialog" type="warning outline">
                    Please note the email address listed above is sent expiry notices and correspondence regarding this domain name. Please ensure
                    that it&apos;s always up to date.
                </DialogNotification>
            )}
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

RegistrantForm = reduxForm({
    form: formName
})(RegistrantForm);

const mapStateToProps = (state) => {
    return {
        domain_protection_check_data: state.domain.domain_protection_check_data,
        domain_email_update_status: state.domain.domain_email_update_status,
        domain_email_update_data: state.domain.domain_email_update_data,
        domain_email_update_error: state.domain.domain_email_update_error
    };
};

export default connect(mapStateToProps, null)(RegistrantForm);
