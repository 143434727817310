/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DeleteDomainLightbox } from 'containers/domain/forms/delete';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DeleteDomain: React.FC = () => {
    /***** HOOKS *****/
    const { id: domainId } = useParams<{ id: string }>();

    /***** RENDER *****/
    return (
        <>
            <ScrollableComponent>
                <NXBox warn>
                    <NXBox.Top
                        title="Delete Domain Name"
                        description="If you no longer require this domain name, you can delete it from your account."
                    >
                        <OutlineButton color="warn" to={`/my-services/domains/admin/delete/${domainId}?open=true`}>
                            Delete Domain
                        </OutlineButton>
                    </NXBox.Top>
                </NXBox>
            </ScrollableComponent>
            <DeleteDomainLightbox />
        </>
    );
};
