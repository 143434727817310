/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DotOnlineRewardCard } from './cards/dotonline';
import { DotStoreReferralCard } from './cards/dotstore';
import { DotXyzReferralCard } from './cards/dotxyz';
import { ReferralRewardCard } from './cards/referral';
import { NrlGiveawayCard } from './cards/nrlGiveaway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { DotSiteRewardCard } from 'containers/vipRewards/modules/rewardCard/cards/dotsite';
import './_rewardCard.scss';

const RewardCard = {
    Referral: ReferralRewardCard,
    DotStore: DotStoreReferralCard,
    DotOnline: DotOnlineRewardCard,
    DotXyz: DotXyzReferralCard,
    DotSite: DotSiteRewardCard,
    NrlGiveaway: NrlGiveawayCard
};

export default RewardCard;
