/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { filterOptions } from 'containers/billing/modules/invoices';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { validate } from './methods';

/*   ACTIONS
 *****************************************************/
import ClearFilterButton from 'components/Buttons/ClearFilterButton';
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import { RenderSelectField, dateValidation, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const filterInvoiceFormName = 'FilterInvoicesForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class InvoiceFilterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDateValidation: [requiredFieldValidation, dateValidation],
            endDateValidation: [requiredFieldValidation, dateValidation]
        };

        this.handleClearForm = this.handleClearForm.bind(this);
    }

    handleClearForm() {
        const { onClear, dispatch } = this.props;

        dispatch(reset(filterInvoiceFormName));

        onClear();
    }

    componentDidUpdate(prevProps) {
        const { start_date, end_date } = this.props;

        if (start_date !== prevProps.start_date || end_date !== prevProps.end_date) {
            //regenerate validation
            const startDateRequired = start_date || (!start_date && !end_date);
            const endDateRequired = end_date || (!start_date && !end_date);

            this.setState({
                startDateValidation: [dateValidation].concat(startDateRequired ? [requiredFieldValidation] : []),
                endDateValidation: [dateValidation].concat(endDateRequired ? [requiredFieldValidation] : [])
            });
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { pristine, submitting, valid, handleSubmit, closeDropdown, start_date, end_date, showDropdown } = this.props;
        const { handleClearForm, invoiceFilterFormFilterByRef } = this;

        if (!showDropdown) return '';

        const onFormSubmit = (values) => {
            handleSubmit(values);
            closeDropdown();
        };

        return (
            <form className="invoiceFilterForm" onSubmit={onFormSubmit}>
                <div className="form__row" ref={invoiceFilterFormFilterByRef}>
                    <div className="form__column full">
                        <Field
                            label="Filter by"
                            name="filter_by"
                            component={RenderSelectField}
                            type="select"
                            validate={[requiredFieldValidation]}
                            options={[
                                {
                                    label: 'Due Date',
                                    value: filterOptions.filter_by.DUE
                                },
                                {
                                    label: 'Date Paid',
                                    value: filterOptions.filter_by.PAID
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field label="From" name="start_date" component={RenderDateTimePicker} dateFormat="d/M/yyyy" maxDate={end_date} />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field label="To" name="end_date" component={RenderDateTimePicker} dateFormat="d/M/yyyy" minDate={start_date} />
                    </div>
                </div>
                {renderButton(pristine, submitting, valid, 'Apply', 'primary')}
                <ClearFilterButton onClick={handleClearForm} className="invoiceFilterForm__clearFilter" />
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
InvoiceFilterForm = reduxForm({
    form: filterInvoiceFormName,
    enableReinitialize: true,
    validate
})(InvoiceFilterForm);

const mapStateToProps = (state) => {
    // Map start and end date to the component rather than keeping local state
    const selector = formValueSelector(filterInvoiceFormName);
    return {
        start_date: selector(state, 'start_date'),
        end_date: selector(state, 'end_date')
    };
};

InvoiceFilterForm = withRouter(connect(mapStateToProps, null)(InvoiceFilterForm));

export default InvoiceFilterForm;
