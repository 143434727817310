/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TPPDomainOverviewExpiry from './expiry';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainQuery } from '../../queries/useTppDomainQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { capitalize } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain overview module
 */
function TPPDomainOverview({ path }) {
    /***** HOOKS *****/
    const params = useParams();
    const location = useLocation();
    const scrollRef = useRef(null);

    /***** QUERIES *****/
    const { data: tpp_domain_data, status: tpp_domain_status } = useTppDomainQuery(params.id);

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props: { path, location }, scrollRef: scrollRef.current }, tpp_domain_status === 'success');
    }, [tpp_domain_status]);

    /***** RENDER *****/
    return (
        <section ref={scrollRef} className="tppDomainOverview">
            <NXBox initialStatus={tpp_domain_status === 'pending' ? 'loading' : tpp_domain_status} isLoading={false}>
                {(() => {
                    if (!tpp_domain_data) return '';

                    const { status = '', domain = '', expiry = '', contact } = tpp_domain_data.attributes || {};

                    const registrantEmail = contact?.registrant?.email || '';

                    return (
                        <NXBox.DefaultPadding>
                            <NXBox.Title title="Domain Overview" />
                            <NXBox.OverviewRow>
                                <NXBox.InfoPair title="Domain Name" description={domain} />
                            </NXBox.OverviewRow>
                            <NXBox.OverviewRow>
                                <NXBox.InfoPair title="Status" description={capitalize(status.toLowerCase())} />
                            </NXBox.OverviewRow>
                            <NXBox.OverviewRow>
                                <NXBox.InfoPair title="Expires" description={<TPPDomainOverviewExpiry expiryDate={expiry} />} />
                            </NXBox.OverviewRow>
                            <NXBox.OverviewRow>
                                <NXBox.InfoPair title="Registrant Email Address" description={registrantEmail} />
                            </NXBox.OverviewRow>
                        </NXBox.DefaultPadding>
                    );
                })()}
            </NXBox>
        </section>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainOverview;
