/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Cancel from 'components/Cancel';

/*   ACTIONS
 *****************************************************/
import { deleteMs365Service } from 'components/Cancel/action';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Ms356Delete extends Component {
    constructor(props) {
        super(props);

        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
    }

    handleCancelSubmit(values) {
        const {
            deleteMs365Service,
            history,
            match: {
                params: { id }
            }
        } = this.props;

        deleteMs365Service(id, { ...values }, history);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        registerScrollEvents(this, true);
    }

    render() {
        const {
            ms365_delete_service_status,
            match: {
                params: { id }
            }
        } = this.props;
        const { handleCancelSubmit } = this;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="ms365DeleteMailbox"
            >
                <Cancel
                    title="Delete Subscription"
                    desc="If you no longer need your Microsoft 365 Subscription, you can delete it. Please note that this will remove all Microsoft 365 users currently using this subscription."
                    label="Cancel Service"
                    cancel={{
                        id,
                        service: 'ms365',
                        action: handleCancelSubmit,
                        loading: ms365_delete_service_status
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            ms365_delete_service_status: state.cancel.ms365_delete_service_status
        }),
        {
            deleteMs365Service
        }
    )(Ms356Delete)
);
