/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
// import { decodeBlurHash } from 'fast-blurhash';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import { NXSquare } from 'components/Utils/NXSquare';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RENDER_CARD_SELECT_OPTION_TYPES } from 'components/Form/CardSelectGrid/consts';
import './__SelectCardContentRenderer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { TCardSelectGrid } from 'components/Form/CardSelectGrid/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SelectCardContentRenderer: React.FC<{
    option: TCardSelectGrid.SelectCard.Option;
}> = ({ option }) => {
    /***** RENDER *****/
    switch (option.type) {
        case RENDER_CARD_SELECT_OPTION_TYPES.CUSTOM:
            return option.content;
        case RENDER_CARD_SELECT_OPTION_TYPES.IMAGE:
            return (
                <NXSquare ratio={0.6}>
                    <BackgroundImage imageURL={option.thumb || option.url} cover fill />
                    {option.extra_content}
                </NXSquare>
            );
        case RENDER_CARD_SELECT_OPTION_TYPES.FONT:
            return (
                <Padding xy={4} paddingOnly inject>
                    <div className="SelectCardContentRenderer SelectCardContentRenderer__optionType--font">
                        <Text size--xxxl align--center black break-word _style={{ fontFamily: option.fontFamily }}>
                            {option?.text}
                        </Text>
                        {option?.additionalContent}
                    </div>
                </Padding>
            );
        default:
            return null;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _SelectCardContentRenderer };
