/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import RequestLoader from 'components/Loaders/Request';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SetupComplete from '../forms/setupComplete';
import SetupOrganisation from '../forms/setupOrganisation';
import SetupVerify from '../forms/setupVerify';
import GSuiteUserDetailsForm from '../forms/userDetails';

/*   ACTIONS
*****************************************************/
import {
    createGSuiteAdminData,
    createGSuiteCustomerData,
    getGSuiteService,
    getGSuiteVerificationData,
    getGSuiteVerificationStatus,
    verifyDomainRecord
} from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteSetupModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            domain: undefined,
            step: {
                current: `organisation`,
                list: [
                    // Sidenav step data
                    { key: 'organisation', title: 'Organisation Details' },
                    { key: 'admin', title: 'Setup Admin Account' },
                    { key: 'verify', title: 'Verify Domain Name' },
                    { key: 'complete', title: 'Complete' }
                ],
                status: {
                    // Used to check step completion
                    organisation: false,
                    admin: false,
                    verify: false,
                    complete: false
                }
            },
            view: {
                // Handles the current view template strings
                title: 'Organisation Details',
                desc: 'Please confirm your account details.'
            },
            verification: {
                // Handles any verification data to prevent multiple verfication requests
                status: null,
                method: undefined,
                record: undefined
            },
            requiredTerms: false,
            is_domain_verified: false
        };
        this.handleSetupStep = this.handleSetupStep.bind(this);
        this.handleDetailsUpdate = this.handleDetailsUpdate.bind(this);
        this.handleAdminUpdate = this.handleAdminUpdate.bind(this);
        this.handleGSuiteService = this.handleGSuiteService.bind(this);
        this.handleRecordCheck = this.handleRecordCheck.bind(this);
    }

    handleSetupStep(nextStep) {
        const { gsuite_service_data } = this.props;
        const { step } = this.state;
        const { attributes } = gsuite_service_data;
        const { users, is_domain_verified } = attributes;
        let view = {
            title: '',
            desc: ''
        };
        let preStep = nextStep ? nextStep : undefined;

        if (!preStep) {
            Object.keys(step.status).forEach((i) => {
                if (!preStep && !step.status[i]) {
                    preStep = i;
                }
            });
        }

        switch (preStep) {
            case 'organisation':
                view = {
                    title: 'Organisation Details',
                    desc: 'Please confirm your account details.'
                };
                break;

            case 'admin':
                view = {
                    title: 'Setup Admin Account',
                    desc: htmr(
                        `Setup your administrator account for <strong>${attributes.domain}</strong>. You can change or add more administrators in the future.`
                    )
                };
                break;

            case 'verify':
                view = {
                    title: 'Domain Name Verification',
                    desc: htmr(
                        `Google needs to verify you as the owner of <strong>${attributes.domain}</strong>. To do so, you're required to add a TXT record to that domain name's DNS. Go to your Domain Names DNS management and add the TXT record below.`
                    )
                };
                break;

            case 'complete':
                view = {
                    title: 'Setup Complete',
                    desc: htmr(
                        `Setup of your Google Workspace service is now complete. Once your domain has been validated, an email will be sent to <strong>${attributes.alternate_email}</strong> and you will be directed to accept Google's Terms and Conditions. Once accepted, you can login and start using your service!`
                    )
                };
                break;

            default:
                break;
        }

        this.setState({
            loading: false,
            step: {
                ...step,
                current: preStep
            },
            view,
            requiredTerms: users && users.length > 0 && 'agreed_to_terms' in users[0] ? users[0].agreed_to_terms : false,
            is_domain_verified
        });
    }

    handleDetailsUpdate(values) {
        const { createGSuiteCustomerData, gsuiteid } = this.props;
        const attributes = {
            ...values
        };

        this.setState(
            {
                loading: true
            },
            () => {
                createGSuiteCustomerData(gsuiteid, attributes);
            }
        );
    }

    handleAdminUpdate(values) {
        const { createGSuiteAdminData, gsuiteid } = this.props;
        const { domain } = this.state;

        const attribute = {
            ...values,
            email_address: values.email_address + `@${domain}`,
            is_admin: true,
            require_change_password: values.require_change_password ? values.require_change_password : false
        };

        this.setState(
            {
                loading: true
            },
            () => {
                createGSuiteAdminData(gsuiteid, attribute);
            }
        );
    }

    handleRecordCheck() {
        const { verifyDomainRecord, gsuiteid } = this.props;

        this.setState(
            {
                loading: true
            },
            () => {
                verifyDomainRecord(gsuiteid);
            }
        );
    }

    handleGSuiteService() {
        const { gsuiteid, gsuite_service_data, getGSuiteVerificationStatus } = this.props;
        const { step } = this.state;
        const { handleSetupStep } = this;
        const { attributes } = gsuite_service_data;
        const { customer_id, users, domain, is_domain_verified } = attributes;

        this.setState(
            {
                domain: domain,
                step: {
                    ...step,
                    status: {
                        ...step.status,
                        organisation: customer_id ? true : false,
                        admin: users && users.length > 0 ? true : false,
                        verify: is_domain_verified
                    }
                }
            },
            () => {
                if (customer_id && users && users.length > 0 && !is_domain_verified) {
                    getGSuiteVerificationStatus(gsuiteid);
                    handleSetupStep('verify');
                } else {
                    handleSetupStep();
                }
            }
        );
    }

    componentDidMount() {
        const { gsuiteid, gsuite_service_data } = this.props;
        const { handleGSuiteService } = this;

        if (gsuite_service_data && gsuiteid === gsuite_service_data.id.toString()) {
            handleGSuiteService();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            gsuiteid,
            gsuite_service_data,
            gsuite_verification_status_status,
            gsuite_verification_status_data,
            gsuite_verification_status,
            gsuite_verification_data,
            gsuite_verify_record_status,
            gsuite_customer_data_status,
            gsuite_user_create_status,
            getGSuiteVerificationStatus,
            getGSuiteVerificationData
        } = this.props;
        const { step } = this.state;
        const { handleSetupStep } = this;

        if (gsuite_customer_data_status === 'success' && prevProps.gsuite_customer_data_status === 'loading') {
            getGSuiteService(gsuiteid);
        }

        if (gsuite_user_create_status === 'success' && prevProps.gsuite_user_create_status === 'loading') {
            this.setState(
                {
                    step: {
                        ...step,
                        status: {
                            ...step.status,
                            admin: true
                        }
                    }
                },
                () => {
                    handleSetupStep('verify');
                    getGSuiteVerificationStatus(gsuiteid);
                }
            );
        }

        if (gsuite_user_create_status === 'error' && prevProps.gsuite_user_create_status === 'loading') {
            this.setState({
                loading: false
            });
        }

        if (gsuite_verification_status_status === 'success' && prevProps.gsuite_verification_status_status === 'loading') {
            const { verified } = gsuite_verification_status_data;
            const { is_domain_verified } = gsuite_service_data.attributes;

            if (!verified && !is_domain_verified) {
                getGSuiteVerificationData(gsuiteid);
            }
        }

        // Check verification status & get record data
        if (gsuite_verification_status === 'success' && prevProps.gsuite_verification_status === 'loading') {
            const { attributes } = gsuite_verification_data;
            const { method, token } = attributes;

            this.setState(
                {
                    verification: {
                        status: null,
                        method: method,
                        record: token
                    }
                },
                () => {
                    if (step.current !== 'verify' && step.current !== 'complete') {
                        handleSetupStep('verify');
                    }
                }
            );
        }

        if (gsuite_verify_record_status === 'success' && prevProps.gsuite_verify_record_status === 'loading') {
            this.setState(
                {
                    step: {
                        ...step,
                        status: {
                            ...step.status,
                            verify: true
                        }
                    }
                },
                () => {
                    handleSetupStep('complete');
                }
            );
        }

        if (gsuite_verify_record_status === 'error' && prevProps.gsuite_verify_record_status === 'loading') {
            handleSetupStep('verify');
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { gsuiteid, history, gsuite_service_status, app_viewport } = this.props;
        const { domain, step, view, verification, loading } = this.state;
        const { handleDetailsUpdate, handleAdminUpdate, handleRecordCheck } = this;

        const handleCurrentView = () => {
            if (loading) {
                return <RequestLoader />;
            }

            switch (step.current) {
                case 'organisation':
                    return <SetupOrganisation onSubmit={handleDetailsUpdate} />;

                case 'admin':
                    return <GSuiteUserDetailsForm domain={domain} isAdminSetup={true} onSubmit={handleAdminUpdate} />;

                case 'verify':
                    return <SetupVerify verification={verification} domain={domain} onSubmit={handleRecordCheck} />;

                case 'complete':
                    return <SetupComplete gsuiteid={gsuiteid} />;

                default:
                    return <RequestLoader />;
            }
        };

        const handleMenuOptionRender = () => {
            return step.list.map((item) => {
                return (
                    <li
                        key={item.key}
                        className={`gsuiteSetup__list--option${step.status[item.key] ? ` completed` : ``}${
                            step.current === item.key ? ` selected` : ``
                        }`}
                    >
                        {item.title}
                        <i className="icon icon-check-square"></i>
                    </li>
                );
            });
        };

        const handleMenuRender = () => {
            return (
                <div className="gsuiteSetup__menu">
                    <div className="gsuiteSetup__menu--title">Setup Google Workspace</div>
                    <ul className="gsuiteSetup__list">{handleMenuOptionRender(app_viewport === 'sm' || app_viewport === 'xs' ? true : false)}</ul>
                </div>
            );
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {
            custom: {
                render: handleCurrentView(),
                pos: 'bottom'
            }
        };
        if (step.current === 'complete') {
            conditionalProps = {
                bottom: true,
                columns: [
                    {
                        render: (
                            <div className="sharedBox__infoColumn hasIcon">
                                <i className="infoColumn__icon icon icon-check confirm"></i>
                                <div className="infoColumn__wrapper">
                                    <div className="title">Setup Google Workspace Service</div>
                                    <div className="desc">Completed</div>
                                </div>
                            </div>
                        )
                    }
                ],
                action: {
                    label: 'Manage Service',
                    type: 'onClick',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        history.push(`/my-services/google/manage/overview/${gsuiteid}`);
                    }
                }
            };
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="gsuiteSetup__container"
            >
                {handleMenuRender()}
                <Transition when={step.current}>
                    <Fragment>
                        <Box
                            premounted={true}
                            className="gsuiteSetup__box"
                            title={view.title}
                            desc={view.desc}
                            status={gsuite_service_status}
                            {...conditionalProps}
                        />
                    </Fragment>
                </Transition>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_service_status: state.gsuite.gsuite_service_status,
        gsuite_service_data: state.gsuite.gsuite_service_data,
        gsuite_customer_data_status: state.gsuite.gsuite_customer_data_status,
        gsuite_verification_status: state.gsuite.gsuite_verification_status,
        gsuite_verification_data: state.gsuite.gsuite_verification_data,
        gsuite_verification_status_status: state.gsuite.gsuite_verification_status_status,
        gsuite_verification_status_data: state.gsuite.gsuite_verification_status_data,
        gsuite_user_create_status: state.gsuite.gsuite_user_create_status,
        gsuite_verify_record_status: state.gsuite.gsuite_verify_record_status,
        app_viewport: state.app.app_viewport
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGSuiteVerificationStatus,
            getGSuiteVerificationData,
            createGSuiteCustomerData,
            createGSuiteAdminData,
            verifyDomainRecord
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteSetupModule));
