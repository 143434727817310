/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useCreateKatanaSite } from 'containers/katana/queries/mutations';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function KatanaRegisterDomain() {
    /***** STATE *****/
    const [optimisticKatanaServiceID, setOptimisticKatanaServiceID] = useState(null);

    /***** HOOKS *****/
    const history = useHistory();

    const { domain_name } = useParams<{ domain_name: string }>();

    /***** QUERIES *****/
    const { getKatanaDestination } = useKatanaURL(optimisticKatanaServiceID);

    const { mutate: mutateCreateKatanaSite } = useCreateKatanaSite();

    /***** FUNCTIONS *****/
    function closeModal() {
        history.push('/my-services/vip-sites');
    }

    function claimKatanaPage() {
        mutateCreateKatanaSite(domain_name, {
            onSuccess: (data) => {
                if (data?.data?.site?.id) {
                    setOptimisticKatanaServiceID(data?.data?.site?.id);
                }
            }
        });
    }
    /***** EFFECTS *****/
    useEffect(() => {
        if (!domain_name) {
            return;
        }
        claimKatanaPage();
    }, [domain_name]);

    useEffect(() => {
        if (!optimisticKatanaServiceID) {
            return;
        }

        history.push(getKatanaDestination(katanaServicePages.SETUP.to));
    }, [optimisticKatanaServiceID]);

    return (
        <Modal ariaLabel="Domain Registration" isOpen onClose={closeModal}>
            <RequestLoader message="Forging VIPsite..." />
        </Modal>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaRegisterDomain };
