/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RadioButtonSet.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * RadioButtonSet shows a set of buttons that can be selected. Only one button can be selected at a time.
 */
class RadioButtonSet extends Component {
    render() {
        const { label, options, activeOption, setActiveOption, color, size } = this.props;

        const getColor = () => {
            switch (color) {
                case 'primary':
                case 'secondary':
                    return color;
                default:
                    return 'secondary';
            }
        };

        const getSize = () => {
            switch (size) {
                case 'small':
                case 'large':
                    return size;
                default:
                    return 'large';
            }
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className={`RadioButtonSet ${getColor()} ${getSize()}`}>
                {label ? <label>{label}</label> : ''}
                <div className="RadioButtonSet__container">
                    {options.map(({ value, label }, index) => (
                        <button
                            key={index}
                            type="button"
                            onClick={() => {
                                if (activeOption !== value) setActiveOption(value);
                            }}
                            className={`RadioButtonSet__button ${activeOption === value ? 'active' : ''}`}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default RadioButtonSet;

RadioButtonSet.propTypes = {
    /**
     * Label to go above the radio buttons
     */
    label: PropTypes.node,

    /**
     * List of buttons to include in the button switch
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            // Unique identifier for the option
            value: PropTypes.string,
            // Button text of the option
            label: PropTypes.string
        })
    ).isRequired,

    /**
     * "value" property of the active option
     */
    activeOption: PropTypes.string.isRequired,

    /**
     * function to change the active option
     */
    setActiveOption: PropTypes.func.isRequired,

    /**
     * Color of the button switch. Defaults to secondary if not passed
     */
    color: PropTypes.oneOf(['primary', 'secondary']),

    /**
     * Size of the button switch. Defaults to large if not passed
     */
    size: PropTypes.oneOf(['large', 'small'])
};

RadioButtonSet.defaultProps = {
    color: 'secondary',
    size: 'large'
};
