/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FreeDomainTile.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function FreeDomainTile({ text, button, timeUntilExpiry }) {
    /***** RENDER *****/
    return (
        <div className="FreeDomainTile">
            <div className="FreeDomainTile__top">
                <h4 className="FreeDomainTile__title">{text}</h4>
                {button}
            </div>
            {timeUntilExpiry ? <p className="FreeDomainTile__footer">Offer expires in {timeUntilExpiry}</p> : ''}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
FreeDomainTile.propTypes = {
    // The main text to display on the tile
    text: PropTypes.string.isRequired,

    // The button to display on the tile
    button: PropTypes.element.isRequired,

    // The time until expiry in days, hours and minutes
    timeUntilExpiry: PropTypes.string
};

export default FreeDomainTile;
