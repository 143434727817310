/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaSetupEditorRouteParams } from 'containers/katana/types';
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useSetupEditorRouteParams(): KatanaSetupEditorRouteParams {
    /***** HOOK RESULTS *****/
    const params = useParams<NXUtils.ReplaceKey<KatanaSetupEditorRouteParams, 'id' | 'childpage', string>>();

    function getChildPage() {
        if (!params.childpage) {
            return;
        }
        const childPageNumber = Number(params.childpage);
        if (isNaN(childPageNumber)) {
            return params.childpage;
        }
        return childPageNumber;
    }

    /***** HOOK RESULTS *****/
    return {
        ...params, //
        id: Number(params.id),
        ...(params.childpage ? { childpage: getChildPage() } : {})
    };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export { useSetupEditorRouteParams };
