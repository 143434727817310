/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { touch } from 'redux-form';
import store from 'store/store';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { NXFormContext } from 'components/Form/NXForm/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NNXForm.ExtraFunctions}
 */
const ExtraFunctions = (reduxFormProps) => {
    const { initialize, initialValues, initialiseOnMount, touchAllOnMount, children, form } = reduxFormProps;

    /***** HOOKS *****/
    const submitButtonClickEventRef = useRef(
        writable(/** @type {React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null} */ (null))
    );

    const { fields } = useSelector(
        (/** @type {*} */ state) => {
            return { fields: Object.keys(state?.form?.[form]?.registeredFields ?? {}) };
        },
        {
            equalityFn: (prev, next) => shallowEqual(prev.fields, next.fields)
        }
    );

    const touchAll = () => {
        store.dispatch(touch(form, ...fields));
    };

    /***** EFFECTS *****/
    useEffect(() => {
        if (initialiseOnMount) {
            initialize(initialValues);
        }
        if (touchAllOnMount) {
            touchAll();
        }
    }, []);

    const allFormProps = {
        ...reduxFormProps,
        touchAll,
        submitButtonClickEvent: submitButtonClickEventRef.current
    };

    return <NXFormContext.Provider value={allFormProps}>{children(allFormProps)}</NXFormContext.Provider>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ExtraFunctions };
