/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import KatanaGroupFormFieldGroup from 'containers/katana/formFields/group/inputTypes/group';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/group/consts';
import KatanaGroupFormFieldCallToAction from 'containers/katana/formFields/group/inputTypes/call_to_action';
import KatanaGroupFormFieldGrid from 'containers/katana/formFields/group/inputTypes/grid';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaGroupFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    switch (input_type) {
        case SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE.GROUP:
            return <KatanaGroupFormFieldGroup property={property} />;

        case SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE.GRID:
            return <KatanaGroupFormFieldGrid property={property} />;

        case SITE_SECTION_GROUP_PROPERTY_INPUT_TYPE.CALL_TO_ACTION:
            return <KatanaGroupFormFieldCallToAction property={property} />;

        default:
            return <InvalidInputType name={name} inputType={input_type} fieldType="Group" />;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaGroupFormField };
