/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const planNames = {
    'AXIGEN-STARTER': 'Axigen Starter',
    AXIGEN: 'Axigen',
    'AXIGEN-PLUS': 'Axigen Plus',
    'SYD-EMAIL-STARTER': 'Email Starter',
    'SYD-EMAIL': 'Email',
    'SYD-EMAIL-PLUS': 'Email Plus'
};
