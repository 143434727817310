// @ts-nocheck
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { entries, isEqual } from 'lodash';
import React, { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { DragHandle } from 'components/DragHandle';
import Grid from 'components/Grid';
import { SortableItem } from 'components/SortableItem';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { KatanaRepeatedFormFieldRemoveButton } from 'containers/katana/formFields/repeated/removeButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { useTargetKeyToPropertyPath } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useTargetKeyToPropertyPath';
import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useModifiedFieldArrayProperties } from 'containers/katana/formFields/repeated/hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';
import './__InlineListFieldArrayChild.scss';

const customGridColumnsDictionary = {
    'properties.work_project[0].images': '6rem minmax(0, 1fr)'
} as const;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<Katana.RepeatedPropertyFieldArrayChildProps>}
 */
export const _InlineListFieldArrayChild = ({ member, index, fields, properties, property, sortable }) => {
    /***** HOOKS *****/
    const PropertiesRenderer = useRecursivePropertiesRenderer();
    const { dynamicChange } = useDynamicFormActions();
    /***** RENDER HELPERS *****/
    // have to modify the properties to include the member key
    const modifiedProperties = useModifiedFieldArrayProperties(member, properties, true);
    const singularName = useGetCaveat(CaveatTypes.SINGULAR_NAME, property.caveats, property.name);
    const onRemoveServiceTargetKeyCopy = useGetCaveat(
        CaveatTypes.ON_REMOVE_ENTRY_WHERE_VALUE_IS_IN_TARGET_VALUE_REASSIGN_TARGET_VALUE_TO_FIRST_ENTRY_IN_LIST,
        property.caveats
    );

    const targetPropertyAccessor = useTargetKeyToPropertyPath(property?.key, onRemoveServiceTargetKeyCopy);

    const targetPropertyValue = useGetDynamicFormFieldValue(targetPropertyAccessor);

    const fieldValues = fields.get(index);

    /***** FUNCTIONS *****/

    function removeService() {
        // if the target property accessor is not defined, just remove the field
        const valuesFromRemovingField = entries(fields.get(index));
        const entryInFields = valuesFromRemovingField.find(([_, value]) => {
            return isEqual(value, targetPropertyValue);
        });

        const newEntryIndex = index === 0 ? 1 : 0;
        const firstFieldEntry = fields.get(newEntryIndex);

        if (!!entryInFields && !!firstFieldEntry) {
            const entryInFieldsKey = entryInFields[0];
            const firstFieldKeyValue = firstFieldEntry[entryInFieldsKey];

            dynamicChange(targetPropertyAccessor, firstFieldKeyValue);
        }

        fields.remove(index);
    }

    const gridColumns = useMemo(() => {
        console.log('property.key', property.key);
        console.log('property', property);
        const customColumns = customGridColumnsDictionary[property.key];
        console.log('customColumns', customColumns);
        const currentColumns = customColumns || `repeat(${modifiedProperties.length}, auto)`;
        return sortable ? `${DragHandle.width}px ${currentColumns}` : currentColumns;
    }, [property, modifiedProperties]);

    /***** RENDER *****/
    return (
        <WrapWithComponent component={SortableItem} id={fieldValues?.[SORTABLE_ID_KEY]} wrap={sortable}>
            <div className="KatanaRepeatedFormField__inlineDisplayItem">
                <KatanaRepeatedFormFieldRemoveButton removeService={removeService} singularName={singularName} />
                <ContentEditorModuleContent>
                    <Grid columns={gridColumns} alignItems--start>
                        {sortable && <DragHandle.DNDKit fullHeight id={fieldValues?.[SORTABLE_ID_KEY]} />}
                        <PropertiesRenderer properties={modifiedProperties} />
                    </Grid>
                </ContentEditorModuleContent>
            </div>
        </WrapWithComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
