/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { terms_url } from 'config/config';
import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import { RenderField, RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'securityInfoForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let SecurityInfoForm = ({ handleSubmit, options }) => (
    <form className="accountSecurityInfo__form" onSubmit={handleSubmit}>
        <div className="form__row">
            <div className="form__column full">
                <Field
                    label="Security Question"
                    name="question_id"
                    component={RenderSelectField}
                    type="select"
                    validate={[requiredFieldValidation]}
                    className="form__dropdown"
                    options={options}
                />
            </div>
        </div>
        <div className="form__row">
            <div className="form__column full">
                <Field
                    component={RenderField}
                    label="Security Answer"
                    name="answer"
                    type="text"
                    placeholder=""
                    validate={[requiredFieldValidation]}
                    className="form__textfield"
                />
            </div>
        </div>
        <div className="form__row">
            <div className="form__column full">
                <Field
                    component={RenderDateTimePicker}
                    label="Date of Birth"
                    name="dob"
                    dateFormat="d/M/yyyy"
                    validate={[requiredFieldValidation]}
                    className="form__textfield"
                />
            </div>
        </div>
        <div className="form__row">
            <div className="form__column full">
                <div className="form__description">
                    You agree to our{' '}
                    <Anchor target="_blank" href={terms_url}>
                        Terms, Policies and Agreements
                    </Anchor>{' '}
                    by updating your security details.
                </div>
            </div>
        </div>
        <div className="form__row">
            <ReduxFormButton form={formName} className="form__column full">
                Save Changes
            </ReduxFormButton>
        </div>
    </form>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
SecurityInfoForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(SecurityInfoForm);

const mapStateToProps = (state) => {
    if (!state?.account?.account_security_information_data) return {};

    const dob = DateTime.fromFormat(state.account.account_security_information_data.dob, 'dd/MM/yyyy').toJSDate();

    const initialValues = {
        ...state.account.account_security_information_data,
        dob: dob
    };

    initialValues.question =
        state.account.account_security_information_data && state.account.account_security_information_data.question_id
            ? state.account.account_security_information_data.question_id
            : '';

    return {
        initialValues
    };
};

SecurityInfoForm = connect(mapStateToProps, null)(SecurityInfoForm);

export default SecurityInfoForm;
