/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SubAccountsList({ data, actionOnClick, /* Redux props */ hosting_subaccount_list_status }) {
    /***** RENDER HELPERS *****/
    const renderSubAccounts = () => {
        if (!data || data.length === 0) return <div className="ticketsServicesTable__subaccountsError">No sub accounts found</div>;

        return data.map((subaccount, index) => {
            const { user, domain } = subaccount;

            return (
                <div key={index} className="service__subaccount">
                    <div className="service__subaccountLeft">
                        <div className="service__subaccountInfo">
                            <span className="service__subaccountDomain">{domain}</span>
                            <span className="service__subaccountSlash">/</span>
                            <span className="service__subaccountUser">{user}</span>
                        </div>
                        <div className="service__subaccountPackage">{subaccount.package}</div>
                    </div>
                    <OutlineButton
                        type="onClick"
                        size="small"
                        onClick={(e) => {
                            e.preventDefault();
                            actionOnClick({ user, domain });
                        }}
                    >
                        Select
                    </OutlineButton>
                </div>
            );
        });
    };

    /***** RENDER *****/
    switch (hosting_subaccount_list_status) {
        case 'error':
            return <div className="ticketsServicesTable__subaccountsError">Something went wrong, could not get sub accounts</div>;
        case 'success':
            return renderSubAccounts();
        case 'loading':
            return <RequestLoader />;
        default:
            return '';
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect((state) => ({
        hosting_subaccount_list_status: state.hosting.hosting_subaccount_list_status
    }))(SubAccountsList)
);
