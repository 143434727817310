/**
 * @param {string} entryCreationBehaviour
 * @returns {{
 *      method: EntryCreationBehaviourTypeValues,
 *      target: string
 * } | null}
 */
export function entryCreationBehaviourParser(entryCreationBehaviour) {
    const behaviourData = entryCreationBehaviour?.split(':');
    const entryBehaviourObject = {
        method: /** @type {EntryCreationBehaviourTypeValues} */ (behaviourData[0]),
        target: behaviourData[1]
    };
    return behaviourData?.length ? entryBehaviourObject : null;
}
