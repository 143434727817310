/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has, keys } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Accordion from 'components/Accordion';
import { OptionsIcon } from 'components/OptionsIcon';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetDynamicFormFieldSyncErrors } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldSyncErrors';
import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useModifiedFieldArrayProperties } from 'containers/katana/formFields/repeated/hooks';
import { useStoreEffect } from 'utilities/hooks/useStoreEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<Katana.RepeatedPropertyFieldArrayChildProps>}
 */
const _AccordionFieldArrayChild = (props) => {
    const { member, index, fields, properties, property, sortable } = props;
    /***** HOOKS *****/
    const { submitButtonClickEvent } = useContext(ContentEditorRenderModulesContext);
    const PropertiesRenderer = useRecursivePropertiesRenderer();
    const { syncErrors } = useGetDynamicFormFieldSyncErrors();

    const fieldValues = fields.get(index);

    /***** RENDER HELPERS *****/
    // have to modify the properties to include the member key
    const modifiedProperties = useModifiedFieldArrayProperties(member, properties, true);
    const singularName = useGetCaveat(CaveatTypes.SINGULAR_NAME, property.caveats, property.name);
    const accordionTitleFormValueTarget = useGetCaveat(CaveatTypes.ACCORDION_TITLE_VALUE_TARGET, property.caveats);

    /***** FUNCTIONS *****/
    function removeService() {
        fields.remove(index);
    }

    const accordionTitleFormValue = useMemo(() => fieldValues[accordionTitleFormValueTarget], [fieldValues, accordionTitleFormValueTarget]);
    const accordionIsDefaultActive = useMemo(() => !keys(fieldValues).filter((key) => key !== SORTABLE_ID_KEY).length, [fieldValues]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(accordionIsDefaultActive);

    const handleSubmitButtonEvent = useCallback(
        /**
         * @param {TContentEditorRenderModules.SubmitButtonClickEventValue} e
         */
        (e) => {
            const errorInPath = has(syncErrors, member);
            if (errorInPath) {
                setIsAccordionOpen(true);
            }
        },
        [syncErrors, member]
    );

    /***** EFFECTS *****/
    useStoreEffect(submitButtonClickEvent, handleSubmitButtonEvent, false);

    const AccordionComponent = useMemo(() => (sortable ? Accordion.WithDragHandle : Accordion), [sortable]);

    const memoedContent = useMemo(
        () => (
            <Padding xy={4} paddingOnly>
                {/* <KatanaRepeatedFormFieldRemoveButton removeService={removeService} singularName={singularName} /> */}
                <ContentEditorModuleContent>
                    <PropertiesRenderer properties={modifiedProperties} />
                </ContentEditorModuleContent>
            </Padding>
        ),
        [modifiedProperties]
    );

    /***** RENDER *****/
    return (
        <AccordionComponent
            controlled={{
                active: isAccordionOpen,
                toggle: () => setIsAccordionOpen((currentState) => !currentState)
            }}
            id={fieldValues?.[SORTABLE_ID_KEY]}
            border
            title={accordionTitleFormValue ? accordionTitleFormValue : singularName}
            colour="primary"
            afterChevronContent={<OptionsIcon onConfirm={removeService} />}
            content={memoedContent}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _AccordionFieldArrayChild };
