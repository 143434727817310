/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Accepts a callback function that will be called after a specified delay. if the dependencies change, the timeout will be reset.
 *
 * Usage notes:
 *  - behaves similar to use effect, don't pass a dependency that will be updated inside the callback function otherwise you will get an infinite loop.
 *
 * @template {Array<unknown>} G
 * @template U
 * @template {(...args: G) => U} T
 * @param {T} func
 * @param {number} delay
 * @param {React.DependencyList} dependencies
 */
const useDebounce = (func, delay, dependencies) => {
    const firstUpdate = useRef(true);

    useEffect(() => {
        // do not call this function on first render
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const timeout = setTimeout(func, delay);

        return () => void clearTimeout(timeout);
    }, [delay, ...dependencies]);
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useDebounce;
