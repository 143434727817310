/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ticket_name, service_status } from 'config/config';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import KnowledgeBase from './sections/knowledgeBase';
import ETickets from './sections/etickets';
import Feedback from './sections/feedback';
import Migration from './sections/migration';
import ServiceStatus from './sections/serviceStatus';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';
import Transition from 'components/Transition';
import Subnav from 'components/Subnav';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_support.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Support extends Component {
    constructor(props) {
        super(props);
        this.navitem = [
            {
                label: 'Support Centre',
                link: '/support/support-centre',
                icon: 'support'
            },
            {
                label: `${ticket_name}s`,
                link: '/support/tickets',
                icon: 'eticket'
            },
            {
                label: 'Migration Request',
                link: '/support/migration-request',
                icon: 'icon-migrations'
            },
            {
                label: 'Feedback',
                link: '/support/feedback',
                icon: 'customer-service'
            },
            {
                label: 'Service Status',
                external: true,
                link: service_status,
                icon: 'service-status'
            }
        ];
    }

    render() {
        const {
            location: { pathname },
            match: {
                params: { subpage }
            }
        } = this.props;

        const renderSubPage = () => {
            switch (subpage) {
                case 'support-centre':
                    return <KnowledgeBase />;
                case 'tickets':
                    return <ETickets />;
                case 'feedback':
                    return <Feedback />;
                case 'migration-request':
                    return <Migration />;
                case 'service-status':
                    return <ServiceStatus />;
                default:
                    return <PageNotFound />;
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="support">
                <Subnav items={this.navitem} pathname={pathname} />
                <Transition when={subpage} className="support__section">
                    {renderSubPage()}
                </Transition>
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(Support);
