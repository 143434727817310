/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ValidateCNAME extends Component {
    render() {
        const { record, content, copyToClipboard } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="validateCNAME">
                <div className="validateCNAME__text">Create the following DNS CNAME record on your domain name:</div>
                <div className="validateCNAME__container">
                    <div className="validateCNAME__text label">Record</div>
                    <div className="validateCNAME__input">
                        <div className="validateCNAME__value">{record}</div>
                        <Anchor
                            className="validateCNAME__action"
                            onClick={(e) => {
                                e.preventDefault();
                                copyToClipboard(record);
                            }}
                        >
                            Copy
                        </Anchor>
                    </div>
                </div>
                <div className="validateCNAME__container">
                    <div className="validateCNAME__text label">Content</div>
                    <div className="validateCNAME__input">
                        <div className="validateCNAME__value">{content}</div>
                        <Anchor
                            className="validateCNAME__action"
                            onClick={(e) => {
                                e.preventDefault();
                                copyToClipboard(content);
                            }}
                        >
                            Copy
                        </Anchor>
                    </div>
                </div>
                <div className="validateCNAME__text">
                    Once created, click the <strong>Validate Domain Name</strong> button below. Allow up to 15 minutes for DNS propogation.
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ValidateCNAME = reduxForm({
    form: 'validateCNAME'
})(ValidateCNAME);

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            copyToClipboard
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(ValidateCNAME);
