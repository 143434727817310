/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import ColourSelectCircle from 'components/ColourSelectCircle';
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { brightnessByColor } from 'utilities/methods/brightnessByColor';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RenderColourSelect.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<import('redux-form').WrappedFieldProps & {
 *      options: Array<{ id: string; hex_code: HexString; borderColor?: string }>;
 *      preventDeselect?: boolean;
 * }>}
 */
const _RenderColourSelect = ({ input, options, preventDeselect = false }) => {
    const { value, onChange } = input;

    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Grid columns={isMobile ? 'repeat(6, 1fr)' : 'repeat(8, 1fr)'} className="RenderColourSelect" gap={2}>
            {options.map(({ id, hex_code, borderColor }) => {
                const isSelected = value === id;
                const colourBrightness = brightnessByColor(hex_code);
                const borderColourBrightness = colourBrightness && colourBrightness > 235 ? '#cfd8dc' : null;
                function getSelectionValue() {
                    if (isSelected) {
                        return preventDeselect ? id : null;
                    }

                    return id;
                }

                return (
                    <ColourSelectCircle
                        key={id}
                        colour={hex_code}
                        borderColor={borderColor || borderColourBrightness}
                        onSelect={() => onChange(getSelectionValue())}
                        selected={isSelected}
                    />
                );
            })}
        </Grid>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const RenderColourSelect = Object.assign(_RenderColourSelect, {
    type: 'select'
});

export default RenderColourSelect;
