/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import { logoutApp } from 'components/Header/action';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import RequestLoader from 'components/Loaders/Request';
import { toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { endAllSessions, endSpecificSession, getActiveLoginHistory, getExpiredLoginHistory } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

function getSessionMatrixRow(sessionMatrixEntry) {
    const { login_at, expires } = sessionMatrixEntry.attributes;

    return {
        session: toLuxonDate(login_at, 'yyyy-MM-dd').toFormat('d MMM yyyy') + (sessionMatrixEntry.attributes.current ? ' (current)' : ''),
        expires: toLuxonDate(expires, 'yyyy-MM-dd').toFormat('d MMM yyyy'),
        name: sessionMatrixEntry.attributes.name,
        ipaddress: sessionMatrixEntry.attributes.ip_address
    };
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class LoginHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEndSessionsLightbox: false,
            showExpiredSessionsTable: false,
            activeSessionMatrix: [],
            expiredSessionsMatrix: []
        };
        this.openEndSessionLightbox = this.openEndSessionLightbox.bind(this);
        this.closeEndSessionLightbox = this.closeEndSessionLightbox.bind(this);
        this.getExpiredLoginSessions = this.getExpiredLoginSessions.bind(this);
        this.endAllActiveSessions = this.endAllActiveSessions.bind(this);
    }

    openEndSessionLightbox() {
        this.setState({
            showEndSessionsLightbox: true
        });
    }

    closeEndSessionLightbox() {
        this.setState({
            showEndSessionsLightbox: false
        });
    }

    getExpiredLoginSessions() {
        const { getExpiredLoginHistory } = this.props;
        getExpiredLoginHistory();
    }

    endAllActiveSessions() {
        const { endAllSessions } = this.props;
        endAllSessions();
    }

    componentDidUpdate(prevProps) {
        const {
            account_active_history_status,
            account_active_history_data,
            account_expired_history_status,
            account_expired_history_data,
            account_end_sessions_status,
            account_end_specific_status,
            endSpecificSession,
            getActiveLoginHistory,
            logoutApp
        } = this.props;
        const { getExpiredLoginSessions, closeEndSessionLightbox } = this;
        registerScrollEvents(this, account_active_history_status === 'success' && prevProps.account_active_history_status === 'loading');

        const renderActionButton = (session) => {
            const { id, attributes } = session;
            const { current } = attributes;
            return {
                label: current ? 'Logout' : 'End',
                type: 'onClick',
                className: 'endbutton',
                size: 'small',
                onClick: (e) => {
                    e.preventDefault();
                    if (current) {
                        logoutApp();
                    } else {
                        endSpecificSession(id);
                    }
                }
            };
        };

        if (account_end_specific_status === 'success' && prevProps.account_end_specific_status === 'loading') {
            getActiveLoginHistory();
        }

        if (account_end_sessions_status === 'success' && prevProps.account_end_sessions_status === 'loading') {
            closeEndSessionLightbox();
            getActiveLoginHistory();
            getExpiredLoginSessions();
        }

        if (account_expired_history_status === 'success' && prevProps.account_expired_history_status === 'loading') {
            /*   CREATE TABLE MATRIX
             **********************************************************************************************************/
            if (account_expired_history_data && account_expired_history_data.length > 0) {
                const expiredSessionsMatrix = Array(account_expired_history_data.length).fill();
                Object.keys(expiredSessionsMatrix).forEach((value) => {
                    const sessionMatrixEntry = getSessionMatrixRow(account_expired_history_data[value]);

                    expiredSessionsMatrix[value] = sessionMatrixEntry;
                });

                this.setState({
                    showExpiredSessionsTable: true,
                    expiredSessionsMatrix
                });
            }
        }

        if (account_active_history_status === 'success' && prevProps.account_active_history_status === 'loading') {
            /*   CREATE TABLE MATRIX
             **********************************************************************************************************/
            if (account_active_history_data && account_active_history_data.length > 0) {
                const activeSessionMatrix = Array(account_active_history_data.length).fill();
                Object.keys(activeSessionMatrix).forEach((value) => {
                    const sessionMatrixEntry = getSessionMatrixRow(account_active_history_data[value]);

                    sessionMatrixEntry.actions = renderActionButton(account_active_history_data[value]);

                    activeSessionMatrix[value] = sessionMatrixEntry;
                });
                this.setState({
                    activeSessionMatrix
                });
            }
            this.setState({
                showEndSessionsLightbox: false
            });
        }
    }

    render() {
        const {
            account_active_history_status,
            account_end_specific_status,
            account_end_sessions_status,
            account_expired_history_status,
            getActiveLoginHistory
        } = this.props;
        const { showEndSessionsLightbox, showExpiredSessionsTable, activeSessionMatrix, expiredSessionsMatrix } = this.state;
        const { endAllActiveSessions, getExpiredLoginSessions, openEndSessionLightbox, closeEndSessionLightbox } = this;

        const handleFooterRender = () => {
            if (account_expired_history_status || showExpiredSessionsTable) {
                return false;
            }

            return {
                title: `Show Expired Sessions`,
                action: () => {
                    getExpiredLoginSessions(999);
                }
            };
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountLoginHistory"
            >
                <Box
                    request={{
                        action: getActiveLoginHistory,
                        args: false,
                        status: account_active_history_status
                    }}
                    className="accountLoginHistory__box"
                    title="Login History"
                    desc={`Below are the most recent logins to your ${application} account.`}
                    status={account_end_specific_status}
                    action={{
                        buttonType: 'Outline',
                        label: 'End All Sessions',
                        type: 'onClick',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            openEndSessionLightbox();
                        }
                    }}
                    custom={{
                        render: (
                            <Fragment>
                                <div className="accountLoginHistory__table--header">Active Sessions</div>
                                <Table
                                    className="accountLoginHistory__table"
                                    header={[
                                        {
                                            title: `Session Login`,
                                            className: `loginStart`
                                        },
                                        {
                                            title: `Session Expires`,
                                            className: `loginExpires`
                                        },
                                        {
                                            title: `Name`,
                                            className: `name`
                                        },
                                        {
                                            title: `IP Address`,
                                            className: `ipAddress`,
                                            datalabel: `ipaddress`
                                        },
                                        {
                                            action: true
                                        }
                                    ]}
                                    loading={account_active_history_status}
                                    matrix={activeSessionMatrix}
                                    error="You have no active sessions at the moment."
                                    stacked={true}
                                    embedded={true}
                                />
                                {account_expired_history_status === 'loading' ? <RequestLoader /> : ''}
                                {showExpiredSessionsTable ? (
                                    <Fragment>
                                        <div className="accountLoginHistory__table--header">Expired Sessions</div>
                                        <Table
                                            className="accountLoginHistory__table"
                                            header={[
                                                {
                                                    title: `Session Login`,
                                                    className: `loginStart`
                                                },
                                                {
                                                    title: `Session Expires`,
                                                    className: `loginExpires`
                                                },
                                                {
                                                    title: `Name`,
                                                    className: `name`
                                                },
                                                {
                                                    title: `IP Address`,
                                                    className: `ipAddress`,
                                                    datalabel: `ipaddress`
                                                }
                                            ]}
                                            loading={account_expired_history_status}
                                            matrix={expiredSessionsMatrix}
                                            error="You have no expired sessions at the moment."
                                            stacked={true}
                                            embedded={true}
                                        />
                                    </Fragment>
                                ) : (
                                    ''
                                )}
                            </Fragment>
                        ),
                        pos: 'bottom'
                    }}
                    footer={handleFooterRender()}
                />

                {showEndSessionsLightbox ? (
                    <OverlayLightbox
                        title="End All Active Sessions"
                        onOpen={showEndSessionsLightbox}
                        loading={account_end_sessions_status}
                        confirm={{
                            desc: htmr(`Doing this will end all sessions, except for your current one. You will remain logged in.`),
                            buttonText: 'End Active Sessions',
                            buttonAction: endAllActiveSessions,
                            closeText: 'No, Keep Sessions Active',
                            closeAction: closeEndSessionLightbox
                        }}
                        onClose={closeEndSessionLightbox}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        account_active_history_status: state.account.account_active_history_status,
        account_active_history_data: state.account.account_active_history_data,
        account_expired_history_status: state.account.account_expired_history_status,
        account_expired_history_data: state.account.account_expired_history_data,
        account_end_specific_status: state.account.account_end_specific_status,
        account_end_sessions_status: state.account.account_end_sessions_status
    };
};

const mapDispatchToProps = {
    getActiveLoginHistory,
    getExpiredLoginHistory,
    endSpecificSession,
    endAllSessions,
    logoutApp
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginHistory));
