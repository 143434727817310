/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RegistrantForm from '../forms/registrant';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { updateRegistrantEmail } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Registrant = (props) => {
    const {
        disabled,
        domainid,
        /**
         * Redux Props
         */
        domain_contact_data,
        domain_contact_status,
        domain_contact_update_status,
        domain_email_update_status,
        updateRegistrantEmail
    } = props;

    /***** STATE *****/
    const [registrantData, setRegistrantData] = useState(null);

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** FUNCTIONS *****/
    function handleRegistrantEmail(values) {
        const post = { registrant: values };
        updateRegistrantEmail(domainid, post);
    }

    function handleRegistrantStatus() {
        if (
            domain_contact_update_status === 'loading' ||
            domain_email_update_status === 'loading' ||
            (domain_contact_status === 'loading' && !registrantData)
        ) {
            return 'loading';
        }

        return 'success';
    }

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, domain_contact_status === 'success');
    }, [domain_contact_status]);

    useEffect(() => {
        if (domain_contact_status !== 'success' || !domain_contact_data || Number(domainid) !== domain_contact_data.id) return;
        const { attributes } = domain_contact_data;
        const { registrant } = attributes;
        setRegistrantData(registrant);
    }, [domain_contact_status, domain_contact_data]);

    useEffect(() => {
        if (domain_contact_update_status === 'success' && domain_contact_data) {
            const { attributes } = domain_contact_data;
            const { registrant } = attributes;

            setRegistrantData(registrant);
        }
    }, [domain_contact_update_status, domain_contact_data]);

    useEffect(() => {
        if (domain_contact_data) {
            const { attributes } = domain_contact_data;
            const { registrant } = attributes;
            setRegistrantData(registrant);
        }
    }, []);

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div ref={scrollRef} className="registrantEmail">
            <Box
                premounted={true}
                disabled={disabled}
                className="registrantEmail__box"
                status={handleRegistrantStatus()}
                title="Registrant Email Address"
                info="It is important to ensure that your registrant email address remains accurate and up to date at all times as domain name related correspondence such as expiry and transfer notices are sent to your registrant email address."
                custom={{
                    render: <RegistrantForm contactDetails={registrantData} initialValues={registrantData} onSubmit={handleRegistrantEmail} />,
                    pos: 'bottom'
                }}
            />
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        domain_contact_status: state.domain.domain_contact_status,
        domain_contact_data: state.domain.domain_contact_data,
        domain_contact_update_status: state.domain.domain_contact_update_status,
        domain_email_update_status: state.domain.domain_email_update_status
    };
};

const mapDispatchToProps = {
    updateRegistrantEmail
};

Registrant = connect(mapStateToProps, mapDispatchToProps)(Registrant);

Registrant = withRouter(Registrant);

export default Registrant;
