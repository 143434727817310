/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FreeDomainClaimForm from 'components/Promo/FreeDomain/ClaimForm';
import VipRewardsHome from 'containers/vipRewards/sections/home';
import VipRewardsMoreInfo from 'containers/vipRewards/sections/moreInfo';
import VipRewardsSignupSuccess from './sections/moreInfo/signupSuccess';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchPageError from 'components/Errors/FetchPageError';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useSearchParams from 'utilities/hooks/useSearchParams';
import { getRewardTiersList } from './action';
import { useVipRewards } from './hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { freeDotOnlinePromoCode, freeDotSitePromoCode, freeDotStorePromoCode, freeDotXyzPromoCode } from 'components/Promo/promoCodes';
import { vipRewardsReadableName } from 'containers/vipRewards/consts';
import { application } from 'config/config';
import { checkYearPromoCode } from 'config/containers/promotions/methods';
import './_vipRewards.scss';

const baseRoute = '/vip-rewards';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**  VIPrewards Routes
 * - vip-rewards (not signed up)
 * - vip-rewards (signed up)
 */
function VipRewards() {
    /***** HOOKS *****/
    const history = useHistory();
    const location = useLocation();
    const rewards_tiers_list_status = useSelector((state) => state.rewards.rewards_tiers_list_status);
    const rewards_tiers_list_data = useSelector((state) => state.rewards.rewards_tiers_list_data);

    const { currentTier, rewardsTiers } = useVipRewards();
    const { searchParamsGet } = useSearchParams();

    /***** STATE *****/
    const [isSignupSuccessLightboxOpen, setIsSignupSuccessLightboxOpen] = useState(false);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!rewards_tiers_list_data) {
            getRewardTiersList();
        }
    }, []);

    /**
     * Set Page Title for VIPrewards routes
     *
     * @override
     * @see {/src/router/user/index.js > UserArea > updatePageTitle} updatePageTitle
     */
    useEffect(() => {
        if (location.pathname.startsWith(baseRoute)) {
            document.title = `${vipRewardsReadableName} | ${application}`;
        }
    }, [location.pathname]);

    /***** FUNCTIONS *****/
    function openSignupSuccessLightbox() {
        setIsSignupSuccessLightboxOpen(true);
    }

    function closeSignupSuccessLightbox() {
        setIsSignupSuccessLightboxOpen(false);
        history.push('/vip-rewards');
    }

    /***** RENDER HELPERS *****/
    const isSignedUp = !!currentTier;

    function renderPage() {
        if (rewards_tiers_list_status === 'error') {
            return <FetchPageError />;
        }

        if (rewards_tiers_list_status === 'loading') {
            return <RequestLoader />;
        }

        if (!rewardsTiers) {
            return '';
        }

        if (!isSignedUp) {
            return (
                <Switch>
                    <Route exact path={`${baseRoute}`} render={() => <VipRewardsMoreInfo openSignupSuccessLightbox={openSignupSuccessLightbox} />} />
                    <Route
                        path={baseRoute}
                        render={(props) => {
                            return <Redirect to={`/vip-rewards${props?.location?.search || ''}`} />;
                        }}
                    />
                </Switch>
            );
        }

        return (
            <Switch>
                <Route exact path={baseRoute} component={VipRewardsHome} />
                <Route
                    path={baseRoute}
                    render={(props) => {
                        return <Redirect to={`/vip-rewards${props?.location?.search || ''}`} />;
                    }}
                />
            </Switch>
        );
    }

    /***** RENDER *****/
    return (
        <main className="vipRewards">
            {renderPage()}

            {!!isSignupSuccessLightboxOpen && (
                <OverlayLightbox onOpen={isSignupSuccessLightboxOpen} onClose={closeSignupSuccessLightbox} title="Welcome to VentraIP Rewards">
                    <VipRewardsSignupSuccess closeSignupSuccessLightbox={closeSignupSuccessLightbox} />
                </OverlayLightbox>
            )}

            {searchParamsGet('free-domain') === 'store' && (
                <OverlayLightbox
                    title="Claim your free .store domain"
                    desc="Find out if the name you want is available by searching below."
                    onOpen={searchParamsGet('free-domain') === 'store'}
                    onClose={() => {
                        checkYearPromoCode(freeDotStorePromoCode, '.store');
                        history.push(location.pathname);
                    }}
                >
                    <FreeDomainClaimForm tld=".store" promoCode={freeDotStorePromoCode} />
                </OverlayLightbox>
            )}

            {searchParamsGet('free-domain') === 'online' && (
                <OverlayLightbox
                    title="Claim your free .online domain"
                    desc="Find out if the name you want is available by searching below."
                    onOpen={searchParamsGet('free-domain') === 'online'}
                    onClose={() => {
                        checkYearPromoCode(freeDotOnlinePromoCode, '.online');
                        history.push(location.pathname);
                    }}
                >
                    <FreeDomainClaimForm tld=".online" promoCode={freeDotOnlinePromoCode} />
                </OverlayLightbox>
            )}

            {searchParamsGet('free-domain') === 'xyz' && (
                <OverlayLightbox
                    title="Claim your free .xyz domain"
                    desc="Find out if the name you want is available by searching below."
                    onOpen={searchParamsGet('free-domain') === 'xyz'}
                    onClose={() => {
                        checkYearPromoCode(freeDotXyzPromoCode, '.xyz');
                        history.push(location.pathname);
                    }}
                >
                    <FreeDomainClaimForm tld=".xyz" promoCode={freeDotXyzPromoCode} />
                </OverlayLightbox>
            )}

            {searchParamsGet('free-domain') === 'site' && (
                <OverlayLightbox
                    title="Claim your free .site domain"
                    desc="Find out if the name you want is available by searching below."
                    onOpen={searchParamsGet('free-domain') === 'site'}
                    onClose={() => {
                        checkYearPromoCode(freeDotSitePromoCode, '.site');
                        history.push(location.pathname);
                    }}
                >
                    <FreeDomainClaimForm tld=".site" promoCode={freeDotSitePromoCode} />
                </OverlayLightbox>
            )}
        </main>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default VipRewards;
