/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PromoCodePill from './Pill';
import PromoCodeApplyButton from './ApplyButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PromoCode.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PromoCode({ className, isDisabled, customDisabledMessage, isLoading, appliedPromoCode, removeAppliedCode, onApply, isRemoveable }) {
    /***** STATE *****/
    const [inputValue, setInputValue] = useState('');

    /***** FUNCTIONS *****/
    function onApplyClick() {
        if (!isDisabled && inputValue && !isLoading) {
            onApply(inputValue);
            setInputValue('');
        }
    }

    /***** RENDER HELPERS *****/
    const renderInput = () => {
        return (
            <div className={classNames('form__item', { form__item: isDisabled })}>
                <div className="form__title">
                    <label htmlFor="promo_code">
                        Have a Coupon Code?<div className="PromoCode__couponTooltip">{customDisabledMessage || 'Unable to apply promo codes.'}</div>
                    </label>
                </div>
                <div className="form__item__inner">
                    <div className="wrapperbox">
                        <input
                            name="promo_code"
                            id="promo_code"
                            type="text"
                            placeholder="Enter your coupon code here if you have one"
                            tabIndex="0"
                            disabled={isDisabled}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <div className={classNames('PromoCode', { 'PromoCode--disabled': isDisabled, className: !!className })}>
            <div className="PromoCode__couponTop">
                {renderInput()}
                <div className="PromoCode__couponApplyContainer">
                    <PromoCodeApplyButton isLoading={isLoading} isDisabled={!inputValue || isLoading} applyButtonOnClick={onApplyClick} />
                </div>
            </div>

            {appliedPromoCode && !isLoading ? (
                <div className="PromoCode__appliedCoupons">
                    <PromoCodePill code={appliedPromoCode} removeButtonOnClick={removeAppliedCode} isRemoveable={isRemoveable} />
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PromoCode.propTypes = {
    /**
     * Additional className to give to the component
     */
    className: PropTypes.string,

    /**
     * Whether or not to disable to input field and "Apply Coupon" anchor
     */
    isDisabled: PropTypes.bool,

    /**
     * Message to go in the tooltip that appears on hover of the input field when "disabled" prop is true
     */
    customDisabledMessage: PropTypes.node,

    /**
     * Whether to show a loader in place of the "apply promo" button
     */
    isLoading: PropTypes.bool,

    /**
     * The promo code that has been successfully applied
     */
    appliedPromoCode: PropTypes.string,

    /**
     * Function to remove / unapply the applied promo code
     */
    removeAppliedCode: PropTypes.func,

    /**
     * Function to apply the promo code
     */
    onApply: PropTypes.func,

    /**
     * If the promo code is removeable, the pill wil show an "x" button to remove it
     */
    isRemoveable: PropTypes.bool
};

export default PromoCode;
