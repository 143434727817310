import PropTypes from 'prop-types';
import { ReactChildrenProp } from 'utilities/consts';

export const RENDER_CARD_SELECT_OPTION_TYPES = Object.freeze({
    IMAGE: 'image',
    FONT: 'font',
    CUSTOM: 'custom'
});

const ImageOptionType = PropTypes.shape({
    type: PropTypes.oneOf([RENDER_CARD_SELECT_OPTION_TYPES.IMAGE]),
    url: PropTypes.string
});

const CustomOptionType = PropTypes.shape({
    type: PropTypes.oneOf([RENDER_CARD_SELECT_OPTION_TYPES.CUSTOM]),
    content: ReactChildrenProp,
    value: PropTypes.string
});

const fontOptionFontType = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    font_family: PropTypes.string,
    google_fonts_key: PropTypes.string
});

const FontOptionType = PropTypes.shape({
    type: PropTypes.oneOf([RENDER_CARD_SELECT_OPTION_TYPES.FONT]),
    font: fontOptionFontType
});

export const RenderCardSelectOptionPropType = PropTypes.oneOfType([ImageOptionType, FontOptionType, CustomOptionType]);
