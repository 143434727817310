/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RenderSelectedForm from '../forms/migrationRequestForm/renderSelectedForm';
import SelectService from '../modules/migrationSelect';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Migration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 'select-service',
            selectedService: null,
            selectedEnv: undefined
        };

        this.selectService = this.selectService.bind(this);
        this.selectEnv = this.selectEnv.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    selectService(id) {
        this.setState({
            step: 'provide-details',
            selectedService: id
        });
    }

    selectEnv(title) {
        this.setState({
            selectedEnv: title
        });
    }

    goBack() {
        this.setState({
            selectedEnv: undefined,
            selectedService: null,
            step: 'select-service'
        });
    }

    render() {
        const { selectService, selectEnv, goBack } = this;
        const { step, selectedEnv, selectedService } = this.state;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <section className="migration">
                <div className="migration__header">
                    <div className="migration__title">Website Migration Request</div>
                    <a href="https://ventraip.com.au/web-hosting/transfer-hosting/" target="blank" className="migration__headerLink">
                        How does the migration work?
                    </a>
                    {step === 'provide-details' ? (
                        <button className="migration__backLink" onClick={(e) => goBack()}>
                            <span className="icon icon-arrow-left"></span>
                        </button>
                    ) : (
                        ''
                    )}
                </div>
                {step === 'select-service' ? (
                    <SelectService selectService={selectService} selectedEnv={selectedEnv} selectEnv={selectEnv} />
                ) : (
                    <RenderSelectedForm selectedEnv={selectedEnv} selectedService={selectedService} />
                )}
            </section>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    app_user_data: state.app.app_user_data,
    support_submit_migration_request_status: state.support.support_submit_migration_request_status
});

Migration = withRouter(connect(mapStateToProps, null)(Migration));

export default Migration;
