/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get } from 'lodash';
import React, { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import classNames from 'classnames';
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { Revealer } from 'components/Revealer';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _SelectCard as SelectCard } from 'components/Form/CardSelectGrid/_SelectCard';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { TCardSelectGrid } from 'components/Form/CardSelectGrid/types';
import type { WrappedFieldProps } from 'redux-form';
import './_CardSelectGrid.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const CardSelectGrid: React.FC<WrappedFieldProps & TCardSelectGrid.Props> = ({
    selectionText = 'Choose this photo',
    options,
    label,
    description,
    itemsPerRow = 2,
    isHidingDisabled = false,
    alignItems,
    textAlign,
    cardContentGrow = true,
    onChangeProxy,
    valueProxy,
    disabled,
    /**
     * Redux Props
     */
    input,
    meta
}) => {
    const { name, value, onChange } = input;
    const maxBaseRange = Math.min(itemsPerRow * 2, options.length);
    const baseRange: [number, number] = [0, isHidingDisabled ? options.length : maxBaseRange];
    /***** STATE *****/
    const valueProxiedValue = useMemo(() => (valueProxy ? valueProxy(value) : value), [value]);

    // Only check this on mount as we don't want it opening and closing all the time
    const isValueInVisibleRange = useMemo(() => {
        if (!valueProxiedValue) {
            return true;
        }
        const valueIndex = options.findIndex((option) =>
            [get(option, 'value'), get(option, 'fontID'), get(option, 'url')].includes(valueProxiedValue)
        );
        return valueIndex >= baseRange[0] && valueIndex < baseRange[1];
    }, [Boolean(valueProxiedValue)]);

    /***** FUNCTIONS *****/
    function handleOnChange(_value: TCardSelectGrid.SelectCard.OnChangeValue) {
        onChange(onChangeProxy ? onChangeProxy(_value) : _value);
    }

    /***** RENDER *****/
    return (
        <FormItem
            name={name}
            className={classNames('CardSelectGrid', {
                'CardSelectGrid--hasDescription': description,
                'CardSelectGrid--cardContentGrow': cardContentGrow
            })}
        >
            <FormLabel htmlFor={name}>{label}</FormLabel>
            {description}
            <FormItemInner meta={meta} no-bg fullWidth>
                <Revealer.Wrappers.GridHide
                    gridProps={{
                        'columns': `repeat(${itemsPerRow}, 1fr)`,
                        'alignItems--stretch': alignItems === 'stretch',
                        'gap': 4
                    }}
                    itemsPerRow={itemsPerRow}
                    data={options}
                    isDefaultOpen={!isValueInVisibleRange}
                    range={baseRange}
                >
                    {(option, i) => (
                        <SelectCard
                            disabled={disabled}
                            key={i}
                            option={option}
                            selectionText={selectionText}
                            onChange={handleOnChange}
                            value={valueProxiedValue}
                            textAlign={textAlign}
                        />
                    )}
                </Revealer.Wrappers.GridHide>
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default CardSelectGrid;
