/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import Table from 'components/Table';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getFileQuarantine } from '../state/securityActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class FileQuarantine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasFiles: false
        };
    }

    componentDidUpdate(prevProps) {
        const { hosting_file_quarantine_status, hosting_file_quarantine_data } = this.props;
        registerScrollEvents(this, hosting_file_quarantine_status === 'success' && prevProps.hosting_file_quarantine_status === 'loading');

        if (hosting_file_quarantine_status === 'success' && prevProps.hosting_file_quarantine_status === 'loading') {
            const { attributes } = hosting_file_quarantine_data;

            if (attributes && attributes.quarantined && attributes.quarantined.length > 0) {
                this.setState({
                    hasFiles: true
                });
            }
        }
    }

    render() {
        const { hosting_file_quarantine_status, hosting_file_quarantine_data, hostingid, subaccount, getFileQuarantine } = this.props;
        const { hasFiles } = this.state;

        let conditionalProps = {
            bottom: true,
            columns: [
                {
                    render: (
                        <div className="sharedBox__infoColumn hasIcon">
                            <i className="infoColumn__icon icon icon-check confirm"></i>
                            <div className="infoColumn__wrapper">
                                <div className="title">File Quarantine Status</div>
                                <div className="desc">No quarantined files found! You&apos;re in the clear.</div>
                            </div>
                        </div>
                    )
                }
            ]
        };

        if (hasFiles) {
            const {
                attributes: { quarantined }
            } = hosting_file_quarantine_data;

            const quarantineMatrix = quarantined.map(({ quarantine_time, quarantine_message, actual_file_location }) => ({
                date: quarantine_time,
                message: <p>{quarantine_message}</p>,
                location: actual_file_location
            }));

            conditionalProps = {
                custom: {
                    render: (
                        <Table
                            embedded={true}
                            className="fileQuarantine__table"
                            header={[
                                {
                                    title: `Date`,
                                    className: `date`
                                },
                                {
                                    title: `Quarantine Message`,
                                    className: `quarantinemessage`
                                },
                                {
                                    title: `File Location`,
                                    className: `filelocation`
                                }
                            ]}
                            loading={hosting_file_quarantine_status}
                            matrix={quarantineMatrix}
                            stacked={true}
                        />
                    ),
                    pos: 'bottom'
                }
            };
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="fileQuarantine"
            >
                <Box
                    request={{
                        action: getFileQuarantine,
                        args: [hostingid, subaccount],
                        status: hosting_file_quarantine_status
                    }}
                    status={hosting_file_quarantine_status}
                    className="fileQuarantine__box"
                    title="File Quarantine"
                    desc="File Quarantine protects your service from malware by deleting or moves suspicious files into quarantine. It also prevents users from uploading malicious PHP and perl shell scripts, that may be used to launch attacks or perform unintended actions."
                    info={`The File Quarantine tool helps us prevent exploitation of your account by malware by deleting or moving suspicious files to quarantine before they become active. It can also prevent users uploading malicious PHP and Perl shell scripts, that may be commonly used to launch attacks or perform unintended actions.
                        <br><br>
                        If you have files that have been quarantined, contact our Customer Care team or reinstall the compromised file.`}
                    {...conditionalProps}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_file_quarantine_status: state.hosting.hosting_file_quarantine_status,
        hosting_file_quarantine_data: state.hosting.hosting_file_quarantine_data,
        hosting_file_quarantine_error: state.hosting.hosting_file_quarantine_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getFileQuarantine
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileQuarantine));
