/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import OverviewForm from '../forms/overview';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { ScrollableComponent } from 'components/ScrollableComponent';

/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType, scrollOptions, toLuxonDate, truncateText } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getEmailMailboxInformation, updateServiceDetails } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditLightbox: false,
            showEditData: undefined,
            emailHostingName: null,
            invoiceDueDate: undefined,
            billingCycle: undefined,
            mailboxInfo: {
                domain: '',
                domain_status: '',
                registration_date: '',
                next_invoice_date: '',
                next_due_date: '',
                billing_cycle: '',
                amount: ''
            },
            planNames: {
                'SYD-EMAIL-STARTER': 'Email Starter',
                'SYD-EMAIL': 'Email',
                'SYD-EMAIL-PLUS': 'Email Plus'
            }
        };
        this.submitEdit = this.submitEdit.bind(this);
        this.openEditLightbox = this.openEditLightbox.bind(this);
        this.closeEditLightbox = this.closeEditLightbox.bind(this);
    }

    submitEdit(values) {
        const {
            updateServiceDetails,
            match: {
                params: { mailbox }
            }
        } = this.props;
        const attributes = {
            ...values
        };
        updateServiceDetails(mailbox, attributes);
    }

    openEditLightbox() {
        const { email_information_mailbox_data } = this.props;
        const { attributes } = email_information_mailbox_data;
        this.setState({
            showEditLightbox: true,
            showEditData: {
                ...attributes
            }
        });
    }

    closeEditLightbox() {
        this.setState({
            showEditLightbox: false,
            showEditData: undefined
        });
    }

    componentDidMount() {
        const { email_information_mailbox_data } = this.props;

        const { attributes, included } = email_information_mailbox_data;
        const product = getIncludedObjBasedOnType(included, 'product');

        this.setState({
            mailboxInfo: {
                ...attributes,
                product: product.attributes
            },
            showEditData: {
                ...attributes
            }
        });
    }

    componentDidUpdate(prevProps) {
        const {
            email_information_mailbox_status,
            email_information_mailbox_data,
            email_mailbox_update_status,
            getEmailMailboxInformation,
            match: {
                params: { mailbox }
            }
        } = this.props;

        registerScrollEvents(this, email_information_mailbox_status === 'success' && prevProps.email_information_mailbox_status === 'loading');

        if (email_information_mailbox_status === 'success' && prevProps.email_information_mailbox_status === 'loading') {
            const { attributes, included } = email_information_mailbox_data;
            const product = getIncludedObjBasedOnType(included, 'product');

            this.setState({
                mailboxInfo: {
                    ...attributes,
                    product: product.attributes
                }
            });
        }

        if (email_mailbox_update_status === 'success' && prevProps.email_mailbox_update_status === 'loading') {
            this.closeEditLightbox();
            getEmailMailboxInformation(mailbox);
        }
    }

    render() {
        const {
            sidebarRefs,
            email_information_mailbox_status,
            email_mailbox_update_status,
            history,
            match: {
                params: { mailbox, id }
            }
        } = this.props;
        const { showEditLightbox, showEditData, mailboxInfo, planNames } = this.state;
        const { product } = mailboxInfo;
        const { submitEdit, openEditLightbox, closeEditLightbox } = this;

        if (product) {
            /*   SET CONDITIONAL PROPS
             **********************************************************************************************************/
            const conditionalProps = {
                className: `emailOverview__box`,
                split: {
                    left: {
                        render: (
                            <Fragment>
                                <div className="sharedSplit__container--title">
                                    <h2>Service Overview</h2>
                                    {mailbox ? (
                                        <Anchor
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openEditLightbox();
                                            }}
                                        >
                                            Edit Details
                                        </Anchor>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Email Address / Username</div>
                                    <div className="sharedSplit__description domain">{truncateText(mailboxInfo.domain, 35, '...')}</div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Status</div>
                                    <div
                                        className={`sharedSplit__description status ${mailboxInfo.domain_status === 'suspended' ? 'warning' : ''} ${
                                            mailboxInfo.domain_status === 'active' ? 'confirm' : ''
                                        }`}
                                    >
                                        {mailboxInfo.domain_status}
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Product Name</div>
                                    <div className="sharedSplit__description plan">
                                        <div className="title">{mailboxInfo && mailboxInfo.product ? planNames[mailboxInfo.product.name] : ''}</div>
                                        {mailboxInfo && mailboxInfo.product && mailboxInfo.product.name.toLowerCase() === 'syd-email-plus' ? (
                                            ''
                                        ) : (
                                            <div className="action">
                                                <Anchor
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        const upgradeLink = `/my-services/email-hosting/mailbox/upgrade/${id}/mailbox/${mailbox}`;
                                                        scroll.scrollTo(sidebarRefs[upgradeLink].getBoundingClientRect().top, scrollOptions);
                                                        history.push(upgradeLink);
                                                    }}
                                                >
                                                    Upgrade
                                                </Anchor>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Activated</div>
                                    <div className="sharedSplit__description">
                                        {toLuxonDate(mailboxInfo.registration_date).toFormat('dd MMM yyyy')}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    },

                    right: {
                        render: (
                            <Fragment>
                                <div className="sharedSplit__container--title">
                                    <h2>Billing Overview</h2>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Next Renewal Date</div>
                                    <div className="sharedSplit__description">
                                        {mailboxInfo.invoice_due_date
                                            ? toLuxonDate(mailboxInfo.invoice_due_date).toFormat('dd MMM yyyy')
                                            : toLuxonDate(mailboxInfo.next_due_date).toFormat('dd MMM yyyy')}
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Next Invoice Generation Date</div>
                                    <div className="sharedSplit__description">
                                        {toLuxonDate(mailboxInfo.next_invoice_date).toFormat('dd MMM yyyy')}
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Billing Cycle</div>
                                    <div className="sharedSplit__description hasAction">
                                        <div className="title">{mailboxInfo.billing_cycle}</div>
                                        <div className="action">
                                            <ScrollableComponent.Anchor
                                                pathname={`/my-services/email-hosting/mailbox/billing/${id}/mailbox/${mailbox}`}
                                            >
                                                Change
                                            </ScrollableComponent.Anchor>
                                        </div>
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Renewal Amount</div>
                                    <div className="sharedSplit__description">${mailboxInfo.amount} AUD</div>
                                </div>
                            </Fragment>
                        )
                    }
                }
            };

            /*  Render Component
             **********************************************************************************************************/
            return (
                <div
                    ref={(el) => {
                        this.scrollRef = el;
                    }}
                    className="emailOverview"
                >
                    <Box premounted={true} status={email_information_mailbox_status} {...conditionalProps} />
                    {showEditLightbox ? (
                        <OverlayLightbox
                            onOpen={showEditLightbox}
                            title="Edit Mailbox Details"
                            onClose={closeEditLightbox}
                            loading={email_mailbox_update_status}
                            className="editEmailService__lightbox"
                        >
                            <OverviewForm onSubmit={submitEdit} data={showEditData} />
                        </OverlayLightbox>
                    ) : (
                        ''
                    )}
                </div>
            );
        } else {
            return '';
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            email_information_mailbox_status: state.email.email_information_mailbox_status,
            email_information_mailbox_data: state.email.email_information_mailbox_data,
            email_mailbox_update_status: state.email.email_mailbox_update_status,
            sidebarRefs: state.sidebar.sidebarRefs
        }),
        {
            updateServiceDetails,
            getEmailMailboxInformation
        }
    )(Overview)
);
