/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function EditDetailsButton(props) {
    const { canEditDetails, allowEditDetails } = props;
    if (canEditDetails) return '';

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="form__item migrationRequestForm__allowEditDetailsButton--wrapper">
            <Anchor className="migrationRequestForm__allowEditDetails--button" onClick={allowEditDetails}>
                Edit details
            </Anchor>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default EditDetailsButton;
