/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { resetScrollEvents } from 'utilities/methods/commonActions';
import { renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleRedirect } from 'utilities/methods/redirects/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EmailGeneral extends Component {
    componentDidUpdate() {
        const { match, email_information_mailbox_data, history } = this.props;
        const { params } = match;
        const { mailbox } = params;

        if (mailbox && email_information_mailbox_data) {
            handleRedirect({
                serviceType: 'email_hosting',
                status: email_information_mailbox_data?.attributes?.domain_status,
                domainName: email_information_mailbox_data?.attributes?.domain,
                history
            });
        }
    }

    render() {
        const { match, email_information_mailbox_data, modules } = this.props;
        const { params } = match;
        const { mailbox } = params;

        if (mailbox && !email_information_mailbox_data) return <RequestLoader />;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return <div className="emailGeneral section">{renderSectionModules(modules)}</div>;
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    email_information_mailbox_data: state.email.email_information_mailbox_data
});

const mapDispatchToProps = {
    resetScrollEvents
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailGeneral));
