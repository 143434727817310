/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_KatanaSectionSettingsModule.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaSectionSettingsModule: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div className="KatanaSectionSettingsModule">{children}</div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
