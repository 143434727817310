/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import StyledLoader from 'components/Loaders/Styled';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TruthyProp } from 'utilities/consts';
import './_NXBox.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXBoxNamespace } from 'components/NXBox/types';

const NXBoxAppliedPropTypes = {
    /**
     * Applies warning colour border
     */
    warn: TruthyProp
};
const NXBoxAppliedPropTypeKeys = Object.keys(NXBoxAppliedPropTypes);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The base Box. Provides loading statuses and some basic styling such as border and border-radius
 */
const _NXBox: React.FC<NXBoxNamespace.Props> = (props) => {
    const { className, children, initialStatus, isLoading, onError } = props;

    /***** RENDER HELPERS *****/
    const renderContent = () => {
        switch (true) {
            case onError && initialStatus === 'error':
                return onError;
            case initialStatus === 'error':
                return <FetchComponentError />;
            case initialStatus === 'loading':
                return <StyledLoader />;
            case isLoading:
                return <RequestLoader />;
            default:
                return children;
        }
    };

    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        componentName: 'NXBox',
        delimiter: '--',
        keyBoundary: NXBoxAppliedPropTypeKeys
    });

    const NXBoxClasses = classNames('NXBox', className, appliedStylingClasses);

    /***** RENDER *****/
    return (
        <div className={NXBoxClasses}>
            <div className="NXBox--flushBorder">
                <ErrorBoundary fallback={<FetchComponentError />}>{renderContent()}</ErrorBoundary>
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _NXBox;
