/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FormItem.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NFormItem } from 'components/Form/FormItem/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FormItem: React.FC<NFormItem.Props> = ({ disabled = false, className, children, isPlaceholderItalic = false, name, size }) => {
    /***** RENDER HELPERS *****/
    const formItemClasses = classNames(
        'FormItem',
        {
            [`FormItem__${name?.replaceAll('.', '__')}`]: name,
            [`FormItem__${name?.replaceAll('.', '__')?.replaceAll(/\[\d\]/g, '__index')}`]: name,
            [`FormItem--size-${size}`]: size,
            'FormItem--disabled': disabled,
            'FormItem--isPlaceholderItalic': isPlaceholderItalic
        },
        className
    );

    /***** RENDER *****/
    return <div className={formItemClasses}>{children}</div>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
