/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import classNames from 'classnames';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NameserversAddOrRemoveButton from 'containers/domain/forms/customns/addOrRemoveButton';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, renderButton, requiredFieldValidation, trailingCharactersValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_tppDomainNameserversForm.scss';

/**
 * Redux form "field array" helper function to render the list of fields dynamically
 */
const renderFields = ({ fields }) => {
    return (
        <>
            {fields.map((field, index) => (
                <div key={`${field}.record-${index}`} className="form__row">
                    <div
                        className={classNames('tppDomainNameserversForm__record', {
                            'tppDomainNameserversForm__record--removeable': index === fields.length - 1
                        })}
                    >
                        <div className="form__column">
                            <Field
                                name={`${field}.record`}
                                component={RenderField}
                                type="text"
                                placeholder="nsX.hosting-services.net.au"
                                validate={[requiredFieldValidation, trailingCharactersValidation]}
                            />
                        </div>
                        {index === fields.length - 1 && fields.length > 2 ? (
                            <NameserversAddOrRemoveButton action="remove" onClick={() => fields.remove(index)} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ))}
            {fields.length < 9 ? (
                <div className="tppDomainNameserversForm__addButtonContainer">
                    <NameserversAddOrRemoveButton action="add" onClick={() => fields.push({ record: '' })} />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain nameservers form
 */
let TPPDomainNameserversForm = ({ isTppDomainUpdateDnsLoading, handleSubmit, pristine, valid }) => {
    /***** RENDER *****/
    return (
        <>
            <form className="tppDomainNameserversForm" onSubmit={handleSubmit}>
                <FieldArray name="nameservers" component={renderFields} />
                {renderButton(
                    pristine,
                    isTppDomainUpdateDnsLoading,
                    valid,
                    isTppDomainUpdateDnsLoading ? <RequestLoader /> : 'Set Custom Nameservers',
                    'primary'
                )}
            </form>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

TPPDomainNameserversForm = reduxForm({
    form: 'tppDomainNameservers'
})(TPPDomainNameserversForm);

export default TPPDomainNameserversForm;
