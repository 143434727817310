/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZone as NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/useKatanaFileUpload';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<import('components/Form/NXDropZone/types').TNXDropZone.ReduxForm.WithKatanaFileUpload.Props>}
 */
const _NXDropZoneReduxFormWithKatanaFileUpload = ({ serviceID, className, ...baseProps }) => {
    const { input, meta, disabled, label } = baseProps;
    const { form } = meta;
    const { name, onChange } = input;

    /***** HOOKS *****/
    const dispatch = useDispatch();

    /**
     * @param {UseKatanaFileUpload.Values} files
     */
    function handleOnChange(files) {
        dispatch(touch(form, name));
        onChange(files);
    }

    /***** QUERIES *****/
    const { isUploading, onChangeProxy } = useKatanaFileUpload(serviceID, handleOnChange);

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} no-bg fullWidth>
                <IsDataUpdatingOverlay.StableDomStructure noBorder isDataUpdating={isUploading} message="Uploading File...">
                    <NXDropZone {...baseProps} onChange={onChangeProxy} />
                </IsDataUpdatingOverlay.StableDomStructure>
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _NXDropZoneReduxFormWithKatanaFileUpload };
