/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class InstallForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: null,
            selectedOption: `sni`
        };
        this.setPrice = this.setPrice.bind(this);
        this.selectOption = this.selectOption.bind(this);
    }

    setPrice(price) {
        this.setState({
            price: price
        });
    }

    selectOption(option) {
        this.setState(
            {
                selectedOption: option
            },
            () => {
                if (option === 'dedicated') {
                    this.setPrice(99.95);
                } else {
                    this.setPrice(null);
                }
            }
        );
    }

    render() {
        const { hasExistingAddon, dedicatedIP, submitForm } = this.props;
        const { price, selectedOption } = this.state;
        const { selectOption } = this;

        const renderInstallWithPurchase = () => {
            return (
                <>
                    <div className="installSSLForm__wrapper">
                        <button
                            className={`overlayAddon__option ${selectedOption === `sni` ? 'selected' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                selectOption(`sni`);
                            }}
                        >
                            <span className="option__title">
                                <span className="option__radio" />
                                <span className="option__text">Install with a Shared IP Address</span>
                            </span>
                            <span className="option__price">Free</span>
                        </button>
                        <button
                            className={`overlayAddon__option ${selectedOption === `dedicated` ? 'selected' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                selectOption(`dedicated`);
                            }}
                        >
                            <span className="option__title">
                                <span className="option__radio" />
                                <span className="option__text">Install with a Dedicated IP Address</span>
                            </span>
                            <span className="option__price">$99.95 /Yearly</span>
                        </button>
                    </div>
                    <div className="installSSLForm__price">
                        <div className="label">Amount Due</div>
                        <div className="value">{price ? `$${price} AUD` : `Free`}</div>
                    </div>
                    <div className="installSSLForm__action">
                        <SolidButton
                            type="onClick"
                            onClick={(e) => {
                                e.preventDefault();
                                submitForm(selectedOption);
                            }}
                        >
                            Install SSL Certificate
                        </SolidButton>
                    </div>
                </>
            );
        };

        const renderInstallWithExistingAddon = () => {
            return (
                <>
                    <div className="installSSLForm__description">
                        Our system has detected that this web hosting service included Dedicated IP Address addon.
                    </div>
                    <div className="installSSLForm__wrapper">
                        <div className="installSSLForm__addon">
                            <div className="installSSLForm__addon--ip">Dedicated IP Address</div>
                            <div className="installSSLForm__addon--address">{dedicatedIP}</div>
                        </div>
                        <button
                            className={`overlayAddon__option ${selectedOption === `existing` ? 'selected' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                selectOption(`existing`);
                            }}
                        >
                            <span className="option__title full">
                                <span className="option__radio" />
                                <span className="option__text">Install with existing Dedicated IP Address</span>
                            </span>
                        </button>
                        <button
                            className={`overlayAddon__option ${selectedOption === `remove` ? 'selected' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                selectOption(`remove`);
                            }}
                        >
                            <span className="option__title full">
                                <span className="option__radio" />
                                <span className="option__text">Install and remove existing Dedicated IP Address</span>
                            </span>
                        </button>
                    </div>
                    <div className="installSSLForm__action">
                        <SolidButton
                            type="onClick"
                            onClick={(e) => {
                                e.preventDefault();
                                submitForm(selectedOption);
                            }}
                        >
                            Install SSL Certificate
                        </SolidButton>
                    </div>
                </>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return <div className="installSSLForm">{hasExistingAddon ? renderInstallWithExistingAddon() : renderInstallWithPurchase()}</div>;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default InstallForm;
