/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { application, ventraSynMigrationEndOfLifeDate, ventraSynMigrationShowEndOfLifeDate } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SynergyLoginForm from 'containers/ventraSynMigration/forms/synergyLoginForm';
import { Intro } from 'containers/ventraSynMigration/modules/intro';
import MarketingSidebar from 'containers/ventraSynMigration/modules/marketingSidebar';
import VerticalTimeline from 'containers/ventraSynMigration/modules/verticalTimeline';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import FetchComponentError from 'components/Errors/FetchComponentError';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { checkRequiresMigration, synergyLogin } from 'containers/ventraSynMigration/action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class MarketingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lightboxOpen: false
        };

        this.timelineItems = [
            {
                icon: 'icon-sw-review-costa',
                title: 'Review your new Synergy Wholesale costs and promotional discount',
                paragraph:
                    'Our system will provide you with exactly what your new costs with Synergy Wholesale would be for the accounts you select to migrate. As part of this exclusive offer, we will supply you with the remainder of this month + one full month of free hosting at Synergy Wholesale for each cPanel account you choose to move using this offer.'
            },
            {
                icon: 'icon-sw-choose-services',
                title: 'Choose the services you wish to migrate',
                paragraph:
                    "Complete our simple form to let us know which services you’d like to migrate and when a migration would work best for you. You can do as many or as few at a time as you'd like, not forced to do them all at once!"
            },
            {
                icon: 'icon-sw-create-account',
                title: 'Create a Synergy Wholesale Account',
                paragraph: `We will create a new account using your current ${application} details, so it’s quick and easy!`
            },
            {
                icon: 'icon-sw-migration-dns',
                title: 'Migration & DNS Updates',
                paragraph: 'We will take care of your migrations and update your DNS on your domains with Synergy Wholesale or VentraIP.'
            },
            {
                icon: 'icon-sw-use-credit',
                title: 'Move your unused credit to Synergy Wholesale',
                paragraph:
                    'Once all of your migrations are complete, you can cancel your reseller services with VentraIP and opt to move your unused credit to your Synergy Wholesale account to pay for future transactions.'
            }
        ];

        this.toggleLightbox = this.toggleLightbox.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    }

    toggleLightbox() {
        this.setState({
            lightboxOpen: !this.state.lightboxOpen
        });
    }

    handleLoginSubmit(values) {
        const { synergyLogin } = this.props;

        synergyLogin(values);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { checkRequiresMigration } = this.props;

        checkRequiresMigration();
    }

    componentDidUpdate(prevProps, prevState) {
        const { vsm_synergy_login_status, history } = this.props;

        if (vsm_synergy_login_status === 'success' && prevProps.vsm_synergy_login_status === 'loading')
            history.push('/synergy-reseller-migration/select-services');
    }

    render() {
        const { app_viewport, vsm_synergy_login_status, vsm_requires_migration_status, vsm_requires_migration_data } = this.props;
        const { lightboxOpen } = this.state;
        const { timelineItems, toggleLightbox, handleLoginSubmit } = this;

        const renderBanner = () => {
            return (
                <div className="vsMarketing__bannerContainer">
                    <div className="vsMarketing__banner">
                        <p className="vsMarketing__bannerText">
                            Your reseller services will reach their end of life on{' '}
                            <span className="vsMarketing__bannerTime">{ventraSynMigrationEndOfLifeDate}</span> and will need to be moved to a new
                            provider.
                        </p>
                    </div>
                </div>
            );
        };

        const renderMainContent = () => {
            const points = [
                'A painless as possible migration path',
                'One time $99 activation fee waived',
                'The choice to move unused credit from VentraIP to Synergy Wholesale'
            ];

            return (
                <div className="vsMarketing__main">
                    <div className="vsMarketing__topSection">
                        <Intro />
                        <p className="vsMarketing__offerText">
                            Moving your clients to a new web hosting provider might feel daunting. That&apos;s why we&apos;ve simplified the process,
                            to make it as easy as possible.
                        </p>
                        <div className="vsMarketing__threePoints">
                            {points.map((point, index) => (
                                <div key={index} className="vsMarketing__point">
                                    <i className="icon icon-tick-solid" />
                                    {point}
                                </div>
                            ))}
                        </div>
                        <VerticalTimeline title="How does it work?" items={timelineItems} />
                    </div>

                    {app_viewport === 'md' && <MarketingSidebar numOfQuotes={1} />}

                    <div className="vsMarketing__bottomSection">
                        <SolidButton className="vsMarketing__getStartedButton" to="/synergy-reseller-migration/select-services">
                            GET STARTED
                        </SolidButton>
                        {!vsm_requires_migration_data.has_linked_account && (
                            <>
                                <div className="vsMarketing__or">OR</div>
                                <p className="vsMarketing__orText">Already a Synergy Wholesale partner?</p>
                                <OutlineButton type="onClick" onClick={toggleLightbox} className="vsMarketing__loginButton">
                                    LOGIN HERE
                                </OutlineButton>
                            </>
                        )}
                    </div>

                    {['sm', 'xs'].includes(app_viewport) && <MarketingSidebar numOfQuotes={1} />}
                </div>
            );
        };

        const renderLoginLightbox = () => {
            return (
                <OverlayLightbox onOpen={lightboxOpen} onClose={toggleLightbox} loading={vsm_synergy_login_status}>
                    <SynergyLoginForm onSubmit={handleLoginSubmit} />
                </OverlayLightbox>
            );
        };

        const renderPage = () => {
            return (
                <div className="vsMarketing">
                    {ventraSynMigrationShowEndOfLifeDate && renderBanner()}
                    <div className="vsMarketing__container">
                        {renderMainContent()}
                        {!['md', 'sm', 'xs'].includes(app_viewport) && <MarketingSidebar numOfQuotes={2} />}
                    </div>
                    {renderLoginLightbox()}
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        switch (vsm_requires_migration_status) {
            case 'success':
                return <div className="ventraSynMigration">{renderPage()}</div>;
            case 'error':
                return <FetchComponentError />;
            case 'loading':
            default:
                return <RequestLoader />;
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            app_viewport: state.app.app_viewport,
            vsm_synergy_login_status: state.vsm.vsm_synergy_login_status,
            vsm_requires_migration_status: state.vsm.vsm_requires_migration_status,
            vsm_requires_migration_data: state.vsm.vsm_requires_migration_data
        }),
        {
            checkRequiresMigration,
            synergyLogin
        }
    )(MarketingPage)
);
