/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TwoFactor from './modules/2fa';
import AccountAccess from './modules/accountAccess';
import AdditionalUsers from './modules/additionalUsers';
import Close from './modules/close';
import Contacts from './modules/contacts';
import Details from './modules/details';
import AccountEmail from './modules/emails';
import LoginHistory from './modules/loginHistory';
import Overview from './modules/overview';
import ChangePassword from './modules/password';
import Referral from './modules/referral';
import SecurityInfo from './modules/securityInfo';
import AccountSubscriptions from './modules/subscriptions/subscriptions';
import Emails from './sections/emails';
import General from './sections/general';
import Security from './sections/security';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsAccountHolder } from 'containers/account/methods';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { handleSideMenuOptions, isURLValid } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_account.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Account extends Component {
    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { app_user_data } = this.props;

        if (!checkIsAccountHolder(app_user_data)) {
            pushNotification({ status: 401, details: 'As an additional user you are not authorized to view this resource.' });
        }
    }

    render() {
        const { match, app_user_data, app_check_token_data, app_viewport, login_account_list_data } = this.props;
        const { url, params } = match;
        const { subpage } = params;
        const { role } = app_user_data;

        const modules = {
            general: [
                {
                    sidebarLink: {
                        label: 'Overview',
                        link: '/account/general/overview'
                    },
                    component: <Overview />,
                    condition: true,
                    identifier: 'account/overview'
                },
                {
                    sidebarLink: {
                        label: 'Personal Details',
                        link: '/account/general/personal-details'
                    },
                    component: <Details />,
                    condition: true,
                    identifier: 'account/personal-details'
                },
                {
                    sidebarLink: {
                        label: 'Contacts',
                        link: '/account/general/contacts'
                    },
                    component: <Contacts />,
                    condition: true,
                    identifier: 'account/contacts'
                },
                {
                    sidebarLink: {
                        label: 'Additional Users',
                        link: '/account/general/additional-users'
                    },
                    component: <AdditionalUsers />,
                    condition: true,
                    identifier: 'account/additional-users'
                },
                {
                    sidebarLink: {
                        label: 'Account Access',
                        link: '/account/general/account-access'
                    },
                    component: <AccountAccess />,
                    condition: app_check_token_data?.is_vpn || (login_account_list_data && login_account_list_data.length > 1),
                    identifier: 'account/account-access'
                },
                {
                    sidebarLink: {
                        label: 'Referrals',
                        link: '/account/general/referrals'
                    },
                    component: <Referral />,
                    condition: true,
                    identifier: 'account/referrals'
                },
                {
                    sidebarLink: {
                        label: 'Close Account',
                        link: '/account/general/close'
                    },
                    component: <Close />,
                    condition: true,
                    identifier: 'account/close-account'
                }
            ],

            security: [
                {
                    sidebarLink: {
                        label: 'Change Password',
                        link: '/account/security/password'
                    },
                    component: <ChangePassword />,
                    condition: true,
                    identifier: 'account/change-password'
                },
                {
                    sidebarLink: {
                        label: 'Security Information',
                        link: '/account/security/information'
                    },
                    component: <SecurityInfo />,
                    condition: true,
                    identifier: 'account/security-information'
                },
                {
                    sidebarLink: {
                        label: 'Two-Factor Authentication',
                        link: '/account/security/two-factor'
                    },
                    component: <TwoFactor />,
                    condition: true,
                    identifier: 'account/2fa'
                },
                {
                    sidebarLink: {
                        label: 'Login History',
                        link: '/account/security/login-history'
                    },
                    component: <LoginHistory />,
                    condition: true,
                    identifier: 'account/login-history'
                }
            ],

            emails: [
                {
                    sidebarLink: {
                        label: 'View Emails',
                        link: '/account/emails/view'
                    },
                    component: <AccountEmail />,
                    condition: true,
                    identifier: 'account/view-emails'
                },
                {
                    sidebarLink: {
                        label: 'Subscriptions',
                        link: '/account/emails/subscriptions'
                    },
                    component: <AccountSubscriptions />,
                    condition: true,
                    identifier: 'account/subscriptions'
                }
            ]
        };

        const renderSidemenu = () => {
            const generalList = {
                list_title: null,
                list_items: handleSideMenuOptions(modules, 'general')
            };

            const securityList = {
                list_title: 'Security',
                list_icon: 'lock',
                list_items: handleSideMenuOptions(modules, 'security')
            };

            const emailList = {
                list_title: 'Emails and Notifications',
                list_icon: 'email',
                list_items: handleSideMenuOptions(modules, 'emails')
            };

            return <Sidebar title="My Profile" icon="account" links={[generalList, securityList, emailList]} />;
        };

        const renderSection = () => {
            switch (subpage) {
                case 'general':
                    return <General modules={modules.general} />;
                case 'security':
                    return <Security modules={modules.security} />;
                case 'emails':
                    return <Emails modules={modules.emails} />;
                default:
                    break;
            }
        };

        const renderMyAccount = () => {
            return (
                <Fragment>
                    {app_viewport === 'sm' || app_viewport === 'xs' ? renderSidemenu() : ''}

                    <Page sidebar={app_viewport === 'md' || app_viewport === 'lg' || app_viewport === 'xl' ? renderSidemenu() : ''}>
                        <Transition when={subpage} className="account__section">
                            {renderSection()}
                        </Transition>
                    </Page>
                </Fragment>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        if (isURLValid(modules, url) && role === 'Account Holder') {
            return renderMyAccount();
        } else {
            const { isExact } = match;
            const { token } = params;
            if (isExact && token) {
                return ''; // Activate Account goes here
            } else {
                return <PageNotFound />;
            }
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    app_user_data: state.app.app_user_data,
    app_check_token_data: state.app.app_check_token_data,
    app_viewport: state.app.app_viewport,
    login_account_list_data: state.login.login_account_list_data
});

Account = connect(mapStateToProps)(Account);

Account = withRouter(Account);

export default Account;
