/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';
import VipRewardsHomePurchaseHostingLightbox from 'containers/vipRewards/sections/home/purchaseHostingLightbox';
import AddHostingDropdown from './dropdown';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import newHostingBackground from 'assets/images/hosting/hosting_banner_background.png';
import newHostingImg from 'assets/images/hosting/hosting_banner_icon.svg';

import './_addHosting.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AddHosting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUpgradeDropdownOpen: false,
            upgradeType: null,
            isPurchaseHostingLightboxOpen: false
        };

        this.toggleUpgradeDropdown = this.toggleUpgradeDropdown.bind(this);
        this.setIsPurchaseHostingLightboxOpen = this.setIsPurchaseHostingLightboxOpen.bind(this);
        this.setUpgradeType = this.setUpgradeType.bind(this);
    }

    toggleUpgradeDropdown() {
        this.setState({
            isUpgradeDropdownOpen: !this.state.isUpgradeDropdownOpen
        });
    }

    setIsPurchaseHostingLightboxOpen(state) {
        this.setState({
            ...this.state,
            isPurchaseHostingLightboxOpen: state
        });
    }

    setUpgradeType(type) {
        const { setIsPurchaseHostingLightboxOpen } = this;

        this.setState(
            {
                ...this.state,
                upgradeType: type
            },
            () => setIsPurchaseHostingLightboxOpen(true)
        );
    }

    componentDidUpdate() {
        registerScrollEvents(this, true);
    }

    render() {
        const { domain_information_data } = this.props;
        const { isUpgradeDropdownOpen, isPurchaseHostingLightboxOpen, upgradeType } = this.state;
        const { toggleUpgradeDropdown, setIsPurchaseHostingLightboxOpen, setUpgradeType } = this;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="addHosting"
            >
                <NXBox initialStatus="success" isLoading={false} className="addHosting__Outer">
                    <ServiceOverviewBanner slim src={newHostingBackground}>
                        <ServiceOverviewBanner.ContentWrapper>
                            <ServiceOverviewBanner.Heading size--custom={26}>
                                Need a hosting service for this domain name?
                            </ServiceOverviewBanner.Heading>
                            <ServiceOverviewBanner.Description>Consider adding a Select Hosting plan</ServiceOverviewBanner.Description>
                        </ServiceOverviewBanner.ContentWrapper>
                        <ServiceOverviewBanner.Image src={newHostingImg} alt="vipsites logo" />
                    </ServiceOverviewBanner>

                    <NXBox.Bottom>
                        <NXBox.BottomColumns
                            columns={[
                                <Text key={1} secondary size--s>
                                    Our new Select hosting plans are the perfect solution for tailoring resources that better suit your hosting needs
                                    with selectable plans for any size website.
                                </Text>,
                                <OutlineButton key={2} type="onClick" onClick={toggleUpgradeDropdown}>
                                    View Plans
                                </OutlineButton>
                            ]}
                        />
                    </NXBox.Bottom>
                    <NXBox.Dropdown isOpen={isUpgradeDropdownOpen}>
                        <NXBox.DefaultPadding>
                            <NXBox.Dropdown.CloseButton onClose={toggleUpgradeDropdown} />
                            <Text size--l bold secondary>
                                Select Hosting Type
                            </Text>
                            <AddHostingDropdown action={setUpgradeType} />
                        </NXBox.DefaultPadding>
                    </NXBox.Dropdown>
                </NXBox>

                {isPurchaseHostingLightboxOpen ? (
                    <OverlayLightbox
                        onOpen={isPurchaseHostingLightboxOpen}
                        onClose={() => setIsPurchaseHostingLightboxOpen(false)}
                        title="Purchase Web Hosting"
                    >
                        <VipRewardsHomePurchaseHostingLightbox
                            initiallySelectedHostingType={upgradeType}
                            initialPrimaryDomain={domain_information_data?.attributes?.domain}
                        />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect((state) => ({
        domain_information_data: state.domain.domain_information_data
    }))(AddHosting)
);
// - check if it needs to work with a promo code (old file has promo code stuff in component did update)
