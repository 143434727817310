/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';

/*   ACTIONS
 *****************************************************/
import { copyToClipboard, getIncludedObjBasedOnType, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { checkTempURL, disableTempURL, enableTempURL } from '../state/configActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TempURL extends Component {
    constructor(props) {
        super(props);
        this.toggleTempURLForm = this.toggleTempURLForm.bind(this);
        this.state = {
            status: 'disabled',
            data: null,
            service: undefined,
            showTempURLDropdown: false
        };
    }

    toggleTempURLForm() {
        const { showTempURLDropdown } = this.state;

        this.setState({
            showTempURLDropdown: !showTempURLDropdown
        });
    }

    componentDidMount() {
        const { hosting_information_data } = this.props;
        const { attributes, included } = hosting_information_data;
        const product = getIncludedObjBasedOnType(included, 'product');

        this.setState({
            service: {
                domain: attributes.domain ? attributes.domain : '',
                plan: product && product.name ? product.name : ''
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { hosting_tempurl_check_status, hosting_tempurl_check_data } = this.props;

        registerScrollEvents(this, hosting_tempurl_check_status === 'success' && prevProps.hosting_tempurl_check_status === 'loading');

        if (hosting_tempurl_check_status === 'success' && prevProps.hosting_tempurl_check_status === 'loading') {
            const { attributes } = hosting_tempurl_check_data;
            const { temporary_url_enabled } = attributes;

            this.setState({
                status: temporary_url_enabled ? 'enabled' : 'disabled',
                data: attributes
            });
        }
    }

    render() {
        const {
            hostingid,
            subaccount,
            checkTempURL,
            hosting_tempurl_status,
            hosting_tempurl_check_status,
            hosting_information_data,
            enableTempURL,
            disableTempURL,
            copyToClipboard
        } = this.props;
        const { service, status, data, showTempURLDropdown } = this.state;
        const { toggleTempURLForm } = this;
        const host = data && `${data.ip_address} ${hosting_information_data.attributes.domain} www.${hosting_information_data.attributes.domain}`;
        let conditionalProps;

        const renderDropdown = () => {
            return (
                <>
                    <div className="dropdown">
                        <div className="sharedBox__title">{`Have you tried modifying your computer's host file?`}</div>
                        <div className="sharedBox__description">
                            {`While creating a temporary URL is a way of testing out a website before it's live, we recommended adjusting your
                            computer's host file instead as it's a much more effective solution. Add this entry to your computer's hosts file below:`}
                        </div>
                        <div className="sharedBox__clipboard">
                            <div className="sharedBox__clipboard--text">
                                {`${data && data.ip_address} ${
                                    hosting_information_data.attributes.domain
                                } ${`www.${hosting_information_data.attributes.domain}`}`}
                            </div>
                            <button
                                onClick={() => {
                                    copyToClipboard(host);
                                }}
                            >
                                <i className="dropdown__clipboard--icon icon icon-copy" />
                            </button>
                        </div>
                        <div className="sharedBox__description">
                            <span>Need help? </span>
                            <Anchor
                                className="dropdown__help--link"
                                href="/support/support-centre/web-hosting/testing-your-website-before-pointing-your-domain-to-our-servers"
                            >
                                View our guide here
                            </Anchor>
                        </div>
                    </div>
                    {renderFooter()}
                </>
            );
        };

        const renderFooter = () => {
            return (
                <>
                    <div className="sharedBox__subtitle">If you have already tried doing this, create a temporary URL:</div>
                    <SolidButton
                        className="Tempurl__footer--button"
                        type="onClick"
                        onClick={() => {
                            enableTempURL(hostingid, subaccount);
                        }}
                    >
                        Create URL
                    </SolidButton>
                </>
            );
        };

        if (status === 'disabled') {
            conditionalProps = {
                desc: `If you require access to your website prior to directing your domain name to our servers, then you can enable a temporary URL. The URL will be available for 28 days.`,
                action: {
                    label: 'More Information',
                    type: 'onClick',
                    className: '',
                    color: '',
                    size: 'large',
                    onClick: () => {
                        toggleTempURLForm();
                    }
                },
                dropdown: {
                    render: renderDropdown(),
                    condition: showTempURLDropdown,
                    close: toggleTempURLForm
                }
            };
        } else {
            conditionalProps = {
                desc: htmr(`A temporary URL has been setup for your <strong>${service.plan}</strong> service - <strong>${service.domain}</strong>`),
                bottom: true,
                columns: [
                    {
                        render: (
                            <div className="sharedBox__infoColumn">
                                <div className="title">Temporary URL Address</div>
                                <div className="desc">{data.url}</div>
                            </div>
                        )
                    },
                    {
                        render: (
                            <div className="sharedBox__infoColumn">
                                <div className="title">URL Expires</div>
                                <div className="desc">{data.expiry ? toLuxonDate(data.expiry).toFormat('dd MMM yyyy') : 'Not Available'}</div>
                            </div>
                        )
                    }
                ],
                action: {
                    label: 'Disable',
                    type: 'onClick',
                    className: '',
                    color: 'warn',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        disableTempURL(hostingid, subaccount);
                    },
                    list: [
                        {
                            label: 'Reset to 28 days',
                            type: 'onClick',
                            className: '',
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                enableTempURL(hostingid, subaccount);
                            }
                        }
                    ]
                }
            };
        }

        const handleLoadingStatus = () => {
            if (hosting_tempurl_check_status === 'loading' || hosting_tempurl_status === 'loading') {
                return 'loading';
            }

            return 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="Tempurl"
            >
                <Box
                    request={{
                        action: checkTempURL,
                        args: [hostingid, subaccount],
                        status: hosting_tempurl_check_status
                    }}
                    className="customHosting__box"
                    title="Temporary URL"
                    status={handleLoadingStatus()}
                    info={`If you require access to your website prior to directing your domain name at our servers, then enabling the use of our temporary url may be your best option.
					28 days from activation the Temporary URL will be deactivated automatically, you will have to re-enable it on this page if you require it after this time period.`}
                    {...conditionalProps}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_tempurl_check_status: state.hosting.hosting_tempurl_check_status,
        hosting_tempurl_check_data: state.hosting.hosting_tempurl_check_data,
        hosting_tempurl_check_error: state.hosting.hosting_tempurl_check_error,
        hosting_tempurl_status: state.hosting.hosting_tempurl_status,
        hosting_tempurl_data: state.hosting.hosting_tempurl_data,
        hosting_tempurl_error: state.hosting.hosting_tempurl_error,
        hosting_information_data: state.hosting.hosting_information_data,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            copyToClipboard,
            checkTempURL,
            enableTempURL,
            disableTempURL
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TempURL));
