/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { Select } from 'components/Form/Select';
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { filterInstagramType } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/filterInstagramType';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaMutation } from 'containers/katana/queries/mutationTree';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface AccountConnectedProps {
    propertyKey: string;
    currentlyConnectedSocialID: number | null;
    setDynamicSocialID: (socialID: number) => void;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _AccountConnected: React.FC<AccountConnectedProps> = ({ propertyKey, currentlyConnectedSocialID, setDynamicSocialID }) => {
    /***** STATE *****/
    const { value: isShowingConfirmRemove, setTrue: showConfirmRemove, setFalse: hideConfirmRemove } = useBoolean(false);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { dynamicChange } = useDynamicFormActions();
    const userSocialID = useGetDynamicFormFieldValue(propertyKey);

    /***** QUERIES *****/
    const { mutateAsync: mutateUnlinkSocialID, isPending: isUnlinkSocialIDPending } = katanaMutation.serviceID.unlinkSocialID.useMutation(id);
    const { data: instagramAccounts } = katanaQuery.serviceID.listSocialAccounts.useQuerySelector(id, filterInstagramType);

    /***** FUNCTIONS *****/
    function handleUnlinkAccount() {
        if (userSocialID) {
            mutateUnlinkSocialID(userSocialID)
                .catch(_.noop)
                .finally(() => {
                    dynamicChange(propertyKey, null);
                });
        } else {
            dynamicChange(propertyKey, null);
        }
        hideConfirmRemove();
    }

    /***** RENDER HELPERS *****/
    const socialAccountSelectOptions = useMemo(() => {
        return [
            {
                label: 'No Account',
                value: null
            },
            ..._.map(instagramAccounts, ({ id, username }) => {
                const labelContent =
                    id === currentlyConnectedSocialID ? (
                        <Text>
                            {username}{' '}
                            <Text italic secondary>
                                (currently connected)
                            </Text>
                        </Text>
                    ) : (
                        username
                    );

                return {
                    label: labelContent,
                    value: id
                };
            })
        ];
    }, [instagramAccounts, currentlyConnectedSocialID]);

    const currentInstagramAccountName = useMemo(() => {
        const currentAccount = _.find(instagramAccounts, { id: userSocialID });
        return currentAccount?.username;
    }, [instagramAccounts, userSocialID]);

    const isUserIDInSocialAccounts = useMemo(
        () => userSocialID && _.some(instagramAccounts, { id: userSocialID }),
        [instagramAccounts, userSocialID]
    );

    /***** RENDER *****/
    if (isUnlinkSocialIDPending) {
        return <RequestLoader message="Unlinking Social Account..." />;
    }

    const hasSocialAccountOptions = Boolean(socialAccountSelectOptions.length > 1);
    if (!hasSocialAccountOptions && !isUserIDInSocialAccounts) {
        return;
    }

    return (
        <Flex direction="column" gap={2}>
            {hasSocialAccountOptions && (
                <Field
                    name={propertyKey}
                    component={Select.ReduxForm}
                    options={socialAccountSelectOptions}
                    className="AccountConnected__Select"
                    onSelect={setDynamicSocialID}
                />
            )}
            {isUserIDInSocialAccounts && (
                <>
                    <OutlineButton type="onClick" color="warn" onClick={showConfirmRemove} width="full">
                        Unlink Account
                    </OutlineButton>
                    <Modal.Compact isOpen={isShowingConfirmRemove} onClose={hideConfirmRemove} ariaLabel="Unlink Instagram Account Confirmation">
                        <Text.Heading h2 align--center>
                            Unlink Instagram Account Confirmation
                        </Text.Heading>
                        <Padding y={4}>
                            <Text size--m secondary align--center>
                                Unlinking will remove this Instagram account from all Katana sites. If you wish to use a different account, you can
                                connect a new one or select a previously connected account.
                            </Text>
                        </Padding>
                        <Flex justify="center">
                            <OutlineButton type="onClick" onClick={hideConfirmRemove}>
                                Cancel
                            </OutlineButton>
                            <SolidButton type="onClick" onClick={handleUnlinkAccount}>
                                <Text>
                                    Unlink <Text italic>{currentInstagramAccountName}</Text>
                                </Text>
                            </SolidButton>
                        </Flex>
                    </Modal.Compact>
                </>
            )}
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
