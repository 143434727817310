/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InfoNotification from '../InfoNotification';
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PayInvoiceNotification.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PayInvoiceNotification({ text, openPayInvoice, openCancelInvoice }) {
    /***** RENDER *****/
    return (
        <InfoNotification className="InfoNotification--invoice" type="warning">
            <div className="text">{text}</div>
            <div className="action">
                <SolidButton type="onClick" color="white" onClick={openPayInvoice}>
                    Pay Invoice
                </SolidButton>
                {openCancelInvoice ? (
                    <SolidButton type="onClick" color="white" onClick={openCancelInvoice}>
                        Cancel Invoice
                    </SolidButton>
                ) : (
                    ''
                )}
            </div>
        </InfoNotification>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PayInvoiceNotification.propTypes = {
    // The instruction text to go in the notification
    text: PropTypes.string,

    // Function to open the pay invoice lightbox for whatever page the notification is on
    openPayInvoice: PropTypes.func,

    // Function to open the cancel invoice lightbox for whatever page the notification is on
    openCancelInvoice: PropTypes.func
};

export default PayInvoiceNotification;
