/* eslint-disable jsx-a11y/no-static-element-interactions */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_InactiveButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated
 * The InactiveButton component implements a button that is disabled, that is to say, it is a greyed out button with light text (or other children).
 *
 * The InactiveButton also provides the ability to customize the size and loading state.
 *
 * @param {{
 *   children: React.ReactNode,
 *   className?: string,
 *   size?: 'small' | 'medium' | 'large',
 *   loading?: boolean
 * }} props
 * @returns
 */
const InactiveButton = ({ children, className, size, loading }) => {
    const classes = classNames('InactiveButton', className, size);

    /***** RENDER *****/
    return (
        <button aria-disabled type="button" className={classes}>
            {loading ? <RequestLoader width={40} height={40} /> : children}
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

InactiveButton.propTypes = {
    // contents of the button
    children: PropTypes.node,

    // extra className to give the button
    className: PropTypes.string,

    // how big the button should be
    size: PropTypes.oneOf(['small', 'medium', 'large']),

    // whether or not to display a request loader in place of children
    loading: PropTypes.bool
};

export default InactiveButton;
