/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RadioButtonCircle from 'components/Form/RadioButtonCircle';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import classNames from 'classnames';
import './__RadioButtonCircleButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      isActive: boolean;
 *      onClick: () => void;
 *      children: React.ReactNode;
 *      color?: RadioButtonCircleColours;
 *      disabled?: boolean;
 *      className?: string
 * }>}
 */
const _RadioButtonCircleButton = ({ isActive, onClick, children, color = 'confirm', disabled, className }) => {
    /***** RENDER *****/
    return (
        <Flex items="center" inject>
            <button type="button" className={classNames('RadioButtonCircleButton', className)} onClick={onClick} disabled={disabled}>
                <RadioButtonCircle color={color} isActive={isActive} />
                <Text semiBold black size--m align--left>
                    {children}
                </Text>
            </button>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _RadioButtonCircleButton };
