/**********************************************************************************************************addonMethods
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, renderCheckboxField, requiredAcceptedValidation, requiredFieldValidation, validateIsValidDomain } from 'utilities/methods/form';

const formName = 'primaryDomainForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let PrimaryDomainForm = ({ handleSubmit, domain }) => (
    <form className="primaryDomainForm" onSubmit={handleSubmit}>
        <Field
            label="New Primary Domain Name"
            name="newPrimaryDomain"
            component={RenderField}
            validate={[requiredFieldValidation, validateIsValidDomain]}
            type="text"
            className="form__textfield"
        />
        <div className="confirmation__container">
            <h5 className="confirmation__heading">Confirmation</h5>
            <div className="confirmation__input">
                <Field
                    name="addonConfirm"
                    label={htmr(
                        String.raw`<strong>I confirm the NEW domain name <strong className='highlight'>IS NOT</strong> currently configured as an Addon.</strong>`
                    )}
                    component={renderCheckboxField}
                    validate={[requiredAcceptedValidation]}
                    type="checkbox"
                    className="form__textfield"
                />
            </div>
            <div className="confirmation__input">
                <Field
                    name="parkedConfirm"
                    label={htmr(
                        String.raw`<strong>I confirm the NEW domain name <strong className='highlight'>IS NOT</strong> currently configured as an Alias Domain.</strong>`
                    )}
                    component={renderCheckboxField}
                    validate={[requiredAcceptedValidation]}
                    type="checkbox"
                    className="form__textfield"
                />
            </div>
            <div className="confirmation__input">
                <Field
                    name="currentConfirm"
                    label={htmr(
                        String.raw`<strong>I acknowledge that by changing the primary domain name on this account the current domain name (<strong className='highlight'>${domain}</strong>) will cease to function (i.e. emails and website, etc).</strong>`
                    )}
                    component={renderCheckboxField}
                    validate={[requiredAcceptedValidation]}
                    type="checkbox"
                    className="form__textfield"
                />
            </div>
            <div className="primaryDomainForm__warningContainer">
                <div className="primaryDomainForm__warning">
                    <span className="primaryDomainForm__warningNote">Please Note:</span>
                    Changing your primary domain name WILL NOT change your website&apos;s URL. More information can be found in our{' '}
                    <Anchor to="/support/support-centre/web-hosting/changing-primary-domain-cpanel-hosting-service">Knowledge Base</Anchor>. If you
                    don&apos;t feel comfortable changing your primary domain name, please submit an eTicket to our Technical Support team who will be
                    happy to assist you with this.
                </div>
            </div>
        </div>
        <ReduxFormButton form={formName}>Change Primary Domain</ReduxFormButton>
    </form>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
PrimaryDomainForm = reduxForm({
    form: formName
})(PrimaryDomainForm);

export default withRouter(PrimaryDomainForm);
