/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get } from 'lodash';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import CheckListItemCircle from 'components/CheckListItemCircle';
import Grid from 'components/Grid';
import NXCard from 'components/NXCard';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _SelectCardContentRenderer as SelectCardContentRenderer } from 'components/Form/CardSelectGrid/_SelectCardContentRenderer';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RENDER_CARD_SELECT_OPTION_TYPES } from 'components/Form/CardSelectGrid/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { TCardSelectGrid } from 'components/Form/CardSelectGrid/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SelectCard: React.FC<TCardSelectGrid.SelectCard.Props> = ({ option, selectionText, onChange, value, textAlign, disabled }) => {
    /***** FUNCTIONS *****/
    function onCardClick() {
        switch (option.type) {
            case RENDER_CARD_SELECT_OPTION_TYPES.CUSTOM:
                return onChange(option.value);
            case RENDER_CARD_SELECT_OPTION_TYPES.IMAGE:
                option?.onSelect?.();
                return onChange(option.url);
            case RENDER_CARD_SELECT_OPTION_TYPES.FONT: {
                return onChange(option.fontID);
            }
            default:
                return;
        }
    }

    function isSelected() {
        switch (option.type) {
            case RENDER_CARD_SELECT_OPTION_TYPES.CUSTOM:
                return option.value === value;
            case RENDER_CARD_SELECT_OPTION_TYPES.IMAGE:
                return option.url === value || option.url === get(value, 'url');
            case RENDER_CARD_SELECT_OPTION_TYPES.FONT:
                return option.fontID === value;
            default:
                return false;
        }
    }

    const selected = isSelected();

    /***** RENDER *****/
    return (
        <NXCard disabled={disabled} border onClick={onCardClick} highlighted={selected}>
            <NXCard.Content>
                <SelectCardContentRenderer option={option} />
            </NXCard.Content>
            <NXCard.Actions>
                <Grid fullWidth columns="auto 1fr" alignItems--center>
                    <CheckListItemCircle size={20} checkSize={12} checked={selected} />{' '}
                    <Text
                        size--s
                        black
                        align--center={textAlign === 'center'}
                        align--left={textAlign === 'left'}
                        align--right={textAlign === 'right'}
                        lead--1
                    >
                        {option?.selectionText || selectionText}
                    </Text>
                </Grid>
            </NXCard.Actions>
        </NXCard>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _SelectCard };
