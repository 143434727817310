/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ticket_name } from 'config/config';
import React, { Component } from 'react';
/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ConfigureError extends Component {
    render() {
        const { history } = this.props;
        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="sslError">
                <div className="sslError__container">
                    <i className="icon icon-alert-triangle"></i>
                    <div className="sslError__description">
                        It looks like we&apos;ve run into an issue while configuring your SSL certificate. This SSL certificate may already be active.
                        If you believe this is incorrect, please submit a support request via the &apos;Submit a new {ticket_name}&apos; button below.
                    </div>
                </div>
                <div className="sslError__action">
                    <SolidButton
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            history.push(`/my-services/ssl`);
                        }}
                    >
                        Back to SSL Certificates
                    </SolidButton>
                    <SolidButton
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            history.push(`/support/tickets/submit`);
                        }}
                    >
                        Submit a new {ticket_name}
                    </SolidButton>
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default ConfigureError;
