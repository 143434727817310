/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Tip from 'components/Tooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxToolTip.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides a tooltip for the NXBox Component. This is positioned absolutely, as such it will be put in the top right of it's closest non-static parent.
 */
function NXToolTip({ info }) {
    /***** RENDER *****/
    return (
        <div className="NXBoxTooltip">
            <Tip info={info} />
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXToolTip.propTypes = {
    /**
     * The string of information you want to display inside the tooltip
     */
    info: PropTypes.string.isRequired
};

export default NXToolTip;
