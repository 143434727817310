/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainMigrateMutation } from '../../queries/mutations';
import { useTppDomainQuery } from '../../queries/useTppDomainQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_tppDomainMigrate.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain migrate module
 */
function TPPDomainMigrate() {
    /***** HOOKS *****/
    const history = useHistory();
    const params = useParams();

    /***** QUERIES *****/
    const { data: tpp_domain_data, status: tpp_domain_status } = useTppDomainQuery(params.id);
    const { mutate: migrateTppDomain, isPending: isMigrateTppDomainPending } = useTppDomainMigrateMutation();

    /***** RENDER HELPERS *****/
    function getInitialStatus() {
        if (tpp_domain_status === 'pending') {
            return 'loading';
        }

        // NOTE: This feature is only supporting .*.au at the moment because it's free to transfer. If we need to support other tld's we need to build some UI to show the pricing before the customer clicks the Migrate button
        if (typeof tpp_domain_data?.attributes?.domain !== 'string' || !tpp_domain_data?.attributes?.domain.endsWith('.au')) {
            return 'error';
        }

        return tpp_domain_status;
    }

    /***** RENDER *****/
    return (
        <NXBox className="tppDomainMigrate__box" initialStatus={getInitialStatus()}>
            <section className="tppDomainMigrate">
                <Text white size--xxl lead--none semiBold className="tppDomainMigrate__title">
                    <h3>Migrate your legacy domain to VentraIP and unlock more management features!</h3>
                </Text>
                <Text white size--s lead--s className="tppDomainMigrate__description">
                    <p>
                        <span>
                            Click the &quot;Migrate Now&quot; button to initiate the migration of this domain into your VentraIP account. We will then
                            send an email to the main email address on your VentraIP account, containing an approval link. You will need to click the
                            approval link to proceed with the migration.
                        </span>
                    </p>
                </Text>
                <SolidButton
                    type="onClick"
                    onClick={() => {
                        migrateTppDomain(
                            { domainName: tpp_domain_data?.attributes?.domain, eppCode: tpp_domain_data?.attributes?.password },
                            {
                                onSuccess: (data) => {
                                    if (data.invoice_id && data.status === 'unpaid') {
                                        history.push(`/billing/invoices/${data.invoice_id}/pay`);
                                        pushNotification({
                                            status: 200,
                                            details:
                                                'Your migration has been initiated. Please pay the invoice to proceed with the migration process.'
                                        });
                                    } else {
                                        pushNotification({
                                            status: 200,
                                            details: 'Your migration has been initiated.'
                                        });
                                    }
                                }
                            }
                        );
                    }}
                    isLoading={isMigrateTppDomainPending}
                >
                    Migrate Now!
                </SolidButton>
            </section>
        </NXBox>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainMigrate;
