/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FONT_META_TYPES, fontMetaTypeValues } from './consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function getFontFamilyByFontMetaData(fontsMetaData, fontStyles) {
    /***** STATE *****/
    const fontFamilyData = {};
    fontMetaTypeValues.forEach((fontType) => {
        const selectedFont = fontStyles?.[fontType] ?? '';
        const fontFamily = fontsMetaData?.[selectedFont]?.font_family ?? '';
        fontFamilyData[fontType] = fontFamily;
    });

    return fontFamilyData;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function getSelectedFontsFromFontsMeta(fontsMetaData, fontStyles) {
    const selectedFonts = Object.values(fontStyles)
        .filter((selectedFontID) => fontsMetaData?.[selectedFontID])
        .map((selectedFontID) => ({
            font: fontsMetaData?.[selectedFontID]?.name ?? ''
        }));

    /***** RENDER *****/
    return selectedFonts;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**
 * @param {KatanaAPI.Katana.Site.ServiceID.Meta.Fonts.GET.FontData} fontData
 */
export const mapFontOptions = ({ name, google_fonts_key, font_family }) => ({
    label: <Text _style={{ fontFamily: font_family }}>{name}</Text>,
    value: google_fonts_key
});

export const fontBodyHeading = [FONT_META_TYPES.BODY, FONT_META_TYPES.HEADING];

export function filterBodyOrHeadingFonts(fonts) {
    return fonts.filter(({ types }) => {
        return fontBodyHeading.some((type) => types.includes(type));
    });
}
