/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CloseAccountForm from '../forms/close';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { closeAccount } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class CloseAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCloseLightbox: false
        };
        this.openCloseLightbox = this.openCloseLightbox.bind(this);
        this.closeCloseLightbox = this.closeCloseLightbox.bind(this);
        this.handleCloseAccount = this.handleCloseAccount.bind(this);
    }

    openCloseLightbox() {
        this.setState({
            showCloseLightbox: true
        });
    }

    closeCloseLightbox() {
        this.setState({
            showCloseLightbox: false
        });
    }

    handleCloseAccount() {
        const { closeAccount } = this.props;
        closeAccount();
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        registerScrollEvents(this, true);
    }

    render() {
        const { account_close_status } = this.props;
        const { showCloseLightbox } = this.state;
        const { handleCloseAccount, openCloseLightbox, closeCloseLightbox } = this;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="cancelAccount"
            >
                <Box
                    premounted={true}
                    className="cancelAccount__box"
                    title="Close Account"
                    desc="Active services must be cancelled prior to closing your account."
                    color="warn"
                    status={account_close_status}
                    action={{
                        label: 'Close Account',
                        type: 'onClick',
                        className: '',
                        color: 'warn',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            openCloseLightbox();
                        }
                    }}
                />
                {showCloseLightbox ? (
                    <OverlayLightbox
                        title="Sorry to see you go!"
                        status={account_close_status}
                        onOpen={showCloseLightbox}
                        onClose={closeCloseLightbox}
                        onSuccessClose={closeCloseLightbox}
                    >
                        <CloseAccountForm handleClose={closeCloseLightbox} onSubmit={handleCloseAccount} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        account_close_status: state.account.account_close_status
    };
};

const mapDispatchToProps = {
    closeAccount
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CloseAccount));
