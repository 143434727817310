/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PasswordChecker from 'components/PasswordChecker';
import { validateDedicatedEmailsRequirements, dedicatedEmailsRequirements } from 'components/PasswordChecker/axigen';

/*   ACTIONS
 *****************************************************/
import { validateConfirmValidation, requiredFieldValidation, RenderField, RenderRevealPasswordField } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const NameAndPasswordFields = ({ passwordInput }) => {
    /***** RENDER *****/
    return (
        <>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="First Name"
                        name="firstname"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="Last Name"
                        name="lastname"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Password"
                        name="password"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateDedicatedEmailsRequirements]}
                        className="form__textfield"
                        parent={this}
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <PasswordChecker input={passwordInput} requirements={dedicatedEmailsRequirements} />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Confirm Password"
                        name="password_confirmation"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateConfirmValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NameAndPasswordFields.propTypes = {
    // password field input to pass to the password checker
    passwordInput: PropTypes.string
};

export default NameAndPasswordFields;
