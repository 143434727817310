/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import HelpBox from 'components/HelpBox';
import DialogNotification from 'components/Notifications/DialogNotification';

/*   ACTIONS
 *****************************************************/
import { validateEmailAddress } from 'containers/email/action';
import { RenderField, domainFieldValidation, emailPrefixValidation, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function EmailAddressFields({
    disableDomain,
    username,
    domain_name,
    emailAddressValidateKey,
    helpboxOpen,

    // Redux props
    email_validate_status,
    email_validate_data,
    email_validate_error,
    validateEmailAddress
}) {
    /***** FUNCTIONS *****/
    const handleValidate = () => validateEmailAddress(`${username}@${domain_name}`, emailAddressValidateKey);

    /***** RENDER HELPERS *****/
    const isVerifyDisabled =
        email_validate_status[emailAddressValidateKey] === 'loading' ||
        !username ||
        emailPrefixValidation(username) ||
        !domain_name ||
        domainFieldValidation(domain_name);

    const renderVerify = () => {
        if (disableDomain) return '';

        if (isVerifyDisabled) {
            return (
                <InactiveButton className="mailboxEmailFields__verify" loading={email_validate_status[emailAddressValidateKey] === 'loading'}>
                    Verify
                </InactiveButton>
            );
        }

        return (
            <SolidButton className="mailboxEmailFields__verify" color="primary" type="onClick" onClick={() => handleValidate()}>
                Verify
            </SolidButton>
        );
    };

    const renderMessage = () => {
        if (email_validate_status[emailAddressValidateKey] === 'error')
            return (
                <DialogNotification type="error" outline>
                    {email_validate_error[emailAddressValidateKey]?.details || 'Something went wrong.'}
                </DialogNotification>
            );

        if (email_validate_data[emailAddressValidateKey] && !email_validate_data[emailAddressValidateKey].owns_domain)
            return (
                <DialogNotification type="warning" outline>
                    We could not find this domain within your {application} account. Please ensure you own or have access to the domain name before
                    continuing.
                </DialogNotification>
            );

        return '';
    };

    /***** RENDER *****/
    return (
        <>
            <div className="form__row mailboxEmailFields">
                <div className="form__column mailboxEmailFields__username">
                    <Field
                        label="Username"
                        name="username"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation, emailPrefixValidation]}
                        onKeyDown={(e) => {
                            if (!isVerifyDisabled && e.key === 'Enter') {
                                handleValidate();
                            }
                        }}
                    />
                </div>
                <p className="mailboxEmailFields__at">@</p>
                <div
                    className={classNames('form__column', 'mailboxEmailFields__domain', { 'mailboxEmailFields__domain--withVerify': !disableDomain })}
                >
                    <Field
                        label="Domain Name"
                        name="domain_name"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation, domainFieldValidation]}
                        disabled={disableDomain}
                        onKeyDown={(e) => {
                            if (!isVerifyDisabled && e.key === 'Enter') {
                                handleValidate();
                            }
                        }}
                    />
                    {renderVerify()}
                    <HelpBox
                        isOpen={helpboxOpen}
                        desc={`Enter the email address for the new mailbox you wish to create. Please do not enter your current email address.${
                            disableDomain ? '' : ' If you change the email address after verifying, it will need to be re-verified.'
                        }`}
                    />
                </div>
            </div>
            {renderMessage()}
        </>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    email_validate_status: state.email.email_validate_status,
    email_validate_data: state.email.email_validate_data,
    email_validate_error: state.email.email_validate_error
});

const mapDispatchToProps = {
    validateEmailAddress
};

EmailAddressFields.propTypes = {
    // Whether or not to disable to "domain" field (should be disabled if it is already prefilled)
    disableDomain: PropTypes.bool,

    // "username" form field value
    username: PropTypes.string,

    // "domain_name" form field value
    domain_name: PropTypes.string,

    // The unique key to use for looking up the email address validation status
    emailAddressValidateKey: PropTypes.string,

    // Whether to show the email address helpbox
    helpboxOpen: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailAddressFields);
