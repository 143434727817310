/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import SolidButton from 'components/Buttons/SolidButton';
import { CheckBox } from 'components/Form/CheckBox';
import { Modal } from 'components/Lightboxes/Modal';
import DialogNotification from 'components/Notifications/DialogNotification';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSearchParams from 'utilities/hooks/useSearchParams';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useDeleteDomainMutation } from 'containers/domain/queries/useDeleteDomainMutation';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_deleteDomainLightbox.scss';

/**********************************************************************************************************
 *   TYPE DECLARATIONS
 **********************************************************************************************************/
type DomainInformationDataState = { domain: { domain_information_data: Artah.Domain.ID.GET._200['data'] } };

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DeleteDomainLightbox: React.FC = () => {
    /***** STATE *****/
    const [isAcknowledgementChecked, setIsAcknowledgementChecked] = useState(false);

    /***** HOOKS *****/
    const { id: domainId } = useParams<{ id: string }>();
    const { searchParamsGet, searchParamsRemove } = useSearchParams();
    const location = useLocation();
    const history = useHistory();

    const domain_information_data = useSelector((state: DomainInformationDataState) => state.domain.domain_information_data);

    /***** QUERIES *****/
    const { mutate: mutateDeleteDomain, isPending: isDeleteDomainMutationPending } = useDeleteDomainMutation();

    /***** FUNCTIONS *****/
    function closeModal() {
        searchParamsRemove('open');
        setIsAcknowledgementChecked(false);
    }

    function handleDeleteDomainClick() {
        mutateDeleteDomain(domainId, {
            onSuccess: () => history.push('/my-services/domains')
        });
    }

    /***** RENDER HELPERS *****/
    const isModalOpen = !!(location.pathname === `/my-services/domains/admin/delete/${domainId}` && searchParamsGet('open') === 'true');

    /***** RENDER *****/
    return (
        <Modal className="deleteDomainLightbox" isOpen={isModalOpen} onClose={closeModal} ariaLabel="Delete Domain">
            <div className="deleteDomainLightbox__content">
                <DialogNotification type="error">
                    You are about to delete {domain_information_data?.attributes?.domain || ''} from your account.
                </DialogNotification>
                <Modal.Title>Delete Domain Name</Modal.Title>
                <div className="deleteDomainLightbox__description">
                    <Text secondary size--s>
                        You are about to delete{' '}
                        <Text bold>
                            <span>{domain_information_data?.attributes?.domain || ''}</span>
                        </Text>
                        . Deleting this domain will immediately stop DNS resolution, which means your website and email hosting will no longer
                        function. The domain will enter a delete sequence and will not be available for re-registration right away.
                    </Text>
                    <Text secondary size--s>
                        Any remaining time on the registration is non-refundable. We recommend allowing the domain to expire naturally if you might
                        need it in the future.
                    </Text>
                </div>
                <AcknowledgementBox title="Confirmation" titleNoPadding className="deleteDomainLightbox__confirmation">
                    <CheckBox
                        disabled={isDeleteDomainMutationPending}
                        isChecked={isAcknowledgementChecked}
                        onChange={() => setIsAcknowledgementChecked((prev) => !prev)}
                    >
                        <Text secondary size--s align--left>
                            I understand canceling my domain name is irreversible and will affect any services currently connected to it.
                        </Text>
                    </CheckBox>
                </AcknowledgementBox>
                <SolidButton
                    type="onClick"
                    isLoading={isDeleteDomainMutationPending}
                    disabled={!isAcknowledgementChecked}
                    color="primary"
                    onClick={handleDeleteDomainClick}
                >
                    Delete Domain Name
                </SolidButton>
                <Modal.BackLink onClick={closeModal}>NO, GO BACK</Modal.BackLink>
            </div>
        </Modal>
    );
};
