import { useMutation } from '@tanstack/react-query';
import { API as EMAIL } from 'utilities/api/email';

/***** GET HALON URL *****/
export function useGetHalonUrlMutation() {
    return useMutation({
        mutationFn: (id: string) => EMAIL.email.get.spamFiltering(id),
        onSuccess: () => {}
    });
}
