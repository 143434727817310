/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxOverviewRow.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides flex styling to its children, and additional styling to an NXBoxInfoPair__title inside of it
 */
function NXBoxOverviewRow({ children }) {
    /***** RENDER *****/
    return <div className="NXBoxOverviewRow">{children}</div>;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxOverviewRow.propTypes = {
    // Row children. Pass multiple siblings to take advantage of the row flexbox styling
    children: PropTypes.node
};

export default NXBoxOverviewRow;
