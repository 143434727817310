/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _DefaultLabel as DefaultLabel } from './DefaultLabel';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CLASS_ccp__select__item } from 'utilities/methods/consts';
import './_CustomDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function _CustomDropdown({ className, label, disabled, render }) {
    /***** STATE *****/
    const [isOpen, setIsOpen] = useState(false);

    /***** HOOKS *****/
    const containerRef = useRef(null);
    const labelRef = useRef(null);

    // Clickaway
    const closeDropdown = (e) => {
        // Handle event type blur from useClickAway
        if (e.type === 'blur') {
            return setIsOpen(false);
        }
        if (e && (e.target.classList.contains(CLASS_ccp__select__item) || e.target.classList.contains('rdtDay'))) return;

        setIsOpen((prev) => {
            // Focus the dropdown label only when it's being closed
            if (prev) {
                labelRef.current?.focus();
            }
            return false;
        });
    };

    useClickAway([containerRef], closeDropdown);

    /***** FUNCTIONS *****/
    function dropdownTitleOnClick() {
        setIsOpen(!isOpen);
    }

    /***** RENDER HELPERS *****/
    const customDropdownClassNames = classNames('CustomDropdown', className, {
        disabled: disabled
    });

    const renderLabel = () => {
        if (typeof label === 'function') return label({ labelRef, dropdownTitleOnClick, disabled });
        if (typeof label === 'string')
            return <DefaultLabel labelRef={labelRef} dropdownTitleOnClick={dropdownTitleOnClick} disabled={disabled} label={label} isOpen={isOpen} />;
        throw new Error('"Label" prop must be of type "string" or "function"!');
    };

    /***** RENDER *****/
    return (
        <div className={customDropdownClassNames} ref={containerRef}>
            {renderLabel()}
            <div className={classNames('CustomDropdown__dropdown', { 'CustomDropdown__dropdown--open': isOpen })}>
                {render(closeDropdown, isOpen)}
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

_CustomDropdown.propTypes = {
    // Optional extra className
    className: PropTypes.string,

    // Label to show when no option has been selected
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    // Whether or no the dropdown should be disabled
    disabled: PropTypes.bool,

    // Function that returns the custom JSX to render inside the dropdown. Takes closeDropdown and isOpen as args
    render: PropTypes.func
};
