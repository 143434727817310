/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__RadioButtonCircle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The RadioButtonCircle component provides a standardized circle that should be used for a RadioButton,
 * This doesn't store any state and accepts isActive to allow the parent to control it's state such as
 * by wrapping it in a button and then using the button's state to control the circle's state.
 * @param {object} props
 * @param {boolean} props.isActive whether the control is currently active
 * @param {RadioButtonCircleColours} [props.color] The color of the circle when active
 * @param {boolean} [props.disabled] Whether the control is currently disabled
 */
function _RadioButtonCircle({ isActive, color, disabled }) {
    /***** RENDER *****/
    return (
        <span
            className={classNames('RadioButtonCircle', {
                [`RadioButtonCircle--${color}`]: color,
                'RadioButtonCircle--selected': isActive,
                'RadioButtonCircle--disabled': disabled
            })}
        />
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _RadioButtonCircle };
