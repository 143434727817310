/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Modal } from 'components/Lightboxes/Modal';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_IconOverview.scss';

const allCCPIcons = [
    'icon-about-awards',
    'icon-about-certs',
    'icon-about-company',
    'icon-about-data-centre',
    'icon-about-our-values',
    'icon-account',
    'icon-accounts',
    'icon-acquisitions',
    'icon-active-sync',
    'icon-address-book',
    'icon-alert-circle',
    'icon-alert-octagon',
    'icon-alert-triangle',
    'icon-amex-outline',
    'icon-apps',
    'icon-archive',
    'icon-arrow-down',
    'icon-arrow-left',
    'icon-arrow-right',
    'icon-arrow-up',
    'icon-arrow',
    'icon-arrow2',
    'icon-at-sign',
    'icon-au',
    'icon-au2',
    'icon-aus-data-centre',
    'icon-aus-owned2',
    'icon-aus-support',
    'icon-backups',
    'icon-balloon',
    'icon-banknote',
    'icon-bell-off',
    'icon-bell',
    'icon-billing-icon',
    'icon-blog-close',
    'icon-blog-facebook',
    'icon-blog-gplus',
    'icon-blog-insta',
    'icon-blog-search',
    'icon-blog-twitter',
    'icon-blog-youtube',
    'icon-bolt',
    'icon-bookmark',
    'icon-boost-resources',
    'icon-browser-compatibility',
    'icon-business-hosting',
    'icon-business-services',
    'icon-caldav',
    'icon-car',
    'icon-car2',
    'icon-card-abandonment',
    'icon-cart',
    'icon-category',
    'icon-check-circle',
    'icon-check-square',
    'icon-check',
    'icon-chevron-down',
    'icon-chevron-left',
    'icon-chevron-right',
    'icon-chevron-up',
    'icon-chevrons-down',
    'icon-chevrons-left',
    'icon-chevrons-right',
    'icon-chevrons-up',
    'icon-choose-os',
    'icon-clipboard',
    'icon-clock',
    'icon-cloud-hardware',
    'icon-cloud-off',
    'icon-cloud',
    'icon-cloudflare',
    'icon-cloudlinux',
    'icon-code',
    'icon-cog',
    'icon-coins_collect',
    'icon-compare',
    'icon-competitor',
    'icon-completely-free',
    'icon-contact-customer-care',
    'icon-contact-sales',
    'icon-contact-support',
    'icon-copy',
    'icon-cpanel',
    'icon-cpu',
    'icon-cpu1',
    'icon-credit-card',
    'icon-cross',
    'icon-custom-sender-name',
    'icon-customer-service',
    'icon-dash-settings',
    'icon-database',
    'icon-day-pass',
    'icon-ddos-protection',
    'icon-delete',
    'icon-delete1',
    'icon-direct-admin path1',
    'icon-direct-admin path2',
    'icon-direct-admin path3',
    'icon-direct-admin path4',
    'icon-direct-admin path5',
    'icon-dns-records',
    'icon-doc',
    'icon-dollar',
    'icon-domain-accredited',
    'icon-domain-names-header',
    'icon-domain-names',
    'icon-domain-pricing',
    'icon-domain-sale',
    'icon-domain-wide-admin',
    'icon-dots-menu',
    'icon-download-cloud',
    'icon-download',
    'icon-drag-drop',
    'icon-ecommerce',
    'icon-edit-2',
    'icon-edit-3',
    'icon-edit',
    'icon-email-hosting',
    'icon-email-web-forwarding',
    'icon-email',
    'icon-email2',
    'icon-encryption',
    'icon-epp',
    'icon-eticket',
    'icon-express-service-manager',
    'icon-external-link',
    'icon-eye-closed',
    'icon-eye-open',
    'icon-facebook',
    'icon-file-minus',
    'icon-file-plus',
    'icon-file-text',
    'icon-folder-minus',
    'icon-folder-plus',
    'icon-folder',
    'icon-general1',
    'icon-general2',
    'icon-general3',
    'icon-general4',
    'icon-general5',
    'icon-gift',
    'icon-giveaway',
    'icon-globe',
    'icon-google-maps',
    'icon-googleplus',
    'icon-grid',
    'icon-group-sms',
    'icon-gsuite',
    'icon-hard-drive',
    'icon-hash',
    'icon-header-acc-alt',
    'icon-header-notif-alt',
    'icon-help-circle',
    'icon-help',
    'icon-home',
    'icon-icon_auda',
    'icon-icon_money-back',
    'icon-icon_sla',
    'icon-Icon-1',
    'icon-icon-business',
    'icon-icon-custom',
    'icon-icon-migrations',
    'icon-icon-wordpress',
    'icon-Icon',
    'icon-id-protection',
    'icon-image',
    'icon-inbox',
    'icon-info',
    'icon-instagram',
    'icon-instagram2',
    'icon-installatron',
    'icon-instant-activation',
    'icon-international',
    'icon-invalid',
    'icon-issue',
    'icon-its-simple',
    'icon-its-stress-free',
    'icon-layers',
    'icon-lifetime-reassurance',
    'icon-lifetime-warranty',
    'icon-lightbox-cross',
    'icon-link-2',
    'icon-link',
    'icon-list',
    'icon-litespeed',
    'icon-live-chat',
    'icon-location',
    'icon-lock',
    'icon-login',
    'icon-logout',
    'icon-mail-filtering',
    'icon-map',
    'icon-mastercard path1',
    'icon-mastercard path2',
    'icon-mastercard path3',
    'icon-mastercard path4',
    'icon-mastercard path5',
    'icon-mastercard-outline',
    'icon-match-service',
    'icon-memory',
    'icon-memory1',
    'icon-message-circle',
    'icon-message-square',
    'icon-microsoft',
    'icon-migrate-website',
    'icon-minus',
    'icon-mobile',
    'icon-money-back',
    'icon-monitor',
    'icon-more-horizontal',
    'icon-more-vertical',
    'icon-multi-part-sms',
    'icon-multi-part-sms2',
    'icon-multiple-websites',
    'icon-no-lock-in-contracts',
    'icon-nz',
    'icon-on-sale-label',
    'icon-online-sms',
    'icon-optional-extras',
    'icon-os-reload',
    'icon-os',
    'icon-other-control-panel',
    'icon-packages',
    'icon-paperclip',
    'icon-pay-invoice',
    'icon-pen',
    'icon-pencil',
    'icon-phone',
    'icon-phone1',
    'icon-php-selector',
    'icon-plesk-logo path1',
    'icon-plesk-logo path2',
    'icon-plesk-logo path3',
    'icon-plesk-logo path4',
    'icon-plesk-logo path5',
    'icon-plesk-logo path6',
    'icon-plus-circle',
    'icon-plus-faq',
    'icon-plus-square',
    'icon-plus',
    'icon-popular',
    'icon-power-management',
    'icon-power',
    'icon-profound-hosting',
    'icon-protect_lock',
    'icon-qr-code',
    'icon-quotes-left',
    'icon-quotes-right',
    'icon-redundant-mx-records',
    'icon-refer-friend',
    'icon-refresh-ccw',
    'icon-refresh-cw',
    'icon-refresh',
    'icon-reload',
    'icon-remote-access',
    'icon-renew-domain',
    'icon-repeat',
    'icon-responsive',
    'icon-rotate-ccw',
    'icon-rotate-cw',
    'icon-rss',
    'icon-save-time-money',
    'icon-scissors',
    'icon-search',
    'icon-secure-hosting',
    'icon-secure-hosting2',
    'icon-secure-mail',
    'icon-send',
    'icon-server',
    'icon-service-status',
    'icon-settings',
    'icon-share-2',
    'icon-share',
    'icon-shield-off',
    'icon-shield',
    'icon-shopping-bag',
    'icon-shopping-cart',
    'icon-site-seal',
    'icon-slider-icon',
    'icon-smile_heart',
    'icon-social-media',
    'icon-spam-filtering',
    'icon-spinner',
    'icon-spinner2',
    'icon-squarespace',
    'icon-ssd-storage',
    'icon-ssd-storage1',
    'icon-ssl',
    'icon-ssl2',
    'icon-star',
    'icon-stars',
    'icon-stock-library',
    'icon-stored-locally',
    'icon-support-getting-started',
    'icon-support-vipcontrol',
    'icon-support-web-access',
    'icon-support',
    'icon-sw-aus-servers',
    'icon-sw-aus-support',
    'icon-sw-choose-services',
    'icon-sw-confirm-services',
    'icon-sw-create-account',
    'icon-sw-create-custom-plans',
    'icon-sw-dns',
    'icon-sw-enterprise-hardware',
    'icon-sw-load-balancing',
    'icon-sw-low-contention',
    'icon-sw-migration-dns',
    'icon-sw-offsite-backups',
    'icon-sw-review-costa',
    'icon-sw-select-services',
    'icon-sw-stars',
    'icon-sw-success',
    'icon-sw-uptime-sla',
    'icon-sw-use-credit',
    'icon-sw-whmcs-api',
    'icon-sw-you-are-here',
    'icon-switch-arrow',
    'icon-tag',
    'icon-tail',
    'icon-tell-us',
    'icon-thumbs-down',
    'icon-thumbs-up',
    'icon-tick-solid',
    'icon-tick',
    'icon-transfer-domain',
    'icon-trash-2',
    'icon-trash',
    'icon-trusted',
    'icon-twitter',
    'icon-unlimited-pages',
    'icon-unlock',
    'icon-upload-cloud',
    'icon-upload',
    'icon-uptime',
    'icon-user-check',
    'icon-user-minus',
    'icon-user-plus',
    'icon-user-x',
    'icon-user',
    'icon-users',
    'icon-valid',
    'icon-view-cart',
    'icon-vipbuilder',
    'icon-visa-outline',
    'icon-vps-fully-managed',
    'icon-vps-self-managed',
    'icon-vps',
    'icon-web-hosting',
    'icon-webdav-storage',
    'icon-whm-multi',
    'icon-wholesale-network',
    'icon-wordpress',
    'icon-wrench',
    'icon-x-circle',
    'icon-x-square',
    'icon-x',
    'icon-years',
    'icon-youtube',
    'icon-zadmin',
    'icon-zoom-in',
    'icon-zoom-out',
    'icon-zuver'
];

function DEV_IconOverview() {
    /***** HOOKS *****/
    const history = useHistory();

    /***** RENDER *****/
    return (
        <Modal
            isOpen
            onClose={() => {
                history.push('/dashboard');
            }}
        >
            <div className="IconOverview">
                {allCCPIcons.map((icon) => {
                    return (
                        <div
                            key={icon}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                zIndex: '9999999999',
                                backgroundColor: 'white',
                                width: '100%;',
                                height: '100%',
                                justifySelf: 'stretch',
                                textAlign: 'center'
                            }}
                        >
                            <i className={`icon ${icon}`} style={{ fontSize: '24px' }} />
                            {icon}
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
}

export default DEV_IconOverview;
