/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NCheckBox } from 'components/Form/CheckBox/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CheckBoxCircle: React.FC<Omit<NCheckBox.Props, 'type'>> = ({ ...props }) => <CheckBox {...props} type="circle" />;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
