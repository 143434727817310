/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import htmr from 'htmr';
import { activeProducts } from 'config/config';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class UpgradeConfirm extends Component {
    render() {
        const { handleSubmit, handleBack, closeLightbox, selectedMeta, selectedData } = this.props;

        const renderReviewRows = (data) => {
            const { name, details, image } = selectedData;

            function mapReviewListItems(feature, index) {
                const regex = /\d/g;
                const result = regex.test(feature);
                if (result) {
                    const split = feature.split(' ');
                    const highlighted = split.shift();
                    const featureText = split.join(' ');
                    return `<li key="feature-${index}" className="product__feature"><span className="highlight">${highlighted}</span> ${featureText.toString()}</li>`;
                }
                return `<li key="feature-${index}" className="product__feature--full">${feature.toString()}</li>`;
            }

            const tooltipInfo = `<ul>
                ${details?.map(mapReviewListItems)}
            </ul>`.replace(/,/g, '');

            return data.map((row, index) => {
                return (
                    <div key={index} className="upgradeConfirm__row">
                        <div className="upgradeConfirm__column title">{htmr(row.title)}</div>
                        <div className="upgradeConfirm__column info">
                            {activeProducts.list.includes(row.value.toLowerCase()) ? <img src={image} alt={name} /> : ''}
                            {row.value}
                            {activeProducts.list.includes(row.value.toLowerCase()) ? <Tooltip info={tooltipInfo} /> : ''}
                        </div>
                    </div>
                );
            });
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="upgradeConfirm__container" onSubmit={handleSubmit}>
                <Anchor className="upgradeConfirm__revise" onClick={handleBack}>
                    Revise
                </Anchor>
                <div className="upgradeConfirm__wrapper">{renderReviewRows(selectedMeta)}</div>
                <div className="upgradeConfirm__overview">
                    <div className="upgradeConfirm__total title">TOTAL UPGRADE COST</div>
                    <div className="upgradeConfirm__total cost">
                        {selectedData.total}
                        <span className="cost__subtext">AUD</span>
                    </div>
                </div>
                <SolidButton className="button" type="submit">
                    Confirm Upgrade
                </SolidButton>
                <Anchor className="commonlink" onClick={closeLightbox}>
                    CANCEL UPGRADE
                </Anchor>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
UpgradeConfirm = reduxForm({
    form: 'UpgradeConfirm',
    enableReinitialize: false
})(UpgradeConfirm);

export default connect((state) => ({
    hosting_upgrade_list_status: state.hosting.hosting_upgrade_list_status,
    hosting_upgrade_list_data: state.hosting.hosting_upgrade_list_data,
    hosting_upgrade_list_error: state.hosting.hosting_upgrade_list_error,
    hosting_upgrade_status: state.hosting.hosting_upgrade_status
}))(UpgradeConfirm);
