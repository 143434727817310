/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ActiveSync from './modules/activeSync';
import Alias from './modules/aliases';
import BillingCycle from './modules/billingCycle';
import ServiceDetails from './modules/details';
import DiskBlock from './modules/diskBlocks';
import Mailboxes from './modules/mailboxes';
import AddMailboxes from './modules/mailboxesAdd';
import Overview from './modules/overview';
import Upgrade from './modules/upgrade';
import Admin from './sections/admin';
import General from './sections/general';
import Tools from './sections/tools';
import { SpamFilteringLog } from './modules/spamFilteringLog';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import Cancel from 'components/Cancel';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import PageNotFound from 'components/Errors/PageNotFound';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar';
import Subnav from 'components/Subnav';
import Title from 'components/Title';

/*   ACTIONS
 *****************************************************/
import { getEmailMailboxInformation, mailboxLogin, resetEmailState } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';
import { handleSideMenuOptions, isURLValid } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_email.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function Email() {
    /***** HOOKS *****/
    const { subpage, section, id: domainGroupId, mailbox } = useParams();
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();

    const email_mailbox_list_status = useSelector((state) => state.email.email_mailbox_list_status);
    const email_mailbox_list_error = useSelector((state) => state.email.email_mailbox_list_error);
    const email_information_mailbox_data = useSelector((state) => state.email.email_information_mailbox_data);
    const email_diskblock_list_data = useSelector((state) => state.email.email_diskblock_list_data);
    const email_domain_group_details_data = useSelector((state) => state.email.email_domain_group_details_data);
    const app_viewport = useSelector((state) => state.app.app_viewport);

    /***** EFFECTS *****/
    useEffect(() => {
        if (mailbox) {
            dispatch(getEmailMailboxInformation(mailbox));
        }
    }, [mailbox]);

    useEffectAfterMount(() => {
        if (email_mailbox_list_status === 'error' && email_mailbox_list_error?.status === 403) {
            history.push(redirectUrls.email_hosting);
        }
    }, [email_mailbox_list_status, email_mailbox_list_error]);

    /***** RENDER HELPERS *****/
    const renderTitle = () => {
        // If its an individual mailbox
        if (mailbox) {
            const { attributes, included, type } = email_information_mailbox_data || {};
            const { domain } = attributes || {};

            const product = getIncludedObjBasedOnType(included, 'product');
            const baseDomain = domain?.split('@')[1] ?? '';

            return (
                <Title
                    serviceTitle={baseDomain}
                    serviceSubTitle={product?.attributes?.name || ''}
                    serviceType={type}
                    useSubtitle={true}
                    subtitleData={{
                        title: (
                            <div className="emailHosting__title">
                                <div className="service">{domain || ''}</div>
                            </div>
                        ),
                        action: {
                            label: 'Login to Mailbox',
                            onClick: () => {
                                dispatch(mailboxLogin(mailbox));
                            }
                        }
                    }}
                    action={[
                        {
                            className: 'listAccount__action',
                            label: 'List Mailboxes',
                            type: 'onClick',
                            color: 'secondary',
                            onClick: (e) => {
                                e.preventDefault();
                                dispatch(resetEmailState());
                                history.push(`/my-services/email-hosting/general/mailboxes/${domainGroupId}`);
                            }
                        },
                        <CustomDropdown
                            className="TitleShared__singleDropdown"
                            label={({ labelRef, dropdownTitleOnClick }) => (
                                <button
                                    className="TitleShared__singleDropdownButton"
                                    ref={labelRef}
                                    type="button"
                                    onClick={() => dropdownTitleOnClick()}
                                >
                                    Email Hosting Help
                                    <i className="item__icon icon icon-chevron-down" />
                                </button>
                            )}
                            key="dropdown"
                            render={() => (
                                <SelectOptions
                                    options={[
                                        {
                                            label: 'Email Hosting Help Articles',
                                            onSelect: () => history.push('/support/support-centre/email-hosting')
                                        },
                                        {
                                            label: 'Submit a Technical Support eTicket for this Service',
                                            onSelect: () =>
                                                history.push(
                                                    getTicketSubmitUrl({
                                                        department: 'technical-support',
                                                        service: mailbox
                                                    })
                                                )
                                        },
                                        {
                                            label: 'Submit a Billing eTicket for this Service',
                                            onSelect: () =>
                                                history.push(
                                                    getTicketSubmitUrl({
                                                        department: 'accounts-billing',
                                                        service: mailbox
                                                    })
                                                )
                                        }
                                    ]}
                                />
                            )}
                        />
                    ]}
                />
            );
        }

        // If it's the mailbox list page
        return (
            <Title
                serviceTitle={email_domain_group_details_data?.attributes?.domain || ''}
                serviceType={email_domain_group_details_data?.type}
                action={
                    <OutlineButton className="TitleShared__dropdown medium" color="primary" to="/support/support-centre/email-hosting">
                        Email Hosting Help
                    </OutlineButton>
                }
            />
        );
    };

    let domain = '';

    if (email_information_mailbox_data?.attributes?.domain) {
        // If we're on the single mailbox page, get the domain from the single mailbox data. In this data the "domain" is the full email address, so it has to be split
        domain = email_information_mailbox_data.attributes.domain?.split('@')[1];
    } else if (email_domain_group_details_data) {
        // else if we're on the mailboxes list page, get the data from the service detail data (group of mailboxes under one domain). Here the "domain" is only the domain
        domain = email_domain_group_details_data?.attributes?.domain || '';
    }

    let topTierPlan;

    if (email_information_mailbox_data) {
        const { included } = email_information_mailbox_data;
        const product = getIncludedObjBasedOnType(included, 'product');
        topTierPlan = product.attributes.name.endsWith('PLUS');
    }

    const modules = {
        general: [
            {
                sidebarLink: {
                    label: 'Mailboxes',
                    link: `/my-services/email-hosting/general/mailboxes/${domainGroupId}`
                },
                component: <Mailboxes emailid={domainGroupId} domain={domain} />,
                condition: !mailbox,
                identifier: 'email/mailboxes'
            },
            {
                sidebarLink: {
                    label: 'Add Mailboxes',
                    link: `/my-services/email-hosting/general/add-mailbox/${domainGroupId}`
                },
                component: <AddMailboxes emailid={domainGroupId} domain={domain} />,
                condition: !mailbox,
                identifier: 'email/add-mailboxes'
            },
            {
                sidebarLink: {
                    label: 'Overview',
                    link: `/my-services/email-hosting/mailbox/overview/${domainGroupId}/mailbox/${mailbox}`
                },
                component: <Overview domain={domain} />,
                condition: mailbox,
                identifier: 'email/overview'
            },
            {
                sidebarLink: {
                    label: 'Service Details',
                    link: mailbox
                        ? `/my-services/email-hosting/mailbox/details/${domainGroupId}/mailbox/${mailbox}`
                        : `/my-services/email-hosting/general/details/${domainGroupId}`
                },
                component: <ServiceDetails serviceid={mailbox || domainGroupId} domain={domain ?? null} />,
                condition: true,
                identifier: 'email/service-details'
            },
            {
                sidebarLink: {
                    label: email_diskblock_list_data ? 'Disk Blocks' : null,
                    link: `/my-services/email-hosting/mailbox/diskblocks/${domainGroupId}/mailbox/${mailbox}`
                },
                component: <DiskBlock emailid={domainGroupId} mailbox={mailbox} />,
                condition: mailbox,
                identifier: 'email/disk-blocks'
            },
            {
                sidebarLink: {
                    label: 'Upgrade Plan',
                    link: `/my-services/email-hosting/mailbox/upgrade/${domainGroupId}/mailbox/${mailbox}`
                },
                component: <Upgrade emailid={domainGroupId} mailbox={mailbox} />,
                condition: mailbox && email_information_mailbox_data && !topTierPlan,
                identifier: 'email/upgrade-plan'
            },
            {
                sidebarLink: {
                    label: 'Billing Cycle',
                    link: `/my-services/email-hosting/mailbox/billing/${domainGroupId}/mailbox/${mailbox}`
                },
                component: <BillingCycle />,
                condition: mailbox && email_information_mailbox_data && email_information_mailbox_data,
                identifier: 'email/billing-cycle'
            }
        ],
        tools: [
            {
                sidebarLink: {
                    label: 'Manage Aliases',
                    link: `/my-services/email-hosting/tools/aliases/${domainGroupId}`
                },
                component: <Alias />,
                condition: !mailbox,
                identifier: 'email/manage-aliases'
            },
            {
                sidebarLink: {
                    label: 'Email Spam Filtering Log',
                    link: `/my-services/email-hosting/tools/spam-filtering-log/${domainGroupId}`
                },
                component: <SpamFilteringLog />,
                condition: !mailbox,
                identifier: 'email/email-spam-filtering-log'
            }
        ],
        admin: [
            {
                sidebarLink: {
                    label: 'ActiveSync',
                    link: `/my-services/email-hosting/admin/activesync/${domainGroupId}/mailbox/${mailbox}`
                },
                component: <ActiveSync domain={domain} mailbox={mailbox} />,
                condition: mailbox,
                identifier: 'email/active-sync'
            },
            {
                sidebarLink: {
                    label: 'Delete Mailbox',
                    link: `/my-services/email-hosting/admin/delete/${domainGroupId}/mailbox/${mailbox}`
                },
                component: (
                    <Cancel
                        title="Delete Mailbox"
                        desc="If you no longer need this mailbox, you can remove it from your account."
                        label="Cancel Service"
                        cancel={{
                            id: parseInt(mailbox),
                            service: 'emailHosting'
                        }}
                    />
                ),
                condition: mailbox,
                identifier: 'email/delete-mailbox'
            }
        ]
    };

    const renderSidemenu = () => {
        const generalList = {
            list_title: 'General',
            list_icon: 'accounts',
            list_items: handleSideMenuOptions(modules, 'general')
        };

        const toolsList = {
            list_title: 'Tools',
            list_icon: 'settings',
            list_items: handleSideMenuOptions(modules, 'tools')
        };

        const adminList = {
            list_title: 'Admin',
            list_icon: 'settings',
            list_items: handleSideMenuOptions(modules, 'admin')
        };

        return <Sidebar links={[generalList, toolsList, adminList]} />;
    };

    const renderSection = () => {
        switch (subpage) {
            case 'tools':
                return <Tools modules={modules.tools} />;
            case 'admin':
                return <Admin modules={modules.admin} />;
            case 'general':
            default:
                return <General modules={modules.general} />;
        }
    };

    const handleRenderState = () => {
        if (isURLValid(modules, location.pathname)) {
            return (
                <Fragment>
                    {renderTitle()}
                    {app_viewport === 'sm' || app_viewport === 'xs' ? renderSidemenu() : ''}

                    <Page
                        className="emailHosting"
                        sidebar={app_viewport === 'md' || app_viewport === 'lg' || app_viewport === 'xl' ? renderSidemenu() : ''}
                    >
                        {renderSection()}
                    </Page>
                </Fragment>
            );
        }

        return <PageNotFound />;
    };

    /***** RENDER *****/
    return (
        <Fragment>
            <Subnav pathname={location.pathname} />
            {handleRenderState()}
        </Fragment>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Email;
