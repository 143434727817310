/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/support';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';

/*********************************************************************************************************
 *   TYPE DEFINITIONS
 *********************************************************************************************************/
/**
 * @typedef {API['support']['POST']['tickets']['reply']} TEndpoint
 */

/**********************************************************************************************************
 * HOOK START
 **********************************************************************************************************/
/**
 * Submit an eTicket reply
 *
 * @type {NXQuery.TMutation<TEndpoint>}
 */
const useReplyEticketMutation = (options) => {
    return useMutation({
        mutationFn: API.support.POST.tickets.reply,
        onError: handleDefaultErrorNotification,
        ...options
    });
};

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useReplyEticketMutation };
