import { _CancelServiceFormReduxFormDefault } from 'containers/services/forms/cancelServiceForm/reduxform/default';

// This component does the same thing as the component in "components/Cancel", but this one is just a more lightweight version. As some point we should address the duplicate, as "components/Cancel" is a bit of a legacy component and is not very flexible in the way it is built. It would be good to get rid of it in favour of something more modular.

const CancelServiceForm = Object.assign(
    {},
    {
        ReduxForm: {
            Default: _CancelServiceFormReduxFormDefault
        }
    }
);

export { CancelServiceForm };
