/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { modifyPropertyKeys } from 'containers/katana/formFields/methods/modifyPropertyKeys';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 *
 * @param {*} member
 * @param {Katana.SectionDefinitions.PropertiesModified[]} properties
 * @param {boolean} [isFieldArrayMember]
 * @returns {Katana.SectionDefinitions.PropertiesModified[]}
 */
export function useModifiedFieldArrayProperties(member, properties, isFieldArrayMember) {
    /***** STATE *****/
    const [modifiedProperties, setModifiedProperties] = useState([]);

    /***** EFFECTS *****/
    useEffect(() => {
        const clonedModifiedProperties = modifyPropertyKeys(member, properties, isFieldArrayMember);
        setModifiedProperties(clonedModifiedProperties);
    }, [member, properties, isFieldArrayMember]);

    /***** RENDER *****/
    return modifiedProperties;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
