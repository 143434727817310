/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getCaveat } from 'containers/katana/formFields/methods/getCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentOptionCardNamespace } from 'containers/katana/components/contentOptionCard/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useMapSectionDefinitionToOptionsCardData(
    serviceID: number,
    definitionKeys: ContentOptionCardNamespace.Props['sectionDefinitionID'][]
) {
    /***** QUERIES *****/
    const { data: get_katana_section_definitions_ordered_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(serviceID);

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        if (!get_katana_section_definitions_ordered_data) {
            return [];
        }

        return definitionKeys.map((sectionID) => {
            const { thumbnail, description, name, caveats } = get_katana_section_definitions_ordered_data[sectionID];

            /***** RENDER HELPERS *****/
            const tooltipMessage = getCaveat(CaveatTypes.TOOL_TIP, caveats) as string | undefined;

            const contentOptionData: ContentOptionCardNamespace.Props = {
                cardImageURL: thumbnail,
                description,
                name,
                sectionDefinitionID: sectionID,
                tooltipMessage
            };

            return contentOptionData;
        });
    }, [definitionKeys]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
