/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { LiveChatWidget } from '@livechat/widget-react';
import * as Sentry from '@sentry/react';
import { application, company, serviceNav } from 'config/config';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';
import Header from 'components/Header';
import { logoutApp } from 'components/Header/action';
import AnimatedSwitch from 'components/Transition/Switch/AnimationSwitch';
import { removeUrlParams } from 'containers/login/action';

/*   Containers
 *****************************************************/
import Account from 'containers/account';
import AuDirectContestedApplications from 'containers/auDirect/sections/contestedApplications';
import Billing from 'containers/billing';
import Dashboard from 'containers/dashboard';
import PostLoginChecklist from 'containers/dashboard/Components/postLoginChecklist';
import Domains from 'containers/domain';
import TPPDomain from 'containers/domain/tppDomains';
import Email from 'containers/email';
import { GSuite } from 'containers/gsuite';
import Hosting from 'containers/hosting';
import { AuthorizeInstagram } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/AuthorizeInstagram';
import { KatanaMain } from 'containers/katana/main';
import Ms365 from 'containers/ms365';
import MyServices from 'containers/services';
import Ssl from 'containers/ssl';
import Support from 'containers/support';
import { VentraSynMigration } from 'containers/ventraSynMigration';
import VipRewards from 'containers/vipRewards';
import Vps from 'containers/vps';

/*   ACTIONS
 *****************************************************/
import { changeLivechatVisibility, livechatVisibilityOptions } from 'App/action';
import { isTicketSubmitUrlValid } from 'containers/support/methods';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getPageTitle } from 'router/user/methods';
import useSearchParams from 'utilities/hooks/useSearchParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/***** MEGAMAY24 START *****/
import { MegaMaySpinBanner } from 'config/containers/promotions/megamay2024/components/spinBanner';
import { activePromotion } from 'utilities/methods/commonActions';
/***** MEGAMAY24 END *****/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_user.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let UserArea = (props) => {
    const { login_url_params, removeUrlParams, changeLivechatVisibility, logoutApp } = props;

    /***** STATE *****/
    const [route, setRoute] = useState('');
    const location = useLocation();

    /***** HOOKS *****/
    const savedURLRef = useRef(null);
    const { searchParamsGet } = useSearchParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** EFFECTS *****/
    useEffect(() => {
        savedURLRef.current = login_url_params?.ref;
        removeUrlParams();

        changeLivechatVisibility(livechatVisibilityOptions.MINIMIZED);
    }, []);

    /***** RENDER HELPERS *****/
    const sentryStartedRef = useRef(false);

    async function onRouteChanged(location) {
        // Push location changes to console and update page title
        const newPageTitle = getPageTitle(location);
        if (newPageTitle) {
            document.title = newPageTitle;
        }
        setRoute(location);

        try {
            if (location.includes(serviceNav.VIPsites.link)) {
                if (!sentryStartedRef.current) {
                    Sentry.setTag('katana', 'true');
                    const replay = Sentry.getReplay();
                    replay.start();
                    sentryStartedRef.current = true;
                }
            } else {
                if (sentryStartedRef.current) {
                    await Sentry.getReplay().stop();
                    Sentry.setTag('katana', '');
                    sentryStartedRef.current = false;
                }
            }
        } catch (error) {
            //
        }
    }

    function renderSupportRoutes(props) {
        const { match, location } = props;
        const { search } = location;
        const { params } = match;
        const { subpage, section, param4: department, param5 } = params;

        //redirect
        if (!subpage) {
            return <Redirect to="/support/support-centre" />;
        }

        // Redirect back to tickets list if trying to "view" a ticket but no mask is provided
        if (subpage === 'tickets' && section === 'view' && !department) {
            return <Redirect to="/support/tickets" />;
        }

        if (section === 'submit') {
            // Make sure only valid URL's can be navigated to, within the "submit" flow, and redirect if necessary search params are missing
            if (department === 'customer-care' && !param5) {
                return <Redirect to="/support/tickets/submit/customer-care/feedback?invoice=na&service=na" />;
            }

            if (department === 'customer-care' && !search) {
                return <Redirect to={`/support/tickets/submit/customer-care/${param5}?invoice=na&service=na`} />;
            }

            if (department === 'sales' && !search) {
                return <Redirect to="/support/tickets/submit/sales?invoice=na" />;
            }

            if (department === 'technical-support' && !search) {
                return <Redirect to="/support/tickets/submit/technical-support?invoice=na&service=select" />;
            }

            if (!isTicketSubmitUrlValid(location, match)) {
                // When someone clicks "submit an eticket" from the website, it adds on some search params for GA. We need to strip those off
                if (location.pathname === '/support/tickets/submit' && location.search) {
                    return <Redirect to="/support/tickets/submit" />;
                }

                return <Route component={PageNotFound} />;
            }
        }

        return <Route exact path="/support/:subpage?/:section?/:param4?/:param5?" component={Support} />;
    }

    function renderServiceRoutes() {
        return React.Children.toArray([
            <Redirect exact from="/my-services" to="/my-services/domains" />,
            <Route exact path="/my-services/:service" component={MyServices} />,
            <Route exact path="/my-services/:service/bulk/:section?" component={MyServices} />,
            <Route exact path="/my-services/domains/:subpage/:section/:id" component={Domains} />,
            <Route exact path="/my-services/domains/direct-au-applications/" component={AuDirectContestedApplications} />,
            <Route exact path="/my-services/domains/legacy/:subpage/:section/:id/" component={TPPDomain} />,
            <Route exact path="/my-services/hosting/:subpage/:section/:id" component={Hosting} />,
            <Route exact path="/my-services/hosting/:subpage/:section/:id/subaccount/:subaccount" component={Hosting} />,
            <Route exact path="/my-services/email-hosting/:subpage/:section/:id" component={Email} />,
            <Route exact path="/my-services/email-hosting/:subpage/:section/:id/mailbox/:mailbox" component={Email} />,
            <Route path={serviceNav.VIPsites.link} component={KatanaMain} />,
            <Route exact path="/my-services/vps/:subpage/:section/:id" component={Vps} />,
            <Route exact path="/my-services/google/:subpage/:id" component={GSuite} />,
            <Route exact path="/my-services/google/:subpage/:section/:id" component={GSuite} />,
            <Route exact path="/my-services/google/:subpage/:section/:id/mailbox/:mailbox/:subsection" component={GSuite} />,
            <Route exact path="/my-services/microsoft-365/:subpage/:section/:id" component={Ms365} />,
            <Route exact path="/my-services/ssl/:subpage/:section/:id" component={Ssl} />
        ]);
    }

    function renderBillingRoutes() {
        return React.Children.toArray([
            <Redirect exact from="/billing" to="/billing/payment-methods" />,
            <Route exact path="/billing/:section/:id?/:action?" component={Billing} />
        ]);
    }

    function renderAccountRoutes() {
        const redirectToAccountSubpageAndSection = ({
            match: {
                params: { subpage, section }
            }
        }) => <Redirect to={`/account/${subpage}/${section}`} />;

        return React.Children.toArray([
            <Redirect exact from="/account" to="/account/general/overview" />,
            <Redirect exact from="/activate/account/:token" to="/account/general/overview" />,
            <Route exact path="/account/:subpage/:section/:id" render={redirectToAccountSubpageAndSection} />,
            <Route exact path="/account/:subpage/:section" component={Account} />
        ]);
    }

    function synergyMigrationRoutes() {
        const subpages = ['more-info', 'select-services', 'confirm-migration', 'create-account', 'success'];
        const render = ({
            match: {
                params: { subpage }
            }
        }) =>
            subpage && subpages.includes(subpage) ? (
                <VentraSynMigration subpage={subpage} />
            ) : (
                <Redirect to="/synergy-reseller-migration/more-info" />
            );

        return <Route exact path="/synergy-reseller-migration/:subpage?" render={render} />;
    }

    function renderVipRewardsRoutes() {
        return <Route path="/vip-rewards" component={VipRewards} />;
    }

    function processURL() {
        const refRedirect = searchParamsGet('ref');
        const redirectValue = savedURLRef.current || refRedirect;
        if (redirectValue) {
            return <Redirect to={redirectValue} />;
        }

        return <Redirect to="/dashboard" component={Dashboard} />;
    }
    function renderLiveChat() {
        const { app_user_data, app_check_token_data, app_livechat_visibility, changeLivechatVisibility } = props;

        // Do not render live chat if the user is on the VPN or in a dev environment
        if (app_check_token_data?.is_vpn || company !== 'VentraIP Australia' || import.meta.env.VITE_CCP_ENVIRONMENT === 'development') {
            return '';
        }

        // Do not render live chat if the user is on a VIPsites route AND on a mobile device
        if (location.pathname.includes(serviceNav.VIPsites.link) && isMobile) {
            return '';
        }

        const visitor = {
            name: `${app_user_data.firstname} ${app_user_data.lastname}`,
            email: app_user_data.email
        };

        const params = {
            Company: company,
            Location: application
        };

        // Render live chat minimized if user is on a VIPsites route ELSE render as usual
        const visibility = location.pathname.includes(serviceNav.VIPsites.link)
            ? livechatVisibilityOptions.MINIMIZED.visibility
            : app_livechat_visibility;

        return (
            <LiveChatWidget
                license="7094961"
                customerName={visitor.name}
                customerEmail={visitor.email}
                sessionVariables={params}
                visibility={visibility}
                onVisibilityChanged={changeLivechatVisibility}
            />
        );
    }

    /**********************************************************************************************************
     *   CUSTOM STYLING
     **********************************************************************************************************/

    const animatedSwitchAnim = {
        atEnter: {
            opacity: 0
        },
        atLeave: {
            opacity: 0
        },
        atActive: {
            opacity: 1
        }
    };

    const redirectToBillingSection = ({
        match: {
            params: { section, id }
        }
    }) => <Redirect to={`/billing/${section}/${id}`} />;

    const redirectToDashboard = () => (
        <Redirect
            to={{
                pathname: '/dashboard',
                state: { redirect: true }
            }}
        />
    );

    const interceptRouteChange = (callback) => {
        return ({ location: { pathname } }) => {
            if (route !== pathname) {
                onRouteChanged(pathname);
            }

            return callback();
        };
    };

    /***** RENDER *****/
    return (
        <div className="UserLayout">
            {/* MEGAMAY24 START */}
            {activePromotion('megamay2024') ? <MegaMaySpinBanner /> : ''}
            {/* MEGAMAY24 END */}
            <Header />
            <Route
                render={interceptRouteChange(() => (
                    <AnimatedSwitch
                        atEnter={animatedSwitchAnim.atEnter}
                        atLeave={animatedSwitchAnim.atLeave}
                        atActive={animatedSwitchAnim.atActive}
                        className="animatedSwitch animatedSwitch__userDynamic"
                    >
                        {/********************* KATANA REDIRECT URLS *********************/}
                        <Route path="/katana/authorize/instagram" component={AuthorizeInstagram} />

                        {/********************* PASSWORD REDIRECTS *********************/}
                        <Route path="/reset-password" render={() => void logoutApp()} />
                        <Route path="/reset-password/:token" render={() => void logoutApp()} />

                        {/********************* REDIRECTS FOR BOOKMARKS *********************/}
                        <Redirect exact from="/home" to="/dashboard" />
                        <Redirect exact from="/home/hosting/" to="/my-services/hosting" />
                        <Redirect exact from="/home/domain/" to="/my-services/domains" />
                        <Redirect exact from="/home/account/emails/" to="/account/emails/view" />
                        <Route exact path="/account/billing/:section/:id" render={redirectToBillingSection} />

                        {/********************* LOGIN ROUTES *********************/}
                        <Route exact path="/" render={processURL} />
                        <Route exact path="/login" render={processURL} />
                        <Route exact path="/staff-login/:token" render={processURL} />

                        {/********************* DASHBOARD ROUTE *********************/}
                        <Route exact path="/dashboard" component={Dashboard} />

                        {/********************* REFERRAL ROUTE *********************/}
                        <Route exact path="/refer" render={redirectToDashboard} />

                        {/********************* LEGACY SUPPORT ROUTES *********************/}
                        <Redirect exact path="/support/eTickets" to="/support/tickets" />

                        {/********************* SUPPORT ROUTES *********************/}
                        <Route exact path="/support/:subpage?/:section?/:param4?/:param5?" render={renderSupportRoutes} />

                        {/********************* ACCOUNT ROUTES *********************/}
                        {renderAccountRoutes()}

                        {/********************* BILLING ROUTES *********************/}
                        {renderBillingRoutes()}

                        {/********************* SERVICES ROUTES *********************/}
                        {renderServiceRoutes()}

                        {/********************* VENTRA SYNERGY MIGRATION ROUTES *********************/}
                        {synergyMigrationRoutes()}

                        {/********************* VIP REWARDS ROUTES *********************/}
                        {renderVipRewardsRoutes()}

                        {/********************* 404 ROUTE *********************/}
                        <Route component={PageNotFound} />
                    </AnimatedSwitch>
                ))}
            />
            {renderLiveChat()}
            <PostLoginChecklist />
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    login_url_params: state.login.login_url_params,
    app_check_token_data: state.app.app_check_token_data,
    app_user_data: state.app.app_user_data,
    app_livechat_visibility: state.app.app_livechat_visibility
});

const mapDispatchToProps = {
    logoutApp,
    removeUrlParams,
    changeLivechatVisibility
};

UserArea = connect(mapStateToProps, mapDispatchToProps)(UserArea);

export default UserArea;
