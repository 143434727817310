/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__CheckBoxListItem.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 *      onChange?: (isChecked: boolean) => void;
 *      isChecked?: boolean;
 *      className?: string;
 *      intrinsic?: React.HTMLProps<HTMLButtonElement>
 * }>}
 */
const _CheckBoxListItem = ({ children, onChange, isChecked = false, className, intrinsic }) => {
    /***** RENDER *****/
    return (
        <CheckBox className={classNames('CheckBoxListItem', className)} isChecked={isChecked} onChange={onChange} intrinsic={intrinsic}>
            <Text size--s align--left>
                {children}
            </Text>
        </CheckBox>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _CheckBoxListItem };
