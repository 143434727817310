/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { deleteGSuiteUser } from '../action';
/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import DeleteForm from '../forms/userDelete';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteDelete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteLightbox: false
        };
        this.toggleDeleteLightbox = this.toggleDeleteLightbox.bind(this);
        this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
    }

    toggleDeleteLightbox() {
        const { showDeleteLightbox } = this.state;

        this.setState({
            showDeleteLightbox: !showDeleteLightbox
        });
    }

    handleDeleteSubmit() {
        const { gsuiteid, mailbox, deleteGSuiteUser, history } = this.props;
        deleteGSuiteUser(gsuiteid, mailbox, history);
    }

    componentDidUpdate(prevProps) {
        const { gsuite_service_status } = this.props;
        registerScrollEvents(this, gsuite_service_status === 'success' && prevProps.gsuite_service_status === 'loading');
    }

    render() {
        const { gsuite_user_delete_status } = this.props;
        const { showDeleteLightbox } = this.state;
        const { handleDeleteSubmit, toggleDeleteLightbox } = this;

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="gsuiteDeleteUser"
            >
                <Box
                    premounted={true}
                    className="gsuiteDeleteUser__box"
                    title="Delete User"
                    desc={`If you don't need this user account anymore, you can delete it.`}
                    color="warn"
                    action={{
                        label: `Delete User`,
                        type: 'onClick',
                        className: '',
                        color: 'warn',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            toggleDeleteLightbox();
                        }
                    }}
                />
                {showDeleteLightbox ? (
                    <OverlayLightbox
                        title="Delete User"
                        loading={gsuite_user_delete_status}
                        onOpen={showDeleteLightbox}
                        onClose={toggleDeleteLightbox}
                        onSuccessClose={toggleDeleteLightbox}
                    >
                        <DeleteForm onSubmit={handleDeleteSubmit} close={toggleDeleteLightbox} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_user_delete_status: state.gsuite.gsuite_user_delete_status,
        gsuite_user_delete_data: state.gsuite.gsuite_user_delete_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteGSuiteUser
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteDelete));
