/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RightBorderButton from 'components/Buttons/RightBorderButton';
import SolidButton from 'components/Buttons/SolidButton';
import OutlineDropdown from 'components/Dropdowns/OutlineDropdown';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXTable from 'components/NXTable';
import TableAccordion from 'components/NXTable/TableAccordion';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { deleteWebForwarder } from 'containers/domain/action';
import { scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderTag } from 'containers/domain/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_domainTable.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The domain forwarders table
 */
let DomainTable = ({
    openLightbox,

    /** Redux State */
    app_viewport,
    domain_information_data,
    domain_web_record_list_status,
    domain_web_record_status,
    domain_web_record_store
}) => {
    const { dispatch } = store;

    /***** FUNCTIONS *****/
    function editButtonAction(serviceID) {
        openLightbox('edit', serviceID, 'web');
    }

    function deleteButtonAction(id) {
        dispatch(deleteWebForwarder(domain_information_data.id, id));
    }

    /***** RENDER HELPERS *****/
    function renderAddForwarderButton() {
        return (
            <RightBorderButton
                color="secondary"
                type="onClick"
                onClick={() => {
                    openLightbox('add', null, 'web');
                }}
            >
                <Text uppercase>Add Domain Forwarder</Text>
                <PhosphorIcons.Plus />
            </RightBorderButton>
        );
    }

    function renderMobileTable() {
        const renderContent = (id) => (
            <NXTable.Actions.Base>
                <SolidButton
                    type="onClick"
                    color="warning"
                    onClick={() => {
                        editButtonAction(id);
                        scrollToRef(document.querySelector('.domain_forwarding'));
                    }}
                >
                    Edit
                </SolidButton>
                <SolidButton
                    type="onClick"
                    color="warn"
                    onClick={() => {
                        deleteButtonAction(id);
                    }}
                >
                    Delete
                </SolidButton>
            </NXTable.Actions.Base>
        );

        return (
            <NXTable.Accordion.ToggleWrapper isLoading={[domain_web_record_status, domain_web_record_list_status].includes('loading')}>
                {domain_web_record_store.map(({ attributes: { destination, forwarder_type, hostname }, id }, i) => (
                    <NXTable.Accordion key={i} index={i} content={renderContent(id)}>
                        <TableAccordion.Child>
                            <Text bold uppercase size--xs>
                                Type
                            </Text>
                            {renderTag(forwarder_type)}
                        </TableAccordion.Child>
                        <TableAccordion.Child>
                            <Text bold uppercase size--xs>
                                Hostname
                            </Text>
                            <Text size--s>{hostname}</Text>
                        </TableAccordion.Child>
                        <TableAccordion.Child>
                            <Text bold uppercase size--xs>
                                Destination
                            </Text>
                            <Text size--s>{destination}</Text>
                        </TableAccordion.Child>
                    </NXTable.Accordion>
                ))}
                <Border bottom>
                    <NXTable.Footer>{renderAddForwarderButton()}</NXTable.Footer>
                </Border>
            </NXTable.Accordion.ToggleWrapper>
        );
    }

    function renderDesktopTable() {
        // Desktop table
        return (
            <NXTable columns="60px 1fr 1fr NXActions" className="domainTable">
                <NXTable.Header>
                    <NXTable.Header.Title>Type</NXTable.Header.Title>
                    <NXTable.Header.Title>Source Address</NXTable.Header.Title>
                    <NXTable.Header.Title>Destination</NXTable.Header.Title>
                </NXTable.Header>
                <NXTable.Body>
                    {domain_web_record_store.map(({ attributes: { destination, forwarder_type, hostname }, id }, i) => (
                        <NXTable.Row key={i}>
                            {renderTag(forwarder_type)}
                            <Text size--s secondary>
                                {hostname}
                            </Text>
                            <Text size--s secondary>
                                {destination}
                            </Text>
                            <NXTable.Actions>
                                <OutlineDropdown
                                    className="small alignRight"
                                    title="Edit"
                                    type="onClick"
                                    color="warning"
                                    titleOnClick={(e) => {
                                        editButtonAction(id);
                                    }}
                                    list={[
                                        {
                                            label: 'Delete',
                                            type: 'onClick',
                                            className: '',
                                            color: 'warn',
                                            onClick: (e) => {
                                                deleteButtonAction(id);
                                            }
                                        }
                                    ]}
                                />
                            </NXTable.Actions>
                        </NXTable.Row>
                    ))}
                    <Border bottom>
                        <NXTable.Footer>{renderAddForwarderButton()}</NXTable.Footer>
                    </Border>
                </NXTable.Body>
            </NXTable>
        );
    }

    function renderTable() {
        // Mobile table
        if (['sm', 'xs'].includes(app_viewport)) {
            return renderMobileTable();
        }

        return renderDesktopTable();
    }

    /***** RENDER *****/
    return <div className="domainTable">{renderTable()}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

DomainTable.propTypes = {
    /** The function that handles opening the lightbox */
    openLightbox: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport,
        domain_information_data: state.domain.domain_information_data,
        domain_web_record_store: state.domain.domain_web_record_store,
        domain_web_record_list_status: state.domain.domain_web_record_list_status,
        domain_web_record_status: state.domain.domain_web_record_status
    };
};
DomainTable = connect(mapStateToProps)(DomainTable);

export default DomainTable;
