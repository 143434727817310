/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import { Redirect, Route, Switch } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FormHandlerRoute } from 'containers/katana/containers/ContentEditorLightbox/formHandlerRoute';
import { AddSectionRoute } from 'containers/katana/containers/ContentEditorLightbox/routes/addSection';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaPages, katanaServicePages, katanaSubPageEnums } from 'containers/katana/consts';

const { BASIC_DETAILS } = katanaSubPageEnums;
const { SETUP } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ContentEditorRoutes = function ContentEditorRoutes() {
    /***** HOOKS *****/
    const params = useSetupEditorRouteParams();
    const { page } = params;
    const { getKatanaDestination } = useKatanaURL();

    /***** RENDER *****/
    return (
        <Switch>
            <Route
                exact
                path={`${serviceNav.VIPsites.link}/:id/(${SETUP.to})`}
                render={() => {
                    return <Redirect to={getKatanaDestination(page, BASIC_DETAILS)} />;
                }}
            />

            <Route exact path={`${serviceNav.VIPsites.link}/:id/${katanaPages.ADD_SECTION}/`}>
                <AddSectionRoute />
            </Route>
            <Route exact path={`${serviceNav.VIPsites.link}/:id/:page/:subpage?/:childpage?/`}>
                <FormHandlerRoute />
            </Route>
        </Switch>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ContentEditorRoutes;
