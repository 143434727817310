/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, getFormMeta, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EmailAddressFields from './purchase/emailAddressFields';
import NameAndPasswordFields from './purchase/nameAndPasswordFields';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { getEmailMailboxInformation, validateEmailAddressReset } from 'containers/email/action';
import { generateId, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { RenderField, renderButton } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let SetupService = ({
    id,

    // Redux props
    change,
    pristine,
    submitting,
    valid,
    handleSubmit,
    password_input,
    username,
    domain_name,
    getEmailMailboxInformation,
    validateEmailAddressReset,
    email_information_mailbox_status,
    email_information_mailbox_data,
    email_validate_data,
    fieldsMeta
}) => {
    const emailHostingPlan = getIncludedObjBasedOnType(email_information_mailbox_data?.included, 'product')?.attributes?.name || '';
    const billingCycle = email_information_mailbox_data?.attributes?.billing_cycle || '';

    /***** STATE *****/
    const [emailAddressValidateKey] = useState(generateId());

    /***** EFFECTS *****/
    useEffect(() => {
        getEmailMailboxInformation(id);
    }, []);

    useEffect(() => {
        // When the email information request completes, set the pre-selected plan and billing cycle
        if (!email_information_mailbox_data) return;

        change('plan', emailHostingPlan);
        change('billing_cycle', billingCycle);
    }, [email_information_mailbox_data]);

    useEffect(() => {
        // Email address must be re-validated if it changes
        validateEmailAddressReset(emailAddressValidateKey);
    }, [username, domain_name]);

    /***** RENDER HELPERS *****/
    const renderRestOfForm = () => (
        <>
            <div className="form__row">
                <div className="form__column full">
                    <Field label="Email Hosting Plan" name="plan" type="text" component={RenderField} disabled />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field label="Billing Cycle" name="billing_cycle" type="text" component={RenderField} disabled />
                </div>
            </div>
            <NameAndPasswordFields passwordInput={password_input} />
            {renderButton(pristine, submitting, valid, 'Confirm')}
        </>
    );

    /***** RENDER *****/
    if (['loading', null].includes(email_information_mailbox_status)) return <RequestLoader />;

    return (
        <form className="setupService" onSubmit={handleSubmit}>
            <Text secondary size--s align--center lead--s>
                <p className="setupService__desc">
                    This email hosting service has been purchased but isn&apos;t set up yet. Please enter your chosen service details below.
                </p>
            </Text>
            <div className="setupService__form">
                <EmailAddressFields
                    helpboxOpen={fieldsMeta?.username?.active || fieldsMeta?.domain_name?.active}
                    emailAddressValidateKey={emailAddressValidateKey}
                    disableDomain={!!email_information_mailbox_data?.attributes?.domain}
                    username={username}
                    domain_name={email_information_mailbox_data?.attributes?.domain || domain_name}
                />
                {email_validate_data[emailAddressValidateKey] ? renderRestOfForm() : ''}
            </div>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SetupService = reduxForm({
    enableReinitialize: true
})(SetupService);

const mapStateToProps = (state) => {
    const selector = formValueSelector('setupServiceForm');
    const password_input = selector(state, 'password');
    const username = selector(state, 'username');
    const domain_name = selector(state, 'domain_name');

    return {
        email_information_mailbox_status: state.email.email_information_mailbox_status,
        email_information_mailbox_data: state.email.email_information_mailbox_data,
        email_validate_data: state.email.email_validate_data,
        form: 'setupServiceForm',
        password_input,
        username,
        domain_name,
        fieldsMeta: getFormMeta('setupServiceForm')(state)
    };
};

const mapDispatchToProps = {
    getEmailMailboxInformation,
    validateEmailAddressReset,
    change
};

SetupService.propTypes = {
    // id of the unconfigured mailbox
    id: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupService);
