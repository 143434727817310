import { _SelectReduxFormDefault } from 'components/Form/Select/wrappers/reduxForm/Default';
import { _Select } from './_Select';
import { _SelectHookFormDefault } from './wrappers/hookForm/Default';

const Select = Object.assign(_Select, {
    HookForm: _SelectHookFormDefault,
    ReduxForm: _SelectReduxFormDefault
});

export { Select };
