/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CheckListItemCircle from 'components/CheckListItemCircle';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import katanaLogoWide from 'assets/images/katana/vipsites_banner_logo.svg';
import { SiteSetupStageSubPageOrder, katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from '../consts';

import './_contentEditorSidebar.scss';
const { NAVIGATION, PRESET_CONTENT, CONTACT_INFORMATION, BASIC_DETAILS, REGISTER_DOMAIN, FINALISE } = katanaSubPageEnums;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function ContentEditorSidebar() {
    /***** HOOKS *****/
    const { id, subpage } = useSetupEditorRouteParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(Number(id));

    /***** RENDER *****/
    return (
        <Grid columns="100%" className="ContentEditorSidebar" gap={4}>
            {!isMobile && <img src={katanaLogoWide} alt="VIPsites Banner Logo" width="90%" height="auto" />}
            {SiteSetupStageSubPageOrder.map((routeKey) => {
                const { Icon, title, ChildMenuRenderer, checkListKey } = katanaContentEditorRoutes[routeKey];

                if (!Icon) return '';
                const isCurrentRoute =
                    subpage === routeKey ||
                    (routeKey === PRESET_CONTENT && subpage === NAVIGATION) ||
                    (routeKey === BASIC_DETAILS && subpage === CONTACT_INFORMATION) ||
                    (routeKey === FINALISE && subpage === REGISTER_DOMAIN);

                const menuItemProps = {
                    primary: isCurrentRoute,
                    secondary: !isCurrentRoute
                };

                // function onMenuItemClick() {
                //     history.push(getKatanaDestination(page, routeKey));
                // }

                const iconSize = isMobile ? 30 : 20;

                const isChecked = get_katana_service_data?.attributes?.launch_checklist.includes(checkListKey);

                const MenuIcon = isCurrentRoute ? Icon.Bold : Icon;

                return (
                    <div className="ContentEditorSidebar__menuItem" key={routeKey}>
                        {/* <button type="button" onClick={onMenuItemClick}> */}
                        <Grid columns={isMobile ? '100%' : 'auto 1fr'}>
                            <CheckListItemCircle size={iconSize} checkSize={iconSize * 0.6} noBorder checked={isChecked}>
                                <MenuIcon {...menuItemProps} size={iconSize} />
                            </CheckListItemCircle>
                            {isMobile ? (
                                ''
                            ) : (
                                <Text {...menuItemProps} size--m semiBold align--left>
                                    {title}
                                </Text>
                            )}
                        </Grid>
                        {/* </button> */}

                        {isCurrentRoute && ChildMenuRenderer && !isMobile ? <ChildMenuRenderer /> : ''}
                    </div>
                );
            })}
        </Grid>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ContentEditorSidebar;
