/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InvoiceUrlLightbox from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox';
import AppLoader from 'components/Loaders/App';
import SolidNotification from 'components/Notifications/SolidNotification';
import { StaffMenu } from 'components/StaffMenu';
import ToastNotification from 'components/Toast';
import Transition from 'components/Transition';
import GuestRouter from 'router/guest';
import UserRouter from 'router/user';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ActivateAccount from '../containers/login/modules/activate';

/*   ACTIONS
 *****************************************************/
import { approveAdditionalUser } from '../containers/account/action';
import { approveMoveService } from '../containers/services/action';
import { checkAlertBanner, handleStaffLogin, interceptInvalidToken, loadAppConfig, updateViewport } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { setAxiosDefaults } from 'App/methods';
import { useRegisterTestRoutes } from 'components/TestRoutes/hooks/useRegisterTestRoutes';
import { AppTestRoutes } from 'components/TestRoutes/routes/app';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { history } from './consts';

import '../config/tokens/_cssVariables.scss';
import '../styles/_reset.scss';

import 'assets/icons/ccpicons.css';
import '../styles/_anim.scss';
import '../styles/_common.scss';
import '../styles/_form.scss';
import './_app.scss';

const googleAnalyticsIds = {
    sandbox: 'G-XHEEHTRRTW',
    production: 'G-VTB9C86524'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let App = (props) => {
    const {
        updateViewport,
        approveAdditionalUser,
        approveMoveService,
        interceptInvalidToken,
        app_user_data,
        app_mounting,
        app_check_token_data,
        app_alert_banner_data,
        account_approve_additional_user_status,
        account_approve_additional_user_error,
        service_move_approve_status,
        service_move_approve_error,
        login_url_params
    } = props;

    /***** STATE *****/
    const [isStaffLogin, setIsStaffLogin] = useState(false);

    /***** FUNCTIONS *****/
    function apponResize() {
        updateViewport();
    }

    /***** EFFECTS *****/
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const invitation_token = params.get('token');
        const move_token = params.get('move_token');
        setAxiosDefaults();
        updateViewport();
        window.addEventListener('resize', apponResize);
        ReactGA.initialize(import.meta.env.VITE_CCP_ENVIRONMENT === 'development' ? googleAnalyticsIds.sandbox : googleAnalyticsIds.production);
        interceptInvalidToken();

        /****************************************************************************
         * STAFF LOGIN
         ****************************************************************************/
        const staffCheckPath = document.location.pathname;
        if (staffCheckPath.startsWith('/staff-login')) {
            const splitParams = staffCheckPath.split('/');

            setIsStaffLogin(true);
            handleStaffLogin({
                token: splitParams && splitParams.length === 3 ? splitParams[2] : false
            });
        } else if (invitation_token) {
            approveAdditionalUser(invitation_token);
        } else if (move_token) {
            approveMoveService(move_token);
        } else {
            checkAlertBanner();
            loadAppConfig();
        }

        return () => {
            window.removeEventListener('resize', apponResize);
        };
    }, []);

    useEffect(() => {
        if (!app_user_data) {
            return;
        }
        if (login_url_params?.token) {
            return approveAdditionalUser(login_url_params.token);
        }
        if (login_url_params?.move_token) {
            return approveMoveService(login_url_params.move_token, true);
        }
    }, [app_user_data]);

    /***** RENDER HELPERS *****/
    const renderApp = () => {
        if (app_user_data) {
            return <UserRouter />;
        }

        if (account_approve_additional_user_status === 'error') {
            const { code } = account_approve_additional_user_error;

            if (code === 'ERR_ACCESS_TOKEN') return <GuestRouter />;

            if (code === 'ERR_INVITATION_REQUIRES_SIGN_UP') {
                if (!app_check_token_data?.is_security_detail_completed || !app_check_token_data?.is_user_detail_completed)
                    return <ActivateAccount template="additional-user" />;
            }

            if (code === 'ERR_MOVE_TOKEN_INVALID' || code === 'ERR_INVITATION_TOKEN_INVALID')
                return <ActivateAccount template="invalid" type="additional_user" />;

            return <ActivateAccount template="expired-token" />;
        }

        if (service_move_approve_status === 'error') {
            const { code } = service_move_approve_error;

            if (code === 'ERR_ACCESS_TOKEN') return <GuestRouter />;

            if (code === 'ERR_INVITATION_REQUIRES_SIGN_UP') {
                if (!app_check_token_data?.is_security_detail_completed || !app_check_token_data?.is_user_detail_completed)
                    return <ActivateAccount template="move-service" />;
            }

            if (code === 'ERR_MOVE_TOKEN_INVALID' || code === 'ERR_INVITATION_TOKEN_INVALID') return <ActivateAccount template="invalid" />;

            return <ActivateAccount template="expired-move" />;
        }

        return <GuestRouter />;
    };

    const testRoutes = useRegisterTestRoutes(AppTestRoutes);

    /***** RENDER *****/
    return (
        <Transition when={app_mounting}>
            {app_alert_banner_data?.enabled && (
                <SolidNotification className="alertBanner" dismissible={false} color="warning">
                    {htmr(app_alert_banner_data.message)}
                </SolidNotification>
            )}
            <ToastNotification />
            <Router history={history}>
                {app_mounting ? (
                    <AppLoader fullHeight message={isStaffLogin ? `You are logging in as a staff member` : ``} />
                ) : (
                    <>
                        <StaffMenu />
                        {renderApp()}
                        <Switch>{testRoutes}</Switch>
                        <InvoiceUrlLightbox />
                    </>
                )}
            </Router>
        </Transition>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    account_approve_additional_user_error: state.account.account_approve_additional_user_error,
    account_approve_additional_user_status: state.account.account_approve_additional_user_status,
    app_alert_banner_data: state.app.app_alert_banner_data,
    app_check_token_data: state.app.app_check_token_data,
    app_mounting: state.app.app_mounting,
    app_user_data: state.app.app_user_data,
    login_url_params: state.login.login_url_params,
    service_move_approve_error: state.services.service_move_approve_error,
    service_move_approve_status: state.services.service_move_approve_status
});

const mapDispatchToProps = {
    approveAdditionalUser,
    updateViewport,
    interceptInvalidToken,
    approveMoveService
};

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
