/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import GoogleIcon from 'assets/images/domains/google.png';
import OfficeIcon from 'assets/images/domains/officeIcon.png';
import ShopifyIcon from 'assets/images/domains/shopify.png';
import VentraIcon from 'assets/images/vip-logo-circle.svg';
import { presets } from './types';

/**
 * Provides the icon for the correlating preset
 *
 * @param {import('./types').TPresetName} key
 */
export const handleRenderIcon = (key) => {
    switch (key) {
        default:
        case presets.GOOGLE_WORKSPACE:
            return <img className="dns__preset--icon" src={GoogleIcon} alt={`${presets.GOOGLE_WORKSPACE} Presets`} />;

        case presets.OFFICE_365:
            return <img className="dns__preset--icon" src={OfficeIcon} alt={`${presets.OFFICE_365} Presets`} />;

        case presets.VENTRAIP_EMAIL:
            return <img className="dns__preset--icon" src={VentraIcon} alt={`${presets.VENTRAIP_EMAIL} Presets`} />;

        case presets.SHOPIFY:
            return <img className="dns__preset--icon" src={ShopifyIcon} alt={`${presets.SHOPIFY} Presets`} />;

        case presets.SQUARESPACE:
            return <i className="dns__preset--icon icon icon-squarespace" />;
    }
};

/**
 * Provides a simplistic check to see if the record is conflicting with any other records in a list of records
 *
 * @param {{
 *   content: string,
 *   hostname: string,
 *   dns_type: string
 * }} record
 * @param {{
 *   attributes: {
 *     content: string,
 *     ttl: string,
 *     prio: string,
 *     hostname: string,
 *     dns_type: string
 *   }
 * }[]} records
 * @returns boolean
 */
export const isRecordConflicting = ({ hostname, content, dns_type }, records) => {
    //CNAME cannot be on the same subdomain as any other record
    if (dns_type === 'CNAME') {
        return records?.some(({ attributes: existing }) => existing.hostname === hostname) ?? false;
    }

    //In general, other records cannot have the exact same type, hostname and content
    return (
        records?.some(
            ({ attributes: existing }) => existing.content === content && existing.hostname === hostname && existing.dns_type === dns_type
        ) ?? false
    );
};
