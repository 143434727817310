/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';
import SolidTag from 'components/Tags/SolidTag';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SuggestionsSplit() {
    /***** HOOKS *****/
    const domain_suggestion_status = useSelector((state) => state.domain.domain_suggestion_status);
    const domain_suggestion_data = useSelector((state) => state.domain.domain_suggestion_data);

    /***** RENDER HELPERS *****/
    const renderSuggestions = () => {
        if (Array.isArray(domain_suggestion_data?.attributes?.list) && domain_suggestion_status === 'success') {
            return domain_suggestion_data.attributes.list.map(function (item, index) {
                const domain = item.domain.split('.');
                return (
                    <div key={index} className="info__container">
                        <Flex gap={1} items="center">
                            <Text secondary size--s>
                                {domain[0]}
                                <Text black bold>
                                    .{typeof domain[2] === 'undefined' ? `${domain[1]}` : `${domain[1]}.${domain[2]}`}
                                </Text>
                            </Text>
                            {item.is_premium && (
                                <SolidTag color="record__purple" round>
                                    Premium
                                </SolidTag>
                            )}
                        </Flex>
                        <div className="box__description">
                            ${item.price}/yr AUD{' '}
                            <a
                                className="suggestion__go"
                                href={`${getPurchaseLink('Domain Names')}?a=add&domain=register&query=${item.domain}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="icon icon-search"></i>
                            </a>
                        </div>
                    </div>
                );
            });
        }

        if (domain_suggestion_status === 'loading') {
            return (
                <div className="info__container">
                    <RequestLoader />
                    <div className="info__container--text">Fetching the best variations!</div>
                </div>
            );
        }

        return (
            <div className="info__container">
                <div className="box__description failed">
                    <i className="icon icon-search"></i>
                    We are unable to find any variations of your domain name at this time.
                </div>
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <div className="service__suggestion">
            <div className="suggestion__container">
                <i className="icon icon-protect_lock"></i>
                <h2 className="suggestion__title">Protect your brand</h2>
                <div className="suggestion__description">Secure available variations of your domain name while they&apos;re still on offer.</div>
            </div>
            <div className="suggestion__list">{renderSuggestions()}</div>
            <div className="suggestion__more">
                <div className="more__description">You can find a full list of variations on our website</div>
                <Anchor target="_blank" href={getPurchaseLink('Domain Names')}>
                    Show full list
                </Anchor>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SuggestionsSplit;
