/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useRef } from 'react';
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   Types
 **********************************************************************************************************/
type AutoCompleteAddressProps = WrappedFieldProps & {
    label: JSX.Element;
    placeholder: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SingleFieldAutoCompleteAddress = ({ label, placeholder, meta, input }: AutoCompleteAddressProps) => {
    /***** HOOKS *****/
    const autocompleteInputRef = useRef<HTMLInputElement>(null);
    const autocomplete = useRef<any>();

    /***** FUNCTIONS *****/
    const handlePlaceChanged = () => {
        const place = autocomplete.current?.getPlace();

        if (place?.geometry) {
            input.onChange(place.formatted_address);
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        autocomplete.current = new google.maps.places.Autocomplete(autocompleteInputRef.current!, {
            types: ['geocode'],
            componentRestrictions: { country: ['au', 'nz'] }
        });
        autocomplete.current.addListener('place_changed', handlePlaceChanged);
    }, []);

    /***** RENDER *****/
    return <Input.ReduxForm.Default meta={meta} input={input} inputRef={autocompleteInputRef} placeholder={placeholder} label={label} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
