/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getGSuiteCustomerData, updateGSuiteCustomerData } from '../action';
import GSuiteAccountDetailsForm from '../forms/account';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteAccountDetails extends Component {
    constructor(props) {
        super(props);
        this.handleAccountUpdate = this.handleAccountUpdate.bind(this);
    }

    handleAccountUpdate(values) {
        const { updateGSuiteCustomerData, gsuiteid } = this.props;
        updateGSuiteCustomerData(gsuiteid, values);
    }

    componentDidUpdate(prevProps) {
        const { gsuite_customer_data_status } = this.props;
        registerScrollEvents(this, gsuite_customer_data_status === 'success' && prevProps.gsuite_customer_data_status === 'loading');
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { gsuiteid, gsuite_customer_data_status, getGSuiteCustomerData } = this.props;
        const { handleAccountUpdate } = this;

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="gsuiteAccountDetails"
            >
                <Box
                    request={{
                        action: getGSuiteCustomerData,
                        args: gsuiteid,
                        status: gsuite_customer_data_status
                    }}
                    className="gsuiteAccountDetails__box"
                    title="Account Details"
                    status={gsuite_customer_data_status}
                    custom={{
                        render: <GSuiteAccountDetailsForm onSubmit={handleAccountUpdate} />,
                        pos: 'bottom'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_customer_data_status: state.gsuite.gsuite_customer_data_status
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGSuiteCustomerData,
            updateGSuiteCustomerData
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteAccountDetails));
