/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NoServicesPlaceholder.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class NoServices extends Component {
    render() {
        const {
            message,
            linkText,
            service: { name, purchaseLink }
        } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="NoServicesPlaceholder">
                <div className="NoServicesPlaceholder__text">{message || `You don't currently have any ${name} services with us`}</div>
                <Anchor href={purchaseLink} target="_blank">
                    {linkText || `Purchase ${name} services`}
                </Anchor>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
NoServices.propTypes = {
    /**
     * The message to display to the user, if this is not provided, the text is generated based off the service name `service.name`
     */
    message: PropTypes.string,

    /**
     *  The text to display in the link, if this is not provided, the text is generated based off the service name `service.name`
     */
    linkText: PropTypes.string,

    /**
     *  The service object, this is used to generate the message and link text if they are not provided
     */
    service: PropTypes.shape({
        /**
         *  The name of the service, this is used to generate the message and link text if they are not provided
         */
        name: PropTypes.string,

        /**
         *  The link for the Anchor tag to use.
         */
        purchaseLink: PropTypes.string
    }).isRequired
};

export default NoServices;
