/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class VerticalTimeline extends Component {
    render() {
        const { items, progress, title } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className={`vsVerticalTimeline${progress ? ' vsVerticalTimeline--progress' : ''}`}>
                <h3 className="vsVerticalTimeline__heading">{title}</h3>
                <div className="vsVerticalTimeline__container">
                    <div className="vsVerticalTimeline__line"></div>
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={`vsVerticalTimeline__item${
                                progress && index <= progress.activeItemNum - 1 ? ' vsVerticalTimeline__item--active' : ''
                            }`}
                        >
                            <div className="vsVerticalTimeline__iconContainer">
                                <i className={`icon ${item.icon}`} />
                            </div>
                            <div className="vsVerticalTimeline__itemText">
                                <h4 className="vsVerticalTimeline__itemHeading">{item.title}</h4>
                                <p className="vsVerticalTimeline__itemParagraph">{item.paragraph}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default VerticalTimeline;
