/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OutlineButton from 'components/Buttons/OutlineButton';
import OutlineDropdown from 'components/Dropdowns/OutlineDropdown';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Avatar from 'components/Placeholders/Avatar';
import { getCurrentDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { addAdditionalUser, cancelInvitation, getAdditionalUsers, removeAdditionalUser, resendInvitation } from '../action';
import AddAdditionalUserForm from '../forms/addAdditionalUser';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AdditionalUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLightbox: false,
            lightboxTemplate: null,
            user: null
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.handleSubAccountForm = this.handleSubAccountForm.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.removeAdditionalUser = this.removeAdditionalUser.bind(this);
    }

    openLightbox(template, values) {
        this.setState({
            showLightbox: true,
            lightboxTemplate: template,
            user: values
        });
    }

    closeLightbox() {
        this.setState({
            showLightbox: false,
            lightboxTemplate: null
        });
    }

    removeAdditionalUser() {
        const { removeAdditionalUser } = this.props;
        const { user } = this.state;

        removeAdditionalUser(user.id);
    }

    handleSubAccountForm(values) {
        const { addAdditionalUser } = this.props;

        const access_expiry = values.access_expiry ? DateTime.fromJSDate(values.access_expiry).toFormat('yyyy-MM-dd') : null;

        const account = {
            access_expiry,
            email: values.email,
            group: 'admin'
        };

        addAdditionalUser(account);
    }

    componentDidUpdate(prevProps) {
        const {
            account_additional_user_list_status,
            account_add_additional_user_status,
            account_remove_additional_user_status,
            account_update_additional_user_permission_status
        } = this.props;
        const { closeLightbox } = this;

        if (prevProps.account_add_additional_user_status === 'loading' && account_add_additional_user_status === 'success') {
            closeLightbox();
        }

        if (prevProps.account_remove_additional_user_status === 'loading' && account_remove_additional_user_status === 'success') {
            closeLightbox();
        }

        if (
            prevProps.account_update_additional_user_permission_status === 'loading' &&
            account_update_additional_user_permission_status === 'success'
        ) {
            closeLightbox();
        }

        registerScrollEvents(this, account_additional_user_list_status === 'success' && prevProps.account_additional_user_list_status === 'loading');
    }

    render() {
        const {
            account_additional_user_list_status,
            account_additional_user_list_data,
            account_add_additional_user_status,
            account_remove_additional_user_status,
            getAdditionalUsers,
            resendInvitation,
            cancelInvitation
        } = this.props;
        const { showLightbox, lightboxTemplate, user } = this.state;
        const { openLightbox, closeLightbox, handleSubAccountForm, removeAdditionalUser } = this;
        const backgrounds = [
            'linear-gradient(90deg, #0a9fe5, #10c5ef)',
            'linear-gradient(90deg, #5956d7, #c544fb)',
            'linear-gradient(90deg, #0dd41a, #86fc6f)',
            'linear-gradient(90deg, #c32888, #a01e54)',
            'linear-gradient(90deg, #f4ad26, #ca3c78)',
            'linear-gradient(90deg, #0575e6, #0054a8)',
            'linear-gradient(90deg, #fda085, #f6d365)',
            'linear-gradient(90deg, #8fd3f4, #84fab0)'
        ];

        const userList = () => {
            if (account_additional_user_list_data && account_additional_user_list_data.length > 0) {
                return account_additional_user_list_data
                    .filter(({ attributes }) => attributes.role !== 'account holder')
                    .map((user, index) => {
                        const { id, attributes } = user;
                        const { firstname, lastname, email, status, remaining_access_hours } = attributes;

                        if (status === 'pending') {
                            const menu = [
                                {
                                    label: 'Resend Invitation',
                                    type: 'onClick',
                                    onClick: () => {
                                        resendInvitation(email);
                                    }
                                },
                                {
                                    label: 'Cancel Invitation',
                                    type: 'onClick',
                                    onClick: () => {
                                        cancelInvitation(email);
                                    }
                                }
                            ];

                            return (
                                <div key={index} className="accountUsers__users">
                                    <div className="accountUsers__user">
                                        <div className="accountUsers__info">
                                            <div className="accountUsers__info--email pending">{email}</div>
                                        </div>
                                    </div>
                                    <div className="accountUsers__action-wrapper">
                                        {remaining_access_hours ? (
                                            <div className="accountUsers__access">
                                                Account access
                                                <div className="accountUsers__access--expiry">
                                                    expires in:
                                                    <div className="accountUsers__access--expiry-remaining">
                                                        &nbsp;{calculateRemainingAccessTime(remaining_access_hours)}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="accountUsers__action">
                                            <OutlineDropdown color="gray" title="Pending Setup" list={menu} />
                                        </div>
                                    </div>
                                </div>
                            );
                        }

                        return (
                            <div key={index} className="accountUsers__users">
                                <div className="accountUsers__user">
                                    <div className="accountUsers__initial">
                                        <Avatar fullname={`${firstname} ${lastname}`} size={43} fontSize={14} background={backgrounds[index]} />
                                    </div>
                                    <div className="accountUsers__info">
                                        <div className="accountUsers__info--name">{`${firstname} ${lastname}`}</div>
                                        <div className="accountUsers__info--email">{email}</div>
                                    </div>
                                </div>
                                <div className="accountUsers__action-wrapper">
                                    {remaining_access_hours ? (
                                        <div className="accountUsers__access">
                                            Account access
                                            <div className="accountUsers__access--expiry">
                                                expires in
                                                <div className="accountUsers__access--expiry-remaining">
                                                    : {calculateRemainingAccessTime(remaining_access_hours)}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="accountUsers__action">
                                        <OutlineButton
                                            color="primary"
                                            type="onClick"
                                            onClick={() => {
                                                openLightbox('remove', { id, firstname, lastname });
                                            }}
                                        >
                                            Remove User
                                        </OutlineButton>
                                    </div>
                                </div>
                            </div>
                        );
                    });
            }
        };

        const calculateRemainingAccessTime = (remainingAccessHours) => {
            const relativeCalendar = getCurrentDate().plus({ hours: remainingAccessHours }).toRelative();
            // Remove 'in ' from the toRelative string so it can be formatted nicely.
            return relativeCalendar.substring(3);
        };

        const removeConfirmation = () => {
            return `
                <div class="subdescription highlight">User: ${user.firstname} ${user.lastname}</div>
                <div>You are about to Remove the user <span class="highlight">${user.firstname} ${user.lastname}</span>. 
                They will no longer be able to access this ${application} account.</div>
            `;
        };

        const description = `Additional users allow other people to access your ${application} account. Please note that additional users can purchase new and close down current services, pay for services using stored credit cards on your account and have control over your website. Additional users cannot close your account or move services to other accounts.`;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountUsers"
            >
                <Box
                    request={{
                        action: getAdditionalUsers,
                        args: false,
                        status: account_additional_user_list_status
                    }}
                    className="accountUsers__box"
                    title="Additional Users"
                    desc={description}
                    action={{
                        label: 'Add New User',
                        type: 'onClick',
                        size: 'large',
                        color: 'primary',
                        onClick: () => {
                            openLightbox('add');
                        }
                    }}
                    status={account_additional_user_list_status}
                    custom={{
                        render: userList(),
                        pos: 'bottom'
                    }}
                />

                {lightboxTemplate === 'add' && (
                    <OverlayLightbox title="Add New User" onOpen={showLightbox} onClose={closeLightbox} loading={account_add_additional_user_status}>
                        <AddAdditionalUserForm onSubmit={handleSubAccountForm} />
                    </OverlayLightbox>
                )}
                {lightboxTemplate === 'remove' && (
                    <OverlayLightbox
                        title="Are you sure you want to remove this user?"
                        color="warn"
                        onOpen={showLightbox}
                        loading={account_remove_additional_user_status}
                        confirm={{
                            desc: htmr(removeConfirmation()),
                            buttonText: 'Remove User',
                            buttonAction: removeAdditionalUser,
                            closeText: 'NO, KEEP THIS USER ACTIVE',
                            closeAction: closeLightbox
                        }}
                        onClose={closeLightbox}
                    />
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        account_additional_user_list_status: state.account.account_additional_user_list_status,
        account_additional_user_list_data: state.account.account_additional_user_list_data,
        account_add_additional_user_status: state.account.account_add_additional_user_status,
        account_remove_additional_user_status: state.account.account_remove_additional_user_status,
        account_update_additional_user_permission_status: state.account.account_update_additional_user_permission_status,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getAdditionalUsers,
            addAdditionalUser,
            removeAdditionalUser,
            resendInvitation,
            cancelInvitation
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdditionalUsers));
