/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request/_requestLoader';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RequestLoaderWithBackground.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows a loading animation with an optional message
 * @type {React.FC<NRequestLoader.Props>}
 */
const _RequestLoaderWithBackground = (props) => <RequestLoader {...props} className={classNames(props?.className, 'RequestLoaderWithBackground')} />;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default _RequestLoaderWithBackground;
