/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DismissibleBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dismissed: !!localStorage.getItem(props.localStorageKey)
        };

        this.dismiss = this.dismiss.bind(this);
    }

    dismiss() {
        const { localStorageKey } = this.props;

        this.setState(
            {
                dismissed: true
            },
            () => dismissBannerLocalStorage(localStorageKey)
        );
    }

    render() {
        const { children } = this.props;
        const { dismissed } = this.state;
        const { dismiss } = this;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return dismissed ? '' : children(dismiss);
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DismissibleBanner.propTypes = {
    /**
     * key used to store the dismissed value in local storage
     */
    localStorageKey: PropTypes.string,

    /**
     * a function which returns the banner component to render when dismissed = false. Dismiss func is passed to children as an argument by the DismissibleBanner component
     */
    children: PropTypes.func
};

export default DismissibleBanner;

export const dismissBannerLocalStorage = (localStorageKey) => localStorage.setItem(localStorageKey, 'true');
