import { keys } from 'lodash';
import { useMemo } from 'react';
import { SECOND } from 'utilities/consts';
import { useObjectDiff } from 'utilities/hooks/useObjectDiff';

/**
 * @type {Array<[string, number]>}
 */
const objectDiffValues = [
    ['site.style.fonts.brand', 0],
    ['navigation.style', 0],
    ['navigation_items', 0],
    ['site.color', 0],
    ['site.style', 0],
    ['business.name', 500],
    ['business.email', 1000],
    ['business.phone', 1000],
    ['business.business_number', 1000],
    ['business.address', 1000],
    ['business.operating_hours', 1500],
    ['business.tag_line', 500],
    ['business.logo', 0],
    ['socials', 500],
    ['navigation.show_socials_in_header', 0],
    ['navigation.show_socials_in_footer', 0],
    ['navigation.call_to_action_text', 500],
    ['navigation.call_to_action_section_id', 0],
    ['navigation.call_to_action_type', 2500],
    ['navigation.call_to_action_enabled', 0],
    ['navigation.call_to_action_value', 1000]
];

/**
 * @param {Partial<KatanaSitePreviewAttributes>} formValues
 */
export function useActiveFormDynamicDebounceTimer(formValues) {
    const objectDiff = useObjectDiff(formValues);

    return useMemo(() => {
        /**
         * Convert the object diff to a string of keys so we can use `.includes` to find the value
         */
        const objectDiffKeysValue = keys(objectDiff).join(' ');
        /**
         * Find the value of the diff
         */
        const objectDiffValue = objectDiffValues.find(([key]) => objectDiffKeysValue.includes(key));
        /**
         * If the value is found, return it, otherwise return 1000
         */
        return objectDiffValue ? objectDiffValue[1] : SECOND;
    }, [objectDiff]);
}
