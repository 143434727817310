/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderButton } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { NXFormContext } from 'components/Form/NXForm/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      color?: BaseButtonColourTypeDef;
 *      children: import('react').ReactNode;
 *      className?: string;
 * }>}
 */
const _NXFormButton = ({ children, color, className }) => {
    /***** HOOKS *****/
    const { submitting, pristine, valid } = useContext(NXFormContext);

    /***** RENDER *****/
    return renderButton(pristine, submitting, valid, children, color, className);
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _NXFormButton };
