/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { keys, set } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXPicturedIframeSourceDocPreview from 'components/PicturedIframeSourceDocPreview';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetContentEditorSiteSectionPreviewQuery } from 'containers/katana/containers/ContentEditorLightbox/methods';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createContentEditorModulesFormName } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/methods';
import { useActiveFormDynamicDebounceTimer } from 'containers/katana/containers/ContentEditorLightbox/hooks/useActiveFormDynamicDebounceTimer';
import { useActiveValidFormValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useActiveValidFormValues';
import { useAllowPreviewState } from 'containers/katana/containers/ContentEditorLightbox/hooks/useAllowPreviewState';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useTransformedActiveFormValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useTransformedActiveFormValues';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import useDebounce from 'utilities/hooks/useDebounce';
import { useReduxForm } from 'utilities/hooks/useReduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { contentEditorPictureInPictureState } from 'containers/katana/containers/ContentEditorLightbox/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      isOpen: boolean;
 *      leftAnchorElement: HTMLElement;
 * }>}
 */
const ContentEditorPicturePreview = function ContentEditorPicturePreview({ isOpen, leftAnchorElement }) {
    /***** STATE *****/
    const [displayingPreview, setDisplayingPreview] = useState('');
    /***** HOOKS *****/
    const isMobileOrTablet = useAppViewport(['xs', 'sm', 'md']);
    const { childpage, subpage, id } = useSetupEditorRouteParams();
    const { siteData } = useSiteDataPreviewValues();
    const isPreviewAllowed = useAllowPreviewState();

    /***** QUERIES *****/
    const {
        data: get_section_preview_data,
        isLoading: isGetSectionPreviewLoading,
        isError: isGetSectionPreviewError
    } = useGetContentEditorSiteSectionPreviewQuery(isOpen, siteData);

    const activeFormKey = createContentEditorModulesFormName(subpage);
    const formData = useReduxForm(activeFormKey);
    const finalFormValues = useActiveValidFormValues(formData?.values, formData?.syncErrors);
    const transformedActiveFormValues = useTransformedActiveFormValues(finalFormValues, subpage);
    const isGetSectionDefinitionsFetching = katanaQuery.serviceID.meta.getSectionDefinitions.useIsFetching(id);
    const isGetSectionsFetching = katanaQuery.serviceID.getSections.useIsFetching(id);

    const [finalQueryArguments, setFinalQueryArguments] = useState({ serviceID: id });

    const sitePreviewData = useMemo(() => {
        const previewData = keys(transformedActiveFormValues).length ? { serviceID: id, attributes: transformedActiveFormValues } : { serviceID: id };

        if (keys(siteData).length) {
            set(previewData, 'attributes.site', siteData);
        }
        return previewData;
    }, [transformedActiveFormValues, id, siteData]);

    const dynamicDebounceValue = useActiveFormDynamicDebounceTimer(transformedActiveFormValues);
    useDebounce(
        () => {
            setFinalQueryArguments(sitePreviewData);
        },
        dynamicDebounceValue,
        [sitePreviewData, transformedActiveFormValues]
    );

    const {
        data: get_site_preview_data,
        isLoading: isGetSitePreviewLoading,
        isError: isGetSitePreviewError
    } = katanaQuery.serviceID.getSitePreview.useQuery(finalQueryArguments);

    /***** RENDER HELPERS *****/
    const sectionEditorLoadingData = useMemo(
        () => [
            { condition: Boolean(isGetSectionDefinitionsFetching) || Boolean(isGetSectionsFetching), message: 'Loading section data...' },
            { condition: isGetSectionPreviewLoading, message: 'Fetching latest section preview...' }
        ],
        [isGetSectionDefinitionsFetching, isGetSectionsFetching, isGetSectionPreviewLoading]
    );
    const siteEditorLoadingData = useMemo(
        () => [{ condition: isGetSitePreviewLoading, message: 'Fetching latest site preview...' }],
        [isGetSitePreviewLoading]
    );

    /***** EFFECTS *****/
    useEffect(() => {
        if (childpage && get_section_preview_data) {
            setDisplayingPreview(get_section_preview_data);
        } else if (!childpage && get_site_preview_data) {
            setDisplayingPreview(get_site_preview_data);
        }
    }, [get_section_preview_data, get_site_preview_data, childpage]);
    useEffect(() => {
        if (isOpen && isPreviewAllowed && !isMobileOrTablet) {
            const storedPictureState = contentEditorPictureInPictureState.retrieve();
            contentEditorPictureInPictureState.store.set(storedPictureState || 'visible');
        }
    }, [isOpen, isPreviewAllowed]);

    useEffect(() => {
        if (!isPreviewAllowed) {
            contentEditorPictureInPictureState.store.set('hidden');
        }
    }, [isPreviewAllowed]);

    /***** RENDER *****/
    return (
        <NXPicturedIframeSourceDocPreview
            leftAnchorElement={leftAnchorElement}
            className="ContentEditorPicturePreview"
            stateStoreData={contentEditorPictureInPictureState}
            key={childpage ? 'childpage' : 'site'}
            loaderData={childpage ? sectionEditorLoadingData : siteEditorLoadingData}
            srcDoc={displayingPreview}
            isError={childpage ? isGetSectionPreviewError : isGetSitePreviewError}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ContentEditorPicturePreview };
