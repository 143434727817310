/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CustomNSForm from '../forms/customns';
import store from 'store/store';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {ReturnType<typeof mapStateToProps>} reduxProps
 * @typedef {import('react-router-dom').RouteComponentProps} routerProps
 * @typedef {{
 *   onSubmit: (data: Record<string, string>) => void
 * }} Props
 * @typedef {{
 *   showRecordLightbox: boolean,
 * }} State
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {Props & reduxProps & routerProps} props
 */
let CustomNameservers = ({ domain_nameserver_store, domain_update_current_configuration_status, onSubmit }) => {
    const [showRecordLightbox, setShowRecordLightbox] = React.useState(false);

    const toggleRecordLightbox = React.useCallback(() => {
        store.dispatch(reset('customNSForm'));
        setShowRecordLightbox((showRecordLightbox) => !showRecordLightbox);
    }, []);

    const handleNameserverRender = React.useCallback(
        () =>
            domain_nameserver_store?.map((item) => (
                <div key={item} className="record__item">
                    {item}
                </div>
            )),
        [domain_nameserver_store]
    );

    useEffect(() => {
        if (domain_update_current_configuration_status === 'success') {
            setShowRecordLightbox(false);
        }
    }, [domain_update_current_configuration_status]);

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="customNameservers">
            <div className="customNameservers__left">
                <div className="desc">Nameservers</div>
                <div className="title">{handleNameserverRender()}</div>
            </div>
            <div className="customNameservers__right">
                <OutlineButton color="secondary" type="onClick" onClick={toggleRecordLightbox}>
                    Edit
                </OutlineButton>
            </div>

            {!!showRecordLightbox && (
                <OverlayLightbox
                    onOpen
                    onClose={toggleRecordLightbox}
                    title="Edit Custom Nameservers"
                    loading={domain_update_current_configuration_status}
                >
                    <CustomNSForm onSubmit={onSubmit} />
                </OverlayLightbox>
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return /** @type {const} */ ({
        domain_information_data: state.domain.domain_information_data,
        domain_nameserver_store: state.domain.domain_nameserver_store,
        domain_update_current_configuration_status: state.domain.domain_update_current_configuration_status
    });
};

CustomNameservers = connect(mapStateToProps)(CustomNameservers);

CustomNameservers = withRouter(CustomNameservers);

export default CustomNameservers;
