export const configKeys = /** @type {const} */ ({
    FREE_DNS: 'free_dns',
    CUSTOM_HOSTING: 'custom_hosting',
    FORWARDING: 'forwarding',
    VENTRAIP_HOSTING: 'ventraip_hosting',
    PARK_DOMAIN: 'park_domain',
    CUSTOM_NAMESERVERS: 'custom_nameservers'
});

/**
 * @template T
 * @typedef {import("utilities/types").ValueOf<T>} ValueOf
 */

/**
 * @typedef {ValueOf<typeof configKeys>} TConfigKeys
 */
