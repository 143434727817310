/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { CSSProperties} from 'react';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AllTokens } from 'config/tokens/base';
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
**********************************************************************************************************/
type UseTheme = <T extends string | undefined = undefined>(props: Record<string, AllTokens | NXUtils.Falsy | NXUtils.NoInfer<T>>) => CSSProperties;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * **Description**
 * The useTheme hook takes in an object where the keys represent a local scss variable (e.g. '--Text-color')  
 * and the values represent a token name (e.g. 'C_Anchor_color_text_base'). It returns an object where the  
 * keys are the local scss variable and the values are the token value.  
 *
 * For any given key-value pair where the value is falsy, the key-value pair will be ignored. This is similar  
 * to how the `classNames` utility function works but intended for applying styling through scss variables  
 * 
 * **Type Inference**: This component intentionally does not infer custom strings that are passed as values. This is so that  
 * it is obvious that if you are passing a value that is not always a token, it may not work as intended, and will definitely   
 * not handle that custom value.  
 *
 * If you are handling custom strings (ie. color="notice" and are using this same variable to set a classname such as &-notice)  
 * then you can pass the generic type to the useTheme hook like so:  
 * @example
 * ```ts
 * const themeStyles = useTheme<'notice' | 'warn'>({
 *   '--Border-color': colour
 * })
 * ```
 * @link {components/Utils/Border} for implementation example
 * 
 * **Notice:** The object passed to this function must have a static length of key-value pairs so that the underlying  
 * hook can properly memoize the results. If you are passing a dynamic object for some reason, you should memoize  
 * the object before passing it to this hook.
 *
 */
export const useTheme: UseTheme = (props) => {
    return useMemo(() => {
        return Object.entries(props).reduce<Record<string, string>>((acc, [localVariable, tokenName]) => {
            if (!tokenName) return acc;
            if (!/^(S|C|P)_/.test(tokenName)) return acc;

            return {
                ...acc,
                [localVariable]: `var(--${tokenName})`
            };
        }, {});
    }, Object.values(props));
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
