/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { get, writable } from 'svelte/store';
import { useEventListener } from 'usehooks-ts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useUUID } from 'utilities/hooks/useUUID';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_CheckBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NCheckBox } from 'components/Form/CheckBox/types';

const baseState = {
    uuid: '',
    startState: false
};
const checkboxPointerDownStartUUID = writable(baseState);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _CheckBox: React.FC<NCheckBox.Props> = ({ isChecked = false, onChange, children, disabled, className, type = 'square', intrinsic }) => {
    /***** STATE *****/
    const _uuid = useUUID();
    const pointerUpEventListenerCallback = useRef<((e: any) => void) | null>(null);

    /***** FUNCTIONS *****/
    /**
     * When the pointer moves up we need to reset the start uuid to the base state
     */
    function onPointerUp(e) {
        if (e.pointerType === 'touch') {
            onChange?.(!isChecked);
            return;
        }
        checkboxPointerDownStartUUID.set(baseState);
        pointerUpEventListenerCallback.current = null;
    }

    /**
     * This function now acts as the "onClick" rather than having "onClick" on the actual button
     */
    const onPointerDown: React.PointerEventHandler<HTMLButtonElement> = (e) => {
        if (e.pointerType === 'touch') return;
        const startState = !isChecked;
        onChange?.(startState);
        checkboxPointerDownStartUUID.set({ uuid: _uuid, startState });
        document.addEventListener('pointerup', onPointerUp);
        pointerUpEventListenerCallback.current = onPointerUp;
    };

    /**
     * When the point enters another checkbox we need to check if it's allowed to perform the toggle.
     * If the checkboxPointerDownStartUUID has a uuid that means it's currently actively trying to check additional boxes
     */
    function onPointerEnter() {
        const { uuid, startState } = get(checkboxPointerDownStartUUID);
        if (uuid) {
            onChange?.(startState);
        }
    }

    function onKeyUp(e: React.KeyboardEvent<HTMLButtonElement>) {
        if (e.code === 'Space') {
            onChange?.(!isChecked);
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        return () => {
            const { uuid } = get(checkboxPointerDownStartUUID);
            if (uuid === _uuid) {
                checkboxPointerDownStartUUID.set(baseState);
            }
        };
    }, []);

    /**
     * Cleanup the event listener
     */
    useEffect(() => {
        return () => {
            if (pointerUpEventListenerCallback.current) {
                document.removeEventListener('pointerup', pointerUpEventListenerCallback.current);
            }
        };
    }, []);

    // Prevent further pointer events from being triggered when page blurs
    useEventListener('blur', onPointerUp);

    /***** RENDER *****/
    return (
        <button
            disabled={disabled}
            role="checkbox"
            type="button"
            aria-checked={isChecked ? 'true' : 'false'}
            className={classNames('CheckBox', { 'CheckBox--active': isChecked, [`CheckBox--type-${type}`]: type }, className)}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onPointerEnter={onPointerEnter}
            onKeyUp={onKeyUp}
            {...intrinsic}
        >
            <span className="CheckBox__toggleBox" />
            {children}
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
