/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { Markdown as ReactMarkdown } from 'utilities/forkedLibraries/react-markdown';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TicketReplyForm from '../forms/ticketReplyForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Accordion from 'components/Accordion';
import Anchor from 'components/Anchor';
import OutlineButton from 'components/Buttons/OutlineButton';
import OutlineDropdown from 'components/Dropdowns/OutlineDropdown';
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import Avatar from 'components/Placeholders/Avatar';
import SolidTag from 'components/Tags/SolidTag';

/*   ACTIONS
 *****************************************************/
import { scrollToRef, truncateSimple } from 'utilities/methods/commonActions';
import { downloadAttachment, getIndividualTicket } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TicketView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

        this.formRef = createRef();

        this.expandMessages = this.expandMessages.bind(this);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getIndividualTicket, match } = this.props;
        const {
            params: { param4 }
        } = match;

        if (param4) getIndividualTicket(param4);
    }

    componentDidUpdate(prevProps) {
        const { formRef } = this;
        const {
            location: { search },
            match: {
                params: { param4 }
            },
            support_individual_ticket_status,
            getIndividualTicket
        } = this.props;

        if (search === '?reply=true' && support_individual_ticket_status === 'success') {
            return scrollToRef(formRef?.current);
        }

        if (param4 && param4 !== prevProps.match.params.param4) getIndividualTicket(param4);
    }

    expandMessages() {
        this.setState({
            expanded: true
        });
    }

    render() {
        const { expandMessages, formRef } = this;
        const { expanded } = this.state;
        const {
            app_viewport,
            history,
            support_individual_ticket_status,
            support_individual_ticket_meta,
            handleSubmitReply,
            openMarkResolved,
            toggleLightboxTemplate,
            match: {
                params: { param4 }
            },
            downloadAttachment,
            support_download_attachment_status
        } = this.props;

        const renderHeader = () => {
            if (support_individual_ticket_meta) {
                const {
                    subject,
                    department,
                    domain_name,
                    feedback_status,
                    service_affected,
                    service_name,
                    ticket_status,
                    invoice_id,
                    subaccount_name,
                    subaccount_domain
                } = support_individual_ticket_meta;

                const getTagColor = () => {
                    switch (ticket_status) {
                        case 'open':
                            return 'notice';
                        case 'resolved':
                        default:
                            return 'confirm';
                    }
                };

                let reopened = false;
                let referencedMask;
                const squareBrackets = subject.split(']')[0];
                if (squareBrackets.substr(1, 8) === 'Reopened') {
                    referencedMask = squareBrackets.split(' ')[2];
                    reopened = true;
                }

                const renderSmallScreenHeader = () => {
                    if (service_affected || service_name || domain_name || invoice_id || subaccount_name || subaccount_domain)
                        return (
                            <div className="accordion__wrapper">
                                <Accordion
                                    title="Show Service Details"
                                    content={
                                        <ul className="ticketViewHeader__bottomMobile">
                                            {invoice_id && (
                                                <li>
                                                    <div className="ticketViewHeader__key">Invoice Affected</div>
                                                    <div className="ticketViewHeader__value">{invoice_id}</div>
                                                </li>
                                            )}
                                            {service_affected && (
                                                <li>
                                                    <div className="ticketViewHeader__key">Service Affected</div>
                                                    <div className="ticketViewHeader__value">{service_affected}</div>
                                                </li>
                                            )}
                                            {service_name && (
                                                <li>
                                                    <div className="ticketViewHeader__key">Service</div>
                                                    <div className="ticketViewHeader__value">
                                                        {feedback_status === 'active' ? `[active] ` : ''}
                                                        {service_name}
                                                    </div>
                                                </li>
                                            )}
                                            {domain_name && (
                                                <li>
                                                    <div className="ticketViewHeader__key">Domain Name</div>
                                                    <div className="ticketViewHeader__value">{domain_name}</div>
                                                </li>
                                            )}
                                            {subaccount_name && (
                                                <li>
                                                    <div className="ticketViewHeader__key">Sub Account Username</div>
                                                    <div className="ticketViewHeader__value">{subaccount_name}</div>
                                                </li>
                                            )}
                                            {subaccount_domain && (
                                                <li>
                                                    <div className="ticketViewHeader__key">Sub Account Domain</div>
                                                    <div className="ticketViewHeader__value">{subaccount_domain}</div>
                                                </li>
                                            )}
                                        </ul>
                                    }
                                />
                            </div>
                        );

                    return '';
                };

                return (
                    <div className="ticketViewHeader__wrapper">
                        <div className="ticketViewHeader">
                            <div
                                className={`ticketViewHeader__top${
                                    !(service_affected || service_name || domain_name || invoice_id) ? ' ticketViewHeader__top--noBorderBottom' : ''
                                }`}
                            >
                                <div className="ticketViewHeader__meta">
                                    <div className="ticketViewHeader__metaTop">
                                        <div className="ticketViewHeader__subject">{truncateSimple(subject, 100, '...')}</div>
                                        <SolidTag className="ticketViewHeader__tag" color={getTagColor()}>
                                            {ticket_status}
                                        </SolidTag>
                                    </div>
                                    <div className="ticketViewHeader__metaBottom">
                                        <div className="ticketViewHeader__department">{department}</div>
                                        <div className="ticketViewHeader__circle"></div>
                                        <div className="ticketViewHeader__mask">{param4}</div>
                                        {reopened ? (
                                            <>
                                                <div className="ticketViewHeader__circle ticketViewHeader__circle--reopened"></div>
                                                <div className="ticketViewHeader__reopened">
                                                    {`Reopened from `}
                                                    <Anchor type="onClick" onClick={() => history.push(`/support/tickets/view/${referencedMask}`)}>
                                                        {referencedMask}
                                                    </Anchor>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                {ticket_status === 'resolved' ? (
                                    <OutlineButton
                                        type="onClick"
                                        onClick={() =>
                                            toggleLightboxTemplate('Issues', {
                                                id: param4,
                                                subject: subject
                                            })
                                        }
                                        className="ticketViewHeader__outlineButton"
                                    >
                                        Still having issues?
                                    </OutlineButton>
                                ) : (
                                    <OutlineDropdown
                                        title="Reply"
                                        titleOnClick={() => scrollToRef(formRef?.current)}
                                        list={[
                                            {
                                                label: 'Mark eTicket as Resolved',
                                                type: 'onClick',
                                                onClick: () => toggleLightboxTemplate('Resolve', { id: param4 })
                                            }
                                        ]}
                                    />
                                )}
                            </div>

                            {['md', 'lg', 'xl'].includes(app_viewport) && (service_affected || service_name || domain_name || invoice_id) && (
                                <>
                                    <div className="ticketViewHeader__bottom">
                                        {invoice_id && (
                                            <div className="ticketViewHeader__bottomLeft">
                                                <div className="ticketViewHeader__key">Invoice Affected</div>
                                                <div className="ticketViewHeader__value">{invoice_id}</div>
                                            </div>
                                        )}
                                        {service_affected && (
                                            <div className="ticketViewHeader__bottomLeft">
                                                <div className="ticketViewHeader__key">Service Affected</div>
                                                <div className="ticketViewHeader__value">{service_affected}</div>
                                            </div>
                                        )}
                                        {(service_name || domain_name) && (
                                            <div className="ticketViewHeader__bottomRight">
                                                <div className="ticketViewHeader__key">
                                                    {service_name && 'Service'}
                                                    {service_name && domain_name && ' / '}
                                                    {domain_name && 'Domain Name'}
                                                </div>
                                                <div className="ticketViewHeader__value">
                                                    {feedback_status === 'active' ? `[active] ` : ''}
                                                    {service_name && service_name}
                                                    {service_name && domain_name && ' / '}
                                                    {domain_name || ''}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {['md', 'lg', 'xl'].includes(app_viewport) && (subaccount_name || subaccount_domain) && (
                                        <div className="ticketViewHeader__bottom">
                                            {subaccount_name && (
                                                <div className="ticketViewHeader__bottomLeft">
                                                    <div className="ticketViewHeader__key">Sub Account Username</div>
                                                    <div className="ticketViewHeader__value">{subaccount_name}</div>
                                                </div>
                                            )}
                                            {subaccount_domain && (
                                                <div className="ticketViewHeader__bottomRight">
                                                    <div className="ticketViewHeader__key">Sub Account Domain</div>
                                                    <div className="ticketViewHeader__value">{subaccount_domain}</div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}

                            {['xs', 'sm'].includes(app_viewport) ? renderSmallScreenHeader() : ''}
                        </div>
                    </div>
                );
            }
            return '';
        };

        const renderSingleMessage = (item, collapsed) => {
            const {
                attributes: { createdDate, fullname, message, responseAge, is_worker, staff_title, attachment }
            } = item;

            const renderMessage = (message, className = 'ticketViewMessage__message') => {
                //replace line breaks with 2 spaces and a non-breaking space to correctly force double line breaks where required
                const htmlToMarkup = message.replace(/<br \/>/g, '&nbsp;  ');
                const classes = ({ bold, italic, ordered }) =>
                    classNames({
                        'ticketViewMessage__markdown': true,
                        'ticketViewMessage__markdown--bold': bold,
                        'ticketViewMessage__markdown--italic': italic,
                        'ticketViewMessage__markdown--ordered': ordered
                    });

                const mapComponents = {
                    strong: ({ node, ...props }) => <span {...props} className={classes({ bold: true })} />,
                    em: ({ node, ...props }) => <span {...props} className={classes({ italic: true })} />,
                    a: ({ node, ...props }) => {
                        const { href } = props;
                        const hostname = import.meta.env.HOST;

                        if (href.includes(hostname)) {
                            return (
                                <Anchor to={href.replace(hostname, '').trim()} {...props}>
                                    {href.trim()}
                                </Anchor>
                            );
                        }
                        return <Anchor {...props} />;
                    },
                    ol: ({ node, ...props }) => <ol {...props} className={classes({ ordered: true })} />
                };

                return (
                    <ReactMarkdown className={className} components={mapComponents} remarkPlugins={[remarkGfm]}>
                        {htmlToMarkup}
                    </ReactMarkdown>
                );
            };

            const renderExcerpt = () => {
                const withoutLineBreaks = message.replace(/<br \/>|\n/g, ' ');
                const textToRender = withoutLineBreaks.length > 100 ? `${withoutLineBreaks.substr(0, 100)}...` : withoutLineBreaks;

                return renderMessage(textToRender, 'ticketViewMessage__excerpt');
            };

            const renderAttachments = () => {
                return attachment?.length > 0 ? (
                    <div className="ticketViewMessage__attachments">
                        <h5>Attachments</h5>
                        {support_download_attachment_status === 'loading' ? (
                            <p className="ticketViewMessage__loading">Downloading attachment ...</p>
                        ) : (
                            <ul>
                                {attachment.map(({ name, size, id }) => (
                                    <li key={name}>
                                        <span>{name}</span>
                                        <span>({size})</span>
                                        <Anchor onClick={() => downloadAttachment(param4, id, name)}>
                                            <i className="icon icon-download" />
                                        </Anchor>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ) : (
                    ''
                );
            };

            const renderAvatar = () => {
                if (collapsed && !expanded && (app_viewport === 'xs' || app_viewport === 'sm'))
                    return is_worker ? (
                        <div className="ticketViewMessage__ventraLogo"></div>
                    ) : (
                        <Avatar size={40} fontSize={14} fullname={fullname} background="linear-gradient(-45deg, #38ef7d, #11998e)" />
                    );

                return '';
            };

            const classes = classNames({
                'ticketViewMessage': true,
                'ticketViewMessage--worker': is_worker,
                'ticketViewMessage--customer': !is_worker,
                'ticketViewMessage--collapsed': collapsed && !expanded
            });

            return (
                <div key={createdDate} className={classes}>
                    <div className="ticketViewMessage__body">
                        <div className="ticketViewMessage__time">
                            {responseAge} (<em>{createdDate}</em>)
                        </div>
                        {renderMessage(message)}
                        {renderExcerpt()}
                        {renderAvatar()}
                        {renderAttachments()}
                    </div>
                    <div className="ticketViewMessage__sender">
                        <div className="ticketViewMessage__ventraLogo"></div>
                        <Avatar
                            size={(collapsed && !expanded) || app_viewport === 'xs' || app_viewport === 'sm' ? 40 : 95}
                            fontSize={(collapsed && !expanded) || app_viewport === 'xs' || app_viewport === 'sm' ? 14 : 30}
                            fullname={fullname}
                            background={is_worker ? `linear-gradient(45deg,#fec813 0,#c52d85)` : `linear-gradient(-45deg, #38ef7d, #11998e)`}
                        />
                        <div className="ticketViewMessage__fullName">{fullname}</div>
                        {is_worker && <div className="ticketViewMessage__staffTitle">{staff_title}</div>}
                    </div>
                </div>
            );
        };

        const renderMessages = () => {
            const { support_individual_ticket_data: ticketData } = this.props;

            if (!ticketData) {
                return null;
            }

            if (ticketData.length <= 3 || expanded) {
                return ticketData.map((item, index) => renderSingleMessage(item, false, index));
            }

            const firstTwoMessages = ticketData.slice(0, 2).map((item, index) => renderSingleMessage(item, false, index));
            const collapsedMessages = ticketData.slice(2, -1).map((item, index) => renderSingleMessage(item, true, index));
            const lastMessage = renderSingleMessage(ticketData[ticketData.length - 1], false);

            return (
                <>
                    {firstTwoMessages}
                    <div className="ticketView__collapsedBorder"></div>
                    {collapsedMessages}
                    <div className="ticketView__collapsedBorder ticketView__collapsedBorder--top">
                        <div className="ticketView__expand">
                            2 Earlier Replies
                            <Anchor className="ticketView__expandAnchor" onClick={expandMessages}>
                                Expand All
                            </Anchor>
                        </div>
                    </div>
                    {lastMessage}
                </>
            );
        };

        const renderReplyForm = () => {
            const { support_individual_ticket_meta: ticketMeta } = this.props;

            if (!ticketMeta) {
                return null;
            }

            const { department, ticket_status, subject } = ticketMeta;
            const isPremium = subject.split(' ')[0].includes('[PREM]') || subject.split(' ')[0].includes('[FMVPS]');

            if (ticket_status === 'resolved') {
                return null;
            }

            return (
                <TicketReplyForm
                    formRef={formRef}
                    onSubmit={(values) => handleSubmitReply(values, department)}
                    department={department}
                    isPremium={isPremium}
                    openMarkResolved={openMarkResolved}
                    toggleLightboxTemplate={toggleLightboxTemplate}
                />
            );
        };

        const renderPage = () => {
            return (
                <div className="ticketView">
                    {renderHeader()}
                    {renderMessages()}
                    {renderReplyForm()}
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        switch (support_individual_ticket_status) {
            case 'error':
                return <FetchPageError />;
            case 'success':
                return renderPage();
            case 'loading':
            default:
                return <RequestLoader />;
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            app_viewport: state.app.app_viewport,
            support_individual_ticket_status: state.support.support_individual_ticket_status,
            support_individual_ticket_data: state.support.support_individual_ticket_data,
            support_individual_ticket_meta: state.support.support_individual_ticket_meta,
            support_download_attachment_status: state.support.support_download_attachment_status
        }),
        {
            getIndividualTicket,
            downloadAttachment
        }
    )(TicketView)
);
