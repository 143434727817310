/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import ChangePlanLightbox from 'components/Lightboxes/OverlayLightbox/Components/changePlan';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';

/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { downgradeEmailService, getEmailMailboxInformation, getEmailProducts, getMailboxList, upgradeDetails, upgradeEmailService } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import upgradeEmailImage from 'assets/images/email/email_banner_icon.svg';
import upgradeEmailBackground from 'assets/images/email/email_hosting_banner_background.png';

import { planNames } from '../consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EmailUpgrade extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showUpgradeLightbox: false,
            showInvoiceLightbox: false,
            invoiceID: null,
            lightboxTemplate: 'Upgrade',
            maxPlan: false,
            currentPlan: null,
            selectedPlan: null,
            selectedPlanName: null,
            selectedUpgradeMeta: {
                current: null,
                new: null,
                cycle: null,
                cost: null,
                discount: null,
                refunded: null
            }
        };

        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleLightboxTemplate = this.toggleLightboxTemplate.bind(this);
        this.handleConfirmBack = this.handleConfirmBack.bind(this);
        this.redirectDowngrade = this.redirectDowngrade.bind(this);
    }

    /************** OPEN/CLOSE PAY INVOICE **************/
    closePayInvoice() {
        const { emailid, mailbox, getEmailMailboxInformation } = this.props;

        this.setState(
            {
                showInvoiceLightbox: false,
                invoiceID: false
            },
            () => {
                getEmailMailboxInformation(mailbox, emailid);
            }
        );
    }

    closePayInvoiceSuccess() {
        const { emailid, mailbox, getMailboxList, getEmailMailboxInformation } = this.props;
        this.setState(
            {
                showAddLightbox: false,
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getMailboxList(emailid);
                getEmailMailboxInformation(mailbox, emailid);
            }
        );
    }

    /************** OPEN/CLOSE UPGRADE LIGHTBOX **************/
    toggleLightboxTemplate(template, open) {
        const { showUpgradeLightbox } = this.state;

        this.setState({
            showUpgradeLightbox: open ? true : !showUpgradeLightbox,
            lightboxTemplate: template ? template : 'Upgrade'
        });
    }

    handleConfirmBack() {
        const { toggleLightboxTemplate } = this;
        toggleLightboxTemplate('Plans', true);
    }

    redirectDowngrade() {
        const { email_information_mailbox_data, history } = this.props;
        const product = getIncludedObjBasedOnType(email_information_mailbox_data.included, 'product');
        history.push({
            pathname: `/support/tickets/submit/accounts-billing`,
            state: {
                selectedServices: {
                    id: email_information_mailbox_data.id,
                    title: email_information_mailbox_data.attributes.domain,
                    sub_title: product.attributes.name.replace('SYD-', '').replace('-', ' '),
                    status: email_information_mailbox_data.attributes.status,
                    type: email_information_mailbox_data.type
                },
                relatedService: true,
                redirect: true
            }
        });
    }

    /************** HANDLE UPGRADE REQUEST **************/
    componentDidMount() {
        const { email_information_mailbox_data } = this.props;

        const { included } = email_information_mailbox_data;
        const invoice = getIncludedObjBasedOnType(included, 'invoice');
        const product = getIncludedObjBasedOnType(included, 'product');

        this.setState({
            currentPlan: product && product.attributes.name ? product.attributes.name : 'Not Available',
            invoiceID: invoice ? invoice.id : false
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            email_product_list_status,
            email_upgrade_status,
            email_upgrade_data,
            email_upgrade_details_status,
            email_information_mailbox_data,
            upgradeDetails
        } = this.props;
        const { lightboxTemplate, selectedPlan, selectedUpgradeMeta } = this.state;
        const { id } = email_information_mailbox_data;

        registerScrollEvents(this, email_product_list_status === 'success' && prevProps.email_product_list_status === 'loading');

        if (email_product_list_status === 'success' && prevProps.email_product_list_status === 'loading' && !!email_information_mailbox_data) {
            const { included } = email_information_mailbox_data;
            const product = getIncludedObjBasedOnType(included, 'product');

            this.setState({
                currentPlan: product.attributes.name,
                maxPlan: product.attributes.name === 'AXIGEN-PLUS' ? true : false
            });
        }

        if (email_upgrade_status === 'success' && prevProps.email_upgrade_status === 'loading') {
            const { id } = email_upgrade_data;

            this.setState({
                showUpgradeLightbox: false,
                showInvoiceLightbox: true,
                invoiceID: id
            });
        }

        if (
            (email_upgrade_status === 'error' && prevProps.email_upgrade_status === 'loading') ||
            (email_upgrade_details_status === 'error' && prevProps.email_upgrade_details_status === 'loading')
        ) {
            this.setState({
                showUpgradeLightbox: false,
                lightboxTemplate: null,
                invoiceID: null
            });
        }

        if (lightboxTemplate !== prevState.lightboxTemplate && lightboxTemplate === 'Confirm') {
            const attributes = {
                product_id: selectedPlan,
                billing_cycle: selectedUpgradeMeta[2].value
            };

            upgradeDetails(id, attributes);
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { emailid, email_product_list_status, email_upgrade_status, getEmailProducts } = this.props;
        const { currentPlan, showInvoiceLightbox, selectedUpgradeMeta, showUpgradeLightbox, lightboxTemplate, invoiceID } = this.state;
        const { closePayInvoice, handleConfirmBack, toggleLightboxTemplate, closePayInvoiceSuccess, redirectDowngrade } = this;

        /*   RENDER LIGHTBOX
         **********************************************************************************************************/
        const renderLightbox = () => {
            switch (lightboxTemplate) {
                case 'Downgrade':
                    return (
                        <OverlayLightbox
                            onOpen={showUpgradeLightbox}
                            onClose={toggleLightboxTemplate}
                            title="Downgrade Email Hosting Service"
                            confirm={{
                                desc: htmr(
                                    `In order to downgrade this service, you will need to submit an eTicket to our Accounts and Billing team.`
                                ),
                                buttonText: 'Submit an eTicket',
                                buttonAction: redirectDowngrade,
                                closeText: 'No, Go Back',
                                closeAction: handleConfirmBack
                            }}
                        />
                    );

                case 'Plan':
                default: {
                    const {
                        mailbox: mailboxID,
                        email_product_list_data,
                        email_information_mailbox_data,
                        email_product_list_status,
                        email_upgrade_details_status,
                        email_upgrade_details_data,
                        upgradeDetails
                    } = this.props;

                    const { selectedPlanName } = this.state;

                    const {
                        attributes: { billing_cycle },
                        included
                    } = email_information_mailbox_data;

                    const name = getIncludedObjBasedOnType(included, 'product')?.attributes?.name;

                    // Currently all services have the same billing cycles
                    const availableBillingCycles = Object.keys(email_product_list_data?.[0]?.attributes?.price || {});
                    const order = ['EMAIL-STARTER', 'EMAIL', 'EMAIL-PLUS'];

                    const planOptions = email_product_list_data
                        ?.map((data) => {
                            const {
                                attributes: { name, description, price, details }
                            } = data;
                            return {
                                name: name.replace('SYD-', '').trim(),
                                description: description,
                                price: (cycle) => price[cycle].base_price,
                                details: details,
                                rawData: data
                            };
                        })
                        .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

                    const { new_plan_cost: price, refunded_amount: refund, amount_due: total } = email_upgrade_details_data?.attributes || {};
                    const reviewData = {
                        price,
                        refund,
                        total,
                        name: selectedPlanName
                    };

                    const onSelectPlan = ({ rawData, cycle, name: planName }) => {
                        if (order.indexOf(planName) < order.indexOf(name)) {
                            this.setState({ lightboxTemplate: 'Downgrade' });
                        }

                        upgradeDetails(mailboxID, {
                            product_id: rawData.id,
                            billing_cycle: cycle
                        });

                        this.setState({
                            selectedPlanName: planName
                        });
                    };

                    const onConfirm = ({ cycle, rawData }) => {
                        const { upgradeEmailService } = this.props;

                        if (selectedUpgradeMeta) {
                            upgradeEmailService(mailboxID, {
                                product_id: rawData.id,
                                billing_cycle: cycle
                            });
                        }
                    };

                    return (
                        <ChangePlanLightbox
                            isOpen={true}
                            billingCycle={{ current: billing_cycle, available: availableBillingCycles }}
                            upgradeType="Email Hosting"
                            planOptions={planOptions}
                            reviewData={reviewData}
                            currentPlan={name.replace('SYD-', '').trim()}
                            onClose={toggleLightboxTemplate}
                            onSelectPlan={onSelectPlan}
                            onConfirm={onConfirm}
                            isLoading={email_upgrade_details_status === 'loading' || email_product_list_status === 'loading'}
                        />
                    );
                }
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="emailUpgradeRef"
            >
                {currentPlan === 'AXIGEN-PLUS' ? (
                    ''
                ) : (
                    <div className="emailUpgrade">
                        <Box
                            request={{
                                action: getEmailProducts,
                                args: null,
                                status: email_product_list_status
                            }}
                            className="emailUpgrade__box"
                            status={email_upgrade_status}
                            override={
                                <div className="emailUpgrade__container">
                                    <ServiceOverviewBanner slim src={upgradeEmailBackground}>
                                        <ServiceOverviewBanner.ContentWrapper>
                                            <ServiceOverviewBanner.Heading size--custom={26}>Upgrade Your Email Plan</ServiceOverviewBanner.Heading>
                                            <ServiceOverviewBanner.Description>
                                                Store more emails by increasing the disk space on your mailbox
                                            </ServiceOverviewBanner.Description>
                                        </ServiceOverviewBanner.ContentWrapper>
                                        <ServiceOverviewBanner.Image src={upgradeEmailImage} alt="Upgrade Your Email Plan" />
                                    </ServiceOverviewBanner>

                                    <div className="emailUpgrade__wrapper--bottom">
                                        <div className="emailUpgrade__information">
                                            <div className="emailUpgrade__title">Current Plan</div>
                                            <div className="emailUpgrade__description">{planNames[currentPlan]}</div>
                                        </div>
                                        <div className="emailUpgrade__action">
                                            {invoiceID ? (
                                                <InactiveButton className="emailUpgrade__button">View Plans</InactiveButton>
                                            ) : (
                                                <OutlineButton
                                                    type="onClick"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        toggleLightboxTemplate('Upgrade');
                                                    }}
                                                    className="emailUpgrade__button"
                                                >
                                                    View Plans
                                                </OutlineButton>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                        {showUpgradeLightbox ? renderLightbox() : ''}
                        {showInvoiceLightbox && (
                            <OverlayLightbox
                                title={'Pay Invoice #' + invoiceID}
                                invoiceid={invoiceID}
                                onOpen={showInvoiceLightbox}
                                onClose={closePayInvoice}
                                onSuccessClose={closePayInvoiceSuccess}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        email_information_mailbox_data: state.email.email_information_mailbox_data,
        email_product_list_status: state.email.email_product_list_status,
        email_product_list_data: state.email.email_product_list_data,
        email_upgrade_status: state.email.email_upgrade_status,
        email_upgrade_data: state.email.email_upgrade_data,
        email_upgrade_details_status: state.email.email_upgrade_details_status,
        email_upgrade_details_data: state.email.email_upgrade_details_data
    };
};

const mapDispatchToProps = {
    getMailboxList,
    getEmailMailboxInformation,
    upgradeEmailService,
    downgradeEmailService,
    getEmailProducts,
    upgradeDetails
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailUpgrade));
