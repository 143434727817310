/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { BUTTON_COLORS } from 'components/Buttons/_BaseButton';
import { renderCheckboxField, requiredAcceptedValidation } from 'utilities/methods/form';

const formName = 'ClearForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ClearForm = ({ resetCert, closeLightbox }) => (
    <div className="cancelForm__container CancelForm">
        <div className="cancelForm__description">You are about to start the SSL Certificate configuration process from the beginning.</div>

        <div className="cancelForm__description">
            Starting over means clearing the current configuration on your SSL Certificate. Any progress you&apos;ve currently made will be wiped and
            you&apos;ll be forced to start the configuration process from the beginning.
        </div>

        <div className="confirmation__container">
            <h5 className="confirmation__heading">Confirmation</h5>
            <div className="confirmation__input">
                <Field
                    name="clearConfiguration"
                    label="You acknowledge that this process is instant. Once the SSL Certificates current configuration has been cleared,
                    you will have to restart the configuration process from the beginning."
                    component={renderCheckboxField}
                    validate={[requiredAcceptedValidation]}
                    type="checkbox"
                    className="form__textfield cancelForm__checkbox"
                />
            </div>
        </div>

        <ReduxFormButton form={formName} onClick={resetCert} color={BUTTON_COLORS.CONFIRM}>
            Continue
        </ReduxFormButton>

        <Anchor className="OverlayConfirm__commonlink" onClick={closeLightbox}>
            No, Go Back
        </Anchor>
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ClearForm = reduxForm({
    form: formName
})(ClearForm);

export default ClearForm;
