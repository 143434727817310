/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DismissibleBanner from 'components/DismissibleBanner';
import SolidButton from 'components/Buttons/SolidButton';
import DismissButton from 'components/DismissibleBanner/DismissButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_provideFeedbackBanner.scss';
import cashImage from 'assets/images/support/cash.png';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TProvideFeedbackBanner = React.FC<{
    handleSurveyOnClick: () => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ProvideFeedbackBanner: TProvideFeedbackBanner = ({ handleSurveyOnClick }) => {
    /***** RENDER *****/
    return (
        <DismissibleBanner localStorageKey="feedbackBannerDismissed">
            {(dismiss) => (
                <div className="provideFeedbackBanner">
                    <div className="provideFeedbackBanner__imageContainer">
                        <img className="provideFeedbackBanner__image" src={cashImage} alt="Win $50 account credit!" />
                    </div>
                    <div className="provideFeedbackBanner__content">
                        <div className="provideFeedbackBanner__text">
                            <div className="provideFeedbackBanner__heading">Win $50 account credit!</div>
                            <div className="provideFeedbackBanner__paragraph">
                                Go in the draw to win $50 account credit at the end of the month when you provide feedback on resolved eTickets.
                            </div>
                        </div>
                        <SolidButton type="onClick" onClick={handleSurveyOnClick} color="white">
                            Provide Feedback
                        </SolidButton>
                    </div>
                    <DismissButton onClick={dismiss} />
                </div>
            )}
        </DismissibleBanner>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
