/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**
 * @deprecated use v5 format, see katanaQuery
 */
const usePreviewSplitInvoiceBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'PREVIEW_SPLIT_INVOICE',
    APIRoute: (data) => () => ACCOUNT_API.user.invoice.single.split.preview.POST(data),
    defaultOptions: {
        onError: () => handleDefaultErrorNotification,
        enabled: (data) => !!(data.invoiceID && data.itemIds?.length > 0)
    }
});

const { getDefaults } = usePreviewSplitInvoiceBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * Split an invoice
 */
function usePreviewSplitInvoiceQuery(data, options = {}) {
    return useQuery({
        ...getDefaults(data),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { usePreviewSplitInvoiceBoilerPlate, usePreviewSplitInvoiceQuery };
