/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { dev_api as devAPI, api as prodAPI } from 'config/config';
import store from 'store/store';

function configureSentryEmailScope() {
    const { app } = store.getState();
    const email = app?.app_user_data?.email;

    try {
        Sentry.configureScope((scope) => {
            scope.setUser({ email });
        });
    } catch (error) {
        console.error('Failed to attach user context to Sentry.');
    }
}

export function loginGetUserSuccessSideEffects() {
    configureSentryEmailScope();
}
export const API_BASE_URL = import.meta.env.VITE_CCP_ENVIRONMENT === 'development' ? devAPI : prodAPI;
/**
 * This function can be used to set axios defaults on either the global axios, or an instance of axios.
 */
export function setAxiosDefaults(instance = null) {
    const axiosInstance = instance || axios;
    axiosInstance.defaults.baseURL = API_BASE_URL;

    if (window?.ccpIsAppWebview) {
        const accessToken = localStorage.getItem('access_token');
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
        axiosInstance.defaults.withCredentials = true;
    }
}
