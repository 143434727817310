/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import { createContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

export const sectionDefinitionStylesPropType = PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    key: PropTypes.string,
    component: PropTypes.string
});

export const SECTION_DEFINITION_PROPERTY_TYPES = /** @type {const} */ ({
    BOOLEAN: 'boolean',
    CHOICE: 'choice',
    FILE: 'file',
    GROUP: 'group',
    NUMBER: 'number',
    REPEATED: 'repeated',
    TEXT: 'text'
});
export const SECTION_DEFINITION_PROPERTY_TYPES_VALUES = Object.values(SECTION_DEFINITION_PROPERTY_TYPES);

export const SectionDefinitionContext = createContext(/** @type {Katana.SectionDefinitions.ValuesModifiedPropertiesAndValidations} */ ({}));
