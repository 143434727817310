/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { getNextPageParamDefault } from 'utilities/methods/queries/getNextPageParamDefault';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {Parameters<typeof KATANA_API.katana.GET>[0]} Filters
 */

const baseFilter = {
    page: 1,
    sort: 'desc',
    // sort_by: 'id'
    record_per_page: 50
};

/**
 * @param {Filters} filters
 */
function createQueryKey(filters) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service_list', filters]));
}

/**
 * @param {Filters} filters

 **/
function createQueryOptions(filters) {
    /**
     * The order to which you pass the keys in MATTERS!!!
     * 1. queryKey
     * 2. queryFn
     * 3. initialPageParam
     * 4. getNextPageParam
     * 5. select
     */
    return infiniteQueryOptions({
        queryKey: createQueryKey(filters),
        queryFn: async (queryFunctionContext) => {
            const { pageParam = filters } = queryFunctionContext;
            return KATANA_API.katana.GET(pageParam).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            });
        },
        initialPageParam: filters,
        getNextPageParam: (lastPage) => {
            return getNextPageParamDefault({ lastPage, filters });
        },
        select: (data) => {
            const firstPageMeta = data?.pages?.[0]?.meta;
            const dataFlatMap = data.pages.map((page) => page.data);
            return {
                ...data,
                pages: dataFlatMap,
                meta: firstPageMeta
            };
        }
    });
}

/**
 * @param {Filters} filters
 */
function invalidateQueries(filters = baseFilter) {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey(filters)
    });
}

/**
 * List Katana Sites
 * @param {Filters} [filters]
 */
function _useInfiniteQuery(filters = baseFilter) {
    return useInfiniteQuery(createQueryOptions(filters));
}

export const getSiteList = Object.freeze({
    useInfiniteQuery: _useInfiniteQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions
});
