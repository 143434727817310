/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _, { has, keys, merge } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getContentDefinitionFormName } from 'containers/katana/components/contentDefinitionForm/methods';
import { useRecursiveActiveValidFormValues } from 'containers/katana/containers/ContentEditorLightbox/methods/recursiveWalkToOmitKeysByValidationRules';
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import useDebounce from 'utilities/hooks/useDebounce';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

/**
 * @param {boolean} [enabled]
 * @param {import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes} [siteData]
 */
export function useGetContentEditorSiteSectionPreviewQuery(enabled = false, siteData) {
    /***** STATE *****/
    const [queryData, setQueryData] = useState(
        /** @type {KatanaQuery.ServiceID.GetSectionSitePreview.QueryParams} */ ({ serviceID: null, sectionID: null, katanaSectionID: null })
    );

    /***** HOOKS *****/
    const { id, childpage } = useSetupEditorRouteParams();

    const isChildPageID = _.isNumber(childpage);

    const { data: get_section_definition_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useByRouteKeyQuery(id);
    const { data: get_sections_data } = katanaQuery.serviceID.getSections.useQuery(id);
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);
    const { data: get_katana_site_sections_data } = katanaQuery.serviceID.getSections.useQuery(id);
    const sectionID = get_katana_site_sections_data?.find(({ id }) => id === childpage)?.section_id;

    const childPageRouteKey = isChildPageID && sectionID ? getSectionNameRouteKey(get_katana_section_definitions_data?.[sectionID]?.name) : childpage;
    const definitionID = useMemo(() => get_section_definition_data?.[childPageRouteKey]?.id, [get_section_definition_data, childPageRouteKey]);
    const formName = useMemo(() => {
        if (!has(get_section_definition_data, childPageRouteKey)) return null;
        return getContentDefinitionFormName(definitionID);
    }, [childPageRouteKey, get_section_definition_data, definitionID]);

    const activeFormData = useSelector((state) => state.form?.[formName] ?? {});

    const finalFormValues = useRecursiveActiveValidFormValues(
        activeFormData?.values,
        activeFormData?.syncErrors,
        get_section_definition_data?.[childPageRouteKey]
    );

    const section = useMemo(() => get_sections_data?.find?.((section) => section.section_id === definitionID), [get_sections_data, definitionID]);
    // Need to add no updater here if the object does not meet requirements
    useDebounce(
        () => {
            /** @type {KatanaQuery.ServiceID.GetSectionSitePreview.QueryParams} */
            let newQueryData = {
                serviceID: id,
                sectionID: isChildPageID ? childpage : section?.id || 'new',
                katanaSectionID: definitionID,
                ...finalFormValues
            };

            if (keys(siteData).length) {
                newQueryData = merge({}, newQueryData, { site: siteData });
            }
            setQueryData(newQueryData);
        },
        300,
        [id, section, finalFormValues, siteData, childpage]
    );

    /***** HOOK RESULTS *****/
    return katanaQuery.serviceID.getSectionSitePreview.useQuery({ ...queryData, enabled });
}
