/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { validatorNumber } from 'utilities/methods/validators';
import { checkDomainValidation, checkImapCredentialValidations, checkIpValidations, checkIsTechnical, checkRequiredFields } from '../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { migrationFormNameDetails, migrationFormNameFull } from '../consts';
import { controlPanelDetailsRequiredFields } from './consts';

const controlPanelAllRequiredFields = [
    ...controlPanelDetailsRequiredFields,
    'delete_data',
    'email_reconfig',
    'incompatible_check',
    'migration_date',
    'migration_time'
];

const controlPanelSSHDetailsRequiredFields = ['ssh_username', 'ssh_password', 'ssh_port'];

/**
 * Validate specific type details for cpanel details
 */
function validateControlPanelDetailTypes(values, errors) {
    errors = checkIpValidations(values, errors);

    errors = checkDomainValidation(values, errors);

    return errors;
}

/**
 * Validating the control panel details
 * @param values
 * @return {*|{}}
 */
export const ControlPanelDetailsValidation = (values) => {
    let errors = {};

    errors = validateControlPanelDetailTypes(values, errors);

    errors = checkRequiredFields(values, errors, controlPanelDetailsRequiredFields);

    return errors;
};

/**
 * Validate all control panel fields
 * @param values
 * @return {*|{}}
 */
export const ControlPanelFormValidation = (values) => {
    let errors = {};

    errors = validateControlPanelDetailTypes(values, errors);

    errors = checkRequiredFields(values, errors, controlPanelAllRequiredFields);

    if (values['ssh_check'] === true) {
        if (!validatorNumber(values['ssh_port'])) {
            errors['ssh_port'] = 'The port needs to be a number';
        }

        errors = checkRequiredFields(values, errors, controlPanelSSHDetailsRequiredFields);
    }

    errors = checkIsTechnical(values, errors);

    errors = checkImapCredentialValidations(values, errors);

    return errors;
};

export const MigrationReduxControlPanelFormPart1 = {
    form: migrationFormNameDetails,
    forceUnregisterOnUnmount: true,
    validate: ControlPanelDetailsValidation
};

export const MigrationReduxControlPanelFormPart2 = {
    form: migrationFormNameFull,
    forceUnregisterOnUnmount: true,
    validate: ControlPanelFormValidation,
    initialValues: {
        ssh_check: false
    }
};
