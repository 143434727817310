/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { activeProducts } from 'config/config';
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import upgradeimg from 'assets/images/hosting/hosting_image_upgrade.svg';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import UpgradeConfirm from '../forms/upgradeConfirm';
import UpgradeDropdown from '../forms/upgradeDropdown';
import UpgradeForm from '../forms/upgradeSelect';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/

import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import InfoNotification from 'components/Notifications/InfoNotification';

/*   ACTIONS
 *****************************************************/
import { getHostingResourceConfig } from 'containers/hosting/state/baseActions';
import { getIncludedObjBasedOnType, scrollToComponent } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { calculateHostingUpgradeCost, getHostingInformation, getHostingProducts, upgradeLegacyService } from '../state/accountActions';

const legacyProducts = [
    'SAU-LEGACY-1',
    'SAU-LEGACY-2',
    'SAU-LEGACY-4',
    'SAU-WHM-LEGACY-3',
    'Web Access Windows Legacy',
    'Web Access Linux Legacy',
    'Summit Basic Hosting',
    'Summit Standard Hosting',
    'Summit Advanced Hosting',
    'Summit Premium Hosting',
    'LEGACY-INTASERVE-STARTER',
    'LEGACY-INTASERVE-UNLIMITED',
    'LEGACY-FORLIFE-1',
    'SYD-WHMMULTI',
    'SYD-WHMMULTI-PLUS',
    'MEL-WHMMULTI',
    'MEL-WHMMULTI-PLUS',
    'NetOrigin Accelerate Hosting',
    'NetOrigin Accelerate+ Hosting',
    'Hosting',
    'Hosting with Power Pack',
    'Multi Hosting',
    'Multi Hosting with Power Pack',
    'MEL-ECONOMY-SAVER',
    'MEL-ECONOMY',
    'MEL-ECONOMY-PLUS',
    'MEL-BUSINESS-LITE',
    'MEL-BUSINESS',
    'MEL-BUSINESS-PLUS',
    'SYD-ECONOMY-SAVER',
    'SYD-ECONOMY',
    'SYD-ECONOMY-PLUS',
    'SYD-BUSINESS-LITE',
    'SYD-BUSINESS',
    'SYD-BUSINESS-PLUS',
    'Starter',
    'Freedom',
    'Premier',
    'Zuver Hosting',
    'Zuver Hosting with Power Pack',
    'Zuver Multi Hosting',
    'Zuver Multi Hosting with Power Pack',
    'Zuver Smart',
    'Zuver Smarter',
    'Zuver Smartest'
];

const upgradePricingLabels = {
    new_plan_cost: 'New Plan Cost',
    amount_due: 'Total Due Today',
    credit_amount: 'Credit',
    prepaid_amount: 'Deductions',
    addons: 'Selected Addons',
    discount_amount: 'Discount',
    refunded_amount: 'Refunded Amount',
    pro_rata_cost: 'Pro rata Cost'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class HostingUpgrade extends Component {
    constructor(props) {
        super(props);
        this.toggleInvoiceLightbox = this.toggleInvoiceLightbox.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleTemplateLightbox = this.toggleTemplateLightbox.bind(this);
        this.submitLegacyRequest = this.submitLegacyRequest.bind(this);
        this.toggleUpgradeDropdown = this.toggleUpgradeDropdown.bind(this);
        this.setUpgradeType = this.setUpgradeType.bind(this);
        this.handleConfirmBack = this.handleConfirmBack.bind(this);
        this.handleSelectedPlan = this.handleSelectedPlan.bind(this);
        this.handleCustomHostingConfirmation = this.handleCustomHostingConfirmation.bind(this);
        this.state = {
            showUpgradeDropdown: false,
            showTemplateLightbox: false,
            lightboxTemplate: 'upgrade',
            upgradeType: 'custom',
            invoiceID: null,
            legacy: false,
            selectedData: {},
            selectedMeta: {
                current: null,
                new: null,
                cycle: null,
                cost: null,
                deductions: null
            },
            selectedPlan: null,
            currentPlan: null
        };
    }

    /************** INVOICE LIGHTBOX TOGGLES **************/

    toggleInvoiceLightbox(id) {
        this.setState({
            showUpgradeDropdown: false,
            showTemplateLightbox: !!id,
            lightboxTemplate: id ? 'invoice' : false,
            invoiceID: id ? id : null
        });
    }

    closePayInvoiceSuccess() {
        const { getHostingInformation, hostingid } = this.props;

        this.setState(
            {
                showUpgradeDropdown: false,
                showTemplateLightbox: false,
                lightboxTemplate: 'upgrade',
                upgradeType: 'custom',
                invoiceID: null
            },
            () => {
                getHostingInformation(hostingid);
            }
        );
    }

    /************** TEMPLATE LIGHTBOX TOGGLES **************/
    toggleTemplateLightbox(template) {
        this.setState({
            showTemplateLightbox: template ? true : !this.state.showTemplateLightbox,
            lightboxTemplate: template ? template : 'upgrade'
        });
    }

    setUpgradeType(type) {
        this.setState(
            {
                upgradeType: type
            },
            () => {
                this.toggleTemplateLightbox('upgrade');
            }
        );
    }

    /************** RESOURCE TOGGLES **************/

    toggleUpgradeDropdown() {
        this.setState({
            showUpgradeDropdown: !this.state.showUpgradeDropdown
        });
    }

    /************** RESOURCE SUBMIT **************/
    submitLegacyRequest() {
        const { hostingid, upgradeLegacyService, hosting_upgrade_list_data } = this.props;
        const { selectedMeta, selectedData, selectedPlan } = this.state;
        const migrationDate = DateTime.local().plus({ days: 1 }).toFormat('dd MMMM yyyy');

        function getPlanConfig() {
            if (selectedData.config) {
                return Object.keys(selectedData.config).map((key) => {
                    return { config_id: selectedData.config[key].config_id, option_id: selectedData.config[key].option_id };
                });
            }

            return hosting_upgrade_list_data
                .filter((plan) => plan.attributes.name === selectedData.name)
                .map((filteredPlan) => filteredPlan.attributes.config)[0];
        }

        if (selectedMeta) {
            const payload = {
                product_id: selectedPlan,
                billing_cycle: selectedMeta.filter((meta) => meta.title === 'Billing Cycle')[0].value,
                date: migrationDate,
                time: `Early Morning (00:00 - 09:00)`,
                configs: getPlanConfig()
            };

            upgradeLegacyService(hostingid, payload);
        }
    }

    /************** STATE FUNCTIONS **************/

    handleConfirmBack() {
        this.toggleTemplateLightbox('upgrade');
    }

    handleSelectedPlan(selected) {
        const { hostingid, calculateHostingUpgradeCost } = this.props;
        const { currentPlan } = this.state;
        const { id, billingCycle, config } = selected;

        const attributes = {
            product_id: id,
            config,
            billing_cycle: billingCycle
        };

        this.setState(
            {
                selectedPlan: id,
                selectedData: {
                    ...selected,
                    current: currentPlan
                },
                lightboxTemplate: 'loading'
            },
            () => calculateHostingUpgradeCost(hostingid, attributes)
        );
    }

    handleCustomHostingConfirmation(billingCycle, config, addons, pricing) {
        const selectedMeta = [
            {
                title: '<strong>NEW</strong> Hosting Plan',
                value: 'Select Hosting'
            },
            {
                title: 'Billing Cycle',
                value: billingCycle
            },
            {
                title: 'CPU',
                value: config.cpu.name
            },
            {
                title: 'Memory',
                value: config.mem.name
            },
            {
                title: 'Disk Space',
                value: config.disk.name
            }
        ];

        Object.keys(pricing).forEach((price) => {
            if (parseFloat(pricing[price].value) > 0) {
                selectedMeta.push({
                    title: upgradePricingLabels[price],
                    value: '$' + pricing[price].value
                });
            }
        });

        const selectedData = {
            name: 'Select Hosting',
            config,
            addons,
            total: `$${pricing.amount_due.value}`,
            billingCycle: billingCycle
        };

        this.setState({
            selectedData,
            selectedMeta,
            lightboxTemplate: 'confirm'
        });
    }

    /************** COMPONENT STATES **************/

    componentDidMount() {
        const { hosting_isReseller, getHostingProducts, hosting_information_data } = this.props;
        const { included } = hosting_information_data;

        const product = getIncludedObjBasedOnType(included, 'product');
        const invoice = getIncludedObjBasedOnType(included, 'invoice');
        const p = product.attributes.name;

        this.setState(
            {
                currentPlan: p,
                legacy: legacyProducts.includes(p),
                invoiceID: invoice && invoice.id ? invoice.id : false
            },
            () => {
                hosting_isReseller ? getHostingProducts('reseller') : getHostingProducts('shared');
            }
        );
    }

    componentDidUpdate(prevProps) {
        const {
            location,
            hosting_upgrade_status,
            hosting_upgrade_data,
            hosting_upgrade_list_status,
            hosting_upgrade_calculate_status,
            hosting_upgrade_calculate_data,
            hosting_information_data,
            scrollToComponent
        } = this.props;
        const { selectedData, showUpgradeDropdown } = this.state;

        registerScrollEvents(this, true);

        if (location !== prevProps.location && location.pathname.includes('upgrade')) {
            const query = new URLSearchParams(location.search);
            const open = query.get('open');

            if (open) {
                const { included } = hosting_information_data;
                const invoice = getIncludedObjBasedOnType(included, 'invoice');

                if (!invoice && !showUpgradeDropdown) {
                    this.toggleUpgradeDropdown();
                    scrollToComponent(this.scrollRef, location.pathname);
                }
            }
        }

        if (
            hosting_upgrade_calculate_status === 'success' &&
            prevProps.custom_hosting_calculate_status === 'loading' &&
            this.state.lightboxTemplate === 'loading'
        ) {
            const { attributes } = hosting_upgrade_calculate_data;
            const { amount_due } = attributes;

            const selectedMeta = [
                {
                    title: 'Current Plan',
                    value: selectedData.current
                },
                {
                    title: '<strong>NEW</strong> Hosting Plan',
                    value: selectedData.name
                },
                {
                    title: 'Billing Cycle',
                    value: selectedData.billingCycle
                }
            ];

            Object.keys(attributes).forEach((price) => {
                if (parseFloat(attributes[price]) > 0) {
                    selectedMeta.push({
                        title: upgradePricingLabels[price],
                        value: '$' + attributes[price]
                    });
                }
            });

            this.setState({
                selectedMeta,
                selectedData: {
                    ...selectedData,
                    total: '$' + amount_due
                },
                lightboxTemplate: 'confirm'
            });
        }

        if (hosting_upgrade_status === 'success' && prevProps.hosting_upgrade_status === 'loading') {
            const { included } = hosting_information_data;
            const product = getIncludedObjBasedOnType(included, 'product');
            const p = product.attributes.name;

            this.setState(
                {
                    showLightbox: false,
                    currentPlan: p,
                    maxPlan: activeProducts.max.includes(p),
                    legacy: legacyProducts.includes(p)
                },
                () => {
                    if (hosting_upgrade_data.type === 'invoice') {
                        this.setState({
                            showTemplateLightbox: true,
                            lightboxTemplate: 'invoice',
                            invoiceID: hosting_upgrade_data.id
                        });
                    }
                    getHostingProducts('shared');
                }
            );
        }

        if (hosting_upgrade_status === 'error' && prevProps.hosting_upgrade_status === 'loading') {
            this.setState({
                showLightbox: false,
                lightboxTemplate: null,
                invoiceID: null
            });
        }

        if (hosting_upgrade_list_status === 'success' && prevProps.hosting_upgrade_list_status === 'loading') {
            const { included } = hosting_information_data;
            const product = getIncludedObjBasedOnType(included, 'product');
            const p = product.attributes.name;

            this.setState({
                currentPlan: p,
                legacy: legacyProducts.includes(p)
            });
        }

        if (hosting_upgrade_status === 'error' && prevProps.hosting_upgrade_status === 'loading') {
            this.setState({
                lightboxTemplate: 'confirm'
            });
        }
    }

    /************** RENDER BEGIN **************/

    render() {
        const {
            hostingid,
            hosting_isReseller,
            hosting_upgrade_status,
            hosting_upgrade_list_status,
            hosting_resource_config_status,
            getHostingResourceConfig
        } = this.props;
        const {
            showUpgradeDropdown,
            showTemplateLightbox,
            lightboxTemplate,
            invoiceID,
            legacy,
            selectedMeta,
            selectedData,
            upgradeType,
            currentPlan
        } = this.state;
        const {
            toggleInvoiceLightbox,
            closePayInvoiceSuccess,
            toggleTemplateLightbox,
            toggleUpgradeDropdown,
            setUpgradeType,
            submitLegacyRequest,
            handleConfirmBack,
            handleSelectedPlan,
            handleCustomHostingConfirmation
        } = this;

        const renderLightbox = () => {
            switch (lightboxTemplate) {
                case 'upgrade':
                    return (
                        <OverlayLightbox
                            onOpen={showTemplateLightbox}
                            className="hostingUpgrade__lightbox"
                            onClose={toggleTemplateLightbox}
                            title="Choose a new Plan"
                        >
                            <UpgradeForm
                                hostingid={hostingid}
                                type={upgradeType}
                                handleSelectedPlan={handleSelectedPlan}
                                handleCustomHostingConfirmation={handleCustomHostingConfirmation}
                            />
                        </OverlayLightbox>
                    );

                case 'confirm':
                    return (
                        <OverlayLightbox
                            onOpen={showTemplateLightbox}
                            onClose={toggleTemplateLightbox}
                            title="Review Hosting Upgrade"
                            loading={hosting_upgrade_status}
                        >
                            <UpgradeConfirm
                                selectedMeta={selectedMeta}
                                selectedData={selectedData}
                                onSubmit={submitLegacyRequest}
                                handleBack={handleConfirmBack}
                            />
                        </OverlayLightbox>
                    );

                case 'invoice':
                    return (
                        <OverlayLightbox
                            onOpen={showTemplateLightbox}
                            onClose={toggleTemplateLightbox}
                            title={`Pay Invoice #` + invoiceID}
                            invoiceid={invoiceID}
                            onSuccessClose={closePayInvoiceSuccess}
                        />
                    );

                default:
                case 'loading':
                    return (
                        <OverlayLightbox
                            onOpen={showTemplateLightbox}
                            className="hostingUpgrade__lightbox"
                            onClose={toggleTemplateLightbox}
                            title="Review Hosting Upgrade"
                        >
                            <div className="hostingUpgrade__lightbox--loading">
                                <RequestLoader />
                                <div>Verifying Upgrade Information...</div>
                            </div>
                        </OverlayLightbox>
                    );
            }
        };

        const handleLoadingStatus = () => {
            if (hosting_upgrade_list_status === 'loading' || hosting_upgrade_status === 'loading') {
                return 'loading';
            } else if (hosting_upgrade_list_status === 'error' || hosting_upgrade_status === 'error') {
                return 'error';
            }
            return 'success';
        };

        /*  CONDITIONAL PROPS
         **********************************************************************************************************/
        const conditionalProps = {
            override: (
                <div className="hostingUpgrade__container">
                    <div className="hostingUpgrade__wrapper--top">
                        <div className="hostingUpgrade__title">
                            {legacy ? `Your hosting service is now considered a legacy product` : `Need a hosting service for this domain name?`}
                        </div>
                        <div className="hostingUpgrade__description">Consider {legacy ? `moving to a` : `adding a`} Select Hosting plan</div>
                        <img className="hostingUpgrade__image" src={upgradeimg} alt="Hosting Upgrade" />
                    </div>
                    {invoiceID ? (
                        <InfoNotification type="warning">
                            <div className="text">
                                You have an outstanding invoice for this service. To upgrade your service, make a payment now or contact our billing
                                team for assistance.
                            </div>
                            <div className="action">
                                <SolidButton
                                    color="white"
                                    type="onClick"
                                    size="medium"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleInvoiceLightbox(invoiceID);
                                    }}
                                >
                                    Pay Invoice
                                </SolidButton>
                            </div>
                        </InfoNotification>
                    ) : (
                        ''
                    )}
                    <div className="hostingUpgrade__wrapper--bottom">
                        <div className="hostingUpgrade__information">
                            {legacy ? (
                                <div className="hostingUpgrade__title">
                                    Our new Select hosting plans are the perfect solution for tailoring resources that better suit your hosting needs
                                    with selectable plans for any size website.
                                </div>
                            ) : (
                                <div className="hostingUpgrade__information">
                                    <div className="hostingUpgrade__title">Current Plan</div>
                                    <div className="hostingUpgrade__description">
                                        {currentPlan} {hosting_isReseller ? 'Reseller' : 'Shared'} Hosting
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="hostingUpgrade__action">
                            {invoiceID ? (
                                <InactiveButton className="hostingUpgrade__button">
                                    <i className="icon icon-lock"></i>
                                    <span>View Plans</span>
                                </InactiveButton>
                            ) : (
                                <OutlineButton
                                    type="onClick"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleUpgradeDropdown();
                                    }}
                                    className="hostingUpgrade__button"
                                >
                                    View Plans
                                </OutlineButton>
                            )}
                        </div>
                    </div>
                </div>
            )
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="hostingUpgrade"
            >
                <Box
                    request={{
                        action: getHostingResourceConfig,
                        args: false,
                        status: hosting_resource_config_status
                    }}
                    className="hostingUpgrade__box"
                    status={handleLoadingStatus()}
                    {...conditionalProps}
                    dropdown={{
                        title: `Select Hosting Type`,
                        render: <UpgradeDropdown action={setUpgradeType} />,
                        condition: showUpgradeDropdown,
                        close: toggleUpgradeDropdown
                    }}
                />
                {showTemplateLightbox ? renderLightbox() : ''}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    hosting_isReseller: state.hosting.hosting_isReseller,
    hosting_information_data: state.hosting.hosting_information_data,
    hosting_upgrade_list_status: state.hosting.hosting_upgrade_list_status,
    hosting_upgrade_list_data: state.hosting.hosting_upgrade_list_data,
    hosting_upgrade_status: state.hosting.hosting_upgrade_status,
    hosting_upgrade_data: state.hosting.hosting_upgrade_data,
    hosting_resource_config_status: state.hosting.hosting_resource_config_status,
    hosting_upgrade_calculate_status: state.hosting.hosting_upgrade_calculate_status,
    hosting_upgrade_calculate_data: state.hosting.hosting_upgrade_calculate_data
});

const mapDispatchToProps = {
    scrollToComponent,
    getHostingInformation,
    getHostingProducts,
    upgradeLegacyService,
    getHostingResourceConfig,
    calculateHostingUpgradeCost
};

HostingUpgrade = connect(mapStateToProps, mapDispatchToProps)(HostingUpgrade);

HostingUpgrade = withRouter(HostingUpgrade);

export default HostingUpgrade;
