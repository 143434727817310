/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import MobileInvoiceRow from 'containers/billing/modules/invoices/tables/invoicesTable/mobileInvoiceRow';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderHeader } from 'containers/billing/modules/invoices/tables/invoicesTable/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    InvoicesTableContext,
    desktopColumns,
    desktopTableHeadings,
    mobileColumns,
    mobileTableHeadings,
    mobileTableSizes
} from 'containers/billing/modules/invoices/tables/invoicesTable/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function OverdueInvoicesTable({ invoicesData, contentComponents }) {
    const invoicesTableContext = {
        contentComponents
    };

    /***** HOOKS *****/
    const app_viewport = useSelector((state) => state.app.app_viewport);

    /***** RENDER HELPERS *****/
    function renderDesktopInvoicesRows() {
        return (
            invoicesData?.map((invoiceData) => {
                const filteredDesktopTableHeading = desktopTableHeadings.filter(([sortKey]) => has(contentComponents, sortKey));
                return (
                    <NXTable.Row key={invoiceData.id}>
                        {filteredDesktopTableHeading.map(([sortKey], i) => {
                            const hasDesktopRender = has(contentComponents[sortKey], 'desktop');
                            if (hasDesktopRender) return contentComponents[sortKey].desktop(invoiceData, i);
                            return contentComponents[sortKey](invoiceData, i);
                        })}
                    </NXTable.Row>
                );
            }) ?? ''
        );
    }

    function renderMobileInvoiceRows() {
        return invoicesData?.map((invoiceData, i) => <MobileInvoiceRow invoiceData={invoiceData} index={i} key={invoiceData.id} />) ?? '';
    }

    function renderDesktopTable() {
        return (
            <InvoicesTableContext.Provider value={invoicesTableContext}>
                <NXTable key="desktop" columns={desktopColumns} className="invoicesTable">
                    {renderHeader(desktopTableHeadings)}
                    <NXTable.Body>{renderDesktopInvoicesRows()}</NXTable.Body>
                </NXTable>
            </InvoicesTableContext.Provider>
        );
    }

    function renderMobileTable() {
        return (
            <InvoicesTableContext.Provider value={invoicesTableContext}>
                <NXTable key="mobile" columns={mobileColumns} className="invoicesTable">
                    {renderHeader(mobileTableHeadings)}
                    <NXTable.Accordion.ToggleWrapper>{renderMobileInvoiceRows()}</NXTable.Accordion.ToggleWrapper>
                </NXTable>
            </InvoicesTableContext.Provider>
        );
    }

    /***** RENDER *****/
    if (mobileTableSizes.includes(app_viewport)) return renderMobileTable();

    return renderDesktopTable();
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
OverdueInvoicesTable.propTypes = {
    /**
     * The invoices data
     */
    invoicesData: PropTypes.arrayOf(PropTypes.object),

    /**
     * The content components required by the table to display the invoices content
     */
    contentComponents: PropTypes.object
};

export default OverdueInvoicesTable;
