/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * AlternativePricingTable.Row Private Component - Renders a row in the pricing table.
 *
 * @param {{
 *   className?: string,
 *   label: React.ReactNode,
 *   content: React.ReactNode
 * }} props
 */
const _Row = ({ className, label, content }) => {
    const classes = classNames('alternativePriceTable__row', className);

    /***** RENDER *****/
    return (
        <div className={classes}>
            {label}
            <div className="alternativePriceTable__rowContent">{content}</div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _Row;
