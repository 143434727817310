import { getSocialsAuthorisationLink } from 'containers/katana/queries/serviceID/getSocialsAuthorisationLink';
import { handleAuthorisationCallback } from 'containers/katana/queries/serviceID/handleAuthorisationCallback';
import { unlinkSocialID } from 'containers/katana/queries/serviceID/unlinkSocialID';
import { downloadEventUnsplashImages } from 'containers/katana/queries/unsplash/downloadEventUnsplashImages';
import { uploadFile } from 'containers/katana/queries/uploadFile';

export const katanaMutation = Object.freeze({
    uploadFile,
    unsplash: {
        downloadEventUnsplashImages
    },
    serviceID: {
        getSocialsAuthorisationLink,
        handleAuthorisationCallback,
        unlinkSocialID
    }
});
