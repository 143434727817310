/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderField, emailFieldValidation, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteAccountDetailsForm extends Component {
    render() {
        const { pristine, submitting, valid, handleSubmit } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="gsuiteAccountDetails__form" onSubmit={handleSubmit}>
                <div className="form__row">
                    <div className="form__column">
                        <Field
                            label="Full Name"
                            name="full_name"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--half">
                        <Field
                            label="Company Name"
                            name="company_name"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                    <div className="form__column form__column--half">
                        <Field
                            label="Alternative Email Address"
                            name="alternate_email"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation, emailFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--half">
                        <Field
                            label="Address"
                            name="address"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                    <div className="form__column form__column--half">
                        <Field
                            label="ZIP/Postal Code"
                            name="postal_code"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>

                <div className="form__row">
                    <div className="form__column full">{renderButton(pristine, submitting, valid)}</div>
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
GSuiteAccountDetailsForm = reduxForm({
    form: 'gsuiteAccountDetailsForm',
    enableReinitialize: true
})(GSuiteAccountDetailsForm);

const mapStateToProps = (state) => {
    let initialValues = {};

    if (state.gsuite.gsuite_customer_data_data) {
        const { full_name, company_name, alternate_email, address, postal_code } = state.gsuite.gsuite_customer_data_data.attributes;
        initialValues = {
            full_name,
            company_name,
            alternate_email,
            address,
            postal_code
        };
    }
    return {
        initialValues,
        gsuite_customer_data_data: state.gsuite.gsuite_customer_data_data
    };
};

GSuiteAccountDetailsForm = connect(mapStateToProps, null)(GSuiteAccountDetailsForm);

export default GSuiteAccountDetailsForm;
