/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CheckBoxList } from 'components/CheckBoxList/_CheckBoxList';
import { _CheckBoxListItem as CheckBoxListItem } from 'components/CheckBoxList/_CheckBoxListItem';
import { CheckBoxListItemReactHookFormDefault } from 'components/CheckBoxList/_CheckBoxListItem/wrappers/hookform/Default';
import { _CheckBoxListItemReduxFormDefault as CheckBoxListItemReduxFormDefault } from 'components/CheckBoxList/_CheckBoxListItem/wrappers/reduxform/Default';

const CheckBoxList = Object.assign(_CheckBoxList, {
    Item: Object.assign(CheckBoxListItem, {
        ReduxForm: CheckBoxListItemReduxFormDefault,
        HookForm: CheckBoxListItemReactHookFormDefault
    })
});

export { CheckBoxList };
