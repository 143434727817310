/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Field, change } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import { Input } from 'components/Form/Input';
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderPillMultiSelect } from 'components/Form/RenderPillMultiSelect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { selectorEqualityCheckValues, selectorValues } from 'components/Form/Button/reduxForm';
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import './_webPageTypeContentModule.scss';

const WEBPAGE_TYPES_ENUM = /** @type {const} */ ({
    BUSINESS_SERVICES: 'Business Services',
    CAFE_RESTAURANT: 'Cafe/Restaurant',
    REAL_ESTATE: 'Real Estate',
    MARKETING: 'Marketing',
    PHOTOGRAPHY: 'Photography',
    GYMS: 'Gyms',
    WEB_DEVELOPMENT: 'Web Development',
    GARDENING: 'Gardening',
    TRADE: 'Trade',
    NEWS_MEDIA: 'News & Media',
    IT_SERVICES: 'IT Services'
});

const webPageTypeEnumValues = Object.values(WEBPAGE_TYPES_ENUM);

const FormFieldsEnum = /** @type {const} */ ({
    WEBPAGE_TYPES: useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS.BUSINESS_CATEGORIES,
    OTHER_TYPE: 'other'
});

const { WEBPAGE_TYPES, OTHER_TYPE } = FormFieldsEnum;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _WebPageTypeContentModule = () => {
    /***** HOOKS *****/
    const { form } = useContext(ContentEditorRenderModulesContext);
    const formValues = useSelector(selectorValues(form), { equalityFn: selectorEqualityCheckValues });

    /***** FUNCTIONS *****/
    function addOtherValue() {
        const otherValue = formValues.values?.[OTHER_TYPE];
        if (!otherValue) return;
        const keyWords = formValues.values?.[WEBPAGE_TYPES] ?? [];
        store.dispatch(change(form, OTHER_TYPE, ''));
        if (keyWords.includes(otherValue)) {
            return;
        }
        store.dispatch(change(form, WEBPAGE_TYPES, [...keyWords, otherValue]));
    }

    function handleOnKeyDown(e) {
        if (e.code === 'Enter') {
            e.preventDefault();
            addOtherValue();
        }
    }

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="Choose Your Website's Category"
            description="Please select the category that best fits your website. You can select multiple options. If your category isn't listed, you can add a custom one below."
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <Field name={WEBPAGE_TYPES} component={RenderPillMultiSelect} options={webPageTypeEnumValues} type="text" />
                    <Grid columns="3fr 1fr" alignItems--end className="WebPageTypeContentModule__otherGrid">
                        <Field
                            name={OTHER_TYPE}
                            label="Can't see one above? Type your own below"
                            component={Input.ReduxForm.Default}
                            placeholder="Eg. Mechanic"
                            onKeyDown={handleOnKeyDown}
                            type="text"
                        />
                        <OutlineButton type="onClick" onClick={addOtherValue}>
                            Add
                        </OutlineButton>
                    </Grid>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const WebPageTypeContentModule = Object.assign(_WebPageTypeContentModule, {
    formFields: FormFieldsEnum
});

export default WebPageTypeContentModule;
