/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Invoices from 'containers/billing/modules/invoices';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useSearchParams from 'utilities/hooks/useSearchParams';
import { invoicesTableContentComponents } from 'containers/billing/modules/invoices/tables/invoicesTable/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { INVOICE_HEADINGS } from 'containers/billing/modules/invoices/tables/invoicesTable/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SelectInvoice() {
    /***** HOOKS *****/
    const { searchParamsAdd } = useSearchParams();

    /***** FUNCTIONS *****/
    function selectInvoice(id) {
        searchParamsAdd('invoice', id);
    }

    /***** RENDER HELPERS *****/
    const tableContentComponentsOverride = {
        ...invoicesTableContentComponents,
        [INVOICE_HEADINGS.ACTION]: (data) => {
            return (
                <OutlineButton key="tableContentComponentsOverrideAction" size="small" type="onClick" onClick={() => selectInvoice(data.id)}>
                    Select
                </OutlineButton>
            );
        }
    };

    /***** RENDER *****/
    return (
        <div className="ticketAbout__invoiceList">
            <Invoices
                enableBoxTopActions={false}
                NXBoxTopTitle="Select Invoice"
                NXBoxTopDescription="Choose a recent invoice or search by invoice #"
                tableContentComponentsOverride={tableContentComponentsOverride}
            />
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SelectInvoice;
