/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, change, formValueSelector, reduxForm, reset } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderField, ipAddressValidation, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { deleteHostIP } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ChildNSForm extends Component {
    constructor(props) {
        super(props);
        this.handleAddInput = this.handleAddInput.bind(this);
        this.handleRemoveInput = this.handleRemoveInput.bind(this);
        this.processInputFields = this.processInputFields.bind(this);
        this.updateInputFields = this.updateInputFields.bind(this);
        this.state = {
            inputs: [`ip1`],
            hostname: undefined,
            records: null
        };
    }

    handleAddInput() {
        const { inputs } = this.state;

        this.setState({
            inputs: inputs.concat(['ip' + (inputs.length + 1)])
        });
    }

    handleRemoveInput(index) {
        const { domain_child_record_list_data, domain_information_data, deleteHostIP, record, mode } = this.props;
        const { records } = this.state;

        if (mode !== 'add' && records && records[index]) {
            deleteHostIP(domain_information_data.id, domain_child_record_list_data[record].attributes.hostname, records[index]);
        } else {
            this.setState({
                inputs: this.state.inputs.slice(0, -1)
            });
        }
    }

    processInputFields() {
        const { records } = this.state;
        const { updateInputFields } = this;

        const inputArray = [];
        records.forEach((data, index) => {
            inputArray.push('ip' + (index + 1));
        });

        this.setState(
            {
                inputs: inputArray
            },
            () => {
                updateInputFields();
            }
        );
    }

    updateInputFields() {
        const { domain_child_record_list_data, record, form, dispatch } = this.props;
        const { attributes } = domain_child_record_list_data[record];
        const { ip, hostname } = attributes;

        dispatch(change(form, 'hostname', hostname));

        Object.entries(ip).forEach((data, index) => {
            dispatch(change(form, 'ip' + (index + 1), data[1]));
        });
    }

    componentDidMount() {
        const { domain_child_record_list_data, record, mode } = this.props;
        const { processInputFields } = this;

        if (mode === 'edit' && domain_child_record_list_data && record) {
            const { attributes } = domain_child_record_list_data[record];
            const { ip, hostname } = attributes;

            this.setState(
                {
                    records: ip,
                    hostname: hostname
                },
                () => {
                    processInputFields();
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { domain_child_record_list_status, domain_child_record_list_data, record, mode, dispatch } = this.props;
        const { processInputFields } = this;

        if (domain_child_record_list_status === 'success' && prevProps.domain_child_record_list_status === 'loading') {
            if (mode === 'edit' && domain_child_record_list_data && record) {
                const { attributes } = domain_child_record_list_data[record];
                const { ip, hostname } = attributes;

                this.setState(
                    {
                        records: ip,
                        hostname: hostname
                    },
                    () => {
                        dispatch(reset('childNSForm'));
                        processInputFields();
                    }
                );
            }
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { pristine, submitting, valid, handleSubmit, mode, domain_information_data, ipAddress } = this.props;
        const { inputs, hostname } = this.state;
        const { handleAddInput, handleRemoveInput } = this;

        const renderEditForm = () => {
            return (
                <div className="manageHost__form">
                    <div className="manageHost__row title__row">
                        <div className="manageHost__column">
                            <div className="manageHost__title">Hostname</div>
                            <div className="manageHost__data">{hostname}</div>
                            <input name="hostname" type="hidden" />
                        </div>
                    </div>
                    <div className="manageHost__divider"></div>
                    <div className="manageHost__row">
                        <div className="manageHost__column">
                            <Field
                                label="IP Addresses"
                                name="ip1"
                                component={RenderField}
                                type="text"
                                placeholder={ipAddress}
                                validate={[requiredFieldValidation, ipAddressValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    {inputs.map((input, index) => {
                        if (index === 0) {
                            return null;
                        }
                        return (
                            <div key={index} className={`manageHost__row${index + 1 === inputs.length ? ' last ' : ' '}hasButton`}>
                                <div className="manageHost__column">
                                    <Field
                                        name={input}
                                        component={RenderField}
                                        type="text"
                                        placeholder="IPv4 or IPv6 Address"
                                        className="form__textfield"
                                        validate={ipAddressValidation}
                                    />
                                    <button
                                        className="ip__button--remove"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleRemoveInput(index);
                                        }}
                                    >
                                        <i className="icon icon-x"></i>
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    <div className={`manageHost__row button ${inputs.length >= 5 ? ' hidden ' : ' '}`}>
                        <div className="manageHost__column">
                            <button
                                className="ip__button--add"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAddInput();
                                }}
                            >
                                <i className="icon icon-plus-faq"></i>
                            </button>
                        </div>
                    </div>
                </div>
            );
        };

        const renderAddForm = () => {
            return (
                <div className="manageHost__form">
                    <div className="manageHost__row">
                        <div className="manageHost__column ext__label">
                            <Field
                                label="Hostname"
                                name="hostname"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                className="manageHost__textfield"
                                append={{
                                    type: `domain`,
                                    text: `.${domain_information_data.attributes.domain}`
                                }}
                            />
                        </div>
                    </div>
                    <div className="manageHost__divider"></div>
                    <div className="manageHost__row">
                        <div className="manageHost__column">
                            <Field
                                label="IP Address"
                                name="ip_address"
                                component={RenderField}
                                type="text"
                                placeholder="IPv4 or IPv6 Address"
                                validate={[requiredFieldValidation, ipAddressValidation]}
                                className="manageHost__textfield"
                            />
                        </div>
                    </div>
                    {inputs.map((input, index) => {
                        if (index === 0) {
                            return null;
                        }
                        return (
                            <div key={index} className={`manageHost__row ${index + 1 === inputs.length ? ' last ' : ' '}`}>
                                <div className="manageHost__column">
                                    <Field
                                        name={input}
                                        component={RenderField}
                                        type="text"
                                        placeholder="IPv4 or IPv6 Address"
                                        className="form__textfield"
                                    />
                                    <button
                                        className="ip__button--remove"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleRemoveInput(index);
                                        }}
                                    >
                                        <i className="icon icon-x"></i>
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    <div className={`manageHost__row button ${inputs.length >= 5 ? ' hidden ' : ' '}`}>
                        <div className="manageHost__column">
                            <button
                                className="ip__button--add"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAddInput();
                                }}
                            >
                                <i className="icon icon-plus-faq"></i>
                            </button>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <form className="manageHost" onSubmit={handleSubmit}>
                {mode === 'add' ? renderAddForm() : renderEditForm()}
                <div className="manageHost__submit">{renderButton(pristine, submitting, valid)}</div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ChildNSForm = reduxForm({
    form: 'childNSForm',
    enableReinitialize: true
})(ChildNSForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector('childNSForm');
    const ipAddress = selector(state, 'ip1');
    const hostname = selector(state, 'hostname');

    return {
        domain_child_record_list_status: state.domain.domain_child_record_list_status,
        domain_child_record_list_data: state.domain.domain_child_record_list_data,
        domain_information_data: state.domain.domain_information_data,
        form: 'childNSForm',
        ipAddress,
        hostname
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteHostIP
        },
        dispatch
    );

ChildNSForm = connect(mapStateToProps, mapDispatchToProps)(ChildNSForm);

export default withRouter(ChildNSForm);
