/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { billingCycles } from 'config/config';
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import RequestLoader from 'components/Loaders/Request';
import Slider from 'components/Slider';
import { pushNotification } from 'components/Toast/functions';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { calculateHostingUpgradeCost } from '../../../state/accountActions';
import { updateResourceAllocations } from '../../../state/baseActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_upgradeSelectChangeResourcesForm.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ChangeResourceForm extends Component {
    constructor(props) {
        super(props);
        this.handleDiskChange = this.handleDiskChange.bind(this);
        this.handleCPUChange = this.handleCPUChange.bind(this);
        this.handleMemoryChange = this.handleMemoryChange.bind(this);
        this.handlePresetChange = this.handlePresetChange.bind(this);
        this.handleChangeSubmit = this.handleChangeSubmit.bind(this);
        this.setupSliders = this.setupSliders.bind(this);
        this.getBillingCycle = this.getBillingCycle.bind(this);
        this.state = {
            diskLabels: undefined,
            cpuLabels: undefined,
            memLabels: undefined,
            defaultConfig: {
                cpu: undefined,
                mem: undefined,
                disk: undefined
            },
            currentConfig: {
                cpu: {
                    value: null,
                    orderValue: null,
                    price: null,
                    name: null
                },
                mem: {
                    value: null,
                    orderValue: null,
                    price: null,
                    name: null
                },
                disk: {
                    value: null,
                    orderValue: null,
                    price: null,
                    name: null
                }
            },
            newConfig: {
                cpu: {
                    value: null,
                    orderValue: null,
                    price: null,
                    name: null
                },
                mem: {
                    value: null,
                    orderValue: null,
                    price: null,
                    name: null
                },
                disk: {
                    value: null,
                    orderValue: null,
                    price: null,
                    name: null
                }
            },
            pricing: {
                new_plan_cost: {
                    label: 'New Plan Cost',
                    value: null
                },
                refunded_amount: {
                    label: 'Refunded Amount',
                    value: null
                },
                discount_amount: {
                    label: 'Discount',
                    value: null
                },
                amount_due: {
                    label: 'Total Due Today',
                    value: null
                }
            },
            billingCycle: 'Monthly'
        };
    }

    /************** HANDLE STATE CHANGES **************/

    handleMemoryChange(value, force, callback) {
        const { onChangeCallback } = this.props;
        const { newConfig, defaultConfig, billingCycle } = this.state;

        this.setState(
            {
                newConfig: {
                    ...newConfig,
                    mem: {
                        config_id: defaultConfig['mem'][value].config_id,
                        option_id: defaultConfig['mem'][value].option_id,
                        value: defaultConfig['mem'][value].value,
                        orderValue: defaultConfig['mem'][value].orderValue,
                        price: defaultConfig['mem'][value].pricing[billingCycle],
                        name: defaultConfig['mem'][value].name
                    }
                }
            },
            () => {
                onChangeCallback('default');
                if (force) this.handleChangeSubmit();
                if (callback) callback();
            }
        );
    }

    handleDiskChange(value, force, callback) {
        const { onChangeCallback } = this.props;
        const { newConfig, defaultConfig, billingCycle } = this.state;

        this.setState(
            {
                newConfig: {
                    ...newConfig,
                    disk: {
                        config_id: defaultConfig['disk'][value].config_id,
                        option_id: defaultConfig['disk'][value].option_id,
                        value: defaultConfig['disk'][value].value,
                        orderValue: defaultConfig['disk'][value].orderValue,
                        price: defaultConfig['disk'][value].pricing[billingCycle],
                        name: defaultConfig['disk'][value].name
                    }
                }
            },
            () => {
                onChangeCallback('default');
                if (force) this.handleChangeSubmit();
                if (callback) callback();
            }
        );
    }

    handleCPUChange(value, force, callback) {
        const { onChangeCallback } = this.props;
        const { newConfig, defaultConfig, billingCycle } = this.state;

        this.setState(
            {
                newConfig: {
                    ...newConfig,
                    cpu: {
                        config_id: defaultConfig['cpu'][value].config_id,
                        option_id: defaultConfig['cpu'][value].option_id,
                        value: defaultConfig['cpu'][value].value,
                        orderValue: defaultConfig['cpu'][value].orderValue,
                        price: defaultConfig['cpu'][value].pricing[billingCycle],
                        name: defaultConfig['cpu'][value].name
                    }
                }
            },
            () => {
                onChangeCallback('default');
                if (force) this.handleChangeSubmit();
                if (callback) callback();
            }
        );
    }

    handlePresetChange(preset) {
        const { defaultConfig, billingCycle } = this.state;

        const newConfig = {
            cpu: {
                config_id: defaultConfig['cpu'][preset.cpu.orderValue].config_id,
                option_id: defaultConfig['cpu'][preset.cpu.orderValue].option_id,
                value: defaultConfig['cpu'][preset.cpu.orderValue].value,
                orderValue: defaultConfig['cpu'][preset.cpu.orderValue].orderValue,
                price: defaultConfig['cpu'][preset.cpu.orderValue].pricing[billingCycle],
                name: defaultConfig['cpu'][preset.cpu.orderValue].name
            },
            disk: {
                config_id: defaultConfig['disk'][preset.disk.orderValue].config_id,
                option_id: defaultConfig['disk'][preset.disk.orderValue].option_id,
                value: defaultConfig['disk'][preset.disk.orderValue].value,
                orderValue: defaultConfig['disk'][preset.disk.orderValue].orderValue,
                price: defaultConfig['disk'][preset.disk.orderValue].pricing[billingCycle],
                name: defaultConfig['disk'][preset.disk.orderValue].name
            },
            mem: {
                config_id: defaultConfig['mem'][preset.mem.orderValue].config_id,
                option_id: defaultConfig['mem'][preset.mem.orderValue].option_id,
                value: defaultConfig['mem'][preset.mem.orderValue].value,
                orderValue: defaultConfig['mem'][preset.mem.orderValue].orderValue,
                price: defaultConfig['mem'][preset.mem.orderValue].pricing[billingCycle],
                name: defaultConfig['mem'][preset.mem.orderValue].name
            }
        };

        this.setState(
            {
                newConfig
            },
            () => {
                this.handleChangeSubmit();
            }
        );
    }

    handleChangeSubmit(option) {
        const { productid, calculateHostingUpgradeCost, updateResourceAllocations, hostingid } = this.props;

        const { defaultConfig, newConfig, billingCycle } = this.state;

        let attributes = {
            billing_cycle: billingCycle,
            config: [
                {
                    config_id: defaultConfig['cpu'][newConfig.cpu.orderValue].config_id,
                    option_id: defaultConfig['cpu'][newConfig.cpu.orderValue].option_id
                },
                {
                    config_id: defaultConfig['mem'][newConfig.mem.orderValue].config_id,
                    option_id: defaultConfig['mem'][newConfig.mem.orderValue].option_id
                },
                {
                    config_id: defaultConfig['disk'][newConfig.disk.orderValue].config_id,
                    option_id: defaultConfig['disk'][newConfig.disk.orderValue].option_id
                }
            ]
        };

        if (productid) attributes = { ...attributes, product_id: productid };

        switch (option) {
            case 'billing cycle change':
                this.setState(
                    {
                        newConfig: {
                            cpu: {
                                config_id: defaultConfig['cpu'][newConfig.cpu.orderValue].config_id,
                                option_id: defaultConfig['cpu'][newConfig.cpu.orderValue].option_id,
                                value: defaultConfig['cpu'][newConfig.cpu.orderValue].value,
                                orderValue: defaultConfig['cpu'][newConfig.cpu.orderValue].orderValue,
                                price: defaultConfig['cpu'][newConfig.cpu.orderValue].pricing[billingCycle],
                                name: defaultConfig['cpu'][newConfig.cpu.orderValue].name
                            },
                            disk: {
                                config_id: defaultConfig['disk'][newConfig.disk.orderValue].config_id,
                                option_id: defaultConfig['disk'][newConfig.disk.orderValue].option_id,
                                value: defaultConfig['disk'][newConfig.disk.orderValue].value,
                                orderValue: defaultConfig['disk'][newConfig.disk.orderValue].orderValue,
                                price: defaultConfig['disk'][newConfig.disk.orderValue].pricing[billingCycle],
                                name: defaultConfig['disk'][newConfig.disk.orderValue].name
                            },
                            mem: {
                                config_id: defaultConfig['mem'][newConfig.mem.orderValue].config_id,
                                option_id: defaultConfig['mem'][newConfig.mem.orderValue].option_id,
                                value: defaultConfig['mem'][newConfig.mem.orderValue].value,
                                orderValue: defaultConfig['mem'][newConfig.mem.orderValue].orderValue,
                                price: defaultConfig['mem'][newConfig.mem.orderValue].pricing[billingCycle],
                                name: defaultConfig['mem'][newConfig.mem.orderValue].name
                            }
                        }
                    },
                    () => calculateHostingUpgradeCost(hostingid, attributes)
                );
                break;

            case 'submit':
                updateResourceAllocations(hostingid, attributes);
                break;

            default:
                calculateHostingUpgradeCost(hostingid, attributes);
                break;
        }
    }

    /************** COMPONENT STATES **************/

    setupSliders(callback) {
        const { hosting_resource_config_data } = this.props;

        const getLabel = (resource) => {
            return {
                label: resource.name,
                disabled: !resource.show_order
            };
        };

        const cpuLabels = {};
        hosting_resource_config_data.attributes['CPU'].forEach((resource, index) => {
            return (cpuLabels[index] = getLabel(resource));
        });
        const diskLabels = {};
        hosting_resource_config_data.attributes['Disk Space'].forEach((resource, index) => {
            return (diskLabels[index] = getLabel(resource));
        });

        const memLabels = {};
        hosting_resource_config_data.attributes['Memory'].forEach((resource, index) => {
            return (memLabels[index] = getLabel(resource));
        });

        const defaultConfig = {
            cpu: hosting_resource_config_data.attributes['CPU'].map((resource, index) => {
                return {
                    ...resource,
                    orderValue: index
                };
            }),
            disk: hosting_resource_config_data.attributes['Disk Space'].map((resource, index) => {
                return {
                    ...resource,
                    orderValue: index
                };
            }),
            mem: hosting_resource_config_data.attributes['Memory'].map((resource, index) => {
                return {
                    ...resource,
                    orderValue: index
                };
            })
        };

        const newConfig = {
            cpu: {
                ...hosting_resource_config_data.attributes['CPU'][0],
                orderValue: 0
            },
            disk: {
                ...hosting_resource_config_data.attributes['Disk Space'][0],
                orderValue: 0
            },
            mem: {
                ...hosting_resource_config_data.attributes['Memory'][0],
                orderValue: 0
            }
        };

        const currentConfig = {
            ...newConfig
        };

        callback({
            cpuLabels,
            diskLabels,
            memLabels,
            defaultConfig,
            newConfig,
            currentConfig
        });
    }

    getBillingCycle() {
        // This should handle the odd 'One Time' billing cycle
        const { billingCycle, hosting_resource_data, hosting_information_data } = this.props;

        function billingCycleData() {
            if (billingCycle) return billingCycle;
            if (hosting_resource_data) return hosting_resource_data.attributes.billing_cycle;
            return hosting_information_data.attributes.billing_cycle;
        }

        const billing = billingCycleData();

        return billing === 'One Time' ? 'Monthly' : billing;
    }

    componentDidMount() {
        const { hosting_resource_data, hosting_resource_config_data } = this.props;

        if (hosting_resource_config_data) {
            this.setupSliders((processedData) => {
                const { newConfig } = processedData;

                function getHostingResourceDataObject() {
                    if (hosting_resource_data) {
                        return {
                            cpu: {
                                ...hosting_resource_config_data.attributes['CPU'].filter(
                                    (resource) => resource.option_id === hosting_resource_data.attributes.config_values.cpu.option_id
                                )[0],
                                orderValue: hosting_resource_data.attributes.config_values.cpu.orderValue
                            },
                            disk: {
                                ...hosting_resource_config_data.attributes['Disk Space'].filter(
                                    (resource) => resource.option_id === hosting_resource_data.attributes.config_values.disk_space.option_id
                                )[0],
                                orderValue: hosting_resource_data.attributes.config_values.disk_space.orderValue
                            },
                            mem: {
                                ...hosting_resource_config_data.attributes['Memory'].filter(
                                    (resource) => resource.option_id === hosting_resource_data.attributes.config_values.memory.option_id
                                )[0],
                                orderValue: hosting_resource_data.attributes.config_values.memory.orderValue
                            }
                        };
                    }
                    return false;
                }
                const resourceConfig = getHostingResourceDataObject();

                this.setState({
                    ...processedData,
                    billingCycle: this.getBillingCycle(),
                    currentConfig: resourceConfig ? resourceConfig : newConfig,
                    newConfig: resourceConfig ? resourceConfig : newConfig
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            preset,
            billingCycle,
            hosting_resource_config_status,
            hosting_resource_config_data,
            hosting_upgrade_calculate_status,
            hosting_upgrade_calculate_data
        } = this.props;

        if (preset && preset !== prevProps.preset) {
            this.handlePresetChange(preset);
        }

        if (billingCycle !== prevProps.billingCycle) {
            this.setState(
                {
                    billingCycle: this.getBillingCycle()
                },
                () => {
                    this.handleChangeSubmit('billing cycle change');
                }
            );
        }

        if (hosting_resource_config_status === 'success' && prevProps.hosting_resource_config_status === 'loading' && hosting_resource_config_data) {
            const { attributes } = hosting_resource_config_data;
            this.setState({
                defaultConfig: {
                    cpu: attributes['CPU'],
                    disk: attributes['Disk Space'],
                    mem: attributes['Memory']
                }
            });
        }

        if (hosting_upgrade_calculate_status === 'success' && prevProps.hosting_upgrade_calculate_status === 'loading') {
            const {
                attributes: { discount_amount, refunded_amount, amount_due, new_plan_cost }
            } = hosting_upgrade_calculate_data;
            const newTotal = parseFloat(amount_due);

            this.setState({
                pricing: {
                    new_plan_cost: {
                        label: 'New Plan Cost',
                        value: new_plan_cost
                    },
                    refunded_amount: {
                        label: 'Refunded Amount',
                        value: refunded_amount
                    },
                    discount_amount: {
                        label: 'Discount',
                        value: discount_amount
                    },
                    amount_due: {
                        label: 'Total Due Today',
                        value: newTotal.toFixed(2)
                    }
                }
            });
        }
    }

    /************** RENDER BEGIN **************/

    render() {
        const { app_viewport, custiomHostingUpgradeConfirm, hosting_upgrade_calculate_status } = this.props;

        const { cpuLabels, diskLabels, memLabels, pricing, currentConfig, billingCycle, newConfig } = this.state;

        const { handleDiskChange, handleCPUChange, handleMemoryChange, handleChangeSubmit } = this;

        const renderSlider = (data) => {
            const { title, subtitle, tooltip, dataRef, labels, actions } = data;

            let sliderMin = 0;

            if (labels) sliderMin = Object.values(labels).filter(({ disabled }) => disabled).length;

            const handleDropdownOptions = (data) =>
                Object.keys(data)
                    .filter((key) => !labels[key].disabled)
                    .map((key) => {
                        return {
                            label: htmr(`<strong>${labels[key].label}</strong> ${title}`),
                            onClick: (e) => {
                                e.preventDefault();
                                actions.change(key, true);
                            }
                        };
                    });

            const handlePricingRender = () => {
                function getCorrectPrice() {
                    if (newConfig[dataRef].price) return newConfig[dataRef].price;
                    else if (currentConfig[dataRef].pricing) return currentConfig[dataRef].pricing[billingCycle];
                    return 0;
                }

                const price = getCorrectPrice();
                return `$${price.toFixed(2)}${billingCycles[billingCycle]}`;
            };

            return (
                <div className="changeResource__option">
                    {!['sm', 'xs'].includes(app_viewport) ? (
                        <>
                            <div className="changeResource__config-wrapper">
                                <div className="changeResource__config-option">
                                    {title} <Tooltip className="changeResource__config-tooltip" info={tooltip} />
                                </div>
                                <div className="changeResource__config-name">
                                    <strong>{newConfig[dataRef].name}</strong> {subtitle}
                                </div>
                                <div className="changeResource__config-price">{handlePricingRender()}</div>
                            </div>
                            {labels ? (
                                <Slider
                                    className="changeResource__slider"
                                    min={0}
                                    max={Object.keys(labels).length - 1}
                                    labels={labels}
                                    onChange={(val) => {
                                        actions.change(val);
                                    }}
                                    onChangeComplete={(val, e) => {
                                        if (val >= sliderMin) {
                                            handleChangeSubmit(e);
                                        } else {
                                            actions.change(sliderMin, null, () => handleChangeSubmit(e));
                                            pushNotification({
                                                status: 500,
                                                details: 'New resources must be greater than or equal to the new minimums.'
                                            });
                                        }
                                    }}
                                    value={newConfig[dataRef].orderValue}
                                    tooltip={false}
                                />
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        <>
                            {labels ? (
                                <SelectDropdown
                                    label={htmr(`<strong>${newConfig[dataRef].name}</strong> ${title}`)}
                                    options={handleDropdownOptions(labels)}
                                />
                            ) : (
                                ''
                            )}
                            <div className="changeResource__config-wrapper">
                                <div className="changeResource__config-price">{handlePricingRender()}</div>
                            </div>
                        </>
                    )}
                </div>
            );
        };

        const handlePricingTotalsRender = () => {
            function renderAllocationPricing() {
                if (hosting_upgrade_calculate_status === 'loading') {
                    return (
                        <div className="changeResource__loading">
                            <RequestLoader message="Calculating Costs" />
                        </div>
                    );
                }

                if (hosting_upgrade_calculate_status === null) {
                    return (
                        <div className="changeResource__upgradePrice--na">
                            <i className="icon icon-icon-custom" />
                            <p>Adjust the {app_viewport !== 'xs' && app_viewport !== 'sm' ? 'sliders' : 'options'} above to customise your service</p>
                        </div>
                    );
                }

                if (pricing) {
                    return (
                        <>
                            {Object.keys(pricing).map((type) => {
                                if (type === 'due' || type === 'amount_due') return null;
                                const { label, value } = pricing[type];
                                if (value && value !== `0.00`) {
                                    return (
                                        <div key={label} className="changeResource__upgradePrice--sub">
                                            <div className="price__title">{label}</div>
                                            <div className="price__total">
                                                ${value} {type === 'total' ? billingCycles[billingCycle] : `AUD`}
                                            </div>
                                        </div>
                                    );
                                }
                                return '';
                            })}
                            <div className="changeResource__upgradePrice">
                                <div className="price__title">Total Due Today</div>
                                <div className="price__total">${pricing.amount_due.value ? pricing.amount_due.value : `-.--`} AUD</div>
                            </div>
                        </>
                    );
                }

                return '';
            }
            // This totals render is used for the Upgrade component
            return <div className="changeResource__allocationPricing upgrade">{renderAllocationPricing()}</div>;
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="changeResource__form upgrade">
                {renderSlider({
                    title: `Disk Space`,
                    subtitle: `Disk Space`,
                    tooltip: `Your files and databases will be stored on our new enterprise SAS SSD storage which is lightning fast and highly reliable.`,
                    dataRef: `disk`,
                    labels: diskLabels,
                    actions: {
                        change: (val, force, cb) => handleDiskChange(val, force, cb)
                    }
                })}
                {renderSlider({
                    title: `CPU Cores`,
                    subtitle: `CPU`,
                    tooltip: `This is the limit of CPU available to your website, where 100% equals 1 CPU core. You can view your CPU usage inside cPanel at anytime.`,
                    dataRef: `cpu`,
                    labels: cpuLabels,
                    actions: {
                        change: handleCPUChange
                    }
                })}
                {renderSlider({
                    title: `Memory`,
                    subtitle: `Memory`,
                    tooltip: `The is the amount of memory your website processes can use before throttling is applied. You can view your memory usage inside cPanel at any time.`,
                    dataRef: `mem`,
                    labels: memLabels,
                    actions: {
                        change: handleMemoryChange
                    }
                })}

                {/* TEMP, un-hardcode this later */}
                <p className="changeResource__allocationNote">
                    <b>Please note:</b> Our minimum resources for Select Hosting have increased. When changing your resources, you must select at
                    least 5GB Disk Space, 200% CPU and 2GB Memory.
                </p>

                {handlePricingTotalsRender()}
                {hosting_upgrade_calculate_status === 'loading' ? (
                    <InactiveButton>Confirm</InactiveButton>
                ) : (
                    <SolidButton
                        color="primary"
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            custiomHostingUpgradeConfirm(billingCycle, newConfig, [], pricing);
                        }}
                    >
                        Confirm
                    </SolidButton>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            hosting_information_data: state.hosting.hosting_information_data,
            hosting_resource_data: state.hosting.hosting_resource_data,
            hosting_resource_config_status: state.hosting.hosting_resource_config_status,
            hosting_resource_config_data: state.hosting.hosting_resource_config_data,
            hosting_upgrade_calculate_status: state.hosting.hosting_upgrade_calculate_status,
            hosting_upgrade_calculate_data: state.hosting.hosting_upgrade_calculate_data,
            app_viewport: state.app.app_viewport
        }),
        {
            calculateHostingUpgradeCost,
            updateResourceAllocations
        }
    )(ChangeResourceForm)
);
