/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { resetScrollEvents } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Removes all existing scroll refs from the sidebar. Needs to be called inside any component that represents a "section" in the sidebar
 */
function useResetScrollEvents() {
    /***** HOOKS *****/
    const dispatch = useDispatch();

    /***** EFFECTS *****/
    useEffect(() => {
        return () => dispatch(resetScrollEvents());
    }, []);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useResetScrollEvents;
