/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ventraSynMigrationHalfPriceEndDate } from 'config/config';
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Accordion from 'components/Accordion';
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import Table from 'components/Table';
import SolidTag from 'components/Tags/SolidTag';
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/*   ACTIONS
 *****************************************************/
import {
    getMigratableResellerAccounts,
    getMigrationCostPreview,
    getMigrationDates,
    getResellerSubaccounts
} from 'containers/ventraSynMigration/action';
import { vsmMethods } from 'containers/ventraSynMigration/methods';
import { getCurrentDate, processUsageStats, roundUpToNearestCent, toLuxonDate } from 'utilities/methods/commonActions';
import { ControlledCheckbox } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SelectServiceForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentKeyword: '',
            openResellerAccount: null,
            selectAll: {}
        };

        this.maxAccounts = props.vsm_requires_migration_data?.maximum_accounts_per_request || 100;

        this.monitorKeyword = this.monitorKeyword.bind(this);
        this.filterSubaccounts = this.filterSubaccounts.bind(this);
        this.setOpenResellerAccount = this.setOpenResellerAccount.bind(this);
        this.toggleOneSelectAll = this.toggleOneSelectAll.bind(this);
        this.getSubAccountsMatrix = this.getSubAccountsMatrix.bind(this);
        this.getMigrationPreview = this.getMigrationPreview.bind(this);
    }

    monitorKeyword(keyword = '') {
        this.setState({
            currentKeyword: keyword
        });
    }

    filterSubaccounts(data) {
        const { currentKeyword } = this.state;

        if (!data) {
            return [];
        }

        return data.filter((account) => account?.attributes?.domain?.includes(currentKeyword));
    }

    setOpenResellerAccount(id = null) {
        this.setState({
            openResellerAccount: id
        });
    }

    toggleOneSelectAll(resellerServiceId, cb, arg) {
        this.setState(
            {
                selectAll: {
                    ...this.state.selectAll,
                    [resellerServiceId]: !this.state.selectAll[resellerServiceId]
                }
            },
            cb(arg)
        );
    }

    getSubAccountsMatrix(data) {
        const { selectAll } = this.state;
        const { accounts, updateAccounts } = this.props;
        const { toggleOneSelectAll, maxAccounts } = this;

        if (!data?.length > 0) return [];

        const renderDomainColumn = (domain, manageable_domain, service_id, is_migrated, migration_date, pci_compliance, subaccountId) => {
            const foundAccount = accounts.find((item) => item.id === subaccountId);

            function renderMigration() {
                if (is_migrated) {
                    return (
                        <div className="vsSelectForm__migratedAt">
                            Migration scheduled for {DateTime.fromFormat(migration_date, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')}
                        </div>
                    );
                }

                if (manageable_domain) {
                    return <SolidTag color="confirm">automated dns update available</SolidTag>;
                }

                return <SolidTag color="secondary">manual dns update required</SolidTag>;
            }

            return (
                <>
                    {!is_migrated && !foundAccount && accounts.length >= maxAccounts ? (
                        <HoverTooltip
                            alignment="left"
                            content={`You can only schedule a maximum of ${maxAccounts} total cPanel accounts across all reseller services for migration in one booking. To select this account you must unselect one or more selected accounts.`}
                        >
                            <ControlledCheckbox
                                label={domain}
                                disabled={true}
                                checked={foundAccount ? true : false}
                                onClick={() => {
                                    function getNewList() {
                                        if (foundAccount) {
                                            return accounts.filter((item) => item.id !== subaccountId);
                                        }

                                        return [
                                            ...accounts,
                                            { id: subaccountId, update_dns: false, info: { service_id, domain, manageable_domain, pci_compliance } }
                                        ];
                                    }

                                    const newList = getNewList();

                                    if (selectAll[service_id]) {
                                        toggleOneSelectAll(service_id, updateAccounts, newList);
                                    } else {
                                        updateAccounts(newList);
                                    }
                                }}
                            />
                        </HoverTooltip>
                    ) : (
                        <ControlledCheckbox
                            label={domain}
                            disabled={is_migrated || (!foundAccount && accounts.length >= maxAccounts)}
                            checked={foundAccount ? true : false}
                            onClick={() => {
                                function getNewList() {
                                    if (foundAccount) {
                                        return accounts.filter((item) => item.id !== subaccountId);
                                    }

                                    return [
                                        ...accounts,
                                        { id: subaccountId, update_dns: false, info: { service_id, domain, manageable_domain, pci_compliance } }
                                    ];
                                }

                                const newList = getNewList();

                                if (selectAll[service_id]) {
                                    toggleOneSelectAll(service_id, updateAccounts, newList);
                                } else {
                                    updateAccounts(newList);
                                }
                            }}
                        />
                    )}
                    <div className="vsSelectForm__tagContainer">
                        {renderMigration()}
                        {!pci_compliance.is_compliant && <SolidTag color="warn">email issues detected</SolidTag>}
                    </div>
                </>
            );
        };

        return data
            .sort((a, b) => {
                if (a.attributes.manageable_domain === b.attributes.manageable_domain) return 0;
                if (!a.attributes.manageable_domain && b.attributes.manageable_domain) return 1;
                return -1;
            })
            .map((subacc) => {
                const {
                    id,
                    attributes: { domain, is_migrated, migration_date, manageable_domain, pci_compliance, new_allocation, new_price, service_id }
                } = subacc;

                return {
                    // These strings with spaces produce extra class names but its the ony way to have the mobile column header be correct
                    'service being migrated': renderDomainColumn(
                        domain,
                        manageable_domain,
                        service_id,
                        is_migrated,
                        migration_date,
                        pci_compliance,
                        id
                    ),
                    'new allocation': is_migrated ? '-' : processUsageStats.disk.valueTitle(new_allocation),
                    'new cost': is_migrated ? '-' : `$${new_price}`
                };
            });
    }

    getMigrationPreview() {
        const { getMigrationCostPreview, formatServices } = this.props;

        getMigrationCostPreview(formatServices());
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getMigratableResellerAccounts, getMigrationDates } = this.props;

        getMigratableResellerAccounts();
        getMigrationDates();
    }

    componentDidUpdate(prevProps, prevState) {
        const { accounts } = this.props;
        const { getMigrationPreview } = this;

        if (accounts && accounts !== prevProps.accounts) getMigrationPreview();
    }

    render() {
        const {
            history,
            accounts,
            updateAccounts,
            vsm_reseller_list_status,
            vsm_reseller_list_data,
            vsm_subaccount_list_status,
            vsm_subaccount_list_data,
            vsm_preview_status,
            vsm_preview_data,
            getResellerSubaccounts
        } = this.props;
        const { currentKeyword, openResellerAccount, selectAll } = this.state;
        const { monitorKeyword, filterSubaccounts, setOpenResellerAccount, toggleOneSelectAll, getSubAccountsMatrix, maxAccounts } = this;

        const renderSubAccounts = () => {
            return (
                <Table
                    className=""
                    header={[
                        {
                            title: `Service being migrated`,
                            className: `service`
                        },
                        {
                            title: <span>New storage allocation</span>,
                            tooltip: `The new storage allocation is the storage size your new plans at Synergy Wholesale will be created with, this is determined by your current storage usage for each of your cPanel accounts.`,
                            className: `allocation`
                        },
                        {
                            title: <span>Service cost once migrated</span>,
                            tooltip:
                                '<div>The price shown is the monthly cost for the service once your migration is complete and you have received up to two free months of hosting with Synergy Wholesale.</div><ul style="margin-top: 10px;list-style-type:disc;margin-left:16px;"><li>All plans are provisioned with 1 CPU core</li><li>All services hosted on WHMMULTI and WHMMULTI PLUS plans are provisioned with 1GB of RAM</li><li>All services hosted on Beginner, Advanced, Professional Hosting plans are provisioned with 2GB of RAM</li><li>All plans can be altered from your Synergy Wholesale account once migrated.</li></ul>',
                            className: `cost`
                        }
                    ]}
                    embedded={true}
                    loading={vsm_subaccount_list_status}
                    matrix={getSubAccountsMatrix(currentKeyword ? filterSubaccounts(vsm_subaccount_list_data) : vsm_subaccount_list_data)}
                    stacked={true}
                    error="No subaccounts matched your search"
                />
            );
        };

        const renderSearch = () => {
            return (
                <div className="search__container">
                    <div className="search__form">
                        <input
                            type="text"
                            onChange={(e) => monitorKeyword(e.target.value)}
                            value={currentKeyword}
                            placeholder="Search for a sub account"
                        />
                        {currentKeyword ? (
                            <button type="onclick" onClick={(e) => monitorKeyword()}>
                                <SolidTag color="info">Clear</SolidTag>
                            </button>
                        ) : (
                            <button type="submit">
                                <span className="icon icon-search"></span>
                            </button>
                        )}
                    </div>
                </div>
            );
        };

        const renderResellerAccount = (resellerServiceId) => {
            const notAlreadyMigratedList =
                vsm_subaccount_list_data?.length > 0
                    ? vsm_subaccount_list_data.filter((subaccount) => subaccount?.attributes?.is_migrated !== true)
                    : [];

            return (
                <Box
                    request={{
                        action: getResellerSubaccounts,
                        args: [resellerServiceId],
                        status: vsm_subaccount_list_status
                    }}
                    desc={
                        <>
                            <div className="vsSelectForm__boxHeader">
                                <ControlledCheckbox
                                    label={
                                        <>
                                            <div className="vsSelectForm__allSubAccLabel">Migrate all sub accounts</div>
                                            <div className="vsSelectForm__numSubAccounts">({notAlreadyMigratedList.length} in total)</div>
                                        </>
                                    }
                                    disabled={notAlreadyMigratedList.length <= 0 || accounts.length >= maxAccounts}
                                    checked={selectAll[resellerServiceId]}
                                    onClick={() => {
                                        let newList = accounts.filter((account) => account.info.service_id !== resellerServiceId);

                                        if (!selectAll[resellerServiceId]) {
                                            const accountsToAdd = notAlreadyMigratedList.map((subaccount) => {
                                                const {
                                                    id,
                                                    attributes: { service_id, domain, manageable_domain, pci_compliance }
                                                } = subaccount;
                                                return { id, update_dns: false, info: { service_id, domain, manageable_domain, pci_compliance } };
                                            });

                                            newList = [...newList, ...accountsToAdd];
                                        }

                                        toggleOneSelectAll(resellerServiceId, updateAccounts, newList);
                                    }}
                                />
                                <div className="vsSelectForm__searchAndRefresh">
                                    {renderSearch()}
                                    <button onClick={() => getResellerSubaccounts(resellerServiceId, true)}>
                                        <i className="vsSelectForm__refresh icon icon-refresh" />
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                    custom={{
                        pos: 'bottom',
                        render: renderSubAccounts()
                    }}
                    footer={
                        <>
                            <div className="vsSelectForm__tableFooter">
                                The above prices are quotes based on your current storage. Should your storage fluctuate between now and the
                                migration, your cost will change if your storage allocation is required to be altered for the migration to complete.
                            </div>
                        </>
                    }
                />
            );
        };

        const renderResellerAccountList = () => {
            return (
                <div className="vsSelectForm__accordionContainer">
                    {vsm_reseller_list_data.map((item, index) => {
                        const {
                            id,
                            attributes: { product, domain }
                        } = item;

                        return (
                            <Accordion
                                key={index}
                                title={
                                    <>
                                        <div className="vsSelectForm__accordionTitleProduct">{product}</div>
                                        <div className="vsSelectForm__accordionTitleDomain">{domain}</div>
                                    </>
                                }
                                content={renderResellerAccount(id)}
                                servicesContainer={true}
                                controlled={{
                                    active: openResellerAccount === id,
                                    toggle: () => setOpenResellerAccount(openResellerAccount === id ? null : id)
                                }}
                            />
                        );
                    })}
                </div>
            );
        };

        const renderBottom = () => {
            const totalCost = vsm_preview_data?.total_cost?.toFixed(2) || '0.00';
            const halfCost = vsm_preview_data?.total_cost ? roundUpToNearestCent(vsm_preview_data.total_cost / 2) : '0.00';
            const freeUntilDate = getCurrentDate().plus({ months: 2 }).startOf('month').toFormat('d LLLL y');

            const renderCostPreview = () => {
                switch (vsm_preview_status) {
                    case 'error':
                        return <div className="vsSelectForm__previewUnavailable">Cost preview not available</div>;
                    case 'loading':
                        return <RequestLoader width={40} height={40} />;
                    case 'success':
                    default:
                        return vsmMethods.isHalfPrice(freeUntilDate) ? (
                            <>
                                <div className="vsSelectForm__pricingGroup vsSelectForm__pricingGroup--halfPrice">
                                    <div className="vsSelectForm__pricingDesc">
                                        Half price from {freeUntilDate} until{' '}
                                        {toLuxonDate(ventraSynMigrationHalfPriceEndDate, 'dd MMMM yyyy TT').toFormat('dd MMMM yyyy')}
                                    </div>
                                    <div className="vsSelectForm__pricingTotal">
                                        ${halfCost}
                                        <span>/mo</span>
                                    </div>
                                    <del className="vsSelectForm__pricingTotal vsSelectForm__pricingTotal--strike">
                                        ${totalCost}
                                        <span>/mo</span>
                                    </del>
                                </div>
                                <div className="vsSelectForm__pricingGroup">
                                    <div className="vsSelectForm__pricingDesc">
                                        After {toLuxonDate(ventraSynMigrationHalfPriceEndDate, 'dd MMMM yyyy TT').toFormat('dd MMMM yyyy')}
                                    </div>
                                    <div className="vsSelectForm__pricingTotal">
                                        ${totalCost}
                                        <span>/mo</span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="vsSelectForm__pricingGroup">
                                <div className="vsSelectForm__pricingDesc">After {freeUntilDate}</div>
                                <div className="vsSelectForm__pricingTotal">
                                    ${totalCost}
                                    <span>/mo</span>
                                </div>
                            </div>
                        );
                }
            };

            return (
                <div className="vsSelectForm__bottom">
                    <h4 className="vsSelectForm__infoHeading">Information</h4>
                    <div className="vsSelectForm__infoBox">
                        <div className="vsSelectForm__infoBoxBg vsSelectForm__infoBoxBg--confirm" />
                        <div className="vsSelectForm__infoBoxContent">
                            <SolidTag color="confirm">automated dns update available</SolidTag>
                            <p className="vsSelectForm__infoBoxText">
                                This domain name points to the service you have selected to migrate and is within your VentraIP or your Synergy
                                Wholesale account. With your permission, we can automatically migrate the account and update the DNS for you. You can
                                request this on the next page.
                            </p>
                        </div>
                    </div>
                    <div className="vsSelectForm__infoBox">
                        <div className="vsSelectForm__infoBoxBg vsSelectForm__infoBoxBg--secondary" />
                        <div className="vsSelectForm__infoBoxContent">
                            <SolidTag color="secondary">manual dns update required</SolidTag>
                            <p className="vsSelectForm__infoBoxText">
                                The domain name for this service is not within your VentraIP or Synergy Wholesale account. You will need to update the
                                DNS records yourself once we have completed your migration, or transfer the domain into either your Synergy Wholesale
                                or VentraIP account before selecting the domain, and we can do it for you!
                            </p>
                        </div>
                    </div>
                    <div className="vsSelectForm__infoBox">
                        <div className="vsSelectForm__infoBoxBg vsSelectForm__infoBoxBg--warn" />
                        <div className="vsSelectForm__infoBoxContent">
                            <SolidTag color="warn">email issues detected</SolidTag>
                            <p className="vsSelectForm__infoBoxText">
                                This tag will show next to any cPanel account if our system has detected one or more email addresses within that
                                account using mail connections that need attention to move to Synergy Wholesale. Hosting services at Synergy Wholesale
                                are PCI compliant to ensure our partners and their clients have a secure online environment.
                                <br />
                                <br />
                                Email accounts using IMAP and POP3 will require to be connected via at least TLS 1.2 to remain functional using
                                third-party mail clients. You will be able to retrieve a list of the detected email addresses followed by why action
                                needs to be taken via the next page.
                            </p>
                        </div>
                    </div>
                    <h4 className="vsSelectForm__infoHeading">Total</h4>
                    <div className="vsSelectForm__infoBox">
                        <div className="vsSelectForm__infoBoxBg vsSelectForm__infoBoxBg--secondary" />
                        <div className="vsSelectForm__infoBoxContent vsSelectForm__infoBoxContent--totals">
                            <p className="vsSelectForm__totalsInfo">What you&apos;ll pay Synergy Wholesale for these hosting services</p>
                            <div className="vsSelectForm__pricing">
                                <div className="vsSelectForm__pricingGroup vsSelectForm__pricingGroup--free">
                                    <HoverTooltip
                                        alignment="right"
                                        color="black"
                                        content="This exclusive offer will supply you with the remainder of this month + one full month of free hosting at Synergy Wholesale for each cPanel account you choose to move to Synergy Wholesale using this offer."
                                    >
                                        <div className="vsSelectForm__pricingDesc">Until {freeUntilDate}</div>
                                    </HoverTooltip>
                                    <div className="vsSelectForm__pricingTotal">FREE</div>
                                </div>
                                <p className="vsSelectForm__pricingFreeConcluded">Once your free time has concluded</p>
                                {renderCostPreview()}
                            </div>
                        </div>
                    </div>
                    {accounts.length > 0 && vsm_preview_status !== 'loading' ? (
                        <SolidButton type="onClick" onClick={() => history.push('/synergy-reseller-migration/confirm-migration')}>
                            Continue
                        </SolidButton>
                    ) : (
                        <InactiveButton>Continue</InactiveButton>
                    )}
                </div>
            );
        };

        const renderPage = () => {
            return (
                <div className="vsSelectForm">
                    <div className="vsSelectForm__stepOne">
                        <span>Step 1.</span>
                        Below is a list of your reseller hosting service(s). Expand your service(s), and then select the subaccounts you wish to
                        migrate/review costs for.
                    </div>
                    {accounts.length >= maxAccounts && (
                        <p className="vsSelectForm__maxAccounts">
                            You have reached the limit of cPanel accounts for this migration booking!
                            {/* <Tooltip info={`You can only schedule a maximum of ${maxAccounts} sub accounts to be migrated at one time.`} /> */}
                        </p>
                    )}
                    {renderResellerAccountList()}
                    {renderBottom()}
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        switch (vsm_reseller_list_status) {
            case 'success':
                return renderPage();
            case 'error':
                return <FetchComponentError />;
            case 'loading':
            default:
                return <RequestLoader />;
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            vsm_requires_migration_data: state.vsm.vsm_requires_migration_data,
            vsm_reseller_list_status: state.vsm.vsm_reseller_list_status,
            vsm_reseller_list_data: state.vsm.vsm_reseller_list_data,
            vsm_subaccount_list_status: state.vsm.vsm_subaccount_list_status,
            vsm_subaccount_list_data: state.vsm.vsm_subaccount_list_data,
            vsm_preview_status: state.vsm.vsm_preview_status,
            vsm_preview_data: state.vsm.vsm_preview_data
        }),
        {
            getMigratableResellerAccounts,
            getResellerSubaccounts,
            getMigrationCostPreview,
            getMigrationDates
        }
    )(SelectServiceForm)
);
