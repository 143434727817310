/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BPAYMethodInfo from './bpay';
import CreditCardMethodInfo from './creditCard';
import EFTTransferMethodInfo from './eftTransfer';
import PaymentMethodsNavItems from './navItems';
import PayPalMethodInfo from './paypal';
import PaymentMethodsRadioButton from './radioButton';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useSetDefaultPaymentMethodMutation, useSetPaymentMethodDataAsDefaultMutation } from 'containers/billing/queries';
import { useGetAvailablePaymentMethodsQuery } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';
import { useGetDefaultPaymentMethodBoilerPlate, useGetDefaultPaymentMethodQuery } from 'containers/billing/queries/useGetDefaultPaymentMethodQuery';
import { getUserPaymentMethodDataListBoilerPlate } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';
import './_paymentMethods.scss';
import { CREDIT_CARD_ACTIONS } from './creditCard/defaultPayment';

const warningMsg =
    'PLEASE NOTE: Changing your default payment method from Credit Card to a new payment method will remove your saved credit cards stored on you account';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let PaymentMethods = (props) => {
    /***** STATE *****/
    const [activeTab, setActiveTab] = useState(null);
    const [selectedPaymentMethodData, setSelectedPaymentMethodData] = useState(null);
    const [isConfirmChangeDefaultPaymentMethodOpen, setIsConfirmChangeDefaultPaymentMethodOpen] = useState(false);
    const [creditCardLightboxMode, setCreditCardLightboxMode] = useState(null);
    function closeConfirmLightBox() {
        setIsConfirmChangeDefaultPaymentMethodOpen(null);
    }

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** QUERIES *****/
    const {
        data: get_default_payment_method_data,
        status: get_default_payment_method_status,
        isFetching: isGetDefaultPaymentMethodFetching,
        isError: isGetDefaultPaymentMethodError
    } = useGetDefaultPaymentMethodQuery({
        onSuccess: (data) => {
            const { default_payment_method } = data.attributes;
            setActiveTab(default_payment_method);
        }
    });

    const { isPending: isSetPaymentMethodDataAsDefaultPending } = useSetPaymentMethodDataAsDefaultMutation();
    const { isLoading: isGetAvailablePaymentMethodsLoading } = useGetAvailablePaymentMethodsQuery({ onError: () => {} });
    const {
        mutate: mutateSetDefaultPaymentMethod,
        status: set_default_payment_method,
        isPending: isSetDefaultPaymentMethodPending
    } = useSetDefaultPaymentMethodMutation({
        onSuccess: (response) => {
            closeConfirmLightBox();
            handleDefaultSuccessNotification(response);
            getUserPaymentMethodDataListBoilerPlate.resetQueries();
            useGetDefaultPaymentMethodBoilerPlate.invalidate();
        }
    });

    const defaultMethod = get_default_payment_method_data?.attributes?.default_payment_method ?? '';

    const isAddCreditCardDefault = activeTab === PAYMENT_METHODS.CREDIT_CARD;

    /***** FUNCTIONS *****/
    function handleUpdateDefaultPaymentMethod() {
        mutateSetDefaultPaymentMethod(selectedPaymentMethodData.id);
    }

    function openAddCreditCardLightbox() {
        setCreditCardLightboxMode(CREDIT_CARD_ACTIONS.ADD);
    }

    function onClickMakeDefaultMethod(_selectedPaymentMethodData) {
        setSelectedPaymentMethodData(_selectedPaymentMethodData);
        if (isAddCreditCardDefault) {
            openAddCreditCardLightbox();
        } else {
            setIsConfirmChangeDefaultPaymentMethodOpen(!!_selectedPaymentMethodData);
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, get_default_payment_method_status === 'success');
    }, [get_default_payment_method_status]);

    useEffect(() => {
        if (!activeTab && get_default_payment_method_data?.attributes?.default_payment_method) {
            setActiveTab(get_default_payment_method_data?.attributes?.default_payment_method);
        }
    }, [get_default_payment_method_data, activeTab]);

    useEffect(() => {
        setSelectedPaymentMethodData(null);
    }, [activeTab]);

    /***** RENDER HELPERS *****/
    const renderPaymentMethodInfo = () => {
        switch (activeTab) {
            case PAYMENT_METHODS.PAYPAL:
                return <PayPalMethodInfo />;

            case PAYMENT_METHODS.BPAY:
                return <BPAYMethodInfo />;

            case PAYMENT_METHODS.EFT_TRANSFER:
            case PAYMENT_METHODS.EFT:
                return <EFTTransferMethodInfo />;

            case PAYMENT_METHODS.CREDIT_CARD:
                return <CreditCardMethodInfo {...{ activeTab, creditCardLightboxMode, setCreditCardLightboxMode }} />;
            default:
                return '';
        }
    };

    const renderConfirmChangeDefault = () => {
        const desc = (
            <div>
                <DialogNotification outline type="warning">
                    Are your sure you want to change your default payment method? Doing this will remove your setup credit cards from file and stop
                    automatic payments via card.
                </DialogNotification>
                <br />
                Paying invoices with your new payment method will need to be made manually. Reverting your payment back to Credit Card can be done at
                anytime.
            </div>
        );

        if (!isConfirmChangeDefaultPaymentMethodOpen) {
            return null;
        }

        return (
            <OverlayLightbox
                title="Change default payment method"
                className="PaymentMethodsConfirmChangeDefaultLightbox"
                warningMsg={defaultMethod === PAYMENT_METHODS.CREDIT_CARD && activeTab !== PAYMENT_METHODS.CREDIT_CARD ? warningMsg : null}
                onOpen
                loading={set_default_payment_method}
                onClose={closeConfirmLightBox}
                confirm={{
                    desc,
                    buttonText: 'Confirm',
                    buttonAction: handleUpdateDefaultPaymentMethod,
                    closeText: 'No, Go Back',
                    closeAction: closeConfirmLightBox
                }}
            />
        );
    };

    function renderUpdatePaymentMethodActionLabel() {
        if (defaultMethod === activeTab || !selectedPaymentMethodData) {
            return (
                <>
                    <PhosphorIcons.LockKey.Fill />
                    &nbsp;Update
                </>
            );
        }
        if (isSetPaymentMethodDataAsDefaultPending) {
            return <RequestLoader />;
        }
        return 'Update';
    }

    function handlePaymentMethodButtonClick() {
        if (isAddCreditCardDefault) {
            openAddCreditCardLightbox();
        } else {
            onClickMakeDefaultMethod(selectedPaymentMethodData);
        }
    }

    function renderUpdatePaymentMethodButton() {
        const isInactive = defaultMethod === activeTab || !selectedPaymentMethodData || isSetPaymentMethodDataAsDefaultPending;
        const ButtonType = isInactive ? InactiveButton : SolidButton;

        return (
            <ButtonType
                key="renderUpdatePaymentMethodButton"
                size="large"
                type="onClick"
                onClick={isInactive ? null : handlePaymentMethodButtonClick}
            >
                {renderUpdatePaymentMethodActionLabel()}
            </ButtonType>
        );
    }

    function renderPaymentMethodContent() {
        if (isGetDefaultPaymentMethodFetching || isSetDefaultPaymentMethodPending || isGetAvailablePaymentMethodsLoading) {
            return <RequestLoader message="Loading Default Payment Method" height={30} />;
        }

        if (isGetDefaultPaymentMethodError) {
            return <FetchComponentError />;
        }

        return (
            <NXBox.BottomColumns
                columns={[
                    <PaymentMethodsRadioButton key={1} {...{ activeTab, selectedPaymentMethodData, onClickMakeDefaultMethod }} />,
                    isAddCreditCardDefault ? (
                        <SolidButton key="openAddCreditCardLightbox" size="large" type="onClick" onClick={openAddCreditCardLightbox}>
                            Add Credit Card
                        </SolidButton>
                    ) : (
                        renderUpdatePaymentMethodButton()
                    )
                ]}
            ></NXBox.BottomColumns>
        );
    }

    /***** RENDER *****/
    return (
        <>
            <div ref={scrollRef} className="availablePaymentMethod container">
                <NXBox className="availablePaymentMethod__box">
                    <NXBox.Top title="Available Payment Methods" />
                    <NXBox.DefaultPadding>
                        <PaymentMethodsNavItems {...{ activeTab, setActiveTab }} />
                    </NXBox.DefaultPadding>
                    {renderPaymentMethodInfo()}
                    <NXBox.Bottom>{renderPaymentMethodContent()}</NXBox.Bottom>
                </NXBox>
            </div>
            {renderConfirmChangeDefault()}
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PaymentMethods = withRouter(PaymentMethods);

export default PaymentMethods;
