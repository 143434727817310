/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';

/**********************************************************************************************************
 *   CONSTS - IMPORTS
 **********************************************************************************************************/
import { migrationFormNameFull } from './consts';

/**********************************************************************************************************
 *   CONSTS - DATA
 **********************************************************************************************************/
const radioGroupField = getFieldComponentAndClassName(FieldTypesEnum.RADIO_GROUP);
const textField = getFieldComponentAndClassName(FieldTypesEnum.TEXT);
const radioGroupOptions = [
    {
        label: 'Yes',
        value: true
    },
    {
        label: 'No',
        value: false
    }
];
const sshAccessDetailFields = [
    {
        label: 'SSH Username',
        name: 'ssh_username'
    },
    {
        label: 'SSH Password',
        name: 'ssh_password'
    },
    {
        label: 'SSH Port',
        name: 'ssh_port'
    }
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let SSHAccessDetailsForm = (props) => {
    const { ssh_check } = props;

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="sshCheck">
            <Field
                component={radioGroupField.component}
                name="ssh_check"
                type={FieldTypesEnum.SELECT}
                className={radioGroupField.className}
                options={radioGroupOptions}
            />

            {ssh_check ? (
                <>
                    {sshAccessDetailFields.map((field) => {
                        const { label, name } = field;
                        return (
                            <Field
                                key={name}
                                component={textField.component}
                                label={label}
                                name={name}
                                type={FieldTypesEnum.TEXT}
                                className={textField.className}
                            />
                        );
                    })}
                </>
            ) : (
                ''
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    const valueSelector = formValueSelector(migrationFormNameFull);
    return {
        ssh_check: valueSelector(state, 'ssh_check')
    };
};

SSHAccessDetailsForm = connect(mapStateToProps, null)(SSHAccessDetailsForm);

export default SSHAccessDetailsForm;
