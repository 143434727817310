/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useReviewPlan } from '.';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *  ReviewTotal - Private Component exported as part of the ComposableReviewPlan Component
 */
const _ReviewTotal = () => {
    /***** HOOKS *****/
    const { total, isDowngrade } = useReviewPlan();

    /***** RENDER *****/
    return (
        <div className="reviewPlan__total">
            <Padding x={0} y={3}>
                <Text bold secondary size--s>
                    {`TOTAL ${isDowngrade ? 'DOWNGRADE' : 'UPGRADE'} COST`}
                </Text>
            </Padding>
            <Text bold primary>
                <span>
                    ${total}
                    <Text bold primary size--xss>
                        <span> AUD</span>
                    </Text>
                </span>
            </Text>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _ReviewTotal;
