/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function KatanaDescription({ children, caveats }) {
    const descriptionItalic = useGetCaveat(CaveatTypes.DESCRIPTION_ITALIC, caveats);

    /***** RENDER *****/
    if (children)
        return (
            <Text secondary size--s italic={descriptionItalic}>
                {children}
            </Text>
        );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaDescription;
