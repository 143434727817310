/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { has, keys } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useFormValuesFileUpload } from 'containers/katana/hooks/useFormValuesFileUpload';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   Katana Update site info
 **********************************************************************************************************/
/**
 * Gets the site info
 * @param {number} serviceID
 * @param {any} options - mutation options
 * @param {{
 *  context?: import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Uploads.POST.Parameters['formData']['context']
 * }} fileUploadOptions
 */
export function useUpdateKatanaSiteInfoMutation(serviceID, options = {}, fileUploadOptions = {}) {
    /***** HOOKS *****/
    const { page } = useSetupEditorRouteParams();
    const processAndUploadFiles = useFormValuesFileUpload(serviceID, fileUploadOptions);

    /***** FUNCTIONS *****/
    /**
     * @param {import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Info.POST.Params['attributes']} values
     */
    async function handleMutationAsync(values) {
        const filteredAttributes = filterEndpointAttributeKeys(values, useUpdateKatanaSiteInfoMutation.acceptableKeys);

        const fileRecords = await processAndUploadFiles(filteredAttributes);

        if (has(values, 'color') || has(values, 'style')) {
            const cancelQueries = [
                katanaQuery.serviceID.getService.cancelQueries(serviceID),
                katanaQuery.serviceID.getSiteInfo.cancelQueries(serviceID),
                katanaQuery.serviceID.getNavigation.cancelQueries(serviceID)
            ];
            await Promise.all(cancelQueries);
        }

        if (has(values, 'color')) {
            katanaQuery.serviceID.getService.optimisticSiteColourUpdate(serviceID, values.color);
            katanaQuery.serviceID.getSiteInfo.applyOptimisticColourUpdate(serviceID, values.color);
            katanaQuery.serviceID.getNavigation.optimisticNavigationColourUpdate(serviceID, values.color);
        }

        if (has(values, 'style')) {
            katanaQuery.serviceID.getSiteInfo.applyOptimisticStyleUpdate(serviceID, values.style);
            katanaQuery.serviceID.getService.applyOptimisticStyleUpdate(serviceID, values.style);
        }

        const updatedAttributes = {
            ...filteredAttributes,
            ...fileRecords
        };

        if (!keys(updatedAttributes).length) {
            return Promise.resolve();
        }
        return await KATANA_API.katana.service_id.info.POST({ serviceID, attributes: updatedAttributes });
    }

    /**
     * @param {import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Info.POST.Params['attributes']} values
     */
    function handleMutation(values) {
        return handleMutationAsync(values);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: handleMutation,
        onSuccess: page === 'setup' ? null : handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,
        onSettled: () => {
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSiteInfo.invalidateQueries(serviceID);
            katanaQuery.serviceID.getBusinessInfo.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSitePreview.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSectionSitePreview.invalidateAllQueries(serviceID);
        },
        ...options
    });
}

useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS = /** @type {const} */ ({
    TITLE: 'title',
    DESCRIPTION: 'description',
    IS_ACTIVE: 'is_active',
    COLOR: 'color',
    FAVICON: 'favicon',
    STYLE: 'style'
});
useUpdateKatanaSiteInfoMutation.acceptableKeys = Object.values(useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS);
