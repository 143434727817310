/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import cPanelImg from 'assets/images/support/cpanel.svg';
import Anchor from 'components/Anchor';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import Progress from 'components/Progress';

import { truncateText } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getCpanelDetails, getSubaccountList } from '../../state/accountActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Overview extends Component {
    constructor(props) {
        super(props);
        this.processUsage = this.processUsage.bind(this);
        this.processLimit = this.processLimit.bind(this);
        this.state = {
            usage: null,
            subaccount: null
        };
    }

    processLimit(limit) {
        if (limit && limit === 'unlimited') {
            return undefined;
        }

        const limitInt = limit / 1000 + 'GB';
        return limitInt;
    }

    processUsage(usage) {
        if (usage && usage === 'unlimited') {
            return undefined;
        }

        const usageInt = usage + 'MB';
        return usageInt;
    }

    componentDidUpdate(prevProps) {
        const { hosting_information_status, hosting_subaccount_list_status, hosting_subaccount_list_data } = this.props;
        registerScrollEvents(this, hosting_information_status === 'success' && prevProps.hosting_information_status === 'loading');

        if (hosting_subaccount_list_status === 'success' && prevProps.hosting_subaccount_list_status === 'loading') {
            const { attributes } = hosting_subaccount_list_data;
            const { disk_used, disk_quote, allowed_subaccount_amount, subaccounts } = attributes;

            this.setState({
                usage: {
                    disk_used,
                    disk_quote
                },
                subaccount: {
                    total: allowed_subaccount_amount,
                    length: subaccounts.length
                }
            });
        }
    }

    componentDidMount() {
        const { hostingid, getSubaccountList, hosting_subaccount_list_data } = this.props;

        if (!hosting_subaccount_list_data) {
            getSubaccountList(hostingid);
        } else {
            const { attributes } = hosting_subaccount_list_data;
            const { disk_used, disk_quote, allowed_subaccount_amount, subaccounts } = attributes;

            this.setState({
                usage: {
                    disk_used,
                    disk_quote
                },
                subaccount: {
                    total: allowed_subaccount_amount,
                    length: subaccounts.length
                }
            });
        }
    }

    render() {
        const { hostingid, hosting_isReseller, hosting_information_status, hosting_information_data, getCpanelDetails } = this.props;
        const { usage, subaccount } = this.state;
        const { processUsage, processLimit } = this;
        const { domain, domain_status, next_invoice_date, next_due_date, billing_cycle, registration_date, amount } =
            hosting_information_data.attributes;
        const hostingName = hosting_information_data.included[0].attributes.name.toLowerCase();

        const renderDate = (date) => {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const newDate = new Date(date);
            if (newDate.toLocaleDateString('en-GB', options) === 'Invalid Date') {
                return '';
            } else {
                return newDate.toLocaleDateString('en-GB', options);
            }
        };

        /*  Render Component
         **********************************************************************************************************/
        switch (hosting_information_status) {
            case 'loading':
                return (
                    <div
                        ref={(el) => {
                            this.scrollRef = el;
                        }}
                        className="ComponentLoading"
                    >
                        <div className="ComponentLoading__wrapper">
                            <RequestLoader />
                        </div>
                    </div>
                );

            case 'success':
                return (
                    <div
                        ref={(el) => {
                            this.scrollRef = el;
                        }}
                        className="hostingOverview"
                    >
                        <div className="box split">
                            <div className="box__split--left">
                                <div className="box__wrapper">
                                    <h2 className="box__heading">Service Overview</h2>
                                    <div className="info__container">
                                        <div className="box__description">Primary Domain Name</div>
                                        <div className="box__subheading">
                                            {truncateText(domain, 35, '...')}
                                            <Anchor to={`/my-services/hosting/account/primary-domain/${hostingid}?open=true`}>Change</Anchor>
                                        </div>
                                    </div>
                                    <div className="info__container">
                                        <div className="box__description">Status</div>
                                        <div
                                            className={`box__subheading ${domain_status === 'Suspended' ? 'warning' : ''} ${
                                                domain_status === 'Active' ? 'confirm' : ''
                                            }`}
                                        >
                                            {domain_status}
                                        </div>
                                    </div>
                                    <div className="info__container">
                                        <div className="box__description">Hosting Plan</div>
                                        <div className="box__subheading plan">{hostingName}</div>
                                    </div>
                                    <div className="info__container">
                                        <div className="box__description">Activated</div>
                                        <div className="box__subheading">{renderDate(registration_date)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="box__split--right">
                                <div className="box__wrapper">
                                    <h2 className="box__heading">Billing Overview</h2>
                                    <div className="info__container">
                                        <div className="box__description">Next Renewal Date</div>
                                        <div className="box__subheading">{renderDate(next_due_date)}</div>
                                    </div>
                                    <div className="info__container">
                                        <div className="box__description">Next Invoice Generation Date</div>
                                        <div className="box__subheading">{renderDate(next_invoice_date)}</div>
                                    </div>
                                    <div className="info__container">
                                        <div className="box__description">Billing Cycle</div>
                                        <div className="box__subheading">
                                            {billing_cycle}
                                            <Anchor to={`/my-services/hosting/account/renew/${hostingid}?open=true&type=billing`}>Change</Anchor>
                                        </div>
                                    </div>
                                    <div className="info__container">
                                        <div className="box__description">Renewal Amount</div>
                                        <div className="box__subheading">${amount} AUD</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {hosting_isReseller && subaccount ? (
                            <div className="hostingOverview__extended">
                                <div className="hostingOverview__extended--progress">
                                    {subaccount ? (
                                        <div>
                                            <div className="cpanelProgress">
                                                <img className="cpanelProgress__image" src={cPanelImg} alt="cPanel" />
                                                <Progress
                                                    title="Active cPanel Accounts"
                                                    max={subaccount.total}
                                                    value={subaccount.length}
                                                    valueTitle={subaccount.length}
                                                    maxTitle={subaccount.total}
                                                />
                                            </div>
                                            <Anchor to={`/my-services/hosting/reseller/subaccounts/${hostingid}`}>View all cPanel services</Anchor>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="hostingOverview__extended--progress">
                                    {usage ? (
                                        <Progress
                                            title="Disk Space"
                                            max={usage.disk_quote}
                                            value={usage.disk_used}
                                            valueTitle={processUsage(usage.disk_used)}
                                            maxTitle={processLimit(usage.disk_quote)}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                );

            case 'error':
                return (
                    <div
                        ref={(el) => {
                            this.scrollRef = el;
                        }}
                        className="ComponentError"
                    >
                        <div className="ComponentError__wrapper">
                            <FetchComponentError />
                            <div className="error__refresh">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getCpanelDetails(hostingid);
                                    }}
                                >
                                    <i className="icon icon-reload" />
                                </button>
                            </div>
                        </div>
                    </div>
                );

            default:
                return '';
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_isReseller: state.hosting.hosting_isReseller,
        hosting_information_status: state.hosting.hosting_information_status,
        hosting_information_data: state.hosting.hosting_information_data,
        hosting_information_error: state.hosting.hosting_information_error,
        hosting_subaccount_list_status: state.hosting.hosting_subaccount_list_status,
        hosting_subaccount_list_data: state.hosting.hosting_subaccount_list_data,
        hosting_subaccount_list_error: state.hosting.hosting_subaccount_list_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCpanelDetails,
            getSubaccountList
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));
