/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Border from 'components/Utils/Border';
import CloseButton from './CloseButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders its children with a top border when open, otherwise renders nothing
 *
 * @type {React.FC<{
 *   isOpen: boolean,
 *   embedded?: boolean,
 *   children: React.ReactNode
 * }>}
 */
function NXBoxDropdown({ isOpen, embedded, children }) {
    /***** RENDER *****/
    if (!isOpen) {
        return null;
    }

    return (
        <Border top={!embedded} colour="C_NXBoxSplit__section_color_border">
            <div className="NXBoxDropdown">{children}</div>
        </Border>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** Compound Components START *****/
NXBoxDropdown.CloseButton = CloseButton;
/***** Compound Components END *****/

export default NXBoxDropdown;
