/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// assets
import power_off_image from 'assets/images/vps/power-off.svg';
import power_on_image from 'assets/images/vps/power-on.svg';
import power_reload_image from 'assets/images/vps/power-reboot.svg';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ConfirmationLightbox } from 'containers/vps';
import { getVPSPowerState, getVPSPowerStateSilent, rebootVPS, shutdownVPS, startupVPS } from 'containers/vps/action';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { cancelGetSolusVPSInfo } from 'utilities/api/vps';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class PowerManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDropdownOpen: false,
            selectedAction: 'reboot',
            isChecking: false,
            rebootVPSLightbox: false,
            shutdownVPSLightbox: false,
            startupVPSLightbox: false
        };

        this.renderVPSStatus = this.renderVPSStatus.bind(this);
        this.reboot = this.reboot.bind(this);
        this.shutdown = this.shutdown.bind(this);
        this.startup = this.startup.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkingState = this.checkingState.bind(this);
        this.getActions = this.getActions.bind(this);
        this.closeRebootLightbox = this.closeRebootLightbox.bind(this);
        this.closeShutdownLightbox = this.closeShutdownLightbox.bind(this);
        this.openShutdownLightbox = this.openShutdownLightbox.bind(this);
        this.openRebootLightbox = this.openRebootLightbox.bind(this);
        this.openStartupLightbox = this.openStartupLightbox.bind(this);
        this.closeStartupLightbox = this.closeStartupLightbox.bind(this);
    }

    checkingState() {
        const { vps_information_data } = this.props;
        const { id } = vps_information_data;
        this.setState({
            isChecking: true
        });

        this.checkerTimer = window.setInterval(() => {
            const { getVPSPowerStateSilent } = this.props;

            getVPSPowerStateSilent(id);
        }, 3000);

        if (!this.checkerTimer) return;

        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            window.clearInterval(this.checkerTimer);
            this.setState({
                isChecking: false
            });
        }, 15000);
    }

    reboot() {
        const { vps_information_data, rebootVPS } = this.props;
        const { id } = vps_information_data;
        this.setState({
            isDropdownOpen: false,
            isChecking: true
        });

        rebootVPS(id);
    }

    startup() {
        const { vps_information_data, startupVPS } = this.props;
        const { id } = vps_information_data;
        this.setState({
            isDropdownOpen: false,
            isChecking: true
        });

        startupVPS(id);
    }

    shutdown() {
        const { vps_information_data, shutdownVPS } = this.props;
        const { id } = vps_information_data;
        this.setState(
            {
                isDropdownOpen: false,
                isChecking: true
            },
            () => shutdownVPS(id)
        );
    }

    renderVPSStatus() {
        const { vps_power_state_data } = this.props;
        const { isChecking } = this.state;

        if (!vps_power_state_data) return;

        const { state } = vps_power_state_data;

        const renderIcon = () => {
            if (isChecking === true)
                return (
                    <img
                        src={power_reload_image}
                        alt="power on"
                        className="VPS-Power-Management__status-icon VPS-Power-Management__status-icon--loading"
                    />
                );

            return (
                <img
                    src={state === serverPowerStates.RUNNING ? power_on_image : power_off_image}
                    alt="power on"
                    className="VPS-Power-Management__status-icon"
                />
            );
        };

        return (
            <div className="VPS-Power-Management__status">
                <div className="VPS-Power-Management__status-icon-wrapper">{renderIcon()}</div>
                <div className="VPS-Power-Management__details">
                    <div className="VPS-Power-Management__details-label">VPS Status</div>
                    <div className="VPS-Power-Management__details-value">{isChecking ? 'Loading' : state}</div>
                </div>
            </div>
        );
    }

    handleSubmit() {
        const { openRebootLightbox, openShutdownLightbox } = this;
        const { selectedAction } = this.state;

        if (selectedAction === 'reboot') openRebootLightbox();
        else openShutdownLightbox();
    }

    getActions() {
        const { vps_power_state_data } = this.props;
        const defaultButton = {
            type: 'onClick',
            label: 'Manage',
            size: 'big',
            onClick: (e) => {
                e.preventDefault();
                const { isDropdownOpen } = this.state;

                if (isDropdownOpen) {
                    this.setState({
                        isDropdownOpen: false
                    });
                } else {
                    this.setState({
                        isDropdownOpen: true
                    });
                }
            }
        };

        if (!vps_power_state_data) return defaultButton;

        const { state } = vps_power_state_data;
        const { isChecking } = this.state;

        if (isChecking) return null;

        if (state === serverPowerStates.RUNNING) return defaultButton;

        if (state === serverPowerStates.SHUTDOWN) {
            const { openStartupLightbox } = this;

            return {
                type: 'onClick',
                label: 'Power On VPS',
                size: 'big',
                onClick: (e) => {
                    e.preventDefault();
                    openStartupLightbox();
                }
            };
        }

        return null;
    }

    closeShutdownLightbox() {
        this.setState({
            shutdownVPSLightbox: false
        });
    }

    openShutdownLightbox() {
        this.setState({
            shutdownVPSLightbox: true
        });
    }

    closeRebootLightbox() {
        this.setState({
            rebootVPSLightbox: false
        });
    }

    openRebootLightbox() {
        this.setState({
            rebootVPSLightbox: true
        });
    }

    closeStartupLightbox() {
        this.setState({
            startupVPSLightbox: false
        });
    }

    openStartupLightbox() {
        this.setState({
            startupVPSLightbox: true
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps) {
        const { vps_power_state_status, vps_reboot_status, vps_shutdown_status, vps_startup_status } = this.props;
        const { checkingState } = this;

        registerScrollEvents(this, vps_power_state_status === 'success' && prevProps.vps_power_state_status === 'loading');

        if (vps_reboot_status === 'success' && prevProps.vps_reboot_status === 'loading') {
            this.setState(
                {
                    isDropdownOpen: false
                },
                checkingState
            );
        }

        if (vps_shutdown_status === 'success' && prevProps.vps_shutdown_status === 'loading') {
            this.setState(
                {
                    isDropdownOpen: false
                },
                checkingState
            );
        }

        if (vps_startup_status === 'success' && prevProps.vps_startup_status === 'loading') {
            this.setState(
                {
                    isDropdownOpen: false
                },
                checkingState
            );
        }
    }

    componentWillUnmount() {
        if (this.checkerTimer) {
            window.clearInterval(this.checkerTimer);
            this.setState({
                isChecking: false
            });
        }

        if (cancelGetSolusVPSInfo) {
            cancelGetSolusVPSInfo();
        }
    }

    render() {
        const { vps_information_data, vps_power_state_status, getVPSPowerState } = this.props;
        const { id } = vps_information_data;
        const { isDropdownOpen, selectedAction, rebootVPSLightbox, shutdownVPSLightbox, startupVPSLightbox } = this.state;
        const {
            renderVPSStatus,
            handleSubmit,
            getActions,
            closeRebootLightbox,
            closeShutdownLightbox,
            closeStartupLightbox,
            reboot,
            shutdown,
            startup
        } = this;

        return (
            <Fragment>
                <div
                    ref={(el) => {
                        this.scrollRef = el;
                    }}
                    className={`VPS-Power-Management${isDropdownOpen ? ' VPS-Power-Management--open' : ''}`}
                >
                    <Box
                        request={{
                            action: getVPSPowerState,
                            args: id,
                            status: vps_power_state_status
                        }}
                        title="Power Management"
                        desc="Reboot or shutdown your Virtual Private Server."
                        bottom={true}
                        className="VPS-Power-Management__box"
                        columns={[
                            {
                                render: renderVPSStatus()
                            }
                        ]}
                        action={getActions()}
                        status={vps_power_state_status}
                    />
                    {isDropdownOpen && (
                        <div className="VPS-Power-Management__dropdown VPS-Dropdown">
                            <ul className="VPS-Dropdown__list">
                                <button
                                    className={`VPS-Dropdown__item${selectedAction === 'reboot' ? ' VPS-Dropdown__item--selected' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            selectedAction: 'reboot'
                                        });
                                    }}
                                >
                                    <div className="VPS-Dropdown__radio" />
                                    <div className="VPS-Dropdown__details">
                                        <div className="VPS-Dropdown__details-heading">Reboot Server</div>
                                        <div className="VPS-Dropdown__details-description">
                                            Rebooting your server will shut it down and restart it again; the process will take 1 minute to complete.
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className={`VPS-Dropdown__item${selectedAction === 'shutdown' ? ' VPS-Dropdown__item--selected' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            selectedAction: 'shutdown'
                                        });
                                    }}
                                >
                                    <div className="VPS-Dropdown__radio" />
                                    <div className="VPS-Dropdown__details">
                                        <div className="VPS-Dropdown__details-heading">Shutdown Server</div>
                                        <div className="VPS-Dropdown__details-description">
                                            Shutting down your VPS will stop any processes running on your server.
                                        </div>
                                    </div>
                                </button>
                            </ul>
                            <SolidButton
                                className="VPS-Power-Management__button"
                                type="onClick"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                Confirm
                            </SolidButton>
                        </div>
                    )}
                </div>
                {rebootVPSLightbox && (
                    <OverlayLightbox onOpen={rebootVPSLightbox} className="VPS-Lightbox" onClose={closeRebootLightbox} title="Reboot VPS">
                        <ConfirmationLightbox
                            description="Please confirm you wish to Reboot your VPS. Note there will be some downtime for your service."
                            action={reboot}
                            actionlabel="Reboot"
                        />
                    </OverlayLightbox>
                )}
                {shutdownVPSLightbox && (
                    <OverlayLightbox onOpen={shutdownVPSLightbox} className="VPS-Lightbox" onClose={closeShutdownLightbox} title="Shutdown VPS">
                        <ConfirmationLightbox
                            description="Please confirm you wish to Shutdown your VPS. Note there will be some downtime for your service."
                            action={shutdown}
                            actionlabel="Shutdown"
                        />
                    </OverlayLightbox>
                )}
                {startupVPSLightbox && (
                    <OverlayLightbox onOpen={startupVPSLightbox} className="VPS-Lightbox" onClose={closeStartupLightbox} title="Power On VPS">
                        <ConfirmationLightbox
                            description="Please confirm you wish to power on your VPS. Note there will be some downtime for your service."
                            action={startup}
                            actionlabel="Power On"
                        />
                    </OverlayLightbox>
                )}
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            vps_power_state_status: state.vps.vps_power_state_status,
            vps_power_state_data: state.vps.vps_power_state_data,
            vps_information_data: state.vps.vps_information_data,
            vps_reboot_status: state.vps.vps_reboot_status,
            vps_shutdown_status: state.vps.vps_shutdown_status,
            vps_startup_status: state.vps.vps_startup_status
        }),
        {
            getVPSPowerState,
            getVPSPowerStateSilent,
            rebootVPS,
            startupVPS,
            shutdownVPS
        }
    )(PowerManagement)
);

const serverPowerStates = {
    RUNNING: 'running',
    SHUTDOWN: 'shutdown',
    UNAVAILABLE: 'unavailable',
    UNKNOWN: 'unknown'
};
