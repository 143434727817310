import { useMemo, useRef } from 'react';
import { deepDiff } from 'utilities/methods/recursion/deepDiff/deepDiff';

/**
 * @param {Object} obj
 */
export function useObjectDiff(obj) {
    const lastObjRef = useRef(obj);

    return useMemo(() => {
        const diff = deepDiff(lastObjRef.current, obj);
        lastObjRef.current = obj;
        return diff;
    }, [obj]);
}
