/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SimpleCheckbox } from 'components/Form/SimpleCheckbox';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaBooleanFormFieldCheckbox: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, validationFunctions } = property;

    return <Field name={key} label={name} component={SimpleCheckbox.ReduxForm} validate={validationFunctions} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaBooleanFormFieldCheckbox };
