/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import GSuiteUserDetailsForm from '../forms/userDetails';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Note from 'components/Note';
import Table from 'components/Table';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import { cancelGetUsers } from 'utilities/api/gsuite';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { createGSuiteUserData, getGSuiteService, getGSuiteUserAccounts } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteUserAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUserSetup: false,
            showInvoiceLightbox: false,
            invoiceID: false,
            users: null,
            pending: {},
            domain: ''
        };
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleUserSetup = this.toggleUserSetup.bind(this);
        this.handleCreateUserRequest = this.handleCreateUserRequest.bind(this);
    }

    /************** OPEN/CLOSE PAY INVOICE **************/
    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceID: id
        });
    }

    closePayInvoice() {
        this.setState({
            showInvoiceLightbox: false,
            invoiceID: null
        });
    }

    closePayInvoiceSuccess() {
        const { gsuiteid } = this.props;

        this.setState(
            {
                showBillingDropdown: false,
                billingOptions: null,
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getGSuiteService(gsuiteid);
            }
        );
    }

    /************** TOGGLE USER SETUP **************/
    toggleUserSetup() {
        const { showUserSetup } = this.state;

        this.setState({
            showUserSetup: !showUserSetup
        });
    }

    handleCreateUserRequest(values) {
        const { createGSuiteUserData, gsuiteid, gsuite_service_data } = this.props;

        const valuesToSend = {
            ...values,
            email_domain: gsuite_service_data?.attributes?.domain || ''
        };

        createGSuiteUserData(gsuiteid, valuesToSend);
    }

    componentDidMount() {
        const { gsuite_service_data } = this.props;

        if (gsuite_service_data) {
            const { attributes } = gsuite_service_data;
            const { domain } = attributes;

            this.setState({
                domain
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { gsuite_user_accounts_status, gsuite_user_accounts_data, gsuite_user_create_status } = this.props;
        const { toggleUserSetup } = this;
        registerScrollEvents(this, gsuite_user_accounts_status === 'success' && prevProps.gsuite_user_accounts_status === 'loading');

        if (gsuite_user_accounts_status === 'success' && prevProps.gsuite_user_accounts_status === 'loading') {
            const { attributes, invoice } = gsuite_user_accounts_data;
            const { users, pending_payment, pending_accounts } = attributes;

            this.setState({
                users,
                pending: {
                    payment: pending_payment,
                    setup: pending_accounts,
                    invoice: invoice ? invoice : null
                }
            });
        }

        if (gsuite_user_create_status === 'success' && prevProps.gsuite_user_create_status === 'loading') {
            toggleUserSetup();
        }
    }

    componentWillUnmount() {
        if (cancelGetUsers) {
            cancelGetUsers();
        }
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    }

    render() {
        const {
            history,
            gsuiteid,
            gsuite_verification_status_status,
            gsuite_verification_status_data,
            gsuite_user_accounts_status,
            gsuite_user_create_status,
            getGSuiteUserAccounts
        } = this.props;
        const { showUserSetup, showInvoiceLightbox, InvoiceID, users, pending, domain } = this.state;
        const { openPayInvoice, closePayInvoice, closePayInvoiceSuccess, handleCreateUserRequest, toggleUserSetup } = this;

        /*   CREATE MATRIX
         **********************************************************************************************************/
        let userAccountMatrix = [];

        if (users && users.length > 0) {
            userAccountMatrix = Array(users.length).fill();
            Object.keys(userAccountMatrix).forEach((value) => {
                const { attributes } = users[value];
                const { key, primary_email, first_name, last_name } = attributes;

                userAccountMatrix[value] = {
                    mailbox: (
                        <div className="gsuiteMailbox">
                            <div className="gsuiteMailbox__title">{primary_email}</div>
                            <div className="gsuiteMailbox__desc">{`${first_name} ${last_name}`}</div>
                        </div>
                    ),
                    actions: {
                        label: 'Manage User',
                        size: 'medium',
                        color: 'primary',
                        type: 'onClick',
                        onClick: (e) => {
                            e.preventDefault();
                            history.push(`/my-services/google/manage/overview/${gsuiteid}/mailbox/${key}/user-details`);
                        }
                    }
                };
            });

            if (pending.payment) {
                userAccountMatrix.push({
                    mailbox: (
                        <div className="gsuiteMailbox">
                            <div className="gsuiteMailbox__desc--alt">This seat hasn&apos;t been setup yet and is awaiting payment</div>
                        </div>
                    ),
                    actions: {
                        label: 'Pay Invoice',
                        type: 'onClick',
                        color: 'primary',
                        size: 'small',
                        button: 'Solid',
                        onClick: (e) => {
                            e.preventDefault();
                            openPayInvoice(pending.invoice);
                        },
                        list: [
                            {
                                label: 'View Invoice',
                                type: 'onClick',
                                onClick: (e) => {
                                    e.preventDefault();
                                    history.push(`/billing/invoice/${pending.invoice}`);
                                }
                            }
                        ]
                    }
                });
            }

            if (pending.setup) {
                for (let i = 0; i < pending.setup; i++) {
                    if (
                        gsuite_verification_status_status === 'success' &&
                        gsuite_verification_status_data &&
                        !gsuite_verification_status_data.attributes.verified
                    ) {
                        userAccountMatrix.push({
                            mailbox: (
                                <div className="gsuiteMailbox">
                                    <div className="gsuiteMailbox__desc--alt">This seat hasn&apos;t been setup yet</div>
                                </div>
                            ),
                            actions: (
                                <div className="contact__link">
                                    <div className="contact__note">The associated domain is not verified, Please verify the domain name</div>
                                    <Anchor
                                        onClick={() => {
                                            history.push(`/support/tickets/submit`);
                                        }}
                                    >
                                        Contact us for assistance
                                    </Anchor>
                                </div>
                            )
                        });
                    } else {
                        userAccountMatrix.push({
                            mailbox: (
                                <div className="gsuiteMailbox">
                                    <div className="gsuiteMailbox__desc--alt">This seat hasn&apos;t been setup yet</div>
                                </div>
                            ),
                            actions: {
                                label: 'Setup User',
                                type: 'onClick',
                                color: 'primary',
                                size: 'small',
                                button: 'Solid',
                                onClick: (e) => {
                                    e.preventDefault();
                                    toggleUserSetup();
                                }
                            }
                        });
                    }
                }
            }
        }

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {
            title: `User Accounts`
        };

        if (showUserSetup) {
            conditionalProps = {
                title: `Setup User`
            };
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="gsuiteUserAccounts"
            >
                <Box
                    request={{
                        action: getGSuiteUserAccounts,
                        args: gsuiteid,
                        status: gsuite_user_accounts_status
                    }}
                    title="User Accounts"
                    status={gsuite_user_create_status}
                    desc={<Note beforeColon="Please Note" text="It can take up to 5-10 minutes for changes to reflect." />}
                    custom={{
                        render: (
                            <Transition when={showUserSetup}>
                                {showUserSetup ? (
                                    <GSuiteUserDetailsForm domain={domain} isNested={true} onSubmit={handleCreateUserRequest} />
                                ) : (
                                    <Table
                                        premounted={true}
                                        embedded={true}
                                        className="gsuiteUserAccounts__table"
                                        header={[
                                            {
                                                title: `Mailbox / Name`,
                                                className: `mailbox`
                                            },
                                            {
                                                action: true
                                            }
                                        ]}
                                        matrix={userAccountMatrix}
                                        error="You have no users active on your account."
                                    />
                                )}
                            </Transition>
                        ),
                        pos: 'bottom'
                    }}
                    toggle={{
                        condition: showUserSetup,
                        action: (e) => {
                            e.preventDefault();
                            toggleUserSetup();
                        }
                    }}
                    {...conditionalProps}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + InvoiceID}
                        openPayInvoiceid={InvoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_user_accounts_status: state.gsuite.gsuite_user_accounts_status,
        gsuite_user_accounts_data: state.gsuite.gsuite_user_accounts_data,
        gsuite_user_create_status: state.gsuite.gsuite_user_create_status,
        gsuite_verification_status_status: state.gsuite.gsuite_verification_status_status,
        gsuite_verification_status_data: state.gsuite.gsuite_verification_status_data,
        gsuite_service_status: state.gsuite.gsuite_service_data,
        gsuite_service_data: state.gsuite.gsuite_service_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGSuiteUserAccounts,
            createGSuiteUserData
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteUserAccounts));
