/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { change, updateSyncErrors } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Tip from 'components/Tooltip';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { returnErrorAndWarnClass } from 'utilities/methods/form';
import PhoneInputCountrySelect from './PhoneCountrySelect';
import { PhoneInputContext } from './PhoneInputContext';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import './_PhoneInput.scss';

const errorData = /** @type {const} */ ({
    TOO_SHORT: 'Phone number too short.',
    TOO_LONG: 'Phone number too long.',
    DEFAULT: 'Invalid phone number.'
});

/**
 * @param {keyof typeof errorData | string} errorCode
 */
function createPhoneError(errorCode) {
    if (errorCode in errorData) {
        return {
            error: errorData[errorCode],
            code: errorCode
        };
    }

    return {
        error: errorData.DEFAULT,
        code: errorCode
    };
}

/***** HELPER FUNCTIONS *****/
/**
 * @param {string} inputValue
 * @returns {{ error: string, code: string } | import('libphonenumber-js').PhoneNumber}
 */
const parsePhoneWithError = (inputValue) => {
    try {
        return parsePhoneNumber(inputValue);
    } catch (error) {
        return createPhoneError(error.message);
    }
};

/***** RENDER HELPERS *****/
function renderAppend({ append }) {
    if (!append) return '';

    return (
        <div className="appendedText">
            {append.text}
            <Tip
                info={`This text will be appended to the end of anything you type into this input field. <br/><br/> For example, entering 'abc123' will become abc123${append.text} when the form is submitted.`}
            >
                <PhosphorIcons.WarningCircle />
            </Tip>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function RenderPhoneField({
    input,
    label,
    tooltip,
    country = 'AU',
    readOnly,
    placeholder = '+613 9013 8464',
    append,
    disabled,
    meta,
    required,
    countrySelectEnabled = false,
    forceStaticCountry = false
}) {
    const { value, name, onChange, onBlur } = input;
    const { dispatch, form, touched, error, warning, initial } = meta;

    /***** STATE *****/
    const [selectedCountry, setSelectedCountry] = useState(country);
    const previousPhone = React.useRef(null);
    const nextPhone = React.useRef(null);

    /***** FUNCTIONS *****/
    function setPhoneFieldErrorMessage(message) {
        dispatch(
            updateSyncErrors(form, {
                [name]: message
            })
        );
    }

    /**
     * @param {string} [inputValue]
     */
    const updateValue = (inputValue) => {
        const _inputValue = inputValue ?? '';

        // don't dispatch an update if it's the same value as this will trigger an event loop due to dispatch in onSelectedCountryChange
        if (_inputValue === previousPhone.current) {
            return;
        }

        /**
         * Redux Form does not update when the value of the field is undefined.
         * Redux Form only updates if it's an actual value, since `undefined` is not a real value, it does not update.
         * Fix: if the input value is undefined, set the value to null instead and return.
         */
        if (inputValue === undefined) {
            return updateValue(null);
        }
        previousPhone.current = _inputValue;
        onChange(_inputValue);
    };

    /**
     * Handles the case where the user enters a phone number starting with +0 (invalid phone). The reason this requires a ref is because
     * updating the value does not update the component, if the value it is changing to is the same value as the previous one, therefore
     * adding a refs state means that we can get this value on the next render, working around this issue.
     *
     * @param {string} [inputValue]
     * @returns boolean
     */
    const handlePlusZero = (inputValue) => {
        const selectedCountryCallingCode = Number(getCountryCallingCode(selectedCountry));

        switch (true) {
            case !!nextPhone.current: {
                onChange(nextPhone.current);
                nextPhone.current = null;
                return true;
            }
            case inputValue?.startsWith('+0'): {
                const startWithCountryCallingCode = `+${selectedCountryCallingCode}${inputValue?.slice(
                    String(selectedCountryCallingCode).length + 2
                )}`;

                nextPhone.current = startWithCountryCallingCode;
                onChange('101010101'); //Change value to something that is unlikely to be the existing value to trigger rerender
                return true;
            }
            default:
                return false;
        }
    };

    /**
     * @param {string} [inputValue]
     */
    function onPhoneInputChange(inputValue = '') {
        const selectedCountryCallingCode = Number(getCountryCallingCode(selectedCountry));

        if (inputValue === undefined) {
            return updateValue(inputValue);
        }
        if (handlePlusZero(inputValue)) {
            return;
        }

        const parsedPhoneNumber = parsePhoneWithError(inputValue);

        if (has(parsedPhoneNumber, 'error')) {
            // If the input is empty and the field is not required, don't show an error
            if (!inputValue.length && !required) {
                return;
            }
            setPhoneFieldErrorMessage(parsedPhoneNumber.error);
            return updateValue(inputValue);
        }

        const callingCodes = parsedPhoneNumber.getMetadata().country_calling_codes;
        const countryCallingCode = Number(parsedPhoneNumber.countryCallingCode);

        // Update selected country
        if (!forceStaticCountry && !callingCodes[countryCallingCode].includes(selectedCountry)) {
            const newSelectedCountry = callingCodes[countryCallingCode][0];
            setSelectedCountry(newSelectedCountry);
        }

        // Handle static country code
        if (forceStaticCountry && inputValue?.startsWith('+') && !inputValue?.startsWith('+' + selectedCountryCallingCode)) {
            const phoneWithCountryCode = '+' + selectedCountryCallingCode + parsedPhoneNumber?.nationalNumber;
            return updateValue(phoneWithCountryCode);
        }

        updateValue(inputValue);
    }

    function onSelectedCountryChange(_selectedCountry) {
        try {
            const parsedPhoneNumber = parsePhoneNumber(value);
            if (parsedPhoneNumber) {
                const { nationalNumber } = parsedPhoneNumber;
                const newCallingCode = getCountryCallingCode(_selectedCountry);
                const updatedPhoneNumber = `+${newCallingCode}${nationalNumber}`;
                dispatch(change(form, name, updatedPhoneNumber, true));
            }
        } catch (error) {
            return;
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        onSelectedCountryChange(selectedCountry);
    }, [selectedCountry]);

    useEffect(() => {
        setSelectedCountry(country);
    }, [country]);

    useEffect(() => {
        onPhoneInputChange(value);
    }, [value]);

    /***** RENDER HELPERS *****/
    const selectionClassNames = classNames('selection', {
        isAppended: append,
        readonly: readOnly,
        error: returnErrorAndWarnClass(touched, error, warning, initial)
    });

    const context = {
        selectedCountry,
        setSelectedCountry,
        countrySelectEnabled
    };

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name}>
            <FormLabel htmlFor={name}>
                {label}
                {tooltip ? <Tip info={tooltip} /> : ''}
            </FormLabel>

            <FormItemInner meta={meta}>
                <div className={selectionClassNames}>
                    <PhoneInputContext.Provider value={context}>
                        <PhoneInput
                            id={name}
                            disabled={disabled}
                            country={selectedCountry}
                            // "defaultCountry" means that when the value is "+61....." and you clear the field and type "04", it doesn't prepend the "+"
                            {...(countrySelectEnabled ? {} : { defaultCountry: selectedCountry })}
                            placeholder={placeholder}
                            value={value}
                            onChange={onPhoneInputChange}
                            onBlur={() => {
                                // Do not pass e to onBlur since it takes the value of the field and not the state we keep track of
                                onBlur();
                            }}
                            countrySelectComponent={PhoneInputCountrySelect}
                        />
                    </PhoneInputContext.Provider>
                    {renderAppend({ append })}
                </div>
            </FormItemInner>
        </FormItem>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

RenderPhoneField.propTypes = {
    /**
     * The Value provided by the Redux Form Field.
     */
    input: PropTypes.object.isRequired,

    /**
     * The label for the field.
     */
    label: PropTypes.string,

    /**
     * The tooltip for the field.
     */
    tooltip: PropTypes.string,

    /**
     * The country code to use for the phone number.
     */
    country: PropTypes.string,

    /**
     * Whether the field is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * The placeholder text for the field.
     */
    placeholder: PropTypes.string,

    /**
     * The text to append to the end of the phone number.
     */
    append: PropTypes.shape({
        text: PropTypes.string.isRequired
    }),

    /**
     * Whether the field is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * The meta data for the field.
     */
    meta: PropTypes.object.isRequired,

    /**
     * Whether the country select is enabled.
     */
    countrySelectEnabled: PropTypes.bool
};

export default RenderPhoneField;
