/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext, useEffect } from 'react';
import { Field, change } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/CheckBoxList';
import { Input } from 'components/Form/Input';
import { RadioButtonsWithSelectedRender } from 'components/Form/RadioButtonsWithSelectedRender';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useGetDynamicFormFieldValues } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { fieldLabelWithOptional } from 'containers/katana/components/fieldLabelWithOptional';
import { useGetMultiLoaderData } from 'containers/katana/containers/contentEditorModules/callToAction/useGetMultiLoaderData';
import { useGetRoutableCallToActionSections } from 'containers/katana/containers/contentEditorModules/callToAction/useGetRoutableCallToActionSections';
import { validationURLorHash } from 'utilities/methods/form/validations/validationURLorHash';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import { CallToActionType } from 'containers/katana/containers/contentEditorModules/callToAction/consts';
import './_CallToActionContentModule.scss';
const { CALL_TO_ACTION } = usePostNavigationMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const CallToActionContentModule = Object.assign(
    function CallToActionContentModule() {
        /***** HOOKS *****/
        // const { id } = useSetupEditorRouteParams();
        const { form } = useContext(ContentEditorRenderModulesContext);
        const formValues = useGetDynamicFormFieldValues();
        const routableOptions = useGetRoutableCallToActionSections();
        const { dynamicChange } = useDynamicFormActions();
        const multiLoaderData = useGetMultiLoaderData();

        /***** QUERIES *****/
        // const { data: get_katana_site_business_info } = katanaQuery.serviceID.getBusinessInfo.useQuery(id);

        /***** RENDER HELPERS *****/
        const isLinkOrSection = [CallToActionType.LINK, CallToActionType.SECTION].includes(formValues.values?.[CALL_TO_ACTION]?.type);
        const isShowingCTAContent = Boolean(formValues.values?.[CALL_TO_ACTION]?.enabled);

        useEffect(() => {
            // Only do dynamic change if the value for type is falsy
            if (!formValues.values?.[CALL_TO_ACTION]?.type) {
                dynamicChange(`${CALL_TO_ACTION}.type`, CallToActionType.LINK);
            }
        }, [formValues]);

        /***** RENDER *****/
        return (
            <NXBox.TopAccordion
                className="CallToActionContentModule"
                title="Customer Engagement Button"
                description="A button on your website that tells the user to take a specified action."
            >
                <NXBox.DefaultPadding>
                    <RequestLoader.MultiLoader loaders={multiLoaderData}>
                        <CheckBoxList>
                            <Field name={`${CALL_TO_ACTION}.enabled`} component={CheckBoxList.Item.ReduxForm}>
                                Display customer engagement button
                            </Field>
                            {isShowingCTAContent && (
                                <Field
                                    name={`${CALL_TO_ACTION}.type`}
                                    component={RadioButtonsWithSelectedRender.ReduxForm.Default}
                                    fullWidth
                                    onSelect={(newSelection) => {
                                        if (newSelection === formValues?.values?.call_to_action?.type) {
                                            return;
                                        }
                                        store.dispatch(change(form, `${CALL_TO_ACTION}.enabled`, true));
                                        store.dispatch(change(form, `${CALL_TO_ACTION}.text`, null));
                                        store.dispatch(change(form, `${CALL_TO_ACTION}.value`, null));
                                        store.dispatch(change(form, `${CALL_TO_ACTION}.section_id`, null));
                                    }}
                                >
                                    <RadioButtonsWithSelectedRender.Item
                                        color="confirm"
                                        label="A button that takes the user somewhere"
                                        value={
                                            formValues.values?.[CALL_TO_ACTION]?.type === CallToActionType.LINK
                                                ? CallToActionType.LINK
                                                : CallToActionType.SECTION
                                        }
                                        isSelected={isLinkOrSection}
                                    >
                                        {({ shouldRenderChildren }) => (
                                            <Revealer isOpen={shouldRenderChildren}>
                                                <CheckBoxList>
                                                    <CheckBoxList>
                                                        <Field
                                                            label={
                                                                <Text size--m medium black>
                                                                    What will the button say?{' '}
                                                                    <Text warn bold>
                                                                        *
                                                                    </Text>
                                                                </Text>
                                                            }
                                                            name={`${CALL_TO_ACTION}.text`}
                                                            component={Input.ReduxForm.Default}
                                                            validate={[requiredFieldValidation]}
                                                        />

                                                        <Field
                                                            name={`${CALL_TO_ACTION}.type`}
                                                            label={
                                                                <Text size--m medium black>
                                                                    Where will it go when clicked?
                                                                </Text>
                                                            }
                                                            component={RadioButtonsWithSelectedRender.ReduxForm.Default}
                                                            onSelect={() => {
                                                                store.dispatch(change(form, `${CALL_TO_ACTION}.value`, null));
                                                                store.dispatch(change(form, `${CALL_TO_ACTION}.section_id`, null));
                                                            }}
                                                        >
                                                            <RadioButtonsWithSelectedRender.Item
                                                                color="confirm"
                                                                label={
                                                                    <>
                                                                        Somewhere on my website{' '}
                                                                        <Text warn bold>
                                                                            *
                                                                        </Text>
                                                                    </>
                                                                }
                                                                value={CallToActionType.SECTION}
                                                            >
                                                                <Field
                                                                    name={`${CALL_TO_ACTION}.section_id`}
                                                                    component={RenderSelectField}
                                                                    validate={[requiredFieldValidation]}
                                                                    options={routableOptions}
                                                                />
                                                            </RadioButtonsWithSelectedRender.Item>

                                                            <RadioButtonsWithSelectedRender.Item
                                                                color="confirm"
                                                                label={
                                                                    <>
                                                                        To an external URL{' '}
                                                                        <Text warn bold>
                                                                            *
                                                                        </Text>
                                                                    </>
                                                                }
                                                                value={CallToActionType.LINK}
                                                            >
                                                                <Field
                                                                    name={`${CALL_TO_ACTION}.value`}
                                                                    placeholder="E.g. https://ventraip.com.au/"
                                                                    validate={[requiredFieldValidation, validationURLorHash]}
                                                                    component={Input.ReduxForm.Default}
                                                                />
                                                            </RadioButtonsWithSelectedRender.Item>
                                                        </Field>
                                                    </CheckBoxList>
                                                </CheckBoxList>
                                            </Revealer>
                                        )}
                                    </RadioButtonsWithSelectedRender.Item>

                                    <RadioButtonsWithSelectedRender.Item
                                        color="confirm"
                                        label="A button that shows my phone number"
                                        value={CallToActionType.PHONE}
                                        // disabled={!get_katana_site_business_info?.phone}
                                    >
                                        {({ shouldRenderChildren }) => (
                                            <>
                                                {/* {!get_katana_site_business_info?.phone ? (
                                                    <NoPhoneWarningMessage />
                                                ) : ( */}
                                                <Revealer isOpen={shouldRenderChildren}>
                                                    <CheckBoxList>
                                                        <CheckBoxList>
                                                            <Flex direction="column">
                                                                <Field
                                                                    label={
                                                                        <Text black size--m medium>
                                                                            {fieldLabelWithOptional('Pretext')}
                                                                        </Text>
                                                                    }
                                                                    name={`${CALL_TO_ACTION}.text`}
                                                                    placeholder="E.g. Call Us on"
                                                                    component={Input.ReduxForm.Default}
                                                                />
                                                                <Flex direction="column" gap={1}>
                                                                    <Text black size--m medium>
                                                                        Business phone number
                                                                    </Text>
                                                                    <Field
                                                                        name={`${CALL_TO_ACTION}.value`}
                                                                        placeholder="04 1234 5678"
                                                                        validate={shouldRenderChildren ? [requiredFieldValidation] : []}
                                                                        component={Input.ReduxForm.Default}
                                                                    />
                                                                    {/* <FormItem name="phone"> */}
                                                                    {/* <Input
                                                                                name="phone"
                                                                                disabled
                                                                                value={get_katana_site_business_info?.phone}
                                                                            /> */}
                                                                    {/* <Text secondary size--s italic>
                                                                                The phone number above is taken from the phone number used in the
                                                                                Basic Details section a few steps back
                                                                            </Text> */}
                                                                    {/* </FormItem> */}
                                                                </Flex>
                                                            </Flex>
                                                        </CheckBoxList>
                                                    </CheckBoxList>
                                                </Revealer>
                                                {/* )} */}
                                            </>
                                        )}
                                    </RadioButtonsWithSelectedRender.Item>
                                </Field>
                            )}
                        </CheckBoxList>
                    </RequestLoader.MultiLoader>
                </NXBox.DefaultPadding>
            </NXBox.TopAccordion>
        );
    },
    {
        formFields: { CALL_TO_ACTION }
    }
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default CallToActionContentModule;
