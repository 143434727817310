/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { LabelledSwitch } from 'containers/account/modules/subscriptions/switch';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Border from 'components/Utils/Border';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useSubscriptionsMutation } from 'containers/account/queries/useSubscriptionMutation';
import { useSubscriptionsQuery } from 'containers/account/queries/useSubscriptionsQuery';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
// eslint-disable-next-line no-undef
export type SubscriptionToggle = AccountAPI.account.PUT.subscription.Subscription;

type TSubscription = React.FC<{
    title: string;
    children: React.ReactNode;
    sms?: SubscriptionToggle;
    email?: SubscriptionToggle;
    className?: string;
}>;

// eslint-disable-next-line no-undef
const togglePropsToAttribute = (prop: SubscriptionToggle) => {
    switch (prop) {
        case 'smsPromotion':
            return 'sms_promo_enabled';
        case 'emailPromotion':
            return 'promotion_email_enabled';
        case 'smsInvoice':
            return 'sms_invoice_enabled';
        case 'smsNotifications':
            return 'sms_domain_enabled';
        case 'smsSuspension':
        default:
            return 'sms_suspend_enabled';
    }
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Subscription: TSubscription = ({ title, children, sms, email, className }) => {
    /***** QUERIES *****/
    const { data, isFetching } = useSubscriptionsQuery();
    const { mutate: toggleSubscriptionSettings } = useSubscriptionsMutation();
    const { status } = useSubscriptionsMutation.useState();

    /***** RENDER HELPERS *****/
    const emailValue = data?.attributes?.[togglePropsToAttribute(email)] ?? false;
    const smsValue = data?.attributes?.[togglePropsToAttribute(sms)] ?? false;

    /***** RENDER *****/
    return (
        <Border top className={className}>
            <div className="accountSubscriptions__toggle">
                <div className="accountSubscriptions__column--left">
                    <div className="accountSubscriptions__subheading">{title}</div>
                    <div className="accountSubscriptions__description">{children}</div>
                </div>
                <div className="accountSubscriptions__column--right">
                    {!!sms && (
                        <LabelledSwitch
                            label="SMS"
                            onClick={() => toggleSubscriptionSettings({ enable: !smsValue, subscription: sms })}
                            value={smsValue}
                            disabled={status === 'pending' || isFetching}
                        />
                    )}
                    {!!email && (
                        <LabelledSwitch
                            label="EMAIL"
                            onClick={() => toggleSubscriptionSettings({ enable: !emailValue, subscription: email })}
                            value={emailValue}
                            disabled={status === 'pending' || isFetching}
                        />
                    )}
                </div>
            </div>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
