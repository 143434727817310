/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { terms_url } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SLAClaimForm from '../forms/SLAClaimForm';
/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getSLAClaimEvents, submitSLAClaim } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SLACreditClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSLAForm: false
        };
        this.toggleSLAForm = this.toggleSLAForm.bind(this);
        this.handleSLASubmit = this.handleSLASubmit.bind(this);
    }

    toggleSLAForm() {
        const { showSLAForm } = this.state;

        this.setState({
            showSLAForm: !showSLAForm
        });
    }

    handleSLASubmit(values) {
        submitSLAClaim({ hosting_id: values.hosting_id, event_id: values.event_id, comments: values.comments });
    }

    componentDidUpdate(prevProps) {
        const { account_sla_claim_events_status } = this.props;
        registerScrollEvents(this, account_sla_claim_events_status === 'success' && prevProps.account_sla_claim_events_status === 'loading');
    }

    render() {
        const { account_sla_claim_events_status, account_sla_claim_submit_status } = this.props;
        const { showSLAForm } = this.state;
        const { toggleSLAForm, handleSLASubmit } = this;

        /*   RENDER COMPONENT
         **********************************************************************************************************/

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountSLA"
            >
                <Box
                    request={{
                        action: getSLAClaimEvents,
                        args: false,
                        status: account_sla_claim_events_status
                    }}
                    status={account_sla_claim_submit_status}
                    className="accountSLA__box"
                    title="SLA Credit Claim"
                    desc="In the event of an extended unscheduled outage, you can request a credit in accordance with your Service Level Agreement."
                    action={{
                        label: 'Submit a Claim',
                        type: 'onClick',
                        className: '',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            toggleSLAForm();
                        }
                    }}
                    dropdown={{
                        title: 'Submit a new SLA claim',
                        render: <SLAClaimForm onSubmit={handleSLASubmit} />,
                        condition: showSLAForm,
                        close: toggleSLAForm
                    }}
                    info={`<p>If your website experiences downtime greater than what's set in our Service Level Agreement (SLA),
                    you may be eligible to receive account credit that can be applied to future invoices. 
                    If you are eligible for a credit you must use this tool to claim it. For more information,
                    please see our <a href="${terms_url}" target="_blank">Service Level Agreement</a>.</p>`}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        account_sla_claim_events_status: state.account.account_sla_claim_events_status,
        account_sla_claim_submit_status: state.account.account_sla_claim_submit_status,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SLACreditClaim));
