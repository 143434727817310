/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import CardSelectGrid from 'components/Form/CardSelectGrid';
import BackgroundColour from 'components/Utils/BackgroundColour';
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SectionStyleData.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SectionStyleData({ styles = {} }) {
    /***** RENDER HELPERS *****/
    const styleEntries = useMemo(() => Object.values(styles), [styles]);

    const hasMultipleStyles = styleEntries?.length > 1;

    const options = useMemo(
        () =>
            styleEntries.map((entry) => {
                const { key, title, icon } = entry;
                /** @type {import('components/Form/CardSelectGrid/types').TCardSelectGrid.SelectCard.CustomOption} */
                const newEntry = {
                    type: 'custom',
                    content: (
                        <BackgroundColour light-gray>
                            <NXSquare ratio={0.58}>
                                <BackgroundImage imageURL={icon} contain fill />
                            </NXSquare>
                        </BackgroundColour>
                    ),
                    selectionText: title,
                    value: key
                };
                return newEntry;
            }),
        [styles, styleEntries]
    );

    /***** RENDER *****/
    if (!hasMultipleStyles) {
        return null;
    }
    return (
        <Field
            name="style"
            label="Content Style"
            className="SectionStyleData"
            options={options}
            component={CardSelectGrid}
            alignItems="stretch"
            textAlign="left"
        />
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SectionStyleData;
