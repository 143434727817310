/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext, useMemo } from 'react';
import { Field, change } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import RenderPhoneField from 'components/Form/PhoneInput';
import { TextArea } from 'components/Form/TextArea';
import InteractiveGoogleMapsIframe from 'components/InteractiveGoogleMapsIframe';
import NXBox from 'components/NXBox';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import { SingleFieldAutoCompleteAddress } from 'components/Form/SingleFieldAutoCompleteAddress';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SelectDropdownField from 'components/Form/SelectDropdownField';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { fieldLabelWithOptional } from 'containers/katana/components/fieldLabelWithOptional';
import { formatBusinessNumber } from 'containers/katana/containers/contentEditorModules/contactEmailMobile/methods';
import { useContentEditorFormValues } from 'containers/katana/hooks/useContentEditorFormValues';
import { emailFieldValidation, notRequiredACNorABNValidationByType, validateReactPhoneNumberInputNotRequired } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

const { EMAIL, PHONE, ADDRESS, OPERATING_HOURS, BUSINESS_NUMBER, BUSINESS_NUMBER_TYPE } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;

const operationHoursPlaceholder = `Monday: 8am-7pm
Tuesday: 8am-7pm
Wednesday: 8am-7pm
Thursday: 8am-7pm
Friday: 8am-7pm
Saturday: 9am-5pm
Sunday: Closed`;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _ContactEmailMobileContentModule = () => {
    /***** HOOKS *****/
    const { form } = useContext(ContentEditorRenderModulesContext);
    const formValues = useContentEditorFormValues();

    /***** RENDER HELPERS *****/
    const businessNumberValidators = useMemo(() => {
        return [notRequiredACNorABNValidationByType(formValues.values?.business_number_type)];
    }, [formValues.values?.[BUSINESS_NUMBER_TYPE]]);

    /***** RENDER *****/
    return (
        <NXBox>
            <NXBox.Top
                title="Contact Details"
                description="Let's add some contact details where your customers can reach out to you. You can choose which details to display on your website later."
            />
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <Field
                        name={EMAIL}
                        label={
                            <Flex items="center" gap={1}>
                                {fieldLabelWithOptional('Enter a contact email address')}
                                <Tooltip info=" If you decide to include a 'Contact Us' section on your website, any inquiries submitted through the contact form will be sent to this email address." />
                            </Flex>
                        }
                        validate={[emailFieldValidation]}
                        component={Input.ReduxForm.Default}
                        placeholder="E.g. example@example.com"
                        type="text"
                    />
                    {/* <Text secondary size--s italic>
                        If you choose to have a contact us area on your webpage, when a user submits the contact form, this is where the enquiry will
                        be sent to.
                    </Text> */}

                    <Field
                        label={
                            <Flex items="center" gap={1}>
                                {fieldLabelWithOptional('Enter a contact mobile number')}
                                <Tooltip info="If you opt to show a phone number on your website, this is the number that will be displayed" />
                            </Flex>
                        }
                        name={PHONE}
                        component={RenderPhoneField}
                        country="AU"
                        type="tel"
                        required={false}
                        placeholder="E.g. +61 491 570 159"
                        validate={[validateReactPhoneNumberInputNotRequired]}
                    />
                    {/* <Text secondary size--s italic>
                        If you choose to display a phone number on your web page, this is what will be displayed.
                    </Text> */}

                    <Field
                        // label={fieldLabelWithOptional('Enter your business address')}
                        label={
                            <Flex items="center" gap={1}>
                                {fieldLabelWithOptional('Enter your business address')}
                                <Tooltip
                                    info={`If you choose to have a 'Contact Us' area on your site, you can display your business address using Google Maps.`}
                                />
                            </Flex>
                        }
                        name={ADDRESS}
                        component={SingleFieldAutoCompleteAddress}
                        placeholder="E.g. Level 1/66 Victor Cres, Narre Warren VIC 3805"
                    />
                    {/* <Text secondary size--s italic>
                        {`If you choose to have a 'Contact Us' area on your page, you can display your business' address using Google Maps.`}
                    </Text> */}
                    <InteractiveGoogleMapsIframe address={formValues.values?.[ADDRESS] || 'Melbourne 3000'} />

                    <Field
                        // label={fieldLabelWithOptional('Supply operating hours')}
                        label={
                            <Flex items="center" gap={1}>
                                {fieldLabelWithOptional('Add your business operating hours')}
                                <Tooltip
                                    info={`If you choose to have a 'Contact Us' area on your site, you can list your business' operating hours by entering them above.`}
                                />
                            </Flex>
                        }
                        name={OPERATING_HOURS}
                        component={TextArea.ReduxForm.Default}
                        placeholder={operationHoursPlaceholder}
                        rows={7}
                    />
                    {/* <Text secondary size--s italic>
                        {`If you choose to have a 'Contact Us' area on your page, you can list your business' operating hours by entering them above.`}
                    </Text> */}

                    <Field
                        // label={fieldLabelWithOptional('Include other business information')}
                        label={
                            <Flex items="center" gap={1}>
                                {fieldLabelWithOptional('Include other business information')}
                                <Tooltip info="You can display some other business information on your website like your ABN or ACN number." />
                            </Flex>
                        }
                        name={BUSINESS_NUMBER_TYPE}
                        component={SelectDropdownField}
                        onChange={() => {
                            store.dispatch(change(form, BUSINESS_NUMBER, ''));
                        }}
                        options={[
                            ...(formValues?.values?.[BUSINESS_NUMBER_TYPE] ? [{ label: 'Please select', value: null }] : []),
                            { label: 'ABN', value: 'ABN' },
                            { label: 'ACN', value: 'ACN' }
                        ]}
                        defaultValue="ABN"
                    />
                    {Boolean(formValues.values?.[BUSINESS_NUMBER_TYPE]) && (
                        <Field
                            key={formValues.values?.[BUSINESS_NUMBER_TYPE]}
                            name={BUSINESS_NUMBER}
                            component={Input.ReduxForm.Default}
                            format={(value) => formatBusinessNumber(value, formValues.values?.business_number_type)}
                            normalize={(value) => formatBusinessNumber(value, formValues.values?.business_number_type).replaceAll(' ', '')}
                            type="text"
                            placeholder={formValues.values?.business_number_type === 'ABN' ? 'E.g. 91 137 543 018' : 'E.g. 137 543 018'}
                            validate={businessNumberValidators}
                        />
                    )}
                    {/* <Text secondary size--s italic>
                        You can display some other business information on your webpage like your ABN or ACN.
                    </Text> */}
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const ContactEmailMobileContentModule = Object.assign(_ContactEmailMobileContentModule, {
    formFields: { EMAIL, PHONE, ADDRESS, OPERATING_HOURS, BUSINESS_NUMBER_TYPE, BUSINESS_NUMBER }
});

export default ContactEmailMobileContentModule;
