/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Clipboard from 'components/Clipboard';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Note from 'components/Note';
import PayInvoiceNotification from 'components/Notifications/PayInvoice';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { getMs365Service } from 'containers/ms365/action';
import { ms365Methods } from 'containers/ms365/methods';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useCancelInvoiceMutation } from 'containers/billing/queries/invoice';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleDefaultSuccessNotification, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Overview = (props) => {
    const { getMs365Service, ms365_information_status, ms365_information_data } = props;

    /***** STATE *****/
    const { value: isInvoiceLightboxShowing, setTrue: openPayInvoice, setFalse: closeInvoiceLightbox } = useBoolean(false);
    const {
        value: isCancelLightboxShowing,
        toggle: toggleCancelLightbox,
        setTrue: openCancelInvoice,
        setFalse: closeCancelInvoice
    } = useBoolean(false);

    /***** HOOKS *****/
    const scrollRef = useRef();
    const params = useParams();

    /***** QUERIES *****/
    const { mutate: mutateCancelInvoice, status: cancel_invoice_status } = useCancelInvoiceMutation({
        onError: toggleCancelLightbox,
        onSuccess: (response) => {
            handleDefaultSuccessNotification(response);
            toggleCancelLightbox();
            getMs365Service(params?.id);
        }
    });

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, ms365_information_status === 'success');
    }, [ms365_information_status]);

    /***** RENDER HELPERS *****/
    const invoiceID = ms365Methods.getInvoiceId(ms365_information_data);

    const adminEmail = ms365_information_data?.attributes.admin_email || '';

    const configPricing = ms365Methods.getConfigPricing(ms365_information_data).filter(({ quantity }) => quantity > 0);

    /***** RENDER *****/
    return (
        <div ref={scrollRef} className="ms365Overview">
            {invoiceID ? (
                <PayInvoiceNotification
                    text="You have an outstanding invoice for this service."
                    openPayInvoice={openPayInvoice}
                    openCancelInvoice={openCancelInvoice}
                />
            ) : (
                ''
            )}
            <Box
                premounted
                status={ms365_information_status}
                className="ms365Overview__box"
                split={{
                    left: {
                        render: (
                            <>
                                <div className="sharedSplit__container--title">
                                    <h2>Service Overview</h2>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Number of Seats</div>
                                    <div className="sharedSplit__description domain">
                                        {ms365Methods.getTotalSeats(ms365_information_data?.attributes.configs)}
                                        {invoiceID ? (
                                            ''
                                        ) : (
                                            <Anchor to={`/my-services/microsoft-365/manage/manage-seats/${ms365_information_data?.id}?open=true`}>
                                                Change Seats
                                            </Anchor>
                                        )}
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading sharedSplit__subheading--adminDetails">
                                        Initial Admin Email
                                        <Tooltip info="This is only the initial admin email. If you change the admin email within the Microsoft 365 Management Portal then this initial admin email will no longer work." />
                                    </div>
                                    <div className="sharedSplit__description domain">
                                        {adminEmail}
                                        <Clipboard value={adminEmail} />
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading sharedSplit__subheading--adminDetails">
                                        Initial Admin Password
                                        <Tooltip
                                            info={
                                                '<p>If you forget your password or need to reset it, we recommend going to <a class="ms365Overview__tooltipLink" href="https://admin.microsoft.com" target="_blank">https://admin.microsoft.com</a> and using the "Forgot Your Password" tool.</p>'
                                            }
                                        />
                                    </div>
                                    <div className="sharedSplit__description domain">
                                        {ms365_information_data?.attributes.password || ''}
                                        <Clipboard value={ms365_information_data?.attributes.password || ''} />
                                    </div>
                                    <Note text="Your password will need to be changed after initial login." />
                                </div>
                            </>
                        )
                    },
                    right: {
                        render: (
                            <>
                                <div className="sharedSplit__container--title">
                                    <h2>Billing Overview</h2>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Next Renewal Date</div>
                                    <div className="sharedSplit__description">
                                        {toLuxonDate(ms365_information_data?.attributes.next_due_date).toFormat('ccc, MMM d yyyy')}
                                    </div>
                                </div>
                                <div className="sharedSplit__container">
                                    <div className="sharedSplit__subheading">Monthly Billing Amount</div>
                                    <div className="sharedSplit__description">${ms365_information_data?.attributes.amount}</div>
                                    <ul className="sharedSplit__configs">
                                        {configPricing.map(({ monthly_price, name, quantity }, index) => (
                                            <li key={index}>
                                                <p>
                                                    <small className="sharedSplit__configName">
                                                        {quantity}x {name}
                                                    </small>
                                                    <small className="sharedSplit__configMonthlyPrice">(${monthly_price} per seat, per month)</small>
                                                </p>
                                                <p className="sharedSplit__configTotalPrice">${(Number(monthly_price) * quantity).toFixed(2)}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )
                    }
                }}
            />
            {isInvoiceLightboxShowing ? (
                <OverlayLightbox
                    title={`Pay Invoice #${invoiceID}`}
                    invoiceid={invoiceID}
                    onOpen
                    onClose={closeInvoiceLightbox}
                    onSuccessClose={closeInvoiceLightbox}
                />
            ) : (
                ''
            )}
            {isCancelLightboxShowing ? (
                <OverlayLightbox
                    onOpen
                    onClose={closeCancelInvoice}
                    title="Cancel Microsoft 365 Invoice?"
                    confirm={{
                        desc: `Are you sure you want to cancel this invoice?`,
                        buttonText: 'Cancel',
                        buttonAction: () => mutateCancelInvoice(invoiceID),
                        closeText: 'No, Go Back',
                        closeAction: closeCancelInvoice,
                        loading: cancel_invoice_status
                    }}
                />
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Overview = connect(
    (state) => ({
        ms365_information_status: state.ms365.ms365_information_status,
        ms365_information_data: state.ms365.ms365_information_data
    }),
    {
        getMs365Service
    }
)(Overview);

Overview = withRouter(Overview);

export default Overview;
