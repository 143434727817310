/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Function that returns the current viewport or a boolean if the viewport matches the provided viewport
 */
export const useAppViewport: useAppViewport = (match, negative) => {
    /***** HOOKS *****/
    const viewport = useSelector((state: any) => state.app.app_viewport as TViewport);

    /***** HOOK RESULTS *****/
    if (!match) {
        return viewport as any;
    }

    if (Array.isArray(match)) {
        const isMatched = match.includes(viewport);
        return (negative ? !isMatched : isMatched) as any;
    }

    return (negative ? viewport !== match : viewport === match) as any;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
