/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';
import { useStateValueHasFile } from 'components/Form/NXDropZone/hooks/useStateValueHasFile';
import ImageSelectControl from 'components/ImageSelectControl';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { imageAcceptsTypes } from 'components/Form/NXDropZone/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A component that renders a drop zone for file uploads with an image control.
 *
 * @type {React.FC<TNXDropZone.Variants.WithImageControl.Props>}
 */
const NXDropZoneWithImageControl = ({ input, label, meta, imageControlProps, accept = imageAcceptsTypes, singleFile = false }) => {
    const { name, value, onChange } = input;

    /***** HOOKS *****/
    /**
     * @type {React.MutableRefObject<import('react-dropzone').DropzoneRef | null | undefined>}
     */
    const zoneRef = useRef();
    const [hasFile, setHasFile] = useStateValueHasFile(value);

    /***** FUNCTIONS *****/
    function handleOnDrop() {
        setHasFile(false);
    }

    /***** RENDER *****/
    return (
        <>
            <Field
                name={name}
                label={label}
                component={NXDropZone.ReduxForm.Default}
                zoneRef={zoneRef}
                className={classNames('NXDropZoneWithImageControl', { hidden: hasFile })}
                onDropZoneDrop={handleOnDrop}
                type="dropzone"
                accept={accept}
                singleFile={singleFile}
            />
            <ImageSelectControl
                imageURL={value}
                className={classNames('NXDropZoneWithImageControl', { hidden: !hasFile })}
                onClickUploadNew={() => {
                    zoneRef?.current?.open?.();
                }}
                onClickRemove={() => {
                    onChange(singleFile ? null : []);
                }}
                {...imageControlProps}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXDropZoneWithImageControl;
