/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext } from 'react';
import { writable } from 'svelte/store';

const possibleStates = ['hidden', 'visible', 'minimised', 'maximised'];
/**
 * @param {PictureInPicture.States} [defaultState]
 * @param {string} [identifier]
 * @returns {PictureInPicture.CreateStateData}
 */
export function createPictureInPictureState(defaultState = 'hidden', identifier) {
    const store = writable(defaultState);

    if (identifier) {
        const UUID = `storable__${identifier}`;
        /**
         * @type {PictureInPicture.CommitStateData}
         */
        const commit = (value) => {
            localStorage.setItem(UUID, JSON.stringify(value));
        };
        /**
         * @type {PictureInPicture.RetrieveStateData}
         */
        const retrieve = () => {
            const value = /** @type {PictureInPicture.States | null} */ (JSON.parse(localStorage.getItem(UUID)));
            return possibleStates.includes(value) ? value : null;
        };

        return { store, commit, retrieve };
    }
    return { store };
}
export const PictureInPictureContext = createContext(/** @type {PictureInPicture.ContextData} */ ({}));
