/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _ReactHookFormSimpleCheckbox } from 'components/Form/SimpleCheckbox/_ReactHookFormSimpleCheckbox';
import { _ReduxFormSimpleCheckbox } from 'components/Form/SimpleCheckbox/_ReduxFormSimpleCheckbox';

/***** EXPORTS *****/
export const SimpleCheckbox = {
    ReduxForm: _ReduxFormSimpleCheckbox,
    HookForm: _ReactHookFormSimpleCheckbox
};
