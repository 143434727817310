/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { INVOICE_HEADINGS, InvoicesTableContext, mobileTableHeadings } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function MobileInvoiceRow({ invoiceData, index }) {
    /***** HOOKS *****/
    const mobileContentRefs = useRef([]);
    const { contentComponents } = useContext(InvoicesTableContext);

    /***** FUNCTIONS *****/
    function updateMobileContentRefs(ref, refIndex) {
        mobileContentRefs.current[refIndex] = ref;
    }

    /***** RENDER HELPERS *****/
    const filteredMobileTableHeading = mobileTableHeadings.filter(([sortKey]) => has(contentComponents, sortKey));

    function renderMobileAction() {
        const hasMobileRender = has(contentComponents[INVOICE_HEADINGS.ACTION], 'mobile');
        if (hasMobileRender) return contentComponents[INVOICE_HEADINGS.ACTION].mobile(invoiceData, 'actionHasMobileRender', updateMobileContentRefs);
        return contentComponents[INVOICE_HEADINGS.ACTION](invoiceData, 'action');
    }

    /***** RENDER *****/
    return (
        <NXTable.Accordion index={index} preventClickRefs={mobileContentRefs} content={<NXTable.Actions>{renderMobileAction()}</NXTable.Actions>}>
            <NXTable.Row>
                {filteredMobileTableHeading.map(([sortKey], j) => {
                    const hasMobileRender = has(contentComponents[sortKey], 'mobile');
                    if (hasMobileRender) return contentComponents[sortKey].mobile(invoiceData, `${j}actionHasMobileRender`, updateMobileContentRefs);
                    return contentComponents[sortKey](invoiceData, `${j}`, updateMobileContentRefs);
                })}
            </NXTable.Row>
        </NXTable.Accordion>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

MobileInvoiceRow.propTypes = {
    /**
     * The individual invoice data
     */
    invoiceData: PropTypes.object.isRequired,

    /**
     * The index of the accordion.
     */
    index: PropTypes.number.isRequired
};

export default MobileInvoiceRow;
