/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';

/*   ACTIONS
 *****************************************************/
import { getHostingResources } from 'containers/hosting/state/baseActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function VictoriaEducationHostingUpgrade() {
    /***** HOOKS *****/
    const hosting_resource_status = useSelector((state) => state.hosting.hosting_resource_status);
    const hosting_resource_data = useSelector((state) => state.hosting.hosting_resource_data);

    const dispatch = useDispatch();

    const { id: hostingId } = useParams();

    /***** EFFECTS *****/
    useEffect(() => {
        dispatch(getHostingResources(hostingId));
    }, []);

    /***** RENDER HELPERS *****/
    const { config_values } = hosting_resource_data?.attributes || {};

    /***** RENDER *****/
    return (
        <ScrollableComponent>
            <NXBox initialStatus={hosting_resource_status}>
                <NXBox.Top
                    title="Upgrade/Downgrade"
                    description="To upgrade or downgrade the resource allocations of this service you need to submit an eTicket to our Accounts & Billing department."
                />
                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={[
                            <NXBox.InfoPair key={1} title="CPU" description={config_values?.cpu.name || ''} />,
                            <NXBox.InfoPair key={2} title="Memory" description={config_values?.memory.name || ''} />,
                            <NXBox.InfoPair key={3} title="Disk Space" description={config_values?.disk_space.name || ''} />,

                            <OutlineButton key={4} to={getTicketSubmitUrl({ department: 'accounts-billing', service: hostingId })}>
                                Submit an eTicket
                            </OutlineButton>
                        ]}
                    />
                </NXBox.Bottom>
            </NXBox>
        </ScrollableComponent>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
