/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaServiceSiteListSetupURL } from 'containers/katana/hooks/useKatanaServiceSetupURL';

/***** QUERIES *****/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function KatanaServiceListEntryActionButton({ katanaSiteData }) {
    const { attributes, id: serviceID } = katanaSiteData;
    const status = /** @type {Katana.SiteStatus} */ (attributes?.site_status);
    const serviceStatus = /** @type {Service.Status} */ (attributes?.status);
    const isPendingCancellation = serviceStatus === 'pending cancellation';

    /***** HOOKS *****/
    const finalKatanaURL = useKatanaServiceSiteListSetupURL(serviceID);

    /***** FUNCTIONS *****/
    function handlePrefetch() {
        if (!serviceID) {
            return;
        }
        katanaQuery.serviceID.getService.prefetchQuery(serviceID);
    }

    /***** RENDER *****/
    return (
        <OutlineButton disabled={isPendingCancellation || status === 'suspended'} to={finalKatanaURL} onMouseOver={handlePrefetch} size="small">
            {attributes?.is_setup_complete ? 'Manage' : 'Setup'}
        </OutlineButton>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default KatanaServiceListEntryActionButton;
