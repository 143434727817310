/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { isValidElement } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxInfoPair.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A key / value type pair which renders the title in grey, and the description below it in black
 */
function NXBoxInfoPair({ title, description }) {
    /***** RENDER *****/
    return (
        <Flex className="NXBoxInfoPair" direction="column" gap={0}>
            <Text size--s medium secondary lead--none>
                <h5 className="NXBoxInfoPair__title">{title}</h5>
            </Text>
            {isValidElement(description) ? (
                description
            ) : (
                <Text lead--none className="NXBoxInfoPair__description">
                    {description}
                </Text>
            )}
        </Flex>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxInfoPair.propTypes = {
    /**
     * Info pair title
     */
    title: PropTypes.string.isRequired,
    /**
     * Info pair description, can be a string, or a custom JSX element
     */
    description: PropTypes.node
};

export default NXBoxInfoPair;
