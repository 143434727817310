/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useMemo, useRef, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _DeveloperThemeCompanySelector as CompanySelector } from 'components/StaffMenu/Theme/toggle';
import { _DeveloperThemeTokenPreview as TokenPreview } from 'components/StaffMenu/Theme/tokens';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import Gradient from 'components/Utils/Gradient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStaffMenu } from 'components/StaffMenu/context';
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { DeveloperThemeContext } from 'components/StaffMenu/Theme/context';
import { __DO_NOT_USE_IN_PRODUCTION_OR_YOU_WILL_BE_FIRED__INTASERVE__CONFIG__ as CONFIG_INTASERVE } from 'config/tokens/intaserve';
import { __DO_NOT_USE_IN_PRODUCTION_BUILD_OR_YOU_WILL_BE_FIRED__TRADIE__CONFIG__ as CONFIG_TRADIE } from 'config/tokens/tradie';
import { __DO_NOT_USE_IN_PRODUCTION_BUILD_OR_YOU_WILL_BE_FIRED__VENTRA__CONFIG__ as CONFIG_VENTRA } from 'config/tokens/ventra';
import './_Theme.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type Theme = keyof typeof themeMap;

const themeMap = {
    ventra: CONFIG_VENTRA,
    intaserve: CONFIG_INTASERVE,
    tradie: CONFIG_TRADIE
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DeveloperTheme = () => {
    /***** STATE *****/
    const [theme, setTheme] = useState<Theme>(import.meta.env.VITE_COMPANY ?? 'ventra');

    /***** HOOKS *****/
    const { active, toggle } = useStaffMenu();

    const developerThemeRef = useRef<HTMLDivElement>(null);
    const refs = useMemo(() => [developerThemeRef], [developerThemeRef]);
    useClickAway(refs, () => {
        if (active) {
            toggle();
        }
    });

    /***** FUNCTIONS *****/
    const _setTheme = (theme: Theme) => {
        const [root_theme] = Array.from(document.querySelectorAll<HTMLElement>(':root'));

        if (!root_theme) return;

        Object.entries(themeMap[theme]).forEach(([token, value]) => {
            root_theme.style.setProperty(`--${token}`, value);
        });

        setTheme(theme);
    };

    const resetTheme = (theme: Theme) => {
        const [root_theme] = Array.from(document.querySelectorAll<HTMLElement>(':root'));

        if (!root_theme) return;

        Object.keys(themeMap[theme]).forEach((token) => {
            root_theme.style.removeProperty(`--${token}`);
        });
    };

    /***** EFFECTS *****/
    useEffect(() => {
        // If the component mounts and the theme is different to the VITE_COMPANY, then we know that vite is triggering the remount (as the state has been updated).
        // We only want to set the theme in this case when the selected theme does not match the default, otherwise we don't need to update the styles of the dom
        // as these styles are already set in the style sheet

        // When the component unmounts, we can remove all theme from the styles so that it can either be readded with the relevant style when the component remounts,
        // or not add the style again at all and use the default style sheet styles
        if (import.meta.env.VITE_COMPANY !== theme) {
            _setTheme(theme);
        }
        return () => resetTheme(theme);
    }, []);

    /***** RENDER *****/
    if (!import.meta.env.DEV) return null;

    return (
        <Gradient development-pink className="DeveloperTheme">
            <div ref={developerThemeRef} style={{ width: '100%' }}>
                <Flex fullHeight fullWidth justify="center" items="center">
                    <button type="button" className="DeveloperTheme__button" onClick={toggle}>
                        <PhosphorIcons.PaintBucket className="DeveloperTheme__icon" size={26} />
                    </button>
                    {active && (
                        <DeveloperThemeContext.Provider value={{ theme, config: themeMap[theme], setTheme: _setTheme }}>
                            <Flex className="DeveloperTheme__actions" direction="column">
                                <CompanySelector />
                                <TokenPreview />
                            </Flex>
                        </DeveloperThemeContext.Provider>
                    )}
                </Flex>
            </div>
        </Gradient>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
