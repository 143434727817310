/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { REDUX_FORM_BUTTON_STATE, ReduxFormButton } from 'components/Form/Button/reduxForm';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { toLuxonDate } from 'utilities/methods/commonActions';
import { getRenewVPSDetails } from '../action';

const formName = 'RenewForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class RenewForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPrepay: {
                cycle: undefined,
                price: undefined,
                duration: undefined,
                discount: undefined,
                total: undefined,
                renew_next: undefined
            },
            showConfirmLightbox: false
        };
        this.openConfirmLightbox = this.openConfirmLightbox.bind(this);
        this.closeConfirmLightbox = this.closeConfirmLightbox.bind(this);
        this.getBillingDetails = this.getBillingDetails.bind(this);
    }

    openConfirmLightbox() {
        this.setState({
            showConfirmLightbox: true
        });
    }

    closeConfirmLightbox() {
        this.setState({
            showConfirmLightbox: false
        });
    }

    getBillingDetails(value) {
        const { getRenewVPSDetails, vpsId, prices } = this.props;

        if (value && prices) {
            const attributes = {
                billing_cycle: value
            };

            const price = prices[value].price.split(' - $')[1].split(' ')[0];
            const duration = prices[value].price.split(' - ')[0];

            this.setState(
                {
                    selectedPrepay: {
                        cycle: value,
                        price,
                        duration,
                        discount: undefined,
                        total: undefined,
                        renew_next: undefined
                    }
                },
                () => {
                    getRenewVPSDetails(vpsId, attributes);
                }
            );
        }
    }

    componentDidMount() {
        const {
            initialValues: { newBillingCycle }
        } = this.props;
        const { getBillingDetails } = this;

        getBillingDetails(newBillingCycle);
    }

    componentDidUpdate(prevProps) {
        const { vps_renew_details_status, vps_renew_details_data, newBillingCycle } = this.props;
        const { selectedPrepay } = this.state;
        const { getBillingDetails } = this;

        if (newBillingCycle && newBillingCycle !== prevProps.newBillingCycle) getBillingDetails(newBillingCycle);

        if (vps_renew_details_status === 'success' && prevProps.vps_renew_details_status === 'loading') {
            const { attributes } = vps_renew_details_data;

            this.setState({
                selectedPrepay: {
                    cycle: selectedPrepay.cycle,
                    price: selectedPrepay.price,
                    duration: selectedPrepay.duration,
                    discount: attributes.discount,
                    total: attributes.total,
                    renew_next: attributes.next_renewal_date
                }
            });
        }
    }

    render() {
        const { options, vps_renew_details_status, submitPrepayRequest } = this.props;
        const { showConfirmLightbox, selectedPrepay } = this.state;
        const { openConfirmLightbox, closeConfirmLightbox } = this;

        const getPrepayingUntilDate = () => {
            const { duration, renew_next } = selectedPrepay;
            if (duration && renew_next) {
                const number = Number(duration.split(' ')[0]);
                const word = duration.split(' ')[1].replace('s', '').toLowerCase();
                const months = word === 'year' ? number * 12 : number;
                return toLuxonDate(renew_next, 'yyyy-MM-dd').plus({ months }).toFormat('cccc, d LLLL y');
            }

            return '';
        };

        return (
            <form className="popup__form hostingRenew--form">
                {/* Initial Form */}
                <p className="hostingRenew__doubleLabel">Select how long you would like to renew your service for.</p>
                <p className="hostingRenew__doubleLabel">
                    Any available discounts will be shown once you&apos;ve selected your billing cycle and hit &ldquo;Continue&rdquo;.
                </p>
                <Field
                    name="newBillingCycle"
                    component={RenderSelectField}
                    type="select"
                    validate={[requiredFieldValidation]}
                    className="form__dropdown"
                    options={options}
                />
                <ReduxFormButton
                    form={formName}
                    onClick={openConfirmLightbox}
                    force={selectedPrepay.cycle ? REDUX_FORM_BUTTON_STATE.ACTIVE : REDUX_FORM_BUTTON_STATE.INACTIVE}
                >
                    Continue
                </ReduxFormButton>

                {/* Confirmation Lightbox */}
                {showConfirmLightbox && (
                    <OverlayLightbox
                        title="Review Hosting Renewal"
                        onOpen={showConfirmLightbox}
                        onClose={closeConfirmLightbox}
                        className="hostingRenew__overlay"
                        loading={vps_renew_details_status}
                    >
                        <Fragment>
                            <div className="hostingRenew__summary">
                                <div className="hostingRenew__summary--row">
                                    <div className="hostingRenew__summary--title">Renew For</div>
                                    <div className="hostingRenew__summary--value">{selectedPrepay.duration}</div>
                                </div>
                                <div className="hostingRenew__summary--row">
                                    <div className="hostingRenew__summary--title">Renewing Until</div>
                                    <div className="hostingRenew__summary--value">{getPrepayingUntilDate()}</div>
                                </div>
                            </div>
                            <div className="hostingRenew__breakdown">
                                {selectedPrepay.discount && selectedPrepay.discount !== '0.00' ? (
                                    <>
                                        <div className="hostingRenew__breakdown--row">
                                            <div className="hostingRenew__breakdown--title">Renewal Cost</div>
                                            <div className="hostingRenew__breakdown--value">${selectedPrepay.price} AUD</div>
                                        </div>
                                        <div className="hostingRenew__breakdown--row">
                                            <div className="hostingRenew__breakdown--title">Discount</div>
                                            <div className="hostingRenew__breakdown--value">-${selectedPrepay.discount} AUD</div>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                <div className="hostingRenew__breakdown--row">
                                    <div className="hostingRenew__breakdown--title">Total Renewal Cost</div>
                                    <div className="hostingRenew__breakdown--value highlight">${selectedPrepay.total} AUD</div>
                                </div>
                            </div>
                            <SolidButton
                                type="onClick"
                                onClick={(e) => {
                                    e.preventDefault();
                                    submitPrepayRequest(selectedPrepay);
                                }}
                            >
                                Confirm Renewal
                            </SolidButton>
                            <Anchor
                                className="OverlayConfirm__commonlink"
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeConfirmLightbox();
                                }}
                            >
                                Cancel Renewal
                            </Anchor>
                        </Fragment>
                    </OverlayLightbox>
                )}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
RenewForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(RenewForm);

const selector = formValueSelector(formName);

export default withRouter(
    connect(
        (state) => {
            const initialValues = {
                newBillingCycle: state.vps.vps_information_data.attributes.billing_cycle
            };

            return {
                initialValues,
                newBillingCycle: selector(state, 'newBillingCycle'),
                vps_renew_details_status: state.vps.vps_renew_details_status,
                vps_renew_details_data: state.vps.vps_renew_details_data
            };
        },
        {
            getRenewVPSDetails
        }
    )(RenewForm)
);
