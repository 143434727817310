/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useRef, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EppShowCode from 'containers/domain/modules/eppCode/showCode';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import OutlineButton from 'components/Buttons/OutlineButton';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainEppQuery } from '../queries/useTppDomainEppQuery';
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain EPP Code module
 */
function TPPDomainEPPCode({ path }) {
    /***** HOOKS *****/
    const params = useParams();
    const location = useLocation();
    const scrollRef = useRef(null);
    const dispatch = useDispatch();

    /***** QUERIES *****/
    const { data: tpp_domain_epp_data, status: tpp_domain_epp_status } = useTppDomainEppQuery(params.id);
    const { data: tpp_domain_transfer_lock_data } = useTppDomainTransferLockQuery(params.id);

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props: { path, location }, scrollRef: scrollRef.current }, tpp_domain_epp_status === 'success');
    }, [tpp_domain_epp_status]);

    /***** RENDER HELPERS *****/
    const eppCode = tpp_domain_epp_data?.attributes?.password || '';

    /***** RENDER *****/
    return (
        <section ref={scrollRef} className="tppDomainEpp">
            <NXBox initialStatus={tpp_domain_epp_status === 'pending' ? 'loading' : tpp_domain_epp_status}>
                <NXBox.ToolTip info='When transferring a domain name to another registrar, you will need to provide the EPP code to the new registrar. Providing the EPP code verifies that you have the correct authority to move the domain name. EPP codes can also be referred to as "Auth Codes" or "Domain Passwords".' />
                <NXBox.Top title="EPP Code" description="Your EPP code is required when transferring your domain name." />

                {tpp_domain_transfer_lock_data?.attributes?.['transfer-lock'] ? (
                    <NXBox.DefaultPadding>
                        <DialogNotification type="warning">
                            You currently have transfer lock enabled on this domain name meaning you will not be able to use this password to transfer
                            the domain name elsewhere.
                        </DialogNotification>
                    </NXBox.DefaultPadding>
                ) : (
                    ''
                )}

                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={[
                            <EppShowCode key={1} eppCode={eppCode} />,
                            <OutlineButton key={2} type="onClick" onClick={() => dispatch(copyToClipboard(eppCode))}>
                                Copy to Clipboard
                            </OutlineButton>
                        ]}
                    ></NXBox.BottomColumns>
                </NXBox.Bottom>
            </NXBox>
        </section>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainEPPCode;
