/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderAdvanceListSlim } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ValidateBeacon from './validateBeacon';
import ValidateCNAME from './validateCNAME';
import ValidateTXT from './validateTXT';

const formName = 'validateForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ValidateForm extends Component {
    render() {
        const { cert, method, handleSubmit, ssl_beacon_data, ssl_validation_status } = this.props;

        /*   State is a pos and now I gotta use this shitty workaround.
         **********************************************************************************************************/
        let data = {
            cname: {
                content: '',
                subdomain: ''
            },
            domain: '',
            url: '',
            url_secure: '',
            beacon: '',
            approver_email: ''
        };

        if (ssl_beacon_data) {
            const { attributes } = ssl_beacon_data;
            const { cname_content, cname_subdomain, domain, url, url_secure, approver_email, beacon } = attributes;

            data = {
                cname: {
                    content: cname_content,
                    subdomain: cname_subdomain
                },
                domain,
                url,
                url_secure,
                beacon,
                approver_email
            };
        }
        /*   End of shitty workaround.
         **********************************************************************************************************/

        let optionList;
        if (cert.vendor?.includes('Comodo')) {
            const { cname, domain, beacon, url, url_secure, approver_email } = data;

            optionList = [
                {
                    attributes: {
                        name: 'email',
                        displayName: 'Email Validation',
                        desc: `Send a confirmation link to ${approver_email}.`
                    }
                },
                {
                    attributes: {
                        name: 'cname',
                        displayName: 'CNAME Record Validation',
                        desc: 'Create a DNS CNAME record on your domain name.',
                        content: <ValidateCNAME content={cname.content} record={cname.subdomain} domain={domain} />
                    }
                },
                {
                    attributes: {
                        name: 'file',
                        displayName: 'Beacon Validation',
                        desc: 'Please create a publically accessible file on your service.',
                        content: <ValidateBeacon url={url} url_secure={url_secure} content={beacon} />
                    }
                }
            ];
        } else {
            const { beacon, url, url_secure, approver_email } = data;

            optionList = [
                {
                    attributes: {
                        name: 'email',
                        displayName: 'Email Validation',
                        desc: `Send a confirmation link to ${approver_email}.`
                    }
                },
                {
                    attributes: {
                        name: 'txt',
                        displayName: 'TXT Record Validation',
                        desc: 'Create a DNS TXT record on your domain name.',
                        content: <ValidateTXT beacon={beacon} />
                    }
                },
                {
                    attributes: {
                        name: 'file',
                        displayName: 'Beacon Validation',
                        desc: 'Please create a publically accessible file on your service.',
                        content: <ValidateBeacon url={url} url_secure={url_secure} content={beacon} />
                    }
                }
            ];
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form onSubmit={handleSubmit}>
                {ssl_validation_status !== 'success' ? (
                    <Fragment>
                        <div className="sslValidate__desc">Select a validation method below:</div>

                        <div className="sslValidate__options">
                            <div className="sslValidate__input">
                                <Field component={RenderAdvanceListSlim} name="method" type="hidden" className="form__textfield" list={optionList} />
                            </div>
                            {method === 'cname' || method === 'txt' ? (
                                <DialogNotification className="sslConfigure__dialog" type="warning">
                                    PLEASE NOTE: Allow up to 15 minutes for DNS propogation. Validation may also take up to 15 minutes as a result.
                                </DialogNotification>
                            ) : (
                                ''
                            )}
                            <ReduxFormButton form={formName}>Validate Domain Name</ReduxFormButton>
                        </div>
                    </Fragment>
                ) : (
                    ''
                )}

                {cert.type === 'EV' || (cert.type === 'OV' && ssl_validation_status === 'success') ? (
                    <Fragment>
                        <div className="sslValidate__desc">
                            Please note that Premium SSL certificates will also require additional manual verification. Please check your mailbox for
                            further information from the certificate authority.
                        </div>
                        <div className="sslValidateFurther">
                            <div className="sslValidateFurther__text">
                                If you haven&apos;t received an email from the certificate authority, you can resend the validation email.
                            </div>
                            <div className="sslValidateFurther__action">
                                <OutlineButton
                                    type="onClick"
                                    color="secondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    Resend Email
                                </OutlineButton>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    ''
                )}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ValidateForm = reduxForm({
    form: formName
})(ValidateForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const method = selector(state, 'method');

    return {
        ssl_configuration_data: state.ssl.ssl_configuration_data,
        ssl_configuration_status: state.ssl.ssl_configuration_status,
        ssl_configuration_error: state.ssl.ssl_configuration_error,
        ssl_validation_status: state.ssl.ssl_validation_status,
        ssl_beacon_data: state.ssl.ssl_beacon_data,
        ssl_beacon_status: state.ssl.ssl_beacon_status,
        ssl_beacon_error: state.ssl.ssl_beacon_error,
        ssl_information_data: state.ssl.ssl_information_data,
        method
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValidateForm);
