/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { isAttachmentDataAttachment, isAttachmentDataLink } from 'containers/katana/queries/methods/attachmentData';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { API_BASE_URL } from 'App/methods';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param { UseKatanaFileUpload.Values} value
 * @returns {string}
 */
export function useGetKatanaFileUploadValue(value) {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        if (!Array.isArray(value) && typeof value !== 'string' && has(value, 'url')) {
            return value.url;
        }

        if (isAttachmentDataAttachment(value) && id) {
            return `${API_BASE_URL}api/katana/${id}/uploads/${value.attachment.id}/file`;
        }

        if (isAttachmentDataLink(value)) {
            return value.url;
        }

        if (Array.isArray(value) && value.length) {
            return value?.[0].url;
        } else if (typeof value === 'string') {
            return value;
        }

        return '';
    }, [id, value]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
