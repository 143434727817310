/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { application, recovery_email } from 'config/config';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RenderPhoneField from 'components/Form/PhoneInput';
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import { TextArea } from 'components/Form/TextArea';
import RequestLoader from 'components/Loaders/Request';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import {
    RenderAdvanceListSlim,
    RenderField,
    RenderSelectField,
    dateValidation,
    domainFieldValidation,
    emailFieldValidation,
    generatePostcodeValidation,
    peopleNameFieldValidation,
    renderButton,
    requiredFieldValidation
} from 'utilities/methods/form';
import { getSecurityQuestions } from '../../action';

const postcodeValidation = generatePostcodeValidation();

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mountedView: false,
            currentView: false,
            security: false
        };
        this.mountView = this.mountView.bind(this);
        this.changeView = this.changeView.bind(this);
    }

    mountView(value) {
        // Preps view to be displayed without rendering it immediately
        this.setState({
            mountedView: value
        });
    }

    changeView(value) {
        // Toggles the actual view and displays it after mounting
        this.setState({
            currentView: value
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getSecurityQuestions, login_security_questions_data } = this.props;

        if (login_security_questions_data) {
            this.setState({
                security: login_security_questions_data
            });
        } else {
            getSecurityQuestions();
        }
    }

    componentDidUpdate(prevProps) {
        const { login_security_questions_status, login_security_questions_data, login_forgot_email_status, login_forgot_password_status } =
            this.props;
        const { currentView } = this.state;
        const { changeView } = this;

        if (prevProps.login_forgot_email_status === 'error' && login_forgot_email_status === 'error' && currentView !== 'email') {
            changeView('email');
        }

        if (prevProps.login_forgot_password_status === 'error' && login_forgot_password_status === 'error' && currentView !== 'password') {
            changeView('password');
        }

        if (prevProps.login_security_questions_status === 'loading' && login_security_questions_status === 'success') {
            this.setState({
                security: login_security_questions_data
            });
        }
    }

    render() {
        const { pristine, submitting, valid, handleSubmit, navigation, login_forgot_password_status, login_forgot_email_status } = this.props;
        const { mountedView, currentView, security } = this.state;
        const { changeView, mountView } = this;

        const renderSecurityOptions = () => {
            if (security) {
                const options = () => {
                    return security.map((item) => {
                        const { attributes, id } = item;
                        const { question } = attributes;
                        return {
                            label: question,
                            value: id
                        };
                    });
                };
                let securityOptions = [
                    {
                        label: 'Please select',
                        value: ''
                    }
                ];
                securityOptions = securityOptions.concat(options());
                return (
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <Field
                                label="Security Question"
                                name="security_question_id"
                                component={RenderSelectField}
                                validate={[requiredFieldValidation]}
                                type="select"
                                className="form__dropdown"
                                options={securityOptions}
                            />
                        </div>
                    </div>
                );
            }

            return '';
        };

        const handleForgotPasswordRender = () => {
            return (
                <Fragment>
                    <div className="form__row">
                        <div className="form__column full login__textfield">
                            <Field
                                label="Email Address"
                                name="email"
                                component={RenderField}
                                type="email"
                                placeholder="Email"
                                validate={[requiredFieldValidation, emailFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    {renderButton(
                        pristine,
                        submitting,
                        valid && login_forgot_password_status !== 'loading',
                        login_forgot_password_status === 'loading' ? <RequestLoader /> : 'Submit',
                        undefined,
                        'login__forgotPasswordRenderButton'
                    )}
                    <div className="login__link-container">
                        <Anchor
                            className="login__link login__BackToLoginButton"
                            onClick={(e) => {
                                e.preventDefault();
                                navigation('login');
                            }}
                        >
                            Back to Login
                        </Anchor>
                    </div>
                </Fragment>
            );
        };

        const handleForgotUsernameRender = () => {
            return (
                <Fragment>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Service Name (your domain name)"
                                name="service"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, domainFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Full Name"
                                name="name"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, peopleNameFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    {renderSecurityOptions()}
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Security Answer"
                                name="security_answer"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <Field
                                label="Date of Birth"
                                name="dob"
                                component={RenderDateTimePicker}
                                dateFormat="d/M/yyyy"
                                validate={[requiredFieldValidation, dateValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Mobile Number"
                                name="phone"
                                component={RenderPhoneField}
                                required
                                type="tel"
                                validate={[requiredFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Postcode"
                                name="postcode"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, postcodeValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="An email address we can currently contact you on"
                                name="contact_email"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, emailFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field label="Message (optional)" name="message" component={TextArea.ReduxForm.Default} className="form__textfield" />
                        </div>
                    </div>
                    {renderButton(
                        pristine,
                        submitting,
                        valid && login_forgot_email_status !== 'loading',
                        login_forgot_email_status === 'loading' ? <RequestLoader /> : 'Submit',
                        undefined,
                        'login__emailRecoverySubmitButton'
                    )}

                    <div className="login__link-container">
                        <Anchor
                            className="login__link"
                            onClick={(e) => {
                                e.preventDefault();
                                navigation('login');
                            }}
                        >
                            Back to Login
                        </Anchor>
                    </div>

                    <Box
                        premounted={true}
                        className="contactRecovery"
                        title={`Can't fill out this form?`}
                        desc="If you cannot answer the security questions, but still need access to the account, please contact our Recovery team via email outlining your situation, and they will be in touch with you soon."
                        bottom={true}
                        columns={[
                            {
                                render: (
                                    <div className="sharedBox__infoColumn hasIcon">
                                        <a className="contactRecovery__link" href={`mailto:${recovery_email}`}>
                                            <span className="icon icon-email"></span>
                                            <span className="text">{recovery_email}</span>
                                        </a>
                                    </div>
                                )
                            }
                        ]}
                    />
                </Fragment>
            );
        };

        const handleTitleRender = () => {
            switch (currentView) {
                case 'email':
                    return (
                        <div className="forgot__title">
                            <div className="heading">Email Recovery</div>
                            <div className="description">
                                {`If you are the account holder and are not sure what email you're supposed to be using, please fill out the form below
                                with the security questions answered. We will be in touch to let you know what you need to log in with.`}
                            </div>
                        </div>
                    );

                case 'password':
                default:
                    return (
                        <div className="forgot__title">
                            <div className="heading">Lost Password Reset</div>
                            <div className="description">
                                {`In order to continue with your login to ${application}, you'll need to complete the authentication process. Enter your
                                email address below to begin the password recovery process.`}
                            </div>
                        </div>
                    );

                case false:
                    return (
                        <div className="forgot__title">
                            <div className="heading">Need help logging in?</div>
                        </div>
                    );
            }
        };

        const handleViewToggle = () => {
            switch (currentView) {
                case false: {
                    const optionList = [
                        {
                            attributes: {
                                name: 'password',
                                displayName: `Recover my password`,
                                desc: `Select this if you know your email address and can't remember your password.`,
                                onClick: () => {
                                    mountView('password');
                                }
                            }
                        },
                        {
                            attributes: {
                                name: 'email',
                                displayName: 'Recover my email address',
                                desc: `Select this if you can't remember your email address and need help locating your account.`,
                                onClick: () => {
                                    mountView('email');
                                }
                            }
                        }
                    ];

                    return (
                        <Fragment>
                            <Field component={RenderAdvanceListSlim} name="method" type="hidden" className="form__textfield" list={optionList} />
                            {mountedView ? (
                                <SolidButton
                                    color="primary"
                                    className="login__continueRecoveryButton"
                                    type="onClick"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        changeView(mountedView);
                                    }}
                                >
                                    Continue
                                </SolidButton>
                            ) : (
                                <InactiveButton className="login__continueRecoveryButton">Continue</InactiveButton>
                            )}
                            <div className="login__link-container">
                                <Anchor
                                    className="login__link login__BackToLoginButton"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigation('login');
                                    }}
                                >
                                    Back to Login
                                </Anchor>
                            </div>
                        </Fragment>
                    );
                }
                case 'email':
                    return handleForgotUsernameRender();

                case 'password':
                default:
                    return handleForgotPasswordRender();
            }
        };

        return (
            <Transition when={currentView}>
                <div className={`loginForget--${currentView}`}>
                    {handleTitleRender()}
                    <form className="forgot__form" onSubmit={handleSubmit}>
                        {handleViewToggle()}
                    </form>
                </div>
            </Transition>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Form = reduxForm({
    form: 'forgotForm'
})(Form);

const mapStateToProps = (state) => {
    return {
        login_forgot_password_status: state.login.login_forgot_password_status,
        login_forgot_email_status: state.login.login_forgot_email_status,
        login_security_questions_status: state.login.login_security_questions_status,
        login_security_questions_data: state.login.login_security_questions_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getSecurityQuestions
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
