/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useMemo } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RadioContext } from 'components/Form/Radio/_Radio/consts';
import type { NRadio } from 'components/Form/Radio/_Radio/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _Radio: React.FC<NRadio.Props> = ({ children, activeRadioButton, setActiveRadioButton }) => {
    /***** RENDER HELPERS *****/
    const contextValue = useMemo(() => ({ activeRadioButton, setActiveRadioButton }), [activeRadioButton, setActiveRadioButton]);

    /***** RENDER *****/
    return (
        <RadioContext.Provider value={contextValue}>
            <div className="Radio">{children}</div>
        </RadioContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default _Radio;
