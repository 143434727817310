/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

import { truncateText } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Overview extends Component {
    componentDidUpdate(prevProps) {
        const { hosting_information_status } = this.props;
        registerScrollEvents(this, hosting_information_status === 'success' && prevProps.hosting_information_status === 'loading');
    }

    render() {
        const { hosting_information_status, hosting_information_data } = this.props;
        const { domain, domain_status, next_invoice_date, next_due_date, billing_cycle, registration_date, amount } =
            hosting_information_data.attributes;
        const hostingName = hosting_information_data.included[0].attributes.name.toLowerCase();

        const renderDate = (date) => {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const newDate = new Date(date);
            if (newDate.toLocaleDateString('en-GB', options) === 'Invalid Date') {
                return '';
            } else {
                return newDate.toLocaleDateString('en-GB', options);
            }
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        const conditionalProps = {
            className: `service__overview`,
            split: {
                left: {
                    render: (
                        <Fragment>
                            <h2 className="box__heading">Service Overview</h2>
                            <div className="info__container">
                                <div className="box__description">Primary Domain Name</div>
                                <div className="box__subheading">{truncateText(domain, 35, '...')}</div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Status</div>
                                <div
                                    className={`box__subheading ${domain_status === 'Suspended' ? 'warning' : ''} ${
                                        domain_status === 'Active' ? 'confirm' : ''
                                    }`}
                                >
                                    {domain_status}
                                </div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Hosting Plan</div>
                                <div className="box__subheading plan">{hostingName + ' Shared Hosting'}</div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Activated</div>
                                <div className="box__subheading">{renderDate(registration_date)}</div>
                            </div>
                        </Fragment>
                    )
                },
                right: {
                    render: (
                        <Fragment>
                            <h2 className="box__heading">Billing Overview</h2>
                            <div className="info__container">
                                <div className="box__description">Next Renewal Date</div>
                                <div className="box__subheading">{renderDate(next_due_date)}</div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Next Invoice Generation Date</div>
                                <div className="box__subheading">{renderDate(next_invoice_date)}</div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Billing Cycle</div>
                                <div className="box__subheading">{billing_cycle}</div>
                            </div>
                            <div className="info__container">
                                <div className="box__description">Renewal Amount</div>
                                <div className="box__subheading">${amount} AUD</div>
                            </div>
                        </Fragment>
                    )
                }
            }
        };

        /*  Render Component
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="hostingOverview"
            >
                <Box premounted={true} status={hosting_information_status} {...conditionalProps} />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_information_status: state.hosting.hosting_information_status,
        hosting_information_data: state.hosting.hosting_information_data,
        hosting_information_error: state.hosting.hosting_information_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));
