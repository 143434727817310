/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import KatanaServicesList from 'containers/services/modules/katanaServicesList';
import DomainOverview from './modules/domains';
import EmailOverview from './modules/email';
import GSuiteOverview from './modules/gsuite';
import HostingOverview from './modules/hosting';
import { Microsoft365Table } from './modules/ms365';
import { SSLTable } from './modules/ssl';
import { VPSTable } from './modules/vps';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';
import Subnav from 'components/Subnav';

/*   ACTIONS
 *****************************************************/
import { getEmailList, getGSuiteList, getSslList } from './action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { actionButtonWidth } from 'components/NXTable/hooks/useTableActions';
import { activePromotion } from 'utilities/methods/commonActions';

import './_services.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class MyServices extends Component {
    getServiceList = () => {
        const { match, email_list_data, ssl_list_data, gsuite_list_data } = this.props;
        const { params } = match;
        const { service } = params;

        switch (service) {
            case 'email-hosting':
                if (!email_list_data) {
                    getEmailList();
                }
                break;

            case 'ssl':
                if (!ssl_list_data) {
                    getSslList();
                }
                break;

            case 'gsuite':
                if (!gsuite_list_data) {
                    getGSuiteList();
                }
                break;

            default:
                break;
        }
    };

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        this.getServiceList();

        window.scrollTo(0, 0);
    }

    render() {
        const { location, match } = this.props;
        const { pathname } = location;
        const { params } = match;
        const { service } = params;

        const renderSubPage = () => {
            switch (service) {
                case 'domains':
                    return <DomainOverview />;
                case 'hosting':
                    return <HostingOverview />;

                case serviceNav.VIPsites.key:
                    return <KatanaServicesList />;

                case 'email-hosting':
                    return <EmailOverview />;

                case 'ssl':
                    return <SSLTable />;

                case 'vps':
                    return <VPSTable />;

                case 'google':
                    return <GSuiteOverview />;

                case 'microsoft-365':
                    return <Microsoft365Table />;

                default:
                    return <PageNotFound />;
            }
        };

        /***** MEGAMAY24 START *****/
        // const servicePageClassName = 'ServicePage';
        const servicePageClassName = activePromotion('megamay2024') ? 'ServicePage ServicePage--promo' : 'ServicePage';
        /***** MEGAMAY24 END *****/

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className={servicePageClassName} style={{ '--actionButtonWidth': `${actionButtonWidth}px` }}>
                <Subnav pathname={pathname} />
                <TransitionGroup>
                    <CSSTransition key={service} timeout={600} classNames="ccpPageFade">
                        <div className="anim__wrapper">{renderSubPage()}</div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    email_list_data: state.services.email_list_data,
    ssl_list_data: state.services.ssl_list_data,
    gsuite_list_data: state.services.gsuite_list_data,
    ms365_list_data: state.services.ms365_list_data
});

const mapDispatchToProps = {
    getEmailList,
    getSslList,
    getGSuiteList
};

MyServices = connect(mapStateToProps, mapDispatchToProps)(MyServices);

export default MyServices;
