/**********************************************************************************************************
*   BASE IMPORT
+**********************************************************************************************************/
import { company } from 'config/config';
import htmr from 'htmr';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import NoServicesPlaceholder from 'components/Placeholders/NoServices';
import StatusTag from 'components/StatusTag';
import Table from 'components/Table';

//logos
import cpanel from 'assets/images/support/cpanel.svg';
import directadmin from 'assets/images/support/direct-admin.svg';
import other from 'assets/images/support/other.svg';
import plesk from 'assets/images/support/plesk-logo.svg';
import virtualmin from 'assets/images/support/virtualmin.svg';
import webmin from 'assets/images/support/webmin.png';
import zadmin from 'assets/images/support/zadmin.svg';

/*   ACTIONS
 *****************************************************/
import { getHostingList, getVPSListParams } from 'containers/services/action';
import { getIncludedObjBasedOnType, scrollToRef, textLowerCase } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class MigrationSelectService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            servicesListData: null,
            servicesListMeta: null
        };

        this.serviceListRef = createRef();
        this.webhostingEnvList = [
            {
                title: 'cPanel',
                image: cpanel
            },
            {
                title: 'Plesk',
                image: plesk
            },
            {
                title: 'DirectAdmin',
                image: directadmin
            },
            {
                title: 'ZAdmin',
                image: zadmin
            },
            {
                title: 'Webmin',
                image: webmin
            },
            {
                title: 'Virtualmin',
                image: virtualmin
            },
            {
                title: 'Other',
                image: other
            }
        ];

        this.getTableMatrix = this.getTableMatrix.bind(this);
        this.setServicesList = this.setServicesList.bind(this);
    }

    getTableMatrix() {
        const { servicesListData } = this.state;
        const { selectService } = this.props;

        if (servicesListData?.length > 0) {
            return servicesListData
                .filter((service) => service.attributes.vps_type !== 'self_managed')
                .map((service) => {
                    const {
                        id,
                        included,
                        attributes: { domain, domain_status }
                    } = service;

                    const productDetailsObj = getIncludedObjBasedOnType(included, 'product');
                    const { name } = productDetailsObj.attributes;

                    function getActions() {
                        if (domain_status === 'Active')
                            return {
                                label: 'Select',
                                type: 'onClick',
                                size: 'small',
                                className: 'enabled',
                                onClick: (e) => {
                                    e.preventDefault();
                                    selectService(id);
                                }
                            };
                        return [<InactiveButton key={0}>Select</InactiveButton>];
                    }

                    return {
                        heading: (
                            <div className="migrationSelectService__service">
                                <div
                                    className={`migrationSelectService__serviceTop${
                                        domain_status === 'Active'
                                            ? ' migrationSelectService__serviceTop--enabled'
                                            : ' migrationSelectService__serviceTop--disabled'
                                    }`}
                                >
                                    <div className="migrationSelectService__serviceName">{name}</div>
                                    <div className="migrationSelectService__serviceStatus">
                                        <StatusTag status={textLowerCase(domain_status)} className="domain" />
                                    </div>
                                </div>
                                <div className="migrationSelectService__serviceBottom">
                                    <div className="migrationSelectService__serviceDomain">{domain}</div>
                                </div>
                            </div>
                        ),
                        actions: getActions()
                    };
                });
        }
        return [];
    }

    setServicesList() {
        const { hosting_list_data, hosting_list_meta, vps_list_data, vps_list_meta } = this.props;

        let servicesListData = [];
        if (Array.isArray(hosting_list_data) && Array.isArray(vps_list_data)) {
            servicesListData = hosting_list_data.concat(vps_list_data);
        }

        const getCorrectMeta = () => {
            if (hosting_list_meta?.last_page !== hosting_list_meta?.current_page) {
                return { meta: hosting_list_meta, serviceType: 'hosting' };
            }
            if (vps_list_meta?.last_page !== vps_list_meta?.current_page) {
                return { meta: vps_list_meta, serviceType: 'vps' };
            }
            return null;
        };

        this.setState({
            servicesListData,
            servicesListMeta: getCorrectMeta()
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getVPSListParams } = this.props;

        getHostingList();
        getVPSListParams();
    }

    componentDidUpdate(prevProps) {
        const { hosting_list_data, vps_list_data } = this.props;
        const { setServicesList } = this;

        if (hosting_list_data !== prevProps.hosting_list_data || vps_list_data !== prevProps.vps_list_data) {
            setServicesList();
        }
    }

    render() {
        const { getTableMatrix, webhostingEnvList, serviceListRef } = this;
        const { servicesListMeta } = this.state;
        const {
            hosting_list_status,
            hosting_list_more_status,
            hosting_list_data,
            vps_list_status,
            vps_list_more_status,
            vps_list_data,
            getVPSListParams,
            selectEnv,
            selectedEnv
        } = this.props;

        const renderBoxes = () => {
            return webhostingEnvList.map((box, index) => {
                const { title, image } = box;

                const renderImage = () => {
                    return title === 'Other' ? (
                        <i className="migrationSelectService__boxLogo icon-other-control-panel migrationSelectService__boxLogo--iconSlug" />
                    ) : (
                        <img className="migrationSelectService__boxLogo" src={image} alt={title} />
                    );
                };

                return (
                    <div key={index} className="migrationSelectService__boxWrapper">
                        <button
                            className={`migrationSelectService__box${selectedEnv === title ? ' migrationSelectService__box--selected' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                selectEnv(title);
                                scrollToRef(serviceListRef?.current);
                            }}
                        >
                            {renderImage()}
                            <div className="migrationSelectService__boxTitle">{title}</div>
                            <i className="migrationSelectService__boxIcon icon icon-arrow" />
                        </button>
                    </div>
                );
            });
        };

        const handleLoadingStatus = () => {
            if (hosting_list_status === 'loading' || vps_list_status === 'loading') {
                return 'loading';
            } else if (hosting_list_status === 'error' || vps_list_status === 'error') {
                return 'error';
            }
            return 'success';
        };

        const getServicesList = () => {
            return (
                <Table
                    embedded={true}
                    className="migrationSelectService__table"
                    loading={handleLoadingStatus()}
                    matrix={getTableMatrix()}
                    error={
                        <NoServicesPlaceholder
                            service={{
                                name: 'Web Hosting or VPS',
                                purchaseLink: getPurchaseLink('Web Hosting')
                            }}
                            message="Looks like you have no services available to migrate to."
                            linkText="Purchase a Web Hosting or VPS service"
                        />
                    }
                    stacked={true}
                    layered={true}
                />
            );
        };

        const renderServicesBox = () => {
            return (
                <Box
                    premounted={true}
                    desc={htmr(`Select the <strong>NEW</strong> ${company} service you would like to migrate <strong>TO</strong>`)}
                    custom={{
                        pos: 'bottom',
                        render: getServicesList()
                    }}
                    footer={handleFooterRender()}
                />
            );
        };

        const handleFooterRender = () => {
            if (hosting_list_more_status === 'loading' || vps_list_more_status === 'loading') {
                return <RequestLoader />;
            }

            if (servicesListMeta && servicesListMeta.meta.last_page !== servicesListMeta.meta.current_page) {
                return {
                    title: `Show More`,
                    action: () => {
                        if (servicesListMeta.serviceType === 'hosting') {
                            return getHostingList(
                                {
                                    page: servicesListMeta.meta.current_page + 1,
                                    record_per_page: 10
                                },
                                hosting_list_data
                            );
                        }
                        getVPSListParams(
                            {
                                page: servicesListMeta.meta.current_page + 1,
                                record_per_page: 10
                            },
                            vps_list_data,
                            true
                        );
                    }
                };
            }

            return false;
        };

        const renderPage = () => {
            switch (handleLoadingStatus()) {
                case 'success':
                    return (
                        <>
                            <div className="migrationSelectService__heading">
                                Start by selecting your <strong>current</strong> web hosting environment
                            </div>
                            <div className="migrationSelectService__boxes">{renderBoxes()}</div>
                            <div className="migrationSelectService__serviceList" ref={serviceListRef}>
                                {selectedEnv ? renderServicesBox() : ''}
                            </div>
                        </>
                    );
                case 'error':
                    return <FetchPageError />;
                case 'loading':
                default:
                    return <RequestLoader />;
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="migrationSelectService">
                <div className="migrationSelectService__container">{renderPage()}</div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            hosting_list_status: state.services.hosting_list_status,
            hosting_list_more_status: state.services.hosting_list_more_status,
            hosting_list_data: state.services.hosting_list_data,
            hosting_list_meta: state.services.hosting_list_meta,
            vps_list_status: state.services.vps_list_status,
            vps_list_more_status: state.services.vps_list_more_status,
            vps_list_data: state.services.vps_list_data,
            vps_list_meta: state.services.vps_list_meta
        }),
        {
            getVPSListParams
        }
    )(MigrationSelectService)
);
