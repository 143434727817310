/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CollectMissingDetailsContent from './content';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsAccountHolder } from 'containers/account/methods';
import { useCheckTokenData } from 'utilities/hooks/redux/useCheckTokenData';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Runs the logic to see whether the user is required to complete the missing details form, and marks itself as "required" if so
 */
function CollectMissingDetailsCheck({ markSelfAsRequired }) {
    /***** HOOKS *****/
    const { app_user_data } = useSelector((state) => ({
        app_user_data: state.app.app_user_data
    }));

    const isVPN = useIsVPN();
    const { is_security_detail_completed, is_user_detail_completed, do_detail_completed_check } = useCheckTokenData();

    /***** EFFECTS *****/
    useEffect(() => {
        if (isVPN || !checkIsAccountHolder(app_user_data)) return;
        const isMissingDetailsCollectionRequired = do_detail_completed_check && (!is_security_detail_completed || !is_user_detail_completed);
        if (isMissingDetailsCollectionRequired) {
            markSelfAsRequired();
        }
    }, []);

    /***** RENDER *****/
    return null;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CollectMissingDetailsCheck.propTypes = {
    /**
     * Function that marks "userDetails" item as required in the "postLoginChecklist"
     */
    markSelfAsRequired: PropTypes.func
};

export default CollectMissingDetailsCheck;
export { CollectMissingDetailsContent };
