/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { emailFieldValidation, RenderField, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'MoveForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class MoveForm extends Component {
    render() {
        const { product, domain, handleSubmit } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="moveHosting">
                <div className="moveHosting__info">
                    <span className="moveHosting__info--domain">{domain} / </span>
                    <span className="moveHosting__info--name">{product} </span>
                </div>

                <DialogNotification className="moveHosting__dialog" type="warning" outline={true}>
                    PLEASE NOTE: Once this process is complete, you will no longer be able to manage this service, and the customer you are moving the
                    service to will have full control over it.
                </DialogNotification>
                <form className="moveHosting__form" onSubmit={handleSubmit}>
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Email Address"
                                name="email"
                                component={RenderField}
                                type="email"
                                placeholder="jsmith@example-email.com"
                                validate={[requiredFieldValidation, emailFieldValidation]}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="moveHosting__submit">
                        <ReduxFormButton form={formName}>Move Service</ReduxFormButton>
                    </div>
                </form>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
MoveForm = reduxForm({
    form: formName
})(MoveForm);

export default MoveForm;
