/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { requiredFieldValidation, ipAddressValidation, renderButton, RenderField } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class FirewallForm extends Component {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { pristine, submitting, valid, handleSubmit } = this.props;

        return (
            <form className="firewallForm" onSubmit={handleSubmit}>
                <div className="firewallForm__container">
                    <div className="firewallForm__input">
                        <Field
                            label="IP Address"
                            name="ip_address"
                            component={RenderField}
                            type="text"
                            placeholder="e.g. 123.456.789.12"
                            validate={[requiredFieldValidation, ipAddressValidation]}
                            className="form__textfield"
                        />
                    </div>
                    <div className="firewallForm__action">{renderButton(pristine, submitting, valid, 'Check')}</div>
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
FirewallForm = reduxForm({
    form: 'firewallForm'
})(FirewallForm);

export default withRouter(FirewallForm);
