/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxSplit.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides flexbox and border styling to a list of its sections, best used with just 2 sections
 */
function NXBoxSplit({ sections }) {
    /***** RENDER *****/
    return (
        <div className="NXBoxSplit">
            {sections.map((section, index) => (
                <div key={index} className="NXBoxSplit__section">
                    {section}
                </div>
            ))}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxSplit.propTypes = {
    // Each section will be flex aligned horizontally and take up equal space in the container. Sections will also be divided by border
    sections: PropTypes.arrayOf(PropTypes.node)
};

export default NXBoxSplit;
