/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TransferLockCheckedMessage from 'containers/domain/modules/transferLock/checkedMessage';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import OutlineButton from 'components/Buttons/OutlineButton';
import InactiveButton from 'components/Buttons/InactiveButton';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { useTppDomainDisableTransferLockMutation } from 'containers/domain/tppDomains/queries/mutations';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain Transfer Lock module
 */
function TPPDomainTransferLock({ path }) {
    /***** HOOKS *****/
    const params = useParams();
    const location = useLocation();
    const scrollRef = useRef(null);

    /***** QUERIES *****/
    const { status: tpp_domain_transfer_lock_status, isFetching: isTppDomainTransferLockStatusFetching } = useTppDomainTransferLockQuery(params.id);
    const { mutate: mutateTppDomainDisableTransferLock, isPending: isTppDomainDisableTransferLockLoading } =
        useTppDomainDisableTransferLockMutation();

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props: { path, location }, scrollRef: scrollRef.current }, tpp_domain_transfer_lock_status === 'success');
    }, [tpp_domain_transfer_lock_status]);

    /***** RENDER *****/
    return (
        <section ref={scrollRef} className="tppDomainTransferLock">
            <NXBox
                // Show the skeleton loader initially but the request loader when it's refetching after mutation
                initialStatus={tpp_domain_transfer_lock_status === 'pending' ? 'loading' : tpp_domain_transfer_lock_status}
                isLoading={isTppDomainTransferLockStatusFetching && tpp_domain_transfer_lock_status !== 'loading'}
            >
                <NXBox.Top
                    title="Transfer Lock"
                    description="Transfer lock prevents your domain name from being transferred away to another provider regardless of the EPP/password being correct."
                />
                <NXBox.DefaultPadding>
                    <DialogNotification type="warning">
                        PLEASE NOTE: Transfer lock cannot be re-enabled on this domain once it has been disabled.
                    </DialogNotification>
                </NXBox.DefaultPadding>
                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={[
                            <TransferLockCheckedMessage key={1} />,
                            isTppDomainDisableTransferLockLoading ? (
                                <InactiveButton>
                                    <RequestLoader />
                                </InactiveButton>
                            ) : (
                                <OutlineButton key={2} color="warn" type="onClick" onClick={() => mutateTppDomainDisableTransferLock(params.id)}>
                                    Disable
                                </OutlineButton>
                            )
                        ]}
                    />
                </NXBox.Bottom>
            </NXBox>
        </section>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainTransferLock;
