/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { billingCycles } from 'config/config';
import htmr from 'htmr';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChangeResourcesFormAllocations from './allocations';
import ChangeResourcesFormConfirmLightbox from './confirmLightbox';
import ChangeResourcesFormSummary from './summary';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import Icons from 'components/Icons';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import Slider from 'components/Slider';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { calculateCustomHostingCost } from 'containers/hosting/state/accountActions';
import { getResourceLimits } from 'containers/hosting/state/baseActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import DialogNotification from 'components/Notifications/DialogNotification';
import Text from 'components/Utils/Text';
import './_changeResourcesForm.scss';

const configTypes = /** @type {const} */ ({
    DISK: 'Disk Space',
    CPU: 'CPU',
    MEMORY: 'Memory'
});

const resourceType = /** @type {const} */ ({
    DISK: 'disk',
    CPU: 'cpu',
    MEMORY: 'mem'
});

/**
 * Returns a list of options that are at or above the current option or all active options, this is intended to be used to filter out the options that are below the new minimums
 * while still showing them if the current plan is already below the new minimums.
 *
 * @param {import('./types').TConfigOptions} options
 * @param {number} currentOption
 */
const getActiveOrAboveAndIncludingCurrent = (options, currentOption) => {
    const index = options.findIndex(({ option_id }) => option_id === currentOption);

    return options.filter(({ show_order }, i) => i >= index || show_order);
};

/**
 * Generates the config options for each resource type based on the initial hosting resources
 */
export const prepareConfigs = (
    /** @type {import('./types').TPrepareConfigProps} */ {
        [configTypes.CPU]: cpuOptions,
        [configTypes.MEMORY]: memOptions,
        [configTypes.DISK]: diskOptions,
        current
    }
) =>
    /** @type {const} */ ({
        [resourceType.DISK]: {
            name: 'Disk Space',
            subtitle: 'Disk Space',
            tooltip: 'Your files and databases will be stored on our new enterprise SAS SSD storage which is lightning fast and highly reliable.',
            options: getActiveOrAboveAndIncludingCurrent(diskOptions, current.disk_space.option_id)
        },
        [resourceType.CPU]: {
            name: 'CPU Cores',
            subtitle: 'CPU',
            tooltip:
                'This is the limit of CPU available to your website, where 100% equals 1 CPU core. You can view your CPU usage inside cPanel at anytime.',
            options: getActiveOrAboveAndIncludingCurrent(cpuOptions, current.cpu.option_id)
        },
        [resourceType.MEMORY]: {
            name: 'Memory',
            subtitle: 'Memory',
            tooltip:
                'The is the amount of memory your website processes can use before throttling is applied. You can view your memory usage inside cPanel at any time.',
            options: getActiveOrAboveAndIncludingCurrent(memOptions, current.memory.option_id)
        }
    });

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/** @param {import('./types').props} props */
const ChangeResourceForm = ({
    submitChangeRequest,
    /* Redux props */
    hosting_information_data,
    hosting_resource_config_data,
    hosting_resource_data,
    custom_hosting_calculate_status,
    custom_hosting_calculate_data,
    custom_hosting_change_status,
    app_viewport,
    calculateCustomHostingCost
}) => {
    /***** STATE *****/
    const [availableConfigOptions, setAvailableConfigOptions] = useState(/** @type {import('./types').configs | null} */ (null));

    const { hosting_limit_data, hosting_limit_status } = useSelector((/** @type {*} */ state) => ({
        hosting_limit_data: /** @type {import('utilities/api/hosting').CustomHostingResourceLimits | null} */ (state.hosting.hosting_limits_data),
        hosting_limit_status: /** @type {'loading' | 'error' | 'success' | null} */ (state.hosting.hosting_limits_status)
    }));

    // option_id of the currently selected config option for each resource
    const [selectedConfigOptions, setSelectedConfigOptions] = useState(
        /** @type {import('./types').configs} */ ({
            disk: null,
            cpu: null,
            mem: null
        })
    );
    const [selectedBillingCycle, setSelectedBillingCycle] = useState(null);
    const [isConfirmLightboxOpen, setIsConfirmLightboxOpen] = useState(false);

    // whether or not to show the warning when below disk/inode space.
    const [isBelowMinimumWarningShowing, setIsBelowMinimumWarningShowing] = useState(false);

    // Derive data for currently selected config options
    const currentlySelectedOptions = {
        disk: availableConfigOptions?.disk.options.find((option) => option.option_id === selectedConfigOptions.disk),
        cpu: availableConfigOptions?.cpu.options.find((option) => option.option_id === selectedConfigOptions.cpu),
        mem: availableConfigOptions?.mem.options.find((option) => option.option_id === selectedConfigOptions.mem)
    };

    // Figure out if any of the resource sliders OR the billing cycle are different from their initial values
    const haveConfigsChanged =
        hosting_resource_data?.attributes?.config_values?.disk_space?.option_id !== currentlySelectedOptions?.disk?.option_id ||
        hosting_resource_data?.attributes?.config_values?.cpu?.option_id !== currentlySelectedOptions?.cpu?.option_id ||
        hosting_resource_data?.attributes?.config_values?.memory?.option_id !== currentlySelectedOptions?.mem?.option_id;

    /***** HOOKS *****/
    /** @type {{ id: string }} */
    const { id: serviceId } = useParams();

    /***** FUNCTIONS *****/
    // Builds an "attributes" object for either the "change plan calculate" or "change plan" endpoint
    /**
     * @param {@} changedResourceAccessor
     * @param {number} newOptionIndex
     */
    function prepareResourcesAttributes(/** @type {import('./types').TResourceType | null} */ changedResourceAccessor = null, newOptionIndex = null) {
        return {
            billing_cycle: selectedBillingCycle,
            configs: Object.keys(selectedConfigOptions).map((/** @type {keyof import('./types').configs} */ resource) => {
                // If its the resource being changed, use the new option index, otherwise, use the currently selected option
                // This can also be called when the billing cycle gets changed, in which case "changedResourceAccessor" AND "newOptionIndex" will be null
                const optionToSend =
                    changedResourceAccessor === resource
                        ? availableConfigOptions?.[resource]?.options[newOptionIndex]
                        : availableConfigOptions?.[resource]?.options.find(({ option_id }) => option_id === selectedConfigOptions[resource]);

                return {
                    config_id: optionToSend?.config_id,
                    option_id: optionToSend?.option_id
                };
            })
        };
    }

    // Needs to get called every time one of the sliders has been moved (finished moving) AND when billing cycle is changed
    function recalculatePricing(/** @type {import('./types').TResourceType | null} */ changedResourceAccessor = null, newOptionIndex = null) {
        const attributes = prepareResourcesAttributes(changedResourceAccessor, newOptionIndex);

        calculateCustomHostingCost(serviceId, attributes);
    }

    // Gets called every time any of the sliders are moved
    function updateSlider(/** @type {import('./types').TResourceType} */ changedResourceAccessor, newOptionIndex, hasFinishedMoving = false) {
        setSelectedConfigOptions((prev) => ({
            ...prev,
            [changedResourceAccessor]: availableConfigOptions?.[changedResourceAccessor].options[newOptionIndex].option_id
        }));

        // Only recalculate pricing when the slider has finished being dragged
        if (hasFinishedMoving) recalculatePricing(changedResourceAccessor, newOptionIndex);
    }

    /***** EFFECTS *****/
    // When both "available config options" and "current config options" become available
    useEffect(() => {
        if (!hosting_resource_config_data || !hosting_resource_data || !hosting_limit_data) return;

        const disk = hosting_resource_config_data.attributes[configTypes.DISK].map((disk, i) => ({
            ...disk,
            less_than_current_usage: hosting_limit_data.attributes[configTypes.DISK][i]?.less_than_current_usage ?? false
        }));

        // Set the list of available config options
        setAvailableConfigOptions(
            //note: there is no ts-error here if you use the wrong type as an object key, it's a typescript bug so be careful to use the correct configTypes if modifying
            prepareConfigs({
                [configTypes.CPU]: hosting_resource_config_data?.attributes[configTypes.CPU],
                [configTypes.MEMORY]: hosting_resource_config_data?.attributes[configTypes.MEMORY],
                [configTypes.DISK]: disk,
                current: hosting_resource_data?.attributes.config_values
            })
        );

        const selectedDiskOptionId = hosting_resource_data.attributes.config_values.disk_space.option_id;

        // Set the id's of the currently selected config options. These are the same as the "current config options" initially, but will change whenever the sliders are moved
        setSelectedConfigOptions({
            disk: selectedDiskOptionId,
            cpu: hosting_resource_data.attributes.config_values.cpu.option_id,
            mem: hosting_resource_data.attributes.config_values.memory.option_id
        });

        const isCurrentDiskBelowUsage = disk.find((disk) => disk.option_id === selectedDiskOptionId)?.less_than_current_usage ?? false;

        setIsBelowMinimumWarningShowing(isCurrentDiskBelowUsage);

        // Set the currently selected billing cycle to the current billing cycle of the service
        setSelectedBillingCycle(hosting_resource_data.attributes.billing_cycle);
    }, [hosting_resource_config_data, hosting_resource_data, hosting_limit_data]);

    useEffect(() => {
        if (selectedBillingCycle) recalculatePricing();
    }, [selectedBillingCycle]);

    // Fetch the resource limits if they haven't been fetched yet
    useEffect(() => {
        const idAsNumber = Number(serviceId);

        if (isNaN(idAsNumber)) return;

        getResourceLimits(idAsNumber);
    }, []);

    /***** RENDER HELPERS *****/
    const renderOneSlider = (/** @type {import('./types').TResourceType | null} */ resourceAccessor) => {
        const { name: configName, subtitle, tooltip, options } = availableConfigOptions[resourceAccessor];

        const unavailableOptions = options.filter(({ show_order }) => !show_order);
        const currentSelectedOption = currentlySelectedOptions[resourceAccessor];

        // The minimum value is the number of options that are disabled
        const sliderMin = unavailableOptions.length === options.length ? options.length - 1 : unavailableOptions.length;

        const renderResourcePrice = () => {
            const price = currentlySelectedOptions[resourceAccessor]?.pricing[selectedBillingCycle];
            return `$${price.toFixed(2)}${billingCycles[selectedBillingCycle]}`;
        };

        if (['sm', 'xs'].includes(app_viewport)) {
            return (
                <div className="changeResourcesForm__option">
                    <SelectDropdown
                        label={htmr(`<strong>${currentlySelectedOptions[resourceAccessor].name}</strong> ${configName}`)}
                        options={options
                            .filter(({ show_order }) => show_order)
                            .map(({ name }, index) => ({
                                label: htmr(`<strong>${name}</strong> ${configName}`),
                                onClick: () => updateSlider(resourceAccessor, sliderMin + index, true)
                            }))}
                    />
                    <div className="changeResourcesForm__configWrapper">
                        <div className="changeResourcesForm__configPrice">{renderResourcePrice()}</div>
                    </div>
                </div>
            );
        }

        return (
            <div className="changeResourcesForm__option">
                <div className="changeResourcesForm__configWrapper">
                    <div className="changeResourcesForm__configOption">
                        {configName} <Tooltip className="changeResourcesForm__configTooltip" info={tooltip} />
                    </div>
                    <div className="changeResourcesForm__configName">
                        <strong>{currentlySelectedOptions[resourceAccessor].name}</strong> {subtitle}
                    </div>
                    <div className="changeResourcesForm__configPrice">{renderResourcePrice()}</div>
                </div>

                <Slider
                    className="changeResourcesForm__slider"
                    min={0}
                    max={options.length - 1}
                    labels={options.map(({ name, show_order }) => ({
                        label: name,
                        disabled: !show_order
                    }))}
                    value={options.findIndex(({ option_id }) => option_id === currentSelectedOption.option_id)}
                    // Happens on any movement of the slider
                    onChange={(newOptionIndex) => {
                        if (resourceAccessor === resourceType.DISK) setIsBelowMinimumWarningShowing(options[newOptionIndex].less_than_current_usage);

                        updateSlider(resourceAccessor, newOptionIndex);
                    }}
                    // Happens only on the mouseup event after dragging the slider
                    onChangeComplete={(newOptionIndex) => {
                        // If they try to select one of the disabled options (below the new minimums), force it back to the lowest non-disabled option
                        if (newOptionIndex >= sliderMin) updateSlider(resourceAccessor, newOptionIndex, true);
                        else {
                            updateSlider(resourceAccessor, sliderMin, true);

                            if (options[newOptionIndex].less_than_current_usage) {
                                pushNotification({
                                    status: 500,
                                    details:
                                        'PLEASE NOTE: You cannot change your disk space allocation to this value. The hosting service is currently using more resources than this new allocation would allow for. Please select a higher disk space allowance before confirming any changes.'
                                });
                            } else {
                                pushNotification({
                                    status: 500,
                                    details: 'New resources must be greater than or equal to the new minimums.'
                                });
                            }
                        }
                    }}
                    tooltip={false}
                />

                {isBelowMinimumWarningShowing && resourceAccessor === 'disk' && (
                    <DialogNotification outline type="warning" className="changeResourcesForm__warning">
                        <Text bold>
                            <Text uppercase span bold>
                                Please Note:{' '}
                            </Text>
                            You cannot change your disk space allocation to this value. The hosting service is currently using more resources than
                            this new allocation would allow for. Please select a higher disk space allowance before confirming any changes.
                        </Text>
                    </DialogNotification>
                )}
            </div>
        );
    };

    const getConfirmLightboxWarningMessage = () => {
        if (custom_hosting_calculate_data?.is_billing_cycle_reset) {
            const newStartDate = custom_hosting_calculate_data?.new_period?.start_date;
            const newEndDate = custom_hosting_calculate_data?.new_period?.end_date;

            return `This change plan request will begin a new billing term for this service starting from ${newStartDate}, and ending on ${newEndDate}. You will be refunded any unused time from your current billing term and be charged for the new configuration and billing cycle.`;
        }

        const currentStartDate = custom_hosting_calculate_data?.old_period?.start_date;
        const currentEndDate = custom_hosting_calculate_data?.old_period?.end_date;

        return `This change plan request will maintain your existing billing term, which is from ${currentStartDate} and ending on ${currentEndDate}. You will be refunded any unused time from your current billing term and be charged based on the remaining time in your current billing term for the new configuration.`;
    };

    function renderSubmitButton() {
        if (custom_hosting_calculate_status === 'loading' || !custom_hosting_calculate_data || !haveConfigsChanged || isBelowMinimumWarningShowing) {
            return <InactiveButton>Continue To Price Breakdown</InactiveButton>;
        }

        return (
            <SolidButton color="primary" type="onClick" onClick={() => setIsConfirmLightboxOpen(true)}>
                Continue To Price Breakdown
            </SolidButton>
        );
    }

    /***** RENDER *****/
    if (hosting_limit_status === 'error' || hosting_resource_config_data === 'error') {
        return <FetchComponentError />;
    }

    // Don't render anything if the sliders don't have the required data yet
    if (!availableConfigOptions || !selectedConfigOptions || hosting_limit_status === 'loading')
        return <RequestLoader message="Fetching current resources" />;

    return (
        <div className="changeResourcesForm">
            {['disk', 'cpu', 'mem'].map(renderOneSlider)}

            <p className="changeResourcesForm__allocationNote">
                <b>Please note:</b> Our minimum resources for Select Hosting have increased. When changing your resources, you must select at least
                5GB Disk Space, 200% CPU and 2GB Memory.
            </p>

            <div className="changeResourcesForm__summaryContainer">
                <ChangeResourcesFormAllocations
                    rows={[
                        {
                            icon: <Icons.SSDStorage />,
                            name: 'Disk Space',
                            currentValue: hosting_resource_data?.attributes?.config_values?.disk_space?.name,
                            newValue: currentlySelectedOptions?.disk?.name
                        },
                        {
                            icon: <Icons.CPU />,
                            name: 'CPU Cores',
                            currentValue: hosting_resource_data?.attributes?.config_values?.cpu?.name,
                            newValue: currentlySelectedOptions?.cpu?.name || ''
                        },
                        {
                            icon: <Icons.Memory />,
                            name: 'Memory',
                            currentValue: hosting_resource_data?.attributes?.config_values?.memory?.name,
                            newValue: currentlySelectedOptions?.mem?.name
                        },
                        {
                            icon: <Icons.Coin />,
                            name: 'Cost',
                            currentValue: `$${hosting_information_data?.attributes?.amount}${
                                billingCycles[hosting_resource_data?.attributes?.billing_cycle]
                            }`,
                            newValue:
                                haveConfigsChanged && custom_hosting_calculate_data?.new_plan_cost
                                    ? `$${custom_hosting_calculate_data?.new_plan_cost}${billingCycles[selectedBillingCycle]}`
                                    : '-'
                        },
                        {
                            icon: <Icons.Clock />,
                            name: 'Billing Cycle',
                            currentValue: hosting_resource_data.attributes.billing_cycle,
                            newValue: selectedBillingCycle
                        },
                        {
                            icon: <Icons.Calendar />,
                            name: 'Billing Commence',
                            currentValue:
                                haveConfigsChanged && custom_hosting_calculate_data?.old_period?.start_date
                                    ? custom_hosting_calculate_data.old_period.start_date
                                    : '-',
                            newValue:
                                haveConfigsChanged && custom_hosting_calculate_data?.new_period?.start_date
                                    ? custom_hosting_calculate_data.new_period.start_date
                                    : '-'
                        }
                    ]}
                />
                <ChangeResourcesFormSummary
                    isLoading={custom_hosting_calculate_status === 'loading'}
                    selectedBillingCycle={selectedBillingCycle}
                    setSelectedBillingCycle={setSelectedBillingCycle}
                    total={custom_hosting_calculate_data?.total_due ? `$${custom_hosting_calculate_data.total_due}` : ''}
                    haveConfigsChanged={haveConfigsChanged}
                />
            </div>

            {renderSubmitButton()}

            {isConfirmLightboxOpen ? (
                <OverlayLightbox
                    title="Confirm Web Hosting Upgrade"
                    onOpen
                    loading={false}
                    onClose={() => setIsConfirmLightboxOpen(false)}
                    warningMsg={getConfirmLightboxWarningMessage()}
                >
                    {custom_hosting_change_status === 'loading' ? (
                        <RequestLoader />
                    ) : (
                        <>
                            <ChangeResourcesFormConfirmLightbox
                                selectedBillingCycle={selectedBillingCycle}
                                submitChangeRequest={() => submitChangeRequest(prepareResourcesAttributes())}
                            />
                            <OverlayLightbox.BackLink onClick={() => setIsConfirmLightboxOpen(false)} />
                        </>
                    )}
                </OverlayLightbox>
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const mapStateToProps = (/** @type {*} */ state) =>
    /**@type {const} */ ({
        hosting_information_data: state.hosting.hosting_information_data,
        hosting_resource_data: state.hosting.hosting_resource_data,
        hosting_resource_config_data: state.hosting.hosting_resource_config_data,
        custom_hosting_calculate_status: state.hosting.custom_hosting_calculate_status,
        custom_hosting_calculate_data: state.hosting.custom_hosting_calculate_data,
        custom_hosting_change_status: state.hosting.custom_hosting_change_status,

        app_viewport: state.app.app_viewport
    });

export default connect(mapStateToProps, {
    calculateCustomHostingCost
})(ChangeResourceForm);
