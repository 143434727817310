/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { fixPermissions } from '../state/securityActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class PermissionFixer extends Component {
    constructor(props) {
        super(props);
        this.startPermissionsFix = this.startPermissionsFix.bind(this);
        this.closePermissionsFixConfirm = this.closePermissionsFixConfirm.bind(this);
        this.openPermissionsFixConfirm = this.openPermissionsFixConfirm.bind(this);
        this.state = {
            showConfirmLightbox: false,
            checking: false
        };
    }

    closePermissionsFixConfirm() {
        this.setState({
            showConfirmLightbox: false
        });
    }

    openPermissionsFixConfirm() {
        this.setState({
            showConfirmLightbox: true
        });
    }

    startPermissionsFix() {
        const { hostingid, subaccount, fixPermissions } = this.props;
        fixPermissions(hostingid, subaccount);
    }

    componentDidUpdate(prevProps) {
        const { hosting_permissions_status } = this.props;
        registerScrollEvents(this, hosting_permissions_status === 'success' && prevProps.hosting_permissions_status === 'loading');

        if (hosting_permissions_status === 'success' && prevProps.hosting_permissions_status === 'loading') {
            this.setState({
                checking: true,
                showConfirmLightbox: false
            });
        }
    }

    render() {
        const { hosting_permissions_status, hosting_information_data, subaccount } = this.props;
        const { showConfirmLightbox, checking } = this.state;
        const { startPermissionsFix, openPermissionsFixConfirm } = this;
        const { attributes } = hosting_information_data;
        const { username } = attributes;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        const confirmFix = {
            desc: htmr(
                `If you have custom permissions these will need to be reset by you once this process completes. You'll receive an email upon completion.`
            ),
            buttonText: 'Reset Permissions',
            buttonAction: startPermissionsFix,
            closeText: 'Go Back',
            closeAction: this.closePermissionsFixConfirm,
            loading: hosting_permissions_status
        };

        let conditionalProps = {
            columns: [
                {
                    render: (
                        <div className="sharedBox__infoColumn hasIcon">
                            <i className="infoColumn__icon icon icon-webdav-storage notice"></i>
                            <div className="infoColumn__wrapper">
                                <div className="title">Status</div>
                                <div className="desc">Processing</div>
                            </div>
                        </div>
                    )
                },
                {
                    render: (
                        <div className="sharedBox__infoColumn">
                            <div className="title">Directory</div>
                            <div className="desc">/{subaccount ? subaccount : username}/public_html/*</div>
                        </div>
                    )
                }
            ]
        };

        if (!checking) {
            conditionalProps = {
                columns: [
                    {
                        render: (
                            <div className="sharedBox__infoColumn">
                                <div className="title">Directory</div>
                                <div className="desc">/{subaccount ? subaccount : username}/public_html/*</div>
                            </div>
                        )
                    }
                ]
            };
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="permissionFixer"
            >
                <Box
                    premounted={true}
                    className="permissionFixer__box"
                    title="Permission Fixer"
                    desc="The permission fixer will adjust every file under your public_html account. All permissions will be reset to the default Apache/PHP permissions."
                    bottom={true}
                    action={{
                        label: 'Reset',
                        type: 'onClick',
                        className: '',
                        color: '',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            openPermissionsFixConfirm();
                        }
                    }}
                    info={`
                        Using the Permission Fixer tool will set all file permissions under the public_html folder to 644 and all folder permissions under the public_html folder to be 755. Once this process has been completed, you will receive an email confirmation. 
                        <br><br>
                        If you have custom permissions these will need to be reset by you once this process completes.
                    `}
                    {...conditionalProps}
                />

                {showConfirmLightbox ? (
                    <OverlayLightbox
                        onOpen={showConfirmLightbox}
                        title="Reset Permissions"
                        warningMsg="PLEASE NOTE: This is an advanced feature and may break your website with improper use."
                        confirm={confirmFix}
                        onClose={this.closePermissionsFixConfirm}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_permissions_status: state.hosting.hosting_permissions_status,
        hosting_permissions_data: state.hosting.hosting_permissions_data,
        hosting_permissions_error: state.hosting.hosting_permissions_error,
        hosting_information_data: state.hosting.hosting_information_data,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fixPermissions
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermissionFixer));
