/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { cloneDeep, mergeWith, pick } from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { mergeWithAttributeObjectPicker } from 'containers/katana/queries/methods/mergeWithAttributeObjectPicker';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { KATANA_API } from 'utilities/api/katana';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**
 * @param {KatanaAPI.Katana.ServiceID.Preview.Section.POST.Params} params
 */
function createQueryKey({ serviceID, sectionDefinitionID }) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'preview', 'section', sectionDefinitionID]));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {KatanaAPI.Katana.ServiceID.Preview.Section.POST.Params & { enabled:boolean }} params
 */
function _useQuery(params) {
    const { serviceID } = params;
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID });

    return useQuery(
        queryOptions({
            queryKey: createQueryKey(params),
            queryFn: async ({ signal }) => {
                const attributes = pick(params, ['style', 'properties']);

                const updatedAttributes = await handleModifyEntireAttributeObject(attributes);
                const updatedParams = mergeWith(cloneDeep(params), updatedAttributes, mergeWithAttributeObjectPicker);

                return KATANA_API.katana.service_id.preview.section.POST(updatedParams, signal);
            },
            enabled: TruthKeys(params),
            select: (data) => {
                if (typeof data === 'string') {
                    return data;
                }
            }
        })
    );
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSectionPreview = Object.freeze({
    useQuery: _useQuery,
    createQueryKey
});
