/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { REDUX_FORM_BUTTON_STATE, ReduxFormButton } from 'components/Form/Button/reduxForm';
import { RenderField, RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'hostingPresetForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class HostingPreset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasService: false,
            refService: null,
            serviceList: [],
            nameservers: null,
            multipleService: false
        };

        this.checkHostingServiceData = this.checkHostingServiceData.bind(this);
    }

    checkHostingServiceData(prevProps) {
        const { dispatch, form, hosting_list_data, hosting_list_status, domain_information_data } = this.props;
        const { attributes } = domain_information_data;
        const { domain } = attributes;

        if (!((prevProps?.hosting_list_status === 'loading' || prevProps?.hosting_list_status === undefined) && hosting_list_status === 'success'))
            return;

        if (hosting_list_data) {
            const hostingService = hosting_list_data.filter((hosting) => hosting.attributes.domain === domain);

            if (hostingService.length === 1) {
                const serverInfo = hostingService[0].included.filter((include) => include.type === 'server')[0];
                if (serverInfo && serverInfo.attributes) {
                    this.setState({
                        hasService: true,
                        refService: hostingService[0],
                        nameservers: serverInfo.attributes
                    });
                } else {
                    this.setState({
                        hasService: true,
                        refService: hostingService[0]
                    });
                }

                dispatch(change(form, 'service_id', hostingService[0].id));
            } else {
                const sortedList = [
                    {
                        label: 'Please Select',
                        value: ''
                    }
                ];

                hosting_list_data.forEach((service) => {
                    //When there is an invoice, it shows first on the list so need this check to avoid undefined name
                    if (service.included[0].attributes.name) {
                        sortedList.push({
                            label: service.included[0].attributes.name + ' (' + service.attributes.domain + ')',
                            value: service.id
                        });
                    } else {
                        sortedList.push({
                            label: service.included[1].attributes.name + ' (' + service.attributes.domain + ')',
                            value: service.id
                        });
                    }
                });

                this.setState({
                    serviceList: sortedList,
                    multipleService: hostingService && hostingService.length > 1
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { checkHostingServiceData } = this;
        checkHostingServiceData(prevProps);
    }

    componentDidMount() {
        const { checkHostingServiceData } = this;
        checkHostingServiceData();
    }

    propegateServiceChange = (value) => {
        const { form, hosting_list_data } = this.props;
        this.props.dispatch(change(form, 'hosting_service', value));
        const selectedService = hosting_list_data.filter((service) => service.id === value)[0];

        if (selectedService && selectedService.included) {
            const serverInfo = selectedService.included.filter((include) => include.type === 'server')[0];
            if (serverInfo && serverInfo.attributes) {
                this.setState({
                    nameservers: serverInfo.attributes
                });
            }
        }
    };

    render() {
        const { dispatch, form, handleSubmit, hosting_list_data, domain_information_data, selectedHostingService } = this.props;
        const { hasService, refService, nameservers, multipleService } = this.state;
        const { attributes } = domain_information_data;
        const { domain } = attributes;

        const setHostingService = (e) => {
            e.preventDefault();

            let selectedService;
            hosting_list_data.forEach((service) => {
                if (service.id !== selectedHostingService) return;
                selectedService = service;
            });

            if (selectedService) {
                this.setState(
                    {
                        hasService: true,
                        refService: selectedService
                    },
                    () => {
                        dispatch(change(form, 'service_id', selectedService.id));
                    }
                );
            }
        };

        const handleNameserverRender = () => {
            const rows = Object.keys(nameservers).map((value, index) => {
                if (value === 'Server Name' || nameservers[value] === '') {
                    return '';
                }
                return (
                    <div key={index} className="domainManageSelect__table--row">
                        <div className="domainManageSelect__table--column type">{value}:</div>
                        <div className="domainManageSelect__table--column value">{nameservers[value]}</div>
                    </div>
                );
            });

            return <div className="domainManageSelect__table">{rows}</div>;
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="domainManageSelect__container" onSubmit={hasService ? handleSubmit : setHostingService}>
                {hasService ? (
                    <Fragment>
                        <Field component={RenderField} name="service_id" type="hidden" />
                        <div className="domainManageSelect__content">
                            <div className="domainManageSelect__product">
                                {refService && refService.included ? refService.included[0].attributes.name : 'Domain Name'}
                            </div>
                            <div className="domainManageSelect__service">{domain}</div>
                            {nameservers ? handleNameserverRender() : ''}
                        </div>
                        <div className={`domainManageSelect__action${!nameservers ? `` : ` noTable`}`}>
                            <ReduxFormButton form={formName} force={REDUX_FORM_BUTTON_STATE.ACTIVE}>
                                Connect to {refService?.included?.[0]?.attributes.name ?? ''} service
                            </ReduxFormButton>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="domainManageSelect__content hasInput">
                            <div className="domainManageSelect__product">
                                {multipleService
                                    ? 'You have multiple services with the same domain name.'
                                    : "We couldn't find any services that match your domain name"}
                            </div>
                            <div className="domainManageSelect__service">Please select the service you want to connect to below.</div>
                            <div className="domainManageSelect__input">
                                <Field
                                    name="hosting_service"
                                    component={RenderSelectField}
                                    type="select"
                                    validate={[requiredFieldValidation]}
                                    propegateChange={this.propegateServiceChange}
                                    options={this.state.serviceList}
                                />
                            </div>
                        </div>
                        <ReduxFormButton className="domainManageSelect__action hasInput center" form={formName}>
                            Continue
                        </ReduxFormButton>
                    </Fragment>
                )}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
HostingPreset = reduxForm({
    form: formName
})(HostingPreset);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const selectedHostingService = selector(state, 'hosting_service');

    return {
        hosting_list_status: state.services.hosting_list_status,
        hosting_list_data: state.services.hosting_list_data,
        domain_information_data: state.domain.domain_information_data,
        domain_update_current_configuration_status: state.domain.domain_update_current_configuration_status,
        domain_update_current_configuration_data: state.domain.domain_update_current_configuration_data,
        domain_update_current_configuration_error: state.domain.domain_update_current_configuration_error,
        form: formName,
        selectedHostingService
    };
};

export default connect(mapStateToProps)(HostingPreset);
