/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Text as Text } from 'components/Utils/Text/_Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TextPropTypes } from './_Text/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _TableContent(props) {
    return <Text size--s secondary {...props} />;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

_TableContent.propTypes = {
    /**
     * Still can use Text Props to apply extra styling
     */
    ...TextPropTypes
};

export { _TableContent };
