/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TicketForm from '../forms/ticketForm';
import AboutExistingService from '../modules/ticketAboutExistingService';
import TicketDepartmentSelect from '../modules/ticketDepartmentSelect';
import TicketFeedbackSurvey from '../modules/ticketFeedbackSurvey';
import TicketHome from '../modules/ticketHome';
import Reopen from '../modules/ticketReopen';
import TicketView from '../modules/ticketView';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { getDepartmentPretty, truncateSimple } from 'utilities/methods/commonActions';
import { closeTicket, getAllServices, getOpenTickets, getResolvedSurvey, getSupportQuestionList } from '../action';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useReplyEticketMutation } from '../queries/useReplyToEticketMutation';
import { useSubmitNewTicketMutation } from '../queries/useSubmitNewTicketMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleReadFiles } from 'components/Form/NXDropZone/utilities';
import { getVpsType, vpsTypes } from 'containers/vps/methods';
import { DateTime } from 'luxon';
import useSearchParams from 'utilities/hooks/useSearchParams';
import { getFlatServicesArray, getTicketServiceInfo } from '../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function Tickets({
    match: {
        params: { section, param4, param5 }
    },
    history,

    // redux state
    support_tickets_close_status,
    support_ticket_update_feedback_status_status,
    support_ticket_wait_time_data,
    support_individual_ticket_meta,
    app_user_data,
    support_all_services_data,
    getAllServices,
    closeTicket,
    getOpenTickets,
    getResolvedSurvey,
    getSupportQuestionList
}) {
    /***** STATE *****/
    const [showTicketLightbox, setShowTicketLightbox] = useState(false);
    const [lightboxTemplate, setLightboxTemplate] = useState(null);
    const [lightboxMeta, setLightboxMeta] = useState(undefined);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    /***** HOOKS *****/
    const { searchParamsGet } = useSearchParams();

    const invoiceId = searchParamsGet('invoice');
    const serviceId = searchParamsGet('service');
    const hasSelectedInvoice = invoiceId && !['na', 'none'].includes(invoiceId);
    const hasSelectedService = serviceId && !['na', 'none'].includes(serviceId);
    const subaccountUser = searchParamsGet('subaccount-user');
    const subaccountDomain = searchParamsGet('subaccount-domain');
    const referencedTicketMask = searchParamsGet('referenced-ticket');
    const isReferencedTicket = referencedTicketMask && support_individual_ticket_meta && support_individual_ticket_meta.mask === referencedTicketMask;

    /***** QUERIES *****/
    const { mutateAsync: submitTicketResponseAsync, isPending: isTicketResponsePending, isError: isTicketResponseError } = useReplyEticketMutation();
    const { mutateAsync: submitNewTicketAsync, isPending: isSubmitNewTicketPending, isError: isSubmitNewTicketError } = useSubmitNewTicketMutation();

    /***** FUNCTIONS *****/
    function toggleLightboxTemplate(template, meta) {
        setLightboxMeta(meta);
        setShowTicketLightbox((prev) => !prev);
        setLightboxTemplate(template || null);
    }

    const handleSubmitTicket = async (values) => {
        const flatServicesArray = getFlatServicesArray(support_all_services_data);
        const selectedServiceInfo = getTicketServiceInfo(flatServicesArray.find((service) => service.id === Number(serviceId)));
        const attachments = await handleReadFiles(values.file);

        if (!attachments) {
            // do not continue, reading files encountered an issue and has sent a toast notification to user
            return;
        }

        let department = param4;
        switch (param4) {
            case 'technical-support':
                department = 'support';
                break;
            case 'accounts-billing':
                department = 'account';
                break;
            default:
                break;
        }

        const attributes = {
            ...values,
            is_existing_invoice: !!hasSelectedInvoice,
            is_existing_service: !!hasSelectedService,
            department: department === 'customer-care' ? param5 : department,
            attachments,
            first_time_notice: DateTime.fromJSDate(values.first_time_notice).toFormat('dd-MM-yyyy')
        };

        if (department === 'support' && typeof app_user_data.is_technical === 'boolean') attributes.is_technical = app_user_data.is_technical;

        if (hasSelectedInvoice) {
            attributes.invoice_id = Number(invoiceId);
        }

        if (selectedServiceInfo) {
            const { productType } = selectedServiceInfo;
            if (productType === 'vps') {
                const vpsType = getVpsType(selectedServiceInfo.product);

                if (vpsType === vpsTypes.SELF_MANAGED) attributes.service_type = vpsTypes.SELF_MANAGED;
                else if (vpsType === vpsTypes.FULLY_MANAGED) attributes.service_type = vpsTypes.FULLY_MANAGED;
                else attributes.service_type = vpsTypes.VPS;
            } else if (productType === 'microsoft365') {
                attributes.service_type = 'email-hosting';
            } else {
                attributes.service_type = productType;
            }
            attributes.service_id = selectedServiceInfo.id;

            if (subaccountUser && subaccountDomain) {
                attributes.subaccount_name = subaccountUser;
                attributes.subaccount_domain = subaccountDomain;
            }
        }

        if (isReferencedTicket) {
            attributes.subject = `[Reopened from ${support_individual_ticket_meta.mask}] ${attributes.subject}`;
        }

        if (attributes.is_urgent) {
            attributes.urgent_price = support_ticket_wait_time_data?.attributes?.support?.urgent_price?.authentication;
            setHasSubmitted(false);
        }

        if (!attributes.is_urgent) {
            try {
                await submitNewTicketAsync(attributes);
            } catch (e) {
                return setShowTicketLightbox(null);
            }
        }

        toggleLightboxTemplate('Completion', {
            data: {
                attributes,
                displayedPrice: support_ticket_wait_time_data?.attributes?.support?.urgent_price?.value
            },
            reply: false
        });
    };

    const handleSubmitReply = async (values, department) => {
        const attachments = await handleReadFiles(values.file);

        if (!attachments) {
            // do not continue, reading files encountered an issue and has sent a toast notification to user
            return;
        }

        const attributes = {
            ...values,
            attachments
        };

        if (attributes.is_urgent) {
            attributes.urgent_price = support_ticket_wait_time_data?.attributes?.support?.urgent_price?.authentication;
            setHasSubmitted(false);
        }

        if (!attributes.is_urgent) {
            try {
                await submitTicketResponseAsync({
                    id: param4,
                    attributes
                });
            } catch (e) {
                return setShowTicketLightbox(false);
            }
        }

        toggleLightboxTemplate('Completion', {
            data: {
                id: param4,
                attributes,
                displayedPrice: support_ticket_wait_time_data?.attributes?.support?.urgent_price?.value,
                department
            },
            reply: true
        });
    };

    function markTicketResolved(id) {
        closeTicket(id);
    }

    function resetEticketState() {
        setShowTicketLightbox(false);
        setLightboxTemplate(null);
        setLightboxMeta(undefined);
    }

    /***** EFFECTS *****/
    // Mount
    useEffect(() => {
        getAllServices();
        getSupportQuestionList();
    }, []);

    // Ticket close success or error
    useEffect(() => {
        if (!['success', 'error'].includes(support_tickets_close_status)) return;

        setShowTicketLightbox(false);
        setLightboxTemplate(null);
        setLightboxMeta(undefined);

        if (param4) history.push('/support/tickets');
        else {
            getOpenTickets();
            getResolvedSurvey();
        }
    }, [support_tickets_close_status]);

    // Ticket feedback status success or error
    useEffect(() => {
        if (['success', 'error'].includes(support_tickets_close_status)) getResolvedSurvey();
    }, [support_ticket_update_feedback_status_status]);

    useEffect(() => {
        if (isTicketResponseError || isSubmitNewTicketError) {
            setShowTicketLightbox(false);
        }
    }, [isTicketResponseError, isSubmitNewTicketError]);

    /***** RENDER HELPERS *****/
    const renderHeader = () => {
        return (
            <div className="ticketServices__header">
                <div className="ticketServices__heading">New {getDepartmentPretty(param4)} eTicket</div>
                {isReferencedTicket ? (
                    <div className="ticketServices__referenced">
                        <div className="ticketServices__referencedSubject">
                            Referenced eTicket: {truncateSimple(support_individual_ticket_meta.subject, 50, '...')}
                        </div>
                        <div className="ticketServices__circle"></div>
                        <div className="ticketServices__referencedMask">{support_individual_ticket_meta.mask}</div>
                    </div>
                ) : (
                    ''
                )}
                <button className="ticketServices__backLink" onClick={(e) => history.push('/support/tickets/submit')}>
                    <span className="icon icon-arrow-left"></span>
                </button>
            </div>
        );
    };

    const renderSection = () => {
        if (!section) return <TicketHome toggleLightboxTemplate={toggleLightboxTemplate} />;

        if (section === 'reopen') return <Reopen />;

        if (section === 'view') return <TicketView handleSubmitReply={handleSubmitReply} toggleLightboxTemplate={toggleLightboxTemplate} />;
        // section must now be "submit"

        // If no department selected, show "department select"
        if (!param4) return <TicketDepartmentSelect />;

        // If both "invoice" and "service" are set to something (can be "select", "none", "NA" or a valid ID) in the query params, show the form.
        if (invoiceId && serviceId && serviceId !== 'select') return <TicketForm handleSubmit={handleSubmitTicket} />;

        // If we're not showing "department select" or "form", then we must be showing "about existing service" page
        return <AboutExistingService />;
    };

    const renderLightbox = () => {
        switch (lightboxTemplate) {
            case 'Issues':
                return (
                    <OverlayLightbox
                        className="ticketsStillIssues"
                        onOpen={true}
                        title="Create new eTicket in reference to this one?"
                        loading="success"
                        color="primary"
                        onClose={toggleLightboxTemplate}
                        confirm={{
                            desc: (
                                <>
                                    <div className="ticketsStillIssues__id">Ticket ID: {lightboxMeta.id}</div>
                                    <div className="ticketsStillIssues__subject">{lightboxMeta.subject}</div>
                                </>
                            ),
                            buttonText: 'Open new eTicket',
                            buttonAction: () => {
                                history.push(`/support/tickets/reopen/${lightboxMeta.id}`);
                                toggleLightboxTemplate();
                            },
                            closeText: 'No Thanks',
                            closeAction: toggleLightboxTemplate
                        }}
                    />
                );

            case 'Resolve':
                return (
                    <OverlayLightbox
                        className="ticketsMarkResolved"
                        onOpen={true}
                        title="Close this eTicket?"
                        loading={support_tickets_close_status}
                        color="error"
                        onClose={toggleLightboxTemplate}
                        confirm={{
                            desc: 'Are you sure you want to close this eTicket? You will no longer be able to submit any more replies here.',
                            buttonText: 'Close this eTicket',
                            buttonAction: () => markTicketResolved(lightboxMeta.id),
                            closeText: 'Keep this eTicket Open',
                            closeAction: toggleLightboxTemplate
                        }}
                    />
                );

            case 'Completion': {
                const getLoadingState = () => {
                    if (isTicketResponsePending || isSubmitNewTicketPending) return 'loading';
                    if (isTicketResponseError || isSubmitNewTicketError) return 'error';
                    return 'success';
                };
                const handleUrgentSubmit = async () => {
                    const submit = lightboxMeta?.reply
                        ? async () => submitTicketResponseAsync({ id: lightboxMeta.data.id, attributes: lightboxMeta.data.attributes })
                        : async () => submitNewTicketAsync(lightboxMeta.data.attributes);
                    await submit()
                        .catch(() => void 0)
                        .finally(() => setHasSubmitted(true));
                };

                return lightboxMeta?.data?.attributes?.is_urgent && !hasSubmitted ? (
                    <OverlayLightbox
                        className="ticketsSuccessLightbox"
                        onOpen={true}
                        loading={getLoadingState()}
                        confirm={{
                            desc: (
                                <div className="desc">
                                    <div className="confirmTitle">Confirm lodgement of priority eTicket</div>
                                    <div className="confirmMessage">
                                        This eTicket will be bumped to the top of the queue and investigated immediately, and will invoice you for $
                                        {lightboxMeta?.data?.displayedPrice || '4.95'}.
                                    </div>
                                </div>
                            ),
                            buttonText: 'Confirm',
                            buttonAction: handleUrgentSubmit,
                            closeText: 'No, Go Back',
                            closeAction: toggleLightboxTemplate
                        }}
                        onClose={toggleLightboxTemplate}
                    />
                ) : (
                    <OverlayLightbox
                        className="ticketsSuccessLightbox"
                        onOpen={true}
                        loading={getLoadingState()}
                        confirm={{
                            desc:
                                getLoadingState() === 'success' ? (
                                    <div className="desc">
                                        <i className="icon icon-valid"></i>
                                        <div className="successTitle">Success!</div>
                                        <div className="successMessage">
                                            Our {lightboxMeta?.data?.department || getDepartmentPretty(param4)} team will respond to you shortly.
                                        </div>
                                        <br />
                                        <Text>
                                            Please Note: It may take a few minutes for your eTicket to be visible from within your {application}{' '}
                                            account
                                        </Text>
                                    </div>
                                ) : (
                                    <FetchComponentError />
                                ),
                            buttonText: 'Back to eTickets',
                            buttonAction: () => {
                                history.push('/support/tickets');
                                resetEticketState();
                            }
                        }}
                        onClose={() => {
                            history.push('/support/tickets');
                            resetEticketState();
                        }}
                    />
                );
            }
            case 'Survey':
                return (
                    <OverlayLightbox className="ticketSurvey__lightbox" onOpen={true} onClose={toggleLightboxTemplate}>
                        <TicketFeedbackSurvey toggleLightboxTemplate={toggleLightboxTemplate} lightboxMeta={lightboxMeta} />
                    </OverlayLightbox>
                );

            default:
                break;
        }
    };

    /***** RENDER *****/
    return (
        <div className="tickets">
            {section && !['reopen', 'view'].includes(section) && param4 ? renderHeader() : ''}
            {renderSection()}
            {showTicketLightbox && renderLightbox()}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            app_user_data: state.app.app_user_data,
            support_tickets_close_status: state.support.support_tickets_close_status,
            support_ticket_update_feedback_status_status: state.support.support_ticket_update_feedback_status_status,
            support_ticket_wait_time_data: state.support.support_ticket_wait_time_data,
            support_all_services_data: state.support.support_all_services_data,
            support_individual_ticket_meta: state.support.support_individual_ticket_meta
        }),
        {
            getAllServices,
            closeTicket,
            getOpenTickets,
            getResolvedSurvey,
            getSupportQuestionList
        }
    )(Tickets)
);
