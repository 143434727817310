/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import Tooltip from 'components/Tooltip';
import Progress from 'components/Progress';
import Anchor from 'components/Anchor';

/*   ACTIONS
 *****************************************************/
import { getSolusVPSInfo, getVPSAddons } from 'containers/vps/action';
import { processUsageStats, getCurrentDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ResourceUsageTable extends Component {
    constructor(props) {
        super(props);
        this.renderRow = this.renderRow.bind(this);
    }

    renderRow() {
        const { list } = this.props;

        if (!Array.isArray(list)) return '';

        return list.map((item, index) => (
            <div key={index} className={`VPS-Resource-Usage-Table__row${index % 2 === 0 ? ' VPS-Resource-Usage-Table__row--even' : ''}`}>
                <div className="VPS-Resource-Usage-Table__column VPS-Resource-Usage-Table__column--label">{item.label}</div>
                <div className="VPS-Resource-Usage-Table__column VPS-Resource-Usage-Table__column--value">{item.value}</div>
            </div>
        ));
    }

    render() {
        const { className, heading } = this.props;
        const { renderRow } = this;

        return (
            <div className={`VPS-Resource-Usage-Table${className ? ' ' + className : ''}`}>
                <div className="VPS-Resource-Usage-Table__heading">{heading}</div>
                <div className="VPS-Resource-Usage-Table__body">{renderRow()}</div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ResourceUsage extends Component {
    constructor(props) {
        super(props);
        this.renderBoxContent = this.renderBoxContent.bind(this);
        this.renderAdditionalIPAddreses = this.renderAdditionalIPAddreses.bind(this);
        this.renderMore = this.renderMore.bind(this);
        this.getFetchDate = this.getFetchDate.bind(this);
        this.renderOpenMore = this.renderOpenMore.bind(this);
        this.state = {
            isOpenMore: false,
            fetch_date: null
        };
    }

    getFetchDate() {
        const date = getCurrentDate(true).toFormat('cccc, MMMM d, yyyy t');
        this.setState({
            fetch_date: date
        });
    }

    componentDidMount() {
        const { getFetchDate } = this;

        getFetchDate();
    }

    componentDidUpdate(prevProps) {
        const { vps_solus_information_status } = this.props;
        const { getFetchDate } = this;

        if (vps_solus_information_status === 'success' && prevProps.vps_solus_information_status === 'loading') {
            getFetchDate();
        }
    }

    renderAdditionalIPAddreses() {
        const { vps_solus_information_data } = this.props;
        const { attributes } = vps_solus_information_data;
        const { ipaddresses, ipv6subnets, mainipaddress } = attributes;
        let all_ips = [];

        if (ipaddresses && ipaddresses.length > 0) {
            const additionalIps = ipaddresses.filter((item) => item !== mainipaddress);

            if (additionalIps && additionalIps.length > 0) {
                all_ips = all_ips.concat(additionalIps);
            }
        }

        if (ipv6subnets && ipv6subnets.length > 0) {
            all_ips = all_ips.concat(ipv6subnets);
        }

        if (all_ips && all_ips.length > 0) {
            return all_ips.map((item, index) => (
                <div key={index} className="list-item">
                    {item}
                </div>
            ));
        } else {
            return '';
        }
    }

    renderMore() {
        const { vps_addon_list_status, vps_addon_list_data, vps_solus_information_data } = this.props;
        const { attributes } = vps_solus_information_data;
        const { state, operatingsystem, trafficgraph, hddgraph } = attributes;
        const { isOpenMore } = this.state;

        if (isOpenMore) {
            const renderResourceAllocation = () => {
                if (vps_addon_list_status === 'success' && vps_addon_list_data) {
                    const { attributes } = vps_addon_list_data;
                    const { hardware } = attributes;

                    if (hardware) {
                        const { totals } = hardware;
                        const { bandwidth, cpu, disk, ram } = totals;

                        return (
                            <ResourceUsageTable
                                heading="Resource Allocation"
                                list={[
                                    {
                                        label: 'Bandwidth',
                                        value: bandwidth + 'GB'
                                    },
                                    {
                                        label: 'CPU',
                                        value: cpu + ' Cores'
                                    },
                                    {
                                        label: 'Disk',
                                        value: disk + 'GB'
                                    },
                                    {
                                        label: 'Memory',
                                        value: ram + 'GB'
                                    }
                                ]}
                            />
                        );
                    } else {
                        return '';
                    }
                }
            };

            return (
                <div className="VPS-Resource-Usage__more-details">
                    <ResourceUsageTable
                        heading="Server Details"
                        list={[
                            {
                                label: 'Server Status',
                                value: state
                            },
                            {
                                label: 'Operating System',
                                value: operatingsystem
                            }
                        ]}
                    />
                    {renderResourceAllocation()}
                    {trafficgraph && (
                        <div className="VPS-Resource-Usage__graph">
                            <div className="VPS-Resource-Usage__graph-heading">Bandwidth Usage</div>
                            <div className="VPS-Resource-Usage__graph-image">
                                <img src={trafficgraph} alt="Bandwidth Traffic" />
                            </div>
                        </div>
                    )}
                    {hddgraph && (
                        <div className="VPS-Resource-Usage__graph">
                            <div className="VPS-Resource-Usage__graph-heading">Disk Usage</div>
                            <div className="VPS-Resource-Usage__graph-image">
                                <img src={hddgraph} alt="Disk Traffic" />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return '';
        }
    }

    renderBoxContent() {
        const { getSolusVPSInfo, getVPSAddons, vpsid, vps_solus_information_data } = this.props;
        const { renderAdditionalIPAddreses, renderMore } = this;
        const { fetch_date } = this.state;

        if (vps_solus_information_data) {
            const { attributes } = vps_solus_information_data;
            const {
                bandwidth: { used, total },
                mainipaddress
            } = attributes;

            const { disk } = processUsageStats;

            return (
                <div className="VPS-Resource-Usage">
                    <div className="VPS-Resource-Usage__wrapper">
                        <div className="box__heading VPS-Resource-Usage__heading">
                            Resource Usage
                            <div className="error__refresh VPS-Resource-Usage__reload">
                                <Anchor
                                    onClick={() => {
                                        getSolusVPSInfo(vpsid);
                                        getVPSAddons(vpsid);
                                    }}
                                >
                                    <i className="icon icon-reload" />
                                </Anchor>
                            </div>
                        </div>
                        <Progress
                            className="VPS-Resource-Bar"
                            title="Bandwidth"
                            max={disk.max(total)}
                            value={disk.value(used)}
                            valueTitle={disk.valueTitle(used)}
                            maxTitle={disk.maxTitle(total)}
                        />
                        <div className="VPS-Resource-Usage__cta">
                            <a className="VPS-Resource-Usage__cta-link" href={`../../addons/resource-addons/${vpsid}`}>
                                Need more resources?
                            </a>
                            <div className="VPS-Resource-Usage__cta-date">
                                {`Data is retrieved as of ${fetch_date}`}
                                <Tooltip
                                    className="VPS-Resource-Usage__cta-tooltip"
                                    info="Your bandwidth usage resets on the 1st of each calendar month. If you believe you will run out of bandwidth before then, please contact our technical support team."
                                />
                            </div>
                        </div>
                        <ResourceUsageTable
                            heading="IP Addreses"
                            list={[
                                {
                                    label: 'Primary IP Address',
                                    value: mainipaddress
                                },
                                {
                                    label: 'Additional IP Addresses',
                                    value: renderAdditionalIPAddreses()
                                }
                            ]}
                        />
                        {renderMore()}
                    </div>
                </div>
            );
        }
    }

    renderOpenMore(e) {
        e.preventDefault();
        const { isOpenMore } = this.state;
        this.setState({
            isOpenMore: !isOpenMore
        });
    }

    render() {
        const { renderBoxContent, renderOpenMore } = this;
        const { vps_solus_information_status } = this.props;
        const { isOpenMore } = this.state;

        return (
            <Box
                custom={{
                    pos: 'top',
                    render: renderBoxContent()
                }}
                status={vps_solus_information_status}
                className="VPS-Resource-Usage"
                footer={
                    <Anchor className="VPS-Resource-Usage__more" onClick={renderOpenMore}>
                        {isOpenMore ? 'Hide' : 'View'} Full Service Details
                    </Anchor>
                }
                premounted={true}
            />
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default connect(
    (state) => ({
        vps_solus_information_data: state.vps.vps_solus_information_data,
        vps_solus_information_status: state.vps.vps_solus_information_status,
        vps_addon_list_data: state.vps.vps_addon_list_data,
        vps_addon_list_status: state.vps.vps_addon_list_status
    }),
    {
        getSolusVPSInfo,
        getVPSAddons
    }
)(ResourceUsage);
