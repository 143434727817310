/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import {
    RenderField,
    emailFieldValidation,
    emailPrefixValidation,
    renderButton,
    renderCheckboxField,
    requiredFieldValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Form for adding Aliases to a domain
 */
let AliasesForm = ({
    pristine,
    submitting,
    valid,
    handleSubmit,
    domain,
    email_aliases_list_data,
    dispatch,
    form,
    alias,
    mode,

    /* Redux Form Value */
    catchAll
}) => {
    /***** EFFECTS *****/
    useEffect(() => {
        if (mode !== 'edit') return;

        const selectedAlias = email_aliases_list_data[alias].attributes;

        Object.entries(selectedAlias).forEach(([key, value]) => {
            if (key === 'alias_name' && !value.startsWith('*')) {
                change(form, 'alias_from', value)(dispatch);
            } else if (key === 'alias_to') {
                change(form, 'alias_to', value)(dispatch);
            } else if (value.startsWith('*')) {
                change(form, 'catchAll', true)(dispatch);
            }
        });
    }, []);

    /***** RENDER *****/
    return (
        <form className="emailAliases__form" onSubmit={handleSubmit}>
            {catchAll ? null : (
                <div className="form__row">
                    <div className="form__column full ext__label">
                        <Field
                            label="Catch Address"
                            name="alias_from"
                            component={RenderField}
                            type="text"
                            append={{
                                type: `email`,
                                text: `@${domain}`
                            }}
                            validate={[requiredFieldValidation, emailPrefixValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
            )}
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Deliver To"
                        name="alias_to"
                        component={RenderField}
                        type="email"
                        placeholder="example@example-email.com"
                        validate={[requiredFieldValidation, emailFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="confirmation__container">
                <div className="confirmation__input">
                    <Field name="catchAll" label="Setup as a Catch All" component={renderCheckboxField} type="checkbox" className="form__textfield" />
                </div>
                <Tooltip
                    info={`A Catch All address means all emails sent to this domain will be forwarded to the 'Deliver To' email address provided.`}
                />
            </div>
            <div className="form__row">
                <div className="form__column full">{renderButton(pristine, submitting, valid, 'Save Alias')}</div>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
AliasesForm = reduxForm({
    form: 'aliasForm',
    enableReinitialize: true
})(AliasesForm);

const mapStateToProps = (state) => ({
    email_aliases_list_data: state.email.email_aliases_list_data,
    form: 'aliasForm',
    catchAll: formValueSelector('aliasForm')(state, 'catchAll')
});

AliasesForm = connect(mapStateToProps)(AliasesForm);

export default withRouter(AliasesForm);
