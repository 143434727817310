/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ventraSynMigrationHalfPriceEndDate } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Accordion from 'components/Accordion';
import Anchor from 'components/Anchor';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { TextArea } from 'components/Form/TextArea';
import RequestLoader from 'components/Loaders/Request';
import SolidTag from 'components/Tags/SolidTag';
import Tooltip from 'components/Tooltip';
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/*   ACTIONS
 *****************************************************/
import { getMigrationDates, getPciComplianceCsv } from 'containers/ventraSynMigration/action';
import { vsmMethods } from 'containers/ventraSynMigration/methods';
import { getCurrentDate, pluralize, roundUpToNearestCent, scrollToTop, toLuxonDate } from 'utilities/methods/commonActions';
import {
    ControlledCheckbox,
    RenderSelectField,
    renderButton,
    renderCheckboxField,
    requiredAcceptedValidation,
    requiredFieldValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ConfirmMigrationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentKeyword: '',
            selectAll: false,
            openEmailsAccordion: null
        };

        this.migrationTime = '12am and 8am';

        this.monitorKeyword = this.monitorKeyword.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.filterSubaccounts = this.filterSubaccounts.bind(this);
        this.setShowFullList = this.setShowFullList.bind(this);
        this.setOpenEmailsAccordion = this.setOpenEmailsAccordion.bind(this);
    }

    monitorKeyword(keyword = '') {
        this.setState({
            currentKeyword: keyword
        });
    }

    toggleSelectAll(cb, arg) {
        this.setState(
            {
                selectAll: !this.state.selectAll
            },
            cb(arg)
        );
    }

    filterSubaccounts(data) {
        const { currentKeyword } = this.state;

        return data.filter((account) => account?.info?.domain?.includes(currentKeyword));
    }

    setShowFullList() {
        this.setState({
            showFullList: true
        });
    }

    setOpenEmailsAccordion(subaccountId = null) {
        this.setState({
            openEmailsAccordion: subaccountId
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getMigrationDates, vsm_migration_dates_data } = this.props;

        scrollToTop();

        if (!vsm_migration_dates_data) getMigrationDates();
    }

    render() {
        const {
            handleSubmit,
            formValues,
            pristine,
            submitting,
            valid,
            vsm_preview_status,
            vsm_preview_data,
            vsm_migrate_status,
            accounts,
            history,
            updateAccounts,
            submitButtonText,
            formatServices,
            getPciComplianceCsv,
            vsm_migration_dates_status,
            vsm_migration_dates_data
        } = this.props;
        const { currentKeyword, selectAll, showFullList, openEmailsAccordion } = this.state;
        const { monitorKeyword, toggleSelectAll, filterSubaccounts, setShowFullList, migrationTime, setOpenEmailsAccordion } = this;

        const renderSearch = () => {
            return (
                <div className="search__container">
                    <div className="search__form">
                        <input
                            type="text"
                            onChange={(e) => monitorKeyword(e.target.value)}
                            value={currentKeyword}
                            placeholder="Search for a sub account"
                        />
                        {currentKeyword ? (
                            <button type="onclick" onClick={(e) => monitorKeyword()}>
                                <SolidTag color="info">Clear</SolidTag>
                            </button>
                        ) : (
                            <button type="submit">
                                <span className="icon icon-search"></span>
                            </button>
                        )}
                    </div>
                </div>
            );
        };

        const renderSubaccountsList = (data) => {
            if (currentKeyword && data.length <= 0)
                return <div className="vsConfirmForm__domainRow vsConfirmForm__domainRow--noResults">No subaccounts matched your search</div>;

            return data
                .sort((a, b) => {
                    if (a.info.manageable_domain === b.info.manageable_domain) return 0;
                    if (!a.info.manageable_domain && b.info.manageable_domain) return 1;
                    return -1;
                })
                .map((account, index) => {
                    const {
                        update_dns,
                        info: { domain, manageable_domain, pci_compliance }
                    } = account;

                    return (
                        <div key={index} className="vsConfirmForm__domainRow">
                            <ControlledCheckbox
                                label={domain}
                                disabled={!manageable_domain}
                                checked={update_dns}
                                onClick={() => {
                                    const newList = [...accounts];
                                    newList.splice(accounts.indexOf(account), 1, { ...account, update_dns: !update_dns });

                                    if (selectAll) toggleSelectAll(updateAccounts, newList);
                                    else updateAccounts(newList);
                                }}
                            />
                            <div className="vsSelectForm__tagContainer">
                                {manageable_domain ? (
                                    <SolidTag color="confirm">automated dns update available</SolidTag>
                                ) : (
                                    <SolidTag color="secondary">manual dns update required</SolidTag>
                                )}
                                {!pci_compliance.is_compliant && <SolidTag color="warn">email issues detected</SolidTag>}
                            </div>
                        </div>
                    );
                });
        };

        const renderEmailList = (emails) => {
            return (
                <div className="vsConfirmForm__emails">
                    <p className="vsConfirmForm__emailsIntro">
                        The following email accounts within this cPanel account have been flagged as having PCI compliance issues. Further details of
                        how to resolve these issues are provided in the downloadable CSV file.
                    </p>
                    <ul className="vsConfirmForm__emailsList">
                        {emails.map((email, index) => (
                            <li key={index} className="vsConfirmForm__emailAddress">
                                {email.address}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        };

        const renderEmailEncryptionSection = () => {
            const affectedAccounts = accounts
                .filter((account) => !account.info.pci_compliance.is_compliant)
                .sort((a, b) => {
                    if (a.info.manageable_domain === b.info.manageable_domain) return 0;
                    if (!a.info.manageable_domain && b.info.manageable_domain) return 1;
                    return -1;
                });

            return affectedAccounts.length > 0 ? (
                <>
                    <div className="vsSelectForm__infoBox">
                        <div className="vsSelectForm__infoBoxBg vsSelectForm__infoBoxBg--warn" />
                        <div className="vsSelectForm__infoBoxContent">
                            <SolidTag color="warn">email issues detected</SolidTag>
                            <p className="vsSelectForm__infoBoxText">
                                Our system has detected one or more email addresses within your cPanel account using mail connections that need
                                attention to move to Synergy Wholesale. Hosting services at Synergy Wholesale are PCI compliant to ensure our partners
                                and their clients have a secure online environment.
                                <br />
                                <br />
                                Email accounts using IMAP and POP3 will require to be connected via at least TLS 1.2 to remain functional using
                                third-party mail clients. You can retrieve a list of the detected email addresses followed by what action needs to be
                                taken via the CSV below.
                                <br />
                                <br />
                                Please download the CSV below and{' '}
                                <Anchor
                                    href="https://synergywholesale.com/faq/article/resolving-email-issues-detected-for-your-ventraip-reseller-service/"
                                    target="_blank"
                                >
                                    follow this guide to resolve these issues.
                                </Anchor>
                            </p>
                        </div>
                    </div>
                    <div className="vsConfirmForm__emailsSection">
                        <div className="vsConfirmForm__emailsHeader">
                            <h3 className="vsConfirmForm__emailsHeading">Accounts with email issues</h3>
                            <Anchor type="onClick" onClick={() => getPciComplianceCsv(formatServices())}>
                                Download CSV file of affected email accounts
                            </Anchor>
                        </div>
                        {affectedAccounts.map((account, index) => (
                            <Accordion
                                key={index}
                                title={
                                    <>
                                        <div className="vsConfirmForm__accordionTitleDomain">{account.info.domain}</div>
                                        <div className="vsConfirmForm__accordionTitleEmails">{`${
                                            account.info.pci_compliance.emails.length
                                        } affected email account${pluralize(account.info.pci_compliance.emails.length)}`}</div>
                                    </>
                                }
                                content={renderEmailList(account.info.pci_compliance.emails)}
                                controlled={{
                                    active: openEmailsAccordion === index,
                                    toggle: () => setOpenEmailsAccordion(openEmailsAccordion === index ? null : index)
                                }}
                            />
                        ))}
                    </div>
                </>
            ) : (
                ''
            );
        };

        const renderStepOne = () => {
            return (
                <div className="vsConfirmForm__step">
                    <div className="vsConfirmForm__stepContent">
                        <div className="vsConfirmForm__stepTitle">
                            <span>Step 1.</span>
                            Select the domains you would like us to update the DNS on after the migration is complete.
                        </div>
                        {renderSearch()}
                        <div className="vsConfirmForm__allDomainsWrapper">
                            <ControlledCheckbox
                                label={
                                    <>
                                        <div className="vsConfirmForm__allSubAccLabel">Select all</div>
                                        <div className="vsConfirmForm__numSubAccounts">
                                            ({accounts.filter((account) => account.info.manageable_domain).length} in total)
                                        </div>
                                    </>
                                }
                                disabled={accounts.filter((account) => account.info.manageable_domain).length < 1}
                                checked={selectAll}
                                onClick={() => {
                                    const newList = [...accounts].map((account) =>
                                        account.info.manageable_domain ? { ...account, update_dns: !selectAll } : account
                                    );
                                    toggleSelectAll(updateAccounts, newList);
                                }}
                            />
                        </div>
                        <div className="vsConfirmForm__domainContainer">
                            {renderSubaccountsList(currentKeyword ? filterSubaccounts(accounts) : accounts)}
                        </div>
                        <h4 className="vsConfirmForm__infoHeading">Information</h4>
                        <div className="vsConfirmForm__infoBox">
                            <div className="vsConfirmForm__infoBoxBg vsConfirmForm__infoBoxBg--confirm" />
                            <div className="vsConfirmForm__infoBoxContent">
                                <SolidTag color="confirm">automated dns update available</SolidTag>
                                <p className="vsConfirmForm__infoBoxText">
                                    This domain name points to the service you have selected to migrate and is within your VentraIP or your Synergy
                                    Wholesale account. With your permission, we can automatically migrate the account and update the DNS for you.
                                </p>
                            </div>
                        </div>
                        <div className="vsConfirmForm__infoBox">
                            <div className="vsConfirmForm__infoBoxBg vsConfirmForm__infoBoxBg--secondary" />
                            <div className="vsConfirmForm__infoBoxContent">
                                <SolidTag color="secondary">manual dns update required</SolidTag>
                                <p className="vsConfirmForm__infoBoxText">
                                    The domain name for this service is not within your VentraIP or Synergy Wholesale account. You will need to update
                                    the DNS records yourself once we have completed your migration.
                                </p>
                            </div>
                        </div>

                        {renderEmailEncryptionSection()}
                    </div>
                </div>
            );
        };

        const renderStepTwo = () => {
            const getOptions = () => {
                return vsm_migration_dates_data.dates.map((date) => ({
                    label: toLuxonDate(date, 'yyyy-MM-dd').toFormat('cccc, d LLLL y'),
                    value: date
                }));
            };

            return (
                <div className="vsConfirmForm__step">
                    <div className="vsConfirmForm__stepContent">
                        <div className="vsConfirmForm__stepTitle">
                            <span>Step 2.</span>
                            Select a migration date for your selected services that work best for you.
                        </div>
                        <div className="vsConfirmForm__fieldWrapper">
                            <Field
                                name="migration_date"
                                type="select"
                                label={<div className="vsConfirmForm__migrationTimeHeader">Migration Date</div>}
                                component={RenderSelectField}
                                validate={[requiredFieldValidation]}
                                options={getOptions()}
                            />
                            <div className="vsConfirmForm__migrationTimeHeader">Migration Time</div>
                            <div className="vsConfirmForm__migrationTimeValue">
                                Between {migrationTime}
                                <Tooltip info={`All migrations will be carried out between ${migrationTime} Melbourne time.`} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const renderStepThree = () => {
            const migrationDate = formValues?.migration_date
                ? toLuxonDate(`${formValues.migration_date} 00:00:00`, 'yyyy-MM-dd TT').toFormat('cccc, d LLLL y')
                : 'Not Selected';
            const totalCost = vsm_preview_data?.total_cost?.toFixed(2) || '0.00';
            const halfCost = vsm_preview_data?.total_cost ? roundUpToNearestCent(vsm_preview_data.total_cost / 2) : '0.00';
            const freeUntilDate = getCurrentDate().plus({ months: 2 }).startOf('month').toFormat('d LLLL y');

            const renderCostPreview = () => {
                switch (vsm_preview_status) {
                    case 'error':
                        return <div className="vsConfirmForm__previewUnavailable">Cost preview not available</div>;
                    case 'success':
                    default:
                        return vsmMethods.isHalfPrice(freeUntilDate) ? (
                            <>
                                <div className="vsConfirmForm__pricingGroup vsSelectForm__pricingGroup--halfPrice">
                                    <div className="vsConfirmForm__pricingDesc">
                                        Half price from {freeUntilDate} until{' '}
                                        {toLuxonDate(ventraSynMigrationHalfPriceEndDate, 'dd MMMM yyyy TT').toFormat('dd MMMM yyyy')}
                                    </div>
                                    <div className="vsConfirmForm__pricingTotal">
                                        ${halfCost}
                                        <span>/mo</span>
                                    </div>
                                    <del className="vsConfirmForm__pricingTotal vsConfirmForm__pricingTotal--strike">
                                        ${totalCost}
                                        <span>/mo</span>
                                    </del>
                                </div>
                                <div className="vsConfirmForm__pricingGroup">
                                    <div className="vsConfirmForm__pricingDesc">
                                        After {toLuxonDate(ventraSynMigrationHalfPriceEndDate, 'dd MMMM yyyy TT').toFormat('dd MMMM yyyy')}
                                    </div>
                                    <div className="vsConfirmForm__pricingTotal">
                                        ${totalCost}
                                        <span>/mo</span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="vsConfirmForm__pricingGroup">
                                <div className="vsConfirmForm__pricingDesc">After {freeUntilDate}</div>
                                <div className="vsConfirmForm__pricingTotal">
                                    ${totalCost}
                                    <span>/mo</span>
                                </div>
                            </div>
                        );
                }
            };

            const accountsList = accounts
                .filter((account) => account.update_dns)
                .map((account, index) => (
                    <li key={index} className="vsConfirmForm__infoBoxListItem">
                        {account.info.domain}
                    </li>
                ));

            return (
                <div className="vsConfirmForm__step">
                    <div className="vsConfirmForm__stepContent">
                        <div className="vsConfirmForm__stepTitle">
                            <span>Step 3.</span>
                            Any additional information
                        </div>
                        <Field
                            name="additional_info"
                            label=""
                            component={TextArea.ReduxForm.Default}
                            validate={[]}
                            placeholder="Any additional comments"
                            type="text"
                        />
                        <div className="confirmation__container">
                            <h5 className="confirmation__heading">Acknowledgements</h5>
                            <div className="confirmation__input">
                                <Field
                                    name="delete_data"
                                    label="You accept that ALL data on the destination cPanel account prior to the migration taking place will be deleted to allow the cPanel migration process to proceed. This process will delete ALL data including files, images, databases, email accounts and DNS records."
                                    component={renderCheckboxField}
                                    validate={[requiredAcceptedValidation]}
                                    type="checkbox"
                                />
                            </div>
                            <div className="confirmation__input">
                                <Field
                                    name="email_reconfig"
                                    label="You're aware that any email software configured on your local devices may need to be updated to connect to the new hosting server once the migration is complete. Our technical support team will be able to provide you with this new information as part of the migration process."
                                    component={renderCheckboxField}
                                    validate={[requiredAcceptedValidation]}
                                    type="checkbox"
                                />
                            </div>
                            <div className="confirmation__input">
                                <Field
                                    name="pci_compliant"
                                    label={
                                        <small>
                                            You acknowledge that Synergy Wholesale&apos;s hosting platform is PCI compliant and software on customer
                                            devices will need to use at least TLS 1.2 to remain functional with the new hosting server after the
                                            migration is complete.{' '}
                                            <Anchor
                                                href="https://synergywholesale.com/faq/article/resolving-email-issues-detected-for-your-ventraip-reseller-service/"
                                                target="_blank"
                                            >
                                                Click here to find out how to remain compatible.
                                            </Anchor>
                                        </small>
                                    }
                                    component={renderCheckboxField}
                                    validate={[requiredAcceptedValidation]}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <h4 className="vsConfirmForm__infoHeading">Confirmation</h4>
                        <div className="vsConfirmForm__infoBox vsConfirmForm__infoBox--last">
                            <div className="vsConfirmForm__infoBoxBg vsConfirmForm__infoBoxBg--secondary" />
                            <div className="vsConfirmForm__infoBoxContent vsConfirmForm__infoBoxContent--totals vsConfirmForm__infoBoxContent--confirmPage">
                                <div className="vsConfirmForm__infoBoxLeft">
                                    <div className="vsConfirmForm__infoBoxInfoLine">
                                        <span className="vsConfirmForm__infoBoxBold">Services being migrated:</span>
                                        <span className="vsConfirmForm__infoBoxNormal">{accounts.length}</span>
                                        <Anchor
                                            className="vsConfirmForm__changeLink"
                                            onClick={() => history.push('/synergy-reseller-migration/select-services')}
                                        >
                                            Change
                                        </Anchor>
                                    </div>
                                    <div className="vsConfirmForm__infoBoxInfoLine">
                                        <div className="vsConfirmForm__infoBoxBold">
                                            Services we are updating the DNS on the domain name after the migration:
                                        </div>
                                        <ul className="vsConfirmForm__infoBoxList">
                                            {accountsList.length > 20 && !showFullList ? accountsList.slice(0, 20) : accountsList}
                                        </ul>
                                        {accountsList.length > 20 && !showFullList && (
                                            <Anchor onClick={setShowFullList} className="vsConfirmForm__infoBoxShowAll">
                                                Show Full List
                                            </Anchor>
                                        )}
                                    </div>
                                    <div className="vsConfirmForm__infoBoxInfoLine">
                                        <span className="vsConfirmForm__infoBoxBold">Migration Date:</span>
                                        <span className="vsConfirmForm__infoBoxNormal">{migrationDate}</span>
                                    </div>
                                    <div className="vsConfirmForm__infoBoxInfoLine">
                                        <span className="vsConfirmForm__infoBoxBold">Migration Time:</span>
                                        <span className="vsConfirmForm__infoBoxNormal">{`Between ${migrationTime}`}</span>
                                    </div>
                                    <div className="vsConfirmForm__infoBoxInfoLine">
                                        <div className="vsConfirmForm__infoBoxBold">What happens next?</div>
                                        <div className="vsConfirmForm__infoBoxNormal">
                                            Once you have created a Synergy Wholesale account on the next page, our system will automatically generate
                                            a migration ticket for you. All correspondence around the migration will be available to you within the
                                            Synergy Wholesale Management System.
                                        </div>
                                    </div>
                                </div>
                                <div className="vsConfirmForm__infoBoxRight">
                                    <p className="vsConfirmForm__totalsInfo">What you&apos;ll pay Synergy Wholesale for these hosting services</p>
                                    <div className="vsConfirmForm__pricing">
                                        <div className="vsConfirmForm__pricingGroup vsConfirmForm__pricingGroup--free">
                                            <HoverTooltip
                                                alignment="right"
                                                color="black"
                                                content="This exclusive offer will supply you with the remainder of this month + one full month of free hosting at Synergy Wholesale for each cPanel account you choose to move to Synergy Wholesale using this offer."
                                            >
                                                <div className="vsConfirmForm__pricingDesc">Until {freeUntilDate}</div>
                                            </HoverTooltip>
                                            <div className="vsConfirmForm__pricingTotal">FREE</div>
                                        </div>
                                        <p className="vsConfirmForm__pricingFreeConcluded">Once your free time has concluded</p>
                                        {renderCostPreview()}
                                        <div className="vsSelectForm__pricingGroup">
                                            <div className="vsSelectForm__pricingDesc">Cost to perform migration</div>
                                            <div className="vsSelectForm__pricingTotal">FREE</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderButton(pristine, submitting, valid, submitButtonText, 'primary')}
                    </div>
                </div>
            );
        };

        const renderPage = () => {
            return (
                <form onSubmit={handleSubmit} className="vsConfirmForm">
                    {renderStepOne()}
                    {renderStepTwo()}
                    {renderStepThree()}
                </form>
            );
        };

        const handleLoading = () => {
            if (!vsm_migration_dates_status || vsm_migration_dates_status === 'loading') return 'loading';
            if (vsm_migration_dates_status === 'error') return 'error';

            return submitButtonText === 'CONFIRM MIGRATION' ? vsm_migrate_status : 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        switch (handleLoading()) {
            case 'error':
                return <FetchComponentError />;
            case 'loading':
                return <RequestLoader />;
            case 'success':
            default:
                return renderPage();
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ConfirmMigrationForm = reduxForm({
    form: 'ventraSynMigrationForm',
    destroyOnUnmount: false
})(ConfirmMigrationForm);

export default withRouter(
    connect(
        (state) => ({
            formValues: getFormValues('ventraSynMigrationForm')(state),
            vsm_preview_status: state.vsm.vsm_preview_status,
            vsm_preview_data: state.vsm.vsm_preview_data,
            vsm_migrate_status: state.vsm.vsm_migrate_status,
            vsm_pci_csv_status: state.vsm.vsm_pci_csv_status,
            vsm_pci_csv_data: state.vsm.vsm_pci_csv_data,
            vsm_migration_dates_status: state.vsm.vsm_migration_dates_status,
            vsm_migration_dates_data: state.vsm.vsm_migration_dates_data
        }),
        {
            getPciComplianceCsv,
            getMigrationDates
        }
    )(ConfirmMigrationForm)
);
