/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EmailHostingDiskBlockForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'match',
            currentBillingCycle: null,
            selectedNewCycle: null,
            selectedExtra: null,
            selectedPrice: null,
            newDiskLimit: null
        };

        this.setAddon = this.setAddon.bind(this);
        this.updatePrice = this.updatePrice.bind(this);
        this.setBillingCycle = this.setBillingCycle.bind(this);
        this.setOption = this.setOption.bind(this);
        this.submitDiskRequest = this.submitDiskRequest.bind(this);
    }

    setAddon(addon) {
        this.setState(
            {
                selectedExtra: addon
            },
            () => {
                this.setNewDiskLimit(addon);
            }
        );
    }

    updatePrice() {
        const { diskBlockData } = this.props;
        const { selectedExtra, selectedOption, selectedNewCycle, currentBillingCycle } = this.state;
        const { purchaseOptions } = diskBlockData;

        if (selectedExtra) {
            const billingCycle = selectedOption === 'new' ? selectedNewCycle : currentBillingCycle;
            const filteredOptions = purchaseOptions.filter((value) => value.addon_id === selectedExtra);
            const selectedPrice = filteredOptions[0].pricing[billingCycle];

            this.setState({
                selectedPrice
            });
        }
    }

    setBillingCycle(cycle) {
        this.setState({
            selectedOption: cycle
        });
    }

    setOption(cycle) {
        this.setState({
            selectedOption: 'new',
            selectedNewCycle: cycle
        });
    }

    setNewDiskLimit(id) {
        const { diskBlockData } = this.props;
        const { purchaseOptions, disk } = diskBlockData;

        const filteredOptions = purchaseOptions.filter((value) => value.addon_id === id);
        const selectedLimit = filteredOptions[0].name;

        this.setState(
            {
                newDiskLimit: disk.limit + parseFloat(selectedLimit.replace(/[^0-9.]/g, ''))
            },
            () => {
                this.updatePrice();
            }
        );
    }

    submitDiskRequest() {
        const { confirmAddon } = this.props;
        const { selectedExtra, selectedOption, selectedNewCycle, currentBillingCycle } = this.state;
        const attributes = {
            billing_cycle: selectedOption === 'new' ? selectedNewCycle : currentBillingCycle
        };

        confirmAddon(selectedExtra, attributes);
    }

    componentDidMount() {
        const { email_information_mailbox_data } = this.props;

        this.setState({
            currentBillingCycle: email_information_mailbox_data.attributes.billing_cycle
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOption, selectedNewCycle, selectedExtra } = this.state;

        if (
            (selectedOption && selectedOption !== prevState.selectedOption) ||
            (selectedNewCycle && selectedNewCycle !== prevState.selectedNewCycle) ||
            (selectedExtra && selectedExtra !== prevState.selectedExtra)
        ) {
            this.updatePrice();
        }
    }

    render() {
        const { diskBlockData } = this.props;
        const { currentBillingCycle, selectedOption, selectedNewCycle, selectedExtra, selectedPrice, newDiskLimit } = this.state;
        const { submitDiskRequest, setAddon, setBillingCycle, setOption } = this;
        const { purchaseOptions, billingCycles, disk } = diskBlockData;

        const renderExtraList = (data) => {
            const { addon_id, name, pricing } = data;
            const billingCycle = selectedOption === 'new' ? selectedNewCycle : currentBillingCycle;
            return (
                <button
                    key={addon_id}
                    className={`overlayAddon__option ${selectedExtra === addon_id ? 'selected' : ''}`}
                    onClick={(e) => {
                        e.preventDefault();
                        setAddon(addon_id, pricing[billingCycle]);
                    }}
                >
                    <span className="option__title">
                        <span className="option__radio"></span>
                        <span className="option__text">Extra {name}</span>
                    </span>

                    <span className="option__price">
                        ${pricing[billingCycle]} /{billingCycle}
                    </span>
                </button>
            );
        };

        function renderPurchaseOptions() {
            if (!purchaseOptions) {
                return null;
            }

            return purchaseOptions.map((option) => {
                return renderExtraList(option);
            });
        }

        /*  Render Component
         **********************************************************************************************************/
        return (
            <div className="emailDisk__form">
                <div className="emailDisk__form--top">
                    <button
                        className={`emailDisk__column selectable ${selectedOption === 'match' ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            setBillingCycle('match');
                        }}
                    >
                        <span className="item__radio"></span>
                        <span className="item__label">Match billing cycle with hosting service</span>
                    </button>
                    <CustomDropdown.BillingCyclePrefab
                        isActive={selectedOption === 'new'}
                        options={billingCycles.map((option) => ({ label: option, value: option }))}
                        setOption={setOption}
                        selectedNewCycle={selectedNewCycle}
                    />
                </div>
                <div className="emailDisk__form--bottom">
                    <div className="emailDisk__column">{renderPurchaseOptions()}</div>
                    <div className="emailDisk__column">
                        <div className="overlayAddon__billing--wrapper">
                            <div className="overlayAddon__details">
                                <div className="detail__row">
                                    <span className="title">Current Disk Space Limit</span>
                                    <span className="desc slim">{disk.limit}GB</span>
                                </div>
                                {selectedExtra ? (
                                    <div className="detail__row">
                                        <span className="title">
                                            <span className="highlight">NEW</span> Disk Space Limit
                                        </span>
                                        <span className="desc slim">
                                            <span className="highlight">{newDiskLimit ? newDiskLimit : disk.limit}GB</span>
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="detail__row">
                                    <span className="title">Billing Cycle</span>
                                    <span className="desc">
                                        {selectedOption === 'new' ? selectedNewCycle : `Same as hosting (${currentBillingCycle})`}{' '}
                                    </span>
                                </div>
                                {selectedExtra ? (
                                    <div className="detail__row">
                                        <span className="title">Prorata Cost</span>
                                        <span className="desc">${selectedPrice ? selectedPrice : ` --.-`} AUD</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {selectedExtra ? (
                                    <div className="detail__row--total">
                                        <span className="title">Total Due Today</span>
                                        <span className="desc">${selectedPrice ? selectedPrice : ` --.-`} AUD</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="overlayAddon__action">
                                {selectedExtra ? (
                                    <SolidButton
                                        type="onClick"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            submitDiskRequest();
                                        }}
                                    >
                                        Confirm and Pay
                                    </SolidButton>
                                ) : (
                                    <InactiveButton>Confirm and Pay</InactiveButton>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        email_diskblock_status: state.email.email_diskblock_status,
        email_diskblock_data: state.email.email_diskblock_data,
        email_diskblock_error: state.email.email_diskblock_error,
        email_information_mailbox_data: state.email.email_information_mailbox_data
    };
};

export default connect(mapStateToProps, null)(EmailHostingDiskBlockForm);
