/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderRadioGroup, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'ticketPreferenceForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let TicketPreferenceForm = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit} className="ticketPreferenceForm">
        <Field
            name="is_technical"
            outlined
            component={RenderRadioGroup}
            validate={requiredFieldValidation}
            options={[
                {
                    label: 'I am NOT a technical person',
                    desc: 'No, I am not a technical person and I need clear and helpful instructions when dealing with technical issues.',
                    value: false
                },
                {
                    label: 'I am a technical person',
                    desc: 'Yes, I am a technical person who understands technical jargon and would prefer straightforward communication.',
                    value: true
                }
            ]}
        />
        <ReduxFormButton form={formName}>Save Changes</ReduxFormButton>
    </form>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
TicketPreferenceForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(TicketPreferenceForm);

const mapStateToProps = (state) => ({
    app_user_data: state.app.app_user_data,
    initialValues: { is_technical: state.app?.app_user_data?.is_technical }
});

TicketPreferenceForm = connect(mapStateToProps)(TicketPreferenceForm);

export default TicketPreferenceForm;
