/* eslint-disable react/jsx-key */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { BUTTON_SIZES } from 'components/Buttons/_BaseButton';
import { useVPSOverviewContext } from '../../own';

/*   ACTIONS
 *****************************************************/
import { generateCreateActionButton, generateCreateDropdown } from 'containers/services/modules/helpers';
import { WHMVPSLogin, createAstroVNCSession, resetVPSData } from 'containers/vps/action';
import { vpsMethods, vpsServerTypes } from 'containers/vps/methods';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { isVpsEligibleForRenewDiscount } from 'config/containers/promotions/megamay2024/methods';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { activePromotion, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').TVPSOverview.TVPSActions} TVPSActions
 */

export const DOMAIN_STATUSES = /** @type {const} */ ({
    ACTIVE: 'Active',
    PENDING_CANCELLATION: 'Pending Cancellation'
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Render table actions
 *
 * @type {TVPSActions}
 */
export const OwnRenderActions = ({ data }) => {
    /***** HOOKS *****/
    const { setKeepService, isExpressServiceManager } = useVPSOverviewContext();
    const isMobile = useAppViewport(['xs', 'sm']);
    const dispatch = useDispatch();
    const history = useHistory();

    /***** RENDER HELPERS *****/
    const invoice = getIncludedObjBasedOnType(data.included, 'invoice');
    const { has_whm, vps_type, domain_status } = data.attributes;

    const createActionButton = generateCreateActionButton({
        size: BUTTON_SIZES.MEDIUM,
        type: 'onClick',
        className: classNames('VPSTableBody__actionButton', {
            'VPSTableBody__actionButton--mobile': isMobile
        })
    });

    const buttons = /** @type {const} */ ({
        manage: createActionButton({
            name: 'Manage',
            onClick: () => {
                resetVPSData();
                history.push(`/my-services/vps/account/overview/${data.id}`);
            }
        }),
        whm: createActionButton({
            name: 'WHM',
            color: 'notice',
            onClick: () => WHMVPSLogin(data.id)(dispatch)
        }),
        astroSelfManaged: createActionButton({
            name: 'VNC Login',
            color: 'notice',
            onClick: () => createAstroVNCSession(data.id)(dispatch)
        }),
        keepService: createActionButton({
            name: 'Keep Service',
            onClick: () => setKeepService(data.id)
        }),
        payInvoice: createActionButton({
            name: 'Pay Invoice',
            onClick: () => history.push(`/billing/invoices/${invoice?.id}/pay`)
        }),
        viewInvoice: createActionButton({
            name: 'View Invoice',
            onClick: () => history.push(`/billing/invoice/${invoice?.id}`)
        })
    });

    const isAstro = vpsMethods.getVpsServerType(data) === vpsServerTypes.ASTRO;
    const { astroSelfManaged, keepService, manage, whm } = buttons;
    const createDropdown = generateCreateDropdown(buttons, isMobile);
    const isFMVPS = vps_type === 'fully_managed';
    const isNonFMAstroVPS = isAstro && !!vps_type && !isFMVPS;
    const className = classNames('VPSTableBody__actions', {
        'VPSTableBody__actions--mobile': isMobile
    });

    /***** RENDER *****/
    return (
        <NXTable.Actions className={className}>
            {React.Children.toArray(
                (() => {
                    switch (domain_status) {
                        case DOMAIN_STATUSES.ACTIVE: {
                            const buttons = [];

                            switch (true) {
                                case isNonFMAstroVPS && !has_whm:
                                    buttons.push(manage.button, astroSelfManaged.button);
                                    break;
                                case isNonFMAstroVPS && has_whm:
                                    buttons.push(manage.button, ...createDropdown('whm', ['astroSelfManaged']));
                                    break;
                                case has_whm:
                                    buttons.push(manage.button, whm.button);
                                    break;
                                default:
                                    buttons.push(manage.button);
                                    break;
                            }

                            /***** MEGAMAY24 START *****/
                            if (activePromotion('megamay2024') && !isExpressServiceManager && isVpsEligibleForRenewDiscount(data)) {
                                const promoRenewButton = (
                                    <SolidButton size="medium" className="mmServiceButton" to={`/my-services/vps/account/renew/${data.id}?open=true`}>
                                        Renew
                                    </SolidButton>
                                );

                                buttons.unshift(promoRenewButton);
                            }
                            /***** MEGAMAY24 END *****/

                            return buttons;
                        }
                        case DOMAIN_STATUSES.PENDING_CANCELLATION:
                            return [keepService.button];
                        default: {
                            switch (true) {
                                case !!invoice:
                                    return createDropdown('payInvoice', ['viewInvoice']);
                                default:
                                    return null;
                            }
                        }
                    }
                })()
            )}
        </NXTable.Actions>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
