/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import Anchor from 'components/Anchor';

/*   ACTIONS
 *****************************************************/
import { getAstroVPSInfo, getVPSAddons } from 'containers/vps/action';
import { getCurrentDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ResourceUsageTable extends Component {
    constructor(props) {
        super(props);
        this.renderRow = this.renderRow.bind(this);
    }

    renderRow() {
        const { list } = this.props;

        if (!Array.isArray(list)) return '';

        return list.map((item, index) => (
            <div key={index} className={`VPS-Resource-Usage-Table__row${index % 2 === 0 ? ' VPS-Resource-Usage-Table__row--even' : ''}`}>
                <div className="VPS-Resource-Usage-Table__column VPS-Resource-Usage-Table__column--label">{item.label}</div>
                <div className="VPS-Resource-Usage-Table__column VPS-Resource-Usage-Table__column--value">{item.value}</div>
            </div>
        ));
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { className, heading } = this.props;
        const { renderRow } = this;

        return (
            <div className={`VPS-Resource-Usage-Table${className ? ' ' + className : ''}`}>
                <div className="VPS-Resource-Usage-Table__heading">{heading}</div>
                <div className="VPS-Resource-Usage-Table__body">{renderRow()}</div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ResourceUsage extends Component {
    constructor(props) {
        super(props);

        this.getFetchDate = this.getFetchDate.bind(this);
        this.renderOpenMore = this.renderOpenMore.bind(this);

        this.state = {
            isOpenMore: false,
            fetch_date: null
        };
    }

    getFetchDate() {
        const date = getCurrentDate(true).toFormat('cccc, MMMM d, yyyy t');
        this.setState({
            fetch_date: date
        });
    }

    renderOpenMore(e) {
        e.preventDefault();
        const { isOpenMore } = this.state;
        this.setState({
            isOpenMore: !isOpenMore
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getFetchDate } = this;

        getFetchDate();
    }

    componentDidUpdate(prevProps) {
        const { vps_astro_information_status } = this.props;
        const { getFetchDate } = this;

        if (vps_astro_information_status === 'success' && prevProps.vps_astro_information_status === 'loading') {
            getFetchDate();
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const {
            vps_astro_information_status,
            getAstroVPSInfo,
            getVPSAddons,
            vpsid,
            vps_astro_information_data,
            vps_information_data,
            vps_addon_list_data
        } = this.props;
        const { isOpenMore, fetch_date } = this.state;
        const { renderOpenMore } = this;

        const renderBoxContent = () => {
            if (!vps_astro_information_data || !vps_information_data) return '';

            const {
                attributes: { primary_ip, additional_ips }
            } = vps_information_data;

            const renderAdditionalIPAddreses = () => {
                if (!Array.isArray(additional_ips)) return '';

                return additional_ips.map((item, index) => (
                    <div key={index} className="list-item">
                        {item}
                    </div>
                ));
            };

            const renderMore = () => {
                const {
                    attributes: { state_status, last_used_template }
                } = vps_astro_information_data;

                const renderResourceAllocation = () => {
                    if (!vps_addon_list_data?.attributes.hardware) return '';

                    const { totals } = vps_addon_list_data.attributes.hardware;
                    const { cpu, disk, ram } = totals;

                    return (
                        <ResourceUsageTable
                            heading="Resource Allocation"
                            list={[
                                {
                                    label: 'CPU',
                                    value: cpu + ' Cores'
                                },
                                {
                                    label: 'Disk',
                                    value: disk + 'GB'
                                },
                                {
                                    label: 'Memory',
                                    value: ram + 'GB'
                                }
                            ]}
                        />
                    );
                };

                return (
                    <div className="VPS-Resource-Usage__more-details">
                        <ResourceUsageTable
                            heading="Server Details"
                            list={[
                                {
                                    label: 'Server Status',
                                    value: state_status
                                },
                                {
                                    label: 'Operating System Template',
                                    value: last_used_template
                                }
                            ]}
                        />
                        {renderResourceAllocation()}
                    </div>
                );
            };

            return (
                <div className="VPS-Resource-Usage">
                    <div className="VPS-Resource-Usage__wrapper">
                        <div className="box__heading VPS-Resource-Usage__heading">
                            Resource Usage
                            <div className="error__refresh VPS-Resource-Usage__reload">
                                <Anchor
                                    onClick={() => {
                                        getAstroVPSInfo(vpsid);
                                        getVPSAddons(vpsid);
                                    }}
                                >
                                    <i className="icon icon-reload" />
                                </Anchor>
                            </div>
                        </div>
                        <div className="VPS-Resource-Usage__cta">
                            <a className="VPS-Resource-Usage__cta-link" href={`../../addons/resource-addons/${vpsid}`}>
                                Need more resources?
                            </a>
                            <div className="VPS-Resource-Usage__cta-date">{`Data is retrieved as of ${fetch_date}`}</div>
                        </div>
                        <ResourceUsageTable
                            heading="IP Addreses"
                            list={[
                                {
                                    label: 'Primary IP Address',
                                    value: primary_ip
                                },
                                {
                                    label: 'Additional IP Addresses',
                                    value: renderAdditionalIPAddreses()
                                }
                            ]}
                        />
                        {isOpenMore ? renderMore() : ''}
                    </div>
                </div>
            );
        };

        return (
            <Box
                custom={{
                    pos: 'top',
                    render: renderBoxContent()
                }}
                status={vps_astro_information_status}
                className="VPS-Resource-Usage"
                footer={
                    <Anchor className="VPS-Resource-Usage__more" onClick={renderOpenMore}>
                        {isOpenMore ? 'Hide' : 'View'} Full Service Details
                    </Anchor>
                }
                premounted={true}
            />
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default connect(
    (state) => ({
        vps_information_data: state.vps.vps_information_data,
        vps_astro_information_data: state.vps.vps_astro_information_data,
        vps_astro_information_status: state.vps.vps_astro_information_status,
        vps_addon_list_data: state.vps.vps_addon_list_data,
        vps_addon_list_status: state.vps.vps_addon_list_status
    }),
    {
        getAstroVPSInfo,
        getVPSAddons
    }
)(ResourceUsage);
