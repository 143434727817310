import SVGBackups from 'assets/particles/backups.svg';
import SVGBalloon from 'assets/particles/balloon.svg';
import SVGBusiness from 'assets/particles/business-hosting.svg';
import SVGDatabase from 'assets/particles/database.svg';
import SVGDDos from 'assets/particles/ddos-protection.svg';
import SVGDNS from 'assets/particles/dns-records.svg';
import SVGDomains from 'assets/particles/domain-names.svg';
import SVGEmail from 'assets/particles/email.svg';
import SVGEncryption from 'assets/particles/encryption.svg';
import SVGHelp from 'assets/particles/help-circle.svg';
import SVGIssue from 'assets/particles/issue.svg';
import SVGMX from 'assets/particles/redundant-mx-records.svg';
import SVGTick from 'assets/particles/tick.svg';
import SVGVFM from 'assets/particles/vps-fully-managed.svg';
import SVGVSM from 'assets/particles/vps-self-managed.svg';
import SVGVPS from 'assets/particles/vps.svg';
import SVGWHM from 'assets/particles/whm-multi.svg';
import SVGWholesale from 'assets/particles/wholesale-network.svg';

export const particleParams = {
    detectRetina: true,
    background: {
        color: {
            value: '#ffffff'
        }
    },
    fpsLimit: 60,
    interactivity: {
        events: {
            resize: false
        }
    },
    particles: {
        collisions: {
            enable: true
        },
        number: {
            value: 70
        },
        color: {
            value: '#dadada'
        },
        move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 0.3,
            straight: false
        },
        size: {
            value: 20
        },
        rotate: {
            value: 0,
            random: true,
            direction: 'clockwise',
            animation: {
                enable: true,
                speed: 1.2,
                sync: false
            }
        },
        shape: {
            type: 'images',
            image: [
                { src: SVGBackups, height: 200, width: 200 },
                { src: SVGBalloon, height: 200, width: 200 },
                { src: SVGBusiness, height: 200, width: 200 },
                { src: SVGDatabase, height: 200, width: 200 },
                { src: SVGDDos, height: 200, width: 200 },
                { src: SVGDNS, height: 200, width: 200 },
                { src: SVGDomains, height: 200, width: 200 },
                { src: SVGEmail, height: 200, width: 200 },
                { src: SVGEncryption, height: 200, width: 200 },
                { src: SVGHelp, height: 200, width: 200 },
                { src: SVGIssue, height: 200, width: 200 },
                { src: SVGMX, height: 200, width: 200 },
                { src: SVGTick, height: 200, width: 200 },
                { src: SVGVFM, height: 200, width: 200 },
                { src: SVGVSM, height: 200, width: 200 },
                { src: SVGVPS, height: 200, width: 200 },
                { src: SVGWHM, height: 200, width: 200 },
                { src: SVGWholesale, height: 200, width: 200 }
            ]
        },
        opacity: {
            value: 0.05,
            random: false,
            anim: {
                enable: false
            }
        }
    }
};
