/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { formatLuxonOrdinal, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let GSuiteOverview = (props) => {
    const {
        /** Redux Props */
        gsuite_service_data,
        gsuite_service_status,
        history
    } = props;

    /***** STATE *****/
    const [details, setDetails] = useState({
        id: null,
        number_of_seats: '',
        number_of_users: '',
        next_due_date: null,
        amount: ''
    });

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** EFFECTS *****/
    useEffect(() => {
        if (gsuite_service_data) {
            setDetails({
                id: gsuite_service_data.id,
                ...gsuite_service_data.attributes
            });
        }
    }, []);

    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, gsuite_service_status === 'success');

        if (gsuite_service_status === 'success') {
            setDetails({
                id: gsuite_service_data.id,
                ...gsuite_service_data.attributes
            });
        }
    }, [gsuite_service_status]);

    /***** RENDER HELPERS *****/
    const { id, number_of_seats, number_of_users, next_due_date, amount, billing_cycle } = details;
    function renderBillingAmount() {
        const billingOverviewContent = [
            <div className="sharedSplit__container" key={0}>
                <div className="sharedSplit__subheading">Billing Cycle</div>
                <div className="sharedSplit__description">
                    {billing_cycle ?? <RequestLoader />}{' '}
                    {billing_cycle === 'Annually' ? (
                        ''
                    ) : (
                        <Anchor
                            onClick={() => {
                                history.push(`/my-services/google/manage/billing/${id}?open=true`);
                            }}
                        >
                            Change
                        </Anchor>
                    )}
                </div>
            </div>
        ];

        if (billing_cycle === 'Annually') {
            billingOverviewContent.push(
                <div key="annually" className="sharedSplit__container">
                    <div className="sharedSplit__subheading">Billing Amount</div>
                    <div className="sharedSplit__description">
                        ${(amount / 12).toFixed(2)} / Month (Billed Yearly at ${amount})
                    </div>
                </div>
            );
        } else {
            billingOverviewContent.push(
                <div key="monthly" className="sharedSplit__container">
                    <div className="sharedSplit__subheading">Monthly Billing Amount</div>
                    <div className="sharedSplit__description">${amount}</div>
                </div>
            );
        }

        return billingOverviewContent;
    }

    /*   SET CONDITIONAL PROPS
     **********************************************************************************************************/
    const conditionalProps = {
        className: `gsuiteOverview__box`,
        split: {
            left: {
                render: (
                    <Fragment>
                        <div className="sharedSplit__container--title">
                            <h2>Service Overview</h2>
                        </div>
                        <div className="sharedSplit__container">
                            <div className="sharedSplit__subheading">Number of Seats</div>
                            <div className="sharedSplit__description domain">
                                {number_of_seats}
                                <Anchor
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(`/my-services/google/manage/manage-seats/${id}?open=true`);
                                    }}
                                >
                                    Change Seats
                                </Anchor>
                            </div>
                        </div>
                        <div className="sharedSplit__container">
                            <div className="sharedSplit__subheading">Number of User Accounts</div>
                            <div className="sharedSplit__description">{number_of_users}</div>
                        </div>
                    </Fragment>
                )
            },
            right: {
                render: (
                    <Fragment>
                        <div className="sharedSplit__container--title">
                            <h2>Billing Overview</h2>
                        </div>
                        <div className="sharedSplit__container">
                            <div className="sharedSplit__subheading">Next Renewal Date</div>
                            <div className="sharedSplit__description">
                                {next_due_date ? formatLuxonOrdinal(toLuxonDate(next_due_date).toFormat('EEEE, MMM {d} yyyy')) : ''}
                            </div>
                        </div>
                        {renderBillingAmount()}
                    </Fragment>
                )
            }
        }
    };
    /***** RENDER *****/
    return (
        <div ref={scrollRef} className="gsuiteOverview">
            <Box premounted={true} status={gsuite_service_status} {...conditionalProps} />
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_service_status: state.gsuite.gsuite_service_status,
        gsuite_service_data: state.gsuite.gsuite_service_data
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

GSuiteOverview = connect(mapStateToProps, mapDispatchToProps)(GSuiteOverview);

GSuiteOverview = withRouter(GSuiteOverview);

export default GSuiteOverview;
