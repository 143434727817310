/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import InvoiceDateForm from '../../forms/invoiceDate';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useChangeInvoiceOffsetMutation } from '../../queries/invoice';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_invoiceDate.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let InvoiceDate = ({ app_user_data }) => {
    /***** HOOKS *****/
    const { value: isInvoiceDateDropdownShowing, setFalse: closeInvoiceDateDropdown, toggle: toggleInvoiceDateDropdown } = useBoolean(false);

    /***** QUERIES *****/
    const { mutate: mutateChangeInvoiceOffset } = useChangeInvoiceOffsetMutation({
        onSuccess: closeInvoiceDateDropdown
    });

    /***** FUNCTIONS *****/
    function handleSubmit(e) {
        return new Promise((resolve, reject) => {
            mutateChangeInvoiceOffset(e, {
                onSuccess: resolve,
                onError: reject
            });
        });
    }

    /***** RENDER *****/
    return (
        <NXBox>
            <NXBox.Top
                title="Invoice Generation Preference"
                description={`By default, ${company} generates your renewal invoices 10 days before your service's due dates, 
                    you can opt to have your renewal invoices generate up to 28 days prior to this date.`}
            />
            <NXBox.Bottom>
                <NXBox.BottomColumns
                    columns={[
                        <NXBox.InfoPair key={1} title="Current Invoice Generation" description={`${app_user_data?.invoice_offset || 0} days`} />,
                        <OutlineButton key={2} type="onClick" onClick={toggleInvoiceDateDropdown}>
                            Change
                        </OutlineButton>
                    ]}
                />
            </NXBox.Bottom>
            <NXBox.Dropdown isOpen={isInvoiceDateDropdownShowing}>
                <NXBox.DefaultPadding>
                    <NXBox.Dropdown.CloseButton onClose={closeInvoiceDateDropdown} />
                    <InvoiceDateForm onSubmit={handleSubmit} />
                </NXBox.DefaultPadding>
            </NXBox.Dropdown>
        </NXBox>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        app_user_data: state.app.app_user_data
    };
};

InvoiceDate = connect(mapStateToProps)(InvoiceDate);

InvoiceDate = withRouter(InvoiceDate);

export default InvoiceDate;
