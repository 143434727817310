/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaBooleanFormFieldCheckbox } from 'containers/katana/formFields/boolean/inputTypes/checkbox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_BOOLEAN_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/boolean/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaBooleanFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    switch (input_type) {
        case SITE_SECTION_BOOLEAN_PROPERTY_INPUT_TYPE.CHECKBOX:
            return <KatanaBooleanFormFieldCheckbox property={property} />;

        default:
            return <InvalidInputType name={name} inputType={input_type} fieldType="Boolean" />;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaBooleanFormField };
