/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DiskSpaceMessage from '../diskSpaceMessage';
import EditDetailsButton from '../editDetailsButton';
import { getHostingVpsDomain, validateMigrationDetails } from '../methods';
import SkipDetailValidation from '../skipDetailValidation';
import TargetNotEmptyMessage from '../targetNotEmptyMessage';
import { MigrationReduxCpanelFormPart1 } from './cpanelValidate';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FieldTypesEnum } from 'components/DynamicForm/utils';
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { REDUX_FORM_BUTTON_STATE, ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const formData = [
    {
        label: 'Current Service IP Address',
        name: 'current_ip'
    },
    {
        label: 'Service Domain Name',
        name: 'domain'
    },
    {
        label: 'Service Username',
        name: 'username',
        tooltip: 'Most cPanel usernames are alphanumeric and start with a letter'
    },
    {
        label: 'Service Password',
        name: 'password'
    }
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let CpanelDetails = ({
    support_submit_migration_details_check_service_status,
    support_submit_migration_details_check_service_data,
    handleSubmit,
    valid,
    form,
    validationAttempts,
    stepOneComplete,
    handleSkipNow,
    serviceValidationData,
    handleFormDetailsFieldChange,
    serviceValidationModifiedData,
    canEditDetails,
    allowEditDetails,
    selectedService
}) => {
    const fieldsDisabled = support_submit_migration_details_check_service_status === 'loading';

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <form onSubmit={handleSubmit} onChange={handleFormDetailsFieldChange} className="migrationRequestForm__form migrationRequestForm__cPanel">
            <EditDetailsButton {...{ canEditDetails, allowEditDetails }} />
            {formData.map((field) => {
                const { label, name, tooltip } = field;
                return (
                    <Field
                        key={name}
                        component={Input.ReduxForm.Default}
                        label={label}
                        name={name}
                        tooltip={tooltip || null}
                        placeholder=""
                        type={FieldTypesEnum.TEXT}
                        disabled={fieldsDisabled || !canEditDetails}
                        prepend={name === 'domain' ? 'www.' : null}
                        validated={
                            serviceValidationData && !serviceValidationModifiedData.includes(name)
                                ? validateMigrationDetails({ serviceValidationData, name, support_submit_migration_details_check_service_data })
                                : undefined
                        }
                    />
                );
            })}

            <DiskSpaceMessage serviceValidationData={serviceValidationData} selectedService={selectedService} />

            <TargetNotEmptyMessage />

            {!stepOneComplete && validationAttempts <= 0 ? <SkipDetailValidation onSkip={handleSkipNow} /> : ''}

            <ReduxFormButton
                form={form}
                className="PanelDetailsValidationButton"
                force={(!valid || stepOneComplete) && (!valid || !canEditDetails) ? REDUX_FORM_BUTTON_STATE.INACTIVE : undefined} // inactive if we are on step 2 or canEditDetails is false (or invalid)
            >
                Validate Details
            </ReduxFormButton>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state, props) => {
    const { selectedService } = props;
    const { hosting_list_data, vps_list_data } = state.services;
    const domain = getHostingVpsDomain({ hosting_list_data, vps_list_data, selectedService });

    return {
        support_submit_migration_details_check_service_data: state.support.support_submit_migration_details_check_service_data,
        support_submit_migration_details_check_service_status: state.support.support_submit_migration_details_check_service_status,
        hosting_list_data: state.services.hosting_list_data,
        initialValues: {
            domain
        }
    };
};

CpanelDetails = reduxForm(MigrationReduxCpanelFormPart1)(CpanelDetails);

CpanelDetails = connect(mapStateToProps)(CpanelDetails);

export default CpanelDetails;
