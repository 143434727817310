/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { truncateText } from 'utilities/methods/commonActions';
import { getInformationFromData } from './helpers';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NSSLTable.TSSLServiceName} TSSLServiceName
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private - For use in the _TableBody component.
 * @type {TSSLServiceName}
 */
export const OwnRenderServiceTitle = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const { status, name, domain } = getInformationFromData(data);
    const text = status === 'pending' ? 'Unconfigured' : truncateText(domain, 35, '...') || 'Not Available';

    const classes = /** @type {const} */ ({
        title: classNames('SSLOverviewBody__title', {
            'SSLOverviewBody__title--mobile': isMobile
        }),
        domain: 'SSLOverviewBody__domainTitle--mobile',
        certificate: classNames('SSLOverviewBody__certificateTitle', {
            'SSLOverviewBody__certificateTitle--mobile': isMobile
        })
    });

    /***** RENDER *****/
    return (
        <div className={classes.title}>
            {isMobile && (
                <Text className={classes.domain} lead--1 secondary bold size--xss align--left uppercase>
                    Domain
                </Text>
            )}
            <Text black bold size--s lead--1 align--left={isMobile}>
                {text}
            </Text>
            <Text className={classes.certificate} secondary bold size--xs lead--s uppercase align--left={isMobile}>
                {name || 'Not Available'}
            </Text>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
