/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Switch from 'components/Switch';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TLabelledSwitch = React.FC<{
    onClick: () => void;
    label: string;
    value: boolean;
    disabled: boolean;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LabelledSwitch: TLabelledSwitch = ({ onClick, label, value, disabled }) => {
    /***** RENDER *****/
    return (
        <div className="accountSubscriptions__action-wrapper">
            <div className="accountSubscriptions__action--label">{label}</div>
            <div className="accountSubscriptions__action--switch">
                <Switch onClick={onClick} switchvalue={value} disabled={disabled} />
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
