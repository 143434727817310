/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Button that appears inside the dropdown content of the NXBox, used to close the dropdown
 */
function NXBoxDropdownCloseButton({ onClose }) {
    /***** RENDER *****/
    return (
        <Flex className="NXBoxDropdownCloseButton" justify="end">
            <button onClick={onClose} type="button">
                <PhosphorIcons.X.Bold size={16} />
            </button>
        </Flex>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxDropdownCloseButton.propTypes = {
    // The function to call when the close button is clicked
    onClose: PropTypes.func
};

export default NXBoxDropdownCloseButton;
