/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FormGenericStep2 from '../formGenericStep2';
import { MigrationReduxOtherFormPart2 } from './otherFormValidate';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let OtherPanelSection2 = (props) => {
    const { handleSubmit, form } = props;

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <form onSubmit={handleSubmit} className="migrationRequestForm__form">
            <FormGenericStep2 />

            <ReduxFormButton form={form} className="MigrationRequestConfirmButton">
                Submit Migration Form
            </ReduxFormButton>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

OtherPanelSection2 = reduxForm(MigrationReduxOtherFormPart2)(OtherPanelSection2);

export default OtherPanelSection2;
