/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, change, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { BUTTON_COLORS } from 'components/Buttons/_BaseButton';
import { REDUX_FORM_BUTTON_STATE, ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ControlledCheckbox, RenderSelectField } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import Text from 'components/Utils/Text';
import { addonMethods } from 'containers/hosting/methods';

const formName = 'hostingAddonForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let HostingAddonForm = ({ selectedValues, purchasable, handleSubmit, hosting_information_data, dispatch, form }) => {
    /***** RENDER HELPERS *****/
    const hasSomeChecked = selectedValues && Object.values(selectedValues).includes(true);

    const renderAddonConfigOptions = (configOptions, serviceBillingCycle, addonProductId) => {
        return (
            configOptions &&
            configOptions.length > 0 && (
                <div className="hostingAddons__addonConfig">
                    <div className="hostingAddons__addonConfigTitle">Configure Addon</div>
                    <div className="hostingAddons__addonConfigInfo">Configuration option prices will be added to the base price shown above.</div>
                    {configOptions.map((configOption) => {
                        const optionData = addonMethods.processConfigOptionData(configOption, serviceBillingCycle, addonProductId);

                        return optionData ? (
                            <div className="hostingAddons__configOptionContainer">
                                <Field
                                    label={optionData.name}
                                    component={RenderSelectField}
                                    name={optionData.nameAtt}
                                    type="select"
                                    options={optionData.fieldOptions}
                                />
                            </div>
                        ) : (
                            ''
                        );
                    })}
                </div>
            )
        );
    };

    const renderPurchasableAddons = () => {
        if (purchasable && purchasable.length > 0) {
            return purchasable.map((product) => {
                const {
                    attributes: { billing_cycle }
                } = hosting_information_data;

                const { isFree, price, id, name, description, config_list } = addonMethods.processPurchasableAddonData(product, billing_cycle);

                const renderPrice = () => {
                    return isFree ? (
                        <div className="addon__price addon__price--freeTrial">
                            <s className="addon__stuckOutPrice">{price}</s>
                            <p className="addon__freeTrial">Free Trial</p>
                        </div>
                    ) : (
                        <span className="addon__price">{price}</span>
                    );
                };

                return (
                    <div key={id} className="addon__container">
                        <div className="addon__innerContainer">
                            <div className="addon__checkboxContainer">
                                <ControlledCheckbox
                                    noBackground
                                    checked={selectedValues[id]}
                                    onClick={() => {
                                        dispatch(change(form, id, !selectedValues[id]));
                                    }}
                                />
                                <div>
                                    <Text black bold>
                                        {name}
                                    </Text>
                                    <Text secondary size--s>
                                        {description}
                                    </Text>
                                </div>
                            </div>
                            <span className="addon__price">{renderPrice()}</span>
                        </div>
                        {selectedValues && selectedValues[id] && renderAddonConfigOptions(config_list, billing_cycle, id)}
                    </div>
                );
            });
        }
    };

    /***** RENDER *****/
    return (
        <form className="hostingAddons__form">
            {renderPurchasableAddons()}
            <ReduxFormButton
                form={formName}
                className="hostingAddons__submit"
                onClick={() => handleSubmit(selectedValues)}
                color={BUTTON_COLORS.CONFIRM}
                force={!hasSomeChecked ? REDUX_FORM_BUTTON_STATE.INACTIVE : undefined}
            >
                Confirm Addons
            </ReduxFormButton>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
HostingAddonForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(HostingAddonForm);

export default withRouter(
    connect((state, props) => {
        const selectedValues = getFormValues(formName)(state) || {};
        const initialValues = {};

        props.purchasable.forEach((addon) => {
            if (addon.config_list?.length > 0) {
                addon.config_list.forEach((configOption) => {
                    // add the base config option to initial values
                    initialValues[`${addon.id}__${configOption.id}`] = configOption.options[0].id;
                });
            }
        });

        return {
            hosting_information_data: state.hosting.hosting_information_data,
            app_viewport: state.app.app_viewport,
            form: formName,
            initialValues,
            selectedValues
        };
    })(HostingAddonForm)
);
