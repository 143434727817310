/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetHalonUrlMutation } from 'containers/email/queries/useGetHalonUrlMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Padding from 'components/Utils/Padding';
import { createPopup } from 'utilities/methods/commonActions';

import '../_email.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SpamFilteringLog = () => {
    /***** HOOKS *****/
    const { id: emailId } = useParams();

    /***** QUERIES *****/
    const { mutate: mutateGetHalonUrl } = useGetHalonUrlMutation();

    /***** FUNCTIONS *****/
    const handleClick = () => {
        const { goToTargetUrl, closePopup } = createPopup();

        mutateGetHalonUrl(emailId, {
            onSuccess: (res) => {
                goToTargetUrl(res.data.attributes.action);
            },
            onError: () => {
                closePopup();
            }
        });
    };

    /***** RENDER *****/
    return (
        <>
            <NXBox>
                <Padding xy={6}>
                    <NXBox.Title title="Email Spam Filtering Log" />
                    <NXBox.BottomColumns
                        columns={[
                            <NXBox.Description
                                key={1}
                                description="Easily access your inbound and outbound spam filtering logs to identify any issues with your emails. For example, you can check if an important email was caught in the spam filter."
                            />,
                            <div key={2} className="spamFilteringButton">
                                <OutlineButton width="full" size="large" color="primary" onClick={handleClick}>
                                    Access Spam Filtering
                                </OutlineButton>
                            </div>
                        ]}
                    ></NXBox.BottomColumns>
                </Padding>
            </NXBox>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
