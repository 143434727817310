/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';
import type { TMutationOptions } from 'utilities/methods/tanstack/types';

type UploadFileMutationOptions = TMutationOptions<typeof KATANA_API.katana.service_id.uploads.POST>;

function createMutationKey(serviceID: number) {
    return createBaseQueryKey(/** @type {const} */ ['katana', 'service', serviceID, 'upload-file']);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: number, options: UploadFileMutationOptions = {}) {
    function mutationFn(formData: KatanaAPI.Katana.ServiceID.Uploads.POST.Parameters['formData']) {
        return KATANA_API.katana.service_id.uploads.POST({ serviceID, formData });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey: createMutationKey(serviceID),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const uploadFile = Object.freeze({
    useMutation: _useMutation,
    createMutationKey
});
