/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EmailForwarder from 'containers/domain/forms/forwarders/emailForwarder';
import WebForwarder, { SUB_DOMAIN, WHOLE_DOMAIN } from 'containers/domain/forms/forwarders/webForwarder';
import RecordsForm from '../../forms/records';
import DomainTable from './tables/domainTable';
import EmailTable from './tables/emailTable';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import RequestLoader from 'components/Loaders/Request';
import Note from 'components/Note';

/*   ACTIONS
 *****************************************************/
import {
    addEmailForwarder,
    addWebForwarder,
    editEmailForwarder,
    editWebForwarder,
    getEmailForwarders,
    getWebForwarders,
    overrideCurrentConfiguration
} from '../../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Forwarders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLightbox: null,
            recordLightboxMode: null,
            recordEditID: null
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.handleWebForwarderSubmit = this.handleWebForwarderSubmit.bind(this);
        this.handleEmailForwarderSubmit = this.handleEmailForwarderSubmit.bind(this);
    }

    openLightbox(mode, recordID, type) {
        this.setState({
            showLightbox: type,
            recordLightboxMode: mode,
            recordEditID: recordID ?? null
        });
    }

    closeLightbox() {
        this.setState({
            showLightbox: null,
            recordLightboxMode: null,
            recordEditID: null
        });
    }

    handleWebForwarderSubmit(values) {
        const { domain_information_data, addWebForwarder, editWebForwarder } = this.props;
        const { recordLightboxMode, recordEditID } = this.state;

        const {
            forwarder_type,
            hostname,
            sub_domain,
            destination_protocol,
            destination,
            host_directory,
            wildcard_source,
            retain_path,
            sub_or_whole_domain,
            page_title,
            meta_description,
            meta_keywords,
            redirect_message,
            refresh_second
        } = values;

        function getConcatenatedSource() {
            if (sub_or_whole_domain === SUB_DOMAIN) {
                return `${sub_domain}.${hostname}/${host_directory}`;
            } else if (sub_or_whole_domain === WHOLE_DOMAIN) {
                return `${hostname}/${host_directory}`;
            }
        }

        const concatSource = getConcatenatedSource();
        const concatDestination = `${destination_protocol}${destination}`;

        if (recordLightboxMode === 'edit') {
            const newWebForwarder = {
                id: recordEditID,
                attributes: {
                    forwarder_type,
                    hostname: concatSource,
                    destination: concatDestination,
                    wildcard_source,
                    retain_path
                }
            };

            editWebForwarder(domain_information_data.id, newWebForwarder.id, newWebForwarder);
        } else {
            const newWebForwarder = {
                attributes: {
                    forwarder_type,
                    hostname: concatSource,
                    destination: concatDestination,
                    wildcard_source,
                    retain_path,
                    page_title,
                    meta_description,
                    meta_keywords
                }
            };

            if (forwarder_type === 'html_redirect') {
                newWebForwarder.attributes.redirect_message = redirect_message;
                newWebForwarder.attributes.refresh_second = refresh_second;
            }

            addWebForwarder(domain_information_data.id, newWebForwarder.attributes);
        }

        this.setState({
            showLightbox: null,
            recordLightboxMode: null,
            recordEditID: null
        });
    }

    handleEmailForwarderSubmit(values) {
        const { domain_information_data, addEmailForwarder, editEmailForwarder } = this.props;
        const { recordLightboxMode, recordEditID } = this.state;

        function getConcatenatedSource() {
            if (!values.source) {
                // <-- VALIDATED THE FORMAT OF THE SUBMITTED SOURCE
                return '';
            } else if (values.source && recordLightboxMode === 'edit') {
                return values.source.split('@')[0];
            } else {
                return values.source;
            }
        }

        const concatSource = getConcatenatedSource();

        const newEmailForwarder = {
            // <-- CREATE SUBMITTED RECORD
            attributes: {
                source: values.catch_all ? '' : concatSource,
                destination: values.destination,
                catchAll: values.catch_all
            }
        };

        if (recordLightboxMode === 'edit') {
            newEmailForwarder['id'] = recordEditID;
            editEmailForwarder(domain_information_data.id, newEmailForwarder.id, newEmailForwarder);
        } else {
            addEmailForwarder(domain_information_data.id, newEmailForwarder.attributes);
        }

        this.setState({
            showLightbox: null,
            recordLightboxMode: null,
            recordEditID: null
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { domainid, domain_current_configuration_data, getWebForwarders, getEmailForwarders } = this.props;

        if (domain_current_configuration_data && domain_current_configuration_data.attributes.config === 'forwarding') {
            getWebForwarders(domainid);
            getEmailForwarders(domainid);
        }
    }

    componentDidUpdate(prevProps) {
        const { overrideCurrentConfiguration, domain_email_record_status, domain_information_data } = this.props;

        if (domain_email_record_status === 'success' && prevProps.domain_email_record_status === 'loading')
            overrideCurrentConfiguration(domain_information_data.id);
    }

    render() {
        const {
            domain_web_record_list_status,
            domain_email_record_list_status,
            domain_web_record_status,
            domain_email_record_status,
            domain_web_record_store,
            domain_email_record_store
        } = this.props;
        const { showLightbox, recordLightboxMode, recordEditID } = this.state;
        const { handleWebForwarderSubmit, handleEmailForwarderSubmit, openLightbox, closeLightbox } = this;

        const processStatus = (status, listStatus) => {
            if (status === 'loading' || listStatus === 'loading') return 'loading';
            if (status === 'error' || listStatus === 'error') return 'error';
            return 'success';
        };

        /*   RENDER FUNCTIONS
         **********************************************************************************************************/

        const renderWebForwarders = () => {
            if (processStatus(domain_web_record_status, domain_web_record_list_status) === 'loading') return <RequestLoader />;

            if (showLightbox === 'web')
                return (
                    <div className="webForward__table--description">
                        <WebForwarder
                            mode={recordLightboxMode}
                            recordID={recordLightboxMode === 'edit' ? recordEditID : null}
                            closeLightbox={closeLightbox}
                            onSubmit={handleWebForwarderSubmit}
                        />
                    </div>
                );

            return (
                <Fragment>
                    {Array.isArray(domain_web_record_store) && domain_web_record_store.length <= 0 ? (
                        <div className="webForward__table--description">
                            <div className="text">Not Set</div>
                            <OutlineButton
                                className="action"
                                type="onClick"
                                onClick={() => {
                                    openLightbox('add', null, 'web');
                                }}
                            >
                                Add Domain Forwarder
                            </OutlineButton>
                        </div>
                    ) : (
                        <DomainTable openLightbox={openLightbox} />
                    )}
                </Fragment>
            );
        };

        const renderEmailForwarders = () => {
            if (processStatus(domain_email_record_status, domain_email_record_list_status) === 'loading') return <RequestLoader />;

            if (showLightbox === 'email')
                return (
                    <div className="webForward__table--description">
                        <EmailForwarder
                            mode={recordLightboxMode}
                            recordID={recordLightboxMode === 'edit' ? recordEditID : null}
                            closeLightbox={closeLightbox}
                            onSubmit={handleEmailForwarderSubmit}
                        />
                    </div>
                );

            return (
                <Fragment>
                    {Array.isArray(domain_email_record_store) && domain_email_record_store.length <= 0 ? (
                        <div className="webForward__table--description">
                            <div className="text">Not Set</div>
                            <OutlineButton
                                className="action"
                                type="onClick"
                                onClick={() => {
                                    openLightbox('add', null, 'email');
                                }}
                            >
                                Add Email Forwarder
                            </OutlineButton>
                        </div>
                    ) : (
                        <EmailTable openLightbox={openLightbox} />
                    )}
                </Fragment>
            );
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="forwarders"
            >
                <Fragment>
                    <Note beforeColon="Please note" text="Any changes to domain forwarders may take up to 1 hour to take effect." />
                    <div className="forwarders__subtitle domain_forwarding">Domain Forwarding</div>
                    {renderWebForwarders()}
                    <div className="forwarders__subtitle email_forwarding">Email Forwarding</div>
                    {renderEmailForwarders()}
                    <div className="forwarders__subtitle">DNS Records</div>
                    <RecordsForm forceView />
                </Fragment>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        domain_information_data: state.domain.domain_information_data,
        domain_current_configuration_data: state.domain.domain_current_configuration_data,
        domain_web_record_store: state.domain.domain_web_record_store,
        domain_email_record_store: state.domain.domain_email_record_store,
        domain_web_record_list_status: state.domain.domain_web_record_list_status,
        domain_web_record_status: state.domain.domain_web_record_status,
        domain_email_record_list_status: state.domain.domain_email_record_list_status,
        domain_email_record_status: state.domain.domain_email_record_status
    };
};

const mapDispatchToProps = {
    getEmailForwarders,
    addEmailForwarder,
    editEmailForwarder,
    getWebForwarders,
    addWebForwarder,
    editWebForwarder,
    overrideCurrentConfiguration
};

Forwarders = connect(mapStateToProps, mapDispatchToProps)(Forwarders);

Forwarders = withRouter(Forwarders);

export default Forwarders;
