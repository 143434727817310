/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { validatorDomainName, validatorIPV4, validatorIPV6 } from 'utilities/methods/validators';
import { COPY_invalidDomain, COPY_invalidIpAddress, migrationFormNameDetails, migrationFormNameFull } from '../consts';
import { checkIsTechnical, checkRequiredFields } from '../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const cpanelDetailsRequiredFields = ['current_ip', 'domain', 'username', 'password'];

const cpanelAllRequiredFields = [...cpanelDetailsRequiredFields, 'delete_data', 'email_reconfig', 'migration_date', 'migration_time'];

/**
 * Validate specific type details for cpanel details
 */
function validateCpanelDetailTypes(values, errors) {
    if (values.current_ip && !validatorIPV4(values.current_ip) && !validatorIPV6(values.current_ip)) {
        errors.current_ip = COPY_invalidIpAddress;
    }

    if (values.domain && !validatorDomainName(values.domain)) {
        errors.domain = COPY_invalidDomain;
    }

    return errors;
}

/**
 * Validating the cpanel details
 * @param values
 * @return {*|{}}
 */
export const CpanelDetailsValidation = (values) => {
    let errors = {};

    errors = validateCpanelDetailTypes(values, errors);

    errors = checkRequiredFields(values, errors, cpanelDetailsRequiredFields);

    return errors;
};

/**
 * Validate all cpanel fields
 * @param values
 * @return {*|{}}
 */
export const CpanelFormValidation = (values) => {
    let errors = {};

    errors = validateCpanelDetailTypes(values, errors);

    errors = checkRequiredFields(values, errors, cpanelAllRequiredFields);

    errors = checkIsTechnical(values, errors);

    return errors;
};

export const MigrationReduxCpanelFormPart1 = {
    form: migrationFormNameDetails,
    forceUnregisterOnUnmount: true,
    validate: CpanelDetailsValidation
};

export const MigrationReduxCpanelFormPart2 = {
    form: migrationFormNameFull,
    forceUnregisterOnUnmount: true,
    validate: CpanelFormValidation
};
