/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ventraSynMigrationHalfPriceStartDate, ventraSynMigrationHalfPriceEndDate } from 'config/config';

/*   ACTIONS
 *****************************************************/
import { isBetweenDates, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   METHODS
 **********************************************************************************************************/
export const vsmMethods = {
    isHalfPrice(freeUntilDate) {
        if (toLuxonDate(freeUntilDate, 'd LLLL y') > toLuxonDate(ventraSynMigrationHalfPriceEndDate, 'dd MMMM yyyy TT')) return false;

        return isBetweenDates(
            toLuxonDate(ventraSynMigrationHalfPriceStartDate, 'dd MMMM yyyy TT'),
            toLuxonDate(ventraSynMigrationHalfPriceEndDate, 'dd MMMM yyyy TT')
        );
    }
};
