/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FormRow.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const FormRow: React.FC<{ children: React.ReactNode }> = ({ children }) => <div className="FormRow">{children}</div>;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FormRow;
