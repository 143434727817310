/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const FormStep = ({ children }) => {
    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return <div className="migrationRequestForm__step">{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

FormStep.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default FormStep;
