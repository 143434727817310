/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ProcessHeader extends Component {
    render() {
        const { step, vsm_requires_migration_data } = this.props;

        // takes done, current and future as statuses
        const renderProcessItem = (text, icon, status, index) => {
            return (
                <div key={index} className={`vsHeader__item vsHeader__item--${status}`}>
                    <div className="vsHeader__itemContent">
                        <i className={`icon ${status === 'done' ? 'icon-valid' : icon}`} />
                        <div className="vsHeader__itemText">{text}</div>
                    </div>
                    <div className="vsHeader__itemBottom vsHeader__itemBottom--start"></div>
                    <div className="vsHeader__itemBottom vsHeader__itemBottom--end"></div>
                </div>
            );
        };

        const renderProcessItems = () => {
            const selectServiceStatus = step === 'select-services' ? 'current' : 'done';
            let confirmMigrationStatus = 'current';
            let createAccountStatus = 'future';
            switch (step) {
                case 'select-services':
                    confirmMigrationStatus = 'future';
                    createAccountStatus = 'future';
                    break;
                case 'create-account':
                    confirmMigrationStatus = 'done';
                    createAccountStatus = 'current';
                    break;
                default:
                    break;
            }

            const items = [
                {
                    text: 'Select Services',
                    icon: 'icon-sw-select-services',
                    status: selectServiceStatus
                },
                {
                    text: 'Confirm Migration',
                    icon: 'icon-sw-confirm-services',
                    status: confirmMigrationStatus
                },
                {
                    text: 'Create/Link Account',
                    icon: 'icon-sw-create-account',
                    status: createAccountStatus
                }
            ];

            // Skip the final step if user has already linked their account
            if (vsm_requires_migration_data?.has_linked_account) items.pop();

            return items.map((item, index) => renderProcessItem(item.text, item.icon, item.status, index));
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="vsHeader">
                <div className="vsHeader__spacer vsHeader__spacer--start"></div>
                {renderProcessItems()}
                <div className="vsHeader__spacer vsHeader__spacer--end"></div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect((state) => ({
        vsm_requires_migration_data: state.vsm.vsm_requires_migration_data
    }))(ProcessHeader)
);
