/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change, Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';
import { getActiveDomains } from 'containers/domain/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { emailFieldValidation, RenderField, requiredFieldValidation, validateEmailCompare } from 'utilities/methods/form';

const formName = 'changeEmailForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ChangeEmailForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domains: [],
            user_domains: [],
            isAllSelected: false,
            expandContact: false
        };
        this.addToSelection = this.addToSelection.bind(this);
        this.removeFromSelection = this.removeFromSelection.bind(this);
        this.expandDomainContact = this.expandDomainContact.bind(this);
    }

    addToSelection(item, { all = false } = {}) {
        const { domains } = this.state;

        this.setState({
            isAllSelected: all,
            domains: all ? item.map(({ id }) => id) : [...domains, item]
        });
    }

    removeFromSelection(item, { all = false } = {}) {
        const { domains } = this.state;

        this.setState({
            isAllSelected: false,
            domains: all ? [] : domains.filter((domain) => domain !== item)
        });
    }

    expandDomainContact() {
        const { expandContact } = this.state;

        this.setState({
            expandContact: !expandContact
        });
    }

    componentDidMount() {
        const { form, dispatch, getActiveDomains } = this.props;

        dispatch(change(form, 'domain_list', []));

        getActiveDomains();
    }

    componentDidUpdate(prevProps, prevState) {
        const { form, dispatch, domain_active_status, domain_active_data } = this.props;
        const { domains } = this.state;

        if (domain_active_status === 'success' && prevProps.domain_active_status === 'loading') {
            this.setState({
                user_domains: domain_active_data.filter(({ attributes: { id_protection } }) => !id_protection)
            });
        }

        if (domains !== prevState.domains) {
            dispatch(change(form, 'domain_list', domains));
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { handleSubmit, domain_active_status } = this.props;
        const { user_domains, domains, isAllSelected, expandContact } = this.state;
        const { addToSelection, removeFromSelection, expandDomainContact } = this;

        const renderUpdateDomainContact = () => {
            return (
                <div className="changeEmail__toggle">
                    <button type="button" className="changeEmail__toggle--button" onClick={() => expandDomainContact()}>
                        <span>Update my domain name contacts with this email address too</span>
                        <i className="icon icon-arrow" />
                    </button>
                </div>
            );
        };

        const renderDomainContact = () => {
            return (
                <>
                    <DialogNotification>
                        PLEASE NOTE: Keeping your domain name contacts is important.
                        <br />
                        Your registrant email address is sent expiry notices and
                        <br />
                        correspondence regarding this domain name.
                    </DialogNotification>
                    <div className="changeEmail__items">
                        <div className="changeEmail__items--description">Domains with ID Protection enabled will not appear below.</div>
                        {user_domains.length >= 2 && (
                            <button
                                type="button"
                                className={`changeEmail__item ${isAllSelected ? 'selected' : ''}`}
                                onClick={() => {
                                    if (isAllSelected) removeFromSelection(user_domains, { all: true });
                                    else addToSelection(user_domains, { all: true });
                                }}
                            >
                                <span className="checkbox" />
                                <div className="changeEmail__item--all">Select all domains</div>
                            </button>
                        )}
                        {user_domains.map(({ id, attributes: { domain } }, index) => {
                            return (
                                <button
                                    key={`${id}-${index}`}
                                    type="button"
                                    className={`changeEmail__item ${domains.includes(id) ? 'selected' : ''}`}
                                    onClick={() => {
                                        if (domains.length >= 1 && domains.includes(id)) removeFromSelection(id);
                                        else addToSelection(id);
                                    }}
                                >
                                    <span className="checkbox" />
                                    <div className="changeEmail__item--domain">{domain}</div>
                                </button>
                            );
                        })}
                    </div>
                </>
            );
        };

        return (
            <>
                {domain_active_status === 'loading' ? (
                    <RequestLoader />
                ) : (
                    <form className="changeEmail__form" onSubmit={handleSubmit}>
                        <div className="form__row">
                            <div className="form__column full">
                                <Field
                                    component={RenderField}
                                    label="New Email Address"
                                    name="new_email"
                                    type="text"
                                    placeholder=""
                                    validate={[requiredFieldValidation, emailFieldValidation]}
                                    className="form__textfield"
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__column full">
                                <Field
                                    component={RenderField}
                                    label="Confirm New Email Address"
                                    name="confirm_new_email"
                                    type="text"
                                    placeholder=""
                                    validate={[requiredFieldValidation, emailFieldValidation, validateEmailCompare]}
                                    className="form__textfield"
                                />
                            </div>
                        </div>
                        {user_domains.length >= 1 && renderUpdateDomainContact()}
                        {expandContact && renderDomainContact()}
                        <Field name="domain_list" component={RenderField} type="hidden" />
                        <ReduxFormButton form={formName}>Change Email Address</ReduxFormButton>
                    </form>
                )}
            </>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ChangeEmailForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(ChangeEmailForm);

const mapStateToProps = (state) => {
    return {
        domain_active_status: state.domain.domain_active_status,
        domain_active_data: state.domain.domain_active_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getActiveDomains
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeEmailForm));
