/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ReferralLightbox from 'containers/dashboard/modules/referralLightbox';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { closeModalActions } from 'App/action';
import { getReferralCode } from 'containers/dashboard/action';
import { useVipRewards } from 'containers/vipRewards/hooks';
import { formatLuxonOrdinal, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const referralReadMoreLink = 'https://ventraip.com.au/support-centre/everything-about-ventraip-australias-referral-program/';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Referral extends Component {
    constructor(props) {
        super(props);
        this.generateSuccessfulMatrix = this.generateSuccessfulMatrix.bind(this);
        this.openReferralForm = this.openReferralForm.bind(this);
        this.closeReferralForm = this.closeReferralForm.bind(this);
        this.state = {
            showReferralForm: false,
            fadeout: false
        };

        this.lightboxInitialFocusRef = createRef();
    }

    closeReferralForm = () => {
        const { history } = this.props;

        closeModalActions();

        this.setState({
            fadeout: true
        });

        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            // Remove any google analytics tracking params from the url
            history.push(history.location.pathname);

            this.setState({
                showReferralForm: false
            });
        }, 300);
    };

    openReferralForm = () => {
        this.setState({
            fadeout: false,
            showReferralForm: true
        });
    };

    generateSuccessfulMatrix() {
        const { dashboard_referral_banner_data } = this.props;

        if (dashboard_referral_banner_data && dashboard_referral_banner_data.length > 0) {
            return Object.keys(dashboard_referral_banner_data)
                .filter(function (key) {
                    return (
                        dashboard_referral_banner_data[key].attributes.status === 'redeemed' ||
                        dashboard_referral_banner_data[key].attributes.status === 'eligible' ||
                        dashboard_referral_banner_data[key].attributes.status === 'paid_out'
                    );
                })
                .map(function (key) {
                    const { user, redeemed_at, pays_out, status } = dashboard_referral_banner_data[key].attributes;

                    function getPayoutIn() {
                        if (status === 'paid_out') return 'Referral paid out';
                        if (!pays_out) return 'Payout Pending';
                        return `${pays_out} ${pays_out <= 1 ? 'Day' : 'Days'}`;
                    }

                    return {
                        used_by: user,
                        redeemed_on: formatLuxonOrdinal(toLuxonDate(redeemed_at).toFormat('{d} MMM yyyy')),
                        payout_in: getPayoutIn()
                    };
                });
        }
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const {
            location: { search }
        } = this.props;
        const { openReferralForm } = this;

        if (search.startsWith('?referral=true')) openReferralForm();
    }

    componentDidUpdate(prevProps) {
        const {
            dashboard_referral_banner_status,
            location: { search }
        } = this.props;
        const { openReferralForm } = this;

        if (search.startsWith('?referral=true') && !(prevProps.location?.search || '').startsWith('?referral=true')) openReferralForm();

        registerScrollEvents(this, dashboard_referral_banner_status === 'success' && prevProps.dashboard_referral_banner_status === 'loading');
    }

    render() {
        const { getReferralCode, dashboard_referral_banner_status } = this.props;
        const { closeReferralForm, lightboxInitialFocusRef } = this;
        const { showReferralForm, fadeout } = this.state;

        return (
            <div ref={(el) => (this.scrollRef = el)} className="accountReferral">
                <div className="referral__main">
                    <div className="referral__header">
                        <div className="referral__header--container">
                            <div className="referral__header--text">
                                Refer a Friend and earn <RenderReferralReward /> CASH
                            </div>
                            <SolidButton className="referral__header--button" to="/account/general/referrals?referral=true">
                                Refer a Friend
                            </SolidButton>
                        </div>
                    </div>

                    <Box
                        request={{
                            action: getReferralCode,
                            args: false,
                            status: dashboard_referral_banner_status
                        }}
                        status={dashboard_referral_banner_status}
                        custom={{
                            render: (
                                <div className="referral__box">
                                    <div className="referral__wrapper">
                                        <div className="referral__box--heading">Refer a Friend</div>
                                        <div className="referral__box--description">
                                            Refer someone to VentraIP Australia and when they purchase a Domain Name and Hosting service, you&apos;ll
                                            receive <RenderReferralReward /> cash. New referrals are paid out 60 days after an eligible purchase is
                                            made.
                                        </div>
                                    </div>
                                    <div className="referral__tables">
                                        <div className="referral__tables--heading">Successful Referrals</div>
                                        <Table
                                            className="referral__table"
                                            header={[
                                                {
                                                    title: `USED BY`,
                                                    className: `used`,
                                                    datalabel: `used_by`
                                                },
                                                {
                                                    title: `REDEEMED ON`,
                                                    className: `redeemed`,
                                                    datalabel: `redeemed_on`
                                                },
                                                {
                                                    title: `ELIGIBLE FOR PAYOUT IN`,
                                                    className: `eligible`,
                                                    datalabel: `payout_in`,
                                                    tooltip: `Payouts occur 60 days after a customer makes an eligible purchase.`
                                                }
                                            ]}
                                            matrix={this.generateSuccessfulMatrix()}
                                            stacked={true}
                                            error={`You don't have any successful referrals yet.`}
                                        />
                                    </div>
                                    <Anchor className="referral__box--link" target="_blank" href={referralReadMoreLink}>
                                        Learn more about VentraIP Australia&apos;s Referral Program
                                    </Anchor>
                                </div>
                            ),
                            pos: 'top'
                        }}
                    />
                    <OverlayLightbox
                        className={classNames('referral', { fadeout: fadeout })}
                        onOpen={showReferralForm}
                        onClose={closeReferralForm}
                        removeCloseButton={true}
                        initialFocus={lightboxInitialFocusRef}
                    >
                        <ReferralLightbox closeReferralForm={closeReferralForm} lightboxInitialFocusRef={lightboxInitialFocusRef} />
                    </OverlayLightbox>
                </div>
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const RenderReferralReward = () => {
    /***** HOOKS *****/
    const { isAtOrAboveTier } = useVipRewards();

    /***** RENDER *****/
    return `$${isAtOrAboveTier('silver') ? 75 : 50}`;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            dashboard_referral_banner_status: state.dashboard.dashboard_referral_banner_status,
            dashboard_referral_banner_data: state.dashboard.dashboard_referral_banner_data
        }),
        {
            getReferralCode
        }
    )(Referral)
);
