/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';
import { Subset } from 'components/Subset';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { OwnRenderActions } from './actions';
import { OwnRenderExpiryInfo } from './expiry';
import { OwnRenderServiceStatus } from './status';
import { OwnRenderServiceTitle } from './title';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useSSLOverviewContext } from '../../own';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NSSLTable.TSSLTableBody} TSSLTableBody
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private For use in the SSLTable component.
 * @type {TSSLTableBody}
 */
export const SSLTableBody = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const { isExpressServiceManager, numberOfRows, showExpiredSSLs } = useSSLOverviewContext();

    /***** RENDER HELPERS *****/
    const _data = data?.filter((data) => showExpiredSSLs || data.attributes.status !== 'expired');
    const classes = {
        wrapper: 'SSLOverviewBody',
        row: classNames('SSLOverviewBody__row', {
            'SSLOverviewBody__row--mobile': isMobile
        })
    };

    /***** RENDER *****/
    if (_data.length === 0) {
        return (
            <Text secondary size--xss align--center className="SSLOverviewBody__empty">
                No SSLs matched your search.
            </Text>
        );
    }

    return (
        <div className={classes.wrapper}>
            <WrapWithComponent component={NXTable.Accordion.ToggleWrapper} wrap={isMobile}>
                <Subset range={isExpressServiceManager ? [0, 5] : [0, numberOfRows]} data={_data}>
                    {(data, index) =>
                        isMobile ? (
                            <NXTable.Accordion
                                key={data.id}
                                index={index ?? 0}
                                content={data.attributes.status !== 'expired' && <OwnRenderActions data={data} />}
                            >
                                <OwnRenderServiceTitle data={data} />
                                <OwnRenderServiceStatus data={data} />
                            </NXTable.Accordion>
                        ) : (
                            <NXTable.Row className={classes.row} key={data.id}>
                                <OwnRenderServiceTitle data={data} />
                                <OwnRenderServiceStatus data={data} />
                                <OwnRenderExpiryInfo data={data} />
                                <OwnRenderActions data={data} />
                            </NXTable.Row>
                        )
                    }
                </Subset>
            </WrapWithComponent>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
