/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import './_KatanaSetup.scss';

const { SETUP } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaSetup = function KatanaSetup() {
    /***** HOOKS *****/
    const { getKatanaDestination } = useKatanaURL();

    /***** RENDER *****/
    return (
        <NXBox className="KatanaSetup">
            <NXBox.Top
                title="Get ready to setup your website"
                description="Start building your website today with VIPsites. Add your details, content, and images to create your online presence. Let's turn your ideas into a stunning website that reflects your unique style!"
            >
                <SolidButton to={getKatanaDestination(SETUP)}>Setup website</SolidButton>
            </NXBox.Top>
        </NXBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { KatanaSetup };
