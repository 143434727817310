/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineTag, { TAG_COLOURS } from 'components/Tags/OutlineTag';
import { INVOICE_STATUSES, INVOICE_STATUS_VALUES } from 'utilities/consts';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function getTagStatus(status, is_overdue) {
    if (status === INVOICE_STATUSES.UNPAID && is_overdue === true) {
        return TAG_COLOURS.WARN;
    }
    switch (status) {
        case INVOICE_STATUSES.UNPAID:
            return TAG_COLOURS.NOTICE;
        case INVOICE_STATUSES.PAID:
            return TAG_COLOURS.CONFIRM;
        case INVOICE_STATUSES.CANCELLED:
            return TAG_COLOURS.SECONDARY;
        default:
            return TAG_COLOURS.PRIMARY;
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const InvoiceStatusTag = ({ status, is_overdue }) => {
    /***** RENDER HELPERS *****/
    const tagStatus = getTagStatus(status, is_overdue);
    /***** RENDER *****/
    return <OutlineTag color={tagStatus}>{tagStatus === TAG_COLOURS.WARN ? 'Overdue' : status}</OutlineTag>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
InvoiceStatusTag.propTypes = {
    /**
     * The status of an invoice
     */
    status: PropTypes.oneOf(INVOICE_STATUS_VALUES),

    /**
     * if the invoice is overdue
     */
    is_overdue: PropTypes.bool
};

export default InvoiceStatusTag;
