/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/*   ACTIONS
 *****************************************************/
import { getLocalStates } from 'App/action';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type Option = {
    label: string;
    value: string;
};
type LocalStatesState = {
    app?: {
        app_local_states_data: {
            rawStatesData: Artah.LocalState.ID.GET._200['data'];
            processedOptions: {
                au: Option[];
                nz: Option[];
            };
        };
        app_local_states_status: 'loading' | 'success' | 'error' | null;
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useLocalStates() {
    /***** HOOKS *****/
    const state = useSelector((state: LocalStatesState) => ({
        app_local_states_data: state.app.app_local_states_data,
        app_local_states_status: state.app.app_local_states_status
    }));

    /***** EFFECTS *****/
    useEffect(() => {
        if (state.app_local_states_status === 'loading' || state.app_local_states_data) return;
        getLocalStates();
    });

    /***** HOOK RESULTS *****/
    return state;
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
