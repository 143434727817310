/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows the domain renewal amount for 1 year renewal
 * @param {{
 *      renewalAmount: string
 * }} props
 */
export function DomainRenewalAmount({ renewalAmount }) {
    /***** RENDER *****/
    if (!renewalAmount) {
        return null;
    }

    return (
        <NXBox.OverviewRow>
            <div>
                <Flex items="center" gap={1}>
                    <Text bold uppercase secondary size--xs>
                        Renewal Amount
                    </Text>
                    <Tooltip
                        className="domainOverview__renewalAmountTooltip"
                        info={htmr(
                            "<div>Please note this is the <strong>current</strong> renewal price for this domain name extension and may be subject to change.  Deals on domain name renewals and VIPrewards discounts will be applied when you click the 'Renew' button.</div>"
                        )}
                    />
                </Flex>
                <Text>${renewalAmount}/yr</Text>
            </div>
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainRenewalAmount;
