/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import PricingTable from 'components/PricingTable';
import { Flex } from 'components/Utils/Flex';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { toLuxonDate } from 'utilities/methods/commonActions';
import { calculateMs365UpdateConfig, updateMs365Config } from '../action';
import { ms365Methods } from '../methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ManageSeatsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            configs: ms365Methods.prepareConfigs(props.ms365_information_data),
            proposedConfigs: [],
            typing: false
        };

        this.addSeat = this.addSeat.bind(this);
        this.removeSeat = this.removeSeat.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.calculateNewCost = this.calculateNewCost.bind(this);
        this.handleUpdateConfigs = this.handleUpdateConfigs.bind(this);

        this.debounceTimer = null;
    }

    addSeat(indexToUpdate) {
        const { configs } = this.state;
        const { calculateNewCost } = this;

        this.setState(
            {
                configs: configs.map((config, index) => ({
                    ...config,
                    newQuantity: index === indexToUpdate ? config.newQuantity + 1 : config.newQuantity
                }))
            },
            calculateNewCost
        );
    }

    removeSeat(indexToUpdate) {
        const { configs } = this.state;
        const { calculateNewCost } = this;

        this.setState(
            {
                configs: configs.map((config, index) => ({
                    ...config,
                    newQuantity: index === indexToUpdate ? config.newQuantity - 1 : config.newQuantity
                }))
            },
            calculateNewCost
        );
    }

    inputOnChange(value, indexToUpdate) {
        const { configs } = this.state;

        this.setState(
            {
                typing: true,
                proposedConfigs: configs.map((config, index) => ({
                    ...config,
                    // Stage the typed value in "proposedConfigs" for now, even though it will be a string. It will be converted to a number, or scrapped in componentDidUpdate
                    newQuantity: index === indexToUpdate ? value : config.newQuantity
                }))
            },
            () => {
                // Remove the current debounce timer if there is one
                if (this.debounceTimer) clearTimeout(this.debounceTimer);

                // After 1.5 seconds, set typing to false and let componentDidUpdate handle deciding whether the typed value is valid or not
                this.debounceTimer = setTimeout(() => {
                    this.setState({
                        typing: false
                    });
                }, 1500);
            }
        );
    }

    calculateNewCost() {
        const { configs } = this.state;
        const { calculateMs365UpdateConfig, ms365_information_data } = this.props;

        const attributes = {
            billing_cycle: 'Monthly',
            configs: configs.map((config) => ({
                config_id: config.config_id,
                option_id: config.option_id,
                quantity: config.newQuantity
            }))
        };

        calculateMs365UpdateConfig(ms365_information_data.id, attributes);
    }

    handleUpdateConfigs() {
        const { configs } = this.state;
        const { updateMs365Config, ms365_information_data } = this.props;

        const attributes = {
            billing_cycle: 'Monthly',
            configs: configs.map((config) => ({
                config_id: config.config_id,
                option_id: config.option_id,
                quantity: config.newQuantity
            }))
        };

        updateMs365Config(ms365_information_data.id, attributes);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps, prevState) {
        const { proposedConfigs, typing } = this.state;
        const { calculateNewCost } = this;

        if (!typing && prevState.typing) {
            // If any of the typed values are invalid, scrap them and show an error toast notification
            if (
                proposedConfigs.some(
                    ({ newQuantity, min_quantity, max_quantity }) =>
                        isNaN(Number(newQuantity)) || newQuantity > max_quantity || newQuantity < min_quantity
                )
            ) {
                return this.setState(
                    {
                        proposedConfigs: []
                    },
                    () => {
                        pushNotification({ status: 500, details: 'Please enter a numeric value that does not exceed the allowed maximum.' });
                    }
                );
            }

            // If all typed values are valid, set them as the new configs and reset the proposed configs
            this.setState(
                {
                    configs: proposedConfigs.map((proposedConfig) => ({
                        ...proposedConfig,
                        newQuantity: typeof proposedConfig.newQuantity === 'string' ? Number(proposedConfig.newQuantity) : proposedConfig.newQuantity
                    })),
                    proposedConfigs: []
                },
                calculateNewCost
            );
        }
    }

    render() {
        const { ms365_update_config_calculate_status, ms365_update_config_calculate_data, ms365_information_data } = this.props;
        const { configs, proposedConfigs, typing } = this.state;
        const { addSeat, removeSeat, inputOnChange, handleUpdateConfigs } = this;

        const quantityTotals = configs.reduce(
            (previous, current) => {
                return {
                    quantity: previous.quantity + current.quantity,
                    newQuantity: previous.newQuantity + current.newQuantity
                };
            },
            {
                quantity: 0,
                newQuantity: 0
            }
        );

        const classes = {
            removeButton: ({ quantity, newQuantity }) => {
                return classNames('calculator__button', {
                    'calculator__button--lock': newQuantity <= quantity || typing
                });
            },
            addButton: ({ newQuantity, max_quantity }) => {
                return classNames('calculator__button', {
                    'calculator__button--lock': newQuantity >= max_quantity || typing
                });
            }
        };

        const renderAddRemoveItems = () => {
            const configsToRender = typing ? proposedConfigs : configs;

            return configsToRender.map(({ config_id, monthly_price, newQuantity, max_quantity, unit_name, name, quantity }, index) => (
                <div key={config_id} className="addRemove__item">
                    <h5 className="addRemove__itemTitle">
                        {name}
                        <br />
                        {unit_name} (Max: {max_quantity}) <span>${monthly_price} /seat/mo</span>
                    </h5>
                    <div className="addRemove__form">
                        <button
                            type="button"
                            onClick={() => {
                                if (newQuantity > quantity && !typing) {
                                    removeSeat(index);
                                }
                            }}
                            disabled={newQuantity <= quantity || typing}
                            className={classes.removeButton({ quantity, newQuantity })}
                        >
                            <i className="icon icon-minus"></i>
                        </button>
                        <input
                            className="calculator__field"
                            type="text"
                            inputMode="numeric"
                            onChange={(e) => inputOnChange(e.target.value, index)}
                            value={newQuantity}
                        />
                        <button
                            type="button"
                            onClick={() => {
                                if (newQuantity < max_quantity && !typing) {
                                    addSeat(index);
                                }
                            }}
                            className={classes.addButton({ newQuantity, max_quantity })}
                        >
                            <i className="icon icon-plus-faq"></i>
                        </button>
                    </div>
                </div>
            ));
        };

        const renderNewCosts = () => {
            if (!ms365_information_data) return '';

            const currentCost = ms365_information_data.attributes.amount;

            const totals = {
                ...quantityTotals,
                cost: currentCost,
                newCost: ms365_update_config_calculate_data?.attributes?.new_plan_cost || currentCost,
                totalDueToday: ms365_update_config_calculate_data?.attributes?.amount_due
            };

            return (
                <>
                    <div className="summary">
                        <div className="title">Current Seats</div>
                        <div className="value">{totals.quantity}</div>
                    </div>
                    <div className="summary">
                        <div className="title">
                            <span>NEW</span> Total Seats
                        </div>
                        <div className="value highlight">{totals.newQuantity}</div>
                    </div>
                    {ms365_update_config_calculate_status === 'error' ? (
                        <div className="summary summary--error">Error calculating price.</div>
                    ) : (
                        <>
                            <div className="summary">
                                <div className="title">Current Monthly Cost</div>
                                <div className="value">${totals.cost} AUD</div>
                            </div>
                            <div className="summary">
                                <div className="title">
                                    <span>NEW</span> Monthly Cost
                                </div>
                                <div className="value highlight">${totals.newCost} AUD</div>
                            </div>
                            <div className="summary--subtitle">
                                Your next billing date is {toLuxonDate(ms365_information_data?.attributes.next_due_date).toFormat('ccc, MMM d yyyy')}
                            </div>
                            <PricingTable
                                slim
                                total={{
                                    amount: `$${totals.totalDueToday}`,
                                    label: 'Total Due Today'
                                }}
                            />
                        </>
                    )}
                </>
            );
        };

        const renderConfirmSeatsButton = () => {
            if (
                typing ||
                ms365_update_config_calculate_status === 'loading' ||
                configs.every(({ newQuantity, quantity }) => newQuantity === quantity)
            ) {
                return <InactiveButton className="ms365ManageSeats__form--action">Confirm Seats</InactiveButton>;
            }

            return (
                <SolidButton
                    className="ms365ManageSeats__form--action"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        handleUpdateConfigs();
                    }}
                >
                    Confirm Seats
                </SolidButton>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="ms365ManageSeats__form">
                <div className="ms365ManageSeats__form--container">
                    <div className="ms365ManageSeats__form--addRemove">
                        <div className="addRemove">
                            <div className="addRemove__body">
                                <div className="addRemove__label">
                                    Add or remove seats to <span>{ms365_information_data?.attributes.name || ''}</span>
                                </div>
                                {renderAddRemoveItems()}
                            </div>
                            <NXBox.Footer>
                                <Flex fullWidth justify="end">
                                    <Anchor href="https://ventraip.com.au/faq/article/microsoft-365-products/" target="_blank">
                                        Compare plans and view inclusions
                                    </Anchor>
                                </Flex>
                            </NXBox.Footer>
                        </div>
                    </div>
                    <div className="ms365ManageSeats__form--summary">
                        <div className="ms365ManageSeats__summaryContainer">
                            {ms365_update_config_calculate_status === 'loading' ? <RequestLoader /> : renderNewCosts()}
                        </div>
                        <div className="summary">{renderConfirmSeatsButton()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            ms365_information_data: state.ms365.ms365_information_data,
            ms365_update_config_calculate_status: state.ms365.ms365_update_config_calculate_status,
            ms365_update_config_calculate_data: state.ms365.ms365_update_config_calculate_data
        }),
        {
            calculateMs365UpdateConfig,
            updateMs365Config
        }
    )(ManageSeatsForm)
);
