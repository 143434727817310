import _RadioCheckBoxPanel from 'components/Form/Radio/_CheckBoxCirclePanel';
import _IdentifierMatch from 'components/Form/Radio/_IdentifierMatch';
import _Radio from 'components/Form/Radio/_Radio';
import _RadioReduxFormDefault from 'components/Form/Radio/wrappers/reduxform/Default';

const Radio = Object.assign(_Radio, {
    ReduxForm: {
        Default: _RadioReduxFormDefault
    },
    CheckBoxPanel: _RadioCheckBoxPanel,
    IdentifierMatch: _IdentifierMatch
});

export default Radio;
