/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CpanelForm from './cpanel/cpanelForm';
import ControlPanelForm from './control_panel/controlPanelForm';
import OtherForm from './other/otherForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MigrationServiceTypes } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const RenderSelectedForm = (props) => {
    const { selectedEnv, selectedService } = props;

    const { CPANEL, CONTROL_PANEL, OTHER } = MigrationServiceTypes;

    /**
     * Set default Environment.
     * The CONTROL_PANEL environment is selected if it's not OTHER or CPANEL,
     * this is because the same form is used wether it's ZAdmin/Plesk etc.
     */
    const selectedEnvLowerCase = selectedEnv.toLowerCase();
    let decipheredEnv = OTHER;

    if (selectedEnvLowerCase === CPANEL) {
        decipheredEnv = CPANEL;
    } else if (selectedEnvLowerCase !== OTHER) {
        decipheredEnv = CONTROL_PANEL;
    }

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    switch (decipheredEnv) {
        case MigrationServiceTypes.CPANEL:
            return <CpanelForm currentEnv={selectedEnv} selectedService={selectedService} />;
        case MigrationServiceTypes.CONTROL_PANEL:
            return <ControlPanelForm currentEnv={selectedEnv} selectedService={selectedService} />;
        case MigrationServiceTypes.OTHER:
        default:
            return <OtherForm currentEnv={selectedEnv} selectedService={selectedService} />;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
RenderSelectedForm.propTypes = {
    /**
     * The selected environment, i.e. cpanel, other, control_panel
     */
    selectedEnv: PropTypes.string.isRequired,

    /**
     * the id for the selected service you wish to migrate to
     */
    selectedService: PropTypes.number.isRequired
};

export default RenderSelectedForm;
