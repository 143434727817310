/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';
import type { Updater, Writable } from 'svelte/store';
import { get } from 'svelte/store';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated we're moving to using tanstack/store, this hook will be removed in the future.
 * Automatically updates the value of the state when the original writable input value changes.
 */
export function useStore<TStore extends unknown>(store: Writable<TStore>): [TStore, (v: TStore) => void, (updater: Updater<TStore>) => void] {
    /***** STATE *****/
    const [storeState, setStoreState] = useState(get(store));

    /***** EFFECTS *****/
    useEffect(() => {
        const unsubscribe = store.subscribe(setStoreState);
        return () => {
            unsubscribe();
        };
    }, []);

    /***** HOOK RESULTS *****/
    return [storeState, store.set, store.update] as const;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
