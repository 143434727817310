/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment, createContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ConfirmationLightbox } from 'containers/vps';
import AddAddonLightbox from '../forms/addAddonLightbox';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';
import DialogNotification from 'components/Notifications/DialogNotification';
import Table from 'components/Table';
import OutlineTag from 'components/Tags/OutlineTag';
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/*   ACTIONS
 *****************************************************/
import { getServiceUpgradePaths } from 'containers/services/action';
import { cancelAddon, getVPSAddons } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { servicesMethods } from 'containers/services/methods';
import { vpsMethods, vpsServerTypes } from 'containers/vps/methods';
import { toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { addonTypes } from '../consts';

const ResourceAddonsContext = createContext({
    ResourceAddons: null
});

export const getStatus = (status, attributes) => {
    const { invoices } = attributes;
    let addon_status = status;

    if (invoices && invoices.length > 0 && addon_status !== 'suspended') {
        addon_status = 'unpaid';
    }

    return addon_status;
};

export const RenderStatus = (props) => {
    let tagstatus = '';
    const { status, attributes } = props;
    const addon_status = getStatus(status, attributes);

    if (addon_status === 'unpaid') {
        tagstatus = 'notice';
    } else if (addon_status === 'suspended') {
        tagstatus = 'warn';
    } else if (addon_status === 'active') {
        tagstatus = 'confirm';
    }

    return <OutlineTag color={tagstatus}>{addon_status}</OutlineTag>;
};

export const renderButton = ({ status, attributes, ResourceAddons, id, type }) => {
    const addon_status = getStatus(status, attributes);
    if (addon_status === 'unpaid') {
        const { invoices } = attributes;
        const invoiceID = invoices[0];

        return {
            label: 'Pay Invoice',
            type: 'onClick',
            className: 'VPS-Resource-Addons-Item__dropdown',
            size: 'small',
            color: 'primary',
            button: 'Solid',
            onClick: (e) => {
                if (invoiceID) {
                    e.preventDefault();
                    ResourceAddons.setState({
                        ...ResourceAddons.state,
                        selectedInvoice: invoiceID,
                        payInvoiceLightbox: true
                    });
                }
            },
            list: []
        };
    } else if (addon_status === 'active') {
        if (type !== addonTypes.DISK) {
            return {
                label: 'Remove',
                type: 'onClick',
                className: 'VPS-Resource-Addons-Item__dropdown',
                size: 'small',
                color: 'warn',
                onClick: (e) => {
                    e.preventDefault();
                    ResourceAddons.setState({
                        ...ResourceAddons.state,
                        removeAddonLightbox: true,
                        addonToBeRemoved: {
                            id,
                            attributes
                        }
                    });
                }
            };
        } else {
            return (
                <HoverTooltip
                    alignment="right"
                    color="black"
                    className="VPS-Resource-Addons-Item__tooltip"
                    content={
                        <>
                            PLEASE NOTE: To remove storage you will need to contact our Support Team by{' '}
                            <Anchor to="/support/tickets/submit/technical-support">submitting an eTicket.</Anchor>
                        </>
                    }
                >
                    <InactiveButton className="VPS-Resource-Addons-Item">
                        <i className="icon icon-lock"></i>
                        Remove
                    </InactiveButton>
                </HoverTooltip>
            );
        }
    } else if (addon_status === 'pending cancellation') {
        return <div style={{ width: '110px' }} />;
    } else {
        const { invoices } = attributes;
        const invoiceID = invoices[0];

        return {
            label: 'Renew',
            type: 'onClick',
            className: 'VPS-Resource-Addons-Item__dropdown',
            size: 'small',
            color: 'error',
            button: 'Solid',
            onClick: (e) => {
                e.preventDefault();
                if (invoiceID) {
                    ResourceAddons.setState({
                        ...ResourceAddons.state,
                        selectedInvoice: invoiceID,
                        payInvoiceLightbox: true
                    });
                }
            },
            list: []
        };
    }
};

class ResourceAddonsItem extends Component {
    constructor(props) {
        super(props);
        this.getTableMatrix = this.getTableMatrix.bind(this);
        this.openAddAddonLightbox = this.openAddAddonLightbox.bind(this);
    }

    getTableMatrix({ ResourceAddons, type }) {
        const { addons } = this.props;
        let matrix = [];

        if (addons && addons.length > 0) {
            matrix = Array(addons.length).fill();
            Object.keys(matrix).forEach((value) => {
                const { attributes, id } = addons[value];
                const { name, billing_cycle, price, status, renewal_date, expiry_date } = attributes;
                const date = toLuxonDate(renewal_date);
                const addon_status = getStatus(status, attributes);
                const buttonData = { status, attributes, ResourceAddons, id, type };
                if (addon_status === 'suspended') {
                    matrix[value] = {
                        license: (
                            <div className="VPS-Resource-Addons-Item__license">
                                <div className="VPS-Resource-Addons-Item__license-name">{name}</div>
                                <div className="VPS-Resource-Addons-Item__license-price">
                                    ${price}/{billing_cycle}
                                </div>
                            </div>
                        ),
                        status: (
                            <div className="VPS-Resource-Addons-Item__suspend-note">
                                Pending Deletion{expiry_date ? ` - expires ${toLuxonDate(expiry_date).toFormat('dd/MM/yyyy')}` : ''}
                            </div>
                        ),
                        actions: renderButton(buttonData),
                        className: 'suspended-row'
                    };
                } else {
                    matrix[value] = {
                        license: (
                            <div className="VPS-Resource-Addons-Item__license">
                                <div className="VPS-Resource-Addons-Item__license-name">{name}</div>
                                <div className="VPS-Resource-Addons-Item__license-price">
                                    ${price}/{billing_cycle}
                                </div>
                            </div>
                        ),
                        status: <RenderStatus status={status} attributes={attributes} />,
                        billing: <div className="VPS-Resource-Addons-Item__license-cycle">{billing_cycle}</div>,
                        renewal: <div className="VPS-Resource-Addons-Item__license-renewal">{date.toFormat(`dd/MM/yyyy`)}</div>,
                        actions: renderButton(buttonData)
                    };
                }
            });
        }
        return matrix;
    }

    openAddAddonLightbox(ResourceAddons, type) {
        ResourceAddons.setState({
            ...ResourceAddons.state,
            addingAddonLightbox: true,
            addAddonType: type
        });
    }

    render() {
        const { title, total, description, unit, isGrey, actionLabel, addons, type } = this.props;
        const { getTableMatrix, openAddAddonLightbox } = this;

        return (
            <ResourceAddonsContext.Consumer>
                {({ ResourceAddons }) => {
                    return (
                        <div
                            className={`VPS-Resource-Addons-Item VPS-Resource-Addons VPS-Resource-Addons__row${
                                isGrey ? ' VPS-Resource-Addons__row--grey' : ''
                            }`}
                        >
                            <div className="VPS-Resource-Addons__container">
                                <div className="VPS-Resource-Addons-Item__heading box__heading">{title}</div>
                                <div className="VPS-Resource-Addons-Item__description">{description}</div>
                                <div className="VPS-Resource-Addons-Item__allocation">
                                    <div className="VPS-Resource-Addons-Item__allocation-inner">
                                        <div className="VPS-Resource-Addons-Item__allocation-details">
                                            <div className="VPS-Resource-Addons-Item__allocation-label">Current Allocation</div>
                                            <div className="VPS-Resource-Addons-Item__allocation-value">
                                                {total}
                                                {unit ? unit : 'GB'}
                                            </div>
                                        </div>
                                        <div className="VPS-Resource-Addons-Item__allocation-actions">
                                            <OutlineButton
                                                className="VPS-Resource-Addons-Item__button"
                                                type="onClick"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openAddAddonLightbox(ResourceAddons, type);
                                                }}
                                            >
                                                {actionLabel}
                                            </OutlineButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {addons && addons.length > 0 ? (
                                <Table
                                    className="VPS-Resource-Addons-Item__table"
                                    embedded={true}
                                    header={[
                                        {
                                            title: `${title}/Price`,
                                            className: `${title}/price`
                                        },
                                        {
                                            title: `Status`,
                                            className: `status`
                                        },
                                        {
                                            title: `Billing Period`,
                                            className: `billingperiod`
                                        },
                                        {
                                            title: `Renewal Date`,
                                            className: `renewaldate`
                                        },
                                        {
                                            action: true
                                        }
                                    ]}
                                    matrix={getTableMatrix({ ResourceAddons, type })}
                                    error="You have no invoices at the moment. Why not go ahead and purchase some amazing services to get this list started?"
                                    stacked={true}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    );
                }}
            </ResourceAddonsContext.Consumer>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ResourceAddons extends Component {
    constructor(props) {
        super(props);
        this.renderResourceAddonContent = this.renderResourceAddonContent.bind(this);
        this.closePaymentLightbox = this.closePaymentLightbox.bind(this);
        this.closeRemoveAddonLightbox = this.closeRemoveAddonLightbox.bind(this);
        this.removeAddon = this.removeAddon.bind(this);
        this.closeAddingAddonLightbox = this.closeAddingAddonLightbox.bind(this);
        this.state = {
            payInvoiceLightbox: false,
            selectedInvoice: undefined,
            removeAddonLightbox: false,
            addonToBeRemoved: undefined,
            addingAddonLightbox: false,
            addAddonType: undefined
        };
    }

    componentDidUpdate(prevProps) {
        registerScrollEvents(this, true);
    }

    componentDidMount() {
        const { vpsid, service_change_plan_paths_status } = this.props;
        if (service_change_plan_paths_status !== 'loading') {
            getServiceUpgradePaths(vpsid);
        }
    }

    closePaymentLightbox() {
        this.setState({
            payInvoiceLightbox: false,
            selectedInvoice: undefined
        });
    }

    closeRemoveAddonLightbox() {
        this.setState({
            removeAddonLightbox: false,
            addonToBeRemoved: undefined
        });
    }

    renderResourceAddonContent() {
        const { vps_addon_list_data, vps_information_data } = this.props;
        if (vps_addon_list_data) {
            const { hardware } = vps_addon_list_data.attributes;

            const isAstro = vpsMethods.getVpsServerType(vps_information_data) === vpsServerTypes.ASTRO;

            return (
                <div className="VPS-Resource-Addons__wrapper">
                    <div className="VPS-Resource-Addons__row VPS-Resource-Addons__row--odd VPS-Resource-Addons__row--header">
                        <div className="VPS-Resource-Addons__container">
                            <div className="VPS-Resource-Addons__heading box__heading">Resource Addons</div>
                            <div className="box__description">Resource addons allow you to add additional resources to your VPS.</div>
                        </div>
                    </div>
                    <ResourceAddonsItem
                        title="vCPU"
                        total={hardware.totals.cpu}
                        addons={hardware.addons.cpu}
                        description={`Increase your VPS's performance by adding additional vCPU.`}
                        unit={` vCPU Cores`}
                        actionLabel="Add vCpu"
                        isGrey={true}
                        type={addonTypes.CPU}
                    />
                    <ResourceAddonsItem
                        title="Memory"
                        total={hardware.totals.ram}
                        addons={hardware.addons.ram}
                        description={`Increase your VPS's performance by adding additional memory.`}
                        actionLabel="Add Memory"
                        type={addonTypes.RAM}
                    />
                    <ResourceAddonsItem
                        title="SSD Storage"
                        total={hardware.totals.disk}
                        addons={hardware.addons.disk}
                        description={`Increase your VPS's Disk Space by adding additional SSD storage.`}
                        actionLabel="Add Storage"
                        isGrey={true}
                        type={addonTypes.DISK}
                    />
                    {isAstro ? (
                        ''
                    ) : (
                        <ResourceAddonsItem
                            title="Bandwidth"
                            total={hardware.totals.bandwidth}
                            addons={hardware.addons.bandwidth}
                            description={`Increase your VPS's Bandwidth allocation by adding additional Bandwidth Blocks to your service.`}
                            actionLabel="Add Bandwidth"
                            unit="GB /mo"
                            type={addonTypes.BANDWIDTH}
                        />
                    )}
                </div>
            );
        }
    }

    removeAddon() {
        const { vps_information_data, cancelAddon } = this.props;
        const { id } = vps_information_data;
        const { addonToBeRemoved } = this.state;
        const attributes = {
            addon_id: addonToBeRemoved.id
        };

        cancelAddon(id, attributes);
    }

    closeAddingAddonLightbox() {
        const { getVPSAddons, vps_information_data } = this.props;
        this.setState(
            {
                addingAddonLightbox: false
            },
            () => {
                const { id } = vps_information_data;
                getVPSAddons(id);
            }
        );
    }

    render() {
        const { renderResourceAddonContent, closePaymentLightbox, closeRemoveAddonLightbox, removeAddon, closeAddingAddonLightbox } = this;
        const { vps_addon_list_status, vps_information_data, getVPSAddons, vpsid, service_change_plan_paths_data, service_change_plan_paths_status } =
            this.props;
        const { payInvoiceLightbox, selectedInvoice, removeAddonLightbox, addonToBeRemoved, addingAddonLightbox, addAddonType } = this.state;

        const { attributes: { vps_type = null } = {}, id = '' } = vps_information_data;

        const renderResourceAddonUpgrade = () => {
            const isLegacy = vps_type === 'legacy';
            const isUpgradeable = servicesMethods.upgrade_plan_paths.canUpgrade(service_change_plan_paths_data);
            if (isLegacy || isUpgradeable) {
                return (
                    <NXBox className="VPS-Resource-Addons">
                        <NXBox.Top title="Resource Addons" description="Resource addons allow you to add additional resources to your VPS.">
                            <SolidButton
                                className="VPS-Resource-Addons__upgrade"
                                to={`/my-services/vps/account/upgrade-plan/${id}?upgrade-plan=true`}
                            >
                                Upgrade VPS
                            </SolidButton>
                        </NXBox.Top>
                        <NXBox.DefaultPadding>
                            <DialogNotification type="warning" tail={{ pos: 'top' }}>
                                {isLegacy
                                    ? 'PLEASE NOTE: Legacy VPS cannot have additional resources added. Please consider upgrading to one of our current VPS offerings to add additional vCPU, Memory, Bandwidth and SSD Storage to your VPS.'
                                    : 'PLEASE NOTE: You cannot assign additional resources to your current VPS plan. Resource addons are only available on PREMIUM VPS services. Please consider upgrading your current plan for higher SSD Storage, Bandwidth, vCPU, and Memory allocations.'}
                            </DialogNotification>
                        </NXBox.DefaultPadding>
                    </NXBox>
                );
            }

            function getStatus() {
                if ([vps_addon_list_status, service_change_plan_paths_status].includes('loading')) return 'loading';
                return vps_addon_list_status;
            }

            return (
                <Box
                    request={{
                        action: getVPSAddons,
                        args: vpsid,
                        status: getStatus()
                    }}
                    className="VPS-Resource-Addons"
                    status={getStatus()}
                    custom={{
                        render: renderResourceAddonContent(),
                        pos: 'top'
                    }}
                />
            );
        };

        return (
            <Fragment>
                <ResourceAddonsContext.Provider value={{ ResourceAddons: this }}>
                    <div
                        ref={(el) => {
                            this.scrollRef = el;
                        }}
                    >
                        {renderResourceAddonUpgrade()}
                    </div>
                    {payInvoiceLightbox && (
                        <OverlayLightbox
                            invoiceid={selectedInvoice}
                            title={`Pay Invoice #` + selectedInvoice}
                            onOpen={payInvoiceLightbox}
                            onClose={closePaymentLightbox}
                        />
                    )}
                    {removeAddonLightbox && addonToBeRemoved ? (
                        <OverlayLightbox
                            className="VPS-Lightbox"
                            title={`Remove ${addonToBeRemoved.attributes.name}`}
                            onOpen={removeAddonLightbox}
                            onClose={closeRemoveAddonLightbox}
                        >
                            <ConfirmationLightbox
                                description={`Are you sure you want to remove ${addonToBeRemoved.attributes.name}? This addon will be cancelled immediately.`}
                                action={removeAddon}
                                actionlabel="Remove"
                            />
                        </OverlayLightbox>
                    ) : (
                        ''
                    )}
                    {addingAddonLightbox && (
                        <OverlayLightbox className="VPS-Lightbox" onOpen={addingAddonLightbox} onClose={closeAddingAddonLightbox}>
                            <AddAddonLightbox addAddonType={addAddonType} />
                        </OverlayLightbox>
                    )}
                </ResourceAddonsContext.Provider>
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    vps_addon_list_data: state.vps.vps_addon_list_data,
    vps_addon_list_status: state.vps.vps_addon_list_status,
    vps_information_data: state.vps.vps_information_data,
    service_change_plan_paths_status: state.services.service_change_plan_paths_status,
    service_change_plan_paths_data: state.services.service_change_plan_paths_data,
    sidebarRefs: state.sidebar.sidebarRefs
});

const mapDispatchToProps = {
    getVPSAddons,
    cancelAddon
};

ResourceAddons = connect(mapStateToProps, mapDispatchToProps)(ResourceAddons);

ResourceAddons = withRouter(ResourceAddons);

export default ResourceAddons;
