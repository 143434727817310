/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import React from 'react';
import GoogleFontLoader from 'react-google-font';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import SimpleTable from 'components/SimpleTable';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InfoTableSpan, InfoTableSubHeading } from 'containers/katana/components';
import KatanaModuleCTA from 'containers/katana/components/moduleCTA';
import { KatanaSectionSettingsModule } from 'containers/katana/components/sectionSettingsModule';
import { ColourRenderer } from 'containers/katana/modules/coloursFonts/colourRenderer';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getFontFamilyByFontMetaData } from 'containers/katana/containers/contentEditorModules/webpageFonts/methods';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { isChecklistItemEnabled } from 'containers/katana/modules/launchChecklist/methods';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStage, katanaModuleImages, katanaServicePages, katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';

import './_katanaColoursFontsModule.scss';

const { COLOUR_FONTS } = katanaSubPageEnums;
const { SETUP, EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaColoursFontsModule = function KatanaColoursFontsModule() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const isMobile = useAppViewport(['xs', 'sm']);
    const { getKatanaDestination } = useKatanaURL();

    /***** QUERIES *****/
    const {
        data: get_katana_service_data,
        isSuccess: isGetKatanaServiceSuccess,
        isLoading: isGetKatanaServiceLoading
    } = katanaQuery.serviceID.getService.useQuery(id);

    const { data: get_fonts_meta_data, isLoading: isGetFontsMetaLoading } = katanaQuery.serviceID.meta.getFonts.useQuery(id);
    const { data: get_colours_meta_data, isLoading: isGetColoursMetaLoading } = katanaQuery.serviceID.meta.getColours.useQuery(id);

    /***** RENDER HELPERS *****/
    const isActive = get_katana_service_data?.attributes?.launch_checklist.includes(SiteSetupStage.ThemeAndColorCompleted);

    const fonts = get_katana_service_data?.site?.style?.fonts ?? {};
    const color = get_colours_meta_data?.[get_katana_service_data?.site?.color]?.hex_code ?? null;
    const selectedFontFamilies = getFontFamilyByFontMetaData(get_fonts_meta_data, fonts);

    const fontsAndColoursSimpleTableData = [
        React.Children.toArray([
            <InfoTableSubHeading>Primary font</InfoTableSubHeading>,
            <InfoTableSpan>
                {isGetFontsMetaLoading ? (
                    <RequestLoader fillHeight={21} height={21} />
                ) : (
                    <Text _style={{ fontFamily: selectedFontFamilies.heading }}>{selectedFontFamilies.heading}</Text> ?? '-'
                )}
            </InfoTableSpan>
        ]),
        React.Children.toArray([
            <InfoTableSubHeading>Secondary font</InfoTableSubHeading>,
            <InfoTableSpan>
                {isGetFontsMetaLoading ? (
                    <RequestLoader fillHeight={21} height={21} />
                ) : (
                    <Text _style={{ fontFamily: selectedFontFamilies.body }}> {selectedFontFamilies.body}</Text> ?? '-'
                )}
            </InfoTableSpan>
        ])
    ];

    if (!get_katana_service_data?.business?.logo) {
        fontsAndColoursSimpleTableData.push(
            React.Children.toArray([
                <InfoTableSubHeading>Brand font</InfoTableSubHeading>,
                <InfoTableSpan>
                    {isGetFontsMetaLoading ? (
                        <RequestLoader fillHeight={21} height={21} />
                    ) : (
                        <Text _style={{ fontFamily: selectedFontFamilies.brand }}>{selectedFontFamilies.brand}</Text> ?? '-'
                    )}
                </InfoTableSpan>
            ])
        );
    }

    const googleFontLoaderFonts = values(selectedFontFamilies).map((font) => ({
        font
    }));

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isGetKatanaServiceSuccess}>
            <KatanaSectionSettingsModule>
                <NXBox.TopAccordion
                    title="Colours and Fonts"
                    topChildren={
                        isActive && (
                            <SolidButton
                                to={getKatanaDestination(EDITOR.COLOUR_FONTS)}
                                onMouseOver={() => {
                                    katanaContentEditorRoutes[COLOUR_FONTS].prefetch(id);
                                }}
                            >
                                Edit Colours and Fonts
                            </SolidButton>
                        )
                    }
                >
                    <NXBox.DefaultPadding>
                        {isActive ? (
                            <Grid columns={isMobile ? '100%' : '250px 1fr'} gap={6} fullWidth alignItems--start>
                                <div className="KatanaColoursFontsModule__primaryColourSection">
                                    <Text.Heading h4>Primary Colour</Text.Heading>
                                    <ColourRenderer colour={color}>
                                        {!isGetColoursMetaLoading && !isGetKatanaServiceLoading && !color && (
                                            <Flex justify="center" items="center">
                                                <Text size--s medium secondary italic>
                                                    No colour selected
                                                </Text>
                                            </Flex>
                                        )}
                                    </ColourRenderer>
                                </div>
                                <div className="KatanaColoursFontsModule__primaryColourSection">
                                    <Text.Heading h4>Fonts</Text.Heading>
                                    <GoogleFontLoader fonts={googleFontLoaderFonts} />

                                    <SimpleTable rows={fontsAndColoursSimpleTableData} />
                                </div>
                            </Grid>
                        ) : (
                            <KatanaModuleCTA
                                image={katanaModuleImages.designAndColoursModule}
                                alt="Design and Colours"
                                text="Choose a colour for your website and select the fonts you like"
                                buttonText="Customise your website design"
                                disabled={
                                    !isChecklistItemEnabled(
                                        get_katana_service_data?.attributes?.launch_checklist,
                                        SiteSetupStage.ThemeAndColorCompleted
                                    )
                                }
                                to={getKatanaDestination(SETUP.COLOUR_FONTS)}
                            />
                        )}
                    </NXBox.DefaultPadding>
                </NXBox.TopAccordion>
            </KatanaSectionSettingsModule>
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaColoursFontsModule };
