/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { regexes } from 'utilities/methods/regexes';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @type {React.FC<{ children: React.ReactNode }>}
 */
const RenderReplacement = ({ children }) => (
    <Text bold black>
        &nbsp;<strong>{children}</strong>
    </Text>
);
const { emailRegex, descriptionDomainRegex } = regexes;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *     description: string
 * }>}
 */
const FormattedDescription = ({ description }) => {
    if (!description) return '';

    /***** RENDER HELPERS *****/
    const combinedRegex = new RegExp(`(${emailRegex().source})|(${descriptionDomainRegex().source})`);

    /***** RENDER *****/
    return description.split(combinedRegex).map((part, index) => {
        if (emailRegex().test(part)) {
            return <RenderReplacement key={`${part}-${index}`}>{part}</RenderReplacement>;
        } else if (descriptionDomainRegex().test(part)) {
            return <RenderReplacement key={`${part}-${index}`}>{part}</RenderReplacement>;
        } else {
            return part;
        }
    });
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FormattedDescription;
