/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AlignServices from './modules/alignServices';
import InvoiceDate from './modules/invoiceDate';
import Invoices from './modules/invoices';
import MergeInvoices from './modules/invoicesMerge';
import PaymentMethods from './modules/paymentMethods';
import Claim from './modules/sla';
import SplitInvoice from './modules/splitInvoice';
import Wallet from './modules/wallet';
import InvoiceView from './modules/invoiceView';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';
import Subnav from 'components/Subnav';
import SolidTag from 'components/Tags/SolidTag';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_billing.scss';

function generateNavItems(app_user_data) {
    const navItems = [
        {
            label: 'Invoices',
            link: '/billing/invoices',
            icon: 'icon-migrations'
        },
        {
            label: (
                <div className="Subnav__option--withTag">
                    VentraIP Wallet<SolidTag color="confirm">new</SolidTag>
                </div>
            ),
            link: '/billing/ventraip-wallet',
            icon: ''
        },
        {
            label: 'Services Alignment',
            link: '/billing/services-alignment',
            icon: ''
        },
        {
            label: 'SLA Claim',
            link: '/billing/sla',
            icon: 'customer-service'
        }
    ];

    if (app_user_data?.role === 'Account Holder' || app_user_data?.role === 'Admin') {
        navItems.splice(1, 0, {
            label: 'Payment Methods',
            link: '/billing/payment-methods',
            icon: 'eticket'
        });
    }

    return navItems;
}

const BILLING_SECTIONS = {
    PAYMENT_METHODS: 'payment-methods',
    INVOICES: 'invoices',
    INVOICE: 'invoice',
    VENTRAIP_WALLET: 'ventraip-wallet',
    SPLIT_INVOICE: 'split-invoice',
    INVOICES_MERGE: 'invoices-merge',
    SLA: 'sla',
    SERVICES_ALIGNMENT: 'services-alignment'
};
const BILLING_ACTIONS = {
    PAY: 'pay',
    CANCEL: 'cancel'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Billing = (props) => {
    const { app_user_data, location, match } = props;
    const { pathname } = location;
    const { params } = match;
    const { section, action } = params;

    /***** RENDER HELPERS *****/
    const renderSubPage = () => {
        switch (section) {
            case BILLING_SECTIONS.PAYMENT_METHODS:
                return app_user_data?.role === 'Account Holder' || app_user_data?.role === 'Admin' ? <PaymentMethods /> : <PageNotFound />;

            case BILLING_SECTIONS.INVOICES:
                return (
                    <>
                        <Invoices />
                        <InvoiceDate />
                    </>
                );

            case BILLING_SECTIONS.INVOICE: {
                switch (action) {
                    case BILLING_ACTIONS.PAY:
                    case BILLING_ACTIONS.CANCEL:
                        /**
                         * Show nothing if the user is trying to pay or cancel an invoice, as the lightbox will handle it
                         */
                        return '';
                    default:
                        return <InvoiceView />;
                }
            }
            case BILLING_SECTIONS.VENTRAIP_WALLET:
                return <Wallet />;

            case BILLING_SECTIONS.SPLIT_INVOICE:
                return <SplitInvoice />;

            case BILLING_SECTIONS.INVOICES_MERGE:
                return <MergeInvoices />;

            case BILLING_SECTIONS.SLA:
                return <Claim />;

            case BILLING_SECTIONS.SERVICES_ALIGNMENT:
                return <AlignServices />;

            default:
                return <PageNotFound />;
        }
    };

    /***** RENDER *****/
    return (
        <div className="billing">
            <Subnav items={generateNavItems(app_user_data)} pathname={pathname} />
            <Transition className="billing__section" when={`${section}${action}`}>
                {renderSubPage()}
            </Transition>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    app_user_data: state.app.app_user_data
});

Billing = connect(mapStateToProps)(Billing);

Billing = withRouter(Billing);

export default Billing;
