/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import SolidButton from 'components/Buttons/SolidButton';
import InactiveButton from 'components/Buttons/InactiveButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SplitInvoiceTotal from '../splitInvoiceTotal';
import InvoiceSplitOverviewTable from '../tables/invoiceSplitOverviewTable';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePreviewSplitInvoiceQuery } from 'containers/billing/queries/invoice/usePreviewSplitInvoiceQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SPLIT_INVOICE_PROP_TYPES, SPLIT_INVOICE_WHICH } from '../consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SplitInvoiceBox({ invoiceID, splitData, openSplitLightbox, splitActionButton }) {
    /***** QUERIES *****/
    const { data: preview_split_invoice_data, fetchStatus: previewSplitInvoiceFetchStatus } = usePreviewSplitInvoiceQuery(splitData);

    /***** RENDER HELPERS *****/
    const filterSplitData = ({ id }) => splitData.itemIds.includes(id);

    /***** RENDER *****/
    return (
        <NXBox>
            <NXBox.Top title="Split Invoice" description="Here are the items that will be on the new invoice" />
            {splitData.itemIds.length > 0 ? (
                <InvoiceSplitOverviewTable
                    splitData={splitData}
                    invoiceID={invoiceID}
                    splitActionButton={splitActionButton}
                    filterSplitData={filterSplitData}
                />
            ) : (
                <div className="splitInvoiceView__howTo">
                    Start by clicking the <b>Split</b> button on a product you wish to move to a new invoice
                </div>
            )}

            <SplitInvoiceTotal splitData={splitData} which={SPLIT_INVOICE_WHICH.INVOICE_B} />
            {preview_split_invoice_data ? (
                <div className="splitInvoiceView__continue">
                    {previewSplitInvoiceFetchStatus === 'fetching' ? (
                        <InactiveButton>Continue</InactiveButton>
                    ) : (
                        <SolidButton color="primary" type="onClick" onClick={openSplitLightbox}>
                            Continue
                        </SolidButton>
                    )}
                </div>
            ) : (
                ''
            )}
        </NXBox>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SplitInvoiceBox.propTypes = {
    ...SPLIT_INVOICE_PROP_TYPES,

    /**
     * Opens final split confirmation
     */
    openSplitLightbox: PropTypes.func.isRequired,

    /**
     * The invoice split action button
     */
    splitActionButton: PropTypes.func.isRequired
};

export default SplitInvoiceBox;
