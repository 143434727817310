/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AppUserData = Artah.User.GET._200['data']['attributes'] & Pick<Artah.User.GET._200['data'], 'id'>;

type UserDataState = {
    app?: {
        app_user_data: null | AppUserData;
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useUserData = () => useSelector((state: UserDataState) => state?.app?.app_user_data ?? ({} as Partial<AppUserData>));
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
