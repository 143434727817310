/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get as lodashGet } from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { recursivelyWalkObjectInReverseToFindKeyByPath } from 'utilities/methods/recursion/recursivelyWalkObjectInReverseToFindKeyByPath/recursivelyWalkObjectInReverseToFindKeyByPath';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
    formValues: Record<string, unknown>;
    caveats: KatanaNamespace.SectionDefinitions.AllCaveats[];
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetShowPropertyWhenKeyHasValueCaveat({ caveats = [], property, formValues }: Params) {
    const showPropertyWhenKeyHasValue = useGetCaveat(CaveatTypes.SHOW_PROPERTY_WHEN_KEY_HAS_VALUE, caveats) as { key: string; value: unknown };
    const hasShowPropertyWhenKeyHasValueKey = Boolean(showPropertyWhenKeyHasValue?.key);
    const resolvedShowPropertyWhenKeyHasValue = recursivelyWalkObjectInReverseToFindKeyByPath(
        formValues,
        showPropertyWhenKeyHasValue?.key,
        property.key
    );

    const keyValueForShowingProperty = resolvedShowPropertyWhenKeyHasValue
        ? lodashGet(resolvedShowPropertyWhenKeyHasValue, showPropertyWhenKeyHasValue?.key)
        : undefined;

    const isShowingWhenKeyHasValue = keyValueForShowingProperty === showPropertyWhenKeyHasValue?.value;

    return { showPropertyWhenKeyHasValue: !hasShowPropertyWhenKeyHasValueKey || isShowingWhenKeyHasValue };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
