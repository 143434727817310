import React from 'react';
/**********************************************************************************************************
 *   CONSTS - DATA
 **********************************************************************************************************/
export const MigrationServiceTypes = {
    CONTROL_PANEL: 'control_panel',
    CPANEL: 'cpanel',
    OTHER: 'other'
};

export const MAX_VALIDATION_ATTEMPTS = 1;
export const migrationFormNameDetails = 'migrationRequestFormDetails';
export const migrationFormNameFull = 'migrationRequestFormFull';

/**
 * Error messages to be displayed with serviceValidationData field name is false / invalid,
 */
export const validated_details_unsure = {
    domain: 'We cannot verify the domain right now, please ensure you fix the other validation errors first.',
    username: 'We cannot verify the username right now, please ensure you fix the other validation errors first.',
    password: 'We cannot verify the password right now, please ensure you fix the other validation errors first.'
};

/**********************************************************************************************************
 *   CONSTS - COPY
 **********************************************************************************************************/
export const COPY_invalidIpAddress = (
    <>
        IP Address does not appear to be valid. Please double-check the IP address with your current host. Also ensure the correct formatting, seen
        below:
        <br />
        IPv4 address: 110.000.000.00
        <br />
        IPv6 address: 2001:0db8:85a3:0000:0000:8a2e:0370:7334
    </>
);
export const COPY_invalidDomain = 'Invalid domain name.';
export const COPY_invalidDomainNoWWW = `Invalid domain name. Don't include "www."`;
export const COPY_invalidDomainNoSubdomain = "Invalid domain name. Don't include a subdomain";

export const COPY_validationCorrect = "We've been able to verify this as correct.";
export const COPY_validationCorrected = "We've automatically corrected this using your other details.";
