/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, ipAddressValidation, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { checkIpType } from '../state/configActions';

const formName = 'sshAccessForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SSHAccessForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRecord: null
        };
    }

    componentDidMount() {
        const { checkIpType, hosting_check_ip_data } = this.props;

        if (!hosting_check_ip_data) checkIpType();
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { handleSubmit, form, dispatch, hosting_check_ip_data, hosting_check_ip_status } = this.props;
        const { selectedOption } = this.state;

        /*  Render Component
         **********************************************************************************************************/
        if (hosting_check_ip_status === 'loading') {
            return <RequestLoader />;
        }

        return (
            <form className="sshAccess__form" onSubmit={handleSubmit}>
                {hosting_check_ip_data?.ipv4 ? (
                    <button
                        className={`sshAccess__option ${selectedOption === 'addIP' ? 'selected' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                selectedOption: 'addIP'
                            });
                            dispatch(change(form, 'addIP', true));
                        }}
                    >
                        <span className="option__title">
                            <input type="hidden" name="addIP" />
                            <span className="option__radio"></span>
                            <span className="option__text">Add my IP Address - {hosting_check_ip_data?.ipv4.ip}</span>
                        </span>
                    </button>
                ) : (
                    ''
                )}

                {hosting_check_ip_data?.ipv6 ? (
                    <button
                        className={`sshAccess__option ${selectedOption === 'addIPv6' ? 'selected' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                selectedOption: 'addIPv6'
                            });
                            dispatch(change(form, 'addIPv6', true));
                        }}
                    >
                        <span className="option__title">
                            <input type="hidden" name="addIPv6" />
                            <span className="option__radio"></span>
                            <span className="option__text">Add my IPv6 Address - {hosting_check_ip_data?.ipv6.ip}</span>
                        </span>
                    </button>
                ) : (
                    ''
                )}

                <button
                    className={`sshAccess__option ${selectedOption === 'addExternalIP' ? 'selected' : ''}`}
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            selectedOption: 'addExternalIP'
                        });
                        dispatch(change(form, 'addIP', false));
                    }}
                >
                    <span className="option__title">
                        <span className="option__radio"></span>
                        <span className="option__text">Add a different IP Address</span>
                    </span>
                    {selectedOption === 'addExternalIP' ? (
                        <div className="option__content" style={{ width: '100%' }}>
                            <div className="form__row">
                                <div className="form__column full">
                                    <Field
                                        label="Enter IP Address"
                                        name="ipaddress"
                                        component={RenderField}
                                        type="text"
                                        placeholder=""
                                        validate={[requiredFieldValidation, ipAddressValidation]}
                                        className="form__textfield"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </button>

                <div className="form__row">
                    <ReduxFormButton form={formName} className="form__column full">
                        Enable SSH Access
                    </ReduxFormButton>
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
SSHAccessForm = reduxForm({
    form: formName,
    enableReinitialize: true,
    initialValues: {
        ipaddress: undefined
    }
})(SSHAccessForm);

export default withRouter(
    connect(
        (state) => {
            const selector = formValueSelector(formName);
            const ipAddress = selector(state, 'ipaddress');

            return {
                hosting_sshaccess_list_data: state.hosting.hosting_sshaccess_list_data,
                hosting_sshaccess_status: state.hosting.hosting_sshaccess_status,
                hosting_check_ip_status: state.hosting.hosting_check_ip_status,
                hosting_check_ip_data: state.hosting.hosting_check_ip_data,
                form: formName,
                ipAddress
            };
        },
        {
            checkIpType
        }
    )(SSHAccessForm)
);
