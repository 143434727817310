/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RewardCard from '../rewardCard';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkPromoCodeEligibility, checkYearPromoCode } from 'config/containers/promotions/methods';
import { createDomainAvailabilityQueryKey, postDomainAvailability } from 'containers/domain/action';
import { useVipRewards } from 'containers/vipRewards/hooks';
import { activePromotion } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { dotStoreOnlineAndXYZAvailabilityRequestParams } from 'components/Promo/FreeDomain/DotStoreBanner';
import { freeDotOnlinePromoCode, freeDotSitePromoCode, freeDotStorePromoCode, freeDotXyzPromoCode } from 'components/Promo/promoCodes';

import './_rewardCards.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *   cards: typeof import('containers/vipRewards/sections/introduction').defaultRewardCardsList,
 *   title: string
 * }} props
 */
function RewardCards({ cards, title }) {
    /***** HOOKS *****/
    const promotion_promo_code_status_request_data = useSelector((state) => state.promotion.promotion_promo_code_status_request_data);
    const domain_availability_data = useSelector((state) => state.domain.domain_availability_data);
    const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(dotStoreOnlineAndXYZAvailabilityRequestParams);
    const domainAvailabilityStatus = domain_availability_data[domainAvailabilityQueryKey]?.status;
    const domainAvailabilityData = domain_availability_data[domainAvailabilityQueryKey]?.data;

    const { isAtOrAboveTier } = useVipRewards();

    /***** EFFECTS *****/
    useEffect(() => {
        let shouldFetchTldPricing = false;

        if (activePromotion('freeDotXyz2024')) {
            checkYearPromoCode(freeDotXyzPromoCode, '.xyz');
            shouldFetchTldPricing = true;
        }

        if (activePromotion('freeDotOnline2024')) {
            checkYearPromoCode(freeDotOnlinePromoCode, '.online');
            shouldFetchTldPricing = true;
        }

        if (activePromotion('freeDotStore2024')) {
            checkYearPromoCode(freeDotStorePromoCode, '.store');
            shouldFetchTldPricing = true;
        }

        if (activePromotion('freeDotSite2024')) {
            checkYearPromoCode(freeDotSitePromoCode, '.site');
            shouldFetchTldPricing = true;
        }

        if (shouldFetchTldPricing) {
            if (!domain_availability_data[domainAvailabilityQueryKey]?.data) {
                postDomainAvailability(dotStoreOnlineAndXYZAvailabilityRequestParams, domainAvailabilityQueryKey);
            }
        }
    }, []);

    /***** RENDER HELPERS *****/
    // Only show .store and .online offers if the customer is still eligible for them (they have a max usage)
    const cardsToShow = cards.filter((card) => {
        switch (card) {
            case 'nrlGiveaway':
                return isAtOrAboveTier('VIP') && activePromotion('nrlGiveaway2024');
            case 'dotOnline':
                return (
                    activePromotion('freeDotOnline2024') &&
                    checkPromoCodeEligibility(freeDotOnlinePromoCode, promotion_promo_code_status_request_data)
                );
            case 'dotStore':
                return (
                    activePromotion('freeDotStore2024') && checkPromoCodeEligibility(freeDotStorePromoCode, promotion_promo_code_status_request_data)
                );
            case 'dotXyz':
                return activePromotion('freeDotXyz2024') && checkPromoCodeEligibility(freeDotXyzPromoCode, promotion_promo_code_status_request_data);
            case 'dotSite':
                return (
                    activePromotion('freeDotSite2024') && checkPromoCodeEligibility(freeDotSitePromoCode, promotion_promo_code_status_request_data)
                );

            case 'referral':
                return isAtOrAboveTier('silver');
            default:
                return true;
        }
    });

    const getIsLoading = () => {
        if (
            promotion_promo_code_status_request_data?.[freeDotOnlinePromoCode]?.status === 'loading' ||
            promotion_promo_code_status_request_data?.[freeDotStorePromoCode]?.status === 'loading' ||
            promotion_promo_code_status_request_data?.[freeDotXyzPromoCode]?.status === 'loading' ||
            domainAvailabilityStatus === 'loading'
        ) {
            return true;
        }

        return false;
    };

    const isLoading = getIsLoading();

    const renderCards = () => {
        if (isLoading) {
            return <RequestLoader />;
        }

        if (cardsToShow.length <= 0) {
            return (
                <Text secondary size--s>
                    Unfortunately there are no extra rewards available at this time. Please check back later.
                </Text>
            );
        }

        return React.Children.toArray(
            cardsToShow.map((cardName) => {
                switch (cardName) {
                    case 'nrlGiveaway':
                        return <RewardCard.NrlGiveaway />;
                    case 'referral':
                        return <RewardCard.Referral />;
                    case 'dotStore':
                        return <RewardCard.DotStore availabilityData={domainAvailabilityData} />;
                    case 'dotOnline':
                        return <RewardCard.DotOnline availabilityData={domainAvailabilityData} />;
                    case 'dotXyz':
                        return <RewardCard.DotXyz availabilityData={domainAvailabilityData} />;
                    case 'dotSite':
                        return <RewardCard.DotSite availabilityData={domainAvailabilityData} />;
                    default:
                        return '';
                }
            })
        );
    };

    /***** RENDER *****/
    return (
        <div className="rewardCards">
            <div className="rewardCards__cardContainer">
                <Text primary size--xl semiBold>
                    <h3>{title}</h3>
                </Text>
                {renderCards()}
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

RewardCards.propTypes = {
    /**
     * The title of the rewards section
     */
    title: PropTypes.string,

    /**
     * The list of cards to render
     */
    cards: PropTypes.arrayOf(PropTypes.string)
};

export default RewardCards;
