/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { merge, unset } from 'lodash';
import React, { useRef } from 'react';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RefContext } from 'components/Utils/RefProvider/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode
 * }>}
 */
function RefProvider({ children }) {
    /***** HOOKS *****/
    /**
     * @type {React.MutableRefObject<import('svelte/store').Writable<RefProvider.RefsData>>}
     */
    const writableRef = useRef(writable({}));

    /***** FUNCTIONS *****/
    /**
     * @param {string} name
     * @param {React.MutableRefObject<any>} ref
     */
    function addRef(name, ref) {
        writableRef.current.update((refs) => {
            return merge({}, refs, { [name]: ref });
        });

        return () => {
            writableRef.current.update((refs) => {
                const newRefs = merge({}, refs);
                unset(newRefs, name);
                return newRefs;
            });
        };
    }

    /**
     * @param {string} name
     * @returns {HTMLElement | null}
     */
    function useGetRef(name) {
        const [elementRefData] = useStore(writableRef.current);
        return elementRefData?.[name]?.current;
    }

    /***** RENDER *****/
    return <RefContext.Provider value={{ addRef, useGetRef }}>{children}</RefContext.Provider>;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { RefProvider };
