/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows the id protection status of the domain
 * @param {{
 *      ccTLD: boolean
 *      idProtection: 'Enabled' | 'Disabled' | null
 *      domain_information_data: any
 * }} props
 */
export function DomainIdProtection({ ccTLD, idProtection, domain_information_data }) {
    /***** HOOKS *****/
    const domain_protection_check_status = useSelector((state) => state.domain.domain_protection_check_status);

    /***** RENDER *****/
    if (ccTLD || domain_protection_check_status === 'loading') {
        return null;
    }

    return (
        <NXBox.OverviewRow>
            <NXBox.InfoPair
                title="ID Protection"
                description={
                    <Text bold={!!idProtection} warn={idProtection === 'Disabled'} confirm={idProtection === 'Enabled'}>
                        {idProtection || 'Not Available'}
                    </Text>
                }
            />
            {idProtection ? <Anchor to={`/my-services/domains/security/id-protection/${domain_information_data?.id}`}>Manage</Anchor> : ''}
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainIdProtection;
