/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import './_FetchPageError.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component is used to display an error message when a page fails to load. This differs from FetchComponentError
 * in that it is meant to be used on a page level, not a component level.
 */
const FetchPageError = ({ message = "Something's gone wrong. Please try again later or contact our support team.", height }) => {
    /***** RENDER HELPERS *****/
    const styles = {
        height
    };

    const fetchPageErrorClassNames = classNames('FetchPageError', { 'FetchPageError--customHeight': !!height });

    /***** RENDER *****/
    return (
        <div className={fetchPageErrorClassNames} style={styles}>
            <div className="container">
                <div className="FetchPageError__wrapper">
                    <PhosphorIcons.WarningDiamond size={75} primary />
                    <Text className="description" size--l secondary align--center>
                        {message}
                    </Text>
                </div>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

FetchPageError.propTypes = {
    /**
     * Message content for FetchPageError
     */
    message: PropTypes.node,

    /**
     * Height override.
     */
    height: PropTypes.number
};

FetchPageError.defaultProps = {
    message: "Something's gone wrong. Please try again later or contact our support team."
};

export default FetchPageError;
