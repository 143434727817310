/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
export const SECOND = 1_000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const TruthyProp = PropTypes.oneOfType([PropTypes.bool, PropTypes.any]);
export const ReactChildrenProp = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);
export const ReduxFormMetaProp = PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
    warning: PropTypes.any,
    pristine: PropTypes.bool,
    initial: PropTypes.any
});

export const ShapeSquarePropType = {
    /**
     * SHAPE PROPS
     */
    /**
     * Makes the container square based on the width.
     * Or if a number is used, it will be used to calculate that fraction
     *      The percentage of how much height it should have depending on the width
     *      1 is square, 0.5 is half, 1.5 is one and a half etc..
     */
    ratio: PropTypes.oneOfType([TruthyProp, PropTypes.number])
};

export const INVOICE_STATUSES = {
    PAID: 'paid',
    UNPAID: 'unpaid',
    CANCELLED: 'cancelled',
    REFUNDED: 'refunded',
    DRAFT: 'draft',
    FRAUD: 'fraud',
    DISPUTED: 'disputed'
} as const;
export const INVOICE_STATUS_VALUES = Object.values(INVOICE_STATUSES);
export type InvoiceStatus = (typeof INVOICE_STATUS_VALUES)[number];

/**
 * Following the WHATWG spec, the following input types are supported:
 * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete-cc-name
 */
export const CREDIT_CARD_FIELD_DATA = {
    CARD_HOLDER_NAME: {
        name: 'ccname',
        autoComplete: 'cc-name'
    },

    CARD_NUMBER: {
        name: 'cardnumber',
        autoComplete: 'cc-number'
    },

    CARD_CVV: {
        name: 'cvc',
        autoComplete: 'cc-csc'
    },

    CARD_MONTH: {
        name: 'ccmonth',
        autoComplete: 'cc-exp-month'
    },

    CARD_YEAR: {
        name: 'ccyear',
        autoComplete: 'cc-exp-year'
    },

    CARD_EXPIRY: {
        name: 'ccexpiry',
        autoComplete: 'cc-exp'
    },

    CARD_TYPE: {
        name: 'card-type',
        autoComplete: 'cc-type'
    }
} as const;

export const showNavigation = !window.ccpIsAppWebview;

/**
 * DNS_CONFIG_MODES from CCP: `app/Enums/SWS/DNSConfigMode.php`
 */
export const DNS_CONFIG_MODES = {
    'Custom Hosting': 1,
    'Forwarding': 2,
    'Parked Domain': 3,
    'DNS Hosting': 4,
    'Default Nameservers': 5,
    'Legacy Hosting': 6,
    'Wholesale Hosting': 7
} as const;
