/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      colour: HexString | string,
 *      children: React.ReactNode
 *      className?: string
 * }} props
 */
const ColourRenderer = ({ colour, children, className }) => (
    <div className={classNames('KatanaColoursFontsModule__primaryColourBG', className)} style={{ backgroundColor: colour }}>
        <NXSquare ratio={0.6}>{children}</NXSquare>
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ColourRenderer };
