/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Axios from 'axios';
import { pushNotification } from 'components/Toast/functions';
import {
    API,
    cancelGetCustomer,
    cancelGetData,
    cancelGetPricing,
    cancelGetRecord,
    cancelGetService,
    cancelGetStatus,
    cancelGetUserData,
    cancelGetUsers
} from 'utilities/api/gsuite';
import { API as SERVICES } from 'utilities/api/services';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/

export const GSUITE_INITIAL_STATE = 'gsuite/GSUITE_INITIAL_STATE';
export const GSUITE_CURRENT_STEP = 'gsuite/GSUITE_CURRENT_STEP';

export const GSUITE_SERVICE_REQUEST = 'gsuite/GSUITE_SERVICE_REQUEST';
export const GSUITE_SERVICE_SUCCESS = 'gsuite/GSUITE_SERVICE_SUCCESS';
export const GSUITE_SERVICE_ERROR = 'gsuite/GSUITE_SERVICE_ERROR';

export const GSUITE_MAILBOX_REQUEST = 'gsuite/GSUITE_MAILBOX_REQUEST';
export const GSUITE_MAILBOX_SUCCESS = 'gsuite/GSUITE_MAILBOX_SUCCESS';
export const GSUITE_MAILBOX_ERROR = 'gsuite/GSUITE_MAILBOX_ERROR';

export const GSUITE_LOGIN_REQUEST = 'gsuite/GSUITE_LOGIN_REQUEST';
export const GSUITE_LOGIN_SUCCESS = 'gsuite/GSUITE_LOGIN_SUCCESS';
export const GSUITE_LOGIN_ERROR = 'gsuite/GSUITE_LOGIN_ERROR';

export const GSUITE_DELETE_MAILBOX_REQUEST = 'gsuite/GSUITE_DELETE_MAILBOX_REQUEST';
export const GSUITE_DELETE_MAILBOX_SUCCESS = 'gsuite/GSUITE_DELETE_MAILBOX_SUCCESS';
export const GSUITE_DELETE_MAILBOX_ERROR = 'gsuite/GSUITE_DELETE_MAILBOX_ERROR';

export const GSUITE_CUSTOMER_CHECK_REQUEST = 'gsuite/GSUITE_CUSTOMER_CHECK_REQUEST';
export const GSUITE_CUSTOMER_CHECK_SUCCESS = 'gsuite/GSUITE_CUSTOMER_CHECK_SUCCESS';
export const GSUITE_CUSTOMER_CHECK_ERROR = 'gsuite/GSUITE_CUSTOMER_CHECK_ERROR';

export const GSUITE_CUSTOMER_DATA_REQUEST = 'gsuite/GSUITE_CUSTOMER_DATA_REQUEST';
export const GSUITE_CUSTOMER_DATA_SUCCESS = 'gsuite/GSUITE_CUSTOMER_DATA_SUCCESS';
export const GSUITE_CUSTOMER_DATA_ERROR = 'gsuite/GSUITE_CUSTOMER_DATA_ERROR';

export const GSUITE_SEAT_PRICING_REQUEST = 'gsuite/GSUITE_SEAT_PRICING_REQUEST';
export const GSUITE_SEAT_PRICING_SUCCESS = 'gsuite/GSUITE_SEAT_PRICING_SUCCESS';
export const GSUITE_SEAT_PRICING_ERROR = 'gsuite/GSUITE_SEAT_PRICING_ERROR';

export const GSUITE_VERIFICATION_REQUEST = 'gsuite/GSUITE_VERIFICATION_REQUEST';
export const GSUITE_VERIFICATION_SUCCESS = 'gsuite/GSUITE_VERIFICATION_SUCCESS';
export const GSUITE_VERIFICATION_ERROR = 'gsuite/GSUITE_VERIFICATION_ERROR';

export const GSUITE_VERIFICATION_STATUS_REQUEST = 'gsuite/GSUITE_VERIFICATION_STATUS_REQUEST';
export const GSUITE_VERIFICATION_STATUS_SUCCESS = 'gsuite/GSUITE_VERIFICATION_STATUS_SUCCESS';
export const GSUITE_VERIFICATION_STATUS_ERROR = 'gsuite/GSUITE_VERIFICATION_STATUS_ERROR';

export const GSUITE_VERIFY_RECORD_REQUEST = 'gsuite/GSUITE_VERIFY_RECORD_REQUEST';
export const GSUITE_VERIFY_RECORD_SUCCESS = 'gsuite/GSUITE_VERIFY_RECORD_SUCCESS';
export const GSUITE_VERIFY_RECORD_ERROR = 'gsuite/GSUITE_VERIFY_RECORD_ERROR';

export const GSUITE_USER_ACCOUNTS_REQUEST = 'gsuite/GSUITE_USER_ACCOUNTS_REQUEST';
export const GSUITE_USER_ACCOUNTS_SUCCESS = 'gsuite/GSUITE_USER_ACCOUNTS_SUCCESS';
export const GSUITE_USER_ACCOUNTS_ERROR = 'gsuite/GSUITE_USER_ACCOUNTS_ERROR';

export const GSUITE_USER_DATA_REQUEST = 'gsuite/GSUITE_USER_DATA_REQUEST';
export const GSUITE_USER_DATA_SUCCESS = 'gsuite/GSUITE_USER_DATA_SUCCESS';
export const GSUITE_USER_DATA_ERROR = 'gsuite/GSUITE_USER_DATA_ERROR';

export const GSUITE_USER_CREATE_REQUEST = 'gsuite/GSUITE_USER_CREATE_REQUEST';
export const GSUITE_USER_CREATE_SUCCESS = 'gsuite/GSUITE_USER_CREATE_SUCCESS';
export const GSUITE_USER_CREATE_ERROR = 'gsuite/GSUITE_USER_CREATE_ERROR';

export const GSUITE_USER_DELETE_REQUEST = 'gsuite/GSUITE_USER_DELETE_REQUEST';
export const GSUITE_USER_DELETE_SUCCESS = 'gsuite/GSUITE_USER_DELETE_SUCCESS';
export const GSUITE_USER_DELETE_ERROR = 'gsuite/GSUITE_USER_DELETE_ERROR';

export const GSUITE_PRODUCT_PRICING_REQUEST = 'gsuite/GSUITE_PRODUCT_PRICING_REQUEST';
export const GSUITE_PRODUCT_PRICING_SUCCESS = 'gsuite/GSUITE_PRODUCT_PRICING_SUCCESS';
export const GSUITE_PRODUCT_PRICING_ERROR = 'gsuite/GSUITE_PRODUCT_PRICING_ERROR';

export const GSUITE_PURCHASE_REQUEST = 'gsuite/GSUITE_PURCHASE_REQUEST';
export const GSUITE_PURCHASE_SUCCESS = 'gsuite/GSUITE_PURCHASE_SUCCESS';
export const GSUITE_PURCHASE_ERROR = 'gsuite/GSUITE_PURCHASE_ERROR';

export const GSUITE_UPGRADE_REQUEST = 'gsuite/GSUITE_UPGRADE_REQUEST';
export const GSUITE_UPGRADE_SUCCESS = 'gsuite/GSUITE_UPGRADE_SUCCESS';
export const GSUITE_UPGRADE_ERROR = 'gsuite/GSUITE_UPGRADE_ERROR';

export const GSUITE_BILLING_CYCLE_REQUEST = 'gsuite/GSUITE_BILLING_CYCLE_REQUEST';
export const GSUITE_BILLING_CYCLE_SUCCESS = 'gsuite/GSUITE_BILLING_CYCLE_SUCCESS';
export const GSUITE_BILLING_CYCLE_ERROR = 'gsuite/GSUITE_BILLING_CYCLE_ERROR';

export const GSUITE_UPDATE_BILLING_CYCLE_REQUEST = 'gsuite/GSUITE_UPDATE_BILLING_CYCLE_REQUEST';
export const GSUITE_UPDATE_BILLING_CYCLE_SUCCESS = 'gsuite/GSUITE_UPDATE_BILLING_CYCLE_SUCCESS';
export const GSUITE_UPDATE_BILLING_CYCLE_ERROR = 'gsuite/GSUITE_UPDATE_BILLING_CYCLE_ERROR';

/**********************************************************************************************************
 *   ACTIONS - INITIAL
 **********************************************************************************************************/

export const resetGSuiteState = () => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   ACTIONS - SETUP
 **********************************************************************************************************/

export const gsuiteGoToStep = (step) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_CURRENT_STEP,
            gsuite_current_step: step
        });
    };
};

export const getGSuiteVerificationData = (id) => {
    return (dispatch) => {
        if (cancelGetRecord) {
            cancelGetRecord();
        }
        dispatch({
            type: GSUITE_VERIFICATION_REQUEST
        });
        API.gsuite.GET.record(id)
            .then((response) => {
                const gsuite_verification_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_VERIFICATION_SUCCESS,
                    gsuite_verification_data
                });
            })
            .catch((error) => {
                const gsuite_verification_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_VERIFICATION_ERROR,
                    gsuite_verification_error
                });
            });
    };
};

export const getGSuiteVerificationStatus = (id) => {
    return (dispatch) => {
        if (cancelGetStatus) {
            cancelGetStatus();
        }
        dispatch({
            type: GSUITE_VERIFICATION_STATUS_REQUEST
        });
        API.gsuite.GET.status(id)
            .then((response) => {
                const gsuite_verification_status_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_VERIFICATION_STATUS_SUCCESS,
                    gsuite_verification_status_data
                });
            })
            .catch((error) => {
                const gsuite_verification_status_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_VERIFICATION_STATUS_ERROR,
                    gsuite_verification_status_error
                });
            });
    };
};

export const verifyDomainRecord = (id) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_VERIFY_RECORD_REQUEST
        });
        API.gsuite.POST.verify(id)
            .then((response) => {
                const gsuite_verify_record_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_verify_record_data);
                dispatch({
                    type: GSUITE_VERIFY_RECORD_SUCCESS,
                    gsuite_verify_record_data
                });
            })
            .catch((error) => {
                const gsuite_verify_record_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_verify_record_error);
                dispatch({
                    type: GSUITE_VERIFY_RECORD_ERROR,
                    gsuite_verify_record_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - GENERAL
 **********************************************************************************************************/

export const getGSuiteService = (id) => {
    const { dispatch } = store;
    if (cancelGetService) {
        cancelGetService();
    }
    dispatch({
        type: GSUITE_SERVICE_REQUEST
    });
    API.gsuite.GET.service(id)
        .then((response) => {
            const gsuite_service_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_SERVICE_SUCCESS,
                gsuite_service_data
            });
        })
        .catch((error) => {
            const gsuite_service_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_SERVICE_ERROR,
                gsuite_service_error
            });
        });
};

export const getGSuiteUserAccounts = (id) => {
    return (dispatch) => {
        if (cancelGetUsers) {
            cancelGetUsers();
        }
        dispatch({
            type: GSUITE_USER_ACCOUNTS_REQUEST
        });
        API.gsuite.GET.users(id)
            .then((response) => {
                const gsuite_user_accounts_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_USER_ACCOUNTS_SUCCESS,
                    gsuite_user_accounts_data
                });
            })
            .catch((error) => {
                const gsuite_user_accounts_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_USER_ACCOUNTS_ERROR,
                    gsuite_user_accounts_error
                });
            });
    };
};

export const getGSuiteMailbox = (id, mailbox) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_MAILBOX_REQUEST
        });
        API.gsuite.GET.mailbox(id, mailbox)
            .then((response) => {
                const gsuite_mailbox_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_MAILBOX_SUCCESS,
                    gsuite_mailbox_data
                });
            })
            .catch((error) => {
                const gsuite_mailbox_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_MAILBOX_ERROR,
                    gsuite_mailbox_error
                });
            });
    };
};

export const checkGSuiteCustomer = (id) => {
    return (dispatch) => {
        if (cancelGetCustomer) {
            cancelGetCustomer();
        }
        dispatch({
            type: GSUITE_CUSTOMER_CHECK_REQUEST
        });
        API.gsuite.GET.customer(id)
            .then((response) => {
                const gsuite_customer_check_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_CUSTOMER_CHECK_SUCCESS,
                    gsuite_customer_check_data
                });
            })
            .catch((error) => {
                const gsuite_customer_check_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_CUSTOMER_CHECK_ERROR,
                    gsuite_customer_check_error
                });
            });
    };
};

export const getGSuiteCustomerData = (id) => {
    return (dispatch) => {
        if (cancelGetData) {
            cancelGetData();
        }
        dispatch({
            type: GSUITE_CUSTOMER_DATA_REQUEST
        });
        API.gsuite.GET.data(id)
            .then((response) => {
                const gsuite_customer_data_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_CUSTOMER_DATA_SUCCESS,
                    gsuite_customer_data_data
                });
            })
            .catch((error) => {
                const gsuite_customer_data_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_CUSTOMER_DATA_ERROR,
                    gsuite_customer_data_error
                });
            });
    };
};

export const createGSuiteCustomerData = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_CUSTOMER_DATA_REQUEST
        });
        API.gsuite.POST.customer(id, attributes)
            .then((response) => {
                pushNotification(getDataFromSuccessResponse(response));
                dispatch(getGSuiteCustomerData(id));
            })
            .catch((error) => {
                const gsuite_customer_data_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_customer_data_error);
                dispatch(getGSuiteCustomerData(id));
            });
    };
};

export const updateGSuiteCustomerData = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_CUSTOMER_DATA_REQUEST
        });
        API.gsuite.PUT.customer(id, attributes)
            .then((response) => {
                pushNotification(getDataFromSuccessResponse(response));
                dispatch(getGSuiteCustomerData(id));
            })
            .catch((error) => {
                const gsuite_customer_data_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_customer_data_error);
                dispatch(getGSuiteCustomerData(id));
            });
    };
};

export const getGSuiteLogin = (id) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_LOGIN_REQUEST
        });

        const { goToTargetUrl, closePopup } = createPopup();

        API.gsuite.GET.login(id)
            .then((response) => {
                const gsuite_login_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_LOGIN_SUCCESS,
                    gsuite_login_data
                });

                goToTargetUrl(gsuite_login_data.attributes.link);
            })
            .catch((error) => {
                const gsuite_login_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_LOGIN_ERROR,
                    gsuite_login_error
                });

                closePopup();
            });
    };
};

export const deleteGSuiteService = (id, attributes, history) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_DELETE_MAILBOX_REQUEST
        });
        SERVICES.service.post
            .cancel(id, attributes)
            .then((response) => {
                const gsuite_delete_mailbox_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_DELETE_MAILBOX_SUCCESS,
                    gsuite_delete_mailbox_data
                });
            })
            .catch((error) => {
                const gsuite_delete_service_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_DELETE_MAILBOX_ERROR,
                    gsuite_delete_service_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - SEATS
 **********************************************************************************************************/

export const getGSuiteSeatsPricing = (serviceId, seats, product_id) => {
    return (dispatch) => {
        if (cancelGetPricing) {
            cancelGetPricing();
        }

        dispatch({
            type: GSUITE_SEAT_PRICING_REQUEST
        });

        API.gsuite.GET.pricing(serviceId, seats, product_id)
            .then((response) => {
                const gsuite_seat_pricing_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_SEAT_PRICING_SUCCESS,
                    gsuite_seat_pricing_data
                });
            })
            .catch((error) => {
                if (error instanceof Axios.Cancel) return;

                const gsuite_seat_pricing_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_SEAT_PRICING_ERROR,
                    gsuite_seat_pricing_error
                });
            });
    };
};

export const purchaseGSuiteSeats = (id, seats) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_PURCHASE_REQUEST
        });
        API.gsuite.POST.purchase(id, seats)
            .then((response) => {
                const gsuite_purchase_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_purchase_data);
                dispatch({
                    type: GSUITE_PURCHASE_SUCCESS,
                    gsuite_purchase_data
                });
            })
            .catch((error) => {
                const gsuite_purchase_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_purchase_error);
                dispatch({
                    type: GSUITE_PURCHASE_ERROR,
                    gsuite_purchase_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - MAILBOX
 **********************************************************************************************************/

export const createGSuiteMailbox = (id, attributes, history) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_MAILBOX_REQUEST
        });
        API.gsuite.POST.create(id, attributes)
            .then((response) => {
                const gsuite_mailbox_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_mailbox_data);
                dispatch({
                    type: GSUITE_MAILBOX_SUCCESS,
                    gsuite_mailbox_data
                });
                getGSuiteService(id);
                history.push(`/my-services/google/manage/overview/${id}`);
            })
            .catch((error) => {
                const gsuite_mailbox_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_mailbox_error);
                dispatch({
                    type: GSUITE_MAILBOX_ERROR,
                    gsuite_mailbox_error
                });
            });
    };
};

export const getGSuiteUserData = (id, mailbox) => {
    return (dispatch) => {
        if (cancelGetUserData) {
            cancelGetUserData();
        }
        dispatch({
            type: GSUITE_USER_DATA_REQUEST
        });
        API.gsuite.GET.userData(id, mailbox)
            .then((response) => {
                const gsuite_user_data_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_USER_DATA_SUCCESS,
                    gsuite_user_data_data
                });
            })
            .catch((error) => {
                const gsuite_user_data_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GSUITE_USER_DATA_ERROR,
                    gsuite_user_data_error
                });
            });
    };
};

export const createGSuiteUserData = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_USER_CREATE_REQUEST
        });
        API.gsuite.POST.create(id, attributes)
            .then((response) => {
                const gsuite_user_create_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_user_create_data);
                getGSuiteService(id);
                dispatch({
                    type: GSUITE_USER_CREATE_SUCCESS,
                    gsuite_user_create_data
                });
            })
            .catch((error) => {
                const gsuite_user_create_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_user_create_error);
                dispatch({
                    type: GSUITE_USER_CREATE_ERROR,
                    gsuite_user_create_error
                });
            });
    };
};

export const createGSuiteAdminData = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_USER_CREATE_REQUEST
        });
        API.gsuite.POST.admin(id, attributes)
            .then((response) => {
                const gsuite_user_create_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_user_create_data);
                dispatch(getGSuiteUserAccounts(id));
                dispatch({
                    type: GSUITE_USER_CREATE_SUCCESS,
                    gsuite_user_create_data
                });
            })
            .catch((error) => {
                const gsuite_user_create_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_user_create_error);
                dispatch({
                    type: GSUITE_USER_CREATE_ERROR,
                    gsuite_user_create_error
                });
            });
    };
};

export const updateGSuiteUserData = (id, mailbox, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_USER_DATA_REQUEST
        });
        API.gsuite.PUT.update(id, mailbox, attributes)
            .then((response) => {
                pushNotification(getDataFromSuccessResponse(response));
                dispatch(getGSuiteMailbox(id, mailbox));
            })
            .catch((error) => {
                const gsuite_customer_data_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_customer_data_error);
                dispatch(getGSuiteUserData(id, mailbox));
            });
    };
};

export const deleteGSuiteUser = (id, user, history) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_USER_DELETE_REQUEST
        });
        API.gsuite.DELETE.user(id, user)
            .then((response) => {
                const gsuite_user_delete_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_USER_DELETE_SUCCESS,
                    gsuite_user_delete_data
                });
                if (history) {
                    history.push(`/my-services/google/manage/overview/${id}`);
                }
            })
            .catch((error) => {
                const gsuite_user_delete_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_user_delete_error);
                dispatch({
                    type: GSUITE_USER_DELETE_ERROR,
                    gsuite_user_delete_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - UPGRADE
 **********************************************************************************************************/

export const upgradeGSuiteService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_UPGRADE_REQUEST
        });
        API.gsuite.POST.upgrade(id, attributes)
            .then((response) => {
                const gsuite_upgrade_data = getDataFromSuccessResponse(response);
                pushNotification(gsuite_upgrade_data);
                dispatch({
                    type: GSUITE_UPGRADE_SUCCESS,
                    gsuite_upgrade_data
                });
            })
            .catch((error) => {
                const gsuite_upgrade_error = getErrorFromFailResponse(error);
                pushNotification(gsuite_upgrade_error);
                dispatch({
                    type: GSUITE_UPGRADE_ERROR,
                    gsuite_upgrade_error
                });
            });
    };
};

export const getGSuiteProductPricing = (id) => {
    const { dispatch } = store;
    dispatch({
        type: GSUITE_PRODUCT_PRICING_REQUEST
    });
    API.gsuite.GET.product(id)
        .then((response) => {
            const gsuite_product_pricing_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_PRODUCT_PRICING_SUCCESS,
                gsuite_product_pricing_data
            });
        })
        .catch((error) => {
            const gsuite_product_pricing_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_PRODUCT_PRICING_ERROR,
                gsuite_product_pricing_error
            });
        });
};

export function getGSuiteBillingCycle(id) {
    const { dispatch } = store;

    dispatch({
        type: GSUITE_BILLING_CYCLE_REQUEST
    });
    API.gsuite.GET.billingCycle(id)
        .then((response) => {
            const gsuite_billing_cycle_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_BILLING_CYCLE_SUCCESS,
                gsuite_billing_cycle_data
            });
        })
        .catch(() => {
            dispatch({
                type: GSUITE_BILLING_CYCLE_ERROR
            });
        });
}

export function updateGSuiteBillingCycle(id, billingCycleID) {
    const { dispatch } = store;

    dispatch({
        type: GSUITE_UPDATE_BILLING_CYCLE_REQUEST
    });
    API.gsuite.PUT.billingCycle(id, billingCycleID)
        .then((response) => {
            const gsuite_update_billing_cycle_data = getDataFromSuccessResponse(response);
            dispatch({
                type: GSUITE_UPDATE_BILLING_CYCLE_SUCCESS,
                gsuite_update_billing_cycle_data
            });
        })
        .catch((error) => {
            const gsuite_update_billing_cycle_error = getErrorFromFailResponse(error);
            dispatch({
                type: GSUITE_UPDATE_BILLING_CYCLE_ERROR
            });
            pushNotification(gsuite_update_billing_cycle_error);
        });
}
