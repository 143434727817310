/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FormColumn.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{ children: React.ReactNode, half?: boolean }} props
 */
function FormColumn({ children, half }) {
    /***** RENDER *****/
    return <div className={classNames('FormColumn', { 'FormColumn--half': half })}>{children}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FormColumn;
