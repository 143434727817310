/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useLimitedAttempts } from 'utilities/hooks/useLimitedAttempts';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Lightbox from './lightbox';

/*   ACTIONS
 *****************************************************/
import { resetMigrationDetailsCheckService, submitMigrationDetailsCheckService, submitMigrationRequestForm } from 'containers/support/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MAX_VALIDATION_ATTEMPTS, migrationFormNameDetails } from './consts';
import { getFormValuesFromDetails } from './methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let FormWrapper = (props) => {
    const {
        children,
        service_id,
        support_submit_migration_details_check_service_status,
        support_submit_migration_details_check_service_data,
        support_submit_migration_request_form_status
    } = props;

    /***** STATE *****/
    const mutationObserver = useRef(null);
    const [migrationData, set_migrationData] = useState();
    const [lightboxState, set_lightboxState] = useState(false);
    const [canEditDetails, set_canEditDetails] = useState(true);
    const [stepOneComplete, set_stepOneComplete] = useState(false);
    const [serviceValidationData, set_serviceValidationData] = useState(null);
    const [serviceValidationModifiedData, set_serviceValidationModifiedData] = useState([]);

    /***** HOOKS *****/
    const [attemptsLeft, applyAttempt, depleteAttempts] = useLimitedAttempts(MAX_VALIDATION_ATTEMPTS);

    /***** FUNCTIONS *****/
    function allowEditDetails() {
        set_canEditDetails(true);
    }

    function validationSucceeded() {
        set_stepOneComplete(true);
        set_canEditDetails(false);
    }

    /**
     * Validate the base details
     * @param {string} service - Service, either `cpanel`, `other`, or `control_panel`
     * @param {Object} values - Form values Object.
     * @returns {Promise}
     */
    function validateDetails(service, values) {
        // Hit endpoint with values
        if (service !== 'cpanel') {
            validationSucceeded();
            return;
        }
        return submitMigrationDetailsCheckService({ service, service_id, values });
    }

    function handleSkipNow() {
        validationSucceeded();
    }

    /**
     * Submit final migration, Either by passing the migration data directly or calling it from the migrationData useState
     * @param {Object | undefined} _migrationData
     * @returns
     */
    function submitFinalMigration(_migrationData) {
        const finalData = _migrationData ? _migrationData : migrationData;

        /**
         * Check if there's failed or unsure values in the service validation data. this will be passed to the endpoint under `invalid_details`
         */
        if (
            serviceValidationData &&
            (serviceValidationData.failed.size > 0 || serviceValidationData.unsure.size > 0 || Object.keys(serviceValidationData.errors).length > 0)
        ) {
            const invalidProps = [...Array.from(serviceValidationData.failed), ...Array.from(serviceValidationData.unsure)];
            const invalid_details = {};

            invalidProps.forEach((prop) => {
                if (Object.prototype.hasOwnProperty.call(serviceValidationData.errors, prop) && serviceValidationData.errors[prop] !== '') {
                    invalid_details[prop] = serviceValidationData.errors[prop];
                }
            });

            if (Object.prototype.hasOwnProperty.call(serviceValidationData.errors, 'general')) {
                invalid_details.general = serviceValidationData.errors.general;
            }

            finalData.invalid_details = invalid_details;
        }

        return submitMigrationRequestForm(finalData);
    }

    /**
     *
     * @param {string} service - Service, either `cpanel`, `other`, or `control_panel`
     * @param {string} currentEnv - Current Environment, i.e. plesk, cpanel, etc.
     * @param {Object} values - Holds all the form values
     * @returns
     */
    function handleSubmitMigration(service, currentEnv, values) {
        if (!Object.prototype.hasOwnProperty.call(values, 'is_technical')) {
            values.is_technical = store.getState()?.app?.app_user_data?.is_technical ? true : false;
        }

        if (!Object.prototype.hasOwnProperty.call(values, 'is_urgent')) {
            values.is_urgent = false;
        }

        const formValues = getFormValuesFromDetails();

        Object.keys(formValues).forEach((key) => {
            values[key] = formValues[key];
        });

        values.service = service;
        values.service_id = service_id;
        values.migration_type = currentEnv;

        if (values.is_urgent) {
            set_migrationData(values);
            set_lightboxState('confirm');
            return;
        }

        return submitFinalMigration(values);
    }

    /**
     * when form updates we don't want to check for server validation anymore as we need to resubmit the validation.
     * @param {KeyboardEvent} e
     */
    function handleFormDetailsFieldChange(e) {
        const fieldName = e.target.name;
        if (!serviceValidationModifiedData.includes(fieldName)) {
            set_serviceValidationModifiedData([...serviceValidationModifiedData, fieldName]);
        }
    }

    /**
     * Handle mutation observer cleanup
     */
    function cleanUpMutationObserver() {
        document.removeEventListener('keyup', handleKeyUp);
        if (mutationObserver.current) {
            mutationObserver.current.disconnect();
            mutationObserver.current = null;
        }
    }

    /**
     * Simply check on each mutation if the validation button is no longer inactive.
     */
    function handleMutationObserverBehavior() {
        const validationButton = document.querySelector('.PanelDetailsValidationButton:not(.InactiveButton)');
        if (validationButton) {
            validationButton.focus();
            cleanUpMutationObserver();
        }
    }

    /**
     * handles if the user decides to tab away before the request loader finished,
     * @param {Event} e
     */
    function handleKeyUp(e) {
        if (e.key === 'Tab') {
            cleanUpMutationObserver();
        }
    }

    function handleSubmissionFailed() {
        const validationButton = document.querySelector('.PanelDetailsValidationButton');

        if (validationButton.classList.contains('InactiveButton')) {
            // Create Mutation Observer object to help keep track of button state
            const observer = new MutationObserver(handleMutationObserverBehavior);
            observer.observe(validationButton.parentNode, { childList: true, subtree: true });
            document.addEventListener('keyup', handleKeyUp);
            // If there's any running currently, disconnect that one to prevent memory leak
            if (mutationObserver.current) mutationObserver.current.disconnect();
            mutationObserver.current = observer;
        }
    }

    /***** EFFECTS *****/
    /**
     * useEffect for cleaning up the mutation observer in case that's active.
     * empty array because you only want it to run on component unmount
     */
    useEffect(() => {
        return cleanUpMutationObserver;
    }, []);

    /**
     * Cleanup the Migration details check service state
     */
    useEffect(() => {
        return resetMigrationDetailsCheckService;
    }, []);

    /**
     * Main useEffect hook, this helps determine if the form is validated or not on the frontend
     */
    useEffect(() => {
        const { dispatch } = store;

        const validationObject = {
            unsure: new Set(),
            failed: new Set(),
            corrections: new Set(),
            blank: new Set(),
            errors: {}
        };

        /**
         * When the service check comes back it will be success if the submission to the endpoint had all the right values,
         * Then in the response there will be the failed or corrections that come with it.
         */
        if (support_submit_migration_details_check_service_status === 'success') {
            if (Object.prototype.hasOwnProperty.call(support_submit_migration_details_check_service_data, 'corrections')) {
                /**
                 * Update form corrections name
                 */
                const { corrections } = support_submit_migration_details_check_service_data;
                for (const key in corrections) {
                    validationObject.corrections.add(key);
                    if (Object.prototype.hasOwnProperty.call(corrections, key)) {
                        dispatch(change(migrationFormNameDetails, key, corrections[key]));
                    }
                }
            }

            if (support_submit_migration_details_check_service_data?.failed?.target_not_empty) {
                validationSucceeded();
            } else if (Object.prototype.hasOwnProperty.call(support_submit_migration_details_check_service_data, 'failed')) {
                const migrationDetailsFailedKeys = Object.keys(support_submit_migration_details_check_service_data.failed);
                migrationDetailsFailedKeys.forEach(validationObject.failed.add, validationObject.failed);

                if (validationObject.failed.has('domain')) {
                    depleteAttempts();
                } else {
                    applyAttempt();
                }

                if (!validationObject.failed.has('domain') && migrationDetailsFailedKeys.some((prop) => ['username', 'password'].includes(prop))) {
                    validationObject.blank.add('domain');
                    validationObject.failed.add('domain');
                }

                /**
                 * Current ip failed, need to make domain, username and password "unsure"
                 * Does not include "ip_address" check for other and control_panel
                 */
                if (validationObject.failed.has('current_ip')) {
                    validationObject.unsure.add('domain').add('username').add('password');
                    validationObject.errors.general = support_submit_migration_details_check_service_data.details;
                }

                for (const key in support_submit_migration_details_check_service_data.failed) {
                    validationObject.errors[key] = support_submit_migration_details_check_service_data.failed[key];
                }

                handleSubmissionFailed();
            } else {
                // no failed data, can continue.
                validationSucceeded();
            }
        } else if (support_submit_migration_details_check_service_status === 'error') {
            Object.keys(getFormValuesFromDetails()).forEach(validationObject.failed.add, validationObject.failed);

            applyAttempt();

            handleSubmissionFailed();
        }

        if (['success', 'error'].includes(support_submit_migration_details_check_service_status)) {
            set_serviceValidationData(validationObject);
        }

        // If other dependencies are included, it will cause an infinite loop.
    }, [support_submit_migration_details_check_service_status]);

    /**
     * useEffect for helping the lightbox into the appropriate state after submission of the form
     */
    useEffect(() => {
        if (support_submit_migration_request_form_status === 'success') {
            set_lightboxState('success');
        }
    }, [support_submit_migration_request_form_status]);

    /**
     * Use effect to fire when new serviceValidationData comes through
     * This resets what forms have been changed since last modification
     */
    useEffect(() => {
        set_serviceValidationModifiedData([]);
    }, [serviceValidationData]);

    const childProps = {
        validateDetails,
        attemptsLeft,
        handleSkipNow,
        stepOneComplete,
        handleSubmitMigration,
        serviceValidationData,
        handleFormDetailsFieldChange,
        serviceValidationModifiedData,
        allowEditDetails,
        canEditDetails
    };

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <>
            <div className="migrationRequestForm__wrapper">
                {/* Render child form component */}
                {children(childProps)}
            </div>

            <Lightbox submitFinalMigration={submitFinalMigration} set_lightboxState={set_lightboxState} lightboxState={lightboxState} />
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

FormWrapper.propTypes = {
    /**
     *  The children prop is a render callback function that gets passed the props as shown in `childProps` above
     */
    children: PropTypes.func.isRequired,

    /**
     * The service id of the target service you wish to migrate to.
     */
    service_id: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
    support_submit_migration_details_check_service_status: state.support.support_submit_migration_details_check_service_status,
    support_submit_migration_details_check_service_data: state.support.support_submit_migration_details_check_service_data,
    support_submit_migration_request_form_status: state.support.support_submit_migration_request_form_status
});

FormWrapper = connect(mapStateToProps)(FormWrapper);

export default FormWrapper;
