/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * The return object for the useLimitedAttempts hook
 * @typedef {Object} useLimitedAttemptsReturnObject
 * @property {number} attemptsLeft - How many "attempts" are left after applying an attempt
 * @property {function} applyAttempt - Applies a single attempt.
 * @property {function} depleteAttempts - Sets the attempts remaining to 0
 */

/**
 * helper hook for boolean flag for limited attempts.
 * @param {number} maxAttempts - Amount of attempts are allowed to be used
 * @returns {useLimitedAttemptsReturnObject}
 */
export function useLimitedAttempts(maxAttempts) {
    const [attemptsLeft, set_Attempts] = useState(maxAttempts);

    function depleteAttempts() {
        set_Attempts(0);
    }

    function applyAttempt() {
        set_Attempts(attemptsLeft - 1);
    }

    return [attemptsLeft, applyAttempt, depleteAttempts];
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
