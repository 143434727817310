/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_DismissButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DismissButton extends Component {
    render() {
        const { onClick, background } = this.props;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <button type="button" className="DismissButton" onClick={onClick}>
                {background ? <div className="DismissButton__background" /> : ''}
                <i className="icon icon-x" />
            </button>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DismissButton.propTypes = {
    // The function that will dismiss whatever is being dismissed
    onClick: PropTypes.func,
    // Whether or not to have a square black background behind the dismiss button
    background: PropTypes.bool
};

export default DismissButton;
