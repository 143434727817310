import { _NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';
import { _NXDropZoneReduxFormDefault as NXDropZoneReduxFormDefault } from 'components/Form/NXDropZone/wrappers/reduxform/Default';
import { _NXDropZoneReduxFormWithKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/reduxform/WithKatanaFileUpload';
import { _NXDropZoneWithKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload';
import { _NXDropZoneReduxFormWithNamedAttachments } from './wrappers/reduxform/WithNamedAttachments';

const NXDropZone = Object.assign(_NXDropZone, {
    ReduxForm: {
        Default: NXDropZoneReduxFormDefault,
        WithKatanaFileUpload: _NXDropZoneReduxFormWithKatanaFileUpload,
        WithNamedAttachments: _NXDropZoneReduxFormWithNamedAttachments
    },
    Tanstack: {
        WithKatanaFileUpload: _NXDropZoneWithKatanaFileUpload
    }
});

export { NXDropZone };
