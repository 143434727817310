/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { QueryKey } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

export function createGetQueryDataMethod<TParams extends any, TData>(createQueryKey: (params: TParams) => QueryKey) {
    return function getQueryData(params: TParams): TData | undefined {
        return queryClient.getQueryData(createQueryKey(params));
    };
}
