export const lightboxes = /** @type {const} */ ({
    DISK_BLOCK: 'diskBlock',
    INVOICE: 'invoice',
    KEEP: 'keep',
    REMOVE: 'remove'
});

export const addonStatus = /** @type {const} */ ({
    ACTIVE: 'active',
    PENDING_DELETION: 'pending deletion',
    UNPAID: 'unpaid'
});

export const constants = /** @type {const} */ ({
    description: {
        diskBlock: 'If you require additional storage for your email hosting service, you can purchase an instant disk block.',
        usage: 'If you require additional storage, your plan can be upgraded to include more disk space.'
    },
    outstandingInvoice: 'You have an outstanding disk block invoice, make a payment now to complete the purchase process.',
    lightboxes: {
        [lightboxes.KEEP]: {
            description: "Renewing this Disk Space Block will mean you'll be invoiced for it at the end of your prepaid period."
        },
        [lightboxes.REMOVE]: {
            confirmationFields: {
                confirm_plan_limits:
                    'I understand that removing this disk block will reduce the available space on my email hosting service, and this may result in my service being suspended if I am outside my plan limits.',
                confirm_delete: 'I confirm all data stored as part of this addon, including backups, will be deleted.',
                confirm_backup: 'I confirm any data I require is backed up externally.'
            }
        }
    }
});
