/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/CheckBoxList';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SimpleCheckbox = React.FC<{
    /**
     * Label to display next to the checkbox
     */
    children?: React.ReactNode;

    /**
     * optional Classname to apply to the checkbox
     */
    className?: string;

    /**
     * Name of the checkbox
     */
    name: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ReactHookFormSimpleCheckbox: SimpleCheckbox = ({ children, name, className }) => {
    /***** HOOKS *****/
    const { field } = useController({ name });

    /***** RENDER *****/
    return (
        <CheckBoxList.Item isChecked={field.value} onChange={field.onChange} className={className}>
            {children}
        </CheckBoxList.Item>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
