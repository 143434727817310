type TParams<TData, TStatus extends number> = {
    data: TData;
    status?: TStatus;
    meta?: object;
    message?: string;
    links?: {
        self?: string;
    };
};

type TResponse<TData, TStatus extends number> = {
    data: TData;
    status: TStatus;
    meta?: object;
    message?: string;
    links?: {
        self?: string;
    };
};

export function createOptimisticResponseObject<TData extends object, TStatus extends number>(
    params: TParams<TData, TStatus>
): TResponse<TData, TStatus> {
    const status = params.status ? params.status : (200 as TStatus);
    return {
        status,
        data: params.data,
        message: params.message ?? 'Optimistic Response Object'
    };
}
