/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ReactChildrenProp } from 'utilities/consts';
import './_SimpleTable.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      rows: React.ReactNode[];
 *      alignRowItems?: 'start';
 *      className?: string;
 * }} props
 */
const SimpleTable = (props) => {
    const { rows, alignRowItems, className } = props;

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <ul
            className={classNames(
                'SimpleTable',
                {
                    [`SimpleTable--align-row-items--${alignRowItems}`]: alignRowItems
                },
                className
            )}
        >
            {rows.map((columns, i) => {
                return (
                    <li key={i} className="SimpleTable__row">
                        {columns}
                    </li>
                );
            })}
        </ul>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SimpleTable.propTypes = {
    /**
     * the row that show the content of the table which must include a key i.e.:
     * [<p key="foo">foo</p>,<p key="bar">bar</p>]
     */
    rows: PropTypes.arrayOf(ReactChildrenProp)
};

export default SimpleTable;
