/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePreviewSplitInvoiceQuery } from 'containers/billing/queries/invoice/usePreviewSplitInvoiceQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SPLIT_INVOICE_PROP_TYPES, SPLIT_INVOICE_WHICH_VALUES, defaultSplitPreviewData } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SplitInvoiceTotal({ splitData, which }) {
    /***** QUERIES *****/
    const { data: preview_split_invoice_data = defaultSplitPreviewData, isFetching: isPreviewSplitInvoiceFetching } =
        usePreviewSplitInvoiceQuery(splitData);

    /***** RENDER HELPERS *****/
    const { subtotal, total } = preview_split_invoice_data[which];

    /***** RENDER *****/
    return (
        <div className="splitInvoiceView__total">
            <div className="total__row">
                <div className="total__title">Sub Total</div>
                <div className="total__value">
                    {isPreviewSplitInvoiceFetching ? <RequestLoader fillHeight={16} height={16} /> : <>${Number(subtotal).toFixed(2)} AUD</>}
                </div>
            </div>
            <div className="total__row">
                <div className="total__title">Total Amount Due</div>
                <div className="total__value highlight">
                    {isPreviewSplitInvoiceFetching ? <RequestLoader fillHeight={16} height={16} /> : <>${Number(total).toFixed(2)} AUD</>}
                </div>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SplitInvoiceTotal.propTypes = {
    splitData: SPLIT_INVOICE_PROP_TYPES.splitData,

    /**
     * Which Invoice total to render
     */
    which: PropTypes.oneOf(SPLIT_INVOICE_WHICH_VALUES)
};

export default SplitInvoiceTotal;
