/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FormGenericStep2 from '../formGenericStep2';
import { MigrationReduxControlPanelFormPart2 } from './controlPanelValidate';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ControlPanelSection2 = ({ handleSubmit, form }) => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <form onSubmit={handleSubmit} className="migrationRequestForm__form">
            <FormGenericStep2 />

            <ReduxFormButton form={form} className="MigrationRequestConfirmButton">
                Submit Migration Form
            </ReduxFormButton>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ControlPanelSection2 = reduxForm(MigrationReduxControlPanelFormPart2)(ControlPanelSection2);

export default ControlPanelSection2;
