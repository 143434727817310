/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { clone, remove } from 'lodash';
import React from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { DragHandle } from 'components/DragHandle';
import { CheckBox } from 'components/Form/CheckBox';
import { Input } from 'components/Form/Input';
import Grid from 'components/Grid';
import { SortableItem } from 'components/SortableItem';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SectionOrganiserChildBaseBar } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild/baseBar';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStateEffect } from 'utilities/hooks/useStateEffect';
import { useStore } from 'utilities/hooks/useStore';
import { validationMaximum } from 'utilities/methods/form/validations/validationMaximum';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { navigationLabelsWritable } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/consts';
import './_sectionOrganiserChild.scss';
const validationMax = (value) => validationMaximum(value, 50);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      sectionData: NSectionOrganiser.SectionData
 *      id: number;
 *      isEditingNavigationMenu: boolean;
 *      isShowingInNavigationMenuItem: boolean;
 *      onShowInNavigationMenuItemChanged: (isChecked: boolean) => void;
 * }>}
 */
const NavigationOrganiserChild = (props) => {
    const { sectionData, isShowingInNavigationMenuItem, onShowInNavigationMenuItemChanged } = props;
    const { title, sectionID, customTitle } = sectionData;

    const baseValue = customTitle ?? title;
    /***** STATE *****/
    const { value: isUpdatingSectionName, setTrue: showUpdatingSectionName, setFalse: hideUpdatingSectionName } = useBoolean(false);

    /***** HOOKS *****/
    const [navigationLabelUpdates, setNavigationLabelUpdates] = useStore(navigationLabelsWritable);

    /***** QUERIES *****/

    const isCustomTitle = title !== baseValue;
    const [titleValue, setTitleValue] = useStateEffect(baseValue);
    /***** FUNCTIONS *****/

    const confirmSectionNameUpdate = () => {
        if (titleValue !== baseValue) {
            const clonedNavigationLabelUpdates = clone(navigationLabelUpdates);
            remove(clonedNavigationLabelUpdates, (update) => update.sectionID === sectionID);
            clonedNavigationLabelUpdates.push({ sectionID, attributes: { name: titleValue } });
            setNavigationLabelUpdates(clonedNavigationLabelUpdates);
        }
        hideUpdatingSectionName();
    };

    /***** RENDER *****/
    return (
        <SortableItem id={props.id}>
            {({ isDragging }) => (
                <Grid
                    columns="40px auto auto 1fr"
                    className={classNames('SectionOrganiserChild', {
                        'SectionOrganiserChild--isDragging': isDragging
                    })}
                    gap={2}
                    justify-children--stretch
                    inject
                >
                    <SectionOrganiserChildBaseBar>
                        <CheckBox isChecked={isShowingInNavigationMenuItem} onChange={onShowInNavigationMenuItemChanged} />
                        <div className="SectionOrganiserChild__divider" />
                        <DragHandle.DNDKit id={props.id} />

                        <Padding y={1}>
                            <Grid columns="1fr 100px" justify-children--stretch>
                                {isUpdatingSectionName ? (
                                    <Input.Decoupled.Default
                                        value={titleValue}
                                        intrinsicProps={{
                                            onChange: (e) => setTitleValue(e.target.value),
                                            onKeyDown: (e) => e.key === 'Enter' && confirmSectionNameUpdate()
                                        }}
                                        validate={[validationMax]}
                                    />
                                ) : (
                                    <Text size--m medium lead--xs break-word>
                                        {isCustomTitle || titleValue !== title ? (
                                            <Flex wrap items="center" gap={0}>
                                                {titleValue !== title ? titleValue : customTitle}
                                                &nbsp;
                                                <Text size--s secondary>
                                                    ({title})
                                                </Text>
                                            </Flex>
                                        ) : (
                                            title
                                        )}
                                    </Text>
                                )}
                                {isUpdatingSectionName ? (
                                    <SolidButton type="onClick" onClick={confirmSectionNameUpdate} color="confirm">
                                        <Text bold size--xss>
                                            Update
                                        </Text>
                                    </SolidButton>
                                ) : (
                                    <SolidButton color="gray" type="onClick" onClick={showUpdatingSectionName}>
                                        Rename
                                    </SolidButton>
                                )}
                            </Grid>
                        </Padding>
                    </SectionOrganiserChildBaseBar>
                </Grid>
            )}
        </SortableItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NavigationOrganiserChild;
