/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TransferLockCheckedMessage from 'containers/domain/modules/transferLock/checkedMessage';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

/*   ACTIONS
 *****************************************************/
import { enableTransferLock, disableTransferLock, getTransferLock } from '../../action';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TransferLock extends Component {
    constructor(props) {
        super(props);
        this.handleTransferLock = this.handleTransferLock.bind(this);
        this.state = {
            lockStatus: false
        };
    }

    handleTransferLock(values) {
        const { domainid, disableTransferLock, enableTransferLock } = this.props;

        if (values) {
            enableTransferLock(domainid);
        } else {
            disableTransferLock(domainid);
        }
    }

    componentDidUpdate(prevProps) {
        const { domain_lock_check_status, domain_lock_check_data } = this.props;
        registerScrollEvents(this, domain_lock_check_status === 'success' && prevProps.domain_lock_check_status === 'loading');

        if (domain_lock_check_status === 'success' && prevProps.domain_lock_check_status === 'loading') {
            const { attributes } = domain_lock_check_data;
            const { transfer_is_locked } = attributes;

            this.setState({
                lockStatus: transfer_is_locked
            });
        }
    }

    render() {
        const { domainid, disabled, getTransferLock, domain_lock_check_status, domain_lock_status } = this.props;
        const { lockStatus } = this.state;
        const { handleTransferLock } = this;

        /*   HANDLE LOADING STATUS
         **********************************************************************************************************/
        const handleLoadingStatus = () => {
            if (domain_lock_check_status === 'loading' || domain_lock_status === 'loading') {
                return 'loading';
            } else if (domain_lock_check_status === 'error' || domain_lock_status === 'error') {
                return 'error';
            }
            return 'success';
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {
            action: {
                label: 'Enable Transfer Lock',
                type: 'onClick',
                className: '',
                color: '',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    handleTransferLock(true);
                }
            }
        };

        if (lockStatus) {
            conditionalProps.action = {
                label: 'Disable',
                type: 'onClick',
                className: '',
                color: 'warn',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    handleTransferLock(false);
                }
            };
            conditionalProps = {
                ...conditionalProps,
                bottom: true,
                columns: [
                    {
                        render: <TransferLockCheckedMessage />
                    }
                ]
            };
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="transferLock"
            >
                <Box
                    request={{
                        action: getTransferLock,
                        args: domainid,
                        status: domain_lock_check_status
                    }}
                    disabled={disabled}
                    className="transferLock__box"
                    status={handleLoadingStatus()}
                    title="Transfer Lock"
                    desc="Transfer lock will prevent your domain name from being transferred away to another provider regardless of the EPP/password being correct. We recommend you enabled this to prevent unauthorised transfers from taking place."
                    info="Transfer lock adds an additional level of security to your domain name and prevents someone from unlawfully gaining control of your domain. You can turn off Transfer Lock at anytime if you wish to transfer your domain name away to another registrar."
                    {...conditionalProps}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        domain_lock_status: state.domain.domain_lock_status,
        domain_lock_check_status: state.domain.domain_lock_check_status,
        domain_lock_check_data: state.domain.domain_lock_check_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            enableTransferLock,
            disableTransferLock,
            getTransferLock
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransferLock));
