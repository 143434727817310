/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_serviceSelectItemNotAllowed.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ServiceSelectItemNotAllowed = ({ children, disabled, content }) => {
    if (disabled) {
        return (
            <HoverTooltip usePortal className="ServiceSelectItemNotAllowed" content={content ?? 'This domain cannot be used with the current tool'}>
                {children}
            </HoverTooltip>
        );
    }

    return children;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ServiceSelectItemNotAllowed.propTypes = {
    /**
     * React Children
     */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,

    /**
     * Wether the Component is having to show the hover tooltip
     */
    disabled: PropTypes.bool.isRequired,

    /**
     * The content for the tooltip
     */
    content: PropTypes.string
};

export default ServiceSelectItemNotAllowed;
