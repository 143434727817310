/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_burnedToast.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NBackgroundColour } from 'components/Utils/BackgroundColour/types';

interface BurnedToastProps {
    icon: React.ReactNode;
    children: React.ReactNode;
    colour?: NBackgroundColour.Colours;
    onClick?: () => void;
    to?: string;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BurnedToast: React.FC<BurnedToastProps> = ({ icon, children, colour = 'notice', onClick, to }) => {
    /***** RENDER HELPERS *****/
    const classes = useMemo(
        () =>
            classNames('BurnedToast', {
                [`BurnedToast--colour-${colour}`]: colour
            }),
        [colour]
    );

    const content = useMemo(() => {
        return (
            <Flex items="center">
                {icon}
                <div>{children}</div>
            </Flex>
        );
    }, []);

    /***** RENDER *****/

    if (to) {
        return (
            <Link to={to} className={classes} onClick={onClick}>
                {content}
            </Link>
        );
    }
    if (onClick) {
        return (
            <button type="button" className={classes} onClick={onClick}>
                {content}
            </button>
        );
    }
    return <div className={classes}>{content}</div>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default BurnedToast;
