/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ClearForm from '../lightboxes/clear';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { renderCheckboxField, requiredAcceptedValidation } from 'utilities/methods/form';

const formName = 'confirmForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ConfirmForm extends Component {
    constructor(props) {
        super(props);
        this.toggleClearForm = this.toggleClearForm.bind(this);
        this.state = {
            showClearForm: false,
            cert: {
                approver_email: '',
                city: '',
                state: '',
                country: '',
                domain: '',
                organisation: '',
                phone: '',
                business_category: ''
            }
        };
    }

    toggleClearForm() {
        const { showClearForm } = this.state;

        this.setState({
            showClearForm: !showClearForm
        });
    }

    componentDidMount() {
        const { ssl_csr_information_data } = this.props;

        if (ssl_csr_information_data) {
            const { attributes } = ssl_csr_information_data;

            this.setState({
                cert: {
                    ...attributes
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { ssl_csr_information_status, ssl_csr_information_data } = this.props;

        if (ssl_csr_information_status === 'success' && prevProps.ssl_csr_information_status === 'loading') {
            const { attributes } = ssl_csr_information_data;

            this.setState({
                cert: {
                    ...attributes
                }
            });
        }
    }

    /*********************************************************************************************************
     *   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { handleSubmit, resetCert } = this.props;
        const { showClearForm, cert } = this.state;
        const { toggleClearForm } = this;

        const handleConfirmTableRender = () => {
            const returnTitle = (data) => {
                switch (data) {
                    case 'domain':
                        return 'Domain Name';

                    case 'organisation':
                        return 'Organisation';

                    case 'phone':
                        return 'Phone';

                    case 'state':
                        return 'State';

                    case 'city':
                        return 'City';

                    case 'country':
                        return 'Country';

                    case 'approver_email':
                        return 'Approver Email Address';

                    case 'business_category':
                        return 'Business Category';

                    default:
                        return 'N/A';
                }
            };

            const returnSubtitle = (data) => {
                switch (data) {
                    case true:
                    case 'true':
                        return 'Yes';

                    case false:
                    case 'false':
                        return 'No';

                    default:
                        return data;
                }
            };

            return Object.keys(cert).map((key) => {
                return (
                    <div key={key} className="confirmService__table--row">
                        <div className="title">{returnTitle(key)}</div>
                        <div className="subtitle">{returnSubtitle(cert[key])}</div>
                    </div>
                );
            });
        };

        /*********************************************************************************************************
         *   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="confirmService__confirm" onSubmit={handleSubmit}>
                <div className="confirmService__description">
                    <h4 className="confirmService__header">SSL Certificate Information</h4>
                    <Anchor className="confirmService__link" onClick={toggleClearForm}>
                        Start Over
                    </Anchor>
                </div>
                <div className="confirmService__table">{handleConfirmTableRender()}</div>
                <div className="confirmation__container">
                    <h5 className="confirmation__heading">Confirmation</h5>
                    <div className="confirmation__input">
                        <Field
                            name="confirmation"
                            label="I understand that once I configure this SSL, the process cannot be reversed or refunded."
                            component={renderCheckboxField}
                            validate={[requiredAcceptedValidation]}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                    <div className="confirmation__input">
                        <Field
                            name="mailbox"
                            label={`I have set up ${cert.approver_email} as a mailbox or forwarder.`}
                            component={renderCheckboxField}
                            validate={[requiredAcceptedValidation]}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <ReduxFormButton form={formName} className="confirmService_submit--confirmation">
                    Confirm
                </ReduxFormButton>
                {!!showClearForm && (
                    <OverlayLightbox onOpen={showClearForm} title="Clear SSL Configuration?" onClose={toggleClearForm}>
                        <ClearForm resetCert={resetCert} />
                    </OverlayLightbox>
                )}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ConfirmForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(ConfirmForm);

const mapStateToProps = (state) => {
    return {
        initialValues: {
            confirmation: false,
            mailbox: false
        },
        ssl_csr_information_data: state.ssl.ssl_csr_information_data,
        ssl_csr_information_status: state.ssl.ssl_csr_information_status,
        ssl_csr_submission_error: state.ssl.ssl_csr_submission_error,
        ssl_csr_submission_status: state.ssl.ssl_csr_submission_status,
        ssl_csr_submission_data: state.ssl.ssl_csr_submission_data
    };
};

export default connect(mapStateToProps, null)(ConfirmForm);
