/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import NXBox from 'components/NXBox';
import Progress from 'components/Progress';
import SolidTag from 'components/Tags/SolidTag';
import Tooltip from 'components/Tooltip';
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { addDnsRecord, getDomainRecords } from 'containers/domain/action';
import { getDomainsList } from '../../../services/action';
import { getEmailHostingDomainGroupDetails, sendServiceDetails } from '../../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import './_emailDetails.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EmailOverviewOverview extends Component {
    static dkimRecordAdder = ['free_dns', 'forwarding'];

    constructor(props) {
        super(props);

        this.state = {
            usage: null,
            toggleNotification: undefined,
            matchingDomain: null
        };

        this.processLimit = this.processLimit.bind(this);
        this.processUsage = this.processUsage.bind(this);
    }

    processLimit(limit) {
        if (limit && limit === 'unlimited') {
            return undefined;
        }

        return limit + 'GB';
    }

    processUsage(usage) {
        if (usage && usage === 'unlimited') {
            return undefined;
        }

        return usage + 'MB';
    }

    componentDidMount() {
        const { getEmailHostingDomainGroupDetails, serviceid } = this.props;

        getEmailHostingDomainGroupDetails(serviceid);
    }

    componentDidUpdate(prevProps) {
        const {
            serviceid,
            email_domain_group_details_status,
            email_domain_group_details_data,
            domain_record_status,
            domain_list_status,
            domain_list_data,
            getEmailHostingDomainGroupDetails,
            getDomainsList
        } = this.props;
        const { domain } = this.state;

        registerScrollEvents(this, email_domain_group_details_status === 'success' && prevProps.email_domain_group_details_status === 'loading');

        if (email_domain_group_details_status === 'success' && prevProps.email_domain_group_details_status === 'loading') {
            const { attributes } = email_domain_group_details_data;

            this.setState(
                {
                    ...attributes
                },
                () => getDomainsList()
            );
        }

        if (domain_list_status === 'success' && prevProps.domain_list_status === 'loading') {
            const matchingDomain = domain_list_data
                .filter((item) => item.attributes.domain === domain)
                .map((item) => ({ domain: item.attributes.domain, id: item.id }))[0];

            this.setState(
                {
                    matchingDomain
                },
                () => {
                    if (matchingDomain) getDomainRecords(matchingDomain.id, { hideError: true });
                }
            );
        }

        if (domain_record_status === 'success' && prevProps.domain_record_status === 'loading') {
            getEmailHostingDomainGroupDetails(serviceid);
        }
    }

    render() {
        const {
            serviceid,
            sendServiceDetails,
            email_domain_group_details_status,
            domain_record_status,
            domain_current_configuration_data,
            addDnsRecord,
            email_send_details_status
        } = this.props;
        const { usage, webmail, incoming_server, outgoing_server, mx, ports, spf, dkim, autoconfig, autodiscover, matchingDomain } = this.state;
        const { processLimit, processUsage } = this;

        const copyData = (data) => {
            const el = document.createElement('textarea');
            el.value = data;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        };

        const recordHealth = (check) => {
            return check?.success ? (
                <SolidTag color="success">
                    Correctly Configured
                    <i className="icon icon-tick-solid" />
                </SolidTag>
            ) : (
                <SolidTag color="error">
                    Not Configured
                    <i className="icon icon-x" />
                </SolidTag>
            );
        };

        const recordHealthMessage = (check) => {
            return !check?.success && check?.message && Array.isArray(check.message) && check.message.length > 0 ? (
                <ul className="emailDetails__recordHealthMessageList">
                    {check.message.map((item, index) => (
                        <li key={index} className="emailDetails__recordHealthMessage">
                            {item}
                        </li>
                    ))}
                </ul>
            ) : (
                ''
            );
        };

        const renderAddRecord = (record) => {
            if (!matchingDomain || !domain_current_configuration_data) {
                const urls = {
                    spf: '/support/support-centre/email-hosting/spf-records-for-dedicated-email-hosting',
                    dkim: '/support/support-centre/email-hosting/can-i-set-up-dkim-on-dedicated-email-hosting'
                };

                const url = urls[record] ?? null;

                if (!url) {
                    return;
                }

                return (
                    <Anchor target="_blank" href={url}>
                        Find out how to add record
                    </Anchor>
                );
            }

            if (!EmailOverviewOverview.dkimRecordAdder.includes(domain_current_configuration_data.attributes.config)) {
                return (
                    <HoverTooltip alignment="right" content='To add this DNS record you must have your DNS configuration set to "DNS Hosting".'>
                        <InactiveButton className="emailDetails__action" size="small">
                            Add Record
                        </InactiveButton>
                    </HoverTooltip>
                );
            }

            if (domain_record_status === 'loading') {
                return (
                    <InactiveButton className="emailDetails__action" size="small">
                        Add Record
                    </InactiveButton>
                );
            }

            const recordValues = {
                spf: {
                    type: 'TXT',
                    hostname: matchingDomain.domain,
                    content: spf.record,
                    prio: 0,
                    ttl: 3600
                },
                dkim: {
                    type: 'TXT',
                    hostname: dkim.hostname,
                    content: dkim.record,
                    prio: 0,
                    ttl: 3600
                },
                autodiscover: {
                    type: 'CNAME',
                    hostname: autodiscover.hostname,
                    content: autodiscover.record,
                    prio: 0,
                    ttl: 3600
                },
                autoconfig: {
                    type: 'CNAME',
                    hostname: autoconfig.hostname,
                    content: autoconfig.record,
                    prio: 0,
                    ttl: 3600
                }
            };

            const addRecord = recordValues[record] ?? null;

            if (!addRecord) {
                return;
            }

            return (
                <SolidButton
                    className="emailDetails__action"
                    size="small"
                    color="info"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        addDnsRecord(matchingDomain.id, addRecord);
                    }}
                >
                    Add Record
                </SolidButton>
            );
        };

        const handleDetailsRender = () => {
            return (
                <div className="emailDetails__container">
                    {usage && (
                        <div className="emailDetails__progress">
                            <Progress
                                title="Storage Space"
                                max={usage.disk_limit !== 'unlimited' ? parseFloat(usage.disk_limit * 1000) : undefined}
                                value={usage.disk_used}
                                valueTitle={processUsage(usage.disk_used)}
                                maxTitle={processLimit(usage.disk_limit)}
                            />
                        </div>
                    )}

                    <div className="emailDetails__information">
                        <div className="simplifiedTable">
                            <div className="simplifiedTable__header">
                                <div className="simplifiedTable__row">
                                    <div className="simplifiedTable__column">Configure Email Software</div>
                                </div>
                            </div>
                            <div className="simplifiedTable__body">
                                <div className="simplifiedTable__row">
                                    <div className="simplifiedTable__column">
                                        <p>
                                            Please see our{' '}
                                            <Anchor target="_blank" href="https://ventraip.com.au/faq/article/how-do-i-set-up-my-emails/">
                                                {' '}
                                                FAQ{' '}
                                            </Anchor>{' '}
                                            for how to add your email account to popular mail apps.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="emailDetails__information">
                        <div className="simplifiedTable responsive">
                            <div className="simplifiedTable__header">
                                <div className="simplifiedTable__row">
                                    <div className="simplifiedTable__column">
                                        Server Details
                                        <Tooltip info="These details are required when setting up your incoming and outgoing mail servers." />
                                    </div>
                                </div>
                            </div>
                            <div className="simplifiedTable__body">
                                {webmail && (
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Webmail</div>
                                        <div className="simplifiedTable__column">{webmail}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(webmail);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                )}
                                {incoming_server && (
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Incoming Mail Server</div>
                                        <div className="simplifiedTable__column">{incoming_server}</div>
                                    </div>
                                )}
                                {outgoing_server && (
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Outgoing Mail Server</div>
                                        <div className="simplifiedTable__column">{outgoing_server}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {ports && (
                        <div className="emailDetails__information">
                            <div className="simplifiedTable">
                                <div className="simplifiedTable__header">
                                    <div className="simplifiedTable__row portRow--header">
                                        <div className="simplifiedTable__column">
                                            Ports
                                            <Tooltip info="Ports are used to connect your mail server to your email client." />
                                        </div>
                                        <div className="simplifiedTable__column subtitle">Secure</div>
                                        <div className="simplifiedTable__column subtitle">Unsecure</div>
                                    </div>
                                </div>
                                <div className="simplifiedTable__body">
                                    {ports.incoming_pop3 && (
                                        <div className="simplifiedTable__row portRow">
                                            <div className="simplifiedTable__column title">Incoming POP3 Port</div>
                                            <div className="simplifiedTable__column">{ports.incoming_pop3.secure_port}</div>
                                            <div className="simplifiedTable__column">{ports.incoming_pop3.unsecure_port}</div>
                                        </div>
                                    )}
                                    {ports.incoming_imap && (
                                        <div className="simplifiedTable__row portRow">
                                            <div className="simplifiedTable__column title">Incoming IMAP Mail Port</div>
                                            <div className="simplifiedTable__column">{ports.incoming_imap.secure_port}</div>
                                            <div className="simplifiedTable__column">{ports.incoming_imap.unsecure_port}</div>
                                        </div>
                                    )}
                                    {ports.outgoing_smtp && (
                                        <div className="simplifiedTable__row portRow">
                                            <div className="simplifiedTable__column title">Outgoing SMTP Port</div>
                                            <div className="simplifiedTable__column">{ports.outgoing_smtp.secure_port}</div>
                                            <div className="simplifiedTable__column">{ports.outgoing_smtp.unsecure_port}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {mx && mx.records.length > 0 ? (
                        <div className="emailDetails__information">
                            <div className="simplifiedTable">
                                <div className="simplifiedTable__header">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column">
                                            MX Records
                                            <Tooltip info="MX records are used to tell the world which mail server to send your emails to." />
                                            {recordHealth(mx.check)}
                                        </div>
                                    </div>
                                </div>
                                {recordHealthMessage(mx.check)}
                                <div className="simplifiedTable__body">
                                    {mx.records.map((record, index) => {
                                        return (
                                            <div key={index} className="simplifiedTable__row">
                                                <div className="simplifiedTable__column">
                                                    {record.host}
                                                    <strong>(Priority {record.priority})</strong>
                                                </div>
                                                <div className="simplifiedTable__column action">
                                                    <Anchor
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            copyData(record.host);
                                                        }}
                                                    >
                                                        <div className={`copyText-${record.host}`}>Copy</div>
                                                        <i className="icon icon-x" />
                                                    </Anchor>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {spf && (
                        <div className="emailDetails__information">
                            <div className="simplifiedTable">
                                <div className="simplifiedTable__header">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column configuration">
                                            <div className="configuration__record">
                                                SPF Record
                                                <Tooltip info="SPF record is an email authentication method designed to detect forging sender addresses during the delivery of the email." />
                                                {recordHealth(spf.check)}
                                            </div>
                                            {!spf.check?.success && renderAddRecord('spf')}
                                        </div>
                                    </div>
                                </div>
                                {recordHealthMessage(spf.check)}
                                <div className="simplifiedTable__body">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column">{spf.record}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(spf.record);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {dkim && (
                        <div className="emailDetails__information">
                            <div className="simplifiedTable responsive">
                                <div className="simplifiedTable__header">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column configuration">
                                            <div className="configuration__record">
                                                DKIM Record
                                                <Tooltip info="DKIM records tell other mail servers that this email was sent by you, and was authorised to be sent from our server on your behalf." />
                                                {recordHealth(dkim.check)}
                                            </div>
                                            {!dkim.check?.success && renderAddRecord('dkim')}
                                        </div>
                                    </div>
                                </div>
                                {recordHealthMessage(dkim.check)}
                                <div className="simplifiedTable__body">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Hostname</div>
                                        <div className="simplifiedTable__column">{dkim.hostname}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(dkim.hostname);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Content (TXT)</div>
                                        <div className="simplifiedTable__column">{dkim.record}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(dkim.record);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {autodiscover && (
                        <div className="emailDetails__information">
                            <div className="simplifiedTable responsive">
                                <div className="simplifiedTable__header">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column configuration">
                                            <div className="configuration__record">
                                                Autodiscover Record
                                                <Tooltip info="The Autodiscover record allows certain email clients to automatically receive information about the settings required for your Email Hosting service." />
                                                {recordHealth(autodiscover.check)}
                                            </div>
                                            {!autodiscover.check?.success && renderAddRecord('autodiscover')}
                                        </div>
                                    </div>
                                </div>
                                {recordHealthMessage(autodiscover.check)}
                                <div className="simplifiedTable__body">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Hostname</div>
                                        <div className="simplifiedTable__column">{autodiscover.hostname}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(autodiscover.hostname);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Content (CNAME)</div>
                                        <div className="simplifiedTable__column">{autodiscover.record}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(autodiscover.record);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {autoconfig && (
                        <div className="emailDetails__information">
                            <div className="simplifiedTable responsive">
                                <div className="simplifiedTable__header">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column configuration">
                                            <div className="configuration__record">
                                                Autoconfiguration Record
                                                <Tooltip info="The Autoconfiguration record allows certain email clients to automatically receive information about the settings required for your Email Hosting service." />
                                                {recordHealth(autoconfig.check)}
                                            </div>
                                            {!autoconfig.check?.success && renderAddRecord('autoconfig')}
                                        </div>
                                    </div>
                                </div>
                                {recordHealthMessage(autoconfig.check)}
                                <div className="simplifiedTable__body">
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Hostname</div>
                                        <div className="simplifiedTable__column">{autoconfig.hostname}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(autoconfig.hostname);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                    <div className="simplifiedTable__row">
                                        <div className="simplifiedTable__column title">Content (CNAME)</div>
                                        <div className="simplifiedTable__column">{autoconfig.record}</div>
                                        <div className="simplifiedTable__column action">
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyData(autoconfig.record);
                                                }}
                                            >
                                                Copy
                                            </Anchor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="emailDetails"
            >
                <NXBox className="emailDetails__box" initialStatus={email_domain_group_details_status}>
                    <NXBox.Top
                        title="Service Details"
                        description="The following details are used when connecting your domain name and email client to your mail server."
                    />
                    {handleDetailsRender()}
                    <NXBox.Bottom>
                        <NXBox.BottomColumns
                            columns={[
                                <Text key={1} secondary size--s lead--s>
                                    <p>
                                        If you require a copy of all your service details, we can send them to your {application} account email
                                        address.
                                    </p>
                                </Text>,
                                email_send_details_status === 'loading' ? (
                                    <InactiveButton loading>Send Service Details</InactiveButton>
                                ) : (
                                    <OutlineButton key={2} type="onClick" onClick={() => sendServiceDetails(serviceid)}>
                                        Send Service Details
                                    </OutlineButton>
                                )
                            ]}
                        />
                    </NXBox.Bottom>
                </NXBox>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            domain_record_status: state.domain.domain_record_status,
            email_domain_group_details_status: state.email.email_domain_group_details_status,
            email_domain_group_details_data: state.email.email_domain_group_details_data,
            email_send_details_status: state.email.email_send_details_status,
            domain_list_status: state.services.domain_list_status,
            domain_list_data: state.services.domain_list_data,
            domain_current_configuration_data: state.domain.domain_current_configuration_data
        }),
        {
            getEmailHostingDomainGroupDetails,
            sendServiceDetails,
            addDnsRecord,
            getDomainsList
        }
    )(EmailOverviewOverview)
);
