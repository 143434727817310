import { company } from 'config/config';

/**
 * @type {CancelServiceReasonValue[]}
 */
export const cancelServiceReasonOptions = [
    {
        label: 'No longer need this service',
        value: 'No longer need this service'
    },
    {
        label: `Transferring to another ${company} service`,
        value: `Transferring to another ${company} service`
    },
    {
        label: 'Unhappy with the performance of the service',
        value: 'Unhappy with the performance of the service'
    },
    {
        label: 'The quality of support provided was unsatisfactory',
        value: 'The quality of support provided was unsatisfactory'
    },
    {
        label: 'The price and inclusions are no longer competitive',
        value: 'The price and inclusions are no longer competitive'
    },
    {
        label: 'Other',
        value: 'Other'
    }
];

/**
 * @type {Record<AvailableCancelServiceAcknowledgements, string>}
 */
export const CANCEL_SERVICE_AVAILABLE_ACKNOWLEDGEMENTS = /** @type {const} */ ({
    confirm_delete: 'I confirm all data stored as part of this service will be deleted.',
    confirm_dns_change: 'I understand that any nameservers / DNS linked to this VIPsites service will no longer work',
    confirm_backup: 'I confirm any data I require is backed up externally'
});
