/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { application, company } from 'config/config';
import htmr from 'htmr';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import MoveForm from './form/moveForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OutlineDropdown from 'components/Dropdowns/OutlineDropdown';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { cancelMoveService, moveService, moveStatus } from 'containers/services/action';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Move.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Move extends Component {
    constructor(props) {
        super(props);
        this.handleMoveForm = this.handleMoveForm.bind(this);
        this.openMoveForm = this.openMoveForm.bind(this);
        this.closeMoveForm = this.closeMoveForm.bind(this);
        this.state = {
            showMoveForm: false,
            isAdditionalUser: false
        };
    }

    openMoveForm() {
        this.setState({
            showMoveForm: true
        });
    }

    closeMoveForm() {
        this.setState({
            showMoveForm: false
        });
    }

    handleMoveForm({ email }) {
        const { id, moveService } = this.props;

        const attributes = {
            service_id: id,
            email
        };

        moveService(attributes);
    }

    componentDidUpdate(prevProps) {
        const { service_move_status, service_move_invitation_status, service_move_invitation_error } = this.props;
        const { closeMoveForm } = this;

        registerScrollEvents(this, service_move_status === 'success' && prevProps.service_move_status === 'loading');

        if (
            service_move_invitation_status === 'error' &&
            prevProps.service_move_invitation_status === 'loading' &&
            service_move_invitation_error?.status === 401
        ) {
            this.setState({
                isAdditionalUser: true
            });
        }

        if (service_move_status === 'success' && prevProps.service_move_status === 'loading') {
            closeMoveForm();
        }
    }

    render() {
        const {
            history,
            moveStatus,
            service_move_invitation_status,
            service_move_invitation_data,
            service_move_cancel_status,
            service_move_status,
            cancelMoveService,
            moveService,
            id,
            product,
            domain
        } = this.props;
        const { showMoveForm, isAdditionalUser } = this.state;
        const { handleMoveForm, openMoveForm, closeMoveForm } = this;
        const hasMoveInvitation =
            service_move_invitation_data &&
            service_move_invitation_data.length >= 1 &&
            service_move_invitation_data.some(({ service_id }) => service_id === Number(id));

        const renderPendingInvitation = () => {
            if (hasMoveInvitation) {
                return service_move_invitation_data.map(({ id: move_id, email }, index) => {
                    const attributes = {
                        service_id: id,
                        email
                    };
                    const menu = [
                        {
                            label: 'Resend Invitation',
                            type: 'onClick',
                            onClick: () => {
                                moveService(attributes);
                            }
                        },
                        {
                            label: 'Cancel Invitation',
                            type: 'onClick',
                            onClick: () => {
                                cancelMoveService(move_id, id);
                            }
                        }
                    ];
                    return (
                        <div className="service__move--invitation" key={move_id}>
                            <div className="service__move--email">{email}</div>
                            <OutlineDropdown title="Pending Move Confirmation" className="service__move--dropdown" list={menu} />
                        </div>
                    );
                });
            }
        };

        const description = () => {
            if (hasMoveInvitation) {
                return (
                    <>
                        <div>{`Move this service to an existing ${company} customer or to a new ${application} account.`}</div>
                        <br />
                        <div className="sharedBox__description--highlight">This service is currently in the process of being moved</div>
                    </>
                );
            }

            return <div>{`Move this service to an existing ${company} customer or to a new ${application} account.`}</div>;
        };

        const conditionalProps = {
            ...(!hasMoveInvitation && isAdditionalUser
                ? {
                      action: {
                          buttonType: 'Inactive',
                          label: htmr(`<i className="icon icon-lock"></i>Move`),
                          type: 'onClick',
                          className: '',
                          size: 'large'
                      }
                  }
                : {
                      action: {
                          label: 'Move',
                          type: 'onClick',
                          className: '',
                          size: 'large',
                          onClick: () => openMoveForm()
                      }
                  }),
            ...(hasMoveInvitation && {
                custom: {
                    render: renderPendingInvitation(),
                    pos: 'bottom'
                }
            })
        };

        const handleStatus = () => {
            if ([service_move_status, service_move_invitation_status, service_move_cancel_status].includes('loading')) {
                return 'loading';
            }

            if ([service_move_status, service_move_invitation_status, service_move_cancel_status].includes('error')) {
                return 'error';
            }

            return 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="service__move"
            >
                <Box
                    request={{
                        action: moveStatus,
                        args: id,
                        status: handleStatus()
                    }}
                    title="Move Service"
                    desc={isAdditionalUser ? 'Additional users do not have permission to move services.' : description()}
                    status={isAdditionalUser ? 'success' : handleStatus()}
                    {...conditionalProps}
                />
                {showMoveForm && (
                    <OverlayLightbox title="Move Service" loading={service_move_status} onOpen={showMoveForm} onClose={closeMoveForm}>
                        <MoveForm product={product} domain={domain} history={history} onSubmit={handleMoveForm} />
                    </OverlayLightbox>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            service_move_invitation_status: state.services.service_move_invitation_status,
            service_move_invitation_data: state.services.service_move_invitation_data,
            service_move_invitation_error: state.services.service_move_invitation_error,
            service_move_cancel_status: state.services.service_move_cancel_status,
            service_move_status: state.services.service_move_status
        }),
        {
            moveService,
            moveStatus,
            cancelMoveService
        }
    )(Move)
);
