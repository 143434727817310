/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { serviceNav } from 'config/config';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { showNavigation } from 'utilities/consts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useConditionalServiceNavKeys } from 'utilities/hooks/useConditionalServiceNavKeys';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Subnav.scss';

/**
 * @type {React.FC<{ children: (items: { label: string; link: string; icon: string }[]) => React.ReactNode}>}
 */
const ConstructServiceMenuItems = ({ children }) => {
    const keys = useConditionalServiceNavKeys();
    return children(
        keys.map((item) => ({
            label: item,
            link: serviceNav[item].link,
            icon: serviceNav[item].icon
        }))
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated Please see: [CCP-2457](https://nexigendigital.atlassian.net/browse/CCP-2457)
 */
class Subnav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMobileDropdown: false,
            active: false
        };

        this.openMobileDropdown = this.openMobileDropdown.bind(this);
        this.closeMobileDropdown = this.closeMobileDropdown.bind(this);
        this.clickAway = this.clickAway.bind(this);
        this.setActiveOption = this.setActiveOption.bind(this);

        this.mobileToggleButtonRef = createRef();
    }

    openMobileDropdown() {
        this.setState({
            showMobileDropdown: true
        });
    }

    closeMobileDropdown() {
        this.setState({
            showMobileDropdown: false
        });
    }

    clickAway(e) {
        const { closeMobileDropdown, mobileToggleButtonRef } = this;

        if (mobileToggleButtonRef?.current && !mobileToggleButtonRef.current.contains(e.target)) closeMobileDropdown();
    }

    setActiveOption(newActive) {
        const { active } = this.state;

        if (newActive && active && newActive.link === active.link) {
            this.setState({
                active: newActive
            });
        }
    }

    componentDidMount() {
        const { clickAway } = this;

        document.addEventListener('click', clickAway);
    }

    componentWillUnmount() {
        const { clickAway } = this;

        document.removeEventListener('click', clickAway);
    }

    render() {
        const { pathname, app_viewport } = this.props;
        const { showMobileDropdown, active } = this.state;
        const { openMobileDropdown, closeMobileDropdown, setActiveOption, mobileToggleButtonRef } = this;

        const getLocation = () => {
            const { location } = this.props;
            const { pathname } = location;

            switch (true) {
                case pathname.indexOf('/support') > -1:
                    return 'support';

                case pathname.indexOf('/billing') > -1:
                    return 'billing';

                default:
                    return 'services';
            }
        };

        /**
         * Returns a title or a tuple who's first element is the title and second value represents whether
         * the title should be capitalized.
         *
         * @returns {string | [string, boolean]} - title | [ title, isCapitalized ]
         */
        const getSubPage = () => {
            const formattedPathname = pathname.split('/');

            const mapName = (name) => {
                const input = name.replace('-', ' ');

                switch (input) {
                    case 'gsuite':
                        return 'Google Workspace';

                    case 'vps':
                        return 'VPS';

                    case 'ssl':
                        return 'SSL';

                    case 'tickets':
                        return ['eTickets', false];

                    default:
                        return input;
                }
            };

            if (formattedPathname && formattedPathname.length >= 3) {
                formattedPathname[2] = mapName(formattedPathname[2]);
                return formattedPathname[2];
            }
        };

        /**
         * returns a tuple who's first element is the title and second value represents whether
         * the title should be capitalized.
         *
         * @returns {[string, boolean]} [title, isCapitalized]
         */
        const handleMobileTitleRender = () => {
            let title = '';
            const subtitle = active && active.label ? active.label : getSubPage();

            switch (getLocation()) {
                case `support`:
                    title = `Support Centre`;
                    break;

                case `billing`:
                    title = `Billing`;
                    break;

                default:
                    title = `My Services`;
                    break;
            }

            if (Array.isArray(subtitle)) {
                const [subTitle, isNotCapitalize] = subtitle;

                return [`${title} / ${subTitle}`, isNotCapitalize];
            }

            return [`${title} / ${subtitle}`, true];
        };

        /**
         * The array structure that needs to be passed through this is not documented well, and can cause client crashes.
         *
         * @param {Array<unknown>} navitems
         */
        const handleOptionRender = (navitems) => {
            return navitems.map((item, index) => {
                const { external } = item;
                const selected = item.link.indexOf(pathname) > -1 || pathname.indexOf(item.link) > -1;
                if (selected) setActiveOption(item);
                const subNavOptionClassNames = classNames('Subnav__option', { 'active': selected, 'Subnav__option--selected': selected });

                return (
                    <div className={subNavOptionClassNames} key={index}>
                        {external ? (
                            <a className="Subnav__optionLink" href={item.link} target="_blank" rel="noreferrer">
                                {item.label}
                            </a>
                        ) : (
                            <Link className="Subnav__optionLink" to={item.link}>
                                {item.label}
                            </Link>
                        )}
                    </div>
                );
            });
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/

        const [mobileTitle, isCapitalized] = handleMobileTitleRender();
        const buttonClasses = classNames({
            'Subnav__mobile': true,
            'Subnav__mobile--capitalize': isCapitalized
        });

        return (
            <div className="Subnav Subnav__frame">
                <div className="Subnav__section">
                    {this.props.items ? (
                        handleOptionRender(this.props.items)
                    ) : (
                        <ConstructServiceMenuItems>{(keyItems) => handleOptionRender(keyItems)}</ConstructServiceMenuItems>
                    )}
                </div>
                {['xs', 'sm'].includes(app_viewport) ? (
                    <button
                        ref={mobileToggleButtonRef}
                        className={buttonClasses}
                        onClick={() => {
                            showMobileDropdown ? closeMobileDropdown() : openMobileDropdown();
                        }}
                    >
                        {mobileTitle}
                        <i className="icon icon-arrow" />
                    </button>
                ) : (
                    ''
                )}
                {showMobileDropdown ? (
                    <div className="Subnav__dropdown">
                        {this.props.items ? (
                            handleOptionRender(this.props.items)
                        ) : (
                            <ConstructServiceMenuItems>{(keyItems) => handleOptionRender(keyItems)}</ConstructServiceMenuItems>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport
    };
};

Subnav = withRouter(connect(mapStateToProps, null)(Subnav));

// Render nothing instead of the Subnav if showNavigation is false
if (!showNavigation) {
    Subnav = () => '';
}

export default Subnav;
