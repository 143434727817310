/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { EditorEvents} from '@tiptap/react';
import { useCurrentEditor } from '@tiptap/react';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ToolBarToolTip } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolBarToolTip/ToolBarToolTip';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { usePositionEditorElement } from 'components/Form/RichTextEditor/hooks/usePositionEditorElement/usePositionEditorElement';
import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';
import { HrefWithoutSchemeValidation } from 'utilities/methods/form/validations/validationHrefWithoutScheme';
import { validatorDomainAndSubDomain } from 'utilities/methods/validators';
import { validateQueryParamDomain } from 'utilities/methods/validators/validateQueryParamDomain/validateQueryParamDomain';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FloatingLinkEditor.scss';

const inputClassName = 'FloatingLinkEditor__Input';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FloatingLinkEditorPlugin = () => {
    /***** STATE *****/
    const { editor } = useCurrentEditor();
    const [linkUrl, setLinkUrl] = useState('https://');

    /***** HOOKS *****/
    const inputRef = useRef<HTMLInputElement>(null);
    const floatingLinkEditorRef = useRef<HTMLDivElement>(null);
    const [isLinkEditMode, setIsLinkEditMode] = useRichTextEditorContext('isLinkEditMode');
    const [rootElement] = useRichTextEditorContext('rootElement');

    const previousUrl = editor?.getAttributes('link').href;
    useEffect(() => {
        setLinkUrl(previousUrl || 'https://');
    }, [previousUrl, isLinkEditMode]);

    /***** FUNCTIONS *****/
    const isValidLinkHref = HrefWithoutSchemeValidation.validationMethod(linkUrl);

    function removeCurrentLink() {
        editor?.chain().focus().unsetLink().run();
        setIsLinkEditMode(false);
    }
    function confirmCurrentLink() {
        if (!isValidLinkHref) return;

        const finalURL = validateQueryParamDomain({ domainValidator: validatorDomainAndSubDomain, areQueryParamsOptional: true })(linkUrl)
            ? `https://${linkUrl}`
            : linkUrl;

        if (linkUrl !== '') {
            editor?.chain().focus().extendMarkRange('link').setLink({ href: finalURL }).run();
        }
        setIsLinkEditMode(false);
    }

    const handleOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            event.preventDefault();
            confirmCurrentLink();
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setIsLinkEditMode(false);
        }
    };

    const isLink = editor?.isActive('link');

    usePositionEditorElement({
        rootElement,
        elementToCalculateCenterToRef: floatingLinkEditorRef,
        anchor: 'bottom',
        align: 'left',
        anchorOffset: 20
    });

    /***** EFFECTS *****/
    useEffect(() => {
        if (!editor) {
            return;
        }

        const handleBlur = ({ editor, event }: EditorEvents['blur']) => {
            setIsLinkEditMode(false);
        };

        editor.on('blur', handleBlur);

        return () => {
            editor.off('blur', handleBlur);
        };
    }, []);

    /***** RENDER *****/
    return (
        <Border all={1} inject>
            <div className={classNames('FloatingLinkEditor', { 'FloatingLinkEditor--hidden': !isLinkEditMode })} ref={floatingLinkEditorRef}>
                <Flex gap={0} className="FloatingLinkEditor__contentWrapper">
                    <Flex.Child grow>
                        <Input.Decoupled.Default
                            key={JSON.stringify(isLinkEditMode)}
                            validationBorderOnly
                            name="link"
                            value={linkUrl}
                            inputRef={inputRef}
                            validate={[HrefWithoutSchemeValidation]}
                            intrinsicProps={{
                                className: inputClassName,
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setLinkUrl(e.target.value),
                                onKeyDown: handleOnKeyDown
                            }}
                        />
                    </Flex.Child>

                    <ToolBarToolTip ariaLabel={isLink ? 'un-link' : 'cancel'} className="FloatingLinkEditor__action">
                        <button className="FloatingLinkEditor__action" type="button" color="warn" onClick={removeCurrentLink}>
                            {isLink ? <PhosphorIcons.LinkBreak.Bold warn size={23} /> : <PhosphorIcons.X.Bold warn size={23} />}
                        </button>
                    </ToolBarToolTip>
                    <ToolBarToolTip ariaLabel={isLink ? 'Update' : 'Confirm'} className="FloatingLinkEditor__action">
                        <button
                            className="FloatingLinkEditor__action"
                            disabled={!isValidLinkHref}
                            type="button"
                            color="confirm"
                            onClick={confirmCurrentLink}
                        >
                            <PhosphorIcons.Check.Bold confirm={isValidLinkHref} secondary={!isValidLinkHref} size={23} />
                        </button>
                    </ToolBarToolTip>
                </Flex>
            </div>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
