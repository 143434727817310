/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ManageSeatsForm from 'containers/ms365/forms/manageSeatsForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { getMs365Service } from 'containers/ms365/action';
import { ms365Methods } from 'containers/ms365/methods';
import { scrollToRef } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ManageSeats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showManageDropdown: false,
            showInvoiceLightbox: false,
            invoiceId: null
        };

        this.toggleManageDropdown = this.toggleManageDropdown.bind(this);
        this.toggleInvoiceLightbox = this.toggleInvoiceLightbox.bind(this);
    }

    toggleManageDropdown() {
        const { showManageDropdown } = this.state;

        this.setState({
            showManageDropdown: !showManageDropdown
        });
    }

    toggleInvoiceLightbox(showInvoiceLightbox = false, invoiceId = null) {
        this.setState({
            showInvoiceLightbox,
            invoiceId
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { location, ms365_information_status } = this.props;

        const query = new URLSearchParams(location.search);
        const open = query.get('open');

        if (open) {
            this.toggleManageDropdown();
        }

        if (ms365_information_status === 'success') registerScrollEvents(this, true);
    }

    componentDidUpdate(prevProps) {
        const {
            location,
            match: {
                params: { id }
            },
            getMs365Service,
            ms365_information_status,
            ms365_update_config_status,
            ms365_update_config_data
        } = this.props;
        const { toggleManageDropdown, scrollRef, toggleInvoiceLightbox } = this;

        registerScrollEvents(this, ms365_information_status === 'success' && prevProps.ms365_information_status === 'loading');

        if (location !== prevProps.location && location.pathname.includes('manage-seats')) {
            const query = new URLSearchParams(location.search);
            const open = query.get('open');

            if (open) {
                toggleManageDropdown();
                scrollToRef(scrollRef);
            }
        }

        if (ms365_update_config_status === 'success' && prevProps.ms365_update_config_status === 'loading') {
            if (ms365_update_config_data?.type === 'invoice') {
                return toggleInvoiceLightbox(true, ms365_update_config_data.id);
            }

            getMs365Service(id);
        }
    }

    render() {
        const {
            ms365_information_data,
            ms365_update_config_status,
            match: {
                params: { id }
            },
            getMs365Service
        } = this.props;
        const { showManageDropdown, showInvoiceLightbox, invoiceId } = this.state;
        const { toggleManageDropdown, toggleInvoiceLightbox } = this;

        const invoiceID = ms365Methods.getInvoiceId(ms365_information_data);

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div ref={(el) => (this.scrollRef = el)} className="ms365ManageSeats">
                <Box
                    premounted
                    className="ms365ManageSeats__box"
                    title="Manage Seats"
                    desc={htmr(`Add or remove seats to <strong>${ms365_information_data?.attributes?.name}</strong>`)}
                    status={ms365_update_config_status === 'loading' ? 'loading' : 'success'}
                    action={{
                        buttonType: invoiceID ? 'Inactive' : 'Outline',
                        label: 'Manage Seats',
                        type: 'onClick',
                        className: 'ms365ManageSeats__button',
                        color: 'secondary',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            toggleManageDropdown();
                        }
                    }}
                    dropdown={{
                        title: 'Adjust your seats below',
                        render: <ManageSeatsForm />,
                        condition: showManageDropdown,
                        close: toggleManageDropdown,
                        status: 'success'
                    }}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceId}
                        invoiceid={invoiceId}
                        onOpen={showInvoiceLightbox}
                        onClose={() => {
                            getMs365Service(id);
                            toggleInvoiceLightbox();
                        }}
                        onSuccessClose={() => toggleInvoiceLightbox()}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            ms365_information_status: state.ms365.ms365_information_status,
            ms365_information_data: state.ms365.ms365_information_data,
            ms365_update_config_status: state.ms365.ms365_update_config_status,
            ms365_update_config_data: state.ms365.ms365_update_config_data
        }),
        {
            getMs365Service
        }
    )(ManageSeats)
);
