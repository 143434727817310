/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import React from 'react';

/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 * }>}
 */
export const InfoTableSubHeading = ({ children }) => (
    <Text size--xs uppercase secondary bold>
        {children}
    </Text>
);

/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 * }>}
 */
export const InfoTableSpan = ({ children }) => (
    <Text medium size--s black>
        {children ?? '-'}
    </Text>
);
