/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ShapeSquarePropType } from 'utilities/consts';
import './_NXSquare.scss';

const NXSquareAppliedStylingPropTypes = {
    ...ShapeSquarePropType
};

const NXSquareAppliedStylingPropTypeKeys = Object.keys(NXSquareAppliedStylingPropTypes);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * This is a helper component that creates a ratio div using padding-top
 * @param {{
 *      ratio?: number | boolean
 *      children?: import('react').ReactNode,
 *      className?: string
 * }} props
 */
function NXSquare(props) {
    const { ratio = true, children, className } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: NXSquareAppliedStylingPropTypeKeys,
        componentName: 'NXSquare',
        delimiter: '--'
    });

    const isSquarePropNumber = typeof ratio === 'number';
    const styles = {
        paddingTop: isSquarePropNumber ? `calc(100% * ${ratio})` : '100%'
    };

    /***** RENDER *****/
    return (
        <div className={classNames('NXSquare', appliedStylingClasses, className)} style={styles}>
            <div className="NXSquare__inner">{children}</div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
NXSquare.propTypes = {
    ...NXSquareAppliedStylingPropTypes,

    /**
     * ClassNames
     */
    className: PropTypes.string
};

export { NXSquare };
