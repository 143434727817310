/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ClearFilterButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * The ClearFilterButton component implements an Anchor tag to provide a "clear" button for any filterable component.
 */
function ClearFilterButton({ className, onClick }) {
    const clearFilterButtonClassNames = classNames('ClearFilterButton', className);
    return (
        <Anchor onClick={onClick} className={clearFilterButtonClassNames}>
            Clear filters
        </Anchor>
    );
}

ClearFilterButton.propTypes = {
    /**
     * The class name to add to the button
     */
    className: PropTypes.string,

    /**
     * The function to call when the button is clicked
     */
    onClick: PropTypes.func.isRequired
};

export default ClearFilterButton;
