/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { entries, has, keys } from 'lodash';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Gradient from 'components/Utils/Gradient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStaffMenu } from 'components/StaffMenu/context';
import { useStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { testRoutesStore } from 'components/TestRoutes/const';
import ParentRoute from 'components/TestRoutes/parentRoute';
import './_TestRoutes.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TestRouteRecord = Record<string, JSX.Element[]>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const TestRoutes: React.FC = () => {
    /***** STATE *****/
    const [routes] = useStore(testRoutesStore);

    /***** HOOKS *****/
    const { pathname } = useLocation();
    const splitPathName = pathname.split('/');
    const { active, toggle } = useStaffMenu();

    /***** EFFECTS *****/
    useEffect(() => {
        if (active) {
            toggle();
        }
    }, [pathname]);

    const availableRoutes: TestRouteRecord = Array.from(routes).reduce((acc, { title, path, parent = 'spare' }) => {
        if (parent && !has(acc, parent)) {
            acc[parent] = [];
        }
        const splitPath = path.split('/').map((path, i) => (path.includes(':') ? splitPathName[i] || path : path));
        const newPath = splitPath.join('/');

        acc[parent].push(<Anchor to={newPath}>{title}</Anchor>);

        return acc;
    }, {} as TestRouteRecord);

    const hasAvailableRoutes = !!keys(availableRoutes).length;
    /***** RENDER *****/
    if (!hasAvailableRoutes) return null;
    const { spare, ...rest } = availableRoutes;
    return (
        <Gradient notice className={classNames('TestRoutes', { TestRoutes__open: active })}>
            <button type="button" onClick={toggle}>
                <PhosphorIcons.Link.Bold size={28} white />
            </button>

            {active && (
                <div className="TestRoutes__links">
                    {entries(rest).map(([parent, links]) => {
                        return <ParentRoute key={parent} parent={parent} links={links} />;
                    })}
                    {Boolean(spare?.length) && spare}
                </div>
            )}
        </Gradient>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TestRoutes;
