/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import {
    cPanelReservedAliasesValidation,
    lowerLettersAndDigitsCPanelValidation,
    maxLengthCPanalUsername,
    specialUsernameRequirementEndWithSpecCPanel,
    specialUsernameRequirementStartSpecsCPanel
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_cPanelUsernameValidation.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * If we are going to use this pattern more often, we can turn this into a shared component that lists out hte specs like this.
 */
export function cPanelUsernameValidation(value) {
    let errors = [
        lowerLettersAndDigitsCPanelValidation(value),
        maxLengthCPanalUsername(value),
        specialUsernameRequirementStartSpecsCPanel(value),
        specialUsernameRequirementEndWithSpecCPanel(value),
        cPanelReservedAliasesValidation(value)
    ];
    // Use this as a key to tell ValidationMessage.ReduxForm.Default component when the error message has changed, otherwise referential equality will say that is changes on every keystroke
    const errorId = JSON.stringify(errors);

    errors = errors.filter((el) => el !== undefined);

    if (errors.length === 0) {
        return undefined;
    }

    /***** RENDER *****/
    return (
        <Text key={errorId} semiBold>
            <ul className="listDiscInside">
                {errors.map((val, i) => {
                    return <li key={i}>{val}</li>;
                })}
            </ul>
        </Text>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
