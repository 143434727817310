/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainAutoRenew from './overviewRowComponents/domainAutoRenew';
import DomainExpiry from './overviewRowComponents/domainExpiry';
import DomainIdProtection from './overviewRowComponents/domainIdProtection';
import DomainName from './overviewRowComponents/domainName';
import DomainRegisteredDate from './overviewRowComponents/domainRegisteredDate';
import DomainRegistrantEmail from './overviewRowComponents/domainRegistrantEmail';
import DomainRenewalAmount from './overviewRowComponents/domainRenewalAmount';
import DomainStatus from './overviewRowComponents/domainStatus';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDaysBetween, pluralize, toLuxonDate } from 'utilities/methods/commonActions';

/**
 * @param {number | undefined} _registeredDays
 */
function getRegisteredDaysMessage(_registeredDays) {
    if (_registeredDays === 0) {
        return 'Today';
    }
    if (_registeredDays === undefined) {
        return 'Unknown';
    }
    const numOfDays = _registeredDays.toString().substring(1);

    return `${numOfDays} day${pluralize(numOfDays)} ago`;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Domain overview module
 * @param {{
 *      ccTLD: boolean
 * }} props
 */
function OverviewSplit({ ccTLD }) {
    /***** HOOKS *****/
    const domain_contact_data = useSelector((state) => state.domain.domain_contact_data);
    const domain_contact_status = useSelector((state) => state.domain.domain_contact_status);
    const domain_information_data = useSelector((state) => state.domain.domain_information_data);
    const domain_protection_check_data = useSelector((state) => state.domain.domain_protection_check_data);
    const domain_renew_options_data = useSelector((state) => state.domain.domain_renew_options_data);

    /***** STATE *****/
    const [email, setEmail] = useState('');
    const [registeredMessage, setRegisteredMessage] = useState('');

    /***** EFFECTS *****/
    useEffect(() => {
        if (domain_contact_status !== 'success' || !domain_contact_data || domain_contact_data.id !== Number(domain_information_data?.id)) {
            return;
        }

        setEmail(domain_contact_data.attributes.registrant.email);
    }, [domain_contact_data, domain_contact_status]);

    useEffect(() => {
        if (!domain_information_data) {
            return;
        }
        const { attributes } = domain_information_data;
        const { registration_date } = attributes;
        const registeredDays = getDaysBetween(toLuxonDate(registration_date, 'yyyy-MM-dd TT'));
        const registered = getRegisteredDaysMessage(registeredDays);
        setRegisteredMessage(registered);
    }, [domain_information_data]);

    /***** RENDER HELPERS *****/
    const isRenewable = domain_renew_options_data?.attributes?.is_renewable ?? false;
    const expiryDate = domain_information_data?.attributes?.expiry_date ?? false;
    const renewalAmount = (domain_renew_options_data?.attributes?.prices ?? [])?.find((price) => price.years === 1)?.price;

    const { domain, status, registration_date, is_premium, order_type } = domain_information_data?.attributes || {};
    const idProtection = domain_protection_check_data?.attributes?.id_protection ?? '';

    /***** RENDER *****/
    return (
        <NXBox.Overview
            key={1}
            title="Domain Overview"
            rows={[
                <DomainName key={1} domain={domain} isPremium={is_premium} />,
                <DomainStatus key={2} status={status} />,
                <DomainRegisteredDate key={3} orderType={order_type} registrationDate={registration_date} registeredMessage={registeredMessage} />,
                <DomainExpiry key={4} expiryDate={expiryDate} isRenewable={isRenewable} domainId={domain_information_data?.id} />,
                <DomainRenewalAmount key={5} renewalAmount={renewalAmount} />,
                <DomainAutoRenew key={6} />,
                <DomainRegistrantEmail key={7} email={email} />,
                <DomainIdProtection key={8} ccTLD={ccTLD} idProtection={idProtection} domain_information_data={domain_information_data} />
            ]}
        />
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default OverviewSplit;
