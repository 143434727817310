/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import allLogos from 'assets/images/ventraSynMigration/temp/reseller_migration.png';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Intro = () => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['sm', 'xs']);

    /***** RENDER *****/
    return (
        <div className="vsIntro">
            <img className="vsIntro__logos" src={allLogos} alt="VentraIP and Synergy Wholesale logos" />
            <h3 className="vsIntro__offerHeader">EXCLUSIVE OFFER</h3>
            <p className={classNames('vsIntro__offerIntro', { 'vsIntro__offerIntro--relative': isMobile })}>
                Upgrade to Synergy Wholesale today and receive{' '}
                <HoverTooltip
                    inline
                    alignment={isMobile ? null : `right`}
                    color="black"
                    content={
                        <span>
                            This exclusive offer will supply you with the remainder of this month + one full month of free hosting at Synergy
                            Wholesale for each cPanel account you choose to move to Synergy Wholesale using this offer.
                        </span>
                    }
                >
                    <b>UP TO TWO FREE</b>
                </HoverTooltip>{' '}
                months of hosting with a free automated migration path!
            </p>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
