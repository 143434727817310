/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { keys } from 'lodash';
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import ColourSelectCircle from 'components/ColourSelectCircle';
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      colour: string;
 *      setColour: React.Dispatch<string>;
 *      closeColourSelector: () => void;
 *      preventClickRef: React.MutableRefObject<HTMLElement | null>;
 * }>}
 */
const PresetCustomiserColourSelect = ({ colour, setColour, closeColourSelector, preventClickRef }) => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const colourSelectRef = useRef();

    /***** QUERIES *****/
    const { data: get_colours_meta_data } = katanaQuery.serviceID.meta.getColours.useQuery(id);

    useClickAway([colourSelectRef, preventClickRef], closeColourSelector);

    /***** RENDER HELPERS *****/
    const colourKeys = keys(get_colours_meta_data);
    const colourOptions = colourKeys.map((colourKey) => {
        const { id, hex_code } = get_colours_meta_data[colourKey];
        return { id, hex_code };
    });

    /***** RENDER *****/
    return (
        <Grid columns="repeat(10, 1fr)" className="PresetCustomiserColourSelect" inject gap={1}>
            <div ref={colourSelectRef}>
                {colourOptions.map(({ id, hex_code }) => (
                    <ColourSelectCircle
                        key={id}
                        colour={/** @type {import('types').HexString} */ (hex_code)}
                        selected={colour === id}
                        onSelect={() => {
                            setColour(id);
                        }}
                    />
                ))}
            </div>
        </Grid>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { PresetCustomiserColourSelect };
