/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field, FieldArray } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';

/**********************************************************************************************************
 *   CONSTS - DATA
 **********************************************************************************************************/
const baseEmailFields = [
    {
        label: 'IMAP Server',
        name: 'server'
    },
    {
        label: 'Email Address',
        name: 'email'
    },
    {
        label: 'Password',
        name: 'password'
    }
];

const textField = getFieldComponentAndClassName(FieldTypesEnum.TEXT);

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
function RenderRemoveFieldButton(props) {
    const { fields, index } = props;

    return (
        <Anchor onClick={() => fields.remove(index)} className="migrationRequestForm__removeAdditionalsLink">
            <>
                <small>Remove</small>
                <PhosphorIcons.X />
            </>
        </Anchor>
    );
}

function RenderEmailFields(props) {
    const { emailField, index } = props;

    return baseEmailFields.map((field) => {
        const { label, name } = field;
        return (
            <Field
                key={name}
                component={textField.component}
                label={`${label} ${index + 1}`}
                name={`${emailField}.${name}`}
                type={FieldTypesEnum.TEXT}
                className={textField.className}
            />
        );
    });
}

function RenderAllEmailFields(props) {
    const { fields } = props;

    return (
        <>
            {fields.map((emailField, index) => {
                if (index !== 0) {
                    return (
                        <div key={index} className="migrationRequestForm__stepAdditionals">
                            <div className="migrationRequestForm__stepWrapper">
                                <RenderEmailFields emailField={emailField} index={index} />
                                <RenderRemoveFieldButton index={index} fields={fields} />
                            </div>
                        </div>
                    );
                }
                return (
                    <div key={index} className="migrationRequestForm__stepWrapper migrationRequestForm__emailFieldsFirstWrapper">
                        <RenderEmailFields emailField={emailField} index={index} />
                        <RenderRemoveFieldButton index={index} fields={fields} />
                    </div>
                );
            })}

            <Anchor
                onClick={(e) => {
                    e.preventDefault();
                    fields.push({});
                }}
                className="migrationRequestForm__addAdditionalsLink"
            >
                <>
                    <i className="icon icon-invalid" />
                    <small>Add {fields.length > 0 ? 'another' : 'an'} Email Account</small>
                </>
            </Anchor>
        </>
    );
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const EmailDetailsForm = () => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return <FieldArray name="imap_credentials" component={RenderAllEmailFields} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default EmailDetailsForm;
