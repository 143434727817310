/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXPicturedIframeSourceDocPreview from 'components/PicturedIframeSourceDocPreview';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRefContext } from 'components/Utils/RefProvider/useRefContext';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useStore } from 'utilities/hooks/useStore';
import { useTruthyValue } from 'utilities/hooks/useTruthyValue';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaOverviewPictureInPictureState } from 'containers/katana/consts';
import { useAllowPreviewState } from 'containers/katana/containers/ContentEditorLightbox/hooks/useAllowPreviewState';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const SitePreviewBrowserBox = function SitePreviewBrowserBox() {
    /***** HOOKS *****/
    const isMobileOrTablet = useAppViewport(['xs', 'sm', 'md']);
    const leftAnchorElement = useRefContext('SideBarPage');

    const { id, subpage, page } = useSetupEditorRouteParams();
    const [pictureState, setPictureState] = useStore(katanaOverviewPictureInPictureState.store);
    const isShowingSitePreview = pictureState === 'maximised';

    /***** QUERIES *****/
    const {
        data: get_site_preview_data,
        isFetching: isGetSitePreviewFetching,
        isError
    } = katanaQuery.serviceID.getSitePreview.useQuery({ serviceID: id }, isShowingSitePreview);
    const displayingPreview = useTruthyValue(get_site_preview_data);
    const isPreviewAllowed = useAllowPreviewState();

    /***** EFFECTS *****/
    useEffect(() => {
        if ((page !== 'overview' && subpage) || !isPreviewAllowed) {
            setPictureState('hidden');
        } else if (!isMobileOrTablet) {
            const storedPictureState = katanaOverviewPictureInPictureState.retrieve();
            setPictureState(storedPictureState || 'visible');
        }
    }, [page, subpage, isPreviewAllowed]);

    /***** RENDER HELPERS *****/
    const siteEditorLoadingData = useMemo(
        () => [{ condition: isGetSitePreviewFetching, message: 'Fetching latest site preview...' }],
        [isGetSitePreviewFetching]
    );

    /***** RENDER *****/
    return (
        <NXPicturedIframeSourceDocPreview
            srcDoc={displayingPreview}
            className="SitePreviewBrowserBox"
            stateStoreData={katanaOverviewPictureInPictureState}
            loaderData={siteEditorLoadingData}
            leftAnchorElement={leftAnchorElement}
            isError={isError}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SitePreviewBrowserBox };
