/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { TableRenderSelectField } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @typedef {Object} RenderRadioButtonDropdownListOptionTypeDef
 * @property {string} label
 * @property {string} value
 */
/**
 * @typedef {Object} RenderRadioButtonDropdownListTypeDef
 * @property {string} label
 * @property {string} value
 * @property {string} [description]
 * @property {boolean} [checked]
 * @property {Array<RenderRadioButtonDropdownListOptionTypeDef>} [options]
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated - Don't use class components, rebuild if using
 */
export class RenderRadioButtonDropdown extends Component {
    static radioBehaviour = {
        DEFAULT: 'default',
        HIDE_WHEN_NOT_SELECTED: 'hide_when_not_selected'
    };

    render() {
        const {
            selectOnChange,
            label,
            input,
            name,
            itemlist,
            disabledItemList,
            ellipsized,
            className,
            radioBehaviour = RenderRadioButtonDropdown.radioBehaviour.DEFAULT,
            meta
        } = this.props;
        const { value, onChange } = input;
        const { HIDE_WHEN_NOT_SELECTED } = RenderRadioButtonDropdown.radioBehaviour;

        const renderList = () => {
            if (itemlist && itemlist.length > 0) {
                return itemlist.map((item, index) => {
                    let isDisabled = false;
                    const isSelected = item.value === value;

                    function renderOptions(options) {
                        if (!options) return '';
                        if (radioBehaviour === HIDE_WHEN_NOT_SELECTED && !isSelected) {
                            return '';
                        }
                        if (Array.isArray(options)) {
                            return (
                                <Field
                                    key="index-method"
                                    name="index-method"
                                    component={TableRenderSelectField}
                                    parent={this}
                                    attributes={{
                                        type: `select`
                                    }}
                                    settings={{
                                        isTag: true
                                    }}
                                    selectOnChange={selectOnChange}
                                    options={options}
                                    ellipsized={ellipsized}
                                />
                            );
                        }
                        return options;
                    }

                    if (disabledItemList && disabledItemList.length > 0) {
                        disabledItemList.forEach((i) => {
                            if (i === item.value) {
                                isDisabled = true;
                            }
                        });
                    }
                    if (isDisabled) {
                        return (
                            <div className="RadioButtonDropdown__item disabled" key={index}>
                                <PhosphorIcons.CheckCircle confirm size={24} />
                                <div className="RadioButtonDropdown__data data">
                                    <div className="data__heading">{item.label}</div>
                                </div>
                            </div>
                        );
                    } else {
                        if (value === '' && item.value) {
                            onChange(true);
                        }

                        return (
                            <div className={`RadioButtonDropdown__item${isSelected ? ' selected' : ''}`} key={index}>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        if (selectOnChange && item.options) {
                                            e.preventDefault();
                                            selectOnChange();
                                        }
                                        onChange(item.value);
                                    }}
                                    className={`RadioButtonDropdown__radio-dummy${isSelected ? ' RadioButtonDropdown__radio-dummy--selected' : ''}`}
                                />
                                <input style={{ display: 'none' }} {...input} type="radio" checked={isSelected} value={item.value} />

                                <div className="RadioButtonDropdown__data data">
                                    {item.label ? (
                                        <div className={`data__heading${!item.description ? ' data__heading--no-description' : ''}`}>
                                            {item.label}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {renderOptions(item.options)}

                                    {item.description ? <div className="data__description">{item.description}</div> : ''}
                                </div>
                            </div>
                        );
                    }
                });
            } else {
                return '';
            }
        };

        /***** RENDER *****/
        return (
            <FormItem name={name}>
                <FormLabel htmlFor={name}>{label}</FormLabel>

                <FormItemInner meta={meta} no-bg>
                    <div className={`LargeListRadioButtons${className ? ' ' + className : ''}`}>{renderList()}</div>
                </FormItemInner>
            </FormItem>
        );
    }
}
