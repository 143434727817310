/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback, useLayoutEffect, useRef } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import Radio from 'components/Form/Radio';
import NXBox from 'components/NXBox';
import { Revealer } from 'components/Revealer';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NXDropZoneWithKatanaImageUploadAndControl from 'components/Form/NXDropZone/variants/NXDropZoneWithKatanaImageUploadAndControl';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import LimitedWidthContentModule from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent/limitedWidthContentModule';
import { _PickLogoStyle as PickLogoStyle } from 'containers/katana/containers/contentEditorModules/basicDetails/pickLogoStyle';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { NAME, TAG_LINE, LOGO } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;
const { STYLE } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _BasicDetailsContentModule = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { dynamicChange } = useDynamicFormActions();
    const zoneRef = useRef<DropzoneRef>();

    /***** QUERIES *****/
    const { data: get_katana_site_info_data } = katanaQuery.serviceID.getSiteInfo.useQuery(id);
    const { data: get_katana_site_business_info_data } = katanaQuery.serviceID.getBusinessInfo.useQuery(id);

    /***** FUNCTIONS *****/
    const handleOnEqualIdentifierLogoClick = useCallback(() => {
        // Open Dropzone
        if (zoneRef.current) {
            zoneRef.current?.open?.();
        }
    }, []);

    const handleOnZoneRef = useCallback((ref: DropzoneRef) => {
        zoneRef.current = ref;
    }, []);

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        const hasLogo = Boolean(get_katana_site_business_info_data?.[LOGO]);
        if (hasLogo) {
            dynamicChange('logo_choice', LOGO);
            return;
        }
        const hasBrandStyle = Boolean(get_katana_site_info_data?.style?.fonts?.brand);
        if (hasBrandStyle) {
            dynamicChange('logo_choice', STYLE);
            return;
        }
    }, [get_katana_site_info_data, get_katana_site_business_info_data]);

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion isOpenDefault={true} title="Your Website Details">
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <Field
                        name={NAME}
                        label={
                            <Flex items="center" gap={1}>
                                Your Website Name?
                                <Tooltip info="Don't worry, you'll be able to change this later if you need to!" />
                            </Flex>
                        }
                        component={Input.ReduxForm.Default}
                        validate={[requiredFieldValidation]}
                        placeholder={`Eg. John Smith's Cabinet Making`}
                        type="text"
                    />

                    <Field label="Add Your Logo" name="logo_choice" component={Radio.ReduxForm.Default} fullWidth>
                        <Flex direction="column" gap={1}>
                            <LimitedWidthContentModule>
                                <Radio.CheckBoxPanel
                                    type="circle"
                                    identifier={LOGO}
                                    fullWidth
                                    onEqualIdentifierClick={handleOnEqualIdentifierLogoClick}
                                >
                                    Upload my website logo
                                </Radio.CheckBoxPanel>
                            </LimitedWidthContentModule>

                            <Radio.IdentifierMatch identifier={LOGO}>
                                {(isMatch) => (
                                    <Revealer isOpen={isMatch}>
                                        <Padding paddingOnly top={2} bottom={4}>
                                            <Field
                                                name={LOGO}
                                                component={NXDropZoneWithKatanaImageUploadAndControl}
                                                onZoneRef={handleOnZoneRef}
                                                serviceID={id}
                                                type="dropzone"
                                                singleFile
                                                validate={isMatch ? [requiredFieldValidation] : []}
                                            />
                                        </Padding>
                                    </Revealer>
                                )}
                            </Radio.IdentifierMatch>

                            <LimitedWidthContentModule>
                                <Radio.CheckBoxPanel type="circle" identifier={STYLE} fullWidth>
                                    {`I don't have a website logo to upload yet`}
                                </Radio.CheckBoxPanel>
                            </LimitedWidthContentModule>
                            <Radio.IdentifierMatch identifier={STYLE}>
                                {(isMatch) => (
                                    <Revealer isOpen={isMatch}>
                                        <Padding paddingOnly top={2} bottom={4}>
                                            <PickLogoStyle isMatch={isMatch} />
                                        </Padding>
                                    </Revealer>
                                )}
                            </Radio.IdentifierMatch>
                        </Flex>
                    </Field>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const BasicDetailsContentModule = Object.assign(_BasicDetailsContentModule, {
    formFields: { NAME, TAG_LINE, LOGO, STYLE }
});

export default BasicDetailsContentModule;
