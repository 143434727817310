/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChangeResourceForm from './changeResourcesForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import FreedomImg from 'assets/images/hosting/upgradeFreedom.svg';
import PremierImg from 'assets/images/hosting/upgradePremier.svg';
import StarterImg from 'assets/images/hosting/upgradeStarter.svg';
import { billingCycles } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class UpgradeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productId:
                this.props.hosting_upgrade_list_data && this.props.hosting_upgrade_list_data.length > 0
                    ? this.props.hosting_upgrade_list_data[0].id
                    : null,
            showCombinationDropdown: false,
            selectedOption: 'match',
            selectedNewCycle: null,
            currentBillingCycle: this.props.hosting_information_data.attributes.billing_cycle,
            lowTier: true,
            options: ['Triennially', 'Biennially', 'Annually', 'Semi-Annually', 'Quarterly', 'Monthly'],
            yrValue: ['/Triennially', '/Biennially', '/Annually', '/Semi-Annually', '/Quarterly', '/Monthly'],
            upgradeImages: {
                'starter+': StarterImg,
                'freedom+': FreedomImg,
                'premier+': PremierImg
            },
            memory: null,
            cpu: null,
            disk_space: null,
            billingOptions: null,
            presetActive: false,
            presetValues: {
                cpu: {
                    orderValue: null
                },
                mem: {
                    orderValue: null
                },
                disk: {
                    orderValue: null
                }
            }
        };

        this.removeLowTier = this.removeLowTier.bind(this);
        this.setBillingCycle = this.setBillingCycle.bind(this);
        this.setOption = this.setOption.bind(this);
        this.setPresetValues = this.setPresetValues.bind(this);
        this.toggleCombinationDropdown = this.toggleCombinationDropdown.bind(this);
    }

    toggleCombinationDropdown() {
        this.setState({
            showCombinationDropdown: !this.state.showCombinationDropdown
        });
    }

    setBillingCycle(cycle) {
        this.setState({
            selectedOption: cycle
        });
    }

    setOption(cycle) {
        this.setState({
            selectedOption: 'new',
            selectedNewCycle: cycle.replace(/\s/g, '')
        });
    }

    setPresetValues(type) {
        switch (type.toLowerCase()) {
            case 'starter':
            case 'starter+':
                this.setState({
                    presetActive: type,
                    presetValues: {
                        cpu: {
                            orderValue: 1
                        },
                        mem: {
                            orderValue: 1
                        },
                        disk: {
                            orderValue: 2
                        }
                    }
                });
                break;

            case 'freedom':
            case 'freedom+':
                this.setState({
                    presetActive: type,
                    presetValues: {
                        cpu: {
                            orderValue: 2
                        },
                        mem: {
                            orderValue: 3
                        },
                        disk: {
                            orderValue: 4
                        }
                    }
                });
                break;

            case 'premier':
            case 'premier+':
                this.setState({
                    presetActive: type,
                    presetValues: {
                        cpu: {
                            orderValue: 3
                        },
                        mem: {
                            orderValue: 4
                        },
                        disk: {
                            orderValue: 6
                        }
                    }
                });
                break;

            default:
                this.setState({
                    presetActive: false,
                    presetValues: {
                        cpu: {
                            orderValue: null
                        },
                        mem: {
                            orderValue: null
                        },
                        disk: {
                            orderValue: null
                        }
                    }
                });
                break;
        }
    }

    removeLowTier() {
        this.setState({
            lowTier: false
        });
    }

    componentDidMount() {
        this.setState({
            showCombinationDropdown: true,
            presetActive: 'Freedom+',
            presetValues: {
                cpu: {
                    orderValue: 2
                },
                mem: {
                    orderValue: 3
                },
                disk: {
                    orderValue: 4
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        const {
            hostingid,
            custom_hosting_details_status,
            custom_hosting_details_data,
            hosting_billingcycle_status,
            hosting_billingcycle_data,
            custom_hosting_change_status,
            custom_hosting_remove_status,
            getCustomHosting
        } = this.props;

        if (custom_hosting_details_status === 'success' && prevProps.custom_hosting_details_status === 'loading') {
            const { attributes } = custom_hosting_details_data;
            const { config_values, invoice } = attributes;
            const { memory, cpu, disk_space } = config_values;

            this.setState({
                memory: memory.name,
                cpu: cpu.name,
                disk_space: disk_space.name,
                invoiceID: invoice
            });
        }

        if (hosting_billingcycle_status === 'success' && prevProps.hosting_billingcycle_status === 'loading') {
            const { attributes } = hosting_billingcycle_data;
            const optionList = [];

            Object.keys(attributes).forEach((key) => {
                optionList.push({
                    label: key,
                    value: key
                });
            });

            this.setState({
                billingOptions: optionList
            });
        }

        if (custom_hosting_change_status === 'success' && prevProps.custom_hosting_change_status === 'loading') {
            this.setState({
                showDropdown: false
            });
            getCustomHosting(hostingid);
        }

        if (custom_hosting_remove_status === 'success' && prevProps.custom_hosting_remove_status === 'loading') {
            getCustomHosting(hostingid);
        }
    }

    render() {
        const { hostingid, type, handleSelectedPlan, handleCustomHostingConfirmation, hosting_upgrade_list_data, hosting_upgrade_status } =
            this.props;
        const {
            productId,
            showCombinationDropdown,
            currentBillingCycle,
            yrValue,
            upgradeImages,
            selectedOption,
            selectedNewCycle,
            options,
            presetActive,
            presetValues
        } = this.state;
        const { toggleCombinationDropdown, setBillingCycle, setPresetValues, setOption } = this;

        const renderFeatureList = (list) => {
            return list.map((feature, index) => {
                const regex = /\d/g;
                const result = regex.test(feature);
                if (result) {
                    const split = feature.split(' ');
                    const highlighted = split.shift();
                    const featureText = split.join(' ');
                    return (
                        <div key={'feature-' + index} className="product__feature">
                            <span className="highlight">{highlighted}</span>
                            {' ' + featureText.toString()}
                        </div>
                    );
                }
                return (
                    <div key={'feature-' + index} className="product__feature--full">
                        {feature}
                    </div>
                );
            });
        };

        const processProductData = (attributes) => {
            const { name, price, details, config } = attributes;
            const plan = name.toLowerCase();
            const planName = plan.replace(/^\w/, (c) => c.toUpperCase());
            let currentPrice = price.Monthly;
            let currentAdv = yrValue[0];

            if (selectedNewCycle && selectedOption === 'new') {
                Object.keys(price).forEach((key, index) => {
                    if (selectedNewCycle === key) {
                        currentPrice = price[key];
                        currentAdv = yrValue[index];
                    }
                });
            } else if (selectedOption === 'match') {
                Object.keys(price).forEach((key, index) => {
                    if (currentBillingCycle === key) {
                        currentPrice = price[key];
                        currentAdv = yrValue[index];
                    }
                });
            }

            return {
                planName,
                details,
                currentPrice,
                currentAdv,
                config
            };
        };

        const renderProduct = (data) => {
            const { attributes, id } = data;
            const { planName, details, currentPrice, currentAdv, config } = processProductData(attributes);

            const getGradient = () => {
                switch (planName.toLowerCase()) {
                    case 'freedom+':
                        return 'gradientB';

                    case 'premier+':
                        return 'gradientC';

                    case 'starter+':
                    default:
                        return 'gradientA';
                }
            };

            return (
                <div key={`${id}-${planName.toLowerCase()}`} className={`upgradeProductSelect ${getGradient()}`}>
                    <div className="product__img">
                        <img src={upgradeImages[planName.toLowerCase()]} alt={planName} />
                    </div>
                    <div className="product__title">{planName}</div>
                    <div className="product__price">
                        ${currentPrice} <span className="product__price--subtext">{billingCycles[currentAdv.substring(1)]}</span>
                    </div>
                    {details ? <div className="product__desc">{renderFeatureList(details)}</div> : ''}
                    <SolidButton
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSelectedPlan({
                                id,
                                name: planName,
                                details,
                                config,
                                price: currentPrice,
                                billingCycle: selectedOption === 'new' ? selectedNewCycle : currentBillingCycle,
                                image: upgradeImages[planName.toLowerCase()]
                            });
                        }}
                    >
                        Choose Product
                    </SolidButton>
                </div>
            );
        };

        const renderCustomHosting = () => {
            const renderCustomPreset = (data) => {
                const { attributes, id } = data;
                const { planName, currentPrice } = processProductData(attributes);

                return (
                    <button
                        key={`${id}-${planName}`}
                        className={`changeResource__preset${presetActive === planName ? ' active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            setPresetValues(planName);
                        }}
                    >
                        <div className="preset__title">{planName}</div>
                        <div className="preset__price">
                            from ${currentPrice} {billingCycles[selectedOption === 'new' ? selectedNewCycle : currentBillingCycle]}
                        </div>
                    </button>
                );
            };

            return hosting_upgrade_status === 'loading' ? (
                <RequestLoader />
            ) : (
                <div className="changeResource__lightbox">
                    <div className="changeResource__break">
                        <div className="changeResource__line"></div>
                        <Anchor className="changeResource__break--title" onClick={toggleCombinationDropdown}>
                            See popular combinations
                            <i className={`icon icon-chevron-${showCombinationDropdown ? 'up' : 'down'}`} />
                        </Anchor>
                        <div className="changeResource__line"></div>
                    </div>
                    <div className={`changeResource__presetSelection${showCombinationDropdown ? ' active' : ''}`}>
                        {hosting_upgrade_list_data ? hosting_upgrade_list_data.map((data) => renderCustomPreset(data)) : ''}
                    </div>
                    <ChangeResourceForm
                        hostingid={hostingid}
                        productid={productId}
                        onChangeCallback={setPresetValues}
                        preset={presetActive ? presetValues : false}
                        billingCycle={selectedOption === 'new' ? selectedNewCycle : currentBillingCycle}
                        custiomHostingUpgradeConfirm={handleCustomHostingConfirmation}
                    />
                </div>
            );
        };

        /*  Render Component
         **********************************************************************************************************/
        return (
            <div className="hostingUpgrade__form">
                <div className="hostingUpgrade__form--top">
                    <button
                        className={`hostingUpgrade__column selectable ${selectedOption === 'match' ? 'active' : ''}`}
                        onClick={() => setBillingCycle('match')}
                    >
                        <span className="item__radio"></span>
                        <span className="item__label">Match billing cycle with hosting service</span>
                    </button>

                    <CustomDropdown.BillingCyclePrefab
                        isActive={selectedOption === 'new'}
                        options={options.map((option) => ({ label: option, value: option }))}
                        setOption={setOption}
                        selectedNewCycle={selectedNewCycle}
                    />
                </div>
                <div className="hostingUpgrade__form--bottom">
                    {type === 'custom' ? (
                        renderCustomHosting()
                    ) : (
                        <div className="hostingUpgrade__column">
                            {hosting_upgrade_list_data
                                ? hosting_upgrade_list_data.map((data, index) => renderProduct(data, index, hosting_upgrade_list_data.length))
                                : ''}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    hosting_information_data: state.hosting.hosting_information_data,
    hosting_upgrade_list_data: state.hosting.hosting_upgrade_list_data,
    hosting_upgrade_status: state.hosting.hosting_upgrade_status
});

UpgradeForm = connect(mapStateToProps)(UpgradeForm);

export default UpgradeForm;
