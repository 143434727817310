/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getAutoSslStatus, startAutoSslStatus } from '../state/securityActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AutoSSL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sslStatus: false
        };
    }

    componentDidUpdate(prevProps) {
        const { hosting_autossl_check_status, hosting_autossl_check_data } = this.props;
        registerScrollEvents(this, hosting_autossl_check_status === 'success' && prevProps.hosting_autossl_check_status === 'loading');

        if (hosting_autossl_check_status === 'success' && prevProps.hosting_autossl_check_status === 'loading') {
            const { attributes } = hosting_autossl_check_data;

            if (attributes) {
                const { is_auto_ssl_installed } = attributes;

                this.setState({
                    sslStatus: is_auto_ssl_installed
                });
            } else {
                this.setState({
                    sslStatus: 'installing'
                });
            }
        }
    }

    render() {
        const { hostingid, subaccount, hosting_autossl_check_status, startAutoSslStatus, getAutoSslStatus } = this.props;
        const { sslStatus } = this.state;

        const renderSslStatus = (status) => {
            switch (status) {
                case true:
                    return (
                        <div className="sharedBox__infoColumn hasIcon">
                            <i className="infoColumn__icon icon icon-check confirm"></i>
                            <div className="infoColumn__wrapper">
                                <div className="title">Auto SSL Certificate Status</div>
                                <div className="desc">Installed</div>
                            </div>
                        </div>
                    );

                case 'installing':
                    return (
                        <div className="sharedBox__infoColumn hasIcon">
                            <i className="infoColumn__icon icon icon-save-time-money notice"></i>
                            <div className="infoColumn__wrapper">
                                <div className="title">Auto SSL Certificate Status</div>
                                <div className="desc">Processing</div>
                            </div>
                        </div>
                    );

                case false:
                default:
                    return (
                        <div className="sharedBox__infoColumn">
                            <div className="title">Auto SSL Certificate Status</div>
                            <div className="desc">Not Installed</div>
                        </div>
                    );
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        const conditionalProps = {};

        if (!sslStatus) {
            conditionalProps.action = {
                buttonType: 'Solid',
                label: 'Start AutoSSL Check',
                type: 'onClick',
                className: '',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    startAutoSslStatus(hostingid, subaccount);
                }
            };
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="AutoSsl"
            >
                <Box
                    request={{
                        action: getAutoSslStatus,
                        args: [hostingid, subaccount],
                        status: hosting_autossl_check_status
                    }}
                    title="Auto SSL"
                    recommended={true}
                    desc={htmr(
                        `${company} hosting services come with a free Comodo SSL Certificate that is automatically installed on your service.`
                    )}
                    status={hosting_autossl_check_status}
                    bottom={true}
                    columns={[
                        {
                            render: renderSslStatus(sslStatus)
                        }
                    ]}
                    info={`
							Having an SSL installed is an integral part of your websites security. An SSL Certificate establishes an encrypted link between a web server and a browser.
							This link ensures that all data passed between the web server and browsers remain private.
						`}
                    {...conditionalProps}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_autossl_check_status: state.hosting.hosting_autossl_check_status,
        hosting_autossl_check_data: state.hosting.hosting_autossl_check_data,
        hosting_autossl_check_error: state.hosting.hosting_autossl_check_error,
        hosting_autossl_status: state.hosting.hosting_autossl_status,
        hosting_autossl_data: state.hosting.hosting_autossl_data,
        hosting_autossl_error: state.hosting.hosting_autossl_error,
        hosting_information_data: state.hosting.hosting_information_data,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getAutoSslStatus,
            startAutoSslStatus
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoSSL));
