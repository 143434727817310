/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getEligibility } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Eligibility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eligibility: {
                registrantType: null,
                eligibilityType: null,
                registrantID: null,
                registrantName: null,
                eligibilityNumber: null,
                eligibilityStatus: null
            }
        };
    }

    componentDidUpdate(prevProps) {
        const { domain_eligibility_status, domain_eligibility_data } = this.props;
        registerScrollEvents(this, domain_eligibility_status === 'success' && prevProps.domain_eligibility_status === 'loading');

        if (domain_eligibility_status === 'success' && prevProps.domain_eligibility_status === 'loading') {
            const { attributes } = domain_eligibility_data;
            this.setState({
                eligibility: {
                    registrantType: attributes.registrant_id_type,
                    registrantID: attributes.registrant_id,
                    registrantName: attributes.registrant_name,
                    eligibilityType: attributes.eligibility_type,
                    eligibilityNumber: attributes.eligibility_id,
                    eligibilityStatus: attributes.status
                }
            });
        }
    }

    render() {
        const { domainid, getEligibility, domain_eligibility_status, domain_eligibility_data, domain_eligibility_error } = this.props;
        const { eligibility } = this.state;

        const handleRowRender = (title, data) => {
            let userFriendlyTitle;
            switch (title) {
                case 'registrantType':
                    userFriendlyTitle = `Registrant Type`;
                    break;

                case 'registrantID':
                    userFriendlyTitle = `Registrant ID`;
                    break;

                case 'registrantName':
                    userFriendlyTitle = `Registrant Name`;
                    break;

                case 'eligibilityType':
                    userFriendlyTitle = `Eligibility Type`;
                    break;

                case 'eligibilityNumber':
                    userFriendlyTitle = `Eligibility Number`;
                    break;

                case 'eligibilityStatus':
                    userFriendlyTitle = `Eligibility Status`;
                    break;

                default:
                    break;
            }

            if (!data) {
                return '';
            }

            return (
                <div key={title} className="eligibility__row">
                    <div className="eligibility__column">{userFriendlyTitle}</div>
                    <div className="eligibility__column">{htmr(data)}</div>
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="eligibility"
            >
                <Box
                    request={{
                        action: getEligibility,
                        args: domainid,
                        status: domain_eligibility_status
                    }}
                    className="eligibility__box"
                    title="Eligibility Information"
                    desc={htmr(
                        `Below is the eligibility information tied to your domain name. If you ever need to change these details you will need to perform a <Anchor target='_self' href='/my-services/domains/admin/cor/${domainid}'>Change of Registrant.</Anchor>`
                    )}
                    notification={{
                        status: domain_eligibility_status,
                        data: domain_eligibility_data,
                        error: domain_eligibility_error
                    }}
                    custom={{
                        render: (
                            <div className="eligibility__table">
                                {Object.keys(eligibility).map((key) => {
                                    return handleRowRender(key, eligibility[key]);
                                })}
                            </div>
                        ),
                        pos: 'bottom'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        domain_eligibility_status: state.domain.domain_eligibility_status,
        domain_eligibility_data: state.domain.domain_eligibility_data,
        domain_eligibility_error: state.domain.domain_eligibility_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getEligibility
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Eligibility));
