/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TypeDefaults<TInput, TExpectedType> = Omit<TExpectedType, keyof TInput>;

export function createDataObjectCreatorMethod<TData extends object>() {
    return <TInputData extends object>(data: TInputData, fallbackData: TypeDefaults<TInputData, TData>): TData => {
        return _.merge({}, data, fallbackData) as any;
    };
}
