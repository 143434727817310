/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import htmr from 'htmr';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

import { searchHostingForDomain, sslInstall } from '../action';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import InstallForm from '../forms/install';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Install extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = createRef();
        this.state = {
            showInvoiceLightbox: false,
            invoiceID: null,
            showInstallForm: null,
            service: false,
            hasExistingAddon: false,
            dedicatedIP: null
        };
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleInstallForm = this.toggleInstallForm.bind(this);
        this.submitInstallRequest = this.submitInstallRequest.bind(this);
        this.processDomainName = this.processDomainName.bind(this);
    }

    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceID: id
        });
    }

    closePayInvoice() {
        this.setState({
            showInvoiceLightbox: false,
            invoiceID: null
        });
    }

    closePayInvoiceSuccess() {
        const { domainid, getDomainRenew } = this.props;
        this.setState(
            {
                showInstallForm: false,
                showInvoiceLightbox: false,
                showCancelLightbox: false,
                invoiceID: null
            },
            () => {
                getDomainRenew(domainid);
            }
        );
    }

    toggleInstallForm() {
        const { showInstallForm } = this.state;

        this.setState({
            showInstallForm: !showInstallForm
        });
    }

    submitInstallRequest(type) {
        const { sslid, sslInstall } = this.props;
        const { service } = this.state;
        const values = {
            service_id: service.id,
            ip_type: type,
            user: service.username
        };

        sslInstall(sslid, values);
    }

    componentDidMount() {
        registerScrollEvents(this, true);
    }

    processDomainName(domain) {
        let domainName = domain;
        if (domainName && domainName.startsWith('*.')) {
            domainName = domainName.replace('*.', '');
        }
        return domainName;
    }

    componentDidUpdate(prevProps) {
        const { ssl_hosting_search_status, ssl_hosting_search_data, ssl_information_data, ssl_install_status, ssl_install_data } = this.props;
        const { toggleInstallForm, openPayInvoice } = this;

        if (ssl_install_status === 'success' && prevProps.ssl_install_status === 'loading') {
            toggleInstallForm(ssl_install_data && ssl_install_data.id ? openPayInvoice(ssl_install_data.id) : false);
        }

        if (ssl_install_status === 'error' && prevProps.ssl_install_status === 'loading') {
            this.setState({
                showInvoiceLightbox: false,
                invoiceID: null,
                showInstallForm: null
            });
        }

        if (ssl_hosting_search_status === 'success' && prevProps.ssl_hosting_search_status === 'loading') {
            if (ssl_hosting_search_data && ssl_hosting_search_data.length > 0) {
                const { id, attributes, included } = ssl_hosting_search_data[0];
                const { domain, domain_status, username, dedi_ip_addon_addr } = attributes;
                const product = getIncludedObjBasedOnType(included, 'product');

                this.setState({
                    hasExistingAddon: dedi_ip_addon_addr !== undefined,
                    dedicatedIP: dedi_ip_addon_addr,
                    service: {
                        id: id,
                        product: product && product.attributes && product.attributes.name ? product.attributes.name.toLowerCase() : false,
                        domain: domain,
                        status: domain_status,
                        username: username,
                        installed: ssl_information_data.attributes.hosting_installed
                    }
                });
            }
        }
    }

    render() {
        const { ssl_install_status, ssl_install_data, ssl_information_data, ssl_hosting_search_status, searchHostingForDomain } = this.props;
        const { showInvoiceLightbox, invoiceID, showInstallForm, service, hasExistingAddon, dedicatedIP } = this.state;
        const { openPayInvoice, closePayInvoice, closePayInvoiceSuccess, submitInstallRequest, toggleInstallForm, processDomainName } = this;

        /*   RENDER COLUMN
         **********************************************************************************************************/
        const handleColumnRender = () => {
            if (!service) {
                return (
                    <div className="sharedBox__infoColumn">
                        <div className="infoColumn__wrapper">
                            <div className="title">No {company} hosting services found</div>
                            <div className="desc">Use the SSL details provided below to install your certificate on another providers service.</div>
                        </div>
                    </div>
                );
            } else if (service && !service.installed) {
                return (
                    <div className="sharedBox__infoColumn">
                        <div className="infoColumn__wrapper">
                            <div className="title">Install SSL Certificate on</div>
                            <div className="desc">
                                {processDomainName(service.domain)}{' '}
                                {service.product ? <span className="highlight">({service.product} Web Hosting)</span> : ''}
                            </div>
                        </div>
                    </div>
                );
            } else if (service && service.installed) {
                return (
                    <div className="sharedBox__infoColumn hasIcon">
                        <i className="infoColumn__icon icon icon-check confirm"></i>
                        <div className="infoColumn__wrapper">
                            <div className="title">SSL Certificate installed on</div>
                            <div className="desc">
                                {processDomainName(service.domain)}{' '}
                                {service.product ? <span className="highlight">({service.product} Web Hosting)</span> : ''}
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div className="sharedBox__infoColumn">
                    <div className="infoColumn__wrapper">
                        <div className="desc">Unable to retrieve SSL install status.</div>
                    </div>
                </div>
            );
        };

        /*   RENDER BUTTON
         **********************************************************************************************************/
        const renderButton = () => {
            if (service && !service.installed) {
                return {
                    buttonType: 'Outline',
                    label: 'Install SSL',
                    type: 'onClick',
                    className: '',
                    color: '',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        toggleInstallForm();
                    }
                };
            }
        };

        /*   RENDER TITLE
         **********************************************************************************************************/
        const renderTitle = () => {
            if (!service.installed) {
                return 'Install SSL Certificate';
            }

            return 'Setting up your SSL Certificate';
        };

        /*   RENDER INVOICE STATUS
         **********************************************************************************************************/
        const handleInvoiceStatus = () => {
            if (ssl_install_data) {
                const { id } = ssl_install_data;

                if (id) {
                    return {
                        type: 'warning',
                        text: htmr(
                            `You have an outstanding renewal invoice, make a payment now to complete the domain renew process. You can also cancel the request by cancelling the invoice.`
                        ),
                        action: [
                            {
                                label: 'Pay Invoice',
                                buttonType: 'Solid',
                                type: 'onClick',
                                className: '',
                                color: 'white',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    openPayInvoice(id);
                                }
                            }
                        ]
                    };
                }
            }
            return '';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="installSSL"
            >
                <Box
                    request={{
                        action: searchHostingForDomain,
                        args: ssl_information_data.attributes.domain,
                        status: ssl_hosting_search_status
                    }}
                    className="installSSL__box"
                    title={renderTitle()}
                    desc={`This tool allows a simple way to install SSL certificates on a ${company} service.`}
                    notification={{
                        info: handleInvoiceStatus()
                    }}
                    bottom={true}
                    columns={[
                        {
                            render: handleColumnRender()
                        }
                    ]}
                    action={renderButton()}
                    dropdown={{
                        title: 'Installation Options',
                        render: <InstallForm hasExistingAddon={hasExistingAddon} dedicatedIP={dedicatedIP} submitForm={submitInstallRequest} />,
                        condition: showInstallForm,
                        close: toggleInstallForm,
                        status: ssl_install_status
                    }}
                />
                {showInvoiceLightbox && (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceID}
                        invoiceid={invoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                )}
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        ssl_install_data: state.ssl.ssl_install_data,
        ssl_install_status: state.ssl.ssl_install_status,
        ssl_install_error: state.ssl.ssl_install_error,
        ssl_information_data: state.ssl.ssl_information_data,
        ssl_hosting_search_status: state.ssl.ssl_hosting_search_status,
        ssl_hosting_search_data: state.ssl.ssl_hosting_search_data,
        ssl_hosting_search_error: state.ssl.ssl_hosting_search_error
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            sslInstall,
            searchHostingForDomain
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Install));
