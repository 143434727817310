/* eslint-disable react/jsx-pascal-case */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXCard from 'components/NXCard';
import { Flex } from 'components/Utils/Flex';
import { Hr } from 'components/Utils/Hr';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PreviewWebpageButton } from 'containers/katana/components/previewWebpageButton';
import { AddSectionButton } from 'containers/katana/modules/presetContent/AddSectionButton';
import { SectionOrganiser } from 'containers/katana/modules/presetContent/components/sectionOrganiser';
import { SectionOrganiserChildBaseBar } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild/baseBar';
import SitePreviewContent from 'containers/katana/modules/presetContent/SitePreviewContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function ActivePresetContentModule() {
    /***** HOOKS *****/
    const isDesktop = useAppViewport('lg');
    const isTablet = useAppViewport('md');
    const isMobile = useAppViewport(['xs', 'sm']);
    const { getKatanaDestination } = useKatanaURL();

    /***** RENDER HELPERS *****/
    function getGridColumns() {
        if (isMobile) {
            return '100%';
        }
        if (isDesktop) {
            return '1fr 2fr';
        }
        if (isTablet) {
            return '2fr 3fr';
        }
        return '275px 1fr';
    }

    /***** RENDER *****/
    return (
        <Grid columns={getGridColumns()} gap={6} fullWidth alignItems--start>
            <div className="KatanaPresetContentModule__websiteLayout">
                <Flex>
                    <Text.Heading h4>Your current layout</Text.Heading>
                </Flex>
                <NXCard border>
                    <NXCard.Content>
                        <SitePreviewContent />
                    </NXCard.Content>
                    <Flex direction="column" items="stretch" inject>
                        <NXCard.Actions>
                            <PreviewWebpageButton />
                        </NXCard.Actions>
                    </Flex>
                </NXCard>
            </div>
            <div className="KatanaPresetContentModule__informationTable">
                <Text.Heading h4>Your current sections</Text.Heading>
                <Text secondary size--s>
                    Below are the different sections that make up your website. You can reorder them by clicking and dragging the dotted icons on the
                    left.
                </Text>
                <Grid columns={isMobile ? '1fr auto' : '1fr 140px'} inject>
                    <SectionOrganiserChildBaseBar>
                        <Padding x={1}>
                            <Text size--m medium lead--xs break-word>
                                Navigation Menu
                            </Text>
                        </Padding>
                        <SolidButton width={isMobile ? 'auto' : 'full'} to={getKatanaDestination(katanaServicePages.EDITOR.NAVIGATION)} color="gray">
                            {isMobile ? (
                                <Padding x={2}>
                                    <PhosphorIcons.PencilSimple />
                                </Padding>
                            ) : (
                                <Text bold size-xss>
                                    Edit
                                </Text>
                            )}
                        </SolidButton>
                    </SectionOrganiserChildBaseBar>
                </Grid>
                <Hr grey />
                {isMobile && (
                    <Padding bottom={2}>
                        <AddSectionButton />
                    </Padding>
                )}
                <SectionOrganiser />
            </div>
        </Grid>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ActivePresetContentModule };
