import { isArray, isEmpty, isObject } from 'lodash';

/**
 *
 * @param {Object} input
 * @returns {boolean}
 */
export function isObjectOrArrayEmpty(input) {
    return (isObject(input) || isArray(input)) && isEmpty(input);
}
