/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TicketPreferenceForm from 'containers/account/forms/ticketPreferenceForm';
import ChangeEmailForm from '../forms/changeEmail';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { getCurrentDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getTwoFactor, updateClientType, updateEmailAddress } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AccountOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmailLightbox: false,
            showTicketPreferenceLightbox: false
        };
        this.openEmailLightbox = this.openEmailLightbox.bind(this);
        this.closeEmailLightbox = this.closeEmailLightbox.bind(this);
        this.handleEmailForm = this.handleEmailForm.bind(this);
        this.toggleTicketPreferenceLightbox = this.toggleTicketPreferenceLightbox.bind(this);
    }

    openEmailLightbox() {
        this.setState({
            showEmailLightbox: true
        });
    }

    closeEmailLightbox() {
        this.setState({
            showEmailLightbox: false
        });
    }

    handleEmailForm(values) {
        const { new_email, domain_list } = values;
        const attributes = {
            email: new_email,
            ...(domain_list.length >= 1 && {
                domain_list: domain_list.map((id) => {
                    return {
                        id
                    };
                })
            })
        };

        updateEmailAddress(attributes);
    }

    toggleTicketPreferenceLightbox() {
        this.setState({
            showTicketPreferenceLightbox: !this.state.showTicketPreferenceLightbox
        });
    }

    componentDidMount() {
        const { getTwoFactor, account_twofactor_data } = this.props;

        if (!account_twofactor_data) {
            getTwoFactor();
        }
    }

    componentDidUpdate(prevProps) {
        const { account_update_email_status, account_update_client_type_status } = this.props;
        const { closeEmailLightbox, toggleTicketPreferenceLightbox } = this;

        registerScrollEvents(this, true);

        if (prevProps.account_update_email_status === 'loading' && account_update_email_status === 'success') closeEmailLightbox();

        if (prevProps.account_update_client_type_status === 'loading' && account_update_client_type_status === 'success')
            toggleTicketPreferenceLightbox();
    }

    render() {
        const {
            history,
            app_user_data,
            app_check_token_data,
            login_account_list_data,
            account_twofactor_status,
            account_twofactor_data,
            account_update_email_status,
            account_update_client_type_status
        } = this.props;

        const { showEmailLightbox, showTicketPreferenceLightbox } = this.state;
        const { openEmailLightbox, closeEmailLightbox, handleEmailForm, toggleTicketPreferenceLightbox } = this;

        const getCustomerFromNewFormat = () => {
            const from = DateTime.fromISO(app_user_data.customer_from).setZone('Australia/Sydney');
            const now = getCurrentDate();
            const duration = now.diff(from, ['years', 'months', 'days', 'hours']).toObject();

            function getCustomerDurationString() {
                if (duration.years > 0 && duration.months > 0) return duration.years + ' year and ' + duration.months + ' months';
                if (duration.years < 1 && duration.months > 0) return duration.months + ' month(s)';
                if (duration.years > 0 && duration.months < 1) return duration.years + ' years(s)';
                return '';
            }

            function getDurationString() {
                if (duration?.years > 0 || duration?.months > 0) return `Customer for ${getCustomerDurationString()}`;
                return 'Customer for less than a month';
            }

            const durationString = getDurationString();

            return durationString;
        };

        const getInitials = () => {
            let initial = '';

            if (app_user_data.firstname) {
                initial += app_user_data.firstname.substring(0, 1);
            }

            if (app_user_data.lastname) {
                initial += app_user_data.lastname.substring(0, 1);
            }

            return initial;
        };

        const renderTwoFactorLink = () => {
            if (account_twofactor_status === 'success') {
                if (account_twofactor_data && account_twofactor_data.length > 0) {
                    return '';
                } else {
                    return (
                        <div className="profilepreview__enable__twofactor">
                            <Anchor to="/account/security/two-factor">Enable Two-Factor Authentication</Anchor>
                        </div>
                    );
                }
            }

            return '';
        };

        const renderAccountNumber = () => {
            if (login_account_list_data) {
                const account = login_account_list_data.find(({ id }) => id === app_check_token_data?.account_id);
                const { attributes } = account;
                const { role } = attributes;

                if (role === 'account holder') {
                    return (
                        <div className="profile__account">
                            <span className="label">Account Number</span>
                            <span className="value">{app_user_data.account_number}</span>
                        </div>
                    );
                }
            }

            return '';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountOverview"
            >
                <Box
                    premounted={true}
                    className="accountOverview__box"
                    custom={{
                        render: (
                            <Fragment>
                                <div className="profile__avatar">
                                    <div className="profile__initials">{getInitials()}</div>
                                </div>
                                <div className="profile__details">
                                    <div className="profile__details--container">
                                        <div className="profile__name">{`${app_user_data.firstname} ${app_user_data.lastname}`}</div>
                                        <div className="profile__email">
                                            <span>{app_user_data.email}</span>
                                        </div>
                                        {renderAccountNumber()}
                                        <div className="profile__loyalty">{getCustomerFromNewFormat()}</div>
                                    </div>

                                    <div className="profile__actions">
                                        <div>
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openEmailLightbox();
                                                }}
                                            >
                                                Change Email Address
                                            </Anchor>
                                        </div>
                                        <div>
                                            <Anchor
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    history.push(`/account/security/password?open=true`);
                                                }}
                                            >
                                                Change Password
                                            </Anchor>
                                        </div>
                                        {renderTwoFactorLink()}
                                        <Anchor onClick={toggleTicketPreferenceLightbox}>Technical person preference</Anchor>
                                    </div>
                                </div>
                            </Fragment>
                        ),
                        pos: 'top'
                    }}
                />
                {showEmailLightbox ? (
                    <OverlayLightbox
                        title="Change Email Address"
                        loading={account_update_email_status}
                        onOpen={showEmailLightbox}
                        onClose={closeEmailLightbox}
                        onSuccessClose={closeEmailLightbox}
                        initialFocus="close-button"
                    >
                        <ChangeEmailForm onSubmit={handleEmailForm} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
                {showTicketPreferenceLightbox && (
                    <OverlayLightbox
                        title="Technical person preference"
                        desc={
                            <div>
                                Our team wants to do their best to speak your language and provide you with an outstanding experience every time. To
                                help them do that, you can now tell us whether or not you consider yourself to be a technical person and we will
                                tailor our communication accordingly.
                                <br />
                                <br />
                                When you mark yourself as a technical person, we will reduce the amount of ‘fluff’ and speak to you directly with
                                technical terminology. If you do not consider yourself to be a technical person, we will place more emphasis on
                                providing plain and simple responses with step-by-step instructions to help you through your issue.
                                <br />
                                <br />
                                Please note that when enabling the technical person option that it will be set to the entire account, meaning all
                                future communication by eTicket, live chat or phone will follow this setting.
                            </div>
                        }
                        loading={account_update_client_type_status}
                        onOpen={showTicketPreferenceLightbox}
                        onClose={toggleTicketPreferenceLightbox}
                        onSuccessClose={toggleTicketPreferenceLightbox}
                    >
                        <TicketPreferenceForm onSubmit={updateClientType} />
                    </OverlayLightbox>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            app_user_data: state.app.app_user_data,
            app_check_token_data: state.app.app_check_token_data,
            login_account_list_data: state.login.login_account_list_data,
            account_twofactor_status: state.account.account_twofactor_status,
            account_twofactor_data: state.account.account_twofactor_data,
            account_update_email_status: state.account.account_update_email_status,
            account_update_client_type_status: state.account.account_update_client_type_status
        }),
        {
            getTwoFactor
        }
    )(AccountOverview)
);
