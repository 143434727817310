/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import { entries, isNull, isUndefined, keys } from 'lodash';
import type React from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

function useKatanaServiceNavKeyConditions() {
    const { data: site_list_data } = katanaQuery.getSiteList.useInfiniteQuery();
    return (
        site_list_data?.meta?.total_all_records !== 0 &&
        !isUndefined(site_list_data?.meta?.total_all_records) &&
        !isNull(site_list_data?.meta?.total_all_records)
    );
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useConditionalServiceNavKeys() {
    const serviceNavKeys = keys(serviceNav);

    const displayConditions = {
        // [serviceNav['VIPsites'].key]: useKatanaServiceNavKeyConditions()
    };

    const displayConditionEntries = entries(displayConditions);

    const filteredServiceNavKeys = serviceNavKeys.filter((key) => {
        const conditionEntry = displayConditionEntries.find(([conditionKey]) => serviceNav[key].key === conditionKey);
        return isUndefined(conditionEntry) || Boolean(conditionEntry?.[1]);
    });

    // const isVPN = useIsVPN();

    /***** HOOK RESULTS *****/
    // return isVPN ? serviceNavKeys : filteredServiceNavKeys;
    return filteredServiceNavKeys;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**
 * Class component equivelent of useConditionalServiceNavKeys
 */
export const ConditionalServiceNavKeys: React.FC<{
    children: (keys: string[]) => React.ReactNode;
}> = ({ children }) => {
    const keys = useConditionalServiceNavKeys();

    return children(keys);
};
