/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import SolidTag from 'components/Tags/SolidTag';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { truncateText } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show the domain name
 * @param {{
 *      domain: string
 *       isPremium: boolean
 * }} props
 */
function DomainName({ domain, isPremium }) {
    /***** RENDER *****/
    return (
        <NXBox.OverviewRow>
            <NXBox.InfoPair
                title="Domain Name"
                description={
                    <Text>
                        <Flex gap={1} items="center">
                            {domain ? truncateText(domain, 35, '...') : 'Not Available'}
                            {isPremium === true && (
                                <SolidTag color="record__purple" round>
                                    Premium
                                </SolidTag>
                            )}
                        </Flex>
                    </Text>
                }
            />
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainName;
