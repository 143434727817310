/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { includes } from 'lodash';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { get as svelteGet } from 'svelte/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import Anchor from 'components/Anchor';
import { SimpleCheckbox } from 'components/Form/SimpleCheckbox';
import RequestLoader from 'components/Loaders/Request';
import NXTable from 'components/NXTable';
import DialogNotification from 'components/Notifications/DialogNotification';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DNSHostingChangeKatanaLaunch } from 'containers/katana/components/launchWebsite/dnsHostingChange';
import ValidFormSubmitButton from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/validFormSubmitButton';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainQuery } from 'containers/domain/queries/queryTree';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { domainURL } from 'containers/domain/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useStore } from 'utilities/hooks/useStore';
import { requiredCheckboxFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    katanaTrialStateSimplifiedLaunch,
    simplifiedFlowLoadingMessage
} from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import { DNS_CONFIG_MODES } from 'utilities/consts';
import './_launchWebsiteButton.scss';

const FieldNames = /** @type {const} */ {
    SQUARE_SPACE: 'verification_code',
    REMOVE_CONFLICTING: 'remove_conflicting'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ConflictingRecordsForm = function ConflictingRecordsForm() {
    /***** HOOKS *****/
    const { id, subpage } = useSetupEditorRouteParams();
    const [simplifiedFlowMessage] = useStore(simplifiedFlowLoadingMessage);

    /***** QUERIES *****/
    const {
        data: check_enable_site_data,
        isLoading: isCheckKatanaServiceDetailsLoading,
        isError: isCheckKatanaServiceDetailsError,
        isFetching: isCheckKatanaServiceDetailsFetching
    } = katanaQuery.serviceID.getServiceDetails.useQuery(id);

    const { data: katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);
    const { has_trial_invoice } = katana_service_data?.attributes ?? {};
    const { data: get_dns_data, isLoading: isGetDNSLoading, isError: isGetDNSError } = katanaQuery.serviceID.getDNS.useQuery(id);

    const presetPreviewData = useMemo(
        () => ({
            id: check_enable_site_data?.domain_id,
            preset: 'katana',
            attributes: {
                replacements: {
                    hostname: check_enable_site_data?.hostname,
                    server_hostname: check_enable_site_data?.server_hostname
                }
            }
        }),
        [check_enable_site_data]
    );
    const { data: get_dns_preset_preview_data, isFetching: isGetDNSPresetPreviewFetching } =
        domainQuery.domainID.getDNSPresetPreview.useQuery(presetPreviewData);

    /***** FUNCTIONS *****/
    function renderTable(records = []) {
        if (!Array.isArray(records) || !records?.length) {
            return null;
        }

        return (
            <Border top left right bottom>
                <NXTable columns="1.2fr 2fr 3fr">
                    <NXTable.Header gap={2}>
                        <NXTable.Header.Title>Record Type</NXTable.Header.Title>
                        <NXTable.Header.Title>Hostname</NXTable.Header.Title>
                        <NXTable.Header.Title>Content</NXTable.Header.Title>
                    </NXTable.Header>
                    {React.Children.toArray(
                        records?.map(({ type, hostname, hostName, content }) => (
                            <NXTable.Row gap={2}>
                                <Text size--s>{type}</Text>
                                <Text size--s>{hostname ?? hostName ?? ''}</Text>
                                <Text size--s>{content}</Text>
                            </NXTable.Row>
                        ))
                    )}
                </NXTable>
            </Border>
        );
    }

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isCheckKatanaServiceDetailsLoading || isCheckKatanaServiceDetailsFetching, message: 'Checking Service Details...' },
        { condition: isGetDNSLoading, message: 'Loading DNS Records...' },
        { condition: isGetDNSPresetPreviewFetching, message: 'Loading DNS Preset...' }
    ]);

    const weHaveDomainAndNotHosted = Boolean(
        !includes([DNS_CONFIG_MODES.Forwarding, DNS_CONFIG_MODES['DNS Hosting']], check_enable_site_data?.domain_dns_config) &&
            check_enable_site_data?.domain_id
    );

    const isSimplifiedFlow = svelteGet(katanaTrialStateSimplifiedLaunch);

    /***** RENDER *****/
    if (weHaveDomainAndNotHosted && !isSimplifiedFlow && check_enable_site_data?.domain_id) {
        return <DNSHostingChangeKatanaLaunch />;
    }

    if (get_dns_data?.is_dns_configured || isSimplifiedFlow || (!check_enable_site_data?.domain_id && has_trial_invoice)) {
        return (
            <div className="ConflictingRecordsForm">
                {isSimplifiedFlow && Boolean(simplifiedFlowMessage) && <RequestLoader message={simplifiedFlowMessage} />}
                <ValidFormSubmitButton ignorePristine />
            </div>
        );
    }

    if (isCheckKatanaServiceDetailsError || isGetDNSError) {
        return (
            <>
                <DialogNotification type="error" tail={{ pos: 'top' }}>
                    <Text semiBold>
                        {check_enable_site_data?.domain_id ? (
                            <>
                                We had some trouble updating your DNS records. There could be a few reasons for this.{' '}
                                <Anchor color="white" underline to={domainURL('manage', 'dns', check_enable_site_data?.domain_id)}>
                                    <Text semiBold>Click here</Text>
                                </Anchor>
                                {` to manage your domain name's DNS records`}
                            </>
                        ) : (
                            'We had some trouble updating your DNS records.'
                        )}
                    </Text>
                </DialogNotification>
                <Text secondary align--center>
                    Not too sure what to do next?{' '}
                    <Anchor to={`/support/tickets/submit/technical-support?invoice=na&service=${check_enable_site_data?.domain_id ?? 'select'}`}>
                        Submit an eTicket to our Technical Support team
                    </Anchor>
                </Text>
            </>
        );
    }

    return (
        <>
            {isCheckKatanaServiceDetailsLoading ||
                (isGetDNSPresetPreviewFetching && (
                    <Field
                        className="hidden"
                        name="PREVENT_SUBMIT"
                        validate={[requiredCheckboxFieldValidation]}
                        component={SimpleCheckbox.ReduxForm}
                    />
                ))}
            <RequestLoader.MultiLoader loaders={multiLoaderData}>
                <div className="ConflictingRecordsForm">
                    <Text size--l>{check_enable_site_data?.domain_id ? 'Records Being Added' : 'Required Records'}</Text>
                    {renderTable(check_enable_site_data?.domain_id ? get_dns_preset_preview_data?.add : get_dns_data?.required_dns_records)}
                    {!check_enable_site_data?.domain_id && (
                        <AcknowledgementBox.Compact
                            notice={
                                <>
                                    <Text uppercase bold>
                                        Please Note:{' '}
                                    </Text>
                                    {subpage === 'fix-dns'
                                        ? 'DNS changes can take up to 48 hours to propagate fully. During this time, your website may not be immediately accessible at your domain. Please allow some time for the changes to take effect.'
                                        : 'The records you are about to add may conflict with some of your existing records.'}
                                </>
                            }
                            title="Confirmation"
                        >
                            <Field
                                label={
                                    <Text secondary size--s bold>
                                        I want to remove any existing records that conflict with the new records being added to my DNS.
                                    </Text>
                                }
                                name={FieldNames.REMOVE_CONFLICTING}
                                validate={[requiredCheckboxFieldValidation]}
                                component={SimpleCheckbox.ReduxForm}
                            />
                        </AcknowledgementBox.Compact>
                    )}
                    {/* Only display conflicting records if they exist */}
                    {!!get_dns_preset_preview_data?.remove?.length && (
                        <>
                            <Text size--l>Conflicting Records</Text>
                            {renderTable(get_dns_preset_preview_data?.remove)}

                            <AcknowledgementBox.Compact
                                notice={
                                    <>
                                        <Text uppercase bold>
                                            Please Note:{' '}
                                        </Text>
                                        {subpage === 'fix-dns'
                                            ? 'DNS changes can take up to 48 hours to propagate fully. During this time, your website may not be immediately accessible at your domain. Please allow some time for the changes to take effect.'
                                            : 'The records you are about to add may conflict with some of your existing records.'}
                                    </>
                                }
                                title="Confirmation"
                            >
                                <Field
                                    label={
                                        <Text secondary size--s bold>
                                            I want to remove any existing records that conflict with the new records being added to my DNS.
                                        </Text>
                                    }
                                    name={FieldNames.REMOVE_CONFLICTING}
                                    validate={[requiredCheckboxFieldValidation]}
                                    component={SimpleCheckbox.ReduxForm}
                                />
                            </AcknowledgementBox.Compact>
                        </>
                    )}
                    <ValidFormSubmitButton />
                </div>
            </RequestLoader.MultiLoader>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ConflictingRecordsForm };
