/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';
import NXTable from 'components/NXTable';
import TableAccordion from 'components/NXTable/TableAccordion';
import BackgroundColour from 'components/Utils/BackgroundColour';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import { NXSquare } from 'components/Utils/NXSquare';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ShowMore } from 'containers/services/modules/helpers/showMore';
import AddWebsiteButton from 'containers/services/modules/katanaServicesList/addWebsiteButton';
import KatanaServiceListEntryActionButton from 'containers/services/modules/katanaServicesList/katanaServiceListEntryActionButton';
import KatanaServiceListEntryTag from 'containers/services/modules/katanaServicesList/katanaServiceListEntryTag';
import { KatanaServiceListEntryVisual } from 'containers/services/modules/katanaServicesList/katanaServiceListEntryVisual';
import { KatanaServiceListEntry } from './katanaServiceListEntry';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const katanaServiceListTableHeadings = [['domain', 'Domain'] as const, ['status', 'Status'] as const];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function KatanaServiceListTable({ isExpressServiceManager = false }) {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const {
        data: get_katana_site_list_data,
        isLoading: isGetKatanaSiteListLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = katanaQuery.getSiteList.useInfiniteQuery();

    /***** RENDER HELPERS *****/
    const katanaSites = useMemo(() => {
        if (!get_katana_site_list_data) return [];
        const flatPages = get_katana_site_list_data?.pages?.flat() ?? [];
        if (isExpressServiceManager) return flatPages.splice(0, 5);
        return flatPages;
    }, [isExpressServiceManager, get_katana_site_list_data]);

    function renderKatanaServiceListStatus() {
        if (isGetKatanaSiteListLoading) {
            return <RequestLoader message="Loading your VIPsites" fillHeight={50} height={26} />;
        }
        if (!katanaSites.length) {
            return <NXTable.ErrorMessage message="You have no VIPsites on your account. Why don't you set up one below to get started!" />;
        }
        return null;
    }

    const renderData = useMemo(() => {
        const baseData: Array<'LOADING' | 'ADD_WEBSITE' | (typeof katanaSites)[number]> = [];

        if (isGetKatanaSiteListLoading) {
            baseData.push('LOADING');
        }

        if (katanaSites.length) {
            baseData.push(...katanaSites);
        }

        baseData.push('ADD_WEBSITE');

        return baseData;
    }, [isGetKatanaSiteListLoading, katanaSites]);

    /***** RENDER *****/
    if (!isExpressServiceManager) {
        return (
            <Grid columns={isMobile ? '1fr' : '1fr 1fr'} justify-children--stretch={true} align-children--stretch={true} gap={6}>
                {renderData.map((dataEntry) => {
                    if (dataEntry === 'ADD_WEBSITE') return <AddWebsiteButton key="ADD_WEBSITE" />;
                    if (dataEntry === 'LOADING')
                        return (
                            <Border all={2} dashed radius="C_BaseButton_border-radius_default" inject key="LOADING">
                                <BackgroundColour secondary-alt opacity={0.1}>
                                    <NXSquare ratio={0.7}>
                                        <Flex justify="center" items="center" fullHeight fullWidth direction="column" gap={6}>
                                            {renderKatanaServiceListStatus()}
                                        </Flex>
                                    </NXSquare>
                                </BackgroundColour>
                            </Border>
                        );
                    return <KatanaServiceListEntryVisual key={dataEntry.id} katanaSiteData={dataEntry} />;
                })}
            </Grid>
        );
    }

    return (
        <WrapWithComponent component={Border} all wrap={!isExpressServiceManager}>
            {isMobile ? (
                <NXTable key="mobile" columns="80px 1fr">
                    <NXTable.Body isLoading={isGetKatanaSiteListLoading}>
                        {renderKatanaServiceListStatus()}
                        <NXTable.Accordion.ToggleWrapper>
                            {katanaSites.map((katanaSiteData, index) => (
                                <NXTable.Accordion
                                    key={`${index}${katanaSiteData?.id}`}
                                    index={index}
                                    content={
                                        <NXTable.Actions.Base>
                                            <KatanaServiceListEntryActionButton katanaSiteData={katanaSiteData} />
                                        </NXTable.Actions.Base>
                                    }
                                >
                                    <TableAccordion.Child>
                                        <Text bold uppercase size--xs>
                                            Type
                                        </Text>
                                        <KatanaServiceListEntryTag
                                            status={katanaSiteData?.attributes?.status}
                                            site_status={katanaSiteData?.attributes?.site_status}
                                        />
                                    </TableAccordion.Child>
                                    <TableAccordion.Child>
                                        <Text bold uppercase size--xs>
                                            Domain
                                        </Text>
                                        <Text size--s>{katanaSiteData?.attributes?.name}</Text>
                                    </TableAccordion.Child>
                                </NXTable.Accordion>
                            ))}
                        </NXTable.Accordion.ToggleWrapper>
                    </NXTable.Body>
                </NXTable>
            ) : (
                <NXTable columns="1fr 1fr NXActions">
                    <NXTable.Header headings={katanaServiceListTableHeadings} />
                    <NXTable.Body>
                        {renderKatanaServiceListStatus()}
                        {katanaSites.map((katanaSiteData) => {
                            return (
                                <NXTable.Row key={katanaSiteData.id}>
                                    <KatanaServiceListEntry katanaSiteData={katanaSiteData} />
                                </NXTable.Row>
                            );
                        })}
                    </NXTable.Body>
                </NXTable>
            )}
            {!isGetKatanaSiteListLoading && !isExpressServiceManager && !!katanaSites.length && (
                <NXTable.Footer.Tanstack.ListShowButton
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                />
            )}

            <ShowMore
                isExpressServiceManager={isExpressServiceManager}
                isLoading={isGetKatanaSiteListLoading}
                totalRows={get_katana_site_list_data?.meta?.total_all_records}
                title="Show all VIPsites services"
                link={serviceNav.VIPsites.link}
            />
        </WrapWithComponent>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaServiceListTable;
