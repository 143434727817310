/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import PasswordChecker, { validateConfirmValidation } from 'components/PasswordChecker';
import { googleWorkspaceRequirements, validateGoogleWorkspaceRequirements } from 'components/PasswordChecker/google';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, RenderRevealPasswordField, renderCheckboxField, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'gsuiteUserDetailsForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let GSuiteUserDetailsForm = (props) => {
    const {
        isAdminSetup,
        isNested,
        handleSubmit,
        /** Redux Props */
        match,
        password_input,
        gsuite_mailbox_data,
        gsuite_service_data,
        gsuite_user_data_status
    } = props;
    const { params } = match;
    const { id, mailbox } = params;

    /***** FUNCTIONS *****/
    const getDomain = () => {
        if (
            gsuite_mailbox_data &&
            gsuite_mailbox_data?.attributes?.primary_email &&
            typeof gsuite_mailbox_data.attributes.primary_email === 'string'
        ) {
            const split = gsuite_mailbox_data.attributes.primary_email.split('@');
            return split ? split[1] : '';
        } else if (gsuite_service_data && gsuite_service_data?.attributes?.domain && typeof gsuite_service_data.attributes.domain === 'string') {
            return gsuite_service_data.attributes.domain;
        }

        return '';
    };

    const passwordConfirmationValidation = [validateConfirmValidation];
    if (password_input || !mailbox) passwordConfirmationValidation.push(requiredFieldValidation);

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <form className="gsuiteUserDetails__form" onSubmit={handleSubmit}>
            <div className="form__row">
                <div className="form__column form__column--half">
                    <Field
                        label="First Name"
                        name="first_name"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="form__column form__column--half">
                    <Field
                        label="Last Name"
                        name="last_name"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="Email Address"
                        name="email_address"
                        component={RenderField}
                        type="text"
                        append={{
                            type: `email`,
                            text: `@${getDomain()}`
                        }}
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="Password"
                        name="password"
                        component={RenderRevealPasswordField}
                        validate={(value) => validateGoogleWorkspaceRequirements(value, !mailbox)}
                        className="form__textfield"
                        parent={this}
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="Confirm Password"
                        name="password_confirmation"
                        component={RenderRevealPasswordField}
                        validate={passwordConfirmationValidation}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className={`gsuiteUserDetails__validation${password_input ? ' active ' : ' '}`}>
                <PasswordChecker input={password_input} requirements={googleWorkspaceRequirements} />
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        name="require_change_password"
                        label="Require user to change password on next login"
                        component={renderCheckboxField}
                        type="checkbox"
                        className="form__textfield"
                    />
                </div>
            </div>
            {isAdminSetup ? (
                ''
            ) : (
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            name="is_admin"
                            label="This user is an administrator"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
            )}
            <div className="form__row">
                <ReduxFormButton form={formName} className="form__column full" loading={gsuite_user_data_status === 'loading'}>
                    {(id && mailbox === 'setup') || isNested ? 'Create User' : 'Update'}
                </ReduxFormButton>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
GSuiteUserDetailsForm = reduxForm({
    form: formName
})(GSuiteUserDetailsForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const password_input = selector(state, 'password');

    let initialValues = {
        is_admin: false,
        require_change_password: false
    };

    if (state.gsuite.gsuite_user_data_data) {
        const { attributes } = state.gsuite.gsuite_user_data_data;
        const { first_name, last_name, is_admin, require_change_password, primary_email } = attributes;

        const getEmailAddress = () => {
            if (primary_email) {
                const email_string = primary_email.split('@');
                return email_string && email_string.length > 0 ? email_string[0] : null;
            } else {
                return '';
            }
        };

        initialValues = {
            first_name,
            last_name,
            is_admin,
            require_change_password,
            email_address: getEmailAddress()
        };
    }

    return {
        form: formName,
        initialValues,
        password_input,
        gsuite_user_data_data: state.gsuite.gsuite_user_data_data,
        gsuite_user_data_status: state.gsuite.gsuite_user_data_status,
        gsuite_mailbox_data: state.gsuite.gsuite_mailbox_data,
        gsuite_service_data: state.gsuite.gsuite_service_data
    };
};

GSuiteUserDetailsForm = connect(mapStateToProps, null)(GSuiteUserDetailsForm);

export default withRouter(GSuiteUserDetailsForm);
