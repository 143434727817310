/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const checkboxField = getFieldComponentAndClassName(FieldTypesEnum.CHECKBOX);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DNSUpdateForm = () => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <Field
            label="My domain is currently with VentraIP Australia, and I would like the DNS Configuration updated to point to this NEW hosting service once the migration is complete"
            name="dns_update"
            component={checkboxField.component}
            type={FieldTypesEnum.CHECKBOX}
            className={checkboxField.className}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DNSUpdateForm;
