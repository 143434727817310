/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ReferralBanner from 'components/Promo/ReferralBanner';
import WalletCashOutForm from '../forms/walletCashOutForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import ClearFilterButton from 'components/Buttons/ClearFilterButton';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import ControlledDateTimePicker from 'components/Form/ControlledDateTimePicker';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RadioButtonSet from 'components/RadioButtonSet';
import Table from 'components/Table';
import SolidTag from 'components/Tags/SolidTag';
import Tooltip from 'components/Tooltip';
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/*   ACTIONS
 *****************************************************/
import { getWalletBalances, getWalletTransactions, searchWalletTransactions, walletAddCredit, walletCashOut } from 'containers/billing/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { convertToNumber, isLastPage, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const transactionTypes = {
    CREDIT: 'credit',
    CASH: 'cash'
};

export const cashOutLightboxModes = {
    ADD_CREDIT: 'add-credit',
    CASH_OUT: 'cash-out'
};

const baseState = {
    currentKeyword: null,
    startDate: null,
    endDate: null,
    minDate: null,
    maxDate: null
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Wallet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...baseState,
            transactionType: transactionTypes.CREDIT,
            showCashOutLightbox: false,
            cashOutLightboxMode: null
        };

        this.transactionsHeaderRef = createRef();
        this.monitorKeyword = this.monitorKeyword.bind(this);
        this.setTransactionType = this.setTransactionType.bind(this);
        this.toggleCashOutLighbox = this.toggleCashOutLighbox.bind(this);
        this.handleCashOutSubmit = this.handleCashOutSubmit.bind(this);
        this.handleClearForm = this.handleClearForm.bind(this);
    }

    monitorKeyword(currentKeyword = null) {
        this.setState({
            currentKeyword
        });
    }

    setTransactionType(transactionType = transactionTypes.CREDIT) {
        this.setState({
            transactionType
        });
    }

    toggleCashOutLighbox(cashOutLightboxMode = null, cb) {
        this.setState(
            {
                showCashOutLightbox: !this.state.showCashOutLightbox,
                cashOutLightboxMode
            },
            () => {
                if (cb) cb();
            }
        );
    }

    handleCashOutSubmit(values) {
        const { walletAddCredit, walletCashOut } = this.props;

        const { is_custom_amount, method, custom_amount, account_name, branch_number, account_number, save_details, acknowledge_irreversible } =
            values;

        const payload = {
            custom_amount: {
                is_custom_amount,
                amount: is_custom_amount ? custom_amount : null
            },
            acknowledge_irreversible
        };

        if (method === cashOutLightboxModes.CASH_OUT) {
            payload.bank_details = {
                account_name,
                branch_number,
                account_number
            };
            payload.save_details = save_details;

            return walletCashOut(payload);
        }

        walletAddCredit(payload);
    }

    getDateFormatted(date) {
        return DateTime.fromJSDate(date).toFormat('dd-MM-yyyy');
    }

    handleClearForm() {
        this.setState(baseState);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps, prevState) {
        const { getWalletBalances, getWalletTransactions, account_wallet_add_credit_status, account_wallet_cash_out_status } = this.props;
        const { transactionType, startDate, endDate } = this.state;
        const { toggleCashOutLighbox, getDateFormatted } = this;

        // Re-fetch transactions list everytime one of the filters changes
        if (transactionType !== prevState.transactionType || startDate !== prevState.startDate || endDate !== prevState.endDate) {
            getWalletTransactions({
                'type': transactionType,
                'start-date': startDate ? getDateFormatted(startDate) : null,
                'end-date': endDate ? getDateFormatted(endDate) : null
            });
        }
        if (
            (account_wallet_add_credit_status === 'success' && prevProps.account_wallet_add_credit_status === 'loading') ||
            (account_wallet_cash_out_status === 'success' && prevProps.account_wallet_cash_out_status === 'loading')
        ) {
            toggleCashOutLighbox(null, () => {
                getWalletBalances();
                getWalletTransactions({
                    'type': transactionType,
                    'start-date': startDate ? getDateFormatted(startDate) : null,
                    'end-date': endDate ? getDateFormatted(endDate) : null
                });
            });
        }
    }

    render() {
        const {
            getWalletBalances,
            getWalletTransactions,
            account_wallet_status,
            account_wallet_data,
            account_wallet_transactions_status,
            account_wallet_transactions_data,
            account_wallet_transactions_meta,
            account_wallet_transactions_search_status,
            account_wallet_transactions_search_data
        } = this.props;
        const { currentKeyword, transactionType, startDate, endDate, minDate, maxDate, showCashOutLightbox, cashOutLightboxMode } = this.state;
        const { setTransactionType, toggleCashOutLighbox, handleCashOutSubmit, getDateFormatted, handleClearForm } = this;

        const renderTopSection = () => {
            const renderBoxCustom = () => {
                if (!account_wallet_data) return '';

                return (
                    <div className="wallet__actionsContainer">
                        <div className="wallet__action">
                            <div className="wallet__actionLeft">
                                <h4 className="wallet__actionTitle">Account Credit</h4>
                                <div className="wallet__actionDesc">
                                    ${account_wallet_data.account_credit}{' '}
                                    <Tooltip info="Account Credit will be automatically applied to new invoices." />
                                </div>
                            </div>
                            {convertToNumber(account_wallet_data.referral_credit) > 0 ? (
                                <SolidButton type="onClick" onClick={() => toggleCashOutLighbox(cashOutLightboxModes.ADD_CREDIT)}>
                                    Add from Referral Cash
                                </SolidButton>
                            ) : (
                                <InactiveButton>Add from Referral Cash</InactiveButton>
                            )}
                        </div>
                        <div className="wallet__action">
                            <div className="wallet__actionLeft">
                                <h4 className="wallet__actionTitle">Referral Cash</h4>
                                <div className="wallet__actionDesc">
                                    ${account_wallet_data.referral_credit}{' '}
                                    <Tooltip info="Manage your referral payouts. New referrals are paid out 60 days after an eligible purchase is made." />
                                </div>
                            </div>
                            {convertToNumber(account_wallet_data.referral_credit) > 0 ? (
                                <SolidButton type="onClick" onClick={() => toggleCashOutLighbox(cashOutLightboxModes.CASH_OUT)}>
                                    Cash Out
                                </SolidButton>
                            ) : (
                                <InactiveButton>Cash Out</InactiveButton>
                            )}
                        </div>
                    </div>
                );
            };

            return (
                <Box
                    className="wallet__actions"
                    request={{
                        action: getWalletBalances,
                        status: account_wallet_status
                    }}
                    status={account_wallet_status}
                    title="VentraIP Wallet"
                    desc="Your VentraIP Wallet will allow you to manage your available Account Credit and Referral Cash."
                    custom={{
                        pos: 'bottom',
                        render: renderBoxCustom()
                    }}
                />
            );
        };

        const renderReferralBanner = () => {
            return (
                <>
                    <ReferralBanner />
                    <div className="wallet__referralLinkContainer">
                        <Anchor to="/account/general/referrals">Manage my referrals</Anchor>
                    </div>
                </>
            );
        };

        const renderBottomSection = () => {
            const renderBoxCustom = () => {
                const getTableMatrix = () => {
                    const renderStatus = (status) => {
                        let color;

                        switch (status.toLowerCase()) {
                            case 'completed':
                                color = 'confirm';
                                break;
                            default:
                                color = 'warn';
                                break;
                        }

                        return <SolidTag color={color}>{status}</SolidTag>;
                    };

                    const handleMatrixCondition = () => {
                        if (currentKeyword) return account_wallet_transactions_search_data?.length > 0 ? account_wallet_transactions_search_data : [];
                        if (account_wallet_transactions_data?.length > 0) return account_wallet_transactions_data;
                        return [];
                    };

                    return handleMatrixCondition().map(
                        ({
                            attributes: {
                                amount: { value, type },
                                date,
                                note,
                                status,
                                transaction_type
                            }
                        }) => ({
                            date: toLuxonDate(date).toFormat('dd MMMM yyyy'),
                            type: (
                                <HoverTooltip
                                    alignment="left"
                                    content="If you have any questions or queries regarding this transaction, please submit an eTicket to our Accounts & Billing Team for further information."
                                >
                                    <span>{transaction_type}</span>
                                </HoverTooltip>
                            ),
                            status: renderStatus(status),
                            amount: (
                                <p className={`walletTransactions__amount ${type === 'positive' ? 'walletTransactions__amount--positive' : ''}`}>
                                    {type === 'positive' ? '+' : '-'}${value.replace('-', '')}
                                </p>
                            )
                        })
                    );
                };

                const handleTableLoading = () => {
                    if (account_wallet_transactions_status === 'loading' || account_wallet_transactions_search_status === 'loading') return 'loading';
                    if (account_wallet_transactions_status === 'error' || account_wallet_transactions_search_status === 'error') return 'error';
                    return 'success';
                };

                const showClearFilterButton = Object.entries(baseState).some(([key, value]) => this.state[key] !== value);

                return (
                    <>
                        <div className="walletTransactions__header" ref={this.transactionsHeaderRef}>
                            <h3 className="walletTransactions__title">Wallet Transactions</h3>
                            <div className="walletTransactions__actions">
                                <div className="walletTransactions__filters">
                                    <RadioButtonSet
                                        activeOption={transactionType}
                                        setActiveOption={setTransactionType}
                                        options={[
                                            {
                                                value: transactionTypes.CASH,
                                                label: 'Referral Cash'
                                            },
                                            {
                                                value: transactionTypes.CREDIT,
                                                label: 'Account Credit'
                                            }
                                        ]}
                                        size="small"
                                    />
                                    <div className="walletTransactions__datepickers">
                                        <ControlledDateTimePicker
                                            label="Start"
                                            format="d/M/y"
                                            value={startDate}
                                            parentRef={this.transactionsHeaderRef}
                                            onChange={(value) => {
                                                this.setState({ startDate: value, minDate: value });
                                            }}
                                            maxDate={maxDate}
                                        />
                                        <ControlledDateTimePicker
                                            label="End"
                                            format="d/M/y"
                                            value={endDate}
                                            parentRef={this.transactionsHeaderRef}
                                            onChange={(value) => {
                                                this.setState({
                                                    endDate: value,
                                                    maxDate: value
                                                });
                                            }}
                                            minDate={minDate}
                                        />
                                    </div>

                                    {showClearFilterButton ? (
                                        <ClearFilterButton onClick={handleClearForm} className="walletTransactions__clearFilter" />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <Table
                            header={[
                                {
                                    title: `Date`
                                },
                                {
                                    title: `Type`
                                },
                                {
                                    title: `Status`
                                },
                                {
                                    title: `Amount`
                                }
                            ]}
                            loading={handleTableLoading()}
                            matrix={getTableMatrix()}
                            error={currentKeyword ? `No transactions matched your search.` : `No Wallet Transactions found.`}
                            stacked={true}
                            embedded={true}
                            showAll={{
                                label: 'SHOW MORE',
                                status: account_wallet_transactions_status,
                                conditions: !isLastPage(account_wallet_transactions_meta) && !account_wallet_transactions_search_data,
                                onClick: (e) => {
                                    e.preventDefault();
                                    getWalletTransactions(
                                        {
                                            'type': transactionType,
                                            'start-date': startDate ? getDateFormatted(startDate) : null,
                                            'end-date': endDate ? getDateFormatted(endDate) : null,
                                            'page': account_wallet_transactions_meta ? account_wallet_transactions_meta.current_page + 1 : null
                                        },
                                        account_wallet_transactions_data
                                    );
                                }
                            }}
                        />
                    </>
                );
            };

            return (
                <Box
                    className="walletTransactions"
                    request={{
                        action: getWalletTransactions,
                        args: { type: transactionType },
                        status: account_wallet_transactions_status
                    }}
                    custom={{
                        pos: 'bottom',
                        render: renderBoxCustom()
                    }}
                />
            );
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="wallet">
                {renderTopSection()}
                {renderReferralBanner()}
                {renderBottomSection()}

                {showCashOutLightbox && (
                    <OverlayLightbox
                        className="walletFormLightbox"
                        title="Cash out referral cash"
                        desc="Get your eligible referral cash paid straight to your bank account or account credit."
                        onOpen={showCashOutLightbox}
                        onClose={toggleCashOutLighbox}
                    >
                        <WalletCashOutForm methodInitialValue={cashOutLightboxMode} onSubmit={handleCashOutSubmit} />
                    </OverlayLightbox>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            account_wallet_status: state.billing.account_wallet_status,
            account_wallet_data: state.billing.account_wallet_data,
            account_wallet_transactions_status: state.billing.account_wallet_transactions_status,
            account_wallet_transactions_data: state.billing.account_wallet_transactions_data,
            account_wallet_transactions_meta: state.billing.account_wallet_transactions_meta,
            account_wallet_transactions_search_status: state.billing.account_wallet_transactions_search_status,
            account_wallet_transactions_search_data: state.billing.account_wallet_transactions_search_data,
            account_wallet_add_credit_status: state.billing.account_wallet_add_credit_status,
            account_wallet_cash_out_status: state.billing.account_wallet_cash_out_status
        }),
        {
            getWalletBalances,
            getWalletTransactions,
            searchWalletTransactions,
            walletAddCredit,
            walletCashOut
        }
    )(Wallet)
);
