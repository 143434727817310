/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/types';

export function collectionIncludes<TSearchCollection extends _.List<unknown>>(
    collectionToSearch: TSearchCollection,
    collectionToFind: Array<NXUtils.ValueOf<TSearchCollection>> | string[] | string
) {
    return _.findIndex(collectionToSearch, (o) => _.includes(collectionToFind, o)) !== -1;
}
