/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { has } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DetailsForm from '../forms/detailsForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import Grid from 'components/Grid';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Note from 'components/Note';
import Progress from 'components/Progress';
import { ScrollableComponent } from 'components/ScrollableComponent';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { copyToClipboard, processUsageStats } from 'utilities/methods/commonActions';
import { getCpanelDetails, resetCpanelPassword, sendServiceDetails, updateCpanelUsername } from '../state/accountActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Details extends Component {
    constructor(props) {
        super(props);
        this.changeUsername = this.changeUsername.bind(this);
        this.closeDetailsAction = this.closeDetailsAction.bind(this);
        this.openDetailsAction = this.openDetailsAction.bind(this);
        this.processProducts = this.processProducts.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.toggleCurrentTab = this.toggleCurrentTab.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
        this.state = {
            selectedTab: false,
            product: null,
            detailsAction: false,
            showPassword: {
                cpanel: false,
                ftp: false
            }
        };
    }

    /**
     * @param {string} type - cpanel or ftp
     */
    togglePassword(type) {
        const { showPassword } = this.state;

        const newShowPasswordState = {
            ...showPassword
        };

        newShowPasswordState[type] = !newShowPasswordState[type];

        this.setState({
            showPassword: newShowPasswordState
        });
    }

    openDetailsAction(action) {
        this.setState({
            detailsAction: action
        });
    }

    closeDetailsAction() {
        this.setState({
            detailsAction: false
        });
    }

    resetPassword() {
        const { resetCpanelPassword, hostingid } = this.props;
        resetCpanelPassword(hostingid);
    }

    changeUsername(values) {
        const { updateCpanelUsername, hostingid, subaccount, history } = this.props;
        const attributes = {
            username: values.username
        };
        updateCpanelUsername(hostingid, attributes, subaccount, history);
    }

    toggleCurrentTab() {
        this.setState({
            selectedTab: !this.state.selectedTab
        });
    }

    renderDetails(data, type) {
        const { cpanel, ftp, showPassword, server } = this.state;
        const { togglePassword } = this;
        const { copyToClipboard } = this.props;

        const renderRows = [];

        const detailsData = type === 'ftp' ? ftp : cpanel;
        const detailsShowPassword = showPassword[type];

        Object.entries(data).forEach((row, index) => {
            let useRightCol, rightCol;
            if (row[0] === 'FTP Address') {
                row[1] = `${row[1]} OR ftp://${server['IP Address']}`;
            }
            if (row[0] === 'Username') {
                useRightCol = true;
                rightCol = {
                    title: 'Change',
                    onClick: (e) => {
                        e.preventDefault();
                        this.openDetailsAction('Change');
                    }
                };
            } else if (row[0] === 'Password') {
                useRightCol = true;
                rightCol = {
                    title: 'Reset',
                    onClick: (e) => {
                        e.preventDefault();
                        this.openDetailsAction('Reset');
                    }
                };
            } else if (row[0] && !row[1]) {
                return;
            }

            const copyPassword = () => {
                copyToClipboard(detailsData.Password);
            };

            const togglePasswordVisibility = () => {
                togglePassword(type);
            };

            const togglePasswordIcon = detailsShowPassword ? 'icon-eye-closed' : 'icon-eye-open';

            renderRows.push(
                <div key={index} className="tab__row">
                    <div className="row__title">
                        {row[0]}
                        {row[0] === 'Password' && (
                            <>
                                <button onClick={togglePasswordVisibility} className={`row__input--icon icon ${togglePasswordIcon}`} />
                                <button onClick={copyPassword} className="row__input--icon icon icon-copy" />
                            </>
                        )}
                    </div>

                    {row[0] === 'Password' ? (
                        <div className={`row__info ${useRightCol ? 'hasAction' : ''}`}>
                            <input type={detailsShowPassword ? 'text' : 'password'} readOnly className="row__input" value={detailsData.Password} />
                        </div>
                    ) : (
                        <div className={`row__info ${useRightCol ? 'hasAction' : ''}`}>{row[1]}</div>
                    )}

                    {useRightCol && (
                        <button className="row__action" onClick={rightCol.onClick}>
                            {rightCol.title}
                        </button>
                    )}
                </div>
            );
        });
        return renderRows;
    }

    componentDidMount() {
        const { hostingid, getCpanelDetails, hosting_cpanel_details_data, hosting_information_subaccount_data } = this.props;

        if (hosting_information_subaccount_data) {
            const { included } = hosting_information_subaccount_data;
            included.forEach((data) => {
                this.setState({
                    [data.type]: data.attributes
                });
            });
        } else if (!hosting_cpanel_details_data) {
            getCpanelDetails(hostingid);
        } else {
            const { included } = hosting_cpanel_details_data;
            included.forEach((data) => {
                this.setState({
                    [data.type]: data.attributes
                });
            });
        }

        this.intervalId = setInterval(() => getCpanelDetails(hostingid), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    componentDidUpdate(prevProps) {
        const { hosting_cpanel_details_data, hosting_cpanel_details_status, hosting_cpanel_status, hosting_information_subaccount_data } = this.props;

        if (
            hosting_cpanel_details_status === 'success' &&
            prevProps.hosting_cpanel_details_status === 'loading' &&
            hosting_cpanel_details_data &&
            has(hosting_cpanel_details_data, 'included')
        ) {
            if (hosting_information_subaccount_data) {
                const { included } = hosting_information_subaccount_data;
                included.forEach((data) => {
                    this.setState({
                        [data.type]: data.attributes
                    });
                });
            } else {
                const { included } = hosting_cpanel_details_data;
                included.forEach((data) => {
                    this.setState({
                        [data.type]: data.attributes
                    });
                });
            }
        }

        if ((hosting_cpanel_status === 'success' || hosting_cpanel_status === 'error') && prevProps.hosting_cpanel_status === 'loading') {
            this.setState({
                detailsAction: false
            });
        }
    }

    processProducts() {
        const { hosting_information_subaccount_data, hosting_information_data } = this.props;
        const { product } = this.state;
        if (hosting_information_subaccount_data) {
            hosting_information_subaccount_data.included.forEach((item) => {
                if (item.type === 'product' && product !== item.attributes.name.toLowerCase()) {
                    this.setState({
                        product: item.attributes.name.toLowerCase()
                    });
                }
            });
        } else {
            hosting_information_data.included.forEach((item) => {
                if (item.type === 'product' && product !== item.attributes.name.toLowerCase()) {
                    this.setState({
                        product: item.attributes.name.toLowerCase()
                    });
                }
            });
        }
    }

    render() {
        const {
            app_viewport,
            hosting_cpanel_details_status,
            hosting_cpanel_status,
            hosting_information_subaccount_status,
            hosting_isReseller,
            hosting_send_details_status,
            hostingid,
            sendServiceDetails,
            subaccount
        } = this.props;
        const { selectedTab, detailsAction, usage, ftp, cpanel, server, product } = this.state;
        const { toggleCurrentTab, closeDetailsAction, resetPassword, changeUsername, renderDetails, processProducts } = this;
        processProducts();

        const handleChangeLightboxRender = () => {
            if (detailsAction === 'Change') {
                return (
                    <OverlayLightbox
                        onOpen={detailsAction ? true : false}
                        className="hostingDetails__OverlayLightbox"
                        title="Change cPanel and FTP Username"
                        loading={hosting_cpanel_status}
                        onClose={closeDetailsAction}
                    >
                        {hosting_cpanel_status === 'loading' ? (
                            <div className="OverlayInvoice">
                                <RequestLoader />
                            </div>
                        ) : (
                            <div>
                                <div className="OverlayConfirm__description inline">
                                    Changing your username will update both your cPanel and FTP username.
                                </div>
                                <DetailsForm onSubmit={changeUsername} />
                                <button type="button" className="OverlayConfirm__commonlink" onClick={closeDetailsAction}>
                                    {`I DON'T WANT TO CHANGE IT`}
                                </button>
                            </div>
                        )}
                    </OverlayLightbox>
                );
            } else {
                const confirmReset = {
                    desc: htmr(`If you have lost or forgotten your cPanel password, you can use this function to reset it.`),
                    buttonText: 'Reset Password',
                    buttonAction: resetPassword,
                    closeText: "I DON'T WANT TO CHANGE IT",
                    closeAction: closeDetailsAction,
                    loading: hosting_cpanel_status
                };

                return (
                    <OverlayLightbox
                        onOpen={detailsAction ? true : false}
                        title="Reset cPanel and FTP Password"
                        loading={hosting_cpanel_status}
                        confirm={confirmReset}
                        onClose={closeDetailsAction}
                    />
                );
            }
        };

        function initialStatus() {
            const statuses = [hosting_cpanel_details_status, hosting_information_subaccount_status];
            if (statuses.includes('loading')) return 'loading';
            else if (statuses.includes('error')) return 'error';
            return 'success';
        }

        const renderStats = () => {
            if (usage) {
                const { cpu, disk, memory, inode } = processUsageStats;
                const { disk_limit, disk_used, cpu_usage, inode_limit, inode_used, mem_total, mem_usage } = usage;

                return (
                    <div className="hostingDetails__progress">
                        <Progress
                            title="Storage Space"
                            max={disk.max(disk_limit)}
                            value={disk.value(disk_used)}
                            valueTitle={disk.valueTitle(disk_used)}
                            maxTitle={disk.maxTitle(disk_limit)}
                        />
                        {mem_usage ? (
                            <Progress
                                title="Memory"
                                max={memory.max(mem_total)}
                                value={memory.value(mem_usage)}
                                valueTitle={memory.valueTitle(mem_usage)}
                                maxTitle={memory.maxTitle(mem_total)}
                            />
                        ) : (
                            ''
                        )}
                        {product !== 'starter' && cpu_usage ? (
                            <Progress
                                title="CPU Usage"
                                max={cpu.max()}
                                value={cpu.value(cpu_usage)}
                                valueTitle={cpu.valueTitle(cpu_usage)}
                                maxTitle={cpu.maxTitle()}
                            />
                        ) : (
                            ''
                        )}
                        {inode_used ? (
                            <>
                                <Progress
                                    title="Inode Usage"
                                    max={inode.max(inode_limit)}
                                    value={inode.value(inode_used)}
                                    valueTitle={inode.valueTitle(inode_used)}
                                    maxTitle={inode.maxTitle(inode_limit)}
                                />
                                <Note
                                    beforeColon="Please note"
                                    text="Due to 'hidden files' created by cPanel, there may sometimes be a slight discrepancy between this inode usage figure and what cPanel displays the inodes as."
                                />
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                );
            }
            return '';
        };

        function renderTabs() {
            if (!selectedTab && cpanel && ftp)
                return (
                    <div className="hostingDetails__tab--right">
                        <div className="tab__title">cPanel Details</div>
                        <div className="tab__details">{renderDetails(cpanel, 'cpanel')}</div>
                        <div className="tab__title">FTP Details</div>
                        <div className="tab__details">{renderDetails(ftp, 'ftp')}</div>
                    </div>
                );
            else if (selectedTab && server)
                return (
                    <div className="hostingDetails__tab--left">
                        <div className="tab__title">Server Details</div>
                        <div className="tab__details">{renderDetails(server)}</div>
                    </div>
                );

            return '';
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <ScrollableComponent ready={hosting_cpanel_details_status === 'success'} allowImmediateReady className="hostingDetails">
                <NXBox initialStatus={initialStatus()}>
                    <NXBox.Top title="Details" />
                    <div className="hostingDetails__wrapper">
                        {subaccount || hosting_isReseller ? '' : renderStats()}
                        {hosting_isReseller && !subaccount && server ? (
                            <div className="hostingDetails__tabContainer noTabs">
                                <div className="hostingDetails__tab--left">
                                    <div className="tab__title">Server Details</div>
                                    <div className="tab__details">{renderDetails(server)}</div>
                                </div>
                            </div>
                        ) : (
                            <div className="hostingDetails__tabContainer">
                                <div className="hostingDetails__tabSelector">
                                    <button
                                        className={`tabSelector__option ${!selectedTab ? 'selected' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleCurrentTab();
                                        }}
                                    >
                                        cPanel and FTP Details
                                    </button>
                                    <button
                                        className={`tabSelector__option ${selectedTab ? 'selected' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleCurrentTab();
                                        }}
                                    >
                                        Server Details
                                    </button>
                                </div>
                                {renderTabs()}
                            </div>
                        )}
                    </div>
                    <NXBox.Bottom>
                        <Grid columns={['sm', 'xs'].includes(app_viewport) ? '100%' : '1fr 200px'}>
                            <Text secondary size--s key={0}>
                                {`If you require a copy of all your service details, we can send them to your ${application} account email address.`}
                            </Text>

                            {hosting_send_details_status === 'loading' ? (
                                <InactiveButton key={1}>
                                    <RequestLoader />
                                </InactiveButton>
                            ) : (
                                <OutlineButton
                                    type="onClick"
                                    key={1}
                                    onClick={() => {
                                        sendServiceDetails(hostingid);
                                    }}
                                >
                                    Send Service Details
                                </OutlineButton>
                            )}
                        </Grid>
                    </NXBox.Bottom>
                </NXBox>

                {detailsAction ? handleChangeLightboxRender(detailsAction) : ''}
            </ScrollableComponent>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            app_viewport: state.app.app_viewport,
            hosting_cpanel_details_data: state.hosting.hosting_cpanel_details_data,
            hosting_cpanel_details_status: state.hosting.hosting_cpanel_details_status,
            hosting_cpanel_status: state.hosting.hosting_cpanel_status,
            hosting_information_data: state.hosting.hosting_information_data,
            hosting_information_subaccount_data: state.hosting.hosting_information_subaccount_data,
            hosting_information_subaccount_status: state.hosting.hosting_information_subaccount_status,
            hosting_isReseller: state.hosting.hosting_isReseller,
            hosting_send_details_status: state.hosting.hosting_send_details_status
        }),
        {
            copyToClipboard,
            getCpanelDetails,
            resetCpanelPassword,
            sendServiceDetails,
            updateCpanelUsername
        }
    )(Details)
);
