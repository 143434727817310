export const INVOICE_ACTIONS = {
    PAY: 'pay',
    CANCEL: 'cancel'
};

/**
 * Used to generate the url of the pay / cancel invoice lightbox.
 */
export function getInvoiceActionUrl(action, invoiceId) {
    if (!Object.values(INVOICE_ACTIONS).includes(action)) throw new Error('Action must be one of the supported actions.');

    return `/billing/invoices/${invoiceId}/${action}`;
}
