import _ from 'lodash';

export function getAllTruthyPaths(obj: Object, parentKey = ''): string[] {
    if (_.isArray(obj)) {
        return obj.flatMap((value, index) => getAllTruthyPaths(value, `${parentKey}[${index}]`));
    }

    if (_.isObject(obj)) {
        return _.keys(obj).flatMap((key) => getAllTruthyPaths(obj[key], parentKey ? `${parentKey}.${key}` : key));
    }

    if (!_.isObject(obj) && !_.isNil(obj)) {
        return [parentKey];
    }

    return [];
}
