/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import _, { cloneDeep, has } from 'lodash';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { applyNewSectionSort } from 'containers/katana/queries/methods/applyNewSectionSort';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SECOND } from 'utilities/consts';
import { typedSet } from 'utilities/methods/objectMethods';

export declare namespace getSections {
    type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.sections.GET>>;
}

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(['katana', 'service', serviceID, 'sections'] as const);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.site.service_id.sections.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: SECOND * 25,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function setQueryData(serviceID: number, updater: (oldData: getSections.TData) => getSections.TData | undefined) {
    return queryClient.setQueryData(createQueryKey(serviceID), updater);
}

function invalidateQueries(serviceID: number) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

function prefetchQuery(serviceID: number) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function cancelQueries(serviceID: number) {
    return queryClient.cancelQueries({ queryKey: createQueryKey(serviceID) });
}

function useIsFetching(serviceID: number) {
    return queryClient.isFetching({ queryKey: createQueryKey(serviceID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery(serviceID: number) {
    return useQuery(createQueryOptions(serviceID));
}

function useGetIDByDefinitionID(serviceID: number, sectionDefinitionID: string) {
    const query = _useQuery(serviceID);
    const { data: get_katana_site_sections_data } = query;

    if (!_.isArray(get_katana_site_sections_data)) {
        return { sectionID: null, query };
    }

    const section = get_katana_site_sections_data.find((section) => section.section_id === sectionDefinitionID);

    return { sectionID: section?.id ?? null, query };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

function optimisticSectionSort(serviceID: number, sectionIDs: number[]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        clonedData.data = applyNewSectionSort(sectionIDs, clonedData.data);

        return clonedData;
    });
}

function optimisticNameUpdate(serviceID: number, sectionID: number, name: string) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        const section = clonedData.data.find((section) => section.id === sectionID);
        if (!section) {
            return;
        }

        typedSet(section, 'name', name);

        return clonedData;
    });
}

function optimisticSectionAddition(serviceID: number, sectionData: Artah.Katana.Site.ID.Sections.GET._200['data'][number]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        clonedData.data.push(sectionData);

        sortSectionIntoCorrectOrder(clonedData.data);

        return clonedData;
    });
}

function optimisticSectionPropertiesUpdate(
    serviceID: number,
    sectionID: number,
    propertiesData: Artah.Katana.Site.ID.Sections.GET._200['data'][number]['properties']
) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        const section = clonedData.data.find((section) => section.id === sectionID);
        if (!section) {
            return;
        }

        section.properties = propertiesData;

        return clonedData;
    });
}

export const getSections = Object.freeze({
    useQuery: _useQuery,
    useGetIDByDefinitionID,
    useIsFetching,
    optimisticSectionAddition,
    optimisticSectionPropertiesUpdate,
    optimisticSectionSort,
    optimisticNameUpdate,
    cancelQueries,
    invalidateQueries,
    prefetchQuery,
    setQueryData,
    createQueryKey,
    createQueryOptions
});
