/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { cloneDeep, has, merge } from 'lodash';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';

/**
 * @param {number} serviceID
 */
function createQueryKey(serviceID) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'service', serviceID, 'info']));
}

/**
 * @param {number} serviceID
 */
function createQueryOptions(serviceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.service_id.info.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**
 * @template {Awaited<ReturnType<typeof KATANA_API.katana.service_id.info.GET>>} TData
 * @param {number} serviceID
 * @param {(oldData: TData) => TData | undefined} updater
 */
function setQueryData(serviceID, updater) {
    return queryClient.setQueryData(createQueryKey(serviceID), updater);
}

/**
 * @param {number} serviceID
 */
export function cancelQueries(serviceID) {
    return queryClient.cancelQueries({
        queryKey: createQueryKey(serviceID)
    });
}

/**
 * @param {number} serviceID
 */
function resetQueries(serviceID) {
    return queryClient.resetQueries({
        queryKey: createQueryKey(serviceID)
    });
}

/**
 * @param {number} serviceID
 */
function prefetchQuery(serviceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

/**
 * @param {number} serviceID
 */
function invalidateQueries(serviceID) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the site info
 * @param {number} serviceID
 */
function _useQuery(serviceID) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 * @param {string} color
 */
function applyOptimisticColourUpdate(serviceID, color) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.color')) {
            return;
        }

        clonedData.data.color = color;

        return clonedData;
    });
}

/**
 * @param {number} serviceID
 * @param {NXUtils.RecursivePartial<KatanaAPI.Katana.ServiceID.Info.GET.Style>} style
 */
function applyOptimisticStyleUpdate(serviceID, style) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);
        if (!has(clonedData, 'data.style')) {
            return;
        }

        clonedData.data.style = merge({}, clonedData.data.style, style);

        return clonedData;
    });
}

export const getSiteInfo = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    cancelQueries,
    resetQueries,
    prefetchQuery,
    invalidateQueries,
    applyOptimisticColourUpdate,
    applyOptimisticStyleUpdate,
    createQueryKey,
    createQueryOptions
});
