/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PricingTable.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class PricingTable extends Component {
    render() {
        const { total, rows, slim } = this.props;

        const renderRows = () => {
            if (!rows) return '';

            return rows.map(({ label, amount }, index) => (
                <div key={index} className="PricingTable__row">
                    <p className="PricingTable__rowLabel">{label}</p>
                    <p className="PricingTable__rowPrice">{amount}</p>
                </div>
            ));
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className={`PricingTable${slim ? ' PricingTable--slim' : ''}`}>
                {renderRows()}
                <div className="PricingTable__row PricingTable__row--total">
                    <p className="PricingTable__rowLabel">{total.label}</p>
                    <p className="PricingTable__rowPrice">{total.amount}</p>
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default PricingTable;

PricingTable.propTypes = {
    // Total amount due
    total: PropTypes.shape({
        // Total label (left side of the row)
        label: PropTypes.string,
        // Total amount (right side of the row)
        amount: PropTypes.string
    }),

    // Any additional rows that need to go above the total amount due, eg. subtotal, discount
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            // Row label (left side of the row)
            label: PropTypes.string,
            // Row amount (right side of the row)
            amount: PropTypes.string
        })
    ),

    // Whether or not the pricing table should have a full border and grey background
    slim: PropTypes.bool
};
