/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DiskBlockForm from '../../forms/diskBlocks';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import RightBorderButton from 'components/Buttons/RightBorderButton';
import SolidButton from 'components/Buttons/SolidButton';
import { BUTTON_TYPES } from 'components/Buttons/_BaseButton';
import CancelAddon from 'components/Cancel/Addon';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';
import NXTable from 'components/NXTable';
import PayInvoiceNotification from 'components/Notifications/PayInvoice';
import Progress from 'components/Progress';
import OutlineTag from 'components/Tags/OutlineTag';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { cancelRemoveDiskBlock, getDiskBlockInfo, getEmailMailboxUsage, purchaseDiskBlock, removeDiskBlock, resetDiskBlockInfo } from '../../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { addonStatus, constants, lightboxes } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EmailHostingDiskBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDiskBlockLightbox: false,
            showRemoveLightbox: false,
            showKeepLightbox: false,
            showConfirmLightbox: false,
            showInvoiceLightbox: false,
            invoiceid: null,
            addonid: null,
            billingCycles: null,
            purchaseOptions: null
        };

        this.openDiskBlockLightbox = this.openDiskBlockLightbox.bind(this);
        this.closeDiskBlockLightbox = this.closeDiskBlockLightbox.bind(this);
        this.openRemoveConfirm = this.openRemoveConfirm.bind(this);
        this.closeRemoveConfirm = this.closeRemoveConfirm.bind(this);
        this.openKeepConfirm = this.openKeepConfirm.bind(this);
        this.closeKeepConfirm = this.closeKeepConfirm.bind(this);
        this.handleKeepDiskBlock = this.handleKeepDiskBlock.bind(this);
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.submitDiskBlockRequest = this.submitDiskBlockRequest.bind(this);
        this.processUsage = this.processUsage.bind(this);
        this.getLoadingState = this.getLoadingState.bind(this);
    }

    openPayInvoice(id) {
        this.setState({
            showConfirmLightbox: false,
            showInvoiceLightbox: true,
            invoiceId: id
        });
    }

    closePayInvoice() {
        this.setState({
            showInvoiceLightbox: false,
            invoiceId: null
        });
    }

    openKeepConfirm(id) {
        this.setState({
            showKeepLightbox: true,
            addonid: id
        });
    }

    closeKeepConfirm() {
        this.setState({
            showKeepLightbox: false,
            addonid: null
        });
    }

    handleKeepDiskBlock() {
        const { mailbox, cancelRemoveDiskBlock } = this.props;
        const { addonid } = this.state;
        cancelRemoveDiskBlock(mailbox, addonid);
    }

    openRemoveConfirm(id) {
        this.setState({
            showRemoveLightbox: true,
            addonid: id
        });
    }

    closeRemoveConfirm() {
        this.setState({
            showRemoveLightbox: false,
            addonid: null
        });
    }

    openDiskBlockLightbox() {
        const { resetDiskBlockInfo } = this.props;
        resetDiskBlockInfo();
        this.setState({
            showDiskBlockLightbox: true
        });
    }

    closeDiskBlockLightbox() {
        this.setState({
            showDiskBlockLightbox: false
        });
    }

    submitDiskBlockRequest(id, attributes) {
        const { mailbox, purchaseDiskBlock } = this.props;
        purchaseDiskBlock(mailbox, id, attributes);
    }

    getLoadingState() {
        const { email_diskblock_list_status, email_information_mailbox_status, email_usage_status } = this.props;

        switch (true) {
            case [email_diskblock_list_status, email_information_mailbox_status, email_usage_status].includes('loading'):
                return 'loading';
            case [email_diskblock_list_status, email_information_mailbox_status, email_usage_status].includes('error'):
                return 'error';
            default:
                return 'success';
        }
    }

    /**
     * @param {number} usage - a number in MB
     */
    processUsage(usage) {
        switch (true) {
            case usage && usage === 'unlimited':
                return undefined;
            case usage < 1000:
                return `${usage}MB`;
            case usage < 1024:
                return `~1GB`;
            default:
                return `${(usage / 1024).toFixed(2)}GB`;
        }
    }

    componentDidMount() {
        const { mailbox, getDiskBlockInfo, email_information_mailbox_data } = this.props;

        /** @type {string | undefined} */
        const name = email_information_mailbox_data?.included?.find(({ type }) => type === 'product')?.attributes?.name;

        if (name === 'SYD-EMAIL-PLUS') {
            getDiskBlockInfo(mailbox);
        }

        getEmailMailboxUsage(mailbox);
    }

    componentDidUpdate(prevProps) {
        const {
            email_diskblock_list_status,
            email_diskblock_purchase_status,
            email_diskblock_status,
            email_diskblock_list_data,
            email_diskblock_purchase_data,
            getDiskBlockInfo,
            mailbox
        } = this.props;
        registerScrollEvents(this, email_diskblock_list_status === 'success' && prevProps.email_diskblock_list_status === 'loading');

        if (email_diskblock_list_status === 'success' && prevProps.email_diskblock_list_status === 'loading') {
            const { purchase, billing_cycle } = email_diskblock_list_data.attributes;
            this.setState({
                billingCycles: billing_cycle,
                purchaseOptions: purchase
            });
        }

        if (email_diskblock_purchase_status === 'success' && prevProps.email_diskblock_purchase_status === 'loading') {
            const { included } = email_diskblock_purchase_data;
            const invoice = getIncludedObjBasedOnType(included, 'invoice');
            const { id } = invoice;

            this.setState(
                {
                    showDiskBlockLightbox: false,
                    showKeepLightbox: false,
                    showRemoveLightbox: false,
                    showConfirmLightbox: false,
                    showInvoiceLightbox: false
                },
                () => {
                    this.openPayInvoice(id);
                }
            );
        }

        if (email_diskblock_purchase_status === 'error' && prevProps.email_diskblock_purchase_status === 'loading') {
            this.setState({
                showDiskBlockLightbox: false,
                showKeepLightbox: false,
                showRemoveLightbox: false,
                showConfirmLightbox: false,
                showInvoiceLightbox: false
            });
        }

        if (email_diskblock_status === 'success' && prevProps.email_diskblock_status === 'loading') {
            this.setState(
                {
                    showDiskBlockLightbox: false,
                    showKeepLightbox: false,
                    showRemoveLightbox: false,
                    showConfirmLightbox: false,
                    showInvoiceLightbox: false
                },
                () => getDiskBlockInfo(mailbox)
            );
        }
    }

    render() {
        const {
            mailbox,
            email_diskblock_list_status,
            email_diskblock_purchase_status,
            email_diskblock_status,
            email_diskblock_list_data,
            email_usage_data,
            history
        } = this.props;
        const {
            showDiskBlockLightbox,
            showInvoiceLightbox,
            showKeepLightbox,
            showRemoveLightbox,
            invoiceId,
            addonid,
            purchaseOptions,
            billingCycles
        } = this.state;
        const {
            openDiskBlockLightbox,
            closeDiskBlockLightbox,
            submitDiskBlockRequest,
            handleKeepDiskBlock,
            closeRemoveConfirm,
            closeKeepConfirm,
            openPayInvoice,
            closePayInvoice,
            processUsage,
            getLoadingState
        } = this;
        const { invoice_id, current_disk_blocks } = email_diskblock_list_data?.attributes ?? {};
        const { storage, usage } = email_usage_data?.attributes ?? {};
        const isMaxPlan = !!email_diskblock_list_data;
        const hasTable = current_disk_blocks?.length > 0;

        /**
         * @param {'unpaid' | 'active' | 'pending deletion'} status
         */
        const renderStatus = (status) => {
            switch (status) {
                case addonStatus.UNPAID:
                    return <OutlineTag color="notice">{status}</OutlineTag>;
                case addonStatus.ACTIVE:
                    return <OutlineTag color="confirm">{status}</OutlineTag>;
                case addonStatus.PENDING_DELETION:
                    return <OutlineTag color="warn">{status}</OutlineTag>;
                default:
                    return <OutlineTag>{status}</OutlineTag>;
            }
        };

        /**
         * @param {'unpaid' | 'active' | 'pending deletion'} status
         * @param {number} addonId
         * @param {number} invoiceId
         */
        const renderButton = (status, addonId, invoiceId) => {
            const { openKeepConfirm, openRemoveConfirm } = this;

            const defaultProps = {
                type: BUTTON_TYPES.ON_CLICK,
                size: /** @type {const} */ ('medium')
            };

            switch (true) {
                case status === addonStatus.UNPAID && !!invoiceId && !!addonId:
                    return (
                        <OutlineButton {...defaultProps} onClick={() => history.push(`/billing/invoices/${invoiceId}`)}>
                            Pay Invoice
                        </OutlineButton>
                    );
                case status === addonStatus.PENDING_DELETION:
                    return (
                        <SolidButton {...defaultProps} color="warning" onClick={() => openKeepConfirm(addonId)}>
                            Keep Active
                        </SolidButton>
                    );
                case status === addonStatus.ACTIVE:
                    return (
                        <OutlineButton {...defaultProps} color="error" onClick={() => openRemoveConfirm(addonId)}>
                            Remove
                        </OutlineButton>
                    );
                default:
                    return <></>;
            }
        };

        const classes = {
            footer: classNames({
                'emailDisk__footer': true,
                'emailDisk__footer--blend': hasTable
            }),
            footerContent: classNames({
                'emailDisk__footerContent': true,
                'emailDisk__footerContent--noAddButton': !isMaxPlan || hasTable
            })
        };

        /*  Render Component
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="emailDisk"
                name="emailDisk"
            >
                <NXBox initialStatus={getLoadingState()}>
                    {!!invoice_id && (
                        <Padding xy={4}>
                            <PayInvoiceNotification openPayInvoice={() => openPayInvoice(invoice_id)} text={constants.outstandingInvoice} />
                        </Padding>
                    )}
                    <NXBox.Top
                        title={isMaxPlan ? 'Disk Blocks' : 'Disk Usage'}
                        description={isMaxPlan ? constants.description.diskBlock : constants.description.usage}
                    />
                    <NXBox.Footer className={classes.footer}>
                        <Padding x={2} y={5}>
                            <div className={classes.footerContent}>
                                <Progress
                                    title="Current Usage"
                                    max={storage}
                                    value={usage}
                                    valueTitle={processUsage(usage)}
                                    maxTitle={`${Math.round(storage / 1024)}GB`}
                                />
                                {isMaxPlan && !hasTable && (
                                    <OutlineButton onClick={openDiskBlockLightbox} type={BUTTON_TYPES.ON_CLICK} className="emailDisk__addButton">
                                        Add More
                                    </OutlineButton>
                                )}
                            </div>
                        </Padding>
                    </NXBox.Footer>
                    {!!hasTable && (
                        <NXTable columns="1fr 1fr 1fr 1fr NXActions">
                            <NXTable.Header>
                                <NXTable.Header.Title>Amount</NXTable.Header.Title>
                                <NXTable.Header.Title>Status</NXTable.Header.Title>
                                <NXTable.Header.Title>Billing Period</NXTable.Header.Title>
                                <NXTable.Header.Title>Renewal Date</NXTable.Header.Title>
                            </NXTable.Header>
                            <NXTable.Body>
                                {current_disk_blocks.map((block) => (
                                    <NXTable.Row key={block.id}>
                                        <Text secondary size--s>{`${block.disk_block_amount}${block.unit}`}</Text>
                                        {renderStatus(block.status)}
                                        <Text secondary size--s>
                                            {block.billing_cycle}
                                        </Text>
                                        <Text secondary size--s>
                                            {toLuxonDate(block.next_due_date).toFormat('dd MMM yyyy')}
                                        </Text>
                                        <NXTable.Actions>{renderButton(block.status, block.id, block.invoice_id)}</NXTable.Actions>
                                    </NXTable.Row>
                                ))}
                            </NXTable.Body>
                            <NXTable.Footer>
                                <RightBorderButton color="secondary" type="onClick" onClick={openDiskBlockLightbox}>
                                    <Text uppercase>Add Disk Block Addon</Text>
                                    <Text size--xs>
                                        <PhosphorIcons.Plus />
                                    </Text>
                                </RightBorderButton>
                            </NXTable.Footer>
                        </NXTable>
                    )}
                </NXBox>

                {showDiskBlockLightbox && email_diskblock_list_status !== 'error' && email_diskblock_status !== 'error' ? (
                    <OverlayLightbox
                        onOpen={showDiskBlockLightbox && email_diskblock_list_status !== 'error' && email_diskblock_status !== 'error'}
                        onClose={closeDiskBlockLightbox}
                        title="Increase/Decrease Disk Space"
                        loading={email_diskblock_purchase_status}
                    >
                        <DiskBlockForm
                            confirmAddon={submitDiskBlockRequest}
                            mailbox={mailbox}
                            diskBlockData={{ purchaseOptions, billingCycles, disk: { limit: Math.round(storage / 1024), usage } }}
                        />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
                {showRemoveLightbox && (
                    <CancelAddon
                        type="email"
                        onClose={closeRemoveConfirm}
                        confirmationFields={[
                            {
                                name: 'confirm_plan_limits',
                                label: constants.lightboxes[lightboxes.REMOVE].confirmationFields.confirm_plan_limits
                            },
                            {
                                name: 'confirm_delete',
                                label: constants.lightboxes[lightboxes.REMOVE].confirmationFields.confirm_delete
                            },
                            {
                                name: 'confirm_backup',
                                label: constants.lightboxes[lightboxes.REMOVE].confirmationFields.confirm_backup
                            }
                        ]}
                        addonId={addonid}
                    />
                )}
                {showKeepLightbox && (
                    <OverlayLightbox
                        onOpen={showKeepLightbox}
                        onClose={closeKeepConfirm}
                        title="Keep Disk Space Block Active"
                        loading={email_diskblock_status}
                        confirm={{
                            desc: constants.lightboxes[lightboxes.KEEP].description,
                            buttonText: 'Keep Active',
                            buttonAction: handleKeepDiskBlock,
                            closeText: 'No, Keep It Disabled',
                            closeAction: closeKeepConfirm,
                            loading: email_diskblock_status
                        }}
                    />
                )}
                {showInvoiceLightbox && (
                    <OverlayLightbox onOpen title={'Pay Invoice #' + invoiceId} invoiceid={invoiceId} onClose={closePayInvoice} />
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return /** @type {const} */ ({
        email_diskblock_list_status: state.email.email_diskblock_list_status,
        email_diskblock_list_data: state.email.email_diskblock_list_data,
        email_diskblock_list_error: state.email.email_diskblock_list_error,
        email_diskblock_purchase_status: state.email.email_diskblock_purchase_status,
        email_diskblock_purchase_data: state.email.email_diskblock_purchase_data,
        email_diskblock_purchase_error: state.email.email_diskblock_purchase_error,
        email_diskblock_status: state.email.email_diskblock_status,
        email_diskblock_data: state.email.email_diskblock_data,
        email_diskblock_error: state.email.email_diskblock_error,
        email_information_mailbox_data: state.email.email_information_mailbox_data,
        email_usage_data: state.email.email_usage_data,
        email_usage_status: state.email.email_usage_status
    });
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetDiskBlockInfo,
            getDiskBlockInfo,
            purchaseDiskBlock,
            removeDiskBlock,
            cancelRemoveDiskBlock
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailHostingDiskBlock));
