/**
 * This billing cycle component used to be a shared component (that's why it still has code that is not specific to gsuite).
 * At some point we want to refactor it to remove the stuff that isn't specific to gsuite, because now each container has its own billing cycle component
 */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { restrictedProducts } from 'config/config';
import { has } from 'lodash';
import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BillingCycleForm from './form/billingCycleForm';
import BillingCycleSuccess from './form/billingCycleSuccess';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { formatLuxonOrdinal, getIncludedObjBasedOnType, scrollToRef, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import classNames from 'classnames';
import { ScrollableComponent } from 'components/ScrollableComponent';
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let BillingCycle = (props) => {
    const {
        information,
        serviceid,
        serviceType,
        history,
        mailbox,
        getService,
        update,
        status,
        location,
        data,
        updatedStatus,
        hosting_billing_status,
        getData,
        updatedData,
        mailboxInformation
    } = props;

    /***** STATE *****/
    const [showBillingDropdown, setShowBillingDropdown] = useState(false);
    const [billingOptions, setBillingOptions] = useState(null);
    const [showBillingCycleLightbox, setShowBillingCycleLightbox] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const [services, setServices] = useState(null);
    const [currentBillingCycle, setCurrentBillingCycle] = useState('');

    /***** HOOKS *****/
    const scrollRef = createRef();

    /***** FUNCTIONS *****/
    function closeBillingCycle() {
        setShowBillingCycleLightbox(false);
        if (serviceType === 'email') {
            getService(mailbox, serviceid, history);
        } else {
            getService(serviceid);
        }
    }

    function toggleBillingForm() {
        setShowBillingDropdown(!showBillingDropdown);
    }

    function submitBillingRequest(values) {
        switch (serviceType) {
            case 'gsuite':
                update(serviceid, values.newBillingCycle.id);
                break;

            default: {
                const attributes = {
                    billing_cycle: values.newBillingCycle.name,
                    billing_cycle_id: values.newBillingCycle.id,
                    include_other: values.other
                };

                update(serviceid, attributes);
                break;
            }
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        const invoice = getIncludedObjBasedOnType(information?.included, 'invoice');

        setInvoice(invoice);
    }, []);

    useEffect(() => {
        if (status === 'success') {
            const {
                attributes: { billing_cycles, other_services }
            } = data;
            const optionList = billing_cycles.map(({ amount, name }) => {
                return {
                    label: `${name.name} - $${amount} AUD`,
                    value: name
                };
            });

            setBillingOptions(optionList);
            setServices(other_services);
        }
    }, [status]);

    useEffectAfterMount(() => {
        if (!has(information, 'attributes')) return;
        const { billing_cycle } = information.attributes;

        if (updatedStatus === 'loading') {
            setCurrentBillingCycle(<RequestLoader />);
            return;
        }

        if (billing_cycle) {
            setCurrentBillingCycle(billing_cycle);
            return;
        }
    }, [information, data, updatedStatus]);

    useEffect(() => {
        if (location?.pathname?.includes('billing')) {
            const query = new URLSearchParams(location.search);
            const open = query.get('open');

            if (open) {
                setShowBillingDropdown(true);
                scroll.scrollTo(scrollRef.current.getBoundingClientRect().top);
            }
        }
    }, [location]);

    useEffectAfterMount(() => {
        if (updatedStatus === 'success') {
            setShowBillingDropdown(false);
            setShowBillingCycleLightbox(true);
        }
    }, [updatedStatus]);

    useEffect(() => {
        if (showBillingDropdown) {
            scrollToRef(scrollRef.current);
        }
    }, [showBillingDropdown]);

    /***** RENDER HELPERS *****/
    const renderButton = () => {
        return {
            label: 'Change',
            type: 'onClick',
            className: '',
            size: 'large',
            onClick: (e) => {
                e.preventDefault();
                toggleBillingForm();
            }
        };
    };

    if (!information) return '';

    const { attributes } = information;
    const { next_due_date } = attributes;

    function checkRestrictedProductList() {
        const product = attributes.product.name;
        return restrictedProducts.filter((item) => item === product).length > 0;
    }

    const propsHasProduct = {
        disabled: {
            icon: `alert-circle`,
            message:
                'This plan is currently only eligible for monthly billing and will be reaching end of life in the future. We will provide more detailed information and an upgrade path for your plan soon.'
        }
    };

    const conditionalProps = has(attributes, 'product') && checkRestrictedProductList() ? propsHasProduct : {};

    function noErrorStatus() {
        if (['error', 'success'].includes(updatedStatus)) {
            return 'success';
        }
        return updatedStatus;
    }

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={status === 'success'} className={classNames('emailHostingBillingCycle', { dropdown: showBillingDropdown })}>
            <div ref={scrollRef}>
                <Box
                    request={{
                        action: getData,
                        args: serviceid,
                        status: status
                    }}
                    status={noErrorStatus()}
                    className="billingCycle"
                    title="Billing Cycle"
                    desc={`You can change your billing cycle to make when you're invoiced more convenient. You will be invoiced on the new billing cycle at the end of your current prepaid period.`}
                    bottom={true}
                    columns={[
                        {
                            render: (
                                <div className="billingCycle__column">
                                    <div className="title">Current Billing Cycle</div>
                                    <div className="desc">
                                        {serviceType === 'email' ? mailboxInformation?.attributes.billing_cycle : currentBillingCycle}
                                    </div>
                                </div>
                            )
                        },
                        {
                            render: (
                                <div className="billingCycle__column">
                                    <div className="title">Next Invoice Date</div>
                                    <div className="desc">{formatLuxonOrdinal(toLuxonDate(next_due_date).toFormat('EEEE, MMM {d} yyyy'))}</div>
                                </div>
                            )
                        }
                    ]}
                    action={renderButton()}
                    dropdown={{
                        render: (
                            <BillingCycleForm
                                onSubmit={submitBillingRequest}
                                options={billingOptions}
                                invoice={invoice}
                                service={information}
                                services={services}
                            />
                        ),
                        condition: showBillingDropdown,
                        close: toggleBillingForm,
                        status: hosting_billing_status
                    }}
                    {...conditionalProps}
                />
                {showBillingCycleLightbox && (
                    <OverlayLightbox onOpen={showBillingCycleLightbox} onClose={closeBillingCycle}>
                        <BillingCycleSuccess
                            closeLightbox={closeBillingCycle}
                            currentInvoice={invoice}
                            updatedInvoice={updatedData}
                            dueDate={next_due_date}
                            serviceType={serviceType}
                        />
                    </OverlayLightbox>
                )}
            </div>
        </ScrollableComponent>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
BillingCycle = connect(null, {})(BillingCycle);

BillingCycle = withRouter(BillingCycle);

export default BillingCycle;
