/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { destroy } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ConfirmMigrationForm from 'containers/ventraSynMigration/forms/confirmMigrationForm';
import CreateAccountForm from 'containers/ventraSynMigration/forms/createAccountForm';
import SelectServicesForm from 'containers/ventraSynMigration/forms/selectServicesForm';
import { Intro } from 'containers/ventraSynMigration/modules/intro';
import MigrationSuccessPage from 'containers/ventraSynMigration/modules/migrationSuccessPage';
import ProcessHeader from 'containers/ventraSynMigration/modules/processHeader';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { checkRequiresMigration, clearVsmState, migrateServices, synergyRegister } from 'containers/ventraSynMigration/action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class MigrationProcess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 'select-services',
            accounts: []
        };

        this.updateAccounts = this.updateAccounts.bind(this);
        this.formatServices = this.formatServices.bind(this);
        this.registerThenConfirmMigration = this.registerThenConfirmMigration.bind(this);
        this.alreadyLinkedConfirmMigration = this.alreadyLinkedConfirmMigration.bind(this);
    }

    updateAccounts(accounts = [], cb) {
        this.setState(
            {
                accounts
            },
            () => {
                if (cb) cb();
            }
        );
    }

    formatServices() {
        const { accounts } = this.state;

        const resellerServices = {};

        accounts.forEach((subaccount) => {
            const {
                id,
                update_dns,
                info: { service_id }
            } = subaccount;
            // add the subaccount's paarent service to resellerServices if it doesnt already exist (ie for the first subaccount on that parent service)
            if (!resellerServices[service_id]) resellerServices[service_id] = [];
            // push the subaccount onto the parent services's accounts list
            resellerServices[service_id].push({ id, update_dns });
        });

        return Object.entries(resellerServices).map(([key, value]) => ({ service_id: key, accounts: value }));
    }

    registerThenConfirmMigration(values) {
        const { synergyRegister } = this.props;
        const { formatServices } = this;

        synergyRegister({ ...values, services: formatServices() });
    }

    alreadyLinkedConfirmMigration(values) {
        const { migrateServices } = this.props;
        const { formatServices } = this;

        migrateServices({ ...values, services: formatServices() });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const {
            checkRequiresMigration,
            match: {
                params: { subpage }
            },
            history
        } = this.props;

        checkRequiresMigration();

        if (subpage !== 'select-services') history.push('/synergy-reseller-migration/select-services');
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            history,
            match: {
                params: { subpage }
            },
            vsm_migrate_status,
            destroy,
            clearVsmState,
            checkRequiresMigration
        } = this.props;
        const { updateAccounts } = this;

        if (vsm_migrate_status === 'success' && prevProps.vsm_migrate_status === 'loading') {
            updateAccounts([], () => history.push('/synergy-reseller-migration/success'));
            destroy('ventraSynMigrationForm');
            clearVsmState();
            checkRequiresMigration();
        }

        // If they try to go back from the success page, send them all the way back to the start
        if (prevProps.match.params.subpage === 'success' && ['confirm-migration', 'create-account'].includes(subpage)) {
            checkRequiresMigration();
            history.push('/synergy-reseller-migration/select-services');
        }
    }

    componentWillUnmount() {
        const { destroy, clearVsmState } = this.props;

        destroy('ventraSynMigrationForm');
        clearVsmState();
    }

    render() {
        const {
            vsm_requires_migration_status,
            vsm_requires_migration_data,
            history,
            match: {
                params: { subpage }
            }
        } = this.props;
        const { accounts } = this.state;
        const { updateAccounts, formatServices, registerThenConfirmMigration, alreadyLinkedConfirmMigration } = this;

        const renderForm = () => {
            switch (subpage) {
                case 'select-services':
                    return <SelectServicesForm formatServices={formatServices} updateAccounts={updateAccounts} accounts={accounts} />;
                case 'confirm-migration':
                    return (
                        <ConfirmMigrationForm
                            updateAccounts={updateAccounts}
                            accounts={accounts}
                            formatServices={formatServices}
                            submitButtonText={vsm_requires_migration_data.has_linked_account ? 'CONFIRM MIGRATION' : 'Continue'}
                            onSubmit={
                                vsm_requires_migration_data.has_linked_account
                                    ? alreadyLinkedConfirmMigration
                                    : (values) => history.push('/synergy-reseller-migration/create-account')
                            }
                        />
                    );
                case 'create-account':
                    return <CreateAccountForm accounts={accounts} formatServices={formatServices} onSubmit={registerThenConfirmMigration} />;
                case 'success':
                    return <MigrationSuccessPage />;
                default:
                    return '';
            }
        };

        const renderPage = () => {
            return (
                <div className="vsProcess">
                    <Intro />
                    <Box
                        desc={subpage === 'success' ? false : <ProcessHeader step={subpage} />}
                        premounted={true}
                        custom={{
                            render: renderForm(),
                            pos: 'bottom'
                        }}
                    />
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        switch (vsm_requires_migration_status) {
            case 'success':
                return renderPage();
            case 'error':
                return <FetchComponentError />;
            case 'loading':
            default:
                return <RequestLoader />;
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            vsm_requires_migration_status: state.vsm.vsm_requires_migration_status,
            vsm_requires_migration_data: state.vsm.vsm_requires_migration_data,
            vsm_migrate_status: state.vsm.vsm_migrate_status
        }),
        {
            clearVsmState,
            checkRequiresMigration,
            synergyRegister,
            migrateServices,
            destroy
        }
    )(MigrationProcess)
);
