/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PromoCodePillApplied from 'components/PromoCode/Pill/Applied';
import SolidTag from 'components/Tags/SolidTag';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import VipRewardsLogo from 'assets/images/vipRewards/vipRewardsLogo.svg';
import { vipRewardsReadableName } from 'containers/vipRewards/consts';

import './_domainRenewAutoDiscountDisplay.scss';

const discountTypes = {
    PERCENTAGE: 'percentage',
    FIXED: 'fixed'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function DomainRenewAutoDiscountDisplay({ discountType, discountValue, isAppliedTagShowing, message = 'We found you the best available deal!' }) {
    /***** HOOKS *****/
    const { currentTier } = useVipRewards();

    /***** RENDER HELPERS *****/
    function getDiscountText() {
        switch (discountType) {
            case discountTypes.FIXED: {
                let readableValue = discountValue;
                if (discountValue?.toString().endsWith('.00')) {
                    readableValue = Number(discountValue).toString();
                }

                return `$${readableValue}`;
            }
            case discountTypes.PERCENTAGE: {
                const readableValue = Number(discountValue).toString();

                return `${readableValue}%`;
            }
            default:
                return '';
        }
    }

    /***** RENDER *****/
    return (
        <div className="domainRenewAutoDiscount">
            {currentTier ? (
                <div className="domainRenewAutoDiscount__vipRewards">
                    <img src={VipRewardsLogo} alt={vipRewardsReadableName} />
                    <SolidTag color={currentTier.lowercase === 'vip' ? 'primary' : currentTier.lowercase}>{currentTier.readable}</SolidTag>
                </div>
            ) : (
                ''
            )}
            <div className="domainRenewAutoDiscount__container">
                <Flex direction="column" gap={0} className="domainRenewAutoDiscount__left">
                    <Text secondary size--s lead--s>
                        {message}
                    </Text>
                    {discountType && discountValue ? (
                        <Text primary size--l lead--s medium>
                            <p>
                                Your discount is <span>{getDiscountText()} OFF</span>
                            </p>
                        </Text>
                    ) : (
                        ''
                    )}
                </Flex>
                {isAppliedTagShowing ? <PromoCodePillApplied /> : ''}
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

DomainRenewAutoDiscountDisplay.propTypes = {
    /**
     * Eg. "percentage"
     */
    discountType: PropTypes.oneOf(Object.values(discountTypes)),

    /**
     * The value of the discount
     */
    discountValue: PropTypes.string,

    /**
     * Whether or not to show the "applied" tag
     */
    isAppliedTagShowing: PropTypes.bool,

    /**
     * The "best available deal" message to show
     */
    message: PropTypes.string
};

export default DomainRenewAutoDiscountDisplay;
