/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

// This will need to be updated if our checkboxes are made focusable
export const focusableElementsSelector =
    'button:not([disabled]), [href], input:not([type="checkbox"]):not([tabindex="-1"]):not([type="hidden"]), textarea, [tabindex]:not([tabindex="-1"])';

/**
 * @typedef {Object} ModalPropsTypeDef
 * @property {string} ariaLabel String to be set as the aria-label attribute on the .OverlayModal element. The aria-label attribute defines a string value that labels an interactive element.
 * @property {React.ReactNode} [backgroundChildren] Background contents of the modal.
 * @property {React.ReactNode} children Contents of the modal.
 * @property {string} [className] Class to append to the .Overlay element.
 * @property {React.RefObject<HTMLElement> | 'close-button'} [initialFocus] The ref of an element that should receive focus when the modal is opened. If initialFocus is ommitted then the first focusable element in the modal will receive initial focus. If 'close-button' is passed then the close button will recieve focus (use this when the lightbox has an initial loading state and the refs immediately disappear).
 * @property {(e?: import('react').SyntheticEvent ) => void} [onClose] Function to be called when the close button is clicked or Escape key is hit. This should change the state that controls "open" to false.
 * @property {boolean} isOpen Whether or not the modal is open.
 * @property {boolean} [preventScrollBlock] Whether or not to add the body scroll blocking
 * @property {React.ReactNode} [preButtonContent] Content to put before the close button
 * @property {boolean} [removeCloseButton] Whether or not to hide the default button which closes the modal. If true, an alternative button to close the modal will need to be rendered as part of the children prop.
 * @property {React.MutableRefObject<HTMLDivElement>} [overlayRef] The ref of the overlay element
 */
