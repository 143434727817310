/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows the registrant email address for the domain
 * @param {{
 *      email: string
 * }} props
 */
export function DomainRegistrantEmail({ email }) {
    /***** HOOKS *****/
    const domain_contact_status = useSelector((state) => state.domain.domain_contact_status);

    /***** RENDER *****/
    if (domain_contact_status === 'loading') {
        return null;
    }

    return (
        <NXBox.OverviewRow>
            <NXBox.InfoPair title="Registrant Email Address" description={email || 'Not Available'} />
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainRegistrantEmail;
