/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import whm_logo from 'assets/images/vps/whm-logo.svg';
import Clipboard from 'components/Clipboard';

import Text from 'components/Utils/Text';
import { WHMVPSLogin } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class WHMLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordRevealed: false,
            width: '150px'
        };
        this.passwordField = createRef();
        this.updatePasswordFieldWidth = this.updatePasswordFieldWidth.bind(this);
    }

    updatePasswordFieldWidth() {
        if (this.passwordField.current) {
            const width = (this.passwordField.current.value.length + 1) * 8 + 'px';
            this.setState({
                width
            });
        }
    }

    componentDidMount() {
        const { updatePasswordFieldWidth } = this;
        updatePasswordFieldWidth();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPasswordRevealed } = this.state;
        const { updatePasswordFieldWidth } = this;
        if (isPasswordRevealed !== prevState.isPasswordRevealed) {
            updatePasswordFieldWidth();
        }
    }

    render() {
        const { vps_user_information_status, WHMVPSLogin } = this.props;
        const { isPasswordRevealed, width } = this.state;

        if (vps_user_information_status !== 'success') {
            return '';
        }

        const { vps_information_data, vps_information_status } = this.props;

        if (!vps_information_data || vps_information_status !== 'success') {
            return '';
        }

        const { attributes, id } = vps_information_data;

        if (!attributes || !attributes.has_whm) {
            return '';
        }

        const { vps_user_information_data } = this.props;
        const { username, password } = vps_user_information_data.attributes;

        if (!username || !password || username.length <= 0 || password.length <= 0) {
            return '';
        }

        const renderPassword = () => {
            const RenderField = () => {
                if (isPasswordRevealed) {
                    return (
                        <input
                            style={{ width }}
                            ref={this.passwordField}
                            className="VPS-WHMLink__input"
                            type="text"
                            readOnly="readOnly"
                            value={password}
                        />
                    );
                } else {
                    return (
                        <input
                            style={{ width }}
                            ref={this.passwordField}
                            className="VPS-WHMLink__input"
                            type="password"
                            readOnly="readOnly"
                            value={password}
                        />
                    );
                }
            };

            return (
                <div className="VPS-WHMLink__details VPS-WHMLink__details--password">
                    <div className="VPS-WHMLink__details-label">
                        Password
                        <button
                            type="button"
                            onClick={() => {
                                if (isPasswordRevealed) {
                                    this.setState({
                                        isPasswordRevealed: false
                                    });
                                } else {
                                    this.setState({
                                        isPasswordRevealed: true
                                    });
                                }
                            }}
                        >
                            <i className={`icon icon-eye-${isPasswordRevealed ? 'closed' : 'open'}`} />
                        </button>
                    </div>
                    <div className="VPS-WHMLink__input-wrapper">
                        {RenderField()}
                        <Clipboard text="Copy" value={password} />
                    </div>
                </div>
            );
        };

        return (
            <div className="VPS-WHMLink box">
                <div className="VPS-WHMLink__column VPS-WHMLink__column--logo">
                    <div className="VPS-WHMLink__logo-wrapper">
                        <img src={whm_logo} alt="WHM" className="VPS-WHMLink__logo-image" />
                    </div>
                    <div className="VPS-WHMLink__link-wrapper">
                        <button
                            type="button"
                            className="VPS-WHMLink__link-anchor"
                            onClick={(e) => {
                                e.preventDefault();
                                WHMVPSLogin(id);
                            }}
                        >
                            <Text white>
                                <span className="VPS-WHMLink__link-text">Login URL</span>
                            </Text>
                            <i className="WHNLink__link-icon icon-external-link" />
                        </button>
                    </div>
                </div>
                <div className="VPS-WHMLink__column VPS-WHMLink__column--details">
                    <div className="VPS-WHMLink__details">
                        <div className="VPS-WHMLink__details-label">Username</div>
                        <div className="VPS-WHMLink__details-value">{username}</div>
                    </div>
                    {renderPassword()}
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        vps_user_information_data: state.vps.vps_user_information_data,
        vps_user_information_status: state.vps.vps_user_information_status,
        vps_information_data: state.vps.vps_information_data,
        vps_information_status: state.vps.vps_information_status
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            WHMVPSLogin
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(WHMLink);
