/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ValidationMessage } from 'components/Form/ValidationMessage';
import MultiFieldArrayChild from 'containers/katana/containers/contentEditorModules/multiFieldArrayInput/multiFieldArrayChild';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

/**
 * @template TFormattedValue
 * @typedef {MultiFieldArrayInputPropsTypeDef<TFormattedValue> & import('redux-form').WrappedFieldProps} MultiFieldArrayInputPropTypeDefs
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @template TFormattedValue
 * @param {MultiFieldArrayInputPropTypeDefs<TFormattedValue>} props
 */
function MultiFieldArrayInput({ fieldsContent, input, meta, ...props }) {
    const { value, onChange, name } = input;

    /***** RENDER *****/
    return (
        <FormItem>
            {fieldsContent.map((field) => {
                <MultiFieldArrayChild key={name + field.key} {...{ field, value, onChange, name, ...props }} />;
                return <MultiFieldArrayChild key={name + field.key} {...{ field, value, onChange, name, ...props }} />;
            })}
            <ValidationMessage.ReduxForm.Default {...meta} />
        </FormItem>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default MultiFieldArrayInput;
