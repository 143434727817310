/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import SolidTag from 'components/Tags/SolidTag';
import Text from 'components/Utils/Text';

import VipRewardsTierIcon from '../tierIcon';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { tiers, vipRewardsReadableName } from 'containers/vipRewards/consts';

import './_vipRewardsSignupSuccess.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsSignupSuccess({ closeSignupSuccessLightbox }) {
    /***** HOOKS *****/
    const { customerFrom, currentTier } = useVipRewards();

    /***** RENDER *****/
    return (
        <div className="vipRewardsSignupSuccess">
            <Text medium primary>
                <h3>You&apos;re in! Check out your reward level</h3>
            </Text>
            <VipRewardsTierIcon tier={currentTier?.lowercase} />
            <SolidTag className="vipRewardsSignupSuccess__tag" color={currentTier?.lowercase === tiers.VIP ? 'primary' : currentTier?.lowercase}>
                {currentTier?.lowercase.toUpperCase()}
            </SolidTag>
            <Text secondary size--s>
                <p>Customer since {customerFrom.readable}</p>
            </Text>
            <SolidButton type="onClick" onClick={closeSignupSuccessLightbox}>
                See my {vipRewardsReadableName} benefits
            </SolidButton>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT end
 **********************************************************************************************************/

VipRewardsSignupSuccess.propTypes = {
    /**
     * Function to close the lightbox
     */
    closeSignupSuccessLightbox: PropTypes.func
};

export default VipRewardsSignupSuccess;
