/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AdditionalInfoForm from '../additionalInfoForm';
import DateTimeForm from '../dateTimeForm';
import DNSUpdateForm from '../dnsUpdateForm';
import FormStep from '../formStep';
import StepTitle from '../stepTitle';
import TechnicalPersonForm from '../technicalPersonForm';
import CpanelAcknowledgementsForm from './cpanelAcknowledgementsForm';
import { MigrationReduxCpanelFormPart2 } from './cpanelValidate';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let CpanelSection2 = ({ handleSubmit, form }) => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <form onSubmit={handleSubmit} className="migrationRequestForm__form">
            <FormStep>
                <StepTitle>Step 2. Select a migration date and time</StepTitle>

                <DateTimeForm />
            </FormStep>

            <FormStep>
                <StepTitle>Step 3. Additional Information</StepTitle>

                <AdditionalInfoForm />
            </FormStep>

            <TechnicalPersonForm />

            <AcknowledgementBox.FlushBox>
                <AcknowledgementBox title="Acknowledgements" flush>
                    <CpanelAcknowledgementsForm />
                </AcknowledgementBox>

                <AcknowledgementBox title="DNS Update" flush>
                    <DNSUpdateForm />
                </AcknowledgementBox>
            </AcknowledgementBox.FlushBox>

            <ReduxFormButton form={form} className="MigrationRequestConfirmButton">
                Submit Migration Form
            </ReduxFormButton>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CpanelSection2 = reduxForm(MigrationReduxCpanelFormPart2)(CpanelSection2);

export default CpanelSection2;
