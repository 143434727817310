/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import SolidDropdown from 'components/Dropdowns/SolidDropdown';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXTable from 'components/NXTable';
import SortableButton from 'components/SortableButton';
import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import InvoiceStatusTag from 'containers/billing/modules/invoices/invoiceStatusTag';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { invoiceMethods } from 'containers/billing/methods';
import { buildTooltipInfo, getActionDropDownData } from '../../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { INVOICE_HEADINGS, sortableHeadings } from './consts';

/***** RENDER HELPERS *****/
export const invoicesTableContentComponents = /** @type {const} */ ({
    /**
     * Invoice ID render component
     * @param {Object} data - Invoice Data Object
     */
    [INVOICE_HEADINGS.INVOICE_ID]: (data, i, updateRefs) => {
        const { id } = data;
        return (
            <div
                className="invoicesTable__content--invoiceID"
                key={i}
                ref={(ref) => {
                    updateRefs?.(ref, i);
                }}
            >
                <Anchor to={`/billing/invoice/${id}`}>{id}</Anchor>
                <Tooltip info={buildTooltipInfo(data)} iconOverride={<PhosphorIcons.Question secondary />} />
            </div>
        );
    },
    [INVOICE_HEADINGS.STATUS]: ({ attributes: { status, is_overdue } }, i) => <InvoiceStatusTag key={i} status={status} is_overdue={is_overdue} />,
    [INVOICE_HEADINGS.TOTAL]: ({ attributes: { total } }, i) => (
        <Text secondary size--s key={i}>
            ${total} AUD
        </Text>
    ),
    [INVOICE_HEADINGS.DUE_DATE]: ({ attributes: { status, due_date } }, i) => (
        <Text secondary size--s key={i}>
            {invoiceMethods.handleDateRender(status, due_date)}
        </Text>
    ),
    [INVOICE_HEADINGS.DATE_PAID]: ({ attributes: { status, date_paid } }, i) => (
        <Text secondary size--s key={i}>
            {invoiceMethods.handleDateRender(status, date_paid)}
        </Text>
    ),
    [INVOICE_HEADINGS.ACTION]: {
        desktop: (invoiceData, i) => {
            const actionDropDownData = getActionDropDownData(invoiceData);

            if (actionDropDownData?.list) {
                return (
                    <NXTable.Actions key={`${i}SolidDropdown`}>
                        <SolidDropdown key={`${i}SolidDropdown`} size="small" {...actionDropDownData} />
                    </NXTable.Actions>
                );
            }

            return (
                <NXTable.Actions key={`${i}OutlineButton`}>
                    <OutlineButton key={`${i}OutlineButton`} size="small" onMouseOver={actionDropDownData.onMouseOver} to={actionDropDownData.to}>
                        {actionDropDownData.label}
                    </OutlineButton>
                </NXTable.Actions>
            );
        },
        mobile: (invoiceData) => {
            const actionDropDownData = getActionDropDownData(invoiceData);
            return renderMobileActions(actionDropDownData);
        }
    }
});

export function renderMobileActions(_actionDropDownData) {
    const finalActionsList = [];
    if (_actionDropDownData?.list) {
        finalActionsList.push(
            <SolidButton key="renderMobileActions_actionDropDownData?.list" to={_actionDropDownData.to}>
                {_actionDropDownData.title}
            </SolidButton>
        );

        _actionDropDownData.list
            .filter((item) => !item.disabled)
            .forEach((buttonData, i) => {
                finalActionsList.push(<SolidButton key={`_actionDropDownData.list.forEach${i}`} {...buttonData} />);
            });

        return finalActionsList;
    }

    finalActionsList.push(
        <OutlineButton key="finalActionsList.push(" to={_actionDropDownData.to}>
            {_actionDropDownData.label}
        </OutlineButton>
    );

    return finalActionsList;
}

export function renderHeader(headings) {
    return (
        <NXTable.Header>
            <NXTable.SortableButtonWrapper>
                {headings.map(([sortKey, content]) => {
                    if (sortableHeadings.includes(sortKey)) {
                        return (
                            <SortableButton key={sortKey} sortKey={sortKey}>
                                {content}
                            </SortableButton>
                        );
                    }
                    return <NXTable.Header.Title key={sortKey}>{content}</NXTable.Header.Title>;
                })}
            </NXTable.SortableButtonWrapper>
        </NXTable.Header>
    );
}
