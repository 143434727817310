/**
 *
 * Format a string to fit specific requirements for example 'XX XXX XXX XXX' for an ABN
 * @param {string} inputString
 * @param {string} format
 * @returns {string}
 */
function formatString(inputString, format) {
    if (typeof inputString !== 'string') {
        return inputString;
    }
    // Remove non-alphanumeric characters and split the format string into parts
    const formatParts = format.split(' ').filter((part) => /\w/.test(part));

    // Remove non-alphanumeric characters from the input string
    const alphanumericInput = inputString.replace(/\W/g, '');

    // Initialize the result as an empty string
    let result = '';

    let inputIndex = 0;

    // Iterate through the format parts
    for (const formatPart of formatParts) {
        // Take as many characters from the input string as the format part requires
        const partLength = formatPart.length;
        const formattedPart = alphanumericInput.substr(inputIndex, partLength);

        // Append the formatted part to the result
        result += formattedPart;

        // Move the input index to the next part
        inputIndex += partLength;

        // Add a space if it's not the last format part
        if (inputIndex < alphanumericInput.length - 1) {
            result += ' ';
        }
    }

    return result;
}

export { formatString };
