/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { createContext, useContext } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import _Header from './_Header';
import _Row from './_Row';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_AlternativePricingTable.scss';

const AlternativePricingTableContext = createContext(/** @type { color: 'black' } */ ({ color: 'black' }));

const useAlternativePricingTable = () => useContext(AlternativePricingTableContext);

/**
 * @param {{
 *   className: string,
 *   color: 'black',
 *   children: React.ReactNode
 * }} props
 */
const AlternativePricingTable = ({ className, children, color = 'black' }) => {
    const classes = classNames('alternativePriceTable', `alternativePriceTable--${color}`, className);
    const contextValues = { color };

    return (
        <AlternativePricingTableContext.Provider value={contextValues}>
            <div className={classes}>{children}</div>
        </AlternativePricingTableContext.Provider>
    );
};

AlternativePricingTable.Header = _Header;
AlternativePricingTable.Row = _Row;

export { AlternativePricingTable as default, useAlternativePricingTable };
