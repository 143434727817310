/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { whois_lookup_url } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { disableIdProtection, enableIdProtection, getIdProtection } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Protect extends Component {
    constructor(props) {
        super(props);
        this.handleProtect = this.handleProtect.bind(this);

        this.state = {
            protectionStatus: false
        };
    }

    handleProtect(values) {
        const { domainid, disableIdProtection, enableIdProtection } = this.props;

        if (values) {
            enableIdProtection(domainid);
        } else {
            disableIdProtection(domainid);
        }
    }

    componentDidUpdate(prevProps) {
        const { domain_protection_check_status, domain_protection_check_data } = this.props;
        registerScrollEvents(this, domain_protection_check_status === 'success' && prevProps.domain_protection_check_status === 'loading');

        if (domain_protection_check_status === 'success' && prevProps.domain_protection_check_status === 'loading') {
            const { attributes } = domain_protection_check_data;
            const { id_protection } = attributes;

            this.setState({
                protectionStatus: id_protection
            });
        }
    }

    render() {
        const { domainid, disabled, getIdProtection, domain_protection_status, domain_protection_check_status, domain_information_data } = this.props;
        const { protectionStatus } = this.state;
        const { handleProtect } = this;

        /*   HANDLE LOADING STATUS
         **********************************************************************************************************/
        const handleLoadingStatus = () => {
            if (domain_protection_check_status === 'loading' || domain_protection_status === 'loading') return 'loading';
            else if (domain_protection_check_status === 'error' || domain_protection_status === 'error') return 'error';
            return 'success';
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {
            recommended: true,
            action: {
                label: 'Enable ID Protection',
                type: 'onClick',
                className: '',
                color: '',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    handleProtect(true);
                }
            }
        };

        if (protectionStatus === 'Enabled') {
            conditionalProps = {
                bottom: true,
                columns: [
                    {
                        render: (
                            <div className="sharedBox__infoColumn hasIcon">
                                <i className="infoColumn__icon icon icon-tick-solid confirm"></i>
                                <div className="infoColumn__wrapper">
                                    <div className="title">ID Protection is enabled</div>
                                    <div className="desc">Your details will be hidden from any public domain lookup</div>
                                </div>
                            </div>
                        )
                    }
                ],
                action: {
                    label: 'Disable',
                    type: 'onClick',
                    className: '',
                    color: 'warn',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        handleProtect(false);
                    }
                }
            };
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="idProtection"
            >
                <Box
                    request={{
                        action: getIdProtection,
                        args: domainid,
                        status: domain_protection_check_status
                    }}
                    disabled={disabled}
                    className="idProtection__box"
                    status={handleLoadingStatus()}
                    title="ID Protection"
                    desc="ID Protection hides your personal details and replaces them with generic data, keeping your sensitive information hidden from the outside world."
                    footer={
                        <div>
                            <Anchor
                                className="footer__link"
                                href={`${whois_lookup_url}${domain_information_data?.attributes?.domain || ''}`}
                                target="_blank"
                            >
                                What does someone see when they look up my domain name?
                            </Anchor>
                        </div>
                    }
                    info="When you register a domain name, as per regulatory requirements, your details are added to the WHOIS database. By enabling ID Protection, we mask these details which helps to protect your identity and prevent scammers from being able to access your data."
                    {...conditionalProps}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            domain_protection_status: state.domain.domain_protection_status,
            domain_protection_check_status: state.domain.domain_protection_check_status,
            domain_protection_check_data: state.domain.domain_protection_check_data,
            domain_information_data: state.domain.domain_information_data
        }),
        {
            enableIdProtection,
            disableIdProtection,
            getIdProtection
        }
    )(Protect)
);
