/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderField, renderButton, RenderSelectField } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DNSSECForm extends Component {
    render() {
        const { pristine, submitting, valid, handleSubmit, dnsSecOptions } = this.props;
        const { algorithms, digestTypes } = dnsSecOptions;

        const algorithms_dropdown = [];
        const digestTypes_dropdown = [];

        if (algorithms) {
            const { attributes } = algorithms;
            Object.keys(attributes).forEach(function (key) {
                algorithms_dropdown.push({
                    label: '[' + key + '] ' + attributes[key],
                    value: key
                });
            });
        }

        if (digestTypes) {
            const { attributes } = digestTypes;
            Object.keys(attributes).forEach(function (key) {
                digestTypes_dropdown.push({
                    label: '[' + key + '] ' + attributes[key],
                    value: key
                });
            });
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="dnssec__form" onSubmit={handleSubmit}>
                <div className="form__row">
                    <div className="form__column full">
                        <Field label="Key Tag" name="keyTag" component={RenderField} type="text" placeholder="" className="form__textfield" />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Algorithm"
                            name="algorithm"
                            component={RenderSelectField}
                            type="select"
                            placeholder=""
                            className="form__dropdown"
                            options={algorithms_dropdown}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Digest Type"
                            name="digestType"
                            component={RenderSelectField}
                            type="select"
                            placeholder=""
                            className="form__dropdown"
                            options={digestTypes_dropdown}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field label="Digest" name="digest" component={RenderField} type="text" placeholder="" className="form__textfield" />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">{renderButton(pristine, submitting, valid)}</div>
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DNSSECForm = reduxForm({
    form: 'dnssecForm',
    enableReinitialize: true
})(DNSSECForm);

const mapStateToProps = (state) => {
    return {
        form: 'dnssecForm',
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

DNSSECForm = connect(mapStateToProps)(DNSSECForm);

export default withRouter(DNSSECForm);
