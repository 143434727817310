/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let QueueJumpFormLabel = (props) => {
    const { support_migration_migration_dates_data } = props;

    const urgent_cost = support_migration_migration_dates_data?.attributes?.urgent_cost || '9.95';

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <>
            <div className="DynamicForm__urgentTooltip">
                You have an outstanding queue jump invoice. Please pay the invoice to push your request to the front of the queue.
            </div>
            <div className="checkboxLabelTop">
                Queue Jump<small className="checkboxLabelPrice">${urgent_cost}</small>
            </div>
            <div className="checkboxLabelBottom">
                Place your request at the top of the migration queue. With a queue jump, we prioritise your migration to be looked at sooner.
            </div>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    support_migration_migration_dates_data: state.support.support_migration_migration_dates_data
});

QueueJumpFormLabel = connect(mapStateToProps, null)(QueueJumpFormLabel);

export default QueueJumpFormLabel;
