/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TruthyProp } from 'utilities/consts';
import './__NXCardActions.scss';

const NXCardActionsAppliedStylingPropTypes = {
    /**
     * Applies flex direction column
     */
    'column': TruthyProp,

    /**
     * Applies align-items flex-start
     */
    'align--start': TruthyProp,

    /**
     * Justify content center
     */
    'center': TruthyProp,

    /**
     * Sets default gap for content
     */
    'gap': TruthyProp,

    'fullWidth': TruthyProp
};
const NXCardActionsAppliedStylingPropTypeKeys = Object.keys(NXCardActionsAppliedStylingPropTypes);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      children: React.ReactNode | React.ReactElement;
 *      className?: string;
 *      onClick?: () => void;
 *      fullWidth?: boolean;
 *      column?: boolean;
 *      "align--start"?: boolean;
 *      center?: boolean;
 *      gap?: boolean;
 * }} props
 */
function _NXCardActions(props) {
    const { children, className, onClick } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: NXCardActionsAppliedStylingPropTypeKeys,
        componentName: 'NXCardActions',
        delimiter: '--'
    });

    const nxCardActionsClassNames = classNames('NXCardActions', appliedStylingClasses, className);

    /***** RENDER *****/
    if (onClick)
        return (
            <button type="button" onClick={onClick} className={nxCardActionsClassNames}>
                {children}
            </button>
        );
    return <div className={nxCardActionsClassNames}>{children}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXCardActions };
