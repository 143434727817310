/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ExtraFunctions } from 'components/Form/NXForm/_NXForm/ExtraFunctions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component serves as a customizable wrapper around a Redux form, providing additional functionalities and handling form initialization based on certain conditions.
 * It also provides a context that can be used to access the form's props from any child component.
 * @type {React.FC<NNXForm.Props>}
 */
const _NXForm = ({
    initialValues: initialValuesProps,
    onSubmit,
    children,
    form,
    className,
    initialiseOnMount,
    enableReinitialize,
    keepDirtyOnReinitialize,
    destroyOnUnmount
}) => {
    const [_initialValues, _setInitialValues] = useState(initialValuesProps);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!isEqual(_initialValues, initialValuesProps)) {
            _setInitialValues(initialValuesProps);
        }
    }, [_initialValues, initialValuesProps]);

    const Result = useMemo(
        () =>
            reduxForm({
                form,
                initialValues: _initialValues,
                onSubmit,
                enableReinitialize,
                keepDirtyOnReinitialize,
                destroyOnUnmount
            })(ExtraFunctions),
        [_initialValues, form, onSubmit, enableReinitialize, keepDirtyOnReinitialize]
    );
    /***** RENDER *****/
    return (
        <Result initialiseOnMount={initialiseOnMount} className={className}>
            {(/** @type {NNXForm.ChildrenProps} */ resultProps) => children(resultProps)}
        </Result>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXForm };
