/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_addOrRemoveButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Button to add or remove a nameserver record to the form
 */
function NameserversAddOrRemoveButton({ action, onClick }) {
    /***** RENDER *****/
    return (
        <button
            type="button"
            className={`nameserversAddOrRemoveButton nameserversAddOrRemoveButton--${action === 'add' ? 'add' : 'remove'}`}
            onClick={onClick}
        >
            <i className={`icon icon-${action === 'add' ? 'plus-faq' : 'x'}`} />
        </button>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NameserversAddOrRemoveButton.propTypes = {
    /**
     * The action type of the button (add or remove)
     */
    action: PropTypes.oneOf(['add', 'remove']),

    /**
     * Function to add or remove a form field (record) to the list
     */
    onClick: PropTypes.func
};

export default NameserversAddOrRemoveButton;
