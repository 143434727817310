/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { cloneDeep } from 'lodash';

/**
 * @param {string} member
 * @param {Array<Katana.SectionDefinitions.PropertiesModified>} properties
 * @param {boolean} [isFieldArrayMember]
 */
export function modifyPropertyKeys(member, properties, isFieldArrayMember) {
    if (!properties) {
        return [];
    }
    const clonedProperties = cloneDeep(properties);
    return clonedProperties.map((clonedProperty) => {
        const key = isFieldArrayMember ? clonedProperty.originalKey ?? clonedProperty.key : clonedProperty.key;
        const newMember = clonedProperty.key ? `${member}.${key}` : null;

        if (clonedProperty.properties) {
            clonedProperty.properties = modifyPropertyKeys(newMember, clonedProperty.properties, isFieldArrayMember);
        }

        return {
            ...clonedProperty,
            key: newMember,
            originalKey: clonedProperty?.originalKey ?? clonedProperty.key
        };
    });
}
