/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import { formatLuxonOrdinal, toLuxonDate } from 'utilities/methods/commonActions';

/*   ACTIONS
 *****************************************************/
import { getGSuiteSeatsPricing, purchaseGSuiteSeats } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { gsuiteBillingTermsFaqLink } from 'containers/gsuite/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let GSuiteManageSeatsForm = (props) => {
    const {
        gsuiteid,
        /** Redux Props */
        getGSuiteSeatsPricing,
        gsuite_service_data,
        purchaseGSuiteSeats,
        gsuite_seat_pricing_status,
        gsuite_seat_pricing_data
    } = props;

    /***** STATE *****/
    const [seats, setSeats] = useState({
        current: null,
        newCurrent: null,
        cost: null,
        newCost: null,
        totalDueToday: null,
        calculatedFor: null
    });

    /***** HOOKS *****/
    const calculatorRef = createRef();
    const isCommitmentPlan = Boolean(gsuite_service_data?.attributes?.is_commitment_plan);

    /***** FUNCTIONS *****/
    /************** SEAT MANAGEMENT **************/
    function addSeat() {
        const newVal = seats.newCurrent + 1;
        setSeats({
            ...seats,
            newCurrent: newVal
        });
        calculatorRef.current.value = newVal;
        getSeatPricing(newVal);
    }

    function removeSeat() {
        if (
            // Customers cannot remove seats if they are on an annual commitment plan
            (gsuite_service_data?.attributes?.is_commitment_plan && seats.newCurrent <= seats.current) ||
            seats.newCurrent <= (gsuite_service_data.attributes.number_of_users ?? 1)
        )
            return;

        const newVal = seats.newCurrent - 1;

        setSeats({
            ...seats,
            newCurrent: newVal
        });
        calculatorRef.current.value = newVal;
        getSeatPricing(newVal);
    }

    function onChange(e) {
        e.preventDefault();
        calculatorRef.current.value = e.currentTarget.value;
        const val = parseInt(e.currentTarget.value) > 300 ? seats.newCurrent : parseInt(e.currentTarget.value);

        if (val && !isNaN(val)) {
            if (val <= seats.current) {
                setSeats({
                    ...seats,
                    current: seats.current,
                    newCurrent: seats.current
                });
                calculatorRef.current.value = seats.current;
                getSeatPricing(seats.current);
            } else {
                setSeats({
                    ...seats,
                    newCurrent: val
                });
                getSeatPricing(val);
            }
        } else {
            setSeats({
                ...seats,
                current: seats.current,
                newCurrent: seats.current
            });
            calculatorRef.current.value = seats.current;
        }
    }

    /**
     * @param {number} numberOfNewSeats
     */
    function getSeatPricing(numberOfNewSeats) {
        getGSuiteSeatsPricing(gsuite_service_data?.id, numberOfNewSeats);
    }

    /************** SUBMIT HANDLING **************/
    function handleSeatPurchase() {
        purchaseGSuiteSeats(gsuiteid, seats.newCurrent);
    }

    function getBillingCycleNaming() {
        if (gsuite_service_data?.attributes?.billing_cycle === 'Annually') return 'Annual';
        if (gsuite_service_data?.attributes?.billing_cycle === 'Monthly') return 'Monthly';
        return '';
    }

    function renderUpdateSeatsButton() {
        if (gsuite_seat_pricing_status === 'loading' || (seats && seats.current === seats.newCurrent && seats.calculatedFor !== seats.newCurrent)) {
            return (
                <InactiveButton className="gsuiteManageSeats__form--action">
                    {gsuite_seat_pricing_status === 'loading' ? <RequestLoader /> : 'Update Seats'}
                </InactiveButton>
            );
        }
        return (
            <SolidButton
                className="gsuiteManageSeats__form--action"
                type="onClick"
                onClick={(e) => {
                    e.preventDefault();
                    handleSeatPurchase();
                }}
            >
                Update Seats
            </SolidButton>
        );
    }

    const billingCycleNaming = getBillingCycleNaming();

    /***** EFFECTS *****/
    useEffect(() => {
        if (!gsuite_service_data) return;

        const { number_of_seats, amount } = gsuite_service_data.attributes;

        setSeats({
            current: number_of_seats,
            newCurrent: number_of_seats,
            cost: amount,
            newCost: amount,
            totalDueToday: null,
            calculatedFor: number_of_seats
        });
        calculatorRef.current.value = number_of_seats;
    }, []);

    useEffectAfterMount(() => {
        if (gsuite_seat_pricing_status === 'success') {
            const { attributes } = gsuite_seat_pricing_data;
            if (!seats.cost) {
                setSeats({
                    ...seats,
                    cost: attributes.new_plan_cost,
                    totalDueToday: attributes.amount_due,
                    calculatedFor: seats.newCurrent
                });
                calculatorRef.current.value = seats.newCurrent;
            } else {
                setSeats({
                    ...seats,
                    newCost: attributes.new_plan_cost,
                    totalDueToday: attributes.amount_due,
                    calculatedFor: seats.newCurrent
                });
                calculatorRef.current.value = seats.newCurrent;
            }
        }
    }, [gsuite_seat_pricing_status]);

    /***** RENDER HELPERS *****/
    const renderRemoveSeatButton = () => (
        <button
            type="submit"
            onClick={removeSeat}
            // Customers cannot remove seats if they are on an annual commitment plan
            className={classNames('calculator__button', {
                'calculator__button--lock':
                    (isCommitmentPlan && seats.newCurrent <= seats.current) ||
                    seats.newCurrent <= (gsuite_service_data.attributes.number_of_users ?? 1)
            })}
        >
            <i className="icon icon-minus" />
        </button>
    );

    /***** RENDER *****/
    if (!gsuite_service_data) return <RequestLoader />;

    return (
        <div className="gsuiteManageSeats__form">
            <div className="gsuiteManageSeats__form--container">
                <div className="gsuiteManageSeats__form--addRemove">
                    <div className="addRemove">
                        <div className="addRemove__label">
                            Add or remove seats to <span>{gsuite_service_data.attributes.domain}</span>
                        </div>
                        <div className="addRemove__form">
                            {/* Customers cannot remove seats if they are on an annual commitment plan */}
                            {isCommitmentPlan && seats.newCurrent <= seats.current ? (
                                <HoverTooltip
                                    alignment="left"
                                    content={
                                        <>
                                            Cannot remove seats from this service. Contact our{' '}
                                            <Anchor to="/support/tickets/submit/accounts-billing">Billing Team</Anchor> for further information or
                                            head to our{' '}
                                            <Anchor href={gsuiteBillingTermsFaqLink} target="_blank">
                                                Google Workspace FAQ
                                            </Anchor>
                                            .
                                        </>
                                    }
                                >
                                    {renderRemoveSeatButton()}
                                </HoverTooltip>
                            ) : (
                                renderRemoveSeatButton()
                            )}
                            <input onKeyUp={onChange} ref={calculatorRef} type="text" className="calculator__field" />
                            <button onClick={addSeat} className={`calculator__button${seats.newCurrent >= 300 ? ' calculator__button--lock' : ''}`}>
                                <i className="icon icon-plus-faq" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="gsuiteManageSeats__form--summary">
                    <div className="summary">
                        <div className="title">Current Seats</div>
                        <div className="value">{seats.current}</div>
                    </div>
                    <div className="summary">
                        <div className="title">
                            <span>NEW</span> Total Seats
                        </div>
                        <div className="value highlight">{seats.newCurrent}</div>
                    </div>
                    <div className="summary">
                        <div className="title">Current {billingCycleNaming} Cost</div>
                        <div className="value">${seats.cost} AUD</div>
                    </div>
                    <div className="summary">
                        <div className="title">
                            <span>NEW</span> {billingCycleNaming} Cost
                        </div>
                        <div className="value highlight">
                            {gsuite_seat_pricing_status === 'loading' ? (
                                <RequestLoader height={14} noPadding />
                            ) : (
                                `$${seats.newCost ? seats.newCost : '0.00'} AUD`
                            )}
                        </div>
                    </div>

                    {gsuite_seat_pricing_status === 'loading' || !seats.totalDueToday || seats.totalDueToday === '0.00' ? (
                        ''
                    ) : (
                        <div className="summary">
                            <div className="title">Total Due Today</div>
                            <div className="value highlight">${seats.totalDueToday} AUD</div>
                        </div>
                    )}

                    <div className="summary--subtitle">
                        {gsuite_service_data.next_due_date ? (
                            <div className="summary--subtitle">
                                (Your next billing date is{' '}
                                {formatLuxonOrdinal(toLuxonDate(gsuite_service_data.next_due_date).toFormat('EEEE, MMM {d} yyyy'))})
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="summary--divider" />

                    {isCommitmentPlan && (
                        <DialogNotification tail={{ pos: 'bottom' }} type="warning">
                            PLEASE NOTE: Seats cannot be removed until the end of your billing cycle. Please contact our{' '}
                            <Anchor href="https://vip.ventraip.com.au/support/tickets/submit/accounts-billing">Billing Team</Anchor> for further
                            information.
                        </DialogNotification>
                    )}

                    <div className="summary">{renderUpdateSeatsButton()}</div>
                </div>
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    gsuite_service_data: state.gsuite.gsuite_service_data,
    gsuite_seat_pricing_status: state.gsuite.gsuite_seat_pricing_status,
    gsuite_seat_pricing_data: state.gsuite.gsuite_seat_pricing_data
});

const mapDispatchToProps = {
    purchaseGSuiteSeats,
    getGSuiteSeatsPricing
};

GSuiteManageSeatsForm = connect(mapStateToProps, mapDispatchToProps)(GSuiteManageSeatsForm);

export default GSuiteManageSeatsForm;
