/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { get as hookFormGet, useFormContext } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInnerHookForm } from 'components/Form/FormItemInner/hookForm';
import { FormLabel } from 'components/Form/FormLabel';
import { _Input as Input } from 'components/Form/Input/_Input';
import type { NNXInput } from 'components/Form/Input/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type InputHookFormProps = NNXInput.Props & {
    validate?: (value: string) => string | undefined;
    label: React.ReactNode;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _InputHookFormDefault: React.FC<InputHookFormProps> = ({
    label,
    disabled,
    placeholder,
    isPlaceholderItalic,
    name: fieldName,
    validate,
    intrinsicProps,
    size
}) => {
    /***** HOOKS *****/
    const { register, formState } = useFormContext();

    /***** RENDER HELPERS *****/
    const { name, onChange, onBlur, ref } = register(fieldName, { validate });

    const meta = {
        touched: hookFormGet(formState.touchedFields, name),
        error: hookFormGet(formState.errors, name)?.message
    };

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name} size={size}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInnerHookForm meta={meta}>
                <Input
                    disabled={disabled}
                    name={name}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    intrinsicProps={{ ...intrinsicProps, onChange, onBlur, ref }}
                />
            </FormItemInnerHookForm>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _InputHookFormDefault };
