/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CancelAddonForm from './form/cancelAddonForm';

/*   ACTIONS
 *****************************************************/
import { removeActiveAddon } from 'containers/hosting/state/baseActions';
import { removeVpsSoftwareAddon } from 'containers/vps/action';
import { removeDiskBlock } from 'containers/email/action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class CancelAddon extends Component {
    constructor(props) {
        super(props);

        this.submitRemoveAddon = this.submitRemoveAddon.bind(this);
    }

    submitRemoveAddon(values) {
        const { type, addonId, removeActiveAddon, removeVpsSoftwareAddon, removeDiskBlock } = this.props;

        let cancelAddon;

        switch (type) {
            case 'email':
                cancelAddon = removeDiskBlock;
                break;
            case 'vps':
                cancelAddon = removeVpsSoftwareAddon;
                break;
            case 'hosting':
            default:
                cancelAddon = removeActiveAddon;
                break;
        }

        const attributes = {
            ...values,
            type: 'immediate',
            refund_method: 'none',
            additional_services: []
        };

        cancelAddon(addonId, attributes);
    }

    render() {
        const { type, onClose, confirmationFields, hosting_addon_remove_status, vps_addon_cancel_status, email_diskblock_status } = this.props;
        const { submitRemoveAddon } = this;

        const handleLoading = () => {
            switch (type) {
                case 'email':
                    return email_diskblock_status;
                case 'vps':
                    return vps_addon_cancel_status;
                case 'hosting':
                default:
                    return hosting_addon_remove_status;
            }
        };

        return <CancelAddonForm loading={handleLoading()} confirmationFields={confirmationFields} onSubmit={submitRemoveAddon} onClose={onClose} />;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default connect(
    (state) => ({
        hosting_addon_remove_status: state.hosting.hosting_addon_remove_status,
        vps_addon_cancel_status: state.vps.vps_addon_cancel_status,
        email_diskblock_status: state.email.email_diskblock_status
    }),
    {
        removeActiveAddon,
        removeVpsSoftwareAddon,
        removeDiskBlock
    }
)(CancelAddon);
