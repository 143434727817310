/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, change, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import Table from 'components/Table';
import { RenderField, requiredFieldValidation } from 'utilities/methods/form';

import { ConfirmationLightbox } from 'containers/vps';
import { cancelReverseDNS } from 'utilities/api/vps';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getReverseDNS, updateReverseDNS } from '../action';

function getHostnameValue(hostname) {
    if (!hostname) {
        return '';
    }

    const lcHostname = hostname.toLowerCase();

    return lcHostname === 'unassigned' ? '' : lcHostname;
}

class ReverseDNSLightbox extends Component {
    constructor(props) {
        super(props);
        this.RenderFields = this.RenderFields.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { updateReverseDNSStatus, closeLightbox } = this.props;

        if (updateReverseDNSStatus !== prevProps.updateReverseDNSStatus && updateReverseDNSStatus === 'success') {
            closeLightbox();
        }
    }

    componentDidMount() {
        const { selectedIP, dispatch, form } = this.props;

        if (!selectedIP) {
            return;
        }

        const { attributes } = selectedIP;
        const { ip, hostname } = attributes;

        const hostname_value = getHostnameValue(hostname);
        dispatch(change(form, `ip`, ip));
        dispatch(change(form, `hostname`, hostname_value));
    }

    RenderFields() {
        const { selectedIP } = this.props;
        const { attributes } = selectedIP;
        const { ip } = attributes;

        if (selectedIP) {
            return (
                <div className="form__row">
                    <div className="form__column form__column--full VPS-Reverse-DNS-Lightbox__row">
                        <Field
                            name="ip"
                            component={RenderField}
                            type="text"
                            className="form__textfield"
                            label="IP Address"
                            readOnly={true}
                            placeholder={ip}
                            tabIndex={-1}
                        />
                    </div>
                    <div className="form__column form__column--full VPS-Reverse-DNS-Lightbox__row">
                        <Field
                            name="hostname"
                            component={RenderField}
                            type="text"
                            validate={requiredFieldValidation}
                            className="form__textfield"
                            label="Hostname"
                        />
                    </div>
                </div>
            );
        }
    }

    render() {
        const { handleSubmit, updateReverseDNSStatus } = this.props;
        const { RenderFields } = this;

        return (
            <div
                className={`VPS-Reverse-DNS-Lightbox VPS-Lightbox-Main${
                    updateReverseDNSStatus === 'loading' ? ' VPS-Reverse-DNS-Lightbox--loading' : ''
                }`}
            >
                <div className="VPS-Reverse-DNS-Lightbox__wrapper">
                    <div className="heading">Edit/Add Reverse DNS</div>
                    <form onSubmit={handleSubmit} className="form">
                        {RenderFields()}
                        <div className="form__row">
                            <div className="form__column form__column--full">
                                <SolidButton type="submit">Update</SolidButton>
                            </div>
                        </div>
                    </form>
                </div>
                {updateReverseDNSStatus === 'loading' && (
                    <div className="VPS-Reverse-DNS-Lightbox__RequestLoader-wrapper">
                        <RequestLoader />
                    </div>
                )}
            </div>
        );
    }
}

ReverseDNSLightbox = reduxForm({
    form: 'ReverseDNSLightboxForm'
})(ReverseDNSLightbox);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ReverseDNS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reverseDNSLightboxMode: false,
            selectedIP: undefined,
            confirmDeleteLightboxMode: undefined
        };
        this.closeReverseDNSLightboxMode = this.closeReverseDNSLightboxMode.bind(this);
        this.openReverseDNSLightboxMode = this.openReverseDNSLightboxMode.bind(this);
        this.handleSubmitReverseDNS = this.handleSubmitReverseDNS.bind(this);
        this.renderTableMatrix = this.renderTableMatrix.bind(this);
        this.renderBoxContent = this.renderBoxContent.bind(this);
        this.openConfirmDeleteLightbox = this.openConfirmDeleteLightbox.bind(this);
        this.closeConfirmDeleteLightbox = this.closeConfirmDeleteLightbox.bind(this);
        this.unsetHostname = this.unsetHostname.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { vps_reverse_dns_status } = this.props;

        registerScrollEvents(this, vps_reverse_dns_status === 'success' && prevProps.vps_reverse_dns_status === 'loading');
    }

    componentWillUnmount() {
        if (cancelReverseDNS) {
            cancelReverseDNS();
        }
    }

    unsetHostname() {
        const { vps_information_data, updateReverseDNS } = this.props;
        const { id } = vps_information_data;
        const { selectedIP } = this.state;
        const { ip } = selectedIP.attributes;
        const attributes = {
            ip,
            hostname: 'unassigned'
        };

        updateReverseDNS(id, attributes);
    }

    closeReverseDNSLightboxMode() {
        this.setState({
            reverseDNSLightboxMode: false
        });
    }

    openReverseDNSLightboxMode(selectedIP) {
        this.setState({
            reverseDNSLightboxMode: true,
            selectedIP
        });
    }

    openConfirmDeleteLightbox(selectedIP) {
        this.setState({
            confirmDeleteLightboxMode: true,
            selectedIP
        });
    }

    closeConfirmDeleteLightbox() {
        this.setState({
            confirmDeleteLightboxMode: false
        });
    }

    handleSubmitReverseDNS(values) {
        const { vps_information_data, updateReverseDNS } = this.props;
        const { id } = vps_information_data;
        const { ip, hostname } = values;
        const attributes = {
            ip,
            hostname: hostname.toLowerCase()
        };

        updateReverseDNS(id, attributes);
    }

    renderBoxContent() {
        const { renderTableMatrix } = this;
        const { vps_reverse_dns_status } = this.props;

        return (
            <div className="VPS-Reverse-DNS__content">
                <div className="box__heading  VPS-Reverse-DNS__heading">Reverse DNS</div>
                <div className="box__description VPS-Reverse-DNS__description">
                    Reverse DNS is the method of resolving an IP address into a domain name. Reverse DNS lookups are conducted by mail servers to make
                    sure that the other mail server they are dealing with is who they say they are.
                </div>
                <Table
                    className="VPS-Reverse-DNS__table"
                    header={[
                        {
                            title: `IP Address`,
                            className: `ipaddress`
                        },
                        {
                            title: `Hostname`,
                            className: `hostname`
                        },
                        {
                            action: true
                        }
                    ]}
                    loading={vps_reverse_dns_status}
                    matrix={renderTableMatrix()}
                    error="You have no IPs!"
                    stacked={true}
                    dynamicActions={true}
                />
            </div>
        );
    }

    renderTableMatrix() {
        const { vps_reverse_dns_data } = this.props;
        const { openReverseDNSLightboxMode, openConfirmDeleteLightbox } = this;

        if (vps_reverse_dns_data && vps_reverse_dns_data.length > 0) {
            const matrix = Array(vps_reverse_dns_data.length).fill();
            vps_reverse_dns_data.forEach((item, index) => {
                const { attributes } = item;
                const { ip, hostname } = attributes;
                const renderAction = () => {
                    const list = [];

                    if (hostname !== 'unassigned' && hostname && hostname !== '') {
                        list.push({
                            label: 'Delete',
                            type: 'onClick',
                            className: 'VPS-Reverse-DNS__table-action VPS-Reverse-DNS__table-action--delete',
                            onClick: (e) => {
                                e.preventDefault();
                                openConfirmDeleteLightbox(item);
                            }
                        });
                    }
                    return [
                        {
                            label: 'Edit',
                            className: 'VPS-Reverse-DNS__table-dropdown',
                            size: 'medium',
                            color: 'notice',
                            type: 'onClick',
                            list: list,
                            onClick: (e) => {
                                e.preventDefault();
                                openReverseDNSLightboxMode(item);
                            }
                        }
                    ];
                };

                if (attributes.message) {
                    matrix[index] = {
                        ip_address: ip,
                        hostname: <span className="italic">{attributes.message}</span>,
                        actions: [
                            {
                                label: 'Contact Us',
                                className: 'VPS-Reverse-DNS__table-dropdown',
                                size: 'medium',
                                color: 'notice',
                                type: 'onClick',
                                onClick: (e) => {
                                    e.preventDefault();
                                    const { history } = this.props;
                                    history.push('/support/tickets/submit/technical-support');
                                }
                            }
                        ]
                    };
                } else {
                    matrix[index] = {
                        ip_address: ip,
                        hostname: getHostnameValue(hostname),
                        actions: renderAction()
                    };
                }
            });
            return matrix;
        } else {
            return [];
        }
    }

    render() {
        const { reverseDNSLightboxMode, selectedIP, confirmDeleteLightboxMode } = this.state;
        const { closeReverseDNSLightboxMode, closeConfirmDeleteLightbox, handleSubmitReverseDNS, renderBoxContent, unsetHostname } = this;
        const { vps_reverse_dns_status, getReverseDNS, vps_information_data } = this.props;
        const { id } = vps_information_data;

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
            >
                <Box
                    request={{
                        action: getReverseDNS,
                        args: id,
                        status: vps_reverse_dns_status
                    }}
                    className="VPS-Reverse-DNS"
                    custom={{
                        pos: 'top',
                        render: renderBoxContent()
                    }}
                    status={vps_reverse_dns_status}
                />
                {reverseDNSLightboxMode && (
                    <OverlayLightbox className="VPS-Lightbox" onClose={closeReverseDNSLightboxMode} onOpen={reverseDNSLightboxMode}>
                        <ReverseDNSLightbox
                            selectedIP={selectedIP}
                            updateReverseDNSStatus={vps_reverse_dns_status}
                            onSubmit={handleSubmitReverseDNS}
                            className="VPS-Reverse-DNS-Lightbox VPS-Lightbox-Main"
                        />
                    </OverlayLightbox>
                )}
                {confirmDeleteLightboxMode && (
                    <OverlayLightbox
                        className="VPS-Lightbox"
                        title="Unset IP Address"
                        onClose={closeConfirmDeleteLightbox}
                        onOpen={confirmDeleteLightboxMode}
                    >
                        <ConfirmationLightbox description="Please confirm you wish to unset this IP" actionlabel="Confirm" action={unsetHostname} />
                    </OverlayLightbox>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        vps_information_data: state.vps.vps_information_data,
        vps_reverse_dns_status: state.vps.vps_reverse_dns_status,
        vps_reverse_dns_data: state.vps.vps_reverse_dns_data,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getReverseDNS,
            updateReverseDNS
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReverseDNS));
