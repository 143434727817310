/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**
 * @param {KatanaSitePreviewParams} data
 */
function createQueryKey({ serviceID, attributes }) {
    const mainKey = /** @type {const} */ (['katana', 'service', serviceID, 'preview', 'site']);
    const attributesKey = attributes ? [attributes] : [];
    return createBaseQueryKey([...mainKey, ...attributesKey]);
}

/**
 * @param {KatanaSitePreviewParams} data
 * @param {boolean} [enabled]
 */
function createQueryOptions({ serviceID, attributes }, enabled = true) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, attributes }),
        queryFn: ({ signal }) => KATANA_API.katana.service_id.preview.site.POST({ serviceID, attributes }, signal),
        staleTime: Infinity,
        enabled: Boolean(serviceID) && enabled,
        select: (data) => {
            if (typeof data === 'string') {
                return data;
            }
        }
    });
}

/**
 * @param {KatanaSitePreviewParams} data
 */
function prefetchQuery(data) {
    return queryClient.prefetchQuery(createQueryOptions(data));
}

// TODO: invalidate this query when any of the service data has changed
/**
 * @param {number} serviceID
 */
function invalidateQueries(serviceID) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey({ serviceID }) });
}
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {KatanaSitePreviewParams} data
 * @param {boolean} [enabled]
 */
function _useQuery(data, enabled) {
    return useQuery(createQueryOptions(data, enabled));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSitePreview = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions
});
