/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import SimpleTable from 'components/SimpleTable';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InfoTableSpan, InfoTableSubHeading } from 'containers/katana/components';
import KatanaModuleCTA from 'containers/katana/components/moduleCTA';
import { KatanaSectionSettingsModule } from 'containers/katana/components/sectionSettingsModule';
import { RenderLogoOrBrandFont } from 'containers/katana/modules/information/renderBrand/renderLogoOrBrandFont';
import { WebpageStatusContent } from 'containers/katana/modules/information/webpageStatusContent/webpageStatusContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { isChecklistItemEnabled } from 'containers/katana/modules/launchChecklist/methods';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStage, katanaModuleImages, katanaServicePages, katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';
import './_KatanaInformationModule.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { PublishedWithIssues } from 'containers/katana/modules/information/publishedWithIssues';

const { BASIC_DETAILS, CONTACT_INFORMATION } = katanaSubPageEnums;
const { SETUP, EDITOR } = katanaServicePages;

const SimpleTableGrid = ({ children }) => <Grid columns="1fr 2fr">{children}</Grid>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaInformationModule = () => {
    /***** HOOKS *****/
    const params = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();
    const isMobileSmall = useAppViewport('xs');
    const isMobile = useAppViewport(['xs', 'sm']);
    const isTablet = useAppViewport('md');
    const { id } = params;

    /***** QUERIES *****/
    const { data: get_katana_service_data, isSuccess: isGetServiceSuccess } = katanaQuery.serviceID.getService.useQuery(id);

    /***** RENDER HELPERS *****/
    const isActive = get_katana_service_data?.attributes?.launch_checklist?.includes(SiteSetupStage.WebpageInfoCompleted);
    function getGridColumns() {
        if (isMobile) {
            return '100%';
        }
        if (isTablet) {
            return '175px 1fr';
        }
        return '225px 1fr';
    }

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isGetServiceSuccess}>
            <KatanaSectionSettingsModule>
                <NXBox.TopAccordion
                    title="Basic Information"
                    topChildren={
                        <>
                            {isActive && (
                                <Grid columns={isMobileSmall ? '100%' : 'repeat(2, minmax(auto, 200px))'} gap={1} fullWidth={isMobileSmall}>
                                    <SolidButton
                                        to={getKatanaDestination(EDITOR.CONTACT_INFORMATION)}
                                        onMouseOver={() => {
                                            katanaContentEditorRoutes[CONTACT_INFORMATION].prefetch(id);
                                        }}
                                    >
                                        Edit Contact Information
                                    </SolidButton>
                                    <SolidButton
                                        to={getKatanaDestination(EDITOR.BASIC_DETAILS)}
                                        onMouseOver={() => {
                                            katanaContentEditorRoutes[BASIC_DETAILS].prefetch(id);
                                        }}
                                    >
                                        Edit Website Information
                                    </SolidButton>
                                </Grid>
                            )}
                        </>
                    }
                >
                    <NXBox.DefaultPadding>
                        {isActive ? (
                            <Grid columns={getGridColumns()} gap={6} alignItems--start>
                                <div className="KatanaInformationModule__currentLayout">
                                    <Text.Heading h4>Website Logo</Text.Heading>
                                    <Border all>
                                        <RenderLogoOrBrandFont />
                                    </Border>
                                </div>
                                <div className="KatanaInformationModule__currentSections">
                                    <Text.Heading h4>Website information</Text.Heading>
                                    <SimpleTable
                                        rows={React.Children.toArray(
                                            [
                                                <SimpleTableGrid>
                                                    <InfoTableSubHeading>Name of website</InfoTableSubHeading>
                                                    <InfoTableSpan>{get_katana_service_data?.business?.name ?? '-'}</InfoTableSpan>
                                                </SimpleTableGrid>,
                                                !!get_katana_service_data.attributes?.is_setup_complete && (
                                                    <SimpleTableGrid>
                                                        <InfoTableSubHeading>Website Status</InfoTableSubHeading>
                                                        <WebpageStatusContent />
                                                        <PublishedWithIssues />
                                                    </SimpleTableGrid>
                                                ),
                                                <SimpleTableGrid>
                                                    <InfoTableSubHeading>Industry</InfoTableSubHeading>
                                                    <InfoTableSpan>
                                                        {get_katana_service_data?.attributes?.business_categories?.join?.(', ') ?? ''}
                                                    </InfoTableSpan>
                                                </SimpleTableGrid>,
                                                // TODO: Move this to contact information module
                                                <SimpleTableGrid>
                                                    <InfoTableSubHeading>Contact Phone Number</InfoTableSubHeading>
                                                    <InfoTableSpan>{get_katana_service_data?.business?.phone ?? 'N/A'}</InfoTableSpan>
                                                </SimpleTableGrid>,
                                                <SimpleTableGrid>
                                                    <InfoTableSubHeading>Contact Email Address</InfoTableSubHeading>
                                                    <InfoTableSpan>{get_katana_service_data?.business?.email ?? 'N/A'}</InfoTableSpan>
                                                </SimpleTableGrid>
                                            ].filter(Boolean)
                                        )}
                                    />
                                </div>
                            </Grid>
                        ) : (
                            <KatanaModuleCTA
                                image={katanaModuleImages.websiteInformationModule}
                                alt="Website Logo and Information"
                                text="Add in the essential information for your website"
                                buttonText="Add information"
                                disabled={
                                    !isChecklistItemEnabled(get_katana_service_data.attributes.launch_checklist, SiteSetupStage.WebpageInfoCompleted)
                                }
                                to={getKatanaDestination(SETUP.BASIC_DETAILS)}
                            />
                        )}
                    </NXBox.DefaultPadding>
                </NXBox.TopAccordion>
            </KatanaSectionSettingsModule>
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaInformationModule };
