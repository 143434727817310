/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';

/**
 * @param {string} domain
 */
function createQueryKey(domain) {
    return createBaseQueryKey(/** @type {const} */ (['katana', 'check-domain', domain]));
}

/**
 * @param {string} domain
 * @param {boolean} [enabled]
 */
function createQueryOptions(domain, enabled = true) {
    return queryOptions({
        queryKey: createQueryKey(domain),
        queryFn: () => KATANA_API.katana.check.POST(domain),
        staleTime: MINUTE * 5,
        enabled: enabled && Boolean(domain),
        select: (data) => {
            return data.data;
        }
    });
}

/**
 * @param {string} domain
 */
function invalidateQueries(domain) {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey(domain)
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {string} domain
 * @param {boolean} [enabled]
 */
function _useQuery(domain, enabled) {
    return useQuery(createQueryOptions(domain, enabled));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const checkDomain = /** @type {const} */ ({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
    invalidateQueries
});
