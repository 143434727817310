/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ProgressStepsNode.scss';

export const nodeStates = {
    NOT_STARTED: 'not_started',
    IN_PROGRESS: 'in_progress',
    COMPLETE: 'complete'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A single step in the "ProgressSteps" component
 */
function ProgressStepsNode({ state }) {
    /***** RENDER *****/
    return (
        <div
            className={classNames('ProgressStepsNode', {
                'ProgressStepsNode--complete': state === nodeStates.COMPLETE,
                'ProgressStepsNode--current': state === nodeStates.IN_PROGRESS
            })}
        >
            {state === nodeStates.COMPLETE ? <PhosphorIcons.CheckFat.Fill white size={16} /> : ''}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ProgressStepsNode.propTypes = {
    /**
     * The state that the step is in
     */
    state: PropTypes.oneOf(Object.values(nodeStates))
};

export default ProgressStepsNode;
