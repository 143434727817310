/**
 * @param {Katana.SectionDefinitions.ValidationsWithTypedNames[]} validations
 * @param {ValidationRuleTypesValues} name
 */
export function getValidationValue(validations = [], name) {
    const validation = validations.find(({ name: validationName }) => validationName === name);

    if (!validation) {
        return null;
    }

    const { value } = validation;

    return value;
}
