/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';
import Switch from 'components/Switch';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { disableAutoRenew, enableAutoRenew } from '../../../../action';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetUserPaymentMethodDataListQuery } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { constants } from 'containers/services/modules/domains';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows the auto renew status of the domain
 */
function DomainAutoRenew() {
    /***** HOOKS *****/
    const dispatch = useDispatch();

    const domain_information_data = useSelector((state) => state.domain.domain_information_data);
    const domain_auto_renew_status = useSelector((state) => state.domain.domain_auto_renew_status);

    const { auto_renew, is_premium, auto_renew_invoice } = domain_information_data?.attributes || {};

    /***** STATE *****/
    const [showRenewLightbox, setShowRenewLightbox] = useState(false);

    /***** QUERIES *****/
    const { data: getUserPaymentMethodDataList, isLoading: isGetUserPaymentMethodDataListLoading } = useGetUserPaymentMethodDataListQuery();

    /***** EFFECTS *****/
    useEffect(() => {
        if (domain_auto_renew_status === 'success' && showRenewLightbox) {
            setShowRenewLightbox(false);
        }
    }, [domain_auto_renew_status]);

    /***** FUNCTIONS *****/
    function toggleAutoRenew(renew) {
        if (renew === true) {
            dispatch(disableAutoRenew(domain_information_data?.id));
            return;
        }

        const hasActiveCreditCard =
            getUserPaymentMethodDataList?.length && getUserPaymentMethodDataList.some(({ attributes }) => !attributes.is_expired);

        dispatch(
            enableAutoRenew(domain_information_data?.id, {
                onSuccess: () => {
                    if (!hasActiveCreditCard) {
                        pushNotification(
                            <div>
                                <Text bold>
                                    <span>Please Note: </span>
                                </Text>
                                {constants.autoRenew.missingCard}
                            </div>
                        );
                    }
                }
            })
        );
    }

    /***** RENDER *****/
    return (
        <NXBox.OverviewRow>
            {is_premium ? (
                <NXBox.InfoPair
                    title="Auto Renew"
                    description={
                        <Flex items="center" gap={1}>
                            <Text bold warn>
                                Unavailable
                            </Text>
                            <Tooltip info="Premium domains cannot be automatically renewed as they require explicit confirmation." />
                        </Flex>
                    }
                />
            ) : (
                <>
                    <div>
                        <Flex items="center" gap={1}>
                            <Text bold uppercase secondary size--xs>
                                Auto Renew
                            </Text>
                            <Tooltip info="We'll automatically invoice you 14 days before your domain is due to expire and attempt to charge any stored credit card. If we're unable to charge your credit card we will let you know by email. We recommend adding a credit card to your account if you wish to use this feature!" />
                        </Flex>
                        <Text bold warn={!auto_renew} confirm={auto_renew}>
                            <p>{auto_renew ? 'Enabled' : 'Disabled'}</p>
                        </Text>
                    </div>
                    <Switch
                        onClick={() => {
                            if (domain_auto_renew_status !== 'loading' && auto_renew === true && auto_renew_invoice) {
                                setShowRenewLightbox(true);
                            } else if (domain_auto_renew_status !== 'loading' && !showRenewLightbox) {
                                toggleAutoRenew(auto_renew);
                            }
                        }}
                        switchvalue={auto_renew}
                        disabled={domain_auto_renew_status === 'loading' || isGetUserPaymentMethodDataListLoading}
                    />
                </>
            )}
            {showRenewLightbox && (
                <OverlayLightbox
                    title="Disable Auto Renew?"
                    onOpen={showRenewLightbox}
                    loading={domain_auto_renew_status}
                    confirm={{
                        desc: htmr(
                            `Disabling auto renew will cancel invoice <strong>#${
                                auto_renew_invoice ?? ''
                            }</strong> and remove the scheduled auto renewal.`
                        ),
                        buttonText: 'Confirm',
                        buttonAction: () => dispatch(disableAutoRenew(domain_information_data?.id)),
                        closeText: 'No, Go Back',
                        closeAction: () => setShowRenewLightbox(false),
                        loading: domain_auto_renew_status
                    }}
                    onClose={() => setShowRenewLightbox(false)}
                />
            )}
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainAutoRenew;
