/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import CreditCardImage from 'components/CreditCardImage';
import OutlineDropdown from 'components/Dropdowns/OutlineDropdown';
import RequestLoader from 'components/Loaders/Request';
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useSetPaymentMethodDataAsDefaultMutation } from 'containers/billing/queries';
import { useGetUserPaymentMethodDataListQuery } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';
import './_creditCardList.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const CreditCardList = ({ handleRemoveCreditCard }) => {
    /***** QUERIES *****/
    const { mutate: mutateSetPaymentMethodDataAsDefault } = useSetPaymentMethodDataAsDefaultMutation();
    const { data: get_user_payment_method_data_list_data = [], isLoading: isGetUserPaymentMethodDataListLoading } =
        useGetUserPaymentMethodDataListQuery({
            select: useGetUserPaymentMethodDataListQuery.selectFilters.getPaymentMethodType(PAYMENT_METHODS.CREDIT_CARD),
            onError: () => {}
        });

    /***** RENDER *****/
    if (isGetUserPaymentMethodDataListLoading) return <RequestLoader fillHeight={120} message="Fetching credit cards" />;

    if (!get_user_payment_method_data_list_data?.length) {
        return (
            <div className="creditCardList__notFound">
                <Text secondary size--s align--center>
                    We could not find any credit cards in our system
                </Text>
            </div>
        );
    }

    return get_user_payment_method_data_list_data.map(
        ({ id, attributes, isLoadingRemoval = false, isMockAdditionLoading = false, isDataUpdating = false }, index) => {
            const { is_default, card_type, identifier, expiry, is_expired } = attributes;

            const expiryLuxonData = toLuxonDate(expiry);
            const expiryYear = expiryLuxonData.year;
            const expiryMonth = expiryLuxonData.month;

            const menu = [];

            function onHandleRemoveCreditCard() {
                handleRemoveCreditCard(id);
            }

            function handleMutatePutPaymentMethodDataSingle() {
                mutateSetPaymentMethodDataAsDefault(id);
            }

            menu.push({
                label: 'Remove',
                type: 'onClick',
                color: is_expired ? 'warn' : 'primary',
                onClick: isMockAdditionLoading ? null : onHandleRemoveCreditCard
            });

            if (!is_default && !is_expired) {
                menu.push({
                    label: 'Make Primary Card',
                    type: 'onClick',
                    onClick: isMockAdditionLoading ? null : handleMutatePutPaymentMethodDataSingle
                });
            }

            return (
                <div
                    key={index}
                    className={classNames('creditCardList__configured', {
                        'creditCardList__configured--isLoadingRemoval': isLoadingRemoval,
                        'creditCardList__configured--isDataUpdating': isDataUpdating,
                        'creditCardList__configured--isMockAdditionLoading': isMockAdditionLoading
                    })}
                >
                    <div className="creditCardList__itemWrapper">
                        <div className="creditCardList__frame">
                            <CreditCardImage className="creditCardList__icon" cardType={card_type} />

                            <div className="creditCardList__details">
                                {isMockAdditionLoading ? (
                                    ''
                                ) : (
                                    <div className="creditCardList__card--type">
                                        {card_type}
                                        {is_default ? (
                                            <div className="creditCardList__tag">
                                                <SolidTag color="success">PRIMARY</SolidTag>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                                <div className="creditCardList__card-info-wrapper">
                                    <Text secondary size--s italic className="creditCardList__card-info">
                                        **************{identifier}
                                    </Text>

                                    <Text secondary size--s italic className="creditCardList__card-info" warn={is_expired}>
                                        <span>
                                            Expiry: {expiryMonth} / {expiryYear}
                                        </span>
                                    </Text>
                                </div>
                            </div>
                        </div>

                        {is_expired && (
                            <p className="creditCardList__expiredNotice">
                                This credit card has expired. Please add a new card or remove the expired card to avoid any service disruptions.
                            </p>
                        )}

                        <div className="creditCardList__action">
                            {is_expired ? (
                                <SolidButton type="onClick" color={menu[0].color ?? 'primary'} onClick={menu[0].onClick}>
                                    {menu[0].label}
                                </SolidButton>
                            ) : (
                                <>
                                    {menu.length === 1 ? (
                                        <OutlineButton type="onClick" color={menu[0].color ?? 'primary'} onClick={menu[0].onClick}>
                                            {menu[0].label}
                                        </OutlineButton>
                                    ) : (
                                        <OutlineDropdown color="primary" title={menu[0].label} list={menu.slice(1)} titleOnClick={menu[0].onClick} />
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {isLoadingRemoval ? <RequestLoader height={40} message="Removing credit card..." /> : ''}
                    {isMockAdditionLoading ? <RequestLoader height={40} message="Adding credit card..." /> : ''}
                    {isDataUpdating ? <RequestLoader height={40} message="Updating credit card..." /> : ''}
                </div>
            );
        }
    );
};

CreditCardList.propTypes = {
    /**
     * Function to handle lightbox removal
     */
    handleRemoveCreditCard: PropTypes.func
};

export default CreditCardList;
