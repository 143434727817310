/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

import { getGSuiteVerificationData, getGSuiteVerificationStatus, verifyDomainRecord } from '../action';

import SetupVerify from '../forms/setupVerify';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteAccountStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoice: false,
            showInvoiceLightbox: false,
            showRecordLightbox: false,
            domain: undefined,
            isVerified: false,
            verification: undefined,
            adminUser: undefined
        };
        this.toggleRecordLightbox = this.toggleRecordLightbox.bind(this);
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.handleRecordCheck = this.handleRecordCheck.bind(this);
        this.getAdminUser = this.getAdminUser.bind(this);
    }

    /************** OPEN/CLOSE PAY INVOICE **************/

    openPayInvoice() {
        this.setState({
            showInvoiceLightbox: true
        });
    }

    closePayInvoice() {
        this.setState({
            showInvoiceLightbox: false
        });
    }

    closePayInvoiceSuccess() {
        this.setState({
            showInvoiceLightbox: false
        });
    }

    toggleRecordLightbox() {
        const { showRecordLightbox } = this.state;

        this.setState({
            showRecordLightbox: !showRecordLightbox
        });
    }

    getAdminUser(users) {
        return users.find((user) => {
            return user.is_admin;
        });
    }

    handleRecordCheck() {
        const { gsuiteid, verifyDomainRecord } = this.props;
        verifyDomainRecord(gsuiteid);
    }

    componentDidMount() {
        const { gsuiteid, gsuite_service_data, getGSuiteVerificationStatus } = this.props;
        const { getAdminUser } = this;
        const { attributes, included } = gsuite_service_data;
        const { domain, users } = attributes;
        const invoice = getIncludedObjBasedOnType(included, 'invoice');

        this.setState(
            {
                domain: domain,
                invoice,
                adminUser: getAdminUser(users)
            },
            () => {
                getGSuiteVerificationStatus(gsuiteid);
            }
        );
    }

    componentDidUpdate(prevProps) {
        const {
            gsuiteid,
            gsuite_verification_status_status,
            gsuite_verification_status_data,
            gsuite_verification_status,
            gsuite_verification_data,
            getGSuiteVerificationData
        } = this.props;

        if (gsuite_verification_status_status === 'success' && prevProps.gsuite_verification_status_status === 'loading') {
            const { attributes } = gsuite_verification_status_data;
            const { verified } = attributes;

            if (!verified) {
                getGSuiteVerificationData(gsuiteid); // If verification is false, get the verification token and method
            }
        }

        if (gsuite_verification_status === 'success' && prevProps.gsuite_verification_status === 'loading') {
            const { attributes } = gsuite_verification_data;
            const { method, token } = attributes;

            this.setState({
                // Set the verification data in state and display the status
                verification: {
                    status: null,
                    method: method,
                    record: token
                }
            });
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { gsuite_service_status, gsuite_service_data } = this.props;
        const { domain, verification, invoice, adminUser, showInvoiceLightbox, showRecordLightbox } = this.state;
        const { toggleRecordLightbox, openPayInvoice, closePayInvoice, closePayInvoiceSuccess, handleRecordCheck } = this;

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {
            className: `gsuiteStatus__box`,
            override: <div></div>
        };

        if (verification) {
            conditionalProps = {
                className: `gsuiteStatus__box`,
                override: (
                    <div className="gsuiteStatus__container">
                        <div className="gsuiteStatus__wrapper--left">
                            <div className="gsuiteStatus__text">
                                Certain features may not be available to you as the associated domain is not verified. Please verify the domain or
                                contact technical support for assistance.
                            </div>
                        </div>
                        <div className="gsuiteStatus__wrapper--right">
                            <div className="gsuiteStatus__action">
                                <SolidButton
                                    color="white"
                                    type="onClick"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleRecordLightbox();
                                    }}
                                    className="gsuiteStatus__button"
                                >
                                    Verify Domain
                                </SolidButton>
                            </div>
                        </div>
                    </div>
                )
            };
        }

        if (showRecordLightbox) {
            conditionalProps = {
                title: `Google Workspace Domain Verification`,
                className: `gsuiteStatus__box--verify`,
                custom: {
                    render: <SetupVerify verification={verification} domain={domain} onSubmit={handleRecordCheck} />,
                    pos: `bottom`
                },
                toggle: {
                    condition: showRecordLightbox,
                    action: (e) => {
                        e.preventDefault();
                        toggleRecordLightbox();
                    }
                }
            };
        }

        if (invoice) {
            conditionalProps = {
                ...conditionalProps,
                override: (
                    <div className="gsuiteStatus__container">
                        <div className="gsuiteStatus__wrapper--left">
                            <div className="gsuiteStatus__text">
                                Certain features may not be available to you as there is currently an outstanding invoice for this service. Make a
                                payment now or contact our billing team for assistance.
                            </div>
                        </div>
                        <div className="gsuiteStatus__wrapper--right">
                            <div className="gsuiteStatus__action">
                                <SolidButton
                                    color="white"
                                    type="onClick"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openPayInvoice(invoice);
                                    }}
                                    className="gsuiteStatus__button"
                                >
                                    Pay Invoice
                                </SolidButton>
                            </div>
                        </div>
                    </div>
                )
            };
        }

        if (adminUser && !adminUser.agreed_to_terms) {
            conditionalProps = {
                className: `gsuiteStatus__box`,
                override: (
                    <div className="gsuiteStatus__container">
                        <div className="gsuiteStatus__wrapper--left">
                            <div className="gsuiteStatus__text">
                                Certain features may not work as intended or be available to you as you have not accepted Google&apos;s Terms of
                                Service. Login to Google Workspace to accept the Terms of Service.
                            </div>
                        </div>
                        <div className="gsuiteStatus__wrapper--right">
                            <div className="gsuiteStatus__action">
                                <SolidButton
                                    className="gsuiteStatus__button"
                                    color="white"
                                    size="small"
                                    type="anchor"
                                    href={gsuite_service_data.attributes.login_link}
                                    target="_blank"
                                >
                                    Login to Admin Portal
                                </SolidButton>
                            </div>
                        </div>
                    </div>
                )
            };
        }

        return (
            <div className="gsuiteStatus">
                {verification || invoice || (adminUser && !adminUser.agreed_to_terms) ? (
                    <Box premounted={true} status={gsuite_service_status} {...conditionalProps} />
                ) : (
                    ''
                )}
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoice.id}
                        invoiceid={invoice.id}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_verification_status: state.gsuite.gsuite_verification_status,
        gsuite_verification_data: state.gsuite.gsuite_verification_data,
        gsuite_verification_status_status: state.gsuite.gsuite_verification_status_status,
        gsuite_verification_status_data: state.gsuite.gsuite_verification_status_data,
        gsuite_verify_record_status: state.gsuite.gsuite_verify_record_status,
        gsuite_verify_record_data: state.gsuite.gsuite_verify_record_data,
        gsuite_service_status: state.gsuite.gsuite_service_status,
        gsuite_service_data: state.gsuite.gsuite_service_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGSuiteVerificationStatus,
            getGSuiteVerificationData,
            verifyDomainRecord
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteAccountStatus));
