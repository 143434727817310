/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { Field, change, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, emailFieldValidation, renderCheckboxField, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   SHARED FUNCTIONS
 **********************************************************************************************************/
const validate = (values) => {
    const errors = {};
    if (!values.general_emails && !values.invoice_emails && !values.product_emails) {
        errors.general_emails = '1 of these is required';
        errors.invoice_emails = '1 of these is required';
        errors.product_emails = '1 of these is required';
    }
    return errors;
};

const formName = 'AccountsContactsForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ContactForm extends Component {
    componentDidMount() {
        const { mode, dispatch, selectedContact, form } = this.props;

        if (mode === 'Edit' && selectedContact) {
            const { attributes } = selectedContact;
            const { email, firstname, lastname, general_emails, product_emails, invoice_emails } = attributes;

            dispatch(change(form, 'email', email));
            dispatch(change(form, 'firstname', firstname));
            dispatch(change(form, 'lastname', lastname));
            dispatch(change(form, 'general_emails', general_emails));
            dispatch(change(form, 'product_emails', product_emails));
            dispatch(change(form, 'invoice_emails', invoice_emails));
        }
    }

    render() {
        const { mode, handleSubmit } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="accountContacts__form" onSubmit={handleSubmit}>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            label="First Name"
                            name="firstname"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            label="Last Name"
                            name="lastname"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            label="Email Address"
                            name="email"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation, emailFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            name="general_emails"
                            label={
                                <Fragment>
                                    <strong>General:</strong>
                                    <div>Announcements, password reminders</div>
                                </Fragment>
                            }
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            name="invoice_emails"
                            label={
                                <Fragment>
                                    <strong>Invoices:</strong>
                                    <div>Invoices, reminders, payment confirmations</div>
                                </Fragment>
                            }
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            name="product_emails"
                            label={
                                <Fragment>
                                    <strong>Products:</strong>
                                    <div>Order details, welcome email</div>
                                </Fragment>
                            }
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <ReduxFormButton form={formName} className="form__column form__column--full">
                        {mode} Contact
                    </ReduxFormButton>
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ContactForm = reduxForm({
    form: formName,
    enableReinitialize: true,
    validate
})(ContactForm);

export default ContactForm;
