/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import DialogNotification from 'components/Notifications/DialogNotification';
import { ScrollableComponent } from 'components/ScrollableComponent';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useSubscriptionsQuery } from 'containers/account/queries/useSubscriptionsQuery';

/**********************************************************************************************************
 *   CONSTS
**********************************************************************************************************/
import './_Subscriptions.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { Subscription } from 'containers/account/modules/subscriptions/subscription';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AccountSubscriptions = () => {
    /***** HOOKS *****/
    const { phone } = useSelector((state: any) => state.app.app_user_data);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { isSuccess, refetch, isRefetching, isError } = useSubscriptionsQuery();

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isSuccess} className="accountSubscriptions">
            <NXBox
                isLoading={isRefetching && isError}
                initialStatus={!isRefetching && isError ? 'error' : undefined}
                onError={<NXBox.RefreshableError onRefresh={refetch} title="Email and SMS Subscription Preferences" />}
            >
                <NXBox.DefaultPadding>
                    <NXBox.Title title="Email and SMS Subscription Preferences" />
                    <NXBox.Description description="Configure how you would like to receive emails from us." />
                </NXBox.DefaultPadding>
                <Subscription title={`${company} Promotions`} email="emailPromotion" sms="smsPromotion">
                    Receive promotions, discounts, company news and industry content right into your inbox.
                </Subscription>
                <Subscription title="Invoice Reminders" sms="smsInvoice">
                    Notices will be sent 2 days before the next due date.
                </Subscription>
                <Subscription title="Domain Expiry Notifications" sms="smsNotifications">
                    Domain expiry notifications will be sent 2 days before expiry.
                </Subscription>
                <Subscription className="accountSubscriptions__toggle--last" title="Web Hosting Suspension Notifications" sms="smsSuspension">
                    Sends you a message if a hosting service under your account has been suspended.
                    <Text secondary lead--1 size--s>
                        A message will be sent to{' '}
                        <Text span black>
                            {formatPhoneNumber(phone)}
                        </Text>
                    </Text>
                </Subscription>
                <Padding x={isMobile ? 4 : 6} bottom={isMobile ? 4 : 6}>
                    <DialogNotification type="warning" tail={{ pos: 'top' }}>
                        PLEASE NOTE: These messages may be sent outside normal business operating hours and during weekends and holidays.
                    </DialogNotification>
                </Padding>
            </NXBox>
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default AccountSubscriptions;
