/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetDefaultPaymentMethodQuery } from 'containers/billing/queries/useGetDefaultPaymentMethodQuery';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const PaymentMethodsNavItems = ({ activeTab, setActiveTab }) => {
    /***** QUERIES *****/
    const {
        data: get_default_payment_method_data,
        isLoading: isGetDefaultPaymentMethodLoading,
        isError: isGetDefaultPaymentMethodError
    } = useGetDefaultPaymentMethodQuery();

    /***** RENDER *****/
    if (isGetDefaultPaymentMethodLoading) {
        return <RequestLoader message="Loading Payment Methods" />;
    }

    if (isGetDefaultPaymentMethodError) {
        return <FetchComponentError />;
    }

    if (!get_default_payment_method_data?.attributes?.payment_option?.length) {
        return '';
    }

    return (
        <ul className="nav">
            {get_default_payment_method_data?.attributes?.payment_option.map((item, index) => {
                return (
                    <li key={`paymentOption-${index}`} className={classNames({ selected: activeTab === item })}>
                        <button
                            onClick={() => {
                                setActiveTab(item);
                            }}
                        >
                            {item}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PaymentMethodsNavItems;
