/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useImageURLorFileStream } from 'components/ImageSelectControl/hooks/useImageURLorFileStream';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ImageSelectControlSimple.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      imageURL: import('components/ImageSelectControl/hooks/useImageURLorFileStream').ImageDataOrFile;
 *      onImageClick: () => void;
 *      onClickRemove: () => void;
 *      size?: number;
 *      className?: string;
 * }} props
 */
function ImageSelectControlSimple({ imageURL, onImageClick, onClickRemove, size, className }) {
    /***** HOOKS *****/
    const { dataURL, isLoading } = useImageURLorFileStream(imageURL);

    /***** RENDER *****/
    return (
        <div className={classNames('ImageSelectControlSimple', className)}>
            <button className="ImageSelectControlSimple__imageButton" type="button" onClick={onImageClick}>
                {isLoading ? <RequestLoader /> : <img src={dataURL} width={size ? size : '100%'} height={size ? size : 'auto'} alt="Hero Banner" />}
            </button>
            {dataURL && (
                <SolidButton type="onClick" color="warn" onClick={onClickRemove}>
                    <PhosphorIcons.Trash size={26} />
                </SolidButton>
            )}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ImageSelectControlSimple;
