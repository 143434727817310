/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';
import ImageSelectControlSimple from 'components/ImageSelectControl/simple';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStateValueHasFile } from 'components/Form/NXDropZone/hooks/useStateValueHasFile';
import { isKatanaFileAttachment } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/isKatanaFileAttachment';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { imageAcceptsTypes } from 'components/Form/NXDropZone/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A component that renders a drop zone for file uploads with an image control.
 *
 * @type {React.FC<TNXDropZone.Variants.CompactWithSingleImageControlAndUpload.Props>}
 */
const NXDropZoneCompactWithSingleImageControlAndUpload = ({ input, label, meta, imageControlProps, accept = imageAcceptsTypes }) => {
    const { name, value, onChange } = input;

    /***** HOOKS *****/
    /**
     * @type {React.MutableRefObject<import('react-dropzone').DropzoneRef | null | undefined>}
     */
    const _zoneRef = useRef();
    const { id } = useSetupEditorRouteParams();
    const [hasFile, setHasFile] = useStateValueHasFile(value);

    /***** FUNCTIONS *****/
    function handleOnDrop() {
        setHasFile(false);
    }

    /***** RENDER *****/
    return (
        <>
            <ImageSelectControlSimple
                imageURL={isKatanaFileAttachment(value) ? value.url : value}
                className={classNames({ hidden: !hasFile })}
                onImageClick={() => {
                    _zoneRef?.current?.open?.();
                }}
                onClickRemove={() => {
                    onChange(null);
                }}
            />
            <Field
                name={name}
                label={label}
                component={NXDropZone.ReduxForm.WithKatanaFileUpload}
                zoneRef={_zoneRef}
                serviceID={id}
                className={classNames('NXDropZoneCompactWithSingleImageControl', { hidden: hasFile })}
                type="dropzone"
                onDropZoneDrop={handleOnDrop}
                accept={accept}
                singleFile={true}
                compact={true}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXDropZoneCompactWithSingleImageControlAndUpload;
