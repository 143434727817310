/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { NSelectDropDown } from 'components/Dropdowns/SelectDropdown/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SelectDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * Select Dropdown allowing you to select a value from a list of options from which that value gets passed to that options' onClick function
 *
 * `preselectedOptionIndex` - Pass a number if one of the options should be preselected
 *
 * `options` - List of dropdown options
 *
 * `className` - Optional extra className
 *
 * `label` - Label to show Above the dropdown element
 *
 * `disabled` - Whether or no the dropdown should be disabled
 *
 * `noSelectionLabel` - Label to show when no option has been selected
 *
 * `optionDisplayLimit` - Limit amount of options to display before turning into a scroll
 *
 * `direction` - Direction at which the dropdown should open
 */
const SelectDropdown: React.FC<NSelectDropDown.Props> = ({
    preselectedOptionIndex = null,
    options,
    className,
    label,
    disabled,
    noSelectionLabel,
    optionDisplayLimit,
    direction = 'down'
}) => {
    /***** STATE *****/
    const { value: isShowingDropdown, setFalse: hideDropdown, toggle: toggleDropdown } = useBoolean(false);
    const [selectedLabel, setSelectedLabel] = useState(null);

    /***** HOOKS *****/
    // Ref
    const dropdownRef = useRef(null);

    // Clickaway
    useClickAway([dropdownRef], hideDropdown);

    /***** FUNCTIONS *****/
    const setNewLabel = (label) => {
        setSelectedLabel(label);
    };

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (typeof preselectedOptionIndex === 'number' && preselectedOptionIndex !== -1) {
            setSelectedLabel(options[preselectedOptionIndex]?.label || options[0].label);
        }
    }, [preselectedOptionIndex]);

    /***** RENDER HELPERS *****/
    const renderDropdownOption = (data, index) => {
        return (
            <button
                key={`${data.label}-${index}`}
                className={classNames('SelectDropdown__option', data.className, {
                    'SelectDropdown__option--selected': selectedLabel === data.label
                })}
                onClick={(e) => {
                    hideDropdown();
                    setNewLabel(data.label);
                    data.onClick(e);
                }}
            >
                {!!data.icon && data.icon}
                <Text align--left color={selectedLabel === data.label && 'S_color_text_primary_base'} secondary={selectedLabel !== data.label}>
                    {data.label}
                </Text>
            </button>
        );
    };

    /***** RENDER *****/
    return (
        <div
            className={classNames(
                'SelectDropdown',
                {
                    'SelectDropdown--disabled': disabled,
                    'SelectDropdown--active': isShowingDropdown,
                    'SelectDropdown--direction-up': direction === 'up'
                },
                className
            )}
            ref={dropdownRef}
        >
            {label ? (
                <Text secondary size--s medium className="SelectDropdown__label">
                    {label}
                </Text>
            ) : (
                ''
            )}
            <button
                type="button"
                className="SelectDropdown__toggleButton"
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled) toggleDropdown();
                }}
                disabled={disabled}
            >
                <div className="SelectDropdown__title">
                    <div className="text">{selectedLabel ? selectedLabel : noSelectionLabel}</div>
                    <PhosphorIcons.Chevron state={isShowingDropdown ? 'up' : 'down'} />
                </div>
            </button>
            {isShowingDropdown ? (
                <div
                    className={classNames('SelectDropdown__dropdown', {
                        'SelectDropdown__dropdown--options-limit': optionDisplayLimit,
                        [`SelectDropdown__dropdown--options-limit-${optionDisplayLimit}`]: optionDisplayLimit
                    })}
                >
                    {options.map((option, index) => {
                        return renderDropdownOption(option, index);
                    })}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SelectDropdown;
