/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/CheckBoxList';
import CardSelectGrid from 'components/Form/CardSelectGrid';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getColourStyleSelectContent } from 'containers/katana/containers/ContentEditorLightbox/methods/getColourStyleSelectContent';
const { COLOR, TRANSPARENT, VISIBLE } = usePostNavigationMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _NavigationColourThemeContentModule = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(id);

    /***** RENDER HELPERS *****/
    const constructedColourOptions = useMemo(
        () => [
            { type: 'custom', value: 'white', selectionText: 'Light Contrast', content: getColourStyleSelectContent('light') },
            {
                type: 'custom',
                value: get_site_info_data?.color,
                selectionText: 'Colour Contrast',
                content: getColourStyleSelectContent('standard')
            },
            { type: 'custom', value: 'black', selectionText: 'Dark Contrast', content: getColourStyleSelectContent('dark') }
        ],
        [get_site_info_data]
    );
    const colourOptions = isGetKatanaSiteInfoLoading ? [] : constructedColourOptions;

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion title="Menu Colour Scheme" description="Select a colour that you'd like to use for the menu background.">
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    {isGetKatanaSiteInfoLoading ? (
                        <RequestLoader message="Loading Navigation Colours..." />
                    ) : (
                        <>
                            <Field
                                key={get_site_info_data?.color}
                                name={COLOR}
                                component={CardSelectGrid}
                                itemsPerRow={3}
                                options={colourOptions}
                                preventDeselect
                                validate={[requiredFieldValidation]}
                            />
                            <Field name={TRANSPARENT} component={CheckBoxList.Item.ReduxForm}>
                                <Text semiBold black size--m>
                                    Make my navigation background transparent
                                </Text>
                            </Field>
                            <Text secondary size--s italic>
                                This feature is currently not compatible with all section and style configurations. It is recommended to be used with
                                either the Hero Banner or About Us sections.
                            </Text>
                            <Field name={VISIBLE} component={CheckBoxList.Item.ReduxForm} inverse>
                                <Text semiBold black size--m>
                                    Hide my navigation
                                </Text>
                            </Field>
                            <Text secondary size--s italic>
                                Enabling this setting will prevent both your navigation and footer from displaying. This can be useful when creating a
                                very simplistic website.
                            </Text>
                        </>
                    )}
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const NavigationColourThemeContentModule = Object.assign(_NavigationColourThemeContentModule, {
    formFields: { COLOR, TRANSPARENT }
});

export default NavigationColourThemeContentModule;
