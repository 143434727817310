/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SplitInvoiceTotal from '../splitInvoiceTotal';
import InvoiceSplitOverviewTable from '../tables/invoiceSplitOverviewTable';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SPLIT_INVOICE_PROP_TYPES, SPLIT_INVOICE_WHICH } from '../consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function OriginalInvoiceBox({ invoiceID, splitData, splitActionButton }) {
    /***** RENDER HELPERS *****/
    const filterSplitData = ({ id }) => !splitData.itemIds.includes(id);

    /***** RENDER *****/
    return (
        <NXBox>
            <NXBox.Top title="Original Invoice" description="Select the items you wish to split off into a new invoice" />

            <InvoiceSplitOverviewTable
                splitData={splitData}
                invoiceID={invoiceID}
                splitActionButton={splitActionButton}
                filterSplitData={filterSplitData}
            />

            <SplitInvoiceTotal splitData={splitData} which={SPLIT_INVOICE_WHICH.INVOICE_A} />
        </NXBox>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

OriginalInvoiceBox.propTypes = {
    ...SPLIT_INVOICE_PROP_TYPES,

    /**
     * The split action handler
     */
    splitActionButton: PropTypes.func.isRequired
};

export default OriginalInvoiceBox;
