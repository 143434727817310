/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_changeResourcesFormSummary.scss';

const BILLING_CYCLE_DROPDOWN_STATES = {
    LOADING: 'loading',
    CAN_CHANGE: 'can_change',
    CANT_CHANGE: 'cant_change'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Summary
 */
let ChangeResourcesFormSummary = ({
    haveConfigsChanged,
    isLoading,
    selectedBillingCycle,
    setSelectedBillingCycle,
    /* Redux props */
    hosting_billingcycle_data,
    hosting_resource_data,
    custom_hosting_calculate_status,
    custom_hosting_calculate_data
}) => {
    /***** STATE *****/
    const [billingCycleDropdownState, setBillingCycleDropdownState] = useState(BILLING_CYCLE_DROPDOWN_STATES.LOADING);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!['success', 'error'].includes(custom_hosting_calculate_status)) return;

        // Default to "cant change" if calculate request errors
        const newState = custom_hosting_calculate_data?.is_billing_cycle_reset
            ? BILLING_CYCLE_DROPDOWN_STATES.CAN_CHANGE
            : BILLING_CYCLE_DROPDOWN_STATES.CANT_CHANGE;

        setBillingCycleDropdownState(newState);
    }, [custom_hosting_calculate_status]);

    /***** RENDER HELPERS *****/
    const renderSummary = () => {
        const billingCycleOptions =
            hosting_billingcycle_data?.attributes?.billing_cycles?.map(({ name }) => ({
                label: `${name.name}${hosting_resource_data.attributes.billing_cycle === name.name ? ' (Same As Current)' : ''}`,
                value: name.name,
                onClick: () => setSelectedBillingCycle(name.name)
            })) || [];

        const total = custom_hosting_calculate_data?.total_due ? `$${custom_hosting_calculate_data.total_due}` : '';

        const renderBillingCycle = () => {
            if (billingCycleDropdownState === BILLING_CYCLE_DROPDOWN_STATES.LOADING) {
                return <RequestLoader />;
            }
            if (billingCycleDropdownState === BILLING_CYCLE_DROPDOWN_STATES.CANT_CHANGE) {
                return (
                    <>
                        <Text secondary medium size--s>
                            Billing Cycle
                        </Text>
                        <Text lead--l>{hosting_resource_data.attributes.billing_cycle}</Text>
                    </>
                );
            }
            return (
                <SelectDropdown
                    label="Billing Cycle"
                    options={billingCycleOptions}
                    preselectedOptionIndex={billingCycleOptions?.findIndex(({ value }) => value === selectedBillingCycle)}
                />
            );
        };

        return (
            <>
                {renderBillingCycle()}
                <div className="changeResourcesFormSummary__total">
                    <Text semiBold size--s secondary>
                        Total Due Today
                    </Text>
                    {isLoading && haveConfigsChanged ? (
                        <div className="changeResourcesFormSummary__loaderContainer">
                            <RequestLoader />
                        </div>
                    ) : (
                        <Text semiBold primary>
                            {haveConfigsChanged ? total : '-'}
                        </Text>
                    )}
                </div>
                <Text size--s secondary italic lead--none>
                    <p className="changeResourcesFormSummary__note">
                        A price breakdown will be confirmed when the button below is clicked. You will not be charged at this point.
                    </p>
                </Text>
            </>
        );
    };

    /***** RENDER *****/
    return <div className="changeResourcesFormSummary">{renderSummary()}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ChangeResourcesFormSummary.propTypes = {
    // Whether any of the resource sliders are different from their initial values. Changing only the billing cycle doesn't count, at least 1 of the configs has to have been changed
    haveConfigsChanged: PropTypes.bool,

    // Whether or not to show a loader in place of the summary
    isLoading: PropTypes.bool,

    // The currently selected billing cycle
    selectedBillingCycle: PropTypes.string,

    // The setter to update the currently selected billing cycle
    setSelectedBillingCycle: PropTypes.func
};

const mapStateToProps = (state) => ({
    hosting_billingcycle_data: state.hosting.hosting_billingcycle_data,
    hosting_resource_data: state.hosting.hosting_resource_data,
    custom_hosting_calculate_status: state.hosting.custom_hosting_calculate_status,
    custom_hosting_calculate_data: state.hosting.custom_hosting_calculate_data
});

ChangeResourcesFormSummary = connect(mapStateToProps)(ChangeResourcesFormSummary);

export default ChangeResourcesFormSummary;
