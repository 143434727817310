/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { support_number } from 'config/config';
import { sendFeedback } from '../action';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FeedbackForm from '../forms/feedbackForm';
import FetchComponentError from 'components/Errors/FetchComponentError';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import Anchor from 'components/Anchor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Feedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLightbox: false
        };

        this.toggleLightbox = this.toggleLightbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleLightbox(pushToSupportCentre) {
        const { history } = this.props;
        const { showLightbox } = this.state;

        this.setState(
            {
                showLightbox: !showLightbox
            },
            () => {
                if (pushToSupportCentre) history.push('/support/support-centre');
            }
        );
    }

    handleSubmit(values) {
        const { sendFeedback } = this.props;
        const { toggleLightbox } = this;

        toggleLightbox();
        const attributes = {
            ...values,
            subject: 'General Feedback',
            department: 'feedback',
            is_existing_service: false
        };
        sendFeedback(attributes);
    }

    render() {
        const { support_feedback_form_status } = this.props;
        const { showLightbox } = this.state;
        const { toggleLightbox, handleSubmit } = this;

        const renderLightboxDesc = () => {
            return (
                <div className="feedback__lightboxDesc">
                    <i className="icon icon-valid" />
                    Thank you for submitting your feedback
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <section className="feedback">
                <div className="feedback__wrapper">
                    <Box
                        className="feedback__box"
                        premounted={true}
                        title="Let us know what you think"
                        desc={
                            <>
                                <>
                                    If you have feedback regarding our Support, Services or you just want to say &apos;Hi&apos;, we would love to hear
                                    from you. To talk to someone directly, please call{' '}
                                </>
                                <Anchor href={`tel:${support_number}`}>{support_number}</Anchor>
                                <>.</>
                            </>
                        }
                        custom={{
                            render: <FeedbackForm onSubmit={handleSubmit} />,
                            pos: 'bottom'
                        }}
                    />
                </div>
                <OverlayLightbox
                    onOpen={showLightbox}
                    onClose={() => toggleLightbox(true)}
                    confirm={{
                        desc: support_feedback_form_status === 'error' ? <FetchComponentError /> : renderLightboxDesc(),
                        buttonText: 'Back to Support Centre',
                        buttonAction: () => toggleLightbox(true),
                        closeText: '',
                        closeAction: () => toggleLightbox(true),
                        loading: support_feedback_form_status
                    }}
                />
            </section>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            support_feedback_form_status: state.support.support_feedback_form_status
        }),
        {
            sendFeedback
        }
    )(Feedback)
);
