/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';
import { useStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const siteDataPreviewWritable = writable(/** @type {KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes} */ ({}));

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useSiteDataPreviewValues() {
    /***** STATE *****/
    const [siteData, setSiteData] = useStore(siteDataPreviewWritable);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { mutateAsync: mutateAsyncUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(id);

    /***** FUNCTIONS *****/
    /**
     * @param {Promise[]} promises
     */
    function submitSiteData(promises) {
        if (values(siteData).length) {
            promises.push(
                mutateAsyncUpdateKatanaSiteInfo(siteData).then((e) => {
                    setSiteData({});
                    return e;
                })
            );
        }
    }

    /***** HOOK RESULTS *****/
    return {
        siteData,
        setSiteData,
        submitSiteData
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export { useSiteDataPreviewValues };
