/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import OverdueInvoicesTable from './invoicesTable';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { invoicesTableContentComponents } from 'containers/billing/modules/invoices/tables/invoicesTable/methods';
import { useOverdueInvoicesQuery } from 'containers/dashboard/queries/useOverdueInvoicesQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { mobileTableSizes } from 'containers/billing/modules/invoices/tables/invoicesTable/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function OverdueInvoices() {
    /***** HOOKS *****/
    const app_viewport = useSelector((state) => state.app.app_viewport);

    /***** QUERIES *****/
    const { data: overdue_invoices_data, isFetching: isOverdueInvoicesFetching } = useOverdueInvoicesQuery();

    /***** RENDER *****/
    if (!Array.isArray(overdue_invoices_data) || overdue_invoices_data.length < 1) {
        return '';
    }

    return (
        <NXBox
            className={classNames('accountInvoices', { 'accountInvoices--mobileTable': mobileTableSizes.includes(app_viewport) })}
            isLoading={isOverdueInvoicesFetching}
        >
            <NXBox.Top
                title={
                    <Text warn size--xl semiBold lead--none>
                        Overdue Invoices
                    </Text>
                }
            />
            <OverdueInvoicesTable invoicesData={overdue_invoices_data} contentComponents={invoicesTableContentComponents} />
        </NXBox>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default OverdueInvoices;
