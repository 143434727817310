/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Article from './article';

/*   ACTIONS
 *****************************************************/
import { registerCategoryRef } from '../action';
import { pluralize } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Category extends Component {
    constructor(props) {
        super(props);

        this.categoryRef = createRef();
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { category, registerCategoryRef } = this.props;
        const { categoryRef } = this;

        registerCategoryRef(category.title, categoryRef);
    }

    render() {
        const {
            category,
            support_knowledge_base_category,
            support_knowledge_base_sub_category,
            history,
            match: {
                params: { section, param4 }
            }
        } = this.props;
        const { categoryRef } = this;

        const renderArticles = (articles, isWithinSubcat, isBelowSubcats) => {
            return articles?.length > 0 ? (
                <div
                    className={
                        isWithinSubcat
                            ? `knowledgeBase__subcatArticleContainer`
                            : `knowledgeBase__catArticleContainer${isBelowSubcats ? ` knowledgeBase__catArticleContainer--belowSubcat` : ''}`
                    }
                >
                    {(isWithinSubcat || !isBelowSubcats) && <i className="icon icon-arrow-right knowledgeBase__arrowRight" />}
                    {articles.map((article, index) => (
                        <Article key={index} article={article} />
                    ))}
                </div>
            ) : (
                ''
            );
        };

        const renderSubcategories = () => {
            return (
                category.subcategories?.length > 0 && (
                    <div className="knowledgeBase__categorySubcatContainer">
                        <i className="icon icon-arrow-right knowledgeBase__arrowRight" />
                        {category.subcategories.map((subcat, index) => (
                            <div
                                key={index}
                                className={`knowledgeBase__subcategory${
                                    subcat === support_knowledge_base_sub_category ? ' knowledgeBase__subcategory--active' : ''
                                }`}
                            >
                                <button
                                    className="knowledgeBase__subcategoryAccordion"
                                    onClick={() => {
                                        if (param4 !== subcat.slug) {
                                            return history.push(subcat.link);
                                        }
                                        const arr = subcat.link.split('/');
                                        const newUrl = arr.slice(0, arr.length - 1).join('/');
                                        history.push(newUrl);
                                    }}
                                >
                                    <div className="knowledgeBase__subcategoryTitle">{subcat.title}</div>
                                    <div className="knowledgeBase__subcategoryRight">
                                        <div className="knowledgeBase__subcategoryCount">
                                            {subcat.posts.length} help article{pluralize(subcat.posts.length)}
                                        </div>
                                        <i className="icon icon-arrow-down knowledgeBase__arrowDown" />
                                    </div>
                                </button>
                                {subcat === support_knowledge_base_sub_category && renderArticles(subcat.posts, true)}
                            </div>
                        ))}
                    </div>
                )
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={categoryRef}
                className={`knowledgeBase__category${
                    category.title === support_knowledge_base_category?.title ? ' knowledgeBase__category--active' : ''
                }`}
            >
                <button
                    className="knowledgeBase__categoryAccordion"
                    onClick={() => {
                        if (section !== category.slug) {
                            return history.push(category.link);
                        }
                        const arr = category.link.split('/');
                        const newUrl = arr.slice(0, arr.length - 1).join('/');
                        history.push(newUrl);
                    }}
                >
                    <div className="knowledgeBase__categoryTitle">{category.title}</div>
                    <i className="icon icon-arrow-down knowledgeBase__arrowDown" />
                </button>
                {category.title === support_knowledge_base_category?.title ? (
                    <>
                        {renderSubcategories()}
                        {renderArticles(support_knowledge_base_category.posts, false, category.subcategories?.length > 0)}
                    </>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            support_knowledge_base_category: state.support.support_knowledge_base_category,
            support_knowledge_base_sub_category: state.support.support_knowledge_base_sub_category
        }),
        {
            registerCategoryRef
        }
    )(Category)
);
