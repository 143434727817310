/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { cashOutLightboxModes } from '../modules/wallet';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { BankDetails } from 'components/Cancel/form/refundMethods';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';

/*   ACTIONS
 *****************************************************/
import { convertToNumber } from 'utilities/methods/commonActions';
import {
    RenderCurrencyField,
    RenderRadioButtonSet,
    RenderRadioGroupWithNotification,
    renderButton,
    renderCheckboxField,
    requiredAcceptedValidation,
    requiredFieldValidation
} from 'utilities/methods/form';
import { calculateWalletCashOut } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class WalletCashOutForm extends Component {
    constructor(props) {
        super(props);

        this.customAmountValidation = this.customAmountValidation.bind(this);
    }

    customAmountValidation(value) {
        const { account_wallet_data, method } = this.props;

        const numberValue = convertToNumber(value);

        if (numberValue <= 0) return 'Amount entered cannot be $0.00.';
        if (numberValue > convertToNumber(account_wallet_data.referral_credit))
            return 'Amount entered is higher than your current referral cash balance.';
        if (method === cashOutLightboxModes.CASH_OUT && numberValue < convertToNumber(account_wallet_data.minimum_cash_out || 10))
            return 'Amount entered is less than the minimum cash out amount.';

        return undefined;
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { change, methodInitialValue } = this.props;

        change('method', methodInitialValue);
    }

    componentDidUpdate(prevProps, prevState) {
        const { method, is_custom_amount, custom_amount, calculateWalletCashOut } = this.props;
        const { customAmountValidation } = this;

        if (method !== prevProps.method || is_custom_amount !== prevProps.is_custom_amount || custom_amount !== prevProps.custom_amount) {
            const validCustomAmount = custom_amount && convertToNumber(custom_amount) > 0 && !customAmountValidation(custom_amount);
            calculateWalletCashOut(method, is_custom_amount ? custom_amount : null, !is_custom_amount || validCustomAmount);
        }
    }

    render() {
        const {
            pristine,
            submitting,
            valid,
            method,
            is_custom_amount,
            custom_amount,
            handleSubmit,
            account_wallet_data,
            account_wallet_add_credit_status,
            account_wallet_cash_out_status,
            account_calculate_wallet_cash_out_status,
            account_calculate_wallet_cash_out_data
        } = this.props;
        const { customAmountValidation } = this;

        const balancesList = (list) => {
            return (
                <ul className="walletForm__balance">
                    {list.map(({ title, amount }, index) => (
                        <li key={index} className="walletForm__balanceItem">
                            <h5 className="walletForm__balanceTitle">{title}</h5>
                            <p className="walletForm__balanceAmount">${amount}</p>
                        </li>
                    ))}
                </ul>
            );
        };

        const renderCustomAmountField = () => {
            const validate = [requiredFieldValidation];

            if (method === cashOutLightboxModes.CASH_OUT) validate.push(customAmountValidation);

            return (
                <Field
                    label={
                        <p>
                            Enter a custom amount{' '}
                            {method === cashOutLightboxModes.CASH_OUT ? <em>(minimum ${account_wallet_data.minimum_cash_out || '10'})</em> : ''}
                        </p>
                    }
                    name="custom_amount"
                    component={RenderCurrencyField}
                    validate={validate}
                />
            );
        };

        const renderCashOutFields = () => {
            return (
                <>
                    <h4 className="walletForm__subheading">Please enter your bank details</h4>
                    <BankDetails requiredFields={['account_name', 'branch_number', 'account_number']} />

                    <Field
                        label="Save my bank details for next time"
                        name="save_details"
                        type="checkbox"
                        component={renderCheckboxField}
                        noBackground={true}
                    />

                    <DialogNotification type="info" tail={{ pos: 'top' }}>
                        PLEASE NOTE: Transactions may not be instant. Please allow 3-5 business days for the funds to reach your bank account.
                    </DialogNotification>
                </>
            );
        };

        const renderNewBalancesFields = () => {
            if (is_custom_amount && (!custom_amount || convertToNumber(custom_amount) <= 0 || customAmountValidation(custom_amount))) return '';
            if (account_calculate_wallet_cash_out_status === 'loading') return <RequestLoader />;

            const balancesListItems = [
                {
                    title: `Referral cash balance after processing`,
                    amount: account_calculate_wallet_cash_out_data?.referral_credit_remainder || '0.00'
                }
            ];

            if (method === cashOutLightboxModes.ADD_CREDIT) {
                balancesListItems.push({
                    title: `Account credit after processing`,
                    amount: account_calculate_wallet_cash_out_data?.account_credit_remainder || '0.00'
                });
            }

            return balancesList(balancesListItems);
        };

        const renderRestOfForm = () => {
            if (
                method === cashOutLightboxModes.CASH_OUT &&
                convertToNumber(account_wallet_data.referral_credit) < convertToNumber(account_wallet_data.minimum_cash_out || 10)
            )
                return (
                    <>
                        <div className="walletForm__lessThanMinimum">
                            <i className="icon icon-x" />
                            <div>
                                Unfortunately your Referral Cash balance is less than the minimum cash out amount of $
                                {account_wallet_data.minimum_cash_out}. Check out our{' '}
                                <Anchor to="/account/general/referrals">referrals program</Anchor> to see how you can earn more referral cash.
                            </div>
                        </div>
                    </>
                );

            return (
                <>
                    <Field
                        label={`Amount to ${'cash out'}`}
                        name="is_custom_amount"
                        component={RenderRadioButtonSet}
                        validate={[requiredFieldValidation]}
                        options={[
                            {
                                value: false,
                                label: `Current Balance ($${account_wallet_data.referral_credit || '0.00'})`
                            },
                            {
                                value: true,
                                label: 'Custom Amount'
                            }
                        ]}
                        size="large"
                    />
                    {is_custom_amount && renderCustomAmountField()}
                    {method === cashOutLightboxModes.CASH_OUT && renderCashOutFields()}
                    {renderNewBalancesFields()}
                    <Field
                        label="I acknowledge that this process is irreversible. When you hit “Submit”, the amounts cannot be returned to your VIP Wallet and will be transferred to your selected method above."
                        name="acknowledge_irreversible"
                        type="checkbox"
                        component={renderCheckboxField}
                        validate={[requiredAcceptedValidation]}
                        noBackground={true}
                    />
                    {renderButton(pristine, submitting, valid, 'Submit', 'primary')}
                </>
            );
        };

        const isLoading = () => {
            return account_wallet_add_credit_status === 'loading' || account_wallet_cash_out_status === 'loading';
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        if (isLoading()) return <RequestLoader />;

        return (
            <form className="walletForm" onSubmit={handleSubmit}>
                {balancesList([
                    {
                        title: `Current Referral Cash Balance`,
                        amount: account_wallet_data.referral_credit || '0.00'
                    }
                ])}
                <Field
                    label="What would you like to do with your referral cash?"
                    name="method"
                    outlined={true}
                    component={RenderRadioGroupWithNotification}
                    validate={[requiredFieldValidation]}
                    options={[
                        {
                            label: 'CASH OUT TO AUSTRALIAN BANK ACCOUNT',
                            desc: 'Deposit your earned referral cash to a nominated Australian bank account.',
                            value: cashOutLightboxModes.CASH_OUT,
                            notification: {
                                type: 'warning',
                                text: 'NOTE: At this stage, we can only fulfil cashouts to Australian bank accounts. If you do not have an Australian bank account, you can convert to account credit.'
                            }
                        },
                        {
                            label: 'CONVERT TO ACCOUNT CREDIT',
                            desc: 'Turn your referral cash into account credit. Your account credit can be used for any future invoices with VentraIP.',
                            value: cashOutLightboxModes.ADD_CREDIT
                        }
                    ]}
                />
                {renderRestOfForm()}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
WalletCashOutForm = reduxForm({
    form: 'walletCashOutForm'
})(WalletCashOutForm);

const selector = formValueSelector('walletCashOutForm');

export default connect(
    (state) => {
        let initialValues = {
            is_custom_amount: false,
            save_details: false
        };

        if (state.app.app_user_data?.bank_details) initialValues = { ...initialValues, ...state.app.app_user_data.bank_details };

        return {
            initialValues,
            method: selector(state, 'method'),
            is_custom_amount: selector(state, 'is_custom_amount'),
            custom_amount: selector(state, 'custom_amount'),
            account_wallet_data: state.billing.account_wallet_data,
            account_wallet_add_credit_status: state.billing.account_wallet_add_credit_status,
            account_wallet_cash_out_status: state.billing.account_wallet_cash_out_status,
            account_calculate_wallet_cash_out_status: state.billing.account_calculate_wallet_cash_out_status,
            account_calculate_wallet_cash_out_data: state.billing.account_calculate_wallet_cash_out_data
        };
    },
    {
        change,
        calculateWalletCashOut
    }
)(WalletCashOutForm);
