/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OverlayLightboxBackLink.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * "GO BACK" link that goes under the "Confirm" button in most lightboxes
 */
function OverlayLightboxBackLink({ text = 'GO BACK', onClick }) {
    /***** RENDER *****/
    return (
        <Anchor className="OverlayLightboxBackLink" color="primary" onClick={onClick}>
            {text}
        </Anchor>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

OverlayLightboxBackLink.propTypes = {
    // Link text
    text: PropTypes.string,

    // onClick function
    onClick: PropTypes.func
};

export default OverlayLightboxBackLink;
