/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useCancelInvoiceMutation } from 'containers/billing/queries/invoice';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function CancelInvoiceLightbox({ id, closeLightbox }) {
    /***** QUERIES *****/
    const { mutate: mutateCancelInvoice, status: cancel_invoice_status } = useCancelInvoiceMutation({
        onSuccess: (response) => {
            handleDefaultSuccessNotification(response);
            closeLightbox();
        }
    });

    /***** RENDER *****/
    return (
        <OverlayLightbox
            title="Confirm Cancel Invoice"
            onOpen
            onClose={closeLightbox}
            loading={cancel_invoice_status}
            confirm={{
                desc: `Are you sure you wish to cancel invoice #${id}? Please note that once this invoice has been cancelled any associated services with the invoice will also be removed.`,
                buttonText: 'Cancel Invoice',
                buttonAction: () => mutateCancelInvoice(id),
                closeText: 'No, Go Back',
                closeAction: closeLightbox
            }}
        />
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CancelInvoiceLightbox.propTypes = {
    // The invoice id
    id: PropTypes.string,

    // function to call when "X" (close) button is clicked
    closeLightbox: PropTypes.func
};

export default CancelInvoiceLightbox;
