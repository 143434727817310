/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/

import Delete from 'containers/ms365/modules/delete';
import ManageSeats from 'containers/ms365/modules/manageSeats';
import Overview from 'containers/ms365/modules/overview';
import Upgrade from 'containers/ms365/modules/upgrade';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import PageSectionModules from 'components/PageSectionModules';
import SideBarPage from 'components/SideBarPage';
import Subnav from 'components/Subnav';
import Title from 'components/Title';

/*   ACTIONS
 *****************************************************/
import { getMs365Service, getMs365UpgradePaths } from 'containers/ms365/action';
import { getTicketSubmitUrl } from 'containers/support/methods';
import { createPopup, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { handleSideMenuOptions } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ms365PortalUrl } from 'containers/ms365/consts';
import './_ms365.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Ms365 = (props) => {
    const { history, ms365_information_status, ms365_information_data, ms365_upgrade_paths_data, getMs365Service, getMs365UpgradePaths } = props;
    /***** HOOKS *****/
    const location = useLocation();
    /**
     * @type {{ id: string; subpage: 'admin' | 'manage' }}
     */
    const { id, subpage } = useParams();

    /***** EFFECTS *****/
    useEffect(() => {
        getMs365Service(id);
        getMs365UpgradePaths(id);
    }, []);

    /***** RENDER HELPERS *****/
    const modules = {
        manage: [
            {
                sidebarLink: {
                    label: 'Overview',
                    link: `/my-services/microsoft-365/manage/overview/${id}`
                },
                component: <Overview />,
                condition: true,
                identifier: 'ms365/overview'
            },
            {
                sidebarLink: {
                    label: 'Upgrade/Downgrade',
                    link: `/my-services/microsoft-365/manage/upgrade/${id}`
                },
                component: <Upgrade />,
                condition: !!ms365_upgrade_paths_data,
                identifier: 'ms365/upgrade-downgrade'
            },
            {
                sidebarLink: {
                    label: 'Manage Seats',
                    link: `/my-services/microsoft-365/manage/manage-seats/${id}`
                },
                component: <ManageSeats />,
                condition: true,
                identifier: 'ms365/manage-seats'
            }
        ],

        admin: [
            {
                sidebarLink: {
                    label: 'Delete Subscription',
                    link: `/my-services/microsoft-365/admin/delete-subscription/${id}`
                },
                component: <Delete />,
                condition: true,
                identifier: 'ms365/delete-subscription'
            }
        ]
    };

    const renderTitle = () => {
        if (!ms365_information_data) return '';

        const {
            id,
            attributes: { name },
            included
        } = ms365_information_data;
        const productName = getIncludedObjBasedOnType(included, 'product')?.attributes.name;

        const actions = [
            {
                status: null,
                label: 'Microsoft Azure Management Portal',
                color: 'notice',
                type: 'onClick',
                onClick: (e) => {
                    e.preventDefault();
                    createPopup('https://portal.azure.com/VmeBcSqWnCUgeCbg.onmicrosoft.com');
                }
            },
            {
                status: null,
                label: 'Microsoft 365 Management Portal',
                color: 'notice',
                type: 'onClick',
                onClick: (e) => {
                    e.preventDefault();
                    createPopup(ms365PortalUrl);
                }
            },
            <CustomDropdown
                className="TitleShared__singleDropdown"
                label={({ labelRef, dropdownTitleOnClick }) => (
                    <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                        Microsoft 365 Help
                        <i className="item__icon icon icon-chevron-down" />
                    </button>
                )}
                key="dropdown"
                render={() => (
                    <SelectOptions
                        options={[
                            {
                                label: 'Microsoft 365 Help Articles',
                                onSelect: () => history.push(`/support/support-centre/microsoft-365`)
                            },
                            {
                                label: 'Submit a Technical Support eTicket for this Service',
                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                            },
                            {
                                label: 'Submit a Billing eTicket for this Service',
                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                            }
                        ]}
                    />
                )}
            />
        ];

        return <Title serviceTitle={name} serviceSubTitle={productName} serviceType="microsoft-365" history={history} action={actions} />;
    };

    const manageList = {
        list_title: 'Manage',
        list_items: handleSideMenuOptions(modules, 'manage')
    };

    const adminList = {
        list_title: 'Admin',
        list_items: handleSideMenuOptions(modules, 'admin')
    };

    const sidebarList = [manageList, adminList];

    const handleRenderState = () => {
        if (ms365_information_status === 'loading') return <RequestLoader />;
        if (ms365_information_status === 'error') return <FetchPageError />;

        return (
            <>
                {renderTitle()}
                <SideBarPage sidebarList={sidebarList} className={classNames('ms365')}>
                    <PageSectionModules modules={modules} which={subpage} />
                </SideBarPage>
            </>
        );
    };

    /***** RENDER *****/
    return (
        <>
            <Subnav pathname={location.pathname} />
            {handleRenderState()}
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

Ms365 = connect(
    (state) => ({
        ms365_information_status: state.ms365.ms365_information_status,
        ms365_information_data: state.ms365.ms365_information_data,
        ms365_upgrade_paths_data: state.ms365.ms365_upgrade_paths_data
    }),
    {
        getMs365Service,
        getMs365UpgradePaths
    }
)(Ms365);

export default Ms365;

/**********************************************************************************************************
 *   EXPORTS
 **********************************************************************************************************/
