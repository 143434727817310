/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CancelInvoiceLightbox from './cancelInvoiceLightbox';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { INVOICE_ACTIONS } from './methods';

export const INVOICE_LIGHTBOX_FROM = '/billing/invoices/{{id}}';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show the pay / cancel invoice lightbox if the URL is "/billing/invoices/{{ id }}/{{ action }}", where the action is one of the allowed "ACTIONS"
 *
 * This component manually stores the history so that it can determine if there is something to go back to or should use a default.
 */
function InvoiceUrlLightbox() {
    /***** STATE *****/
    const [history, setHistory] = useState([]);

    /***** HOOKS *****/
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const id = pathArray[3] || '';
    const action = pathArray[4] || '';

    // react-router-dom history, to avoid naming clash with useState history above
    const rrdHistory = useHistory();

    /***** FUNCTIONS *****/
    // If the user came from another url, send them back there. If not, fallback to invoices list
    const closeLightbox = () => {
        function getUrlToGoBackTo() {
            if (history.length < 2 || history[1] === location.pathname) return '/billing/invoices';
            return history[1];
        }

        rrdHistory.push(getUrlToGoBackTo(), { from: INVOICE_LIGHTBOX_FROM });
    };

    /***** EFFECTS *****/
    useEffect(() => {
        setHistory([location.pathname, ...history].slice(0, 2));
    }, [location.pathname]);

    /***** RENDER *****/
    // Only show the lightbox if the url fits the correct format
    if (!location.pathname.startsWith('/billing/invoice') || !id || !Object.values(INVOICE_ACTIONS).includes(action)) return '';

    return (
        <>
            {action === INVOICE_ACTIONS.PAY ? (
                <OverlayLightbox invoiceid={id} title={`Pay Invoice #${id}`} onOpen onClose={closeLightbox} onSuccessClose={closeLightbox} />
            ) : (
                ''
            )}
            {action === INVOICE_ACTIONS.CANCEL ? <CancelInvoiceLightbox id={id} closeLightbox={closeLightbox} /> : ''}
        </>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default InvoiceUrlLightbox;
