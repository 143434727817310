/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useLocation } from 'react-router-dom';
import { isURLValid, renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PageSectionModules.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NDevelopmentModuleFilter } from 'components/DevelopmentModuleFilter/types';

interface PageSectionModulesProps {
    /**
     * Object to which the key is the subpage and the value is
     */
    modules: NDevelopmentModuleFilter.ReadOnlyModules;
    /**
     * The modules to render to render
     */
    which: string;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders sections base on modules for that section
 */
const PageSectionModules: React.FC<PageSectionModulesProps> = ({ modules, which }) => {
    const location = useLocation();
    /***** RENDER *****/
    if (!isURLValid(modules, location.pathname)) {
        return <PageNotFound />;
    }
    return <div className="PageSectionModules">{renderSectionModules(modules[which])}</div>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PageSectionModules;
