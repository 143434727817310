/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ValidationMessage } from 'components/Form/ValidationMessage';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FormItemInnerPrependedText } from 'components/Form/FormItemInner/prependedText';
import { WrapperBox } from 'components/Form/WrapperBox/WrapperBox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';
import { returnErrorAndWarnClass } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FormItemInner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<TFormItemInner.Props>}
 */
const FormItemInner = (props) => {
    const { children, meta, wrapperboxClasses, prepend, validated, disabled, fullWidth, borderOnly } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: ['no-bg'],
        componentName: 'FormItemInner',
        delimiter: '--'
    });

    const { touched, error, warning, pristine, initial } = meta;

    const formItemInnerClass = classNames('FormItemInner form__item__inner', appliedStylingClasses);

    /***** RENDER *****/
    return (
        <div className={formItemInnerClass}>
            <WrapperBox
                fullWidth={fullWidth}
                className={wrapperboxClasses}
                error={returnErrorAndWarnClass(touched, error, warning, pristine) && !validated}
                isPrepended={!!prepend}
                disabled={disabled}
                validatedValid={returnErrorAndWarnClass(touched, validated?.valid, warning, initial)}
                validatedInvalid={returnErrorAndWarnClass(touched, validated && !validated.valid, warning, initial)}
            >
                <FormItemInnerPrependedText>{prepend}</FormItemInnerPrependedText>
                {children}
            </WrapperBox>
            {!borderOnly && <ValidationMessage.ReduxForm.Default {...meta} validated={validated} />}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { FormItemInner };
