/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**
 *
 * @type {{ACTIVE: 'active', TRUTHY: 'truthy', FALSY: 'falsy', VALID: 'valid'}}
 */
export const DynamicFormConditionCheck = {
    TRUTHY: 'truthy',
    FALSY: 'falsy',
    VALID: 'valid',
    ACTIVE: 'active'
};
