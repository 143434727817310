/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext, useCallback, useContext, useId } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TStaffMenuContext = {
    active: string;
    setActive: React.Dispatch<React.SetStateAction<string>>;
};

/**********************************************************************************************************
 *   CONTEXT START
 **********************************************************************************************************/
export const StaffMenuContext = createContext<TStaffMenuContext>({
    setActive: () => {
        throw new Error('StaffMenuContext MUST be used within the StaffMenu');
    },
    active: ''
});

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useStaffMenu = () => {
    /***** HOOKS *****/
    const id = useId();
    const { active: _active, setActive } = useContext(StaffMenuContext);

    /***** FUNCTIONS *****/
    const toggle = useCallback(() => {
        setActive((active) => {
            return active === id ? '' : id;
        });
    }, [setActive, id]);

    /***** RENDER HELPERS *****/
    const active = _active === id;

    /***** RESULT *****/
    return {
        id,
        active,
        toggle,
        setActive
    };
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
