/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChangeNameserversForm from '../forms/changeNameservers';
import CustomNameserversForm from '../forms/customns';
import HostingPresetForm from '../forms/hostingPreset';
import RecordsForm from '../forms/records';
import CustomNameservers from './customns';
import Forwarders from './forwarders';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import SolidTag from 'components/Tags/SolidTag';

/*   ACTIONS
 *****************************************************/
import { isValid } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { updateDnsConfiguration } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { configKeys } from '../forms/changeNameservers/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../forms/changeNameservers/consts').TConfigKeys} TConfigKeys
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class _ManageDomainDNS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            template: {
                /**
                 * @type {TConfigKeys}
                 */
                key: configKeys.FREE_DNS,
                title: 'DNS Hosting',
                desc: 'This will allow you to manage A, AAAA, MX, CAA CNAME, TXT, NS, SRV and ALIAS records of the domain name zone without the need for a hosting account or a third party DNS provider.'
            },
            /**
             * @type {TConfigKeys | ''}
             */
            active: '',
            showAddPresetLightbox: false,
            showConfirmLightbox: false,
            /**
             * @type {{ service_id: number } | { nameservers: string[] } | undefined }}
             */
            showConfirmData: undefined,
            /**
             * @type {string[]}
             */
            newNameservers: []
        };
        this.changeView = this.changeView.bind(this);
        this.handleDnsUpdate = this.handleDnsUpdate.bind(this);
        this.toggleConfirmLightbox = this.toggleConfirmLightbox.bind(this);
        this.getBoxLoadingStatus = this.getBoxLoadingStatus.bind(this);
        this.getLoadingStatus = this.getLoadingStatus.bind(this);
    }

    /*   CHANGE VIEW METHODS
     *****************************************************/
    /**
     * @param {TConfigKeys} key
     */
    changeView(key) {
        const { active } = this.state;

        switch (key) {
            case configKeys.FREE_DNS:
                return this.setState({
                    template: {
                        key: configKeys.FREE_DNS,
                        title: `DNS Hosting`,
                        desc: `This will allow you to manage A, AAAA, MX, CAA, CNAME, TXT, NS, SRV and ALIAS records of the domain name zone without the need for a hosting account or a third party DNS provider.`
                    }
                });

            case configKeys.CUSTOM_HOSTING:
                return this.setState({
                    template: {
                        key: configKeys.CUSTOM_HOSTING,
                        title: `Custom Nameservers`,
                        desc: `Custom Nameservers will direct all traffic for your domain name to the services designated on those Nameservers.`
                    }
                });

            case configKeys.FORWARDING:
                return this.setState({
                    template: {
                        key: configKeys.FORWARDING,
                        title: `Forwarding`,
                        desc: `This allows you to setup HTML, Cloak, 301 Permanent and 302 Temporary redirects which allow you to redirect users to other domains. You can also setup Email Forwarding to redirect emails to other email addresses.`
                    }
                });

            case configKeys.VENTRAIP_HOSTING:
                return this.setState({
                    template: {
                        key: configKeys.VENTRAIP_HOSTING,
                        title: `${company} Hosting`,
                        desc: `This will allow you to connect your domain name to a ${company} hosting service.`
                    }
                });

            case configKeys.PARK_DOMAIN:
                return this.setState({
                    template: {
                        key: configKeys.PARK_DOMAIN,
                        title: active !== configKeys.PARK_DOMAIN ? `Park Domain` : false,
                        desc:
                            active !== configKeys.PARK_DOMAIN ? `Don't need to use your domain name just yet? You can park it for future use.` : false
                    }
                });

            default:
                return this.setState({
                    template: null
                });
        }
    }

    /*   SUBMIT METHODS
     *****************************************************/
    handleDnsUpdate() {
        const { match } = this.props;
        const { params } = match;
        const { id } = params;
        const { template, showConfirmData } = this.state;
        const { CUSTOM_HOSTING, FORWARDING, VENTRAIP_HOSTING } = configKeys;

        const attributes = {
            dns_type: template.key
        };

        if (/** @type {Array<TConfigKeys>}*/ ([FORWARDING, CUSTOM_HOSTING]).includes(template.key)) {
            attributes.nameservers = showConfirmData;
        }

        if (template.key === VENTRAIP_HOSTING) {
            // if it's already hosting then changing will take it to custom nameservers
            if ('service_id' in (showConfirmData ?? {})) {
                attributes.service_id = showConfirmData?.service_id;
            } else {
                attributes.nameservers = showConfirmData;
                attributes.dns_type = CUSTOM_HOSTING;
            }
        }

        return updateDnsConfiguration(id, attributes);
    }

    /*   TOGGLE LIGHTBOXES
     *****************************************************/
    /**
     * @param {TConfigKeys} [type]
     */
    toggleConfirmLightbox(type) {
        return (data) => {
            const { showConfirmLightbox } = this.state;
            const { hosting_list_data } = this.props;
            const { CUSTOM_NAMESERVERS, CUSTOM_HOSTING, FREE_DNS, FORWARDING, PARK_DOMAIN, VENTRAIP_HOSTING } = configKeys;

            if (type && /** @type {Array<TConfigKeys>}*/ ([CUSTOM_NAMESERVERS, CUSTOM_HOSTING]).includes(type)) {
                const newNameservers = Object.values(data)
                    .filter((value) => value !== null)
                    .reduce((acc, val) => acc.concat(val), []);

                this.setState({ newNameservers });
            }

            if (type && /** @type {Array<TConfigKeys>}*/ ([FREE_DNS, FORWARDING, PARK_DOMAIN]).includes(type)) {
                const newNameservers = ['ns1.nameserver.net.au', 'ns2.nameserver.net.au', 'ns3.nameserver.net.au'];

                this.setState({ newNameservers });
            }

            // hosting_list_data should always be defined, but in case, the nameservers fall back to 'unknown'
            if (type === VENTRAIP_HOSTING) {
                const { included } = (hosting_list_data ?? []).find(({ id }) => id === data.service_id);
                const { attributes } = included?.find(({ type }) => type === 'server') ?? {};

                const getValues = (attributes) =>
                    Object.entries(attributes)
                        .filter(([key]) => key.includes('Nameserver'))
                        .map(([, value]) => value);

                const newNameservers = attributes ? getValues(attributes) : ['unknown'];

                this.setState({ newNameservers });
            }

            this.setState({
                showConfirmLightbox: !showConfirmLightbox,
                showConfirmData: data ? data : false
            });
        };
    }

    /*    Loading Status
     *****************************************************/
    getLoadingStatus() {
        const { domain_current_configuration_status, domain_third_party_status, domain_record_status } = this.props;

        if ([domain_current_configuration_status, domain_third_party_status, domain_record_status].includes('loading')) {
            return 'loading';
        } else if (domain_current_configuration_status === 'error') {
            return 'error';
        }

        return 'success';
    }

    getBoxLoadingStatus() {
        const { hosting_list_status, domain_third_party_status } = this.props;

        if (hosting_list_status === 'loading' || domain_third_party_status === 'loading') {
            return 'loading';
        }

        if (hosting_list_status === 'error') {
            return 'error';
        }

        return 'success';
    }

    /*   LIFECYCLE METHODS
     *****************************************************/
    componentDidMount() {
        const { domain_dns_view } = this.props;

        this.setState(
            {
                active: domain_dns_view.config
            },
            () => {
                this.changeView(domain_dns_view.config);
            }
        );
    }

    componentDidUpdate(prevProps) {
        const { domain_current_configuration_status, domain_update_current_configuration_status, domain_dns_view } = this.props;
        registerScrollEvents(this, domain_current_configuration_status === 'success' && prevProps.domain_current_configuration_status === 'loading');

        if (
            (domain_update_current_configuration_status === 'success' && prevProps.domain_update_current_configuration_status === 'loading') ||
            (domain_update_current_configuration_status === 'error' && prevProps.domain_update_current_configuration_status === 'loading')
        ) {
            this.setState(
                {
                    showAddPresetLightbox: false,
                    showConfirmLightbox: false,
                    showConfirmData: false,
                    active: domain_dns_view.config
                },
                () => {
                    this.changeView(domain_dns_view.config);
                }
            );
        }
    }

    render() {
        const { app_viewport, domain_information_data } = this.props;
        const { template, active, showConfirmLightbox, newNameservers } = this.state;
        const { changeView, handleDnsUpdate, toggleConfirmLightbox, getBoxLoadingStatus, getLoadingStatus } = this;

        /**
         * Function to render the content of the box component
         */
        const handleBoxContentRender = () => {
            /**
             * @param {string} app_viewport
             */
            const tagRender = (app_viewport) => {
                if (!['xs', 'sm'].includes(app_viewport)) {
                    return (
                        <SolidTag color="info" className="manageDNS__currentTag">
                            Current Configuration
                        </SolidTag>
                    );
                }

                return (
                    <Tooltip
                        className="checkIcon"
                        direction="up"
                        distance={10}
                        arrowSize={5}
                        content={<div className="checkIcon__tooltip">Current Configuration</div>}
                    />
                );
            };

            /**
             * @param {TConfigKeys} key
             * @param {TConfigKeys} active
             */
            const selectContentFromTemplateKey = (key, active) => {
                const { CUSTOM_HOSTING, CUSTOM_NAMESERVERS, VENTRAIP_HOSTING, FORWARDING, PARK_DOMAIN, FREE_DNS } = configKeys;

                const customNameservers = () => {
                    if (/** @type {Array<TConfigKeys>} */ ([CUSTOM_HOSTING, CUSTOM_NAMESERVERS]).includes(active)) {
                        return <CustomNameservers onSubmit={toggleConfirmLightbox(CUSTOM_NAMESERVERS)} />;
                    }

                    return <CustomNameserversForm onSubmit={toggleConfirmLightbox(CUSTOM_NAMESERVERS)} />;
                };

                const ventraIPHosting = () =>
                    VENTRAIP_HOSTING === active ? (
                        <CustomNameservers onSubmit={toggleConfirmLightbox(CUSTOM_NAMESERVERS)} />
                    ) : (
                        <HostingPresetForm onSubmit={toggleConfirmLightbox(VENTRAIP_HOSTING)} />
                    );

                const freeDNS = () => (active !== FREE_DNS ? null : <RecordsForm parent={this} />);

                const forwarding = () => (active !== FORWARDING ? null : <Forwarders domainid={domain_information_data.id} />);

                const parkDomain = () => {
                    if (active !== PARK_DOMAIN) return null;

                    return (
                        <div className="parkedDomain__container">
                            <i className="icon icon-uptime parkedDomain__icon"></i>
                            <div className="parkedDomain__text">
                                <div className="parkedDomain__title">Your domain name is currently parked</div>
                                <div className="parkedDomain__desc">
                                    To connect your domain name to a hosting or email service, select the appropriate option above.
                                </div>
                            </div>
                        </div>
                    );
                };

                switch (key) {
                    case CUSTOM_HOSTING:
                        return customNameservers();
                    case VENTRAIP_HOSTING:
                        return ventraIPHosting();
                    case FREE_DNS:
                        return freeDNS();
                    case FORWARDING:
                        return forwarding();
                    case PARK_DOMAIN:
                        return parkDomain();
                    default:
                        return null;
                }
            };

            /**
             * @param {'loading' | 'success' | 'error'} status
             */
            const renderContent = (status) => {
                switch (status) {
                    case 'loading':
                        return <RequestLoader />;
                    case 'success':
                        return selectContentFromTemplateKey(template.key, active);
                    case 'error':
                    default:
                        return null;
                }
            };

            return (
                <>
                    {isValid(active) && active === template.key ? tagRender(app_viewport) : null}
                    <div className="manageDNS__content">{renderContent(getLoadingStatus())}</div>
                </>
            );
        };

        /**
         * function to generate conditional props for the box component, this can be adjusted when moved to NXBox
         *
         * @param {TConfigKeys} key
         * @param {TConfigKeys} active
         */
        const generateBoxConditionalProps = (key, active) => {
            const { PARK_DOMAIN, FREE_DNS, FORWARDING } = configKeys;

            const conditionalProps = {
                action: {
                    label: 'Enable',
                    type: 'onClick',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        toggleConfirmLightbox(key)();
                    }
                }
            };

            switch (true) {
                case key === PARK_DOMAIN && active !== PARK_DOMAIN:
                    return {
                        ...conditionalProps,
                        info: `Parked domains allow you to use additional domains (which you own/have registered) with your web hosting service(s) for the purposes of redirection, mirroring and hosting other websites. It can also be used to hold onto a domain if you don't need it just yet.`
                    };
                case key === FREE_DNS && active !== FREE_DNS:
                    return conditionalProps;
                case key === FORWARDING && active !== FORWARDING:
                    return conditionalProps;
                default:
                    return null;
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <section
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="manageDNS"
            >
                <Box
                    premounted={true}
                    className="manageDNS__box"
                    status={getBoxLoadingStatus()}
                    title={template.title}
                    desc={template.desc}
                    tabs={{
                        condition: template.key,
                        active: active,
                        data: [
                            {
                                key: configKeys.VENTRAIP_HOSTING,
                                title: company,
                                icon: 'acquisitions',
                                action: (e) => {
                                    e.preventDefault();
                                    changeView(configKeys.VENTRAIP_HOSTING);
                                }
                            },
                            {
                                key: configKeys.CUSTOM_HOSTING,
                                title: 'Custom Nameservers',
                                icon: 'dns-records',
                                action: (e) => {
                                    e.preventDefault();
                                    changeView(configKeys.CUSTOM_HOSTING);
                                }
                            },
                            {
                                key: configKeys.FREE_DNS,
                                title: 'DNS Hosting',
                                icon: 'cloud-hardware',
                                action: (e) => {
                                    e.preventDefault();
                                    changeView(configKeys.FREE_DNS);
                                }
                            },
                            {
                                key: configKeys.FORWARDING,
                                title: 'Forwarding',
                                icon: 'email-web-forwarding',
                                action: (e) => {
                                    e.preventDefault();
                                    changeView(configKeys.FORWARDING);
                                }
                            },
                            {
                                key: configKeys.PARK_DOMAIN,
                                title: 'Park Domain',
                                icon: 'car2',
                                action: (e) => {
                                    e.preventDefault();
                                    changeView(configKeys.PARK_DOMAIN);
                                }
                            }
                        ]
                    }}
                    custom={{
                        render: handleBoxContentRender(),
                        pos: `bottom`
                    }}
                    {...generateBoxConditionalProps(template.key, active)}
                />
                {showConfirmLightbox ? (
                    <OverlayLightbox
                        onOpen
                        onClose={toggleConfirmLightbox()}
                        className="manageDNS__confirm"
                        title="Current DNS settings will be overridden"
                    >
                        <ChangeNameserversForm
                            newNameservers={newNameservers}
                            onCancel={toggleConfirmLightbox()}
                            newDNS={template.key}
                            onSubmit={handleDnsUpdate}
                        />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </section>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport,
        domain_current_configuration_status: state.domain.domain_current_configuration_status,
        domain_dns_view: state.domain.domain_dns_view,
        domain_information_data: state.domain.domain_information_data,
        domain_record_status: state.domain.domain_record_status,
        domain_third_party_status: state.domain.domain_third_party_status,
        domain_update_current_configuration_status: state.domain.domain_update_current_configuration_status,
        hosting_list_status: state.services.hosting_list_status,
        hosting_list_data: state.services.hosting_list_data
    };
};

let ManageDomainDNS = connect(mapStateToProps)(_ManageDomainDNS);

ManageDomainDNS = withRouter(ManageDomainDNS);

export default ManageDomainDNS;
