/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { loginTokenCheck, requestAccounts } from '../../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *
 * @param {{
 *      resendPushNotificationCount: number
 * }} props
 */
function VipcAppTwoFactorPolling({ resendPushNotificationCount }) {
    /***** EFFECTS *****/
    useEffect(() => {
        function checkToken() {
            loginTokenCheck({
                successCallback: () => {
                    requestAccounts();
                    clearInterval(intervalId);
                    clearTimeout(timeoutId);
                }
            });
        }

        const intervalId = setInterval(() => {
            checkToken();
        }, 10000);

        const timeoutId = setTimeout(() => clearInterval(intervalId), 300000);

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [resendPushNotificationCount]);

    /***** RENDER *****/
    return <RequestLoader />;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default VipcAppTwoFactorPolling;
