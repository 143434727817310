/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { BlipAppliedStylingPropKeys } from 'components/Blip/consts';
import './_Blip.scss';

const BlipClassName = 'Blip';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NBlip.Props>}
 */
const Blip = (props) => {
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: BlipAppliedStylingPropKeys,
        componentName: BlipClassName,
        delimiter: '--'
    });

    const blipClassNames = classNames(BlipClassName, appliedStylingClasses);

    /***** RENDER *****/
    return <div className={blipClassNames} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Blip;
