/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { tiers } from 'containers/vipRewards/consts';

import BronzeTier from 'assets/images/vipRewards/vipreward-bronze.svg';
import GoldTier from 'assets/images/vipRewards/vipreward-gold.svg';
import PlatinumTier from 'assets/images/vipRewards/vipreward-platinum.svg';
import SilverTier from 'assets/images/vipRewards/vipreward-silver.svg';
import VipTier from 'assets/images/vipRewards/vipreward-vip.svg';

const tierOptions = Object.values(tiers);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *   tier: ValueOf<typeof tiers>;
 *   className?: string;
 * }} props 
 */
function VipRewardsTierIcon({ tier, className }) {
    /***** RENDER HELPERS *****/
    const getTierIconImageSrc = () => {
        switch (tier) {
            case tiers.VIP:
                return VipTier;
            case tiers.BRONZE:
                return BronzeTier;
            case tiers.SILVER:
                return SilverTier;
            case tiers.GOLD:
                return GoldTier;
            case tiers.PLATINUM:
                return PlatinumTier;
            default:
                return '';
        }
    };

    /***** RENDER *****/
    return <img src={getTierIconImageSrc()} className={className} alt={tier} />;
}
/**********************************************************************************************************
 *   COMPONENT end
 **********************************************************************************************************/

VipRewardsTierIcon.propTypes = {
    /**
     * What tier the customer is currently in
     */
    tier: PropTypes.oneOf(tierOptions)
};

export default VipRewardsTierIcon;
