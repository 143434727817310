/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import upgradeimg from 'assets/images/ms365/upgrade.svg';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import ChangePlanLightbox from 'components/Lightboxes/OverlayLightbox/Components/changePlan';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { calculateUpgradeMs365Plan, getMs365Service, getMs365UpgradePaths, upgradeMs365Plan } from '../action';
import { ms365Methods } from '../methods';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Upgrade extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceLightboxId: null,
            showUpgradeLightbox: false,
            lightboxTemplate: lightboxModes.CHOOSE_PLAN,
            selectedUpgradeMeta: {
                confirm: [],
                id: null,
                total: null,
                type: 'upgrade',
                selectedPlanName: null
            }
        };

        this.toggleLightboxTemplate = this.toggleLightboxTemplate.bind(this);
    }

    toggleLightboxTemplate(showUpgradeLightbox = false, lightboxTemplate = lightboxModes.CHOOSE_PLAN, invoiceId = null) {
        this.setState({
            showUpgradeLightbox,
            lightboxTemplate,
            invoiceLightboxId: invoiceId
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { ms365_information_status, ms365_upgrade_paths_status } = this.props;

        if (ms365_upgrade_paths_status === 'success' && ms365_information_status === 'success') {
            registerScrollEvents(this, true);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            ms365_upgrade_calculate_status,
            ms365_upgrade_calculate_data,
            ms365_information_status,
            ms365_upgrade_paths_status,
            ms365_upgrade_status,
            ms365_upgrade_data,
            getMs365Service,
            getMs365UpgradePaths,
            match: {
                params: { id }
            }
        } = this.props;
        const { showUpgradeLightbox, selectedUpgradeMeta } = this.state;
        const { toggleLightboxTemplate } = this;

        registerScrollEvents(
            this,
            ms365_information_status === 'success' &&
                ms365_upgrade_paths_status === 'success' &&
                (prevProps.ms365_information_status === 'loading' || prevProps.ms365_upgrade_paths_status === 'loading')
        );

        if (
            ms365_upgrade_calculate_status === 'success' &&
            prevProps.ms365_upgrade_calculate_status === 'loading' &&
            showUpgradeLightbox &&
            selectedUpgradeMeta &&
            selectedUpgradeMeta.confirm
        ) {
            this.setState({
                lightboxTemplate: lightboxModes.CONFIRM,
                selectedUpgradeMeta: {
                    ...selectedUpgradeMeta,
                    total: ms365_upgrade_calculate_data.attributes.new_plan_cost
                }
            });
        }

        if (ms365_upgrade_calculate_status === 'error' && prevProps.ms365_upgrade_calculate_status === 'loading') {
            toggleLightboxTemplate(true, lightboxModes.ERROR);
        }

        if (ms365_upgrade_status === 'error' && prevProps.ms365_upgrade_status === 'loading') {
            toggleLightboxTemplate();
        }

        if (ms365_upgrade_status === 'success' && prevProps.ms365_upgrade_status === 'loading') {
            if (ms365_upgrade_data?.type === 'invoice') {
                return toggleLightboxTemplate(true, lightboxModes.INVOICE, ms365_upgrade_data.id);
            }

            toggleLightboxTemplate();
            getMs365Service(id);
            getMs365UpgradePaths(id);
        }
    }

    render() {
        const {
            ms365_information_data,
            ms365_information_status,
            ms365_upgrade_status,
            ms365_upgrade_paths_status,
            calculateUpgradeMs365Plan,
            ms365_upgrade_calculate_status,
            ms365_upgrade_calculate_data,
            match: {
                params: { id }
            },
            getMs365Service,
            getMs365UpgradePaths,
            ms365_upgrade_paths_data: data
        } = this.props;
        const { invoiceLightboxId, lightboxTemplate, showUpgradeLightbox, selectedPlanName } = this.state;
        const { toggleLightboxTemplate } = this;

        const productName = getIncludedObjBasedOnType(ms365_information_data?.included, 'product')?.attributes.name;
        const invoiceID = ms365Methods.getInvoiceId(ms365_information_data);

        const getLoadingStatus = () => {
            if ([ms365_upgrade_paths_status, ms365_upgrade_status].includes('loading')) return 'loading';
            return 'success';
        };

        /*   RENDER LIGHTBOX
         **********************************************************************************************************/
        const renderLightbox = () => {
            switch (lightboxTemplate) {
                case lightboxModes.INVOICE:
                    return (
                        <OverlayLightbox
                            title={'Pay Invoice #' + invoiceLightboxId}
                            invoiceid={invoiceLightboxId}
                            onOpen={showUpgradeLightbox && lightboxTemplate === lightboxModes.INVOICE}
                            onClose={() => {
                                getMs365Service(id);
                                getMs365UpgradePaths(id);
                                toggleLightboxTemplate();
                            }}
                            onSuccessClose={() => toggleLightboxTemplate()}
                        />
                    );

                case lightboxModes.CHOOSE_PLAN:
                default: {
                    const name = ms365_information_data.included[0]?.attributes?.name;

                    const planOptions = data.map((data) => {
                        return {
                            name: data.planName,
                            description: data.desc,
                            price: (_) => data.configList[0].monthlyCost,
                            priceFrequency: 'Monthly',
                            details: [],
                            rawData: data
                        };
                    });

                    const onSelectPlan = (plan) => {
                        const { name } = plan;

                        calculateUpgradeMs365Plan(id, {
                            billing_cycle: 'Monthly',
                            product_id: plan.rawData.planId
                        });

                        this.setState({
                            selectedPlanName: name
                        });
                    };

                    const reviewData = {
                        name: selectedPlanName,
                        price: ms365_upgrade_calculate_data?.attributes.new_plan_cost,
                        total: ms365_upgrade_calculate_data?.attributes.amount_due,
                        refund: ms365_upgrade_calculate_data?.attributes.refunded_amount
                    };

                    const onConfirm = ({ rawData }) => {
                        const {
                            upgradeMs365Plan,
                            match: {
                                params: { id }
                            }
                        } = this.props;

                        upgradeMs365Plan(id, {
                            product_id: rawData.planId,
                            billing_cycle: 'Monthly'
                        });
                    };

                    const closeLightbox = () => {
                        toggleLightboxTemplate(false);
                    };

                    return (
                        <ChangePlanLightbox
                            isOpen={true}
                            billingCycle={{ current: 'Monthly', available: ['Monthly'] }}
                            upgradeType="Microsoft 365"
                            planOptions={planOptions}
                            currentPlan={name}
                            onClose={closeLightbox}
                            onSelectPlan={onSelectPlan}
                            onConfirm={onConfirm}
                            customCycleText={() => <span>/Seat/Month*</span>}
                            isLoading={ms365_information_status === 'loading' || ms365_upgrade_calculate_status === 'loading'}
                            reviewData={reviewData}
                        />
                    );
                }
            }
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div ref={(el) => (this.scrollRef = el)} className="ms365Upgrade">
                <Box
                    premounted
                    className="ms365Upgrade__box"
                    status={getLoadingStatus()}
                    override={
                        <div className="ms365Upgrade__container">
                            <div className="ms365Upgrade__wrapper--top">
                                <div className="ms365Upgrade__title">Need to upgrade your Microsoft 365 Subscription?</div>
                                <div className="ms365Upgrade__description">
                                    Upgrade your Microsoft 365 Subscription to get more storage space and advanced features.
                                </div>
                                <img className="ms365Upgrade__image" src={upgradeimg} alt="Microsoft 365 Upgrade" />
                            </div>
                            <div className="ms365Upgrade__wrapper--bottom">
                                <div className="ms365Upgrade__information">
                                    <div className="ms365Upgrade__title">Current Plan</div>
                                    <div className="ms365Upgrade__description">{productName}</div>
                                </div>
                                <div className="ms365Upgrade__action">
                                    {invoiceID ? (
                                        <InactiveButton className="ms365Upgrade__button">View Plans</InactiveButton>
                                    ) : (
                                        <OutlineButton
                                            type="onClick"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleLightboxTemplate(true, lightboxModes.CHOOSE_PLAN);
                                            }}
                                            className="ms365Upgrade__button"
                                        >
                                            View Plans
                                        </OutlineButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                />
                {showUpgradeLightbox ? renderLightbox() : ''}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            ms365_information_status: state.ms365.ms365_information_status,
            ms365_information_data: state.ms365.ms365_information_data,
            ms365_upgrade_paths_status: state.ms365.ms365_upgrade_paths_status,
            ms365_upgrade_paths_data: state.ms365.ms365_upgrade_paths_data,
            ms365_upgrade_calculate_status: state.ms365.ms365_upgrade_calculate_status,
            ms365_upgrade_calculate_data: state.ms365.ms365_upgrade_calculate_data,
            ms365_upgrade_status: state.ms365.ms365_upgrade_status,
            ms365_upgrade_data: state.ms365.ms365_upgrade_data
        }),
        {
            calculateUpgradeMs365Plan,
            getMs365Service,
            getMs365UpgradePaths,
            upgradeMs365Plan
        }
    )(Upgrade)
);

const lightboxModes = {
    CHOOSE_PLAN: 'choose_plan',
    LOADING: 'loading',
    CONFIRM: 'confirm',
    ERROR: 'error',
    INVOICE: 'invoice'
};
