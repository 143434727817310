/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_MessagePlaceholder.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Message extends Component {
    render() {
        const { message, icon } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="MessagePlaceholder">
                {icon ? <i className={`icon icon-${icon}`}></i> : ''}
                <div className="MessagePlaceholder__text">{message}</div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

Message.propTypes = {
    /**
     * Message - This is the text of the message to render in VentraIP Grey,
     */
    message: PropTypes.string,

    /**
     * Icon - This is the icon to render
     */
    icon: PropTypes.string
};

export default Message;
