/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface RecordHealthProps {
    configured: boolean | undefined | null | 'loading';
}
const RecordHealth: React.FC<RecordHealthProps> = ({ configured }) => {
    /***** RENDER *****/
    switch (configured) {
        case 'loading':
            return <SolidTag color="warning">Checking Configuration...</SolidTag>;
        case true:
            return (
                <SolidTag color="success">
                    Correctly Configured &nbsp;
                    <PhosphorIcons.CheckFat.Fill size={14} />
                </SolidTag>
            );
        default:
            return (
                <SolidTag color="error">
                    Not Configured &nbsp; <PhosphorIcons.X.Bold size={14} />
                </SolidTag>
            );
    }
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default RecordHealth;
