/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

import GSuiteManageSeatsForm from '../forms/manageSeats';

import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getGSuiteSeatsPricing, getGSuiteService } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteManageSeats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInvoiceLightbox: false,
            invoiceID: null,
            showManageDropdown: false,
            service: {
                domain: ''
            }
        };
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleManageDropdown = this.toggleManageDropdown.bind(this);
        this.scrollRef = createRef();
    }

    /************** OPEN/CLOSE PAY INVOICE **************/
    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceID: id
        });
    }

    closePayInvoice() {
        const { gsuiteid } = this.props;

        this.setState(
            {
                showInvoiceLightbox: false
            },
            () => {
                getGSuiteService(gsuiteid);
            }
        );
    }

    closePayInvoiceSuccess() {
        const { gsuiteid } = this.props;
        this.setState(
            {
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getGSuiteService(gsuiteid);
            }
        );
    }

    /************** HANDLE ACTIONS **************/

    toggleManageDropdown() {
        const { showManageDropdown } = this.state;

        this.setState({
            showManageDropdown: !showManageDropdown
        });
    }

    componentDidMount() {
        const { gsuite_service_data } = this.props;

        if (gsuite_service_data) {
            const { attributes, included } = gsuite_service_data;
            const invoice = getIncludedObjBasedOnType(included, 'invoice');

            this.setState(
                {
                    service: { ...attributes },
                    invoiceID: invoice ? invoice.id : false
                },
                () => {
                    registerScrollEvents(this, true);
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { gsuite_purchase_status, gsuite_purchase_data, location, gsuiteid } = this.props;
        const { openPayInvoice } = this;

        if (gsuite_purchase_status === 'success' && prevProps.gsuite_purchase_status === 'loading') {
            const isPurchaseSeatsInvoicePaid = gsuite_purchase_data?.attributes?.status === 'paid';

            if (!isPurchaseSeatsInvoicePaid) {
                openPayInvoice(gsuite_purchase_data.id);
            } else {
                getGSuiteService(gsuiteid);
            }
        }

        if (gsuite_purchase_status === 'error' && prevProps.gsuite_purchase_status === 'loading') {
            getGSuiteService(gsuiteid);
        }

        if (location !== prevProps.location && location.pathname.includes('manage-seats')) {
            const query = new URLSearchParams(location.search);
            const open = query.get('open');

            if (open) {
                this.toggleManageDropdown();
                scroll.scrollTo(this.scrollRef.getBoundingClientRect().top);
            }
        }
    }

    render() {
        const { gsuiteid, getGSuiteSeatsPricing, gsuite_purchase_status, gsuite_seat_pricing_status, gsuite_service_status, gsuite_service_data } =
            this.props;
        const { showManageDropdown, service, showInvoiceLightbox, invoiceID } = this.state;
        const { closePayInvoice, closePayInvoiceSuccess, toggleManageDropdown } = this;
        const { attributes, id } = gsuite_service_data;
        const { number_of_seats } = attributes;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="gsuiteManageSeats"
            >
                <Box
                    request={{
                        action: getGSuiteSeatsPricing,
                        args: [id, number_of_seats],
                        status: gsuite_seat_pricing_status
                    }}
                    className="gsuiteManageSeats__box"
                    title="Manage Seats"
                    desc={htmr(`Add or remove seats to <strong>${service.domain}</strong>`)}
                    status={gsuite_service_status}
                    action={{
                        buttonType: invoiceID ? 'Inactive' : 'Outline',
                        label: 'Manage Seats',
                        type: 'onClick',
                        className: 'gsuiteManageSeats__button',
                        color: 'secondary',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            toggleManageDropdown();
                        }
                    }}
                    dropdown={{
                        title: 'Adjust your seats below',
                        render: <GSuiteManageSeatsForm gsuiteid={gsuiteid} />,
                        condition: showManageDropdown,
                        close: toggleManageDropdown,
                        status: gsuite_purchase_status
                    }}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceID}
                        invoiceid={invoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_service_status: state.gsuite.gsuite_service_status,
        gsuite_service_data: state.gsuite.gsuite_service_data,
        gsuite_purchase_status: state.gsuite.gsuite_purchase_status,
        gsuite_purchase_data: state.gsuite.gsuite_purchase_data,
        gsuite_seat_pricing_status: state.gsuite.gsuite_seat_pricing_status
    };
};

const mapDispatchToProps = {
    getGSuiteSeatsPricing
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteManageSeats));
