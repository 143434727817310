/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ErrorThrower from 'components/Errors/ErrorBoundary/errorThrower';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import errorImage from 'assets/images/something-went-wrong-page.png';
import './_ErrorBoundary.scss';

/***** RENDER HELPERS *****/
const CustomErrorFallback = () => {
    const toDashboard = () => {
        window.location.href = '/';
    };

    const tryAgain = () => {
        window.location.reload();
    };

    return (
        <div className="ErrorBoundary__align">
            <div className="ErrorBoundary__center">
                <img src={errorImage} alt="something went wrong" className="ErrorBoundary__img" />
                <Text bold black align--center size--xxxl>
                    <h1>Something went wrong...</h1>
                </Text>
                <br />
                <Text size--xl secondary align--center>
                    We are working on fixing the problem. Please try again.
                </Text>
                <div className="ErrorBoundary__buttonWrapper">
                    <SolidButton type="onClick" onClick={tryAgain} className="ErrorBoundary__refresh">
                        Try Again
                    </SolidButton>
                    <SolidButton type="onClick" onClick={toDashboard} className="ErrorBoundary__refresh">
                        To Dashboard
                    </SolidButton>
                </div>
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************
 * An Base level error boundary that is used to catch errors at the highest level of the application.
 * Lower level errors can be caught by Error boundaries further within the tree.
 */
const CustomErrorBoundary = ({
    children,
    fallback = CustomErrorFallback,
    onError = (error, componentStack, eventId) => {},
    onReset = (error, componentStack, eventId) => {
        window.location.reload();
    }
}) => {
    const isErrorThrowerShown = import.meta.env.MODE === 'development' && import.meta.env.VITE_SHOW_ERROR_BOUNDARY_ERROR_BUTTON === 'true';
    /***** RENDER *****/
    return (
        <Sentry.ErrorBoundary fallback={fallback} onError={onError} onReset={onReset}>
            {isErrorThrowerShown ? <ErrorThrower /> : ''}
            {children}
        </Sentry.ErrorBoundary>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CustomErrorBoundary.propTypes = {
    /**
     * The fallback component to render when an error is caught.
     */
    fallback: PropTypes.func,

    /**
     * A callback that will be called when an error is caught.
     */
    onError: PropTypes.func,

    /**
     * A callback that will be called when the error is reset.
     */
    onReset: PropTypes.func,

    /**
     * The children to render.
     */
    children: PropTypes.node.isRequired
};

export default CustomErrorBoundary;
