/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { entries, get, has, merge } from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const businessTransformPropeties = {
    address: 'address',
    email: 'email',
    name: 'name',
    operating_hours: 'operating_hours',
    phone: 'phone',
    tag_line: 'tag_line',
    business_number_type: 'business_number_type',
    business_number: 'business_number'
};

const navigationTransformProperties = {
    show_socials_in_header: 'show_socials_in_header',
    show_socials_in_footer: 'show_socials_in_footer',
    style: 'style',
    call_to_action_type: 'call_to_action.type',
    call_to_action_enabled: 'call_to_action.enabled',
    call_to_action_value: 'call_to_action.value',
    call_to_action_text: 'call_to_action.text',
    call_to_action_section_id: 'call_to_action.section_id'
};

const coloursFontsTransformProperties = {
    color: 'color',
    style: 'style'
};

/**
 *
 * @param {
 *      | typeof coloursFontsTransformProperties
 *      | typeof navigationTransformProperties
 *      | typeof businessTransformPropeties
 *  } transformProperties
 * @param {Record<string,any>} activeFormValues
 * @param {"navigation" | "navigation_items" | "socials" | "site" | "business"} mergeKey
 */
function createReducedTransformKeys(transformProperties, activeFormValues, mergeKey) {
    return entries(transformProperties).reduce((acc, [key, value]) => {
        if (has(activeFormValues, value)) {
            return merge(acc, { [mergeKey]: { [key]: get(activeFormValues, value) } });
        }
        return acc;
    }, {});
}

/**
 * @param {Record<string,any>} activeFormValues
 * @param {string} subpage
 * @returns {Partial<KatanaSitePreviewAttributes>}
 */
export function useTransformedActiveFormValues(activeFormValues, subpage) {
    return useMemo(() => {
        switch (subpage) {
            case 'colours-fonts': {
                const colourFontEntries = createReducedTransformKeys(coloursFontsTransformProperties, activeFormValues, 'site');
                return colourFontEntries;
            }
            case 'navigation-and-socials': {
                const navigationEntries = createReducedTransformKeys(navigationTransformProperties, activeFormValues, 'navigation');

                const socialsEntries = has(activeFormValues, 'social_links') ? { socials: get(activeFormValues, 'social_links') } : {};

                /**
                 * @type {NNavigationLabels.MappedNavigationLabelsToSections[]}
                 */
                const sections = get(activeFormValues, 'sections');
                const navigation_items = activeFormValues?.section_ids?.map?.((sectionID, index) => {
                    /** @type {KatanaNavigationItem} */
                    const navigationItem = {
                        targetable_type: 'site_section',
                        targetable_id: sectionID,
                        order: index
                    };

                    const foundSection = sections?.find((section) => section.id === sectionID);
                    if (foundSection) {
                        navigationItem.name = foundSection.name;
                    }

                    return navigationItem;
                });
                const navigationItems = has(activeFormValues, 'section_ids') ? { navigation_items } : {};

                return merge({}, navigationEntries, socialsEntries, navigationItems, sections);
            }

            case 'contact-information':
            case 'basic-details': {
                const colourFontEntries = createReducedTransformKeys(coloursFontsTransformProperties, activeFormValues, 'site');
                const logoEntry = has(activeFormValues, 'logo.url') ? { business: { logo: get(activeFormValues, 'logo.url') } } : {};
                const businessEntries = createReducedTransformKeys(businessTransformPropeties, activeFormValues, 'business');

                return merge({}, businessEntries, colourFontEntries, logoEntry);
            }
            default:
                return {};
        }
    }, [activeFormValues, subpage]);
}
