/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Clipboard.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The Clipboard Clips your value to the use clipboard and shows a toast notification scoped to `notificationScopes.GLOBAL`
 */
function Clipboard(props) {
    const { className, text, value, copyToClipboard } = props;

    /***** RENDER *****/
    return (
        <Anchor
            href={null}
            className={className ? ' ' + className : ''}
            onClick={(e) => {
                e.preventDefault();
                copyToClipboard(value);
            }}
        >
            {text}
        </Anchor>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Clipboard.propTypes = {
    /**
     * React Class Name
     */
    className: PropTypes.string,

    /**
     * Text to display on the button
     */
    text: PropTypes.string,

    /**
     * Value to copy to clipboard
     */
    value: PropTypes.string
};

Clipboard.defaultProps = {
    text: 'Copy'
};

export default connect(null, {
    copyToClipboard
})(Clipboard);
