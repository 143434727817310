/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import { CheckBoxList } from 'components/CheckBoxList';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { requiredCheckboxFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CANCEL_SERVICE_AVAILABLE_ACKNOWLEDGEMENTS } from 'containers/services/forms/cancelServiceForm/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      acknowledgements: Array<AvailableCancelServiceAcknowledgements>
 * }} props
 */
function RenderAcknowledgements({ acknowledgements }) {
    if (!acknowledgements.length) {
        return;
    }

    /***** RENDER *****/
    return (
        <AcknowledgementBox title="Confirmation">
            <Flex direction="column">
                {acknowledgements.map((key) => {
                    const isKeyArray = Array.isArray(key);
                    const _key = isKeyArray ? key[0] : key;
                    return (
                        <Field name={_key} key={_key} component={CheckBoxList.Item.ReduxForm} validate={[requiredCheckboxFieldValidation]}>
                            <Text span size--xss>
                                {isKeyArray ? key[1] : CANCEL_SERVICE_AVAILABLE_ACKNOWLEDGEMENTS[key]}
                            </Text>
                        </Field>
                    );
                })}
            </Flex>
        </AcknowledgementBox>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { RenderAcknowledgements };
