/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import htmr from 'htmr';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ConditionalRender } from 'components/Utils/ConditionalRender';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 ***********************************************************************************************************/
/**
 * SelectPlan - Private Component Used by the ChangePlan Lightbox to render a list of plan options to select from
 */
const SelectPlan = ({ planOptions = [], currentPlan, onSelectPlan, cycle = 'Monthly', customCycleText }) => {
    /***** PROP VALIDATION *****/
    if (planOptions.some((option) => !Array.isArray(option.details))) throw new Error('details must be an array');

    /***** FUNCTIONS *****/
    const currentPlanIndex = planOptions.findIndex((option) => option.name === currentPlan);

    /***** RENDER HELPERS *****/
    const renderButton = (index, customButton) => {
        if (index === currentPlanIndex && 'This is your current plan') {
            return <InactiveButton className="product__action--button">This is your current plan</InactiveButton>;
        }

        if (customButton && typeof customButton === 'function') {
            return customButton({ index, currentPlanIndex, onSelectPlan });
        }

        if (index < currentPlanIndex) {
            return (
                <OutlineButton
                    type="onClick"
                    onClick={() => {
                        onSelectPlan(index);
                    }}
                >
                    Downgrade
                </OutlineButton>
            );
        }

        return (
            <SolidButton
                className="product__action--button"
                type="onClick"
                onClick={() => {
                    onSelectPlan(index);
                }}
            >
                Upgrade
            </SolidButton>
        );
    };

    /***** RENDER *****/
    return (
        <div className="selectPlan">
            <Grid columns="repeat(auto-fill, 300px)" gap={0}>
                {planOptions.map((option, index) => (
                    <div key={index} className={classNames({ 'selectPlan__plan': true, 'selectPlan__plan--current': index === currentPlanIndex })}>
                        <div className={classNames('selectPlan__title', `selectPlan__title--${index === currentPlanIndex ? 'blue' : 'primary'}`)}>
                            <Text span bold size--xl>
                                {option.name}
                            </Text>
                            <ConditionalRender
                                condition={currentPlan === option.name}
                                onTrue={<SolidTag className="selectPlan__currentPlan">Current Plan</SolidTag>}
                                onFalse={null}
                            />
                        </div>
                        <div className={classNames({ 'selectPlan__description': true, 'selectPlan__description--empty': !option.description })}>
                            <Text secondary size--m lead--none>
                                {option.description || ''}
                            </Text>
                        </div>
                        <div className="selectPlan__price">
                            <Text bold size--xl secondary>
                                <span>${option?.price(cycle)}</span>
                            </Text>{' '}
                            <Text size--m secondary>
                                <span>{customCycleText ? customCycleText(cycle, option) : `/${cycle}`}</span>
                            </Text>
                        </div>
                        <div className="selectPlan__action">{renderButton(index, option.customButton)}</div>
                        {option.details.map((detail, index) => (
                            <Text key={'feature-' + index} size--s secondary>
                                <div>{htmr(detail)}</div>
                            </Text>
                        ))}
                    </div>
                ))}
            </Grid>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SelectPlan.propTypes = {
    /**
     * required - an array of plan options to display
     */
    planOptions: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * required - the name of the plan
             */
            name: PropTypes.string.isRequired,

            /**
             * optional - the description of the plan
             */
            description: PropTypes.string,

            /**
             * required - the price of the plan, this is a function that takes a cycle and returns a price
             */
            price: PropTypes.func.isRequired,

            /**
             * required - the cycle of the plan
             */
            cycle: PropTypes.string.isRequired,

            /**
             * required - an array of details to display at the bottom of the card
             */
            details: PropTypes.arrayOf(PropTypes.string).isRequired,

            /**
             * optional - this should be included from the changePlan component and is only used when onSelectPlan is called
             */
            rawData: PropTypes.any,

            /**
             * optional - this is a function that returns a custom button to display on the plan card
             */
            customButton: PropTypes.func
        })
    ).isRequired,

    /**
     * required - the name of the current plan
     */
    currentPlan: PropTypes.string.isRequired,

    /**
     * required - the function to call when a plan is selected; If a customButton is provided, this function is passed onSelectPlan in the case that the customButton is clicked
     */
    onSelectPlan: PropTypes.func.isRequired,

    /**
     * optional - this is a function that returns a custom cycle text to display on the plan card
     */
    customCycleText: PropTypes.func,

    /**
     * optional - the cycle to display on the plan card
     */
    cycle: PropTypes.string
};

export default SelectPlan;
