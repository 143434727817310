/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CLASS_ccp__select__item } from 'utilities/methods/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NSelect } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Select: React.FC<NSelect.Props> = ({ label, name, value, onChange, onBlur, options, className = null, meta }) => {
    /***** STATE *****/
    const [showOptionList, setShowOptionList] = useState(false);
    const [focused, setFocused] = useState(false);

    /***** HOOKS *****/
    const dropdownRef = useRef<HTMLButtonElement>();
    const menuRef = useRef<HTMLDivElement>();

    useClickAway([dropdownRef], () => {
        setShowOptionList(false);
        setFocused(false);
    });

    /***** FUNCTIONS *****/
    // use onKeyDown to close the menu when tabbing onto next element. Can't use onBlur because it clashes with onClick when selecting an option
    function onKeyDown(e) {
        if (focused) {
            const selectedIndex = options.findIndex((option) => option.value === value);

            // scroll the options menu so that the selected option is always in view
            if (showOptionList && options.length > 5) {
                if (e.key === 'ArrowUp') {
                    if (menuRef?.current?.scrollTop > (selectedIndex - 1) * 40 && selectedIndex > 0) {
                        menuRef.current.scrollTop = (selectedIndex - 1) * 40;
                    }
                }

                if (e.key === 'ArrowDown') {
                    if (selectedIndex + 1 > 4 && menuRef.current?.scrollTop < (selectedIndex - 3) * 40 && selectedIndex + 1 < options.length) {
                        menuRef.current.scrollTop = (selectedIndex - 3) * 40;
                    }
                }
            }

            switch (e.key) {
                case 'Tab':
                    setShowOptionList(false);
                    setFocused(false);
                    break;
                case 'ArrowDown':
                case 'Down':
                    e.preventDefault();
                    if (selectedIndex === -1) selectOption(options[0].value);
                    else if (selectedIndex < options.length - 1) selectOption(options[selectedIndex + 1].value);
                    break;
                case 'ArrowUp':
                case 'Up':
                    e.preventDefault();
                    if (selectedIndex === -1) selectOption(options[options.length - 1].value);
                    else if (selectedIndex > 0) selectOption(options[selectedIndex - 1].value);
                    break;
                case 'Enter':
                    e.preventDefault();
                    setShowOptionList((prev) => !prev);
                    break;
                case 'Escape':
                case 'Esc':
                    e.preventDefault();
                    setShowOptionList(false);
                    break;
                default:
                    break;
            }
        }
    }

    function selectOption(selectedValue, closeList = undefined) {
        onChange(selectedValue);
        if (closeList) {
            setShowOptionList(false);
        }
    }

    function onButtonClick(e) {
        setShowOptionList((prev) => !prev);
    }

    const renderHiddenOptions = () => {
        if (options && options.length > 0) {
            return options.map((item, index) => {
                let hiddenOptionLabelContent;
                if (typeof item.label === 'object' && 'props' in item.label) {
                    hiddenOptionLabelContent = item.label.props.children;
                } else {
                    hiddenOptionLabelContent = item.label;
                }
                return (
                    <option key={index} value={item.value}>
                        {hiddenOptionLabelContent}
                    </option>
                );
            });
        }
    };

    /***** RENDER HELPERS *****/
    const renderOptions = () => {
        if (options && options.length > 0) {
            return options.map((item, index) => {
                const selectItemClassNames = classNames(CLASS_ccp__select__item, { selected: value === item.value });
                const disabled = !!item?.disabled;

                const buttonProps = {
                    disabled,
                    value: item.value,
                    className: selectItemClassNames,
                    onClick: (e) => {
                        e.preventDefault();
                        selectOption(item.value);
                    }
                };

                if (item.icon) {
                    return (
                        <button key={index} {...buttonProps}>
                            {item.icon} {item.label}
                        </button>
                    );
                }

                return (
                    <button key={index} {...buttonProps}>
                        {item.label}
                    </button>
                );
            });
        }
    };

    const renderLabel = () => {
        if (options && options.length > 0) {
            const filtered = options.filter((option) => value === option.value);
            return filtered && filtered.length > 0 ? filtered[0].label : 'Please Select';
        }
        return 'Please Select';
    };

    /***** RENDER *****/
    return (
        <div className={classNames('form__item', className)}>
            <div className="ccp__select">
                <select id={`${name}--ccp__hidden`} name={name}>
                    {renderHiddenOptions()}
                </select>

                <div className="form__title">{!!label && <label>{label}</label>}</div>

                <button
                    ref={dropdownRef}
                    type="button"
                    className={classNames('ccp__select--toggle', { 'ccp__select--toggle-error': meta?.touched && meta?.error, focused })}
                    onClick={onButtonClick}
                    onFocus={() => setFocused(true)}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                >
                    {renderLabel()}
                    <PhosphorIcons.Chevron state={showOptionList ? 'up' : 'down'} />
                </button>

                {showOptionList && (
                    <div ref={menuRef} className={classNames('ccp__select--menu', { label })}>
                        {renderOptions()}
                    </div>
                )}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
