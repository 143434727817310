/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _DefaultLabel({ labelRef, dropdownTitleOnClick, disabled, label, isOpen }) {
    /***** RENDER *****/
    return (
        <button className="CustomDropdown__title" type="button" ref={labelRef} onClick={!disabled ? dropdownTitleOnClick : null}>
            <div className="text">{label}</div>
            <PhosphorIcons.Chevron state={isOpen ? 'up' : 'down'} />
        </button>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

_DefaultLabel.propTypes = {
    // Ref to pass to the dropdown title, used for clickaway functionality in the parent component
    labelRef: PropTypes.object,

    // Label text
    label: PropTypes.string,

    // Whether or no the dropdown should be disabled
    disabled: PropTypes.bool,

    // Function that closes the dropdown when the title is clicked
    dropdownTitleOnClick: PropTypes.func,

    // Whether the dropdown is open
    isOpen: PropTypes.bool.isRequired
};

export { _DefaultLabel };
