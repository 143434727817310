/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AccountDetails from './modules/account';
import BillingGsuite from './modules/billing';
import Delete from './modules/delete';
import ManageSeats from './modules/manageSeats';
import Overview from './modules/overview';
import SetupModule from './modules/setup';
import Status from './modules/status';
import Upgrade from './modules/upgrade';
import UserAccounts from './modules/userAccounts';
import UserDelete from './modules/userDelete';
import UserDetails from './modules/userDetails';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import PageSectionModules from 'components/PageSectionModules';
import SideBarPage from 'components/SideBarPage';
import Subnav from 'components/Subnav';
import Title from 'components/Title';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { handleRedirect } from 'utilities/methods/redirects/methods';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';
import { handleSideMenuOptions } from 'utilities/methods/sectionRendering';
import { getGSuiteLogin, getGSuiteMailbox, getGSuiteService } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import { genericTerminatedCancelled } from 'utilities/methods/redirects/messageContent';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_gsuite.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let GSuite = (props) => {
    const {
        /** Redux Props */
        getGSuiteLogin,
        getGSuiteMailbox,
        gsuite_login_status,
        gsuite_mailbox_data,
        gsuite_mailbox_status,
        gsuite_service_data,
        gsuite_service_error,
        gsuite_service_status
    } = props;

    /***** STATE *****/
    const [mounted, setMounted] = useState(false);

    /***** HOOKS *****/
    /**
     * @type {{
     * 		subpage: "admin" | "manage" | "setup";
     * 		id: string;
     * 		mailbox: string;
     * }}
     */
    const { subpage, id, mailbox } = useParams();
    const history = useHistory();
    const location = useLocation();

    /***** EFFECTS *****/
    useEffect(() => {
        if (id && mailbox) {
            getGSuiteMailbox(id, mailbox);
        }

        getGSuiteService(id);
    }, []);

    useEffect(() => {
        if (gsuite_service_error?.status === 403) {
            history.push(redirectUrls.google_workspace);
            pushNotification(genericTerminatedCancelled);
        } else if (gsuite_service_data) {
            handleRedirect({
                serviceType: 'google_workspace',
                status: gsuite_service_data?.attributes?.status,
                domainName: gsuite_service_data?.attributes?.domain,
                history
            });
        }
    }, [gsuite_service_error, gsuite_service_data]);

    useEffect(() => {
        if (gsuite_service_status === 'success') {
            setMounted(true);
        }
    }, [gsuite_service_status]);

    /***** RENDER HELPERS *****/
    const renderTitle = () => {
        if (gsuite_service_data) {
            const { type, id, attributes, included } = gsuite_service_data;
            const { domain } = attributes;
            const product = getIncludedObjBasedOnType(included, 'product');
            let actions = null;
            const support = (
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                            Google Workspace Help
                            <i className="item__icon icon icon-chevron-down" />
                        </button>
                    )}
                    key="dropdown"
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'Google Workspace Help Articles',
                                    onSelect: () => history.push(`/support/support-centre/google-workspace`)
                                },
                                {
                                    label: 'Submit a Technical Support eTicket for this Service',
                                    onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                },
                                {
                                    label: 'Submit a Billing eTicket for this Service',
                                    onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                }
                            ]}
                        />
                    )}
                />
            );

            if (subpage !== 'setup') {
                actions = [
                    {
                        status: gsuite_login_status,
                        label: 'Google Workspace Login',
                        color: 'notice',
                        type: 'onClick',
                        onClick: (e) => {
                            e.preventDefault();
                            getGSuiteLogin(id);
                        }
                    },
                    support
                ];
            } else {
                actions = [support];
            }

            if (mailbox) {
                return (
                    <Title
                        serviceTitle={domain}
                        serviceSubTitle={product.attributes.name}
                        serviceType={type}
                        useSubtitle={true}
                        subtitleData={{
                            icon: `email`,
                            title: gsuite_mailbox_data ? gsuite_mailbox_data.attributes.primary_email : ''
                        }}
                        action={[
                            {
                                className: 'listAccount__action',
                                label: 'List Accounts',
                                color: 'secondary',
                                type: 'onClick',
                                onClick: (e) => {
                                    e.preventDefault();
                                    history.push(`/my-services/google/manage/overview/${id}`);
                                }
                            },
                            support
                        ]}
                    />
                );
            }

            return <Title serviceTitle={domain} serviceSubTitle={product.attributes.name} serviceType={type} history={history} action={actions} />;
        }

        return '';
    };

    const modules = {
        manage: [
            {
                sidebarLink: {
                    label: null,
                    link: `/my-services/google/manage/status/${id}`
                },
                component: <Status gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/status'
            },
            {
                sidebarLink: {
                    label: 'Overview',
                    link: `/my-services/google/manage/overview/${id}`
                },
                component: <Overview gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/overview'
            },
            {
                sidebarLink: {
                    label: 'Upgrade',
                    link: `/my-services/google/manage/upgrade/${id}`
                },
                component: <Upgrade gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/upgrade'
            },
            {
                sidebarLink: {
                    label: 'Billing',
                    link: `/my-services/google/manage/billing/${id}`
                },
                component: <BillingGsuite gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/billing'
            },
            {
                sidebarLink: {
                    label: 'Manage Seats',
                    link: `/my-services/google/manage/manage-seats/${id}`
                },
                component: <ManageSeats gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/manage-seats'
            },
            {
                sidebarLink: {
                    label: 'User Accounts',
                    link: `/my-services/google/manage/user-accounts/${id}`
                },
                component: <UserAccounts gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/user-accounts'
            },
            {
                sidebarLink: {
                    label: 'User Details',
                    link: `/my-services/google/manage/overview/${id}/mailbox/${mailbox}/user-details`
                },
                component: <UserDetails gsuiteid={id} mailbox={mailbox} />,
                condition: mailbox,
                identifier: 'gsuite/user-details'
            },
            {
                sidebarLink: {
                    label: 'Delete User',
                    link: `/my-services/google/manage/overview/${id}/mailbox/${mailbox}/delete`
                },
                component: <UserDelete gsuiteid={id} mailbox={mailbox} />,
                condition: mailbox,
                identifier: 'gsuite/delete-user'
            }
        ],
        admin: [
            {
                sidebarLink: {
                    label: 'Account Details',
                    link: `/my-services/google/admin/account-details/${id}`
                },
                component: <AccountDetails gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/account-details'
            },
            {
                sidebarLink: {
                    label: 'Delete Mailbox',
                    link: `/my-services/google/admin/delete/${id}`
                },
                component: <Delete gsuiteid={id} />,
                condition: !mailbox,
                identifier: 'gsuite/delete-mailbox'
            }
        ],
        setup: [
            {
                sidebarLink: {
                    label: null,
                    link: `/my-services/google/setup/${id}`
                },
                component: <SetupModule gsuiteid={id} />,
                condition: true,
                identifier: 'gsuite/setup'
            }
        ]
    };

    const manageList = {
        list_title: 'Manage',
        list_items: handleSideMenuOptions(modules, 'manage')
    };

    const adminList = {
        list_title: 'Admin',
        list_items: handleSideMenuOptions(modules, 'admin')
    };

    const sidebarList = [manageList, adminList];

    const handleRenderState = () => {
        if (!mounted || gsuite_service_status === 'loading' || gsuite_mailbox_status === 'loading') return <RequestLoader />;
        if (gsuite_service_status === 'error' || (mailbox && gsuite_mailbox_status === 'error')) return <PageNotFound />;

        if (subpage === 'setup') {
            return (
                <Fragment>
                    {renderTitle()}
                    <SideBarPage.Wrapper>
                        <PageSectionModules modules={modules} which="setup" />
                    </SideBarPage.Wrapper>
                </Fragment>
            );
        }

        return (
            <Fragment>
                {renderTitle()}
                <SideBarPage sidebarList={sidebarList} className={classNames('gsuite')}>
                    <PageSectionModules modules={modules} which={subpage} />
                </SideBarPage>
            </Fragment>
        );
    };

    /***** RENDER *****/
    return (
        <Fragment>
            <Subnav pathname={location.pathname} />
            {handleRenderState()}
        </Fragment>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        gsuite_service_status: state.gsuite.gsuite_service_status,
        gsuite_service_data: state.gsuite.gsuite_service_data,
        gsuite_service_error: state.gsuite.gsuite_service_error,
        gsuite_mailbox_status: state.gsuite.gsuite_mailbox_status,
        gsuite_mailbox_data: state.gsuite.gsuite_mailbox_data,
        gsuite_login_status: state.gsuite.gsuite_login_status
    };
};

const mapDispatchToProps = {
    getGSuiteMailbox,
    getGSuiteLogin
};

GSuite = connect(mapStateToProps, mapDispatchToProps)(GSuite);

export { GSuite };
