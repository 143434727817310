/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useCallback } from 'react';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CheckBoxListItem as CheckBoxListItem } from 'components/CheckBoxList/_CheckBoxListItem';
import { ValidationMessage } from 'components/Form/ValidationMessage';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _CheckBoxListItemReduxFormDefault: React.FC<{
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    children: React.ReactNode;
    inverse?: boolean;
}> = ({ input, meta, children, inverse }) => {
    const { value, onChange } = input;

    /***** FUNCTIONS *****/
    const handleChangeProxy = useCallback(
        (value: boolean) => {
            if (inverse) {
                onChange(!value);
                return;
            }
            onChange(value);
        },
        [inverse]
    );

    /***** RENDER HELPERS *****/
    const valueProxy = inverse ? !value : value;

    /***** RENDER *****/
    return (
        <CheckBoxListItem isChecked={valueProxy} onChange={handleChangeProxy}>
            {children}
            <ValidationMessage.ReduxForm.Default {...meta} />
        </CheckBoxListItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
