/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useMegaMayAvaliableSpinsQuery } from '../../queries';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getRemainingSpins, prizes, wheelStatuses } from '../spinModal';

import WheelButton from '../../assets/wheel-button.svg';
import WheelTicker from '../../assets/wheel-ticker.svg';
import Wheel from '../../assets/wheel.svg';

import './_megamay24SpinningWheel.scss';

const segments = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const angle = 360 / segments.length;
const numOfExtraSpins = 1;

function getResultIndex(prize) {
    switch (prize) {
        case prizes.VOUCHER:
            return 0;
        case prizes.FIJI:
            return 1;
        case prizes.CREDIT:
            return 2;
        default:
            return -1;
    }
}

let rotation = 0;
let end = 0;
let stopplease = false;
let startTime = undefined;
let lasttime = 0;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      status: string
 *      setStatus: (status: string) => void
 *      prizeWon: string | null
 *      requestResult: () => void
 *      isMegaMaySpinWheelPending: boolean
 * }>}
 */
const MegaMaySpinningWheel = ({ status, setStatus, prizeWon, requestResult, isMegaMaySpinWheelPending }) => {
    /***** HOOKS *****/
    /**
     * @type React.RefObject<HTMLDivElement>
     */
    const wheelRef = useRef(null);

    /***** QUERIES *****/
    const { data: megaMayAvailableSpinsData } = useMegaMayAvaliableSpinsQuery();

    /***** RENDER HELPERS *****/
    const numOfSpinsRemaining = getRemainingSpins(megaMayAvailableSpinsData);
    const resultIndex = getResultIndex(prizeWon);

    /***** FUNCTIONS *****/
    function loop(timestamp) {
        if (startTime === undefined) {
            startTime = timestamp;
        }
        const deltaInSeconds = (timestamp - lasttime) / 1000;
        // The max spin speed is 360deg / second. To make this happen, we need to get the time between frames in seconds (delta time), which is effectively "what proportion of a second does the time between frames represent". Then we multiply that by 360 to work out how many degrees to rotate by in that frame.
        const maxDegreesToRotateForThisAnimationFrame = deltaInSeconds * 360;

        if (!stopplease) {
            rotation += maxDegreesToRotateForThisAnimationFrame;
            wheelRef.current.style.transform = `rotate(${rotation}deg)`;
        } else {
            if (rotation >= end - 1) {
                // Finished spinning
                rotation = end;
                wheelRef.current.style.transform = `rotate(${rotation}deg)`;

                setStatus(wheelStatuses.SPUN);

                return;
            }

            const distancBetweenStartAndEnd = end - rotation;
            // This Math.min is essentially saying "once the api request has finished and we want to start slowing down, try to rotate by the delta time proportion of the whole remaining distance, but cap it at the current spinning speed (1 spin / second). Then once the distance left to spin becomes less than 360 deg, the amount to rotate by will start to decrease, and the wheel will gradually slow down."
            // Multiplying by 2 is just a way to make it wait longer before slowing down, because that's like saying "try to rotate the proportion of 2x the remaining distance, instead of the actual remaining distance. Therefore it wont start to slow down until there is only 180deg left to rotate".
            const actualDegreesToRotateInThisFrame = Math.min(
                distancBetweenStartAndEnd * deltaInSeconds * 2,
                maxDegreesToRotateForThisAnimationFrame
            );
            rotation += actualDegreesToRotateInThisFrame;
            wheelRef.current.style.transform = `rotate(${rotation}deg)`;
        }
        lasttime = timestamp;
        requestAnimationFrame(loop);
    }

    function clickButton() {
        startTime = undefined;
        stopplease = false;
        requestAnimationFrame(loop);
        requestResult();
    }

    const isSpinButtonDisabled = isMegaMaySpinWheelPending || status === wheelStatuses.SPINNING || numOfSpinsRemaining <= 0;

    useEffectAfterMount(() => {
        if (!isMegaMaySpinWheelPending) {
            const extraDistanceFromNearestFullSpin = numOfExtraSpins * 360 + angle * (segments.length - resultIndex);
            const numOfCompletedSpins = Number((rotation / 360).toFixed(0));
            const newEnd = numOfCompletedSpins * 360 + extraDistanceFromNearestFullSpin;

            end = newEnd;
            stopplease = true;
        }
    }, [isMegaMaySpinWheelPending]);

    /***** RENDER *****/
    return (
        <div className="mmWheel__container">
            <img className="mmWheel__markerImage" src={WheelTicker} alt="Wheel marker" />
            <div className="mmWheel" ref={wheelRef}>
                <img className="mmWheel__wheelImage" src={Wheel} alt="Spinning Wheel" />
            </div>
            <button className="mmWheel__button" type="button" onClick={clickButton} disabled={isSpinButtonDisabled}>
                <img src={WheelButton} alt="Click to spin" />
            </button>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default MegaMaySpinningWheel;
