/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';
import { getCurrentDate, toLuxonDate } from 'utilities/methods/commonActions';
import { renderButton, RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';
import { previewAlignServices } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AlignForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alignedServicesDetails: {}
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            dispatch,
            form,
            selectedServices,
            renewalDate,
            billingCycle,
            previewAlignServices,
            account_services_alignment_preview_status,
            account_services_alignment_preview_data
        } = this.props;

        if (renewalDate && billingCycle && (renewalDate !== prevProps.renewalDate || billingCycle !== prevProps.billingCycle)) {
            const attributes = {
                services: selectedServices.map(({ id }) => {
                    return {
                        id
                    };
                }),
                next_billing_cycle_id: billingCycle,
                next_invoice_date: renewalDate
            };

            previewAlignServices(attributes);
        }

        if (account_services_alignment_preview_status === 'success' && prevProps.account_services_alignment_preview_status === 'loading') {
            this.setState({
                alignedServicesDetails: account_services_alignment_preview_data.attributes
            });
        }

        if (selectedServices !== prevProps.selectedServices) {
            dispatch(change(form, 'billingCycle', undefined));
            dispatch(change(form, 'renewalDate', undefined));

            this.setState({
                alignedServicesDetails: {}
            });
        }
    }

    render() {
        const { handleSubmit, pristine, submitting, valid, renewalDate, billingCycle, selectedServices, account_services_alignment_preview_status } =
            this.props;
        const { alignedServicesDetails } = this.state;
        const getBillingCycleFrequency = (billingCycle) => {
            switch (billingCycle) {
                case 'Monthly':
                    return 1;
                case 'Quarterly':
                    return 2;
                case 'Semi-Annually':
                    return 3;
                case 'Annually':
                    return 4;
                case 'Biennially':
                    return 5;
                case 'Triennially':
                    return 6;
                default:
                    return 0;
            }
        };
        const now = DateTime.local();
        const hasGSuite = selectedServices
            .map(
                ({
                    product: {
                        attributes: { service_type }
                    }
                }) => service_type
            )
            .includes('gsuite');
        const nextDueDates = selectedServices.map(({ next_due_date }) => toLuxonDate(next_due_date, 'yyyy-MM-dd TT'));
        const furthestDueDate = nextDueDates.find((date) => date.toMillis() === Math.max(...nextDueDates.map((date) => date.toMillis())));
        const renewalDateOptions = () => {
            if (hasGSuite) {
                const oneMonthFromNow = now.plus({ days: 30 });

                return [
                    {
                        label: `${oneMonthFromNow.day}/${oneMonthFromNow.month}/${oneMonthFromNow.year}`,
                        value: oneMonthFromNow.toISODate()
                    }
                ];
            }

            return [...Array(5).keys()].map((counter) => {
                const furthestDueDateIncremental = furthestDueDate.plus({ days: counter + 1 });

                return {
                    label: `${furthestDueDateIncremental.day}/${furthestDueDateIncremental.month}/${furthestDueDateIncremental.year}`,
                    value: furthestDueDateIncremental.toISODate()
                };
            });
        };
        const billingCycleOptions = Object.values(
            selectedServices
                .map(({ available_billing_cycle }) => available_billing_cycle)
                .reduce((accumulator, currentValue) => accumulator.concat(currentValue))
                .reduce((accumulator, { id, name }) => {
                    accumulator[name] = accumulator[name] || { id, name, counter: 0 };
                    accumulator[name].counter += 1;
                    return accumulator;
                }, {})
        )
            .filter(({ counter }) => counter === selectedServices.length)
            .map(({ name, id }) => {
                return {
                    label: name,
                    value: id,
                    frequency: getBillingCycleFrequency(name)
                };
            })
            .sort((first, second) => first.frequency - second.frequency);

        const renderNewAlignServices = () => {
            return (
                <div className="alignServices__new">
                    <div className="alignServices__new--heading">New aligned services details</div>
                    <div className="alignServices__details">
                        <div className="alignServices__container">
                            <div className="alignServices__column">
                                <div className="alignServices__column--heading">Date Issued</div>
                                <div>{getCurrentDate().toFormat('d LLLL y')}</div>
                            </div>
                            <div className="alignServices__column">
                                <div className="alignServices__column--heading">
                                    <span className="alignServices__column--highlight">NEW</span> Date Due
                                </div>
                                <div>{toLuxonDate(alignedServicesDetails.new_due_date, 'yyyy-MM-dd TT').toFormat('d LLLL y')}</div>
                            </div>
                            <div className="alignServices__column">
                                <div className="alignServices__column--heading">
                                    <span className="alignServices__column--highlight">NEW</span> Amount Due
                                </div>
                                <div>${alignedServicesDetails.total} AUD</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="alignServices__form" onSubmit={handleSubmit}>
                <div>
                    <div className="form__column full">
                        <Field
                            component={RenderSelectField}
                            label="Select renewal date"
                            name="renewalDate"
                            validate={[requiredFieldValidation]}
                            type="select"
                            options={renewalDateOptions()}
                        />
                    </div>
                </div>
                {Object.keys(alignedServicesDetails).length >= 1 && (billingCycle || renewalDate) && (
                    <DialogNotification type="warning">
                        Please note: An invoice will be generated from today&apos;s date until the selected renewal date to cover the pro rata
                        difference. This invoice will need to be paid today for the services to be aligned.
                    </DialogNotification>
                )}
                <div>
                    <div className="form__column full">
                        <Field
                            component={RenderSelectField}
                            label="Select billing cycle"
                            name="billingCycle"
                            validate={[requiredFieldValidation]}
                            type="select"
                            options={billingCycleOptions}
                        />
                    </div>
                </div>
                {account_services_alignment_preview_status === 'loading' ? (
                    <RequestLoader />
                ) : (
                    account_services_alignment_preview_status === 'success' &&
                    Object.keys(alignedServicesDetails).length >= 1 &&
                    renderNewAlignServices()
                )}
                {renderButton(
                    pristine,
                    submitting,
                    valid && account_services_alignment_preview_status === 'success',
                    'Continue',
                    'primary',
                    'alignServices__button'
                )}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
AlignForm = reduxForm({
    form: 'alignForm'
})(AlignForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(`alignForm`);
    const { renewalDate, billingCycle } = selector(state, 'renewalDate', 'billingCycle');

    return {
        account_services_alignment_preview_status: state.billing.account_services_alignment_preview_status,
        account_services_alignment_preview_data: state.billing.account_services_alignment_preview_data,
        renewalDate,
        billingCycle
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            previewAlignServices
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlignForm));
