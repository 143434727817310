/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: number) {
    return useMutation({
        mutationFn: (socialType: 'instagram') => KATANA_API.katana.service_id.user.socials.$type.link.GET(serviceID, socialType),
        onSuccess: () => {}
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSocialsAuthorisationLink = Object.freeze({
    useMutation: _useMutation
});
