import PropTypes from 'prop-types';
import { TruthyProp } from 'utilities/consts';



export const appliedStylingPropTypes = {
    /**
     * Wether to force remove padding
     */
    'noPadding': TruthyProp,

    /**
     * Flex Prop align request loader to the left
     */
    'align-start': TruthyProp,

    /**
     * Auto heights the main request loader
     */
    'heightAuto': TruthyProp
};

export const appliedStylingPropTypeKeys = Object.keys(appliedStylingPropTypes);

export const requestLoaderPropTypes = {
    /**
     * React ClassNames
     */
    className: PropTypes.string,

    /**
     * The height of the encapsulating element (to prevent layout shifting)
     */
    fillHeight: PropTypes.number,

    /**
     * The width of the encapsulating element (to prevent layout shifting)
     */
    fillWidth: PropTypes.number,

    /**
     * Height of the loader
     */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Width of the loader
     */
    width: PropTypes.number,

    /**
     * Message content for under the loader
     */
    message: PropTypes.string,

    ...appliedStylingPropTypes
};
