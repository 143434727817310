/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DefaultPayment, { CREDIT_CARD_ACTIONS } from './defaultPayment';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import availableCreditCardsImage from 'assets/images/payment-methods.png';
import { PAYMENT_METHODS_VALUES } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function CreditCardMethodInfo({ creditCardLightboxMode, setCreditCardLightboxMode }) {
    /***** RENDER *****/
    return (
        <div key="creditcard" className="tab__item tab__item--creditcard">
            <img className="availablePaymentMethod__image" src={availableCreditCardsImage} alt="available credit card" />
            <div className="availablePaymentMethod__description">
                <strong>PLEASE NOTE:</strong>{' '}
                {`Choosing Credit Card as your default payment method means we'll automatically charge invoices three days
                before their due date and send you confirmation by email.`}
            </div>
            <div className="availablePaymentMethod__button">
                <OutlineButton
                    type="onClick"
                    size="small"
                    color="primary"
                    onClick={() => {
                        setCreditCardLightboxMode(CREDIT_CARD_ACTIONS.ADD);
                    }}
                >
                    Add Credit Card
                </OutlineButton>
            </div>
            <DefaultPayment {...{ creditCardLightboxMode, setCreditCardLightboxMode }} />
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CreditCardMethodInfo.propTypes = {
    /**
     * Which active payment method tab is currently selected
     */
    activeTab: PropTypes.oneOf(PAYMENT_METHODS_VALUES),

    /**
     * Function to allow selection of credit card lightbox modes
     */
    creditCardLightboxMode: PropTypes.oneOf([...Object.values(CREDIT_CARD_ACTIONS), null]),

    /**
     * Function to set the current credit card lightbox mode
     */
    setCreditCardLightboxMode: PropTypes.func.isRequired
};

export default CreditCardMethodInfo;
