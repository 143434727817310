/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSlots } from 'components/Slot/methods';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SortableButton.scss';

const sortableSortStates = /** @type {const} */ ([false, 'asc', 'desc']);
const sortableSortStatesIcons = /** @type {const} */ ([false, 'up', 'down']);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Sortable Button allows you to easily get a desired Key + Sorting direction to help guide what you should sort
 * 
 * Note: The onSort and currentSort methods on the Sortable Button should NOT be provided by the user if used within the
 * NXTable Component, but instead get injected by the SortableButtonWrapper component. The Sortable Button Wrapper retrieves 
 * these from the NXTable Context
 * 
 * @param {{
 *      sortKey: string,
 *      children: React.ReactNode,
 *      onSort?: () => void,
 *      currentSort?: {
 *          sortKey: string | false,
 *          sortMethod: string | false
 *      } | {}
 * }} props
 */
function SortableButton({ sortKey, children, onSort, currentSort = {} }) {
    /***** STATE *****/
    const [sortState, setSortState] = useState(0);

    /***** FUNCTIONS *****/
    /**
     * Handles sorting
     */
    function toggleSort() {
        let nextState = sortState + 1;
        if (nextState >= sortableSortStates.length) {
            nextState = 0;
        }
        setSortState(nextState);
        const sortData = { sortKey, sortMethod: sortableSortStates[nextState] };
        onSort(sortData);
    }

    /***** EFFECTS *****/
    /**
     * When the sort changes from the master, we need to change the components internals.
     */
    useEffect(() => {
        if (currentSort.sortKey !== sortKey && sortState !== 0) {
            setSortState(0);
        }
    }, [currentSort, sortKey, sortState]);

    /***** RENDER HELPERS *****/
    function renderSortIcon() {
        const state = sortableSortStatesIcons[sortState];
        if (state === false) return '';

        return <PhosphorIcons.Chevron className="sortedIcon" state={state} />;
    }

    const { defaultChildren, afterIcon } = getSlots(['defaultChildren', 'afterIcon'], children);

    /***** RENDER *****/
    return (
        <div className="sortableButton">
            <button className="sortableButton__clickWrapper" onClick={toggleSort}>
                <Text bold uppercase size--xs>
                    {defaultChildren}
                </Text>
                {renderSortIcon()}
            </button>
            {afterIcon}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SortableButton.propTypes = {
    /**
     * The key this is sorting
     */
    sortKey: PropTypes.string.isRequired,

    /**
     * Child Components
     */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,

    /**
     * Function that gets called when a sort happens
     */
    onSort: PropTypes.func,

    /**
     * This is so that the button knows to reset it's state if it's not the current sorted.
     * can leave this out if you don't want the sortable button to reset it's state automatically.
     */
    currentSort: PropTypes.shape({
        sortKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        sortMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
};

export default SortableButton;
