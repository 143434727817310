/* eslint-disable react/jsx-pascal-case */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_CancelServiceContent.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This needs to be able to get modified when user passes options.
 * @param {{
 *      children: React.ReactNode;
 *      title?: string
 *      primaryName?: string
 *      primaryProduct?: string
 * }} props
 */
const CancelServiceContent = ({ children, title = 'Cancel Service', primaryName, primaryProduct }) => (
    <div className="CancelServiceContent__content">
        <Flex direction="column" gap={4}>
            <Text align--center size--h2>
                {title}
            </Text>
            <Text align--center size--s>
                {!!primaryName && (
                    <Text bold black>
                        {primaryName} /
                    </Text>
                )}{' '}
                {!!primaryProduct && (
                    <Text secondary align--center>
                        {primaryProduct}
                    </Text>
                )}
            </Text>
        </Flex>
        <Padding y={6}>{children}</Padding>
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { CancelServiceContent };
