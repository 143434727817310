/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { supportedValidations } from 'containers/katana/formFields/methods/validations/consts';
import { validationRuleTypes } from 'containers/katana/formFields/validationRuleTypes';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getComplexValue } from 'containers/katana/formFields/methods/validations/getComplexValue';
import { getFormFieldIsRequired } from 'containers/katana/formFields/methods/validations/getFormFieldIsRequired';
import { formFieldsInRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsInRuleParser';
import { formFieldsMaxRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsMaxRuleParser';
import { formFieldsMinRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsMinRuleParser';
import { requiredFieldValidation } from 'utilities/methods/form';
import { validationString } from 'utilities/methods/form/validations/validationString';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

const { STRING, URL, REQUIRED, IN, MIN, MAX, REQUIRED_IF, REQUIRED_UNLESS } = validationRuleTypes;

interface getComplexValidationMethodsParams {
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
    formValues: Record<string, unknown>;
}

export function getComplexValidationMethods({ formValues, property }: getComplexValidationMethodsParams) {
    const { complex_validation } = property;
    if (!complex_validation) {
        return [];
    }

    const validationEntries = _.entries(complex_validation);

    return validationEntries.reduce((current, [key, validations]) => {
        const validationsMethods = validations
            .filter((validation) => supportedValidations.includes(validation.name))
            .map((validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number]) => {
                switch (validation.name) {
                    case URL:
                    case STRING: {
                        return getComplexValue(validationString, key);
                    }

                    case REQUIRED:
                        return getComplexValue(requiredFieldValidation, key);

                    case REQUIRED_IF: {
                        const isRequired = getFormFieldIsRequired({ validation, formValues, property });

                        if (isRequired) {
                            return getComplexValue(requiredFieldValidation, key);
                        }

                        // The key of the complex rule is required if another key in the same property is a certain value.
                        /**
                         * { attachment, type } <- attachment would be required if type === the value of the validation rule
                         */
                        break;
                    }
                    case REQUIRED_UNLESS: {
                        // The key of the complex rule is required if another key in the same property is a certain value.
                        /**
                         * { attachment, type } <- attachment would be required if type === the value of the validation rule
                         */
                        const isRequired = getFormFieldIsRequired({ validation, formValues, property });

                        if (isRequired === false) {
                            return getComplexValue(requiredFieldValidation, key);
                        }
                        break;
                    }

                    case MIN: {
                        return getComplexValue(formFieldsMinRuleParser(validation), key);
                    }

                    case MAX: {
                        return getComplexValue(formFieldsMaxRuleParser(validation), key);
                    }

                    case IN: {
                        return getComplexValue(formFieldsInRuleParser(validation), key);
                    }
                    default:
                        return undefined;
                }
            })
            .filter(Boolean);

        return _.concat(current, validationsMethods);
    }, []);
}
