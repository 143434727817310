/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { modifyInitialValues } from './methods';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * A React hook that modifies initial values based on form fields from modules.
 * @param {Object} initialValues - Original initial values object.
 * @param {Object[]} modules - The array of modules containing formFields.
 * @returns {Object} Modified initial values object.
 */
function useModifiedInitialValues(initialValues, modules, isSubmitting = false) {
    const [newInitialValues, setNewInitialValues] = useState({});
    useEffect(() => {
        if (isSubmitting) return;
        if (!initialValues) {
            return setNewInitialValues({});
        }
        setNewInitialValues(modifyInitialValues({ initialValues, modules }));
    }, [initialValues, modules]);

    return newInitialValues;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export default useModifiedInitialValues;
