/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import FetchComponentError from 'components/Errors/FetchComponentError';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Lightbox = (props) => {
    const {
        lightboxState,
        set_lightboxState,
        history,
        submitFinalMigration,
        support_submit_migration_request_form_status,
        support_migration_migration_dates_data
    } = props;

    function closeLightbox() {
        set_lightboxState(false);
    }

    const urgent_cost = support_migration_migration_dates_data?.attributes?.urgent_cost || '9.95';

    switch (lightboxState) {
        case 'confirm':
            return (
                <OverlayLightbox
                    className="migrationLightbox"
                    onOpen={true}
                    loading="success"
                    confirm={{
                        desc: (
                            <div className="desc">
                                <div className="confirmTitle">Confirm Migration Request Queue Jump</div>
                                <div className="confirmMessage">
                                    Selecting the urgent migration will generate an invoice for <strong>${urgent_cost}</strong>.
                                </div>
                            </div>
                        ),
                        buttonText: 'Confirm',
                        buttonAction: () => submitFinalMigration(),
                        closeText: 'No, Go Back',
                        closeAction: closeLightbox
                    }}
                    onClose={closeLightbox}
                />
            );

        case 'success':
            return (
                <OverlayLightbox
                    className="migrationLightbox"
                    onOpen={true}
                    loading={support_submit_migration_request_form_status}
                    confirm={{
                        desc:
                            support_submit_migration_request_form_status === 'success' ? (
                                <div className="desc">
                                    <i className="icon icon-valid"></i>
                                    <div className="successTitle">Your migration request has been submitted!</div>
                                    <div className="successMessage">Our migrations team will respond to you shortly.</div>
                                </div>
                            ) : (
                                <FetchComponentError />
                            ),
                        buttonText: 'Back to Support Centre',
                        buttonAction: () => history.push('/support/support-centre')
                    }}
                    onClose={() => history.push('/support/support-centre')}
                />
            );
        default:
            return '';
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    support_submit_migration_request_form_status: state.support.support_submit_migration_request_form_status,
    support_migration_migration_dates_data: state.support.support_migration_migration_dates_data
});

Lightbox = withRouter(connect(mapStateToProps, null)(Lightbox));

export default Lightbox;
