/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { synergyLoginUrl } from 'config/config';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VerticalTimeline from 'containers/ventraSynMigration/modules/verticalTimeline';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Anchor from 'components/Anchor';

/*   ACTIONS
 *****************************************************/
import { clearVsmState } from 'containers/ventraSynMigration/action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class MigrationSuccessPage extends Component {
    constructor(props) {
        super(props);

        this.timelineItems = [
            {
                icon: 'icon-sw-you-are-here',
                title: 'You are here!',
                paragraph:
                    'A confirmation email from Synergy Wholesale is now on it’s way to you with information outlining your move to Synergy Wholesale.'
            },
            {
                icon: 'icon-sw-migration-dns',
                title: 'Migrations',
                paragraph: 'We will shortly create a ticket within your Synergy Wholesale account for further updates on your migration progress.'
            },
            {
                icon: 'icon-sw-dns',
                title: 'DNS',
                paragraph:
                    'We will update your DNS on the domain names you have permitted us to do so. If you need to handle any manual DNS updates, our team will ensure the relevant DNS records are supplied to you via your migration ticket.'
            },
            {
                icon: 'icon-sw-use-credit',
                title: 'Claim and move your unused credit from VentraIP to Synergy Wholesale',
                paragraph:
                    'Once you’re happy with your new plans at Synergy Wholesale and all of your migrations are complete. You can cancel your hosting plan with VentraIP and request any unused credit (if applicable) be moved to your Synergy Wholesale account.'
            }
        ];
    }

    render() {
        const { history, clearVsmState } = this.props;
        const { timelineItems } = this;

        const renderPage = () => {
            return (
                <div className="vsSuccess">
                    <i className="vsSuccess__tick icon icon-sw-success" />
                    <div className="vsSuccess__message">
                        You have successfully linked your Synergy Wholesale account and booked in your migrations for the selected services.
                    </div>
                    <VerticalTimeline title="What happens next?" items={timelineItems} progress={{ activeItemNum: 1 }} />
                    <SolidButton type="anchor" href={synergyLoginUrl} target="_blank">
                        LOGIN TO SYNERGY WHOLESALE
                    </SolidButton>
                    <Anchor
                        onClick={() => {
                            clearVsmState();
                            history.push('/synergy-reseller-migration/select-services');
                        }}
                    >
                        START OVER
                    </Anchor>
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return renderPage();
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(null, {
        clearVsmState
    })(MigrationSuccessPage)
);
