/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{ title: string, nameservers: string[]}} props
 */
const DisplayNameservers = ({ title, nameservers }) => (
    <div className="manageDNS__confirmNameservers">
        <Text secondary align--left bold black className="manageDNS__confirmText">
            {title}
        </Text>
        <Border top right bottom left>
            <Padding xy={3}>
                <Grid columns="120px 1fr" gap={5}>
                    <Text bold align--left secondary>
                        Nameservers
                    </Text>
                    <Flex direction="column" gap={0}>
                        {nameservers.map((ns) => (
                            <Text key={ns}>{ns}</Text>
                        ))}
                    </Flex>
                </Grid>
            </Padding>
        </Border>
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

DisplayNameservers.propTypes = {
    /**
     * The title of the nameservers being displayed
     */
    title: PropTypes.string.isRequired,

    /**
     * The nameservers to display
     */
    nameservers: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DisplayNameservers;
