/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxDescription.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The description text of the Box
 * @param {{
 *    description: React.ReactNode
 * }} props
 */
function NXBoxDescription({ description }) {
    /***** RENDER *****/
    return (
        <Text size--s secondary className="NXBoxDescription">
            {description}
        </Text>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxDescription.propTypes = {
    // The description text
    description: PropTypes.string
};

export default NXBoxDescription;
