/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders a Box title, and a list of any UI, usually NXBoxOverviewRow
 */
function NXBoxOverview({ title, rows }) {
    /***** RENDER *****/
    return (
        <Padding x={6} y={5}>
            <div className="NXBoxOverview">
                <NXBox.Title title={title} />
                {rows}
            </div>
        </Padding>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxOverview.propTypes = {
    // Overview title
    title: PropTypes.string,

    // Info rows to show in the overview
    rows: PropTypes.arrayOf(PropTypes.node)
};

export default NXBoxOverview;
