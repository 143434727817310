/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { capitalize, clamp } from './utils';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Slider.scss';

/**
 * Predefined constants
 */
const constants = /** @type {const} */ ({
    orientation: {
        horizontal: {
            dimension: 'width',
            direction: 'left',
            reverseDirection: 'right',
            coordinate: 'x'
        },
        vertical: {
            dimension: 'height',
            direction: 'top',
            reverseDirection: 'bottom',
            coordinate: 'y'
        }
    }
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Slider extends Component {
    static defaultProps = {
        min: 0,
        max: 100,
        step: 1,
        value: 0,
        orientation: 'horizontal',
        tooltip: true,
        reverse: false,
        labels: {},
        handleLabel: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            limit: 0,
            grab: 0
        };
    }

    /**
     * Format label/tooltip value
     * @param  value{Number}
     * @return {Formatted Number}
     */
    handleFormat = (value) => {
        const { format } = this.props;

        return format ? format(value) : value;
    };

    /**
     * Update slider state on change
     * @return {void}
     */
    handleUpdate = () => {
        if (!this.slider) {
            // for shallow rendering
            return;
        }
        const { orientation } = this.props;

        const dimension = capitalize(constants.orientation[orientation].dimension);
        const sliderPos = this.slider[`offset${dimension}`];
        const handlePos = this.handle[`offset${dimension}`];

        this.setState({
            limit: sliderPos - handlePos,
            grab: handlePos / 2
        });
    };

    /**
     * Attach event listeners to mousemove/mouseup events
     * @return {void}
     */
    handleStart = (e) => {
        const { onChangeStart } = this.props;

        document.addEventListener('mousemove', this.handleDrag);
        document.addEventListener('mouseup', this.handleEnd);

        this.setState(
            {
                active: true
            },
            () => {
                onChangeStart && onChangeStart(e);
            }
        );
    };

    /**
     * Handle drag/mousemove event
     * @param  {Object} e - Event object
     * @return {void}
     */
    handleDrag = (e) => {
        e.stopPropagation();

        const { onChange } = this.props;

        const {
            target: { className, classList, dataset }
        } = e;

        if (!onChange || className === 'rangeslider__labels') return;

        let value = this.position(e);

        if (classList && classList.contains('rangeslider__label-item') && dataset.value) {
            value = parseFloat(dataset.value);
        }

        onChange && onChange(value, e);
    };

    handleKeyboardLabelInteraction = (e, key) => {
        const { onChange } = this.props;

        switch (e.key) {
            case ' ':
            case 'Space':
            case 'Enter':
                e.preventDefault();
                onChange(key, e);
                break;
            default:
                break;
        }
    };

    /**
     * Detach event listeners to mousemove/mouseup events
     * @return {void}
     */
    handleEnd = (e) => {
        const { onChangeComplete } = this.props;
        const { handleDrag, handleEnd } = this;

        const value = this.position(e);

        this.setState(
            {
                active: false
            },
            () => {
                onChangeComplete && onChangeComplete(value, e);
            }
        );
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleEnd);
    };

    /**
     * Support for key events on the slider handle
     * @param  {Object} e - Event object
     * @return {void}
     */
    handleKeyDown = (e) => {
        const { key } = e;
        const { value, min, max, step, onChange } = this.props;

        let sliderValue;

        switch (key) {
            case 'ArrowUp':
            case 'ArrowRight':
                e.preventDefault();
                sliderValue = value + step > max ? max : value + step;
                onChange && onChange(sliderValue, e);
                break;
            case 'ArrowDown':
            case 'ArrowLeft':
                e.preventDefault();
                sliderValue = value - step < min ? min : value - step;
                onChange && onChange(sliderValue, e);
                break;
            case 'Home':
                e.preventDefault();
                onChange(min, e);
                break;

            case 'End':
                e.preventDefault();
                onChange(max, e);
                break;
            default:
                break;
        }
    };

    /**
     * Calculate position of slider based on its value
     * @param  {number} value - Current value of slider
     * @return {position} pos - Calculated position of slider based on value
     */
    getPositionFromValue = (value) => {
        const { limit } = this.state;
        const { min, max } = this.props;

        const diffMaxMin = max - min;
        const diffValMin = value - min;
        const percentage = diffValMin / diffMaxMin;
        const pos = Math.round(percentage * limit);

        return pos;
    };

    /**
     * Translate position of slider to slider value
     * @param  {number} pos - Current position/coordinates of slider
     * @return {number} value - Slider value
     */
    getValueFromPosition = (pos) => {
        const { limit } = this.state;
        const { orientation, min, max, step } = this.props;

        const percentage = clamp(pos, 0, limit) / (limit || 1);
        const baseVal = step * Math.round((percentage * (max - min)) / step);
        const value = orientation === 'horizontal' ? baseVal + min : max - baseVal;

        return clamp(value, min, max);
    };

    /**
     * Correctly types an Event/SynethicEvent to include the properties from the more specific Event Type
     *
     * @type {<T extends React.SyntheticEvent | Event>(e: React.SyntheticEvent | Event, instance: new (...args: any[]) => T) => e is T}
     */
    isEventInstanceOf = (e, instance) => e instanceof instance || (!(e instanceof Event) && e.nativeEvent instanceof instance);

    /**
     * @param {React.SyntheticEvent | Event} e
     * @param {'clientX' | 'clientY'} clientCoordinateStyle
     */
    getCoordinate = (e, clientCoordinateStyle) => {
        const { isEventInstanceOf: isInstanceOf } = this;
        const fallback = 0;

        // handle mouse events
        if (isInstanceOf(e, MouseEvent)) {
            return e[clientCoordinateStyle];
        }

        if (!isInstanceOf(e, TouchEvent)) {
            return fallback; //fallback to a default value
        }

        // try getting touches first (used for touchMove)
        const coordinate = e.touches[0]?.[clientCoordinateStyle];

        if (coordinate) {
            return coordinate;
        }

        // if no touches, try changedTouches (used for touchEnd)
        const touch = e.changedTouches[0];

        if (touch) {
            return touch[clientCoordinateStyle];
        }

        // fallback to a default value
        return fallback;
    };

    /**
     * Calculate position of slider based on value
     * @param  {Object} e - Event object
     * @return {number} value - Slider value
     */
    position = (e) => {
        const { grab } = this.state;
        const { orientation, reverse } = this.props;
        const { getValueFromPosition, getCoordinate } = this;

        const node = this.slider;
        const coordinateStyle = constants.orientation[/** @type {'horizontal' | 'vertical'} */ (orientation)].coordinate;
        const directionStyle = reverse ? constants.orientation[orientation].reverseDirection : constants.orientation[orientation].direction;
        const clientCoordinateStyle = /** @type {const} */ (`client${capitalize(coordinateStyle)}`);
        const coordinate = getCoordinate(e, clientCoordinateStyle);
        const direction = node?.getBoundingClientRect?.()?.[directionStyle];
        const pos = reverse ? direction - coordinate - grab : coordinate - direction - grab;
        const value = getValueFromPosition(pos);
        return value;
    };

    /**
     * Grab coordinates of slider
     * @param  {Object} pos - Position object
     * @return {Object} - Slider fill/handle coordinates
     */
    coordinates = (pos) => {
        const { limit, grab } = this.state;
        const { orientation } = this.props;
        const { getValueFromPosition, getPositionFromValue } = this;
        const value = getValueFromPosition(pos);
        const position = getPositionFromValue(value);
        const handlePos = orientation === 'horizontal' ? position + grab : position;
        const fillPos = orientation === 'horizontal' ? handlePos : limit - handlePos;

        return {
            fill: fillPos,
            handle: handlePos,
            label: handlePos
        };
    };

    renderLabels = (labels) => (
        <ul
            ref={(sl) => {
                this.labels = sl;
            }}
            className={cx('rangeslider__labels')}
        >
            {labels}
        </ul>
    );

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        this.handleUpdate();
        this.resizeObserver = new ResizeObserver(this.handleUpdate);
        this.resizeObserver.observe(this.slider);
    }

    componentWillUnmount() {
        this.resizeObserver?.disconnect();
    }

    render() {
        const { value, orientation, className, tooltip, reverse, labels, min, max, handleLabel } = this.props;
        const { active } = this.state;
        const {
            handleKeyboardLabelInteraction,
            getPositionFromValue,
            coordinates,
            handleDrag,
            handleStart,
            handleEnd,
            handleKeyDown,
            renderLabels,
            handleFormat
        } = this;

        const dimension = constants.orientation[orientation].dimension;
        const direction = reverse ? constants.orientation[orientation].reverseDirection : constants.orientation[orientation].direction;
        const position = getPositionFromValue(value);
        const coords = coordinates(position);
        const fillStyle = { [dimension]: `${coords.fill}px` };
        const handleStyle = { [direction]: `${coords.handle}px` };
        const showTooltip = tooltip && active;

        const labelItems = [];
        const labelKeys = Object.keys(labels);

        if (labelKeys.length > 0) {
            for (const key of labelKeys) {
                const labelPosition = getPositionFromValue(key);
                const labelCoords = coordinates(labelPosition);
                const labelStyle = { [direction]: `${labelCoords.label}px` };
                labelItems.push(
                    <li key={key} className={cx('rangeslider__label-item')} data-value={key} style={labelStyle}>
                        <button
                            className={`rangeslider__label-button${this.props.labels[key].disabled ? ' rangeslider__label-button--disabled' : ''}`}
                            disabled={this.props.labels[key].disabled}
                            onMouseDown={handleDrag}
                            onTouchStart={handleStart}
                            onTouchEnd={handleEnd}
                            onKeyDown={(e) => {
                                handleKeyboardLabelInteraction(e, key);
                            }}
                        >
                            {this.props.labels[key].label}
                        </button>
                    </li>
                );
            }
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(s) => {
                    this.slider = s;
                }}
                role="slider"
                tabIndex={-1}
                className={cx('rangeslider', `rangeslider-${orientation}`, { 'rangeslider-reverse': reverse }, className)}
                onMouseDown={handleDrag}
                onMouseUp={handleEnd}
                onTouchStart={handleStart}
                onTouchEnd={handleEnd}
                onKeyDown={handleKeyDown}
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                aria-orientation={orientation}
            >
                <div tabIndex={-1} className="rangeslider__fill" style={fillStyle} />
                <div
                    ref={(sh) => {
                        this.handle = sh;
                    }}
                    role="button"
                    tabIndex={0}
                    className="rangeslider__handle"
                    onMouseDown={handleStart}
                    onTouchMove={handleDrag}
                    onTouchEnd={handleEnd}
                    onKeyDown={handleKeyDown}
                    style={handleStyle}
                >
                    {showTooltip ? (
                        <div
                            ref={(st) => {
                                this.tooltip = st;
                            }}
                            className="rangeslider__handle-tooltip"
                        >
                            <span>{handleFormat(value)}</span>
                        </div>
                    ) : null}
                    <div className="rangeslider__handle-label">{handleLabel}</div>
                </div>
                {labels ? renderLabels(labelItems) : null}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

Slider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    orientation: PropTypes.string,
    tooltip: PropTypes.bool,
    reverse: PropTypes.bool,
    labels: PropTypes.object,
    handleLabel: PropTypes.string,
    format: PropTypes.func,
    onChangeStart: PropTypes.func,
    onChange: PropTypes.func,
    onChangeComplete: PropTypes.func
};

export default Slider;
