/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ticket_name } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DatabaseAccessForm from '../forms/databaseAccessForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { disableDatabaseAccess, enableDatabaseAccess, listDatabaseAccess } from '../state/configActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DatabaseAccess extends Component {
    constructor(props) {
        super(props);
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.submitAccessRequest = this.submitAccessRequest.bind(this);
        this.disableAccessRequest = this.disableAccessRequest.bind(this);
        this.state = {
            showLightbox: false,
            mode: false,
            ipAccessList: undefined,
            dbInfo: {
                ip: null
            },
            dbMeta: {
                limit: 10
            }
        };
    }

    openLightbox(mode) {
        this.setState({
            showLightbox: true,
            mode: mode
        });
    }

    closeLightbox() {
        this.setState({
            showLightbox: false,
            mode: false
        });
    }

    submitAccessRequest(values) {
        const { hostingid, subaccount, enableDatabaseAccess, hosting_check_ip_data } = this.props;
        let ip = values.ipaddress;
        if (values.addIP) ip = hosting_check_ip_data?.ipv4.ip;
        else if (values.addIPv6) ip = hosting_check_ip_data?.ipv6.ip;

        enableDatabaseAccess(hostingid, { ip_address: ip }, subaccount);
    }

    disableAccessRequest(ipaddress) {
        const { hostingid, subaccount, disableDatabaseAccess } = this.props;
        const attributes = {
            ip_address: ipaddress
        };
        disableDatabaseAccess(hostingid, attributes, subaccount);
    }

    componentDidMount() {
        const {
            app_user_data,
            hosting_information_data: { included }
        } = this.props;

        this.setState({
            dbInfo: {
                ip: app_user_data.ip_address,
                port: '3306',
                hostname: included[1].attributes.server_hostname
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { hosting_database_list_status, hosting_database_list_data, hosting_database_list_meta, hosting_database_status } = this.props;
        registerScrollEvents(this, hosting_database_list_status === 'success' && prevProps.hosting_database_list_status === 'loading');

        if ((hosting_database_status === 'success' || hosting_database_status === 'error') && prevProps.hosting_database_status === 'loading') {
            this.closeLightbox();
        }

        if (hosting_database_list_status === 'error' && prevProps.hosting_database_list_status === 'loading') {
            this.closeLightbox();
        }

        if (hosting_database_list_status === 'success' && prevProps.hosting_database_list_status === 'loading') {
            this.setState({
                ipAccessList: hosting_database_list_data,
                showLightbox: false,
                mode: false,
                dbMeta: {
                    limit: hosting_database_list_meta?.limit || this.state.dbMeta.limit
                }
            });
        }
    }

    render() {
        const { hosting_database_list_status, hosting_database_status, listDatabaseAccess, subaccount, hostingid } = this.props;
        const { ipAccessList, showLightbox, mode, dbInfo, dbMeta } = this.state;
        const { openLightbox, submitAccessRequest, disableAccessRequest } = this;

        const handleLoadingStatus = () => {
            if (hosting_database_list_status === 'loading' || hosting_database_status === 'loading') {
                return 'loading';
            } else if (hosting_database_list_status === 'error') {
                return 'error';
            }
            return 'success';
        };

        /*   CREATE TABLE MATRIX
        /**********************************************************************************************************/
        const dbMatrix =
            ipAccessList?.map(({ attributes: { ip_address, date_added } }) => ({
                'ip': ip_address,
                'Date Added': toLuxonDate(date_added).toFormat('dd-MM-yyyy t'),
                'actions': {
                    label: 'Delete',
                    type: 'onClick',
                    className: '',
                    color: 'warning',
                    onClick: (e) => {
                        e.preventDefault();
                        disableAccessRequest(ip_address);
                    }
                }
            })) || [];

        let conditionalProps = {
            desc: `Open an external database connection by adding the IP Addresses you want to give access to.`,
            info: `We only allow external access for up to ${dbMeta.limit} IP addresses at a time. If you require external database access for more than ${dbMeta.limit} IP addresses then you will need to start a new ${ticket_name} with our Technical Support team requesting access for more than ${dbMeta.limit} IPs along with a full reason as to why you need access for more than ${dbMeta.limit} IPs.`
        };

        if (ipAccessList && ipAccessList.length < 1) {
            conditionalProps = {
                ...conditionalProps,
                action: {
                    label: 'Add IP Address',
                    type: 'onClick',
                    className: '',
                    color: '',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        this.setState({
                            showLightbox: true,
                            mode: 'add'
                        });
                    }
                }
            };
        }

        if (ipAccessList && ipAccessList.length >= 1) {
            conditionalProps = {
                ...conditionalProps,
                custom: {
                    render: (
                        <>
                            <div className="sshAccess__wrapper">
                                <div className="sshAccess__row">
                                    <div className="row__title">Hostname</div>
                                    <div className="row__desc">{dbInfo.hostname}</div>
                                </div>
                                <div className="sshAccess__row">
                                    <div className="row__title">Port</div>
                                    <div className="row__desc">{dbInfo.port}</div>
                                </div>
                                <div className="sshAccess__row">
                                    <div className="row__title">This device&apos;s IP Address</div>
                                    <div className="row__desc">{dbInfo.ip}</div>
                                </div>
                            </div>
                            <div className="sshAccess__table">
                                <Table
                                    embedded
                                    stacked
                                    loading={hosting_database_list_status}
                                    header={[
                                        {
                                            title: `IP Address`,
                                            className: `ipaddress`
                                        },
                                        {
                                            title: `Date Added`,
                                            className: `timestamp`
                                        },
                                        {
                                            action: true
                                        }
                                    ]}
                                    error=""
                                    matrix={dbMatrix}
                                    footer={{
                                        label: 'Add IP Address',
                                        icon: 'plus-faq',
                                        onClick: (e) => {
                                            e.preventDefault();
                                            openLightbox('add');
                                        }
                                    }}
                                />
                            </div>
                        </>
                    ),
                    pos: 'bottom'
                }
            };
        }

        if (ipAccessList && ipAccessList.length >= dbMeta.limit) {
            conditionalProps = {
                ...conditionalProps,
                custom: {
                    render: (
                        <>
                            <div className="sshAccess__wrapper">
                                <div className="sshAccess__row">
                                    <div className="row__title">Hostname</div>
                                    <div className="row__desc">{dbInfo.hostname}</div>
                                </div>
                                <div className="sshAccess__row">
                                    <div className="row__title">Port</div>
                                    <div className="row__desc">{dbInfo.port}</div>
                                </div>
                                <div className="sshAccess__row">
                                    <div className="row__title">This device&apos;s IP Address</div>
                                    <div className="row__desc">{dbInfo.ip}</div>
                                </div>
                            </div>
                            <div className="sshAccess__table">
                                <Table
                                    embedded
                                    stacked
                                    loading={hosting_database_list_status}
                                    header={[
                                        {
                                            title: 'IP Address',
                                            className: 'ipaddress'
                                        },
                                        {
                                            title: 'Date Added',
                                            className: 'timestamp'
                                        },
                                        {
                                            action: true
                                        }
                                    ]}
                                    error=""
                                    matrix={dbMatrix}
                                />
                            </div>
                        </>
                    ),
                    pos: 'bottom'
                }
            };
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="databaseAccess"
            >
                {/* Main Container */}
                <Box
                    request={{
                        action: listDatabaseAccess,
                        args: [hostingid, subaccount],
                        status: hosting_database_list_status
                    }}
                    className="databaseAccess__box"
                    title="External Database Access"
                    status={handleLoadingStatus()}
                    {...conditionalProps}
                />

                {/* Lightboxes */}
                {showLightbox && mode === 'add' && (
                    <OverlayLightbox
                        onOpen={showLightbox && mode === 'add'}
                        title="Add IP Address for Database Access"
                        onClose={this.closeLightbox}
                        loading={hosting_database_status}
                    >
                        <DatabaseAccessForm mode={mode} onSubmit={submitAccessRequest} />
                    </OverlayLightbox>
                )}

                {showLightbox && mode === 'edit' && (
                    <OverlayLightbox
                        onOpen={showLightbox && mode === 'edit'}
                        title="Edit IP Address for Database Access"
                        onClose={this.closeLightbox}
                        loading={hosting_database_status}
                    >
                        <DatabaseAccessForm mode={mode} onSubmit={submitAccessRequest} />
                    </OverlayLightbox>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            hosting_database_list_status: state.hosting.hosting_database_list_status,
            hosting_database_list_data: state.hosting.hosting_database_list_data,
            hosting_database_list_meta: state.hosting.hosting_database_list_meta,
            hosting_database_status: state.hosting.hosting_database_status,
            hosting_information_data: state.hosting.hosting_information_data,
            hosting_check_ip_data: state.hosting.hosting_check_ip_data,
            app_user_data: state.app.app_user_data
        }),
        {
            listDatabaseAccess,
            enableDatabaseAccess,
            disableDatabaseAccess
        }
    )(DatabaseAccess)
);
