/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TPPDomainContactsForm from 'containers/domain/tppDomains/forms/contacts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Accordion from 'components/Accordion';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainContactsQuery } from '../queries/useTppDomainContactsQuery';
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { useTppDomainUpdateContactsMutation } from 'containers/domain/tppDomains/queries/mutations';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getIsLegacyDomainTransferLocked } from 'containers/domain/tppDomains/modules/transferLock/methods';
import { getLocalStates, getCountries } from 'App/action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain contacts module
 */
function TPPDomainContacts({ path }) {
    /***** HOOKS *****/
    const params = useParams();
    const location = useLocation();
    const scrollRef = useRef(null);
    const { app_countries_status, app_countries_data, app_local_states_status } = useSelector((state) => ({
        app_countries_status: state.app.app_countries_status,
        app_countries_data: state.app.app_countries_data,
        app_local_states_status: state.app.app_local_states_status
    }));

    /***** QUERIES *****/
    const {
        data: tpp_domain_contacts_data,
        status: tpp_domain_contacts_status,
        isFetching: isTppDomainContactsFetching
    } = useTppDomainContactsQuery(params.id);
    const { data: tpp_domain_transfer_lock_data } = useTppDomainTransferLockQuery(params.id);
    const { mutate: mutateTppDomainUpdateContacts, isPending: isTppDomainUpdateContactsLoading } = useTppDomainUpdateContactsMutation();

    /***** FUNCTIONS *****/
    function handleContactUpdate(values) {
        // Convert back from 2 letter code to uppercase country name before sending back to TPP
        const country =
            app_countries_data?.find(({ attributes }) => attributes.country_code === values.country)?.attributes.country ?? values.country ?? '';

        const payload = {
            registrant: { ...values, country: country.toUpperCase() }
        };

        mutateTppDomainUpdateContacts({ domainId: params.id, newContacts: payload });
    }

    /***** RENDER HELPERS *****/
    const isTransferLocked = getIsLegacyDomainTransferLocked(tpp_domain_transfer_lock_data);
    const registrantContactData = tpp_domain_contacts_data?.attributes?.contact?.registrant || {};

    function getInitialValues() {
        let country = '';

        // Map the uppercase country name coming from TPP, to one of the codes from the "country" endpoint data. Form needs the value to be the 2 letter code, not the country name
        if (app_countries_data && typeof registrantContactData.country === 'string') {
            country =
                app_countries_data.find(({ attributes }) => (attributes.country || '').toLowerCase() === registrantContactData.country.toLowerCase())
                    ?.attributes.country_code || '';
        }

        return {
            ...registrantContactData,
            country
        };
    }

    function getInitialStatus() {
        if (tpp_domain_contacts_status === 'pending' || [app_countries_status, app_local_states_status].includes('loading')) return 'loading';
        return tpp_domain_contacts_status;
    }

    const initialStatus = getInitialStatus();

    /***** EFFECTS *****/
    useEffect(() => {
        getLocalStates();
        getCountries();
    }, []);

    useEffect(() => {
        registerScrollEvents({ props: { path, location }, scrollRef: scrollRef.current }, initialStatus === 'success');
    }, [initialStatus]);

    /***** RENDER *****/
    return (
        <section ref={scrollRef} className="tppDomainContacts">
            <NXBox initialStatus={initialStatus} isLoading={isTppDomainContactsFetching}>
                <NXBox.Top title="Domain Name Contacts" />
                <NXBox.DefaultPadding>
                    {isTransferLocked ? (
                        <DialogNotification type="warning">
                            Domain name contacts for this domain cannot currently be updated as the domain is transfer locked.
                        </DialogNotification>
                    ) : (
                        ''
                    )}
                    <Accordion
                        title="Registrant Contact"
                        content={
                            <TPPDomainContactsForm
                                initialValues={getInitialValues()}
                                onSubmit={handleContactUpdate}
                                isTppDomainUpdateContactsLoading={isTppDomainUpdateContactsLoading}
                            />
                        }
                    />
                </NXBox.DefaultPadding>
            </NXBox>
        </section>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainContacts;
