/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_HelpBox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The HelpBox component provides a standardized box that should be used to provide help text to the user.
 * This is in the format of a title, description and a list of bullet points, similar to the NXBox but with bullet points.
 */
function HelpBox({ isOpen, title, desc, bullets }) {
    /***** RENDER *****/
    return (
        <div className={classNames('HelpBox', { 'HelpBox--open': isOpen })}>
            <div className="HelpBox__triangle">
                <div className="HelpBox__triangleInner"></div>
            </div>
            {title ? <div className="HelpBox__title">{title}</div> : ''}
            {desc ? <div className="HelpBox__desc">{desc}</div> : ''}
            {bullets ? (
                <ul className="HelpBox__bullets">
                    {bullets.map((bullet, index) => (
                        <li key={index}>{bullet}</li>
                    ))}
                </ul>
            ) : (
                ''
            )}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

// This component is designed to be used along side an input field. The absolute positioning will render it to the right of the input on large screen sizes, and then wrap underneath the input once the screen starts to get smaller. Place the HelpBox inside of "form__column" element, immediately after an input field.
HelpBox.propTypes = {
    // Whether or not the HelpBox should be shown
    isOpen: PropTypes.bool,

    // Title of help box (primary color text)
    title: PropTypes.string,

    // Description of help box
    desc: PropTypes.string,

    // Bullet point to show after the description
    bullets: PropTypes.arrayOf(PropTypes.string)
};

export default HelpBox;
