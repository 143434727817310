/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import Icons from 'components/Icons';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_checkedIcon.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The green check icon, and the text shown when transfer lock is enabled
 */
function TransferLockCheckedMessage() {
    /***** RENDER *****/
    return (
        <div className="transferLockCheckedMessage">
            <Icons.TickSolid confirm />
            <div className="transferLockCheckedMessage__text">
                <Text secondary semiBold>
                    Transfer Lock is enabled
                </Text>
                <Text size--s secondary>
                    This domain name is unable to be transferred to another registrar.
                </Text>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TransferLockCheckedMessage;
