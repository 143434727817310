/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NXDropZoneCompactWithSingleImageControlAndUpload from 'components/Form/NXDropZone/variants/NXDropZoneCompactWithSingleImageControlAndUpload';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAcceptMimeTypes } from 'containers/katana/formFields/file/methods';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaFileFormFieldNXDropZoneCompact: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, type, caveats, validationFunctions } = property;

    /***** RENDER HELPERS *****/
    const mimeTypeAccept = useGetCaveat(CaveatTypes.IMAGE_MIME_TYPE, caveats, []);
    const dropzoneMimeTypeAccept = createAcceptMimeTypes(mimeTypeAccept);

    /***** RENDER *****/
    return (
        <Field
            name={key}
            label={name}
            component={NXDropZoneCompactWithSingleImageControlAndUpload}
            type={type}
            accept={dropzoneMimeTypeAccept}
            validate={validationFunctions}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormFieldNXDropZoneCompact };
