/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import { BUTTON_COLORS, BUTTON_TYPES } from 'components/Buttons/_BaseButton';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';
import { renderCheckboxField, requiredCheckboxFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

const formName = 'gsuiteVerifyDomainForm';
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let GSuiteVerifyDomainForm = ({
    gsuite_verify_record_status,
    gsuite_verification_status_status,
    gsuite_verification_status,
    handleSubmit,
    verification,
    copyToClipboard
}) => {
    if (gsuite_verification_status_status === 'loading' || gsuite_verification_status === 'loading' || gsuite_verify_record_status === 'loading') {
        return <RequestLoader />;
    }

    return (
        <form className="gsuiteVerify__form" onSubmit={handleSubmit}>
            <div className="gsuiteVerify__wrapper">
                <div className="gsuiteVerify__container">
                    <div className="gsuiteVerify__label">TXT Record</div>
                    <div className="gsuiteVerify__record">{verification.record}</div>
                </div>
                <div className="gsuiteVerify__action">
                    <SolidButton
                        color={BUTTON_COLORS.SECONDARY}
                        type={BUTTON_TYPES.ON_CLICK}
                        onClick={() => void copyToClipboard(verification.record, true)}
                    >
                        Copy Record to Clipboard
                    </SolidButton>
                </div>
            </div>
            <div className="form__row">
                <span className="sharedBox__description">Using cPanel to manage your DNS records? View our guide on </span>
                <Anchor to="/support/support-centre/web-hosting/adding-removing-and-managing-dns-records-in-cpanel">
                    Adding DNS records in cPanel
                </Anchor>
            </div>
            <div className="form__row">
                <span className="sharedBox__description">Using Free DNS Hosting to manage your DNS records? View our guide on </span>
                <Anchor to="/support/support-centre/domain-names/editing-your-dns-records-from-vipcontrol-using-free-dns-hosting">
                    Adding DNS records in {application} using Free DNS Hosting
                </Anchor>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        name="record_added"
                        label="I have added the TXT record to my DNS"
                        component={renderCheckboxField}
                        validate={[requiredCheckboxFieldValidation]}
                        type="checkbox"
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <ReduxFormButton form={formName} color={BUTTON_COLORS.CONFIRM} className="form__column full">
                    Verify Domain Name
                </ReduxFormButton>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
GSuiteVerifyDomainForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(GSuiteVerifyDomainForm);

const mapStateToProps = (state) => {
    return {
        gsuite_verify_record_status: state.gsuite.gsuite_verify_record_status,
        gsuite_verification_status_status: state.gsuite.gsuite_verification_status_status,
        gsuite_verification_status: state.gsuite.gsuite_verification_status,
        gsuite_verification_data: state.gsuite.gsuite_verification_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            copyToClipboard
        },
        dispatch
    );

GSuiteVerifyDomainForm = connect(mapStateToProps, mapDispatchToProps)(GSuiteVerifyDomainForm);

export default GSuiteVerifyDomainForm;
