/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { Field, reduxForm, change, getFormValues, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PasswordChecker from 'components/PasswordChecker';
import { validateDedicatedEmailsRequirements, dedicatedEmailsRequirements, excludedCharacters } from 'components/PasswordChecker/axigen';

/*   ACTIONS
 *****************************************************/
import { validateConfirmValidation, requiredFieldValidation, renderButton, RenderField, RenderRevealPasswordField } from 'utilities/methods/form';
import PasswordGenerator from 'components/Password/Generator';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EditOverview extends Component {
    componentDidMount() {
        const { data, form, dispatch } = this.props;

        if (data) {
            Object.entries(data).forEach((value) => {
                switch (value[0]) {
                    case `password`:
                        dispatch(change(form, value[0], value[1]));
                        dispatch(change(form, `password_confirmation`, value[1]));
                        break;

                    case `first_name`:
                        dispatch(change(form, `firstname`, value[1]));
                        break;

                    case `last_name`:
                        dispatch(change(form, `lastname`, value[1]));
                        break;

                    default:
                        break;
                }
            });
        }
    }

    render() {
        const { formValues, password_input, pristine, submitting, valid, handleSubmit } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="popup__form editEmailService" onSubmit={handleSubmit}>
                <div className="form__row">
                    <div className="form__column half">
                        <Field
                            label="First Name"
                            name="firstname"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                    <div className="form__column half">
                        <Field
                            label="Last Name"
                            name="lastname"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Password"
                            name="password"
                            component={RenderRevealPasswordField}
                            validate={[requiredFieldValidation, validateDedicatedEmailsRequirements]}
                            className="form__textfield"
                            parent={this}
                        />
                    </div>
                </div>
                {formValues ? (
                    <div className="form__row">
                        <div className="form__column half">
                            <PasswordChecker input={password_input} requirements={dedicatedEmailsRequirements} />
                        </div>
                        <div className="form__column half">
                            <PasswordGenerator excludedCharacters={excludedCharacters} />
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Confirm Password"
                            name="password_confirmation"
                            component={RenderRevealPasswordField}
                            validate={[requiredFieldValidation, validateConfirmValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                {renderButton(pristine, submitting, valid, 'Update Details')}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
EditOverview = reduxForm({
    enableReinitialize: true
})(EditOverview);

const mapStateToProps = (state) => {
    const formValues = getFormValues(`editEmailService`)(state);
    const selector = formValueSelector(`editEmailService`);
    const password_input = selector(state, 'password');

    return {
        form: `editEmailService`,
        password_input,
        formValues
    };
};

export default connect(mapStateToProps)(EditOverview);
