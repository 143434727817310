import _RequestLoaderWithBackground from 'components/Loaders/Request/_WithBackground';
import _RequestLoaderMultiLoader from 'components/Loaders/Request/_multiLoader';
import _RequestLoader from 'components/Loaders/Request/_requestLoader';

const RequestLoader = Object.assign(_RequestLoader, {
    MultiLoader: _RequestLoaderMultiLoader,
    WithBackground: _RequestLoaderWithBackground
});

export default RequestLoader;
