/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Icons from 'components/Icons';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PromoCodePill.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PromoCodePill({ code, removeButtonOnClick, isRemoveable }) {
    /***** RENDER *****/
    return (
        <div className={classNames('PromoCodePill', { 'PromoCodePill--removeable': isRemoveable })}>
            <div className="PromoCodePill__text">{code}</div>
            {isRemoveable ? (
                <button className="PromoCodePill__remove" type="button" onClick={() => removeButtonOnClick()}>
                    <Icons.Close />
                </button>
            ) : (
                ''
            )}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PromoCodePill.propTypes = {
    /**
     * The promo code to display in the pill
     */
    code: PropTypes.node,

    /**
     * Function to call when the "X" button is clicked
     */
    removeButtonOnClick: PropTypes.func,

    /**
     * Whether the promo code can be remove or not
     */
    isRemoveable: PropTypes.bool
};

export default PromoCodePill;
