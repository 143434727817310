/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Security from '../sections/security';
import General from './sections/general';

import TPPDomainContacts from './modules/contacts';
import TPPDomainMigrate from './modules/migrate';
import TPPDomainNameservers from './modules/nameservers';
import TPPDomainOverview from './modules/overview';
import TPPDomainRenew from './modules/renew';

import TPPDomainEPPCode from './modules/eppCode';
import TPPDomainTransferLock from './modules/transferLock';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import PageNotFound from 'components/Errors/PageNotFound';
import Icons from 'components/Icons';
import Sidebar from 'components/Sidebar';
import Subnav from 'components/Subnav';
import SolidTag from 'components/Tags/SolidTag';
import Title from 'components/Title';
import Transition from 'components/Transition';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { useTppDomainQuery } from './queries/useTppDomainQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getIsLegacyDomainTransferLocked } from 'containers/domain/tppDomains/modules/transferLock/methods';
import { handleSideMenuOptions, isURLValid } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_tppDomain.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The TPP domain manage page
 */
function TPPDomain() {
    /***** HOOKS *****/
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const { id } = params;

    const { app_viewport } = useSelector((state) => ({
        app_viewport: state.app.app_viewport
    }));

    /***** QUERIES *****/
    const { data: tpp_domain_data, isLoading: isTppDomainLoading } = useTppDomainQuery(params.id);
    const { data: tpp_domain_transfer_lock_data } = useTppDomainTransferLockQuery(params.id);

    /***** RENDER HELPERS *****/
    const isTransferLocked = getIsLegacyDomainTransferLocked(tpp_domain_transfer_lock_data);

    const renderTitle = () => {
        const serviceTitle = (
            <div className="tppDomain__serviceTitle">
                <Text size--xl black>
                    {tpp_domain_data?.attributes?.domain || ''}
                </Text>
                <div className="tppDomain__serviceTitleLegacy">
                    <SolidTag color="legacy">Legacy</SolidTag>
                    {/* TEMP: remove until we have FAQ's */}
                    {/* <Anchor href={legacyDomainsFaqLink} target="_blank">
                        What does this mean?
                    </Anchor> */}
                </div>
            </div>
        );

        return (
            <Title
                isLoading={isTppDomainLoading}
                serviceTitle={serviceTitle}
                serviceType="legacy-domain"
                history={history}
                action={[
                    <CustomDropdown
                        className="TitleShared__singleDropdown"
                        label={({ labelRef, dropdownTitleOnClick }) => (
                            <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={dropdownTitleOnClick}>
                                Domain Names Help
                                <Icons.ChevronDown />
                            </button>
                        )}
                        key="dropdown"
                        render={() => (
                            <SelectOptions
                                options={[
                                    {
                                        label: 'Domain Name Help Articles',
                                        onSelect: () => history.push('/support/support-centre/domain-names')
                                    }
                                    // TEMP: disable as don't have time. Will do later
                                    // {
                                    //     label: 'Submit a Technical Support eTicket for this Service',
                                    //     onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                    // },
                                    // {
                                    //     label: 'Submit a Billing eTicket for this Service',
                                    //     onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                    // }
                                ]}
                            />
                        )}
                    />
                ]}
            />
        );
    };

    const modules = {
        general: [
            {
                sidebarLink: {
                    label: 'Migrate Domain',
                    link: `/my-services/domains/legacy/general/migrate/${id}`
                },
                component: <TPPDomainMigrate />,
                condition: true
            },
            {
                sidebarLink: {
                    label: 'Domain Overview',
                    link: `/my-services/domains/legacy/general/overview/${id}`
                },
                component: <TPPDomainOverview />,
                condition: true
            },
            {
                sidebarLink: {
                    label: 'Renew Domain Name',
                    link: `/my-services/domains/legacy/general/renew/${id}`
                },
                component: <TPPDomainRenew />,
                condition: true
            },
            {
                sidebarLink: {
                    label: 'Contacts',
                    link: `/my-services/domains/legacy/general/contacts/${id}`
                },
                component: <TPPDomainContacts />,
                condition: true
            },
            {
                sidebarLink: {
                    label: 'Domain Nameservers',
                    link: `/my-services/domains/legacy/general/nameservers/${id}`
                },
                component: <TPPDomainNameservers />,
                condition: true
            }
        ],
        security: [
            {
                sidebarLink: {
                    label: 'Transfer Lock',
                    link: `/my-services/domains/legacy/security/transfer/${id}`
                },
                component: <TPPDomainTransferLock />,
                condition: isTransferLocked
            },
            {
                sidebarLink: {
                    label: 'EPP Password',
                    link: `/my-services/domains/legacy/security/epp/${id}`
                },
                component: <TPPDomainEPPCode />,
                condition: true
            }
        ]
    };

    const renderSidemenu = () => {
        const generalList = {
            list_title: 'General',
            list_icon: 'accounts',
            list_items: handleSideMenuOptions(modules, 'general')
        };

        const securityList = {
            list_title: 'Security',
            list_icon: 'secure-hosting',
            list_items: handleSideMenuOptions(modules, 'security')
        };

        return <Sidebar title="Manage Domain" icon="domain-names" links={[generalList, securityList]} />;
    };

    const renderSection = () => {
        switch (params.subpage) {
            default:
            case 'general':
                return <General modules={modules.general.filter(({ condition }) => condition)} />;
            case 'security':
                return <Security modules={modules.security.filter(({ condition }) => condition)} />;
        }
    };

    const handleRenderState = () => {
        if (!isURLValid(modules, location.pathname)) return <PageNotFound />;

        return (
            <>
                {renderTitle()}
                {app_viewport === 'sm' || app_viewport === 'xs' ? renderSidemenu() : ''}
                <div className="tppDomain">
                    {app_viewport === 'md' || app_viewport === 'lg' || app_viewport === 'xl' ? renderSidemenu() : ''}
                    <Transition when={params.subpage} className="tppDomain__section">
                        {renderSection()}
                    </Transition>
                </div>
            </>
        );
    };

    /***** RENDER *****/
    return (
        <div className="tppDomainPage">
            <Subnav pathname={location.pathname} />
            {handleRenderState()}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default TPPDomain;
