// See comment in "containers/services/forms/cancelServiceForm/index.js". Ideally we would eventually want to get rid of this component, in favour of that one.

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Cancel.scss';

/**********************************************************************************************************
 *   EXPORTED FUNCTIONS
 **********************************************************************************************************/
export const getKeepService = (status) => status === 'Pending Cancellation' || status === 'pending cancellation';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The CancelService component provides a way to cancel (or keep) a service. This is typically used in relation
 * to services on the dashboard/myservices page
 */
class CancelService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCancelLightbox: false,
            showKeepLightbox: false
        };
        this.openCancelForm = this.openCancelForm.bind(this);
        this.closeCancelForm = this.closeCancelForm.bind(this);
        this.openKeepLightbox = this.openKeepLightbox.bind(this);
        this.closeKeepLightbox = this.closeKeepLightbox.bind(this);
    }

    /************** OPEN/CLOSE FORM LIGHTBOX **************/
    openCancelForm() {
        this.setState({
            showCancelLightbox: true
        });
    }

    closeCancelForm() {
        this.setState({
            showCancelLightbox: false
        });
    }

    /************** OPEN/CLOSE KEEP LIGHTBOX **************/
    openKeepLightbox() {
        this.setState({
            showKeepLightbox: true
        });
    }

    closeKeepLightbox() {
        this.setState({
            showKeepLightbox: false
        });
    }

    componentDidUpdate(prevProps) {
        const {
            history,
            service_reverse_cancel_status,
            gsuite_reverse_cancel_status,
            service_cancel_status,
            domain_cancel_status,
            gsuite_cancel_status,
            email_delete_status,
            vps_cancel_status,
            ms365_delete_service_status,
            ms365_reverse_delete_service_status,
            cancel
        } = this.props;

        if (
            (service_cancel_status === 'success' && prevProps.service_cancel_status === 'loading') ||
            (domain_cancel_status === 'success' && prevProps.domain_cancel_status === 'loading') ||
            (gsuite_cancel_status === 'success' && prevProps.gsuite_cancel_status === 'loading') ||
            (email_delete_status === 'success' && prevProps.email_delete_status === 'loading') ||
            (vps_cancel_status === 'success' && prevProps.vps_cancel_status === 'loading') ||
            (ms365_delete_service_status === 'success' && prevProps.ms365_delete_service_status === 'loading') ||
            (service_reverse_cancel_status === 'success' && prevProps.service_reverse_cancel_status === 'loading') ||
            (gsuite_reverse_cancel_status === 'success' && prevProps.gsuite_reverse_cancel_status === 'loading') ||
            (ms365_reverse_delete_service_status === 'success' && prevProps.ms365_reverse_delete_service_status === 'loading')
        ) {
            this.setState(
                {
                    showCancelLightbox: false,
                    showKeepLightbox: false
                },
                () => {
                    if (cancel.onSuccess) {
                        cancel.onSuccess();
                    }

                    switch (cancel.service) {
                        case 'vps':
                            history.push(`/my-services/vps`);
                            break;
                        case 'webHosting':
                            history.push(`/my-services/hosting`);
                            break;
                        case 'gsuite':
                            history.push(`/my-services/google`);
                            break;
                        case 'ms365':
                            history.push(`/my-services/microsoft-365`);
                            break;
                        case 'emailHosting':
                            history.push(`/my-services/email-hosting`);
                            break;
                        default:
                            history.push(`/my-services`);
                            break;
                    }
                }
            );
        }
    }

    render() {
        const {
            title,
            desc,
            label,
            button,
            cancel,
            domain_cancel_status,
            service_cancel_status,
            email_delete_status,
            gsuite_cancel_status,
            vps_cancel_status,
            ms365_delete_service_status,
            service_reverse_cancel_status,
            gsuite_reverse_cancel_status,
            ms365_reverse_delete_service_status
        } = this.props;
        const { showCancelLightbox, showKeepLightbox } = this.state;
        const { openCancelForm, closeCancelForm, openKeepLightbox, closeKeepLightbox } = this;

        const handleButtonRender = () => {
            switch (button.type) {
                case 'solid':
                    if (cancel && getKeepService(cancel.status)) {
                        return (
                            <SolidButton
                                className={`cancelService__button${button.className ? ' ' + button.className : ''}`}
                                type="onClick"
                                size={button.size || null}
                                onClick={(e) => {
                                    e.preventDefault();
                                    openKeepLightbox();
                                }}
                            >
                                Keep Service
                            </SolidButton>
                        );
                    }

                    return (
                        <SolidButton
                            className={`cancelService__button${button.className ? ' ' + button.className : ''}`}
                            color="warn"
                            size={button.size || null}
                            type="onClick"
                            onClick={(e) => {
                                e.preventDefault();
                                openCancelForm();
                            }}
                        >
                            {button.label}
                        </SolidButton>
                    );

                case 'outline':
                default:
                    if (cancel && getKeepService(cancel.status)) {
                        return (
                            <OutlineButton
                                className={`cancelService__button${button.className ? ' ' + button.className : ''}`}
                                size={button.size || null}
                                type="onClick"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openKeepLightbox();
                                }}
                            >
                                Keep Service
                            </OutlineButton>
                        );
                    }

                    return (
                        <OutlineButton
                            className={`cancelService__button${button.className ? ' ' + button.className : ''}`}
                            size={button.size || null}
                            color="warn"
                            type="onClick"
                            onClick={(e) => {
                                e.preventDefault();
                                openCancelForm();
                            }}
                        >
                            {button.label}
                        </OutlineButton>
                    );
            }
        };

        const handleLoadingStatus = () => {
            if (
                domain_cancel_status === 'loading' ||
                service_cancel_status === 'loading' ||
                email_delete_status === 'loading' ||
                gsuite_cancel_status === 'loading' ||
                vps_cancel_status === 'loading' ||
                ms365_delete_service_status === 'loading' ||
                service_reverse_cancel_status === 'loading' ||
                gsuite_reverse_cancel_status === 'loading' ||
                ms365_reverse_delete_service_status === 'loading'
            ) {
                return 'loading';
            } else if (
                domain_cancel_status === 'error' ||
                service_cancel_status === 'error' ||
                email_delete_status === 'error' ||
                gsuite_cancel_status === 'error' ||
                vps_cancel_status === 'error' ||
                ms365_delete_service_status === 'error' ||
                service_reverse_cancel_status === 'error' ||
                gsuite_reverse_cancel_status === 'error' ||
                ms365_reverse_delete_service_status === 'error'
            ) {
                return 'error';
            }

            return 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <Fragment>
                {button ? (
                    handleButtonRender()
                ) : (
                    <Box
                        premounted={true}
                        className="cancelService__box"
                        title={title}
                        desc={desc}
                        color="warn"
                        action={{
                            label: label,
                            type: 'onClick',
                            className: '',
                            color: 'warn',
                            size: 'large',
                            onClick: (e) => {
                                e.preventDefault();
                                openCancelForm();
                            }
                        }}
                    />
                )}
                <OverlayLightbox
                    title="Cancel Service"
                    onOpen={showCancelLightbox}
                    onClose={closeCancelForm}
                    cancel={{
                        ...cancel,
                        closeText: 'Go Back',
                        closeAction: closeCancelForm
                    }}
                    loading={handleLoadingStatus()}
                />
                <OverlayLightbox
                    onOpen={showKeepLightbox}
                    onClose={closeKeepLightbox}
                    title={cancel?.keep?.title}
                    confirm={{
                        desc: htmr(cancel?.keep?.desc || ''),
                        buttonText: 'Keep Active',
                        buttonAction: cancel?.keep?.action,
                        closeText: 'No, Go Back',
                        closeAction: closeKeepLightbox,
                        loading: handleLoadingStatus()
                    }}
                />
            </Fragment>
        );
    }
}

CancelService.propTypes = {
    /**
     * The title of the cancel service overlay when it is visible
     */
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            action: PropTypes.shape({
                icon: PropTypes.string,
                onClick: PropTypes.func
            }),
            links: PropTypes.shape({
                sub: PropTypes.shape({
                    title: PropTypes.string,
                    onClick: PropTypes.func
                }),
                main: PropTypes.shape({
                    title: PropTypes.string,
                    onClick: PropTypes.func
                })
            }),
            link: PropTypes.shape({
                title: PropTypes.string,
                onClick: PropTypes.func
            })
        })
    ]),

    /**
     * The description of the cancel service box
     */
    desc: PropTypes.string,

    /**
     * The label of the cancel service box
     */
    label: PropTypes.string,

    /**
     * The button of the cancel service box, This button information will be shown in place of the "Keep Service" button when
     * Cancel.status is not "Pending Cancellation", otherwise it will show the keep service button. If this is not provided, a box is rendered
     * that displays the desc, label (button) and title
     */
    button: PropTypes.shape({
        /**
         * The className of the cancel service box
         */
        className: PropTypes.string,

        /**
         * How the button should be displayed
         */
        type: PropTypes.oneOf(['solid', 'outline']),

        /**
         * The size of the cancel service box
         */
        size: PropTypes.oneOf(['small', 'medium', 'large']),

        /**
         * The label of the cancel service box, This is shown when the button is pressed as the title of the Overlay Lightbox
         */
        label: PropTypes.string
    }),

    /**
     * The cancel object of the cancel service box, this is used to specify that the service is currently pending cancellation
     * and to specify the keep service box (instead of the button provided)
     */
    cancel: PropTypes.shape({
        /**
         * The status of the cancel service box - providing "Pending Cancellation" will show the keep service option, otherwise
         * It will show the standard Button Label
         */
        status: PropTypes.oneOf(['Pending Cancellation', 'pending cancellation', PropTypes.string]),

        keep: PropTypes.shape({
            /**
             * The Title of the overlay lightbox when the "Keep Service" button is pressed
             */
            title: PropTypes.string,

            /**
             * The description of the overlay lightbox when the "Keep Service" button is pressed
             */
            desc: PropTypes.string,

            /**
             * The action of "Keep Active" button in the lightbox when the "Keep Service" button is pressed
             */
            action: PropTypes.func
        })
    })
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_reverse_cancel_status: state.cancel.gsuite_reverse_cancel_status,
        domain_cancel_status: state.cancel.domain_cancel_status,
        email_delete_status: state.cancel.email_delete_status,
        gsuite_cancel_status: state.cancel.gsuite_cancel_status,
        vps_cancel_status: state.cancel.vps_cancel_status,
        service_cancel_status: state.cancel.service_cancel_status,
        service_reverse_cancel_status: state.cancel.service_reverse_cancel_status,
        ms365_delete_service_status: state.cancel.ms365_delete_service_status,
        ms365_reverse_delete_service_status: state.cancel.ms365_reverse_delete_service_status
    };
};

export default withRouter(connect(mapStateToProps, null)(CancelService));
