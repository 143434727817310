/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxTop.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Wraps and styles the widely used combo of Box title, Box description, and some other UI such as a button
 *
 * @param {{
 *   title: string,
 *   description: string,
 *   children: React.ReactNode,
 *   app_viewport?: string
 * }} props
 */
/**
 * @param {{
 *     title?: React.ReactNode,
 *     description?: React.ReactNode,
 *     children?: React.ReactNode
 * }} props
 */
function NXBoxTop({ title, description, children }) {
    /***** RENDER HELPERS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    const padding = isMobile ? 4 : 6;

    /***** RENDER *****/
    return (
        <Padding x={padding} y={padding}>
            <div className="NXBoxTop">
                <Flex className="NXBoxTop__details" direction="column" gap={0}>
                    <NXBox.Title title={title} />
                    {description ? <NXBox.Description description={description} /> : null}
                </Flex>
                {children}
            </div>
        </Padding>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxTop.propTypes = {
    /**
     * Box title
     */
    title: PropTypes.string,

    /**
     * Box description
     */
    description: PropTypes.string,

    /**
     * Whatever UI you want to go in the top right of the box
     */
    children: PropTypes.node
};

export default NXBoxTop;
