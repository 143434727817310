/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderField, renderButton, renderCheckboxField, requiredAcceptedValidation, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { cPanelUsernameValidation } from 'utilities/methods/form/cPanelUsernameValidation';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DetailsForm extends Component {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { pristine, submitting, valid, handleSubmit } = this.props;

        return (
            <form className="hostingDetails__form" onSubmit={handleSubmit}>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="New Username"
                            name="username"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation, cPanelUsernameValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="confirmation__container">
                    <h5 className="confirmation__heading">Confirmation</h5>
                    <div className="confirmation__input">
                        <Field
                            name="SQL"
                            label="I confirm changing the username will break any MySQL connections currently being used in files referencing the previous username and updating those files are my responsibility."
                            component={renderCheckboxField}
                            validate={[requiredAcceptedValidation]}
                            type="checkbox"
                            className="form__textfield"
                        />
                        <Field
                            name="path"
                            label="I confirm any files using absolute paths are my responsibility to update."
                            component={renderCheckboxField}
                            validate={[requiredAcceptedValidation]}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                {renderButton(pristine, submitting, valid, 'Change Username')}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DetailsForm = reduxForm({
    form: 'detailsForm',
    touchOnChange: true
})(DetailsForm);

export default withRouter(DetailsForm);
