/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChangeRegistrantForm from '../forms/changeRegistrant';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleReadFiles } from 'components/Form/NXDropZone/utilities';

/*   ACTIONS
 *****************************************************/
import useEffectAfterMount from 'utilities/hooks/useEffectAfterMount';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { cancelCor, getCoRPrice, getPendingCor, initiateCor, initiateManualCOR } from '../action';
import { getCoRDisplayPrice } from '../methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ChangeRegistrant = (props) => {
    const {
        disabled,
        /** Redux Props */
        app_check_token_data,
        app_user_data,
        domain_cor_action_data,
        domain_cor_action_status,
        domain_cor_data,
        domain_cor_status,
        domain_get_cor_price_data,
        domain_get_cor_price_status
    } = props;

    /***** STATE *****/
    const [corState, setCorState] = useState('default');
    const [input_values, setInput_values] = useState(null);
    const [showConfirmLightbox, setShowConfirmLightbox] = useState(false);
    const [showCancelLightbox, setShowCancelLightbox] = useState(false);
    const [showInvoiceLightbox, setShowInvoiceLightbox] = useState(false);
    const [invoiceID, setInvoiceID] = useState(null);

    /***** HOOKS *****/
    const scrollRef = useRef();
    const { id: domainId } = useParams();

    /***** FUNCTIONS *****/
    /************** OPEN/CLOSE PAY INVOICE **************/
    function openPayInvoice(id) {
        setShowConfirmLightbox(false);
        setShowInvoiceLightbox(true);
        setInvoiceID(id);
    }

    function closePayInvoice() {
        setShowInvoiceLightbox(false);
    }

    function closePayInvoiceSuccess() {
        setShowInvoiceLightbox(false);
        setInvoiceID(null);
        getPendingCor(domainId);
    }

    /************** CONFIRM CHANGE OF REGISTRANT **************/
    function openConfirm(values) {
        setInput_values(values);
        setShowConfirmLightbox(true);
    }

    function closeConfirm() {
        setShowConfirmLightbox(false);
    }

    /************** CANCEL CHANGE OF REGISTRANT **************/
    function openCancel(invoice_id) {
        setShowCancelLightbox(true);
        setInvoiceID(invoice_id);
    }

    function closeCancel() {
        setShowCancelLightbox(false);
    }

    /************** SUBMIT CHANGE OF REGISTRANT **************/
    function submitCancel() {
        cancelCor(invoiceID, domainId);
    }

    async function submitChangeRegistrant(values) {
        let post = values ? values : input_values;

        if (domain_cor_data?.attributes?.requires_manual && !app_check_token_data?.is_vpn) {
            const attachments = await handleReadFiles(values.attachments);

            delete post.method;
            delete post.attachments;

            return initiateManualCOR(domainId, {
                ...post,
                attachments
            });
        }

        if (post.method === 'keep') {
            post = {
                email: app_user_data.email,
                years: post.years
            };
        }

        return initiateCor(domainId, post);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        getCoRPrice(domainId);
    }, []);

    useEffect(() => {
        const element = {
            props,
            scrollRef: scrollRef.current
        };
        registerScrollEvents(element, domain_cor_status === 'success');

        if (domain_cor_status === 'success') {
            const invoice = getIncludedObjBasedOnType(domain_cor_data?.included, 'invoice');

            if (domain_cor_data?.attributes?.pending) {
                if (domain_cor_data?.included) {
                    setCorState('in-progress');
                    setShowConfirmLightbox(false);
                    setShowCancelLightbox(false);
                    setInvoiceID(invoice.id);
                } else {
                    setCorState('in-progress');
                    setShowConfirmLightbox(false);
                    setShowCancelLightbox(false);
                }
            } else {
                setCorState('default');
                setShowConfirmLightbox(false);
                setShowCancelLightbox(false);
                setInvoiceID(null);
            }
        }
    }, [domain_cor_status, domain_cor_data]);

    useEffectAfterMount(() => {
        if (domain_cor_action_data && domain_cor_action_status === 'success') {
            const { included } = domain_cor_action_data;
            const invoice = getIncludedObjBasedOnType(included, 'invoice');

            if (invoice) {
                openPayInvoice(invoice.id);
            }
            const detailsLowerCse = domain_cor_action_data.details.toLowerCase() ?? '';
            if (
                ['invoice', 'cancelled'].every((word) => detailsLowerCse.includes(word)) ||
                ['ticket', 'submitted'].every((word) => detailsLowerCse.includes(word))
            ) {
                setShowConfirmLightbox(false);
                setShowCancelLightbox(false);
                setInvoiceID(null);
            }
        }
    }, [domain_cor_action_status, domain_cor_action_data]);

    useEffect(() => {
        if (domain_cor_action_status === 'error') {
            setCorState('default');
            setShowConfirmLightbox(false);
            setShowCancelLightbox(false);
        }
    }, [domain_cor_action_status]);

    /***** RENDER HELPERS *****/
    const corPrice = getCoRDisplayPrice(domain_get_cor_price_data);

    /*   HANDLE INVOICE STATUS
     **********************************************************************************************************/
    const handleInvoiceStatus = () => {
        if (invoiceID) {
            return {
                type: 'warning',
                text: htmr(
                    `You have an outstanding invoice, make a payment now to begin the change of registrant process. You can also cancel the request by cancelling this invoice.`
                ),
                action: [
                    {
                        label: 'Pay Invoice',
                        buttonType: 'Solid',
                        type: 'onClick',
                        className: '',
                        color: 'white',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            openPayInvoice(invoiceID);
                        }
                    },
                    {
                        label: 'Cancel Invoice',
                        buttonType: 'Solid',
                        type: 'onClick',
                        className: '',
                        color: 'white',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            openCancel(invoiceID);
                        }
                    }
                ]
            };
        }

        return '';
    };

    /*   SET CONDITIONAL PROPS
     **********************************************************************************************************/
    let conditionalProps = {};

    switch (corState) {
        case 'in-progress':
            conditionalProps = {
                bottom: true,
                columns: [
                    {
                        render: (
                            <div className="sharedBox__infoColumn hasIcon">
                                <i className="infoColumn__icon icon icon-save-time-money notice" />
                                <div className="infoColumn__wrapper">
                                    <div className="title">Change of Registrant Status</div>
                                    <div className="desc">In Progress</div>
                                </div>
                            </div>
                        )
                    }
                ]
            };
            break;

        case 'form':
            conditionalProps = {
                custom: {
                    render: (
                        <div className="changeRegistrant__form">
                            <ChangeRegistrantForm
                                domainid={domainId}
                                onSubmit={
                                    domain_cor_data?.attributes?.requires_manual && !app_check_token_data?.is_vpn
                                        ? submitChangeRegistrant
                                        : openConfirm
                                }
                            />
                        </div>
                    ),
                    pos: 'bottom'
                }
            };
            break;

        default:
            conditionalProps = {
                action: {
                    label: 'Initiate Change of Registrant',
                    type: 'onClick',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        setCorState('form');
                    }
                }
            };
            break;
    }

    const getInitialLoadingStatus = () => {
        const statuses = [domain_get_cor_price_status, domain_cor_status];
        if (statuses.includes('loading')) return 'loading';
        if (statuses.includes('error')) return 'error';
        return 'success';
    };

    const cancelCORLoadingState = () => {
        if (domain_cor_action_status === 'loading' || domain_cor_status === 'loading') return 'loading';
        if (domain_cor_action_status === 'error' || domain_cor_status === 'error') return 'error';
        return 'success';
    };

    /***** RENDER *****/
    return (
        <div ref={scrollRef} className="changeRegistrant">
            <Box
                disabled={disabled}
                request={{
                    action: getPendingCor,
                    args: domainId,
                    status: getInitialLoadingStatus()
                }}
                className="changeRegistrant__box"
                title="Change of Registrant"
                desc={
                    <Fragment>
                        A Change of Registrant is the process in which the .AU licence is transferred from the current legal registrant to a new legal
                        entity.
                        <Anchor
                            className="moreInfo"
                            to="/support/support-centre/domain-names/domain-policy/completing-an-au-domain-change-of-registrant-cor"
                        >
                            More Information
                        </Anchor>
                    </Fragment>
                }
                notification={{
                    info: handleInvoiceStatus()
                }}
                {...conditionalProps}
            />
            {showConfirmLightbox && (
                <OverlayLightbox
                    title="Confirm Change of Registrant"
                    onOpen={showConfirmLightbox}
                    loading={domain_cor_action_status}
                    confirm={{
                        desc: htmr(
                            `When you confirm this submission, the Change of Registrant process will begin. You will be charged <strong className="initiateCor__price--final">$${(
                                corPrice * input_values.years
                            ).toFixed(2)} AUD</strong> which is required to be paid prior to the initiation of the process.`
                        ),
                        buttonText: 'Confirm',
                        buttonAction: () => submitChangeRegistrant(),
                        closeText: 'No, Go Back',
                        closeAction: closeConfirm,
                        loading: domain_cor_action_status
                    }}
                    onClose={closeConfirm}
                />
            )}
            {showCancelLightbox && (
                <OverlayLightbox
                    title="Cancel Change of Registrant"
                    onOpen={showCancelLightbox}
                    loading={domain_cor_action_status}
                    confirm={{
                        desc: htmr(`Cancelling this invoice will also cancel the Change of Registrant request. Are you sure you'd like to do this?`),
                        buttonText: 'Cancel Request',
                        buttonAction: submitCancel,
                        closeText: 'No, Go Back',
                        closeAction: closeCancel,
                        loading: cancelCORLoadingState()
                    }}
                    onClose={closeCancel}
                />
            )}
            {showInvoiceLightbox && (
                <OverlayLightbox
                    title={`Pay Invoice #${invoiceID}`}
                    onOpen={showInvoiceLightbox}
                    invoiceid={invoiceID}
                    onClose={closePayInvoice}
                    onSuccessClose={closePayInvoiceSuccess}
                />
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    domain_cor_action_status: state.domain.domain_cor_action_status,
    domain_cor_action_data: state.domain.domain_cor_action_data,
    domain_cor_status: state.domain.domain_cor_status,
    domain_cor_data: state.domain.domain_cor_data,
    domain_get_cor_price_status: state.domain.domain_get_cor_price_status,
    domain_get_cor_price_data: state.domain.domain_get_cor_price_data,
    app_user_data: state.app.app_user_data,
    app_check_token_data: state.app.app_check_token_data
});

const mapDispatchToProps = {};

ChangeRegistrant = connect(mapStateToProps, mapDispatchToProps)(ChangeRegistrant);
ChangeRegistrant = withRouter(ChangeRegistrant);

export default ChangeRegistrant;
