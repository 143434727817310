/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box/index';
import Switch from 'components/Switch';

/*   ACTIONS
 *****************************************************/
import { getServiceStatus, updateServiceStatus } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ServiceStatus extends Component {
    render() {
        const {
            support_service_status_status,
            support_service_status_data,
            support_service_status_update_status,
            getServiceStatus,
            updateServiceStatus
        } = this.props;

        const renderSwitch = (label, value, onClick) => {
            return (
                <div className="serviceStatus__switchWrapper">
                    <div className="serviceStatus__switchLabel">{label}</div>
                    <Switch
                        className="serviceStatus__switch"
                        onClick={onClick}
                        disabled={support_service_status_update_status === 'loading'}
                        switchvalue={value ? '1' : '0'}
                    />
                </div>
            );
        };

        const renderTableSwitches = () => {
            const {
                attributes: { generalNotice, emailServiceNotice, smsServiceNotice }
            } = support_service_status_data;

            return (
                <>
                    <div className="serviceStatus__row">
                        <div className="serviceStatus__rowText">Events that will affect my services</div>
                        <div className="serviceStatus__switchContainer">
                            {renderSwitch('Send me an SMS', smsServiceNotice, () => updateServiceStatus(smsServiceNotice, 'SMS'))}
                            {renderSwitch('Email Me', emailServiceNotice, () => updateServiceStatus(emailServiceNotice, 'Email'))}
                        </div>
                    </div>
                    <div className="serviceStatus__row">
                        <div className="serviceStatus__rowText">General Notices and events</div>
                        <div className="serviceStatus__switchContainer">
                            {renderSwitch('Email Me', generalNotice, () => updateServiceStatus(generalNotice))}
                        </div>
                    </div>
                </>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <section className="serviceStatus">
                <div className="serviceStatus__wrapper">
                    <Box
                        request={{
                            action: getServiceStatus,
                            args: false,
                            status: support_service_status_status
                        }}
                        className="sharedBox serviceStatus__box"
                        title="Status Notices"
                        desc="Choose which service notices you wish to be notified about via SMS or email"
                        custom={{
                            render: <div className="serviceStatus__content">{support_service_status_data && renderTableSwitches()}</div>,
                            pos: 'bottom'
                        }}
                    />
                </div>
            </section>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            support_service_status_status: state.support.support_service_status_status,
            support_service_status_data: state.support.support_service_status_data,
            support_service_status_update_status: state.support.support_service_status_update_status
        }),
        {
            getServiceStatus,
            updateServiceStatus
        }
    )(ServiceStatus)
);
