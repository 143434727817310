/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ReactChildrenProp, TruthyProp } from 'utilities/consts';
import './_AcknowledgementBox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _AcknowledgementBox = (props) => {
    const { title = 'Acknowledgement', children, flush, className, titleNoPadding } = props;

    /***** RENDER HELPERS *****/
    const containerClasses = classNames('acknowledgementBox__container', className, {
        acknowledgementBox__flush: flush,
        acknowledgementBox__titleNoPadding: titleNoPadding
    });

    /***** RENDER *****/
    return (
        <div className={containerClasses}>
            <h5 className="acknowledgementBox__heading">{title}</h5>

            <div className="acknowledgementBox__input">{children}</div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

_AcknowledgementBox.propTypes = {
    /**
     * The title of the box
     */
    title: ReactChildrenProp,

    /**
     * The className of the box
     */
    className: PropTypes.string,

    /**
     * Node list that you want to render.
     */
    children: PropTypes.node,

    /**
     * Whether you want multiple boxes to bleed together.
     * NOTE: Make sure to wrap them in another div or element. as it uses first/last child selectors
     */
    flush: PropTypes.bool,

    /**
     * Whether you want the title to have no padding on the left
     */
    titleNoPadding: TruthyProp
};

export { _AcknowledgementBox };
