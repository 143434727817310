/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

// Carousel
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Carousel.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function NextArrow({ className, style, onClick }) {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-right" />
        </button>
    );
}

function PrevArrow({ className, style, onClick }) {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-left" />
        </button>
    );
}
// TODO: refactor the dashboard carousel and deals to use this rather than their own implementations. Make the slider props able to be passed in as props on this component
function Carousel({ slides }) {
    /***** RENDER *****/
    return (
        <div className="Carousel">
            <Slider
                dots={true}
                customPaging={(i) => <div></div>}
                arrows={true}
                // autoplay
                autoplaySpeed={5000}
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                // responsive={[
                //     {
                //         breakpoint: 768,
                //         settings: {
                //             slidesToShow: 2,
                //             slidesToScroll: 1
                //         }
                //     },
                //     {
                //         breakpoint: 480,
                //         settings: {
                //             slidesToShow: 1,
                //             slidesToScroll: 1
                //         }
                //     }
                // ]}
            >
                {slides}
            </Slider>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Carousel.propTypes = {
    /**
     * Slides to pass to the carousel
     */
    slides: PropTypes.arrayOf(PropTypes.node)
};

export default Carousel;
