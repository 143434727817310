/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as animationData from 'config/animations/appLoader.json';
import { serviceNav } from 'config/config';
import React from 'react';
import Lottie from 'react-lottie-player';
import { useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import KatanaLoaderImage from 'components/Loaders/App/katanaLoaderImage';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_AppLoader.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface AppLoaderProps {
    className?: string;
    message?: string;
    fullHeight?: boolean;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const AppLoader: React.FC<AppLoaderProps> = ({ className, message, fullHeight }) => {
    /***** HOOKS *****/
    const location = useLocation();

    /***** RENDER HELPERS *****/
    const isVIPsitesPage = location.pathname.includes(serviceNav.VIPsites.link);

    /***** RENDER *****/
    return (
        <div className={`appLoader${className ? ` ${className}` : ''}${fullHeight ? ' appLoader--fullHeight' : ''}`}>
            {isVIPsitesPage ? (
                <KatanaLoaderImage />
            ) : (
                <Lottie
                    animationData={animationData}
                    rendererSettings={{
                        preserveAspectRatio: 'xMidYMid slice'
                    }}
                    loop
                    play
                    style={{
                        height: 100,
                        width: 100
                    }}
                />
            )}
            {message ? <div className="loader__text">{message}</div> : ''}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default AppLoader;
