/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useAlternativePricingTable } from '.';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *   className?: string,
 *   title: React.ReactNode,
 *   value: React.ReactNode,
 * }} props
 */
const _Header = ({ className, title, value }) => {
    const { color } = useAlternativePricingTable();
    const classes = classNames('alternativePriceTable__header', `alternativePriceTable__header--${color}`, className);

    return (
        <div className={classes}>
            <Text white={color === 'black'} className="alternativePriceTable__title">
                {title}
            </Text>
            <Text white={color === 'black'} className="alternativePriceTable__value">
                {value}
            </Text>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _Header;
