/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SolidNotification.scss';

class SolidNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        };
    }

    render() {
        const { color, children, className } = this.props;
        const { isOpen } = this.state;

        if (isOpen && children) {
            return (
                <div className={`SolidNotification ${color} ${className ? ' ' + className : ''}`}>
                    <div className="text">{children}</div>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

SolidNotification.propTypes = {
    /**
     * The color of the notification
     */
    color: PropTypes.oneOf(['warning']),

    /**
     * The children to render
     */
    children: PropTypes.node.isRequired,

    /**
     * The className to add to the component
     */
    className: PropTypes.string
};

export default SolidNotification;
