/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import NXPill from 'components/NXPill';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RenderPillMultiSelect.scss';
// import webpageType from 'containers/katana/containers/contentEditorModules/webpageType';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RenderPillMultiSelectProps = WrappedFieldProps & {
    label: React.ReactNode;
    options: string[];
    disabled?: boolean;
    initialLimit?: number;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RenderPillMultiSelect: React.FC<RenderPillMultiSelectProps> = ({ input, label, options, disabled, initialLimit, meta }) => {
    const { value, name, onChange } = input;

    const [displayLimit, setDisplayLimit] = useState<number>(initialLimit);

    /***** RENDER HELPERS *****/
    const selectedValues = Array.isArray(value) ? value : [];
    const filteredOptionValues = selectedValues.filter((selectedValue) => !options.includes(selectedValue));
    const concatenatedOptions = sortBy([...options, ...filteredOptionValues]);
    const finalOptions =
        displayLimit === 0
            ? concatenatedOptions
            : concatenatedOptions.slice(0, displayLimit < concatenatedOptions.length ? displayLimit : concatenatedOptions.length);

    /***** FUNCTIONS *****/
    function toggleOption(option) {
        if (selectedValues.includes(option)) {
            const selectedOptions = [...selectedValues];
            const optionIndex = selectedOptions.indexOf(option);
            selectedOptions.splice(optionIndex, 1);

            if (selectedOptions.length) {
                onChange(selectedOptions);
                return;
            }

            onChange([]);
            return;
        }

        const selectedOptions = [...selectedValues, option];
        onChange(selectedOptions);
    }

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className="RenderPillMultiSelect">
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner meta={meta} no-bg>
                <Flex gap={2} wrap>
                    {finalOptions.map((webpageType) => {
                        const isHighlighted = selectedValues.includes(webpageType);
                        return (
                            <NXPill isHighlighted={isHighlighted} hoverEffect onClick={() => toggleOption(webpageType)} key={webpageType}>
                                {webpageType}
                            </NXPill>
                        );
                    })}
                    {Boolean(displayLimit) && concatenatedOptions.length > displayLimit && (
                        <NXPill primary hoverEffect onClick={() => setDisplayLimit(0)}>
                            Show More
                        </NXPill>
                    )}
                </Flex>
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
