/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SelectDropdownField from 'components/Form/SelectDropdownField';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaChoiceFormFieldSelect: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, options, validationFunctions } = property;

    /***** RENDER HELPERS *****/
    const newOptions = useMemo(() => options.map((option) => ({ label: option.value, value: option.key })), [options]);

    return <Field name={key} label={name} component={SelectDropdownField} options={newOptions} validate={validationFunctions} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaChoiceFormFieldSelect;
