/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RenderPhoneField from 'components/Form/PhoneInput';
import { TextArea } from 'components/Form/TextArea';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { RenderSelectField, requiredFieldValidation, validateReactPhoneNumberInput } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function AdvancedForm({ cert }) {
    /***** RENDER *****/
    return (
        <div className="sslConfigure__form">
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Enter Your CSR"
                        name="csr"
                        component={TextArea.ReduxForm.Default}
                        type="message"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Private Key"
                        name="private_key"
                        component={TextArea.ReduxForm.Default}
                        type="message"
                        placeholder=""
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="Phone"
                        name="phone"
                        component={RenderPhoneField}
                        required
                        type="tel"
                        validate={[requiredFieldValidation, validateReactPhoneNumberInput]}
                        className="form__textfield"
                    />
                </div>
                {cert.type !== 'EV' ? (
                    <div className="form__column half">
                        <div className="form__column sslConfigure__box__description sslConfigure__Tooltip">
                            Why is this important?
                            <Tooltip info="The Certificate Vendor may be required to call you as part of the verification process." />
                        </div>
                    </div>
                ) : (
                    <div className="form__column half">
                        <Field
                            label="Business Category"
                            name="business_category"
                            component={RenderSelectField}
                            type="select"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                            options={[
                                {
                                    label: 'Please select',
                                    value: ''
                                },
                                {
                                    label: 'Private Organisation',
                                    value: 'Private Organisation'
                                },
                                {
                                    label: 'Government Entity',
                                    value: 'Government Entity'
                                },
                                {
                                    label: 'Business Entity',
                                    value: 'Business Entity'
                                },
                                {
                                    label: 'Non-commercial Entity',
                                    value: 'Non-commercial Entity'
                                }
                            ]}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default AdvancedForm;
