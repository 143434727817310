/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StepTitle = ({ children }) => {
    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="migrationRequestForm__stepLabel">
            <span>{children}</span>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
StepTitle.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default StepTitle;
