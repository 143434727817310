/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ticket_name } from 'config/config';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import PasswordGenerator from 'components/Password/Generator';
import PasswordChecker from 'components/PasswordChecker';
import { increasedStrengthPasswordRequirements, validateIncreasedStrengthRequirements } from 'components/PasswordChecker/default';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderRevealPasswordField, requiredFieldValidation, validateConfirmValidation } from 'utilities/methods/form';

const formName = 'ChangePasswordForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ChangePasswordForm = ({ handleSubmit, password_input }) => (
    <>
        <form onSubmit={handleSubmit} className="accountPassword__form">
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Enter Current Password"
                        name="old_password"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                        tooltip={`You are required to enter your existing password for verification purposes. If you do not know the existing password, please submit a new ${ticket_name} for assistance.`}
                        autoComplete="current-password"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="New Password"
                        name="password"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateIncreasedStrengthRequirements]}
                        className="form__textfield"
                        parent={this}
                        autoComplete="new-password"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Confirm New Password"
                        name="password_confirmation"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateConfirmValidation]}
                        className="form__textfield"
                        autoComplete="new-password"
                    />
                </div>
            </div>
            <div className="form__row">
                <ReduxFormButton form={formName} className="form__column full">
                    Save Changes
                </ReduxFormButton>
            </div>
        </form>
        <div className="accountPassword__validation">
            <PasswordGenerator />
            <PasswordChecker input={password_input} requirements={increasedStrengthPasswordRequirements} />
        </div>
    </>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ChangePasswordForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(ChangePasswordForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const password_input = selector(state, 'password');
    return {
        password_input,
        account_update_password_status: state.account.account_update_password_status,
        account_update_password_data: state.account.account_update_password_data,
        account_update_password_error: state.account.account_update_password_error
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

ChangePasswordForm = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);

export default withRouter(ChangePasswordForm);
