/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import AlternativePricingTable from 'components/PricingTable/AlternatePricingTable';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChangePlanLightbox from 'components/Lightboxes/OverlayLightbox/Components/changePlan';
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';

/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { getGSuiteProductPricing, getGSuiteSeatsPricing, getGSuiteService, upgradeGSuiteService } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import banner from 'assets/images/gsuite/google_banner_background.png';
import upgradeimg from 'assets/images/gsuite/google_banner_icon.png';
import { gsuiteBillingTermsFaqLink } from 'containers/gsuite/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteUpgrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPlan: '',
            showUpgradeLightbox: false,
            lightboxTemplate: 'Upgrade',
            selectedUpgradeMeta: [],
            invoiceID: false,
            selectedPlanName: null
        };
        this.toggleLightboxTemplate = this.toggleLightboxTemplate.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
    }

    toggleLightboxTemplate(template, open) {
        const { showUpgradeLightbox } = this.state;

        this.setState({
            showUpgradeLightbox: open ? true : !showUpgradeLightbox,
            lightboxTemplate: template ? template : 'Upgrade'
        });
    }

    closePayInvoiceSuccess() {
        const { gsuiteid } = this.props;
        this.setState(
            {
                invoiceID: null
            },
            () => {
                getGSuiteService(gsuiteid);
            }
        );
    }

    componentDidMount() {
        const { gsuite_service_data } = this.props;

        if (gsuite_service_data) {
            const { included } = gsuite_service_data;
            const invoice = getIncludedObjBasedOnType(included, 'invoice');
            const product = getIncludedObjBasedOnType(included, 'product');

            this.setState({
                currentPlan: product && product.attributes.name ? product.attributes.name : 'Not Available',
                invoiceID: invoice ? invoice.id : false
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { gsuite_seat_pricing_status, gsuite_product_pricing_status, gsuite_upgrade_data, gsuite_upgrade_status, gsuiteid } = this.props;
        const { showUpgradeLightbox } = this.state;
        registerScrollEvents(this, gsuite_product_pricing_status === 'success' && prevProps.gsuite_product_pricing_status === 'loading');

        // show invoice
        if (gsuite_upgrade_status === 'success' && prevProps.gsuite_upgrade_status === 'loading' && gsuite_upgrade_data?.type === 'invoice') {
            // This is Always one item since this is an upgrade/downgrade
            const isUpgradeInvoicePaid = gsuite_upgrade_data?.attributes?.invoice_items[0].status === 'paid';

            if (isUpgradeInvoicePaid) {
                getGSuiteService(gsuiteid);
            } else {
                this.setState({
                    lightboxTemplate: 'Pay',
                    invoiceID: gsuite_upgrade_data.id
                });
            }
            return;
        }

        if (gsuite_seat_pricing_status === 'error' && prevProps.gsuite_seat_pricing_status === 'loading' && showUpgradeLightbox) {
            this.setState({
                lightboxTemplate: 'Plan'
            });
        }

        if (gsuite_upgrade_status === 'error' && prevProps.gsuite_upgrade_status === 'loading' && showUpgradeLightbox) {
            this.setState({
                showUpgradeLightbox: null,
                lightboxTemplate: 'Plan'
            });
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { gsuiteid, gsuite_upgrade_status, gsuite_product_pricing_status, gsuite_service_data } = this.props;
        const { currentPlan, showUpgradeLightbox, lightboxTemplate, invoiceID } = this.state;
        const { toggleLightboxTemplate, closePayInvoiceSuccess } = this;

        /*   RENDER LIGHTBOX
         **********************************************************************************************************/
        const renderLightbox = () => {
            switch (lightboxTemplate) {
                case 'Pay':
                    return (
                        <OverlayLightbox
                            title={'Pay Invoice #' + invoiceID}
                            invoiceid={invoiceID}
                            onClose={() => {
                                getGSuiteService(gsuiteid);
                            }}
                            onSuccessClose={closePayInvoiceSuccess}
                            onOpen
                        />
                    );

                default: {
                    const {
                        gsuite_product_pricing_data,
                        gsuite_product_pricing_status,
                        gsuite_seat_pricing_status,
                        gsuite_seat_pricing_data,
                        getGSuiteSeatsPricing
                    } = this.props;
                    const { included, attributes } = gsuite_service_data;
                    const { selectedPlanName } = this.state;

                    const currentPlan = included[0].attributes.name;
                    const seats = attributes.number_of_seats;

                    const planOptions = gsuite_product_pricing_data?.map((data) => {
                        const { attributes = {} } = data;
                        const { name, description, price } = attributes;

                        return {
                            name,
                            description,
                            price: (_) => price['Monthly'], //GW only allows upgrading plan when monthly
                            details: [],
                            rawData: data
                        };
                    });

                    const onSelectPlan = ({ name, rawData: { id } }) => {
                        const { id: serviceID } = gsuite_service_data;
                        getGSuiteSeatsPricing(serviceID, seats, id);

                        this.setState({
                            selectedPlanName: name
                        });
                    };

                    const reviewData = {
                        price: gsuite_seat_pricing_data?.attributes.new_plan_cost,
                        total: gsuite_seat_pricing_data?.attributes.amount_due,
                        refund: gsuite_seat_pricing_data?.attributes.refunded_amount,
                        name: selectedPlanName
                    };

                    const onConfirm = ({ rawData, cycle }) => {
                        const { upgradeGSuiteService, gsuiteid } = this.props;
                        upgradeGSuiteService(gsuiteid, {
                            product_id: rawData.id,
                            billing_cycle: cycle
                        });
                    };

                    const customCycleText = (cycle, { rawData }) => {
                        if (cycle === 'Annually') {
                            return (
                                <>
                                    <span>/Seat/Month*</span>
                                    <br />
                                    <span>Billed Yearly at ${rawData.attributes.price.Annually}</span>
                                </>
                            );
                        }

                        return <span>/Seat/Month*</span>;
                    };

                    const reviewRowProps = {
                        label: (
                            <Text black bold>
                                Due Date
                            </Text>
                        ),
                        content: (isDowngrade) => (
                            <Text black bold align--center>
                                {`Must be paid now to initiate ${isDowngrade ? 'downgrade' : 'upgrade'}`}
                            </Text>
                        )
                    };

                    const renderReviewPlan = (props) => {
                        return (
                            <ChangePlanLightbox.ReviewPlan {...props}>
                                <ChangePlanLightbox.ReviewPlan.ReviewTable />
                                {Number(props.total) === 0 ? (
                                    <ChangePlanLightbox.ReviewPlan.ReviewTotal />
                                ) : (
                                    <AlternativePricingTable className="gsuiteUpgrade__formReviewPricingTable">
                                        <AlternativePricingTable.Header title="Amount Due" value={'$' + reviewData.total} />
                                        <AlternativePricingTable.Row
                                            label={reviewRowProps.label}
                                            content={reviewRowProps.content(props.isDowngrade)}
                                        />
                                    </AlternativePricingTable>
                                )}
                                <ChangePlanLightbox.ReviewPlan.ReviewConfirmOrCancel />
                            </ChangePlanLightbox.ReviewPlan>
                        );
                    };

                    const renderSelectPlanCustomInformation = (
                        <Text className="gsuiteUpgrade__form--disclaimer" secondary size--s italic align--right>
                            *Max 300 users
                        </Text>
                    );

                    //change "available" to availableBillingCycles when upgrading while on a yearly cost is available on the backend
                    return (
                        <ChangePlanLightbox
                            isOpen={true}
                            upgradeType="Google Workspace"
                            planOptions={planOptions}
                            currentPlan={currentPlan}
                            onClose={toggleLightboxTemplate}
                            onSelectPlan={onSelectPlan}
                            onConfirm={onConfirm}
                            customCycleText={customCycleText}
                            selectPlanCustomInformation={renderSelectPlanCustomInformation}
                            composableReviewPlan={renderReviewPlan}
                            isLoading={gsuite_product_pricing_status === 'loading' || gsuite_seat_pricing_status === 'loading'}
                            reviewData={reviewData}
                        />
                    );
                }
            }
        };

        if (currentPlan === 'Google Workspace Business') {
            return null;
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                className="gsuiteUpgrade"
                ref={(el) => {
                    this.scrollRef = el;
                }}
            >
                <Box
                    request={{
                        action: getGSuiteProductPricing,
                        args: gsuiteid,
                        status: gsuite_product_pricing_status
                    }}
                    className="gsuiteUpgrade__box"
                    status={gsuite_upgrade_status}
                    override={
                        <div className="gsuiteUpgrade__container">
                            {!gsuite_service_data?.attributes?.is_commitment_plan && (
                                <ServiceOverviewBanner slim src={banner}>
                                    <ServiceOverviewBanner.ContentWrapper>
                                        <ServiceOverviewBanner.Heading size--custom={22}>
                                            Need to upgrade your Google Workspace Account?
                                        </ServiceOverviewBanner.Heading>
                                        <ServiceOverviewBanner.Description size--custom={16}>
                                            Upgrade your Google Workspace plan to get more storage space and advanced features.
                                        </ServiceOverviewBanner.Description>
                                    </ServiceOverviewBanner.ContentWrapper>
                                    <ServiceOverviewBanner.Image src={upgradeimg} alt="Google Workspace Upgrade" />
                                </ServiceOverviewBanner>
                            )}
                            <div className="gsuiteUpgrade__wrapper--bottom">
                                <div className="gsuiteUpgrade__information">
                                    <div className="gsuiteUpgrade__title">Current Plan</div>
                                    <div className="gsuiteUpgrade__description">{currentPlan}</div>
                                </div>
                                <div className="gsuiteUpgrade__action">
                                    {/* For now, we are not letting customers upgrade or downgrade if they are on an annual commitment plan */}
                                    {invoiceID || gsuite_service_data?.attributes?.is_commitment_plan ? (
                                        <InactiveButton className="gsuiteUpgrade__button">View Plans</InactiveButton>
                                    ) : (
                                        <OutlineButton
                                            type="onClick"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleLightboxTemplate('Upgrade');
                                            }}
                                            className="gsuiteUpgrade__button"
                                        >
                                            View Plans
                                        </OutlineButton>
                                    )}
                                </div>
                                {gsuite_service_data?.attributes?.is_commitment_plan ? (
                                    <div className="gsuiteUpgrade__commitmentTermCopyText">
                                        This feature is coming soon. Contact our{' '}
                                        <Anchor to="/support/tickets/submit/accounts-billing">Billing Team</Anchor> for further information or head to
                                        our{' '}
                                        <Anchor href={gsuiteBillingTermsFaqLink} target="_blank">
                                            Google Workspace FAQ
                                        </Anchor>
                                        .
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    }
                />
                {showUpgradeLightbox ? renderLightbox() : ''}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_service_data: state.gsuite.gsuite_service_data,
        gsuite_upgrade_status: state.gsuite.gsuite_upgrade_status,
        gsuite_product_pricing_status: state.gsuite.gsuite_product_pricing_status,
        gsuite_seat_pricing_status: state.gsuite.gsuite_seat_pricing_status,
        gsuite_seat_pricing_data: state.gsuite.gsuite_seat_pricing_data,
        gsuite_upgrade_data: state.gsuite.gsuite_upgrade_data,
        gsuite_product_pricing_data: state.gsuite.gsuite_product_pricing_data
    };
};

const mapDispatchToProps = {
    getGSuiteSeatsPricing,
    upgradeGSuiteService
};

GSuiteUpgrade = connect(mapStateToProps, mapDispatchToProps)(GSuiteUpgrade);

GSuiteUpgrade = withRouter(GSuiteUpgrade);

export default React.memo(GSuiteUpgrade);
