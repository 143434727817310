/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';
import { TextArea } from 'components/Form/TextArea';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const textareaField = getFieldComponentAndClassName(FieldTypesEnum.TEXTAREA);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AdditionalInfoForm = () => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="migrationRequestForm__stepWrapper">
            <Field
                component={TextArea.ReduxForm.Default}
                label="If you have more than 1 website to migrate please list the details for those services in this field"
                name="additional_info"
                placeholder=""
                rows={12}
                type={FieldTypesEnum.TEXTAREA}
                className={textareaField.className}
            />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default AdditionalInfoForm;
