/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import RadioButtonCircle from 'components/Form/RadioButtonCircle';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 ***********************************************************************************************************/
/**
 * ChangeBillingCycle - Private Component used within ChangePlan to display the billing cycle options
 */
const ChangeBillingCycle = ({ current, cycles, onSelect }) => {
    /***** STATE *****/
    const [selectedBillingCycle, setSelectedBillingCycle] = useState(current);

    /***** FUNCTIONS *****/
    const isCurrentCycleSelected = selectedBillingCycle === current;
    const setCurrentBillingCycle = () => setSelectedBillingCycle(current);

    /***** EFFECTS *****/
    useEffect(() => {
        onSelect(selectedBillingCycle);
    }, [selectedBillingCycle]);

    /***** RENDER *****/
    if (cycles.length === 1 && cycles.includes(current)) return <div style={{ marginTop: '-50px' }}></div>;

    return (
        <div className="changeBillingCycle">
            <span className="changeBillingCycle__current">
                <button type="button" onClick={setCurrentBillingCycle}>
                    <RadioButtonCircle isActive={isCurrentCycleSelected} />
                </button>
                <Text secondary size--s>
                    <span>Match billing cycle with existing service</span>
                </Text>
            </span>
            <CustomDropdown.BillingCyclePrefab
                isActive={!isCurrentCycleSelected}
                options={cycles.map((option) => ({ label: option, value: option }))}
                setOption={setSelectedBillingCycle}
                selectedNewCycle={selectedBillingCycle}
            />
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ChangeBillingCycle.propTypes = {
    // The current billing cycle
    current: PropTypes.string.isRequired,

    // The available billing cycles
    cycles: PropTypes.arrayOf(PropTypes.string).isRequired,

    // The function to call when a billing cycle is selected
    onSelect: PropTypes.func.isRequired
};

export default ChangeBillingCycle;
