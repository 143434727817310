/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PropInjector } from 'components/Utils/PropInjector';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FlexChild.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode | React.ReactElement;
 *      inject?: any;
 *      grow?: NXUtils.NumericRange<1,2> | true;
 *      align?: "start" | "end" | "center";
 * }>}
 */
const _FlexChild = ({ children, inject, grow, align }) => {
    /***** RENDER HELPERS *****/
    const flexChildClasses = classNames('FlexChild', {
        'FlexChild--inject': inject,
        [`FlexChild--grow-${grow === true ? 1 : grow}`]: grow,
        [`FlexChild--align-${align}`]: align
    });

    /***** RENDER *****/
    return (
        <PropInjector inject={inject} injectableProps={{ className: flexChildClasses }} injectable={children}>
            <div className={flexChildClasses}>{children}</div>
        </PropInjector>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _FlexChild };
