/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import DialogNotification from 'components/Notifications/DialogNotification';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DiskSpaceMessage = (props) => {
    const { serviceValidationData, selectedService, hosting_list_data } = props;

    const [upgradeURL, set_upgradeURL] = useState(false);

    useEffect(() => {
        const foundHosting = hosting_list_data?.find((hosting_data) => hosting_data.id === selectedService);

        if (!foundHosting || foundHosting.attributes.product.service_type.includes('reseller')) {
            set_upgradeURL(false);
        } else if (
            foundHosting.attributes.product.service_type.includes('shared_hosting') &&
            foundHosting.attributes.product.name.includes('Select Hosting')
        ) {
            set_upgradeURL(`/my-services/hosting/account/custom-hosting/${selectedService}`);
        } else {
            set_upgradeURL(`/my-services/hosting/account/upgrade/${selectedService}`);
        }
    }, [hosting_list_data]);

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    if (serviceValidationData?.failed.has('disk_space')) {
        return (
            <DialogNotification type="warning" tail={{ pos: 'top' }} className="migrationRequestForm__DiskSpaceDialogNotification">
                PLEASE NOTE: We have detected that the disk space on your existing plan exceeds the disk space allocation on the new hosting service
                you wish to migrate to. This may prevent the migration request from going through. We suggest upgrading your allocated disk space with
                us
                {upgradeURL ? (
                    <>
                        {' '}
                        <Anchor to={upgradeURL}>here</Anchor>.
                    </>
                ) : (
                    '.'
                )}{' '}
                Alternatively, we suggest clearing some space and then submitting this migration form again.
            </DialogNotification>
        );
    }

    return '';
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    hosting_list_data: state.services.hosting_list_data
});

DiskSpaceMessage = connect(mapStateToProps)(DiskSpaceMessage);

export default DiskSpaceMessage;
