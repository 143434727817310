/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CreditCardForm from '../../../../forms/creditCard';
import CreditCardList from './creditCardList';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { DELETE_PAYMENT_METHOD_DATA, useCreatePaymentMethodDataMutation, useDeletePaymentMethodDataMutation } from 'containers/billing/queries';
import { useGetAvailablePaymentMethodsQuery } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';
import {
    getUserPaymentMethodDataListBoilerPlate,
    useGetUserPaymentMethodDataListQuery
} from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import {
    cloneObject,
    handleDefaultErrorNotification,
    handleDefaultSuccessNotification,
    toLuxonDate,
    toLuxonDateDefaultFormat
} from 'utilities/methods/commonActions';

import { detectCardType } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';
import { useGetDefaultPaymentMethodBoilerPlate } from 'containers/billing/queries/useGetDefaultPaymentMethodQuery';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';

const backupMessage = 'Please note that your credit card on file may be used as a backup payment method for overdue invoices';
const changeDefaultMessage = 'PLEASE NOTE: Adding a Credit Card will change your default payment method on your account to Credit Credit';

export const CREDIT_CARD_ACTIONS = {
    ADD: 'add',
    REMOVE: 'remove'
};

const CREDIT_CARD_ACTIONS_VALUES = Object.values(CREDIT_CARD_ACTIONS);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function DefaultPayment({ creditCardLightboxMode, setCreditCardLightboxMode }) {
    /***** STATE *****/
    const [selectedCreditCardID, setSelectedCreditCardID] = useState(null);

    /***** FUNCTIONS *****/
    function closeLightbox() {
        setSelectedCreditCardID(null);
        setCreditCardLightboxMode(null);
    }

    /***** QUERIES *****/
    function handleOnSuccessOptimisticUpdate(response, variables) {
        handleDefaultSuccessNotification(response);
        const queryKey = getUserPaymentMethodDataListBoilerPlate.createQueryKey();
        const currentCreditCardListData = queryClient.getQueryData(queryKey);
        const hasLoadingRemoval = currentCreditCardListData.data.some(({ isLoadingRemoval }) => isLoadingRemoval);
        if (hasLoadingRemoval && typeof variables === 'number') {
            // Removes the loading removal Optimistically so that it appears the request has already finished.
            queryClient.setQueryData(queryKey, (oldQueryResponse) => {
                if (!oldQueryResponse) return;
                const clonedOldQueryResponse = cloneObject(oldQueryResponse);
                clonedOldQueryResponse.data = clonedOldQueryResponse.data.filter(({ id }) => id !== variables);
                return clonedOldQueryResponse;
            });
        }
    }
    const userPaymentMethodDataOptions = {
        onMutate: async (mutationData) => {
            const queryKey = getUserPaymentMethodDataListBoilerPlate.createQueryKey();
            // Cancel any outgoing re-fetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousCreditCardListData = queryClient.getQueryData(queryKey);

            const mutationDataType = typeof mutationData;
            if (mutationDataType === 'number') {
                // Set loading update for removal
                queryClient.setQueryData(queryKey, (oldQueryResponse) => {
                    if (!oldQueryResponse) return;
                    const clonedOldQueryResponse = cloneObject(oldQueryResponse);
                    clonedOldQueryResponse.data.find(({ id }) => id === mutationData).isLoadingRemoval = true;
                    return clonedOldQueryResponse;
                });
            }

            if (mutationDataType === 'object' && has(mutationData, 'payment_method_id') && has(mutationData, 'data')) {
                // set mock data for update to the new value
                const { card_number, expiry, is_default } = mutationData.data;
                // Create "Optimistic" card
                const optimisticCard = {
                    is_default,
                    card_type: detectCardType(card_number)?.toLowerCase?.(),
                    identifier: card_number.substring(card_number.length - 4, card_number.length),
                    expiry: toLuxonDate(expiry, 'MM/yy').toFormat(toLuxonDateDefaultFormat),
                    is_expired: false,
                    payment_method: {
                        name: PAYMENT_METHODS.CREDIT_CARD
                    }
                };

                queryClient.setQueryData(queryKey, (oldQueryResponse) => {
                    if (!oldQueryResponse) return;
                    const clonedOldQueryResponse = cloneObject(oldQueryResponse);
                    clonedOldQueryResponse.data.push({ attributes: optimisticCard, isMockAdditionLoading: true });
                    return clonedOldQueryResponse;
                });
            }

            // Return a context with the previous and new todo
            return { previousCreditCardListData, mutationData };
        },
        // If the mutation fails, use the context we returned above
        onError: (err, mutationData, context) => {
            handleDefaultErrorNotification(err);
            queryClient.setQueryData(getUserPaymentMethodDataListBoilerPlate.createQueryKey(), context.previousCreditCardListData);
        }
    };

    const { data: paymentMethodID } = useGetAvailablePaymentMethodsQuery({
        select: useGetAvailablePaymentMethodsQuery.selectFilters.getPaymentMethodID(PAYMENT_METHODS.CREDIT_CARD)
    });

    const { data: get_user_payment_method_data_list_data } = useGetUserPaymentMethodDataListQuery({
        select: useGetUserPaymentMethodDataListQuery.selectFilters.getPaymentMethodType(PAYMENT_METHODS.CREDIT_CARD)
    });

    const hasCreditCards = get_user_payment_method_data_list_data?.length > 0;
    const nonOptimisticCards = get_user_payment_method_data_list_data?.filter?.(({ isMockAdditionLoading }) => !isMockAdditionLoading) ?? [];

    const { mutate: mutateCreatePaymentMethodData } = useCreatePaymentMethodDataMutation({
        ...userPaymentMethodDataOptions,
        onSuccess: (response, variables) => {
            getUserPaymentMethodDataListBoilerPlate.invalidate();
            useGetDefaultPaymentMethodBoilerPlate.invalidate();
            handleOnSuccessOptimisticUpdate(response, variables);
            closeLightbox();
        }
    });

    const { mutate: mutateDeleteUserPaymentMethodData } = useDeletePaymentMethodDataMutation({
        ...userPaymentMethodDataOptions,
        onSuccess: handleOnSuccessOptimisticUpdate,
        onSettled: () => {
            // If there is multiple removal mutations ongoing we don't want to refetch the list just yet.
            const isDeleteUserPaymentMethodDataSingleMutating = queryClient.isMutating({ mutationKey: [DELETE_PAYMENT_METHOD_DATA] });
            if (isDeleteUserPaymentMethodDataSingleMutating >= 2) return;
            getUserPaymentMethodDataListBoilerPlate.invalidate();
        }
    });

    /***** FUNCTIONS *****/
    function handleSubmitCreditCardForm(values) {
        const { is_default = true } = values;

        const creditCardName = values[CREDIT_CARD_FIELD_DATA.CARD_HOLDER_NAME.name];
        const creditCardNumber = values[CREDIT_CARD_FIELD_DATA.CARD_NUMBER.name];
        const creditCardMonth = values[CREDIT_CARD_FIELD_DATA.CARD_MONTH.name];
        const creditCardYear = values[CREDIT_CARD_FIELD_DATA.CARD_YEAR.name];
        const cvv = values[CREDIT_CARD_FIELD_DATA.CARD_CVV.name];

        const mutationData = {
            payment_method_id: paymentMethodID,
            is_default: hasCreditCards ? is_default : true,
            data: {
                card_holder: creditCardName,
                card_number: creditCardNumber.replaceAll(' ', ''),
                expiry: `${creditCardMonth}/${creditCardYear}`,
                cvv
            }
        };
        mutateCreatePaymentMethodData(mutationData);
    }

    function handleRemoveCreditCard(paymentMethodDataID) {
        setSelectedCreditCardID(paymentMethodDataID);
        setCreditCardLightboxMode(CREDIT_CARD_ACTIONS.REMOVE);
    }

    function removeSelectedCreditCard() {
        mutateDeleteUserPaymentMethodData(selectedCreditCardID);
        closeLightbox();
    }

    /***** RENDER HELPERS *****/
    const handleLightboxRender = () => {
        switch (creditCardLightboxMode) {
            case CREDIT_CARD_ACTIONS.ADD:
                return (
                    <OverlayLightbox
                        title="Add Credit Card"
                        warningMsg={nonOptimisticCards.length === 0 ? changeDefaultMessage : ''}
                        onOpen
                        onClose={closeLightbox}
                        goBackLink={closeLightbox}
                    >
                        <CreditCardForm onSubmit={handleSubmitCreditCardForm} />
                    </OverlayLightbox>
                );

            case CREDIT_CARD_ACTIONS.REMOVE:
                return (
                    <OverlayLightbox
                        title="Remove Credit Card"
                        warningMsg={backupMessage}
                        onOpen
                        onClose={closeLightbox}
                        confirm={{
                            desc: `If you remove your primary credit card, we will select one of your backup credit cards to become the new primary card if they're available.`,
                            buttonText: 'Remove',
                            buttonAction: removeSelectedCreditCard,
                            closeText: 'No, Keep Credit Card',
                            closeAction: closeLightbox
                        }}
                    />
                );

            default:
                return '';
        }
    };

    /***** RENDER *****/
    return (
        <>
            <CreditCardList handleRemoveCreditCard={handleRemoveCreditCard} />
            {handleLightboxRender()}
        </>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

DefaultPayment.propTypes = {
    /**
     * The lightbox mode
     */
    creditCardLightboxMode: PropTypes.oneOf([null, ...CREDIT_CARD_ACTIONS_VALUES]),

    /**
     * The function to set the credit card lightbox mode
     */
    setCreditCardLightboxMode: PropTypes.func.isRequired
};

export default DefaultPayment;
