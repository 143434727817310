/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Heading } from 'components/Utils/Text/_Heading';
import _TextStyledLoader from 'components/Utils/Text/_StyledLoader';
import { _Text } from 'components/Utils/Text/_Text';
import { _TableContent } from 'components/Utils/Text/_tableContent';

const Text = Object.assign(_Text, {
    Heading: _Heading,
    TableContent: _TableContent,
    StyledLoader: _TextStyledLoader
});

export default Text;
