/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Revealer } from 'components/Revealer';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Accordion.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *
 * @type {React.FC<NAccordion.Props>}
 */
let _Accordion = (props) => {
    const {
        title,
        content,
        info,
        servicesContainer,
        copyContent,
        controlled,
        colour = 'info',
        border,
        defaultActive = false,
        preTitleContent = null,
        afterChevronContent = null,
        className
    } = props;

    /***** STATE *****/
    const [active, setActive] = useState(defaultActive);

    /***** FUNCTIONS *****/
    const accordionToggle = () => {
        setActive((currentState) => !currentState);
    };

    const isActive = controlled ? controlled.active : active;

    /***** RENDER HELPERS *****/
    const accordionClassNames = classNames(
        'accordion__container',
        `accordion__container--colour-${colour}`,
        {
            accordion__open: isActive,
            accordion__border: border
        },
        className
    );

    /***** RENDER *****/
    return (
        <div className={accordionClassNames}>
            <button
                type="button"
                className={`accordion__header${servicesContainer ? ` accordion__header--servicesContainer` : ''}`}
                onClick={() => {
                    if (controlled) {
                        controlled.toggle();
                    } else {
                        accordionToggle();
                    }
                }}
            >
                <Flex items="center">
                    {preTitleContent}
                    {info ? <Tooltip className="accordion__icon" info={info} /> : ''}
                    <Text uppercase bold size--xss lead--1 className="accordion__title">
                        {title}
                    </Text>
                </Flex>
                <Flex items="center">
                    {!!copyContent && (
                        <button
                            className="accordion__copy"
                            onClick={(e) => {
                                e.stopPropagation();
                                store.dispatch(copyToClipboard(copyContent));
                            }}
                        >
                            Copy
                        </button>
                    )}
                    <PhosphorIcons.Chevron state={isActive ? 'up' : 'down'} bold size={12} secondary={!isActive} white={isActive} />
                    {afterChevronContent}
                </Flex>
            </button>

            <div className="accordion__content">
                <Revealer isOpen={isActive}>{content}</Revealer>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapDispatchToProps = {
    copyToClipboard
};

_Accordion = connect(null, mapDispatchToProps)(_Accordion);

export { _Accordion };
