/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { keys, omit } from 'lodash';
import { useMemo } from 'react';

/**
 *
 * @param {ContentEditorLightbox.Context['activeFormValues']} activeFormValues
 * @param {ContentEditorLightbox.Context['activeFormSyncErrors']} activeFormSyncErrors
 */
export function useActiveValidFormValues(activeFormValues, activeFormSyncErrors) {
    return useMemo(() => {
        const erroredKeys = keys(activeFormSyncErrors);
        return omit(activeFormValues, erroredKeys);
    }, [activeFormValues, activeFormSyncErrors]);
}
