/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getCaveat } from 'containers/katana/formFields/methods/getCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { defaultRichTextFeatures } from 'containers/katana/formFields/methods/getRichTextCaveat/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import type { Katana } from 'containers/katana/types';

export function getRichTextCaveat(caveats: Katana.SectionDefinitions.AllCaveats[] = defaultRichTextFeatures): RichTextEditorNamespace.Features[] {
    return getCaveat(CaveatTypes.RICH_TEXT, caveats) ?? ([] as any);
}
