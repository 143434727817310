/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function FormHandlerRoute() {
    /***** HOOKS *****/
    const { subpage, childpage } = useSetupEditorRouteParams();

    const subPageSelection = subpage === katanaSubPageEnums.ADD_SECTION 
        ?  katanaSubPageEnums.PRESET_CONTENT 
        : subpage;

    const subpageRouteData = subPageSelection && katanaContentEditorRoutes[subPageSelection];
    /***** FUNCTIONS *****/
    const FormHandler = useMemo(() => {
        if (childpage && has(subpageRouteData, 'ChildPageFormHandler')) {
            return subpageRouteData?.ChildPageFormHandler;
        }

        return subpageRouteData?.FormHandler;
    }, [subpage, childpage]);
    /***** RENDER *****/
    if (!FormHandler) return <PageNotFound />;
    return <FormHandler key={`${subpage}${childpage}`} subpageRouteData={subpageRouteData} />;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { FormHandlerRoute };
