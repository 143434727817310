/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import type { TMutationOptions } from 'utilities/methods/tanstack/types';

type DownloadEventUnsplashImagesMutationOptions = TMutationOptions<typeof KATANA_API.katana.unsplash.download.trigger.POST>;

function _useMutation(serviceID: number, options: DownloadEventUnsplashImagesMutationOptions) {
    function mutationFn(downloadLinks: string[]) {
        return KATANA_API.katana.unsplash.download.trigger.POST(serviceID, downloadLinks);
    }

    return useMutation({
        mutationFn,
        ...options
    });
}

export const downloadEventUnsplashImages = Object.freeze({
    useMutation: _useMutation
});
