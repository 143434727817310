/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { RenderSelectField, renderCheckboxField, requiredAcceptedValidation, requiredFieldValidation } from 'utilities/methods/form';
import { formatVPS, getOperatingSystemList } from '../action';

class FormatVPSForm extends Component {
    constructor(props) {
        super(props);
        this.getOSOptions = this.getOSOptions.bind(this);
    }

    getOSOptions() {
        const { getOSListData } = this.props;
        let options = [];

        if (getOSListData && getOSListData.length > 0) {
            options = Object.keys(getOSListData).map((item) => {
                const { attributes } = getOSListData[item];
                const { name, template } = attributes;
                return {
                    label: name,
                    value: template
                };
            });
        }

        return options;
    }

    render() {
        const { handleSubmit } = this.props;
        const { getOSOptions } = this;

        return (
            <form className="VPS-Format__form" onSubmit={handleSubmit}>
                <div className="VPS-Format__warning-message">
                    WARNING: Your Virtual Server will be completely cleared of all data when it has been formatted. Please be sure to back up any
                    files you require.
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            label="Select Operating System"
                            name="operating_system"
                            component={RenderSelectField}
                            validate={[requiredFieldValidation]}
                            type="select"
                            className="form__dropdown"
                            options={getOSOptions()}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <SolidButton type="submit">Confirm</SolidButton>
                    </div>
                </div>
            </form>
        );
    }
}

FormatVPSForm = reduxForm({
    form: 'FormatReduxForm'
})(FormatVPSForm);

FormatVPSForm = connect(
    (state) => ({
        vps_solus_information_status: state.vps.vps_solus_information_status,
        vps_solus_information_data: state.vps.vps_solus_information_data
    }),
    null
)(FormatVPSForm);

class ConfirmLightboxForm extends Component {
    render() {
        const { handleSubmit, closeLightbox } = this.props;
        return (
            <div className="VPS-Format-Confirm VPS-Lightbox-Main">
                <div className="heading">Format VPS</div>
                <div className="VPS-Format-Confirm__description description">
                    Formatting your VPS will result in all your data being lost and your VPS being formatted.
                </div>
                <form className="form VPS-Format-Confirm__form" onSubmit={handleSubmit}>
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <Field
                                name="format"
                                label="Formatting your VPS will result in all your data being lost and your VPS being formatted."
                                component={renderCheckboxField}
                                validate={requiredAcceptedValidation}
                                type="checkbox"
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <SolidButton type="submit">Format VPS</SolidButton>
                        </div>
                    </div>
                </form>
                <Anchor
                    className="commonlink"
                    onClick={(e) => {
                        e.preventDefault();
                        closeLightbox();
                    }}
                >
                    Cancel
                </Anchor>
            </div>
        );
    }
}

ConfirmLightboxForm = reduxForm({
    form: 'FormatVPSConfirmForm'
})(ConfirmLightboxForm);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class FormatVPS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formatVPSPopUp: false,
            selectedOption: undefined,
            confirmLightbox: undefined
        };
        this.submitFormatVPSForm = this.submitFormatVPSForm.bind(this);
        this.closeConfirmLightbox = this.closeConfirmLightbox.bind(this);
        this.formatVPS = this.formatVPS.bind(this);
    }

    closeConfirmLightbox() {
        this.setState({
            confirmLightbox: false
        });
    }

    componentDidUpdate(prevProps) {
        const { vps_solus_information_status } = this.props;
        registerScrollEvents(this, vps_solus_information_status === 'success' && prevProps.vps_solus_information_status === 'loading');
    }

    submitFormatVPSForm(selectedOption) {
        this.setState({
            selectedOption,
            confirmLightbox: true
        });
    }

    formatVPS(values) {
        const { selectedOption } = this.state;
        const { vps_information_data, formatVPS } = this.props;
        const { id } = vps_information_data;
        const { operating_system } = selectedOption;

        this.setState({
            formatVPSPopUp: false,
            confirmLightbox: false
        });

        formatVPS(id, operating_system);
    }

    render() {
        const { formatVPSPopUp, confirmLightbox, selectedOption } = this.state;
        const { submitFormatVPSForm, closeConfirmLightbox, formatVPS } = this;
        const { vps_information_data, vps_os_list_status, vps_os_list_data, getOperatingSystemList, history } = this.props;
        const { attributes, id } = vps_information_data;
        const { vps_type } = attributes;

        if (vps_type !== 'fully_managed') {
            let desc =
                'Unfortunately there are no operating system templates available to choose from at this time. If you wish to format your VPS, please submit an eTicket and provide details of the operating system you would like.';
            let button = {
                type: 'onClick',
                onClick: () => history.push('/support/tickets/submit/technical-support'),
                size: 'Big',
                label: 'Submit an eTicket',
                className: 'VPS-Box-Dropdown__button'
            };

            if (vps_os_list_data?.length > 0) {
                desc = 'You can format your Virtual Private Server based off the default image or change the operating system that it is running.';
                button = {
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        this.setState({
                            formatVPSPopUp: !formatVPSPopUp
                        });
                    },
                    size: 'Big',
                    label: 'Format VPS',
                    className: 'VPS-Box-Dropdown__button'
                };
            }

            return (
                <Fragment>
                    <div
                        ref={(el) => {
                            this.scrollRef = el;
                        }}
                        className={`VPS-Format VPS-Box-Dropdown${formatVPSPopUp ? ' VPS-Box-Dropdown--open' : ''}`}
                    >
                        <Box
                            request={{
                                action: getOperatingSystemList,
                                args: id,
                                status: vps_os_list_status
                            }}
                            className="VPS-Format__box VPS-Box-Dropdown__box"
                            title="Format VPS"
                            desc={desc}
                            action={button}
                            status={vps_os_list_status}
                        />
                        {formatVPSPopUp && (
                            <div className="VPS-Box-Dropdown__popup">
                                <div className="VPS-Box-Dropdown__wrapper">
                                    <FormatVPSForm getOSListData={vps_os_list_data} onSubmit={submitFormatVPSForm} />
                                </div>
                            </div>
                        )}
                    </div>
                    {confirmLightbox && (
                        <OverlayLightbox className="VPS-Lightbox VPS-Format-Confirm-Lightbox" onOpen={confirmLightbox} onClose={closeConfirmLightbox}>
                            <ConfirmLightboxForm selectedOption={selectedOption} onSubmit={formatVPS} />
                        </OverlayLightbox>
                    )}
                </Fragment>
            );
        } else {
            return '';
        }
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        vps_information_data: state.vps.vps_information_data,
        vps_os_list_status: state.vps.vps_os_list_status,
        vps_os_list_data: state.vps.vps_os_list_data,
        vps_solus_information_status: state.sidebar.vps_solus_information_status,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getOperatingSystemList,
            formatVPS
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormatVPS));
