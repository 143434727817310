const tooltipInfo = `The Domain Name System Security Extensions (DNSSEC) adds an extra layer of security to your domain name 
by digitally 'signing' data so you can be assured it is valid. These digital signatures are stored in DNS name servers alongside 
common record types like A, AAAA, MX and CNAME records.
<br>
<br>
<b>Helpful Hints</b>
<br>
DNSSEC doesn’t encrypt data but rather attests to the validity of the address of the site you visit.
`;

const description =
    'DNS Security Extensions provides a level of additional security where the web browser can check to make sure the DNS information is correct and was not modified.';

const enableDNSSec =
    'You are about to enable DNSSEC on this DNS zone. This takes immediate effect and all existing DS records on your domain will be removed. Are you sure you want to proceed?';

const disableDNSSec =
    'You are about to disable DNSSEC on this DNS zone. This takes immediate effect and all existing DS records on your domain will be removed. Are you sure you want to proceed?';

const removeDSRecord = 'Please note that deleting this DNSSEC data will affect any services that have it set in their DNS settings.';

export const views = /** @type {const} */ ({
    ADD: 'add',
    DELETE: 'delete',
    ENABLE: 'enable',
    DISABLE: 'disable'
});

export const dnssecContent = /** @type {const} */ ({
    description,
    tooltipInfo,
    confirmation: {
        [views.ENABLE]: enableDNSSec,
        [views.DELETE]: removeDSRecord,
        [views.DISABLE]: disableDNSSec
    }
});


