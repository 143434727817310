/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    auDirectCat1CutOff,
    auDirectApplicationOpen,
    auDirectApplicationClose,
    audaPriorityStatusToolUrl,
    audaPriorityTokenRetrievalToolUrl
} from 'config/config';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import WithAuApplicationForm, { modes } from 'containers/auDirect/modules/withAuApplicationForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
import StatusTag from 'components/StatusTag';
import Subnav from 'components/Subnav';

/*   ACTIONS
 *****************************************************/
import { getContestedDomainsLists } from 'containers/auDirect/action';
import { getDomainsList } from 'containers/services/action';
import { auDirectMethods } from 'containers/auDirect/methods';
import { textLowerCase, createPopup } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { APPLICATION_NEEDS_RENEWING_TEXT, IN_PROGRESS_DOMAIN_INFO } from 'containers/auDirect/consts';
import '../_auDirect.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AuDirectContestedApplications extends Component {
    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { domain_list_data, getDomainsList } = this.props;

        if (!domain_list_data) getDomainsList();
    }

    componentDidUpdate(prevProps) {
        const { audirect_update_application_status, audirect_register_status, domain_form_status, getDomainsList } = this.props;

        // Refetch .au direct data after application has been updated, or single registration submitted
        if (
            (audirect_update_application_status === 'success' && prevProps.audirect_update_application_status === 'loading') ||
            (domain_form_status === 'success' && prevProps.domain_form_status === 'loading') ||
            (audirect_register_status === 'success' && prevProps.audirect_register_status === 'loading')
        ) {
            getDomainsList();
        }
    }

    render() {
        const { domain_list_status, domain_list_data, history, location } = this.props;

        const changeDateWording = (date) => {
            const arr = date.split(' ');
            arr.splice(1, 0, 'of');
            return arr.join(' ');
        };

        const renderSidebar = () => {
            return (
                <div className="auContested__sidebar">
                    <h4 className="auContested__sidebarTitle">Priority Allocation of .au direct domain names for existing .au registrants</h4>
                    <div className="auContested__sidebarSection">
                        <h5 className="auContested__sidebarHeading">Key Dates</h5>
                        <p className="auContested__sidebarBody">
                            <span className="auContested__sidebarBodyPrimary">{auDirectApplicationOpen}</span> - .au direct open to the public for
                            priority application and registration of non-contested .au direct domain names.
                        </p>
                        <p className="auContested__sidebarBody">
                            <span className="auContested__sidebarBodyPrimary">{auDirectApplicationClose}</span> - Priority application period closes
                            for contested names.
                        </p>
                        <p className="auContested__sidebarBody">
                            <span className="auContested__sidebarBodyPrimary">{auDirectCat1CutOff}</span> - The cut off period for Category 1 or 2.
                            Category 1 is <span className="auContested__sidebarBodyUnderlined">BEFORE</span> the date and Category 2 is{' '}
                            <span className="auContested__sidebarBodyUnderlined">AFTER</span> the date.
                        </p>
                    </div>
                    <div className="auContested__sidebarSection">
                        <h5 className="auContested__sidebarHeading">Priority Categories</h5>
                        <p className="auContested__sidebarBody">
                            Your category depends on when you registered your existing .au domain(s). Category 1 has priority over Category 2.
                        </p>
                        <p className="auContested__sidebarBody">
                            <span className="auContested__sidebarBodyPrimary">Category 1</span> - Domain name licences with a creation date{' '}
                            <span className="auContested__sidebarBodyUnderlined">ON OR BEFORE</span> the Priority Status cut-off date of the{' '}
                            {changeDateWording(auDirectCat1CutOff)}.
                        </p>
                        <p className="auContested__sidebarBody">
                            <span className="auContested__sidebarBodyPrimary">Category 2</span> - Domain name licences with a creation date{' '}
                            <span className="auContested__sidebarBodyUnderlined">AFTER</span> the Priority Status cut-off date of the{' '}
                            {changeDateWording(auDirectCat1CutOff)}.
                        </p>
                    </div>
                    <div className="auContested__sidebarSection">
                        <h5 className="auContested__sidebarHeading">Helpful links</h5>
                        <Anchor href="https://ventraip.au/" target="_blank">
                            Learn more about .au direct
                        </Anchor>
                        <Anchor
                            href="https://assets.auda.org.au/a/2021-08/auDA_au_direct_priority_allocation_process.pdf?VersionId=LoP4EHxRc.LVvwWYLL_UaaVjbIM0wjw."
                            target="_blank"
                        >
                            Overview of the .au direct Priority Allocation Process
                        </Anchor>
                        <Anchor href={audaPriorityTokenRetrievalToolUrl} target="_blank">
                            auDA’s Priority Token Retrieval Tool
                        </Anchor>
                        <Anchor href={audaPriorityStatusToolUrl} target="_blank">
                            auDA’S Priority status tool
                        </Anchor>
                        <Anchor
                            href="https://ventraip.com.au/faq/article/withdrawing-and-declining-an-application-for-a-contested-au-direct-domain-name/"
                            target="_blank"
                        >
                            Withdrawing and declining a contention application
                        </Anchor>
                    </div>
                </div>
            );
        };

        const renderApplications = (toggleApplicationLightbox) => {
            const renderApplicationsTable = () => {
                const getApplicationsMatrix = () => {
                    const contended = auDirectMethods.getContendedDomains(domain_list_data);

                    if (contended.length <= 0) return [];

                    return contended.map((domain) => {
                        const getAction = () => {
                            switch (domain.attributes.status) {
                                case 'In Progress':
                                    return [
                                        // <Anchor type={`onClick`} onClick={() => {
                                        //     if (window.open) window.open(audaPriorityStatusToolUrl, "_blank");
                                        //     else window.location.href = audaPriorityStatusToolUrl;
                                        // }} >Check Contention Status</Anchor>
                                        <Anchor
                                            key={2}
                                            href={null}
                                            className="contact__link"
                                            onClick={() => {
                                                createPopup(IN_PROGRESS_DOMAIN_INFO.FAQ_URL);
                                            }}
                                        >
                                            Find out more
                                        </Anchor>
                                    ];
                                case 'Application Expiring':
                                    return {
                                        label: 'Renew Application',
                                        type: 'onClick',
                                        className: 'contact__button',
                                        color: 'primary',
                                        size: 'medium',
                                        button: 'Solid',
                                        onClick: () => {
                                            toggleApplicationLightbox(domain.attributes.domain, domain.id, modes.RENEW_APPLICATION);
                                        }
                                    };
                                case 'Pending':
                                    if (domain.attributes.invalid_rego_details)
                                        return {
                                            label: 'Re-enter Information',
                                            color: 'primary',
                                            type: 'onClick',
                                            onClick: () => toggleApplicationLightbox(domain.attributes.domain, domain.id, modes.INVALID_ELIGIBILITY),
                                            size: 'medium',
                                            button: 'Solid'
                                        };
                                    if (domain.attributes.pending_application)
                                        return {
                                            label: 'Submit Details',
                                            color: 'primary',
                                            type: 'onClick',
                                            onClick: () => toggleApplicationLightbox(domain.attributes.domain, domain.id),
                                            size: 'medium',
                                            button: 'Solid'
                                        };
                                    return [
                                        <div key="1" />
                                        // <Anchor href={null} className={`contact__link`} onClick={() => {
                                        //     history.push('/support/tickets/submit');
                                        // }}>Contact us for assistance</Anchor>
                                    ];
                                case 'Pending Identity Verification':
                                    return [
                                        <div key="1" />
                                        // <Anchor href={null} className={`contact__link`} onClick={() => {
                                        //     history.push('/support/tickets/submit');
                                        // }}>Contact us for assistance</Anchor>
                                    ];
                                case 'Action Required':
                                    return {
                                        label: 'Re-submit Details',
                                        color: 'primary',
                                        type: 'onClick',
                                        onClick: () => toggleApplicationLightbox(domain.attributes.domain, domain.id, modes.INVALID_APPLICATION),
                                        size: 'medium',
                                        button: 'Solid'
                                    };
                                default:
                                    return [
                                        <div key="1" />
                                        // <Anchor href={null} className={`contact__link`} onClick={() => {
                                        //     history.push('/support/tickets/submit');
                                        // }}>Contact us for assistance</Anchor>
                                    ];
                            }
                        };

                        const getTooltipText = () => {
                            if (domain.attributes.status === 'Application Expiring') return APPLICATION_NEEDS_RENEWING_TEXT.SHORT;
                            if (domain.attributes.status === 'In Progress') return IN_PROGRESS_DOMAIN_INFO.NOTE;
                            return domain.attributes.note;
                        };

                        return {
                            'Contested Domain': domain.attributes.domain,
                            'status': (
                                <div className="auContested__applicationsTableStatus">
                                    <StatusTag status={textLowerCase(domain.attributes.status)} limitWidth={true} />
                                    <Tooltip info={getTooltipText()} />
                                </div>
                            ),
                            'actions': getAction()
                        };
                    });
                };

                return (
                    <Table
                        className="auContested__applicationsTable"
                        header={[
                            {
                                title: `Contested Domain`
                            },
                            {
                                title: `Status`,
                                tooltip: `A brief overview of the status of your current applications. It’s important to ensure your applications are in progress. If you are being asked to re-submit details. Please ensure you do so to ensure your application is considered.`
                            },
                            {
                                action: true
                            }
                        ]}
                        // loading={audirect_contested_domains_status}
                        loading={domain_list_status}
                        matrix={getApplicationsMatrix()}
                        error={<div>You have no contested .au domain applications at this time.</div>}
                        stacked={true}
                        embedded={true}
                    />
                );
            };

            return (
                <Box
                    premounted={true}
                    title="Your Contested .au Domain Applications"
                    desc=".au direct domain names are now available. Existing registrants can submit contention applications for the .au equivalent of their domain names. Below you will find a list of your current domain applications and their relevant status."
                    custom={{
                        render: renderApplicationsTable(),
                        pos: 'bottom'
                    }}
                    purchase={{
                        text: 'GO BACK',
                        type: 'internal',
                        link: () => history.goBack()
                    }}
                />
            );
        };

        const renderHowExactMatch = () => {
            const renderBoxContent = () => {
                return (
                    <div className="auContested__exact">
                        <div className="auContested__exactSection">
                            <p className="auContested__exactHeading">
                                If the domain name you applied with is <strong>Category 1</strong> and...
                            </p>
                            <div className="auContested__exactBox">
                                <p className="auContested__exactText">
                                    No other registrants have submitted an application by {auDirectApplicationClose}
                                </p>
                                <p className="auContested__exactResult">You are awarded the direct .au domain.</p>
                            </div>
                            <div className="auContested__exactBox">
                                <p className="auContested__exactText">
                                    Other registrants have submitted an application with a <strong>Category 1</strong> domain by{' '}
                                    {auDirectApplicationClose}
                                </p>
                                <p className="auContested__exactResult">
                                    It is up to you and the other registrants to agree on the allocation, otherwise the name remains unallocated.
                                </p>
                            </div>
                            <div className="auContested__exactBox">
                                <p className="auContested__exactText">
                                    Other registrants have submitted an application with a <strong>Category 2</strong> domain by{' '}
                                    {auDirectApplicationClose}
                                </p>
                                <p className="auContested__exactResult">You are awarded the direct .au domain.</p>
                            </div>
                        </div>
                        <div className="auContested__exactSection">
                            <p className="auContested__exactHeading">
                                If the domain name you applied with is <strong>Category 2</strong> and...
                            </p>
                            <div className="auContested__exactBox">
                                <p className="auContested__exactText">
                                    No other registrants have submitted an application by {auDirectApplicationClose}
                                </p>
                                <p className="auContested__exactResult">You are awarded the direct .au domain.</p>
                            </div>
                            <div className="auContested__exactBox">
                                <p className="auContested__exactText">
                                    Other registrants have submitted an application with a <strong>Category 2</strong> domain by{' '}
                                    {auDirectApplicationClose}
                                </p>
                                <p className="auContested__exactResult">
                                    Whichever domain name was registered earliest will be awarded the direct .au domain.
                                </p>
                            </div>
                            <div className="auContested__exactBox">
                                <p className="auContested__exactText">
                                    No other registrants have submitted an application with a <strong>Category 1</strong> domain by{' '}
                                    {auDirectApplicationClose}
                                </p>
                                <p className="auContested__exactResult">You are awarded the direct .au domain.</p>
                            </div>
                        </div>
                    </div>
                );
            };

            return (
                <Box
                    premounted={true}
                    title="How to get your .au direct exact match"
                    custom={{
                        render: renderBoxContent(),
                        pos: 'bottom'
                    }}
                />
            );
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="ServicePage">
                <Subnav pathname={location.pathname} />
                <WithAuApplicationForm>
                    {(toggleApplicationLightbox) => (
                        <section className="auContested">
                            {renderSidebar()}

                            <div className="auContested__main">
                                {renderApplications(toggleApplicationLightbox)}
                                {renderHowExactMatch()}
                            </div>
                        </section>
                    )}
                </WithAuApplicationForm>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            audirect_update_application_status: state.auDirect.audirect_update_application_status,
            audirect_register_status: state.auDirect.audirect_register_status,
            domain_form_status: state.services.domain_form_status,
            domain_list_status: state.services.domain_list_status,
            domain_list_data: state.services.domain_list_data
        }),
        {
            getContestedDomainsLists,
            getDomainsList
        }
    )(AuDirectContestedApplications)
);
