/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { ValidationMessage } from 'components/Form/ValidationMessage';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Select as Select } from 'components/Form/Select/_Select';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NFormItem } from 'components/Form/FormItem/types';
import type { NSelect } from 'components/Form/Select/types';

type SelectHookFormDefaultProps = {
    label: React.ReactNode;
    name: string;
    options: Array<NSelect.Option>;
    validate?: (value: string) => string | undefined;
    size?: NFormItem.Size;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SelectHookFormDefault: React.FC<SelectHookFormDefaultProps> = ({ label, name, options, validate = null, size }) => {
    /***** HOOKS *****/
    // This wrapper has to use "useController" as opposed to "register", because our Select input does not work as an uncontrolled component via the ref method (it's not actually a select element)
    const { field, fieldState } = useController({ name, rules: { validate } });

    /***** RENDER HELPERS *****/
    const meta = {
        touched: fieldState.isTouched,
        error: fieldState.error?.message
    };

    /***** RENDER *****/
    return (
        <FormItem size={size}>
            <Select label={label} name={name} value={field.value} onChange={field.onChange} options={options} meta={meta} />
            <ValidationMessage.HookForm.Default {...meta} />
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _SelectHookFormDefault };
