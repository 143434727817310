/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ServiceSelectItemNotAllowed from '../serviceSelectItemNotAllowed';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderBulkServiceExpiry } from '../../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_listItem.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DomainSelectListItem = ({ service, className, onClick, disabledServices, isChecked }) => {
    /***** FUNCTIONS *****/
    function listItemOnClick() {
        onClick(service.id);
    }

    const notAllowedMessage = disabledServices?.find(({ condition }) => condition(service));

    /***** RENDER *****/
    return (
        <ServiceSelectItemNotAllowed disabled={!!notAllowedMessage} content={notAllowedMessage?.content ?? null}>
            <CheckBox
                isChecked={isChecked}
                className={classNames('DomainSelectListItem', className)}
                onChange={notAllowedMessage ? null : listItemOnClick}
            >
                <div className="DomainSelectListItem__info">
                    <div className="DomainSelectListItem__title">{service.attributes.domain}</div>
                    <div className={`DomainSelectListItem__subtitle ${service.attributes.status !== 'Active' ? 'warning' : ''}`}>
                        {renderBulkServiceExpiry(service.attributes.expiry_date)}
                    </div>
                </div>
            </CheckBox>
        </ServiceSelectItemNotAllowed>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DomainSelectListItem.propTypes = {
    /**
     * The service object
     */
    service: PropTypes.object.isRequired,

    /**
     * React Classnames
     */
    className: PropTypes.string,

    /**
     * Callback for toggling if domain is selected or not
     */
    onClick: PropTypes.func.isRequired,

    /**
     * Whether the list item is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Whether the checkbox is checked item is disabled.
     */
    checked: PropTypes.bool
};

export default DomainSelectListItem;
