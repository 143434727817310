/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { DragHandle } from 'components/DragHandle';
import SolidDropdown from 'components/Dropdowns/SolidDropdown';
import { Input } from 'components/Form/Input';
import { NXForm } from 'components/Form/NXForm';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { SortableItem } from 'components/SortableItem';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DeleteSectionConfirmationLightbox } from 'containers/katana/components/DeleteSectionConfirmationLightbox';
import { SectionOrganiserChildBaseBar } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild/baseBar';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useDeleteKatanaSectionMutation } from 'containers/katana/queries/useDeleteKatanaSectionMutation';
import { useUpdateKatanaSiteSectionMutation } from 'containers/katana/queries/useUpdateKatanaSiteSectionMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { newSectionOrganiserData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionOrganiserData';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { validationMaximum } from 'utilities/methods/form/validations/validationMaximum';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_sectionOrganiserChild.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SectionOrganiserNamespace } from 'containers/katana/modules/presetContent/components/sectionOrganiser/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SectionOrganiserChildProps = {
    sectionData: SectionOrganiserNamespace.SectionData;
};

const validationMax = (value) => validationMaximum(value, 50);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const SectionOrganiserChild: React.FC<SectionOrganiserChildProps> = (props) => {
    const { sectionData } = props;
    const { title, route, sectionID, customTitle } = sectionData;

    const baseValue = customTitle ?? title;
    /***** STATE *****/
    const [isShowingConfirmDeletion, setIsShowingConfirmDeletion] = useState(false);
    const { value: isUpdatingSectionName, setTrue: showUpdatingSectionName, setFalse: hideUpdatingSectionName } = useBoolean(false);

    /***** HOOKS *****/
    const componentKey = useMemo(() => `SectionOrganiserChild-${sectionID}`, [sectionID]);
    const { id } = useSetupEditorRouteParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { mutate: mutateDeleteKatanaSection } = useDeleteKatanaSectionMutation(id);
    const { mutate: mutateUpdateKatanaSiteSection } = useUpdateKatanaSiteSectionMutation({ serviceID: id });

    /***** FUNCTIONS *****/
    function removeSection() {
        mutateDeleteKatanaSection(sectionID);
    }

    const confirmSectionNameUpdate = useCallback(
        (values) => {
            if (values.name !== baseValue) {
                mutateUpdateKatanaSiteSection({ sectionID, attributes: values });
            }
            hideUpdatingSectionName();
        },
        [hideUpdatingSectionName, mutateUpdateKatanaSiteSection, baseValue, sectionID]
    );

    /***** RENDER HELPERS *****/
    const isCustomTitle = title !== baseValue;

    const isNewSection = newSectionOrganiserData.useIsNew(sectionID);

    /***** RENDER *****/
    return (
        <SortableItem id={sectionID}>
            {({ isDragging }) => (
                <>
                    <Grid
                        columns="auto 1fr"
                        className={classNames('SectionOrganiserChild', {
                            'SectionOrganiserChild--isDragging': isDragging
                        })}
                        gap={2}
                        justify-children--stretch
                        inject
                    >
                        <SectionOrganiserChildBaseBar className={classNames({ 'SectionOrganiserChild--isNew': isNewSection })}>
                            <DragHandle.DNDKit id={sectionID} />
                            <NXForm
                                form={componentKey}
                                onSubmit={confirmSectionNameUpdate}
                                initialValues={{
                                    name: baseValue
                                }}
                                initialiseOnMount
                                enableReinitialize
                                keepDirtyOnReinitialize
                            >
                                {({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Padding y={1}>
                                            <Grid columns={isMobile ? '1fr auto' : '1fr 140px'} justify-children--stretch>
                                                {isUpdatingSectionName ? (
                                                    <Field name="name" type="text" component={Input.ReduxForm.Default} validate={[validationMax]} />
                                                ) : (
                                                    <Link to={route} className="SectionOrganiserChild__titleLink">
                                                        <Text size--m medium lead--xs break-word>
                                                            {isCustomTitle ? (
                                                                <Flex wrap items="center" gap={0}>
                                                                    {customTitle}
                                                                    &nbsp;
                                                                    <Text size--s secondary>
                                                                        ({title})
                                                                    </Text>
                                                                </Flex>
                                                            ) : (
                                                                title
                                                            )}
                                                        </Text>
                                                    </Link>
                                                )}
                                                {isUpdatingSectionName ? (
                                                    <SolidButton color="confirm">
                                                        <Text bold size--xss>
                                                            Update
                                                        </Text>
                                                    </SolidButton>
                                                ) : (
                                                    <SolidDropdown
                                                        to={route}
                                                        title={
                                                            isMobile ? (
                                                                <PhosphorIcons.PencilSimple />
                                                            ) : (
                                                                <Text bold size--xss>
                                                                    Edit
                                                                </Text>
                                                            )
                                                        }
                                                        color="gray"
                                                        size="medium"
                                                        list={React.Children.toArray([
                                                            <OutlineButton color="secondary-alt" type="onClick" onClick={showUpdatingSectionName}>
                                                                Rename
                                                            </OutlineButton>,
                                                            <OutlineButton
                                                                color="warn"
                                                                type="onClick"
                                                                onClick={() => setIsShowingConfirmDeletion(true)}
                                                            >
                                                                Delete section
                                                            </OutlineButton>
                                                        ])}
                                                    />
                                                )}
                                            </Grid>
                                        </Padding>
                                    </form>
                                )}
                            </NXForm>
                        </SectionOrganiserChildBaseBar>
                    </Grid>

                    <DeleteSectionConfirmationLightbox
                        open={isShowingConfirmDeletion}
                        setOpen={setIsShowingConfirmDeletion}
                        onConfirm={removeSection}
                    />
                </>
            )}
        </SortableItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SectionOrganiserChild;
