/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BaseButton from 'components/Buttons/_BaseButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useImageColourContrastBackgroundColour } from 'components/ImageSelectControl/hooks/useImageColourContrastBackgroundColour';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useImageURLorFileStream } from './hooks/useImageURLorFileStream';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ImageSelectControl.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<ImageSelectControl.Props>}
 */
const ImageSelectControl = ({ imageURL, onClickUploadNew, onClickRemove, size, className }) => {
    /***** HOOKS *****/
    const { dataURL, isLoading } = useImageURLorFileStream(imageURL);
    const { value: isImageLoading, setFalse: stopImageLoading, setTrue: startImageLoading } = useBoolean(false);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** EFFECTS *****/
    useEffect(() => {
        if (imageURL) {
            startImageLoading();
        } else {
            stopImageLoading();
        }
    }, [imageURL]);

    /***** RENDER HELPERS *****/
    const {
        requiresContrast,
        classes: { backgroundContrast }
    } = useImageColourContrastBackgroundColour(imageURL);
    const imageSelectControlClassNames = classNames('ImageSelectControl', className, backgroundContrast);

    const buttonVariant = requiresContrast ? 'SolidButton' : 'OutlineButton';

    /***** RENDER *****/
    return (
        <div className={imageSelectControlClassNames}>
            <Flex justify="center" items="center" direction="column" className="ImageSelectControl__image">
                <img src={dataURL} width={size ? size : '100%'} height={size ? size : 'auto'} alt="Hero Banner" onLoad={stopImageLoading} />
                {(isLoading || isImageLoading) && <RequestLoader />}
            </Flex>
            <Flex gap={isMobile ? 2 : 4} direction={isMobile ? 'column' : 'row'}>
                <BaseButton variant={buttonVariant} type="onClick" color="secondary" onClick={onClickUploadNew}>
                    <PhosphorIcons.UploadSimple />
                    Upload another image
                </BaseButton>
                <BaseButton variant={buttonVariant} type="onClick" color="warn" onClick={onClickRemove}>
                    <PhosphorIcons.Trash />
                    Delete Image
                </BaseButton>
            </Flex>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ImageSelectControl;
