/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import store from 'store/store';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AliasForm from '../forms/aliases';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/*   ACTIONS
 *****************************************************/
import { addAlias, getAliasList, getEmailHostingDomainGroupDetails, getMailboxList, removeAlias } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Aliases extends Component {
    constructor(props) {
        super(props);

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.submitAliasRequest = this.submitAliasRequest.bind(this);
        this.removeAliasRequest = this.removeAliasRequest.bind(this);

        this.state = {
            showLightbox: false,
            mode: false,
            alias: undefined,
            aliasMatrix: undefined
        };
    }

    openLightbox(mode, alias) {
        this.setState({
            showLightbox: true,
            mode: mode,
            alias
        });
    }

    closeLightbox() {
        this.setState({
            showLightbox: false,
            mode: false,
            alias: undefined
        });
    }

    submitAliasRequest(values) {
        const { match, addAlias, email_domain_group_details_data } = this.props;
        const { params } = match;
        const { id } = params;
        const { alias_from, alias_to, catchAll } = values;
        const domain = email_domain_group_details_data?.attributes?.domain ?? '';

        const attributes = {
            alias_to,
            alias_from: catchAll ? `*@${domain}` : `${alias_from}@${domain}`
        };
        addAlias(id, attributes);
    }

    removeAliasRequest(attributes) {
        const { match } = this.props;
        const { params } = match;
        const { id } = params;
        const { removeAlias } = this.props;
        removeAlias(id, attributes);
    }

    componentDidMount() {
        const { dispatch } = store;
        const { email_domain_group_details_data, email_domain_group_details_status, match } = this.props;
        const { params } = match;
        const { id } = params;

        if (!email_domain_group_details_data && email_domain_group_details_status !== 'loading') {
            dispatch(getEmailHostingDomainGroupDetails(id));
        }
    }

    componentDidUpdate(prevProps) {
        const { match, getAliasList, email_aliases_list_status, email_aliases_list_data, email_aliases_status, email_aliases_delete_status } =
            this.props;
        const { removeAliasRequest } = this;
        const { params } = match;
        const { id: emailID } = params;
        registerScrollEvents(this, email_aliases_list_status === 'success' && prevProps.email_aliases_list_status === 'loading');

        if (email_aliases_list_status === 'success' && prevProps.email_aliases_list_status === 'loading') {
            const aliasMatrix = Array(email_aliases_list_data.length).fill();
            Object.keys(aliasMatrix).forEach((value) => {
                const { attributes } = email_aliases_list_data[value];
                const { alias_from, alias_to } = attributes;

                const values = {
                    alias_from,
                    alias_to
                };

                aliasMatrix[value] = {
                    catch: alias_from,
                    deliver: alias_to,
                    actions: {
                        label: 'Delete',
                        type: 'onClick',
                        onClick: (e) => {
                            e.preventDefault();
                            removeAliasRequest(values);
                        }
                    }
                };
            });

            this.setState({
                aliasMatrix
            });
        }

        if (email_aliases_status === 'success' && prevProps.email_aliases_status === 'loading') {
            this.setState(
                {
                    showLightbox: false,
                    mode: false,
                    alias: undefined
                },
                () => {
                    getAliasList(emailID);
                }
            );
        }

        if (email_aliases_status === 'error' && prevProps.email_aliases_status === 'loading') {
            this.setState({
                showLightbox: false,
                mode: false,
                alias: undefined
            });
        }

        if (email_aliases_delete_status === 'success' && prevProps.email_aliases_delete_status === 'loading') {
            getAliasList(emailID);
        }
    }

    render() {
        const {
            match,
            getAliasList,
            email_aliases_list_status,
            email_aliases_status,
            email_aliases_delete_status,
            email_domain_group_details_data,
            email_domain_group_details_status
        } = this.props;
        const { showLightbox, mode, alias, aliasMatrix, options } = this.state;
        const { submitAliasRequest, openLightbox, closeLightbox } = this;
        const { params } = match;
        const { id } = params;
        const domain = email_domain_group_details_data?.attributes?.domain ? email_domain_group_details_data.attributes.domain : '';

        const handleTableLoading = () => {
            if ([email_aliases_delete_status, email_aliases_status, email_domain_group_details_status].includes('loading')) {
                return 'loading';
            }

            return 'success';
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {
            action: {
                label: 'Add Alias',
                type: 'onClick',
                className: '',
                color: '',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    openLightbox('add');
                }
            }
        };

        if (aliasMatrix && aliasMatrix.length >= 1) {
            conditionalProps = {
                ...conditionalProps,
                action: false,
                custom: {
                    render: (
                        <div className="emailAliases__wrapper">
                            <Table
                                embedded={true}
                                className="emailAliases__table"
                                header={[
                                    {
                                        title: `Catch Address`,
                                        className: `catchaddress`
                                    },
                                    {
                                        title: `Deliver Address`,
                                        className: `deliveraddress`
                                    },
                                    {
                                        action: true
                                    }
                                ]}
                                loading={handleTableLoading()}
                                error=""
                                matrix={aliasMatrix}
                                stacked={true}
                                footer={{
                                    label: `Add Alias`,
                                    icon: `plus-faq`,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        openLightbox('add');
                                    }
                                }}
                            />
                        </div>
                    ),
                    pos: 'bottom'
                }
            };
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="emailAliases"
            >
                <Box
                    request={{
                        action: getAliasList,
                        args: id,
                        status: email_aliases_list_status
                    }}
                    className="emailAliases__box"
                    title="Manage Aliases"
                    desc="Aliases help you create email addresses that you can forward on to other email addresses."
                    status={email_aliases_list_status}
                    info={`Email Aliases allow you to create email addresses that forward emails onto a mailbox of your choosing.
						You can create specific email addresses or have all email addresses on a domain forward to your chosen mailbox.`}
                    {...conditionalProps}
                />

                {showLightbox && mode === 'add' ? (
                    <OverlayLightbox onOpen={showLightbox && mode === 'add'} title="Add Alias" onClose={closeLightbox} loading={email_aliases_status}>
                        <AliasForm mode={mode} options={options} onSubmit={submitAliasRequest} domain={domain} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}

                {showLightbox && mode === 'edit' ? (
                    <OverlayLightbox
                        onOpen={showLightbox && mode === 'edit'}
                        title="Edit Alias"
                        onClose={closeLightbox}
                        loading={email_aliases_status}
                    >
                        <AliasForm mode={mode} alias={alias} options={options} onSubmit={submitAliasRequest} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        email_domain_group_details_data: state.email.email_domain_group_details_data,
        email_mailbox_list_error: state.email.email_mailbox_list_error,
        email_aliases_list_status: state.email.email_aliases_list_status,
        email_aliases_list_data: state.email.email_aliases_list_data,
        email_aliases_list_error: state.email.email_aliases_list_error,
        email_aliases_status: state.email.email_aliases_status,
        email_aliases_data: state.email.email_aliases_data,
        email_aliases_error: state.email.email_aliases_error,
        email_aliases_delete_status: state.email.email_aliases_delete_status,
        email_aliases_delete_data: state.email.email_aliases_delete_data,
        email_aliases_delete_error: state.email.email_aliases_delete_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getAliasList,
            addAlias,
            removeAlias,
            getMailboxList
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Aliases));
