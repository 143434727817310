/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import MegaMaySpinningWheel from '../spinningWheel';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Modal } from 'components/Lightboxes/Modal';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useMegaMayAvaliableSpinsQuery, useMegaMaySpinWheelMutation } from '../../queries';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { pluralize } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { megaMayPermitNumbers } from '../deals';

import CreditPrize from '../../assets/credit-prize.png';
import GrandPrize from '../../assets/grand-prize.png';
import LogoSlim from '../../assets/logo-slim.svg';
import Strip from '../../assets/strip.svg';
import SunsetMobile from '../../assets/sunset-mobile.svg';
import Sunset from '../../assets/sunset.svg';
import TapeGreen from '../../assets/tape-green.svg';
import TapeWhite from '../../assets/tape-white.svg';
import VoucherPrize from '../../assets/voucher-prize.png';

import './_megamay24SpinModal.scss';
import { application } from 'config/config';

export const prizes = /** @type {const} */ ({
    FIJI: 'fiji_draw_entry',
    CREDIT: 'account_credit',
    VOUCHER: 'travel_voucher'
});

export const wheelStatuses = /** @type {const} */ ({
    IDLE: 'idle',
    SPINNING: 'spinning',
    SPUN: 'spun'
});

export function getRemainingSpins(megaMayAvailableSpinsData) {
    return typeof megaMayAvailableSpinsData?.remaining_spins === 'number' ? megaMayAvailableSpinsData.remaining_spins : 0;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function MegaMaySpinModal({ isSpinModalOpen, closeSpinModal }) {
    /***** HOOKS *****/
    const isTabletOrMobile = useAppViewport(['md', 'sm', 'xs']);

    /***** STATE *****/
    const [status, setStatus] = useState(wheelStatuses.IDLE);

    /***** QUERIES *****/
    const { data: megaMayAvailableSpinsData } = useMegaMayAvaliableSpinsQuery();
    const {
        mutate: megaMaySpinWheel,
        data: megaMaySpinWheelData,
        isPending: isMegaMaySpinWheelPending,
        isError: isMegaMaySpinWheelError,
        reset: resetMegaMaySpinWheel
    } = useMegaMaySpinWheelMutation();

    function requestResult() {
        setStatus(wheelStatuses.IDLE);
        setTimeout(() => {
            megaMaySpinWheel(null, {
                onSuccess: () => {
                    setStatus(wheelStatuses.SPINNING);
                }
            });
        });
    }

    /***** RENDER HELPERS *****/
    const numOfSpinsRemaining = getRemainingSpins(megaMayAvailableSpinsData);

    const prize = megaMaySpinWheelData?.data?.prize || null;

    function getPrizeDescription() {
        if (prize === prizes.FIJI) {
            return 'an entry into our MEGA Prize giveaway';
        }
        if (prize === prizes.CREDIT) {
            return '$50 of Account Credit';
        }
        if (prize === prizes.VOUCHER) {
            return 'a $150 Travel Voucher';
        }
        return '';
    }

    function getPrizeImage() {
        if (prize === prizes.FIJI) {
            return <img src={GrandPrize} alt="Grand prize draw entry" />;
        }
        if (prize === prizes.CREDIT) {
            return <img src={CreditPrize} alt="$50 of Account Credit" />;
        }
        if (prize === prizes.VOUCHER) {
            return <img src={VoucherPrize} alt="$150 Travel Voucher" />;
        }
        return '';
    }

    function getPrizeInstructions() {
        if (prize === prizes.FIJI) {
            return (
                <>
                    Your entry has been recorded, and <em>you&apos;re in the draw to win a trip to Fiji</em>, including two return Business Class
                    airfares, a five-night stay at the Fiji Marriott Resort Momi Bay in an Over Water Bure Villa, hotel transfers and $4,000.00 credit
                    to spend during the stay! The winner will be drawn on <em>June 4, 2024</em>. Make sure to keep an eye on your emails so you
                    don&apos;t miss out on the announcement!
                </>
            );
        }
        if (prize === prizes.CREDIT) {
            return (
                <>
                    The credit will be applied to your account within 3 to 5 business days and will be automatically applied to any new invoices
                    within your account.
                    <br />
                    <br />
                    By participating in our Spin to Win, you&apos;ve automatically entered for a chance to win a trip to Fiji. The winner will be
                    drawn on <em>June 4, 2024</em>. Make sure to keep an eye on your emails so you don&apos;t miss out on the announcement!
                </>
            );
        }
        if (prize === prizes.VOUCHER) {
            return (
                <>
                    The voucher will be emailed to the primary email address listed on your {application} account within 3 to 5 business days.
                    Redeemable in-store only at participating Flight Centre Travel Group locations in Australia.
                    <br />
                    <br />
                    By participating in our Spin to Win, you&apos;ve automatically entered for a chance to win a trip to Fiji. The winner will be
                    drawn on <em>June 4, 2024</em>. Make sure to keep an eye on your emails so you don&apos;t miss out on the announcement!
                </>
            );
        }
        return '';
    }

    /***** RENDER *****/
    return (
        <Modal
            className="mmSpinModal"
            isOpen={isSpinModalOpen}
            onClose={() => {
                closeSpinModal();
                setStatus(wheelStatuses.IDLE);
                resetMegaMaySpinWheel();
            }}
        >
            <div className="mmSpinModal__background">
                <img className="mmSpinModal__sunset" src={isTabletOrMobile ? SunsetMobile : Sunset} alt="Sunset" />
                <img className="mmSpinModal__strip" src={Strip} alt="Decorative section" />
            </div>

            {isMegaMaySpinWheelError ? (
                <div className="mmSpinModal__error">
                    <Text white size--xl bold>
                        Something went wrong with your wheel spin, please contact support
                    </Text>
                </div>
            ) : (
                <div className="mmSpinModal__content">
                    <img className="mmSpinModal__logoSlim" src={LogoSlim} alt="MegaMay Spin to Win" />
                    <div className={classNames('mmSpinModal__instructions', { 'mmSpinModal__instructions--hidden': numOfSpinsRemaining <= 0 })}>
                        <Text white align--center lead--1>
                            <h2>Click the button in the centre to spin the wheel!</h2>
                        </Text>
                    </div>
                    <MegaMaySpinningWheel
                        status={status}
                        setStatus={setStatus}
                        prizeWon={prize}
                        requestResult={requestResult}
                        isMegaMaySpinWheelPending={isMegaMaySpinWheelPending}
                    />
                    {!isMegaMaySpinWheelPending && status !== wheelStatuses.SPINNING ? (
                        <Text white align--center lead--1>
                            <h3>
                                {status === wheelStatuses.SPUN && numOfSpinsRemaining > 0 ? 'Keep spinning! ' : ''}You have{' '}
                                <span>{numOfSpinsRemaining}</span> spin
                                {pluralize(numOfSpinsRemaining)} remaining
                            </h3>
                        </Text>
                    ) : (
                        ''
                    )}
                    {status === wheelStatuses.SPUN ? (
                        <>
                            <div className="mmSpinModal__prize">
                                <img className="mmSpinModal__prizeBg" src={TapeWhite} alt="White background" />
                                <div className="mmSpinModal__congratulations">
                                    <img src={TapeGreen} alt="Green background" />
                                    <Text>Congratulations!</Text>
                                </div>
                                <div className="mmSpinModal__prizeContent">
                                    <Text className="mmSpinModal__prizeTitle" align--center lead--1>
                                        You&apos;ve won yourself {getPrizeDescription()}!
                                    </Text>
                                    {getPrizeImage()}
                                    <Text className="mmSpinModal__prizeDesc" black bold lead--s align--center>
                                        {getPrizeInstructions()}
                                    </Text>
                                </div>
                            </div>
                            <Text white size--s align--center className="mmSpinModal__tandc">
                                Full Terms and Conditions Apply. Australian residents 18+. Starts: 01/05/24. Ends 11:59 PM (AEST) 31/05/2024.{' '}
                                {megaMayPermitNumbers}
                            </Text>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            )}
        </Modal>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
