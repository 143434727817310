/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/domain';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SECOND } from 'utilities/consts';

/**
 * @param {number} domainID
 */
function createQueryKey(domainID) {
    return createBaseQueryKey(/** @type {const} */ (['domain', domainID, 'information']));
}

/**
 * @param {number} domainID
 */
function createQueryOptions(domainID) {
    return queryOptions({
        queryKey: createQueryKey(domainID),
        queryFn: () => API.domain.GET.information(domainID),
        staleTime: SECOND * 30,
        enabled: Boolean(domainID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} domainID
 */
function _useQuery(domainID) {
    return useQuery(createQueryOptions(domainID));
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const getDomainInfo = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions
});
