/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getExpiryInfo } from 'containers/services/modules/domains/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The expiry date as displayed in the domains list
 */
function DomainsListExpiry({ expiryDate }) {
    // TEMP-START
    expiryDate = expiryDate.split('T')[0];
    // TEMP-END

    /***** RENDER HELPERS *****/
    const expiryInfo = getExpiryInfo(expiryDate);
    const isExpiring = expiryInfo.days < 90;

    /***** RENDER *****/
    return (
        <div className="ServiceOverview__expiryDate">
            <HoverTooltip content={expiryInfo.formattedExpiryDate}>
                <Text size--xss warn={isExpiring} bold={isExpiring} secondary={!isExpiring}>
                    <p>
                        {expiryInfo.partOne} {expiryInfo.partTwo}
                    </p>
                </Text>
            </HoverTooltip>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

DomainsListExpiry.propTypes = {
    /**
     * The expiry date of the domain, in the format: "yyyy-MM-dd"
     */
    expiryDate: PropTypes.string
};

export default DomainsListExpiry;
