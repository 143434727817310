import { _Input } from 'components/Form/Input/_Input';
import { _InputDecoupledDefault as InputDecoupledDefault } from 'components/Form/Input/wrappers/decoupled/Default';
import { _InputHookFormDefault as InputHookFormDefault } from 'components/Form/Input/wrappers/hookForm/Default';
import { _InputReduxFormDefault as InputReduxFormDefault } from 'components/Form/Input/wrappers/reduxform/Default';

const Input = Object.assign(_Input, {
    ReduxForm: {
        Default: InputReduxFormDefault
    },
    HookForm: {
        Default: InputHookFormDefault
    },
    Decoupled: {
        Default: InputDecoupledDefault
    }
});

export { Input };
