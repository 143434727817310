/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FormattedDescription from 'components/FormattedDescription';
import RequestLoader from 'components/Loaders/Request';
import NXTable from 'components/NXTable';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SPLIT_INVOICE_PROP_TYPES } from '../consts';
const TABLE_HEADINGS = {
    DESCRIPTION: 'description',
    COST: 'cost'
};
const tableHeadings = [
    [TABLE_HEADINGS.DESCRIPTION, 'Description'],
    [TABLE_HEADINGS.COST, 'Cost']
];
const tableColumns = '1fr 90px NXActions';

const contentComponents = {
    [TABLE_HEADINGS.DESCRIPTION]: ({ attributes: { description } }, i) => (
        <Text size--xss secondary key={`${description}_${i}`}>
            <p>
                <FormattedDescription description={description} />
            </p>
        </Text>
    ),
    [TABLE_HEADINGS.COST]: ({ attributes: { total } }, i) => (
        <Text size--xss secondary key={`${total}_${i}`}>
            ${total} AUD
        </Text>
    )
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function InvoiceSplitOverviewTable({ invoiceID, splitActionButton, filterSplitData }) {
    /***** QUERIES *****/
    const { data: get_invoice_data = { included: [] }, status: get_invoice_status } = useGetInvoiceQuery(invoiceID);

    /***** RENDER HELPERS *****/
    const splitItems = filterSplitData ? get_invoice_data.included.filter(filterSplitData) : get_invoice_data.included;

    /***** RENDER *****/
    return (
        <NXTable columns={tableColumns}>
            <NXTable.Header headings={tableHeadings} />
            <NXTable.Body>
                {get_invoice_status === 'loading' ? (
                    <RequestLoader />
                ) : (
                    splitItems.map((invoice_item, i) => (
                        <NXTable.Row key={i}>
                            {tableHeadings.map(([key], i) => contentComponents[key](invoice_item, i))}
                            <NXTable.Actions>{splitActionButton(invoice_item.id)}</NXTable.Actions>
                        </NXTable.Row>
                    ))
                )}
            </NXTable.Body>
        </NXTable>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

InvoiceSplitOverviewTable.propTypes = {
    ...SPLIT_INVOICE_PROP_TYPES,

    /**
     * Button to render as the action column for the table
     */
    splitActionButton: PropTypes.func.isRequired,

    /**
     * Filter function to render desired invoice_items
     */
    filterSplitData: PropTypes.func
};

export default InvoiceSplitOverviewTable;
