/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainTable from 'containers/services/modules/domains';
import EmailHostingTable from 'containers/services/modules/email';
import GSuiteTable from 'containers/services/modules/gsuite';
import HostingTable from 'containers/services/modules/hosting';
import KatanaServiceListTable from 'containers/services/modules/katanaServicesList/katanaServiceListTable';
import { Microsoft365Table } from 'containers/services/modules/ms365';
import { SSLTable } from 'containers/services/modules/ssl';
import { VPSTable } from 'containers/services/modules/vps';
import { ExpressServiceManagerPurchaseLink } from './purchaseLink';
import { ExpressServiceManagerTabs } from './serviceTabs';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { serviceNav } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ExpressServiceManager = () => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** STATE *****/
    const [view, setView] = useState(/** @type {NXUtils.ValueOf<typeof serviceNav>['key']} */ (serviceNav['Domain Names'].key));

    /***** RENDER HELPERS *****/
    const tables = {
        [serviceNav['cPanel Hosting'].key]: <HostingTable isExpressServiceManager />,
        [serviceNav['Email Hosting'].key]: <EmailHostingTable isExpressServiceManager />,
        [serviceNav['Google Workspace'].key]: <GSuiteTable isExpressServiceManager />,
        [serviceNav['VPS'].key]: <VPSTable isExpressServiceManager />,
        [serviceNav['SSL Certificates'].key]: <SSLTable isExpressServiceManager />,
        [serviceNav['Microsoft 365'].key]: <Microsoft365Table isExpressServiceManager />,
        [serviceNav['VIPsites'].key]: <KatanaServiceListTable isExpressServiceManager />,
        [serviceNav['Domain Names'].key]: <DomainTable isExpressServiceManager />
    };

    const paddingProps = /** @satisfies {Record<string, Omit<PaddingProps, 'children'>>} */ ({
        outer: {
            x: isMobile ? 0 : 6,
            y: isMobile ? 0 : 5
        },
        title: {
            bottom: isMobile ? 0 : 3,
            xy: isMobile ? 5 : 0
        }
    });

    /***** RENDER *****/
    return (
        <div className="dashboardExpressServiceManager">
            <NXBox>
                {/* Header */}
                <Padding {...paddingProps.outer}>
                    <Padding className="dashboardExpressServiceManager__titleRow" {...paddingProps.title}>
                        <NXBox.Title title="Express Service Manager" />
                        <ExpressServiceManagerPurchaseLink view={view} />
                    </Padding>
                    <ExpressServiceManagerTabs view={view} setView={setView} />
                </Padding>

                {/* Render Tables */}
                {tables[view] ?? tables[serviceNav['Domain Names'].key]}
            </NXBox>
        </div>
    );
};
