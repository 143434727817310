/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import StatusTag from 'components/StatusTag';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { textLowerCase } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import("../../types").TVPSOverview.TVPSStatusTag} TVPSStatusTag
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private - For use in the _TableBody component.
 * @type {TVPSStatusTag}
 */
export const OwnRenderStatusTag = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const status = textLowerCase(data.attributes.domain_status);

    const classes = /** @type {const} */ ({
        tag: classNames('VPSTableBody__tag', {
            'VPSTableBody__tag--mobile': isMobile
        }),
        status: classNames('VPSTableBody__statusTitle', {
            'VPSTableBody__statusTitle--mobile': isMobile
        })
    });

    /***** RENDER *****/
    return (
        <>
            {isMobile && (
                <Text className={classes.status} lead--1 secondary bold size--xss align--left uppercase>
                    Status
                </Text>
            )}
            <StatusTag status={status} className={classes.tag} />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
