/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

import FirewallForm from '../forms/firewallForm';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { checkExternalFirewall, checkFirewall, unblockFirewall } from '../state/securityActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Firewall extends Component {
    constructor(props) {
        super(props);
        this.toggleFirewallDropdown = this.toggleFirewallDropdown.bind(this);
        this.openFirewallBlockLightbox = this.openFirewallBlockLightbox.bind(this);
        this.closeFirewallBlockLightbox = this.closeFirewallBlockLightbox.bind(this);
        this.handleFirewallCheck = this.handleFirewallCheck.bind(this);
        this.handleFirewallUnblock = this.handleFirewallUnblock.bind(this);
        this.state = {
            showFirewallDropdown: false,
            showFirewallBlockLightbox: false,
            clientUnblock: false,
            clientCheck: {
                result: false,
                ip: null,
                status: false,
                reason: null
            },
            externalCheck: {
                result: false,
                ip: null,
                status: false,
                reason: null
            }
        };
    }

    openFirewallBlockLightbox() {
        this.setState({
            showFirewallBlockLightbox: true
        });
    }

    closeFirewallBlockLightbox() {
        this.setState({
            showFirewallBlockLightbox: false
        });
    }

    toggleFirewallDropdown() {
        const { showFirewallDropdown, externalCheck } = this.state;

        const resetExternalCheck = {
            result: false,
            ip: null,
            status: false,
            reason: null
        };

        this.setState({
            showFirewallDropdown: !showFirewallDropdown,
            externalCheck: showFirewallDropdown ? { ...resetExternalCheck } : { ...externalCheck }
        });
    }

    handleFirewallCheck(ip) {
        const { serviceid, checkExternalFirewall } = this.props;
        this.setState(
            {
                externalCheck: {
                    result: false,
                    ip: ip,
                    status: 'loading',
                    reason: null
                }
            },
            () => {
                checkExternalFirewall(serviceid, ip);
            }
        );
    }

    handleFirewallUnblock(ip, client) {
        const { serviceid, unblockFirewall } = this.props;
        if (client) {
            this.setState(
                {
                    clientUnblock: client ? client : false,
                    clientCheck: {
                        status: 'loading'
                    },
                    externalCheck: {
                        result: false,
                        ip: ip,
                        status: 'loading',
                        reason: null
                    }
                },
                () => {
                    unblockFirewall(serviceid, ip, true);
                }
            );
        } else {
            const attributes = {
                ip_address: ip
            };
            this.setState(
                {
                    externalCheck: {
                        result: false,
                        ip: ip,
                        status: 'loading',
                        reason: null
                    }
                },
                () => {
                    unblockFirewall(serviceid, attributes);
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        const {
            app_user_data,
            checkFirewall,
            serviceid,
            hosting_firewall_check_status,
            hosting_firewall_check_data,
            hosting_firewall_external_check_status,
            hosting_firewall_external_check_data,
            hosting_firewall_unblock_status
        } = this.props;
        registerScrollEvents(this, hosting_firewall_check_status === 'success' && prevProps.hosting_firewall_check_status === 'loading');

        const { clientUnblock } = this.state;
        const clientIp = app_user_data.ip_address;

        if (hosting_firewall_check_status === 'success' && prevProps.hosting_firewall_check_status === 'loading') {
            const { attributes } = hosting_firewall_check_data;
            const { ip_address, is_blocked, reason } = attributes;

            this.setState({
                clientCheck: {
                    result: is_blocked,
                    ip: ip_address,
                    status: 'success',
                    reason: reason
                }
            });
        }

        if (hosting_firewall_external_check_status === 'success' && prevProps.hosting_firewall_external_check_status === 'loading') {
            const { attributes } = hosting_firewall_external_check_data;
            const { ip_address, is_blocked, reason } = attributes;
            this.setState({
                externalCheck: {
                    result: is_blocked,
                    ip: ip_address,
                    status: 'success',
                    reason: reason
                }
            });
        }

        if (hosting_firewall_external_check_status === 'error' && prevProps.hosting_firewall_external_check_status === 'loading') {
            this.setState({
                externalCheck: {
                    result: false,
                    ip: null,
                    status: 'error',
                    reason: false
                }
            });
        }

        if (hosting_firewall_unblock_status === 'success' && prevProps.hosting_firewall_unblock_status === 'loading') {
            if (clientUnblock) {
                const attributes = {
                    ip_address: clientIp
                };
                this.setState(
                    {
                        clientCheck: {
                            result: false,
                            ip: null,
                            status: 'loading',
                            reason: false
                        },
                        externalCheck: {
                            result: false,
                            ip: null,
                            status: 'success',
                            reason: null
                        }
                    },
                    () => {
                        checkFirewall(serviceid, attributes);
                    }
                );
            } else {
                this.setState({
                    externalCheck: {
                        result: false,
                        ip: null,
                        status: false,
                        reason: null
                    }
                });
            }
        }

        if (hosting_firewall_unblock_status === 'error' && prevProps.hosting_firewall_unblock_status === 'loading') {
            this.setState({
                externalCheck: {
                    result: false,
                    ip: null,
                    status: false,
                    reason: null
                }
            });
        }
    }

    render() {
        const { checkFirewall, app_user_data, serviceid, hosting_firewall_check_status, hosting_firewall_external_check_status } = this.props;
        const { showFirewallDropdown, showFirewallBlockLightbox, clientCheck, externalCheck } = this.state;
        const { toggleFirewallDropdown, openFirewallBlockLightbox, closeFirewallBlockLightbox, handleFirewallCheck, handleFirewallUnblock } = this;

        const reasonArray = [
            {
                title: 'ftpd',
                desc: 'Excessive invalid FTP login attempts'
            },
            {
                title: 'cpanel',
                desc: 'Excessive invalid cPanel login attempts'
            },
            {
                title: 'whm',
                desc: 'Excessive invalid WHM login attempts'
            },
            {
                title: 'pop3d',
                desc: 'Excessive invalid POP3 login attempts'
            },
            {
                title: 'imapd',
                desc: 'Excessive invalid IMAP login attempts'
            },
            {
                title: 'smtpauth',
                desc: 'Excessive invalid SMTP login attempts'
            },
            {
                title: 'htpasswd',
                desc: 'Excessive invalid htaccess login prompt attempts'
            },
            {
                title: 'mod_security',
                desc: 'When you are attempting to view a page via an invalid URL'
            }
        ];

        /*   RENDER DROPDOWN
         **********************************************************************************************************/
        const renderExternalStatus = () => {
            if (!externalCheck.result && externalCheck.ip && (externalCheck.status === 'success' || externalCheck.status === 'error')) {
                return (
                    <div className="results">
                        <div className="results__status">
                            <i className="results__icon icon icon-check confirm"></i>
                            <div className="results__container">
                                <div className="results__desc strong">{externalCheck.ip}</div>
                                <div className="results__title">This IP Address is not blocked</div>
                            </div>
                            <div className="results__action">
                                <InactiveButton>Unblock IP Address</InactiveButton>
                            </div>
                        </div>
                    </div>
                );
            } else if (externalCheck.result && externalCheck.ip && (externalCheck.status === 'success' || externalCheck.status === 'error')) {
                return (
                    <div className="results">
                        <div className="results__status">
                            <i className="results__icon icon icon-x notice"></i>
                            <div className="results__container">
                                <div className="results__desc strong">{externalCheck.ip}</div>
                                <div className="results__title notice">This IP Address is blocked</div>
                            </div>
                            <div className="results__action">
                                <SolidButton
                                    color="confirm"
                                    type="onClick"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleFirewallUnblock(externalCheck.ip, false);
                                    }}
                                >
                                    Unblock IP Address
                                </SolidButton>
                            </div>
                        </div>
                        <div className="results__reason">{externalCheck.reason}</div>
                    </div>
                );
            }
        };

        const renderStatus = () => {
            if (!clientCheck.result && clientCheck.ip) {
                return (
                    <div className="sharedBox__infoColumn hasIcon">
                        <i className="infoColumn__icon icon icon-check confirm"></i>
                        <div className="infoColumn__wrapper">
                            <div className="title strong">Your IP Address is not blocked</div>
                            <div className="desc strong">{clientCheck.ip}</div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="sharedBox__infoColumn hasIcon">
                        <i className="infoColumn__icon icon icon-x warn"></i>
                        <div className="infoColumn__wrapper">
                            <div className="title">Your IP Address is blocked</div>
                            <div className="desc">{clientCheck.ip}</div>
                            {clientCheck.reason ? <div className="reason">{clientCheck.reason}</div> : ''}
                        </div>
                    </div>
                );
            }
        };

        const renderRow = (data) => {
            return data.map((item, index) => {
                return (
                    <div key={index} className="reason__row">
                        <div className="reason__title">{item.title}</div>
                        <div className="reason__desc">{item.desc}</div>
                    </div>
                );
            });
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        let conditionalProps = {
            action: {
                label: 'Check More',
                type: 'onClick',
                className: '',
                color: '',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    toggleFirewallDropdown();
                }
            }
        };

        if (clientCheck.result && !clientCheck.ip) {
            conditionalProps = {
                action: {
                    label: 'Unblock IP Address',
                    type: 'onClick',
                    color: '',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        handleFirewallUnblock(clientCheck.ip, true);
                    }
                }
            };
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="firewall"
            >
                <Box
                    request={{
                        action: checkFirewall,
                        args: [serviceid, app_user_data.ip_address],
                        status: hosting_firewall_check_status
                    }}
                    className="firewall__box"
                    title="Check Firewall"
                    desc={`Check to see if your current or another IP Address is blocked by our servers' firewall.`}
                    bottom={true}
                    status={hosting_firewall_check_status}
                    columns={[
                        {
                            render: renderStatus()
                        }
                    ]}
                    footer={
                        <Anchor
                            className="footer__link"
                            onClick={(e) => {
                                e.preventDefault();
                                openFirewallBlockLightbox();
                            }}
                        >
                            Common Firewall Block Reasons
                        </Anchor>
                    }
                    info={`Firewalls are an important part of your website’s security as they monitor all incoming and outgoing traffic on a network and protect against viruses, DDoS attacks and any other malicious activity that may affect your website's security. 
                    <br><br>
                    If you see the message “Your connection to this server has been blocked by the firewall” when visiting your website, you can unblock your IP Address using this tool.`}
                    dropdown={{
                        title: `Check an IP Address`,
                        render: (
                            <Fragment>
                                <FirewallForm onSubmit={handleFirewallCheck} />
                                {externalCheck.status === 'success' ? <div className="firewallExternal__results">{renderExternalStatus()}</div> : ''}
                            </Fragment>
                        ),
                        condition: showFirewallDropdown,
                        close: toggleFirewallDropdown,
                        status: hosting_firewall_external_check_status
                    }}
                    {...conditionalProps}
                />
                {showFirewallBlockLightbox ? (
                    <OverlayLightbox onOpen={showFirewallBlockLightbox} title="Common Firewall Block Reasons" onClose={closeFirewallBlockLightbox}>
                        <div className="firewallOverlay__reasons--container">
                            <div className="firewallOverlay__reasons">{renderRow(reasonArray)}</div>
                            <div className="reason__footer">
                                Still can&apos;t resolve the issue?{' '}
                                <Anchor href="/support/tickets/submit/technical-support">Contact our Technical Support team</Anchor>
                            </div>
                        </div>
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        app_user_data: state.app.app_user_data,
        sidebarRefs: state.sidebar.sidebarRefs,
        hosting_firewall_check_status: state.hosting.hosting_firewall_check_status,
        hosting_firewall_check_data: state.hosting.hosting_firewall_check_data,
        hosting_firewall_check_error: state.hosting.hosting_firewall_check_error,
        hosting_firewall_external_check_status: state.hosting.hosting_firewall_external_check_status,
        hosting_firewall_external_check_data: state.hosting.hosting_firewall_external_check_data,
        hosting_firewall_external_check_error: state.hosting.hosting_firewall_external_check_error,
        hosting_firewall_unblock_status: state.hosting.hosting_firewall_unblock_status,
        hosting_firewall_unblock_data: state.hosting.hosting_firewall_unblock_data,
        hosting_firewall_unblock_error: state.hosting.hosting_firewall_unblock_error
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            checkFirewall,
            checkExternalFirewall,
            unblockFirewall
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Firewall));
