/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * “DEPRECATED, USE `components/SortableButton` INSTEAD”
 */
function SortableButton(props) {
    const { currentSort, sort, title, tooltip, order, toggleSort, className } = props;

    const sortableButtonClassNames = classNames('sharedTable__head--text', 'sortable', className);

    /***** FUNCTIONS *****/
    function onClick() {
        toggleSort(sort);
    }

    function getCurrentSort() {
        if (!currentSort || currentSort !== sort) return '';

        switch (order) {
            case 'asc':
                return <i className="icon icon-chevron-up sortedIcon" />;
            case 'desc':
                return <i className="icon icon-chevron-down sortedIcon" />;
            default:
                return '';
        }
    }

    /***** RENDER *****/
    return (
        <button key={title} onClick={onClick} className={sortableButtonClassNames}>
            {title}
            {tooltip ? <Tooltip info={tooltip} /> : ''}
            {getCurrentSort()}
        </button>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
SortableButton.propTypes = {
    /**
     * The currently sorted key, for example:
     * Available sorts: domain, date, ip_address.
     * currently selected is ip_address
     *
     * helps determine if an icon needs to show
     */
    currentSort: PropTypes.string.isRequired,

    /**
     * The sort that is assigned to this button. i.e. date,
     * If the currentSort is also date, it will display the relevant arrows
     */
    sort: PropTypes.string.isRequired,

    /**
     * The text for the button.
     */
    title: PropTypes.string.isRequired,

    /**
     * The text for in the tooltip. not required.
     */
    tooltip: PropTypes.string,

    /**
     * What direction sort to show, or not at all
     */
    order: PropTypes.oneOf(['asc', 'desc', null]).isRequired,

    /**
     * Function to be called when a sort is pressed
     */
    toggleSort: PropTypes.func.isRequired,

    /**
     * Class Name...
     */
    className: PropTypes.string
};

export default SortableButton;
