/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { gaPurchaseEvent } from 'router/google';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PurchaseForm from '../../forms/purchaseForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { addBestPromo, createCart, createOrder } from '../../state/baseActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_purchase.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Purchase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPurchaseDropdownOpen: false
        };

        this.togglePurchaseForm = this.togglePurchaseForm.bind(this);
        this.handlePurchase = this.handlePurchase.bind(this);
    }

    togglePurchaseForm() {
        const { isPurchaseDropdownOpen } = this.state;

        this.setState({
            isPurchaseDropdownOpen: !isPurchaseDropdownOpen
        });
    }

    handlePurchase(values) {
        const {
            hosting_information_data: { included },
            hosting_resource_config_data,
            hosting_resource_data,
            hosting_billingcycle_data,
            createCart,
            addBestPromo,
            createOrder,
            history
        } = this.props;
        const { togglePurchaseForm } = this;

        const product = getIncludedObjBasedOnType(included, 'product');
        const configs = hosting_resource_config_data.attributes;
        const options = Object.values(hosting_resource_data.attributes.config_values).map(({ option_id }) => option_id);

        const configOptions = Object.keys(configs).map((config) => {
            return Object.assign(
                ...configs[config]
                    .filter(({ option_id }) => options.includes(option_id))
                    .map(({ config_id, option_id }) => {
                        return {
                            config_id,
                            option_id
                        };
                    })
            );
        });

        const billingCycleId = hosting_billingcycle_data?.attributes?.billing_cycles?.find((cycle) => cycle?.name?.name === values.billing_cycle)
            ?.name?.id;

        const items = [
            {
                id: product.id,
                billing_cycle_id: billingCycleId,
                name: values.domain,
                addons: [],
                config: configOptions
            }
        ];

        togglePurchaseForm();

        createCart(items, {
            onSuccess: (createCartData) => {
                addBestPromo(createCartData.token, {
                    onSuccess: () => {
                        createOrder(createCartData.token, {
                            onSuccess: (createOrderData) => {
                                gaPurchaseEvent({
                                    invoiceId: createOrderData?.invoice_id,
                                    item_brand: 'Select Hosting',
                                    item_category: 'shared_hosting'
                                });

                                pushNotification({ details: 'Order successfully completed.', status: 200 });

                                history.push(`/billing/invoices/${createOrderData?.invoice_id}/pay`);
                            }
                        });
                    }
                });
            }
        });
    }

    render() {
        const {
            hosting_resource_status,
            hosting_resource_config_status,
            hosting_create_cart_status,
            hosting_add_best_promo_status,
            hosting_create_order_status,
            hosting_billingcycle_status
        } = this.props;
        const { isPurchaseDropdownOpen } = this.state;
        const { togglePurchaseForm, handlePurchase } = this;

        const handleBoxInitialStatus = () => {
            const status = [hosting_resource_status, hosting_resource_config_status, hosting_billingcycle_status];
            if (status.includes('loading') || status.includes(null)) {
                return 'loading';
            }

            if (status.includes('error')) {
                return 'error';
            }

            return 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <ScrollableComponent ready={handleBoxInitialStatus() === 'success'}>
                <NXBox
                    initialStatus={handleBoxInitialStatus()}
                    isLoading={[hosting_create_cart_status, hosting_add_best_promo_status, hosting_create_order_status].includes('loading')}
                >
                    <NXBox.Top
                        title="Purchase this hosting service again"
                        description="Using this tool, you can create a new hosting service which will have the same resources and options as current hosting service."
                    >
                        <OutlineButton type="onClick" onClick={togglePurchaseForm} className="purchaseAgain__purchaseAnother">
                            Purchase Another
                        </OutlineButton>
                    </NXBox.Top>
                    <NXBox.Dropdown isOpen={isPurchaseDropdownOpen}>
                        <NXBox.DefaultPadding>
                            <NXBox.Dropdown.CloseButton onClose={togglePurchaseForm} />
                            <PurchaseForm handlePurchase={handlePurchase} />
                        </NXBox.DefaultPadding>
                    </NXBox.Dropdown>
                </NXBox>
            </ScrollableComponent>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            hosting_information_data: state.hosting.hosting_information_data,
            hosting_resource_status: state.hosting.hosting_resource_status,
            hosting_resource_data: state.hosting.hosting_resource_data,
            hosting_resource_config_status: state.hosting.hosting_resource_config_status,
            hosting_resource_config_data: state.hosting.hosting_resource_config_data,
            hosting_create_cart_status: state.hosting.hosting_create_cart_status,
            hosting_add_best_promo_status: state.hosting.hosting_add_best_promo_status,
            hosting_create_order_status: state.hosting.hosting_create_order_status,
            hosting_billingcycle_status: state.hosting.hosting_billingcycle_status,
            hosting_billingcycle_data: state.hosting.hosting_billingcycle_data
        }),
        {
            createCart,
            addBestPromo,
            createOrder
        }
    )(Purchase)
);
