/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ConditionalRender } from 'components/Utils/ConditionalRender';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useReviewPlan } from '.';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * ReviewConfirmOrCancel - Private Component exported as part of the ComposableReviewPlan Component
 */
const _ReviewConfirmOrCancel = () => {
    /***** HOOKS *****/
    const { customConfirm, onClick, onCancel, isDowngrade } = useReviewPlan();

    /***** RENDER HELPERS *****/
    const grade = isDowngrade ? 'Downgrade' : 'Upgrade';

    /***** RENDER *****/
    return (
        <>
            <ConditionalRender
                condition={!!customConfirm}
                onTrue={customConfirm?.({ onClick })}
                onFalse={
                    <SolidButton type="onClick" onClick={onClick}>
                        Confirm {grade}
                    </SolidButton>
                }
            />
            <Text uppercase>
                <Anchor className="reviewPlan__cancel" color="primary" onClick={onCancel}>
                    Cancel {grade}
                </Anchor>
            </Text>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _ReviewConfirmOrCancel;
