/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { renderCheckboxField, RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { migrationDateOptions } from '../methods';

const migrationTimeOptions = () => [
    {
        label: 'Early Morning',
        value: 'Early Morning (00:00 - 09:00)'
    },
    {
        label: 'Morning',
        value: 'Morning (09:00 - 12:00)'
    },
    {
        label: 'Afternoon',
        value: 'Afternoon (12:00 - 18:00)'
    },
    {
        label: 'Evening',
        value: 'Evening (18:00 - 00:00)'
    }
];

const selector = formValueSelector('UpgradeVPSConfirmForm');

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************
 * Used when reviewing a VPS Upgrade to select the time of the Upgrade along with agreeing to the upgrade terms
 */
class UpgradeConfirmForm extends Component {
    render() {
        const { handleSubmit, asap } = this.props;

        return (
            <form className="VPS-UpgradeConfirmForm form" onSubmit={handleSubmit}>
                <div className="form__row VPS-UpgradeConfirmForm__checkbox">
                    <div className="form__column form__column--full">
                        <Field name="asap" label="ASAP" component={renderCheckboxField} type="checkbox" className="form__textfield" />
                    </div>
                </div>
                {!asap && (
                    <div className="form__row VPS-UpgradeConfirmForm__picktime">
                        <div className="form__column form__column--half">
                            <Field
                                label="Date"
                                name="date"
                                component={RenderSelectField}
                                type="select"
                                validate={[requiredFieldValidation]}
                                className="form__dropdown"
                                options={migrationDateOptions()}
                            />
                        </div>
                        <div className="form__column form__column--half">
                            <Field
                                label="Time"
                                name="time"
                                component={RenderSelectField}
                                type="select"
                                validate={[requiredFieldValidation]}
                                className="form__dropdown"
                                options={migrationTimeOptions()}
                            />
                        </div>
                    </div>
                )}

                {asap && (
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <div className="VPS-UpgradeConfirmForm__asap-note">
                                <div className="VPS-UpgradeConfirmForm__asap-note-wrapper">
                                    Please be advised, by selecting ASAP, you agree to allowing us to perform a reboot on your service throughout any
                                    given time of the day
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="form__row VPS-UpgradeConfirmForm__checkbox">
                    <div className="form__column">
                        <Field
                            name="data_loss"
                            label="I understand that data loss may occur during the upgrade process and that it's recommended that I take a backup of all data before the upgrade time."
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row VPS-UpgradeConfirmForm__checkbox">
                    <div className="form__column">
                        <Field
                            name="downtime"
                            label="I understand there will be a period of downtime during the upgrade."
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row VPS-UpgradeConfirmForm__checkbox">
                    <div className="form__column">
                        <Field
                            name="irreversible"
                            label="I understand upgrades cannot be reversed as reducing disk space can result in data corruption."
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row VPS-UpgradeConfirmForm__button">
                    <div className="form__column form__column--full">
                        <SolidButton className="button" type="submit">
                            Confirm Upgrade
                        </SolidButton>
                    </div>
                </div>
            </form>
        );
    }
}

UpgradeConfirmForm = reduxForm({
    form: 'UpgradeVPSConfirmForm',
    enableReinitialize: false
})(UpgradeConfirmForm);

UpgradeConfirmForm = connect(
    (state) => ({
        asap: selector(state, 'asap')
    }),
    null
)(UpgradeConfirmForm);

export default UpgradeConfirmForm;
