/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RadioButtonsWithSelectedRenderContext } from 'components/Form/RadioButtonsWithSelectedRender/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Radio Buttons rendered using RadioButtonsWithSelectedRender.Item, the main RadioButtonsWithSelectedRender handles some styling and context management
 *
 * The RadioButtonsWithSelectedRender.Item handles the actual rendering of the radio buttons and the selected item
 *
 * @type {React.FC<TRadioButtonsWithSelectedRender.Props>}
 */
const _RadioButtonsWithSelectedRender = ({ value, onChange, children }) => {
    /***** RENDER HELPERS *****/
    const context = {
        _value: value,
        _onChange: onChange
    };

    /***** RENDER *****/
    return (
        <Flex className="RadioButtonsWithSelectedRender" direction="column">
            <RadioButtonsWithSelectedRenderContext.Provider value={context}>{children}</RadioButtonsWithSelectedRenderContext.Provider>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _RadioButtonsWithSelectedRender };
