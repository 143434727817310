/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Search from 'components/Search';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { cancelSearchResolvedTickets } from 'utilities/api/support';
import { truncateSimple } from 'utilities/methods/commonActions';
import { getOpenTickets, getResolvedSurvey, resetSearchResolvedTickets, searchResolvedTickets } from '../../action';
import { processTicketLists } from '../../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { ProvideFeedbackBanner } from './provideFeedbackBanner';

import noitemimg from 'assets/images/support/eticket_banner_icon.svg';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TicketHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resolvedTicketKeyword: ''
        };

        this.monitorResolvedTicketKeyword = this.monitorResolvedTicketKeyword.bind(this);
        this.getOpenTicketMatrix = this.getOpenTicketMatrix.bind(this);
        this.getResolvedTicketMatrix = this.getResolvedTicketMatrix.bind(this);
    }

    monitorResolvedTicketKeyword(keyword) {
        this.setState({
            resolvedTicketKeyword: keyword
        });
    }

    getOpenTicketMatrix(data) {
        const { history, toggleLightboxTemplate } = this.props;

        if (data?.length > 0) {
            return data.map((item) => {
                const {
                    attributes: { department, lastMessage, mask, staffLastResponse, subject, updated_date, worker }
                } = item;

                return {
                    rowClass: staffLastResponse ? 'unread' : '',
                    info: (
                        <div className="openInfo">
                            <div className="openInfo__left">
                                <Anchor className="openInfo__subject" onClick={() => history.push(`/support/tickets/view/${mask}`)}>
                                    {subject ? truncateSimple(subject, 100, '...') : 'No Subject'}
                                </Anchor>
                                <div className="openInfo__leftBottom">
                                    <div className="openInfo__department">{department}</div>
                                    <div className="openInfo__circle"></div>
                                    <div className="openInfo__mask">{mask}</div>
                                </div>
                            </div>
                            <div className="openInfo__right">
                                <div className={`openInfo__whoSaid${staffLastResponse ? ' openInfo__whoSaid--staff' : ''}`}>
                                    {staffLastResponse ? `${worker} replied` : 'You said'}
                                </div>
                                <div className="openInfo__message">
                                    <span className="openInfo__time">{updated_date}:</span>
                                    {lastMessage ? truncateSimple(lastMessage, 50, '...').replace(/<\/?[^>]+(>|$)/g, '') : 'No Messages'}
                                </div>
                            </div>
                        </div>
                    ),
                    actions: [
                        {
                            label: 'View',
                            type: 'onClick',
                            size: 'small',
                            color: 'primary',
                            className: '',
                            onClick: (e) => {
                                e.preventDefault();
                                history.push(`/support/tickets/view/${mask}`);
                            },
                            list: [
                                {
                                    label: 'Reply',
                                    className: '',
                                    type: 'onClick',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        history.push(`/support/tickets/view/${mask}?reply=true`);
                                    }
                                },
                                {
                                    label: 'Close eTicket',
                                    className: '',
                                    type: 'onClick',
                                    onClick: () => toggleLightboxTemplate('Resolve', { id: mask })
                                }
                            ]
                        }
                    ]
                };
            });
        }
        return '';
    }

    getResolvedTicketMatrix(data) {
        const { history, toggleLightboxTemplate } = this.props;

        if (data?.length > 0) {
            return data.map((item) => {
                const {
                    attributes: { department, feedback_status, mask, subject, updated_date }
                } = item;

                const luxonTime = DateTime.fromSeconds(updated_date);

                const actions = [
                    {
                        label: 'View',
                        type: 'onClick',
                        size: 'small',
                        color: 'primary',
                        className: '',
                        onClick: (e) => {
                            e.preventDefault();
                            history.push(`/support/tickets/view/${mask}`);
                        }
                    }
                ];

                if (feedback_status === 'incomplete') {
                    actions.unshift({
                        label: 'Provide Feedback',
                        type: 'onClick',
                        button: 'Solid',
                        size: 'small',
                        color: 'primary',
                        className: '',
                        onClick: (e) => {
                            e.preventDefault();
                            toggleLightboxTemplate('Survey', { id: mask });
                        }
                    });
                }

                const row = {
                    subject: (
                        <Anchor className="resolvedInfoSubject" onClick={() => history.push(`/support/tickets/view/${mask}`)}>
                            {subject ? truncateSimple(subject, 100, '...') : 'No Subject'}
                        </Anchor>
                    ),
                    info: (
                        <div className="resolvedInfo">
                            <div className="resolvedInfo__department">{department}</div>
                            <div className="resolvedInfo__mask">{mask}</div>
                            <div className="resolvedInfo__lastUpdated">
                                {`Last Updated ${luxonTime.toFormat('d LLL yyyy')} at ${luxonTime.toFormat('t')}`}
                            </div>
                        </div>
                    )
                };

                const completed = (
                    <div className="resolvedInfoFeedback__completed">
                        <i className="icon icon-valid" />
                        <div className="resolvedInfoFeedback__completedText">Feedback Completed</div>
                    </div>
                );

                if (feedback_status === 'completed') row.completed = completed;
                row.actions = actions;

                return row;
            });
        }
        return '';
    }

    render() {
        const { monitorResolvedTicketKeyword, getOpenTicketMatrix, getResolvedTicketMatrix } = this;
        const { resolvedTicketKeyword } = this.state;
        const {
            history,
            getOpenTickets,
            getResolvedSurvey,
            searchResolvedTickets,
            resetSearchResolvedTickets,
            toggleLightboxTemplate,
            support_tickets_open_status,
            support_tickets_open_data,
            support_tickets_open_error,
            support_resolved_survey_status,
            support_resolved_survey_data,
            support_resolved_survey_error,
            support_resolved_survey_meta,
            support_resolved_survey_load_more_status,
            support_resolved_ticket_search_status,
            support_resolved_ticket_search_data
        } = this.props;

        const resolvedTicketList = processTicketLists(support_resolved_survey_data);
        const openTicketList = processTicketLists(support_tickets_open_data);

        const renderBanner = () => {
            if (resolvedTicketList.length <= 0) return '';

            let someTicketsRequireFeedback = false;
            resolvedTicketList.forEach((item) => {
                if (item.attributes.feedback_status === 'incomplete') someTicketsRequireFeedback = true;
            });

            const surveyButtonClick = () => {
                toggleLightboxTemplate('Survey', { id: resolvedTicketList[0].attributes.mask });
            };

            return someTicketsRequireFeedback ? <ProvideFeedbackBanner handleSurveyOnClick={surveyButtonClick} /> : '';
        };

        const renderOpenTicketsList = () => {
            return openTicketList.length > 0 ? (
                <Table
                    embedded={true}
                    layered={true}
                    header={false}
                    loading="success"
                    error="You currently have no open eTickets"
                    matrix={getOpenTicketMatrix(openTicketList)}
                />
            ) : (
                <div className="EmptyList">
                    <div className="EmptyList__top">
                        <img src={noitemimg} alt="No Items" className="EmptyList__image" />
                        <div className="EmptyList__moreinfo">You have no current eTickets</div>
                        <SolidButton
                            color="primary"
                            className="EmptyList__button"
                            type="onClick"
                            onClick={() => history.push('/support/tickets/submit')}
                        >
                            Submit a New eTicket
                        </SolidButton>
                    </div>
                </div>
            );
        };

        const renderResolvedTicketsList = () => {
            const renderPagination = () => {
                const showMoreButton = {
                    label: 'Show More',
                    className: 'resolvedTicketsLoadMore',
                    status: support_resolved_survey_load_more_status,
                    conditions: true,
                    onClick: () => getResolvedSurvey(support_resolved_survey_meta.current_page + 1)
                };

                return support_resolved_survey_meta.current_page === support_resolved_survey_meta.total_pages ? false : showMoreButton;
            };

            return (
                <>
                    <Search
                        slim={true}
                        render={{
                            status: support_resolved_ticket_search_status,
                            placeholder: `Search for eTicket`
                        }}
                        functions={{
                            cancel: cancelSearchResolvedTickets,
                            search: searchResolvedTickets,
                            reset: resetSearchResolvedTickets
                        }}
                        helpers={{
                            keyword: monitorResolvedTicketKeyword
                        }}
                    />

                    {resolvedTicketList.length > 0 ? (
                        <Table
                            embedded={true}
                            layered={true}
                            header={false}
                            loading={support_resolved_ticket_search_status}
                            error={"We couldn't find your ticket. Please try to search with different keywords."}
                            matrix={getResolvedTicketMatrix(resolvedTicketKeyword ? support_resolved_ticket_search_data : resolvedTicketList)}
                            showAll={!resolvedTicketKeyword ? renderPagination() : false}
                        />
                    ) : (
                        <div className="noResolvedTickets">You have no current resolved eTickets.</div>
                    )}
                </>
            );
        };

        const renderOpenTicketsBox = () => {
            const boxStatus = support_tickets_open_error?.details === 'Response Cancelled' ? 'loading' : support_tickets_open_status;

            const submitNewTicketBoxTitle = {
                title: 'Open eTickets',
                action: {
                    label: `Submit a New eTicket`,
                    type: 'onClick',
                    size: 'medium',
                    color: 'primary',
                    buttonType: 'Solid',
                    onClick: (e) => {
                        e.preventDefault();
                        history.push('/support/tickets/submit');
                    }
                }
            };

            return (
                <div className="ticketHome__openTickets">
                    <Box
                        request={{
                            action: getOpenTickets,
                            args: false,
                            status: boxStatus
                        }}
                        status={boxStatus}
                        title={openTicketList.length > 0 ? submitNewTicketBoxTitle : 'Open eTickets'}
                        custom={{
                            render: renderOpenTicketsList(),
                            pos: 'bottom'
                        }}
                    />
                </div>
            );
        };

        const renderResolvedTicketsBox = () => {
            const boxStatus = support_resolved_survey_error?.details === 'Response Cancelled' ? 'loading' : support_resolved_survey_status;

            return (
                <div className="ticketHome__resolvedTickets">
                    <Box
                        request={{
                            action: getResolvedSurvey,
                            args: [1],
                            status: boxStatus
                        }}
                        status={boxStatus}
                        title="Resolved eTickets"
                        custom={{
                            render: renderResolvedTicketsList(),
                            pos: 'bottom'
                        }}
                    />
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="ticketHome">
                {renderBanner()}
                {renderOpenTicketsBox()}
                {renderResolvedTicketsBox()}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            support_tickets_open_status: state.support.support_tickets_open_status,
            support_tickets_open_data: state.support.support_tickets_open_data,
            support_tickets_open_error: state.support.support_tickets_open_error,
            support_resolved_survey_status: state.support.support_resolved_survey_status,
            support_resolved_survey_data: state.support.support_resolved_survey_data,
            support_resolved_survey_error: state.support.support_resolved_survey_error,
            support_resolved_survey_meta: state.support.support_resolved_survey_meta,
            support_resolved_survey_load_more_status: state.support.support_resolved_survey_load_more_status,
            support_resolved_ticket_search_status: state.support.support_resolved_ticket_search_status,
            support_resolved_ticket_search_data: state.support.support_resolved_ticket_search_data
        }),
        {
            getOpenTickets,
            getResolvedSurvey,
            searchResolvedTickets,
            resetSearchResolvedTickets
        }
    )(TicketHome)
);
