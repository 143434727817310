/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { iconHideShowEyeClassNameObject } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_eppShowCode.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Info pair with the ability to show or hide the epp code
 */
function EppShowCode({ eppCode }) {
    /***** STATE *****/
    const [isCodeShowing, setIsCodeShowing] = useState();

    /***** FUNCTIONS *****/
    function toggleIsCodeShowing() {
        setIsCodeShowing((prev) => !prev);
    }

    /***** RENDER HELPERS *****/
    const showCodeClassNames = classNames('icon', iconHideShowEyeClassNameObject(isCodeShowing));

    /***** RENDER *****/
    return (
        <div className="eppShowCode">
            <div className="eppShowCode__title">
                <Text secondary size--s>
                    EPP Code
                </Text>
                <button onClick={toggleIsCodeShowing}>
                    <i className={showCodeClassNames} />
                </button>
            </div>
            <Text className="eppShowCode__description">{isCodeShowing ? eppCode : '***************************'}</Text>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

EppShowCode.propTypes = {
    /**
     * EPP Code
     */
    eppCode: PropTypes.string
};

export default EppShowCode;
