/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InvoiceItems } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceItems';
import LightboxPaymentMethods from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDataFromSuccessResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { COPY_closeInvoice, COPY_payInvoiceLater } from 'components/Lightboxes/OverlayLightbox/Components/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const OverlayInvoiceContent = ({ invoiceID, handleSuccessResponse, closeInvoice }) => {
    /***** STATE *****/

    /***** QUERIES *****/
    const { data: get_invoice_data } = useGetInvoiceQuery(invoiceID);

    /***** RENDER HELPERS *****/
    const { amount_due, subtotal, credit } = get_invoice_data?.attributes || {
        amount_due: '0.00',
        subtotal: '0.00',
        credit: '0.00'
    };

    const mutationOptions = {
        onSuccess: (response) => {
            const paymentData = getDataFromSuccessResponse(response);
            handleSuccessResponse(paymentData);
        }
    };

    /***** RENDER *****/
    return (
        <div className="OverlayInvoice">
            <div className="OverlayInvoice__wrapper">
                <div className="OverlayInvoice__details">
                    {get_invoice_data?.included ? (
                        <div className="OverlayInvoice__items">
                            <InvoiceItems items={get_invoice_data.included} />
                        </div>
                    ) : (
                        <div className="OverlayInvoice__items">
                            <div className="item__row">
                                <div className="item__column--description"></div>
                            </div>
                        </div>
                    )}
                    <div className="OverlayInvoice__subtotal">
                        <div className="subtotal__title">Subtotal</div>
                        <div className="subtotal__price">${subtotal} AUD</div>
                    </div>
                </div>
                <div className="OverlayInvoice__options">
                    <div className="Options__overviewContainer">
                        {credit === '0.00' ? (
                            ''
                        ) : (
                            <div className="Options__overview credit">
                                <div className="overview__title--credit">Credit Applied</div>
                                <div className="overview__price--credit">-${credit} AUD</div>
                            </div>
                        )}
                        <div className="Options__overview">
                            <div className="overview__title">Total Amount Due</div>
                            <div className="overview__price">${amount_due} AUD</div>
                        </div>
                    </div>
                    {amount_due === '0.00' ? (
                        <div className="Options__completed">
                            <div className="overview__action">
                                <SolidButton to={`/billing/invoice/${invoiceID}`}>View Invoice Details</SolidButton>
                            </div>
                        </div>
                    ) : (
                        <LightboxPaymentMethods invoiceID={invoiceID} mutationOptions={mutationOptions} />
                    )}
                </div>
            </div>
            <Anchor className="OverlayInvoice__commonlink" onClick={closeInvoice}>
                {amount_due === '0.00' ? COPY_closeInvoice : COPY_payInvoiceLater}
            </Anchor>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { OverlayInvoiceContent };
