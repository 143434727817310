/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @template T
 * @typedef {import("utilities/types").ValueOf<T>} ValueOf
 */

export const presets = /** @type const */ ({
    GOOGLE_WORKSPACE: 'Google Workspace',
    OFFICE_365: 'Office 365',
    VENTRAIP_EMAIL: 'VentraIP Email',
    SHOPIFY: 'Shopify',
    SQUARESPACE: 'Squarespace'
});

export const apiPresetNames = /** @type const */ ({
    [presets.GOOGLE_WORKSPACE]: 'google-mx',
    [presets.OFFICE_365]: 'office-mx',
    [presets.VENTRAIP_EMAIL]: 'ventraip-mx',
    [presets.SHOPIFY]: 'shopify',
    [presets.SQUARESPACE]: 'squarespace'
});

export const views = /** @type const */ ({
    SELECT: 'select',
    PRESET: 'preset'
});

/**
 * @typedef {typeof presets} TPresets
 * @typedef {ValueOf<TPresets>} TPresetName
 * 
 * @typedef {typeof apiPresetNames} TApiPresetNames
 * @typedef {ValueOf<TApiPresetNames>} TApiPresetName
 *
 * @typedef {typeof views} TViews
 * @typedef {ValueOf<TViews>} TViewName
 */
