/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/** Helpers **/
export const ElementArrayToChild = (el) => {
    if (!Array.isArray(el)) return el;
    return (
        <Fragment>
            {el.map((el, i) => (
                <Fragment key={i}>{el}</Fragment>
            ))}
        </Fragment>
    );
};

export const ElementToChild = (el) => {
    if (!el) return null;
    return ElementArrayToChild(el);
};

/**********************************************************************************************************
 *   COMPONENT START
 ***********************************************************************************************************/
/**
 * Conditional Render - Provides a JSX Style ternerary statement that automatically handles either case by default, while this is similar to a ternary statement, 
 * it will automatically insert null if the conditions render prop (e.g. true => onTrue) is not provided
 */
export const ConditionalRender = ({ condition, onTrue, onFalse }) => (condition ? ElementToChild(onTrue) : ElementToChild(onFalse));

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ConditionalRender.propTypes = {
    // A condition that determines whether or not to render the onTrue or onFalse prop
    condition: PropTypes.bool.isRequired,

    // The element(s) to render if the condition is true
    onTrue: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

    // The element(s) to render if the condition is false
    onFalse: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};
