/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import Clipboard from 'components/Clipboard';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { formatLuxonOrdinal, getIncludedObjBasedOnType, iconHideShowEyeClassNameObject, toLuxonDate } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getVpsType, vpsTypes } from '../methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Overview = (props) => {
    const {
        /**
         * React Router Props
         */
        history,

        /**
         * Redux Props
         */
        vps_information_status,
        vps_information_data,
        vps_user_information_status,
        vps_user_information_data,
        vps_astro_information_data,
        vps_solus_information_status
    } = props;
    /***** STATE *****/
    const [showPassword, setShowPassword] = useState(false);

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, vps_solus_information_status === 'success');
    }, [vps_solus_information_status]);

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    if (vps_information_status === 'success') {
        const { attributes, included } = vps_information_data;
        const { domain, domain_status, registration_date, next_due_date, next_invoice_date, billing_cycle, amount } = attributes;
        const product = getIncludedObjBasedOnType(included, 'product');
        const { name } = product.attributes;

        const isSelfManaged = getVpsType(name) === vpsTypes.SELF_MANAGED;

        const renderRoot = () => {
            if (vps_user_information_status === 'success' && vps_user_information_data) {
                const { attributes } = vps_user_information_data;
                const { password } = attributes;
                const RenderField = () => {
                    if (showPassword) {
                        return <input type="text" readOnly={true} className="VPS-Overview__list-item-value-password" value={password} />;
                    } else {
                        return <input type="password" readOnly={true} className="VPS-Overview__list-item-value-password" value={password} />;
                    }
                };

                const vpsOverviewIconClassNames = classNames(
                    'VPS-Overview__list-item-label-icon',
                    'icon',
                    iconHideShowEyeClassNameObject(showPassword)
                );

                return (
                    <li className="VPS-Overview__list-item">
                        <button className="box__description VPS-Overview__list-item-label">
                            Root Password{' '}
                            <button onClick={toggleShowPassword}>
                                <i className={vpsOverviewIconClassNames} />
                            </button>
                        </button>
                        <div className="VPS-Overview__list-item-value VPS-Overview__list-item-value--split">
                            {RenderField()}
                            <Clipboard value={password} className="VPS-Overview__clipboard" />
                        </div>
                    </li>
                );
            } else {
                return '';
            }
        };

        const renderSSHPort = () => {
            if (vps_user_information_status === 'success' && vps_user_information_data) {
                const { attributes } = vps_user_information_data;
                const { sshport } = attributes;

                if (sshport && sshport !== '' && sshport.length > 0) {
                    return (
                        <li className="VPS-Overview__list-item">
                            <div className="box__description VPS-Overview__list-item-label">SSH PORT</div>
                            <div className="VPS-Overview__list-item-value">{sshport}</div>
                        </li>
                    );
                } else {
                    return '';
                }
            }
        };

        const dateFormatting = 'EEEE, MMM {d} yyyy';
        const registrationDateFormatted = registration_date ? formatLuxonOrdinal(toLuxonDate(registration_date).toFormat(dateFormatting)) : '';

        const conditionalProps = {
            split: {
                left: {
                    render: (
                        <Fragment>
                            <div className="VPS-Overview__column">
                                <div className="box__heading VPS-Overview__heading">Service Overview</div>
                                <ul className="VPS-Overview__list">
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">HOSTNAME</div>
                                        <div className="VPS-Overview__list-item-value">{domain}</div>
                                    </li>
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">STATUS</div>
                                        <div
                                            className={`VPS-Overview__list-item-value${
                                                domain_status ? ' VPS-Overview__list-item-value--active' : ''
                                            }`}
                                        >
                                            {domain_status}
                                        </div>
                                    </li>
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">PRODUCT NAME</div>
                                        <div className="VPS-Overview__list-item-value VPS-Overview__list-item-value--split">
                                            <span className="VPS-Overview__list-item-value">{name}</span>
                                            <Anchor
                                                onClick={() => {
                                                    const { id } = vps_information_data;
                                                    const vpsUpgrade = document.querySelector('.VPS-Upgrade');
                                                    if (vpsUpgrade) {
                                                        scroll.scrollTo(vpsUpgrade.offsetTop);
                                                    }
                                                    history.push(`/my-services/vps/account/upgrade-plan/${id}`);
                                                }}
                                                className="VPS-Overview__list-item-value-link"
                                            >
                                                Upgrade
                                            </Anchor>
                                        </div>
                                    </li>
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">ACTIVATED</div>
                                        <div className="VPS-Overview__list-item-value">
                                            <span className="VPS-Overview__list-item-value">{registrationDateFormatted}</span>
                                        </div>
                                    </li>
                                    {isSelfManaged && renderRoot()}
                                    {renderSSHPort()}
                                </ul>
                            </div>
                        </Fragment>
                    )
                },
                right: {
                    render: (
                        <Fragment>
                            <div className="VPS-Overview__column VPS-Overview__column--right">
                                <div className="box__heading VPS-Overview__heading">Billing Overview</div>
                                <ul className="VPS-Overview__list">
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">NEXT RENEWAL DATE</div>
                                        <div className="VPS-Overview__list-item-value">
                                            {formatLuxonOrdinal(toLuxonDate(next_due_date).toFormat(dateFormatting))}
                                        </div>
                                    </li>
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">NEXT INVOICE GENERATION DATE</div>
                                        <div className="VPS-Overview__list-item-value">
                                            {formatLuxonOrdinal(toLuxonDate(next_invoice_date).toFormat(dateFormatting))}
                                        </div>
                                    </li>
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">BILLING CYCLE</div>
                                        <div className="VPS-Overview__list-item-value VPS-Overview__list-item-value--split">
                                            <span className="VPS-Overview__list-item-value">{billing_cycle}</span>
                                            <Anchor
                                                onClick={() => {
                                                    const { id } = vps_information_data;
                                                    const vpsBillingCycle = document.getElementById('VPS-BillingCycle');
                                                    if (vpsBillingCycle) {
                                                        scroll.scrollTo(vpsBillingCycle.offsetTop);
                                                    }
                                                    history.push(`/my-services/vps/account/billing/${id}`);
                                                }}
                                                className="VPS-Overview__list-item-value-link"
                                            >
                                                Change
                                            </Anchor>
                                        </div>
                                    </li>
                                    <li className="VPS-Overview__list-item">
                                        <div className="box__description VPS-Overview__list-item-label">RENEWAL AMOUNT</div>
                                        <div className="VPS-Overview__list-item-value">${amount} AUD</div>
                                    </li>
                                </ul>
                            </div>
                        </Fragment>
                    )
                }
            }
        };

        return (
            <>
                {vps_astro_information_data?.attributes?.needs_reboot && isSelfManaged ? (
                    <DialogNotification type="warning" className="DialogNotificationPage--noMarginTop">
                        Your VPS is currently pending a required reboot, please perform a reboot{' '}
                        <Anchor to={`/my-services/vps/manage/power-management/${vps_information_data?.id}`}>here</Anchor> to apply the changes at your
                        earliest convenience. If you have made changes to your resource allocations recently, they may not be applied in your VPS
                        until this reboot has been performed.
                    </DialogNotification>
                ) : (
                    ''
                )}
                <div className="VPS-Overview" ref={scrollRef}>
                    <Box premounted={true} {...conditionalProps} />
                </div>
            </>
        );
    } else {
        return '';
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        vps_information_data: state.vps.vps_information_data,
        vps_information_status: state.vps.vps_information_status,
        vps_solus_information_status: state.vps.vps_solus_information_status,
        vps_user_information_data: state.vps.vps_user_information_data,
        vps_astro_information_data: state.vps.vps_astro_information_data,
        vps_user_information_status: state.vps.vps_user_information_status
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);

Overview = withRouter(Overview);

export default Overview;
