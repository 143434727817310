/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';
import { Field, blur, change, getFormValues, reduxForm, untouch } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ThirdPartyPresetForm from '../presets';
import { PresetFooter } from './presetFooter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import IconButton from 'components/Buttons/IconButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';
import SolidTag from 'components/Tags/SolidTag';

/*   ACTIONS
 *****************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { TableRenderField, TableRenderSelectField, domainFieldValidationLowercased, requiredFieldValidation } from 'utilities/methods/form';
import { addDnsRecord, deleteDnsRecord, editDnsRecord, resetDnsRecord } from '../../action';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {ReturnType<typeof mapStateToProps>} reduxProps
 * @typedef {import('react-router-dom').RouteComponentProps} routerProps
 * @typedef {unknown & RecordsTable} Props
 * @typedef {{
 *   record: {
 *     status: string,
 *     pending: unknown,
 *     editing: unknown
 *   },
 *   showAddPresetLightbox: boolean,
 *   showResetLightbox: boolean,
 * }} State
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @extends {Component<Props & reduxProps & routerProps, State>}
 */
class RecordsTable extends Component {
    constructor(props) {
        super(props);
        /** @type {State} */
        this.state = {
            record: {
                status: 'ready',
                pending: undefined,
                editing: undefined
            },
            showAddPresetLightbox: false,
            showResetLightbox: false
        };
        this.toggleAddPresetLightbox = this.toggleAddPresetLightbox.bind(this);
        this.toggleResetLightbox = this.toggleResetLightbox.bind(this);
        this.propagateRecordFormValue = this.propagateRecordFormValue.bind(this);
        this.checkForRecordEntry = this.checkForRecordEntry.bind(this);
        this.setFormValues = this.setFormValues.bind(this);
        this.handleResetSubmit = this.handleResetSubmit.bind(this);
        this.handleRecord = this.handleRecord.bind(this);
        this.setEditedRecord = this.setEditedRecord.bind(this);
        this.setPendingRecord = this.setPendingRecord.bind(this);
    }

    /*   TOGGLE LIGHTBOXES
     *****************************************************/
    toggleAddPresetLightbox() {
        const { showAddPresetLightbox } = this.state;

        this.setState({
            showAddPresetLightbox: !showAddPresetLightbox
        });
    }

    toggleResetLightbox() {
        const { showResetLightbox } = this.state;

        this.setState({
            showResetLightbox: !showResetLightbox
        });
    }

    propagateRecordFormValue(input, value) {
        const { dispatch, form } = this.props;

        dispatch(change(form, input, value));
    }

    checkForRecordEntry(check) {
        const { tableValues } = this.props;

        return tableValues && check in tableValues;
    }

    setFormValues(records) {
        const { domain_information_data, dispatch, form } = this.props;
        const domain = domain_information_data.attributes.domain;

        Object.keys(records).forEach((value) => {
            const { id, attributes } = records[value];

            if (attributes) {
                const { content, dns_type, prio, ttl } = attributes;

                let hostname;
                if (attributes.hostname.includes(`.${domain}`)) {
                    hostname = attributes.hostname.replace(`.${domain}`, '');
                } else {
                    hostname = attributes.hostname.replace(domain, '');
                }

                switch (dns_type) {
                    case 'SRV': {
                        const srvValues = content.split(' ');

                        dispatch(
                            batchActions([
                                change(form, `${id}-dns_type`, dns_type),
                                change(form, `${id}-hostname`, hostname),
                                change(form, `${id}-weight`, srvValues[0]),
                                change(form, `${id}-port`, srvValues[1]),
                                change(form, `${id}-destination`, srvValues[2]),
                                change(form, `${id}-prio`, prio),
                                change(form, `${id}-ttl`, ttl)
                            ])
                        );
                        break;
                    }
                    case 'CAA': {
                        const caaValues = content.split(' ');

                        dispatch(
                            batchActions([
                                change(form, `${id}-dns_type`, dns_type),
                                change(form, `${id}-hostname`, hostname),
                                change(form, `${id}-flag`, caaValues[0]),
                                change(form, `${id}-tag`, caaValues[1]),
                                change(form, `${id}-value`, caaValues[2]),
                                change(form, `${id}-prio`, prio),
                                change(form, `${id}-ttl`, ttl)
                            ])
                        );
                        break;
                    }
                    default:
                        dispatch(
                            batchActions([
                                change(form, `${id}-dns_type`, dns_type),
                                change(form, `${id}-hostname`, hostname),
                                change(form, `${id}-content`, content),
                                change(form, `${id}-prio`, prio),
                                change(form, `${id}-ttl`, ttl)
                            ])
                        );
                        break;
                }
            }
        });

        dispatch(
            batchActions([
                blur(form, `new-dns_type`, 'A'),
                blur(form, `new-hostname`, ''),
                blur(form, `new-content`, ''),
                blur(form, `new-weight`, ''),
                blur(form, `new-port`, ''),
                blur(form, `new-destination`, ''),
                blur(form, `new-prio`, ''),
                blur(form, `new-ttl`, ''),
                untouch(form, `new-content`),
                untouch(form, `new-weight`),
                untouch(form, `new-port`),
                untouch(form, `new-destination`),
                untouch(form, `new-prio`)
            ])
        );

        this.forceUpdate();
    }

    /*   HANDLE SUBMITS
     *****************************************************/
    handleResetSubmit() {
        const { domain_information_data, resetDnsRecord } = this.props;
        resetDnsRecord(domain_information_data.id);
    }

    handleRecord(type, id) {
        const { domain_information_data, tableValues, addDnsRecord, editDnsRecord, deleteDnsRecord } = this.props;
        const { record } = this.state;
        const domain = domain_information_data.attributes.domain;

        const handleInputSanitization = (value = '', options = { rootDomain: true }) => {
            const val = value.replace(/\s+/g, ''); // Remove all spaces
            if (val === '@') return domain;
            val.includes(domain) && val.replace(domain, ''); // Strip out root domain
            return options.rootDomain ? val.concat(`.${domain}`) : val; // Concat root domain on condition
        };

        switch (type) {
            case 'delete':
                deleteDnsRecord(domain_information_data.id, record.pending);
                break;

            case 'edit': {
                let editedRecord = {
                    type: tableValues[`${id}-dns_type`] ? tableValues[`${id}-dns_type`] : `A`,
                    hostname: tableValues[`${id}-hostname`] ? handleInputSanitization(tableValues[`${id}-hostname`]) : domain,
                    prio: tableValues[`${id}-prio`] ? tableValues[`${id}-prio`] : 0,
                    ttl: tableValues[`${id}-ttl`] ? tableValues[`${id}-ttl`] : 3600
                };

                switch (tableValues[`${id}-dns_type`]) {
                    case 'TXT':
                        editedRecord = {
                            ...editedRecord,
                            content: tableValues[`${id}-content`]
                        };
                        break;

                    case 'SRV':
                        editedRecord = {
                            ...editedRecord,
                            weight: tableValues[`${id}-weight`],
                            port: tableValues[`${id}-port`],
                            destination: tableValues[`${id}-destination`].replace(/\s+/g, '')
                        };
                        break;

                    case 'CAA':
                        editedRecord = {
                            ...editedRecord,
                            flag: tableValues[`${id}-flag`],
                            tag: tableValues[`${id}-tag`],
                            value: tableValues[`${id}-value`].replace(/\s+/g, '')
                        };
                        break;

                    case 'A':
                    case 'AAAA':
                    case 'CNAME':
                    case 'MX':
                        editedRecord = {
                            ...editedRecord,
                            hostname: editedRecord.hostname.toLowerCase(),
                            content: tableValues[`${id}-content`].replace(/\s+/g, '').toLowerCase()
                        };
                        break;

                    default:
                        editedRecord = {
                            ...editedRecord,
                            content: tableValues[`${id}-content`].replace(/\s+/g, '')
                        };
                        break;
                }

                editedRecord['id'] = id;
                editDnsRecord(domain_information_data.id, editedRecord.id, editedRecord);
                break;
            }
            case 'add':
            default: {
                let newRecord = {
                    type: tableValues[`new-dns_type`] ? tableValues[`new-dns_type`] : `A`,
                    hostname: tableValues[`new-hostname`] ? handleInputSanitization(tableValues[`new-hostname`]) : domain,
                    prio: tableValues[`new-prio`] ? tableValues[`new-prio`] : 0,
                    ttl: tableValues[`new-ttl`] ? tableValues[`new-ttl`] : 3600
                };

                switch (tableValues[`new-dns_type`]) {
                    case 'TXT':
                        newRecord = {
                            ...newRecord,
                            content: tableValues[`new-content`]
                        };
                        break;

                    case 'SRV':
                        newRecord = {
                            ...newRecord,
                            weight: tableValues[`new-weight`],
                            port: tableValues[`new-port`],
                            destination: handleInputSanitization(tableValues[`new-destination`], {
                                rootDomain: false
                            })
                        };
                        break;

                    case 'CAA':
                        newRecord = {
                            ...newRecord,
                            flag: tableValues[`new-flag`],
                            tag: tableValues[`new-tag`],
                            value: tableValues[`new-value`]
                        };
                        break;

                    case 'MX':
                        newRecord = {
                            ...newRecord,
                            prio: tableValues[`new-prio`],
                            hostname: newRecord.hostname.toLowerCase(),
                            content: tableValues[`new-content`].replace(/\s+/g, '').toLowerCase()
                        };
                        break;

                    case 'A':
                    case 'AAAA':
                    case 'CNAME':
                        newRecord = {
                            ...newRecord,
                            hostname: newRecord.hostname.toLowerCase(),
                            content: tableValues[`new-content`].replace(/\s+/g, '').toLowerCase()
                        };
                        break;

                    default:
                        newRecord = {
                            ...newRecord,
                            content: tableValues[`new-content`].replace(/\s+/g, '')
                        };
                        break;
                }

                newRecord['id'] = id;
                addDnsRecord(domain_information_data.id, newRecord);
                break;
            }
        }
    }

    setEditedRecord(id, { dnsType = 'A' } = {}) {
        const { record } = this.state;
        const { dispatch, form } = this.props;

        this.setState(
            {
                record: {
                    ...record,
                    editing: id ? id : undefined,
                    type: dnsType
                }
            },
            () => {
                setTimeout(() => {
                    dispatch(change(form, `${id}-dns_type`, dnsType));
                }, 0);
            }
        );
    }

    setPendingRecord(id) {
        const { record } = this.state;

        this.setState({
            record: {
                ...record,
                pending: id ? id : undefined
            }
        });
    }

    /*   LIFECYCLE METHODS
     *****************************************************/
    componentDidMount() {
        const { domain_record_store } = this.props;
        const { setFormValues } = this;
        setFormValues(domain_record_store);
    }

    componentDidUpdate(prevProps, prevState) {
        const { domain_update_current_configuration_status, domain_record_store, domain_dns_view, tableValues, dispatch, domain_record_status } =
            this.props;
        const { record, showResetLightbox } = this.state;
        const { setFormValues, toggleResetLightbox } = this;

        if (record !== prevState.record) {
            setFormValues(domain_record_store);
        }

        if (
            domain_record_status !== prevProps.domain_record_status ||
            domain_dns_view !== prevProps.domain_dns_view ||
            (domain_update_current_configuration_status === 'success' && prevProps.domain_update_current_configuration_data === 'loading') ||
            domain_record_store !== prevProps.domain_record_store
        ) {
            setFormValues(domain_record_store);

            if (showResetLightbox) {
                toggleResetLightbox();
            }
        }

        if (
            (tableValues && tableValues[`new-dns_type`] === 'MX' && (!prevProps.tableValues || prevProps.tableValues[`new-dns_type`] !== 'MX')) ||
            (tableValues && tableValues[`new-dns_type`] === 'SRV' && (!prevProps.tableValues || prevProps.tableValues[`new-dns_type`] !== 'SRV'))
        ) {
            dispatch(change('recordsForm', 'new-prio', 5));
        }
    }

    render() {
        const {
            app_viewport,
            form,
            pristine,
            valid,
            submitting,
            dispatch,
            tableValues,
            domain_update_current_configuration_status,
            domain_current_configuration_status,
            domain_third_party_status,
            domain_information_data,
            domain_record_status,
            domain_dns_view,
            domain_record_store,
            domain_record_store_status
        } = this.props;
        const { record, showAddPresetLightbox, showResetLightbox } = this.state;
        const { toggleAddPresetLightbox, toggleResetLightbox, handleRecord, setPendingRecord, setEditedRecord, handleResetSubmit } = this;

        const renderTag = (type) => {
            switch (type) {
                case 'A':
                    return <SolidTag color="record__pink">{type}</SolidTag>;

                case 'NS':
                    return <SolidTag color="record__orange">{type}</SolidTag>;

                case 'CNAME':
                    return <SolidTag color="record__violet">{type}</SolidTag>;

                case 'CAA':
                    return <SolidTag color="record__violet">{type}</SolidTag>;

                case 'MX':
                    return <SolidTag color="record__blue">{type}</SolidTag>;

                case 'TXT':
                    return <SolidTag color="record__aqua">{type}</SolidTag>;

                case 'AAAA':
                    return <SolidTag color="record__purple">{type}</SolidTag>;

                case 'SRV':
                    return <SolidTag color="record__green">{type}</SolidTag>;

                case 'ALIAS':
                    return <SolidTag color="record__violet">{type}</SolidTag>;

                default:
                    break;
            }
        };

        const handleTableLoader = () => {
            if (
                domain_update_current_configuration_status === 'loading' ||
                domain_current_configuration_status === 'loading' ||
                domain_record_status === 'loading' ||
                domain_record_store_status === 'loading'
            ) {
                return 'loading';
            }

            return 'success';
        };

        const handleMatrixRender = () => {
            const records = domain_record_store;
            const tempRecordMatrix = Array(records.length).fill();

            const formTypeFieldRender = (id, dnsType) => {
                return (
                    <Field
                        key={`${id}-dns_type`}
                        name={`${id}-dns_type`}
                        tooltip={
                            dnsType === 'CNAME'
                                ? 'A CNAME cannot be placed at the root domain level (such as example.com), because the root domain is the DNS Start of Authority (SOA) which must point to an IP address. '
                                : ''
                        }
                        component={TableRenderSelectField}
                        validate={[requiredFieldValidation]}
                        attributes={{
                            type: `select`
                        }}
                        settings={{
                            isTag: true
                        }}
                        options={[
                            {
                                label: 'A',
                                value: 'A'
                            },
                            {
                                label: 'AAAA',
                                value: 'AAAA'
                            },
                            {
                                label: 'CAA',
                                value: 'CAA'
                            },
                            {
                                label: 'CNAME',
                                value: 'CNAME'
                            },
                            {
                                label: 'MX',
                                value: 'MX'
                            },
                            {
                                label: 'TXT',
                                value: 'TXT'
                            },
                            {
                                label: 'SRV',
                                value: 'SRV'
                            },
                            {
                                label: 'NS',
                                value: 'NS'
                            },
                            {
                                label: 'ALIAS',
                                value: 'ALIAS'
                            }
                        ]}
                    />
                );
            };

            const formHostnameFieldRender = (id, dnsType) => {
                const baseFieldProps = {
                    key: `${id}-hostname`,
                    name: `${id}-hostname`,
                    component: TableRenderField,
                    attributes: {
                        type: `text`
                    },
                    append: {
                        type: `domain`,
                        text: `.${domain_information_data.attributes.domain}`
                    }
                };

                switch (dnsType) {
                    case 'CNAME':
                    case 'SRV':
                    case 'NS':
                        return <Field {...baseFieldProps} validate={[requiredFieldValidation]} />;

                    default:
                        return <Field {...baseFieldProps} />;
                }
            };

            const formContentFieldRender = (id, dnsType) => {
                switch (dnsType) {
                    case 'SRV':
                        return (
                            <div className="records__multiField">
                                <Field
                                    key={`${id}-weight`}
                                    name={`${id}-weight`}
                                    component={TableRenderField}
                                    validate={[requiredFieldValidation]}
                                    attributes={{
                                        type: `text`,
                                        placeholder: `Weight`
                                    }}
                                />
                                <Field
                                    key={`${id}-port`}
                                    name={`${id}-port`}
                                    component={TableRenderField}
                                    validate={[requiredFieldValidation]}
                                    attributes={{
                                        type: `number`,
                                        placeholder: `Port`
                                    }}
                                />
                                <Field
                                    key={`${id}-destination`}
                                    name={`${id}-destination`}
                                    validate={[requiredFieldValidation]}
                                    component={(props) => (
                                        <TableRenderField
                                            {...props}
                                            attributes={{
                                                type: `text`,
                                                placeholder: `Destination`
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        );
                    case 'CAA':
                        return (
                            <div className="records__multiField">
                                <Field
                                    key={`${id}-flag`}
                                    name={`${id}-flag`}
                                    component={TableRenderField}
                                    className="records__flag"
                                    attributes={{
                                        type: `text`,
                                        value: 0,
                                        disabled: true
                                    }}
                                />
                                <Field
                                    key={`${id}-tag`}
                                    name={`${id}-tag`}
                                    component={TableRenderSelectField}
                                    validate={[requiredFieldValidation]}
                                    attributes={{
                                        type: `select`
                                    }}
                                    options={[
                                        {
                                            label: 'issue',
                                            value: 'issue'
                                        },
                                        {
                                            label: 'issuewild',
                                            value: 'issuewild'
                                        },
                                        {
                                            label: 'iodef',
                                            value: 'iodef'
                                        }
                                    ]}
                                />
                                <Field
                                    key={`${id}-value`}
                                    name={`${id}-value`}
                                    component={TableRenderField}
                                    validate={[requiredFieldValidation]}
                                    attributes={{
                                        type: `text`,
                                        placeholder: `Value`
                                    }}
                                />
                            </div>
                        );

                    case 'CNAME':
                    case 'ALIAS':
                    case 'MX':
                        return (
                            <Field
                                key={`${id}-content`}
                                name={`${id}-content`}
                                component={TableRenderField}
                                validate={[requiredFieldValidation, domainFieldValidationLowercased]}
                                attributes={{
                                    type: `text`
                                }}
                            />
                        );

                    default:
                        return (
                            <Field
                                key={`${id}-content`}
                                name={`${id}-content`}
                                component={TableRenderField}
                                validate={[requiredFieldValidation]}
                                attributes={{
                                    type: `text`
                                }}
                            />
                        );
                }
            };

            const formPriorityFieldRender = (id, dnsType) => {
                switch (dnsType) {
                    case 'SRV':
                    case 'MX':
                        return (
                            <Field
                                key={`${id}-prio`}
                                name={`${id}-prio`}
                                component={TableRenderField}
                                attributes={{
                                    type: `number`,
                                    min: 1
                                }}
                            />
                        );

                    default:
                        return (
                            <Field
                                key={`${id}-prio`}
                                name={`${id}-prio`}
                                component={TableRenderField}
                                readOnly="readonly"
                                attributes={{
                                    type: `number`,
                                    min: 1
                                }}
                            />
                        );
                }
            };

            const formTTLFieldRender = (id) => {
                return (
                    <Field
                        key={`${id}-ttl`}
                        name={`${id}-ttl`}
                        component={TableRenderField}
                        attributes={{
                            type: `number`,
                            placeholder: `3600`,
                            min: 1
                        }}
                    />
                );
            };

            const formActionFieldRender = (id, dnsType, type, value) => {
                switch (type) {
                    case 'add':
                        if (pristine || submitting || !valid) {
                            return React.Children.toArray([
                                <ReduxFormButton className="records__formButton" form={form}>
                                    <IconButton
                                        color="secondary"
                                        label="Add Record"
                                        icon={<PhosphorIcons.Plus size={24} />}
                                        className="records__action add"
                                    />
                                </ReduxFormButton>
                            ]);
                        }

                        return [
                            <IconButton
                                key={0}
                                color="confirm"
                                label="Add Record"
                                icon={<PhosphorIcons.Plus size={24} />}
                                className="records__action add"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleRecord('add', id);
                                }}
                            />
                        ];

                    case 'remove':
                        if (record.pending && record.pending === id) {
                            return [
                                <Fragment key={0}>
                                    <IconButton
                                        group={[
                                            {
                                                className: `records__action save`,
                                                icon: <PhosphorIcons.Check size={24} />,
                                                label: `Save`,
                                                color: `confirm`,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    handleRecord('delete', id);
                                                }
                                            },
                                            {
                                                className: `records__action cancel`,
                                                icon: <PhosphorIcons.X size={24} />,
                                                label: `Cancel`,
                                                color: `warn`,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    setPendingRecord();
                                                }
                                            }
                                        ]}
                                    />
                                </Fragment>
                            ];
                        }

                        return [
                            <Fragment key={0}>
                                <IconButton
                                    color="warn"
                                    label="Delete"
                                    icon={<PhosphorIcons.Backspace size={24} />}
                                    className="records__action remove"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPendingRecord(id);
                                    }}
                                />
                            </Fragment>
                        ];

                    default:
                        if (record.pending && record.pending === id) {
                            return [
                                <Fragment key={0}>
                                    <IconButton
                                        group={[
                                            {
                                                className: `records__action save`,
                                                icon: <PhosphorIcons.Check size={24} />,
                                                label: `Confirm`,
                                                color: `info`,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    handleRecord('delete', id);
                                                }
                                            },
                                            {
                                                className: `records__action cancel`,
                                                icon: <PhosphorIcons.X size={24} />,
                                                label: `Cancel`,
                                                color: `warn`,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    setPendingRecord();
                                                }
                                            }
                                        ]}
                                    />
                                </Fragment>
                            ];
                        }

                        if (record.editing === id) {
                            return [
                                <Fragment key={0}>
                                    <IconButton
                                        group={[
                                            {
                                                className: `records__action save`,
                                                icon: <PhosphorIcons.Check size={24} />,
                                                label: `Save`,
                                                color: `confirm`,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    handleRecord('edit', id);
                                                }
                                            },
                                            {
                                                className: `records__action cancel`,
                                                icon: <PhosphorIcons.X size={24} />,
                                                label: `Cancel`,
                                                color: `warn`,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    setEditedRecord();
                                                }
                                            }
                                        ]}
                                    />
                                </Fragment>
                            ];
                        }

                        return [
                            <Fragment key={0}>
                                <IconButton
                                    group={[
                                        {
                                            className: `records__action edit`,
                                            icon: <PhosphorIcons.NotePencil size={24} />,
                                            label: `Edit`,
                                            color: `info`,
                                            onClick: (e) => {
                                                e.preventDefault();
                                                setEditedRecord(id, { dnsType });
                                            }
                                        },
                                        {
                                            className: `records__action remove`,
                                            icon: <PhosphorIcons.Backspace size={24} />,
                                            label: `Delete`,
                                            color: `warn`,
                                            onClick: (e) => {
                                                e.preventDefault();
                                                setPendingRecord(id);
                                            }
                                        }
                                    ]}
                                />
                            </Fragment>
                        ];
                }
            };

            Object.keys(tempRecordMatrix).forEach((value) => {
                const currentRec = records[value].attributes;
                const currentDnsType = tableValues ? tableValues[`${records[value].id}-dns_type`] : '';
                const { content, dns_type, hostname, prio, ttl } = currentRec;

                if (
                    currentRec &&
                    dns_type === 'NS' &&
                    content.includes(`.nameserver.net.au`) &&
                    hostname === domain_information_data.attributes.domain
                ) {
                    tempRecordMatrix[value] = {
                        type: renderTag(dns_type),
                        hostname: hostname,
                        value: content,
                        priority: prio,
                        ttl: ttl,
                        ...(app_viewport === 'xs' || app_viewport === 'sm' ? { icons: false } : { actions: false })
                    };
                } else if (currentRec) {
                    const actions = formActionFieldRender(records[value].id, currentDnsType, `default`, value);

                    const renderRecordPriority = () => {
                        if (record.editing === records[value].id) return formPriorityFieldRender(records[value].id, currentDnsType);
                        if (prio && prio === 0) return '';
                        return prio;
                    };
                    tempRecordMatrix[value] = {
                        type: record.editing === records[value].id ? formTypeFieldRender(records[value].id, currentDnsType) : renderTag(dns_type),
                        hostname: record.editing === records[value].id ? formHostnameFieldRender(records[value].id, currentDnsType) : hostname,
                        value: record.editing === records[value].id ? formContentFieldRender(records[value].id, currentDnsType) : content,
                        priority: renderRecordPriority(),
                        ttl: record.editing === records[value].id ? formTTLFieldRender(records[value].id) : ttl,
                        ...(app_viewport === 'xs' || app_viewport === 'sm' ? { icons: actions } : { actions: actions })
                    };
                }
            });

            const newDnsType = tableValues ? tableValues[`new-dns_type`] : 'A';
            const actions = formActionFieldRender('new', newDnsType, `add`);

            if (newDnsType !== 'SRV' && newDnsType !== 'MX' && tableValues && tableValues[`new-prio`]) {
                dispatch(batchActions([blur(form, `new-prio`, ''), untouch(form, `new-prio`)]));
            }

            tempRecordMatrix[tempRecordMatrix.length] = {
                type: formTypeFieldRender('new', newDnsType),
                hostname: formHostnameFieldRender('new', newDnsType),
                value: formContentFieldRender('new', newDnsType),
                priority: formPriorityFieldRender('new', newDnsType),
                ttl: formTTLFieldRender('new'),
                ...(app_viewport === 'xs' || app_viewport === 'sm' ? { icons: actions } : { actions: actions })
            };

            return tempRecordMatrix;
        };

        const handleTableRender = () => {
            return (
                <Table
                    className={`records__table${domain_dns_view.state === 'edit' ? ` edit` : ``}`}
                    header={[
                        {
                            title: `Type`,
                            className: `type`
                        },
                        {
                            title: `Hostname`,
                            className: `hostname`
                        },
                        {
                            title: `Value`,
                            className: `value`
                        },
                        {
                            title: `Priority`,
                            className: `priority`
                        },
                        {
                            title: `TTL`,
                            className: `ttl`
                        },
                        {
                            action: true
                        }
                    ]}
                    loading={handleTableLoader()}
                    error="You have no records set. Add a record below."
                    stacked={true}
                    embedded={true}
                    matrix={handleMatrixRender()}
                />
            );
        };

        const handleActionsRender = () => {
            return (
                <div className="manageDNS__footer">
                    <OutlineButton
                        size="large"
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleResetLightbox();
                        }}
                    >
                        Reset to Default
                    </OutlineButton>
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <section className="records">
                {domain_dns_view.state === 'view' ? handleTableRender() : <form>{handleTableRender()}</form>}
                {handleActionsRender()}

                <PresetFooter toggleAddPresetLightbox={toggleAddPresetLightbox} />

                {showAddPresetLightbox ? (
                    <OverlayLightbox
                        onOpen={showAddPresetLightbox}
                        title="What app preset would you like to connect your domain name to?"
                        onClose={toggleAddPresetLightbox}
                        loading={domain_third_party_status}
                    >
                        <ThirdPartyPresetForm />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
                {showResetLightbox ? (
                    <OverlayLightbox
                        onOpen={showResetLightbox}
                        title="Reset to Default DNS Records?"
                        onClose={toggleResetLightbox}
                        confirm={{
                            desc: `Are you sure you'd like reset your DNS records? This action is not reversible and all existing records will be overridden.`,
                            buttonText: 'Reset DNS Records',
                            buttonAction: handleResetSubmit,
                            closeText: 'No, Go Back',
                            closeAction: toggleResetLightbox,
                            loading: handleTableLoader()
                        }}
                    />
                ) : (
                    ''
                )}
            </section>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

RecordsTable = reduxForm({
    form: 'recordsForm',
    enableReinitialize: true
})(RecordsTable);

const mapStateToProps = (state) => {
    const tableValues = getFormValues('recordsForm')(state);

    const initialValues = {
        'new-dns_type': 'A'
    };

    return /** @type {const} */ ({
        domain_update_current_configuration_status: state.domain.domain_update_current_configuration_status,
        domain_current_configuration_status: state.domain.domain_current_configuration_status,
        domain_information_data: state.domain.domain_information_data,
        domain_record_status: state.domain.domain_record_status,
        domain_third_party_status: state.domain.domain_third_party_status,
        domain_record_store: state.domain.domain_record_store,
        domain_record_store_status: state.domain.domain_record_store_status,
        domain_dns_view: state.domain.domain_dns_view,
        app_viewport: state.app.app_viewport,
        form: 'recordsForm',
        tableValues,
        initialValues
    });
};

const mapDispatchToProps = {
    resetDnsRecord,
    addDnsRecord,
    editDnsRecord,
    deleteDnsRecord
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordsTable));
