/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/*   ACTIONS
 *****************************************************/
import { addChildNameserver, addHostIP, deleteChildNameserver, deleteHostIP, getChildNameservers } from '../action';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import ChildNSModal from '../forms/childns';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ChildNS extends Component {
    constructor(props) {
        super(props);
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openDeleteLightbox = this.openDeleteLightbox.bind(this);
        this.closeDeleteLightbox = this.closeDeleteLightbox.bind(this);
        this.handleHostRemove = this.handleHostRemove.bind(this);
        this.handleRecordLightboxSubmit = this.handleRecordLightboxSubmit.bind(this);
        this.processIPaddress = this.processIPaddress.bind(this);
        this.renderIPCollection = this.renderIPCollection.bind(this);
        this.state = {
            showDeleteLightbox: false,
            recordDeleteIndex: null,
            showLightbox: false,
            showLightboxMode: null,
            recordIndex: null,
            childNSMatrix: null
        };
    }

    openLightbox(index, mode) {
        this.setState({
            showLightbox: true,
            showLightboxMode: mode,
            recordIndex: index
        });
    }

    closeLightbox() {
        this.setState({
            showLightbox: false,
            showLightboxMode: null,
            recordIndex: null
        });
    }

    openDeleteLightbox(hostname) {
        this.setState({
            showDeleteLightbox: true,
            recordDeleteIndex: hostname
        });
    }

    closeDeleteLightbox() {
        this.setState({
            showDeleteLightbox: false,
            recordDeleteIndex: null
        });
    }

    processIPaddress(ip) {
        return (
            <div key={ip} className="ipCollection__address">
                {ip}
            </div>
        );
    }

    renderIPCollection(collection) {
        return (
            <div className="ipCollection__container">
                {collection.map((ip) => {
                    return this.processIPaddress(ip);
                })}
            </div>
        );
    }

    handleHostRemove() {
        const { domainid, deleteChildNameserver } = this.props;
        const { recordDeleteIndex } = this.state;
        deleteChildNameserver(domainid, recordDeleteIndex);
    }

    handleRecordLightboxSubmit(values) {
        const { domainid, addHostIP, addChildNameserver, domain_child_record_list_data } = this.props;
        const { showLightboxMode, recordIndex } = this.state;

        if (showLightboxMode === 'add') {
            const attributes = {
                hostname: null,
                ip_address: []
            };
            Object.entries(values).forEach((record) => {
                if (record[0] === 'hostname') {
                    attributes.hostname = record[1];
                } else {
                    attributes.ip_address.push(record[1]);
                }
            });
            addChildNameserver(domainid, attributes);
        } else {
            let hostname = '';
            const attributes = {
                ip_address_collection: [],
                old_ip_address_collection: domain_child_record_list_data[recordIndex].attributes.ip
            };
            Object.entries(values).forEach((record) => {
                if (record[0] === 'hostname') {
                    hostname = record[1];
                } else {
                    attributes.ip_address_collection.push(record[1]);
                }
            });
            addHostIP(domainid, hostname, attributes);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            domainid,
            domain_child_record_status,
            domain_child_record_list_status,
            domain_child_record_list_data,
            domain_child_record_delete_status,
            getChildNameservers
        } = this.props;
        const { openLightbox, openDeleteLightbox, renderIPCollection } = this;
        registerScrollEvents(this, domain_child_record_list_status === 'success' && prevProps.domain_child_record_list_status === 'loading');

        if (domain_child_record_list_status === 'success' && prevProps.domain_child_record_list_status === 'loading') {
            const stagedMatrix = Array(domain_child_record_list_data.length).fill();
            Object.keys(stagedMatrix).forEach((value) => {
                const { attributes } = domain_child_record_list_data[value];
                const { hostname, ip } = attributes;

                stagedMatrix[value] = {
                    hostname: hostname,
                    ip: renderIPCollection(ip),
                    actions: {
                        label: 'Edit',
                        type: 'onClick',
                        className: '',
                        color: 'warning',
                        onClick: (e) => {
                            e.preventDefault();
                            openLightbox(value, 'edit');
                        },
                        list: [
                            {
                                label: 'Delete',
                                type: 'onClick',
                                className: '',
                                color: 'warn',
                                onClick: (e) => {
                                    e.preventDefault();
                                    openDeleteLightbox(hostname);
                                }
                            }
                        ]
                    }
                };
            });

            this.setState({
                childNSMatrix: stagedMatrix
            });
        }

        if (domain_child_record_status === 'success' && prevProps.domain_child_record_status === 'loading') {
            this.setState({
                showDeleteLightbox: false,
                recordDeleteIndex: null,
                showLightbox: false,
                showLightboxMode: null,
                recordIndex: null
            });
        }

        if (domain_child_record_delete_status === 'success' && prevProps.domain_child_record_delete_status === 'loading') {
            this.setState(
                {
                    showDeleteLightbox: false
                },
                () => {
                    getChildNameservers(domainid);
                }
            );
        }
    }

    render() {
        const {
            domainid,
            disabled,
            getChildNameservers,
            domain_child_record_list_status,
            domain_child_record_delete_status,
            domain_child_record_status
        } = this.props;
        const { recordIndex, showLightbox, showLightboxMode, showDeleteLightbox, childNSMatrix } = this.state;
        const { openLightbox, closeLightbox, handleHostRemove, handleRecordLightboxSubmit, closeDeleteLightbox } = this;

        /*   RENDER LIGHTBOX
         **********************************************************************************************************/
        const handleLightboxRender = () => {
            switch (showLightboxMode) {
                case 'edit':
                    return (
                        <OverlayLightbox
                            onClose={closeLightbox}
                            onOpen={showLightbox}
                            title="Manage Registry Host"
                            loading={domain_child_record_status}
                        >
                            <ChildNSModal
                                mode={showLightboxMode}
                                onSubmit={handleRecordLightboxSubmit}
                                deleteIP={handleHostRemove}
                                record={recordIndex}
                            />
                        </OverlayLightbox>
                    );

                case 'add':
                    return (
                        <OverlayLightbox
                            onOpen={showLightbox}
                            onClose={closeLightbox}
                            title="Add New Registry Host"
                            loading={domain_child_record_status}
                        >
                            <ChildNSModal mode={showLightboxMode} onSubmit={handleRecordLightboxSubmit} />
                        </OverlayLightbox>
                    );

                default:
                    return '';
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="childNameservers"
            >
                <Box
                    request={{
                        action: getChildNameservers,
                        args: domainid,
                        status: domain_child_record_list_status
                    }}
                    disabled={disabled}
                    className="childNameservers__box"
                    title="Child Nameservers"
                    desc="Child Nameservers allow you to create your own private nameservers."
                    info={`Creating Child Name Servers allows you to create custom Name Servers using your own domain name. For example, if your domain name is mydomain.com, you may decide to create the following name servers: 
                            <br><br>
                            ns1.mydomain.com
                            <br>
                            ns2.mydomain.com
                        
                            <br><br>
                            Helpful Hint
                            <br>
                            If you’re using ${company}’s Name Servers and want to set up Child Name Servers, contact Technical Support to request the IP Addresses.`}
                    custom={{
                        render: (
                            <Table
                                embedded={true}
                                className="childNameservers__table"
                                header={[
                                    {
                                        title: `Hostname`,
                                        className: `hostname`
                                    },
                                    {
                                        title: `IP Addresses`,
                                        className: `ipaddresses`
                                    },
                                    {
                                        action: true
                                    }
                                ]}
                                loading={domain_child_record_list_status}
                                error="You have no host records set. You can add a record below."
                                matrix={childNSMatrix}
                                stacked={true}
                                dynamicActions={true}
                                footer={{
                                    label: `Add New Host`,
                                    icon: <PhosphorIcons.Plus.Bold size={20} secondary />,
                                    className: 'newHostAction',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        if (!disabled) {
                                            openLightbox(null, 'add');
                                        }
                                    }
                                }}
                            />
                        ),
                        pos: 'bottom'
                    }}
                />
                {showLightbox ? handleLightboxRender() : ''}
                {showDeleteLightbox ? (
                    <OverlayLightbox
                        onOpen={showDeleteLightbox}
                        onClose={closeDeleteLightbox}
                        title="Delete Child Nameserver?"
                        loading={domain_child_record_delete_status}
                        confirm={{
                            desc: htmr(
                                `Please note that removing this child nameserver will affect any services that have it set in their DNS settings.`
                            ),
                            buttonText: 'Delete Child Nameserver',
                            buttonAction: handleHostRemove,
                            closeText: 'No, Go Back',
                            closeAction: closeDeleteLightbox
                        }}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        domain_manage_state: state.domain.domain_manage_state,
        domain_current_configuration_data: state.domain.domain_current_configuration_data,
        domain_child_record_list_status: state.domain.domain_child_record_list_status,
        domain_child_record_list_data: state.domain.domain_child_record_list_data,
        domain_child_record_list_error: state.domain.domain_child_record_list_error,
        domain_child_record_delete_status: state.domain.domain_child_record_delete_status,
        domain_child_record_delete_data: state.domain.domain_child_record_delete_data,
        domain_child_record_delete_error: state.domain.domain_child_record_delete_error,
        domain_child_record_status: state.domain.domain_child_record_status,
        domain_child_record_data: state.domain.domain_child_record_data,
        domain_child_record_error: state.domain.domain_child_record_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getChildNameservers,
            addChildNameserver,
            deleteChildNameserver,
            addHostIP,
            deleteHostIP
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChildNS));
