/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import KatanaIndex from 'containers/katana';
import { KatanaRegisterDomain } from 'containers/katana/containers/registerDomain';
import KatanaTrialDomain from 'containers/katana/containers/trial/domainName';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function KatanaMain() {
    return (
        <Switch>
            <Route exact path={`${serviceNav.VIPsites.link}/register/:domain_name`} component={KatanaRegisterDomain} />
            <Route exact path={`${serviceNav.VIPsites.link}/trial/:domain_name`} component={KatanaTrialDomain} />
            <Route exact path={`${serviceNav.VIPsites.link}/:id/:page?/:subpage?/:childpage?`} component={KatanaIndex} />
        </Switch>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaMain };
