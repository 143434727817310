/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { getFormValues } from 'redux-form';
import store from 'store/store';
import { validatorDomainNameNoWWW, validatorEmailAddress, validatorIPV4, validatorIPV6 } from 'utilities/methods/validators';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    COPY_invalidDomainNoWWW,
    COPY_invalidIpAddress,
    COPY_validationCorrect,
    COPY_validationCorrected,
    migrationFormNameDetails,
    validated_details_unsure
} from './consts';

/**
 * For each field check if field in values has a value
 */
export function checkRequiredFields(values, errors, fields) {
    fields.forEach((fieldName) => {
        if (!values[fieldName]) {
            errors[fieldName] = 'Required';
        }
    });
    return errors;
}

export function checkIsTechnical(values, errors) {
    if (values.is_technical === undefined) {
        errors.is_technical = 'Required';
    }
    return errors;
}

export function checkIpValidations(values, errors) {
    if (values.ip_address && !validatorIPV4(values.ip_address) && !validatorIPV6(values.ip_address)) {
        errors.ip_address = COPY_invalidIpAddress;
    }
    return errors;
}

export function checkDomainValidation(values, errors) {
    if (values.domain && !validatorDomainNameNoWWW(values.domain)) {
        errors['domain'] = COPY_invalidDomainNoWWW;
    }
    return errors;
}

export function checkImapCredentialValidations(values, errors) {
    if (Object.prototype.hasOwnProperty.call(values, 'imap_credentials')) {
        const imapNames = ['server', 'email', 'password'];
        const imapCredsArrayErrors = [];

        values.imap_credentials.forEach((imapCreds, credIndex) => {
            const imapCredErrors = {};

            imapNames.forEach((name) => {
                if (!Object.prototype.hasOwnProperty.call(imapCreds, name)) {
                    imapCredErrors[name] = 'Required';
                    imapCredsArrayErrors[credIndex] = imapCredErrors;
                }
            });

            // only validate email address if there's an email value present
            if (Object.prototype.hasOwnProperty.call(imapCreds, 'email') && !validatorEmailAddress(imapCreds.email)) {
                imapCredErrors.email = 'Invalid email address';
                imapCredsArrayErrors[credIndex] = imapCredErrors;
            }
        });

        errors.imap_credentials = imapCredsArrayErrors;
    }

    return errors;
}

/**
 * Check if the value is not in unsure or failed, that means it's correct
 *
 * serviceValidationData - Data object holding all the errors for the form validation
 *
 * name - The name of the field to check
 *
 * @param {{
 *      serviceValidationData: MigrationRequestFromServiceValidationData;
 *      name: string;
 *      support_submit_migration_details_check_service_data: unknown;
 * }} props
 */
export function validateMigrationDetails({ serviceValidationData, name, support_submit_migration_details_check_service_data }) {
    if (!serviceValidationData) return null;

    if (serviceValidationData.blank.has(name)) {
        return null;
    }

    if (!serviceValidationData?.unsure?.has(name) && !serviceValidationData?.failed?.has(name)) {
        return { valid: true, value: COPY_validationCorrect };
    }

    if (serviceValidationData?.unsure?.has(name)) {
        return { valid: false, value: validated_details_unsure?.[name] };
    }

    if (serviceValidationData?.failed?.has(name)) {
        return { valid: false, value: support_submit_migration_details_check_service_data?.failed?.[name] };
    }

    if (serviceValidationData.corrections.has(name)) {
        return { valid: true, value: COPY_validationCorrected };
    }

    return null;
}

/**
 * Find the hosting details and extract the domain
 * @returns {string}
 */
export function getHostingVpsDomain({ hosting_list_data, vps_list_data, selectedService }) {
    const possibleServices = [...(hosting_list_data || []), ...(vps_list_data || [])];
    const foundService = possibleServices.find((service_data) => service_data.id === selectedService);
    const domain = foundService ? foundService.attributes.domain : '';
    return domain;
}

/**
 * Returns the form state for the migration form details
 * @returns {Object}
 */
export function getFormValuesFromDetails() {
    const state = store.getState();
    return getFormValues(migrationFormNameDetails)(state);
}
