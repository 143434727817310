/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import { NXDropZone } from 'components/Form/NXDropZone';
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import { TextArea } from 'components/Form/TextArea';
import {
    RenderRadioGroup,
    RenderRevealPasswordField,
    RenderSelectField,
    getValidationFuncFromString,
    renderCheckboxField
} from '../../utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const FieldTypesEnum = /** @type {const} */ ({
    TEXTAREA: 'textarea',
    TIME: 'time',
    DATE: 'date',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    PASSWORD: 'password',
    RADIO_GROUP: 'radio_group',
    FILE: 'file',
    DROPZONE: 'dropzone',
    HIDDEN: 'hidden',
    RANGE: 'range',
    TEXT: 'text'
});

export function getFieldComponentAndClassName(fieldType) {
    const { TEXTAREA, TIME, DATE, CHECKBOX, SELECT, PASSWORD, RADIO_GROUP, FILE, DROPZONE, HIDDEN, RANGE, TEXT } = FieldTypesEnum;

    switch (fieldType) {
        case TEXTAREA:
            return {
                component: TextArea.ReduxForm.Default,
                className: 'form__textarea'
            };
        case TIME:
        case DATE:
            return {
                component: RenderDateTimePicker,
                className: 'form__date'
            };
        case CHECKBOX:
            return {
                component: renderCheckboxField,
                className: 'form__checkbox'
            };
        case SELECT:
            return {
                component: RenderSelectField,
                className: 'form__dropdown'
            };
        case PASSWORD:
            return {
                component: RenderRevealPasswordField,
                className: 'form__password'
            };
        case RADIO_GROUP:
            return {
                component: RenderRadioGroup,
                className: 'form__radio'
            };
        case FILE:
        case DROPZONE:
            return {
                component: NXDropZone.ReduxForm.WithNamedAttachments,
                className: 'form__textfield file'
            };
        case HIDDEN:
        case RANGE:
        case TEXT:
        default:
            return {
                component: Input.ReduxForm.Default,
                className: 'form__textfield'
            };
    }
}

/**
 * 
 * @param {*} fieldType 
 * @param {ReturnType<typeof getFieldComponentAndClassName>} componentOverride 
 */
export function getFieldComponentAndClassDynamicForm(fieldType, componentOverride) {
    if (componentOverride) {
        return {
            component: componentOverride,
            className: 'form__textfield'
        };
    }

    return getFieldComponentAndClassName(fieldType);
}

export function getInitialValues(formHasSteps, data) {
    if (!data) {
        return {};
    }

    const initialValues = {};
    // this only sets initial value of a field if it's not '', passing an empty string as initial value causes validation to happen before form is touched

    if (formHasSteps) {
        data.forEach((step) => {
            const { inputs } = step;
            inputs.forEach((input) => {
                if (input.type === 'repeatable') {
                    input.fields.forEach((repeatableInput) => {
                        if (repeatableInput.value !== '') {
                            initialValues[repeatableInput.name] = repeatableInput.value;
                        }
                    });
                } else {
                    if (input.value !== '') {
                        initialValues[input.name] = input.value;
                    }
                }
            });
        });
    } else {
        data.forEach((input) => {
            if (input.value !== '') {
                initialValues[input.name] = input.value;
            }
        });
    }

    return initialValues;
}

export function getFieldValidation(valArray) {
    let isConditionallyRequired;

    valArray.forEach((item) => {
        if (item.includes(':') && item.includes('required_')) {
            // validate function in form component file takes care of all validations now
            isConditionallyRequired = true;
        }
    });

    function mapConditionalRequired(str) {
        switch (str) {
            case 'ip':
                return getValidationFuncFromString('ip_long');
            default:
                return getValidationFuncFromString(str);
        }
    }

    return isConditionallyRequired ? [] : valArray.map(mapConditionalRequired).filter((item) => item);
}
