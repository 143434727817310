/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import IconButton from 'components/Buttons/IconButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Slider from 'components/Slider';

/*   ACTIONS
 *****************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PasswordGenerator.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

function PasswordGenerator({ min, max, copyToClipboard, excludedCharacters = [] }) {
    const characterArray = [`ABCDEFGHJKLMNPQRSTUVWXYZ`, `abcdefghjkmnpqrstuvwxy`, `~!@#%^&*,<.>/?_-=+`, `23456789`].map((item) =>
        item.split('').filter((char) => !excludedCharacters.includes(char))
    );

    /***** STATE *****/
    const [length, setLength] = useState(min + 2);
    const [generated, setGenerated] = useState(undefined);

    const characterLimitLabels = {
        0: min.toString(),
        100: max.toString()
    };

    /***** FUNCTIONS *****/
    function generatePassword() {
        let generated = '';
        let prevRow = undefined;

        const getArrLine = () => (arrLine = characterArray[Math.floor(Math.random() * 4)]);
        let arrLine;

        for (let i = 0; i < length; i++) {
            arrLine = getArrLine();
            if (prevRow === arrLine) arrLine = getArrLine();

            generated += arrLine[Math.floor(Math.random() * arrLine.length)];
            prevRow = arrLine;
        }

        setGenerated(generated);
    }

    function handleLengthChange(value) {
        let newValue = value;

        if (value === '0' || value === '100') {
            newValue = Number(characterLimitLabels[value]);
        }

        setLength(newValue);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        generatePassword();
    }, [length]);

    /***** RENDER *****/
    return (
        <div className="PasswordGenerator">
            <div className="PasswordGenerator__title">Password Generator</div>
            <div className="PasswordGenerator__container">
                <div className="PasswordGenerator__result">{generated ? generated : ''}</div>
                <IconButton
                    group={[
                        {
                            className: `PasswordGenerator__refresh`,
                            icon: <PhosphorIcons.ArrowsCounterClockwise size={24} />,
                            label: `Refresh`,
                            color: `secondary`,
                            onClick: generatePassword
                        },
                        {
                            className: `PasswordGenerator__copy`,
                            icon: <PhosphorIcons.Copy size={24} />,
                            label: `Copy to Clipboard`,
                            color: `info`,
                            onClick: () => {
                                copyToClipboard(generated, true);
                            }
                        }
                    ]}
                />
            </div>
            <div className="PasswordGenerator__settings">
                <div className="PasswordGenerator__setting-label">Length - {length} Characters</div>
                <Slider
                    className="PasswordGenerator__slider"
                    min={min}
                    max={max}
                    value={length}
                    onChange={handleLengthChange}
                    tooltip={false}
                    labels={characterLimitLabels}
                />
            </div>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PasswordGenerator.propTypes = {
    // The minimum length the password can be
    min: PropTypes.number,

    // The maximum length the password can be
    max: PropTypes.number
};

PasswordGenerator.defaultProps = {
    min: 10,
    max: 25
};

const mapDispatchToProps = { copyToClipboard };

export default connect(null, mapDispatchToProps)(PasswordGenerator);
