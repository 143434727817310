/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PrimaryForm from '../forms/primaryForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { updatePrimaryDomain } from '../state/accountActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useUrlDropdown from 'components/NXBox/Dropdown/hooks/useUrlDropdown';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// Note: This module currently doesn't get rendered if it's a reseller subaccount, but I'm just going to leave the subaccount functionality here just incase we change that in the future
/**
 *
 * @param {{
 *      hostingid: string
 *      subaccount: string
 * }} props
 */
function Primary({ hostingid, subaccount }) {
    /***** HOOKS *****/
    const hosting_primary_status = useSelector((state) => state.hosting.hosting_primary_status);
    const hosting_information_data = useSelector((state) => state.hosting.hosting_information_data);
    const hosting_information_subaccount_data = useSelector((state) => state.hosting.hosting_information_subaccount_data);
    const hosting_information_status = useSelector((state) => state.hosting.hosting_information_status);

    const history = useHistory();
    const dispatch = useDispatch();

    const { isDropdownOpen, openDropdown, closeDropdown } = useUrlDropdown(`/my-services/hosting/account/primary-domain/${hostingid}`);

    /***** FUNCTIONS *****/
    function submitPrimaryRequest(values) {
        const attributes = {
            primary_domain: values.newPrimaryDomain
        };

        dispatch(updatePrimaryDomain(hostingid, attributes, subaccount, history));
        closeDropdown();
    }

    /***** RENDER HELPERS *****/
    const { domain } = hosting_information_data?.attributes || {};

    /***** RENDER *****/
    return (
        <ScrollableComponent allowImmediateReady ready={hosting_information_status === 'success'}>
            <NXBox initialStatus="success" isLoading={hosting_primary_status === 'loading'}>
                <NXBox.ToolTip
                    info="Your Primary Domain Name is the key identifier of your Web Hosting Service. If you changed your domain name, you will also need to change your Primary Domain Name. 
                        <br><br>
                        Helpful Hints<br>

                        <li>If you are using cPanel to host your emails, your email addresses will automatically be changed to your new Domain Name.</li>  
                        <li>If you have an SSL installed, you will be required to reinstall it as it will no longer be valid.</li>"
                />
                <NXBox.Top
                    title="Primary Domain"
                    description="You can use this tool to change the primary domain name of this cPanel service. A common reason for doing so is if you wish to use the service with another domain name."
                />
                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={[
                            <NXBox.InfoPair
                                key={1}
                                title="Current Primary Domain Name"
                                description={subaccount ? hosting_information_subaccount_data.domain : domain}
                            />,
                            <OutlineButton
                                key={2}
                                type="onClick"
                                onClick={() => {
                                    if (isDropdownOpen) {
                                        closeDropdown();
                                    } else {
                                        openDropdown();
                                    }
                                }}
                            >
                                Change
                            </OutlineButton>
                        ]}
                    />
                </NXBox.Bottom>
                <NXBox.Dropdown isOpen={isDropdownOpen}>
                    <NXBox.DefaultPadding>
                        <NXBox.Dropdown.CloseButton onClose={closeDropdown} />
                        <Text size--l secondary bold lead--none>
                            <h4>Change Primary Domain</h4>
                        </Text>
                        <PrimaryForm
                            onSubmit={submitPrimaryRequest}
                            options=""
                            domain={subaccount ? hosting_information_subaccount_data.domain : domain}
                        />
                    </NXBox.DefaultPadding>
                </NXBox.Dropdown>
            </NXBox>
        </ScrollableComponent>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Primary;
