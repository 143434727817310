/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ThirdPartyPresetName } from 'containers/domain/forms/presets';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef { {
 *   add: {
 *     type: string,
 *     hostname?: string,
 *     content: string,
 *     ttl: string,
 *     priority: null | string
 *   }[],
 *   remove: {
 *     type: string,
 *     hostName?: string,
 *     content: string,
 *     ttl: string,
 *     priority: null | string
 *   }[],
 *   details: string
 * } | null } TData
 */

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const usePreviewPresetChanges = () => {
    const { status, data } = useSelector((/** @type {*} */ state) => ({
        /**
         * @type { 'success' | 'error' | 'loading' | null }
         */
        status: state.domain.domain_preview_preset_changes_status,

        /**
         * @type {TData}
         */
        data: state.domain.domain_preview_preset_changes_data
    }));

    return { status, data };
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the verification_code value from the redux form and safely infers the type - thirdPartyPreset
 */
export const useSquarespaceVerificationCode = () => {
    const { verification_code } = useSelector((/** @type {*} */ state) => {
        /** @type {*} */
        const values = getFormValues(ThirdPartyPresetName)?.(state) ?? {};

        return {
            verification_code: /** @type {string | undefined} */ (values.verification_code)
        };
    });

    return verification_code;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
