/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineTag from '../Tags/OutlineTag';
import Tooltip from '../Tooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const statusTagEnum = /** @type {const} */ ({
    ACTIVE: 'active',
    SUSPENDED: 'suspended',
    EXPIRING: 'expiring',
    EXPIRED: 'expired',
    COMPLETED: 'completed',
    PENDING: 'pending',
    PENDING_IDENTITY_VERIFICATION: 'pending identity verification',
    PENDING_CANCELLATION: 'pending cancellation',
    PENDING_TRANSFER: 'pending transfer',
    IN_PROGRESS: 'in progress',
    PRE_ORDERED: 'pre-ordered',
    REDEMPTION: 'redemption',
    ACTION_REQUIRED: 'action required',
    APPLICATION_EXPIRING: 'application expiring',
    LEGACY: 'legacy'
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StatusTag = ({ status, className, note, limitWidth }) => {
    if (status && typeof status === 'string') {
        let color;
        let newStatus = status;
        switch (status.toLowerCase()) {
            case statusTagEnum.ACTIVE:
                color = 'confirm';
                break;
            case statusTagEnum.SUSPENDED:
            case statusTagEnum.EXPIRING:
            case statusTagEnum.EXPIRED:
                color = 'warn';
                break;
            case statusTagEnum.COMPLETED:
            case statusTagEnum.PENDING:
                color = 'secondary';
                break;
            case statusTagEnum.PENDING_IDENTITY_VERIFICATION:
                color = 'secondary';
                if (limitWidth)
                    newStatus = (
                        <p style={{ margin: '0 -6px' }}>
                            pending identity
                            <br />
                            verification
                        </p>
                    );
                break;
            case statusTagEnum.PENDING_CANCELLATION:
                color = 'secondary';
                if (limitWidth)
                    newStatus = (
                        <p>
                            pending
                            <br />
                            cancellation
                        </p>
                    );
                break;
            case statusTagEnum.PENDING_TRANSFER:
                color = 'secondary';
                break;
            case statusTagEnum.IN_PROGRESS:
            case statusTagEnum.PRE_ORDERED:
                color = 'contention';
                break;
            case statusTagEnum.APPLICATION_EXPIRING:
                color = 'secondary';
                if (limitWidth)
                    newStatus = (
                        <p>
                            application
                            <br />
                            expiring
                        </p>
                    );
                break;
            case statusTagEnum.LEGACY:
                color = 'legacy';
                break;
            default:
                color = 'notice';
                break;
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return note ? (
            <div className={note.containerClassName}>
                {note.message && <Tooltip info={note.message} />}
                <OutlineTag className={className || ''} color={color}>
                    {newStatus || ''}
                </OutlineTag>
            </div>
        ) : (
            <OutlineTag className={className || ''} color={color}>
                {newStatus}
            </OutlineTag>
        );
    }
    return <></>;
};

StatusTag.propTypes = {
    /**
     * Allowed status as defined in `statusTagEnum`. Can be passed as uppercase as the StatusTag component converts to lowercase before comparing
     */
    status: PropTypes.oneOf(Object.values(statusTagEnum)),
    /**
     * Class name to be applied to the tag.
     */
    className: PropTypes.string,
    /**
     * If the StatusTag has a note/message for the user
     */
    note: PropTypes.shape({
        containerClassName: PropTypes.string,
        message: PropTypes.string
    }),
    /**
     * For particular statuses that are quite long, this will wrap the words onto 2 lines to limit the width of the tag
     */
    limitWidth: PropTypes.bool
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default StatusTag;
