/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { createContext } from 'react';
import type { Writable} from 'svelte/store';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';

export const RichTextEditorContext = createContext<Writable<RichTextEditorNamespace.ContextState>>(
    writable({
        isLinkEditMode: false,
        rootElement: null
    })
);

export function newRichTextEditorWritable() {
    return writable<RichTextEditorNamespace.ContextState>({
        isLinkEditMode: false,
        rootElement: null
    });
}

export const richTextFeatureEnum = {
    // UNDO: 'undo',
    // REDO: 'redo',
    NO_NEW_LINE: 'no_new_line',
    LINK: 'link',
    BOLD: 'bold',
    ITALIC: 'italic',

    NUMBERED_LIST: 'ol',
    UNORDERED_LIST: 'ul',

    MARKDOWN: 'markdown'

    // CLEAR: 'clear'
} as const;

export const richTextFeatures = _.values(richTextFeatureEnum);
