/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { random } from 'lodash';
import './_TextStyledLoader.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface TextStyledLoaderProps {
    height?: number;
    lead?: number;
    width?: number | string | 'random';
}

/**
 * Ideally you could let the same props be passed from `Text` component and it automatically determines the height and width based on the font size and lead
 */
const _TextStyledLoader: React.FC<TextStyledLoaderProps> = ({ height = 16, lead = 1.5, width = '100%' }) => {
    const widthRef = useRef(width === 'random' ? `${random(10, 100)}%` : width);
    return <div className="TextStyledLoader" style={{ height: height * lead, width: widthRef.current }} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default _TextStyledLoader;
