/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification/index';

/*   ACTIONS
 *****************************************************/
import { formatDescription } from 'utilities/methods/commonActions';
import { getEmailHostingRecoverFee, getEmailMailboxInformation, restoreAxigenMailbox } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { COPY_restoreMailboxConfirmDialogNotification } from './consts';

function emailRestoreRenderSwitch(email_restore_fee_status, email_restore_fee_data) {
    switch (email_restore_fee_status) {
        case 'loading':
            return <RequestLoader />;
        case 'error':
            return 'Something went wrong loading the restore fee.';
        case 'success':
            return `$${email_restore_fee_data?.fee}`;
        default:
            return '';
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function AxigenRestore(props) {
    // get email hosting single detail data
    const {
        domain,
        closeAxigenRestoreLightbox,
        serviceID,
        history,
        /**
         * Redux props
         */
        email_information_mailbox_data,
        email_information_mailbox_status,
        email_restore_fee_data,
        email_restore_fee_status,
        restoreAxigenMailbox
    } = props;

    /***** FUNCTIONS *****/
    function handleSubmit(e) {
        e.preventDefault();
        restoreAxigenMailbox(serviceID);
        closeAxigenRestoreLightbox();
    }

    /***** EFFECTS *****/
    useEffect(() => {
        const { dispatch } = store;
        if (email_restore_fee_status !== 'loading') getEmailHostingRecoverFee(serviceID);
        if (email_information_mailbox_status !== 'loading') getEmailMailboxInformation(serviceID)(dispatch);
    }, []);

    /***** RENDER *****/
    switch (email_information_mailbox_status) {
        case 'success':
            return (
                <div className="OverlayRestore">
                    <div className="OverlayRestore__wrapper">
                        <div className="OverlayRestore__domain">{domain}</div>

                        <div className="OverlayRestore__details">
                            <div className="OverlayRestore__items">
                                <div className="item__row">
                                    <div className="item__column--description">Email Hosting</div>
                                    <div className="item__column--price">
                                        ${email_information_mailbox_data.attributes.amount}/{email_information_mailbox_data.attributes.billing_cycle}
                                    </div>
                                </div>
                                <div className="item__row">
                                    <div className="item__column--description">
                                        <strong>{formatDescription(email_information_mailbox_data['included'][0].attributes.name)}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="OverlayRestore__items">
                                <div className="item__row">
                                    <div className="item__column--description">Recovery Fee</div>
                                    <div className="item__column--price">
                                        {emailRestoreRenderSwitch(email_restore_fee_status, email_restore_fee_data)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DialogNotification tail={true} type="warning">
                            {COPY_restoreMailboxConfirmDialogNotification}
                        </DialogNotification>

                        <SolidButton type="onClick" className="OverlayRestore__button" onClick={handleSubmit}>
                            Restore
                        </SolidButton>
                    </div>

                    <div></div>
                </div>
            );
        case 'error':
            return (
                <div className="OverlayRestore">
                    <div className="OverlayRestore__error">
                        {`We're unable to retrieve the requested mailbox details. Please try again later. If you believe there may be an issue and require assistance, feel free to submit a `}
                        <Anchor
                            className="support-link"
                            onClick={() => {
                                history.push(`/support/tickets/submit`);
                            }}
                        >
                            support ticket
                        </Anchor>
                    </div>
                </div>
            );

        case 'loading':
        default:
            return (
                <div className="OverlayRestore">
                    <RequestLoader />
                </div>
            );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        email_information_mailbox_data: state.email.email_information_mailbox_data,
        email_information_mailbox_status: state.email.email_information_mailbox_status,
        email_restore_fee_data: state.email.email_restore_fee_data,
        email_restore_fee_status: state.email.email_restore_fee_status
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            restoreAxigenMailbox
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AxigenRestore));
