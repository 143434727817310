/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, change, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import AutocompleteAddress from 'components/AutocompleteAddress';
import RenderPhoneField from 'components/Form/PhoneInput';
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { toLuxonDate } from 'utilities/methods/commonActions';
import { RenderField, RenderSelectField, peopleNameFieldValidation, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { terms_url } from 'config/config';
import './_collectMissingDetailsForm.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Personal details form for users to provide missing details
 */
let CollectMissingDetailsForm = ({
    handleSubmit,
    // Redux props
    formValues,
    change,
    pristine,
    valid,
    form,
    account_security_questions_data,
    account_security_information_data,
    app_user_data,
    account_provide_missing_details_status
}) => {
    /***** EFFECTS *****/
    useEffect(() => {
        // Change all fields on mount rather than setting initial values, so that all fields will be "touched" and errors will show up immediately
        const dob = toLuxonDate(account_security_information_data?.dob || '', 'dd/MM/yyyy');
        const dobFormValue = dob.isValid ? dob.toJSDate() : '';

        // Personal details
        change('firstname', app_user_data?.firstname || '');
        change('lastname', app_user_data?.lastname || '');
        change('company', app_user_data?.company || '');
        change('phone', app_user_data?.phone || '');
        change('address1', app_user_data?.address1 || '');
        change('address2', app_user_data?.address2 || '');
        change('city', app_user_data?.city || '');
        change('state', app_user_data?.state || '');
        change('country', app_user_data?.country || '');
        change('postcode', app_user_data?.postcode || '');

        // Security questions
        change('dob', dobFormValue);
        change('question_id', account_security_information_data?.question_id || '');
        change('answer', account_security_information_data?.answer || '');
    }, []);

    /***** RENDER HELPERS *****/
    const securityQuestions =
        account_security_questions_data?.map((q) => ({
            value: q.id,
            label: q.attributes.question
        })) || [];

    /***** RENDER *****/
    return (
        <div className="collectMissingDetailsForm">
            <form className="collectMissingDetailsForm__form" onSubmit={handleSubmit}>
                <Text className="collectMissingDetailsForm__heading" primary size--l semiBold>
                    <h4>Personal Details</h4>
                </Text>
                <div className="form__row">
                    <div className="form__column half">
                        <Field
                            label="First Name"
                            name="firstname"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation, peopleNameFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                    <div className="form__column half">
                        <Field
                            label="Last Name"
                            name="lastname"
                            component={RenderField}
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation, peopleNameFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column half">
                        <Field label="Company Name" name="company" component={RenderField} type="text" placeholder="" className="form__textfield" />
                    </div>
                    <div className="form__column half">
                        <Field
                            label="Phone"
                            name="phone"
                            component={RenderPhoneField}
                            required
                            countrySelectEnabled={false}
                            country={formValues?.country}
                            type="tel"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <AutocompleteAddress
                    form={form}
                    selectedCountry={formValues?.country}
                    selectedState={formValues?.state}
                    onlyAUandNZ={true}
                    halfWidthFields={true}
                    fieldOrder={['address1', 'address2', 'city', 'state', 'country', 'postcode']}
                />

                <Text className="collectMissingDetailsForm__heading" primary size--l semiBold>
                    <h4>Security Details</h4>
                </Text>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Security Question"
                            name="question_id"
                            component={RenderSelectField}
                            type="select"
                            validate={[requiredFieldValidation]}
                            className="form__dropdown"
                            options={securityQuestions}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column half">
                        <Field
                            component={RenderField}
                            label="Security Answer"
                            name="answer"
                            type="text"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                    <div className="form__column half">
                        <Field
                            component={RenderDateTimePicker}
                            label="Date of Birth"
                            name="dob"
                            dateFormat="d/M/yyyy"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <Text secondary size--s>
                        <p>
                            You agree to our{' '}
                            <Anchor target="_blank" href={`${terms_url}`}>
                                Terms, Policies and Agreements
                            </Anchor>{' '}
                            by updating your security details.
                        </p>
                    </Text>
                </div>

                {renderButton(
                    pristine,
                    account_provide_missing_details_status === 'loading',
                    valid,
                    account_provide_missing_details_status === 'loading' ? <RequestLoader /> : 'Submit'
                )}
            </form>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

CollectMissingDetailsForm = reduxForm({
    form: 'collectMissingDetailsForm',
    touchOnChange: true
})(CollectMissingDetailsForm);

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues('collectMissingDetailsForm')(state),
        account_security_questions_data: state.account.account_security_questions_data,
        account_security_information_data: state.account.account_security_information_data,
        app_user_data: state.app.app_user_data,
        account_provide_missing_details_status: state.account.account_provide_missing_details_status
    };
};

const mapDispatchToProps = { change };

CollectMissingDetailsForm = connect(mapStateToProps, mapDispatchToProps)(CollectMissingDetailsForm);

export default CollectMissingDetailsForm;
