/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SideBarChildMenu from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/sideBarChildMenu';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NSideBarChildMenu } from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/types';
import type { KatanaSetupEditorRouteParams } from 'containers/katana/types';

const { CONTACT_INFORMATION, BASIC_DETAILS } = katanaSubPageEnums;

const menuRestrictions: KatanaSetupEditorRouteParams['subpage'][] = [CONTACT_INFORMATION, BASIC_DETAILS];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BasicDetailsMenu = () => {
    const { childpage, subpage } = useSetupEditorRouteParams();

    const childMenuOptions: NSideBarChildMenu.Option[] = [
        {
            routeKey: BASIC_DETAILS,
            title: 'Website Information',
            identifier: subpage
        },
        {
            routeKey: CONTACT_INFORMATION,
            title: 'Contact Information',
            identifier: subpage
        }
    ];

    if (!childpage && !menuRestrictions.includes(subpage)) {
        return '';
    }

    return <SideBarChildMenu options={childMenuOptions} isLoading={false} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default BasicDetailsMenu;
