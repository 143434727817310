/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { validatorURLProtocol } from 'utilities/methods/validators';
import { migrationFormNameDetails, migrationFormNameFull } from '../consts';
import { checkDomainValidation, checkImapCredentialValidations, checkIpValidations, checkIsTechnical, checkRequiredFields } from '../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { otherDetailsRequiredFields } from './consts';

const otherAllRequiredFields = [
    ...otherDetailsRequiredFields,
    'delete_data',
    'email_reconfig',
    'migration_date',
    'migration_time',
    'incompatible_check'
];

/**
 * Validate specific type details for cpanel details
 */
function validateOtherDetailTypes(values, errors) {
    errors = checkIpValidations(values, errors);

    errors = checkDomainValidation(values, errors);

    if (values['sql_dump_url'] && !validatorURLProtocol(values['sql_dump_url'])) {
        errors['sql_dump_url'] = 'URL is invalid';
    }

    return errors;
}

/**
 * Validating the cpanel details
 * @param values
 * @return {*|{}}
 */
export const OtherDetailsValidation = (values) => {
    let errors = {};

    errors = validateOtherDetailTypes(values, errors);

    errors = checkRequiredFields(values, errors, otherDetailsRequiredFields);

    return errors;
};

/**
 * Validate all cpanel fields
 * @param values
 * @return {*|{}}
 */
export const OtherFormValidation = (values) => {
    let errors = {};

    errors = validateOtherDetailTypes(values, errors);

    errors = checkRequiredFields(values, errors, otherAllRequiredFields);

    errors = checkIsTechnical(values, errors);

    errors = checkImapCredentialValidations(values, errors);

    return errors;
};

export const MigrationReduxOtherFormPart1 = {
    form: migrationFormNameDetails,
    forceUnregisterOnUnmount: true,
    validate: OtherDetailsValidation
};

export const MigrationReduxOtherFormPart2 = {
    form: migrationFormNameFull,
    forceUnregisterOnUnmount: true,
    validate: OtherFormValidation,
    initialValues: {
        ssh_check: false
    }
};
