/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import GSuiteUserDetailsForm from '../forms/userDetails';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getGSuiteUserData, updateGSuiteUserData } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class GSuiteUserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: false
        };
        this.handleUserUpdate = this.handleUserUpdate.bind(this);
    }

    handleUserUpdate(values) {
        const { updateGSuiteUserData, gsuiteid, mailbox, gsuite_user_data_data } = this.props;

        const valuesToSend = {
            ...values,
            email_domain: gsuite_user_data_data?.attributes?.primary_email?.split('@')[1] || ''
        };

        updateGSuiteUserData(gsuiteid, mailbox, valuesToSend);
    }

    componentDidMount() {
        const { gsuite_service_data } = this.props;
        if (gsuite_service_data) {
            const { attributes } = gsuite_service_data;
            const { domain } = attributes;

            this.setState({
                domain
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { gsuite_user_data_status } = this.props;
        registerScrollEvents(this, gsuite_user_data_status === 'success' && prevProps.gsuite_user_data_status === 'loading');
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { gsuiteid, mailbox, gsuite_user_data_status, getGSuiteUserData } = this.props;
        const { domain } = this.state;
        const { handleUserUpdate } = this;

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="gsuiteUserDetails"
            >
                <Box
                    request={{
                        action: getGSuiteUserData,
                        args: [gsuiteid, mailbox],
                        status: gsuite_user_data_status
                    }}
                    className="gsuiteUserDetails__box"
                    title="User Details"
                    status="success"
                    custom={{
                        render: <GSuiteUserDetailsForm domain={domain} onSubmit={handleUserUpdate} />,
                        pos: 'bottom'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        gsuite_service_data: state.gsuite.gsuite_service_data,
        gsuite_user_data_data: state.gsuite.gsuite_user_data_data,
        gsuite_user_data_status: state.gsuite.gsuite_user_data_status
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGSuiteUserData,
            updateGSuiteUserData
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GSuiteUserDetails));
