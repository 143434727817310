/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NavLabelsField from 'containers/katana/containers/contentEditorModules/navigationLabels/navLabelsField';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePostNavigationItemsMutation } from 'containers/katana/queries/usePostNavigationItemsMutation';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { SECTION_IDS } = usePostNavigationItemsMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _NavigationLabelsContentModule = () => {
    /***** RENDER *****/
    return (
        <NXBox.TopAccordion title="Menu Labels" description="Enable your website visitors to navigate to different sections of your website.">
            <NXBox.DefaultPadding>
                <Field name={SECTION_IDS} component={NavLabelsField} />
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const NavigationLabelsContentModule = Object.assign(_NavigationLabelsContentModule, {
    formFields: { SECTION_IDS }
});

export default NavigationLabelsContentModule;
