/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Carousel from 'components/Carousel';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_vipRewardsMoreInfoRewardsCarousel.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsMoreInfoRewardsCarousel({ slides }) {
    /***** RENDER *****/
    return (
        <div className="vipRewardsMoreInfoRewardsCarousel">
            <Carousel slides={slides} />
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
VipRewardsMoreInfoRewardsCarousel.propTypes = {
    /**
     * Slides to pass to the carousel
     */
    slides: PropTypes.arrayOf(PropTypes.node)
};

export default VipRewardsMoreInfoRewardsCarousel;
