/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';
import Search from 'components/Search';
import Table from 'components/Table';
import OutlineTag from 'components/Tags/OutlineTag';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useMergeInvoicesMutation } from 'containers/billing/queries/invoice';
import { useGetMergeableInvoicesQuery } from 'containers/billing/queries/invoice/useGetMergeableInvoicesQuery';
import { usePreviewMergedInvoiceBoilerPlate, usePreviewMergedInvoiceQuery } from 'containers/billing/queries/invoice/usePreviewMergedInvoiceQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import Text from 'components/Utils/Text';
import { formatDescription, getCurrentDate, getDataFromSuccessResponse, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { COPY_backToInvoices, COPY_updatingInvoices } from '../consts';
import './_invoicesMerge.scss';
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let InvoicesMerge = (props) => {
    const { history } = props;

    /***** STATE *****/
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [showConfirmLightbox, setShowConfirmLightbox] = useState(false);
    const [showSuccessLightbox, setShowSuccessLightbox] = useState(false);
    const [currentSearchKeyword, setCurrentSearchKeyword] = useState('');

    /***** QUERIES *****/
    const { data: get_mergeable_invoices_data, isFetching: is_mergeble_invoices_fetching } = useGetMergeableInvoicesQuery();

    const searchFilteredList =
        (currentSearchKeyword
            ? get_mergeable_invoices_data?.filter(({ id }) => String(id).includes(currentSearchKeyword))
            : get_mergeable_invoices_data) ?? [];

    const mergeableInvoices = searchFilteredList.filter(({ attributes: { can_merge } }) => can_merge);

    const mergeableInvoicesNotSelected = mergeableInvoices.filter(({ id }) => !selectedInvoices.find(({ id: selectedID }) => id === selectedID));

    const ineligibleInvoices = searchFilteredList?.filter(({ attributes: { can_merge } }) => !can_merge);

    // Invoice Merge Query Data
    const invoiceMergePayload = {
        invoices: selectedInvoices?.map(({ id }) => {
            return {
                id
            };
        })
    };

    //Deconstruct data from the mergeInvoice response
    const {
        data: previewMergedInvoiceData,
        isLoading: isPreviewMergedInvoiceLoading,
        error: previewMergedInvoiceError
    } = usePreviewMergedInvoiceQuery(invoiceMergePayload, {
        enabled: selectedInvoices?.length > 1
    });

    const {
        mutate: mutateMergeInvoices,
        status: merge_invoices_status,
        data: merge_invoices_data
    } = useMergeInvoicesMutation({
        onSuccess: () => {
            setShowSuccessLightbox(true);
            setShowConfirmLightbox(false);
        },
        onError: () => {
            setShowConfirmLightbox(false);
            useGetMergeableInvoicesQuery.invalidate();
            usePreviewMergedInvoiceBoilerPlate.invalidate();
            setSelectedInvoices([]);
            pushNotification({ status: 400, details: 'Something went wrong, please contact support' });
        }
    });

    /***** FUNCTIONS *****/
    function closeLightbox() {
        setShowConfirmLightbox(false);
        setShowSuccessLightbox(false);
    }

    function addToSelection(item) {
        setSelectedInvoices([...selectedInvoices, item]);
    }

    function removeFromSelection(item) {
        setSelectedInvoices(selectedInvoices.filter((invoice) => invoice.id !== item.id));
    }

    /***** RENDER HELPERS *****/
    const renderSelectedInvoices = () => {
        if (!selectedInvoices?.length) return;

        function getSelectionString() {
            if (selectedInvoices.length === 1) return 'Select other invoice to merge';
            return `${selectedInvoices.length} invoices selected`;
        }
        return (
            <>
                <div className="invoicesMerge__selection--heading">
                    <div>{getSelectionString()}</div>
                    <button
                        className="invoicesMerge__selection--clear"
                        onClick={() => {
                            setSelectedInvoices([]);
                        }}
                    >
                        Clear all
                    </button>
                </div>
                <div className="invoicesMerge__list">
                    <div className="invoicesMerge__select">
                        {selectedInvoices.map((invoice) => {
                            const {
                                id,
                                attributes: { total, status }
                            } = invoice;

                            function removeInvoiceFromSelection() {
                                removeFromSelection(invoice);
                            }

                            return (
                                <IsDataUpdatingOverlay
                                    message={COPY_updatingInvoices}
                                    isDataUpdating={invoice?.isDataUpdating}
                                    fillHeight={30}
                                    height={20}
                                    key={id}
                                >
                                    <button className="invoicesMerge__item selected" onClick={removeInvoiceFromSelection}>
                                        <span className="checkbox" />
                                        <div className="invoicesMerge__item--info">
                                            <Anchor className="invoicesMerge__item--id" to={`/billing/invoice/${id}`}>
                                                #{id}
                                            </Anchor>
                                            <div className="invoicesMerge__item--total">${total}</div>
                                        </div>
                                        <OutlineTag className="secondary">{status.toUpperCase()}</OutlineTag>
                                    </button>
                                </IsDataUpdatingOverlay>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    };

    const renderEligibleUnselectedInvoices = () => {
        if (is_mergeble_invoices_fetching) {
            return <RequestLoader />;
        }
        return (
            <>
                {mergeableInvoicesNotSelected.length >= 1 && (
                    <div className="invoicesMerge__select eligible">
                        <div className="invoicesMerge__item heading">
                            <div className="invoicesMerge__item--info">Mergeable Invoices</div>
                        </div>
                        {mergeableInvoicesNotSelected
                            .sort((first, second) => first.id - second.id)
                            .map((invoice) => {
                                const {
                                    id,
                                    attributes: { total, status }
                                } = invoice;

                                return (
                                    <IsDataUpdatingOverlay
                                        message={COPY_updatingInvoices}
                                        isDataUpdating={invoice?.isDataUpdating}
                                        fillHeight={30}
                                        height={20}
                                        key={id}
                                    >
                                        <button
                                            className="invoicesMerge__item"
                                            onClick={() => {
                                                addToSelection(invoice);
                                            }}
                                        >
                                            <span className="checkbox" />
                                            <div className="invoicesMerge__item--info">
                                                <Anchor className="invoicesMerge__item--id" to={`/billing/invoice/${id}`}>
                                                    #{id}
                                                </Anchor>
                                                <div className="invoicesMerge__item--total">${total}</div>
                                            </div>
                                            <OutlineTag className="secondary">{status.toUpperCase()}</OutlineTag>
                                        </button>
                                    </IsDataUpdatingOverlay>
                                );
                            })}
                    </div>
                )}
            </>
        );
    };

    const renderEligibleInvoices = () => {
        return (
            <>
                <Text size--s secondary medium>
                    Select the other invoices you would link to merge below.
                </Text>
                <Search
                    slim
                    render={{
                        placeholder: 'Search by invoice number'
                    }}
                    functions={{
                        reset: setCurrentSearchKeyword
                    }}
                    helpers={{
                        keyword: setCurrentSearchKeyword
                    }}
                />
                {renderEligibleUnselectedInvoices()}
            </>
        );
    };

    const renderIneligibleInvoices = () => {
        return (
            <>
                {ineligibleInvoices.length >= 1 && (
                    <div className="invoicesMerge__select ineligible">
                        <div className="invoicesMerge__item">
                            <div className="invoicesMerge__item--info">Ineligible Invoices</div>
                        </div>
                        {ineligibleInvoices
                            .sort((first, second) => first.id - second.id)
                            .map((invoice) => {
                                const {
                                    id,
                                    attributes: { is_overdue, total, status }
                                } = invoice;

                                return (
                                    <IsDataUpdatingOverlay
                                        message={COPY_updatingInvoices}
                                        isDataUpdating={invoice?.isDataUpdating}
                                        fillHeight={30}
                                        height={20}
                                        key={id}
                                    >
                                        <div className="invoicesMerge__item">
                                            <PhosphorIcons.X />
                                            <div className="invoicesMerge__item--info">
                                                <Anchor className="invoicesMerge__item--id" to={`/billing/invoice/${id}`}>
                                                    #{id}
                                                </Anchor>
                                                <div className="invoicesMerge__item--total">${total}</div>
                                            </div>
                                            <OutlineTag className="secondary">{is_overdue ? 'OVERDUE' : status.toUpperCase()}</OutlineTag>
                                        </div>
                                    </IsDataUpdatingOverlay>
                                );
                            })}
                    </div>
                )}
            </>
        );
    };

    const renderInfo = () => {
        if (selectedInvoices?.length >= 2 && previewMergedInvoiceData) {
            const { new_due_date, new_amount_due, invoice_items } = previewMergedInvoiceData;

            const invoiceItemMatrix = invoice_items.map(({ description, amount, invoice_id }) => {
                return {
                    description: formatDescription(description),
                    invoice_id: <Anchor to={`/billing/invoice/${invoice_id}`}>#{invoice_id}</Anchor>,
                    amount: `$${amount} AUD`
                };
            });

            const subTotal = invoice_items.map(({ amount }) => parseFloat(amount)).reduce((result, item) => result + item, 0);

            return (
                <>
                    <div className="invoicesMerge__detail--heading">New invoice details</div>
                    <div className="invoicesMerge__info">
                        <div className="invoicesMerge__info--section">
                            <div className="info__title">Date Issued</div>
                            <div className="info__detail">{getCurrentDate().toFormat('dd MMM yyyy')}</div>
                        </div>
                        <div className="invoicesMerge__info--section">
                            <div className="info__title">
                                <span className="highlight">NEW</span> Due Date
                            </div>
                            <div className="info__detail">{toLuxonDate(new_due_date, 'yyyy-MM-dd').toFormat('dd MMM yyyy')}</div>
                        </div>
                        <div className="invoicesMerge__info--section">
                            <div className="info__title">
                                <span className="highlight">NEW</span> Amount Due
                            </div>
                            <div className="info__detail">${new_amount_due} AUD</div>
                        </div>
                    </div>
                    <div className="invoicesMerge__detail--subheading">New invoice breakdown</div>

                    <Table
                        className="invoicesMerge__table"
                        header={[
                            {
                                title: `Description`,
                                className: `desc`
                            },
                            {
                                title: `Original Invoice #`,
                                className: `original`
                            },
                            {
                                title: 'cost',
                                className: 'cost'
                            }
                        ]}
                        matrix={invoiceItemMatrix}
                        embedded={true}
                    />
                    <div className="accountInvoiceView__total">
                        <div className="total__row">
                            <div className="total__title">Sub Total</div>
                            <div className="total__value">${subTotal.toFixed(2)} AUD</div>
                        </div>
                        <div className="total__row">
                            <div className="total__title">Total Amount Due</div>
                            <div className="total__value highlight">${new_amount_due} AUD</div>
                        </div>
                    </div>
                    {selectedInvoices.length >= 2 ? (
                        <SolidButton
                            type="onClick"
                            onClick={() => {
                                setShowConfirmLightbox(true);
                            }}
                        >
                            Confirm Merge Invoices
                        </SolidButton>
                    ) : (
                        <InactiveButton>Confirm Merge Invoices</InactiveButton>
                    )}
                </>
            );
        }
    };

    const renderErrorMessage = (error) => {
        //the previewMergedInvoiceError message is at errors[0].details but FetchComponentError is looking for data.errors[0].details
        const transformedErrorMsg = {
            data: {
                errors: [
                    {
                        details: error?.errors[0].details
                    }
                ]
            }
        };

        return <FetchComponentError error={transformedErrorMsg} />;
    };

    const renderMergingInvoices = () => {
        const mergeInvoiceResult = () => {
            if (selectedInvoices?.length > 1 && isPreviewMergedInvoiceLoading) {
                return <RequestLoader message="Loading invoice" />;
            }
            if (previewMergedInvoiceError) {
                return renderErrorMessage(previewMergedInvoiceError);
            }
            return renderInfo();
        };

        return (
            <div className="invoicesMerge__detail">
                <div className="invoicesMerge__detail--description">
                    You can use this tool to merge renewal invoices of the same type that are currently unpaid. This process will create a new invoice
                    and disregard the invoices you have merged, using the due date of the earliest due invoice. On the left, select the invoices you
                    wish to merge. Below, you will see the preview of the new invoice.
                </div>
                <DialogNotification type="warning">
                    Please note it is not possible for new orders to be renewed with service renewals.
                    <br />
                    If the newly generated invoice is not paid by the new due date, all services on the invoice will be suspended.
                </DialogNotification>
                {mergeInvoiceResult()}
            </div>
        );
    };

    const renderSuccessMessage = () => {
        if (!merge_invoices_data) return;
        const { new_invoice_id } = getDataFromSuccessResponse(merge_invoices_data);

        return (
            <div className="invoicesMergingSuccess">
                <PhosphorIcons.CheckCircle.Fill confirm size={55} />
                <div className="invoicesMergingSuccess__heading">Invoices successfully merged</div>
                <div className="invoicesMergingSuccess__description">
                    <div>This changed has now taken effect.</div>
                    <div>Please take note of the invoice changes below.</div>
                </div>
                <div className="invoicesMergingSuccess__invoices">
                    <div className="invoicesMergingSuccess__old">
                        <div className="invoicesMergingSuccess__old--heading">Disregard old Invoices</div>
                        {selectedInvoices.map(({ id }) => {
                            return (
                                <div key={id} className="invoicesMergingSuccess__old--invoice">
                                    #{id}
                                </div>
                            );
                        })}
                        <div className="invoicesMergingSuccess__old--description">These invoices have now been cancelled</div>
                    </div>
                    <PhosphorIcons.Caret.Right />
                    <div className="invoicesMergingSuccess__new">
                        <div className="invoicesMergingSuccess__new--heading">NEW Invoice created</div>
                        <Anchor className="invoicesMergingSuccess__new--invoice" to={`/billing/invoice/${new_invoice_id}`}>
                            #{new_invoice_id}
                        </Anchor>
                        <div className="invoicesMergingSuccess__new--description">Your services with the new billing cycle</div>
                    </div>
                </div>
                <DialogNotification type="warning">
                    PLEASE NOTE: The due date of the service(s) will remain the same. The invoice will need to be paid by the newly specified due date
                    to prevent service(s) from experiencing downtime.
                </DialogNotification>
                <SolidButton className="invoicesMergingSuccess__button" to="/billing/invoices">
                    {COPY_backToInvoices}
                </SolidButton>
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <div className="invoicesMerge">
            <>
                <div className="invoicesMerge__selection">
                    {renderSelectedInvoices()}
                    {renderEligibleInvoices()}
                    {renderIneligibleInvoices()}
                </div>
                <Box
                    premounted={true}
                    title={{
                        title: `Merge Invoices`,
                        links: {
                            main: {
                                title: 'GO BACK',
                                onClick: (e) => {
                                    e.preventDefault();
                                    history.push('/billing/invoices');
                                }
                            }
                        }
                    }}
                    className="invoicesMerge__new"
                    custom={{
                        render: renderMergingInvoices(),
                        pos: 'bottom'
                    }}
                />
                {showConfirmLightbox && (
                    <OverlayLightbox
                        onOpen
                        title="Confirm Merge Invoices"
                        loading={merge_invoices_status}
                        confirm={{
                            desc: htmr(
                                `Please confirm below that you would like to continue with this action.
                                        Once this action has been performed it cannot be undone and the selected invoices will be merged into a single invoice.`
                            ),
                            buttonText: 'Confirm',
                            buttonAction: () => {
                                mutateMergeInvoices(invoiceMergePayload);
                            },
                            closeAction: closeLightbox,
                            loading: 'success'
                        }}
                        warningMsg="PLEASE NOTE: The due date of the service(s) merged into this invoice will remain the same. The new invoice will need to be paid by the newly specified due date to prevent your service(s) from experiencing downtime."
                        onClose={closeLightbox}
                    />
                )}
                {showSuccessLightbox && (
                    <OverlayLightbox onOpen onClose={closeLightbox}>
                        {renderSuccessMessage()}
                    </OverlayLightbox>
                )}
            </>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

InvoicesMerge = withRouter(InvoicesMerge);

export default InvoicesMerge;
