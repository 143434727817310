/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/CheckBoxList';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NXDropZoneWithImageControl from 'components/Form/NXDropZone/variants/NXDropZoneWithImageControl';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
const { FAVICON } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _FavIconContentModule = () => {
    /***** STATE *****/
    const [isCustomFavIconShowing, setIsCustomFavIconShowing] = useState(false);

    /***** HOOKS *****/
    const favIcon = useGetDynamicFormFieldValue(FAVICON);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!isCustomFavIconShowing && favIcon) {
            setIsCustomFavIconShowing(true);
        }
    }, [favIcon, isCustomFavIconShowing]);

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="Favicon"
            description="Favicons are miniature versions of your business logo, enhancing the professional appearance of your website and making it easily recognisable in bookmarks and browser tabs."
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <CheckBoxList>
                        <CheckBoxList.Item onChange={setIsCustomFavIconShowing} isChecked={isCustomFavIconShowing}>
                            Upload a custom favicon
                        </CheckBoxList.Item>
                    </CheckBoxList>
                    {isCustomFavIconShowing ? (
                        <Field
                            name={FAVICON}
                            label="Favicon Image"
                            type="dropzone"
                            component={NXDropZoneWithImageControl}
                            imageControlProps={{
                                size: 80
                            }}
                            validate={[requiredFieldValidation]}
                            singleFile
                        />
                    ) : (
                        ''
                    )}
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const FavIconContentModule = Object.assign(_FavIconContentModule, {
    formFields: { FAVICON }
});

export default FavIconContentModule;
