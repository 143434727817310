/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Page.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated use `SideBarPage` instead.
 * Container to hold the main content of a page (not including any top navigation)
 * Used to create our default page layout, providing the default padding.
 * Can be used with or without a sidebar.
 * At the moment the sidebar doesn't automatically get given 25% max width, as this is done by the sidebar component.
 */
function Page({ sidebar, children, className }) {
    /***** RENDER *****/
    return (
        <div className={classNames('Page', { [className]: className })}>
            {sidebar ? sidebar : ''}
            <div className={classNames('Page__mainContent', { 'Page__mainContent--fullWidth': !sidebar })}>{children}</div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

Page.propTypes = {
    /**
     * Extra className to give to the main div
     */
    className: PropTypes.string,

    /**
     * The sidebar navigation element (optional)
     */
    sidebar: PropTypes.node,

    /**
     * The main content of the page
     */
    children: PropTypes.node.isRequired
};

export default Page;
