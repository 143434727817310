/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change } from 'redux-form';
import htmr from 'htmr';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ValidateBeacon extends Component {
    componentDidMount() {
        const { url, url_secure, content, dispatch, form } = this.props;

        dispatch(change(form, 'url', url));
        dispatch(change(form, 'url_secure', url_secure));
        dispatch(change(form, 'content', content));
    }

    render() {
        const { url, url_secure, content, copyToClipboard } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="validateBeacon">
                <div className="validateBeacon__container">
                    <div className="validateBeacon__text">Please create a publically accessible file at the following location:</div>
                    <div className="validateBeacon__input">
                        <div className="validateBeacon__value">{url}</div>
                    </div>
                    <div className="validateBeacon__text--strong">OR</div>
                    <div className="validateBeacon__input">
                        <div className="validateBeacon__value">{url_secure}</div>
                    </div>
                </div>
                <div className="validateBeacon__container">
                    <div className="validateBeacon__text">The file must include the following text:</div>
                    <div className="validateBeacon__input">
                        <div className="validateBeacon__value beacon">{htmr(content)}</div>
                        <Anchor
                            className="validateBeacon__action"
                            onClick={(e) => {
                                e.preventDefault();
                                const beaconContent = document.createElement('div');
                                beaconContent.innerHTML = content;
                                document.body.appendChild(beaconContent);
                                copyToClipboard(beaconContent.textContent || beaconContent.innerText);
                                document.body.removeChild(beaconContent);
                            }}
                        >
                            Copy
                        </Anchor>
                    </div>
                </div>
                <div className="validateBeacon__text">
                    Once created, click the <strong>Validate Domain Name</strong> button below. Allow up to 15 minutes for DNS propogation.
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ValidateBeacon = reduxForm({
    form: 'validateBeacon'
})(ValidateBeacon);

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            copyToClipboard
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(ValidateBeacon);
