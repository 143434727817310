/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
enum CCPSessionType {
    WEB_TYPE = 0,
    APP_TYPE = 1
}

type AppCheckTokenData = NXUtils.ReplaceKey<Artah.CheckToken.GET._200['data']['attributes'], 'session_type', CCPSessionType>;
type CheckTokenState = {
    app?: {
        app_check_token_data: null | AppCheckTokenData;
    };
};
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useCheckTokenData = () =>
    useSelector((state: CheckTokenState) => state?.app?.app_check_token_data ?? ({} as Partial<AppCheckTokenData>));
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
