/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import QueueJumpForm from './queueJumpForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { getMigrationDates } from '../../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { migrationFormNameFull } from './consts';
const selectField = getFieldComponentAndClassName(FieldTypesEnum.SELECT);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DateTimeForm = (props) => {
    const { getMigrationDates, is_urgent, support_migration_migration_dates_status, support_migration_migration_dates_data } = props;

    useEffect(() => {
        getMigrationDates();
    }, []);

    function renderMigrationDataLoading() {
        if (is_urgent) return '';

        if (support_migration_migration_dates_status === 'success' && support_migration_migration_dates_data) {
            const { dates, times } = support_migration_migration_dates_data.attributes;

            const migrationDates = dates.map((date) => {
                return {
                    value: date,
                    label: date
                };
            });

            const migrationTimes = times.map((time) => {
                return {
                    value: time,
                    label: time
                };
            });

            return (
                <>
                    <Field
                        component={selectField.component}
                        label="Migration Date"
                        name="migration_date"
                        placeholder=""
                        type={FieldTypesEnum.SELECT}
                        className={selectField.className}
                        options={migrationDates}
                    />
                    <Field
                        component={selectField.component}
                        label="Migration Time"
                        name="migration_time"
                        placeholder=""
                        type={FieldTypesEnum.SELECT}
                        className={selectField.className}
                        options={migrationTimes}
                    />
                </>
            );
        }

        return <RequestLoader />;
    }

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <>
            <QueueJumpForm />
            {renderMigrationDataLoading()}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    const valueSelector = formValueSelector(migrationFormNameFull);
    return {
        is_urgent: valueSelector(state, 'is_urgent'),
        support_migration_migration_dates_data: state.support.support_migration_migration_dates_data,
        support_migration_migration_dates_status: state.support.support_migration_migration_dates_status
    };
};

const mapDispatchToProps = {
    getMigrationDates
};

DateTimeForm = connect(mapStateToProps, mapDispatchToProps)(DateTimeForm);

export default DateTimeForm;
