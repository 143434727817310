/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectOption, { selectOptionPropTypes } from './Option';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SelectOptions.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component is usually used in conjunction with the CustomDropdown component to handle rendering of the list of options
 */
function SelectOptions({ options }) {
    /***** RENDER *****/
    return (
        <ul className="SelectOptions">
            {options.map(({ label, value, isActive, onSelect }, index) => {
                return <SelectOption key={`${value}-${index}`} label={label} value={value} isActive={isActive} onSelect={onSelect} />;
            })}
        </ul>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SelectOptions.propTypes = {
    /**
     * The options to display
     */
    options: PropTypes.arrayOf(PropTypes.shape(selectOptionPropTypes))
};

export default SelectOptions;
