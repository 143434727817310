/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Redirect, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

/**********************************************************************************************************
 *   CONTAINERS
 **********************************************************************************************************/
import Login from 'containers/login';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_guest.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const GuestArea = ({ parent }) => {
    /**********************************************************************************************************
     *   CUSTOM STYLING
     **********************************************************************************************************/
    const animatedSwitchAnim = {
        atEnter: {
            opacity: 0
        },
        atLeave: {
            opacity: 0
        },
        atActive: {
            opacity: 1
        }
    };

    /***** RENDER HELPERS *****/
    const redirectFromRootToLogin = ({ location: { search } }) => {
        if (!search) return <Redirect to="/login" />;
        const urlParams = new URLSearchParams(search);
        const ref = urlParams.get('ref');
        const token = urlParams.get('token');
        const move_token = urlParams.get('move_token');

        function getSearchValue() {
            if (ref) return `?ref=${ref}`;
            if (token) return `?token=${token}`;
            if (move_token) return `?move_token=${move_token}`;
        }

        const to = {
            pathname: '/login',
            search: getSearchValue()
        };

        return <Redirect to={to} />;
    };

    const redirectFromForgotToLogin = {
        pathname: '/login',
        state: { forgot: true }
    };

    const redirectFromCatchallToLogin = ({ location: { pathname, search } }) => {
        const loginRef = pathname.includes('general/close') ? '' : `?ref=${pathname}${search}`;

        return <Redirect to={`/login${loginRef}`} />;
    };

    /*   RENDER COMPONENT
     **********************************************************************************************************/

    return (
        <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_CLIENT_KEY || ''}>
            <AnimatedSwitch {...animatedSwitchAnim} className="animatedSwitch__switchTransition">
                {/********************* REDIRECT *********************/}
                <Redirect exact from="/reset-password" to="/login" />
                <Redirect exact from="/forgot" to={redirectFromForgotToLogin} />
                <Route exact path="/" render={redirectFromRootToLogin} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/reset-password/:token" component={Login} />

                {/* Catch all */}
                <Route path="**" render={redirectFromCatchallToLogin} />
            </AnimatedSwitch>
        </GoogleReCaptchaProvider>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default GuestArea;
