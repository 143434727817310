/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderLargeListRadioButtons } from 'components/Form/RenderLargeListRadioButtons';
import { RenderSelectField, renderCheckboxField, requiredAcceptedValidation, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { booleanValidation, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const additionalServicesText =
    'PLEASE NOTE: Our systems indicate that this service currently has an outstanding invoice that also contains additional services. By changing your billing cycle on this service, your original invoice will be cancelled and a new invoice(s) will be created in its place. Your new invoice number(s) will be available once you have confirmed below.';

const invoiceText =
    "PLEASE NOTE: Our system has detected an outstanding invoice for this service. By changing your billing cycle the outstanding invoice will be cancelled and a replacement invoice with your desired billing cycle will take it's place once you confirm below.";

const acknowledgementText =
    'I understand that my original invoice will be cancelled and split into one or more invoices containing my newly requested billing cycle for this service and any remaining outstanding services from the original invoice.';

const constants = {
    additionalServicesText,
    invoiceText,
    acknowledgementText,
    form: {
        name: 'emailBillingCycleForm'
    }
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BillingCycle = ({
    /**
     * Redux props
     */
    email_information_mailbox_data,
    email_billingcycle_data,
    newBillingCycle,
    handleSubmit
}) => {
    /***** RENDER HELPERS *****/
    const invoice = getIncludedObjBasedOnType(email_information_mailbox_data?.included, 'invoice');

    const options = (email_billingcycle_data?.attributes.billing_cycles || []).map(({ amount, name }) => {
        return {
            label: `${name?.name} - $${amount} AUD`,
            value: name
        };
    });

    const renderNewBillingCycleField = () => (
        <div className="form__row">
            <div className="form__column full">
                <Field
                    label="Select New Billing Cycle"
                    name="newBillingCycle"
                    component={RenderSelectField}
                    validate={[requiredFieldValidation]}
                    type="select"
                    options={options}
                />
            </div>
        </div>
    );

    const renderMultipleServices = () => {
        const invoice_items = invoice.attributes.invoice_items;

        const currentService = invoice_items.find(({ service_id }) => service_id === email_information_mailbox_data?.id);

        const listItems = invoice_items
            .filter((item) =>
                email_billingcycle_data?.attributes.other_services
                    .filter(({ available_billing_cycle_ids }) => available_billing_cycle_ids.includes(newBillingCycle.id))
                    .map(({ id }) => id)
                    .includes(item.service_id)
            )
            .map(({ description }) => description);

        const disabledItems = invoice_items
            .filter((item) =>
                email_billingcycle_data?.attributes.other_services
                    .filter(({ available_billing_cycle_ids }) => !available_billing_cycle_ids.includes(newBillingCycle.id))
                    .map(({ id }) => id)
                    .includes(item.service_id)
            )
            .map(({ description }) => description);

        const list = [
            {
                label: 'Apply this billing cycle change to this service only',
                listItems: currentService.description,
                value: false
            },
            {
                label: 'Apply this billing cycle change to all other services on the same invoice too',
                listItems: listItems,
                value: true
            }
        ];

        return (
            <>
                {renderNewBillingCycleField()}
                <Field
                    component={RenderLargeListRadioButtons}
                    itemlist={list}
                    disabledItemList={disabledItems}
                    validate={[booleanValidation, requiredFieldValidation]}
                    name="other"
                />
                {currentService.status === 'unpaid' ? <DialogNotification type="warning">{constants.additionalServicesText}</DialogNotification> : ''}
                <AcknowledgementBox title="Acknowledgements">
                    <div>
                        <Field
                            name="acknowledgements"
                            label={constants.acknowledgementText}
                            component={renderCheckboxField}
                            validate={[requiredAcceptedValidation]}
                            type="checkbox"
                        />
                    </div>
                </AcknowledgementBox>
            </>
        );
    };

    const renderOneService = () => {
        return (
            <>
                {invoice ? <DialogNotification type="warning">{constants.invoiceText}</DialogNotification> : ''}
                {renderNewBillingCycleField()}
            </>
        );
    };

    /***** RENDER *****/
    return (
        <form onSubmit={handleSubmit}>
            {invoice && invoice.attributes.invoice_items.length > 1 && newBillingCycle ? renderMultipleServices() : renderOneService()}
            <ReduxFormButton form={constants.form.name}>Confirm Billing Cycle</ReduxFormButton>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const BillingCycleForm = reduxForm({
    form: constants.form.name,
    initialValues: {
        newBillingCycle: null
    }
})(BillingCycle);

const mapStateToProps = (state) => {
    const selector = formValueSelector('emailBillingCycleForm');
    const newBillingCycle = selector(state, 'newBillingCycle');

    return {
        email_information_mailbox_data: state.email.email_information_mailbox_data,
        email_billingcycle_data: state.email.email_billingcycle_data,
        newBillingCycle
    };
};

export default connect(mapStateToProps)(BillingCycleForm);
