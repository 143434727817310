/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_changeResourcesFormAllocations.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Table to show the current and new resource allocations, along with billing cycle data
 */
let ChangeResourceFormAllocations = ({ rows, /* Redux props */ app_viewport }) => {
    const newRows = [
        ...rows.slice(0, 4),
        {
            icon: null,
            name: 'divider',
            currentValue: null,
            newValue: null
        },
        ...rows.slice(4)
    ];

    /***** RENDER HELPERS *****/
    const renderNamesColumn = () => (
        <div className="changeResourcesFormAllocations__names">
            <div className="changeResourcesFormAllocations__heading" />
            {newRows.map(({ icon, name }, index) =>
                name === 'divider' ? (
                    <div key={`${name}-${index}`} className="changeResourcesFormAllocations__divider" />
                ) : (
                    <div key={`${name}-${index}`} className="changeResourcesFormAllocations__data">
                        {['xs'].includes(app_viewport) ? '' : icon}
                        {name}
                    </div>
                )
            )}
        </div>
    );

    const renderCurrentColumn = () => (
        <div className="changeResourcesFormAllocations__currentValues">
            <div className="changeResourcesFormAllocations__heading">Current</div>
            {newRows.map(({ currentValue, name }, index) =>
                name === 'divider' ? (
                    <div key={`${name}-${index}`} className="changeResourcesFormAllocations__divider" />
                ) : (
                    <div key={`${name}-${index}`} className="changeResourcesFormAllocations__data">
                        {currentValue}
                    </div>
                )
            )}
        </div>
    );

    const renderNewColumn = () => (
        <div className="changeResourcesFormAllocations__newValues">
            <div className="changeResourcesFormAllocations__heading">New</div>
            {newRows.map(({ newValue, name }, index) =>
                name === 'divider' ? (
                    <div key={`${name}-${index}`} className="changeResourcesFormAllocations__divider" />
                ) : (
                    <div key={`${name}-${index}`} className="changeResourcesFormAllocations__data">
                        {newValue}
                    </div>
                )
            )}
        </div>
    );

    /***** RENDER *****/
    return (
        <div className="changeResourcesFormAllocations">
            {renderNamesColumn()}
            {renderCurrentColumn()}
            {renderNewColumn()}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ChangeResourceFormAllocations.propTypes = {
    // Rows to include in the allocations table
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            // Icon to display next to the name
            icon: PropTypes.node,
            // Name of the data being displayed
            name: PropTypes.string,
            // Current value of the data
            currentValue: PropTypes.string,
            // New (proposed) value of the data based on sliders
            newValue: PropTypes.string
        })
    )
};

const mapStateToProps = (state) => ({
    app_viewport: state.app.app_viewport
});

ChangeResourceFormAllocations = connect(mapStateToProps)(ChangeResourceFormAllocations);

export default ChangeResourceFormAllocations;
