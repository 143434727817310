/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { bpay_details } from 'config/config';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import BPAYImage from 'assets/images/bpay.png';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let BPAYMethodInfo = ({ app_user_data }) => {
    /***** RENDER *****/
    return (
        <div key="bpay" className="tab__item bpay tab__item--bpay">
            <div className="availablePaymentMethod__bpay">
                <img className="availablePaymentMethod__bpay--image" src={BPAYImage} alt="bpay" />
                <div className="table">
                    <div className="table__row">
                        <div className="table__column table__column--label">Biller Code</div>
                        <div className="table__column table__column--value">{bpay_details.biller_code}</div>
                    </div>
                    <div className="table__row">
                        <div className="table__column table__column--label">Reference Number</div>
                        <div className="table__column table__column--value">{app_user_data.account_number}</div>
                    </div>
                </div>
            </div>
            <div className="availablePaymentMethod__description bpay__description">
                <strong>PLEASE NOTE:</strong> BPAY payments do take a number of days to clear. Consider BPAY payments as a standard bank transfer with
                1-2 business days required for processing; this can cause delays over weekends and during public holiday periods.
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        app_user_data: state.app.app_user_data
    };
};

BPAYMethodInfo = connect(mapStateToProps)(BPAYMethodInfo);

export default BPAYMethodInfo;
