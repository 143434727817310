/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import Logo from 'assets/images/vip_logo_white.svg';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ScamWarningBackground from './background';
import ScamWarningMessage from './message';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function ScamWarning() {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="scamWarning">
            <ScamWarningBackground />
            <img className="scamWarning__logo" src={Logo} alt="VentraIP Australia" />
            <div className="scamWarning__content">
                <ScamWarningMessage />
            </div>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default ScamWarning;
