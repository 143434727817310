/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';
import './_Hr.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      grey?: boolean,
 *      primary?: boolean,
 *      secondary?: boolean,
 *      confirm?: boolean,
 *      warn?: boolean,
 *      info?: boolean,
 *      notice?: boolean,
 *      inactive?: boolean,
 *      white?: boolean,
 *      black?: boolean
 * }>}
 */
export const Hr = (props) => (
    <hr
        className={classNames(
            'Hr',
            createAppliedStylingClasses({
                props,
                componentName: 'Hr',
                delimiter: '--'
            })
        )}
    />
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
