/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormLabel } from 'components/Form/FormLabel';
import { Input } from 'components/Form/Input';
import Grid from 'components/Grid';
import Tooltip from 'components/Tooltip';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import {
    RenderRadioGroup,
    RenderSelectField,
    domainFieldValidation,
    generateSubdomainFieldValidation,
    renderButton,
    renderCheckboxField,
    requiredFieldValidation
} from 'utilities/methods/form';
import { validatorDomainHasSubdomain } from 'utilities/methods/validators';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_webForwarder.scss';
import { forwarderLightboxModes } from './consts';
export const SUB_DOMAIN = 'sub_domain';
export const WHOLE_DOMAIN = 'whole_domain';

const destination_protocol = {
    HTTPS: 'https://',
    HTTP: 'http://'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let WebForwarder = ({
    mode,
    closeLightbox,
    /** Redux Props */
    app_viewport,
    domain_information_data,
    forwarder_type,
    handleSubmit,
    host_directory,
    pristine,
    sub_or_whole_domain,
    submitting,
    valid,
    wildcard_source
}) => {
    const { dispatch } = store;
    const domain = domain_information_data?.attributes?.domain;

    /***** HOOKS *****/
    const prevWildcardRef = useRef();
    const subdomainFieldValidation = useMemo(() => generateSubdomainFieldValidation(domain), [domain]);

    /***** EFFECTS *****/
    useEffect(() => {
        if (host_directory?.includes('*') && !wildcard_source && !prevWildcardRef.current) {
            dispatch(change('webForwarderForm', 'wildcard_source', true));
        }
        prevWildcardRef.current = wildcard_source;
    }, [wildcard_source, host_directory]);

    /***** RENDER HELPERS *****/
    function renderHostnameFields() {
        // Mobile Fields
        if (['sm', 'xs'].includes(app_viewport))
            return (
                <>
                    {sub_or_whole_domain === SUB_DOMAIN ? (
                        <>
                            <div className="form__row">
                                <div className="form__column">
                                    <Field
                                        label="Subdomain"
                                        placeholder="Enter subdomain"
                                        name="sub_domain"
                                        component={Input.ReduxForm.Default}
                                        type="text"
                                        className="form__textfield"
                                        validate={[requiredFieldValidation, subdomainFieldValidation]}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    <div className="form__row">
                        <div className="form__column">
                            <Field
                                label="Domain"
                                name="hostname"
                                component={Input.ReduxForm.Default}
                                readOnly={true}
                                type="text"
                                placeholder={domain}
                                className="form__textfield"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column">
                            <Field
                                label="Directory (if applicable)"
                                name="host_directory"
                                placeholder="Leave blank if not applicable"
                                component={Input.ReduxForm.Default}
                                readOnly={wildcard_source}
                                type="text"
                                className="form__textfield"
                            />
                        </div>
                    </div>
                </>
            );

        // Desktop Fields
        return (
            <div className="form__row">
                {sub_or_whole_domain === SUB_DOMAIN ? (
                    <div className="form__column">
                        <FormLabel htmlFor="hostname">Source Address</FormLabel>
                        <Grid columns="1fr auto 1fr auto 1fr" gap={1} alignItems--start justify-children--stretch>
                            <Field
                                placeholder="Enter subdomain"
                                name="sub_domain"
                                component={Input.ReduxForm.Default}
                                type="text"
                                className="form__textfield"
                                validate={[requiredFieldValidation, subdomainFieldValidation]}
                            />
                            <Text size--l secondary lead--1>
                                <p className="form__column--dot">.</p>
                            </Text>
                            <Field
                                name="hostname"
                                component={Input.ReduxForm.Default}
                                readOnly={true}
                                type="text"
                                placeholder={domain}
                                className="form__textfield"
                            />
                            <Text size--l secondary>
                                <p className="form__column--slash">/</p>
                            </Text>
                            <Field
                                name="host_directory"
                                placeholder="Directory (if applicable)"
                                component={Input.ReduxForm.Default}
                                readOnly={wildcard_source}
                                type="text"
                                className="form__textfield"
                            />
                        </Grid>
                    </div>
                ) : (
                    ''
                )}

                {sub_or_whole_domain === WHOLE_DOMAIN ? (
                    <div className="form__column">
                        <FormLabel htmlFor="hostname">Source Address</FormLabel>
                        <Grid columns="1fr auto 1fr" gap={1} alignItems--end justify-children--stretch>
                            <Field
                                name="hostname"
                                component={Input.ReduxForm.Default}
                                readOnly={true}
                                type="text"
                                placeholder={domain}
                                className="form__textfield"
                            />
                            <Text size--l secondary>
                                <p className="form__column--slash">/</p>
                            </Text>
                            <Field
                                name="host_directory"
                                placeholder="Leave blank if not applicable"
                                component={Input.ReduxForm.Default}
                                readOnly={wildcard_source}
                                type="text"
                                className="form__textfield"
                            />
                        </Grid>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }

    /***** RENDER *****/
    return (
        <form className="addRecord__form addRecord__form--webForwarder" onSubmit={handleSubmit}>
            <div className="wrapper">
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            label="Record Type"
                            name="forwarder_type"
                            component={RenderSelectField}
                            type="select"
                            validate={[requiredFieldValidation]}
                            className="form__dropdown"
                            options={[
                                {
                                    label: 'Cloak',
                                    value: 'cloak'
                                },
                                {
                                    label: 'HTML Redirect',
                                    value: 'html_redirect'
                                },
                                {
                                    label: '301 Redirect Permanent',
                                    value: 'permanent_redirect'
                                },
                                {
                                    label: '302 Redirect Temporary',
                                    value: 'temporary_redirect'
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column full">
                        <Field
                            name="sub_or_whole_domain"
                            className="webForwarder__radioGroup--subOrWholeDomain"
                            component={RenderRadioGroup}
                            validate={[requiredFieldValidation]}
                            options={[
                                {
                                    label: (
                                        <>
                                            <Text semiBold size--s>
                                                Forward my whole domain name
                                            </Text>
                                            {['sm', 'xs'].includes(app_viewport) ? '' : <>&nbsp;</>}
                                            <Text italic size--s>
                                                (e.g. domain.com.au)
                                            </Text>
                                        </>
                                    ),
                                    value: WHOLE_DOMAIN
                                },
                                {
                                    label: (
                                        <>
                                            <Text semiBold size--s>
                                                Forward a subdomain
                                            </Text>
                                            {['sm', 'xs'].includes(app_viewport) ? '' : <>&nbsp;</>}
                                            <Text italic size--s>
                                                (e.g. subdomain.domain.com.au)
                                            </Text>
                                        </>
                                    ),
                                    value: SUB_DOMAIN
                                }
                            ]}
                        />
                    </div>
                </div>

                {renderHostnameFields()}

                {sub_or_whole_domain ? (
                    <div className="form__row form__row--alignEnd">
                        <Field
                            name="wildcard_source"
                            label="Wildcard redirect"
                            component={renderCheckboxField}
                            type="checkbox"
                            noBackground
                            className="form__textfield"
                        />
                        <Tooltip
                            info="Forward all URLs after the forward-slash from the source domain to the destination domain, preserving or modifying the slug."
                            iconOverride={<i className="icon icon-help-circle" />}
                        />
                    </div>
                ) : (
                    ''
                )}
                <div className="form__row">
                    <div className="form__column full select__prepend">
                        <Field
                            label="Destination Address *"
                            name="destination_protocol"
                            component={RenderSelectField}
                            validate={[requiredFieldValidation]}
                            type="select"
                            className="form__dropdown"
                            options={[
                                {
                                    label: destination_protocol.HTTPS,
                                    value: destination_protocol.HTTPS
                                },
                                {
                                    label: destination_protocol.HTTP,
                                    value: destination_protocol.HTTP
                                }
                            ]}
                        />
                        <Field
                            name="destination"
                            label="Destination"
                            component={Input.ReduxForm.Default}
                            validate={[requiredFieldValidation, domainFieldValidation]}
                            type="text"
                            placeholder=""
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row form__row--alignEnd">
                    <Field
                        name="retain_path"
                        label="Retain source path"
                        component={renderCheckboxField}
                        type="checkbox"
                        noBackground
                        className="form__textfield"
                    />
                </div>
                {(forwarder_type === 'cloak' || forwarder_type === 'html_redirect') && (
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Page Title"
                                name="page_title"
                                component={Input.ReduxForm.Default}
                                type="text"
                                placeholder=""
                                className="form__textfield"
                            />
                        </div>
                    </div>
                )}
                {['cloak', 'html_redirect'].includes(forwarder_type) && (
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Meta Description"
                                name="meta_description"
                                component={Input.ReduxForm.Default}
                                type="text"
                                placeholder=""
                                className="form__textfield"
                            />
                        </div>
                    </div>
                )}
                {(forwarder_type === 'cloak' || forwarder_type === 'html_redirect') && (
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Meta Keywords"
                                name="meta_keywords"
                                component={Input.ReduxForm.Default}
                                type="text"
                                placeholder=""
                                className="form__textfield"
                            />
                        </div>
                    </div>
                )}
                {forwarder_type === 'html_redirect' && (
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Redirect Message"
                                name="redirect_message"
                                component={Input.ReduxForm.Default}
                                type="text"
                                placeholder=""
                                className="form__textfield"
                            />
                        </div>
                    </div>
                )}
                {forwarder_type === 'html_redirect' && (
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Redirect Seconds"
                                name="refresh_second"
                                component={Input.ReduxForm.Default}
                                type="text"
                                placeholder=""
                                className="form__textfield"
                            />
                        </div>
                    </div>
                )}
                <div className="form__row">
                    <div className="form__column full">
                        {renderButton(pristine, submitting, valid, `${mode === 'add' ? 'Add' : 'Save'} Web forwarder`)}
                    </div>
                </div>
                <div className="addRecord__viewToggle--tooltip">
                    <button className="sharedBox__viewToggle" onClick={closeLightbox}>
                        <i className="icon icon-x" />
                    </button>
                </div>
            </div>
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
WebForwarder.propTypes = {
    /** The mode of the forwarder. Add or edit. */
    mode: PropTypes.oneOf(forwarderLightboxModes),

    /** ID of the record you're modifying */
    recordID: PropTypes.oneOf([PropTypes.number, null]),

    /** The function that closes the lightbox. */
    closeLightbox: PropTypes.func
};

WebForwarder = reduxForm({
    form: 'webForwarderForm',
    enableReinitialize: true
})(WebForwarder);

const mapStateToProps = (state, { mode, recordID }) => {
    const selector = formValueSelector('webForwarderForm');

    const forwarder_type = selector(state, 'forwarder_type');
    const sub_or_whole_domain = selector(state, 'sub_or_whole_domain');
    const host_directory = selector(state, 'host_directory');
    const wildcard_source = selector(state, 'wildcard_source');

    const domain_web_record_store = state.domain.domain_web_record_store;
    const domain = /** @type {string} */ (state.domain.domain_information_data?.attributes?.domain);

    const initialValues = {
        hostname: domain,
        host_directory: ''
    };

    if (mode === 'edit') {
        const selectedRecord = domain_web_record_store.find((record) => record.id === recordID)?.attributes;

        const { hostname, destination } = selectedRecord;

        // Some subdomain magic.
        /**
         * Check wether a domain is a subdomain or not
         */
        const plainHostName = hostname.split('/')[0];
        initialValues.hostname = domain;
        const hasSubDomain = validatorDomainHasSubdomain(plainHostName);
        initialValues.sub_or_whole_domain = hasSubDomain ? SUB_DOMAIN : WHOLE_DOMAIN;

        /**
         * Check for subdomain
         */
        if (hasSubDomain) {
            const replaceDomainRegex = new RegExp(`^(.*).${domain}$`);
            const subDomain = plainHostName.replace(replaceDomainRegex, '$1');
            initialValues.sub_domain = subDomain;
            initialValues.hostname = initialValues.hostname.replace(`${subDomain}.`, '');
        }

        /**
         * Split out host directory for easy display
         */
        const hostDirectory = hostname.split(domain)[1];
        if (hostDirectory) {
            initialValues.host_directory = hostDirectory.replace('/', '');
        }

        /**
         * Set initial values for destination protocol
         */
        if (destination.includes(destination_protocol.HTTPS)) {
            initialValues.destination_protocol = destination_protocol.HTTPS;
            initialValues.destination = destination.replace(destination_protocol.HTTPS, '');
        } else if (destination.includes(destination_protocol.HTTP)) {
            initialValues.destination_protocol = destination_protocol.HTTP;

            initialValues.destination = destination.replace(destination_protocol.HTTP, '');
        }

        /** Set remaining initial values */
        Object.entries(selectedRecord).forEach(([key, value]) => {
            if (!has(initialValues, key)) {
                initialValues[key] = value;
            }
        });
    }

    return {
        initialValues,
        domain_information_data: state.domain.domain_information_data,
        app_viewport: state.app.app_viewport,
        forwarder_type,
        sub_or_whole_domain,
        host_directory,
        wildcard_source
    };
};
WebForwarder = connect(mapStateToProps)(WebForwarder);

export default WebForwarder;
