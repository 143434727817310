/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EppShowCode from 'containers/domain/modules/eppCode/showCode';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import DialogNotification from 'components/Notifications/DialogNotification';

/*   ACTIONS
 *****************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getEppCode } from '../../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EppCode extends Component {
    constructor(props) {
        super(props);
        this.eppCode = React.createRef();

        this.state = {
            epp: null,
            lockStatus: false
        };
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps) {
        const { domain_lock_check_status, domain_lock_check_data, domain_epp_status, domain_epp_data } = this.props;
        registerScrollEvents(this, domain_epp_status === 'success' && prevProps.domain_epp_status === 'loading');

        if (domain_epp_status === 'success' && prevProps.domain_epp_status === 'loading') {
            this.setState({
                epp: domain_epp_data.attributes.epp
            });
        }

        if (domain_lock_check_status === 'success' && prevProps.domain_lock_check_status === 'loading') {
            const { attributes } = domain_lock_check_data;
            const { transfer_is_locked } = attributes;

            this.setState({
                lockStatus: transfer_is_locked
            });
        }
    }

    render() {
        const { domainid, getEppCode, copyToClipboard, domain_epp_status } = this.props;
        const { epp, lockStatus } = this.state;

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {
            bottom: true,
            columns: [
                {
                    render: <EppShowCode eppCode={epp} />
                }
            ],
            action: {
                label: 'Copy to Clipboard',
                type: 'onClick',
                className: '',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    copyToClipboard(epp);
                }
            }
        };

        if (lockStatus) {
            conditionalProps = {
                ...conditionalProps,
                custom: {
                    render: (
                        <DialogNotification type="warning">
                            You currently have transfer lock enabled on this domain name meaning you will not be able to use this password to transfer
                            the domain name elsewhere.
                        </DialogNotification>
                    ),
                    pos: 'bottom'
                }
            };
        }
        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="epp"
            >
                <Box
                    request={{
                        action: getEppCode,
                        args: domainid,
                        status: domain_epp_status
                    }}
                    className="epp__box"
                    title="EPP Code"
                    desc={`Your EPP code is required when transferring your domain name away from ${company} to another registry.`}
                    info="When transferring a domain name to another registrar, you will need to provide the EPP code to the new registrar. Providing the EPP code verifies that you have the correct authority to move the domain name. EPP codes can also be referred to as ‘Auth Codes’ or ‘Domain Passwords’."
                    {...conditionalProps}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        domain_epp_status: state.domain.domain_epp_status,
        domain_epp_data: state.domain.domain_epp_data,
        domain_epp_error: state.domain.domain_epp_error,
        domain_lock_check_status: state.domain.domain_lock_check_status,
        domain_lock_check_data: state.domain.domain_lock_check_data,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            copyToClipboard,
            getEppCode
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EppCode));
