/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EmailPurchase from '../dropdowns/purchaseDropdown';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { formatMailboxPurchaseValues } from 'containers/email/methods';
import { gaPurchaseEvent } from 'router/google';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getMailboxList, purchaseMailbox, purchaseMailboxReset } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class PurchaseMailboxes extends Component {
    constructor(props) {
        super(props);
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.handleMailboxSubmit = this.handleMailboxSubmit.bind(this);
        this.togglePurchaseDropdown = this.togglePurchaseDropdown.bind(this);
        this.state = {
            showPurchaseLightbox: false,
            showInvoiceLightbox: false,
            invoiceID: null
        };
    }

    /************** OPEN/CLOSE PAY INVOICE **************/
    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceID: id
        });
    }

    closePayInvoice() {
        const { togglePurchaseDropdown } = this;
        this.setState(
            {
                showInvoiceLightbox: false
            },
            () => {
                togglePurchaseDropdown();
            }
        );
    }

    closePayInvoiceSuccess() {
        const { emailid, getMailboxList } = this.props;
        this.setState(
            {
                showPurchaseLightbox: false,
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getMailboxList(emailid);
            }
        );
    }

    /************** OPEN/CLOSE POPUP FORM **************/
    togglePurchaseDropdown() {
        const { emailid, getMailboxList, purchaseMailboxReset } = this.props;
        const { showPurchaseLightbox } = this.state;

        if (!showPurchaseLightbox) {
            purchaseMailboxReset();
        }

        this.setState(
            {
                showPurchaseLightbox: !showPurchaseLightbox
            },
            () => {
                if (showPurchaseLightbox) {
                    getMailboxList(emailid);
                }
            }
        );
    }

    /************** SUBMIT MAILBOXES **************/
    handleMailboxSubmit(values) {
        const { purchaseMailbox } = this.props;

        purchaseMailbox(formatMailboxPurchaseValues(values));
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps) {
        const { email_mailbox_list_status, email_purchase_mailbox_status, email_purchase_mailbox_data } = this.props;
        const { openPayInvoice } = this;
        registerScrollEvents(this, email_mailbox_list_status === 'success' && prevProps.email_mailbox_list_status === 'loading');

        if (email_purchase_mailbox_status === 'success' && prevProps.email_purchase_mailbox_status === 'loading') {
            const { attributes } = email_purchase_mailbox_data;

            gaPurchaseEvent({
                invoiceId: attributes.invoice,
                item_brand: 'Axigen',
                item_category: 'email_hosting'
            });

            openPayInvoice(attributes.invoice);
        }
    }

    render() {
        const { domain, email_information_mailbox_status, email_purchase_mailbox_status, email_purchase_mailbox_data, email_purchase_mailbox_error } =
            this.props;
        const { showPurchaseLightbox, showInvoiceLightbox, invoiceID } = this.state;
        const { closePayInvoice, closePayInvoiceSuccess, togglePurchaseDropdown, handleMailboxSubmit } = this;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="emailPurchaseMailbox"
            >
                <Box
                    premounted={true}
                    highlighted={true}
                    className="emailPurchaseMailbox__box"
                    title="Need more mailboxes?"
                    desc={htmr(`Add more mailboxes to your domain <strong>${domain}</strong>`)}
                    status={email_information_mailbox_status}
                    action={{
                        buttonType: 'Outline',
                        label: 'Add Mailboxes',
                        type: 'onClick',
                        className: 'emailPurchaseMailbox__button',
                        color: 'secondary',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            togglePurchaseDropdown();
                        }
                    }}
                    dropdown={{
                        title: 'Configure your mailboxes below',
                        render: (
                            <EmailPurchase
                                submitRequest={handleMailboxSubmit}
                                domain={domain}
                                statuses={{
                                    status: email_purchase_mailbox_status,
                                    data: email_purchase_mailbox_data,
                                    error: email_purchase_mailbox_error
                                }}
                            />
                        ),
                        condition: showPurchaseLightbox,
                        close: togglePurchaseDropdown
                    }}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceID}
                        invoiceid={invoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        email_information_mailbox_status: state.email.email_information_mailbox_status,
        email_mailbox_list_status: state.email.email_mailbox_list_status,
        email_purchase_mailbox_status: state.email.email_purchase_mailbox_status,
        email_purchase_mailbox_data: state.email.email_purchase_mailbox_data,
        email_purchase_mailbox_error: state.email.email_purchase_mailbox_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = {
    purchaseMailbox,
    getMailboxList,
    purchaseMailboxReset
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseMailboxes));
