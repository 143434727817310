/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainQuery } from 'containers/domain/queries/queryTree';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { API } from 'utilities/api/domain';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function usePutDomainDNSTypeMutation({ id, attributes }) {
    /***** HOOKS *****/
    const { page } = useSetupEditorRouteParams();

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: () => API.domain.PUT.configuration(id, attributes),
        onError: handleDefaultErrorNotification,
        onSuccess: page === 'setup' ? null : handleDefaultSuccessNotification,
        onSettled: () => {
            katanaQuery.serviceID.getServiceDetails.resetQueries(id);
            domainQuery.domainID.getDNSPresetPreview.resetQueries({ id, preset: 'katana' });
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { usePutDomainDNSTypeMutation };
