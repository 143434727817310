/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { get } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**
 * @param {number} id
 * @param {string} path
 */
export function getServiceInfoDataFromPath(id, path) {
    const serviceInfo = katanaQuery.serviceID.getService.getQueryData(id);
    if (!serviceInfo) {
        return null;
    }
    if (!('data' in serviceInfo)) {
        return;
    }
    return get(serviceInfo.data, path);
}
