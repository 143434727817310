import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { RenderAdvanceListSlim, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   VALIDATION
 **********************************************************************************************************/
const validate = (values) => {
    const errors = {};
    if (!values.twofactorcode) {
        errors.twofactorcode = 'Required';
    }

    return errors;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class BackupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backupList: [],
            primaryMethod: null
        };
    }

    componentDidMount() {
        const { login_backup_data } = this.props;

        if (login_backup_data) {
            const methods = [];
            methods[login_backup_data.length] = {
                attributes: {
                    name: 'Manual',
                    destination: 'This process may take up to 24 hours',
                    displayName: 'Manual Verification',
                    desc: 'Initiate a manual account recovery. '
                }
            };

            login_backup_data.forEach((data, key) => {
                if (data.attributes.is_primary === 1) {
                    this.setState({ primaryMethod: [data] });
                }
                switch (data.attributes.method) {
                    case 'PUSH':
                        methods[key] = {
                            attributes: {
                                name: data.attributes.method,
                                displayName: `${application} App`,
                                desc: `Use ${application} app and receive the verification push notification`
                            }
                        };
                        break;
                    case 'GAUTH':
                        methods[key] = {
                            attributes: {
                                name: data.attributes.method,
                                displayName: 'Authenticator App',
                                desc: `Use your preferred authentication app and enter the code for ${application}`
                            }
                        };
                        break;

                    case 'SMS':
                        methods[key] = {
                            attributes: {
                                name: data.attributes.method,
                                destination: data.attributes.method_value,
                                displayName: 'Mobile Verification',
                                desc: 'Get a verification code sent to '
                            }
                        };
                        break;

                    case 'EMAIL':
                        methods[key] = {
                            attributes: {
                                name: data.attributes.method,
                                destination: data.attributes.method_value,
                                displayName: 'Email Verification',
                                desc: 'Get a verification code sent to '
                            }
                        };
                        break;

                    default:
                        break;
                }
            });

            this.setState({
                backupList: methods
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { login_2fa_recovery_status, navigation } = this.props;

        if (login_2fa_recovery_status === 'success' && prevProps.login_2fa_recovery_status === 'loading') {
            navigation('2fa');
        }
    }

    render() {
        const { handleSubmit, navigation, login_2fa_recovery_status } = this.props;
        const { backupList } = this.state;

        return (
            <Fragment>
                <div className="login__title">
                    <div className="heading">Two-Factor Options</div>
                    <div className="description">
                        {`If you're having issues or simply cant access your two-factor code, select another way to sign in below`}
                    </div>
                </div>

                <form className="login__form backupForm" onSubmit={handleSubmit}>
                    <div className="form__row">
                        <div className="form__column form__column--full">
                            <Field
                                component={RenderAdvanceListSlim}
                                validate={[requiredFieldValidation]}
                                name="method"
                                type="hidden"
                                className="form__textfield"
                                list={backupList}
                                disabled={login_2fa_recovery_status === 'loading'}
                            />
                        </div>
                    </div>

                    {login_2fa_recovery_status === 'loading' ? (
                        <InactiveButton>
                            <RequestLoader />
                        </InactiveButton>
                    ) : (
                        <SolidButton color="primary" type="submit">
                            Continue
                        </SolidButton>
                    )}
                </form>

                <Anchor
                    className="login__link"
                    onClick={(e) => {
                        e.preventDefault();
                        navigation('2fa');
                    }}
                >
                    Go back a step
                </Anchor>
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
BackupForm = reduxForm({
    form: 'backupForm',
    validate
})(BackupForm);

const mapStateToProps = (state) => {
    return {
        login_2fa_recovery_status: state.login.login_2fa_recovery_status,
        login_backup_data: state.login.login_backup_data
    };
};

export default connect(mapStateToProps)(BackupForm);
