/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AddAddonLightbox from '../forms/addAddonLightbox';
import { RenderStatus, getStatus, renderButton } from './resourceAddons';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import SolidButton from 'components/Buttons/SolidButton';
import CancelAddon from 'components/Cancel/Addon';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { toLuxonDate } from 'utilities/methods/commonActions';
import { renderCheckboxField } from 'utilities/methods/form';
import { getSoftwareAddonPricing, getVPSAddons } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { addonTypes } from '../consts';

const getSelectedAddonIDs = (selectedSoftwares) => {
    const ids = [];

    if (selectedSoftwares && Object.keys(selectedSoftwares).length > 0) {
        Object.keys(selectedSoftwares).forEach((item) => {
            if (selectedSoftwares[item]) {
                const id = parseInt(item.replace('addon_', ''));
                ids.push(id);
            }
        });
    }

    return ids;
};

export const getTotal = (addons, cycle) => {
    let total = 0;

    if (addons && addons.length > 0) {
        addons.forEach((addon) => {
            const { price } = addon.attributes;

            if (price && Object.prototype.hasOwnProperty.call(price, cycle)) {
                total += parseFloat(price[cycle]);
            }
        });
    }

    return total.toFixed(2);
};

const getSelectedAddons = (selectedAddonIDs, getSoftwarePricingData) => {
    const addons = [];

    if (selectedAddonIDs && selectedAddonIDs.length > 0 && getSoftwarePricingData && getSoftwarePricingData.length > 0) {
        selectedAddonIDs.forEach((addonID) => {
            const addon = getSoftwarePricingData.find((addObj) => {
                const { id } = addObj;
                return addonID === id;
            });

            addons.push(addon);
        });
    }

    return addons;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SoftwareAddonsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedSoftwares, parent, submitFormSoftwareStatus, reset, vps_software_addon_pricing_data } = this.props;

        if (selectedSoftwares !== prevProps.selectedSoftwares) {
            const ids = getSelectedAddonIDs(selectedSoftwares);
            const addons = getSelectedAddons(ids, vps_software_addon_pricing_data);
            parent.setState({
                ...parent.state,
                selectedAddons: addons
            });
        }

        if (submitFormSoftwareStatus !== prevProps.submitFormSoftwareStatus && submitFormSoftwareStatus === 'success') {
            reset();
        }
    }

    render() {
        const { handleSubmit, selectedAddons, vps_software_addon_pricing_data } = this.props;
        const total = getTotal(selectedAddons, 'Monthly');

        const renderList = () => {
            if (vps_software_addon_pricing_data && vps_software_addon_pricing_data.length > 0) {
                return vps_software_addon_pricing_data.map((item, index) => {
                    const { attributes, id } = item;
                    const { name, description, price } = attributes;
                    const { Monthly } = price;
                    return (
                        <li
                            key={index}
                            className={`VPS-SoftwareAddons-Licenses__item${index % 2 === 0 ? ' VPS-SoftwareAddons-Licenses__item--even' : ''}`}
                        >
                            <div className="VPS-SoftwareAddons-Licenses__item-left">
                                <Field
                                    name={`software_addons['addon_${id}']`}
                                    label={htmr(name)}
                                    component={renderCheckboxField}
                                    type="checkbox"
                                    className="form__textfield VPS-SoftwareAddons-Licenses__checkbox"
                                />
                                <Tooltip info={description} />
                            </div>
                            <div className="VPS-SoftwareAddons-Licenses__item-price">
                                <span className="price">${Monthly}</span>
                                <span className="cycle">/mo</span>
                                <span className="currency">AUD</span>
                            </div>
                        </li>
                    );
                });
            }

            return <p className="hostingAddons__pending">There are no additional addons available to purchase at this time.</p>;
        };

        return (
            <form className="VPS-SoftwareAddons-Licenses" onSubmit={handleSubmit}>
                <div className="VPS-SoftwareAddons__wrapper">
                    <div className="VPS-SoftwareAddons-Licenses__heading box__heading">Select Addons</div>
                    <div className="box__description">Select your addons below. All addons are configured manually.</div>
                </div>
                <ul className="VPS-SoftwareAddons-Licenses__list">{renderList()}</ul>
                {total && total !== '0.00' ? (
                    <div className="VPS-SoftwareAddons-Licenses__footer">
                        <div className="VPS-SoftwareAddons-Licenses__total">
                            <div className="VPS-SoftwareAddons-Licenses__total-label">Total Addon Amount</div>
                            <div className="VPS-SoftwareAddons-Licenses__total-value">
                                ${total} <span className="currency">AUD</span>
                            </div>
                        </div>
                        <SolidButton className="VPS-SoftwareAddons-Licenses__button" type="submit">
                            Continue
                        </SolidButton>
                    </div>
                ) : (
                    ''
                )}
            </form>
        );
    }
}

SoftwareAddonsForm = reduxForm({
    form: 'SoftwareAddonsForm'
})(SoftwareAddonsForm);

const softwareAddonsFormSelector = formValueSelector('SoftwareAddonsForm');

SoftwareAddonsForm = connect((state) => ({
    vps_information_data: state.vps.vps_information_data,
    vps_addon_list_data: state.vps.vps_addon_list_data,
    vps_addon_list_status: state.vps.vps_addon_list_status,
    vps_addon_list_error: state.vps.vps_addon_list_error,
    vps_software_addon_pricing_data: state.vps.vps_software_addon_pricing_data,
    selectedSoftwares: softwareAddonsFormSelector(state, 'software_addons')
}))(SoftwareAddonsForm);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SoftwareAddons extends Component {
    constructor(props) {
        super(props);
        this.renderContent = this.renderContent.bind(this);
        this.getTableMatrix = this.getTableMatrix.bind(this);
        this.closePaymentLightbox = this.closePaymentLightbox.bind(this);
        this.closeAddingAddonLightbox = this.closeAddingAddonLightbox.bind(this);
        this.onSubmitSoftwareForm = this.onSubmitSoftwareForm.bind(this);
        this.closeRemoveAddonLightbox = this.closeRemoveAddonLightbox.bind(this);

        this.state = {
            selectedInvoice: undefined,
            payInvoiceLightbox: false,
            removeAddonLightbox: false,
            addonToBeRemoved: undefined,
            selectedAddons: undefined,
            addingAddonLightbox: false
        };
    }

    closeRemoveAddonLightbox() {
        this.setState({
            removeAddonLightbox: false,
            addonToBeRemoved: undefined
        });
    }

    closePaymentLightbox() {
        const { getVPSAddons, vps_information_data } = this.props;
        this.setState(
            {
                payInvoiceLightbox: false
            },
            () => {
                const { id } = vps_information_data;
                getVPSAddons(id);
            }
        );
    }

    getTableMatrix() {
        const { vps_addon_list_data } = this.props;
        const { attributes } = vps_addon_list_data;
        const { software } = attributes;
        const { addons } = software;
        let matrix = [];

        if (addons && addons.length > 0) {
            matrix = Array(addons.length).fill();

            Object.keys(matrix).forEach((value) => {
                const { attributes, id } = addons[value];
                const { name, billing_cycle, price, status, renewal_date, expiry_date } = attributes;
                const date = toLuxonDate(renewal_date);
                const addon_status = getStatus(status, attributes);

                if (addon_status === 'suspended') {
                    matrix[value] = {
                        license: (
                            <div className="VPS-Resource-Addons-Item__license">
                                <div className="VPS-Resource-Addons-Item__license-name">{name}</div>
                                <div className="VPS-Resource-Addons-Item__license-price">
                                    ${price}/{billing_cycle}
                                </div>
                            </div>
                        ),
                        status: (
                            <div className="VPS-Resource-Addons-Item__suspend-note">
                                Pending Deletion{expiry_date ? ` - expires ${toLuxonDate(expiry_date).toFormat('dd/MM/yyyy')}` : ''}
                            </div>
                        ),
                        actions: renderButton({ status, attributes, ResourceAddons: this, id, type: addonTypes.SOFTWARE }),
                        className: 'suspended-row'
                    };
                } else {
                    matrix[value] = {
                        license: (
                            <div className="VPS-Resource-Addons-Item__license">
                                <div className="VPS-Resource-Addons-Item__license-name">{name}</div>
                                <div className="VPS-Resource-Addons-Item__license-price">
                                    ${price}/{billing_cycle}
                                </div>
                            </div>
                        ),
                        status: <RenderStatus status={status} attributes={attributes} />,
                        billing: <div className="VPS-Resource-Addons-Item__license-cycle">{billing_cycle}</div>,
                        renewal: <div className="VPS-Resource-Addons-Item__license-renewal">{date.toFormat(`dd/MM/yyyy`)}</div>,
                        actions: renderButton({ status, attributes, ResourceAddons: this, id, type: addonTypes.SOFTWARE })
                    };
                }
            });
        }

        return matrix;
    }

    onSubmitSoftwareForm() {
        this.setState({
            addingAddonLightbox: true
        });
    }

    renderContent() {
        const { getTableMatrix, onSubmitSoftwareForm } = this;
        const { vps_addon_list_data, vps_add_software_addon_status } = this.props;
        const { selectedAddons } = this.state;

        if (vps_addon_list_data) {
            const { attributes } = vps_addon_list_data;
            const { software } = attributes;
            const { addons } = software;

            return (
                <Fragment>
                    <div className="VPS-SoftwareAddons__wrapper">
                        <div className="box__heading">Additional Addons</div>
                        <div className="box__description">
                            We offer a range of additional addons to give you more security, performance and control over your VPS.
                        </div>
                    </div>
                    {addons && addons.length > 0 ? (
                        <Table
                            className="VPS-Resource-Addons-Item__table VPS-SoftwareAddons__table"
                            embedded={true}
                            header={[
                                {
                                    title: `Price`,
                                    className: `price`
                                },
                                {
                                    title: `Status`,
                                    className: `status`
                                },
                                {
                                    title: `Billing Period`,
                                    className: `billingperiod`
                                },
                                {
                                    title: `Renewal Date`,
                                    className: `renewaldate`
                                },
                                {
                                    action: true
                                }
                            ]}
                            matrix={getTableMatrix()}
                            error="You have no invoices at the moment. Why not go ahead and purchase some amazing services to get this list started?"
                            stacked={true}
                        />
                    ) : (
                        ''
                    )}
                    <SoftwareAddonsForm
                        parent={this}
                        submitFormSoftwareStatus={vps_add_software_addon_status}
                        selectedAddons={selectedAddons}
                        onSubmit={onSubmitSoftwareForm}
                    />
                </Fragment>
            );
        }
    }

    closeAddingAddonLightbox() {
        this.setState({
            addingAddonLightbox: false
        });
    }

    componentDidUpdate(prevProps) {
        const { getVPSAddons, vps_solus_information_status, vps_addon_cancel_status, vps_information_data } = this.props;
        const { closeRemoveAddonLightbox } = this;

        registerScrollEvents(this, vps_solus_information_status === 'success' && prevProps.vps_solus_information_status === 'loading');

        if (vps_addon_cancel_status === 'success' && prevProps.vps_addon_cancel_status === 'loading') {
            closeRemoveAddonLightbox();
            getVPSAddons(vps_information_data?.id);
        }
    }

    render() {
        const { renderContent, closePaymentLightbox, closeAddingAddonLightbox, closeRemoveAddonLightbox } = this;
        const { payInvoiceLightbox, selectedInvoice, selectedAddons, addingAddonLightbox, removeAddonLightbox, addonToBeRemoved } = this.state;
        const { vps_information_data, getSoftwareAddonPricing, vps_software_addon_pricing_status } = this.props;
        const { id } = vps_information_data;

        return (
            <Fragment>
                <div
                    ref={(el) => {
                        this.scrollRef = el;
                    }}
                >
                    <Box
                        className="VPS-SoftwareAddons"
                        custom={{
                            pos: 'top',
                            render: renderContent()
                        }}
                        request={{
                            action: getSoftwareAddonPricing,
                            args: id,
                            status: vps_software_addon_pricing_status
                        }}
                        status={vps_software_addon_pricing_status}
                    />
                </div>
                {addingAddonLightbox && (
                    <OverlayLightbox className="VPS-Lightbox" onOpen={addingAddonLightbox} onClose={closeAddingAddonLightbox}>
                        <AddAddonLightbox addAddonType="software" parent={this} selectedAddons={selectedAddons} />
                    </OverlayLightbox>
                )}
                {removeAddonLightbox && addonToBeRemoved ? (
                    <CancelAddon type="vps" onClose={closeRemoveAddonLightbox} addonId={addonToBeRemoved.id} />
                ) : (
                    ''
                )}
                {payInvoiceLightbox && (
                    <OverlayLightbox
                        invoiceid={selectedInvoice}
                        title={`Pay Invoice #` + selectedInvoice}
                        onOpen={payInvoiceLightbox}
                        onClose={closePaymentLightbox}
                    />
                )}
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            vps_information_data: state.vps.vps_information_data,
            vps_addon_list_data: state.vps.vps_addon_list_data,
            vps_addon_list_status: state.vps.vps_addon_list_status,
            vps_addon_list_error: state.vps.vps_addon_list_error,
            vps_add_software_addon_status: state.vps.vps_add_software_addon_status,
            vps_software_addon_pricing_status: state.vps.vps_software_addon_pricing_status,
            vps_solus_information_status: state.vps.vps_solus_information_status,
            vps_addon_cancel_status: state.vps.vps_addon_cancel_status,
            selectedSoftwares: softwareAddonsFormSelector(state, 'software_addons')
        }),
        {
            getVPSAddons,
            getSoftwareAddonPricing
        }
    )(SoftwareAddons)
);
