/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Automatically updates the value of the state when the original writable input value changes.
 * @deprecated we're moving to using tanstack/store, this hook will be removed/modified in the future.
 * @template T
 * @param {import('svelte/store').Writable<T>} store
 * @param {(e: T) => void} callback
 * @param {boolean} [immediateInvocation]
 * Whether to allow the subscriber to immediately invoke the callback after the dependencies change - Default: `true`
 */
export function useStoreEffect(store, callback, immediateInvocation = true) {
    /***** EFFECTS *****/
    useEffect(() => {
        let firedFirst = immediateInvocation;
        const unsubscribe = store.subscribe((value) => {
            if (firedFirst) {
                callback(value);
            } else {
                firedFirst = true;
            }
        });
        return () => {
            unsubscribe();
        };
    }, [store, callback, immediateInvocation]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
