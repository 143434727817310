/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useRef } from 'react';
import DateTimePicker from 'react-datetime-picker';
import PropTypes from 'prop-types';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useResetDateTimeValue } from './useResetDateTimeValue';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ControlledDateTimePicker.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This datepicker wraps the `react-datetime-picker
 */
function ControlledDateTimePicker(props) {
    const { label, format, value, onChange, minDate, maxDate, parentRef } = props;

    /***** HOOKS *****/
    const formItemRef = useRef();
    const dateTimeValue = useResetDateTimeValue(formItemRef, value, parentRef.current, ['clearFilter']);

    /***** RENDER HELPERS *****/
    const componentProps = {
        format,
        value: dateTimeValue,
        onChange,
        minDate,
        maxDate
    };

    return (
        <div className="ControlledDateTimePicker" ref={formItemRef}>
            <div className="ControlledDateTimePicker__label">{label}</div>
            <DateTimePicker {...componentProps} />
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const datePropType = PropTypes.oneOfType([PropTypes.instanceOf(Date)]);
ControlledDateTimePicker.propTypes = {
    /**
     * The label to show above the time picker
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * The value
     */
    value: datePropType,

    /**
     * The format to display the date time in, use:
     * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
     */
    format: PropTypes.string,

    /**
     * Gets called when the value is changed. the parameter is the new value
     */
    onChange: PropTypes.func,

    /**
     * The minimum date to allow picking
     */
    minDate: datePropType,

    /**
     * The maximum date to allow picking
     */
    maxDate: datePropType,

    /**
     * The parent ref to use for resetting the value
     */
    parentRef: PropTypes.object
};

export default ControlledDateTimePicker;
