/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import htmr from 'htmr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayCancel from 'components/Cancel/form/cancel';
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';
import SolidNotification from '../../Notifications/SolidNotification';
import OverlayLightboxBackLink from './Components/BackLink';
import OverlayConfirm from './Components/confirm';
import OverlayInvoice from './Components/invoice';
import OverlayRestore from './Components/restore';

/*   ACTIONS
 *****************************************************/
import { COPY_back } from 'containers/billing/modules/consts';
import { getAlerts } from 'containers/dashboard/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OverlayLightbox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated For the sake of sanity, this component is now in __*Component Prison*__, The new `Modal` is much more flexible and this is just a monster of a component. just don't use it.
 *
 * For the sake of sanity: wrap usage of this component in a conditional statement as the Modal component that's being used has issues with removing styling
 */
class OverlayLightbox extends Component {
    constructor(props) {
        super(props);
        this.timeout = null;
        this.state = {
            fadeout: false,
            postPayment_status: null,
            postPayment_data: null
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.closeLightboxAfterSuccess = this.closeLightboxAfterSuccess.bind(this);
        this.postPaymentLightbox = this.postPaymentLightbox.bind(this);
    }

    closeLightbox() {
        const { onClose } = this.props;
        const { postPayment_status } = this.state;
        const { closeLightboxAfterSuccess } = this;

        if (postPayment_status === 'success') {
            return closeLightboxAfterSuccess();
        }

        onClose();
    }

    closeLightboxAfterSuccess() {
        const { getAlerts, onSuccessClose, onClose } = this.props;
        const { postPayment_status } = this.state;

        if (postPayment_status === 'success') {
            getAlerts();
        }

        if (onSuccessClose) onSuccessClose();
        else onClose();
    }

    postPaymentLightbox(status, data) {
        this.setState({
            postPayment_status: status,
            postPayment_data: data
        });
    }

    render() {
        const {
            children,
            className,
            color,
            warningMsg,
            customMsg,
            title,
            desc,
            icon,
            invoiceid,
            serviceid,
            confirm,
            loading,
            onOpen,
            cancel,
            removeCloseButton,
            initialFocus,
            goBackLink
        } = this.props;

        const { postPayment_status, postPayment_data } = this.state;

        const { closeLightbox, closeLightboxAfterSuccess, postPaymentLightbox } = this;

        const childrenWithExtraProp = React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                closeLightbox
            });
        });

        const handleOverlayRender = () => {
            if (loading && ['pending', 'loading'].includes(loading)) {
                return (
                    <div className="OverlayLoader">
                        <RequestLoader />
                    </div>
                );
            } else if (confirm) {
                return <OverlayConfirm confirm={confirm} color={color} />;
            } else if (cancel) {
                return <OverlayCancel cancel={cancel} />;
            } else if (invoiceid && !postPayment_status) {
                return <OverlayInvoice invoiceID={invoiceid} closeInvoice={closeLightbox} togglePostPayment={postPaymentLightbox} />;
            } else if (invoiceid && postPayment_status) {
                return (
                    <div className="PostPayment__wrapper">
                        <div className="PostPayment__description">{postPayment_data.desc}</div>
                        <SolidButton className="PostPayment__button" type="onClick" onClick={closeLightboxAfterSuccess}>
                            {COPY_back}
                        </SolidButton>
                    </div>
                );
            } else if (serviceid) {
                return <OverlayRestore id={serviceid} closeInvoice={closeLightbox} />;
            } else {
                return (
                    <>
                        {childrenWithExtraProp}
                        {goBackLink ? (
                            <Anchor className="Overlay__goBackLink" onClick={goBackLink}>
                                Go Back
                            </Anchor>
                        ) : (
                            ''
                        )}
                    </>
                );
            }
        };

        return (
            <Modal
                className={classNames({
                    [className]: !!className,
                    hasNotification: !!(warningMsg || customMsg)
                })}
                isOpen={onOpen}
                onClose={closeLightbox}
                ariaLabel={postPayment_status ? postPayment_data.title : title}
                removeCloseButton={removeCloseButton}
                initialFocus={initialFocus}
            >
                {warningMsg ? <SolidNotification color="warning">{htmr(warningMsg)}</SolidNotification> : ''}
                {customMsg ? <div className="Overlay__customNotification">{customMsg}</div> : ''}

                <div className="Overlay__container">
                    {postPayment_status || icon ? (
                        <div className="Overlay__icon">
                            {postPayment_status ? (
                                <i className={`icon icon-${postPayment_data.icon} ${postPayment_status}`}></i>
                            ) : (
                                <i className={`icon icon-${icon.icon} ${icon.status}`}></i>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="Overlay__title">{postPayment_status ? postPayment_data.title : title}</div>
                    {desc && !(loading && ['loading', 'pending'].includes(loading)) && <div className="Overlay__desc">{desc}</div>}
                    {handleOverlayRender()}
                </div>
            </Modal>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

OverlayLightbox.BackLink = OverlayLightboxBackLink;

/**
 * @deprecated For the sake of sanity, this component is now in __*Component Prison*__, The new `Modal` is much more flexible and this is just a monster of a component. just don't use it.
 *
 * For the sake of sanity: wrap usage of this component in a conditional statement as the Modal component that's being used has issues with removing styling
 */
OverlayLightbox = connect(null, {
    getAlerts
})(OverlayLightbox);

export default OverlayLightbox;

/**********************************************************************************************************
 *   Proptypes
 **********************************************************************************************************/
OverlayLightbox.propTypes = {
    /**
     * Determines whether or not the modal is open.
     */
    onOpen: PropTypes.bool.isRequired,

    /**
     * Function to be called when the close button is clicked or Escape key is hit. This should change the state that controls "open" to false.
     */
    onClose: PropTypes.func.isRequired,

    /**
     * Contents of the lightbox
     */
    children: PropTypes.element,

    /** Class to append to the .Overlay element. */
    className: PropTypes.string,

    /** Color of the confirm button if its a confirm lightbox. Only pass this prop if the "confirm" prop is also passed. */
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'confirm', 'error', 'warn', 'warning', 'notice', 'info', 'white']),

    /** Warning message to be displayed in an orange notification at the top of the lightbox */
    warningMsg: PropTypes.string,

    customMsg: PropTypes.element,

    /** Lightbox title */
    title: PropTypes.node,

    /** Lightbox description */
    desc: PropTypes.node,

    /** Icon that can appear at the top of the lightbox */
    icon: PropTypes.shape({
        /** The icon class, eg if its icon-phone, then just pass "phone" as icon.icon */
        icon: PropTypes.string.isRequired,
        /** Color of the icon */
        status: PropTypes.oneOf(['success', 'error'])
    }),

    // ID of the invoice to be paid. If invoiceid is passed then the lightbox will be a pay invoice lightbox
    invoiceid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /** ID of the service to restore. If serviceid is passed then the lightbox will be a restore lightbox */
    serviceid: PropTypes.number,

    /** Pass the confirm prop to show a confirm lightbox. This will include a styled description, icon, confirm and close buttons, and handle loading status by default */
    confirm: PropTypes.shape({
        /** Description to go under the tick icon */
        desc: PropTypes.node,
        /** Text to display on the confirm button */
        buttonText: PropTypes.string,
        /** Function to call when the confirm button is clicked */
        buttonAction: PropTypes.func,
        /** Text to display on the close button */
        closeText: PropTypes.string,
        /** Function to call when the close button is clicked */
        closeAction: PropTypes.func,
        /** Provides loading state of lightbox */
        loading: PropTypes.oneOf(['loading', 'pending', 'error', 'success', null])
    }),

    /** Provides loading state of lightbox */
    loading: PropTypes.oneOf(['loading', 'pending', 'error', 'success', null]),

    /** Function to be called onClick of the "Go Back" button after successful invoice payment */
    onSuccessClose: PropTypes.func,

    /** Details to pass through to cancel component */
    cancel: PropTypes.shape({
        /** ID of the service to be cancelled */
        id: PropTypes.number,
        /** Type of service to be cancelled */
        service: PropTypes.oneOf(['webHosting', 'emailHosting', 'gsuite', 'vps']),
        /** Info about cancelling the cancellation request after it's been lodged */
        keep: {
            /** Keep lightbox title */
            title: PropTypes.string,
            /** Keep lightbox description */
            desc: PropTypes.string,
            /** Keep lightbox button action */
            action: PropTypes.func
        },
        /** Loading status of the cancellation */
        loading: PropTypes.oneOf(['loading', 'error', 'success', null])
    }),

    /** Whether to hide the default button which closes the modal. If true, an alternative button to close the modal will need to be rendered as part of the children prop. */
    removeCloseButton: PropTypes.bool,

    /** The ref of an element that should receive focus when the modal is opened. If initialFocus is omitted then the first focusable element in the modal will receive initial focus. If 'close-button' is passed then the close button will receive focus (use this when the lightbox has an initial loading state and the refs immediately disappear). */
    initialFocus: PropTypes.oneOf([
        'close-button',
        PropTypes.shape({
            current: PropTypes.instanceOf(Element)
        })
    ]),

    /** Function to be called when the "Go Back" link is clicked */
    goBackLink: PropTypes.func
};

/**********************************************************************************************************
 *   COMPONENT EXPORTS
 **********************************************************************************************************/
