/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { UndefinedInitialDataOptions} from '@tanstack/react-query';
import { queryOptions, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseQueryOptions = Omit<UndefinedInitialDataOptions<any, Error, any, any>, 'queryFn' | 'queryKey'>;

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(['katana', 'service', serviceID, 'dns'] as const);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.DNS.GET(serviceID),
        retryOnMount: false,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

/**
 * Get Katana DNS data
 */
function _useQuery(serviceID: number, options?: UseQueryOptions) {
    return useQuery({
        ...createQueryOptions(serviceID),
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getDNS = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions
});
