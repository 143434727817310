/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RightBorderButton from 'components/Buttons/RightBorderButton';
import SolidButton from 'components/Buttons/SolidButton';
import OutlineDropdown from 'components/Dropdowns/OutlineDropdown';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXTable from 'components/NXTable';
import TableAccordion from 'components/NXTable/TableAccordion';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { deleteEmailForwarder } from 'containers/domain/action';
import { scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_emailTable.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The Email forwarders table
 */
let EmailTable = ({
    openLightbox,

    /** Redux State */
    app_viewport,
    domain_email_record_list_status,
    domain_email_record_status,
    domain_email_record_store,
    domain_information_data
}) => {
    const { dispatch } = store;

    /***** FUNCTIONS *****/
    function editButtonAction(serviceID) {
        openLightbox('edit', serviceID, 'email');
    }

    function deleteButtonAction(id) {
        dispatch(deleteEmailForwarder(domain_information_data.id, id));
    }

    /***** RENDER HELPERS *****/
    function renderAddForwarderButton() {
        return (
            <RightBorderButton
                color="secondary"
                type="onClick"
                onClick={() => {
                    openLightbox('add', null, 'email');
                }}
            >
                <Text uppercase>Add Email Forwarder</Text>
                <PhosphorIcons.Plus />
            </RightBorderButton>
        );
    }

    /***** RENDER *****/
    function renderMobileTable() {
        const renderContent = (id) => (
            <NXTable.Actions.Base>
                <SolidButton
                    type="onClick"
                    color="warning"
                    onClick={() => {
                        editButtonAction(id);
                        scrollToRef(document.querySelector('.email_forwarding'));
                    }}
                >
                    Edit
                </SolidButton>
                <SolidButton
                    type="onClick"
                    color="warn"
                    onClick={() => {
                        deleteButtonAction(id);
                    }}
                >
                    Delete
                </SolidButton>
            </NXTable.Actions.Base>
        );

        // Mobile table
        return (
            <NXTable.Accordion.ToggleWrapper isLoading={[domain_email_record_status, domain_email_record_list_status].includes('loading')}>
                {domain_email_record_store.map(({ attributes: { source, destination }, id }, i) => (
                    <NXTable.Accordion key={i} index={i} content={renderContent(id)}>
                        <TableAccordion.Child>
                            <Text bold uppercase size--xs>
                                Source
                            </Text>
                            <Text size--s>{source}</Text>
                        </TableAccordion.Child>
                        <TableAccordion.Child>
                            <Text bold uppercase size--xs>
                                Destination
                            </Text>
                            <Text size--s>{destination}</Text>
                        </TableAccordion.Child>
                        <TableAccordion.Child>
                            <Text bold uppercase size--xs>
                                Catchall
                            </Text>
                            <Text size--s>
                                <i className={`icon ${source.startsWith('@') ? 'icon-check' : 'icon-x'}`} />
                            </Text>
                        </TableAccordion.Child>
                    </NXTable.Accordion>
                ))}
                <Border bottom>
                    <NXTable.Footer>{renderAddForwarderButton()}</NXTable.Footer>
                </Border>
            </NXTable.Accordion.ToggleWrapper>
        );
    }

    function renderDesktopTable() {
        // Desktop table
        return (
            <NXTable columns="1fr 1fr 60px NXActions" className="emailTable">
                <NXTable.Header>
                    <NXTable.Header.Title>Source</NXTable.Header.Title>
                    <NXTable.Header.Title>Destination</NXTable.Header.Title>
                    <NXTable.Header.Title>Catchall</NXTable.Header.Title>
                </NXTable.Header>
                <NXTable.Body>
                    {domain_email_record_store.map(({ attributes: { source, destination }, id }, i) => (
                        <NXTable.Row key={i}>
                            <Text size--s secondary>
                                {source}
                            </Text>
                            <Text size--s secondary>
                                {destination}
                            </Text>
                            <i className={`catchallIcon icon ${source.startsWith('@') ? 'icon-check' : 'icon-x'}`} />
                            <NXTable.Actions>
                                <OutlineDropdown
                                    className="small alignRight"
                                    title="Edit"
                                    type="onClick"
                                    color="warning"
                                    titleOnClick={() => {
                                        editButtonAction(id);
                                    }}
                                    list={[
                                        {
                                            label: 'Delete',
                                            type: 'onClick',
                                            className: '',
                                            color: 'warn',
                                            onClick: (e) => {
                                                deleteButtonAction(id);
                                            }
                                        }
                                    ]}
                                />
                            </NXTable.Actions>
                        </NXTable.Row>
                    ))}
                    <Border bottom>
                        <NXTable.Footer>{renderAddForwarderButton()}</NXTable.Footer>
                    </Border>
                </NXTable.Body>
            </NXTable>
        );
    }

    function renderTable() {
        // Mobile table
        if (['sm', 'xs'].includes(app_viewport)) {
            return renderMobileTable();
        }

        return renderDesktopTable();
    }

    return <div className="emailTable">{renderTable()}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
EmailTable.propTypes = {
    /** The function that handles opening the lightbox */
    openLightbox: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport,
        domain_email_record_list_status: state.domain.domain_email_record_list_status,
        domain_email_record_status: state.domain.domain_email_record_status,
        domain_email_record_store: state.domain.domain_email_record_store,
        domain_information_data: state.domain.domain_information_data
    };
};
EmailTable = connect(mapStateToProps)(EmailTable);

export default EmailTable;
