/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ScrollableComponent } from 'components/ScrollableComponent';
import OverviewSplit from './overviewSplit';
import SuggestionsSplit from './suggestionsSplit';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/*   ACTIONS
 *****************************************************/
import { getDomainRenew, getDomainSuggestions } from '../../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_domainOverview.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Domain overview module
 * @param {{
 *      ccTLD: boolean
 * }} props
 */
function Overview({ ccTLD }) {
    /***** HOOKS *****/
    const app_viewport = useAppViewport();
    const domain_information_data = useSelector((state) => state.domain.domain_information_data);
    const dispatch = useDispatch();

    const isSuggestionsShowing = !['xs', 'sm'].includes(app_viewport);

    /***** EFFECTS *****/
    useEffect(() => {
        getDomainRenew(domain_information_data?.id);

        if (isSuggestionsShowing) {
            dispatch(getDomainSuggestions(domain_information_data?.id));
        }
    }, []);

    /***** RENDER HELPERS *****/
    const splitSections = [<OverviewSplit key="overview" ccTLD={ccTLD} />];

    if (isSuggestionsShowing) {
        splitSections.unshift(<SuggestionsSplit key="suggestions" />);
    }

    /***** RENDER *****/
    return (
        <ScrollableComponent className="domainOverview" allowImmediateReady>
            <NXBox>
                <NXBox.Split sections={splitSections} />
            </NXBox>
        </ScrollableComponent>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Overview;
