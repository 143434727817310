/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { application, url } from 'config/config';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CustomerCareHeader from './customerCareFormHeader';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import DismissButton from 'components/DismissibleBanner/DismissButton';
import DynamicForm from 'components/DynamicForm';
import FetchPageError from 'components/Errors/FetchPageError';
import HelpBox from 'components/HelpBox';
import RequestLoader from 'components/Loaders/Request';
import SolidTag from 'components/Tags/SolidTag';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { getHrsAndMinsFromMillis, isBusinessHours } from 'utilities/methods/commonActions';
import { getKnowledgeBaseData, getTicketWaitTime } from '../action';
import { getFlatServicesArray, getTicketServiceInfo, supportFilterDelayedFuseSearch } from '../methods';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useSearchInvoiceByKeywordQuery } from 'containers/billing/queries/invoice/useSearchInvoiceByKeywordQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getVpsType, vpsTypes } from 'containers/vps/methods';
import useSearchParams from 'utilities/hooks/useSearchParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const serverManagementLink = `${url}faq/article/what-is-a-server-management-day-pass-and-what-does-it-cover/`;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ETicketForm = ({
    handleSubmit,
    match: {
        params: { param4, param5, subpage }
    },
    // Redux props
    app_user_data,
    support_question_list_status,
    support_question_list_data,
    support_ticket_wait_time_status,
    support_ticket_wait_time_data,
    support_knowledge_base_status,
    support_knowledge_base_data,
    support_knowledge_base_all_posts_data,
    support_all_services_status,
    support_all_services_data,
    getTicketWaitTime,
    getKnowledgeBaseData,
    subject
}) => {
    /***** STATE *****/
    const [suggestionsDismissed, setSuggestionsDismissed] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);

    /***** HOOKS *****/
    const { searchParamsGet } = useSearchParams();
    const invoiceId = searchParamsGet('invoice');
    const serviceId = searchParamsGet('service');

    /***** QUERIES *****/
    const { data: search_invoice_by_keyword_data } = useSearchInvoiceByKeywordQuery({ filter: invoiceId === 'na' ? null : invoiceId });

    /***** RENDER HELPERS *****/
    const subaccountUser = searchParamsGet('subaccount-user');
    const subaccountDomain = searchParamsGet('subaccount-domain');
    const hasSelectedInvoice = invoiceId && !['na', 'none'].includes(invoiceId);
    const hasSelectedService = serviceId && !['na', 'none'].includes(serviceId);

    const flatServicesArray = getFlatServicesArray(support_all_services_data);
    const selectedServiceInfo = getTicketServiceInfo(flatServicesArray.find((service) => service.id === Number(serviceId)));

    /***** FUNCTIONS *****/
    function onDocumentClick(e) {
        const faqWrapper = document.querySelector('.DynamicForm__faqWrapper');
        if (faqWrapper?.contains(e.target) === false) {
            setShowSuggestions(false);
        } else if (faqWrapper?.contains(e.target) && e.target.tagName === 'INPUT') {
            setShowSuggestions(true);
        }
    }

    function dismissSuggestions() {
        setSuggestionsDismissed(true);
    }

    function populateSearchResults(searchResult) {
        const filteredResults = searchResult.map((result) => result.item);

        setFilteredSuggestions(filteredResults);
        setShowSuggestions(filteredResults.length > 0);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if ((param4 === 'accounts-billing' && isBusinessHours() && (hasSelectedInvoice || hasSelectedService)) || param4 === 'technical-support')
            getTicketWaitTime();

        getKnowledgeBaseData(true);

        document.addEventListener('click', onDocumentClick);

        return () => document.removeEventListener('click', onDocumentClick);
    }, []);

    useEffect(() => {
        supportFilterDelayedFuseSearch(support_knowledge_base_all_posts_data, subject, populateSearchResults);
    }, [subject]);

    /***** RENDER HELPERS *****/
    const renderFormHeader = () => {
        const renderAffectedService = () => {
            return (
                <>
                    <div className="DynamicForm__headerSection">
                        <div className="DynamicForm__headerHeading">Service Affected</div>
                        <div className="DynamicForm__headerData DynamicForm__headerData--uppercase">{selectedServiceInfo.productType}</div>
                    </div>
                    <div className="DynamicForm__headerSection">
                        <div className="DynamicForm__headerHeading">Service Name</div>
                        <div className="DynamicForm__headerData">
                            {selectedServiceInfo.domain}
                            {selectedServiceInfo.product ? ` / ${selectedServiceInfo.product}` : ''}
                        </div>
                    </div>
                </>
            );
        };

        const renderAffectedInvoice = () => {
            return (
                <div className="DynamicForm__headerSection">
                    <div className="DynamicForm__headerHeading">Invoice Affected</div>
                    <div className="DynamicForm__headerData">#{invoiceId}</div>
                </div>
            );
        };

        const renderAffectedSubaccount = () => {
            return (
                <div className="DynamicForm__header">
                    <div className="DynamicForm__headerSection">
                        <div className="DynamicForm__headerHeading">Sub Account Username</div>
                        <div className="DynamicForm__headerData DynamicForm__headerData--uppercase">{subaccountUser}</div>
                    </div>
                    <div className="DynamicForm__headerSection">
                        <div className="DynamicForm__headerHeading">Sub Account Domain</div>
                        <div className="DynamicForm__headerData">{subaccountDomain}</div>
                    </div>
                </div>
            );
        };

        return hasSelectedService || hasSelectedInvoice ? (
            <>
                <div className="DynamicForm__header">
                    {selectedServiceInfo && renderAffectedService()}
                    {hasSelectedInvoice && renderAffectedInvoice()}
                </div>
                {subaccountUser && subaccountDomain ? renderAffectedSubaccount() : ''}
            </>
        ) : (
            ''
        );
    };

    const renderQueueJumpLabel = () => {
        const urgentPrice =
            param4 === 'accounts-billing'
                ? support_ticket_wait_time_data?.attributes?.account?.urgent_price?.value
                : support_ticket_wait_time_data?.attributes?.support?.urgent_price?.value;

        return (
            <>
                <div className="DynamicForm__urgentTooltip">
                    You have an outstanding queue jump invoice. Please pay the invoice to push your ticket to the front of the queue.
                </div>
                <div className="checkboxLabelTop">
                    Queue Jump<small className="checkboxLabelPrice">${urgentPrice || '4.95'}</small>
                </div>
                <div className="checkboxLabelBottom">Place your eTicket at the top of the queue to be looked at sooner</div>
            </>
        );
    };

    const renderServerManagementJobLabel = () => {
        return (
            <div className="DynamicForm__tooltipContainer">
                Do you require a Server Management Job?
                <Tooltip
                    info={`Purchase a Server Management Job for $29.95 and a senior member of our technical support team will schedule a 1-on-1 consultation with you to discuss exactly what you need and how you would like it done. If the issue falls outside the scope of our basic support but can be handled with a Day Pass, our team will generate and send one out to you. <a className="DynamicForm__tooltipReadMoreLink" href="${serverManagementLink}" target="_blank">Read More</a>`}
                    iconOverride={<i className="icon icon-help" />}
                    className="DynamicForm__tooltipIcon"
                />
            </div>
        );
    };

    const renderTechnicalClientLabel = () => {
        return (
            <>
                <div className="DynamicForm__technicalClientTop">
                    <SolidTag color="warning">NEW</SolidTag>
                    <h4 className="DynamicForm__technicalClientTitle">Are you a technical person?</h4>
                </div>
                <p className="DynamicForm__technicalClientDesc">
                    Our team wants to do their best to speak your language and provide you with an outstanding experience every time. To help them do
                    that, you can now tell us whether or not you consider yourself to be a technical person and we will tailor our communication
                    accordingly.
                    <br />
                    <br />
                    Please note that when enabling the technical person option that it will be set to the entire account, meaning all future
                    communication by eTicket, live chat or phone will follow this setting. You can change your technical person preference at any time
                    in Account Settings.
                </p>
            </>
        );
    };

    const renderFaqSuggestionsList = () => {
        const renderSuggestionsTop = () => {
            return (
                <div className="DynamicForm__faqTop">
                    {filteredSuggestions.length > 0 ? (
                        <>
                            <h4 className="DynamicForm__faqTitle">Maybe some of these articles could help</h4>
                            {filteredSuggestions.slice(0, 3).map((post, index) => (
                                <Anchor key={index} className="DynamicForm__faqLink" to={post.link}>
                                    {post.post_title}
                                </Anchor>
                            ))}
                        </>
                    ) : (
                        <p className="DynamicForm__faqNoneFound">No relevant articles found.</p>
                    )}
                </div>
            );
        };

        const renderSuggestionsBottom = () => {
            const mapTicketTypeToCategory = () => {
                if (param4 === 'accounts-billing' || param4 === 'sales') return 'Accounts';

                if (param4 === 'technical-support') {
                    switch (selectedServiceInfo?.productType) {
                        case 'hosting':
                            return 'Web Hosting';
                        case 'email-hosting':
                            return 'Email Hosting';
                        case 'ssl':
                            return 'SSL Certificates';
                        case 'vps':
                            return 'VPS';
                        case 'gsuite':
                            return 'Google Workspace';
                        case 'domain':
                        default:
                            return 'Domain Names';
                    }
                }

                return application;
            };

            const relevantCategoryName = mapTicketTypeToCategory();

            const popularArticles =
                support_knowledge_base_data?.find((category) => category.title === relevantCategoryName)?.posts?.slice(0, 4) || [];

            return (
                <div className="DynamicForm__faqBottom">
                    <h4 className="DynamicForm__faqTitle">
                        Popular {relevantCategoryName === 'Accounts' ? 'Accounts and Billing' : relevantCategoryName} articles
                    </h4>
                    {popularArticles.map((post, index) => (
                        <Anchor key={index} className="DynamicForm__faqLink" to={post.link}>
                            {post.post_title}
                        </Anchor>
                    ))}
                </div>
            );
        };

        return (
            <aside className="DynamicForm__faq">
                <DismissButton onClick={dismissSuggestions} />
                {renderSuggestionsTop()}
                {renderSuggestionsBottom()}
            </aside>
        );
    };

    /************** DynamicForm props **************/
    const inputOverrides = {
        is_urgent: {
            labelOverride: renderQueueJumpLabel(),
            classNameOverrides: [
                {
                    className: 'DynamicForm__urgentCheckbox',
                    condition: null
                },
                {
                    className: 'DynamicForm__urgentCheckbox--selected',
                    condition: {
                        fieldName: 'is_urgent',
                        check: ['truthy'],
                        allNeeded: true
                    }
                }
            ],
            disabled: support_question_list_data
                ? support_question_list_data.attributes.able_to_submit_urgent.status_overdue ||
                  support_question_list_data.attributes.able_to_submit_urgent.status_unpaid
                : false
        },
        require_day_pass: {
            labelOverride: renderServerManagementJobLabel(),
            classNameOverrides: [
                {
                    className: 'form__textfield serverManagementJob',
                    condition: null
                }
            ]
        },
        received_correspondence: {
            labelOverride: `Have you received any correspondence from ${selectedServiceInfo?.vendor || 'your SSL certificate provider'}?`
        },
        is_technical: {
            labelOverride: renderTechnicalClientLabel(),
            classNameOverrides: [
                {
                    className: 'DynamicForm__technicalClient',
                    condition: null
                }
            ]
        },
        subject: {
            autoComplete: 'off'
        }
    };

    const waitTime =
        param4 === 'accounts-billing'
            ? support_ticket_wait_time_data?.attributes?.account?.wait_time
            : support_ticket_wait_time_data?.attributes?.support?.wait_time;

    const fieldWrappers = {
        is_urgent: {
            classNames: [
                {
                    className: 'DynamicForm__urgent',
                    condition: null
                }
            ],
            beforeInput: (
                <>
                    <div className="DynamicForm__urgentHeading">Something urgent? Jump the queue to get your eTicket looked at sooner</div>
                    {param4 === 'technical-support' && selectedServiceInfo?.has_priority_support && (
                        <div className="DynamicForm__urgentPremBanner">
                            <div className="DynamicForm__bannerBg"></div>
                            <i className="icon icon-star" />
                            <div className="DynamicForm__bannerText">
                                <h4 className="DynamicForm__bannerTitle">This is a priority service</h4>
                                <p className="DynamicForm__bannerParagraph">
                                    Due to this being a premium service, this eTicket will be treated as a priority
                                    {waitTime ? ', and will likely be answered quicker than the estimated time given below.' : '.'}
                                </p>
                            </div>
                        </div>
                    )}
                    {!!waitTime && (
                        <div className="DynamicForm__waitTime">
                            <div className="DynamicForm__waitTimeSmall">Current max wait time</div>
                            <div className="DynamicForm__waitTimeMinsContainer">
                                <div className="DynamicForm__urgentTooltip DynamicForm__urgentTooltip--waitTime">
                                    This is an estimate for the time a standard eTicket has to be in a queue before being answered based on our queue
                                    length, agent availability and expected handle time.
                                    <br />
                                    <br />
                                    The actual wait time may vary slightly, you will receive an email notifying you of a response to your{' '}
                                    {application}
                                    email address once we respond to your eTicket.
                                </div>
                                <div className="DynamicForm__waitTimeMins">{getHrsAndMinsFromMillis(waitTime)}</div>
                            </div>
                        </div>
                    )}
                </>
            ),
            afterInput: ''
        },
        message: {
            classNames: [
                {
                    className: 'DynamicForm__queryWrapper',
                    condition: null
                },
                {
                    className: 'DynamicForm__queryWrapper--helpOpen',
                    condition: {
                        fieldName: 'message',
                        check: ['truthy', 'active'],
                        allNeeded: false
                    }
                }
            ],
            beforeInput: '',
            afterInput: (
                <HelpBox
                    title="Help us help you!"
                    desc="In order to reach as fast a resolution as possible, please include as much detail as you can. You can refer to these
                    prompts for guidance on the sort of info that will allow our team to best assist you."
                    bullets={[
                        'Are there any specific error messages being shown?',
                        'What are the steps to replicate the issue?',
                        'Have you taken any action to try and correct the issue?',
                        'Provide any relevant login information',
                        'When did you first notice the issue?'
                    ]}
                />
            )
        },
        subject: {
            classNames: [
                {
                    className: 'DynamicForm__faqWrapper',
                    condition: null
                },
                {
                    className: 'DynamicForm__faqWrapper--open',
                    condition: {
                        fieldName: 'subject',
                        check: ['truthy', !suggestionsDismissed && showSuggestions, support_knowledge_base_all_posts_data],
                        allNeeded: true
                    }
                }
            ],
            afterInput: renderFaqSuggestionsList()
        }
    };

    const generateQuestionsList = () => {
        const {
            attributes: { questions }
        } = support_question_list_data;

        const getInitialList = () => {
            if (param5) {
                return questions[param5][0].inputs;
            }
            if (param4 === 'accounts-billing') {
                return questions.account[0].inputs;
            }
            if (param4 === 'sales') {
                return questions.sales[0].inputs;
            }
            if (selectedServiceInfo?.productType === 'vps') {
                const vpsType = getVpsType(selectedServiceInfo.product);
                if (vpsType === vpsTypes.SELF_MANAGED) return questions.support.self_managed_vps[0].inputs;
                if (vpsType === vpsTypes.FULLY_MANAGED) return questions.support.fully_managed_vps[0].inputs;
                return questions.support.vps[0].inputs;
            }
            if (selectedServiceInfo?.productType) {
                const productTypeToUse = selectedServiceInfo.productType === 'microsoft365' ? 'email-hosting' : selectedServiceInfo.productType;
                return questions.support[productTypeToUse][0].inputs;
            }
            return questions.feedback[0].inputs;
        };

        const newQuestions = [...getInitialList()];
        let isUrgentIndex = newQuestions.indexOf(newQuestions.find((item) => item.name === 'is_urgent'));
        // Remove is_urgent question under these conditions
        if (isUrgentIndex !== -1 && param4 === 'accounts-billing' && (!isBusinessHours() || (!hasSelectedInvoice && !hasSelectedService))) {
            newQuestions.splice(isUrgentIndex, 1);
            isUrgentIndex = -1;
        }
        let isTechnicalClientIndex = newQuestions.indexOf(newQuestions.find((item) => item.name === 'is_technical'));
        // Remove is_technical question if the flag is already set to true or false on the user
        if (isTechnicalClientIndex !== -1 && app_user_data && app_user_data.is_technical !== null) {
            newQuestions.splice(isTechnicalClientIndex, 1);
            isTechnicalClientIndex = -1;
        }
        const fileUploadIndex = () => {
            if (isTechnicalClientIndex !== -1) return isTechnicalClientIndex;
            if (isUrgentIndex !== -1) return isUrgentIndex;
            return newQuestions.length;
        };
        const fileUploadField = {
            label: 'File Upload',
            name: 'file',
            validation: [],
            type: 'dropzone',
            accept: ''
        };
        newQuestions.splice(fileUploadIndex(), 0, fileUploadField);
        return newQuestions;
    };

    /************** End DynamicForm props **************/

    const renderForm = () => {
        return (
            <div className="ticket__formWrapper">
                <div className="ticket__formWrapperInner">
                    {renderFormHeader()}
                    {param4 === 'customer-care' && <CustomerCareHeader />}
                    <div className="DynamicForm__body">
                        <DynamicForm
                            form="TicketForm"
                            onSubmit={handleSubmit}
                            formHasSteps={false}
                            data={generateQuestionsList()}
                            stepModifiers={false}
                            inputOverrides={inputOverrides}
                            fieldWrappers={fieldWrappers}
                            formButtonProps={{ children: 'Submit eTicket' }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    function getLoadingMessage() {
        if ([null, 'loading'].includes(support_question_list_status)) return 'Loading FAQ...';
        if (['loading'].includes(support_ticket_wait_time_status)) return 'Loading current waiting time...';
        if (['loading'].includes(support_knowledge_base_status)) return 'Loading support knowledge base...';
        if (['loading'].includes(support_all_services_status)) return 'Loading your services...';
        if (subpage !== 'tickets') return 'Loading support page...';
        return false;
    }

    const loadingMessage = getLoadingMessage();
    /***** RENDER *****/
    if (loadingMessage) {
        return <RequestLoader message={loadingMessage} />;
    }
    if (support_question_list_status === 'error' || (hasSelectedService && support_all_services_status === 'error')) {
        return <FetchPageError />;
    }
    // Make sure serviceId exists within the VIPC account, if present
    if (hasSelectedService && support_all_services_data && !flatServicesArray.find((service) => Number(service.id) === Number(serviceId))) {
        return (
            <FetchPageError
                message={`Invalid service ID provided. Please provide the ID of a service within your ${application} account if you wish to submit an eTicket about a service.`}
            />
        );
    }
    // Make sure invoiceId exist within the VIPC account, if present
    if (hasSelectedInvoice && !search_invoice_by_keyword_data?.pages?.find((invoice) => Number(invoice.id) === Number(invoiceId))) {
        return (
            <FetchPageError message="Invalid invoice ID provided. Please provide the ID of one of your existing invoices if you wish to submit an eTicket about an invoice." />
        );
    }

    return renderForm();
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const selector = formValueSelector('TicketForm');

const mapStateToProps = (state) => ({
    app_user_data: state.app.app_user_data,
    support_ticket_wait_time_status: state.support.support_ticket_wait_time_status,
    support_ticket_wait_time_data: state.support.support_ticket_wait_time_data,
    support_question_list_status: state.support.support_question_list_status,
    support_question_list_data: state.support.support_question_list_data,
    support_knowledge_base_status: state.support.support_knowledge_base_status,
    support_knowledge_base_data: state.support.support_knowledge_base_data,
    support_knowledge_base_all_posts_data: state.support.support_knowledge_base_all_posts_data,
    support_all_services_status: state.support.support_all_services_status,
    support_all_services_data: state.support.support_all_services_data,
    subject: selector(state, 'subject')
});

const mapDispatchToProps = {
    getTicketWaitTime,
    getKnowledgeBaseData
};

ETicketForm = connect(mapStateToProps, mapDispatchToProps)(ETicketForm);

ETicketForm = withRouter(ETicketForm);

export default ETicketForm;
