/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BillingCycle from 'containers/gsuite/modules/billing/billingCycle';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DialogNotification from 'components/Notifications/DialogNotification';
import NXBox from 'components/NXBox';

/*   ACTIONS
 *****************************************************/
import { getGSuiteBillingCycle, getGSuiteService, updateGSuiteBillingCycle } from '../../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_billingGsuite.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let BillingGsuite = ({
    gsuiteid,
    /** Redux Props */
    gsuite_billing_cycle_data,
    gsuite_billing_cycle_status,
    gsuite_service_data,
    gsuite_update_billing_cycle_status,
    gsuite_update_billing_cycle_data
}) => {
    /***** RENDER *****/
    return (
        <>
            {gsuite_service_data?.attributes?.billing_cycle === 'Annually' ? (
                <NXBox>
                    <NXBox.Top
                        title="Billing Cycle"
                        description={
                            <DialogNotification className="DialogNotificationPage--noMarginBottom" type="warning">
                                PLEASE NOTE: You are currently on an annual billing cycle,
                                <br />
                                and are NOT able to revert back to a monthly billing cycle.
                            </DialogNotification>
                        }
                    />
                </NXBox>
            ) : (
                <>
                    <DialogNotification className="DialogNotificationBillingCycle" type="warning" tail={{ pos: 'bottom' }}>
                        PLEASE NOTE: We now offer Google Workspace subscriptions on annual billing cycles at a discounted rate. Change your billing
                        cycle today to secure the updated renewal cost.
                    </DialogNotification>
                    <BillingCycle
                        serviceid={gsuiteid}
                        serviceType="gsuite"
                        information={gsuite_service_data}
                        getService={getGSuiteService}
                        getData={getGSuiteBillingCycle}
                        status={gsuite_billing_cycle_status}
                        data={gsuite_billing_cycle_data}
                        updatedStatus={gsuite_update_billing_cycle_status}
                        updatedData={gsuite_update_billing_cycle_data}
                        update={updateGSuiteBillingCycle}
                    />
                </>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    gsuite_billing_cycle_data: state.gsuite.gsuite_billing_cycle_data,
    gsuite_billing_cycle_status: state.gsuite.gsuite_billing_cycle_status,
    gsuite_service_data: state.gsuite.gsuite_service_data,
    gsuite_update_billing_cycle_status: state.gsuite.gsuite_update_billing_cycle_status,
    gsuite_update_billing_cycle_data: state.gsuite.gsuite_update_billing_cycle_data
});

BillingGsuite = connect(mapStateToProps)(BillingGsuite);

export default BillingGsuite;
