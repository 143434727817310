/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useMemo } from 'react';
import GoogleFontLoader from 'react-google-font';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import { NXSquare } from 'components/Utils/NXSquare';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_renderBrand.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

interface RenderBrandProps {
    font: string;
    children?: React.ReactNode;
    ratio?: number | boolean;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const RenderBrand: React.FC<RenderBrandProps> = ({ font, children, ratio }) => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_fonts_meta_data, isLoading: isGetFontsMetaLoading } = katanaQuery.serviceID.meta.getFonts.useQuery(Number(id));

    /**
     * @typedef {{
     *      id: string,
     *      name: string,
     *      types: string[],
     *      font_family: string,
     *      google_fonts_key: string,
     * }} FontMetaDataValueType
     */

    /***** RENDER HELPERS *****/
    /**
     * @type {FontMetaDataValueType | undefined}
     */
    const fontData = useMemo(() => {
        /**
         * @type {FontMetaDataValueType[]}
         */
        const values = Object.values(get_fonts_meta_data ?? {});
        return values.find((fontMetaData) => fontMetaData.id === font);
    }, [get_fonts_meta_data]);

    function renderFontRenderer() {
        if (isGetFontsMetaLoading) {
            return <RequestLoader message="Loading font styles..." />;
        }
        if (fontData) {
            return (
                <Text size--xxxl align--center black break-word _style={{ fontFamily: fontData?.font_family }}>
                    {children || fontData?.name}
                </Text>
            );
        }
        return <FetchComponentError />;
    }
    /***** RENDER *****/
    return (
        <>
            {fontData?.name && <GoogleFontLoader fonts={[{ font: fontData?.name }]} />}

            <NXSquare ratio={ratio}>
                <Padding xy={4} inject paddingOnly>
                    <Flex justify="center" items="center" className="RenderBrandFont">
                        {renderFontRenderer()}
                    </Flex>
                </Padding>
            </NXSquare>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default RenderBrand;
