/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SectionOrganiserChild from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useReorderSectionsMutation } from 'containers/katana/queries/mutations';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import './_sectionOrganiser.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { SectionOrganiserNamespace } from 'containers/katana/modules/presetContent/components/sectionOrganiser/types';

const { EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Name chosen because it will become interactive in the future

 */
const SectionOrganiser = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();

    const isDraggingRef = useRef(false);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    /***** QUERIES *****/
    const { data: get_katana_section_definitions_data, isLoading: isGetKatanaSectionDefinitionsLoading } =
        katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);
    const { data: get_katana_site_sections_data, isLoading: isGetKatanaSiteSectionsLoading } = katanaQuery.serviceID.getSections.useQuery(id);

    const { data: get_katana_service_data, isLoading: isGetKatanaServiceLoading } = katanaQuery.serviceID.getService.useQuery(id);
    const { mutate: mutateReorderSections } = useReorderSectionsMutation(id);

    /***** FUNCTIONS *****/

    const getSectionRoutes = () => {
        const dataSource = get_katana_site_sections_data ?? get_katana_service_data?.sections ?? [];
        const sortedSections = [...dataSource].sort((a, b) => a.order - b.order);
        return sortedSections.map((section) => {
            const { section_id: sectionDefinitionID, id: sectionID, name: sectionName } = section;
            const { navbar_title } = get_katana_section_definitions_data?.[sectionDefinitionID] ?? {};
            return {
                sectionID,
                title: navbar_title ?? sectionName,
                route: getKatanaDestination(EDITOR.PRESET_CONTENT, sectionID),
                customTitle: sectionName
            };
        });
    };

    const sectionRoutes = getSectionRoutes();
    const [sortableSectionRoutes, setSortableSectionRoutes] = useState(sectionRoutes.map(({ sectionID }) => sectionID));
    function populateNewSectionRoutes() {
        if (!isDraggingRef.current) {
            setSortableSectionRoutes(sectionRoutes.map(({ sectionID }) => sectionID));
        }
    }

    function handleDragStart() {
        isDraggingRef.current = true;
    }

    function handleDragEnd({ active, over }: DragEndEvent) {
        isDraggingRef.current = false;

        if (over?.id && active.id !== over?.id) {
            setSortableSectionRoutes((sections) => {
                const oldIndex = sections.indexOf(active.id as number);
                const newIndex = sections.indexOf(over.id as number);
                const reorderedSections = arrayMove(sections, oldIndex, newIndex);
                const sectionRoutesIDs = sectionRoutes.map(({ sectionID }) => sectionID);
                if (!_.isEqual(reorderedSections, sectionRoutesIDs)) {
                    mutateReorderSections(reorderedSections);
                }
                return reorderedSections;
            });
        }
    }

    const sectionsRender = useMemo(() => {
        return sortableSectionRoutes.map((id) => sectionRoutes.find((section) => section.sectionID === id)).filter(Boolean);
    }, [sortableSectionRoutes, sectionRoutes]) as SectionOrganiserNamespace.SectionData[];

    /***** EFFECTS *****/
    useEffect(populateNewSectionRoutes, [get_katana_site_sections_data, get_katana_service_data, get_katana_section_definitions_data]);

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        {
            condition: isGetKatanaSectionDefinitionsLoading,
            message: 'Loading section definitions...'
        },
        {
            condition: isGetKatanaServiceLoading || isGetKatanaSiteSectionsLoading,
            message: 'Loading site sections...'
        }
    ]);

    /***** RENDER *****/
    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            {sectionRoutes?.length ? (
                <DndContext
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                >
                    <SortableContext items={sortableSectionRoutes} strategy={verticalListSortingStrategy}>
                        <div className="SectionOrganiser">
                            {sectionsRender.map((sectionData) => {
                                return <SectionOrganiserChild key={sectionData?.sectionID} sectionData={sectionData} />;
                            })}
                        </div>
                    </SortableContext>
                </DndContext>
            ) : (
                <div className="SectionOrganiser__noSections">
                    <Text>You have no sections added...</Text>
                </div>
            )}
        </RequestLoader.MultiLoader>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { SectionOrganiser };
