/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__Icon.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated Use `PhosphorIcons` instead
 */
function _Icon(props) {
    const { name, className, primary, secondary, confirm, warn, info, notice, white, black } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props: { primary, secondary, confirm, warn, info, notice, white, black },
        componentName: '_Icon',
    });
    const _IconClassNames = classNames('_Icon', 'icon', 'icon-' + name, appliedStylingClasses, className);
    return <i className={_IconClassNames} />;
}

_Icon.propTypes = {
    /**
     * The name of the icon
     */
    name: PropTypes.string.isRequired,

    /**
     * Icon Colours
     */
    /**
     * Colour Primary Purple
     */
    primary: PropTypes.bool,
    /**
     * Colour Secondary Gray
     */
    secondary: PropTypes.bool,
    /**
     * Colour Confirm Green
     */
    confirm: PropTypes.bool,
    /**
     * Colour Warning Red
     */
    warn: PropTypes.bool,
    /**
     * Colour Info Blue
     */
    info: PropTypes.bool,
    /**
     * Colour Notice Orange
     */
    notice: PropTypes.bool,
    /**
     * Colour White
     */
    white: PropTypes.bool,
    /**
     * Colour Black === Gray
     */
    black: PropTypes.bool
};

export default _Icon;
