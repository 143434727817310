/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type React from 'react';
import { useEffect } from 'react';
import { useEventListener } from 'usehooks-ts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isPointerEventWithinRefList } from 'utilities/methods/isPointerEventWithinInRefList';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Adds click event listeners to the document to call the onClose function if the event target is an element outside of the dropdown. Removes the event listener on unmount.
 */
export function useClickAway(refs: React.MutableRefObject<HTMLElement | null>[], onClose: (e: PointerEvent | FocusEvent) => void) {
    /***** FUNCTIONS *****/
    const clickAway = (e: MouseEvent) => {
        const shouldClose = !isPointerEventWithinRefList(e, refs);

        if (shouldClose) {
            onClose(e);
        }
    };

    /***** EFFECTS *****/
    useEventListener('blur', onClose);
    useEffect(() => {
        document.addEventListener('click', clickAway);

        return () => {
            document.removeEventListener('click', clickAway);
        };
    }, [onClose, refs]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
