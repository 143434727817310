/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { textLowerCase } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { redirectMessages } from './messageContent';
import { redirectUrls } from './redirectUrls';

/**
 * Handle redirect by setting the correct service type and status.
 * No status for service type means nothing happens
 * @param {{
 *      serviceType: "domain" | "hosting" | "vps" | "google_workspace" | "email_hosting" | "ssl",
 *      status: "pending" | "expired" | "pending_transfer" | "redemption" | "transferred_away" | "cancelled" | "pending_cancellation" | "suspended" | "terminated" | "validation_submitted",
 *      domainName: string,
 *      history: func
 * }} parameters
 */
export function handleRedirect({ serviceType, status, domainName, history }) {
    // make lower case and replace spaces with underscores
    const formattedStatus = textLowerCase(status).replace(/ /g, '_');

    if (!has(redirectMessages, serviceType) || !has(redirectMessages[serviceType], formattedStatus)) return;

    const message = redirectMessages[serviceType][formattedStatus](domainName);

    const targetURL = redirectUrls[serviceType];

    history.push(targetURL);

    pushNotification({
        status: 400,
        details: message
    });
}
