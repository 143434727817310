/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { getIndividualTicket } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Reopen extends Component {
    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const {
            match: {
                params: { param4 }
            },
            getIndividualTicket
        } = this.props;

        getIndividualTicket(param4);
    }

    componentDidUpdate(prevProps) {
        const { support_individual_ticket_status, support_individual_ticket_meta, history } = this.props;

        if (support_individual_ticket_status === 'success' && prevProps.support_individual_ticket_status === 'loading') {
            const department = support_individual_ticket_meta.department.toLowerCase();

            let departmentUrlParam;
            switch (department) {
                case 'accounts and billing':
                    departmentUrlParam = 'accounts-billing?';
                    break;
                case 'sales':
                    departmentUrlParam = 'sales?invoice=na&';
                    break;
                case 'other':
                case 'compliment':
                case 'customer care':
                case 'complaint':
                    departmentUrlParam = 'customer-care/feedback?invoice=na&service=na&';
                    break;
                case 'technical support':
                case 'n/a':
                default:
                    departmentUrlParam = 'technical-support?invoice=na&service=select&';
                    break;
            }

            history.push(`/support/tickets/submit/${departmentUrlParam}referenced-ticket=${support_individual_ticket_meta.mask}`);
        }
    }

    render() {
        const { support_individual_ticket_status } = this.props;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return support_individual_ticket_status === 'error' ? <FetchComponentError /> : <RequestLoader />;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            support_individual_ticket_status: state.support.support_individual_ticket_status,
            support_individual_ticket_meta: state.support.support_individual_ticket_meta
        }),
        {
            getIndividualTicket
        }
    )(Reopen)
);
