/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BillingCycleForm from '../forms/billinCycleForm';
import RenewForm from '../forms/renewForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import DialogNotification from 'components/Notifications/DialogNotification';
import { ScrollableComponent } from 'components/ScrollableComponent';

/*   ACTIONS
 *****************************************************/
import { hostingRenewPromoBillingCycles } from 'config/config';
import { activePromotion, getIncludedObjBasedOnType, renderPrepaidForDuration, toLuxonDate } from 'utilities/methods/commonActions';
import {
    getHostingBillingCycles,
    getHostingInformation,
    getPrepayInformation,
    prepayHostingService,
    updateBillingCycle
} from '../state/accountActions';
import { getActiveAddons } from '../state/baseActions';

function getRegenerateWithDiscountOptions(hosting_prepay_info_data) {
    return Object.entries(hosting_prepay_info_data?.prices || {})
        .filter(([, { discount_available, allow_regeneration }]) => discount_available && allow_regeneration)
        .map(([key]) => key);
}

function checkCanRegenerateWithDiscount(hosting_prepay_info_data, invoiceId) {
    const regenerateWithDiscountOptions = getRegenerateWithDiscountOptions(hosting_prepay_info_data);

    /**
     * Whenever there is a promotion that requires invoice regeneration insert the promotion's config key into this so that it verifies it during the promotion period
     * i.e.: `activePromotion('mega_may_2024') && invoiceID && !!this.options.length`
     */
    return activePromotion('megamay2024') && invoiceId && !!regenerateWithDiscountOptions.length;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class BillingHosting extends Component {
    constructor(props) {
        super(props);

        this.toggleInvoiceLightbox = this.toggleInvoiceLightbox.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.toggleBillingForm = this.toggleBillingForm.bind(this);
        this.togglePrepayForm = this.togglePrepayForm.bind(this);
        this.submitBillingRequest = this.submitBillingRequest.bind(this);
        this.submitPrepayRequest = this.submitPrepayRequest.bind(this);

        this.state = {
            showBillingForm: false,
            billingOptions: [],
            showPrepayForm: false,
            prepayOptions: [],
            prepayPrices: [],
            showInvoiceLightbox: false,
            invoiceID: null,
            billingInformation: {
                cycle: undefined,
                invoice: undefined,
                prepaid: undefined
            }
        };
    }

    /************** INVOICE TOGGLES **************/

    toggleInvoiceLightbox(id) {
        const { getHostingInformation, serviceid } = this.props;

        this.setState(
            {
                showInvoiceLightbox: !this.state.showInvoiceLightbox,
                invoiceID: id || null
            },
            () => {
                if (!id) getHostingInformation(serviceid);
            }
        );
    }

    closePayInvoiceSuccess() {
        const { getHostingInformation, getActiveAddons, serviceid } = this.props;

        this.setState(
            {
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getHostingInformation(serviceid);
                getActiveAddons(serviceid);
            }
        );
    }

    /************** FORM TOGGLES **************/

    /**
     * @param {boolean} [state]
     */
    toggleBillingForm(state) {
        this.setState({
            showBillingForm: state ? state : !this.state.showBillingForm,
            showPrepayForm: false
        });
    }

    /**
     * @param {boolean} [state]
     */
    togglePrepayForm(state) {
        this.setState({
            showPrepayForm: state ? state : !this.state.showPrepayForm,
            showBillingForm: false
        });
    }

    /************** SUBMIT METHODS **************/
    submitBillingRequest(values) {
        const { serviceid, updateBillingCycle } = this.props;
        const { invoiceID } = this.state;

        const attributes = {
            billing_cycle_id: values.newBillingCycle
        };
        updateBillingCycle(serviceid, attributes, invoiceID);
    }

    submitPrepayRequest(values) {
        const { serviceid, prepayHostingService } = this.props;
        const attributes = {
            billing_cycle: values.cycle
        };
        prepayHostingService(serviceid, attributes);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { hosting_information_data, hosting_prepay_info_data, getPrepayInformation, getHostingBillingCycles, serviceid, location } = this.props;
        const { toggleBillingForm, togglePrepayForm } = this;

        getPrepayInformation(serviceid);
        getHostingBillingCycles(serviceid);

        if (hosting_information_data) {
            const {
                attributes: { billing_cycle, next_invoice_date, next_due_date }
            } = hosting_information_data;
            const invoice = getIncludedObjBasedOnType(hosting_information_data.included, 'invoice');

            const prepaidFor = renderPrepaidForDuration(toLuxonDate(next_due_date, 'yyyy-MM-dd TT'));

            this.setState({
                invoiceID: invoice ? invoice.id : null,
                billingInformation: {
                    cycle: billing_cycle,
                    invoice: toLuxonDate(next_invoice_date, 'yyyy-MM-dd TT').toFormat('cccc, d LLLL y'),
                    prepaid: prepaidFor
                }
            });
        }

        // TODO: refactor this to use url dropdown
        const { pathname } = location;
        const query = new URLSearchParams(location.search);
        const open = query.get('open');
        const type = query.get('type');

        if (open && pathname?.includes('renew')) {
            const invoice = getIncludedObjBasedOnType(hosting_information_data?.included, 'invoice');
            const canRegenerateWithDiscount = checkCanRegenerateWithDiscount(hosting_prepay_info_data, invoice?.id);

            if (type === 'renew') {
                if (!invoice || canRegenerateWithDiscount) {
                    togglePrepayForm(true);
                }
            } else {
                toggleBillingForm(true);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            hosting_information_data,
            hosting_billing_status,
            hosting_prepay_info_status,
            hosting_prepay_info_data,
            hosting_prepay_status,
            hosting_prepay_data,
            hosting_billingcycle_status,
            hosting_billingcycle_data,
            serviceid,
            getHostingInformation,
            location
        } = this.props;
        const { toggleBillingForm, togglePrepayForm } = this;

        if (location !== prevProps.location) {
            const { pathname } = location;
            const query = new URLSearchParams(location.search);
            const open = query.get('open');
            const type = query.get('type');

            if (open && pathname?.includes('renew')) {
                const invoice = getIncludedObjBasedOnType(hosting_information_data?.included, 'invoice');
                const canRegenerateWithDiscount = checkCanRegenerateWithDiscount(hosting_prepay_info_data, invoice?.id);

                if (type === 'renew') {
                    if (!invoice || canRegenerateWithDiscount) {
                        togglePrepayForm(true);
                    }
                } else {
                    toggleBillingForm(true);
                }
            }
        }

        if (hosting_prepay_info_status === 'success' && prevProps.hosting_prepay_info_status === 'loading') {
            const { prices } = hosting_prepay_info_data;

            const processOptions = (options) => {
                if (!options) return [];

                const {
                    attributes: { billing_cycle_name }
                } = hosting_information_data;

                /**
                 * Whenever there is a promotion that requires invoice regeneration insert the promotion's config key into this so that it verifies it during the promotion period
                 * i.e.: `activePromotion('mega_may_2024') && invoiceID && !!this.options.length`
                 */
                const optionsToDisplay = activePromotion()
                    ? Object.entries(options).filter(([key]) => hostingRenewPromoBillingCycles[key])
                    : Object.entries(options);

                return optionsToDisplay.map(([key, value]) => {
                    return {
                        label: `${value.price}${key === billing_cycle_name ? ' (Current Billing Cycle)' : ''}`,
                        value: key
                    };
                });
            };

            this.setState({
                prepayPrices: prices,
                prepayOptions: processOptions(prices)
            });
        }

        if (hosting_billing_status === 'success' && prevProps.hosting_billing_status === 'loading') {
            this.setState(
                {
                    showBillingForm: false
                },
                () => getHostingInformation(serviceid)
            );
        }

        if (hosting_billingcycle_status === 'success' && prevProps.hosting_billingcycle_status === 'loading') {
            const { billing_cycles } = hosting_billingcycle_data.attributes;

            const billingOptions = billing_cycles.map((item) => ({
                label: `${item.name.name} - $${item.amount} AUD${
                    hosting_information_data && hosting_information_data.attributes.billing_cycle_name === item.name.name ? ' (Same As Current)' : ''
                }`,
                value: item.id
            }));

            this.setState({
                billingOptions
            });
        }

        if (hosting_prepay_status === 'success' && prevProps.hosting_prepay_status === 'loading') {
            const { id } = hosting_prepay_data;

            this.setState({
                showPrepayForm: false,
                showInvoiceLightbox: true,
                invoiceID: id
            });
        }

        if (hosting_prepay_status === 'error' && prevProps.hosting_prepay_status === 'loading') {
            this.setState({
                showPrepayForm: false,
                showInvoiceLightbox: false,
                invoiceID: null
            });
        }
    }

    render() {
        const {
            serviceid,
            hosting_information_data,
            hosting_billing_status,
            hosting_prepay_status,
            hosting_billingcycle_status,
            hosting_prepay_info_status,
            hosting_prepay_info_data
        } = this.props;
        const { showBillingForm, showPrepayForm, showInvoiceLightbox, invoiceID, billingInformation, billingOptions, prepayOptions, prepayPrices } =
            this.state;
        const { toggleBillingForm, togglePrepayForm, toggleInvoiceLightbox, closePayInvoiceSuccess, submitBillingRequest, submitPrepayRequest } =
            this;

        const invoice = getIncludedObjBasedOnType(hosting_information_data?.included, 'invoice');
        const regenerateWithDiscountOptions = getRegenerateWithDiscountOptions(hosting_prepay_info_data);
        const canRegenerateWithDiscount = checkCanRegenerateWithDiscount(hosting_prepay_info_data, invoice?.id);

        const renderBillingCycleButton = () => {
            return (
                <OutlineButton
                    className="hostingBilling__button"
                    size="large"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleBillingForm();
                    }}
                >
                    Change
                </OutlineButton>
            );
        };

        const renderPrepayButton = () => {
            if (!invoiceID)
                return (
                    <OutlineButton
                        className="hostingBilling__button"
                        size="large"
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            togglePrepayForm();
                        }}
                    >
                        Renew
                    </OutlineButton>
                );

            if (canRegenerateWithDiscount)
                return (
                    <OutlineButton
                        className="hostingBilling__button"
                        size="large"
                        type="onClick"
                        onClick={(e) => {
                            e.preventDefault();
                            togglePrepayForm();
                        }}
                    >
                        Apply Discount
                    </OutlineButton>
                );

            return (
                <InactiveButton className="hostingBilling__button" type="onClick" size="large">
                    <i className="icon icon-lock"></i>Renew
                </InactiveButton>
            );
        };

        /*   HANDLE INVOICE STATUS
         **********************************************************************************************************/
        const renderPayInvoiceNotification = () => {
            return (
                <DialogNotification type="warning" className="changeResources__payInvoiceNotification">
                    <DialogNotification.ButtonsContainer
                        text=" You have an outstanding invoice for this service. Please, contact our Billing Team for further assistance."
                        buttons={[
                            <SolidButton
                                key={1}
                                type="onClick"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleInvoiceLightbox(invoiceID);
                                }}
                                size="medium"
                                color="white"
                            >
                                Pay Invoice
                            </SolidButton>
                        ]}
                    />
                </DialogNotification>
            );
        };

        /*   HANDLE COLUMN RENDER
         **********************************************************************************************************/
        const handleBoxLoadingStatus = () => {
            if (
                hosting_billingcycle_status === 'loading' ||
                hosting_billing_status === 'loading' ||
                hosting_prepay_status === 'loading' ||
                hosting_prepay_info_status === 'loading'
            ) {
                return 'loading';
            }

            if (
                hosting_billingcycle_status === 'error' ||
                hosting_billing_status === 'error' ||
                hosting_prepay_status === 'error' ||
                hosting_prepay_info_status === 'error'
            ) {
                return 'error';
            }

            return 'success';
        };

        /*   CONDITIONAL PROPS
         **********************************************************************************************************/
        const conditionalProps = {
            columns: [
                {
                    render: (
                        <div className="billingCycle__column">
                            <div className="title">Current Billing Cycle</div>
                            <div className="desc">{billingInformation.cycle}</div>
                        </div>
                    )
                },
                {
                    render: (
                        <div className="billingCycle__column">
                            <div className="title">Next Invoice Date</div>
                            <div className="desc">{billingInformation.invoice}</div>
                        </div>
                    )
                }
            ],
            dropdown: {
                title: 'Change Billing Cycle',
                render: <BillingCycleForm onSubmit={submitBillingRequest} options={billingOptions} />,
                condition: showBillingForm,
                close: () => toggleBillingForm(),
                status: hosting_billing_status
            },
            columnsAtTop: true
        };

        if (billingInformation.prepaid) {
            conditionalProps.columns.push({
                render: (
                    <div className="hostingRenew__column">
                        <div className="title">Prepaid For</div>
                        <div className="desc">{billingInformation.prepaid}</div>
                    </div>
                )
            });
        }

        if (showPrepayForm) {
            conditionalProps.dropdown = {
                title: `Renew For`,
                render: (
                    <RenewForm
                        hostingid={serviceid}
                        // If we're showing the form while there's an unpaid invoice, then that means the customer must be regenerating the invoice to have a discount applied, so we use only the billing cycles with available discounts
                        options={invoiceID ? prepayOptions.filter(({ value }) => regenerateWithDiscountOptions.includes(value)) : prepayOptions}
                        prices={prepayPrices}
                        submitPrepayRequest={submitPrepayRequest}
                    />
                ),
                condition: showPrepayForm,
                close: () => togglePrepayForm(),
                status: hosting_prepay_status
            };
        }

        const isReady = hosting_billingcycle_status === 'success' && hosting_prepay_info_status === 'success';

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <ScrollableComponent ready={isReady} className="hostingBilling">
                <Box
                    premounted={true}
                    className="hostingBilling__box"
                    title="Billing & Payments"
                    status={handleBoxLoadingStatus()}
                    custom={{
                        render: (
                            <>
                                <div className="hostingBilling__box--container">
                                    <div className="hostingBilling__box--title">Billing Cycle</div>
                                    <div className="hostingBilling__box--section">
                                        <div className="hostingBilling__box--desc">
                                            {invoiceID
                                                ? `You can change your billing cycle to make when you’re invoiced more convenient. Changing your billing cycle now will reissue your current outstanding invoice #${invoiceID} with your new billing cycle.`
                                                : "You can change your billing cycle to make when you're invoiced more convenient. You will be invoiced on the new billing cycle at the end of your current prepaid period."}
                                        </div>
                                        {renderBillingCycleButton()}
                                    </div>
                                </div>
                                <div className="hostingBilling__box--container">
                                    {invoiceID ? renderPayInvoiceNotification() : null}
                                    <div className="hostingBilling__box--title">Renew</div>
                                    <div className="hostingBilling__box--section">
                                        <div className="hostingBilling__box--desc">
                                            {canRegenerateWithDiscount
                                                ? 'Looks like you are eligible for a discount on your renewal invoice. Click "Apply Discount" to cancel your current invoice and generate a new one with the discount applied.'
                                                : 'Keep a good thing going by renewing for your service today.'}
                                        </div>
                                        {renderPrepayButton()}
                                    </div>
                                </div>
                            </>
                        ),
                        pos: `bottom`
                    }}
                    {...conditionalProps}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceID}
                        invoiceid={invoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={toggleInvoiceLightbox}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
            </ScrollableComponent>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            hosting_information_data: state.hosting.hosting_information_data,
            hosting_billing_status: state.hosting.hosting_billing_status,
            hosting_prepay_status: state.hosting.hosting_prepay_status,
            hosting_prepay_data: state.hosting.hosting_prepay_data,
            hosting_billingcycle_status: state.hosting.hosting_billingcycle_status,
            hosting_billingcycle_data: state.hosting.hosting_billingcycle_data,
            hosting_prepay_info_status: state.hosting.hosting_prepay_info_status,
            hosting_prepay_info_data: state.hosting.hosting_prepay_info_data
        }),
        {
            getActiveAddons,
            getHostingBillingCycles,
            getHostingInformation,
            getPrepayInformation,
            updateBillingCycle,
            prepayHostingService
        }
    )(BillingHosting)
);
