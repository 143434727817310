/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ticket_name } from 'config/config';
import htmr from 'htmr';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import ChangePlanLightbox from 'components/Lightboxes/OverlayLightbox/Components/changePlan';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import UpgradeConfirmForm from '../forms/upgradeConfirm';

/*   ACTIONS
 *****************************************************/
import { getVPSProducts, upgradeVPSService } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

import useSearchParams from 'utilities/hooks/useSearchParams';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import upgradeBannerBackground from 'assets/images/vps/vps_banner_background.jpeg';
import upgradeVPSImage from 'assets/images/vps/vps_banner_icon.svg';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {(typeof lightboxTemplates)[keyof typeof lightboxTemplates]} TLightboxTemplates
 */

const lightboxTemplates = /** @type {const} */ ({
    PLAN: 'Plan',
    REVIEW: 'Review',
    LOADING: 'Loading',
    LEGACY: 'Legacy',
    DOWNGRADE: 'Downgrade'
});

let Upgrade = (props) => {
    const {
        /**
         * React Router Props
         */
        history,

        /**
         * Redux Props
         */
        vps_information_data,
        vps_upgrade_list_data,
        vps_upgrade_list_status,
        upgradeVPSService,
        getVPSProducts,
        vps_upgrade_status
    } = props;

    /***** STATE *****/
    const [isShowingLightbox, setIsShowingLightbox] = useState(false);
    const [lightboxTemplate, setLightboxTemplate] = useState(/** @type {TLightboxTemplates} */ (lightboxTemplates.PLAN));
    const [currentPlan, setCurrentPlan] = useState(null);
    const [selectedData, setSelectedData] = useState({
        product_id: null,
        current: null,
        selected: null,
        billing_cycle: null,
        new_price: null
    });
    const [selectedReviewData, setSelectedReviewData] = useState({
        currentPlanName: null,
        newPlanName: null,
        newCycle: null,
        newCost: null
    });

    /***** HOOKS *****/
    const { searchParamsGet, searchParamsRemove } = useSearchParams();
    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const scrollRef = useRef(null);

    const openLightbox = (template) => {
        setIsShowingLightbox(true);
        setLightboxTemplate(template);
    };

    const closeLightbox = () => {
        setIsShowingLightbox(false);
    };

    const handleConfirmBack = () => {
        openLightbox(lightboxTemplates.PLAN);
    };

    const redirectDowngrade = () => {
        history.push(`/support/tickets/submit/accounts-billing`);
    };

    const getRequestVPSType = () => {
        const { attributes } = vps_information_data;
        const { vps_type } = attributes;

        if (vps_type === 'self_managed') {
            return 'svps';
        } else if (vps_type === 'fully_managed') {
            return 'fvps';
        } else {
            return 'lvps';
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, vps_upgrade_list_status === 'success');
    }, [vps_upgrade_list_status]);

    useEffect(() => {
        const requestVPSType = getRequestVPSType();
        const { included } = vps_information_data;

        getVPSProducts(requestVPSType);

        setCurrentPlan(included?.[0]?.attributes?.name ?? null);

        if (searchParamsGet('upgrade-plan')) {
            openLightbox(lightboxTemplates.PLAN);
            searchParamsRemove('upgrade-plan');
        }
    }, []);

    useEffect(() => {
        if (vps_upgrade_status === 'success') {
            closeLightbox();
        }
    }, [vps_upgrade_status]);

    /***** RENDER HELPERS *****/
    const renderLightbox = () => {
        switch (lightboxTemplate) {
            case lightboxTemplates.LOADING:
                return (
                    <OverlayLightbox
                        onOpen={isShowingLightbox}
                        className="vpsUpgrade__lightbox"
                        onClose={closeLightbox}
                        title="Review VPS Hosting Upgrade"
                    >
                        <div className="vpsUpgrade__lightbox--loading">
                            <RequestLoader />
                            <Text secondary>Verifying Upgrade Information...</Text>
                        </div>
                    </OverlayLightbox>
                );

            case lightboxTemplates.DOWNGRADE: {
                const confirmDowngrade = {
                    desc: htmr(`In order to downgrade this service, you will need to submit a new ${ticket_name} to our Accounts and Billing team.`),
                    buttonText: `Submit ${ticket_name}`,
                    buttonAction: redirectDowngrade,
                    closeText: 'No, Go Back',
                    closeAction: handleConfirmBack
                };

                return (
                    <OverlayLightbox
                        onOpen={isShowingLightbox}
                        onClose={closeLightbox}
                        title="Downgrade Hosting Service"
                        confirm={confirmDowngrade}
                    />
                );
            }

            case lightboxTemplates.LEGACY: {
                const planOptions = [
                    {
                        name,
                        description: '',
                        price: () => vps_information_data.attributes.amount,
                        details: []
                    },
                    {
                        name: 'Self Managed VPS',
                        description: 'Experience the ultimate speed, reliability, and freedom with Australia’s most powerful VPS platform.',
                        price: () => '79.95*',
                        details: [],
                        customButton: () => <OutlineButton to="/support/tickets/submit/sales">Contact Support</OutlineButton>
                    },
                    {
                        name: 'Fully Managed VPS',
                        description: 'Fully managed by our hosting experts, completely optimised for your online business.',
                        price: () => '279.95*',
                        details: [],
                        customButton: () => <OutlineButton to="/support/tickets/submit/sales">Contact Support</OutlineButton>
                    }
                ];

                return (
                    <ChangePlanLightbox
                        isOpen={isShowingLightbox}
                        upgradeType="VPS Hosting"
                        planOptions={planOptions}
                        currentPlan={name}
                        onClose={closeLightbox}
                        onSelectPlan={() => {}}
                        isLoading={vps_information_data === 'loading'}
                        reviewData={selectedReviewData}
                        className="VPS-Upgrade-Legacy"
                        selectPlanCustomInformation={
                            <Text secondary size--m align--center>
                                Legacy Products do not have direct upgrade paths, contact our support team for enquiries or refer to our website for
                                full pricing and service specifications.
                            </Text>
                        }
                    />
                );
            }

            case lightboxTemplates.PLAN:
            case lightboxTemplates.REVIEW:
            default: {
                const billingCycle = {
                    current: vps_information_data.attributes.billing_cycle,
                    available: vps_upgrade_list_status === 'success' ? Object.keys(vps_upgrade_list_data[0].attributes.price) : []
                };

                const planOptions = vps_upgrade_list_data?.map((data) => {
                    const { attributes = {} } = data;

                    return {
                        name: attributes?.name,
                        description: attributes?.description,
                        price: (cycle) => attributes?.price[cycle],
                        rawData: data,
                        details: []
                    };
                });

                const onSelectPlan = ({ name, cycle, price, rawData }) => {
                    const { product_id } = vps_information_data.attributes;
                    const { id } = rawData;

                    const selectedPlanIndex = vps_upgrade_list_data.findIndex((data) => data.id === rawData.id);
                    const currentPlanIndex = vps_upgrade_list_data.findIndex((data) => data.id === product_id);
                    const isDowngrade = currentPlanIndex > selectedPlanIndex;

                    setSelectedReviewData({
                        name,
                        price,
                        total: price
                    });
                    setSelectedData({
                        product_id: id,
                        current: currentPlan,
                        selected: name,
                        billing_cycle: cycle,
                        new_price: price
                    });

                    if (isDowngrade) {
                        setLightboxTemplate(lightboxTemplates.DOWNGRADE);
                    }
                };

                const onConfirm = (values) => {
                    const { id } = vps_information_data;
                    const attributes = {
                        ...selectedData,
                        ...values
                    };
                    upgradeVPSService(id, attributes);

                    setLightboxTemplate('Loading');
                };

                return (
                    <ChangePlanLightbox
                        isOpen={isShowingLightbox}
                        billingCycle={billingCycle}
                        upgradeType="VPS Hosting"
                        planOptions={planOptions}
                        currentPlan={name}
                        onClose={closeLightbox}
                        onSelectPlan={onSelectPlan}
                        isLoading={vps_upgrade_list_status === 'loading'}
                        reviewData={selectedReviewData}
                        onConfirm={onConfirm}
                        customConfirm={() => <UpgradeConfirmForm onSubmit={onConfirm} />}
                    />
                );
            }
        }
    };

    const { included } = vps_information_data;
    const includedProduct = getIncludedObjBasedOnType(included, 'product');
    const { name } = includedProduct.attributes;

    /***** RENDER *****/
    return (
        <div ref={scrollRef}>
            <NXBox className="VPS-Upgrade">
                <ServiceOverviewBanner slim src={upgradeBannerBackground}>
                    <ServiceOverviewBanner.ContentWrapper>
                        <ServiceOverviewBanner.Heading>Need more resources?</ServiceOverviewBanner.Heading>
                        <ServiceOverviewBanner.Description>Upgrade your VPS service</ServiceOverviewBanner.Description>
                    </ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Image src={upgradeVPSImage} />
                </ServiceOverviewBanner>

                <NXBox.Bottom>
                    <div className="VPS-Upgrade__plan">
                        <div className="VPS-Upgrade__plan-label">Current Plan</div>
                        <div className="VPS-Upgrade__plan-value">{name}</div>
                    </div>
                    <div className="VPS-Upgrade__button-wrapper">
                        <OutlineButton
                            type="onClick"
                            onClick={() => {
                                openLightbox(getRequestVPSType() === 'lvps' ? 'Legacy' : 'Plans');
                            }}
                        >
                            View Plans
                        </OutlineButton>
                    </div>
                </NXBox.Bottom>
            </NXBox>
            {!!isShowingLightbox && renderLightbox()}
        </div>
    );
};

const mapStateToProps = (state) => ({
    vps_information_data: state.vps.vps_information_data,
    vps_upgrade_list_status: state.vps.vps_upgrade_list_status,
    sidebarRefs: state.sidebar.sidebarRefs,
    vps_upgrade_list_data: state.vps.vps_upgrade_list_data,
    vps_upgrade_data: state.vps.vps_upgrade_data,
    vps_upgrade_status: state.vps.vps_upgrade_status
});

const mapDispatchToProps = {
    getVPSProducts,
    upgradeVPSService
};

Upgrade = connect(mapStateToProps, mapDispatchToProps)(Upgrade);

Upgrade = withRouter(Upgrade);

export default Upgrade;
