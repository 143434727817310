/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show the date the domain was registered or transferred to us
 * @param {{
 *       orderType: 'transfer' | 'registration'
 *       registrationDate: string
 *       registeredMessage: string
 * }} props
 */
export function DomainRegisteredDate({ orderType, registrationDate, registeredMessage }) {
    /***** RENDER *****/
    return (
        <NXBox.OverviewRow>
            <NXBox.InfoPair
                title={orderType === 'transfer' ? 'Transfer Date' : 'Registered'}
                description={
                    <Text>
                        {toLuxonDate(registrationDate, 'yyyy-MM-dd TT').toFormat('ccc LLL d y')}&nbsp;
                        <Text secondary size--s italic>
                            <span>{registeredMessage}</span>
                        </Text>
                    </Text>
                }
            />
        </NXBox.OverviewRow>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainRegisteredDate;
