/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SelectOption.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SelectOption({ label, value, isActive, onSelect }) {
    /***** RENDER *****/
    return (
        <li className={classNames('SelectOption', { 'SelectOption--active': isActive })}>
            <button
                onClick={() => {
                    onSelect();
                }}
            >
                {label || value}
            </button>
        </li>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const selectOptionPropTypes = {
    /**
     * Any ui, to be shown as the option label. Falls back to "value" if no label is provided
     */
    label: PropTypes.node,

    /**
     * The option value
     */
    value: PropTypes.string,

    /**
     * Whether or not the option is currently active
     */
    isActive: PropTypes.bool,

    /**
     * Function to call when option is selected
     */
    onSelect: PropTypes.func
};

SelectOption.propTypes = selectOptionPropTypes;

export default SelectOption;
