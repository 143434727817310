/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAcceptMimeTypes } from 'containers/katana/formFields/file/methods';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      targetProperty: import('containers/katana/types').KatanaNamespace.SectionDefinitions.PropertiesModified;
 *      provideValue: UseKatanaFileUpload.OnChange;
 *      serviceID: number;
 *      fileLimit?: number;
 *      fields: import('redux-form').FieldArrayFieldsProps;
 * }>}
 */
const EntryCreationBehaviourHandlerOnImageAddWithKatanaImageUpload = ({ targetProperty, provideValue, serviceID, fileLimit, fields }) => {
    /***** RENDER HELPERS *****/
    const targetMimeCaveat = useGetCaveat(CaveatTypes.IMAGE_MIME_TYPE, targetProperty.caveats);
    const dropzoneMimeTypeAccept = createAcceptMimeTypes(targetMimeCaveat);

    /***** FUNCTIONS *****/
    /**
     * @param {UseKatanaFileUpload.Values} values
     */
    function handleOnChange(values) {
        if (Array.isArray(values)) {
            values?.forEach(provideValue);
        } else {
            provideValue(values);
        }
    }

    const allFields = fields.getAll();

    /***** RENDER *****/
    return (
        <NXDropZone.Tanstack.WithKatanaFileUpload
            serviceID={serviceID}
            onChange={handleOnChange}
            value={allFields}
            accept={dropzoneMimeTypeAccept}
            fileLimit={fileLimit}
            preventFileDisplay
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { EntryCreationBehaviourHandlerOnImageAddWithKatanaImageUpload };
