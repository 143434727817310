/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import Box from 'components/Box/';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function TicketDepartmentSelect() {
    /***** RENDER HELPERS *****/
    const options = [
        {
            icon: 'icon-contact-support',
            title: 'Technical Support',
            description: 'Are you having trouble with a domain name or hosting service?',
            urlEnding: 'technical-support?invoice=na&service=select'
        },
        {
            icon: 'icon-accounts',
            title: 'Accounts & Billing',
            description: 'Have an accounts or billing enquiry that we can help with?',
            urlEnding: 'accounts-billing'
        },
        {
            icon: 'icon-contact-sales',
            title: 'Sales',
            description: 'Do you have a question about one of our products or services?',
            urlEnding: 'sales?invoice=na'
        },
        {
            icon: 'icon-contact-customer-care',
            title: 'Customer Care',
            description: 'Would you like to provide feedback on your experience?',
            urlEnding: 'customer-care/feedback?invoice=na&service=na'
        }
    ];

    const renderBox = (index, icon, title, description, urlEnding) => {
        return (
            <Box
                key={index}
                premounted={true}
                custom={{
                    render: (
                        <div className="ticketSubmit__box">
                            <div className="ticketSubmit__boxLeft">
                                <i className={`ticketSubmit__boxIcon icon ${icon}`}></i>
                                <div className="ticketSubmit__boxText">
                                    <div className="ticketSubmit__boxTitle">{title}</div>
                                    <div className="ticketSubmit__boxDescription">{description}</div>
                                </div>
                            </div>
                            <OutlineButton to={`/support/tickets/submit/${urlEnding}`} className="ticketSubmit__button">
                                Select
                            </OutlineButton>
                        </div>
                    ),
                    pos: 'top'
                }}
            />
        );
    };

    const renderBoxes = () => {
        return options.map((option, index) => {
            const { icon, title, description, urlEnding } = option;
            return renderBox(index, icon, title, description, urlEnding);
        });
    };

    /***** RENDER *****/
    return (
        <div className="ticketSubmit">
            <div className="ticketSubmit__heading">Select the department you wish to contact</div>
            {renderBoxes()}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TicketDepartmentSelect;
