/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { cloneDeep, has, set } from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SetQueryDataMethod<TParams extends any, TDataObject extends object> = (
    params: TParams,
    updater: (oldData: TDataObject | undefined) => TDataObject | undefined
) => void;

export function createOptimisticUpdateMethod<TParams extends any, TDataObject extends object & { status?: 200 }>(
    setQueryData: SetQueryDataMethod<TParams, TDataObject>
) {
    function optimisticUpdate<TKey extends NXUtils.Path<TDataObject>>(
        params: TParams,
        updatePath: TKey,
        valueToUpdatePathWith: NXUtils.Choose<TDataObject, TKey>
    ) {
        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            const clonedData = cloneDeep(oldData);
            if (!has(clonedData, updatePath)) {
                return;
            }

            set(clonedData, updatePath, valueToUpdatePathWith);

            return clonedData;
        });
    }
    return optimisticUpdate;
}
