/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { ticket_name } from 'config/config';
/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import RequestLoader from 'components/Loaders/Request';
import DialogNotification from 'components/Notifications/DialogNotification';
import Transition from 'components/Transition';

import { clearConfiguration, csrConfirmation, getCsrInformation, getSslBeacon, sslConfiguration, sslValidation } from '../action';

import ConfigError from '../forms/configError';
import ConfigReview from '../forms/configReview';
import ConfigSelect from '../forms/configSelect';
import Validate from '../forms/validate';

const SSL_TYPES = /** @type {const} */ ({
    DV: 'DV',
    OV: 'OV',
    EV: 'EV'
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SSLConfigureModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                status: true,
                message: 'Checking current SSL configuration'
            },
            step: {
                current: ``,
                list: [
                    // Sidenav step data
                    { key: 'configure', title: 'Configure SSL Certificate' },
                    { key: 'review', title: 'Review SSL Certificate Details' },
                    { key: 'validate', title: 'Validate Domain Name' }
                ],
                status: {
                    // Used to check step completion
                    configure: false,
                    review: false,
                    validate: false
                }
            },
            view: {
                // Handles the current view template strings
                title: false,
                desc: false
            },
            vendor: {
                name: props.cert.vendor,
                email: props.cert.email
            },
            showInfoDropdown: false
        };
        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleSetupStep = this.handleSetupStep.bind(this);
        this.handleConfiguration = this.handleConfiguration.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.handleEditDetails = this.handleEditDetails.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.toggleInfoDropdown = this.toggleInfoDropdown.bind(this);
    }

    toggleInfoDropdown() {
        const { showInfoDropdown } = this.state;

        this.setState({
            showInfoDropdown: !showInfoDropdown
        });
    }

    toggleLoading(cb, message) {
        const { loading } = this.state;

        this.setState(
            {
                loading: {
                    status: !loading.status,
                    message: message ? message : false
                }
            },
            () => {
                if (cb) {
                    cb();
                }
            }
        );
    }

    handleSetupStep(nextStep) {
        const { loading } = this.state;
        const { history, match } = this.props;
        const { params } = match;
        const { id } = params;
        const { step } = this.state;
        let view = {
            title: '',
            desc: ''
        };
        let preStep = nextStep ? nextStep : undefined;

        if (!preStep) {
            Object.keys(step.status).forEach((i) => {
                if (!preStep && !step.status[i]) {
                    preStep = i;
                }
            });
        }

        switch (preStep) {
            case 'configure':
                view = {
                    title: 'Configure Your SSL Certificate',
                    desc: 'Select SSL configuration method.'
                };
                history.replace(`/my-services/ssl/configure/configuration/${id}`);
                break;

            case 'review':
                view = {
                    title: 'Review SSL Certificate Details',
                    desc: 'Please review your information below to ensure its accuracy.'
                };
                history.replace(`/my-services/ssl/configure/review/${id}`);
                break;

            case 'validate':
                view = {
                    title: 'Validate Domain Name',
                    desc: htmr(
                        String.raw`Now that your SSL Certificate order has been placed, you'll be required to validate your domain name. Follow the steps below to complete this process. <strong>If you have already completed the validation process, then no further action is required. Premium SSL Certificates may require additional manual verification.</strong>`
                    )
                };
                history.replace(`/my-services/ssl/validate/validation/${id}`);
                break;

            case 'further':
                view = {
                    title: 'SSL Certificate Validation',
                    desc: 'Please note that certain SSL certificates require additional manual verification. Please check your mailbox for further information from the certificate authority.'
                };
                history.replace(`/my-services/ssl/validate/further/${id}`);
                break;

            default:
                break;
        }

        this.setState(
            {
                step: {
                    ...step,
                    current: preStep
                },
                view
            },
            () => {
                if (loading.status) this.toggleLoading();
            }
        );
    }

    handleConfiguration(values) {
        const { sslid, sslConfiguration } = this.props;
        this.toggleLoading(sslConfiguration(sslid, values));
    }

    handleConfirmation() {
        const { sslid, csrConfirmation } = this.props;
        this.toggleLoading(csrConfirmation(sslid));
    }

    handleEditDetails() {
        const { sslid, clearConfiguration } = this.props;
        this.toggleLoading(clearConfiguration(sslid));
    }

    handleValidation(values) {
        const { sslid, sslValidation } = this.props;
        this.toggleLoading(sslValidation(sslid, values));
    }

    componentDidMount() {
        const { sslid, getCsrInformation } = this.props;
        getCsrInformation(sslid);
    }

    componentDidUpdate(prevProps) {
        const {
            sslid,
            match,
            history,
            getSslBeacon,
            ssl_configuration_status,
            ssl_beacon_status,
            ssl_csr_clear_status,
            ssl_validation_status,
            ssl_csr_submission_status,
            ssl_csr_information_status,
            ssl_csr_information_data,
            ssl_information_data
        } = this.props;
        const { params } = match;
        const { subpage } = params;
        const { step, loading } = this.state;
        const { handleSetupStep, toggleLoading } = this;

        if (ssl_csr_information_status === 'success' && prevProps.ssl_csr_information_status === 'loading') {
            const { attributes } = ssl_csr_information_data;

            if (subpage === 'validate') {
                this.setState(
                    {
                        loading: {
                            ...loading,
                            message: 'Checking SSL domain validation, this may take a moment.'
                        },
                        step: {
                            ...step,
                            status: {
                                ...step.status,
                                configure: true,
                                review: true
                            }
                        }
                    },
                    () => {
                        getSslBeacon(sslid);
                    }
                );
            } else if (attributes && attributes.approver_email !== null) {
                this.setState(
                    {
                        loading: {
                            ...loading,
                            message: 'Checking SSL configuration'
                        },
                        step: {
                            ...step,
                            status: {
                                ...step.status,
                                configure: true
                            }
                        }
                    },
                    () => {
                        handleSetupStep('review');
                    }
                );
            } else {
                handleSetupStep('configure');
            }
        }

        if (ssl_configuration_status === 'error' && prevProps.ssl_configuration_status === 'loading') {
            toggleLoading();
        }

        if (ssl_csr_submission_status === 'success' && prevProps.ssl_csr_submission_status === 'loading') {
            this.setState(
                {
                    step: {
                        ...step,
                        status: {
                            ...step.status,
                            review: true
                        }
                    }
                },
                () => {
                    getSslBeacon(sslid);
                }
            );
        }

        if (ssl_csr_submission_status === 'error' && prevProps.ssl_csr_submission_status === 'loading') {
            handleSetupStep('review');
        }

        if (ssl_csr_clear_status === 'success' && prevProps.ssl_csr_clear_status === 'loading') {
            this.setState(
                {
                    step: {
                        ...step,
                        status: {
                            configure: false,
                            review: false,
                            validate: false
                        }
                    }
                },
                () => {
                    handleSetupStep('configure');
                }
            );
        }

        if (ssl_beacon_status === 'error' && prevProps.ssl_beacon_status === 'loading') {
            const { attributes } = ssl_information_data;
            if (attributes.status !== 'pending validation') {
                history.push('/my-services/ssl');
            }
            handleSetupStep('error');
        }

        if (
            (ssl_beacon_status === 'success' && prevProps.ssl_beacon_status === 'loading') ||
            (ssl_validation_status === 'error' && prevProps.ssl_validation_status === 'loading')
        ) {
            handleSetupStep('validate');
        }

        if (ssl_validation_status === 'success' && prevProps.ssl_validation_status === 'loading') {
            this.setState(
                {
                    step: {
                        ...step,
                        status: {
                            configure: true,
                            review: true,
                            validate: false
                        }
                    }
                },
                () => {
                    handleSetupStep('validate');
                }
            );
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { cert, sslid, history, ssl_validation_status } = this.props;
        const { step, view, loading, vendor, showInfoDropdown } = this.state;
        const { handleConfiguration, handleConfirmation, handleEditDetails, handleValidation, toggleInfoDropdown } = this;

        const handleDialogRender = () => {
            if (loading) {
                return '';
            }

            switch (step.current) {
                case 'configure':
                case 'review':
                    return (
                        <DialogNotification className="sslConfigure__dialog" type="warning outline">
                            PLEASE NOTE: Our SSL Certificate vendors require you to set up an email account or email forwarder that goes to an{' '}
                            {vendor.email} email address. For example, if your domain was domain.com, you&apos;ll need to set up {vendor.email}
                            @domain.com before they can validate your domain.
                        </DialogNotification>
                    );

                default:
                    return '';
            }
        };

        const handleCurrentView = () => {
            switch (step.current) {
                case 'configure':
                    return <ConfigSelect sslid={sslid} cert={cert} onSubmit={handleConfiguration} />;

                case 'review':
                    return <ConfigReview sslid={sslid} cert={cert} onSubmit={handleConfirmation} resetCert={handleEditDetails} />;

                case 'validate':
                    return <Validate sslid={sslid} cert={cert} onSubmit={handleValidation} history={history} />;

                case 'error':
                    return <ConfigError history={history} />;

                default:
                    return <RequestLoader message={loading.message} />;
            }
        };

        const handleMenuOptionRender = () => {
            return step.list.map((item) => {
                return (
                    <li
                        key={item.key}
                        className={`sslConfigure__list--option${step.status[item.key] ? ` completed` : ``}${
                            step.current === item.key ? ` selected` : ``
                        }`}
                    >
                        {item.title}
                        <i className="icon icon-check-square"></i>
                    </li>
                );
            });
        };

        const handleMenuRender = () => {
            return (
                <div className="sslConfigure__menu">
                    <div className="sslConfigure__menu--title">Configure SSL Certificate</div>
                    <ul className="sslConfigure__list">{handleMenuOptionRender()}</ul>
                </div>
            );
        };

        const handleLoadingStatus = () => {
            if (loading.status) {
                return 'loading';
            }

            return 'success';
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {};

        switch (step.current) {
            case 'validate':
                conditionalProps = {
                    dropdown: {
                        title: 'How long does the process take?',
                        render: (
                            <div className="sslValidateFurther__dropdown">
                                <div className="sslValidateFurther__dropdown--desc">
                                    Generally the verification will take one to seven days to complete. Note that this is handled by the certificate
                                    authority and other circumstances may affect the delivery time.
                                </div>
                                <div className="sslValidateFurther__dropdown--title">What documents do I require?</div>
                                <div className="sslValidateFurther__dropdown--desc">
                                    Sectigo will reach out to you to via the phone number supplied during set up to verify your business information.
                                    If further documentation is required they will let you know. As a general rule of thumb, it helps to have proof of
                                    business registration and physical address documents on hand.
                                </div>
                            </div>
                        ),
                        condition: showInfoDropdown,
                        close: toggleInfoDropdown
                    },
                    footer: (
                        <Fragment>
                            <div className="sslValidateFurther__support">Still haven&apos;t received an email?</div>
                            <Anchor className="footer__link" onClick={() => void history.push('/support/tickets/submit')}>
                                Submit {ticket_name === 'eTicket' ? 'an' : 'a'} {ticket_name}
                            </Anchor>
                        </Fragment>
                    )
                };

                if (ssl_validation_status === 'success' && ![SSL_TYPES.EV, SSL_TYPES.OV].includes(cert.type)) {
                    conditionalProps = {
                        ...conditionalProps,
                        bottom: true,
                        columns: [
                            {
                                render: (
                                    <div className="sharedBox__infoColumn hasIcon">
                                        <i className="infoColumn__icon icon icon-check confirm"></i>
                                        <div className="infoColumn__wrapper">
                                            <div className="title strong">Validation Status</div>
                                            <div className="desc strong">
                                                SSL Certificate has been domain validated. Please allow 15 minutes for DNS propagation.
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        ],
                        action: {
                            label: 'Go to SSL Certificates',
                            type: 'onClick',
                            color: 'secondary',
                            size: 'large',
                            onClick: () => void history.push('/my-services/ssl')
                        }
                    };
                } else if ([SSL_TYPES.EV, SSL_TYPES.OV].includes(cert.type)) {
                    conditionalProps = {
                        ...conditionalProps,
                        bottom: true,
                        columns: [
                            {
                                render: (
                                    <div className="sharedBox__infoColumn hasIcon">
                                        <i className="infoColumn__icon icon icon-instant-activation notice"></i>
                                        <div className="infoColumn__wrapper">
                                            <div className="title strong">Validation Status</div>
                                            <div className="desc strong">Further validation is required by the Certificate Authority</div>
                                        </div>
                                    </div>
                                )
                            }
                        ],
                        action: {
                            label: 'More Info',
                            type: 'onClick',
                            color: 'secondary',
                            size: 'large',
                            onClick: (e) => {
                                e.preventDefault();
                                toggleInfoDropdown();
                            }
                        }
                    };
                }

                break;

            case 'configure':
            case 'review':
            case 'error':
            default:
                break;
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="sslConfigure__container"
            >
                {handleMenuRender()}
                <Transition when={step.current}>
                    <Fragment>
                        {handleDialogRender()}
                        <Box
                            premounted={true}
                            className="sslConfigure__box"
                            title={view.title}
                            desc={view.desc}
                            status={handleLoadingStatus()}
                            custom={{
                                render: handleCurrentView(),
                                pos: 'bottom'
                            }}
                            {...conditionalProps}
                        />
                    </Fragment>
                </Transition>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        ssl_information_status: state.ssl.ssl_information_status,
        ssl_information_data: state.ssl.ssl_information_data,
        ssl_validation_status: state.ssl.ssl_validation_status,
        ssl_validation_data: state.ssl.ssl_validation_data,
        ssl_validation_error: state.ssl.ssl_validation_error,
        ssl_configuration_status: state.ssl.ssl_configuration_status,
        ssl_csr_information_status: state.ssl.ssl_csr_information_status,
        ssl_csr_information_data: state.ssl.ssl_csr_information_data,
        ssl_csr_information_error: state.ssl.ssl_csr_information_error,
        ssl_csr_submission_status: state.ssl.ssl_csr_submission_status,
        ssl_csr_submission_data: state.ssl.ssl_csr_submission_data,
        ssl_csr_submission_error: state.ssl.ssl_csr_submission_error,
        ssl_csr_clear_status: state.ssl.ssl_csr_clear_status,
        ssl_csr_clear_data: state.ssl.ssl_csr_clear_data,
        ssl_csr_clear_error: state.ssl.ssl_csr_clear_error,
        ssl_beacon_status: state.ssl.ssl_beacon_status,
        ssl_beacon_data: state.ssl.ssl_beacon_data,
        ssl_beacon_error: state.ssl.ssl_beacon_error,
        app_viewport: state.app.app_viewport
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            sslConfiguration,
            sslValidation,
            csrConfirmation,
            clearConfiguration,
            getCsrInformation,
            getSslBeacon
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SSLConfigureModule));
