/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { selectAccount } from '../action';
import Avatar from 'components/Placeholders/Avatar';
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AccountSelect extends Component {
    render() {
        const { accountList, selectAccount } = this.props;
        const backgrounds = [
            'linear-gradient(90deg, #0a9fe5, #10c5ef)',
            'linear-gradient(90deg, #5956d7, #c544fb)',
            'linear-gradient(90deg, #0dd41a, #86fc6f)',
            'linear-gradient(90deg, #c32888, #a01e54)',
            'linear-gradient(90deg, #f4ad26, #ca3c78)',
            'linear-gradient(90deg, #0575e6, #0054a8)',
            'linear-gradient(90deg, #f6d365, #fda085)',
            'linear-gradient(90deg, #84fab0, #8fd3f4)'
        ];

        const renderAccountList = () => {
            if (accountList && accountList.length > 0) {
                return accountList.map((result, index) => {
                    const { firstname, lastname, company, role } = result.attributes;

                    return (
                        <button
                            key={index}
                            className="accountSelect__account"
                            onClick={() => {
                                selectAccount(result.id, false);
                            }}
                        >
                            <div className="accountSelect__container">
                                <div className="accountSelect__container--section">
                                    <div className="accountSelect__initial">
                                        <Avatar fullname={`${firstname} ${lastname}`} size={45} fontSize={14} background={backgrounds[index]} />
                                    </div>
                                    <div
                                        className={`accountSelect__type--${company ? 'business' : 'personal'} ${
                                            role !== 'account holder' ? 'full' : ''
                                        }`}
                                    >
                                        <div className="accountSelect__company">{company}</div>
                                        <div className="accountSelect__name">{`${firstname} ${lastname}`}</div>
                                        {role === 'account holder' && <SolidTag>Your account</SolidTag>}
                                    </div>
                                </div>
                            </div>
                        </button>
                    );
                });
            }
        };

        return <div className="accountSelect">{renderAccountList()}</div>;
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectAccount
        },
        dispatch
    );

export default withRouter(connect(null, mapDispatchToProps)(AccountSelect));
