/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import Table from 'components/Table';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getHostingSubAccountInformation, getSubaccountList, prepStateForSubaccount } from '../state/accountActions';
import { cpanelSubAccountLogin, resetResellerState, storeMeta, storeUsername } from '../state/resellerActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SubAccountsList extends Component {
    componentDidUpdate(prevProps) {
        const { hosting_subaccount_list_status } = this.props;
        registerScrollEvents(this, hosting_subaccount_list_status === 'success' && prevProps.hosting_subaccount_list_status === 'loading');
    }

    render() {
        const {
            hostingid,
            getSubaccountList,
            hosting_subaccount_list_status,
            hosting_subaccount_list_data,
            getHostingSubAccountInformation,
            cpanelSubAccountLogin,
            storeUsername,
            storeMeta,
            prepStateForSubaccount,
            history
        } = this.props;

        const renderButton = (subaccount) => {
            switch (subaccount.suspended) {
                case 0:
                    return [
                        {
                            label: 'Login to cPanel',
                            type: 'onClick',
                            className: '',
                            color: 'warning',
                            size: 'medium',
                            onClick: (e) => {
                                e.preventDefault();
                                cpanelSubAccountLogin(hostingid, subaccount.user);
                            }
                        },
                        {
                            label: 'Manage',
                            type: 'onClick',
                            className: '',
                            size: 'medium',
                            onClick: (e) => {
                                e.preventDefault();
                                prepStateForSubaccount();
                                storeMeta(subaccount);
                                storeUsername(subaccount.user);
                                getHostingSubAccountInformation(hostingid, subaccount.user);
                                history.push(`/my-services/hosting/account/details/${hostingid}/subaccount/${subaccount.user}`);
                            }
                        }
                    ];

                case 1:
                    return <div className="contact__link">Account is suspended, please review in WHM via &apos;Manage Suspensions&apos;</div>;

                default:
                    return '';
            }
        };

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const handleTableMatrix = (data) => {
            if (!data) {
                return false;
            }

            const subaccountTempMatrix = Array(data.length).fill();
            Object.keys(subaccountTempMatrix).forEach((value) => {
                if (data[value].deleted === 1) {
                    return false;
                } else {
                    subaccountTempMatrix[value] = {
                        domain: data[value].domain,
                        username: data[value].user,
                        package: data[value].package,
                        actions: renderButton(data[value])
                    };
                }
            });

            return subaccountTempMatrix;
        };

        const handleMatrixCondition = () => {
            if (hosting_subaccount_list_data && hosting_subaccount_list_data.attributes[`subaccounts`]) {
                const {
                    attributes: { subaccounts }
                } = hosting_subaccount_list_data;
                return subaccounts;
            }

            return;
        };

        const subaccountMatrix = handleTableMatrix(handleMatrixCondition());

        /*   RENDER COMPONENT
         **********************************************************************************************************/

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="resellerSubaccounts"
            >
                <Box
                    request={{
                        action: getSubaccountList,
                        args: hostingid,
                        status: hosting_subaccount_list_status
                    }}
                    className="resellerSubaccounts__box"
                    title="cPanel Services"
                    status={hosting_subaccount_list_status}
                    custom={{
                        render: (
                            <Table
                                className="resellerSubaccounts__table"
                                header={[
                                    {
                                        title: `Domain Name`,
                                        className: `domain`
                                    },
                                    {
                                        title: `Username`,
                                        className: `username`
                                    },
                                    {
                                        title: `Package`,
                                        className: `package`
                                    },
                                    {
                                        action: true
                                    }
                                ]}
                                embedded={true}
                                loading={hosting_subaccount_list_status}
                                matrix={subaccountMatrix}
                                error="You have no subaccounts active on your account."
                                stacked={true}
                                dynamicActions={true}
                            />
                        ),
                        pos: 'bottom'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        hosting_subaccount_list_status: state.hosting.hosting_subaccount_list_status,
        hosting_subaccount_list_data: state.hosting.hosting_subaccount_list_data,
        hosting_subaccount_list_error: state.hosting.hosting_subaccount_list_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getHostingSubAccountInformation,
            storeUsername,
            storeMeta,
            getSubaccountList,
            cpanelSubAccountLogin,
            prepStateForSubaccount,
            resetResellerState
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubAccountsList));
