/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import Anchor from 'components/Anchor';
import { withRouter } from 'react-router-dom';
import { COPY_contactForAssistance } from './consts';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SupportLink.scss';

const SupportLink = (props) => {
    const { history } = props;
    return (
        <Anchor
            onClick={(e) => {
                e.preventDefault();
                history.push(`/support/tickets/submit`);
            }}
        >
            {COPY_contactForAssistance}
        </Anchor>
    );
};

export default withRouter(SupportLink);
