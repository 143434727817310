/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_StyledLoader.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StyledLoader = ({ rightAligned }) => {
    /***** RENDER *****/
    return (
        <div className="styledLoader">
            <div className="styledLoader__top">
                <div className={classNames('styledLoader__title', 'animate', { 'styledLoader__title--rightAligned': rightAligned })}>
                    <div className="el"></div>
                </div>
                <div className="styledLoader__desc animate">
                    <div className="el"></div>
                </div>
            </div>
            <div className="styledLoader__bottom">
                <div className="styledLoader__action">
                    <div className="el"></div>
                </div>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

StyledLoader.propTypes = {
    // move the top part of the loader to the right to make room for a title
    rightAligned: PropTypes.bool
};

export default StyledLoader;
