/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { has } from 'lodash';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Text as Text } from 'components/Utils/Text/_Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses, removeKeysFromObject } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TruthyProp } from 'utilities/consts';
import { TEXT_PROP_KEYS, TextPropTypes } from '../_Text/consts';

import './__Heading.scss';

const { MEDIUM, SEMI_BOLD, BLACK, SIZE_M, SIZE_XXXL, LEAD_XS } = TEXT_PROP_KEYS;

const HEADINGS = {
    HEADING_2: 'h2',
    HEADING_4: 'h4'
};

const { HEADING_2, HEADING_4 } = HEADINGS;

const HEADING_TYPES_PROPS = {
    [HEADING_2]: [MEDIUM, BLACK, SIZE_XXXL, LEAD_XS],
    [HEADING_4]: [SEMI_BOLD, BLACK, SIZE_M]
};

const HeadingProps = {
    /** Different Types of Headings */
    h2: TruthyProp,
    h4: TruthyProp
};
const HeadingPropsKeys = Object.keys(HeadingProps);

/**
 *
 * @param {string[]} propArray
 * @param {Record<string, unknown>} props
 */
function createTruthyProps(propArray, props) {
    return propArray
        .filter((propKey) => !has(props, propKey))
        .reduce((obj, propKey) => {
            return {
                ...obj,
                [propKey]: 1
            };
        }, {});
}

/**
 *
 * @param {string} key
 * @param {Record<string, unknown>} props
 */
function getTruthyPropsPropData(key, props) {
    return createTruthyProps(HEADING_TYPES_PROPS[key], props);
}

/**
 * @param {Record<string, unknown>} props
 */
function getHeadingTextProps(props) {
    if (has(props, HEADING_2)) return getTruthyPropsPropData(HEADING_2, props);
    if (has(props, HEADING_4)) return getTruthyPropsPropData(HEADING_4, props);
    return {};
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _Heading(props) {
    const { children, className } = props;

    const textProps = getHeadingTextProps(props);
    const strippedHeadingProps = removeKeysFromObject({ props, keysToRemove: HeadingPropsKeys });
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: HeadingPropsKeys,
        componentName: 'Heading',
        delimiter: '--'
    });

    const headingProps = {
        ...strippedHeadingProps,
        ...textProps,
        className: classNames(className, 'Heading', appliedStylingClasses)
    };

    function renderCorrectTag() {
        if (has(props, HEADING_2)) return <h2>{children}</h2>;
        if (has(props, HEADING_4)) return <h4>{children}</h4>;
    }

    return <Text {...headingProps}>{renderCorrectTag()}</Text>;
}

_Heading.propTypes = {
    /**
     * Still can use Text Props to apply extra styling
     */
    ...TextPropTypes,

    ...HeadingProps
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _Heading };
