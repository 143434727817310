/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import WithAuApplicationForm, { modes } from 'containers/auDirect/modules/withAuApplicationForm';
import TPPDomainsList from 'containers/domain/tppDomains/list';
import BulkActions from '../../Bulk';
import DomainSelectList from '../../Bulk/Components/domainSelectList';
import ApproveForm from '../../forms/approveForm';
import EPPForm from '../../forms/eppForm';
import ExportDisplay from '../../forms/exportDisplay';
import ExportForm from '../../forms/exportForm';
import InfoForm from '../../forms/infoForm';
import DomainsListExpiry from './expiry';

/***** MEGAMAY24 START *****/
import { MegaMayDomainsBanner } from 'config/containers/promotions/megamay2024/components/servicesBanner/panels';
/***** MEGAMAY24 END *****/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import StatusTag, { statusTagEnum } from 'components/StatusTag';
import Switch from 'components/Switch';
import Table from 'components/Table';
import SolidTag from 'components/Tags/SolidTag';
import { TanstackClassAdapter } from 'components/TanstackClassAdapter';
import { pushNotification } from 'components/Toast/functions';
import { Flex } from 'components/Utils/Flex';

/*   ACTIONS
 *****************************************************/
import { resetDomainsState } from 'containers/domain/action';
import { cancelSearchDomain } from 'utilities/api/services';
import {
    bulkManageDomainUpdate,
    disableAutoRenewList,
    domainSearch,
    enableAutoRenewList,
    exportDomainInfo,
    getDomainsList,
    reprocessLockedDomain,
    resendTransferEmail,
    resetDomainSearch,
    submitApproveTransfer,
    submitUpdateEpp,
    submitUpdateInfo
} from '../../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { auDirectMethods } from 'containers/auDirect/methods';
import { useGetUserPaymentMethodDataListQuery } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';
import { servicesMethods } from 'containers/services/methods';
import {
    activePromotion,
    createDownloadFile,
    createPopup,
    getCurrentDate,
    getIncludedObjBasedOnType,
    textLowerCase,
    truncateText
} from 'utilities/methods/commonActions';
import { modifyRenderButtonNote } from '../utils';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { APPLICATION_NEEDS_RENEWING_TEXT, IN_PROGRESS_DOMAIN_INFO } from 'containers/auDirect/consts';
import { bulkDomainTools } from 'containers/services/Bulk/tools';
import { getPurchaseLink } from 'containers/services/consts';
import { DomainsBanner } from '../banners/domains/domains';

export const constants = /** @type {const} */ ({
    autoRenew: {
        missingCard:
            'We are unable to detect that there is a valid credit card set as your default payment method. To ensure that payment can be automatically captured, we encourage you to adjust your billing details accordingly.'
    }
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DomainOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_page: null,
            last_page: null,
            total_all_records: null,
            total_records: null,
            bulkManagePreselectedList: [],
            /**
             * Note - Currently this is always null. The Bulk Domain tool retrieves this information internally
             */
            bulkManagePreappliedPromoCode: null,
            showLightbox: false,
            showLightboxMode: null,
            showLightboxID: null,
            showRenewLightbox: null,
            sortBy: null,
            orderBy: null,
            record_per_page: undefined,
            renewId: null,
            autoRenewInvoice: null,
            requested_data: undefined,
            displayOnScreen: false,
            currentKeyword: null
        };
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openBulkManage = this.openBulkManage.bind(this);
        this.closeBulkManage = this.closeBulkManage.bind(this);
        this.showMore = this.showMore.bind(this);
        this.handleLightboxSubmit = this.handleLightboxSubmit.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.setShow = this.setShow.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
        this.checkBulkManageAction = this.checkBulkManageAction.bind(this);
        this.monitorKeyword = this.monitorKeyword.bind(this);
    }

    openConfirm(id, invoice) {
        this.setState({
            showRenewLightbox: true,
            renewId: id,
            autoRenewInvoice: invoice
        });
    }

    closeConfirm() {
        this.setState({
            showRenewLightbox: false,
            renewId: null,
            autoRenewInvoice: null
        });
    }

    monitorKeyword(keyword = null) {
        this.setState({
            currentKeyword: keyword
        });
    }

    openLightbox(mode, id) {
        this.setState({
            showLightbox: true,
            showLightboxMode: mode,
            showLightboxID: id
        });
    }

    closeLightbox(invoicePaid = false) {
        const { getDomainsList } = this.props;
        this.setState(
            {
                showLightbox: false,
                showLightboxMode: null,
                showLightboxID: null
            },
            () => {
                if (invoicePaid) getDomainsList();
            }
        );
    }

    openBulkManage({ bulkManageTool = null, bulkManagePreselectedList = [], bulkManagePreappliedPromoCode = null } = {}) {
        const { history } = this.props;
        this.setState({
            bulkManageTool,
            bulkManagePreselectedList,
            bulkManagePreappliedPromoCode
        });
        history.push('/my-services/domains/bulk');
    }

    /**
     * Handles the react redux state change for managing bulk domains
     */
    checkBulkManageAction() {
        const { domain_bulk_manage_update } = this.props;
        const { openBulkManage, closeBulkManage } = this;
        const { bulkManagePreselectedList } = this.state;

        if (domain_bulk_manage_update?.openManage && domain_bulk_manage_update) {
            const { bulkManageTool, bulkManagePreselectedList, bulkManagePreappliedPromoCode } = domain_bulk_manage_update;
            openBulkManage({ bulkManageTool, bulkManagePreselectedList, bulkManagePreappliedPromoCode });
        } else if (domain_bulk_manage_update && bulkManagePreselectedList.length > 0) {
            closeBulkManage();
        }
    }

    closeBulkManage() {
        const { history } = this.props;

        history.push(`/my-services/domains`);
        // @ts-ignore
        bulkManageDomainUpdate({ openManage: false });
        this.setState({
            bulkManageTool: null,
            bulkManagePreselectedList: [],
            bulkManagePreappliedPromoCode: null
        });
    }

    setSort(sort) {
        const { getDomainsList } = this.props;
        let params;

        this.setState(
            {
                sortBy: sort
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getDomainsList(params);
            }
        );
    }

    setOrder(order) {
        const { getDomainsList } = this.props;
        let params;

        this.setState(
            {
                orderBy: order
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getDomainsList(params);
            }
        );
    }

    setShow(recordsPerPage) {
        const { getDomainsList } = this.props;
        let params;

        this.setState(
            {
                recordsPerPage: recordsPerPage
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getDomainsList(params);
            }
        );
    }

    handleLightboxSubmit(values) {
        const { submitApproveTransfer, submitUpdateInfo, exportDomainInfo, reprocessLockedDomain } = this.props;
        const { showLightboxMode, showLightboxID } = this.state;
        let attributes;

        switch (showLightboxMode) {
            // @ts-ignore
            case 'epp':
                return submitUpdateEpp(showLightboxID, {
                    epp: values.epp
                });
            case 'info':
                attributes = {
                    type: values.type,
                    number: values.eligibility
                };
                submitUpdateInfo(showLightboxID, attributes);
                break;
            // @ts-ignore
            case 'approve':
                attributes = {
                    transferType: values.method
                };
                submitApproveTransfer(showLightboxID, attributes);
                break;
            // @ts-ignore
            case 'export':
                attributes = {
                    ...values,
                    exportType: values.exportType === 'dcsv' ? 'csv' : values.exportType
                };
                this.setState(
                    {
                        requested_data: values,
                        displayOnScreen: values.exportType === 'dcsv'
                    },
                    () => {
                        exportDomainInfo(attributes);
                    }
                );
                break;
            // @ts-ignore
            case 'resend':
                resendTransferEmail(showLightboxID);
                break;
            // @ts-ignore
            case 'locked':
                reprocessLockedDomain(showLightboxID);
                break;
            default:
                break;
        }
    }

    showMore() {
        const { getDomainsList, domain_list_data } = this.props;
        const { current_page, last_page, sortBy, orderBy } = this.state;

        let next = current_page;
        // @ts-ignore
        if (current_page < last_page) {
            // @ts-ignore
            next++;
        }
        const params = {
            page: next,
            sort_by: sortBy,
            sort: orderBy
        };

        getDomainsList(params, domain_list_data);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { getDomainsList, domain_list_status } = this.props;
        const { checkBulkManageAction } = this;

        if (domain_list_status !== 'loading') {
            getDomainsList();
        }

        checkBulkManageAction();
    }

    componentDidUpdate(prevProps) {
        const {
            domain_auto_renew_status,
            domain_list_more_status,
            domain_list_status,
            domain_export_status,
            domain_export_data,
            domain_form_data,
            domain_list_meta,
            app_user_data,
            domain_form_status,
            domain_bulk_manage_update,
            audirect_update_application_status,
            audirect_register_status,
            getDomainsList
        } = this.props;
        const { displayOnScreen } = this.state;
        const { openLightbox, closeLightbox, checkBulkManageAction } = this;

        if (domain_bulk_manage_update !== prevProps.domain_bulk_manage_update) {
            checkBulkManageAction();
        }

        if (domain_list_status === 'success' && prevProps.domain_list_status === 'loading') {
            const { current_page, last_page, total_all_records, total_records } = domain_list_meta;

            this.setState({
                current_page,
                last_page: last_page,
                total_all_records,
                total_records
            });
        }

        if (domain_list_more_status === 'success' && prevProps.domain_list_more_status === 'loading') {
            const { current_page, last_page, total_all_records, total_records } = domain_list_meta;

            this.setState({
                current_page,
                last_page,
                total_all_records,
                total_records
            });
        }

        if (domain_form_status === 'success' && prevProps.domain_form_status === 'loading') {
            const { included } = domain_form_data;
            const invoice = getIncludedObjBasedOnType(included, 'invoice');

            if (invoice) openLightbox('invoice', invoice.id);
            else closeLightbox();
        }

        if (domain_auto_renew_status === 'success' && prevProps.domain_auto_renew_status === 'loading') {
            this.setState({
                showRenewLightbox: false
            });
        }

        if (domain_export_status === 'success' && prevProps.domain_export_status === 'loading') {
            if ((!displayOnScreen && typeof domain_export_data === 'string') || domain_export_data instanceof String) {
                createDownloadFile(domain_export_data, `domains_${app_user_data.email}_` + getCurrentDate().toFormat('dd-MM-yyyy') + '.csv');
                closeLightbox();
            } else {
                openLightbox('display');
            }
        }

        // Refetch .au direct data after after application has been updated (this covers dashboard and domains list page)
        if (
            (audirect_update_application_status === 'success' && prevProps.audirect_update_application_status === 'loading') ||
            (domain_form_status === 'success' && prevProps.domain_form_status === 'loading') ||
            (audirect_register_status === 'success' && prevProps.audirect_register_status === 'loading')
        ) {
            getDomainsList();
        }
    }

    render() {
        const {
            isExpressServiceManager,
            getDomainsList,
            domain_list_more_status,
            domain_list_status,
            domain_list_data,
            domain_search_status,
            domain_search_data,
            domain_export_status,
            domain_form_status,
            domain_information_data,
            resetDomainsState,
            enableAutoRenewList,
            disableAutoRenewList,
            domain_auto_renew_status,
            domainSearch,
            resetDomainSearch,
            history
        } = this.props;
        const {
            current_page,
            last_page,
            showLightbox,
            showLightboxMode,
            showLightboxID,
            bulkManageTool,
            bulkManagePreselectedList,
            bulkManagePreappliedPromoCode,
            showRenewLightbox,
            renewId,
            autoRenewInvoice,
            requested_data,
            currentKeyword
        } = this.state;
        const {
            openLightbox,
            closeLightbox,
            handleLightboxSubmit,
            setShow,
            openBulkManage,
            closeBulkManage,
            openConfirm,
            closeConfirm,
            monitorKeyword
        } = this;

        const renderDomainName = (domain, isPremium) => {
            return (
                <Flex gap={1} items="center">
                    <Text bold size--s>
                        {domain ? truncateText(domain, 35, '...') : 'Not Available'}
                    </Text>
                    {isPremium === true && (
                        <SolidTag color="record__purple" round>
                            Premium
                        </SolidTag>
                    )}
                </Flex>
            );
        };

        const renderButton = (serviceData, toggleApplicationLightbox) => {
            if (!serviceData?.attributes) return [];

            const { id, attributes } = serviceData;

            const note = htmr(modifyRenderButtonNote(attributes.note));

            switch (attributes.status) {
                case 'pre-ordered':
                    return [
                        <div key={attributes.domain} className="contact__note text--italic text--align-right">
                            This domain has been pre-ordered. VentraIP will attempt to register this name from{' '}
                            <strong className="text--bold-italic text--black">8AM AEDT October 4 2022</strong>.<br />
                            Please&nbsp;
                            <Anchor
                                className="text--italic--important"
                                onClick={() => {
                                    history.push(`/support/tickets/submit`);
                                }}
                            >
                                contact us
                            </Anchor>
                            &nbsp;if you wish to change or cancel your pre-order.
                        </div>
                    ];
                case 'Active':
                case 'Expiring':
                    return servicesMethods.table.handlePromoActionsButtons({
                        isExpressServiceManager,
                        serviceData,
                        category: 'domains',
                        history,
                        actions: [
                            {
                                label: 'DNS',
                                type: 'onClick',
                                className: '',
                                color: 'secondary',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    if (domain_information_data && domain_information_data.id !== id) resetDomainsState();
                                    history.push(`/my-services/domains/manage/dns/${id}`);
                                }
                            },
                            {
                                label: 'Manage',
                                type: 'onClick',
                                className: '',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    if (domain_information_data && domain_information_data.id !== id) resetDomainsState();
                                    history.push(`/my-services/domains/general/overview/${id}`);
                                }
                            }
                        ]
                    });

                case 'Expired':
                    return {
                        label: 'Renew',
                        type: 'onClick',
                        className: '',
                        color: 'warn',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            if (domain_information_data && domain_information_data.id !== id) resetDomainsState();
                            history.push(`/my-services/domains/general/renew/${id}`);
                        }
                    };

                case 'Pending':
                    if (attributes.invalid_epp_code) {
                        return [
                            <div key={attributes.domain} className="contact__note">
                                {note}
                            </div>,
                            {
                                label: 'Re-enter Password',
                                type: 'onClick',
                                className: 'contact__button',
                                color: 'primary',
                                size: 'medium',
                                button: 'Solid',
                                onClick: () => openLightbox('epp', id)
                            }
                        ];
                    } else if (attributes.invalid_rego_details) {
                        // If its a direct .au with invalid eligibility, use the contested application lightbox to resubmit eligibility
                        if (auDirectMethods.isDirectAu(attributes.domain)) {
                            return [
                                <p key={attributes.domain}>{note}</p>,
                                {
                                    label: 'Re-enter Information',
                                    color: 'primary',
                                    type: 'onClick',
                                    className: 'contact__button',
                                    onClick: () => toggleApplicationLightbox(attributes.domain, id, modes.INVALID_ELIGIBILITY),
                                    size: 'medium',
                                    button: 'Solid'
                                }
                            ];
                        }

                        return [
                            <div key={attributes.domain} className="contact__note">
                                {note}
                            </div>,
                            {
                                label: 'Re-enter Information',
                                type: 'onClick',
                                className: 'contact__button',
                                color: 'primary',
                                size: 'medium',
                                button: 'Solid',
                                onClick: () => openLightbox('info', id)
                            }
                        ];
                    } else if (attributes.pending_cust_approval) {
                        return [
                            <div key={attributes.domain} className="contact__note">
                                {note}
                            </div>,
                            {
                                label: 'Approve Transfer',
                                type: 'onClick',
                                className: 'contact__button',
                                color: 'primary',
                                size: 'medium',
                                button: 'Solid',
                                onClick: () => openLightbox('approve', id)
                            }
                        ];
                    } else if (attributes.transfer_locked) {
                        return [
                            <div key={attributes.domain} className="contact__note">
                                {note}
                            </div>,
                            {
                                label: 'Reprocess Transfer',
                                type: 'onClick',
                                className: 'contact__button',
                                color: 'primary',
                                size: 'medium',
                                button: 'Solid',
                                onClick: () => openLightbox('locked', id)
                            }
                        ];
                    } else if (attributes.pending_application) {
                        // .au direct application needs to be submitted
                        return [
                            <p key={attributes.domain}>{note}</p>,
                            {
                                label: 'Submit Details',
                                type: 'onClick',
                                className: 'contact__button',
                                color: 'primary',
                                size: 'medium',
                                button: 'Solid',
                                onClick: (e) => {
                                    e.preventDefault();
                                    toggleApplicationLightbox(attributes.domain, id);
                                }
                            }
                        ];
                    }

                    return [
                        <div key={attributes.domain} className="contact__note">
                            {note}
                        </div>
                        // <Anchor href={null} className={`contact__link`} onClick={() => {
                        //     history.push('/support/tickets/submit');
                        // }}>Contact us for assistance</Anchor>
                    ];

                case 'Pending Identity Verification':
                    return [
                        <div key={attributes.domain} className="contact__note">
                            {note}
                        </div>
                        // <Anchor href={null} className={`contact__link`} onClick={() => {
                        //     history.push('/support/tickets/submit');
                        // }}>Contact us for assistance</Anchor>
                    ];

                case 'Pending Transfer':
                    if (attributes.pending_registrant_approval) {
                        return [
                            <div key={attributes.domain} className="contact__note">
                                {note}
                            </div>,
                            {
                                label: 'Resend Email',
                                type: 'onClick',
                                className: 'contact__button',
                                color: 'primary',
                                size: 'medium',
                                button: 'Solid',
                                onClick: () => openLightbox('resend', id)
                            }
                        ];
                    } else {
                        return [
                            <div key={attributes.domain} className="contact__note">
                                {note}
                            </div>,
                            <Anchor
                                key={2}
                                href={null}
                                className="contact__link"
                                onClick={() => {
                                    history.push('/support/tickets/submit');
                                }}
                            >
                                Contact us for assistance
                            </Anchor>
                        ];
                    }

                case 'Redemption':
                    return [
                        <div key={attributes.domain} className="contact__note">
                            {note}
                        </div>,
                        <Anchor
                            key={2}
                            href={null}
                            className="contact__link"
                            onClick={() => {
                                history.push('/support/tickets/submit');
                            }}
                        >
                            Contact us for assistance
                        </Anchor>
                    ];

                // .au direct domain is in contention
                case 'In Progress':
                    return [
                        <div key={attributes.domain} className="contact__note">
                            {IN_PROGRESS_DOMAIN_INFO.NOTE}
                        </div>,
                        <Anchor
                            key={2}
                            href={null}
                            className="contact__link"
                            onClick={() => {
                                createPopup(IN_PROGRESS_DOMAIN_INFO.FAQ_URL);
                            }}
                        >
                            Find out more
                        </Anchor>
                    ];

                // .au direct application has incorrect token or password
                case 'Action Required':
                    return [
                        <p key={attributes.domain}>{note}</p>,
                        {
                            label: 'Re-submit Details',
                            color: 'primary',
                            type: 'onClick',
                            className: 'contact__button',
                            onClick: () => toggleApplicationLightbox(attributes.domain, id, modes.INVALID_APPLICATION),
                            size: 'medium',
                            button: 'Solid'
                        }
                    ];

                // .au direct application needs renewing
                case 'Application Expiring':
                    return [
                        <p key={attributes.domain}>{APPLICATION_NEEDS_RENEWING_TEXT.SHORT}</p>,
                        {
                            label: 'Renew Application',
                            type: 'onClick',
                            color: 'primary',
                            size: 'medium',
                            button: 'Solid',
                            onClick: () => {
                                toggleApplicationLightbox(attributes.domain, id, modes.RENEW_APPLICATION);
                            }
                        }
                    ];

                default:
                    return false;
            }
        };

        const isLastPage = () => !!(current_page && last_page && current_page === last_page);

        /*  RENDER LIGHTBOX
         **********************************************************************************************************/
        const renderLightbox = () => {
            switch (showLightboxMode) {
                // @ts-ignore
                case 'epp':
                    return (
                        <OverlayLightbox onOpen={showLightbox} onClose={closeLightbox} title="Enter EPP Password" loading={domain_form_status}>
                            <EPPForm onSubmit={handleLightboxSubmit} />
                        </OverlayLightbox>
                    );

                // @ts-ignore
                case 'info':
                    return (
                        <OverlayLightbox
                            onOpen={showLightbox}
                            onClose={closeLightbox}
                            title="Update Eligibility Information"
                            loading={domain_form_status}
                        >
                            <InfoForm onSubmit={handleLightboxSubmit} />
                        </OverlayLightbox>
                    );

                // @ts-ignore
                case 'approve':
                    return (
                        <OverlayLightbox onOpen={showLightbox} onClose={closeLightbox} title="Approve Domain Transfer" loading={domain_form_status}>
                            <ApproveForm onSubmit={handleLightboxSubmit} />
                        </OverlayLightbox>
                    );

                // @ts-ignore
                case 'export':
                    return (
                        <OverlayLightbox onOpen={showLightbox} onClose={closeLightbox} title="Export Your Domains" loading={domain_export_status}>
                            <ExportForm onSubmit={handleLightboxSubmit} />
                        </OverlayLightbox>
                    );

                // @ts-ignore
                case 'display':
                    return (
                        <OverlayLightbox onOpen={showLightbox} onClose={closeLightbox} title="Export Summary" loading={domain_export_status}>
                            <ExportDisplay requestedData={requested_data} />
                        </OverlayLightbox>
                    );
                // @ts-ignore
                case 'resend':
                    return (
                        <OverlayLightbox
                            title="Resend Transfer Confirmation"
                            onOpen={showLightbox}
                            loading={domain_form_status}
                            confirm={{
                                desc: htmr(
                                    `You are able to resend the transfer email approval confirmation email if you didn't receive it. We'll attempt to obtain the new email address and resend the email.`
                                ),
                                buttonText: 'Confirm',
                                buttonAction: handleLightboxSubmit,
                                closeText: 'No, Go Back',
                                closeAction: closeLightbox,
                                loading: domain_form_status
                            }}
                            onClose={closeLightbox}
                        />
                    );
                // @ts-ignore
                case 'locked':
                    return (
                        <OverlayLightbox
                            title="Reprocess Domain Transfer"
                            onOpen={showLightbox}
                            loading={domain_form_status}
                            confirm={{
                                desc: htmr(
                                    `You're about to restart your domain name transfer.<br/>Please ensure the domain name is no longer locked at the existing registrar.`
                                ),
                                buttonText: 'Confirm',
                                buttonAction: handleLightboxSubmit,
                                closeText: 'No, Go Back',
                                closeAction: closeLightbox,
                                loading: domain_form_status
                            }}
                            onClose={closeLightbox}
                        />
                    );
                // @ts-ignore
                case 'invoice':
                    return (
                        <OverlayLightbox
                            invoiceid={showLightboxID}
                            title={`Pay Invoice #` + showLightboxID}
                            onOpen={showLightbox}
                            onClose={closeLightbox}
                            onSuccessClose={() => closeLightbox(true)}
                        />
                    );
                default:
                    return '';
            }
        };

        /*  TOGGLE AUTO RENEW
         **********************************************************************************************************/
        /**
         * @param {{
         *   renew: boolean,
         *   id: string,
         *   creditCardData: { attributes: AccountAPI.CreditCardData[number] }[]
         * }} props
         */
        const toggleAutoRenew = ({ renew, id, creditCardData }) => {
            const hasActiveCreditCard = creditCardData?.length && creditCardData.some(({ attributes }) => !attributes.is_expired);
            if (!renew) {
                const onSuccess = () => {
                    if (!hasActiveCreditCard) {
                        pushNotification(
                            <div>
                                <Text bold>
                                    <span>Please Note: </span>
                                </Text>
                                {constants.autoRenew.missingCard}
                            </div>
                        );
                    }
                };

                return enableAutoRenewList(id, { onSuccess });
            }

            disableAutoRenewList(id);
        };

        const renderRenewLightbox = () => {
            return (
                <OverlayLightbox
                    title="Disable Auto Renew?"
                    onOpen={showRenewLightbox}
                    loading={domain_auto_renew_status}
                    confirm={{
                        desc: htmr(
                            `Disabling auto renew will cancel invoice <strong>#${autoRenewInvoice}</strong> and remove the scheduled auto renewal.`
                        ),
                        buttonText: 'Confirm',
                        buttonAction: disableRenew,
                        closeText: 'No, Go Back',
                        closeAction: closeConfirm,
                        loading: domain_auto_renew_status
                    }}
                    onClose={closeConfirm}
                />
            );
        };

        //This is used with the renderRenewLightbox function for when
        //auto renew has generated an invoice for the domain
        const disableRenew = () => {
            disableAutoRenewList(renewId);
        };

        const handleTableMatrix = (data, toggleApplicationLightbox) => {
            if (!Array.isArray(data)) return false;

            const {
                PENDING,
                PENDING_TRANSFER,
                REDEMPTION,
                ACTION_REQUIRED,
                IN_PROGRESS,
                PENDING_IDENTITY_VERIFICATION,
                PRE_ORDERED,
                APPLICATION_EXPIRING
            } = statusTagEnum;

            const premiumRenewNote = 'Premium domains cannot be automatically renewed as they require explicit confirmation.';

            return data.map((service) => {
                const lcStatus = textLowerCase(service.attributes.status);

                const matrixItem = {
                    domain: renderDomainName(service.attributes.domain, service.attributes.is_premium),
                    status: <StatusTag status={lcStatus} className="table__solidtag" limitWidth={true} />,
                    actions: renderButton(service, toggleApplicationLightbox)
                };

                if (
                    [
                        PENDING,
                        PENDING_TRANSFER,
                        REDEMPTION,
                        ACTION_REQUIRED,
                        IN_PROGRESS,
                        PENDING_IDENTITY_VERIFICATION,
                        PRE_ORDERED,
                        APPLICATION_EXPIRING
                    ].includes(lcStatus)
                ) {
                    matrixItem.className = 'extended';
                } else {
                    matrixItem.expiry = <DomainsListExpiry expiryDate={service.attributes.expiry_date} />;
                    matrixItem.autorenew = (
                        <div>
                            {service.attributes.is_premium === false ? (
                                <TanstackClassAdapter useQuery={useGetUserPaymentMethodDataListQuery}>
                                    {({ data, isLoading }) => (
                                        <Switch
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                    domain_auto_renew_status !== 'loading' &&
                                                    service.attributes.auto_renew === false &&
                                                    service.attributes.auto_renew_invoice
                                                ) {
                                                    openConfirm(service.id, service.attributes.auto_renew_invoice);
                                                } else if (domain_auto_renew_status !== 'loading' && !showRenewLightbox) {
                                                    toggleAutoRenew({
                                                        renew: service.attributes.auto_renew,
                                                        id: service.id,
                                                        creditCardData: data
                                                    });
                                                }
                                            }}
                                            switchvalue={service.attributes.auto_renew}
                                            disabled={isLoading}
                                        />
                                    )}
                                </TanstackClassAdapter>
                            ) : (
                                <Flex items="center" gap={1}>
                                    <span className="text--warning">Unavailable</span>
                                    <div className="hover__tooltip">
                                        <span className="expiry__text">
                                            <PhosphorIcons.WarningCircle />
                                        </span>
                                        <span className="hover__tooltip--text">{premiumRenewNote}</span>
                                    </div>
                                </Flex>
                            )}
                        </div>
                    );
                }

                return matrixItem;
            });
        };

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const handleTableRender = (toggleApplicationLightbox) => {
            const domainMatrix = handleTableMatrix(
                servicesMethods.table.handleMatrixCondition(currentKeyword, domain_list_data, domain_search_data),
                toggleApplicationLightbox
            );

            let conditionalTableProps = { embedded: true };

            if (!isExpressServiceManager) {
                // @ts-ignore
                conditionalTableProps = servicesMethods.table.getSharedConditionalProps({
                    title: `Manage Domain Names`,
                    search: {
                        data: domain_search_data,
                        placeholder: `Search for a domain name`,
                        status: domain_search_status,
                        cancel: cancelSearchDomain,
                        search: domainSearch,
                        reset: resetDomainSearch,
                        keyword: monitorKeyword
                    },
                    paginationStatus: domain_list_more_status,
                    setShow,
                    isLastPage,
                    matrix: domainMatrix
                });

                conditionalTableProps.headingActions.unshift({
                    type: `button`,
                    label: 'Bulk Manage',
                    className: 'headingActions__bulk',
                    onClick: (e) => {
                        e.preventDefault();
                        openBulkManage();
                    }
                });

                conditionalTableProps.headingActions.push({
                    type: `select`,
                    label: 'More options',
                    className: `headingActions__more`,
                    options: [
                        {
                            label: 'Export domain names',
                            onClick: (e) => {
                                e.preventDefault();
                                openLightbox('export');
                            }
                        },
                        {
                            label: 'Manage contested domain names',
                            // @ts-ignore
                            onClick: (e) => {
                                history.push(`/my-services/domains/direct-au-applications`);
                            }
                        }
                    ]
                });
            }

            if (isExpressServiceManager && domainMatrix && domainMatrix.length > 5) {
                conditionalTableProps = {
                    showAll: {
                        label: 'show all domains',
                        status: domain_list_status,
                        conditions: (domainMatrix && domainMatrix.length > 5) || !isLastPage(),
                        onClick: () => history.push('/my-services/domains')
                    },
                    embedded: true
                };
            }

            const getNoServicesTitle = () => {
                if (isExpressServiceManager) return 'You have no domains on your account.';
                if (currentKeyword) return 'No domains matched your search.';
                return "You have no domains on your account. Why don't you purchase some from our website!";
            };

            const getStatus = () => {
                switch (true) {
                    case domain_list_status === 'loading':
                    case domain_search_status === 'loading':
                        return 'loading';
                    case domain_list_status === 'error':
                    case domain_search_status === 'error':
                        return 'error';
                    default:
                        return 'success';
                }
            };

            return (
                <Table
                    className="service__table--domains"
                    sort={{
                        function: getDomainsList
                    }}
                    header={[
                        {
                            title: `Domain`,
                            sort: `name`,
                            className: `domain`,
                            datalabel: `domainname`
                        },
                        {
                            title: `Status`,
                            className: `status`
                        },
                        {
                            title: `Expires`,
                            sort: `expirydate`,
                            className: `expires`
                        },
                        {
                            title: `Auto Renew`,
                            className: `autorenew`,
                            tooltip: `We'll automatically invoice you 14 days before your domain is due to expire and attempt to charge any stored credit card. If we're unable to charge your credit card we will let you know by email. We recommend adding a credit card to your account if you wish to use this feature!`
                        },
                        {
                            action: true
                        }
                    ]}
                    loading={getStatus()}
                    matrix={isExpressServiceManager ? domainMatrix && domainMatrix.slice(0, 5) : domainMatrix}
                    error={
                        <div className="noServices">
                            <div className="noServices__title">{getNoServicesTitle()}</div>
                            {!currentKeyword && (
                                <div className="noServices__action--container">
                                    <SolidButton className="noServices__action" type="anchor" href={getPurchaseLink('Domain Names')}>
                                        Purchase Domain Names
                                    </SolidButton>
                                </div>
                            )}
                        </div>
                    }
                    stacked={true}
                    dynamicActions={true}
                    {...conditionalTableProps}
                />
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        if (isExpressServiceManager) {
            // Return this if this component called in the Express Service Manager or is embedded elsewhere
            return (
                <WithAuApplicationForm>
                    {(toggleApplicationLightbox) => (
                        <Fragment>
                            {handleTableRender(toggleApplicationLightbox)}
                            {showLightbox ? renderLightbox() : ''}
                        </Fragment>
                    )}
                </WithAuApplicationForm>
            );
        }

        const { section } = this.props.match.params;
        const {
            location: { pathname }
        } = this.props;

        return (
            <div className="ServiceOverview">
                {pathname.includes('bulk') ? (
                    <BulkActions
                        title="Bulk Domain Tools"
                        preselectedTool={section ?? bulkManageTool}
                        preselected={bulkManagePreselectedList}
                        preappliedPromoCode={bulkManagePreappliedPromoCode}
                        ServiceSelectionComponent={DomainSelectList}
                        // @ts-ignore
                        tools={bulkDomainTools}
                        closeBulk={closeBulkManage}
                        onSelectTool={(tool) => {
                            if (tool) history.push(`/my-services/domains/bulk/${tool}`);
                        }}
                    />
                ) : (
                    <Fragment>
                        {/* MEGAMAY24 START */}
                        {activePromotion('megamay2024') ? <MegaMayDomainsBanner /> : ''}
                        {/* MEGAMAY24 END */}

                        <WithAuApplicationForm>{(toggleApplicationLightbox) => handleTableRender(toggleApplicationLightbox)}</WithAuApplicationForm>

                        <TPPDomainsList />

                        {showLightbox ? renderLightbox() : ''}
                        {showRenewLightbox ? renderRenewLightbox() : ''}

                        <DomainsBanner />
                    </Fragment>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    // @ts-ignore
    connect(
        (state) => ({
            // @ts-ignore
            domain_list_more_status: state.services.domain_list_more_status,
            // @ts-ignore
            domain_list_status: state.services.domain_list_status,
            // @ts-ignore
            domain_list_data: state.services.domain_list_data,
            // @ts-ignore
            domain_list_meta: state.services.domain_list_meta,
            // @ts-ignore
            domain_search_status: state.services.domain_search_status,
            // @ts-ignore
            domain_search_data: state.services.domain_search_data,
            // @ts-ignore
            domain_export_status: state.services.domain_export_status,
            // @ts-ignore
            domain_export_data: state.services.domain_export_data,
            // @ts-ignore
            domain_auto_renew_status: state.services.domain_auto_renew_status,
            // @ts-ignore
            domain_auto_renew_data: state.services.domain_auto_renew_data,
            // @ts-ignore
            domain_form_status: state.services.domain_form_status,
            // @ts-ignore
            domain_form_data: state.services.domain_form_data,
            // @ts-ignore
            domain_bulk_manage_update: state.services.domain_bulk_manage_update,
            // @ts-ignore
            domain_information_data: state.domain.domain_information_data,
            // @ts-ignore
            app_user_data: state.app.app_user_data,
            // @ts-ignore
            audirect_update_application_status: state.auDirect.audirect_update_application_status,
            // @ts-ignore
            audirect_register_status: state.auDirect.audirect_register_status,
            // @ts-ignore
            account_credit_card_data: state.account.account_credit_card_data
        }),
        {
            domainSearch,
            resetDomainSearch,
            resetDomainsState,
            enableAutoRenewList,
            disableAutoRenewList,
            getDomainsList,
            submitApproveTransfer,
            submitUpdateInfo,
            exportDomainInfo,
            resendTransferEmail,
            reprocessLockedDomain
        }
    )(DomainOverview)
);
