/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { get, writable } from 'svelte/store';
import { useStoreKey } from 'utilities/hooks/useStoreKey';
export const lastSuperActionAdded = writable('');

interface ComponentDebugTracker {
    RichTextEditor?: number;
}

export const componentDebugTracker = writable<ComponentDebugTracker>({});
export const useComponentDebugTracker = (key: keyof ComponentDebugTracker) => useStoreKey(componentDebugTracker, key);
export const useComponentDebugTrackerCount = (key: keyof ComponentDebugTracker) => {
    /***** HOOKS *****/
    const { update } = useComponentDebugTracker(key);
    /***** EFFECTS *****/
    useEffect(() => {
        update((count) => (count ? count + 1 : 1));
        return () => {
            update((count) => (count ? count - 1 : 0));
        };
    }, []);
};

export const SUPER_ACTIONS_KEYS = Object.freeze({
    KATANA_IMAX: 'KATANA_IMAX',
    KONAMI_CODE: 'KONAMI_CODE',
    LOGIN_VIEW_MANIPULATION: 'LOGIN_VIEW_MANIPULATION',
    RICH_TEXT_EDITOR_DEBUG: 'RICH_TEXT_EDITOR_DEBUG',
    CONTROLSHIFTDVD: 'CONTROLSHIFTDVD',
    BIGDADDY: 'BIGDADDY',
    SV_CHEATS_1: 'SV_CHEATS_1',
    KATANA_PREVIEW_IN_SERVICE_LIST: 'KATANA_PREVIEW_IN_SERVICE_LIST'
});

export const SUPER_ACTIONS: SuperUser.ActionDictionary = {
    [SUPER_ACTIONS_KEYS.KATANA_IMAX]: {
        menuContent: 'VIPsites IMAX'
    },
    [SUPER_ACTIONS_KEYS.KATANA_PREVIEW_IN_SERVICE_LIST]: {
        menuContent: 'VIPsites Preview in Service List'
    },
    [SUPER_ACTIONS_KEYS.LOGIN_VIEW_MANIPULATION]: {
        menuContent: 'Login View Manipulation',
        useDisplayCondition: () => {
            const location = useLocation();
            return location.pathname.includes('login');
        }
    },
    [SUPER_ACTIONS_KEYS.RICH_TEXT_EDITOR_DEBUG]: {
        menuContent: 'Rich Text Editor Debug',
        useDisplayCondition: () => {
            const { value: count } = useComponentDebugTracker('RichTextEditor');
            return Boolean(count);
        }
    },
    [SUPER_ACTIONS_KEYS.KONAMI_CODE]: {
        menuContent: 'Konami Code',
        cheatCode: 'ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightba'
    },
    [SUPER_ACTIONS_KEYS.CONTROLSHIFTDVD]: {
        menuContent: 'DVD Logo',
        cheatCode: 'ControlShiftDVD'
    },

    [SUPER_ACTIONS_KEYS.BIGDADDY]: {
        menuContent: 'Big Daddy',
        cheatCode: 'bigdaddy',
        condition: () => get(lastSuperActionAdded) === SUPER_ACTIONS_KEYS.CONTROLSHIFTDVD
    },
    [SUPER_ACTIONS_KEYS.SV_CHEATS_1]: {
        menuContent: 'sv_cheats 1',
        cheatCode: 'svShift_cheats 1'
    }
};

export const superActionKeys = values(SUPER_ACTIONS_KEYS);
export const superActionNoCheats = superActionKeys.filter((actionKey) => !SUPER_ACTIONS[actionKey]?.cheatCode);
export const maxActionKeyLength = values(SUPER_ACTIONS)
    .filter(({ cheatCode }) => cheatCode)
    .reduce((max, { cheatCode }) => Math.max(max, cheatCode?.length ?? 0), 0);

export const superUserSuperActionsState = writable<Partial<Record<(typeof superActionKeys)[number], boolean>>>({});

export const useSuperUserActionKey = (key: (typeof superActionKeys)[number]) => useStoreKey(superUserSuperActionsState, key);
