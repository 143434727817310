/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Progress.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Progress extends Component {
    render() {
        let type;
        const { title, max, maxPlus, maxTitle, color, value, valueTitle } = this.props;

        const getValue = () => {
            if (value && max) {
                if (Math.round((value / max) * 100) >= 100) {
                    return 100;
                }

                return Math.round((value / max) * 100);
            }

            if (value === 0) {
                return 0;
            }

            return 100;
        };

        const getMaxPlus = () => {
            if (value && max) {
                if (Math.round(((maxPlus * 1000) / max) * 100) >= 100) {
                    return 100;
                }

                return Math.round(((maxPlus * 1000) / max) * 100);
            } else if (value === 0) {
                return 0;
            }

            return 100;
        };

        const fillStyle = {
            width: getValue() + '%'
        };

        const subFillStyle = {
            width: getMaxPlus() + '%'
        };

        if (!color) {
            if (value && max) {
                if (Math.round((value / max) * 100) <= 50) {
                    type = `confirm`;
                } else if (Math.round((value / max) * 100) >= 85) {
                    type = `warn`;
                } else {
                    type = `notice`;
                }
            } else if (value === 0) {
                type = `confirm`;
            } else {
                type = `warn`;
            }
        }

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="progressBar">
                <div className="progressBar__container">
                    {title || valueTitle || maxTitle ? (
                        <div className="progressBar__wrapper--top">
                            <div className="progressBar__title">{title}</div>
                            <div className="progressBar__values">{`${valueTitle ? valueTitle : '∞'} / ${maxTitle ? maxTitle : '∞'}`}</div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="progressBar__wrapper--bottom">
                        <div className={`progressBar__bar ${type ? type : ''} ${color ? color : ''}`}>
                            {maxPlus ? <div className={`progressBar__subfill ${type ? type : ''} ${color ? color : ''}`} style={subFillStyle} /> : ''}
                            <div className={`progressBar__fill ${type ? type : ''} ${color ? color : ''}`} style={fillStyle} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Progress.propTypes = {
    /**
     * Title of the progress bar
     */
    title: PropTypes.string,

    /**
     * Max value of the progress bar
     */
    max: PropTypes.number,

    /**
     * Max value of the progress bar
     */
    maxPlus: PropTypes.number,

    /**
     * Title of the max Value
     */
    maxTitle: PropTypes.string,

    /**
     * Color of the progress bar
     */
    color: PropTypes.oneOf(['confirm', 'warn', 'notice', 'primary', 'bronze', 'silver', 'gold', 'platinum']),

    /**
     * Value of the progress bar
     */
    value: PropTypes.number,

    /**
     * Title of the value
     */
    valueTitle: PropTypes.string
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default Progress;
