/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { has } from 'lodash';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, change, formValueSelector, isValid, reduxForm, submit } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import RequestLoader from 'components/Loaders/Request';
import Note from 'components/Note';
import DialogNotification from 'components/Notifications/DialogNotification';
import { RenderRadioGroup, RenderSelectField, renderCheckboxField, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { addHardwareAddon, addSoftwareAddon, getHardwareAddonPricing } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { migrationDateOptions } from '../methods';
import { getTotal } from '../modules/softwareAddons';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { addonTypes } from '../consts';

const getLightboxMeta = (type) => {
    if (type === addonTypes.CPU) {
        return {
            heading: 'Increase vCPU Allowance',
            options: [
                {
                    label: '1 Extra Core',
                    value: 1,
                    calc_value: 1
                },
                {
                    label: '2 Extra Cores',
                    value: 2,
                    calc_value: 2
                },
                {
                    label: '3 Extra Cores',
                    value: 3,
                    calc_value: 3
                },
                {
                    label: '4 Extra Cores',
                    value: 4,
                    calc_value: 4
                }
            ],
            unit: 'vCPU',
            unit_alt: ' cores',
            payloadType: 'CPU'
        };
    } else if (type === addonTypes.RAM) {
        return {
            heading: 'Increase Memory',
            options: [
                {
                    label: 'Extra 1GB',
                    value: 1,
                    calc_value: 1
                },
                {
                    label: 'Extra 2GB',
                    value: 2,
                    calc_value: 2
                },
                {
                    label: 'Extra 3GB',
                    value: 3,
                    calc_value: 3
                },
                {
                    label: 'Extra 4GB',
                    value: 4,
                    calc_value: 4
                }
            ],
            unit: 'Memory',
            unit_alt: 'GB',
            payloadType: 'RAM'
        };
    } else if (type === addonTypes.BANDWIDTH) {
        return {
            heading: 'Increase Bandwidth',
            options: [
                {
                    label: 'Extra 100GB',
                    value: 1,
                    calc_value: 100
                },
                {
                    label: 'Extra 200GB',
                    value: 2,
                    calc_value: 200
                },
                {
                    label: 'Extra 300GB',
                    value: 3,
                    calc_value: 300
                },
                {
                    label: 'Extra 400GB',
                    value: 4,
                    calc_value: 400
                }
            ],
            unit: 'Bandwidth',
            unit_alt: 'GB',
            payloadType: 'Bandwidth'
        };
    } else if (type === addonTypes.DISK) {
        return {
            heading: 'Increase Disk Space',
            options: [
                {
                    label: 'Extra 50GB',
                    value: 1,
                    calc_value: 50
                },
                {
                    label: 'Extra 100GB',
                    value: 2,
                    calc_value: 100
                },
                {
                    label: 'Extra 150GB',
                    value: 3,
                    calc_value: 150
                },
                {
                    label: 'Extra 200GB',
                    value: 4,
                    calc_value: 200
                }
            ],
            unit: 'Disk',
            unit_alt: 'GB',
            payloadType: 'Disk'
        };
    } else if (type === addonTypes.SOFTWARE) {
        return {
            heading: 'Add Software Licenses',
            payloadType: ''
        };
    } else {
        return null;
    }
};

const getPricing = (payloadType, getPricingData) => {
    if (getPricingData && getPricingData.length > 0) {
        return getPricingData.find((item) => {
            return item.attributes.name.indexOf(payloadType) > -1;
        });
    } else {
        return null;
    }
};

const getDropdownOptions = (priceList, currentCycle) => {
    const newPriceList = Object.assign({}, priceList);

    if (has(newPriceList, currentCycle)) {
        delete newPriceList[currentCycle];
    }

    return newPriceList;
};

const isAlternativeSelected = (priceList, currentCycle) => {
    let isSelected = false;

    if (priceList && Object.keys(priceList).length > 0) {
        Object.keys(priceList).forEach((item) => {
            if (item === currentCycle) {
                isSelected = true;
            }
        });
    }

    return isSelected;
};

const getCurrentAllocation = (addAddonType, vps_addon_list_data, type) => {
    if (addAddonType === addonTypes.SOFTWARE) {
        return null;
    } else {
        const { attributes } = vps_addon_list_data;
        const { hardware } = attributes;
        const { totals } = hardware;

        if (totals && has(totals, type) && totals[type]) {
            return totals[type];
        } else {
            return null;
        }
    }
};

const migrationTimeOptions = () => [
    {
        label: 'Early Morning',
        value: 'Early Morning (00:00 - 09:00)'
    },
    {
        label: 'Morning',
        value: 'Morning (09:00 - 12:00)'
    },
    {
        label: 'Afternoon',
        value: 'Afternoon (12:00 - 18:00)'
    },
    {
        label: 'Evening',
        value: 'Evening (18:00 - 00:00)'
    }
];

class AddAddonLightboxRestartForm extends Component {
    componentDidMount() {
        const { dispatch, form } = this.props;
        dispatch(change(form, 'asap', false));
    }

    render() {
        const { handleSubmit, asap, addonType } = this.props;

        return (
            <form onSubmit={handleSubmit} className="form VPS-AddAddonLightbox-Restart-Form">
                {addonType === addonTypes.SOFTWARE ? (
                    <>
                        <div className="VPS-AddAddonLightbox-Restart-Form__heading">VPS Restart</div>
                        <div className="form__row">
                            <div className="form__column form__column--full">
                                <Field
                                    name="asap"
                                    outlined
                                    component={RenderRadioGroup}
                                    className="VPS-AddAddonLightbox-Restart-Form__radio"
                                    options={[
                                        {
                                            label: 'Restart VPS Now',
                                            value: true
                                        },
                                        {
                                            label: 'Choose a date',
                                            value: false
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                        {asap ? (
                            ''
                        ) : (
                            <>
                                <div className="form__row">
                                    <div className="form__column form__column--full">
                                        <Field
                                            label="Date"
                                            name="date"
                                            component={RenderSelectField}
                                            validate={[requiredFieldValidation]}
                                            type="select"
                                            className="form__dropdown"
                                            options={migrationDateOptions()}
                                        />
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__column form__column--full">
                                        <Field
                                            label="Time"
                                            name="time"
                                            component={RenderSelectField}
                                            validate={[requiredFieldValidation]}
                                            type="select"
                                            className="form__dropdown"
                                            options={migrationTimeOptions()}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <Note
                        beforeColon="Please note"
                        text="Due to our hardware upgrades, VPS products no longer need to be restarted to have addons applied. Once the addon purchase has gone through it will be added to your VPS instantly."
                    />
                )}

                <div className="form__row VPS-AddAddonLightbox-Restart-Form__checkbox">
                    <div className="form__column">
                        <Field
                            name="data_loss"
                            label="I understand that data loss may occur during the upgrade process and that it's recommended that I take a backup of all data before the upgrade time."
                            validate={[requiredFieldValidation]}
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="form__row VPS-AddAddonLightbox-Restart-Form__checkbox">
                    <div className="form__column">
                        <Field
                            name="downtime"
                            label="I understand there will be a period of downtime during the upgrade."
                            validate={[requiredFieldValidation]}
                            component={renderCheckboxField}
                            type="checkbox"
                            className="form__textfield"
                        />
                    </div>
                </div>
                {addonType === addonTypes.DISK ? (
                    <div className="form__row VPS-AddAddonLightbox-Restart-Form__checkbox">
                        <div className="form__column">
                            <Field
                                name="disk_confirmation"
                                label="I understand disk space addons cannot be removed as reducing disk space can result in data corruption."
                                validate={[requiredFieldValidation]}
                                component={renderCheckboxField}
                                type="checkbox"
                                className="form__textfield"
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </form>
        );
    }
}

AddAddonLightboxRestartForm = reduxForm({
    form: 'AddAddonLightboxFormAdditionals'
})(AddAddonLightboxRestartForm);

const selector = formValueSelector('AddAddonLightboxFormAdditionals');
AddAddonLightboxRestartForm = connect(
    (state) => ({
        asap: selector(state, 'asap')
    }),
    null
)(AddAddonLightboxRestartForm);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AddAddonLightbox extends Component {
    constructor(props) {
        super(props);
        const { vps_information_data, addAddonType } = props;
        const { billing_cycle } = vps_information_data.attributes;
        const addonMeta = getLightboxMeta(addAddonType);
        const { options } = addonMeta;
        const initialOption = options && options.length > 0 ? options[0] : {};
        const { value, calc_value } = initialOption;
        this.state = {
            selectedBillingCycle: billing_cycle,
            count: value,
            calc_value,
            isDropdownOpen: false,
            totalPrice: undefined,
            payloadType: undefined
        };
        this.renderBillingCycle = this.renderBillingCycle.bind(this);
        this.renderMain = this.renderMain.bind(this);
        this.onDropdownClickAway = this.onDropdownClickAway.bind(this);
        this.updateTotalPrice = this.updateTotalPrice.bind(this);
        this.onHardwareSubmit = this.onHardwareSubmit.bind(this);
        this.renderAlternativePrice = this.renderAlternativePrice.bind(this);
        this.renderBillingCycleOptions = this.renderBillingCycleOptions.bind(this);
        this.renderBillingCycleDetails = this.renderBillingCycleDetails.bind(this);
        this.onSoftwareSubmit = this.onSoftwareSubmit.bind(this);
        this.onSoftwareSubmitForm = this.onSoftwareSubmitForm.bind(this);
    }

    componentDidMount() {
        const { vps_information_data, addAddonType, getHardwareAddonPricing } = this.props;
        const { id } = vps_information_data;

        if (addAddonType !== addonTypes.SOFTWARE) {
            getHardwareAddonPricing(id);
        }
    }

    renderAlternativePrice(alternativePrice, count = 1) {
        if (alternativePrice && Object.keys(alternativePrice) && Object.keys(alternativePrice).length > 0) {
            return Object.keys(alternativePrice).map((item) => {
                const subotal_price = count * alternativePrice[item];

                return {
                    label: (
                        <div className="VPS-AddAddonLightbox-BillingCyle-Alt__option">
                            <span>{item}</span>
                            <span>${subotal_price.toFixed(2)}</span>
                        </div>
                    ),
                    value: item
                };
            });
        }
    }

    renderBillingCycleOptions(alternativePrice, count) {
        const { selectedBillingCycle } = this.state;
        const { vps_information_data } = this.props;
        const { billing_cycle } = vps_information_data.attributes;
        const { renderAlternativePrice } = this;
        const isAlternative = isAlternativeSelected(alternativePrice, selectedBillingCycle);

        return (
            <div className="VPS-AddAddonLightbox-BillingCycle">
                <ul className="VPS-AddAddonLightbox-BillingCycle__list">
                    <li
                        className={`VPS-AddAddonLightbox-BillingCycle__item${
                            billing_cycle === selectedBillingCycle ? ' VPS-AddAddonLightbox-BillingCycle__item--selected' : ''
                        }`}
                    >
                        <button
                            className="VPS-AddAddonLightbox-BillingCycle__anchor"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    selectedBillingCycle: billing_cycle
                                });
                            }}
                        >
                            <span className="VPS-AddAddonLightbox-BillingCycle__radio" />
                            <span className="VPS-AddAddonLightbox-BillingCycle__label">
                                Match billing cycle with VPS (<strong>{billing_cycle}</strong>)
                            </span>
                        </button>
                    </li>

                    <CustomDropdown.BillingCyclePrefab
                        isActive={isAlternative}
                        options={renderAlternativePrice(alternativePrice, count)}
                        setOption={(item) => {
                            this.setState({
                                selectedBillingCycle: item
                            });
                        }}
                        selectedNewCycle={selectedBillingCycle}
                    />
                </ul>
            </div>
        );
    }

    renderBillingCycle() {
        const { count } = this.state;
        const { addAddonType, vps_information_data, vps_hardware_addon_pricing_data } = this.props;
        const { renderBillingCycleOptions } = this;

        if (addAddonType !== addonTypes.SOFTWARE) {
            const addonMeta = getLightboxMeta(addAddonType);
            const { payloadType } = addonMeta;
            const { billing_cycle } = vps_information_data.attributes;
            const pricing = getPricing(payloadType, vps_hardware_addon_pricing_data);

            if (pricing) {
                const { price } = pricing.attributes;
                const alternativePrice = getDropdownOptions(price, billing_cycle);
                return renderBillingCycleOptions(alternativePrice, count);
            } else {
                return '';
            }
        } else {
            const { selectedAddons } = this.props;
            const getTotalSoftwarePrice = () => {
                if (selectedAddons && selectedAddons.length > 0) {
                    const total = {};
                    const firstAddon = selectedAddons[0];
                    const firstAddonPrice = firstAddon.attributes.price;
                    Object.keys(firstAddonPrice).forEach((cycle) => {
                        total[cycle] = 0;
                    });
                    selectedAddons.forEach((item) => {
                        const { attributes } = item;
                        const { price } = attributes;
                        Object.keys(price).forEach((cycle) => {
                            total[cycle] += parseFloat(price[cycle]) || 0;
                        });
                    });
                    return total;
                } else {
                    return null;
                }
            };
            const price = getTotalSoftwarePrice();
            const { billing_cycle } = vps_information_data.attributes;
            const alternativePrice = getDropdownOptions(price, billing_cycle);

            return renderBillingCycleOptions(alternativePrice);
        }
    }

    renderBillingCycleDetails() {
        const { vps_information_data } = this.props;
        const { selectedBillingCycle } = this.state;
        const { billing_cycle } = vps_information_data.attributes;

        if (billing_cycle === selectedBillingCycle) {
            return (
                <Fragment>
                    Same as VPS (<strong>{selectedBillingCycle})</strong>
                </Fragment>
            );
        } else {
            return selectedBillingCycle;
        }
    }

    onDropdownClickAway(e) {
        e.preventDefault();
        const { onDropdownClickAway } = this;
        this.setState({
            isDropdownOpen: false
        });
        document.removeEventListener('click', onDropdownClickAway);
    }

    updateTotalPrice() {
        const { addAddonType, vps_hardware_addon_pricing_data } = this.props;

        if (addAddonType !== addonTypes.SOFTWARE) {
            const addonMeta = getLightboxMeta(addAddonType);
            const { payloadType } = addonMeta;
            const { selectedBillingCycle } = this.state;
            const pricing = getPricing(payloadType, vps_hardware_addon_pricing_data);
            const priceList = pricing.attributes.price;
            let unit_price = 0;

            if (has(priceList, selectedBillingCycle)) {
                unit_price = priceList[selectedBillingCycle];
                const { count } = this.state;
                const totalPrice = (count * unit_price).toFixed(2);
                this.setState({
                    totalPrice,
                    payloadType
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { vps_hardware_addon_pricing_status } = this.props;
        const { selectedBillingCycle } = this.state;
        const { updateTotalPrice } = this;

        if (prevProps.vps_hardware_addon_pricing_status !== vps_hardware_addon_pricing_status && vps_hardware_addon_pricing_status === 'success') {
            updateTotalPrice();
        }
        if (prevState.selectedBillingCycle !== selectedBillingCycle) {
            updateTotalPrice();
        }
    }

    onHardwareSubmit(values) {
        const { data_loss, downtime, disk_confirmation } = values;
        const { vps_information_data, vps_hardware_addon_pricing_data, addHardwareAddon, closeLightbox } = this.props;
        const { payloadType, count, selectedBillingCycle } = this.state;
        const pricing = getPricing(payloadType, vps_hardware_addon_pricing_data);
        const addon_id = pricing.id;
        const { id } = vps_information_data;
        const attributes = {
            addon_type: payloadType,
            addon_id,
            count,
            billing_cycle: selectedBillingCycle,
            data_loss,
            downtime,
            disk_confirmation
        };

        closeLightbox();
        addHardwareAddon(id, attributes);
    }

    onSoftwareSubmit() {
        const { dispatch } = this.props;
        dispatch(submit('AddAddonLightboxFormAdditionals'));
    }

    onSoftwareSubmitForm(values) {
        const { asap, data_loss, downtime, date, time } = values;
        const { vps_information_data, closeLightbox, selectedAddons, addSoftwareAddon } = this.props;
        const { id } = vps_information_data;
        const { selectedBillingCycle } = this.state;
        const getOrder = () => {
            if (selectedAddons && selectedAddons.length > 0) {
                return selectedAddons.map((item) => {
                    const { id } = item;
                    return {
                        id,
                        billing_cycle: selectedBillingCycle
                    };
                });
            } else {
                return null;
            }
        };
        const attributes = {
            order: getOrder(),
            asap,
            data_loss,
            downtime,
            date,
            time
        };

        addSoftwareAddon(id, attributes);
        closeLightbox();
    }

    renderMain() {
        const { addAddonType, isValidAddAddonLightboxFormAdditionals } = this.props;

        if (addAddonType && addAddonType !== addonTypes.SOFTWARE) {
            const { selectedBillingCycle, count, calc_value } = this.state;
            const { addAddonType, vps_addon_list_data, vps_hardware_addon_pricing_status, vps_hardware_addon_pricing_data } = this.props;
            const addonMeta = getLightboxMeta(addAddonType);
            const { payloadType, options, unit, unit_alt } = addonMeta;
            const { renderBillingCycleDetails } = this;

            switch (vps_hardware_addon_pricing_status) {
                case 'loading':
                    return (
                        <div className="VPS-Reverse-DNS-Lightbox__RequestLoader-wrapper">
                            <RequestLoader />
                        </div>
                    );
                case 'success': {
                    const pricing = getPricing(payloadType, vps_hardware_addon_pricing_data);
                    const priceList = pricing.attributes.price;
                    let unit_price = 0;
                    if (has(priceList, selectedBillingCycle)) {
                        unit_price = priceList[selectedBillingCycle];
                    }
                    const renderOptions = () => {
                        if (options && options.length > 0) {
                            return options.map((item, index) => {
                                const { label, value, calc_value } = item;
                                const price = value * unit_price;
                                const totalPrice = price.toFixed(2);
                                return (
                                    <li
                                        className={`VPS-AddAddonLightbox-Options__item${
                                            value === count ? ' VPS-AddAddonLightbox-Options__item--selected' : ''
                                        }`}
                                        key={`${value}-${index}`}
                                    >
                                        <button
                                            onClick={() => {
                                                this.setState({
                                                    count: value,
                                                    calc_value,
                                                    totalPrice
                                                });
                                            }}
                                        >
                                            <div className="VPS-AddAddonLightbox-Options__left">
                                                <span className="VPS-AddAddonLightbox-Options__radio" />
                                                <span className="VPS-AddAddonLightbox-Options__label">{label}</span>
                                            </div>
                                            <div className="VPS-AddAddonLightbox-Options__right">
                                                <span className="VPS-AddAddonLightbox-Options__price">${totalPrice}</span>
                                                <span className="VPS-AddAddonLightbox-Options__cycle">/{selectedBillingCycle}</span>
                                            </div>
                                        </button>
                                    </li>
                                );
                            });
                        } else {
                            return '';
                        }
                    };
                    const currentAllocation = getCurrentAllocation(addAddonType, vps_addon_list_data, addAddonType);
                    const { totalPrice } = this.state;
                    const { onHardwareSubmit } = this;

                    return (
                        <div className="VPS-AddAddonLightbox-Main">
                            <div className="VPS-AddAddonLightbox-Main__left">
                                <ul className="VPS-AddAddonLightbox-Options">{renderOptions()}</ul>
                                <AddAddonLightboxRestartForm onSubmit={onHardwareSubmit} addonType={addAddonType} />
                            </div>
                            <div className="VPS-AddAddonLightbox-Main__right">
                                <div className="VPS-AddAddonLightbox-Main-Box">
                                    <div className="VPS-AddAddonLightbox-Main-Box__line">
                                        <div className="VPS-AddAddonLightbox-Main-Box__label">Current {unit}</div>
                                        <div className="VPS-AddAddonLightbox-Main-Box__value">
                                            {currentAllocation}
                                            {unit_alt}
                                        </div>
                                    </div>
                                    <div className="VPS-AddAddonLightbox-Main-Box__line">
                                        <div className="VPS-AddAddonLightbox-Main-Box__label">
                                            <span className="VPS-AddAddonLightbox-Main-Box__label-pink">NEW</span> {unit}
                                        </div>
                                        <div className="VPS-AddAddonLightbox-Main-Box__value">
                                            <span className="VPS-AddAddonLightbox-Main-Box__label-pink">
                                                {currentAllocation + calc_value}
                                                {unit_alt}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="VPS-AddAddonLightbox-Main-Box__line">
                                        <div className="VPS-AddAddonLightbox-Main-Box__label">Billing Cycle</div>
                                        <div className="VPS-AddAddonLightbox-Main-Box__value">{renderBillingCycleDetails()}</div>
                                    </div>
                                    <div className="VPS-AddAddonLightbox-Main-Box__line VPS-AddAddonLightbox-Main-Box__line--total">
                                        <div className="VPS-AddAddonLightbox-Main-Box__label VPS-AddAddonLightbox-Main-Box__label--total">
                                            Total Cost
                                        </div>
                                        <div className="VPS-AddAddonLightbox-Main-Box__value">
                                            <span className="VPS-AddAddonLightbox-Main-Box__total">${totalPrice}</span>
                                            <span className="VPS-AddAddonLightbox-Main-Box__currency">AUD</span>
                                        </div>
                                    </div>

                                    {addAddonType === addonTypes.DISK ? (
                                        <DialogNotification type="warning" outline>
                                            This action is not reversible, and you will have to contact support if you want to reduce disk space in
                                            the future.
                                        </DialogNotification>
                                    ) : (
                                        ''
                                    )}

                                    {/* If the new total RAM is above 8GB, show lightspeed license warning */}
                                    {addAddonType === addonTypes.RAM && currentAllocation + calc_value > 8 ? (
                                        <DialogNotification type="warning" outline>
                                            Please Note: Your new memory allocation exceeds 8 GB. Your existing LightSpeed licence only allows a
                                            maximum of 8 GB. Please upgrade your LightSpeed licence to mitigate any issues with your resource changes.
                                            For more information on how this would be done, please get in touch with our Technical Support Team.
                                        </DialogNotification>
                                    ) : (
                                        ''
                                    )}

                                    <div className="VPS-AddAddonLightbox-Main-Box__line VPS-AddAddonLightbox-Main-Box__line--button">
                                        {isValidAddAddonLightboxFormAdditionals ? (
                                            <SolidButton
                                                type="onClick"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const { dispatch } = this.props;
                                                    dispatch(submit('AddAddonLightboxFormAdditionals'));
                                                }}
                                            >
                                                Confirm
                                            </SolidButton>
                                        ) : (
                                            <InactiveButton>Confirm</InactiveButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
                default:
                    return '';
            }
        } else {
            const { selectedAddons, addAddonType } = this.props;
            const { selectedBillingCycle } = this.state;
            const renderSoftwareList = () => {
                if (selectedAddons && selectedAddons.length > 0) {
                    return selectedAddons.map((item, index) => {
                        const { attributes } = item;
                        const { name, price } = attributes;
                        const getPriceByCycle = () => {
                            if (price && has(price, selectedBillingCycle)) {
                                return price[selectedBillingCycle];
                            } else {
                                return '';
                            }
                        };
                        return (
                            <div key={index} className="VPS-AddAddonLightbox-Software__addon-item">
                                <div className="VPS-AddAddonLightbox-Software__addon-item-label">{htmr(name)}</div>
                                <div className="VPS-AddAddonLightbox-Software__addon-item-price">
                                    <span className="price">${getPriceByCycle()}</span>
                                    <span className="cycle">/{selectedBillingCycle}</span>
                                </div>
                            </div>
                        );
                    });
                }
            };
            const { renderBillingCycleDetails, onSoftwareSubmit, onSoftwareSubmitForm } = this;

            return (
                <div className="VPS-AddAddonLightbox-Software">
                    <div className="VPS-AddAddonLightbox-Software__column VPS-AddAddonLightbox-Software__column--left">
                        <div className="VPS-AddAddonLightbox-Software__addon-list">
                            <div className="VPS-AddAddonLightbox-Software__heading">Software License</div>
                            {renderSoftwareList()}
                        </div>
                        <AddAddonLightboxRestartForm onSubmit={onSoftwareSubmitForm} addonType={addAddonType} />
                    </div>
                    <div className="VPS-AddAddonLightbox-Software__column VPS-AddAddonLightbox-Software__column--right">
                        <div className="VPS-AddAddonLightbox-Software__price-details">
                            <div className="VPS-AddAddonLightbox-Software__price-details-line">
                                <div className="label">Billing Cycle</div>
                                <div className="value"> {renderBillingCycleDetails()}</div>
                            </div>
                            <div className="VPS-AddAddonLightbox-Software__price-details-line VPS-AddAddonLightbox-Software__price-details-line--total">
                                <div className="label label--total">Total</div>
                                <div className="value value--total">
                                    <span className="price">${getTotal(selectedAddons, selectedBillingCycle)}</span>
                                    <span className="cycle">/{selectedBillingCycle}</span>
                                </div>
                            </div>
                            <div className="VPS-AddAddonLightbox-Software__price-details-line">
                                <SolidButton type="onClick" onClick={onSoftwareSubmit}>
                                    Confirm and Buy
                                </SolidButton>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { vps_solus_information_status, vps_hardware_addon_pricing_status, addAddonType } = this.props;
        const { renderBillingCycle, renderMain } = this;

        if (vps_hardware_addon_pricing_status === 'loading' || vps_solus_information_status === 'loading')
            return (
                <div className="VPS-Reverse-DNS-Lightbox__RequestLoader-wrapper">
                    <RequestLoader />
                </div>
            );

        const { heading } = getLightboxMeta(addAddonType);

        return (
            <div className="VPS-AddAddonLightbox">
                <div className="container">
                    <div className="Overlay__title heading">{heading}</div>
                    {renderBillingCycle()}
                    {renderMain()}
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    const isValidAddAddonLightboxFormAdditionals = isValid('AddAddonLightboxFormAdditionals')(state);

    return {
        isValidAddAddonLightboxFormAdditionals,
        vps_solus_information_status: state.vps.vps_solus_information_status,
        vps_information_data: state.vps.vps_information_data,
        vps_addon_list_data: state.vps.vps_addon_list_data,
        vps_hardware_addon_pricing_status: state.vps.vps_hardware_addon_pricing_status,
        vps_hardware_addon_pricing_data: state.vps.vps_hardware_addon_pricing_data
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            getHardwareAddonPricing,
            addHardwareAddon,
            addSoftwareAddon
        },
        dispatch
    )
});

AddAddonLightbox = connect(mapStateToProps, mapDispatchToProps)(AddAddonLightbox);

export default AddAddonLightbox;
