/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import StatusTag from 'components/StatusTag';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { textLowerCase } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NMicrosoft365Table.TStore} TStore
 * @typedef {import('../../types').NMicrosoft365Table.TMicrosoft365StatusTag} TMicrosoft365StatusTag
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TMicrosoft365StatusTag}
 */
export const MS365TableRenderStatusTag = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const status = textLowerCase(data.attributes.status);

    const classes = /** @type {const} */ ({
        tag: classNames('Microsoft365TableBody__tag', {
            'Microsoft365TableBody__tag--mobile': isMobile
        }),
        status: classNames('Microsoft365TableBody__statusTitle', {
            'Microsoft365TableBody__statusTitle--mobile': isMobile
        })
    });

    return (
        <>
            {isMobile && (
                <Text className={classes.status} lead--1 secondary bold size--xss align--left uppercase>
                    Status
                </Text>
            )}
            <StatusTag status={status} className={classes.tag} />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
