/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ComponentDisabledOverlay.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ComponentDisabled = ({ message, icon }) => {
    if (!message) {
        message = 'This component is disabled for this service.';
    }

    /***** RENDER *****/
    return (
        <div className="ComponentDisabled">
            <div className="ComponentDisabled__overlay"></div>
            <div className="ComponentDisabled__container">
                {icon ? <i className={`ComponentDisabled__icon icon icon-${icon}`}></i> : ''}
                <div className="ComponentDisabled__description">{message}</div>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ComponentDisabled.propTypes = {
    /**
     * Message to display to the user, this will default to "This component is disabled for this service."
     */
    message: PropTypes.string,

    /**
     * Icon to display to the user
     */
    icon: PropTypes.string
};

export default ComponentDisabled;
