/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { company } from 'config/config';
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import { Input } from 'components/Form/Input';
import RequestLoader from 'components/Loaders/Request';
import SortableButton from 'components/Table/SortableButton';

/*   ACTIONS
 *****************************************************/
import { filterDelayedFuseSearch } from 'utilities/methods/search';
import { getModSec } from '../state/securityActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ModSec extends Component {
    static searchOptions = {
        distance: 100,
        keys: ['date_time', 'hostname', 'url', 'request', 'overview', 'mod_sec_id', 'status_code', 'ip_address', 'description']
    };

    static keyMap = {
        url: 'URL:',
        date_time: 'Date/Time:',
        request: 'Request:',
        overview: 'Overview:',
        mod_sec_id: 'Mod Security ID:',
        status_code: 'HTTP Status Code:',
        ip_address: 'IP Address:',
        description: 'Description:'
    };

    static dateFormat = 'yyyy-MM-dd HH:mm:ss';

    constructor(props) {
        super(props);

        this.state = {
            hasHits: false,
            hitAmount: null,
            showDropdown: false,
            openSecDetails: new Set(),
            modSecList: [],
            sortingOptions: {
                currentSort: null,
                order: null
            }
        };

        this.openDropdown = this.openDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.toggleModSecDetails = this.toggleModSecDetails.bind(this);
        this.toggleAllModSecDetails = this.toggleAllModSecDetails.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
        this.sortModSecList = this.sortModSecList.bind(this);
        this.onPerformModSearch = this.onPerformModSearch.bind(this);
        this.setModSearchSecList = this.setModSearchSecList.bind(this);
    }

    toggleModSecDetails(index) {
        const { openSecDetails } = this.state;

        if (openSecDetails.has(index)) {
            openSecDetails.delete(index);
        } else {
            openSecDetails.add(index);
        }

        this.setState({
            openSecDetails
        });
    }

    toggleAllModSecDetails() {
        const { modSecList, openSecDetails } = this.state;

        if (openSecDetails.size < modSecList.length) {
            for (let i = 0; i < modSecList.length; i++) {
                openSecDetails.add(i);
            }
        } else {
            openSecDetails.clear();
        }

        this.setState({
            openSecDetails
        });
    }

    openDropdown() {
        this.setState({
            showDropdown: true
        });
    }

    closeDropdown() {
        this.setState({
            showDropdown: false
        });
    }

    setModSearchSecList(updatedList) {
        this.setState({
            modSecList: updatedList.map((hit) => hit.item)
        });
    }

    onPerformModSearch(value) {
        const { hosting_modsec_data } = this.props;
        const { setModSearchSecList } = this;
        if (value === '') {
            this.setState({
                modSecList: hosting_modsec_data.attributes.hits
            });
        } else {
            filterDelayedFuseSearch(hosting_modsec_data.attributes.hits, value, ModSec.searchOptions, setModSearchSecList);
        }
    }

    toggleSort(sortingOption) {
        const {
            sortingOptions: { order, currentSort }
        } = this.state;

        function getNewSortOrder() {
            if (currentSort !== sortingOption) {
                return 'asc';
            }

            if (order === 'asc') {
                return 'desc';
            }

            return 'asc';
        }

        const newSortOrder = getNewSortOrder();
        this.setState({
            sortingOptions: {
                currentSort: sortingOption,
                order: newSortOrder
            }
        });
    }

    sortModSecList() {
        const {
            modSecList,
            sortingOptions: { currentSort, order }
        } = this.state;

        if (!currentSort) return modSecList;

        switch (currentSort) {
            case 'date_time':
                return modSecList.sort((a, b) => {
                    if (order === 'asc')
                        return DateTime.fromFormat(a.date_time, ModSec.dateFormat) > DateTime.fromFormat(b.date_time, ModSec.dateFormat);
                    // Order is Desc
                    return DateTime.fromFormat(a.date_time, ModSec.dateFormat) < DateTime.fromFormat(b.date_time, ModSec.dateFormat);
                });

            case 'hostname':
                return modSecList.sort((a, b) => {
                    if (order === 'asc') return a.hostname > b.hostname ? -1 : 1;
                    // Order is Desc
                    return a.hostname < b.hostname ? -1 : 1;
                });

            case 'ip_address':
                return modSecList.sort((a, b) => {
                    const num1 = Number(
                        a.ip_address
                            .split('.')
                            .map((num) => `000${num}`.slice(-3))
                            .join('')
                    );
                    const num2 = Number(
                        b.ip_address
                            .split('.')
                            .map((num) => `000${num}`.slice(-3))
                            .join('')
                    );
                    if (order === 'asc') return num1 - num2;
                    return num2 - num1;
                });

            default:
                return modSecList;
        }
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps) {
        const { hosting_modsec_status, hosting_modsec_data } = this.props;

        registerScrollEvents(this, hosting_modsec_status === 'success' && prevProps.hosting_modsec_status === 'loading');

        if (hosting_modsec_data && hosting_modsec_status === 'success' && prevProps.hosting_modsec_status === 'loading') {
            const { attributes } = hosting_modsec_data;

            if (Object.prototype.hasOwnProperty.call(attributes, 'hits') && attributes.hits && attributes.hits.length > 0) {
                this.setState({
                    hasHits: true,
                    hitAmount: attributes.hits.length
                });
            }

            this.setState({
                modSecList: attributes.hits
            });
        }
    }
    render() {
        const { hosting_modsec_status, hostingid, subaccount, getModSec, app_viewport } = this.props;
        const { hasHits, openSecDetails, hitAmount, showDropdown } = this.state;
        const { closeDropdown, openDropdown, toggleModSecDetails, toggleSort } = this;

        /*   RENDER MODSEC ROW
         **********************************************************************************************************/
        const renderModSecHit = (data, index) => {
            const { date_time, ip_address, hostname } = data;

            function toggleModSecDetailsRow(e) {
                e.preventDefault();
                toggleModSecDetails(index);
            }

            return (
                <button key={index} className={`modSecurity__hit ${openSecDetails.has(index) ? 'active' : ''}`} onClick={toggleModSecDetailsRow}>
                    <div className="hit__title">
                        <div className="hit__title--key">{date_time}</div>

                        {['sm', 'xs'].includes(app_viewport) ? (
                            ''
                        ) : (
                            <>
                                <div className="hit__title--key">{hostname}</div>
                                <div className="hit__title--key">{ip_address}</div>
                            </>
                        )}
                        <i className="hit__title--arrow icon icon-arrow" />
                    </div>
                    <div className="hit__dropdown">
                        {Object.keys(data).map((row, index) => {
                            if (['date_time', 'hostname'].includes(row)) {
                                return '';
                            }
                            return (
                                <div key={index} className="hit__row">
                                    <div className="hit__key">{ModSec.keyMap[row]}</div>
                                    <div className="hit__info">{data[row]}</div>
                                </div>
                            );
                        })}
                    </div>
                </button>
            );
        };

        /*   RENDER OPTIONS
         **********************************************************************************************************/
        const renderOptions = () => {
            const {
                sortingOptions: { currentSort, order }
            } = this.state;
            const { sortModSecList, toggleAllModSecDetails, onPerformModSearch } = this;

            if (hosting_modsec_status === 'loading') {
                return (
                    <div className="modSecurity__popup popup">
                        <div className="popup__row">
                            <RequestLoader />
                        </div>
                    </div>
                );
            }

            const sortedModSecList = sortModSecList();

            return (
                <div className="modSecurity__popup popup">
                    <div className="popup__row">
                        <button
                            type="onClick"
                            onClick={(e) => {
                                e.preventDefault();
                                closeDropdown();
                            }}
                            className="popup__close"
                        >
                            <i className="icon icon-blog-close" />
                        </button>

                        <div className="modSecurity__searchPanel">
                            <h3>Events</h3>

                            <div className="modSecurity__searchPanel--actions">
                                <Input.Decoupled.Default
                                    placeholder="Search for an event"
                                    intrinsicProps={{
                                        onChange: (e) => {
                                            onPerformModSearch(e.target.value);
                                        }
                                    }}
                                >
                                    <i className="icon icon-search" />
                                </Input.Decoupled.Default>
                                <Anchor onClick={toggleAllModSecDetails}>Expand/Collapse all</Anchor>
                            </div>
                        </div>

                        <div className="modSecurity__headerPanel">
                            <SortableButton title="DATE/TIME" sort="date_time" currentSort={currentSort} order={order} toggleSort={toggleSort} />
                            {['sm', 'xs'].includes(app_viewport) ? (
                                ''
                            ) : (
                                <>
                                    <SortableButton
                                        title="HOSTNAME"
                                        sort="hostname"
                                        currentSort={currentSort}
                                        order={order}
                                        toggleSort={toggleSort}
                                    />
                                    <SortableButton
                                        title="IP ADDRESS"
                                        sort="ip_address"
                                        currentSort={currentSort}
                                        order={order}
                                        toggleSort={toggleSort}
                                    />
                                </>
                            )}
                        </div>

                        <div className="box__wrapper">
                            <div className="popup__main">
                                <div className="popup__column popup__column--form">{sortedModSecList.map(renderModSecHit)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        let conditionalProps = {
            columns: [
                {
                    render: (
                        <div className="sharedBox__infoColumn hasIcon">
                            <i className="infoColumn__icon icon icon-check confirm" />
                            <div className="infoColumn__wrapper">
                                <div className="title">Hit Count</div>
                                <div className="desc">{`No ModSec hits found! You're in the clear.`}</div>
                            </div>
                        </div>
                    )
                }
            ]
        };

        if (hasHits) {
            conditionalProps = {
                columns: [
                    {
                        render: (
                            <div className="sharedBox__infoColumn hasIcon">
                                <div className="infoColumn__wrapper">
                                    <div className="title">Hit Count</div>
                                    <div className="desc hitAmount">{hitAmount}</div>
                                </div>
                            </div>
                        )
                    }
                ],
                action: {
                    label: 'View All',
                    type: 'onClick',
                    className: '',
                    color: '',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        openDropdown();
                    }
                }
            };
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="modSecurity"
            >
                <Box
                    request={{
                        action: getModSec,
                        args: [hostingid, subaccount],
                        status: hosting_modsec_status
                    }}
                    className="modSecurity__box"
                    title="Mod Security"
                    desc="ModSecurity is a toolkit for real-time web application monitoring, logging, and access control."
                    status={hosting_modsec_status}
                    bottom={true}
                    info={`ModSecurity is a cross-platform web application firewall module that helps to protect your website from various attacks.
                        It is used to block commonly known exploits by use of regular expressions and rule sets and is enabled on all ${company} servers by default.`}
                    {...conditionalProps}
                />
                {showDropdown && hasHits ? renderOptions() : ''}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport,

        hosting_modsec_status: state.hosting.hosting_modsec_status,
        hosting_modsec_data: state.hosting.hosting_modsec_data,
        hosting_modsec_error: state.hosting.hosting_modsec_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getModSec
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModSec));
