/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { get } from 'lodash';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageSectionModules from 'components/PageSectionModules';
import SideBarPage from 'components/SideBarPage';
import UnsavedChangesModal from 'containers/katana/containers/ContentEditorLightbox/unsavedChangesModal/unsavedChangesModal';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import KatanaContentEditorLightbox from 'containers/katana/containers/ContentEditorLightbox';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useStore } from 'utilities/hooks/useStore';
import { handleSideMenuOptions } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SUPER_ACTIONS_KEYS, superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import { katanaServicePages } from 'containers/katana/consts';

import { serviceNav } from 'config/config';
import './_KatanaPageBaseRouter.scss';

const { KATANA_IMAX } = SUPER_ACTIONS_KEYS;
const { OVERVIEW, ADMIN } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaPageBaseRouter = ({ modules }) => {
    /***** HOOKS *****/
    const { page, id } = useSetupEditorRouteParams();
    const [superUserActions] = useStore(superUserSuperActionsState);

    const generalList = {
        list_title: 'My Website',
        list_icon: 'accounts',
        list_items: handleSideMenuOptions(modules, OVERVIEW.to),
        base_url: `${serviceNav.VIPsites.link}/${id}/${OVERVIEW.to}`
    };

    const adminList = {
        list_title: 'Admin',
        list_icon: 'settings',
        list_items: handleSideMenuOptions(modules, ADMIN.to),
        base_url: `${serviceNav.VIPsites.link}/${id}/${ADMIN.to}`
    };

    const sidebarList = [generalList, adminList];

    return (
        <SideBarPage
            sidebarList={sidebarList}
            className={classNames('KatanaPageBaseRouter', {
                KatanaPageBaseRouter__KATANA_IMAX: get(superUserActions, KATANA_IMAX)
            })}
        >
            <KatanaContentEditorLightbox />
            <UnsavedChangesModal />
            <PageSectionModules modules={modules} which={page} />
        </SideBarPage>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaPageBaseRouter };
