/* eslint-disable react/jsx-pascal-case */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { testRoutesStore } from 'components/TestRoutes/const';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { TestRoute } from 'components/TestRoutes/hooks/useRegisterTestRoutes/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useRegisterTestRoutes(testRoutes: TestRoute[] = []) {
    /***** EFFECTS *****/
    useEffect(() => {
        // Register Routes
        testRoutesStore.update((routes) => new Set([...routes, ...testRoutes]));

        return () => {
            // Unregister Routes
            testRoutesStore.update((routes) => {
                const newRoutes = new Set([...routes]);
                testRoutes.forEach((route) => {
                    newRoutes.delete(route);
                });
                return newRoutes;
            });
        };
    }, []);

    /***** HOOK RESULTS *****/
    if (import.meta.env.MODE !== 'development') return [];

    return React.Children.toArray(
        testRoutes
            .filter(({ DEV_Component }) => DEV_Component)
            .map(({ path, DEV_Component }) => (
                <Route exact path={path}>
                    <DEV_Component />
                </Route>
            ))
    );
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
