/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OutlineButton from 'components/Buttons/OutlineButton';
import Avatar from 'components/Placeholders/Avatar';
import { revokeAccountAccess } from 'containers/account/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AccountAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        registerScrollEvents(this, true);
    }

    render() {
        const { account_revoke_access_status, login_account_list_data, revokeAccountAccess } = this.props;
        const backgrounds = [
            'linear-gradient(90deg, #0a9fe5, #10c5ef)',
            'linear-gradient(90deg, #5956d7, #c544fb)',
            'linear-gradient(90deg, #0dd41a, #86fc6f)',
            'linear-gradient(90deg, #c32888, #a01e54)',
            'linear-gradient(90deg, #f4ad26, #ca3c78)',
            'linear-gradient(90deg, #0575e6, #0054a8)',
            'linear-gradient(90deg, #fda085, #f6d365)',
            'linear-gradient(90deg, #8fd3f4, #84fab0)'
        ];

        const accountList = () => {
            if (login_account_list_data && login_account_list_data.length > 1) {
                return login_account_list_data
                    .filter(({ attributes }) => attributes.role !== 'account holder')
                    .map((account, index) => {
                        const { id, attributes } = account;
                        const { firstname, lastname, email } = attributes;

                        return (
                            <div key={index} className="accountUsers__users">
                                <div className="accountUsers__user">
                                    <div className="accountUsers__initial">
                                        <Avatar fullname={`${firstname} ${lastname}`} size={43} fontSize={14} background={backgrounds[index]} />
                                    </div>
                                    <div className="accountUsers__info">
                                        <div className="accountUsers__info--name">{`${firstname} ${lastname}`}</div>
                                        <div className="accountUsers__info--email">{email}</div>
                                    </div>
                                </div>
                                <div className="accountUsers__action">
                                    <OutlineButton
                                        color="primary"
                                        type="onClick"
                                        onClick={() => {
                                            revokeAccountAccess(id);
                                        }}
                                    >
                                        Revoke Access
                                    </OutlineButton>
                                </div>
                            </div>
                        );
                    });
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountUsers"
            >
                <Box
                    className="accountUsers__box"
                    title="Account Access"
                    desc={`Account access shows which ${application} accounts you have access to. Please note that revoking your access to a ${application} account is actioned immediately.`}
                    premounted={true}
                    status={account_revoke_access_status}
                    custom={{
                        render: accountList(),
                        pos: 'bottom'
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        login_account_list_data: state.login.login_account_list_data,
        account_revoke_access_status: state.account.account_revoke_access_status,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            revokeAccountAccess
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountAccess));
