/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { application, ticket_name } from 'config/config';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderCheckboxField, requiredAcceptedValidation } from 'utilities/methods/form';

const formName = 'closeAccountForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let CloseAccountForm = ({ handleSubmit, handleClose }) => (
    <form className="closeAccount__form" onSubmit={handleSubmit}>
        <div className="closeAccount__description">
            Using this form will remove all access you currently have to {application} and ordering services via our website. You will receive no
            further emails from us relating to your account by continuing with this account closure.
        </div>
        <div className="confirmation__container">
            <h5 className="confirmation__heading">Request Account Closure</h5>
            <div className="confirmation__input">
                <Field
                    name="confirmation"
                    label={`You agree to have the account closed and all access removed from ${application} and all ordering abilities for this account. All existing accounts credit, SMS credits, invoices, emails, ${ticket_name}s will be unavailable by confirming this process. You will be logged out of ${application} when you submit this form.`}
                    component={renderCheckboxField}
                    validate={[requiredAcceptedValidation]}
                    type="checkbox"
                    className="form__textfield"
                />
            </div>
        </div>
        <ReduxFormButton form={formName}>Permanently Close Account</ReduxFormButton>
        <Anchor onClick={handleClose}>NO, I WANT TO KEEP MY ACCOUNT OPEN</Anchor>
    </form>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CloseAccountForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(CloseAccountForm);

export default withRouter(CloseAccountForm);
