/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import OriginalInvoiceBox from './boxes/originalInvoice';
import SplitInvoiceBox from './boxes/splitInvoice';
import SplittingInvoiceBox from './boxes/splittingInvoice';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useSplitInvoiceMutation } from 'containers/billing/queries/invoice';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { usePreviewSplitInvoiceQuery } from 'containers/billing/queries/invoice/usePreviewSplitInvoiceQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getCurrentDate, getDataFromSuccessResponse, handleDefaultErrorNotification, toLuxonDate } from 'utilities/methods/commonActions';
import { isLastIncluded } from './methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { COPY_backToInvoices } from '../consts';
import './_splitInvoice.scss';
import { defaultSplitPreviewData } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let SplitInvoice = ({ history, app_viewport }) => {
    /***** STATE *****/
    const [itemIds, setItemIds] = useState([]);
    const { value: isSplitLightboxShowing, setTrue: openSplitLightbox, setFalse: closeSplitLightbox } = useBoolean(false);
    const { value: isSuccessComponentShowing, setTrue: openSuccessComponent, setFalse: closeSuccessComponent } = useBoolean(false);

    /***** HOOKS *****/
    const scrollRef = useRef();
    const params = useParams();
    const { current: invoiceID } = useRef(params.id);

    /***** QUERIES *****/
    const splitData = { invoiceID, itemIds };
    const { data: preview_split_invoice_data = defaultSplitPreviewData } = usePreviewSplitInvoiceQuery(splitData);

    const {
        mutate: mutateSplitInvoice,
        status: split_invoice_status,
        data: split_invoice_data
    } = useSplitInvoiceMutation({
        onSuccess: openSuccessComponent,
        onError: (response) => {
            handleDefaultErrorNotification(response);
            handleCloseSplitLightbox();
        }
    });

    const { data: get_invoice_data = { attributes: {}, included: [] } } = useGetInvoiceQuery(invoiceID);

    /***** FUNCTIONS *****/
    function handleCloseSplitLightbox() {
        if (isSuccessComponentShowing) {
            history.push(`/billing/invoices/`);
        } else {
            closeSplitLightbox();
            closeSuccessComponent();
        }
    }

    function addToSplit(id) {
        if (!itemIds.includes(id) && !isLastIncluded(get_invoice_data.included, splitData.itemIds)) {
            setItemIds([...itemIds, id]);
        }
    }

    function removeFromSplit(id) {
        if (itemIds.includes(id)) {
            setItemIds(itemIds.filter((e) => e !== id));
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (get_invoice_data?.attributes?.can_split === false) {
            history.push(`/billing/invoices/`);
        }
    }, [get_invoice_data]);

    /***** RENDER HELPERS *****/
    const { invoice_a: originalPreview, invoice_b: newPreview } = preview_split_invoice_data;

    const renderInvoiceConfirmation = (title, newInvoice, invoice) => {
        const { total, due_date } = invoice;
        const { created_at } = get_invoice_data.attributes;

        return (
            <>
                <div className="splitInvoiceView__confirmationHeading">
                    {title} {newInvoice ? <div className="splitInvoiceView__confirmationNewFlag">NEW</div> : <></>}
                </div>
                <div className="invoice">
                    <div className="invoice__row">
                        <div className="label">Invoice Date Issued</div>
                        <div className="info">{(newInvoice ? getCurrentDate() : toLuxonDate(created_at, 'yyyy-MM-dd TT')).toFormat('d LLL y')}</div>
                    </div>
                    <div className="invoice__row">
                        <div className="label">Invoice Due Date</div>
                        <div className="info">{toLuxonDate(due_date, 'yyyy-MM-dd TT').toFormat('d LLL y')}</div>
                    </div>
                    <div className="invoice__row">
                        <div className="label">Invoice Amount</div>
                        <div className="info">${total} AUD</div>
                    </div>
                </div>
            </>
        );
    };

    const removeButton = (id) => {
        return (
            <OutlineButton
                className="extendLightbox__action"
                color="warn"
                size="small"
                type="onClick"
                onClick={() => {
                    removeFromSplit(id);
                }}
            >
                Remove
            </OutlineButton>
        );
    };

    const splitButton = (id) => {
        return (
            <SolidButton
                color="primary"
                type="onClick"
                size="small"
                disabled={isLastIncluded(get_invoice_data.included, splitData.itemIds)}
                onClick={() => {
                    addToSplit(id);
                }}
            >
                Split
            </SolidButton>
        );
    };

    const renderSuccessMessage = () => {
        const renderData = getDataFromSuccessResponse(split_invoice_data);
        if (renderData && renderData.length >= 2) {
            return (
                <div className="splitInvoiceSuccess">
                    <i className="icon icon-valid" />
                    <div className="splitInvoiceSuccess__heading">Invoice successfully split</div>
                    <div className="splitInvoiceSuccess__description">
                        <div>This changed has now taken effect.</div>
                        <div>Please take note of the invoice changes below.</div>
                    </div>
                    <div className="splitInvoiceSuccess__invoices">
                        <div className="splitInvoiceSuccess__old">
                            <div className="splitInvoiceSuccess__old--heading">Disregard old Invoice</div>
                            <div className="splitInvoiceSuccess__old--invoice">#{invoiceID}</div>
                            <div className="splitInvoiceSuccess__old--description">The services you chose not to split</div>
                        </div>
                        <i className="icon icon-arrow" />
                        <div className="splitInvoiceSuccess__new">
                            <div className="splitInvoiceSuccess__new--heading">NEW Invoice created</div>
                            <div className="splitInvoiceSuccess__new--invoice">
                                <Link to={`/billing/invoice/${renderData[1]}`}>#{renderData[1]}</Link>
                            </div>
                            <div className="splitInvoiceSuccess__new--description">The services you chose not to split</div>
                        </div>
                        <div className="splitInvoiceSuccess__new">
                            <div className="splitInvoiceSuccess__new--heading">NEW Invoice created</div>
                            <div className="splitInvoiceSuccess__new--invoice">
                                <Link to={`/billing/invoice/${renderData[0]}`}>#{renderData[0]}</Link>
                            </div>
                            <div className="splitInvoiceSuccess__new--description">
                                <div>The services</div>
                                <div>you have split</div>
                            </div>
                        </div>
                    </div>
                    <SolidButton className="splitInvoiceSuccess__button" type="onClick" onClick={handleCloseSplitLightbox}>
                        {COPY_backToInvoices}
                    </SolidButton>
                </div>
            );
        }
    };

    /***** RENDER *****/
    return (
        <div ref={scrollRef} className="splitInvoiceView">
            <SplittingInvoiceBox invoiceID={invoiceID} />

            <Grid columns={['xs', 'sm'].includes(app_viewport) ? '100%' : '1fr 1fr'} gap={4} alignItems--start>
                <OriginalInvoiceBox invoiceID={invoiceID} splitData={splitData} splitActionButton={splitButton} />

                <SplitInvoiceBox invoiceID={invoiceID} splitData={splitData} splitActionButton={removeButton} openSplitLightbox={openSplitLightbox} />
            </Grid>

            {/* FINAL SPLIT LIGHTBOX */}
            {isSplitLightboxShowing ? (
                <OverlayLightbox
                    title={isSuccessComponentShowing ? null : `Please review your billing changes`}
                    onOpen={isSplitLightboxShowing}
                    onClose={handleCloseSplitLightbox}
                    loading={split_invoice_status}
                >
                    {isSuccessComponentShowing ? (
                        renderSuccessMessage()
                    ) : (
                        <div className="splitInvoiceView__confirmation">
                            <div className="splitInvoiceView__confirmationTitle">
                                {`Once this action
								has been performed it cannot be undone and the selected invoice will be split into
								separate invoices.`}
                            </div>
                            {renderInvoiceConfirmation('Split Invoice', true, newPreview)}
                            {renderInvoiceConfirmation('Original Invoice', false, originalPreview)}
                            <SolidButton
                                className="extendLightbox__action"
                                color="primary"
                                type="onClick"
                                onClick={() => mutateSplitInvoice(splitData)}
                            >
                                Confirm
                            </SolidButton>
                            <button className="OverlayConfirm__commonlink" onClick={handleCloseSplitLightbox}>
                                No, Go Back
                            </button>
                        </div>
                    )}
                </OverlayLightbox>
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport
    };
};

SplitInvoice = connect(mapStateToProps)(SplitInvoice);

SplitInvoice = withRouter(SplitInvoice);

export default SplitInvoice;
