/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';
import { KatanaRepeatedFormFieldWrapper } from 'containers/katana/formFields/repeated/KatanaRepeatedFormFieldWrapper';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RepeatablePropertyInputType } from 'containers/katana/formFields/repeated/consts';
import { _AccordionFieldArrayRenderer as AccordionFieldArrayRenderer } from 'containers/katana/formFields/repeated/inputTypes/accordion/_AccordionFieldArrayRenderer';
import { _GridFieldArrayRenderer as GridFieldArrayRenderer } from 'containers/katana/formFields/repeated/inputTypes/grid/_GridFieldArrayRenderer';
import { _InlineListFieldArrayRenderer as InlineListFieldArrayRenderer } from 'containers/katana/formFields/repeated/inputTypes/inline_list/_InlineListFieldArrayRenderer';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaRepeatedFormFieldSwitchRenderer: React.FC<{
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
}> = ({ property }) => {
    const { name, input_type } = property;

    /***** RENDER *****/
    switch (input_type) {
        case RepeatablePropertyInputType.INLINE_LIST:
            return <KatanaRepeatedFormFieldWrapper property={property} component={InlineListFieldArrayRenderer} />;

        case RepeatablePropertyInputType.ACCORDION:
            return <KatanaRepeatedFormFieldWrapper property={property} component={AccordionFieldArrayRenderer} />;

        case RepeatablePropertyInputType.GRID:
            return <KatanaRepeatedFormFieldWrapper property={property} component={GridFieldArrayRenderer} />;

        default:
            return <InvalidInputType name={name} inputType={input_type} fieldType="Repeated" />;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaRepeatedFormFieldSwitchRenderer };
