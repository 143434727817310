/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get, has, set } from 'lodash';

/**
 *
 * @param {object} attributes
 * @param {string[]} acceptableKeys
 * @returns {object}
 */
export function filterEndpointAttributeKeys(attributes, acceptableKeys) {
    if (!attributes) {
        return {};
    }

    return acceptableKeys
        .filter((key) => has(attributes, key))
        .reduce((acc, valueKey) => {
            set(acc, valueKey, get(attributes, valueKey));
            return acc;
        }, {});
}
