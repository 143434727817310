/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { tppDomainContactsBoilerPlate } from 'containers/domain/tppDomains/queries/useTppDomainContactsQuery';
import { tppDomainRenewCheckBoilerPlate } from 'containers/domain/tppDomains/queries/useTppDomainRenewCheckQuery';
import { tppDomainTransferLockBoilerPlate } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { tppDomainDnsBoilerPlate } from 'containers/domain/tppDomains/queries/useTppdomainDnsQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';
import { API as ACCOUNT } from 'utilities/api/account';
import { API as DOMAIN_API } from 'utilities/api/domain';
import { API as SERVICES } from 'utilities/api/services';
import { getDataFromSuccessResponse, getErrorFromFailResponse, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**
 * Renews a TPP domain
 */
export function useTppDomainRenewMutation() {
    return useMutation({
        mutationFn: DOMAIN_API.tppDomains.POST.renew,
        onSuccess: (data, variables) => {
            handleDefaultSuccessNotification(data);
            tppDomainRenewCheckBoilerPlate.invalidate(variables);
        }
    });
}

/**
 * Disables transfer lock on TPP domain
 */
export function useTppDomainDisableTransferLockMutation() {
    return useMutation({
        mutationFn: DOMAIN_API.tppDomains.POST.disableTransferLock,
        onSuccess: (data, variables) => {
            handleDefaultSuccessNotification(data);
            tppDomainTransferLockBoilerPlate.invalidate(variables);
        }
    });
}

/**
 * Update domain contacts on TPP domain
 */
export function useTppDomainUpdateContactsMutation() {
    return useMutation({
        mutationFn: DOMAIN_API.tppDomains.PUT.contacts,
        onSuccess: (data, variables) => {
            handleDefaultSuccessNotification(data);
            tppDomainContactsBoilerPlate.invalidate(variables.domainId);
        }
    });
}

/**
 * Update DNS records on TPP domain
 */
export function useTppDomainUpdateDnsMutation() {
    return useMutation({
        mutationFn: DOMAIN_API.tppDomains.PUT.dns,
        onSuccess: (data, variables) => {
            handleDefaultSuccessNotification(data);
            tppDomainDnsBoilerPlate.invalidate(variables.domainId);
        }
    });
}

/**
 * Migrate TPP Domain to Ventraip
 */
async function migrateTppDomain({ domainName, eppCode }) {
    try {
        const [domain] = domainName.split('.');
        const tld = domainName.slice(domain.length);
        const domainData = {
            domain,
            action: 'transfer',
            tlds: [tld]
        };
        const domainAvailabilityResponse = await DOMAIN_API.domain.POST.availability(domainData);
        const domainAvailabilityData = getDataFromSuccessResponse(domainAvailabilityResponse);

        const paymentMethodsResponse = await ACCOUNT.user.payment_methods.GET();
        const paymentMethodsData = getDataFromSuccessResponse(paymentMethodsResponse);
        const bpayPaymentMethodId = paymentMethodsData?.find((method) => method.name === PAYMENT_METHODS.BPAY)?.id;

        const billingCycleId = domainAvailabilityData[0].price.find((price) => price.name === '1 Year')?.billing_cycle_id;

        const createCartResponse = await SERVICES.cart.POST.create([
            {
                id: domainAvailabilityData[0].product_id,
                billing_cycle_id: billingCycleId,
                name: domainName,
                auto_renew: true,
                epp: eppCode,
                order_type: 'transfer'
            }
        ]);

        const createCartData = getDataFromSuccessResponse(createCartResponse);
        const cartToken = createCartData.token;

        const createOrderResponse = await SERVICES.order.POST.create(cartToken, bpayPaymentMethodId);
        const createOrderData = getDataFromSuccessResponse(createOrderResponse);

        return Promise.resolve(createOrderData);
    } catch (e) {
        return Promise.reject(getErrorFromFailResponse(e));
    }
}

export function useTppDomainMigrateMutation() {
    return useMutation({
        mutationFn: migrateTppDomain,
        onSuccess: () => {}
    });
}
