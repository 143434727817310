/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRefContext } from 'components/Utils/RefProvider/useRefContext';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SideBarPageWrapper.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface SideBarPageWrapperProps {
    className?: string;
    children: React.ReactNode;
}
const _SideBarPageWrapper: React.FC<SideBarPageWrapperProps> = ({ className, children }) => {
    /***** HOOKS *****/
    const sideBarPageRef = useRef<HTMLDivElement>();
    const { addRef } = useRefContext();

    /***** EFFECTS *****/
    // Add the ref to the context when the component mounts
    useEffect(() => {
        if (sideBarPageRef.current && addRef) {
            addRef('SideBarPage', sideBarPageRef);
        }
    }, [addRef]);

    /***** RENDER *****/
    return (
        <div className={classNames('SideBarPageWrapper', className)} ref={sideBarPageRef}>
            {children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default _SideBarPageWrapper;
