/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VipRewardsTierIcon from 'containers/vipRewards/sections/moreInfo/tierIcon';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { capitalize } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_vipRewardsTierBar.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsTierBar({ tier = 'bronze', discount = '55% off' }) {
    return (
        <div className={`vipRewardsTierBar vipRewardsTierBar--${tier}`}>
            <div>
                <VipRewardsTierIcon tier={tier} />
                <Text black semiBold size--l>
                    <p>{capitalize(tier)} Status discount</p>
                </Text>
            </div>
            <Text black bold size--xxxl uppercase>
                <p>{discount}</p>
            </Text>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

VipRewardsTierBar.propTypes = {
    /**
     * Current tier of the logged in user
     */
    tier: PropTypes.string,

    /**
     * Discount amount to display
     */
    discount: PropTypes.string
};

export default VipRewardsTierBar;
