/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ticket_name } from 'config/config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChangeEmailForm from '../forms/changeEmail';
import DetailsForm from '../forms/details';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { loginGetUser } from 'App/action';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { updateEmailAddress, updateUserDetails } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AccountPersonalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmailLightbox: false
        };
        this.handleDetailsForm = this.handleDetailsForm.bind(this);
        this.handleEmailForm = this.handleEmailForm.bind(this);
        this.openEmailLightbox = this.openEmailLightbox.bind(this);
        this.closeEmailLightbox = this.closeEmailLightbox.bind(this);
    }

    openEmailLightbox() {
        this.setState({
            showEmailLightbox: true
        });
    }

    closeEmailLightbox() {
        this.setState({
            showEmailLightbox: false
        });
    }

    handleEmailForm(values) {
        const { new_email, domain_list } = values;
        const attributes = {
            email: new_email,
            ...(domain_list.length >= 1 && {
                domain_list: domain_list.map((id) => {
                    return {
                        id
                    };
                })
            })
        };

        updateEmailAddress(attributes);
    }

    handleDetailsForm(values) {
        updateUserDetails(values);
    }

    componentDidUpdate(prevProps) {
        const { account_update_email_status } = this.props;
        const { closeEmailLightbox } = this;
        registerScrollEvents(this, true);

        if (prevProps.account_update_email_status === 'loading' && account_update_email_status === 'success') {
            loginGetUser();
            closeEmailLightbox();
        }
    }

    render() {
        const { account_update_user_status, account_update_email_status } = this.props;
        const { showEmailLightbox } = this.state;
        const { handleDetailsForm, openEmailLightbox, closeEmailLightbox, handleEmailForm } = this;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountPersonalDetails"
            >
                <Box
                    premounted={true}
                    className="accountPersonalDetails__box"
                    title="Personal Details"
                    status={account_update_user_status === 'loading' ? 'loading' : 'success'}
                    custom={{
                        render: <DetailsForm onSubmit={handleDetailsForm} onOpenChangeEmailForm={openEmailLightbox} />,
                        pos: 'bottom'
                    }}
                />

                {showEmailLightbox ? (
                    <OverlayLightbox
                        title="Change Email Address"
                        warningMsg={`PLEASE NOTE: Existing ${ticket_name}s will be changed to your new email address. Changing your email address DOES NOT update contacts for domain names unless specified below.`}
                        loading={account_update_email_status}
                        onOpen={showEmailLightbox}
                        onClose={closeEmailLightbox}
                        onSuccessClose={closeEmailLightbox}
                    >
                        <ChangeEmailForm onSubmit={handleEmailForm} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default withRouter(
    connect(
        (state) => ({
            account_update_user_status: state.account.account_update_user_status,
            account_update_email_status: state.account.account_update_email_status
        }),
        {}
    )(AccountPersonalDetails)
);
