export const FONT_META_TYPES = /** @type {const} */ ({
    BODY: 'body',
    HEADING: 'heading',
    BRAND: 'brand'
});

export const fontMetaTypeValues = Object.values(FONT_META_TYPES);

export const FormFieldsEnum = {
    PRIMARY_FONT: 'style.fonts.heading',
    SECONDARY_FONT: 'style.fonts.body',
    BRANDING: 'style.fonts.branding'
};
