/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import { ConditionalRender } from 'components/Utils/ConditionalRender';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import OverlayLightbox from '../..';
import ComposableReviewPlan from './_composeableReviewPlan';
import ChangeBillingCycle from './changeBillingCycle';
import ReviewPlan from './reviewPlan';
import SelectPlan from './selectPlan';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ChangePlanLightbox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {{
 *   className: string,
 *   composeableReviewPlan: (props: {}) => React.ReactNode,
 *   currentPlan: any,
 *   customConfirm: React.ReactNode,
 *   customCycleText: React.ReactNode,
 *   customReviewElement: React.ReactNode,
 *   isLoading: boolean,
 *   isOpen: boolean,
 *   onConfirm: () => void,
 *   onClose: () => void,
 *   onSelectPlan: (plan: {}) => void,
 *   planOptions: Array<{
 *     name: string,
 *     price: (cycle: string) => string,
 *     description: string,
 *     details: Array<string>,
 *     rawData: any,
 *     customButton: () => void
 *   }>,
 *   reviewData: {
 *     name: string,
 *     price: string,
 *     refund: string,
 *     total: string
 *   },
 *   selectPlanCustomInformation: React.ReactNode,
 *   upgradeType: string
 *   billingCycle: {
 *     current: string,
 *     available: Array<string>
 *   },
 *   composableReviewPlan: React.ReactNode
 * }} props
 */

/**********************************************************************************************************
 *   COMPONENT START
 ***********************************************************************************************************/
/**
 * ChangePlanLightbox - Component used to display a lightbox to change a plan as well as Review the plan change before confirming
 *
 * @param {props} props
 * @property {React.ReactNode} props.composableReviewPlan - A React element that will be rendered in place of the standard review plan screen. Note that
 * this will typically be composed from the ChangePlanLightbox.ReviewPlan Compound component.
 */
const ChangePlanLightbox = ({
    planOptions,
    reviewData = {},
    currentPlan,
    onClose,
    onSelectPlan,
    onConfirm,
    customConfirm,
    billingCycle = { current: 'Monthly', available: ['Monthly'] },
    isOpen = false,
    upgradeType = '',
    customReviewElement = <></>,
    customCycleText,
    selectPlanCustomInformation = <></>,
    isLoading = true,
    className = '',
    composableReviewPlan
}) => {
    /***** PROP VALIDATION *****/
    if (!onClose) throw new Error('onClose is required');
    if (!onSelectPlan) throw new Error('onSelectPlan is required');

    /***** STATE *****/
    const [selectedBillingCycle, setSelectedBillingCycle] = useState(billingCycle.current);
    const [selectedPlanID, setSelectedPlanID] = useState(null);

    /***** FUNCTIONS *****/
    const handleSelectedPlanChange = (id) => {
        if (id === null || id === undefined) {
            setSelectedPlanID(id);
            return;
        }

        const plan = {
            name: planOptions[id].name,
            price: planOptions[id].price(selectedBillingCycle),
            cycle: selectedBillingCycle,
            rawData: planOptions[id].rawData
        };

        onSelectPlan(plan);
        setSelectedPlanID(id);
    };

    const setDefault = () => {
        setSelectedPlanID(null);
    };

    /***** RENDER *****/

    // Loading Plans
    if (isLoading && !selectedPlanID) {
        return (
            <OverlayLightbox onOpen={isOpen} onClose={onClose} title={`Choose a new ${upgradeType} Plan`} className={className}>
                <RequestLoader />
            </OverlayLightbox>
        );
    }

    // No upgrade options
    if (Array.isArray(planOptions) && planOptions.length === 0)
        return (
            <OverlayLightbox onOpen={isOpen} onClose={onClose} title={`Choose a new ${upgradeType} Plan`} className={className}>
                <div>
                    <Text className="noPlans__message">It looks like there are no upgrade options for this product</Text>
                    <div className="noPlans__container">
                        <SolidButton className="noPlans__button" type="onClick" onClick={onClose}>
                            Close
                        </SolidButton>
                    </div>
                </div>
            </OverlayLightbox>
        );

    // Select plan screen
    if (selectedPlanID === null)
        return (
            <OverlayLightbox onOpen={isOpen} onClose={onClose} title={`Choose a new ${upgradeType} Plan`} className={className}>
                <ChangeBillingCycle current={billingCycle.current} cycles={billingCycle.available} onSelect={setSelectedBillingCycle} />
                <SelectPlan
                    planOptions={planOptions}
                    cycle={selectedBillingCycle}
                    currentPlan={currentPlan}
                    onSelectPlan={handleSelectedPlanChange}
                    customCycleText={customCycleText}
                />
                {selectPlanCustomInformation}
            </OverlayLightbox>
        );

    const isDowngrade = selectedPlanID < planOptions.map((plan) => plan.name).indexOf(currentPlan);

    // Loading Review Data & Review screen
    return (
        <OverlayLightbox
            onOpen={isOpen}
            onClose={onClose}
            title={`Review ${upgradeType} ${isDowngrade ? 'Downgrade' : 'Upgrade'}`}
            className={className}
        >
            <ConditionalRender
                condition={isLoading}
                onTrue={<RequestLoader />}
                onFalse={
                    <ConditionalRender
                        condition={!!composableReviewPlan}
                        onTrue={composableReviewPlan?.({
                            cycle: selectedBillingCycle,
                            currentPlan,
                            onConfirm,
                            onCancel: onClose,
                            onGoBack: setDefault,
                            rawData: planOptions[selectedPlanID]?.rawData,
                            customConfirm,
                            customReviewElement,
                            isDowngrade,
                            ...reviewData
                        })}
                        onFalse={
                            <ReviewPlan
                                cycle={selectedBillingCycle}
                                currentPlan={currentPlan}
                                onConfirm={onConfirm}
                                onCancel={onClose}
                                onGoBack={setDefault}
                                rawData={planOptions[selectedPlanID]?.rawData}
                                customConfirm={customConfirm}
                                customReviewElement={customReviewElement}
                                isDowngrade={isDowngrade}
                                {...reviewData}
                            />
                        }
                    />
                }
            />
        </OverlayLightbox>
    );
};

ChangePlanLightbox.ReviewPlan = ComposableReviewPlan;

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

ChangePlanLightbox.propTypes = {
    // required - A string that represents the current plan name
    currentPlan: PropTypes.string,

    // required - A boolean that represents if the component is loading. If true, the component will render a loading screen
    isLoading: PropTypes.bool,

    // required - An array of objects that represent the available plans
    planOptions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            price: PropTypes.func.isRequired,
            description: PropTypes.string.isRequired,
            details: PropTypes.arrayOf(PropTypes.string).isRequired,
            rawData: PropTypes.object,
            customButton: PropTypes.func
        })
    ),

    // required - A function that will be called when the user clicks the close button
    onClose: PropTypes.func.isRequired,

    // required - A function that will be called when the user selects a plan (This should make a Redux call to get information about the plan pricing)
    onSelectPlan: PropTypes.func.isRequired,

    // required - A function that will be called when the user clicks the confirm button
    onConfirm: PropTypes.func,

    // required - An object that represents the current billing cycle name and the available billing cycles
    billingCycle: PropTypes.shape({
        current: PropTypes.string,
        available: PropTypes.arrayOf(PropTypes.string)
    }),

    // defaults to false - A boolean that represents if the lightbox is open
    isOpen: PropTypes.bool,

    // required - A string that represents the type of upgrade (e.g. 'Email Hosting', 'Web Hosting', etc). This will be used in the lightbox title
    upgradeType: PropTypes.string,

    // Optional - A React element that will be rendered in place confirm button
    customConfirm: PropTypes.element,

    // Optional - A React element that will be rendered below the existing list of review fields
    customReviewElement: PropTypes.element,

    // Optional - A React element that will be rendered in place of the standard '/cycle' text
    customCycleText: PropTypes.element,

    // Optional - A React element that will be rendered below the available plans
    selectPlanCustomInformation: PropTypes.element,

    // Optional - An object that represents the review data that will be displayed in the review screen. This is optional until the review screen in which case it is required
    reviewData: PropTypes.shape({
        name: PropTypes.string,
        price: PropTypes.string,
        refund: PropTypes.string,
        total: PropTypes.string
    }),

    // Optional - A React element that will be rendered in place of the standard review plan screen. Note that this will typically be composed from the ChangePlanLightbox.ReviewPlan Compound component.
    composableReviewPlan: PropTypes.func
};

export default ChangePlanLightbox;
