/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      removeService: () => void;
 *      singularName: string;
 * }} param0
 */
function KatanaRepeatedFormFieldRemoveButton({ removeService, singularName }) {
    return (
        <Anchor className="KatanaRepeatedFormField__removeButton" color="warn" onClick={removeService}>
            <PhosphorIcons.X /> Remove
            {/* {singularName} */}
        </Anchor>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaRepeatedFormFieldRemoveButton };
