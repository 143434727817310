/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaMutation } from 'containers/katana/queries/mutationTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import useSearchParams from 'utilities/hooks/useSearchParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_AuthorizeInstagram.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AuthorizeInstagram = () => {
    /***** HOOKS *****/
    const { searchParamsGet } = useSearchParams();
    const serviceIDDecoded = useMemo(() => {
        const stateParam = searchParamsGet('state');
        return stateParam ? Number(atob(stateParam)) : null;
    }, []);

    /***** QUERIES *****/
    const {
        mutate: mutateHandleAuthorisationCallback,
        isPending: isHandleAuthorisationCallbackPending,
        isSuccess: isHandleAuthorisationCallbackSuccess,
        isError: isHandleAuthorisationCallbackError
    } = katanaMutation.serviceID.handleAuthorisationCallback.useMutation();

    /***** FUNCTIONS *****/
    function handleMutation() {
        const codeParam = searchParamsGet('code');
        if (codeParam) {
            mutateHandleAuthorisationCallback({
                code: codeParam,
                socialType: 'instagram',
                serviceID: serviceIDDecoded
            });
        } else {
            pushNotification({ status: 400, details: 'No authorisation code found in the search parameters' });
        }
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (
            !isHandleAuthorisationCallbackSuccess &&
            !isHandleAuthorisationCallbackError &&
            !isHandleAuthorisationCallbackPending &&
            serviceIDDecoded
        ) {
            handleMutation();
        }
    }, [serviceIDDecoded, isHandleAuthorisationCallbackPending, isHandleAuthorisationCallbackError]);

    /***** RENDER *****/
    return (
        <Flex justify="center" items="center" direction="column" className="AuthorizeInstagram">
            <Flex direction="column" justify="center">
                {isHandleAuthorisationCallbackError && (
                    <>
                        <Text warn size--h2 medium align--center>
                            Your Instagram account has not been connected
                        </Text>
                        <Text secondary size--s medium align--center>
                            Please close this tab and try again
                        </Text>
                    </>
                )}
                {isHandleAuthorisationCallbackSuccess && (
                    <>
                        <Text black size--h2 medium align--center>
                            Your Instagram account has been connected
                        </Text>
                        <Text secondary size--s medium align--center>
                            Feel free to close this tab and return to VIPcontrol.
                        </Text>
                    </>
                )}
                {isHandleAuthorisationCallbackPending && (
                    <>
                        <RequestLoader message="Authorising Instagram..." />
                        <Text warn medium size--xss align--center>
                            Do not close this window...
                        </Text>
                    </>
                )}
            </Flex>
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
