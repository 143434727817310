/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback, useMemo } from 'react';
import type { Writable } from 'svelte/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated we're moving to using tanstack/store, this hook will be removed/modified in the future.
 */
export function useStoreKey<TStore extends Record<any, any>, TKey extends keyof TStore>(
    store: Writable<TStore>,
    key: TKey
): { value: TStore[TKey]; set: (newValue: TStore[TKey]) => void; update: (setterMethod: (e: TStore[TKey]) => TStore[TKey]) => void } {
    /***** HOOKS *****/
    const [storeData, , updateStoreData] = useStore(store);

    const value = useMemo(() => {
        return storeData[key];
    }, [storeData[key]]);

    /***** FUNCTIONS *****/
    const setValue = useCallback(
        (newValue: TStore[TKey]) => {
            updateStoreData((currentStoreData) => {
                return {
                    ...currentStoreData,
                    [key]: newValue
                };
            });
        },
        [storeData[key]]
    );

    const updateValue = useCallback(
        (setterMethod: (e: TStore[TKey]) => TStore[TKey]) => {
            updateStoreData((currentStoreData) => {
                return {
                    ...currentStoreData,
                    [key]: setterMethod(currentStoreData[key])
                };
            });
        },
        [storeData[key]]
    );

    /***** HOOK RESULTS *****/
    const hookResult = useMemo(
        () => ({
            value,
            set: setValue,
            update: updateValue
        }),
        [value, setValue, updateValue]
    );
    return hookResult as any;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
