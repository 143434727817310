/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: number) {
    // This causes a type issue if you don't pass options
    return useMutation({
        mutationFn: (socialID: number) => KATANA_API.katana.service_id.user.socials.$socialID.DELETE(serviceID, socialID),
        onSettled: (data, error, socialID) => {
            katanaQuery.serviceID.listSocialAccounts.invalidateQueries(serviceID);
            const filteredData = katanaQuery.serviceID.listSocialAccounts.getQueryData(serviceID)?.data.filter((account) => account.id !== socialID);
            if (filteredData) {
                katanaQuery.serviceID.listSocialAccounts.optimistic(serviceID, 'data', filteredData);
            }
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const unlinkSocialID = Object.freeze({
    useMutation: _useMutation
});
