/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import MarketingPage from 'containers/ventraSynMigration/sections/marketingPage';
import MigrationProcess from 'containers/ventraSynMigration/sections/migrationProcess';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ventraSynMigration.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{ subpage: string }>}
 */
export const VentraSynMigration = ({ subpage }) => {
    const renderSubpage = () => {
        switch (subpage) {
            case 'more-info':
                return <MarketingPage />;
            default:
                return <MigrationProcess />;
        }
    };

    /***** RENDER *****/
    return <div className="ventraSynMigration">{renderSubpage()}</div>;
};
