/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { DevelopmentModuleFilterButton } from 'components/DevelopmentModuleFilter/_DevelopmentModuleFilterButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import StaffTag from 'components/Staff';
import { SuperUser } from 'components/StaffMenu/SuperUser';
import TestRoutes from 'components/TestRoutes';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DeveloperTheme } from 'components/StaffMenu/Theme';
import { StaffMenuContext } from 'components/StaffMenu/context';
import { DeveloperItem, StaffItem } from 'components/StaffMenu/filter';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_StaffMenu.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StaffMenu = () => {
    const defaultStaffMenuState = JSON.parse(localStorage.getItem('staffMenuState')) ?? true;

    /***** STATE *****/
    const [isOpen, setIsOpen] = useState(defaultStaffMenuState);
    const [activeMenuItem, setActiveMenuItem] = useState<string>('');

    /***** HOOKS *****/
    const { app_check_token_data } = useSelector((state: any) => state.app);

    /***** FUNCTIONS *****/
    const closeMenu = () => {
        setIsOpen(false);
        setActiveMenuItem('');

        localStorage.setItem('staffMenuState', 'false');
    };

    const openMenu = () => {
        setIsOpen(true);
        localStorage.setItem('staffMenuState', 'true');
    };

    /***** RENDER HELPERS *****/
    const className = classNames('StaffMenu', {
        'StaffMenu--open': isOpen
    });

    /***** RENDER *****/
    if (app_check_token_data?.is_vpn) {
        return (
            <StaffMenuContext.Provider value={{ active: activeMenuItem, setActive: setActiveMenuItem }}>
                <Flex direction="column" className={className} gap={2}>
                    <StaffItem component={StaffTag} />
                    <StaffItem component={SuperUser} />
                    <StaffItem component={TestRoutes} />

                    <DeveloperItem component={DevelopmentModuleFilterButton} />
                    <DeveloperItem component={DeveloperTheme} />

                    <div className="StaffMenu__toggleWrapper">
                        <button type="button" className="StaffMenu__showStaffMenu" onClick={openMenu}>
                            <PhosphorIcons.ArrowLineRight white size={12} />
                        </button>
                        <button type="button" className="StaffMenu__hideStaffMenu" onClick={closeMenu}>
                            <PhosphorIcons.ArrowLineLeft white size={12} />
                            <Text size--xs lead--1 white>
                                hide
                            </Text>
                        </button>
                    </div>
                </Flex>
            </StaffMenuContext.Provider>
        );
    }
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { StaffMenu };
