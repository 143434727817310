/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Article extends Component {
    render() {
        const {
            article,
            support_knowledge_base_article,
            match: { url }
        } = this.props;

        const getLinkUrl = () => {
            if (url !== article.link) {
                return article.link;
            }
            const arr = url.split('/');
            return arr.slice(0, arr.length - 1).join('/');
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className={`article${article === support_knowledge_base_article ? ' article--active' : ''}`}>
                <Link to={getLinkUrl()}>{article.post_title}</Link>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect((state) => ({
        support_knowledge_base_article: state.support.support_knowledge_base_article
    }))(Article)
);
