/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useCallback, useRef, useState } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NXDropZoneWithKatanaImageUploadAndControl from 'components/Form/NXDropZone/variants/NXDropZoneWithKatanaImageUploadAndControl';
import { KatanaFileFormFieldChooseStockPhoto } from 'containers/katana/formFields/file/inputTypes/dropzone_or_stock_photo/KatanaFileFormFieldChooseStockPhoto';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAcceptMimeTypes } from 'containers/katana/formFields/file/methods';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import './_dropzone_or_stock_photo.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

interface KatanaFileFormFieldNXDropZoneOrStockPhotoProps {
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaFileFormFieldNXDropZoneOrStockPhoto: React.FC<KatanaFileFormFieldNXDropZoneOrStockPhotoProps> = ({ property }) => {
    const { key, name, type, caveats, validationFunctions } = property;

    /***** STATE *****/
    const [isShowingChooseStockPhoto, setIsShowingChooseStockPhoto] = useState(false);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const zoneRef = useRef<DropzoneRef>();

    /***** FUNCTIONS *****/
    const handleOnEqualIdentifierLogoClick = useCallback(() => {
        // Open Dropzone
        if (zoneRef.current) {
            zoneRef.current?.open?.();
        }
    }, []);

    const handleOnZoneRef = useCallback((ref: DropzoneRef) => {
        zoneRef.current = ref;
    }, []);

    /***** RENDER HELPERS *****/
    const mimeTypeAccept = useGetCaveat(CaveatTypes.IMAGE_MIME_TYPE, caveats, []);
    const dropzoneMimeTypeAccept = createAcceptMimeTypes(mimeTypeAccept);

    /***** RENDER *****/
    return (
        <>
            <Flex direction="column" gap={2} className="KatanaFileFormFieldNXDropZoneOrStockPhoto">
                <CheckBox.Panel
                    type="circle"
                    isChecked={!isShowingChooseStockPhoto}
                    onIsCheckedClick={handleOnEqualIdentifierLogoClick}
                    onChange={(v) => {
                        if (!v) {
                            return;
                        }
                        setIsShowingChooseStockPhoto(!v);
                    }}
                >
                    Upload your own photo
                </CheckBox.Panel>
                <Revealer isOpen={!isShowingChooseStockPhoto}>
                    <Field
                        name={key}
                        label={name}
                        component={NXDropZoneWithKatanaImageUploadAndControl}
                        onZoneRef={handleOnZoneRef}
                        serviceID={id}
                        type={type}
                        accept={dropzoneMimeTypeAccept}
                        validate={validationFunctions}
                        singleFile
                    />
                </Revealer>
                <Flex alignChildren="stretch" direction="column">
                    <CheckBox.Panel
                        type="circle"
                        isChecked={isShowingChooseStockPhoto}
                        onChange={(v) => {
                            if (!v) {
                                return;
                            }
                            setIsShowingChooseStockPhoto(v);
                        }}
                    >
                        Pick a stock photo
                    </CheckBox.Panel>
                </Flex>
            </Flex>

            <Revealer isOpen={isShowingChooseStockPhoto}>
                <KatanaFileFormFieldChooseStockPhoto fieldName={key} />
            </Revealer>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormFieldNXDropZoneOrStockPhoto };
