/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_colourSelectCircle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      colour: HexString,
 *      onSelect: (colour: string) => void,
 *      selected?: boolean,
 *      borderColor?: React.CSSProperties['borderColor'],
 * }>}
 */
const ColourSelectCircle = ({ colour, onSelect, selected, borderColor = null }) => (
    <button
        type="button"
        onClick={() => onSelect(colour)}
        className={classNames('ColourSelectCircle', {
            'ColourSelectCircle--selected': selected
        })}
        style={{ backgroundColor: colour, borderColor }}
    >
        <NXSquare ratio={1} />
    </button>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ColourSelectCircle;
