/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SSHAccessForm from '../forms/sshaccessForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { disableSshAccess, enableSshAccess, listSshAccess } from '../state/configActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SSHAccess extends Component {
    constructor(props) {
        super(props);
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.submitAccessRequest = this.submitAccessRequest.bind(this);
        this.disableAccessRequest = this.disableAccessRequest.bind(this);
        this.state = {
            showLightbox: false,
            ipAccessList: undefined,
            sshInfo: {
                ip: null,
                username: null,
                hostname: null,
                port: '2683'
            },
            sshMeta: {
                limit: 10
            }
        };
    }

    openLightbox() {
        this.setState({
            showLightbox: true
        });
    }

    closeLightbox() {
        this.setState({
            showLightbox: false
        });
    }

    submitAccessRequest(values) {
        const { hostingid, subaccount, enableSshAccess, hosting_check_ip_data } = this.props;
        let ip = values.ipaddress;
        if (values.addIP) ip = hosting_check_ip_data?.ipv4.ip;
        else if (values.addIPv6) ip = hosting_check_ip_data?.ipv6.ip;

        enableSshAccess(hostingid, { ip_address: ip }, subaccount);
    }

    disableAccessRequest(ipaddress) {
        const { hostingid, subaccount, disableSshAccess } = this.props;
        const attributes = {
            ip_address: ipaddress
        };
        disableSshAccess(hostingid, attributes, subaccount);
    }

    componentDidMount() {
        const {
            hostingid,
            subaccount,
            listSshAccess,
            hosting_sshaccess_list_status,
            hosting_sshaccess_list_data,
            app_user_data,
            hosting_information_data: { included, attributes }
        } = this.props;

        if (hosting_sshaccess_list_data && hosting_sshaccess_list_status === 'success') {
            this.setState({
                ipAccessList: hosting_sshaccess_list_data,
                sshInfo: {
                    ip: app_user_data.ip_address,
                    username: subaccount ? subaccount : attributes.username,
                    hostname: included[1].attributes.server_hostname,
                    port: typeof included[0].attributes.name === 'string' && included[0].attributes.name.startsWith('NV ') ? '4001' : '2683'
                }
            });
        }

        if (!hosting_sshaccess_list_data && hosting_sshaccess_list_status !== 'success') {
            listSshAccess(hostingid, subaccount);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            hosting_sshaccess_list_status,
            hosting_sshaccess_list_data,
            hosting_sshaccess_list_meta,
            hosting_sshaccess_status,
            app_user_data,
            hosting_information_data: { included, attributes },
            subaccount
        } = this.props;
        registerScrollEvents(this, hosting_sshaccess_list_status === 'success' && prevProps.hosting_sshaccess_list_status === 'loading');

        if ((hosting_sshaccess_status === 'success' || hosting_sshaccess_status === 'error') && prevProps.hosting_sshaccess_status === 'loading') {
            this.closeLightbox();
        }

        if (
            (hosting_sshaccess_list_status === 'success' || hosting_sshaccess_list_status === 'error') &&
            prevProps.hosting_sshaccess_list_status === 'loading'
        ) {
            this.closeLightbox();
        }

        if (hosting_sshaccess_list_status === 'error' && prevProps.hosting_sshaccess_list_status === 'loading') {
            this.closeLightbox();
        }

        if (hosting_sshaccess_list_status === 'success' && prevProps.hosting_sshaccess_list_status === 'loading') {
            this.setState({
                ipAccessList: hosting_sshaccess_list_data,
                showLightbox: false,
                sshInfo: {
                    ip: app_user_data.ip_address,
                    username: subaccount ? subaccount : attributes.username,
                    hostname: included[1].attributes.server_hostname,
                    port: typeof included[0].attributes.name === 'string' && included[0].attributes.name.startsWith('NV ') ? '4001' : '2683'
                },
                sshMeta: {
                    limit: hosting_sshaccess_list_meta?.limit || this.state.sshMeta.limit
                }
            });
        }
    }

    render() {
        const { hosting_sshaccess_list_status, hosting_sshaccess_status, listSshAccess, hostingid, subaccount } = this.props;
        const { ipAccessList, showLightbox, sshInfo, sshMeta } = this.state;
        const { openLightbox, submitAccessRequest, disableAccessRequest } = this;

        const handleLoadingStatus = () => {
            if (hosting_sshaccess_list_status === 'loading' || hosting_sshaccess_status === 'loading') return 'loading';
            else if (hosting_sshaccess_list_status === 'error') return 'error';
            return 'success';
        };

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const sshMatrix =
            ipAccessList?.map(({ attributes: { ip_address, date_added } }) => ({
                'ip': ip_address,
                'Date Added': toLuxonDate(date_added).toFormat('dd-MM-yyyy t'),
                'actions': {
                    label: 'Delete',
                    type: 'onClick',
                    className: '',
                    color: 'warning',
                    onClick: (e) => {
                        e.preventDefault();
                        disableAccessRequest(ip_address);
                    }
                }
            })) || [];

        let conditionalProps = {
            desc: `Your hosting account can be enabled for SSH access by allowing the IP address you want to give access to.`,
            custom: {
                render: (
                    <Fragment>
                        <div className="sshAccess__wrapper">
                            <div className="sshAccess__row">
                                <div className="row__title">Username</div>
                                <div className="row__desc">{sshInfo.username}</div>
                            </div>
                            <div className="sshAccess__row">
                                <div className="row__title">Hostname</div>
                                <div className="row__desc">{sshInfo.hostname}</div>
                            </div>
                            <div className="sshAccess__row">
                                <div className="row__title">Port</div>
                                <div className="row__desc">{sshInfo.port}</div>
                            </div>
                            <div className="sshAccess__row">
                                <div className="row__title">This device&apos;s IP Address</div>
                                <div className="row__desc">{sshInfo.ip}</div>
                            </div>
                        </div>
                        <div className="sshAccess__table">
                            <Table
                                embedded={true}
                                className="sshAccess__table--wrapper"
                                header={[
                                    {
                                        title: `IP Address`,
                                        className: `ipaddress`
                                    },
                                    {
                                        title: `Date Added`,
                                        className: `timestamp`
                                    },
                                    {
                                        action: true
                                    }
                                ]}
                                loading={hosting_sshaccess_list_status}
                                error=""
                                matrix={sshMatrix}
                                stacked={true}
                                footer={{
                                    label: `Add IP Address`,
                                    icon: `plus-faq`,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        openLightbox('add');
                                    }
                                }}
                            />
                        </div>
                    </Fragment>
                ),
                pos: 'bottom'
            },
            info: `The SSH Access tool allows you to connect to your server securely and perform Linux command-line operations. Both ends of the client/server connection are authenticated using a digital certificate, and passwords are protected by being encrypted.
                <br><br>
                Helpful Hints
                <br>
                <li>Only enable SSH access if you intend on using it.</li>
                <li>SSH Access will be locked down by CageFS and will not allow full access to the file system</li>
                <li>For security reasons, we highly recommend you disable SSH access if you won't be using it for a while.</li>
                <li>Please note that you will be required to authenticate before adding a new IP address for SSH, using a unique security token that is generated and sent to you upon request.</li>
            `
        };

        if (ipAccessList && ipAccessList.length >= sshMeta.limit) {
            conditionalProps = {
                ...conditionalProps,
                custom: {
                    render: (
                        <Fragment>
                            <div className="sshAccess__wrapper">
                                <div className="sshAccess__row">
                                    <div className="row__title">Username</div>
                                    <div className="row__desc">{sshInfo.username}</div>
                                </div>
                                <div className="sshAccess__row">
                                    <div className="row__title">Hostname</div>
                                    <div className="row__desc">{sshInfo.hostname}</div>
                                </div>
                                <div className="sshAccess__row">
                                    <div className="row__title">Port</div>
                                    <div className="row__desc">{sshInfo.port}</div>
                                </div>
                                <div className="sshAccess__row">
                                    <div className="row__title">This device&apos;s IP Address</div>
                                    <div className="row__desc">{sshInfo.ip}</div>
                                </div>
                            </div>
                            <div className="sshAccess__table">
                                <Table
                                    embedded={true}
                                    className="sshAccess__table--wrapper"
                                    header={[
                                        {
                                            title: `IP Address`,
                                            className: `ipaddress`
                                        },
                                        {
                                            title: `Date Added`,
                                            className: `timestamp`
                                        },
                                        {
                                            action: true
                                        }
                                    ]}
                                    loading={hosting_sshaccess_list_status}
                                    error=""
                                    matrix={sshMatrix}
                                    stacked={true}
                                />
                            </div>
                        </Fragment>
                    ),
                    pos: 'bottom'
                }
            };
        }

        if (ipAccessList && ipAccessList.length === 0) {
            conditionalProps = {
                desc: 'Your hosting account can be enabled for SSH access by allowing the IP address you want to give access to.',
                action: {
                    label: 'Add IP Address',
                    type: 'onClick',
                    className: '',
                    color: '',
                    size: 'large',
                    onClick: (e) => {
                        e.preventDefault();
                        this.setState({
                            showLightbox: true
                        });
                    }
                },
                info: String.raw`The SSH Access tool allows you to connect to your server securely and perform Linux command-line operations. Both ends of the client/server connection are authenticated using a digital certificate, and passwords are protected by being encrypted.
                    <br><br>
                    Helpful Hints
                    <br>
                    <li>Only enable SSH access if you intend on using it.</li>
                    <li>SSH Access will be locked down by CageFS and will not allow full access to the file system</li>
                    <li>For security reasons, we highly recommend you disable SSH access if you won't be using it for a while.</li>
                    <li>Please note that you will be required to authenticate before adding a new IP address for SSH, using a unique security token that is generated and sent to you upon request.</li>`
            };
        }

        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="sshAccess"
            >
                {/* Main content */}
                <Box
                    request={{
                        action: listSshAccess,
                        args: [hostingid, subaccount],
                        status: hosting_sshaccess_list_status
                    }}
                    className="sshAccess__box"
                    title="SSH Access"
                    status={handleLoadingStatus()}
                    {...conditionalProps}
                />

                {/* lightboxes */}
                {showLightbox && (
                    <OverlayLightbox
                        onOpen={showLightbox}
                        title="Add IP Address for SSH Access"
                        onClose={this.closeLightbox}
                        loading={hosting_sshaccess_status}
                    >
                        <SSHAccessForm onSubmit={submitAccessRequest} />
                    </OverlayLightbox>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            hosting_sshaccess_list_status: state.hosting.hosting_sshaccess_list_status,
            hosting_sshaccess_list_data: state.hosting.hosting_sshaccess_list_data,
            hosting_sshaccess_list_meta: state.hosting.hosting_sshaccess_list_meta,
            hosting_sshaccess_status: state.hosting.hosting_sshaccess_status,
            hosting_sshaccess_data: state.hosting.hosting_sshaccess_data,
            hosting_information_data: state.hosting.hosting_information_data,
            hosting_check_ip_data: state.hosting.hosting_check_ip_data,
            app_user_data: state.app.app_user_data
        }),
        {
            listSshAccess,
            enableSshAccess,
            disableSshAccess
        }
    )(SSHAccess)
);
