/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { ScrollableComponent } from 'components/ScrollableComponent';
import SimpleTable from 'components/SimpleTable';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import { NXSquare } from 'components/Utils/NXSquare';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InfoTableSpan, InfoTableSubHeading } from 'containers/katana/components';
import KatanaModuleCTA from 'containers/katana/components/moduleCTA';
import { KatanaSectionSettingsModule } from 'containers/katana/components/sectionSettingsModule';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { isChecklistItemEnabled } from 'containers/katana/modules/launchChecklist/methods';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStage, katanaModuleImages, katanaServicePages } from 'containers/katana/consts';
import './_KatanaFinaliseModule.scss';

const { SETUP, EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaFinaliseModule = function KatanaFinaliseModule() {
    /***** HOOKS *****/
    const isMobile = useAppViewport('xs');
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();
    const isMobileAll = useAppViewport(['xs', 'sm']);
    /***** QUERIES *****/
    const { data: get_katana_service_data = { attributes: { launch_checklist: [] } }, isSuccess: isGetKatanaServiceSuccess } =
        katanaQuery.serviceID.getService.useQuery(id);
    const { data: get_katana_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(id);

    /***** RENDER HELPERS *****/
    const isActive = get_katana_service_data.attributes.launch_checklist.includes(SiteSetupStage.FinishingTouchesCompleted);

    const sampleSEODetailsSimpleTableData = React.Children.toArray([
        <Grid columns={isMobileAll ? '1fr 2fr' : '180px 1fr'}>
            <InfoTableSubHeading>PAGE TITLE</InfoTableSubHeading>
            <InfoTableSpan>{get_katana_site_info_data?.title}</InfoTableSpan>
        </Grid>,
        // <Grid columns={isMobileAll? "1fr 2fr" : "180px 1fr"}>
        //     <InfoTableSubHeading>KEYWORDS</InfoTableSubHeading>
        //     <InfoTableSpan>{get_katana_site_info_data?.keywords}</InfoTableSpan>
        // </Grid>,
        <Grid columns={isMobileAll ? '1fr 2fr' : '180px 1fr'}>
            <InfoTableSubHeading>Description</InfoTableSubHeading>
            <InfoTableSpan>{get_katana_site_info_data?.description}</InfoTableSpan>
        </Grid>
    ]);

    /***** RENDER *****/
    return (
        <ScrollableComponent ready={isGetKatanaServiceSuccess}>
            <KatanaSectionSettingsModule>
                <NXBox.TopAccordion
                    title="Finishing Touches"
                    topChildren={isActive && <SolidButton to={getKatanaDestination(EDITOR.FINALISE)}>Edit Details</SolidButton>}
                >
                    <NXBox.DefaultPadding>
                        {isActive ? (
                            <Grid columns={isMobile ? '100%' : '90px 1fr'} gap={6} fullWidth alignItems--start>
                                <div className="KatanaFinaliseModule__favIconSection">
                                    <Text.Heading h4>Favicon</Text.Heading>
                                    <Border all>
                                        <Padding paddingOnly xy={2}>
                                            <NXSquare>
                                                {isGetKatanaSiteInfoLoading ? (
                                                    <RequestLoader />
                                                ) : (
                                                    <>
                                                        {get_katana_site_info_data?.favicon ? (
                                                            <BackgroundImage imageURL={get_katana_site_info_data?.favicon ?? ''} contain fill />
                                                        ) : (
                                                            <Flex justify="center" items="center" fullHeight fullWidth>
                                                                <Text size--m secondary italic align--center>
                                                                    No Favicon Set
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                    </>
                                                )}
                                            </NXSquare>
                                        </Padding>
                                    </Border>
                                </div>
                                <div className="KatanaFinaliseModule__SEODetailsSection">
                                    <Text.Heading h4>SEO Details</Text.Heading>
                                    <SimpleTable rows={sampleSEODetailsSimpleTableData} />
                                </div>
                            </Grid>
                        ) : (
                            <KatanaModuleCTA
                                image={katanaModuleImages.finishingTouchesModule}
                                alt="Finalise"
                                text={`Let's add the icing on the cake with some final details for your website`}
                                buttonText="Finalise web page and launch"
                                disabled={
                                    !isChecklistItemEnabled(
                                        get_katana_service_data.attributes.launch_checklist,
                                        SiteSetupStage.FinishingTouchesCompleted
                                    )
                                }
                                to={getKatanaDestination(SETUP.FINALISE)}
                            />
                        )}
                    </NXBox.DefaultPadding>
                </NXBox.TopAccordion>
            </KatanaSectionSettingsModule>
        </ScrollableComponent>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { KatanaFinaliseModule };
