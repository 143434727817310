/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _Input as Input } from 'components/Form/Input/_Input';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXInput ReduxForm Wrapper
 * @type {React.FC<import('redux-form').WrappedFieldProps & NXInputReduxProps>}
 */
const _InputReduxFormDefault = (props) => {
    const { input, meta, label, disabled, placeholder, isPlaceholderItalic, prepend, validated, readOnly, intrinsicProps, inputRef } = props;
    const { name, value, onChange, onBlur } = input;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} prepend={prepend} validated={validated} no-bg disabled={disabled}>
                <Input
                    disabled={disabled}
                    value={value}
                    name={name}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    inputRef={inputRef}
                    intrinsicProps={{ ...intrinsicProps, onChange, onBlur }}
                />
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _InputReduxFormDefault };
