/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ReactChildrenProp } from 'utilities/consts';
import './_IconButton.scss';

const IconButton = (props) => {
    const { group } = props;

    const renderIconButton = (button) => {
        const { icon, label, color, onClick, className, target, href, to } = button;

        const keyValue = `${button?.key}_${button?.props?.className}_${label}`;
        const key = `${keyValue}-id`;
        function renderIcon() {
            if (typeof icon === 'string') {
                return <i className={`icon icon-${icon}`} />;
            }

            return icon;
        }

        if (to) {
            return (
                <Link key={`IconButton-${key}`} className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''}`} to={to}>
                    <Tooltip
                        className={`IconTooltip ${color ? color : ''}`}
                        direction="up"
                        distance={5}
                        arrowSize={5}
                        content={<div className="IconTooltip__content">{label}</div>}
                    >
                        {renderIcon()}
                    </Tooltip>
                </Link>
            );
        }

        if (target && href && !onClick) {
            return (
                <a
                    key={`IconButton-${key}`}
                    className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''}`}
                    target={target}
                    href={href}
                >
                    <Tooltip
                        className={`IconTooltip ${color ? color : ''}`}
                        direction="up"
                        distance={5}
                        arrowSize={5}
                        content={<div className="IconTooltip__content">{label}</div>}
                    >
                        {renderIcon()}
                    </Tooltip>
                </a>
            );
        }

        if (href && !onClick) {
            return (
                <a key={`IconButton-${key}`} className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''}`} href={href}>
                    <Tooltip
                        className={`IconTooltip ${color ? color : ''}`}
                        direction="up"
                        distance={5}
                        arrowSize={5}
                        content={<div className="IconTooltip__content">{label}</div>}
                    >
                        {renderIcon()}
                    </Tooltip>
                </a>
            );
        }

        return (
            <button
                key={`IconButton-${key}`}
                className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''}`}
                type="button"
                onClick={onClick}
            >
                <Tooltip
                    className={`IconTooltip ${color ? color : ''}`}
                    direction="up"
                    distance={5}
                    arrowSize={5}
                    content={<div className="IconTooltip__content">{label}</div>}
                >
                    {renderIcon()}
                </Tooltip>
            </button>
        );
    };

    if (group) {
        return (
            <section key={`IconGroup-${group.length}`} className="IconGroup">
                {group.map((button) => {
                    return renderIconButton(button);
                })}
            </section>
        );
    }

    return renderIconButton(props);
};

IconButton.propTypes = {
    /**
     * The icon to be rendered
     */
    icon: PropTypes.oneOfType([PropTypes.string, ReactChildrenProp]),

    /**
     * The label to be rendered in the tooltip
     */
    label: PropTypes.string,

    /**
     * The color for customizing the IconButton component
     */
    color: PropTypes.string,

    /**
     * Event handler function for the click event
     */
    onClick: PropTypes.func,

    /**
     * Additional CSS class name(s) for custom styling
     */
    className: PropTypes.string,

    /**
     * The target attribute for <a> tags
     */
    target: PropTypes.string,

    /**
     * The URL for <a> tags
     */
    href: PropTypes.string,

    /**
     * The URL for <Link> tags from 'react-router-dom'
     */
    to: PropTypes.string,

    /**
     * An Array of props to render multiple buttons that have an icon and tooltip associated with them
     */
    group: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The icon to be rendered
             */
            icon: PropTypes.oneOfType([PropTypes.string, ReactChildrenProp]).isRequired,

            /**
             * The label to be rendered in the tooltip
             */
            label: PropTypes.string,

            /**
             * The color for customizing the IconButton component
             */
            color: PropTypes.string,

            /**
             * Event handler function for the click event
             */
            onClick: PropTypes.func,

            /**
             * Additional CSS class name(s) for custom styling
             */
            className: PropTypes.string,

            /**
             * The target attribute for <a> tags
             */
            target: PropTypes.string,

            /**
             * The URL for <a> tags
             */
            href: PropTypes.string,

            /**
             * The URL for <Link> tags from 'react-router-dom'
             */
            to: PropTypes.string
        })
    )
};

export default IconButton;
