/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXBox from 'components/NXBox';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PreviewWebpageButton } from 'containers/katana/components/previewWebpageButton';
import SiteStatusBlipMessage from 'containers/katana/components/siteStatusBlipMessage';
import CheckListItems from 'containers/katana/modules/launchChecklist/checkListItems';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useKatanaChecklistData } from 'containers/katana/modules/launchChecklist/useKatanaChecklistData';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStageOrder, katanaServicePages } from 'containers/katana/consts';
import './_KatanaLaunchChecklist.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { WrapWithComponent } from 'components/WrapWithComponent';
import type { KatanaNamespace } from 'containers/katana/types';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

const { EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaLaunchChecklist = function KatanaLaunchChecklist() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();
    const launchChecklistData = useKatanaChecklistData();

    const isMobile = useAppViewport(['xs', 'sm']);
    const isMobileSmall = useAppViewport('xs');

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);

    /***** RENDER HELPERS *****/
    const launch_checklist = get_katana_service_data?.attributes?.launch_checklist ?? [];
    const readyForLaunch = launchChecklistData.every(({ checkListKey }) => launch_checklist.includes(checkListKey));
    const siteStatus = get_katana_service_data?.attributes?.site_status as KatanaNamespace.SiteStatus;
    const filteredSiteSetupStageOrder = SiteSetupStageOrder.filter((key) => launchChecklistData.find(({ checkListKey }) => checkListKey === key));

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            className="KatanaLaunchChecklist"
            isOpenDefault
            title="Website Launch Checklist"
            description="Once you've checked off all the items on this list, you're ready to launch your website!"
        >
            <NXBox.DefaultPadding>
                <Grid
                    className="KatanaLaunchChecklist__checklist"
                    columns={`repeat(${filteredSiteSetupStageOrder.length}, 1fr)`}
                    alignItems--center
                    fullWidth
                >
                    <CheckListItems serviceID={id} circleSize={isMobileSmall ? 38 : 44} />
                </Grid>
            </NXBox.DefaultPadding>

            {readyForLaunch && (
                <Border top>
                    <NXBox.Bottom>
                        <Grid gap={1} columns={isMobile ? '1fr' : 'minmax(100px, 1fr) minmax(auto, 200px) minmax(auto, 200px)'}>
                            <NXBox.InfoPair
                                title="Website status"
                                description={
                                    <Grid columns="auto 1fr" fullWidth>
                                        <SiteStatusBlipMessage status={siteStatus} />
                                    </Grid>
                                }
                            />
                            <WrapWithComponent component={Flex} gap={1} wrap={isMobile} fullWidth direction={isMobileSmall && 'column'}>
                                <PreviewWebpageButton />
                                <SolidButton to={getKatanaDestination(EDITOR.LAUNCH)}>
                                    <Flex items="center">
                                        <PhosphorIcons.RocketLaunch />
                                        Launch Website
                                    </Flex>
                                </SolidButton>
                            </WrapWithComponent>
                        </Grid>
                    </NXBox.Bottom>
                </Border>
            )}
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { KatanaLaunchChecklist };
