/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { filterDelayedFuseSearch } from 'utilities/methods/search';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

export const knowledgeBaseFuseOptions = {
    shouldSort: true,
    distance: 1000,
    keys: ['post_content', 'post_title']
};

export function supportFilterDelayedFuseSearch(data, pattern, callback) {
    return filterDelayedFuseSearch(data, pattern, knowledgeBaseFuseOptions, callback);
}

export function getFlatServicesArray(allServicesData) {
    return Object.values(allServicesData || {}).reduce((acc, curr) => [...acc, ...curr], []);
}

export function getTicketServiceInfo(service) {
    if (!service) return null;

    let { domain, domain_status, status, product, has_priority_support } = service;
    const { attributes, type, included, id } = service;

    if (attributes) {
        domain = attributes.domain || attributes.name;
        domain_status = attributes.domain_status;

        status = attributes.status;
        product = attributes.product;
        has_priority_support = attributes.has_priority_support;
    }

    function getStatus() {
        if (status) return status.toLowerCase();
        if (domain_status) return domain_status.toLowerCase();
        return undefined;
    }

    function getProductType() {
        if (type) {
            if (type === 'web-hosting') return 'hosting';
            return type;
        }
        if (included) return getIncludedObjBasedOnType(included, 'product')['service_type'];
        return undefined;
    }

    function getProduct() {
        if (product) return product['name'];
        if (included) return getIncludedObjBasedOnType(included, 'product')?.attributes?.name;
        return undefined;
    }

    return {
        id,
        status: getStatus(),
        productType: getProductType(),
        product: getProduct(),
        domain,
        vendor: attributes?.vendor || undefined,
        has_priority_support: has_priority_support || false
    };
}

export function isTicketSubmitUrlValid(location, match) {
    if (!location || !match) return false;

    const { pathname, search } = location;
    const {
        params: { param4 }
    } = match;

    const pathNoTrailingSlash = pathname.endsWith('/') ? pathname.slice(0, pathname.length - 1) : pathname;

    const allowedPathnames = [
        '/support/tickets/submit',
        '/support/tickets/submit/technical-support',
        '/support/tickets/submit/accounts-billing',
        '/support/tickets/submit/sales',
        '/support/tickets/submit/customer-care/feedback',
        '/support/tickets/submit/customer-care/compliment',
        '/support/tickets/submit/customer-care/complaint',
        '/support/tickets/submit/customer-care/other'
    ];

    // Return false if the pathname is not in the list of allowed pathnames
    if (!allowedPathnames.includes(pathNoTrailingSlash)) return false;

    const searchParams = new URLSearchParams(search);

    const allowedSearchParams = ['invoice', 'service', 'subaccount-user', 'subaccount-domain', 'referenced-ticket'];

    const isValidValue = (value) => {
        const num = Number(value);
        // Must be a non-zero number OR be one of the other allowed values
        return (typeof num === 'number' && num > 0) || ['na', 'select', 'none'].includes(value);
    };

    const service = searchParams.get('service');
    const invoice = searchParams.get('invoice');

    // Check that certain params must be present and have certain values on certain urls
    switch (param4) {
        case 'technical-support': {
            if (invoice !== 'na') return false;
            if (!service || ['na', 'none'].includes(service)) return false;
            break;
        }
        case 'accounts-billing':
            if (service && !invoice) return false;
            break;
        case 'sales':
            if (invoice !== 'na') return false;
            break;
        case 'customer-care':
            if (invoice !== 'na' || service !== 'na') return false;
            break;
        case undefined:
        case '':
            break;
        default:
            return false;
    }

    // Check validity of all search params
    for (const [key, value] of searchParams.entries()) {
        // Return false if the search param is not on the allowed list of search params
        if (!allowedSearchParams.includes(key)) return false;
        // Only validate the value if the search param is "invoice" or "service"
        if (!['invoice', 'service'].includes(key)) continue;
        // If the param is "invoice" or "service" and its not a valid value, return false
        if (!isValidValue(value)) return false;
    }

    return true;
}

export function getTicketSubmitUrl({ department = null, invoice = null, service = null, subaccountUser = null, subaccountDomain = null } = {}) {
    const baseUrl = '/support/tickets/submit';

    if (!department) return baseUrl;

    const subaccountParams = `${subaccountUser ? `&subaccount-user=${subaccountUser}` : ''}${
        subaccountDomain ? `&subaccount-domain=${subaccountDomain}` : ''
    }`;

    switch (department) {
        case 'technical-support':
            if (invoice) throw new Error('Technical Support ticket cannot be about an invoice');
            return `${baseUrl}/technical-support?invoice=na&service=${service || 'select'}${service ? subaccountParams : ''}`;
        case 'accounts-billing':
            if (invoice && service) return `${baseUrl}/accounts-billing?invoice=${invoice}&service=${service}${subaccountParams}`;
            if (invoice) return `${baseUrl}/accounts-billing?invoice=${invoice}&service=none`;
            if (service) return `${baseUrl}/accounts-billing?invoice=none&service=${service}${subaccountParams}`;
            return `${baseUrl}/accounts-billing`;
        case 'sales':
            if (invoice) throw new Error('Sales ticket cannot be about an invoice');
            if (service) return `${baseUrl}/sales?invoice=na&service=${service}${service}`;
            return `${baseUrl}/sales?invoice=na`;
        case 'customer-care':
            return `${baseUrl}/customer-care/feedback?invoice=na&service=na`;
        default:
            throw new Error('Invalid department');
    }
}

export function processTicketLists(list) {
    return list && Array.isArray(list) ? list : [];
}
