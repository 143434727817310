import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_shimmer.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const Shimmer: React.FC<{ children?: React.ReactNode }> = ({ children }) => <div className="Shimmer">{children}</div>;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default Shimmer;
