/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EnableTwoFactorContent, { DISMISS_KEY, REMINDER_KEY } from './content';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsAccountHolder } from 'containers/account/methods';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Runs the logic to see whether the user is required to complete enable two factor, and marks itself as "required" if so
 */
function EnableTwoFactorCheck({ markSelfAsRequired }) {
    /***** HOOKS *****/
    const { login_authentication_status, login_authentication_data, app_user_data } = useSelector((state) => ({
        login_authentication_status: state.login.login_authentication_status,
        login_authentication_data: state.login.login_authentication_data,
        app_user_data: state.app.app_user_data
    }));

    const isVPN = useIsVPN();

    /***** EFFECTS *****/
    useEffect(() => {
        if (isVPN) return;

        if (
            checkIsAccountHolder(app_user_data) &&
            login_authentication_status === 'success' &&
            login_authentication_data &&
            !login_authentication_data?.attributes?.two_factor_required
        ) {
            const remind_later_2fa = Number(localStorage.getItem(REMINDER_KEY));
            const dismiss_2fa_prompt = localStorage.getItem(DISMISS_KEY);
            const been_longer_than_7_days =
                remind_later_2fa && !isNaN(remind_later_2fa) ? Date.now() - remind_later_2fa > 1000 * 3600 * 24 * 7 : false;

            if ((!remind_later_2fa && !dismiss_2fa_prompt) || (been_longer_than_7_days && !dismiss_2fa_prompt)) {
                markSelfAsRequired();
            }
        }
    }, []);

    /***** RENDER *****/
    return null;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

EnableTwoFactorCheck.propTypes = {
    /**
     * Function that marks "enableTwoFactor" item as required in the "postLoginChecklist"
     */
    markSelfAsRequired: PropTypes.func
};

export default EnableTwoFactorCheck;
export { EnableTwoFactorContent };
