/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createContentEditorModulesFormName } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/methods';
import { useBooleanStore } from 'utilities/hooks/useBooleanStore';
import { useReduxForm } from 'utilities/hooks/useReduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    katanaContentEditorLightboxHasChanges,
    katanaContentEditorLightboxSkipWritable
} from 'containers/katana/containers/ContentEditorLightbox/consts';
import { ContentEditorRenderModulesContext } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
type ContentEditorModulesFormProps = {
    children: React.ReactNode;
    initialValues?: Object;
    subpage: string;
    onSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'];
};

let ContentEditorModulesForm = reduxForm<{}, ContentEditorModulesFormProps>({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(function _ContentEditorModulesForm(props) {
    const {
        children,
        initialValues,
        /**
         * Redux Props
         */
        submitting,
        valid,
        handleSubmit,
        anyTouched,
        form
    } = props;
    /***** HOOKS *****/
    const { setTrue: allowSkipSection, setFalse: disableSkipSection } = useBooleanStore(katanaContentEditorLightboxSkipWritable);
    const { setFalse: setNoChanges, setValue: setChangesValue } = useBooleanStore(katanaContentEditorLightboxHasChanges);
    const submitButtonClickEventRef = useRef(writable<React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null>(null));

    const formState = useReduxForm(form);
    const { values } = formState;
    const pristine = useMemo(() => isEqual(values, initialValues), [values, initialValues]);

    const context = {
        pristine,
        submitting,
        valid,
        form,
        anyTouched,
        submitButtonClickEvent: submitButtonClickEventRef.current
    };

    /***** EFFECTS *****/
    useEffect(() => {
        //check that initialValues are populated
        if (Object.keys(initialValues).length > 0) {
            if (pristine) {
                allowSkipSection();
            } else {
                disableSkipSection();
            }
            setChangesValue(!pristine);
        }
    }, [pristine]);

    useEffect(() => {
        return () => {
            setNoChanges();
            allowSkipSection();
        };
    }, []);

    /***** RENDER *****/
    return (
        <ContentEditorRenderModulesContext.Provider value={context}>
            <form className="ContentEditorRenderModules" data-sentry-un-mask onSubmit={handleSubmit}>
                {children}
            </form>
        </ContentEditorRenderModulesContext.Provider>
    );
});
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state, props) => {
    const { subpage, initialValues } = props;
    const formName = createContentEditorModulesFormName(subpage);
    return {
        form: formName,
        initialValues
    };
};

ContentEditorModulesForm = connect(mapStateToProps)(ContentEditorModulesForm);

export { ContentEditorModulesForm };
