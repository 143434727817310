/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import _, { find, has, keys } from 'lodash';
import React, { useContext, useMemo, useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DeleteSectionConfirmationLightbox } from 'containers/katana/components/DeleteSectionConfirmationLightbox';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { useDeleteKatanaSectionMutation } from 'containers/katana/queries/useDeleteKatanaSectionMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useReduxForm } from 'utilities/hooks/useReduxForm';
import { useStoreEffect } from 'utilities/hooks/useStoreEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import { ContentEditorRenderModulesContext } from '../../ContentEditorLightbox/contentEditorRenderModules/consts';
import './_validFormSubmitButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface ValidFormSubmitButtonProps {
    ignorePristine?: boolean;
}

const { EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ValidFormSubmitButton: React.FC<ValidFormSubmitButtonProps> = ({ ignorePristine }) => {
    const [isShowingConfirmDeletion, setIsShowingConfirmDeletion] = useState(false);
    /***** HOOKS *****/
    const params = useSetupEditorRouteParams();
    const { page, id, childpage, subpage } = params;
    const { form, submitButtonClickEvent } = useContext(ContentEditorRenderModulesContext);
    const formData = useReduxForm(form);

    /***** QUERIES *****/
    const { data: get_katana_site_sections_data } = katanaQuery.serviceID.getSections.useQuery(id);
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);

    const isChildPageID = _.isNumber(childpage);

    const sectionRouteID = get_katana_site_sections_data?.find(({ id }) => id === childpage)?.section_id;

    const routeKey =
        isChildPageID && sectionRouteID ? getSectionNameRouteKey(get_katana_section_definitions_data?.[sectionRouteID]?.name) : childpage;
    const sectionDefinitionID = katanaQuery.serviceID.meta.getSectionDefinitions.useDefinitionIDbyRouteKey(id, routeKey);
    const {
        sectionID,
        query: { isLoading: isGetKatanaSiteSectionLoading }
    } = katanaQuery.serviceID.getSections.useGetIDByDefinitionID(id, sectionDefinitionID);

    const { mutate: mutateDeleteKatanaSection } = useDeleteKatanaSectionMutation(id);

    /***** FUNCTIONS *****/
    function removeSection() {
        mutateDeleteKatanaSection(sectionID);
    }

    const findErrorElementAttempts = useRef(0);
    const handleSubmitButtonEvent = () => {
        if (!has(formData, 'syncErrors')) {
            return;
        }
        const forElements = document.querySelectorAll(`[for]`);
        const syncErrorKeys = keys(formData.syncErrors);
        const foundElement = Array.from(forElements).find((element) => {
            return find(syncErrorKeys, (key) => key === element.getAttribute('for'));
        });

        const wrapperBoxErrorElement = document.querySelector('.wrapperbox--error');
        const ccpSelectToggleErrorElement = document.querySelector('.ccp__select--toggle-error');
        const finalElement = foundElement || wrapperBoxErrorElement || ccpSelectToggleErrorElement;
        if (finalElement) {
            finalElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            findErrorElementAttempts.current = 0;
        } else if (findErrorElementAttempts.current < 3) {
            findErrorElementAttempts.current += 1;
            requestAnimationFrame(handleSubmitButtonEvent);
        }
    };

    /***** EFFECTS *****/
    useStoreEffect(submitButtonClickEvent, handleSubmitButtonEvent, false);

    /***** RENDER HELPERS *****/
    const isEditor = page === EDITOR.to;

    function getButtonContent() {
        if (['launch', 'fix-dns'].includes(subpage)) {
            return 'Launch Website';
        }

        if (!sectionID && childpage) {
            return 'Create Section';
        }
        return isEditor ? 'Save Updates' : 'Confirm and Continue';
    }

    function renderDeleteSectionButton() {
        if (!isEditor || !childpage) {
            return null;
        }
        if (isGetKatanaSiteSectionLoading) {
            return <RequestLoader message="Loading Section data..." />;
        }
        if (!sectionID) {
            return null;
        }
        return (
            <Anchor onClick={() => setIsShowingConfirmDeletion(true)} color="warn">
                DELETE SECTION
            </Anchor>
        );
    }

    const deleteSectionButton = renderDeleteSectionButton();

    const colour = useMemo(() => {
        if (['launch', 'fix-dns'].includes(subpage)) {
            return null;
        }
        return isEditor ? 'confirm' : 'primary';
    }, [isEditor, subpage]);

    /***** RENDER *****/
    return (
        <>
            <Flex
                direction="column"
                gap={5}
                className={classNames('ValidFormSubmitButton', {
                    [`ValidFormSubmitButton--color-${colour}`]: colour
                })}
            >
                <ReduxFormButton
                    form={form}
                    color={colour}
                    ignorePristine={subpage !== 'launch' || ignorePristine}
                    submitButtonClickEvent={submitButtonClickEvent}
                >
                    {getButtonContent()}
                </ReduxFormButton>
                {deleteSectionButton}
            </Flex>

            <DeleteSectionConfirmationLightbox open={isShowingConfirmDeletion} setOpen={setIsShowingConfirmDeletion} onConfirm={removeSection} />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ValidFormSubmitButton;
