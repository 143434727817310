/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { tagColors } from 'components/Tags/OutlineTag';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SolidTag.scss';

const solidTagColors = [
    ...tagColors,
    'record__pink',
    'record__purple',
    'record__violet',
    'record__blue',
    'record__aqua',
    'record__green',
    'record__orange',
    'record__red',
    'black',
    'legacy'
];

const SolidTag = ({ children, color, className, round }) => <div className={classNames('SolidTag', color, className, { round })}>{children}</div>;

export default SolidTag;

/**********************************************************************************************************
 *   PROPTYPES
 **********************************************************************************************************/
SolidTag.propTypes = {
    /**
     * Text content of the tag
     */
    children: PropTypes.node,

    /**
     * Color of the tag
     */
    color: PropTypes.oneOf(solidTagColors),

    /**
     * Additional class to give to the tag
     */
    className: PropTypes.string,

    /**
     * Make the tag rounded
     */
    round: PropTypes.bool
};
