/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FieldTypesEnum, getFieldComponentAndClassName } from 'components/DynamicForm/utils';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const checkboxField = getFieldComponentAndClassName(FieldTypesEnum.CHECKBOX);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const CpanelMigrationAcknowledgementsForm = () => {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <>
            <Field
                label="You accept that ALL data on the destination cPanel account with VentraIP Australia will be deleted to allow the cPanel migration process to proceed. This process will delete ALL data including files, images, databases, email accounts and DNS records."
                name="delete_data"
                component={checkboxField.component}
                type={FieldTypesEnum.CHECKBOX}
                className={checkboxField.className}
            />

            <Field
                label={`You're aware that any email software configured on your local devices may need to be updated to connect to the new hosting server once the migration is complete. Our technical support team will be able to provide you with this new information as part of the migration process.`}
                name="email_reconfig"
                component={checkboxField.component}
                type={FieldTypesEnum.CHECKBOX}
                className={checkboxField.className}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default CpanelMigrationAcknowledgementsForm;
