/* eslint-disable react/jsx-pascal-case */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DEV_SectionDefinitionForm } from 'containers/katana/components/DEV/DEV_SectionDefinitionForm';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function DEV_RenderAllSectionDefinitions() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    const { data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(Number(id));
    const definitions = Object.values(data || {});
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Padding xy={isMobile ? 2 : 20}>
            <Flex direction="column" items="start">
                {React.Children.toArray(definitions.map((definition) => <a href={`#${definition.id}`}>{definition.id}</a>))}
            </Flex>
            {React.Children.toArray(definitions.map((definition) => <DEV_SectionDefinitionForm sectionDefinition={definition} />))}
        </Padding>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { DEV_RenderAllSectionDefinitions };
