/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/CheckBoxList';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      label?: React.ReactNode;
 *      className?: string;
 * } & import('redux-form').WrappedFieldProps>}
 */
export const _ReduxFormSimpleCheckbox = ({ input, label, className }) => {
    const { value, onChange } = input;

    /***** RENDER *****/
    return (
        <CheckBoxList.Item isChecked={value} onChange={onChange} className={className}>
            {label}
        </CheckBoxList.Item>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
