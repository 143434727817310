/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import type { KATANA_API } from 'utilities/api/katana';
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.user.socials.GET>>;
type TReturn = NXUtils.UnionToIntersection<ResponsesAPIKatanaIDUserSocialsGET200ResponseTypes['data']>;

export function filterInstagramType(data: TData): TReturn {
    return _.filter(data?.data, { type: 'instagram' }) as TReturn;
}
