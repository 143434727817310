/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Blip from 'components/Blip';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InfoTableSpan } from 'containers/katana/components';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from '../queries/queryTree';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TSiteStatusBlipMessage = React.FC<{
    status?: KatanaNamespace.SiteStatus;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SiteStatusBlipMessage: TSiteStatusBlipMessage = ({ status: propStatus }) => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: dnsData } = katanaQuery.serviceID.getDNS.useQuery(id, { enabled: propStatus === undefined });
    const { data: serviceData } = katanaQuery.serviceID.getService.useQuery(id, { enabled: propStatus === undefined });

    /***** RENDER HELPERS *****/
    const getBlipProps = () => {
        const status = propStatus || serviceData?.attributes?.site_status;

        if (status === 'published' && dnsData?.is_site_live) {
            return { confirm: true };
        }

        if (status === 'published') {
            return { published: true };
        }

        return { warn: true };
    };

    const getText = () => {
        const status = propStatus || serviceData?.attributes?.site_status;

        if (status === 'published' && dnsData?.is_site_live) {
            return 'Published and live';
        }

        if (status === 'published') {
            return (
                <Flex items="center">
                    Published - DNS Propagation
                    <Tooltip info="Your website has been published. Please allow up to 24 hours for DNS propagation to take place for your VIPsites website to appear online." />
                </Flex>
            );
        }

        return 'Not Live';
    };

    /***** RENDER *****/
    return (
        <>
            <Blip {...getBlipProps()} />
            <InfoTableSpan>{getText()}</InfoTableSpan>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SiteStatusBlipMessage;
