/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { application, whois_lookup_url } from 'config/config';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContactForm from '../forms/contacts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Accordion from 'components/Accordion';
import Anchor from 'components/Anchor';
import Box from 'components/Box';
import DialogNotification from 'components/Notifications/DialogNotification';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { formatPhoneNumberSynergy } from 'components/Form/PhoneInput/methods';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getDomainContacts, updateDomainContact } from '../action';
import { formatContactDetails } from '../methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Contacts = (props) => {
    const {
        domainid,
        disabled,
        /**
         * Redux Props
         */
        updateDomainContact,
        domain_protection_check_status,
        history,
        getDomainContacts,
        domain_contact_status,
        domain_contact_data,
        domain_protection_check_data,
        domain_information_data,
        domain_contact_update_status
    } = props;

    /***** STATE *****/
    const [localContactListReference, setLocalContactListReference] = useState(null);
    const [activeAccordion, setActiveAccordion] = useState('');

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** HELPER FUNCTIONS ******/
    const toggleAccordion = (contactType) => {
        setActiveAccordion((currentType) => (currentType === contactType ? '' : contactType));
    };

    /***** FUNCTIONS *****/
    function handleContactUpdate(values) {
        const contactType = values.contactupdate || values.contactType;
        const post = {};

        const formattedValues = formatContactDetails(values);

        /** Added fallback or object operator, but it shouldn't happen */
        const contactList = domain_contact_data?.attributes || {};

        if (values.applyAll) {
            for (const key in contactList) {
                post[key] = formattedValues;
            }
        } else {
            for (const key in contactList) {
                const contactDetails = domain_contact_data.attributes[key];
                post[key] = formatContactDetails(contactDetails);

                const synergyPhoneNumber = formatPhoneNumberSynergy(contactDetails.phone);

                post[key].phone = synergyPhoneNumber;
            }

            post[contactType] = formatContactDetails(values);
        }

        updateDomainContact(domainid, post, contactType);
        setActiveAccordion('');
    }

    function handleAccordionToggle(contactType) {
        const updatedContacts = { ...localContactListReference };

        Object.entries(updatedContacts).forEach(([key]) => {
            updatedContacts[key].contactupdate = key;
        });

        toggleAccordion(contactType);
        setLocalContactListReference(updatedContacts);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, domain_protection_check_status === 'success');
    }, [domain_protection_check_status]);

    useEffect(() => {
        /**
         * Only update the localContactList Reference if the domain contact data is the same as the current domain, we don't want to re-render the domain contacts dropdown for the domain that's being loaded if it's not the current domain.
         */
        if (!domain_contact_data) return;
        if (domain_contact_data.id !== Number(domainid)) return;
        setLocalContactListReference(domain_contact_data.attributes);
    }, [domain_contact_data]);

    /***** RENDER HELPERS *****/
    const domain = domain_information_data?.attributes?.domain ?? '';

    const renderContacts = () => {
        if (domain_contact_update_status === 'loading') {
            return <RequestLoader message="Updating contact details" />;
        }

        if (domain_contact_status !== 'loading' && !localContactListReference) {
            return <div className="contact__info">Could not find contacts associated to this domain name.</div>;
        }

        if (!localContactListReference || localContactListReference.length === 0) {
            return <div className="contact__info">No contacts were found associated to this domain name.</div>;
        }

        const titleMap = {
            registrant: 'Registrant Contact',
            tech: 'Technical Contact',
            billing: 'Billing Contact',
            admin: 'Administrative Contact'
        };

        return Object.entries(localContactListReference).map((data, index) => {
            const contactType = data[0];
            const contactData = data[1];
            const isActive = contactType === activeAccordion;
            return (
                <Accordion
                    controlled={{
                        active: isActive,
                        toggle: () => {
                            handleAccordionToggle(contactType);
                        }
                    }}
                    key={contactType}
                    title={titleMap[contactType] ?? 'Contact'}
                    content={renderForm(contactType, contactData, index)}
                />
            );
        });
    };

    const renderForm = (contact, contactData, key) => {
        return <ContactForm contact={contact} initialValues={contactData} onSubmit={handleContactUpdate} key={key} />;
    };

    const handleDisabledStatus = () => {
        const idProtection = domain_protection_check_data?.attributes?.id_protection ?? false;

        if (disabled || (idProtection && idProtection === 'Enabled')) {
            return true;
        }

        return false;
    };

    /*   SET CONDITIONAL PROPS
     **********************************************************************************************************/
    let conditionalProps = {
        custom: {
            render: <div className="contacts__container">{renderContacts()}</div>,
            pos: 'bottom'
        },
        footer: (
            <div>
                <Anchor className="footer__link" href={`${whois_lookup_url}${domain_information_data?.attributes?.domain || ''}`} target="_blank">
                    What does someone see when they look up my domain name?
                </Anchor>
            </div>
        )
    };

    if (handleDisabledStatus()) {
        conditionalProps = {
            bottom: true,
            columns: [
                {
                    render: (
                        <div className="sharedBox__infoColumn">
                            <div className="infoColumn__wrapper">
                                <div className="title">Domain Contacts Hidden</div>
                                <div className="desc contactsDescription">
                                    Your domain name contact details are hidden due to ID Protection being enabled on the domain. To view or edit
                                    those details, you will need to disable ID Protection.
                                </div>
                            </div>
                        </div>
                    )
                }
            ],
            action: {
                buttonType: 'Outline',
                label: 'Manage ID Protection',
                type: 'onClick',
                className: 'contactsAction',
                color: 'secondary',
                size: 'large',
                onClick: (e) => {
                    e.preventDefault();
                    history.push(`/my-services/domains/security/id-protection/${domainid}`);
                }
            }
        };
    }

    /***** RENDER *****/
    return (
        <div ref={scrollRef} className="service__contacts">
            <Box
                request={{
                    action: getDomainContacts,
                    args: domainid,
                    status: domain_contact_status
                }}
                status="success"
                className="contacts__box"
                title="Domain Name Contacts"
                desc={
                    domain.endsWith('.uk') ? (
                        <DialogNotification className="DialogNotificationPage--noMarginBottom" type="warning">
                            NOTE: Contact details for .UK domain names cannot be modified in {application}. To make changes to the contact details for
                            this domain, please visit the{' '}
                            <Anchor href="https://secure.nominet.org.uk/auth/login.html" target="_blank">
                                Nominet portal
                            </Anchor>
                            .
                        </DialogNotification>
                    ) : (
                        ''
                    )
                }
                {...conditionalProps}
            />
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    domain_contact_status: state.domain.domain_contact_status,
    domain_contact_data: state.domain.domain_contact_data,
    domain_protection_check_status: state.domain.domain_protection_check_status,
    domain_protection_check_data: state.domain.domain_protection_check_data,
    domain_information_data: state.domain.domain_information_data,
    domain_contact_update_status: state.domain.domain_contact_update_status
});

const mapDispatchToProps = {
    getDomainContacts,
    updateDomainContact
};

Contacts = connect(mapStateToProps, mapDispatchToProps)(Contacts);

Contacts = withRouter(Contacts);

export default Contacts;
