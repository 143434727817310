/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useContext } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RadioButtonCircle from 'components/Form/RadioButtonCircle';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RadioButtonsWithSelectedRenderContext } from 'components/Form/RadioButtonsWithSelectedRender/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<TRadioButtonsWithSelectedRender.Item.Props>}
 */
const _RadioButtonsWithSelectedRenderItem = ({ children, label, value, color, alwaysRenderChildren, isSelected, disabled }) => {
    /***** HOOKS *****/
    const { _value, _onChange } = useContext(RadioButtonsWithSelectedRenderContext);

    /***** FUNCTIONS *****/
    function handleItemClick() {
        _onChange(value);
    }

    const _isSelected = isSelected || _value === value;
    const shouldRenderChildren = _isSelected || alwaysRenderChildren;

    /***** RENDER *****/
    return (
        <Flex direction="column" className="RadioButtonsWithSelectedRenderItem">
            <RadioButtonCircle.Button disabled={disabled} color={color} isActive={_isSelected} onClick={handleItemClick}>
                {label}
            </RadioButtonCircle.Button>
            {typeof children === 'function' ? children({ shouldRenderChildren }) : shouldRenderChildren && children}
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _RadioButtonsWithSelectedRenderItem };
