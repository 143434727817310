/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { merge } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useFormValuesFileUpload } from 'containers/katana/hooks/useFormValuesFileUpload';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   Katana Update site business info
 **********************************************************************************************************/
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the site info
 * @param {number} serviceID
 */
function useUpdateKatanaSiteBusinessInfoMutation(serviceID, options = {}) {
    /***** HOOKS *****/
    const { page } = useSetupEditorRouteParams();
    const processAndUploadFiles = useFormValuesFileUpload(serviceID);

    /***** FUNCTIONS *****/
    /**
     * @param {KatanaAPI.Katana.ServiceID.Business.POST.Params['attributes']} values
     */
    async function handleMutationAsync(values) {
        const filteredAttributes = filterEndpointAttributeKeys(values, useUpdateKatanaSiteBusinessInfoMutation.acceptableKeys);

        const fileRecords = await processAndUploadFiles(filteredAttributes);

        const updatedAttributes = merge(filteredAttributes, fileRecords);

        if (!Object.keys(updatedAttributes).length) {
            return Promise.resolve();
        }

        return KATANA_API.katana.service_id.business.POST({ serviceID, attributes: updatedAttributes });
    }

    /**
     * @param {KatanaAPI.Katana.ServiceID.Business.POST.Params['attributes']} values
     */
    function handleMutation(values) {
        return handleMutationAsync(values);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: handleMutation,
        onError: handleDefaultErrorNotification,
        onSuccess: page === 'setup' ? null : handleDefaultSuccessNotification,
        onSettled: () => {
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
        },
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

// useUpdateKatanaSiteBusinessInfoMutation.invalidations = {
//     getKatanaServiceBoilerPlate,
//     getKatanaSiteBusinessInfoBoilerPlate
// };

useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS = /** @type {const} */ ({
    ADDRESS: 'address',
    BUSINESS_CATEGORIES: 'business_categories',
    BUSINESS_NUMBER: 'business_number',
    BUSINESS_NUMBER_TYPE: 'business_number_type',
    EMAIL: 'email',
    LOGO: 'logo',
    NAME: 'name',
    OPERATING_HOURS: 'operating_hours',
    PHONE: 'phone',
    TAG_LINE: 'tag_line'
});
useUpdateKatanaSiteBusinessInfoMutation.acceptableKeys = Object.values(useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS);

export { useUpdateKatanaSiteBusinessInfoMutation };
