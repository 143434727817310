/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Modal } from 'components/Lightboxes/Modal';
import KatanaLoaderImage from 'components/Loaders/App/katanaLoaderImage';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useCreateKatanaSite } from 'containers/katana/queries/mutations';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useTrialRouteParams } from 'containers/katana/containers/trial/domainName/methods';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';

import './_katanaTrialDomainModal.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaTrialDomain = () => {
    /***** STATE *****/
    const [optimisticKatanaServiceID, setOptimisticKatanaServiceID] = useState<number>(null);

    /***** HOOKS *****/
    const { domain_name } = useTrialRouteParams();
    const history = useHistory();

    /***** QUERIES *****/
    const {
        data: check_katana_domain_data,
        isSuccess: isCheckDomainSuccess,
        isError: isCheckDomainError
    } = katanaQuery.checkDomain.useQuery(domain_name);
    const {
        mutate: mutateCreateKatanaSite,
        isPending: isCreateKatanaSitePending,
        isError: isCreateKatanaSiteError,
        isSuccess: isCreateKatanaSiteSuccess
    } = useCreateKatanaSite();
    const katanaServiceID =
        typeof check_katana_domain_data === 'object' && !!check_katana_domain_data && 'site' in check_katana_domain_data
            ? check_katana_domain_data?.site?.id
            : 0;
    const { getKatanaDestination } = useKatanaURL(katanaServiceID || optimisticKatanaServiceID);
    const { isSuccess: isGetKatanaServiceSuccess, isError: isGetKatanaServiceError } = katanaQuery.serviceID.getService.useQuery(
        Number(katanaServiceID || optimisticKatanaServiceID)
    );

    /***** FUNCTIONS *****/
    function claimKatanaPage() {
        mutateCreateKatanaSite(domain_name, {
            onSuccess: (data) => {
                if (data?.data?.site?.id) {
                    setOptimisticKatanaServiceID(data?.data?.site?.id);
                }
            }
        });
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (
            !isCheckDomainSuccess ||
            check_katana_domain_data?.site_exists ||
            isCreateKatanaSitePending ||
            isCreateKatanaSiteError ||
            isCreateKatanaSiteSuccess
        )
            return;
        claimKatanaPage();
    }, [isCheckDomainSuccess, isCreateKatanaSitePending, isCreateKatanaSiteError, isCreateKatanaSiteSuccess, check_katana_domain_data]);

    useEffect(() => {
        if (!isGetKatanaServiceSuccess) return;
        if (optimisticKatanaServiceID || katanaServiceID) {
            history.push(getKatanaDestination(katanaServicePages.SETUP));
            katanaQuery.getTrialDomains.invalidateQueries();
        }
    }, [isGetKatanaServiceSuccess, optimisticKatanaServiceID, katanaServiceID]);

    useEffect(() => {
        if (isCreateKatanaSiteError || isCheckDomainError || isGetKatanaServiceError) {
            history.push(serviceNav.VIPsites.link);
        }
    }, [isCheckDomainError, isCreateKatanaSiteError, isGetKatanaServiceError]);

    useEffect(() => {
        return () => {
            katanaQuery.getTrialDomains.invalidateQueries();
        };
    }, []);

    /***** RENDER *****/
    return (
        <Modal
            isOpen
            onClose={() => {
                history.push(serviceNav.VIPsites.link);
            }}
            className="KatanaTrialDomainModal"
            ariaLabel="Trial Domain Name VIPsites"
        >
            <Flex justify="center" items="center" direction="column" fullHeight gap={4}>
                <KatanaLoaderImage />
                <RequestLoader heightAuto />
                <Text size--xl>Getting your VIPsites service ready for:</Text>
                <Text size--xxl bold>
                    {domain_name}
                </Text>
            </Flex>
        </Modal>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaTrialDomain;
