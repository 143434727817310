/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ViewPortControlActive.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ViewPortControlActive = ({ isActive }) => (
    <div
        className={classNames('ViewPortControlActive', {
            'ViewPortControlActive--isActive': isActive
        })}
    />
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
