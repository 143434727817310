/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { NXForm } from 'components/Form/NXForm';
import { TextArea } from 'components/Form/TextArea';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RenderAcknowledgements } from 'containers/services/forms/cancelServiceForm/reduxform/renderAcknowledgements';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { cancelServiceReasonOptions } from 'containers/services/forms/cancelServiceForm/consts';

import './_CancelServiceFormReduxFormDefault.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<CancelServiceProps>}
 */
const _CancelServiceFormReduxFormDefault = (props) => {
    const { onSubmit, reasonOptions = cancelServiceReasonOptions, acknowledgements = ['confirm_backup', 'confirm_delete'] } = props;
    return (
        <NXForm form="CancelServiceModalForm" enableReinitialize destroyOnUnmount className="CancelServiceFormReduxFormDefault" onSubmit={onSubmit}>
            {({ handleSubmit, submitting, pristine, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Flex direction="column" className="CancelServiceFormReduxFormDefault__mainFieldsWrapper">
                        <Field
                            label="Primary Reason for Cancelling the Service"
                            name="reason"
                            component={RenderSelectField}
                            type="select"
                            validate={[requiredFieldValidation]}
                            options={reasonOptions}
                        />

                        <Field label="Additional Comments (Optional)" name="reason_comment" component={TextArea.ReduxForm.Default} rows={5} />

                        <Text secondary lead--s size--xss>
                            PLEASE NOTE: Additional comments are not actively monitored and are used for reference only. Any further requests made may
                            not be actioned by our team. If you require any additional assistance, please contact our{' '}
                            <Anchor to="/support/tickets/submit">Support Team</Anchor>.
                        </Text>
                    </Flex>

                    <RenderAcknowledgements acknowledgements={acknowledgements} />

                    <NXForm.Button>Continue</NXForm.Button>
                </form>
            )}
        </NXForm>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _CancelServiceFormReduxFormDefault };
