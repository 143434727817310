/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/CheckBoxList';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_indentFlush.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function IndentFlush({ children }) {
    /***** RENDER *****/
    return (
        <div className="KatanaIndentFlush">
            <CheckBoxList>
                <CheckBoxList>{children}</CheckBoxList>
            </CheckBoxList>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { IndentFlush };
