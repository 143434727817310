/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { createContext, useContext, useEffect, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import _ReviewConfirmOrCancel from './_reviewConfirmOrCancel';
import _ReviewTable from './_reviewTable';
import _ReviewTotal from './_reviewTotal';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

const ReviewPlanContext = createContext({});
export const useReviewPlan = () => useContext(ReviewPlanContext);

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {{
 *   name: string,
 *   rawData: any,
 *   currentPlan: string,
 *   cycle: string,
 *   refund: string,
 *   customConfirm: ({ onClick }) => import('react').ReactNode,
 *   customReviewElement: import('react').ReactNode,
 *   onConfirm: ({ name, cycle, rawData }) => void,
 *   onCancel: () => void,
 *   onGoBack: () => void,
 *   price: string,
 *   total: string,
 *   isDowngrade: boolean
 * }} TPropsExcludingChildren
 */

/**
 * @typedef {React.ReactNode | (props: propsExcludingChildren) => React.ReactNode} TChildren
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * ComposableReviewPlan - Private Component exported as part of the ChangePlan Component.
 * This component is used to compose the review plan section of the ChangePlan Component.
 * @param {{ children: TChildren } & TPropsExcludingChildren} props
 */
const ComposableReviewPlan = ({ children, ...props }) => {
    const { name, cycle, rawData, price, total, onConfirm } = props;

    /***** STATE *****/
    const [isLoading, setIsLoading] = useState(true);

    /***** FUNCTIONS *****/
    const onClick = () => {
        setIsLoading(true);
        onConfirm({ name, cycle, rawData });
    };

    const context = {
        ...props,
        onClick
    };

    /***** EFFECTS *****/
    useEffect(() => {
        if (name && price && total) setIsLoading(false);
    }, [name, price, total]);

    /***** RENDER HELPERS *****/
    const renderChildren = () => (typeof children === 'function' ? children(context) : children);

    /***** RENDER *****/
    if (isLoading) return <RequestLoader />;

    return (
        <ReviewPlanContext.Provider value={context}>
            <div className="reviewPlan">{renderChildren()}</div>
        </ReviewPlanContext.Provider>
    );
};

ComposableReviewPlan.ReviewTable = _ReviewTable;
ComposableReviewPlan.ReviewTotal = _ReviewTotal;
ComposableReviewPlan.ReviewConfirmOrCancel = _ReviewConfirmOrCancel;

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ComposableReviewPlan;
