/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides primary color and some other text styling such as font size and weight
 * @param {{
 *    title: React.ReactNode
 *    className?: string
 * }} props
 */
function NXBoxTitle({ title, className }) {
    /***** RENDER *****/
    return (
        <Text size--xl primary semiBold className={className}>
            <h3 className="NXBoxTitle">{title}</h3>
        </Text>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

NXBoxTitle.propTypes = {
    // Title text
    title: PropTypes.string
};

export default NXBoxTitle;
