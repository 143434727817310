/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Note.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function Note({ beforeColon, text }) {
    /***** RENDER *****/
    return (
        <p className="Note">
            <span>{beforeColon || 'Note'}:</span> {text}
        </p>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

Note.propTypes = {
    /**
     * Optionally replace "Note" with something else, like "Please note"
     */
    beforeColon: PropTypes.string,

    /**
     * The text that comes after the "Note:" part
     */
    text: PropTypes.string.isRequired
};

export default Note;
