/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { loginGetUser } from 'App/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/support';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';

/*********************************************************************************************************
 *   TYPE DEFINTIONS
 ********************************************************************************************************/
/**
 * @typedef {API['support']['POST']['tickets']['new']} TEndpoint
 */

/**********************************************************************************************************
 * HOOK START
 **********************************************************************************************************/
/**
 * Submit an eTicket reply
 *
 * @type {NXQuery.TMutation<TEndpoint>}
 */
const useSubmitNewTicketMutation = (options) => {
    return useMutation({
        mutationFn: API.support.POST.tickets.new,
        onError: (e) => {
            Sentry.captureException(e);
            handleDefaultErrorNotification(e);
        },
        onSuccess: async (_, { is_technical }) => {
            // Need to refetch user data if is_technical flag has been updated by e-ticket submission
            if (is_technical) {
                await new Promise((resolve) => {
                    loginGetUser({ finallyCallback: resolve });
                });
            }
        },
        ...options
    });
};

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useSubmitNewTicketMutation };
