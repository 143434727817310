/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/*   ACTIONS
 *****************************************************/
import { removeSidebarRef, upsertSidebarRef } from 'components/Sidebar/action';

import { scrollToComponent } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Hook that registers a component with the sidebar so that it can be scrolled to, and scrolls to the component on load
 *
 * @type {useScrollableComponent}
 */
export const useScrollableComponent = (path, ready, options) => {
    const { allowImmediateReady } = options || {};

    /***** HOOKS *****/
    const scrollRef = /** @type {React.RefObject<HTMLDivElement>} */ (React.useRef(null));
    const hasReadyBeenFalse = React.useRef(false);
    const { pathname } = useLocation();

    /***** EFFECTS *****/
    useEffect(() => {
        /**
         *  @type {(action: typeof upsertSidebarRef | typeof removeSidebarRef) => (path: string | Array<string> | null) => void}
         */
        const performAction = (action) => (path) => {
            if (!scrollRef.current || !path) {
                return;
            }

            if (!Array.isArray(path)) {
                action({ [path]: scrollRef.current });
            } else {
                action(path.reduce((acc, item) => ({ ...acc, [item]: scrollRef.current }), {}));
            }
        };

        performAction(upsertSidebarRef)(path);

        return () => {
            performAction(removeSidebarRef)(path);
        };
    }, [path, scrollRef.current]);

    useEffect(() => {
        if (!allowImmediateReady && !ready) {
            hasReadyBeenFalse.current = true;
        }

        switch (true) {
            case !scrollRef.current:
            case !ready:
            case !path:
            case !allowImmediateReady && !hasReadyBeenFalse.current:
            case !Array.isArray(path) && path !== pathname:
            case Array.isArray(path) && !path.includes(pathname):
                return;
            default:
                scrollToComponent(scrollRef.current, path)();
        }
    }, [ready]);

    /***** HOOK RESULTS *****/
    return scrollRef;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
