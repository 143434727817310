/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useHistory, useLocation } from 'react-router-dom';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useSearchParams() {
    /***** HOOKS *****/
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);

    /***** FUNCTIONS *****/
    const pushToHistory = () => history.push(`${location.pathname}?${searchParams.toString()}`);

    /**
     * Retrieve the value of a give key from the url search params
     * or if no key is provided, return all keys
     */
    const getSearchParam = (key: string) => searchParams.get(key);

    const addSearchParam = (key: string, value: any) => {
        searchParams.set(key, value);
        pushToHistory();
    };

    const removeSearchParam = (key: string) => {
        searchParams.delete(key);
        pushToHistory();
    };

    /***** RETURN *****/
    return {
        /**
         * Retrieve the value of a give key from the url search params
         */
        searchParamsGet: getSearchParam,
        /**
         * Add a given key to the url search params
         */
        searchParamsAdd: addSearchParam,
        /**
         * Remove a given key from the url search params
         */
        searchParamsRemove: removeSearchParam
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useSearchParams;
