/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { WrapperBox } from 'components/Form/WrapperBox/WrapperBox';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EntryCreationBehaviourHandlerWrapper } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper';
import { _AccordionFieldArrayChild as AccordionFieldArrayChild } from 'containers/katana/formFields/repeated/inputTypes/accordion/_AccordionFieldArrayChild';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { SortableRepeatedWrapper } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/SortableRepeatedWrapper';
import { useFieldArrayMetaOverrideOnSubmitButtonClick } from 'containers/katana/formFields/repeated/methods/useFieldArrayMetaOverrideOnSubmitButtonClick';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {KatanaRepeatedFieldArrayRenderer}
 */
const _AccordionFieldArrayRenderer = (props) => {
    const { fields, properties, property, meta } = props;

    /***** STATE *****/
    const metaOverride = useFieldArrayMetaOverrideOnSubmitButtonClick(meta);

    const sortable = true;

    /***** RENDER *****/
    return (
        <WrapperBox error={metaOverride.error && !metaOverride.pristine} fullWidth>
            <EntryCreationBehaviourHandlerWrapper {...{ property, properties, fields }}>
                <Flex direction="column">
                    {sortable ? (
                        <SortableRepeatedWrapper fields={fields}>
                            {({ filteredResults }) => {
                                return filteredResults.map(({ member, index, fields, _sortableID }) => {
                                    return (
                                        <AccordionFieldArrayChild
                                            key={_sortableID}
                                            {...{ member, index, fields, properties, property }}
                                            sortable={sortable}
                                        />
                                    );
                                });
                            }}
                        </SortableRepeatedWrapper>
                    ) : (
                        fields.map((member, index, fields) => {
                            return <AccordionFieldArrayChild key={index} {...{ member, index, fields, properties, property }} sortable={sortable} />;
                        })
                    )}
                </Flex>
            </EntryCreationBehaviourHandlerWrapper>
            <ValidationMessage.ReduxForm.FieldArray {...metaOverride} />
        </WrapperBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _AccordionFieldArrayRenderer };
