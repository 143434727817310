/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, emailFieldValidation, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'gsuiteOrganisationForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let GSuiteSetupOrganisationForm = (props) => {
    const { handleSubmit } = props;

    /***** RENDER *****/
    return (
        <form className="gsuiteSetup__form" onSubmit={handleSubmit}>
            <div className="form__row">
                <div className="form__column">
                    <Field
                        label="Full Name"
                        name="full_name"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column form__column--half">
                    <Field
                        label="Company Name"
                        name="company_name"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="form__column form__column--half">
                    <Field
                        label="Alternative Email Address"
                        name="alternate_email"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation, emailFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column form__column--half">
                    <Field
                        label="Address"
                        name="address"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="form__column form__column--half">
                    <Field
                        label="ZIP/Postal Code"
                        name="postal_code"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>

            <div className="form__row">
                <ReduxFormButton form={formName} className="form__column full">
                    Save Changes
                </ReduxFormButton>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
GSuiteSetupOrganisationForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(GSuiteSetupOrganisationForm);

const mapStateToProps = (state) => {
    let initialValues = {};

    if (state.gsuite.gsuite_service_data) {
        const { full_name, company_name, alternate_email, address, postal_code } = state.gsuite.gsuite_service_data.attributes;
        initialValues = {
            full_name,
            company_name,
            alternate_email,
            address,
            postal_code
        };
    }

    return {
        initialValues,
        gsuite_service_data: state.gsuite.gsuite_service_data
    };
};

GSuiteSetupOrganisationForm = connect(mapStateToProps, null)(GSuiteSetupOrganisationForm);

export default GSuiteSetupOrganisationForm;
