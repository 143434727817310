/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FormattedDescription from 'components/FormattedDescription';
import NXTable from 'components/NXTable';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const INVOICE_VIEW_HEADINGS = {
    DESCRIPTION: 'description',
    COST: 'cost'
};

const invoiceDefaultData = { attributes: { invoice_items: [] } };

const desktopTableHeadings = [
    [INVOICE_VIEW_HEADINGS.DESCRIPTION, 'Description'],
    [INVOICE_VIEW_HEADINGS.COST, 'Cost']
];

const contentComponents = {
    [INVOICE_VIEW_HEADINGS.DESCRIPTION]: ({ description, discount, promotion_id, promotion }) => {
        return (
            <Text size--xss secondary key={INVOICE_VIEW_HEADINGS.DESCRIPTION}>
                <FormattedDescription description={description} />
                {Number(discount) > 0 ? (
                    <Text className="invoiceViewTable__discountDescription" span>
                        {promotion_id && promotion ? `Promotional Code: ${promotion}` : 'Discount'}
                    </Text>
                ) : (
                    ''
                )}
            </Text>
        );
    },
    [INVOICE_VIEW_HEADINGS.COST]: ({ subtotal, discount, total }) => {
        if (Number(discount) > 0)
            return (
                <Text size--xss secondary key={`${INVOICE_VIEW_HEADINGS.COST}${discount}`}>
                    ${subtotal} AUD
                    <br />
                    <Text className="invoiceViewTable__discountCost" span>
                        -${discount} AUD
                    </Text>
                </Text>
            );
        return (
            <Text size--xss secondary key={INVOICE_VIEW_HEADINGS.COST}>
                ${total} AUD
            </Text>
        );
    }
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function InvoiceViewTable({ invoiceID }) {
    /***** QUERIES *****/
    const { data: get_invoice_data = invoiceDefaultData } = useGetInvoiceQuery(invoiceID);

    /***** RENDER HELPERS *****/
    const { invoice_items } = get_invoice_data.attributes;

    /***** RENDER *****/
    return (
        <NXTable columns="1fr auto" className="invoiceViewTable">
            <NXTable.Header headings={desktopTableHeadings} />
            <NXTable.Body>
                {invoice_items.map((invoice_item, i) => {
                    const rowClassNames = classNames({ InvoiceViewTable__promoRow: invoice_item.discount > 0 });

                    return (
                        <NXTable.Row key={i} className={rowClassNames}>
                            {desktopTableHeadings.map(([key]) => contentComponents[key](invoice_item))}
                        </NXTable.Row>
                    );
                })}
            </NXTable.Body>
        </NXTable>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

InvoiceViewTable.propTypes = {
    /**
     * The invoice id you want to display
     */
    invoiceID: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default InvoiceViewTable;
