import { entries, isArray, isNil } from 'lodash';

/**
 *
 * @param {Object} input
 * @param {Array<(v:any)=> boolean>} [filters]
 * @returns
 */
export function deepFilter(input, filters = [isNil]) {
    /**
     * @param {unknown} v
     */
    function handleFilters(v) {
        return !filters.some((filter) => filter(v));
    }

    if (typeof input !== 'object' || input === null) {
        return input;
    }

    if (isArray(input)) {
        const filteredArray = input.filter(handleFilters);
        if (filteredArray.length === 0) {
            return [];
        }
        return filteredArray.map((val) => deepFilter(val, filters)).filter(handleFilters);
    }

    return entries(input).reduce((acc, [key, value]) => {
        const deepFilterResult = deepFilter(value, filters);

        const filteredValue = handleFilters(deepFilterResult);
        if (!filteredValue) {
            return acc;
        }
        acc[key] = deepFilterResult;
        return acc;
    }, {});
}
