/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FormWrapper from '../formWrapper';
import ControlPanelSection1 from './controlPanelSection1';
import ControlPanelSection2 from './controlPanelSection2';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ControlPanelForm = (props) => {
    const { currentEnv, selectedService } = props;

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <FormWrapper service_id={selectedService}>
            {({
                validateDetails,
                handleSubmitMigration,
                attemptsLeft,
                handleSkipNow,
                stepOneComplete,
                serviceValidationData,
                handleFormDetailsFieldChange,
                serviceValidationModifiedData,
                allowEditDetails,
                canEditDetails
            }) => (
                <>
                    <ControlPanelSection1
                        onSubmit={(values) => validateDetails('control_panel', values)}
                        validationAttempts={attemptsLeft}
                        handleSkipNow={handleSkipNow}
                        stepOneComplete={stepOneComplete}
                        currentEnv={currentEnv}
                        serviceValidationData={serviceValidationData}
                        handleFormDetailsFieldChange={handleFormDetailsFieldChange}
                        serviceValidationModifiedData={serviceValidationModifiedData}
                        allowEditDetails={allowEditDetails}
                        canEditDetails={canEditDetails}
                        selectedService={selectedService}
                    />

                    {/* Render all others steps here */}
                    {stepOneComplete ? (
                        <ControlPanelSection2
                            onSubmit={(values) => {
                                return handleSubmitMigration('control_panel', currentEnv, values);
                            }}
                        />
                    ) : (
                        ''
                    )}
                </>
            )}
        </FormWrapper>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ControlPanelForm;
