/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { validatePasswordCheckerCustomRequirements } from './index';

/**********************************************************************************************************
 *   GOOGLE WORKSPACE REQUIREMENTS LISTS
 **********************************************************************************************************/
export const googleWorkspaceRequirements = {
    require: ['minLength8', 'upperAndLower', 'num', 'specialChar', 'maxLength100'],
    exclude: []
};

/**********************************************************************************************************
 *   GOOGLE WORKSPACE PASSWORD VALIDATIONS
 **********************************************************************************************************/
export const validateGoogleWorkspaceRequirements = (value, required) =>
    validatePasswordCheckerCustomRequirements(value, googleWorkspaceRequirements, required);
