/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RichTextEditorContext, newRichTextEditorWritable } from 'components/Form/RichTextEditor/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface RichTextEditorContextProviderProps {
    children: React.ReactNode;
}

export const RichTextEditorContextProvider: React.FC<RichTextEditorContextProviderProps> = ({ children }) => {
    /***** HOOKS *****/
    const richTextEditorContextWritable = useRef(newRichTextEditorWritable());

    /***** RENDER *****/
    return <RichTextEditorContext.Provider value={richTextEditorContextWritable.current}>{children}</RichTextEditorContext.Provider>;
};
