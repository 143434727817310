/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/*   ACTIONS
 *****************************************************/
import { generateId } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Card.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated Use NXCard instead
 */
export const Card = (props) => {
    const { className, title, desc, action, icon, image, active } = props;

    const conditionalProps = {
        onClick: action ? action : undefined
    };

    return (
        <button key={generateId()} className={`eui-card${className ? `  ${className}` : ''}${active ? ' active' : ''}`} {...conditionalProps}>
            {image ? <img className="eui-card__img" src={image} alt={title} /> : ''}
            {title ? <div className="eui-card__title">{title}</div> : ''}
            {desc ? <div className="eui-card__desc">{desc}</div> : ''}
            {icon ? <i className={`eui-card__icon icon icon-${icon}`}></i> : ''}
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
/**
 * @deprecated Use NXCard instead
 */
export const CardGroup = (props) => {
    const { className, cardObject } = props;

    return (
        <div key={generateId()} className={`eui-cardGroup${className ? `  ${className}` : ''}`}>
            {cardObject.map((cardItem) => Card(cardItem))}
        </div>
    );
};
