/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PromoCodeApplyButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PromoCodeApplyButton({ isLoading, isDisabled, applyButtonOnClick }) {
    /***** RENDER *****/
    return (
        <div className="PromoCodeApplyButton">
            {isLoading ? (
                <RequestLoader />
            ) : (
                <Anchor
                    onClick={applyButtonOnClick}
                    className={classNames('PromoCodeApplyButton__anchor', {
                        'PromoCodeApplyButton__anchor--disabled': isDisabled
                    })}
                >
                    Apply Coupon
                </Anchor>
            )}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

PromoCodeApplyButton.propTypes = {
    /**
     * Whether to show a loader in place of the "apply promo" button
     */
    isLoading: PropTypes.bool,

    /**
     * Whether to grey out and disable the "apply promo" button
     */
    isDisabled: PropTypes.bool,

    /**
     * Function to call on click of the "apply promo" button
     */
    applyButtonOnClick: PropTypes.bool
};

export default PromoCodeApplyButton;
