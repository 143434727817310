/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxBottomColumns.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { FlexNamespace } from 'components/Utils/Flex/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface NXBoxBottomColumnsProps {
    columns: React.ReactNode[];
    gap?: FlexNamespace.Props['gap'];
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides a column layout to its children
 */
const NXBoxBottomColumns: React.FC<NXBoxBottomColumnsProps> = ({ columns, gap = 4 }) => {
    /***** RENDER *****/
    return (
        <Flex justify="between" gap={gap} items="center" className="NXBoxBottomColumns">
            {columns}
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXBoxBottomColumns;
