/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface TrialRouteParams {
    domain_name: string;
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

const useTrialRouteParams = () => useParams<TrialRouteParams>();

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export { useTrialRouteParams };
