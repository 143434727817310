/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaMutation } from 'containers/katana/queries/mutationTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getFilesFromValues, processValueFileObject } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/methods';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 * @param {{
 *  context?: import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Uploads.POST.Parameters['formData']['context']
 * }} options
 */
export function useFormValuesFileUpload(serviceID, options = {}) {
    /***** QUERIES *****/
    const { mutateAsync: mutateUploadFile } = katanaMutation.uploadFile.useMutation(serviceID, null);

    /***** FUNCTIONS *****/
    /**
     *
     *  @param {Record<string, Array<File> | File> | {}} formValues
     *  @returns {Promise<Record<string, string | string[]> | {}>} - The file urls or an error.
     */
    async function processAndUploadFiles(formValues) {
        const files = getFilesFromValues(formValues);
        return await processValueFileObject(files, mutateUploadFile, options);
    }

    return processAndUploadFiles;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
