/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXBox from 'components/NXBox';
import NXTable from 'components/NXTable';
import Tooltip from 'components/Tooltip';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DNSSECForm from '../../forms/dnssec';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { dnsViews } from '../../const';
import './_AddDNSSEC.scss';
import { dnssecContent, views } from './constants';

/*   ACTIONS
 *****************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import RightBorderButton from 'components/Buttons/RightBorderButton';
import Icons from 'components/Icons';
import { addDnssecData, deleteDnssecData, disableDnssec, enableDnssec, getDnssecData, getDnssecEnabledStatus, getDnssecOptions } from '../../action';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../const').TDnsViews} TDnsViews
 */

const FreeDNS_DNSSEC_Views = [dnsViews.FREE_DNS, dnsViews.FORWARDING];
const unsupportedMessage = 'DNSSEC is not supported for this domain extension.';

/**
 * @param {'loading' | 'success' | 'error' | null} status
 * @param {{ details: unknown }} error
 */
const isDNSSECSupported = (status, error) => !(status === 'error' && error?.details === unsupportedMessage);

/**
 * Helper function that returns information about the currently loading requests.
 *
 * @param {Array<string|null>} statuses
 */
const getCombinedStatus = (statuses) => ({
    isLoading: statuses.some((status) => status === 'loading'),
    isSuccess: statuses.some((status) => status === 'success'),
    isError: statuses.some((status) => status === 'error'),
    status:
        (statuses.find((status) => status === 'error') ||
            statuses.find((status) => status === 'loading') ||
            statuses.find((status) => status === 'success')) ??
        null
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _DNSSEC = ({
    domainid,
    disabled,

    /**
     * Redux Props
     */
    getDnssecOptions,
    addDnssecData,
    deleteDnssecData,
    domain_dnssec_status,
    domain_dnssec_enabled_status_status,
    domain_dnssec_enabled_status_data,
    domain_dnssec_list_status,
    domain_dnssec_list_data,
    domain_dnssec_list_error,
    domain_dnssec_options_data,
    domain_dnssec_options_status,
    domain_dns_view,
    domain_dnssec_enable_status,
    domain_dnssec_disable_status,

    /**
     * React Router
     */
    ...props
}) => {
    /** @type {TDnsViews | undefined} */
    const domainConfig = domain_dns_view?.config;

    /***** STATE *****/
    const [showLightbox, setShowLightbox] = useState(false);
    const [showLightboxView, setShowLightboxView] = useState(/** @type {typeof views[keyof typeof views] | null} */ (null));
    const [uuid, setUuid] = useState(/** @type {string | null} */ (null));
    const scrollRef = useRef(null);
    const isDNSSECEnabled = domain_dnssec_enabled_status_data?.dnssec_status !== 'disable';
    const { status } = getCombinedStatus([
        domain_dnssec_list_status,
        domain_dnssec_enable_status,
        domain_dnssec_disable_status,
        domain_dnssec_enabled_status_status,
        domain_dnssec_options_status
    ]);

    /***** FUNCTIONS *****/
    const openLightbox = (view, uuid) => {
        setShowLightbox(true);
        setShowLightboxView(view);
        setUuid(uuid);
    };

    const closeLightbox = () => {
        setShowLightbox(false);
        setShowLightboxView(null);
        setUuid(null);
    };

    const handleAddData = () => {
        if (!disabled) {
            openLightbox(views.ADD);
        }
    };

    const handleToggleDNSSEC = () => {
        if (disabled) {
            return;
        }

        openLightbox(isDNSSECEnabled ? views.DISABLE : views.ENABLE);
    };

    const enableDNSSEC = () => {
        enableDnssec(domainid);
        closeLightbox();
    };

    const disableDNSSEC = () => {
        disableDnssec(domainid);
        closeLightbox();
    };

    const initializeComponent = () => {
        // DNSSEC for FreeDNS
        if (FreeDNS_DNSSEC_Views.includes(domain_dns_view.config)) {
            getDnssecEnabledStatus(domainid);
        }

        getDnssecData(domainid);
        getDnssecOptions();
    };

    /***** EFFECTS *****/
    useEffect(() => {
        initializeComponent();

        registerScrollEvents({ props, scrollRef: scrollRef.current }, domain_dnssec_list_status === 'success');
    }, []);

    // Close lightbox when removing the record is completed (error or success)
    useEffect(() => {
        if (domain_dnssec_status === 'loading') return closeLightbox;
    }, [domain_dnssec_status]);

    /***** RENDER HELPERS *****/
    const tableRows = domain_dnssec_list_data?.attributes.map((value) => (
        <NXTable.Row key={value.UUID}>
            <Text secondary size--s>
                {value.keyTag}
            </Text>
            <Text secondary size--s>
                {value.algorithm}
            </Text>
            <Text secondary size--s>
                {value.digestType}
            </Text>
            <Tooltip
                className="dnssecDigest"
                info={value.digest}
                iconOverride={
                    <Text secondary size--s>
                        Show Digest
                    </Text>
                }
            />
            <NXTable.Actions>
                <OutlineButton
                    size="small"
                    color="warn"
                    type="onClick"
                    onClick={() => {
                        openLightbox('delete', value.UUID);
                    }}
                >
                    Remove
                </OutlineButton>
            </NXTable.Actions>
        </NXTable.Row>
    ));

    const handleLightboxRender = () => {
        const { confirmation } = dnssecContent;

        const lightboxBaseProps = {
            onOpen: true,
            onClose: closeLightbox,
            loading: domain_dnssec_status
        };

        const confirmBaseProps = {
            closeText: 'No, Go Back',
            closeAction: closeLightbox,
            loading: domain_dnssec_status,
            desc: confirmation[showLightboxView]
        };

        switch (showLightboxView) {
            case views.DELETE:
                return (
                    <OverlayLightbox
                        {...lightboxBaseProps}
                        title="Delete DNSSEC data?"
                        confirm={{
                            ...confirmBaseProps,
                            buttonText: 'Delete DNSSEC data',
                            buttonAction: () => void deleteDnssecData(domainid, uuid)
                        }}
                    />
                );

            /** Enable DNSSEC should only be available when using DNS Hosting or CustomNameservers */
            case views.ENABLE:
                return (
                    <OverlayLightbox
                        {...lightboxBaseProps}
                        title="Enable DNSSEC?"
                        confirm={{
                            ...confirmBaseProps,
                            buttonText: 'Enable DNSSEC',
                            buttonAction: enableDNSSEC
                        }}
                    />
                );

            /** Disable DNSSEC should only be available when using DNS Hosting or CustomNameservers */
            case views.DISABLE:
                return (
                    <OverlayLightbox
                        {...lightboxBaseProps}
                        title="Disable DNSSEC?"
                        confirm={{
                            ...confirmBaseProps,
                            buttonText: 'Disable DNSSEC',
                            buttonAction: disableDNSSEC
                        }}
                    />
                );

            case views.ADD:
            default:
                return (
                    <OverlayLightbox {...lightboxBaseProps} title="Add DNS Security Data">
                        <DNSSECForm onSubmit={(values) => void addDnssecData(domainid, values)} dnsSecOptions={domain_dnssec_options_data} />
                    </OverlayLightbox>
                );
        }
    };

    /***** RENDER *****/
    // Show Non-Supported message if DNSSEC is not supported for this TLD
    if (!isDNSSECSupported(domain_dnssec_list_status, domain_dnssec_list_error)) {
        return (
            <div ref={scrollRef} className="dnssec">
                <NXBox>
                    <NXBox.Top title="DNSSEC" description="DNSSEC isn't supported for this TLD.">
                        <InactiveButton className="dnssec__inactive">
                            <i className="icon icon-lock" />
                            Enable DNSSEC
                        </InactiveButton>
                    </NXBox.Top>
                </NXBox>
            </div>
        );
    }

    const isFreeDNSCapable = /** @type {(TDnsViews | undefined)[]} */ ([dnsViews.FREE_DNS, dnsViews.FORWARDING]).includes(domainConfig);

    return (
        <div ref={scrollRef} className="dnssec">
            <NXBox initialStatus={status} onError={<NXBox.RefreshableError title="DNSSEC" onRefresh={initializeComponent} />}>
                <NXBox.ToolTip info={dnssecContent.tooltipInfo} />
                <NXBox.Top title="DNSSEC" description={dnssecContent.description}>
                    {isFreeDNSCapable && (
                        <OutlineButton color={isDNSSECEnabled ? 'warn' : 'primary'} type="onClick" onClick={handleToggleDNSSEC}>
                            {isDNSSECEnabled ? 'Disable DNSSEC' : 'Enable DNSSEC'}
                        </OutlineButton>
                    )}
                </NXBox.Top>
                <NXTable columns="1fr 1fr 1fr 1fr NXActions">
                    <NXTable.Header>
                        <NXTable.Header.Title>Key Tag</NXTable.Header.Title>
                        <NXTable.Header.Title>Algorithm</NXTable.Header.Title>
                        <NXTable.Header.Title>Digest Type</NXTable.Header.Title>
                        <NXTable.Header.Title>Digest</NXTable.Header.Title>
                    </NXTable.Header>
                    <NXTable.Body>{tableRows}</NXTable.Body>
                </NXTable>
                <NXTable.Footer>
                    <RightBorderButton className="dnssec__addData" color="secondary" type="onClick" onClick={handleAddData}>
                        <Text uppercase>Add Data</Text>
                        <Text size--xs>
                            <Icons.PlusFaq />
                        </Text>
                    </RightBorderButton>
                </NXTable.Footer>
            </NXBox>
            {!!showLightbox && handleLightboxRender()}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) =>
    /** @type {const} */ ({
        domain_dnssec_options_data: state.domain.domain_dnssec_options_data,
        domain_dnssec_list_status: state.domain.domain_dnssec_list_status,
        domain_dnssec_list_data: state.domain.domain_dnssec_list_data,
        domain_dnssec_list_error: state.domain.domain_dnssec_list_error,
        domain_dnssec_status: state.domain.domain_dnssec_status,
        domain_dnssec_enable_status: state.domain.domain_dnssec_enable_status,
        domain_dnssec_enabled_status_data: state.domain.domain_dnssec_enabled_status_data,
        domain_dnssec_disable_status: state.domain.domain_dnssec_disable_status,
        domain_dns_view: state.domain.domain_dns_view,
        domain_dnssec_enabled_status_status: state.domain.domain_dnssec_enabled_status_status,
        domain_dnssec_options_status: state.domain.domain_dnssec_options_status
    });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDnssecOptions,
            addDnssecData,
            deleteDnssecData
        },
        dispatch
    );

const DNSSEC = connect(mapStateToProps, mapDispatchToProps)(_DNSSEC);

export default withRouter(DNSSEC);
