/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SecurityInfoForm from '../forms/securityInfo';

/*   ACTIONS
 *****************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getSecurityQuestions, getUserSecurityInformation, updateSecurityInformation } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SecurityInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditLightbox: false,
            securityData: {
                question: '',
                answer: '',
                dob: ''
            },
            securityQuestions: undefined
        };
        this.openEditLightbox = this.openEditLightbox.bind(this);
        this.closeEditLightbox = this.closeEditLightbox.bind(this);
        this.handleEditSecurity = this.handleEditSecurity.bind(this);
    }

    openEditLightbox() {
        this.setState({
            showEditLightbox: true
        });
    }

    closeEditLightbox() {
        this.setState({
            showEditLightbox: false
        });
    }

    handleEditSecurity(values) {
        const { updateSecurityInformation } = this.props;

        updateSecurityInformation({
            ...values,
            dob: DateTime.fromJSDate(values.dob).toFormat('dd/MM/yyyy')
        });
    }

    componentDidUpdate(prevProps) {
        const {
            getUserSecurityInformation,
            account_security_information_status,
            account_security_information_data,
            account_security_questions_status,
            account_security_questions_data,
            account_security_update_status
        } = this.props;
        registerScrollEvents(this, account_security_information_status === 'success' && prevProps.account_security_information_status === 'loading');

        if (account_security_information_status === 'success' && prevProps.account_security_information_status === 'loading') {
            this.setState({
                securityData: account_security_information_data
            });
        }

        if (account_security_update_status === 'success' && prevProps.account_security_update_status === 'loading') {
            this.closeEditLightbox();
            getUserSecurityInformation();
        }

        if (account_security_questions_status === 'success' && prevProps.account_security_questions_status === 'loading') {
            const securityQuestions = account_security_questions_data.map((q) => {
                return {
                    value: q.id,
                    label: q.attributes.question
                };
            });

            this.setState({
                securityQuestions
            });
        }
    }

    componentDidMount() {
        const { getSecurityQuestions } = this.props;
        getSecurityQuestions();
    }

    render() {
        const { account_security_information_status, account_security_update_status, getUserSecurityInformation } = this.props;
        const { showEditLightbox, securityData, securityQuestions } = this.state;
        const { openEditLightbox, closeEditLightbox, handleEditSecurity } = this;

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="accountSecurityInfo"
            >
                <Box
                    request={{
                        action: getUserSecurityInformation,
                        args: false,
                        status: account_security_information_status
                    }}
                    className="accountSecurityInfo__box"
                    title="Security Information"
                    desc={`Your security information is used to authenticate that you are the owner of your account, please ensure that it's accurate and up to date.`}
                    action={{
                        buttonType: 'Outline',
                        label: 'Edit Information',
                        type: 'onClick',
                        size: 'large',
                        onClick: (e) => {
                            e.preventDefault();
                            openEditLightbox();
                        }
                    }}
                    custom={{
                        render: (
                            <form className="SecurityInformation__form">
                                <div className="form__row">
                                    <div className="form__column full">
                                        <div className="form__item">
                                            <label htmlFor="security-question">Security Question</label>
                                            <div className="form__item__inner">
                                                <div className="wrapperbox hasCTA">
                                                    <input id="security-question" readOnly="readonly" type="text" value={securityData.question} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form__row">
                                    <div className="form__column half">
                                        <div className="form__item">
                                            <label htmlFor="security-answer">Security Answer</label>
                                            <div className="form__item__inner">
                                                <div className="wrapperbox readonly">
                                                    <input id="security-answer" readOnly="readOnly" type="text" value={securityData.answer} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__column half">
                                        <div className="form__item">
                                            <label htmlFor="security-dob">Date of Birth</label>
                                            <div className="form__item__inner">
                                                <div className="wrapperbox readonly">
                                                    <input id="security-dob" readOnly="readOnly" type="text" value={securityData.dob} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ),
                        pos: 'bottom'
                    }}
                />
                {showEditLightbox ? (
                    <OverlayLightbox
                        title="Edit Security Information"
                        loading={account_security_update_status}
                        onOpen={showEditLightbox}
                        onClose={closeEditLightbox}
                        onSuccessClose={closeEditLightbox}
                    >
                        <SecurityInfoForm options={securityQuestions} onSubmit={handleEditSecurity} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        account_security_information_status: state.account.account_security_information_status,
        account_security_information_data: state.account.account_security_information_data,
        account_security_questions_status: state.account.account_security_questions_status,
        account_security_questions_data: state.account.account_security_questions_data,
        account_security_update_status: state.account.account_security_update_status
    };
};

const mapDispatchToProps = {
    getUserSecurityInformation,
    updateSecurityInformation,
    getSecurityQuestions
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecurityInformation));
