/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ticket_name } from 'config/config';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RenderDateTimePicker from 'components/Form/RenderDateTimePicker';
import { getCurrentDate } from 'utilities/methods/commonActions';
import {
    RenderField,
    dateValidationNotRequired,
    emailFieldValidation,
    generateIsNotAccountEmail,
    mailboxUserNameNormalisation,
    requiredFieldValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import Text from 'components/Utils/Text';

const formName = 'AdditionalUserForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides the form for adding additional users to the account
 */
let AddAdditionalUserForm = ({ handleSubmit, app_user_data: { email = null } }) => {
    /***** FUNCTIONS *****/
    const notCurrentAccountValidation = useCallback(
        generateIsNotAccountEmail(email, 'You cannot use your account email to add an additional user. Please use a different email address.'),
        [email]
    );

    const todayDate = getCurrentDate().plus({ days: 1 }).toJSDate();

    /***** RENDER *****/
    return (
        <form className="accountUsers__add" onSubmit={handleSubmit}>
            <div className="form__row">
                <Text secondary size--s medium>
                    Enter the email address of the person you want to add to your account.
                </Text>
            </div>
            <div className="form__row">
                <div className="form__column form__column--full">
                    <Field
                        label="Email Address"
                        name="email"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation, emailFieldValidation, notCurrentAccountValidation]}
                        normalize={mailboxUserNameNormalisation}
                        className="form__textfield"
                        placeholder="hello@domain.com"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column form__column--full">
                    <Field
                        label={
                            <>
                                <Text bold secondary>
                                    Account Access Expiry Date
                                </Text>
                                &nbsp;
                                <Text italic>(optional)</Text>
                            </>
                        }
                        name="access_expiry"
                        component={RenderDateTimePicker}
                        minDate={todayDate}
                        dateFormat="d/M/yyyy"
                        validate={[dateValidationNotRequired]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <Text secondary size--s medium>
                    Please set the Account Access Expiry Date if you want the new user to have limited time access to your account. Otherwise, leave
                    it blank to allow the new user to have indefinite access to your account.
                </Text>
            </div>
            <div className="form__row">
                <div className="form__column form__column--full">
                    <div className="form__item">
                        <div className="form__title">
                            <Text secondary size--s bold>
                                Permissions
                            </Text>
                        </div>
                    </div>
                    <div className="accountUsers__add--permissions">
                        <div className="accountUsers__add--permission">
                            <div className="accountUsers__add--icons">
                                <span className="accountUsers__add--icon icon-valid" />
                            </div>
                            <div className="accountUsers__add--text">Manage Services</div>
                        </div>
                        <div className="accountUsers__add--permission">
                            <div className="accountUsers__add--icons">
                                <span className="accountUsers__add--icon icon-valid" />
                            </div>
                            <div className="accountUsers__add--text">Pay Invoices</div>
                        </div>
                        <div className="accountUsers__add--permission">
                            <div className="accountUsers__add--icons">
                                <span className="accountUsers__add--icon icon-valid" />
                            </div>
                            <div className="accountUsers__add--text">Submit {ticket_name}</div>
                        </div>
                        <div className="accountUsers__add--permission">
                            <div className="accountUsers__add--icons">
                                <span className="accountUsers__add--icon icon-valid" />
                            </div>
                            <div className="accountUsers__add--text">Access cPanel</div>
                        </div>
                        <div className="accountUsers__add--permission">
                            <div className="accountUsers__add--forbidden">
                                <span className="accountUsers__add--icon icon-invalid" />
                            </div>
                            <div className="accountUsers__add--text">Add Users</div>
                        </div>
                        <div className="accountUsers__add--permission">
                            <div className="accountUsers__add--forbidden">
                                <span className="accountUsers__add--icon icon-invalid" />
                            </div>
                            <div className="accountUsers__add--text">Close Account</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form__row">
                <Text secondary size--s medium>
                    Please note that if the user is already a VentraIP Australia customer, they&apos;ll be able to use their existing login details to
                    access this account.
                </Text>
            </div>
            <div className="form__row">
                <ReduxFormButton form={formName} className="form__column form__column--full">
                    Add User
                </ReduxFormButton>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
AddAdditionalUserForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(AddAdditionalUserForm);

const mapStateToProps = (state) => ({
    app_user_data: state.app.app_user_data
});

AddAdditionalUserForm = connect(mapStateToProps)(AddAdditionalUserForm);

export default AddAdditionalUserForm;
