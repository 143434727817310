/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AlignForm from '../forms/align';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import DialogNotification from 'components/Notifications/DialogNotification';
import SolidButton from 'components/Buttons/SolidButton';
import Anchor from 'components/Anchor';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType, toLuxonDate, getDaysBetween } from 'utilities/methods/commonActions';
import { alignServices, getServicesAlignment } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AlignServices extends Component {
    constructor(props) {
        super(props);
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.toggleSelection = this.toggleSelection.bind(this);
        this.handleAlignServices = this.handleAlignServices.bind(this);
        this.submitAlignServices = this.submitAlignServices.bind(this);
        this.state = {
            services: [],
            selectedServices: [],
            newInvoice: undefined,
            invoiceDays: undefined,
            showLightbox: false,
            template: '',
            formValues: {}
        };
    }

    openLightbox(template = '') {
        this.setState({
            showLightbox: true,
            template
        });
    }

    closeLightbox() {
        this.setState({
            showLightbox: false,
            selectedServices: [],
            newInvoice: undefined
        });
    }

    toggleSelection(service, { all = false } = {}) {
        const { selectedServices } = this.state;

        if (all) {
            if (selectedServices.length >= 1) {
                this.setState({
                    selectedServices: []
                });
            } else {
                this.setState({
                    selectedServices: service
                });
            }
        } else {
            if (selectedServices.some(({ id }) => id === service.id)) {
                this.setState({
                    selectedServices: selectedServices.filter(({ id }) => id !== service.id)
                });
            } else {
                this.setState({
                    selectedServices: [...selectedServices, service]
                });
            }
        }
    }

    handleAlignServices(values) {
        this.setState({
            showLightbox: true,
            template: 'confirm',
            formValues: values
        });
    }

    submitAlignServices() {
        const { alignServices } = this.props;
        const {
            selectedServices,
            formValues: { renewalDate, billingCycle }
        } = this.state;

        const attributes = {
            services: selectedServices.map(({ id }) => {
                return {
                    id
                };
            }),
            new_next_due_date: renewalDate,
            new_billing_cycle_id: billingCycle
        };

        alignServices(attributes);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps, prevState) {
        const {
            account_services_alignment_data,
            account_services_alignment_status,
            account_services_alignment_preview_status,
            account_services_alignment_preview_data,
            account_services_align_status,
            account_services_align_data
        } = this.props;

        if (account_services_alignment_status === 'success' && prevProps.account_services_alignment_status === 'loading') {
            this.setState({
                services: account_services_alignment_data.map(({ id, attributes }) => {
                    const product = getIncludedObjBasedOnType(attributes.product, 'product');

                    return {
                        id,
                        product,
                        ...attributes
                    };
                })
            });
        }

        if (account_services_alignment_preview_status === 'success' && prevProps.account_services_alignment_preview_status === 'loading') {
            const { new_due_date } = account_services_alignment_preview_data.attributes;
            this.setState({
                invoiceDays: getDaysBetween(toLuxonDate(new_due_date, 'yyyy-MM-dd TT'))
            });
        }

        if (account_services_align_status === 'success' && prevProps.account_services_align_status === 'loading') {
            const { new_invoice } = account_services_align_data;
            this.setState({
                template: 'success',
                newInvoice: new_invoice
            });
        }
    }

    render() {
        const { getServicesAlignment, account_services_alignment_status, account_services_align_status } = this.props;
        const { services, selectedServices, newInvoice, invoiceDays, showLightbox, template } = this.state;
        const { openLightbox, closeLightbox, toggleSelection, handleAlignServices, submitAlignServices } = this;

        const handleSelectAllState = () => {
            if (services.length === 0) {
                return 'disabled';
            }

            if (services.length !== 0 && selectedServices.length === services.length) {
                return 'selected';
            }

            return '';
        };

        function renderServicesLoop() {
            return services.map((service) => {
                const nextDueDate = toLuxonDate(service.next_due_date, 'yyyy-MM-dd TT');

                return (
                    <button
                        type="button"
                        key={service.id}
                        className={`alignServices__row row ${selectedServices.some(({ id }) => id === service.id) ? 'selected' : ''}`}
                        onClick={() => toggleSelection(service)}
                    >
                        <div className="alignServices__row--item checkbox--field">
                            <span className="checkbox" />
                        </div>
                        <div className="alignServices__row--item half">
                            <span className="product">{service.product.attributes.name}</span>
                            {` - `}
                            <span className="highlight">
                                {service.product.attributes.service_type === 'addon' ? service.parent_name : service.name}
                            </span>
                        </div>

                        <div className="alignServices__row--item quarter">{service.billing_cycle_name}</div>
                        <div className="alignServices__row--item quarter">{`${nextDueDate.day}/${nextDueDate.month}/${nextDueDate.year}`}</div>
                    </button>
                );
            });
        }

        const renderServices = () => {
            return (
                <>
                    <div className="alignServices__select">Select services you wish to align</div>
                    <button
                        type="button"
                        className={`alignServices__heading row ${handleSelectAllState()}`}
                        onClick={() => toggleSelection(services, { all: true })}
                    >
                        <div className="alignServices__heading--item checkbox--field">
                            <span className="checkbox" />
                        </div>
                        <div className="alignServices__heading--item half">DESCRIPTION</div>
                        <div className="alignServices__heading--item quarter">BILLING CYCLE</div>
                        <div className="alignServices__heading--item quarter">RENEW</div>
                    </button>
                    {services.length <= 1 ? <div className="alignServices__row empty">You have no services that can be aligned</div> : ''}
                    {services.length >= 2 ? renderServicesLoop() : ''}
                    <div className="alignServices__tooltip">
                        <Tooltip
                            info={
                                <p>
                                    Domain names are not able to have their billing terms adjusted. Other services may not be automatically alignable
                                    due to various factors. Please <Anchor to="/support/tickets/submit/accounts-billing">submit an eTicket</Anchor> to
                                    our Billing Team if you need assistance with changing the billing alignment of a service not displayed here.
                                </p>
                            }
                        />
                        <div>{`Why don't all of my services appear here?`}</div>
                    </div>
                    {selectedServices.length >= 2 && (
                        <AlignForm invoiceDays={invoiceDays} selectedServices={selectedServices} onSubmit={handleAlignServices} />
                    )}
                </>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <>
                <Box
                    request={{
                        action: getServicesAlignment,
                        args: false,
                        status: account_services_alignment_status
                    }}
                    status={account_services_alignment_status}
                    className="alignServices"
                    title="Aligning recurring services"
                    desc="You can use this tool to place recurring services across multiple invoices onto a single invoice so that they all renew at the same time."
                    custom={{
                        render: renderServices(),
                        pos: `bottom`
                    }}
                />

                {showLightbox && template === 'confirm' && (
                    <OverlayLightbox
                        title="Confirm Align Services"
                        onOpen={showLightbox}
                        loading={account_services_align_status}
                        confirm={{
                            desc: `Please confirm below that you would like to confirm this action. Once this action has been performed it cannot be undone and the selected services will now be on one invoice.`,
                            buttonText: 'Confirm',
                            buttonAction: submitAlignServices,
                            closeAction: closeLightbox
                        }}
                        onClose={closeLightbox}
                    />
                )}

                {showLightbox && template === 'success' && (
                    <OverlayLightbox onOpen={showLightbox} onClose={closeLightbox}>
                        <div className="alignServices__success">
                            <i className="icon icon-valid" />
                            <div className="alignServices__success--title">Services successfully aligned</div>
                            <div className="alignServices__success--description">
                                This changed has now taken effect.
                                <br /> Please take note of the invoice changes below
                            </div>
                            <div className="alignServices__success--highlight">NEW Invoice created</div>
                            <Anchor className="alignServices__success--invoice" onClick={() => openLightbox('invoice')}>
                                #{newInvoice}
                            </Anchor>
                            <div className="alignServices__success--prorata">Pro rata invoice to be paid before services align</div>
                            <DialogNotification tail={true} type="warning">
                                {`PLEASE NOTE: The pro rata invoice listed above must be
                                paid today in
                                order for the services you've selected to align.`}
                            </DialogNotification>
                            <SolidButton className="backToServiceAlignment__button" type="onClick" onClick={() => closeLightbox()}>
                                Back to Service Alignment Tool
                            </SolidButton>
                        </div>
                    </OverlayLightbox>
                )}

                {showLightbox && template === 'invoice' && (
                    <OverlayLightbox
                        title={'Pay Invoice #' + newInvoice}
                        invoiceid={newInvoice}
                        onOpen={showLightbox}
                        onClose={closeLightbox}
                        onSuccessClose={closeLightbox}
                    />
                )}
            </>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        account_services_alignment_status: state.billing.account_services_alignment_status,
        account_services_alignment_data: state.billing.account_services_alignment_data,
        account_services_alignment_preview_data: state.billing.account_services_alignment_preview_data,
        account_services_alignment_preview_status: state.billing.account_services_alignment_preview_status,
        account_services_align_status: state.billing.account_services_align_status,
        account_services_align_data: state.billing.account_services_align_data,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getServicesAlignment,
            alignServices
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlignServices));
