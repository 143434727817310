/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZoneRejectedFileListItem as NXDropZoneRejectedFileListItem } from 'components/Form/NXDropZone/_NXDropZone/_NXDropZoneRejectedFileList';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      rejectedFiles: import('react-dropzone').FileRejection[];
 *      maxFileSize: number;
 * }>}
 */
const _NXDropZoneError = (props) => {
    const { rejectedFiles, maxFileSize } = props;

    /***** RENDER *****/
    if (!rejectedFiles?.length) {
        return;
    }

    return (
        <div className="NXDropZone__errorList">
            {rejectedFiles.map((item, index) => (
                <NXDropZoneRejectedFileListItem rejectedItem={item} key={index} maxFileSize={maxFileSize} />
            ))}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXDropZoneError };
