/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/*   ACTIONS
 *****************************************************/
import { resetScrollEvents } from 'utilities/methods/commonActions';
import { vpsMethods, vpsServerTypes } from 'containers/vps/methods';
import { getSolusVPSInfo, getAstroVPSInfo } from '../action';
import { renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Addons extends Component {
    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { match, getSolusVPSInfo, getAstroVPSInfo, vps_information_data } = this.props;
        const { params } = match;
        const { id } = params;

        if (vpsMethods.getVpsServerType(vps_information_data) === vpsServerTypes.ASTRO) return getAstroVPSInfo(id);
        getSolusVPSInfo(id);
    }

    componentWillUnmount() {
        const { resetScrollEvents } = this.props;

        resetScrollEvents();
    }

    render() {
        const { modules } = this.props;

        return <div className="VPS-Addon">{renderSectionModules(modules)}</div>;
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    vps_information_data: state.vps.vps_information_data
});

const mapDispatchToProps = {
    resetScrollEvents,
    getSolusVPSInfo,
    getAstroVPSInfo
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Addons));
