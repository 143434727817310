/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TPPDomainRenewExpiry from './expiry';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import Icons from 'components/Icons';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import DialogNotification from 'components/Notifications/DialogNotification';
import PayInvoiceNotification from 'components/Notifications/PayInvoice';
import PricingTable from 'components/PricingTable';
import Tooltip from 'components/Tooltip';
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainRenewMutation } from 'containers/domain/tppDomains/queries/mutations';
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { useTppDomainQuery } from '../../queries/useTppDomainQuery';
import { useTppDomainRenewCheckQuery } from '../../queries/useTppDomainRenewCheckQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { INVOICE_ACTIONS, getInvoiceActionUrl } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox/methods';
import { getIsLegacyDomainTransferLocked } from 'containers/domain/tppDomains/modules/transferLock/methods';
import useSearchParams from 'utilities/hooks/useSearchParams';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { UNABLE_TO_RENEW_MESSAGES } from 'containers/domain/tppDomains/modules/renew/consts';
import { getExpiryInfo } from 'containers/services/modules/domains/methods';
import './_tppDomainRenew.scss';

const tppRenewDropdownSearchParam = 'renew-legacy-domain';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * TPP domain renew module
 */
function TPPDomainRenew({ path }) {
    /***** HOOKS *****/
    const location = useLocation();
    const scrollRef = useRef(null);
    const params = useParams();
    const history = useHistory();
    const { searchParamsGet, searchParamsAdd, searchParamsRemove } = useSearchParams();

    /***** QUERIES *****/
    const { data: tpp_domain_data, status: tpp_domain_status } = useTppDomainQuery(params.id);
    const {
        data: tpp_domain_renew_check_data,
        error: tpp_domain_renew_check_error,
        status: tpp_domain_renew_check_status,
        isFetching: isTppDomainRenewCheckFetching
    } = useTppDomainRenewCheckQuery(params.id);
    const { data: tpp_domain_transfer_lock_data } = useTppDomainTransferLockQuery(params.id);
    const { mutate: mutateTppDomainRenew, isPending: isTppDomainRenewLoading } = useTppDomainRenewMutation();

    /***** RENDER HELPERS *****/
    // This is the specific error we expect if the domain is not up for renewal yet
    const isTppErrorCode611 =
        tpp_domain_renew_check_status === 'error' &&
        tpp_domain_renew_check_error?.status === 400 &&
        tpp_domain_renew_check_error?.errors?.[0]?.details ===
            'Domain renewal details cannot be retrieved/submitted at this time. Please contact support team.';

    function getInitialStatus() {
        if ([tpp_domain_status, tpp_domain_renew_check_status].includes('pending')) return 'loading';
        if (isTppErrorCode611) {
            return 'success';
        }
        if ([tpp_domain_status, tpp_domain_renew_check_status].includes('error')) return 'error';
        return 'success';
    }

    const initialStatus = getInitialStatus();
    const isDropdownOpen = !!searchParamsGet(tppRenewDropdownSearchParam);
    const isTransferLocked = getIsLegacyDomainTransferLocked(tpp_domain_transfer_lock_data);

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props: { path, location }, scrollRef: scrollRef.current }, initialStatus === 'success');
    }, [initialStatus]);

    /***** RENDER *****/
    return (
        <section ref={scrollRef} className="tppDomainRenew">
            <NXBox initialStatus={initialStatus} isLoading={isTppDomainRenewCheckFetching}>
                {(() => {
                    if (!tpp_domain_data || (!tpp_domain_renew_check_data && !isTppErrorCode611)) return '';

                    const { expiry = '', domain = '' } = tpp_domain_data.attributes || {};
                    const { price = '', renew_invoice_id, renew_invoice_status = '' } = tpp_domain_renew_check_data?.attributes || {};

                    const expiryInfo = getExpiryInfo(expiry);
                    const isDotAuAndNotWithin90 = !expiryInfo.isWithin90 && domain.endsWith('.au');
                    const isUnpaidRenewalInvoice = renew_invoice_id && renew_invoice_status.toLowerCase() === 'unpaid';
                    // If the invoice has been paid but the invoice action cron hasn't run yet
                    const isPendingRenewal = renew_invoice_id && renew_invoice_status.toLowerCase() === 'paid';

                    function renderNotification() {
                        if (isUnpaidRenewalInvoice) {
                            return (
                                <NXBox.DefaultPadding>
                                    <PayInvoiceNotification
                                        type="warning"
                                        text={UNABLE_TO_RENEW_MESSAGES.PLEASE_PAY_INVOICE}
                                        openPayInvoice={() => history.push(getInvoiceActionUrl(INVOICE_ACTIONS.PAY, renew_invoice_id))}
                                        openCancelInvoice={() => history.push(getInvoiceActionUrl(INVOICE_ACTIONS.CANCEL, renew_invoice_id))}
                                    />
                                </NXBox.DefaultPadding>
                            );
                        }

                        if (isPendingRenewal) {
                            return (
                                <NXBox.DefaultPadding>
                                    <DialogNotification type="warning">Your renewal is currently pending processing.</DialogNotification>
                                </NXBox.DefaultPadding>
                            );
                        }

                        return '';
                    }

                    function getButton() {
                        if (isTransferLocked || isDotAuAndNotWithin90 || isUnpaidRenewalInvoice || isPendingRenewal) {
                            const getCantRenewMessage = () => {
                                if (isTransferLocked) return 'Domain cannot currently be renewed as it is transfer locked.';
                                if (isDotAuAndNotWithin90) return UNABLE_TO_RENEW_MESSAGES.AU_NOT_WITHIN_90;
                                if (isPendingRenewal) return UNABLE_TO_RENEW_MESSAGES.PENDING_RENEWAL;
                                if (isUnpaidRenewalInvoice) return UNABLE_TO_RENEW_MESSAGES.OUTSTANDING_RENEWAL_INVOICE;
                                return '';
                            };

                            return (
                                <HoverTooltip key={2} content={getCantRenewMessage()}>
                                    <InactiveButton>
                                        <Icons.Lock />
                                        Renew
                                    </InactiveButton>
                                </HoverTooltip>
                            );
                        }

                        return (
                            <OutlineButton
                                key={2}
                                type="onClick"
                                onClick={(e) => {
                                    isDropdownOpen
                                        ? searchParamsRemove(tppRenewDropdownSearchParam)
                                        : searchParamsAdd(tppRenewDropdownSearchParam, 'true');
                                }}
                            >
                                Renew
                            </OutlineButton>
                        );
                    }

                    return (
                        <>
                            <NXBox.Top
                                title="Renew Domain Name"
                                description="Ensure your domain name remains registered by renewing it before it expires."
                            />
                            {renderNotification()}
                            <NXBox.Bottom>
                                <NXBox.BottomColumns
                                    columns={[
                                        <NXBox.InfoPair key={1} title="Expires in" description={<TPPDomainRenewExpiry expiryDate={expiry} />} />,
                                        getButton()
                                    ]}
                                />
                            </NXBox.Bottom>
                            <NXBox.Dropdown isOpen={isDropdownOpen}>
                                <NXBox.DefaultPadding>
                                    <NXBox.Dropdown.CloseButton onClose={() => searchParamsRemove(tppRenewDropdownSearchParam)} />
                                    <div>
                                        <Text secondary size--xss lead--l className="tppDomainRenew__renewLengthLabel">
                                            <div>
                                                Renew Domain Name For{' '}
                                                <Tooltip info="Legacy domains are currently only able to be renewed for 1 year." />
                                            </div>
                                        </Text>
                                        <Text>
                                            <p>1 Year - ${price} AUD</p>
                                        </Text>
                                        <PricingTable
                                            slim
                                            total={{
                                                amount: `$${price}`,
                                                label: 'Total Amount Due'
                                            }}
                                        />
                                        {isTppDomainRenewLoading ? (
                                            <InactiveButton>
                                                <RequestLoader />
                                            </InactiveButton>
                                        ) : (
                                            <SolidButton
                                                type="onClick"
                                                onClick={() =>
                                                    mutateTppDomainRenew(params.id, {
                                                        onSuccess: (data) => {
                                                            history.push(getInvoiceActionUrl(INVOICE_ACTIONS.PAY, data.data.id));
                                                        }
                                                    })
                                                }
                                            >
                                                Generate Renewal Invoice
                                            </SolidButton>
                                        )}
                                    </div>
                                </NXBox.DefaultPadding>
                            </NXBox.Dropdown>
                        </>
                    );
                })()}
            </NXBox>
        </section>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainRenew;
