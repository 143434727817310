/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/domain';

export function useDeleteDomainMutation() {
    return useMutation({
        mutationFn: (domainId: string) => API.domain.DELETE.domain(domainId)
    });
}
