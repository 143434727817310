/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, change, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NameserversAddOrRemoveButton from 'containers/domain/forms/customns/addOrRemoveButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, renderButton, requiredFieldValidation, trailingCharactersValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class CustomNSForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: ['ns1', 'ns2', 'ns3'],
            nameservers: []
        };
        this.addNameserver = this.addNameserver.bind(this);
        this.removeNameserver = this.removeNameserver.bind(this);
        this.updateForm = this.updateForm.bind(this);
    }

    addNameserver() {
        if (this.state.inputs.length <= 9) {
            const newInput = `ns${this.state.inputs.length + 1}`;
            this.setState({
                inputs: this.state.inputs.concat([newInput])
            });
        }
    }

    removeNameserver() {
        const { form } = this.props;
        if (this.state.inputs.length >= 2) {
            this.setState(
                {
                    inputs: this.state.inputs.slice(0, -1)
                },
                () => {
                    this.props.dispatch(change(form, `ns${this.state.inputs.length + 1}`, null));
                }
            );
        }
    }

    updateForm() {
        const { domain_current_configuration_data } = this.props;
        const { inputs } = this.state;

        Object.entries(domain_current_configuration_data).forEach((data, index) => {
            if (inputs.length < index + 1) {
                this.setState({
                    inputs: inputs.concat([`ns${inputs.length + 1}`])
                });
            }
        });
    }

    componentDidMount() {
        const { form, domain_current_configuration_data, domain_nameserver_store, formValues } = this.props;
        const { inputs } = this.state;

        // do not update form if formValues is already set (this is reset when clicking the button to open this page)
        if (formValues) {
            return;
        }

        if (domain_nameserver_store) {
            this.updateForm();
        }

        if (domain_current_configuration_data) {
            const { attributes } = domain_current_configuration_data;
            const { nameservers } = attributes;
            const newNameservers = nameservers || [];
            this.setState(
                {
                    nameservers: newNameservers
                },
                () => {
                    const newInputArray = inputs;
                    newNameservers.forEach((ns, index) => {
                        this.props.dispatch(change(form, `ns${index + 1}`, ns));

                        if (newInputArray.length < index + 1) {
                            newInputArray.push(`ns${newInputArray.length + 1}`);
                        }
                    });
                    this.setState({
                        inputs: newInputArray
                    });
                }
            );
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { pristine, submitting, valid, handleSubmit } = this.props;
        const { inputs } = this.state;
        const { addNameserver, removeNameserver } = this;

        return (
            <form className="customNSForm" name="customNSForm" onSubmit={handleSubmit}>
                <div className="customNS__row">
                    <div className="customNS__column full">
                        <Field
                            name="ns1"
                            component={RenderField}
                            type="text"
                            placeholder="nsX.hosting-services.net.au"
                            validate={[requiredFieldValidation, trailingCharactersValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                <div className="customNS__row">
                    <div className="customNS__column full">
                        <Field
                            name="ns2"
                            component={RenderField}
                            type="text"
                            placeholder="nsX.hosting-services.net.au"
                            validate={[requiredFieldValidation, trailingCharactersValidation]}
                            className="form__textfield"
                        />
                    </div>
                </div>
                {inputs.map((input, index) => {
                    if (input === 'ns1' || input === 'ns2') return '';

                    return (
                        <div key={index} className={`customNS__row ${'ns' + inputs.length === input ? 'ns__row' : ''}`}>
                            <div className={`customNS__column full ${index + 1 === inputs.length ? 'customNS__column--removeable' : ''}`}>
                                <Field
                                    name={input}
                                    component={RenderField}
                                    type="text"
                                    placeholder="nsX.hosting-services.net.au"
                                    validate={trailingCharactersValidation}
                                    className="form__textfield"
                                />
                                {index + 1 === inputs.length ? <NameserversAddOrRemoveButton action="remove" onClick={removeNameserver} /> : ''}
                            </div>
                        </div>
                    );
                })}
                <div className={`customNS__row ${inputs.length >= 9 ? ' hidden' : ''}`}>
                    <div className="customNS__column full">
                        <NameserversAddOrRemoveButton action="add" onClick={addNameserver} />
                    </div>
                </div>
                <div className="dynamic__form--row">{renderButton(pristine, submitting, valid, 'Set Custom Nameservers', 'primary')}</div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
CustomNSForm = reduxForm({
    form: 'customNSForm',
    enableReinitialize: true,
    destroyOnUnmount: false
})(CustomNSForm);

const mapStateToProps = (state) => {
    const formValues = getFormValues('customNSForm')(state);

    return {
        domain_current_configuration_data: state.domain.domain_current_configuration_data,
        domain_nameserver_store: state.domain.domain_nameserver_store,
        form: 'customNSForm',
        formValues
    };
};

CustomNSForm = connect(mapStateToProps)(CustomNSForm);

export default withRouter(CustomNSForm);
