import { _TextArea } from 'components/Form/TextArea/_TextArea';
import { _TextAreaReduxFormDefault as TextAreaReduxFormDefault } from 'components/Form/TextArea/wrappers/reduxform/Default';

const TextArea = Object.assign(_TextArea, {
    ReduxForm: {
        Default: TextAreaReduxFormDefault
    }
});

export { TextArea };
