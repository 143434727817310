/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { ValidationMessage } from 'components/Form/ValidationMessage';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Select as Select } from 'components/Form/Select/_Select';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NFormItem } from 'components/Form/FormItem/types';
import type { NSelect } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SelectReduxFormDefaultProps = {
    label: React.ReactNode;
    options: Array<NSelect.Option>;
    size?: NFormItem.Size;
    className?: string;
    onSelect?: (event: string) => void;
} & WrappedFieldProps;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SelectReduxFormDefault: React.FC<SelectReduxFormDefaultProps> = ({ label, options, size, input, meta, className, onSelect }) => {
    const { name, value, onChange } = input;

    /***** FUNCTIONS *****/
    function handleOnChange(event: string) {
        onChange(event);
        onSelect?.(event);
    }

    /***** RENDER *****/
    return (
        <FormItem size={size} className={className}>
            <Select label={label} name={name} value={value} onChange={handleOnChange} options={options} meta={meta} />
            <ValidationMessage.ReduxForm.Default {...meta} />
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
