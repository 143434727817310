/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { zodResolver } from '@hookform/resolvers/zod';
import { billingCycles } from 'config/config';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as z from 'zod';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import { HookFormButton } from 'components/Form/Button/hookForm';
import FormColumn from 'components/Form/FormColumn';
import FormRow from 'components/Form/FormRow';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import RequestLoader from 'components/Loaders/Request';
import PricingTable from 'components/PricingTable';

/*   ACTIONS
 *****************************************************/
import { calculateAddHostingCost } from 'containers/domain/action';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { zodRefinerDomainNoSubdomain } from 'utilities/methods/form/validations/zod/refiners';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { requiredFieldErrorMessage } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const schema = z.object({
    // @ts-ignore
    domain: z.string().min(1, requiredFieldErrorMessage).refine(zodRefinerDomainNoSubdomain.function, zodRefinerDomainNoSubdomain.options),
    billing_cycle: z.string().min(1, requiredFieldErrorMessage)
});

function PurchaseForm({ handlePurchase }) {
    /***** HOOKS *****/
    const dispatch = useDispatch();

    const hosting_resource_data = useSelector((state) => state.hosting.hosting_resource_data);
    const hosting_resource_config_data = useSelector((state) => state.hosting.hosting_resource_config_data);
    const hosting_information_data = useSelector((state) => state.hosting.hosting_information_data);
    const domain_add_hosting_calculate_status = useSelector((state) => state.domain.domain_add_hosting_calculate_status);
    const domain_add_hosting_calculate_data = useSelector((state) => state.domain.domain_add_hosting_calculate_data);

    const methods = useForm({
        defaultValues: {
            domain: '',
            billing_cycle: 'Monthly'
        },
        resolver: zodResolver(schema),
        mode: 'all'
    });

    const { watch } = methods;
    const billing_cycle = watch('billing_cycle');

    /***** FUNCTIONS *****/
    function getPricingPreview() {
        const productId = getIncludedObjBasedOnType(hosting_information_data?.included, 'product')?.id;

        function getConfigOptions() {
            if (!hosting_resource_config_data?.attributes || !hosting_resource_data?.attributes?.config_values) {
                return [];
            }

            const options = Object.values(hosting_resource_data.attributes.config_values).map(({ option_id }) => option_id);

            return Object.values(hosting_resource_config_data.attributes).map((cpuMemOrDiskSpaceOptionsList) => {
                const optionThatMatchesCurrentServiceConfig = cpuMemOrDiskSpaceOptionsList.find((option) => options.includes(option.option_id));

                return {
                    config_id: optionThatMatchesCurrentServiceConfig.config_id,
                    option_id: optionThatMatchesCurrentServiceConfig.option_id
                };
            });
        }

        const attributes = {
            product_id: productId,
            config: getConfigOptions(),
            billing_cycle: billing_cycle || 'Monthly'
        };

        dispatch(calculateAddHostingCost(productId, attributes));
    }

    /***** EFFECTS *****/
    useEffect(getPricingPreview, [billing_cycle]);

    /***** RENDER HELPERS *****/
    const billingCycleOptions = Object.keys(billingCycles)
        .filter((cycleName) => cycleName !== '1 Year')
        .map((cycleName) => ({
            label: cycleName,
            value: cycleName
        }));

    const renderPricingTable = () => {
        if (domain_add_hosting_calculate_status === 'loading') {
            return <RequestLoader />;
        }

        if (domain_add_hosting_calculate_status === 'error') {
            return <FetchComponentError />;
        }

        const isDiscount =
            typeof domain_add_hosting_calculate_data?.discount_amount === 'string' && domain_add_hosting_calculate_data.discount_amount !== '0.00';

        const discountRows = [
            {
                label: 'Subtotal',
                amount: `$${domain_add_hosting_calculate_data?.sub_total || ''}`
            },

            {
                label: 'Discount Amount',
                amount: `$${domain_add_hosting_calculate_data?.discount_amount || ''}`
            }
        ];

        return (
            <PricingTable
                rows={isDiscount ? discountRows : null}
                total={{
                    label: 'Total Due Today',
                    amount: `$${domain_add_hosting_calculate_data?.total || ''}`
                }}
            />
        );
    };

    /***** RENDER *****/
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handlePurchase)}>
                <FormRow>
                    <FormColumn half>
                        <Input.HookForm.Default label="Primary Domain Name" name="domain" />
                    </FormColumn>
                    <FormColumn half>
                        <Select.HookForm label="Select Billing Cycle" name="billing_cycle" options={billingCycleOptions} />
                    </FormColumn>
                </FormRow>
                {renderPricingTable()}
                <HookFormButton force={domain_add_hosting_calculate_status === 'loading' ? 'inactive' : undefined}>
                    Confirm and Pay Invoice
                </HookFormButton>
            </form>
        </FormProvider>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PurchaseForm;
