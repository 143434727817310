/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import DialogNotification from 'components/Notifications/DialogNotification';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { createAstroVNCSession } from 'containers/vps/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let VNCLogin = (props) => {
    const {
        /** Redux State */
        vps_information_data,
        vps_power_state_data,
        vps_power_state_status,
        vps_create_astro_vnc_status
    } = props;
    const { dispatch } = store;

    /***** HOOKS *****/
    const scrollRef = useRef();

    /***** FUNCTIONS *****/
    const vncLoginOnClick = (e) => {
        e.preventDefault();
        if (vps_power_state_data?.state === 'shutdown') pushNotification('Your VPS must be running to enable VNC access.');
        else dispatch(createAstroVNCSession(vps_information_data.id));
    };

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, vps_power_state_status === 'success');
    }, [vps_power_state_status]);

    /***** RENDER HELPERS *****/
    function renderVNCLoginButton() {
        if (vps_create_astro_vnc_status === 'loading')
            return (
                <InactiveButton>
                    <RequestLoader />
                </InactiveButton>
            );
        return (
            <SolidButton type="onClick" onClick={vncLoginOnClick}>
                Access VNC
            </SolidButton>
        );
    }

    /***** RENDER *****/
    return (
        <div ref={scrollRef}>
            <NXBox className="VPS-VNCLogin" initialStatus={vps_power_state_status}>
                <NXBox.Top title="VNC Login" description="VNC access allows you to determine and troubleshoot problems with your VPS."></NXBox.Top>
                <NXBox.DefaultPadding>
                    <div>
                        <DialogNotification type="warning">
                            PLEASE NOTE: This VPS does not support accessing VNC via anything but the VNC Login button below.
                        </DialogNotification>
                        {renderVNCLoginButton()}
                    </div>
                </NXBox.DefaultPadding>
            </NXBox>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    vps_information_data: state.vps.vps_information_data,
    vps_create_astro_vnc_status: state.vps.vps_create_astro_vnc_status,
    vps_power_state_status: state.vps.vps_power_state_status,
    vps_power_state_data: state.vps.vps_power_state_data
});

const mapDispatchToProps = {};

VNCLogin = connect(mapStateToProps, mapDispatchToProps)(VNCLogin);

VNCLogin = withRouter(VNCLogin);

export default VNCLogin;
