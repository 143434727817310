/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { bpay_details } from 'config/config';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RadioButtonCircle from 'components/Form/RadioButtonCircle';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { useGetDefaultPaymentMethodQuery } from 'containers/billing/queries/useGetDefaultPaymentMethodQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import BPayImage from 'assets/images/bpay.png';
import { PAYMENT_METHODS, selectedPaymentMethodPropTypes } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function BPayPaymentMethod({ selectedPaymentMethod, setSelectedPaymentMethod, invoiceID }) {
    /***** QUERIES *****/
    const { data: get_default_payment_method_data, isLoading: isGetDefaultPaymentMethodLoading } = useGetDefaultPaymentMethodQuery();
    const { data: get_invoice_data, isLoading: isGetInvoiceLoading } = useGetInvoiceQuery(invoiceID);

    /***** RENDER HELPERS *****/
    const isBPaySelected = selectedPaymentMethod === PAYMENT_METHODS.BPAY;

    /***** RENDER *****/
    if (isGetDefaultPaymentMethodLoading) return <RequestLoader />;
    return (
        <div key="bpay" className={classNames('method', { 'method--selected': isBPaySelected })}>
            <RadioButtonCircle.Button
                className="method__top"
                isActive={isBPaySelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.BPAY)}
            >
                <div className="method__top--title">
                    <div className="label">BPAY</div>
                </div>
                <div className="method__top--img">
                    <img className="cc_icon bpay" src={BPayImage} alt="bpay" />
                </div>
            </RadioButtonCircle.Button>
            {isBPaySelected ? (
                <div className="method__bottom">
                    <div className="bpay__wrapper">
                        <div className="bpay__row">
                            <div className="label">Biller Code</div>
                            <div className="value">{bpay_details.biller_code}</div>
                        </div>
                        <div className="bpay__row">
                            <div className="label">Reference #</div>
                            <div className="value">{get_default_payment_method_data?.attributes?.account_number ?? ''}</div>
                        </div>
                        <div className="bpay__row">
                            <div className="label">Total</div>
                            <div className="value">
                                {isGetInvoiceLoading ? <RequestLoader /> : `$${get_invoice_data?.attributes?.amount_due ?? ''}`}
                            </div>
                        </div>
                        <div className="bpay__note">
                            Please note BPAY payments do take a number of days to clear. Consider BPAY payments as a standard bank transfer with 1-2
                            business days required for processing; this can cause delays over weekends and during public holiday periods.
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

BPayPaymentMethod.propTypes = {
    ...selectedPaymentMethodPropTypes
};

export default BPayPaymentMethod;
