/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';

import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { getActiveSync, toggleActiveSync } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ActiveSync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSync: false
        };
        this.handleActiveSyncToggle = this.handleActiveSyncToggle.bind(this);
    }

    handleActiveSyncToggle() {
        const { mailbox, toggleActiveSync } = this.props;
        const { activeSync } = this.state;
        const attributes = {
            enable: !activeSync
        };
        toggleActiveSync(mailbox, attributes);
    }

    componentDidUpdate(prevProps) {
        const { mailbox, getActiveSync, email_active_sync_status, email_active_sync_data, email_active_sync_toggle_status } = this.props;
        registerScrollEvents(this, email_active_sync_status === 'success' && prevProps.email_active_sync_status === 'loading');

        if (email_active_sync_status === 'success' && prevProps.email_active_sync_status === 'loading') {
            const { attributes } = email_active_sync_data;
            const { enabled } = attributes;

            this.setState({
                activeSync: enabled
            });
        }

        if (email_active_sync_toggle_status === 'success' && prevProps.email_active_sync_toggle_status === 'loading') {
            getActiveSync(mailbox);
        }
    }

    render() {
        const { mailbox, getActiveSync, email_active_sync_status, email_active_sync_toggle_status } = this.props;
        const { activeSync } = this.state;
        const { handleActiveSyncToggle } = this;

        const handleActiveSyncLoader = () => {
            if (email_active_sync_status === 'loading' || email_active_sync_toggle_status === 'loading') {
                return 'loading';
            }

            return 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="activeSync"
            >
                <Box
                    request={{
                        action: getActiveSync,
                        args: mailbox,
                        status: email_active_sync_status
                    }}
                    className="activeSync__box"
                    title="ActiveSync"
                    desc="ActiveSync enables push notifications to your mobile device or other ActiveSync compatible device."
                    status={handleActiveSyncLoader()}
                    bottom={activeSync}
                    columns={[
                        {
                            render: (
                                <div className="sharedBox__infoColumn hasIcon">
                                    <i className="infoColumn__icon icon icon-tick-solid confirm" />
                                    <div className="infoColumn__wrapper">
                                        <div className="title">ActiveSync is enabled</div>
                                        <div className="desc">You may now use ActiveSync features with your email account</div>
                                    </div>
                                </div>
                            )
                        }
                    ]}
                    action={{
                        label: `${activeSync ? 'Disable' : 'Enable ActiveSync'}`,
                        type: 'onClick',
                        className: '',
                        size: 'large',
                        color: `${activeSync && 'warn'}`,
                        onClick: (e) => {
                            e.preventDefault();
                            handleActiveSyncToggle();
                        }
                    }}
                />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        email_active_sync_status: state.email.email_active_sync_status,
        email_active_sync_data: state.email.email_active_sync_data,
        email_active_sync_error: state.email.email_active_sync_error,
        email_active_sync_toggle_status: state.email.email_active_sync_toggle_status,
        email_active_sync_toggle_data: state.email.email_active_sync_toggle_error,
        email_active_sync_toggle_error: state.email.email_active_sync_toggle_error,
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getActiveSync,
            toggleActiveSync
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveSync));
