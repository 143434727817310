/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class CustomerCareHeader extends Component {
    render() {
        const {
            match: {
                params: { param5 }
            }
        } = this.props;

        const renderDescription = () => {
            switch (param5) {
                case 'compliment':
                    return 'Have something nice to say about us?';
                case 'complaint':
                    return 'Did you have a negative experience?';
                case 'other':
                    return 'Do you have something else you want to say?';
                case 'feedback':
                default:
                    return 'Was there something you think we can improve on?';
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="customerCareHeader">
                <ul className="customerCareHeader__formNav">
                    <li className={param5 === 'feedback' ? `selected` : ''}>
                        <Link to="/support/tickets/submit/customer-care/feedback?invoice=na&service=na">Feedback</Link>
                    </li>
                    <li className={param5 === 'compliment' ? `selected` : ''}>
                        <Link to="/support/tickets/submit/customer-care/compliment?invoice=na&service=na">Compliment</Link>
                    </li>
                    <li className={param5 === 'complaint' ? `selected` : ''}>
                        <Link to="/support/tickets/submit/customer-care/complaint?invoice=na&service=na">Complaint</Link>
                    </li>
                    <li className={param5 === 'other' ? `selected` : ''}>
                        <Link to="/support/tickets/submit/customer-care/other?invoice=na&service=na">Other</Link>
                    </li>
                </ul>
                <div className="customerCareHeader__heading">
                    <div className="customerCareHeader__title">{param5}</div>
                    <div className="customerCareHeader__description">{renderDescription()}</div>
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(CustomerCareHeader);
