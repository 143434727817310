/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import AxigenRestore from '../forms/axigenRestore';
import SetupForm from '../forms/setup';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import Cancel from 'components/Cancel';
import Label from 'components/Label';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Search from 'components/Search';
import StatusTag from 'components/StatusTag';
import SupportLink from 'components/SupportLink';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { cancelSearchMailbox } from 'utilities/api/services';
import { formatLuxonOrdinal, getCurrentDate, textLowerCase, toLuxonDate } from 'utilities/methods/commonActions';
import {
    getEmailHostingRecoverFee,
    getMailboxList,
    keepService,
    mailboxLogin,
    mailboxSearch,
    resetEmailState,
    resetMailboxSearch,
    setupMailbox
} from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { COPY_terminatedButtonText } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Mailboxes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLightbox: false,
            showKeepLightbox: false,
            showAxigenRestoreLightBox: false,
            keepServiceID: undefined,
            domain: null,
            showInvoiceLightbox: false,
            invoiceID: null,
            current_page: null,
            last_page: null,
            total_all_records: null,
            total_records: null,
            current_list_view: null,
            planNames: {
                'syd-email-starter': 'Email Starter',
                'syd-email': 'Email',
                'syd-email-plus': 'Email Plus',
                'syd-email-plus-o': 'Email Plus'
            },
            currentKeyword: '',
            selectedServiceID: null
        };

        this.openAxigenRestoreLightbox = this.openAxigenRestoreLightbox.bind(this);
        this.closeAxigenRestoreLightbox = this.closeAxigenRestoreLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openKeepLightbox = this.openKeepLightbox.bind(this);
        this.closeKeepLightbox = this.closeKeepLightbox.bind(this);
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.monitorKeyword = this.monitorKeyword.bind(this);
        this.handleSetupSubmit = this.handleSetupSubmit.bind(this);
        this.handleKeepSubmit = this.handleKeepSubmit.bind(this);
        this.showMore = this.showMore.bind(this);
    }

    /************** OPEN/CLOSE KEEP LIGHTBOX **************/
    openKeepLightbox(id) {
        this.setState({
            showKeepLightbox: true,
            keepServiceID: id
        });
    }

    closeKeepLightbox() {
        this.setState({
            showKeepLightbox: false,
            keepServiceID: null
        });
    }

    /************** OPEN/CLOSE LIGHTBOX **************/

    closeLightbox() {
        this.setState({
            showLightbox: false
        });
    }

    openLightbox() {
        this.setState({
            showLightbox: true
        });
    }

    /************** OPEN/CLOSE PAY INVOICE **************/
    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceID: id
        });
    }

    closePayInvoice() {
        const { getMailboxList, emailid } = this.props;

        this.setState(
            {
                showInvoiceLightbox: false
            },
            () => {
                getMailboxList(emailid);
            }
        );
    }

    closePayInvoiceSuccess() {
        const { emailid, getMailboxList } = this.props;
        this.setState(
            {
                showAddLightbox: false,
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getMailboxList(emailid);
            }
        );
    }

    /************** HANDLE EMAIL RESTORE **************/
    openAxigenRestoreLightbox(serviceID, domain) {
        getEmailHostingRecoverFee(serviceID);
        this.setState(
            {
                ...this.state,
                domain,
                showAxigenRestoreLightBox: true,
                selectedServiceID: serviceID
            },
            () => {
                getMailboxList(serviceID);
            }
        );
    }

    closeAxigenRestoreLightbox() {
        this.setState({
            showAxigenRestoreLightBox: false
        });
    }

    /************** HANDLE ACTIONS **************/

    handleKeepSubmit() {
        const { emailid, keepService } = this.props;
        const { keepServiceID } = this.state;

        keepService(keepServiceID, emailid);
    }

    monitorKeyword(keyword) {
        this.setState({
            currentKeyword: keyword
        });
    }

    handleSetupSubmit(values) {
        const { emailid, setupMailbox, getMailboxList } = this.props;
        const attributes = {
            ...values
        };
        setupMailbox(emailid, attributes, { successCallback: () => getMailboxList(emailid) });
    }

    showMore() {
        const { emailid, getMailboxList, email_mailbox_list_data } = this.props;
        const { current_page, last_page } = this.state;

        let next = current_page;
        if (current_page < last_page) {
            next++;
        }
        const params = {
            page: next
        };

        getMailboxList(emailid, params, email_mailbox_list_data);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps) {
        const {
            email_mailbox_list_status,
            email_mailbox_list_meta,
            email_mailbox_list_more_status,
            email_mailbox_setup_status,
            email_keep_status,
            email_restore_mailbox_status,
            email_restore_mailbox_data
        } = this.props;

        const { openPayInvoice } = this;

        registerScrollEvents(this, email_mailbox_list_status === 'success' && prevProps.email_mailbox_list_status === 'loading');

        if (email_mailbox_list_status === 'success' && prevProps.email_mailbox_list_status === 'loading') {
            const { current_page, last_page, total_all_records, total_records } = email_mailbox_list_meta;

            this.setState({
                current_page,
                last_page,
                total_all_records,
                total_records
            });
        }

        if (email_mailbox_list_more_status === 'success' && prevProps.email_mailbox_list_more_status === 'loading') {
            const { current_page, last_page, total_all_records, total_records } = email_mailbox_list_meta;

            this.setState({
                current_page,
                last_page,
                total_all_records,
                total_records
            });
        }

        if (
            email_mailbox_setup_status === 'success' &&
            (prevProps.email_mailbox_setup_status === 'loading' || prevProps.email_mailbox_setup_status === 'error')
        ) {
            this.closeLightbox();
        }

        if (email_keep_status === 'success' && prevProps.email_keep_status === 'loading') {
            this.closeKeepLightbox();
        }

        if (email_restore_mailbox_status === 'success' && prevProps.email_restore_mailbox_status === 'loading') {
            const { id: invoiceID } = email_restore_mailbox_data;

            openPayInvoice(invoiceID);
        }
    }

    render() {
        const {
            emailid,
            mailboxLogin,
            email_keep_status,
            email_mailbox_search_status,
            email_mailbox_search_data,
            email_mailbox_list_status,
            email_mailbox_list_data,
            email_mailbox_setup_status,
            email_mailbox_list_more_status,
            getMailboxList,
            mailboxSearch,
            resetMailboxSearch,
            resetEmailState,
            history,
            app_viewport
        } = this.props;

        const {
            showLightbox,
            showKeepLightbox,
            showInvoiceLightbox,
            invoiceID,
            domain,
            current_page,
            last_page,
            currentKeyword,
            showAxigenRestoreLightBox,
            selectedServiceID
        } = this.state;

        const {
            openLightbox,
            closeLightbox,
            openKeepLightbox,
            closeKeepLightbox,
            openPayInvoice,
            closePayInvoice,
            closePayInvoiceSuccess,
            monitorKeyword,
            handleKeepSubmit,
            handleSetupSubmit,
            showMore,
            openAxigenRestoreLightbox,
            closeAxigenRestoreLightbox
        } = this;

        const renderButton = (domain, status, id, invoice) => {
            const defaultRenderButtons = {
                CANCEL: 'CANCEL',
                LOGIN: 'LOGIN',
                MANAGE: 'MANAGE',
                MANAGE_WITH_INVOICE: 'MANAGE_WITH_INVOICE',
                PAY_INVOICE: 'PAY_INVOICE',
                RESTORE: 'RESTORE',
                PENDING_CANCELLATION: 'PENDING_CANCELLATION'
            };

            /**
             * Renders a default panel button
             * @param {string} _button  - use defaultRenderButtons ENUM
             * @returns {ReactComponentElement} - React Element
             */
            function getDefaultRenderButton(_button) {
                const payInvoiceButton = {
                    label: 'Pay Invoice',
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        openPayInvoice(invoice.id);
                    }
                };

                switch (_button) {
                    case defaultRenderButtons.LOGIN:
                        return {
                            label: 'Login',
                            type: 'onClick',
                            color: 'warning',
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                mailboxLogin(id ? id : emailid);
                            }
                        };
                    case defaultRenderButtons.MANAGE:
                        return {
                            label: 'Manage',
                            type: 'onClick',
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                resetEmailState();
                                if (history) {
                                    history.push(`/my-services/email-hosting/mailbox/overview/${emailid}/mailbox/${id}`);
                                }
                            }
                        };
                    case defaultRenderButtons.MANAGE_WITH_INVOICE:
                        return {
                            ...getDefaultRenderButton(defaultRenderButtons.MANAGE),
                            list: [payInvoiceButton]
                        };
                    case defaultRenderButtons.PAY_INVOICE:
                        return { ...payInvoiceButton, size: 'small' };
                    case defaultRenderButtons.CANCEL:
                        return (
                            <Cancel
                                key={1}
                                button={{
                                    type: 'outline',
                                    label: `Delete Mailbox`,
                                    size: 'small',
                                    className: 'emailMailboxes__delete'
                                }}
                                cancel={{
                                    id,
                                    service: `emailHosting`
                                }}
                            />
                        );
                    case defaultRenderButtons.RESTORE:
                        return {
                            label: COPY_terminatedButtonText,
                            type: 'onClick',
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                openAxigenRestoreLightbox(id, domain);
                            }
                        };
                    case defaultRenderButtons.PENDING_CANCELLATION:
                        return {
                            label: 'Keep Service',
                            type: 'onClick',
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                openKeepLightbox(id);
                            }
                        };
                    default:
                        return <p>Unknown render button: {_button}</p>;
                }
            }

            if (!domain || !status) {
                return {
                    label: 'Setup',
                    button: 'Solid',
                    type: 'onClick',
                    className: '',
                    size: 'small',
                    onClick: (e) => {
                        e.preventDefault();
                        openLightbox();
                    }
                };
            }

            const { LOGIN, MANAGE, MANAGE_WITH_INVOICE, PAY_INVOICE, PENDING_CANCELLATION, RESTORE, CANCEL } = defaultRenderButtons;

            switch (status) {
                case 'active':
                    if (invoice) {
                        return [getDefaultRenderButton(LOGIN), getDefaultRenderButton(MANAGE_WITH_INVOICE)];
                    }

                    return [getDefaultRenderButton(LOGIN), getDefaultRenderButton(MANAGE)];

                case 'Pending Cancellation':
                    return [getDefaultRenderButton(PENDING_CANCELLATION)];

                case 'terminated':
                    if (invoice) {
                        return [getDefaultRenderButton(PAY_INVOICE)];
                    }

                    return [getDefaultRenderButton(RESTORE)];

                case 'suspended':
                    if (invoice) {
                        return [getDefaultRenderButton(CANCEL), getDefaultRenderButton(PAY_INVOICE)];
                    }

                    return [getDefaultRenderButton(CANCEL)];

                case 'pending':
                    return <SupportLink />;
                default:
                    if (invoice) {
                        return [getDefaultRenderButton(LOGIN), getDefaultRenderButton(MANAGE_WITH_INVOICE)];
                    }

                    return [getDefaultRenderButton(LOGIN), getDefaultRenderButton(PAY_INVOICE)];
            }
        };

        const renderRenewal = (date) => {
            const renewDate = toLuxonDate(date);
            const renew = getCurrentDate().diff(renewDate, 'days');
            const renewDays = parseInt(renew.toObject()['days']);
            const renewFormat = formatLuxonOrdinal(renewDate.toFormat('EEEE, MMM {d} yyyy'));

            if (renewDays > 1) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">Overdue by {renewDays} days</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            } else if (renewDays === -1) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">In 1 day</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            } else if (renewDays === 0) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">Today</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            } else if (renewDays === 1) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">Overdue yesterday</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            } else {
                return (
                    <div className="hover__tooltip">
                        <span className={`renew__text ${renewDays > -90 && renewDays < 0 ? 'warning' : ''}`}>
                            In {renewDays.toString().substring(1)} days
                        </span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            }
        };

        const renderMailboxTitle = (mailbox, product) => {
            const { name } = product;
            return (
                <div className="mailboxTitle">
                    <div className="mailboxTitle__title">{mailbox}</div>
                    <div className="mailboxTitle__subtitle">{name}</div>
                </div>
            );
        };

        const isLastPage = () => {
            return !!(current_page && last_page && current_page === last_page);
        };

        const handleTableMatrix = (data) => {
            if (!data) {
                return false;
            }

            const emailTempMatrix = Array(data.length).fill();

            Object.keys(emailTempMatrix).forEach((value) => {
                const { id, included, attributes } = data[value];
                const { domain, domain_status, next_due_date, note } = attributes;
                const invoice = included ? included.filter((include) => include.type === 'invoice')[0] : null;
                const product = included ? included.filter((include) => include.type === 'product')[0] : null;

                const noteData = {
                    containerClassName: `table__tag--container`,
                    message: note
                };

                emailTempMatrix[value] = {
                    domainname: renderMailboxTitle(domain, product && product.attributes ? product.attributes : false),
                    status: <StatusTag limitWidth status={textLowerCase(domain_status)} className="table__solidtag" note={noteData} />,
                    renews: renderRenewal(next_due_date),
                    actions: renderButton(domain, domain_status, id, invoice ? invoice : undefined)
                };
            });

            return emailTempMatrix;
        };

        const handleTableLoading = () => {
            if (email_mailbox_list_status === 'loading' || email_mailbox_search_status === 'loading') {
                return 'loading';
            }

            return 'success';
        };
        let showAll = null;
        const _showAll = {
            label: 'Show More',
            status: email_mailbox_list_more_status,
            conditions: !isLastPage(),
            onClick: (e) => {
                e.preventDefault();
                showMore();
            }
        };

        const handleMatrixCondition = () => {
            if (currentKeyword) {
                showAll = null;
                return email_mailbox_search_data?.length > 0 ? email_mailbox_search_data : false;
            }
            if (email_mailbox_list_data?.length > 0) {
                showAll = _showAll;
                return email_mailbox_list_data;
            }
        };

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const emailMatrix = handleTableMatrix(handleMatrixCondition());
        const mainDomain = email_mailbox_list_data ? `@${email_mailbox_list_data[0]?.attributes?.domain?.split('@')[1]}` : '';

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="emailMailboxes"
            >
                <Box
                    request={{
                        action: getMailboxList,
                        args: emailid,
                        status: email_mailbox_list_status
                    }}
                    className="emailMailboxes__box"
                    status={email_mailbox_list_status}
                    title="Mailboxes"
                    custom={{
                        render: (
                            <Fragment>
                                <Search
                                    slim={true}
                                    render={{
                                        status: email_mailbox_search_status,
                                        placeholder: 'Search for a mailbox'
                                    }}
                                    functions={{
                                        cancel: cancelSearchMailbox,
                                        search: mailboxSearch,
                                        reset: resetMailboxSearch
                                    }}
                                    helpers={{
                                        keyword: monitorKeyword
                                    }}
                                    conditions={{
                                        serviceID: emailid
                                    }}
                                    labelAfterInput={!['sm', 'xs'].includes(app_viewport) ? mainDomain : ''}
                                />
                                {['sm', 'xs'].includes(app_viewport) ? (
                                    <div className="Label__wrapper">
                                        <Label>{mainDomain}</Label>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <Table
                                    className="emailMailboxes__table"
                                    header={[
                                        {
                                            title: `Mailbox / Service`,
                                            className: `mailbox`
                                        },
                                        {
                                            title: `Status`,
                                            className: `status`
                                        },
                                        {
                                            title: `Renews`,
                                            className: `renews`
                                        },
                                        {
                                            action: true
                                        }
                                    ]}
                                    loading={handleTableLoading()}
                                    matrix={emailMatrix}
                                    stacked={true}
                                    embedded={true}
                                    error={
                                        currentKeyword
                                            ? 'No mailboxes matched your search.'
                                            : `You have no active mailboxes on your account. Why don't you purchase some below to get started!`
                                    }
                                    showAll={showAll}
                                />
                            </Fragment>
                        ),
                        pos: 'bottom'
                    }}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceID}
                        invoiceid={invoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
                {showLightbox ? (
                    <OverlayLightbox
                        className="setupService__lightbox"
                        onOpen={showLightbox}
                        onClose={closeLightbox}
                        title="Setup Mailbox"
                        loading={email_mailbox_setup_status}
                    >
                        <SetupForm onSubmit={handleSetupSubmit} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}

                {showKeepLightbox ? (
                    <OverlayLightbox
                        onOpen={showKeepLightbox}
                        onClose={closeKeepLightbox}
                        title="Keep Mailbox?"
                        confirm={{
                            desc: htmr(`Would you like to keep this mailbox active?`),
                            buttonText: 'Keep Active',
                            buttonAction: handleKeepSubmit,
                            closeText: 'No, Go Back',
                            closeAction: closeKeepLightbox,
                            loading: email_keep_status
                        }}
                    />
                ) : (
                    ''
                )}

                {showAxigenRestoreLightBox ? (
                    <OverlayLightbox onOpen={showAxigenRestoreLightBox} onClose={closeAxigenRestoreLightbox} title="Restore Mailbox?">
                        <AxigenRestore
                            domain={domain}
                            history={history}
                            serviceID={selectedServiceID}
                            closeAxigenRestoreLightbox={closeAxigenRestoreLightbox}
                        />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    email_mailbox_list_status: state.email.email_mailbox_list_status,
    email_mailbox_list_data: state.email.email_mailbox_list_data,
    email_mailbox_list_meta: state.email.email_mailbox_list_meta,
    email_mailbox_list_more_status: state.email.email_mailbox_list_more_status,
    email_mailbox_search_status: state.email.email_mailbox_search_status,
    email_mailbox_search_data: state.email.email_mailbox_search_data,
    email_keep_status: state.email.email_keep_status,
    email_mailbox_setup_status: state.email.email_mailbox_setup_status,
    email_restore_mailbox_data: state.email.email_restore_mailbox_data,
    email_restore_mailbox_status: state.email.email_restore_mailbox_status,
    app_viewport: state.app.app_viewport
});

const mapDispatchToProps = {
    resetEmailState,
    keepService,
    getMailboxList,
    setupMailbox,
    mailboxLogin,
    mailboxSearch,
    resetMailboxSearch
};

Mailboxes = connect(mapStateToProps, mapDispatchToProps)(Mailboxes);

Mailboxes = withRouter(Mailboxes);

export default Mailboxes;
