/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import ScamWarningImg from 'assets/images/scam_warning.png';
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ScamWarningMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown() {
        const { dropdownOpen } = this.state;

        this.setState({
            dropdownOpen: !dropdownOpen
        });
    }

    render() {
        const { app_viewport } = this.props;
        const { dropdownOpen } = this.state;
        const { toggleDropdown } = this;

        const renderRest = () => (
            <>
                <p>
                    When logging into {application}, ensure that the URL you have in your search bar is <b>https://vip.ventraip.com.au/login.</b> If
                    your browser&apos;s address bar has any other URL like the image shows below, you should consider this a scam, and you should
                    leave the site immediately.
                    <Anchor href="https://ventraip.com.au/faq/article/verifying-if-a-ventraip-email-is-phishing/" target="_blank">
                        Read more.
                    </Anchor>
                </p>
                <img className="scamWarning__warningImage" src={ScamWarningImg} alt="Scam Warning" />
            </>
        );

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="scamWarning__message">
                <SolidTag className="black">IMPORTANT</SolidTag>
                {['sm', 'xs'].includes(app_viewport) ? (
                    <>
                        <button type="button" onClick={toggleDropdown}>
                            <h2>
                                Maintaining Account Security
                                <i className={`icon icon-arrow-${dropdownOpen ? 'up' : 'down'}`} />
                            </h2>
                            <p>
                                VentraIP is aware of recent increases in fraudulent email activity across the online services industry.{' '}
                                {dropdownOpen ? '' : <span>Read more.</span>}
                            </p>
                        </button>
                    </>
                ) : (
                    <h2>Maintaining Account Security</h2>
                )}
                {dropdownOpen || !['sm', 'xs'].includes(app_viewport) ? renderRest() : ''}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default connect((state) => ({
    app_viewport: state.app.app_viewport
}))(ScamWarningMessage);
