/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {{
 *   file: File,
 *   event: string,
 *   message: string
 * }} TFileReadReject
 *
 * @typedef {{
 *   file_name: string,
 *   attachment: string
 * }} TFileReadResolve
 */

/**
 * @param {PromiseSettledResult<any>[]} results
 * @returns {Array<{
 *   status: 'rejected',
 *   reason: TFileReadReject
 * }>}
 */
// @ts-ignore
const getRejected = (results) => results.filter((result) => result.status === 'rejected');

/**
 * @param {PromiseSettledResult<any>[]} results
 * @returns {Array<{
 *   status: 'fulfilled',
 *   value: TFileReadResolve
 * }>}
 */
// @ts-ignore
const getFulfilled = (results) => results.filter((result) => result.status === 'fulfilled');

/**
 * @description Provides standard functionality for reading files when the form is ready to submit. This will return a
 * Promise that resolves with the files that were read.
 *
 * @param {Array<File>} files - The files that are to be read.
 */
export const handleReadFiles = async (files) => {
    if (!files || !files.length) {
        return [];
    }

    // Handle all file reading and conversion to base64
    const results = await Promise.allSettled(
        files?.map(
            (/** @type {File} */ file) =>
                new Promise((resolve, reject) => {
                    const getEventAsString = (e) => {
                        if (e instanceof Error) return JSON.stringify(e, Object.getOwnPropertyNames(e));
                        return JSON.stringify(e);
                    };

                    try {
                        const reader = new FileReader();

                        reader.onabort = (e) =>
                            reject(
                                /** @satisfies {TFileReadReject} */ ({
                                    message: `Reading of File: ${file.name} was aborted, Ensure the file has not been moved or deleted`,
                                    event: getEventAsString(e),
                                    file
                                })
                            );
                        reader.onerror = (e) =>
                            reject(
                                /** @satisfies {TFileReadReject} */ ({
                                    message: `Failed to read File: ${file.name}, Ensure the file has not been moved or deleted`,
                                    event: getEventAsString(e),
                                    file
                                })
                            );
                        reader.onload = () => {
                            try {
                                resolve(
                                    /** @satisfies {TFileReadResolve} */ ({
                                        file_name: file.name,
                                        attachment: reader.result.toString().split('base64,')[1]
                                    })
                                );
                            } catch (e) {
                                reject(
                                    /** @satisfies {TFileReadReject} */ ({
                                        message: `Failed to encode file: ${file.name}, please try again or use a different file`,
                                        event: 'yes',
                                        file
                                    })
                                );
                            }
                        };

                        reader.readAsDataURL(file);
                    } catch (e) {
                        reject(
                            /** @satisfies {TFileReadReject} */ ({
                                message: `Failed to read file: ${file.name}, Ensure the file has not been moved or deleted`,
                                file,
                                event: getEventAsString(e)
                            })
                        );
                    }
                })
        )
    ).catch((error) => ({ error }));

    if ('error' in results) {
        // Shouldn't ever happen
        return pushNotification();
    }

    const rejected = getRejected(results);
    const fulfilled = getFulfilled(results);

    if (rejected.length) {
        // send sentry alert
        Sentry.captureMessage(JSON.stringify(rejected));

        // Tell customer something went wrong
        return pushNotification({
            status: 500,
            details: rejected[0].reason.message
        });
    }

    return fulfilled.map(({ value }) => value);
};
