/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaGroupFieldGrid } from 'components/Form/KatanaGroupField/grid';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaGroupFormFieldGrid: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name = '', type, properties = [] } = property;

    /***** RENDER *****/
    return <Field name={key} label={name} component={KatanaGroupFieldGrid} properties={properties} type={type} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaGroupFormFieldGrid;
