/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import React, { useRef } from 'react';
import GoogleFontLoader from 'react-google-font';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { PresetCustomiserFontSelect } from 'components/PictureInPicture/controls/fontSelect/presetCustomiserFontSelect';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PictureInPictureIconControlButton } from 'components/PictureInPicture/controls/ControlIconButton';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { filterBodyOrHeadingFonts } from 'containers/katana/containers/contentEditorModules/webpageFonts/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PictureInPictureFontSelect.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _PictureInPictureFontSelect: React.FC<{
    siteData: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes;
    setSiteData: React.Dispatch<React.SetStateAction<KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes>>;
}> = ({ siteData, setSiteData }) => {
    /***** STATE *****/
    const { value: isFontOpensOpen, toggle: toggleFontOpensOpen, setFalse: closeFontOptions } = useBoolean(false);

    /***** HOOKS *****/
    const preventClickRef = useRef();
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_fonts_meta_data } = katanaQuery.serviceID.meta.getFonts.useQuery(id);

    /***** RENDER HELPERS *****/
    const fonts = values(get_fonts_meta_data);
    const availableFonts = filterBodyOrHeadingFonts(fonts).map(({ name }) => ({ font: name }));

    /***** RENDER *****/
    return (
        <Flex className="PictureInPictureFontsButton__wrapper">
            <PictureInPictureIconControlButton
                text="Change font"
                Icon={PhosphorIcons.TextAa}
                onClick={toggleFontOpensOpen}
                buttonRef={preventClickRef}
            />
            <GoogleFontLoader fonts={availableFonts} />

            {isFontOpensOpen && (
                <PresetCustomiserFontSelect
                    siteData={siteData}
                    setSiteData={setSiteData}
                    closeFontOptions={closeFontOptions}
                    preventClickRef={preventClickRef}
                />
            )}
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _PictureInPictureFontSelect };
