/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ConfigAdvanced from '../forms/configAdvanced';
import ConfigSimple from '../forms/configSimple';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderAdvanceListSlim } from 'utilities/methods/form';

const formName = 'configureForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ConfigureForm extends Component {
    componentDidMount() {
        const { ssl_configuration_error, dispatch, form } = this.props;
        if (ssl_configuration_error) {
            const { previousRequest } = ssl_configuration_error;
            Object.keys(previousRequest).forEach((key) => {
                dispatch(change(form, key, previousRequest[key]));
            });
        }
    }

    render() {
        const { cert, handleSubmit, selectedCountry } = this.props;

        const optionList = [
            {
                attributes: {
                    name: 'simple',
                    displayName: 'Simple Setup',
                    desc: 'Step by step process for your SSL certificate setup',
                    content: <ConfigSimple cert={cert} selectedCountry={selectedCountry} />
                }
            },
            {
                attributes: {
                    name: 'advanced',
                    displayName: 'Advanced Setup',
                    desc: 'Provide us with your own generated Certificate Sign Request (CSR)',
                    content: <ConfigAdvanced cert={cert} />
                }
            }
        ];

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form onSubmit={handleSubmit}>
                <div className="sslConfigure__options">
                    <div className="sslConfigure__input">
                        <Field component={RenderAdvanceListSlim} name="method" type="hidden" className="form__textfield" list={optionList} />
                    </div>
                </div>
                <ReduxFormButton form={formName} className="sslConfigure__submit">
                    Continue Setup
                </ReduxFormButton>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ConfigureForm = reduxForm({
    form: formName
})(ConfigureForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector(formName);
    const selectedState = selector(state, 'state');
    const selectedCountry = selector(state, 'country');

    return {
        form: formName,
        selectedState,
        selectedCountry,
        ssl_configuration_error: state.ssl.ssl_configuration_error
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureForm);
