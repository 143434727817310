/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Configure from './sections/configure';
import Manage from './sections/manage';

import Certificate from './modules/certificate';
import Configuration from './modules/configure';
import Install from './modules/install';
import Overview from './modules/overview';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar';
import Subnav from 'components/Subnav';
import Title from 'components/Title';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';
import { handleSideMenuOptions, isURLValid } from 'utilities/methods/sectionRendering';
import { getSslInformation } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import { handleRedirect } from 'utilities/methods/redirects/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import './_ssl.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class SSL extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            cert: {
                product: '',
                domain: '',
                type: '',
                email: ''
            }
        };
    }

    componentDidMount() {
        const { match, getSslInformation } = this.props;
        const { params } = match;
        const { id } = params;

        getSslInformation(id);
    }

    componentDidUpdate(prevProps) {
        const { ssl_information_status, ssl_information_data, ssl_information_error, history } = this.props;

        if (ssl_information_error?.status === 403) {
            history.push(redirectUrls.ssl);
            pushNotification(ssl_information_error);
        } else if (ssl_information_data) {
            handleRedirect({
                serviceType: 'ssl',
                status: ssl_information_data?.attributes?.status,
                domainName: ssl_information_data?.attributes?.domain,
                history
            });
        }

        if (ssl_information_status === 'success' && prevProps.ssl_information_status === 'loading') {
            const { included, attributes } = ssl_information_data;
            const { domain, ssl_type, vendor } = attributes;
            const product = getIncludedObjBasedOnType(included, 'product');

            this.setState({
                mounted: true,
                cert: {
                    product: product.attributes.name,
                    domain: domain,
                    vendor: vendor,
                    type: ssl_type,
                    email: vendor === 'Trustwave' ? 'webmaster' : 'admin'
                }
            });
        }
    }

    render() {
        const { history, location, match, app_viewport, ssl_information_status, ssl_information_data } = this.props;
        const { mounted, cert } = this.state;
        const { pathname } = location;
        const { url } = match;
        const { params } = match;
        const { id, subpage } = params;

        const renderTitle = () => {
            if (ssl_information_data) {
                const { type } = ssl_information_data;

                return (
                    <Title
                        serviceTitle={cert.product}
                        serviceSubTitle={cert.domain}
                        serviceType={type}
                        action={[
                            <CustomDropdown
                                className="TitleShared__singleDropdown"
                                label={({ labelRef, dropdownTitleOnClick }) => (
                                    <button
                                        className="TitleShared__singleDropdownButton"
                                        ref={labelRef}
                                        type="button"
                                        onClick={() => dropdownTitleOnClick()}
                                    >
                                        SSL Certificate Help
                                        <i className="item__icon icon icon-chevron-down" />
                                    </button>
                                )}
                                key="dropdown"
                                render={() => (
                                    <SelectOptions
                                        options={[
                                            {
                                                label: 'SSL Certificate Help Articles',
                                                onSelect: () => history.push('/support/support-centre/ssl-certificates')
                                            },
                                            {
                                                label: 'Submit a Technical Support eTicket for this Service',
                                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'technical-support', service: id }))
                                            },
                                            {
                                                label: 'Submit a Billing eTicket for this Service',
                                                onSelect: () => history.push(getTicketSubmitUrl({ department: 'accounts-billing', service: id }))
                                            }
                                        ]}
                                    />
                                )}
                            />
                        ]}
                    />
                );
            }

            return '';
        };

        const modules = {
            manage: [
                {
                    sidebarLink: {
                        label: 'Overview',
                        link: `/my-services/ssl/manage/overview/${id}`
                    },
                    component: <Overview sslid={id} />,
                    condition: true,
                    identifier: 'ssl/overview'
                },
                {
                    sidebarLink: {
                        label: 'Install SSL',
                        link: `/my-services/ssl/manage/install/${id}`
                    },
                    component: <Install sslid={id} />,
                    condition: true,
                    identifier: 'ssl/install'
                },
                {
                    sidebarLink: {
                        label: 'SSL Details',
                        link: `/my-services/ssl/manage/details/${id}`
                    },
                    component: <Certificate sslid={id} />,
                    condition: true,
                    identifier: 'ssl/details'
                }
            ],
            configure: [
                {
                    sidebarLink: {
                        label: 'Configuration',
                        link: `/my-services/ssl/configure/configuration/${id}`
                    },
                    component: <Configuration sslid={id} cert={cert} />,
                    condition: true,
                    identifier: 'ssl/configuration'
                }
            ]
        };

        const renderSidemenu = () => {
            const manageList = {
                list_title: 'Manage',
                list_items: handleSideMenuOptions(modules, 'manage')
            };

            return <Sidebar title="Manage SSL Certificate" icon="about-certs" links={[manageList]} />;
        };

        const renderSection = () => {
            if (subpage === 'manage') return <Manage modules={modules.manage.filter(({ condition }) => condition)} />;
            return null;
        };

        const handleRenderState = () => {
            if (!mounted || ssl_information_status === 'loading') {
                return <RequestLoader />;
            } else if (ssl_information_status === 'error') {
                return <PageNotFound />;
            }

            if (subpage === 'configure' || subpage === 'validate') {
                return (
                    <Fragment>
                        {renderTitle()}
                        <Page>
                            <Configure modules={modules.configure.filter(({ condition }) => condition)} />
                        </Page>
                    </Fragment>
                );
            }

            if (isURLValid(modules, url)) {
                return (
                    <Fragment>
                        {renderTitle()}
                        {app_viewport === 'sm' || app_viewport === 'xs' ? renderSidemenu() : ''}
                        <div className="ssl">
                            {app_viewport === 'md' || app_viewport === 'lg' || app_viewport === 'xl' ? renderSidemenu() : ''}
                            <Transition when={subpage} className="ssl__section">
                                {renderSection()}
                            </Transition>
                        </div>
                    </Fragment>
                );
            }

            return <PageNotFound />;
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <Fragment>
                <Subnav pathname={pathname} />
                {handleRenderState()}
            </Fragment>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        ssl_information_status: state.ssl.ssl_information_status,
        ssl_information_data: state.ssl.ssl_information_data,
        ssl_information_error: state.ssl.ssl_information_error,
        app_viewport: state.app.app_viewport
    };
};

const mapDispatchToProps = {
    getSslInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(SSL);
