/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import HostingAddonsForm from '../forms/addons';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import OutlineButton from 'components/Buttons/OutlineButton';
import CancelAddon from 'components/Cancel/Addon';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/*   ACTIONS
 *****************************************************/
import { addonMethods } from 'containers/hosting/methods';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { cancelAddonsRequest, getActiveAddons, keepActiveAddon, updateActiveAddons } from '../state/baseActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class HostingAddons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lightboxTemplate: null,
            lightboxMeta: null
        };

        this.setLightboxTemplate = this.setLightboxTemplate.bind(this);
        this.handleAddonSubmit = this.handleAddonSubmit.bind(this);
        this.handleAddonCancel = this.handleAddonCancel.bind(this);
        this.handleAddonKeep = this.handleAddonKeep.bind(this);
        this.toggleInvoiceLightbox = this.toggleInvoiceLightbox.bind(this);
    }

    setLightboxTemplate(lightboxTemplate = null, lightboxMeta = null, cb) {
        this.setState(
            {
                lightboxTemplate,
                lightboxMeta
            },
            () => {
                if (cb) cb();
            }
        );
    }

    handleAddonSubmit(values) {
        const { hostingid, updateActiveAddons, hosting_information_data } = this.props;

        const addons = addonMethods.processAddonsForPurchase(values, hosting_information_data.attributes?.billing_cycle_id);

        updateActiveAddons(hostingid, { addons });
    }

    handleAddonCancel() {
        const { lightboxMeta } = this.state;
        const { cancelAddonsRequest } = this.props;

        cancelAddonsRequest(lightboxMeta.invoiceID);
    }

    handleAddonKeep() {
        const { hostingid, keepActiveAddon } = this.props;
        const { lightboxMeta } = this.state;
        const attributes = { addon_id: lightboxMeta.addon_id };

        keepActiveAddon(hostingid, attributes);
    }

    /************** INVOICE TOGGLES **************/
    toggleInvoiceLightbox(id) {
        this.setState({
            showInvoiceLightbox: !this.state.showInvoiceLightbox,
            invoiceID: id ? id : null
        });
    }

    /************** COMPONENT STATES **************/

    componentDidUpdate(prevProps) {
        const {
            hosting_addon_update_status,
            hosting_addon_update_data,
            hosting_addon_remove_status,
            hosting_addon_cancel_status,
            hosting_addon_keep_status,
            getActiveAddons,
            hostingid,
            hosting_addons_list_status
        } = this.props;
        const { setLightboxTemplate } = this;

        registerScrollEvents(this, hosting_addons_list_status === 'success' && prevProps.hosting_addons_list_status === 'loading');

        if (
            (hosting_addon_cancel_status === 'success' && prevProps.hosting_addon_cancel_status === 'loading') ||
            (hosting_addon_remove_status === 'success' && prevProps.hosting_addon_remove_status === 'loading') ||
            (hosting_addon_keep_status === 'success' && prevProps.hosting_addon_keep_status === 'loading')
        ) {
            setLightboxTemplate(null, null, () => getActiveAddons(hostingid));
        }

        if (hosting_addon_update_status === 'success' && prevProps.hosting_addon_update_status === 'loading') {
            const { id } = hosting_addon_update_data;
            setLightboxTemplate('invoice', { invoiceID: id }, getActiveAddons(hostingid));
        }
    }

    render() {
        const {
            hostingid,
            getActiveAddons,
            hosting_addons_list_status,
            hosting_addons_list_data,
            hosting_addon_update_status,
            hosting_addon_cancel_status,
            hosting_addon_keep_status
        } = this.props;
        const { lightboxTemplate, lightboxMeta } = this.state;
        const { handleAddonSubmit, handleAddonCancel, handleAddonKeep, setLightboxTemplate } = this;

        const renderActiveAddon = (addon) => {
            const { id, name, description, price, pendingCancellation } = addonMethods.processActiveAddonData(
                addon,
                hosting_addons_list_data.product_addons
            );

            return (
                <div key={id} className="hostingAddons__addon">
                    <div className="addon__container">
                        <div className="addon__column">
                            <div className="title">{name}</div>
                            <div className="desc">{description}</div>
                        </div>
                        <div className="addon__column">
                            <span className="addon__price">{price}</span>
                        </div>
                        <div className="addon__column action">
                            {pendingCancellation ? (
                                <OutlineButton
                                    color="notice"
                                    type="onClick"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setLightboxTemplate('keep', { addon_id: id, name });
                                    }}
                                >
                                    Keep Active
                                </OutlineButton>
                            ) : (
                                <OutlineButton
                                    color="warn"
                                    type="onClick"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setLightboxTemplate('remove', { addon_id: id });
                                    }}
                                >
                                    Remove
                                </OutlineButton>
                            )}
                        </div>
                    </div>
                </div>
            );
        };

        /*   SET CONDITIONAL PROPS
         **********************************************************************************************************/
        let conditionalProps = {};

        const pendingPurchase = [];
        const purchasable = [];

        if (hosting_addons_list_data?.product_addons) {
            hosting_addons_list_data.product_addons.forEach((item) => {
                if (!item.is_purchased) {
                    if (item.invoice) {
                        pendingPurchase.push(item);
                    } else {
                        purchasable.push(item);
                    }
                }
            });
        }

        if (pendingPurchase.length > 0 || purchasable.length > 0 || hosting_addons_list_data?.service_addons?.length > 0) {
            conditionalProps = {
                custom: {
                    render: (
                        <>
                            {pendingPurchase.length > 0 && (
                                <>
                                    <div className="hostingAddons__pending">
                                        <div className="hostingAddons__pendingTitle">Addons Pending Invoice Payment</div>
                                        <ul className="hostingAddons__pendingList">
                                            {pendingPurchase.map((addon, index) => (
                                                <li key={index}>{addon.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            )}
                            {purchasable.length > 0 && (
                                <>
                                    <div className="hostingAddons__table--header">Available Addons</div>
                                    <HostingAddonsForm handleSubmit={handleAddonSubmit} purchasable={purchasable} />
                                </>
                            )}

                            {hosting_addons_list_data?.service_addons?.length > 0 && (
                                <>
                                    <div className="hostingAddons__table--header">Active Addons</div>
                                    <div className="hostingAddons__active">
                                        {hosting_addons_list_data.service_addons.map((addonItem) => {
                                            return renderActiveAddon(addonItem);
                                        })}
                                    </div>
                                </>
                            )}
                        </>
                    ),
                    pos: 'bottom'
                }
            };
        } else {
            conditionalProps = {
                custom: {
                    render: <p className="hostingAddons__pending">There are no addons available to purchase at this time.</p>,
                    pos: 'bottom'
                }
            };
        }

        const invoice = pendingPurchase.length > 0 ? pendingPurchase[0].invoice : null;

        if (invoice) {
            conditionalProps = {
                ...conditionalProps,
                notification: {
                    info: {
                        type: 'warning',
                        text: htmr(
                            'You have an outstanding addon invoice, make a payment now to complete addon purchase. You can also cancel the order by cancelling the invoice.'
                        ),
                        action: [
                            {
                                label: 'Pay Invoice',
                                buttonType: 'Solid',
                                type: 'onClick',
                                className: '',
                                color: 'white',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    setLightboxTemplate('invoice', { invoiceID: invoice });
                                }
                            },
                            {
                                label: 'Cancel Invoice',
                                buttonType: 'Solid',
                                type: 'onClick',
                                className: '',
                                color: 'white',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    setLightboxTemplate('cancel', { invoiceID: invoice });
                                }
                            }
                        ]
                    }
                }
            };
        }

        const handleLoadingStatus = () => {
            if (hosting_addons_list_status === 'loading' || hosting_addon_update_status === 'loading') return 'loading';
            if (hosting_addons_list_status === 'error') return 'error';
            return 'success';
        };

        /*  Render Component
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.scrollRef = el;
                }}
                className="hostingAddons"
            >
                <Box
                    request={{
                        action: getActiveAddons,
                        args: hostingid,
                        status: hosting_addons_list_status
                    }}
                    className="hostingAddons__box"
                    title="Addons"
                    desc="Add extra functionality to your hosting service with these addons."
                    status={handleLoadingStatus()}
                    {...conditionalProps}
                />
                {lightboxTemplate === 'cancel' ? (
                    <OverlayLightbox
                        onOpen={lightboxTemplate === 'cancel'}
                        onClose={setLightboxTemplate}
                        title="Cancel Addon Invoice?"
                        confirm={{
                            desc: htmr("Cancelling this invoice will also cancel the addon order. Are you sure you'd like to do this?"),
                            buttonText: 'Cancel',
                            buttonAction: handleAddonCancel,
                            closeText: 'No, Go Back',
                            closeAction: setLightboxTemplate,
                            loading: hosting_addon_cancel_status
                        }}
                    />
                ) : (
                    ''
                )}
                {lightboxTemplate === 'remove' ? <CancelAddon type="hosting" onClose={setLightboxTemplate} addonId={lightboxMeta.addon_id} /> : ''}
                {lightboxTemplate === 'keep' ? (
                    <OverlayLightbox
                        className="hostingAddons__lightbox"
                        onOpen={lightboxTemplate === 'keep'}
                        onClose={setLightboxTemplate}
                        title="Keep Addon?"
                        confirm={{
                            desc: htmr(`Would you like to keep <strong>${lightboxMeta.name}</strong> active?`),
                            buttonText: 'Keep Active',
                            buttonAction: handleAddonKeep,
                            closeText: 'No, Go Back',
                            closeAction: setLightboxTemplate,
                            loading: hosting_addon_keep_status
                        }}
                    />
                ) : (
                    ''
                )}
                {lightboxTemplate === 'invoice' ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + lightboxMeta.invoiceID}
                        invoiceid={lightboxMeta.invoiceID}
                        onOpen={lightboxTemplate === 'invoice'}
                        onClose={setLightboxTemplate}
                        onSuccessClose={() => setLightboxTemplate(null, null, () => getActiveAddons(hostingid))}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            hosting_information_data: state.hosting.hosting_information_data,
            hosting_addons_list_status: state.hosting.hosting_addons_list_status,
            hosting_addons_list_data: state.hosting.hosting_addons_list_data,
            hosting_addon_remove_status: state.hosting.hosting_addon_remove_status,
            hosting_addon_update_status: state.hosting.hosting_addon_update_status,
            hosting_addon_update_data: state.hosting.hosting_addon_update_data,
            hosting_addon_cancel_status: state.hosting.hosting_addon_cancel_status,
            hosting_addon_keep_status: state.hosting.hosting_addon_keep_status
        }),
        {
            getActiveAddons,
            updateActiveAddons,
            cancelAddonsRequest,
            keepActiveAddon
        }
    )(HostingAddons)
);
