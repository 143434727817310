/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useResetScrollEvents from 'utilities/methods/hooks/useResetScrollEvents';
import { renderSectionModules } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The "general" section of TPP domain management
 */
function General({ modules }) {
    /***** HOOKS *****/
    useResetScrollEvents();

    /***** RENDER *****/
    return <div>{renderSectionModules(modules)}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default General;
