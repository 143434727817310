/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import PayPalImage from 'assets/images/paypal.png';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PayPalMethodInfo() {
    return (
        <div key="paypal" className="tab__item tab__item--paypal paypal">
            <img className="availablePaymentMethod__image" src={PayPalImage} alt="paypal" />
            <div className="availablePaymentMethod__description">
                <strong>PLEASE NOTE:</strong> Choosing PayPal as your payment method requires manual payment for every invoice. Recurring or automatic
                payment <strong>CANNOT</strong> be setup.
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PayPalMethodInfo;
