/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, getFormMeta, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Anchor from '../Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import { RenderLargeListRadioButtons } from 'components/Form/RenderLargeListRadioButtons';
import { FieldTypesEnum, getFieldComponentAndClassDynamicForm, getFieldValidation, getInitialValues } from './utils';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { DynamicFormConditionCheck } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @extends {React.Component<import('./types').DynamicFormTypes.TDynamicFormProps>}
 */
class DynamicForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputs: {},
            additionals: {}
        };

        this.addOrRemoveAdditionals = this.addOrRemoveAdditionals.bind(this);
        this.evaluateConditions = this.evaluateConditions.bind(this);
    }

    addOrRemoveAdditionals(groupName, action) {
        const { form, formValues, dispatch } = this.props;
        const { additionals } = this.state;

        if (!Object.prototype.hasOwnProperty.call(additionals, groupName)) {
            return this.setState({
                additionals: {
                    ...this.state.additionals,
                    [groupName]: 1
                }
            });
        }

        const currentNumOfAdditionals = this.state.additionals[groupName];
        if (action === 'decrement') {
            Object.keys(formValues).forEach((fieldName) => {
                if (fieldName.includes(groupName) && fieldName.includes(currentNumOfAdditionals + 1)) {
                    dispatch(change(form, fieldName, undefined));
                }
            });
        }

        this.setState({
            additionals: {
                ...this.state.additionals,
                [groupName]: action === 'increment' ? currentNumOfAdditionals + 1 : currentNumOfAdditionals - 1
            }
        });
    }

    /**
     *
     * @param {import('components/DynamicForm/typedef').DynamicFormConditions} condition
     * @returns {boolean}
     */
    evaluateConditions(condition) {
        const { formValues, fields } = this.props;

        if (!condition) return true;

        /**
         * @param {import('components/DynamicForm/typedef').DynamicFormCondition} _condition
         * @returns {boolean}
         */
        function evaluateCondition(_condition) {
            const { fieldName, check, allNeeded } = _condition;

            const { TRUTHY, FALSY, ACTIVE } = DynamicFormConditionCheck;

            /**
             * @param {import('components/DynamicForm/typedef').DynamicFormConditionCheckType} word
             * @return {boolean}
             */
            const evaluateCheck = (word) => {
                if (typeof word !== 'string') return !!word;

                switch (word) {
                    case TRUTHY:
                        return formValues && formValues[fieldName];
                    case FALSY:
                        return formValues && !formValues[fieldName];
                    case ACTIVE:
                        return fields && fields[fieldName]?.active;
                    default:
                        return false;
                }
            };

            if (allNeeded) {
                for (let i = 0; i < check.length; i++) {
                    if (!evaluateCheck(check[i])) {
                        return false;
                    }
                }
                return true;
            }

            for (let i = 0; i < check.length; i++) {
                if (evaluateCheck(check[i])) {
                    return true;
                }
            }
            return false;
        }

        if (Array.isArray(condition)) {
            for (let i = 0; i < condition.length; i++) {
                const _condition = condition[i];

                if (!evaluateCondition(_condition)) {
                    return false;
                }
            }

            return true;
        }

        return evaluateCondition(condition);
    }

    render() {
        const { formHasSteps, data, stepModifiers, inputOverrides, fieldWrappers, handleSubmit, form, formButtonProps, formValues, change } =
            this.props;
        const { additionals } = this.state;
        const { addOrRemoveAdditionals, evaluateConditions } = this;

        const renderInputField = (index, input) => {
            const overrides = inputOverrides[input.name] || {};
            const fieldWrapper = fieldWrappers[input.name] || false;
            const { componentOverride, classNameOverrides, validationOverride, labelOverride, disabled, autoComplete } = overrides;

            const props = {};

            const componentAndClassObj = getFieldComponentAndClassDynamicForm(input.type, componentOverride);

            const newClassName =
                formValues && classNameOverrides?.map((item) => (evaluateConditions(item.condition) ? item.className : '')).join(' ');

            if (input.type === FieldTypesEnum.DROPZONE) {
                props.accept = input.accept;
                props.label = '';
                props.onChange = (files) => change(input.name, files);
                props.compact = true;
            }

            if (input.type === FieldTypesEnum.TEXTAREA) {
                props.rows = 12;
            }

            if (input.type === 'radio_group') {
                props.selected = input.selected;
                props.options = input.options;
            }

            if (input.type === 'select') {
                props.selected = input.selected;
                props.options = [...input.options].map((item) => ({ label: item[Object.keys(item)[0]], value: item[Object.keys(item)[0]] }));
            }

            if (input.type === 'date' || input.type === 'time') {
                props.dateFormat = 'd/M/yyyy';
                props.maxDate = new Date();
            }

            if (disabled) {
                props.disabled = true;
            }

            // Workaround to stop firefox from auto filling with login credentials
            if (input.type === 'password') {
                props.autoComplete = 'new-password';
            }

            if (autoComplete) {
                props.autoComplete = autoComplete;
            }

            if (componentOverride === RenderLargeListRadioButtons) {
                props.itemlist = input.options;
            }

            const renderReduxFormField = () => {
                return (
                    <Field
                        key={index}
                        component={componentAndClassObj.component}
                        label={labelOverride || input.label}
                        name={input.name}
                        placeholder={input.placeholder || ''}
                        validate={validationOverride || getFieldValidation(input.validation)}
                        type={input.type === 'radio' ? false : input.type}
                        className={newClassName || componentAndClassObj.className}
                        props={props}
                    />
                );
            };

            const renderFieldWithWrapper = () => {
                const classes =
                    (formValues && fieldWrapper.classNames.map((item) => (evaluateConditions(item.condition) ? item.className : '')).join(' ')) ?? '';

                return (
                    <div className={classes}>
                        {fieldWrapper.beforeInput}
                        {renderReduxFormField()}
                        {fieldWrapper.afterInput}
                    </div>
                );
            };

            return fieldWrapper ? renderFieldWithWrapper() : renderReduxFormField();
        };

        const renderStep = (step, index) => {
            const { inputs, label } = step;

            // if repeatable, set group name
            let groupName;
            let groupLabel;
            let repeatable = false;
            const repeatableSet = inputs.find((input) => input.type === 'repeatable');

            if (inputs.find((input) => input.type === 'repeatable')) {
                repeatable = true;
                groupName = repeatableSet.name;
                groupLabel = repeatableSet.label;
            }

            const renderAddAdditionalsLink = () => {
                return (
                    <Anchor
                        onClick={(e) => {
                            e.preventDefault();
                            addOrRemoveAdditionals(groupName, 'increment');
                        }}
                        className="DynamicForm__addAdditionalsLink"
                    >
                        <>
                            <i className="icon icon-invalid" />
                            <small>Add another {groupLabel || 'Email Account'}</small>
                        </>
                    </Anchor>
                );
            };

            const renderRemoveAdditionalsLink = () => {
                return (
                    <Anchor onClick={() => addOrRemoveAdditionals(groupName, 'decrement')} className="DynamicForm__removeAdditionalsLink">
                        <>
                            <small>Remove</small>
                            <PhosphorIcons.X />
                        </>
                    </Anchor>
                );
            };

            const renderOneSetOfAdditionals = (num) => {
                return (
                    <div key={num} className="DynamicForm__stepAdditionals">
                        <div className="DynamicForm__stepFields">
                            {repeatableSet.fields.map((input, index) => {
                                const newField = { ...input };
                                newField.label += ` ${num}`;

                                return renderInputField(index, newField);
                            })}
                        </div>
                        {num === additionals[groupName] + 1 && renderRemoveAdditionalsLink()}
                        {renderAddAdditionalsLink()}
                    </div>
                );
            };

            const renderAdditionals = () => {
                const fieldLabelPrefixNumbers = [];

                for (let j = 2; j < additionals[groupName] + 2; j++) {
                    fieldLabelPrefixNumbers.push(j);
                }

                return fieldLabelPrefixNumbers.map((num) => renderOneSetOfAdditionals(num));
            };

            const getStepModifiers = () => {
                const currentStepModifiers = stepModifiers[label];

                if (currentStepModifiers && formValues) {
                    const modifiers = currentStepModifiers.map((item) => {
                        const { modifier, condition } = item;
                        const evaluation = evaluateConditions(condition) ? ` DynamicForm__step--${modifier}` : '';
                        return evaluation;
                    });

                    return modifiers.join('');
                }
                return '';
            };

            return (
                <div key={index} className={`DynamicForm__step${getStepModifiers()}`}>
                    <div className="DynamicForm__stepContainer">
                        <div className="DynamicForm__stepLabel">
                            <span>{`Step ${index + 1}. `}</span>
                            {label}
                        </div>
                        <div className="DynamicForm__stepFields">
                            {inputs.map((input, index) => {
                                // repeatable fields
                                if (input.type === 'repeatable') {
                                    return input.fields.map((field, index) => {
                                        return renderInputField(index, field);
                                    });
                                }

                                return renderInputField(index, input);
                            })}
                        </div>
                        {repeatable && additionals && !additionals[groupName] && renderAddAdditionalsLink()}
                    </div>
                    {renderAdditionals()}
                </div>
            );
        };

        const renderSteps = () => {
            return data.map((step, index) => renderStep(step, index));
        };

        const renderFields = () => {
            return data.map((field, index) => renderInputField(index, field));
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="DynamicForm" onSubmit={handleSubmit}>
                {formHasSteps ? renderSteps() : renderFields()}
                <ReduxFormButton form={form} {...formButtonProps} />
            </form>
        );
    }
}

DynamicForm = reduxForm({
    enableReinitialize: true
})(DynamicForm);

DynamicForm = connect((state, ownProps) => {
    const { formHasSteps, data, form } = ownProps;

    return {
        initialValues: getInitialValues(formHasSteps, data),
        formValues: getFormValues(form)(state),
        fields: getFormMeta(form)(state)
    };
})(DynamicForm);
/**********************************************************************************************************
 *   END DYNAMIC FORM BUILDER
 **********************************************************************************************************/

export default DynamicForm;
