/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import RenderPhoneField from 'components/Form/PhoneInput';
import {
    RenderField,
    RenderSelectField,
    domainFieldNoProtocolValidation,
    domainFieldValidation,
    requiredFieldValidation,
    validateReactPhoneNumberInput
} from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { getLocalStates } from 'App/action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let SimpleForm = ({
    cert,
    selectedCountry,

    /**
     * Redux State
     */
    app_local_states_data
}) => {
    /***** EFFECTS *****/
    useEffect(() => {
        const { dispatch } = store;

        dispatch(change('configureForm', 'country', 'AU'));

        if (!app_local_states_data) getLocalStates();
    }, []);

    return (
        <div className="sslConfigure__form">
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Domain Name"
                        name="domain"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation, domainFieldValidation, domainFieldNoProtocolValidation]}
                        className="form__textfield"
                        tooltip={`This is the hosting service you want the SSL to be installed on. To secure your main domain,
                            please enter mydomain.com or to secure your subdomain, please enter subdomain.mydomain.com`}
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="Organisation"
                        name="organisation"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="City"
                        name="city"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="Country"
                        name="country"
                        component={RenderSelectField}
                        type="select"
                        validate={[requiredFieldValidation]}
                        className="form__dropdown"
                        options={[
                            {
                                label: 'Australia',
                                value: 'AU'
                            },
                            {
                                label: 'New Zealand',
                                value: 'NZ'
                            }
                        ]}
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="State"
                        name="state"
                        component={RenderSelectField}
                        type="select"
                        validate={[requiredFieldValidation]}
                        className="form__dropdown"
                        options={app_local_states_data?.processedOptions?.[selectedCountry?.toLowerCase()] ?? []}
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="Phone"
                        name="phone"
                        component={RenderPhoneField}
                        required
                        country={selectedCountry ?? 'AU'}
                        countrySelectEnabled={false}
                        type="tel"
                        validate={[requiredFieldValidation, validateReactPhoneNumberInput]}
                        className="form__textfield"
                    />
                </div>
                {cert.type !== 'EV' ? (
                    <div className="form__column half">
                        <div className="form__column sslConfigure__box__description sslConfigure__Tooltip">
                            Why is this important?
                            <Tooltip
                                className="sslConfigure__Tooltip__important"
                                info="The Certificate Vendor may be required to call you as part of the verification process."
                            />
                        </div>
                    </div>
                ) : (
                    <div className="form__column half">
                        <Field
                            label="Business Category"
                            name="business_category"
                            component={RenderSelectField}
                            type="select"
                            placeholder=""
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                            options={[
                                {
                                    label: 'Please select',
                                    value: ''
                                },
                                {
                                    label: 'Private Organisation',
                                    value: 'Private Organisation'
                                },
                                {
                                    label: 'Government Entity',
                                    value: 'Government Entity'
                                },
                                {
                                    label: 'Business Entity',
                                    value: 'Business Entity'
                                },
                                {
                                    label: 'Non-commercial Entity',
                                    value: 'Non-commercial Entity'
                                }
                            ]}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    app_local_states_data: state.app.app_local_states_data
});

SimpleForm = connect(mapStateToProps)(SimpleForm);

export default SimpleForm;
