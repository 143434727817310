/**********************************************************************************************************
 *   CONSTS - COPY TEXT
 **********************************************************************************************************/

export const addonTypes = {
    CPU: 'cpu',
    RAM: 'ram',
    DISK: 'disk',
    BANDWIDTH: 'bandwidth',
    SOFTWARE: 'software'
};
