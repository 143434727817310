/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { isValidElementType } from 'react-is';
import { useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Modal } from 'components/Lightboxes/Modal';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function DEV_IconsOverview() {
    /***** HOOKS *****/
    const history = useHistory();

    /***** RENDER HELPERS *****/
    function renderIconSet(iconSet, parentKey = '') {
        return Object.entries(iconSet).flatMap(([key, Value]) => {
            const validElementType = isValidElementType(Value);
            if (!validElementType) {
                return renderIconSet(Value, parentKey ? `${parentKey}.${key}` : key);
            }

            return (
                <Grid columns="100%" alignItems--center justifyItems--center>
                    <Value size={28} />
                    <Text>{parentKey ? `${parentKey}.${key}` : key}</Text>
                </Grid>
            );
        });
    }

    const allPHIcons = renderIconSet(PhosphorIcons);

    /***** RENDER *****/
    return (
        <Modal
            isOpen
            onClose={() => {
                history.push('/dashboard');
            }}
        >
            <Text uppercase bold>
                Phosphor Icons:
            </Text>
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>{allPHIcons}</div>
        </Modal>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DEV_IconsOverview;
