/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class BillingCycleSuccess extends Component {
    static successButtonMessage(type) {
        switch (type) {
            case 'email':
                return 'Back to Manage Mailbox';
            default:
                return 'Back';
        }
    }

    render() {
        const { currentInvoice, updatedInvoice, closeLightbox, serviceType } = this.props;

        const renderInvoices = () => {
            const { new_invoice_id, remaining_invoice_id } = updatedInvoice;

            return (
                <div className="emailBillingCycleSuccess__invoices">
                    <div className="emailBillingCycleSuccess__old">
                        <div className="emailBillingCycleSuccess__old--heading">Disregard old Invoice</div>
                        <div className="emailBillingCycleSuccess__old--invoice">#{currentInvoice.id}</div>
                        <div className="emailBillingCycleSuccess__old--description">Your services that were on the same invoice</div>
                    </div>
                    <i className="icon icon-arrow" />
                    <div className="emailBillingCycleSuccess__new">
                        <div className="emailBillingCycleSuccess__new--heading">NEW Invoice created</div>
                        <div className="emailBillingCycleSuccess__new--invoice">#{new_invoice_id}</div>
                        <div className="emailBillingCycleSuccess__new--description">Your services with the new billing cycle</div>
                    </div>
                    {remaining_invoice_id && (
                        <div className="emailBillingCycleSuccess__remaining">
                            <div className="emailBillingCycleSuccess__remaining--heading">NEW Invoice create</div>
                            <div className="emailBillingCycleSuccess__remaining--invoice">#{remaining_invoice_id}</div>
                            <div className="emailBillingCycleSuccess__remaining--description">Your other services</div>
                        </div>
                    )}
                </div>
            );
        };

        const renderSuccessDescription = () => {
            return <div className="emailBillingCycleSuccess__description">This will take effect at the end of the current cycle.</div>;
        };

        return (
            <div className="emailBillingCycleSuccess">
                <i className="icon icon-valid" />
                <div className="emailBillingCycleSuccess__heading">Billing cycle successfully changed</div>
                {renderSuccessDescription()}
                {currentInvoice && updatedInvoice && renderInvoices()}
                <SolidButton className="emailBillingCycleSuccess__button" type="onClick" onClick={closeLightbox}>
                    {BillingCycleSuccess.successButtonMessage(serviceType)}
                </SolidButton>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
BillingCycleSuccess = reduxForm({
    form: 'billingCycleSuccess'
})(BillingCycleSuccess);

export default withRouter(BillingCycleSuccess);
